import { PlusSquareOutlined } from '@ant-design/icons';
import { Button, Space, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { TWorksheetWorkerView } from 'src/domain/worksheet/view/tWorksheetWorkerView';
import { WorksheetHeadData } from 'src/domain/worksheet/view/worksheetHeadData';
import AddWorksheetWorkerModal from '../modal/AddWorksheetWorkerModal';

interface ModalProp {
  worksheetHeadData: WorksheetHeadData;
  setWorksheetHeadData: any;
  tworksheetWorkerFormDataList: TWorksheetWorkerView[];
  setTworksheetWorkerFormDataList: any;
}

const WorkerDetailTab: React.FC<ModalProp> = (props) => {
  const { worksheetHeadData, setWorksheetHeadData, tworksheetWorkerFormDataList, setTworksheetWorkerFormDataList } = props;
  const [visibleWorksheetWorkerModal, setVisibleWorksheetWorkerModal] = useState(false);

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      key: "index",
      align: "center" as "center",
      width: 50,
      render: (value: any, item: TWorksheetWorkerView, index: number) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: "ชื่อ-นามสกุล",
      dataIndex: "iworkerName",
      key: "iworkerName",
      width: 150,
    },
    {
      title: "ที่อยู่",
      dataIndex: "iworkerAddress",
      key: "iworkerAddress",
      width: 250,
    },
    {
      title: "เบอร์โทร",
      dataIndex: "iworkerPhone",
      key: "iworkerPhone",
      width: 150,
    },
    {
      title: "ประเภทพนักงาน",
      dataIndex: "itypeworkerName",
      key: "itypeworkerName",
      width: 150,
    },
    {
      title: "",
      key: "action",
      align: "center" as "center",
      with: 20,
      render: (value: any, item: TWorksheetWorkerView, index: number) => {
        return (
          <span>
            <a onClick={(e) => { onDelete(item, index); }} style={{ color: "red" }}
            >
              X
            </a>
          </span>
        );
      },
    },
  ];

  const reindex = (tworksheetWorkerFormDataList: TWorksheetWorkerView[]) => {
    return tworksheetWorkerFormDataList.map((item, index) => {
      item.iid = index;
      return item;
    });
  }

  useEffect(() => {
    setWorksheetHeadData({
      ...(worksheetHeadData as WorksheetHeadData),
      tworksheetWorkerViewList: [...reindex(tworksheetWorkerFormDataList)],
    });
  }, [tworksheetWorkerFormDataList]);

  const onDelete = (item: TWorksheetWorkerView, index: number) => {
    const array = tworksheetWorkerFormDataList ? [...tworksheetWorkerFormDataList] : [];
    array?.splice(index, 1);
    setTworksheetWorkerFormDataList(array);
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
          justifyContent: "space-between",
        }}
      >
        <Space align="baseline" direction="horizontal">
          <Button
            size="small"
            type="primary"
            icon={<PlusSquareOutlined />}
            onClick={() => setVisibleWorksheetWorkerModal(true)}
          >
            เพิ่ม
          </Button>
        </Space>
        <Typography.Title
          type="danger"
          level={4}
          style={{ height: "22px", textAlign: "right" }}
        >
          จำนวน :{" "}
          {!worksheetHeadData?.tworksheetWorkerViewList ? 0 : worksheetHeadData?.tworksheetWorkerViewList.length}
        </Typography.Title>
      </div>
      <div>
        <Table
          columns={columns}
          dataSource={tworksheetWorkerFormDataList}
          size="small"
          pagination={false}
          rowKey={(item: TWorksheetWorkerView) => item.iid}
        />
      </div>
      <div>
        {visibleWorksheetWorkerModal && (
          <AddWorksheetWorkerModal
            visibleWorksheetWorkerModal={visibleWorksheetWorkerModal}
            setVisibleWorksheetWorkerModal={setVisibleWorksheetWorkerModal}
            tworksheetWorkerFormDataList={tworksheetWorkerFormDataList}
            setTworksheetWorkerFormDataList={setTworksheetWorkerFormDataList}
          ></AddWorksheetWorkerModal>
        )}
      </div>
    </>
  )
}

export default WorkerDetailTab