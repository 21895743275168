import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Form, Input, Modal } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "src/stores/store";
import { TBankHead } from "../../domain/master/tBankHead";
import tBankHeadService from "../../services/bank/tBankHeadService";
import { NotificationType, showNotification } from "../../utils/notificationUtils";

const { confirm } = Modal;

interface IParams {
  ibankCode: string;
  visibleBankHeadModal: boolean;
  setVisibleBankHeadModal: any;
}

const AddEditBankHeadModal: React.FC<IParams> = (props) => {
  const { ibankCode, visibleBankHeadModal, setVisibleBankHeadModal } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [bankHead, setBankHead] = useState<TBankHead>({} as TBankHead);
  const [form] = Form.useForm();
  const history = useHistory();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  let title: string = "เพิ่มข้อมูลธนาคาร";
  if (ibankCode) {
    title = "แก้ไขข้อมูลธนาคาร";
  }

  useEffect(() => {
    if (trigger) {
      form.validateFields()
        .then(() => {
          confirm({
            title: "ยืนยัน!",
            icon: <ExclamationCircleOutlined />,
            content: "คุณต้องการที่จะบันทึกข้อมูลหรือไม่?",
            cancelText: "ยกเลิก",
            okText: "ยืนยัน",
            onOk() {
              form.submit();
            },
            onCancel() {
              setTrigger(false);
            }
          });
        }).catch(() => {
          setTrigger(false);
        });
    }
  }, [trigger]);

  useEffect(() => {
    loadBankHead();
    return () => { };
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      ibankCode: bankHead.ibankCode,
      ibankName: bankHead.ibankName,
      shortname: bankHead.shortname,
    });
    return () => { };
  }, [bankHead]);

  const loadBankHead = async () => {
    if (ibankCode) {
      const res = await tBankHeadService.getBankHeadByIBankCode(ibankCode);
      setBankHead(res.data);
    } else {
      const nextCodeRes = await tBankHeadService.getNextBankHeadCode();
      setBankHead({ ...bankHead, ibankCode: nextCodeRes.data });
    }
  };

  const onFinish = async (values: any) => {
    const _bankHead: TBankHead = {
      ibankCode: values.ibankCode,
      ibankName: values.ibankName,
      shortname: values.shortname,
      isavStation: userInfo.saveStation,
      itermNo: userInfo.termNo,
      icreateUser: userInfo.userId,
      ilastUser: userInfo.userId
    };

    if (!ibankCode) {
      try {
        const isExistDataRes = await tBankHeadService.checkExistData(values.shortname, values.ibankName);
        if (isExistDataRes.data) {
          showNotification(NotificationType.ERROR, `ข้อมูลธนาคารนี้  ${values.shortname} :  ${values.ibankName}   มีอยู่แล้ว ไม่สามารถเพิ่มใหม่ได้ `);
          form.setFieldsValue({
            shortname: "",
            ibankName: "",
          });
          setTrigger(false);
        } else {
          const res = await tBankHeadService.postBankHead(_bankHead);
          showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลธนาคารเรียบร้อยแล้ว!");
          form.resetFields();
          setTrigger(false);
          setVisibleBankHeadModal(false);
          history.replace("/app/bankHead/view/" + res.data.ibankCode);
        }
      } catch (err) {
        showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        setTrigger(false);
      }
    } else {
      try {
        await tBankHeadService.updateBankHead(_bankHead);
        showNotification(NotificationType.SUCCESS, "แก้ไขข้อมูลธนาคารเรียบร้อยแล้ว!");
        form.resetFields();
        setTrigger(false);
        setVisibleBankHeadModal(false);
        history.replace("/app/bankHead/view/" + ibankCode);
      } catch (err) {
        showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        setTrigger(false);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    setTrigger(false);
  };

  const handleShortNameOnBlur = (shortnameValue: string) => {
    form.setFieldsValue({
      shortname: shortnameValue.toUpperCase(),
    });
  };

  return (
    <>
      <Modal
        title={title}
        cancelText={"ยกเลิก"}
        okText={"บันทึก"}
        visible={visibleBankHeadModal}
        width={700}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setVisibleBankHeadModal(false)}
      >
        <Content className="app-page-content">
          <Form
            form={form}
            name="bankHead"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            // initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="รหัส"
              name="ibankCode"
              rules={[{ required: true }]}
            >
              <Input disabled={true} style={{ width: 100 }} />
            </Form.Item>

            <Form.Item
              label="รหัสย่อ"
              name="shortname"
              rules={[
                {
                  required: true, message: "กรุณาป้อนรหัสย่อ"
                },
                {
                  validator: (_, value) => {
                    if (value && (value === " " || value === "-")) {
                      return Promise.reject(new Error('กรุณาป้อนข้อมูลรหัสย่อให้ถูกต้อง!'))
                    } else {
                      return Promise.resolve()
                    }
                  },
                },
              ]}
            >
              <Input
                style={{ width: 200 }}
                maxLength={10}
                placeholder={"ป้อนรหัสย่อ"}
                onBlur={(event) => handleShortNameOnBlur(event.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="ชื่อธนาคาร"
              name="ibankName"
              rules={[
                { required: true, message: "กรุณาใส่ชื่อธนาคาร!", },
                {
                  validator: (_, value) => {
                    if (value && (value === " " || value === "-")) {
                      return Promise.reject(new Error('กรุณากรอกข้อมูลธนาคารให้ถูกต้อง!'))
                    } else {
                      return Promise.resolve()
                    }
                  },
                },
              ]}
            >
              <Input maxLength={30} placeholder={"ป้อนชื่อธนาคาร"} />
            </Form.Item>
          </Form>
        </Content>
      </Modal>
    </>
  );
};

export default AddEditBankHeadModal;
