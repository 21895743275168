import http from "../lib/http";
import Constants from "../utils/constants";

const getSteelDetailTAssetHeadsByCode = (reserveCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetSteelDetail/getSteelDetailTAssetHeadsByCode", {
    params: {
      reserveCode
    },
  });
};

const defaultApi = {
  getSteelDetailTAssetHeadsByCode,
};

export default defaultApi;