import http from "src/lib/http";
import Constants from "src/utils/constants";

const getLessThanEqualsAtInstallment = (worksheetCode: string, atInstallment: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetInstalmentDetail/getLessThanEqualsAtInstallment", {
    params: {
      worksheetCode,
      atInstallment,
    },
  });
};

const defaultApi = {
  getLessThanEqualsAtInstallment,
};

export default defaultApi;