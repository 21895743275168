import { WarningOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, Form, Input, Modal, Row, Space, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { TRangradWangbillHeadView } from 'src/domain/view/tRangradWangbillHeadView';
import PageHeader from 'src/layouts/PageHeader';
import tRangradWangbillHeadService from 'src/services/tRangradWangbillHeadService';
import { toThDate } from 'src/utils/dateTimeUtils';
import { formatNumber } from 'src/utils/numberUtils';
import FinanceReciveableFromRangRadDrawer from './FinanceReciveableFromRangRadDrawer';

const { warning } = Modal;

const FinanceNonReciveableFromRangRad: React.FC<RouteComponentProps> = (props) => {
  const [form] = Form.useForm();
  const [page, setPage] = React.useState(1);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [tRangradWangbillHeadViews, setTRangradWangbillHeadViews] = useState<TRangradWangbillHeadView[]>([]);
  const [selectTRangradWangbillHeadView, setSelectTRangradWangbillHeadView] = useState<TRangradWangbillHeadView>({} as TRangradWangbillHeadView);
  const [onDrawerVisible, setOnDrawerVisible] = useState<boolean>(false);
  const [selectHighlight, setSelectHighlight] = useState<number>(-1)

  const [sumNetTotal, setSumNetTotal] = useState<number>(0.00)
  const [rangradWangbillCount, setRangradWangbillCount] = useState<number>(0)

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: TRangradWangbillHeadView, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
    },
    {
      title: "วันที่สร้าง",
      dataIndex: "ientryDate",
      key: "ientryDate",
      width: 80,
      align: "center" as "center",
      render: (value: any, item: TRangradWangbillHeadView) => {
        return <span>{item.ientryDate ? toThDate(item.ientryDate) : ""}</span>;
      },
    },
    {
      title: "เลขที่ใบแจ้งหนี้",
      dataIndex: "idebitnoteNo",
      key: "idebitnoteNo",
      width: 120,
      align: "center" as "center",
      render: (value: string, item: TRangradWangbillHeadView) => {
        return <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.idebitnoteNo}</Button>
      }
    },
    {
      title: "ชื่อลูกหนี้",
      dataIndex: "debitorname",
      key: "debitorname",
      width: 250,
    },
    {
      title: "ค่าบริการ",
      dataIndex: "nettotal",
      key: "nettotal",
      align: "right" as "right",
      render: (value: any, item: TRangradWangbillHeadView) => {
        return <span>{formatNumber(item.nettotal)}</span>;
      },
    },
    {
      title: "สถานะใบแจ้งหนี้",
      dataIndex: "idebitnotestatusName",
      key: "idebitnotestatusName",
      width: 150,
    },
    {
      title: "ผู้บันทึก",
      dataIndex: "icreateUserName",
      key: "icreateUserName",
    },
  ];

  const getItemColor = (item: TRangradWangbillHeadView, index: any) => {
    let rowStyle = '';
    let rowTextStyle = '';
    if (index === selectHighlight) {
      rowStyle = "row-selected"
    }
    switch (item.idebitnotestatus) {
      case "00":
        rowTextStyle = 'black';
        break;
      case "01":
        rowTextStyle = 'red';
        break;
      case "02":
        rowTextStyle = 'blue';
        break;
      default:
        rowTextStyle = 'black';
        break;
    }

    return rowStyle + ' text-' + rowTextStyle;
  }

  useEffect(() => {
    doSearch(form);
  }, []);

  useEffect(() => {
    (async () => {
      if (!onDrawerVisible && selectTRangradWangbillHeadView.idebitnoteNo) {
        const res = await tRangradWangbillHeadService.getByDebitNoteNo(selectTRangradWangbillHeadView.idebitnoteNo);
        const index = (tRangradWangbillHeadViews as Array<TRangradWangbillHeadView>).findIndex(object => object.idebitnoteNo === selectTRangradWangbillHeadView.idebitnoteNo);
        if (index !== -1 && res.data.idebitnotestatus === '99') {
          const arraySplice = tRangradWangbillHeadViews ? [...tRangradWangbillHeadViews] : [];
          arraySplice?.splice(index, 1);
          setTRangradWangbillHeadViews(arraySplice);
        }
          
        form.setFieldsValue({
          debitNoteCodeDebitorName: "",
        });
      }
    })();
  }, [onDrawerVisible]);

  const doSearch = async (values: any) => {
    setIsLoading(true);
    const debitNoteCodeDebitorName = (values.debitNoteCodeDebitorName) ? values.debitNoteCodeDebitorName : "";
    const fromDate = "";
    const toDate = "";
    const debitNoteStatuses = ['00', '01', '02', '03', '04', '97'];
    // const debitNoteStatuses = ['02'];//this.rdHeadCol.GetByDateStatus("", "", "02", debitCode, debitorName, false);
    const res = await tRangradWangbillHeadService.getByDateStatus(fromDate, toDate, debitNoteCodeDebitorName, debitNoteStatuses.toString());

    setTRangradWangbillHeadViews(res.data.RANGRAD_WANGBILL_LIST);
    setSumNetTotal(res.data.SUM_NET_TOTAL);
    setRangradWangbillCount(res.data.RANGRAD_WANGBILL_COUNT);
    form.setFieldsValue({
      sumServiceAmount: formatNumber(res.data.SUM_SERVICE_AMOUNT),
      sumVatAmount: formatNumber(res.data.SUM_VAT_AMOUNT),
      worksheetInCount: res.data.RANGRAD_WANGBILL_IN_COUNT,
      worksheetOutCount: res.data.RANGRAD_WANGBILL_OUT_COUNT,
    })
    setIsLoading(false);
  }

  const onItemCliked = (item: TRangradWangbillHeadView) => {

    if (item.idebitnotestatus !== '02') {
      warning({
        title: "รายงาน!",
        icon: <WarningOutlined />,
        content: "ไม่สามารถรับเงินได้ เนื่องจากสถานะใบแจ้งหนี้ยังไม่ได้ถูกส่งให้การเงิน!",
        okText: "ตกลง"
      });
      return;
    }
    setSelectTRangradWangbillHeadView(item);
    setOnDrawerVisible(true);
  };

  return (
    <>
      <PageHeader title="" onClose={() => history.push("/app/financeReciveableFromRangRad/list")}>
        <div className="d-flex flex-row">
          <div className="action-container"></div>
        </div>
      </PageHeader>
      <Content className="app-page-content">
        <Descriptions
          style={{ margin: "1em" }}
          column={4}
          title="ค้นหาเงินเร่งรัด"
          size="middle"
        />
        <div>
          <Form
            form={form}
            name="tWorksheetInsuranceForm"
            onFinish={doSearch}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "baseline",
                justifyContent: "space-between",
                margin: "1em 0 1em 0",
                padding: "1em",
                borderBlockEnd: "2px solid #c9c9c9",
              }}
            >
              <Form.Item
                label="เลขที่ใบแจ้งหนี้/ชื่อลูกหนี้"
                name="debitNoteCodeDebitorName"
                rules={[{ required: false }]}
                style={{ width: "50%" }}
              >
                <Input
                  allowClear
                  placeholder="ป้อนเลขที่ใบแจ้งหนี้/ชื่อลูกหนี้"
                  onPressEnter={form.submit}
                ></Input>
              </Form.Item>
              <Space direction="horizontal" size={"middle"}>
                <Space direction="vertical" size={"small"} align='center'>
                  <Typography.Title level={3}>จำนวนใบแจ้งหนี้</Typography.Title>
                  <Typography.Title type="danger" level={3}>{rangradWangbillCount}</Typography.Title>
                </Space>
                <Space direction="vertical" size={"small"} align='center'>
                  <Typography.Title level={3}>จำนวนเงินทั้งหมด</Typography.Title>
                  <Typography.Title type="danger" level={3}>{formatNumber(sumNetTotal)}</Typography.Title>
                </Space>
              </Space>
            </div>
            <div>
              <Table
                columns={columns}
                dataSource={tRangradWangbillHeadViews}
                size="small"
                loading={isLoading}
                rowClassName={(item, index) => getItemColor(item, index)}
                rowKey={(item: TRangradWangbillHeadView) => item.idebitnoteNo!}
                onRow={(item: TRangradWangbillHeadView, index: any) => {
                  return {
                    onClick: () => {
                      if (selectHighlight === index) {
                        setSelectHighlight(-1);
                      } else {
                        setSelectHighlight(index);
                      }
                    },
                  };
                }}
                pagination={{
                  pageSize: 15,
                  onChange(current) {
                    setPage(current);
                  }
                }}
              />
            </div>
            <Row>
              <Col span={7} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text strong>ค่าบริการ: </Typography.Text>
              </Col>
              <Col span={5}>
                <Form.Item
                  name="sumServiceAmount"
                >
                  <Input style={{ border: 0, fontWeight: "bold", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                </Form.Item>
              </Col>
              <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text strong>ใบแจ้งหนี้นอก: </Typography.Text>
              </Col>
              <Col span={9}>
                <Space align='baseline'>
                  <Form.Item
                    name="worksheetOutCount"
                  >
                    <Input style={{ border: 0, fontWeight: "bold", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                  </Form.Item>
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={7} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text strong>ภาษี: </Typography.Text>
              </Col>
              <Col span={5}>
                <Form.Item
                  name="sumVatAmount"
                >
                  <Input style={{ border: 0, fontWeight: "bold", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                </Form.Item>
              </Col>
              <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text strong>ใบแจ้งหนี้ใน: </Typography.Text>
              </Col>
              <Col span={9}>
                <Space align='baseline'>
                  <Form.Item
                    name="worksheetInCount"
                  >
                    <Input style={{ border: 0, fontWeight: "bold", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                  </Form.Item>
                </Space>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
      <div>
        {onDrawerVisible && (
          <FinanceReciveableFromRangRadDrawer
            onDrawerVisible={onDrawerVisible}
            setOnDrawerVisible={setOnDrawerVisible}
            selectTRangradWangbillHeadView={selectTRangradWangbillHeadView}
          ></FinanceReciveableFromRangRadDrawer>
        )}
      </div>
    </>
  )
}

export default FinanceNonReciveableFromRangRad