import { Form, Input, Modal, Select, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { RyRefownerAsset } from 'src/domain/ryRefownerAsset';
import { TPrenameRef } from 'src/domain/tPrenameRef';
import tPrenameRefService from 'src/services/reference/tPrenameRefService';
import ryRefownerAssetService from 'src/services/tasset/ryRefownerAssetService';
import { useAppSelector } from 'src/stores/store';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';

const { Option } = Select;

interface IProps {
  visibleAddRyRefownerAssetModal: boolean;
  setVisibleAddRyRefownerAssetModal: any;
}
const AddRyRefownerAssetModal: React.FC<IProps> = (props) => {
  const { visibleAddRyRefownerAssetModal, setVisibleAddRyRefownerAssetModal } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [prenames, setPrenames] = useState<TPrenameRef[]>([]);
  const [ryRefownerAssetCode, setRyRefownerAssetCode] = useState<string>("");
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  const [form] = Form.useForm();

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  useEffect(() => {
    initial();
    return () => { };
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      id: ryRefownerAssetCode
    });
  }, [ryRefownerAssetCode]);

  const initial = async () => {
    if (visibleAddRyRefownerAssetModal) {
      const prenamesRes = await tPrenameRefService.getTPrenameRefList();
      const nextCodeRes = await ryRefownerAssetService.getNextRyRefownerAssetCode();

      setRyRefownerAssetCode(nextCodeRes.data);
      setPrenames(prenamesRes.data)
    }
  };

  const onFinish = async (values: any) => {
    const _ryRefownerAsset: RyRefownerAsset = {
      id: values.id,
      prenamecode: values.prenamecode,
      name: values.name,
      remark: values.remark,
      savstation: userInfo.saveStation,
      createuser: userInfo.userId,
      lastuser: userInfo.userId,
      termno: userInfo.termNo
    };

    try {
      await ryRefownerAssetService.postRyRefownerAsset(_ryRefownerAsset);
      showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลผู้ถือกรรมสิทธิ์เรียบร้อยแล้ว");
      setVisibleAddRyRefownerAssetModal(false);
      form.resetFields();
      setTrigger(false);
    } catch (err) {
      showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
      setTrigger(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน");
    setTrigger(false);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>เพิ่มข้อมูลผู้ถือกรรมสิทธิ์</Typography.Title>
          </div>
        }
        visible={visibleAddRyRefownerAssetModal}
        width={"50%"}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setVisibleAddRyRefownerAssetModal(false)}
      >
        <Form
          form={form}
          name="ryRefownerAsset"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="รหัส"
            name="id"
            rules={[
              { required: false }
            ]}
          >
            <Input disabled={true} style={{ width: "200px" }} />
          </Form.Item>
          <Form.Item
            label="คำนำหน้า"
            name="prenamecode"
            rules={[{ required: true, message: "กรุณาระบุคำนำหน้า !" }]}
          >
            <Select
              showSearch
              size="middle"
              placeholder="เลือกคำนำหน้า"
              optionFilterProp="children"
              filterOption={true}
              style={{ width: "200px" }}
            >
              {prenames.map((obj) => {
                return (
                  <Option key={obj.iprenameCode} value={obj.iprenameCode}>
                    {obj.iprenameName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label="ชื่อ"
            name="name"
            rules={[{ required: true, message: "กรุณาระบุชื่อ!" }]}
          >
            <Input placeholder="ป้อนชื่อ" maxLength={250}></Input>
          </Form.Item>

          <Form.Item
            label="หมายเหตุ"
            name="remark"
            rules={[{ required: true, message: "กรุณาระบุหมายเหตุ!" }]}
          >
            <TextArea
              rows={4}
            ></TextArea>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default AddRyRefownerAssetModal