import { EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Descriptions, Form, Input, Modal, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { AssetWorksheet } from "src/domain/chart/assetWorksheet";
import { TWorksheetHead } from "src/domain/worksheet/tWorksheetHead";
import { TWorksheetConfirmReserveView } from "src/domain/worksheet/view/tWorksheetConfirmReserveView";
import PageHeader from "src/layouts/PageHeader";
import chartviewService from "src/services/chart/chartviewService";
import tConfirmreserveHeadService from "src/services/tConfirmreserveHeadService";
import { useAppSelector } from "src/stores/store";
import Constants from "src/utils/constants";
import { formatTime, toThDate } from "src/utils/dateTimeUtils";
import { NotificationType, showNotification } from "src/utils/notificationUtils";
import { formatNumber } from "src/utils/numberUtils";
import "../../custom-style.css";
import tWorksheetHeadService from "../../services/tWorksheetHeadService";
import ChartNewWorksheet from "../chart/ChartNewWorksheet";
import AddEditWorkSheetSteelHeadDrawer from "../steel/AddEditWorkSheetSteelHeadDrawer";

const { confirm } = Modal;

const ViewTConfirmreserveHead: React.FC<RouteComponentProps> = () => {
    const [tWorksheetHeadConfirmReserves, setTWorksheetHeadConfirmReserves] = useState<TWorksheetConfirmReserveView[]>([]);
    const [form] = Form.useForm();
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = React.useState(false);
    const history = useHistory();
    const userInfo = useAppSelector((state) => state.authen.authenUser.user);
    const [statePg, setStatePg] = useState<string>("UPD");
    const [selectedTConfirmreserveHead, setSelectedTConfirmreserveHead] = useState<TWorksheetConfirmReserveView>({} as TWorksheetConfirmReserveView);
    const [onDrawerClose, setOnDrawerClose] = useState(false);
    const [visibleAddEditWorkSheetSteelHeadDrawer, setVisibleAddEditWorkSheetSteelHeadDrawer] = useState<boolean>(false);
    const [selectHighlight, setSelectHighlight] = useState<number>(-1)

    const [worksheetPreviewData, setWorksheetPreviewData] = useState<any>();
    const [worksheetPreviewBlob, setWorksheetPreviewBlob] = useState<any>();
    const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false);

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            width: 70,
            render: (value: number, item: TWorksheetConfirmReserveView, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
        },
        {
            title: "เลขที่ใบจอง",
            dataIndex: "ireserveCode",
            key: "ireserveCode",
            width: 130
        },
        {
            title: "วันเวลาทำงาน",
            dataIndex: "startDate",
            key: "startDate",
            width: 200,
            render: (value: any, item: TWorksheetConfirmReserveView) => (<>{toThDate(item.istartDate)}:{formatTime(item.istartTime)} ถึง {toThDate(item.iendDate)}:{formatTime(item.iendTime)}</>)
        },
        {
            title: "ลูกค้า",
            dataIndex: "icustName",
            key: "icustName",
        },
        {
            title: "รถ",
            dataIndex: "truckNumbers",
            key: "truckNumbers",
            width: 100
        },
        {
            title: "บริการ",
            dataIndex: "serviceName",
            key: "serviceName",
        },
        {
            title: "สถานที่",
            dataIndex: "ilocation",
            key: "ilocation",
        },
        {
            title: "ค่าบริการ",
            dataIndex: "iserviceAmt",
            key: "iserviceAmt",
            width: 100,
            align: "right" as "right",
            render: (value: any, item: TWorksheetConfirmReserveView) => {
                return <span>{formatNumber(item.iserviceAmt)}</span>;
            },
        },
        {
            title: "ค่าอื่นๆ",
            dataIndex: "iextraAmt",
            key: "iextraAmt",
            width: 100,
            align: "right" as "right",
            render: (value: any, item: TWorksheetConfirmReserveView) => {
                return <span>{formatNumber(item.iextraAmt)}</span>;
            },
        },
        {
            title: "ภาษี",
            dataIndex: "itaxAmt",
            key: "itaxAmt",
            width: 100,
            align: "right" as "right",
            render: (value: any, item: TWorksheetConfirmReserveView) => {
                return <span>{formatNumber(item.itaxAmt)}</span>;
            },
        },
        {
            title: "รวม",
            dataIndex: "itotal2Amt",
            key: "itotal2Amt",
            width: 100,
            align: "right" as "right",
            render: (value: any, item: TWorksheetConfirmReserveView) => {
                return <span>{formatNumber(item.itotal2Amt)}</span>;
            },
        }
    ];

    const getItemColor = (item: TWorksheetConfirmReserveView, index: any) => {
        const now = moment().format(Constants.DB_FORMAT);
        let rowStyle = '';
        if (index === selectHighlight) {
            rowStyle = "row-selected"
        }
        if (Number(now) > Number(item.iendDate)) {
            return rowStyle ? rowStyle + ' text-red' : 'text-red';
        }
        if (Number(item.iflgWshOt) !== 0) {
            return rowStyle ? rowStyle + ' text-blue' : 'text-blue';
        }
        return rowStyle;
    }

    useEffect(() => {
        initial();
    }, []);

    useEffect(() => {
        if (!onDrawerClose || visibleAddEditWorkSheetSteelHeadDrawer) {
            initial();
        }
    }, [onDrawerClose, visibleAddEditWorkSheetSteelHeadDrawer]);

    useEffect(() => {
        if (!isPreviewModalVisible && worksheetPreviewData) {
            history.replace("/app/tConfirmreserveHead/list");
        }
    }, [isPreviewModalVisible]);

    const onEditWorksheet = () => {
        if (!selectedTConfirmreserveHead.ireserveCode) {
            showNotification(NotificationType.WARNING, "กรุณาระบุใบงานที่ต้องการแก้ไขก่อน !");
            setLoading(false);
        } else {
            if ('04' === selectedTConfirmreserveHead.typeworksheetflag) {
                setVisibleAddEditWorkSheetSteelHeadDrawer(true);
            } else {
                setOnDrawerClose(true);
            }
        }
    }
    
    const printWorksheet = async (tWorksheetHead: TWorksheetHead) => {
        const worksheetConfirmRes = await tWorksheetHeadService.getByReserveCode(tWorksheetHead.ireserveCode!)
        const resultWorksheetPreview = await chartviewService.getWorksheetPreview(worksheetConfirmRes.data, true);
        if (resultWorksheetPreview.data) {
            setWorksheetPreviewBlob(resultWorksheetPreview.data);
            const fileObj = URL.createObjectURL(resultWorksheetPreview.data);
            setWorksheetPreviewData(fileObj);
            setIsPreviewModalVisible(true);
        } else {
            showNotification(
                NotificationType.ERROR,
                "ไม่พบข้อมูลที่เกี่ยวข้อง ไม่สามารถแสดงข้อมูลตัวอย่างได้"
            );
        }
    };

    const initial = () => {
        setLoading(true);
        const branchId = userInfo.branchId;
        const reserveCodeCustname = form.getFieldValue("reserveCodeCustname") ? form.getFieldValue("reserveCodeCustname") : "";
        tWorksheetHeadService.getWorksheetConfirmReserveByBranchId(branchId, reserveCodeCustname)
            .then((res) => {
                setTWorksheetHeadConfirmReserves(res.data);
            }).finally(() => {
                setLoading(false);
            });
    };

    const onItemCliked = (item: TWorksheetConfirmReserveView) => {
        setSelectedTConfirmreserveHead(item);
    }

    const onItemDoubleCliked = (item: TWorksheetConfirmReserveView) => {
        setLoading(true);
        tWorksheetHeadService.getByReserveCode(item.ireserveCode!)
            .then((res) => {
                setLoading(false);
                const tWorksheetHead = res.data as TWorksheetHead;
                if (tWorksheetHead.ireserveFlg !== 0) {
                    showNotification(NotificationType.ERROR, "รายการจองนี้ถูกยืนยันไปแล้วกรุณาโหลดข้อมูลใหม่อีกครั้ง!");
                    initial();
                } else {
                    const nowDateStr = moment().format(Constants.DATE_FORMAT);
                    const nowTimeStr = moment().format(Constants.TIME_FORMAT);
                    const nowDateAsInt = Number(moment().format(Constants.DB_FORMAT));
                    const nowTimeAsInt = Number(moment().format(Constants.DB_TIME_FORMAT));
                    const startDateAsInt = Number(tWorksheetHead.istartDate);
                    const endDateAsInt = Number(tWorksheetHead.iendDate);

                    Promise.resolve().then(() => {
                        return new Promise((resolve, reject) => {
                            if (startDateAsInt < nowDateAsInt) {
                                confirm({
                                    title: "คำเตือน!",
                                    icon: <ExclamationCircleOutlined />,
                                    content: "ไม่สามารถยืนยันใบจองที่มีวันที่เริ่มงาน ก่อนวันที่ " + nowDateStr + " ได้ ต้องการพิมพ์ใบสั่งงานหลังกำหนด",
                                    cancelText: "ยกเลิก",
                                    okText: "ยืนยัน",
                                    onOk() {
                                        resolve(true);
                                    },
                                    onCancel() {
                                        reject(false);
                                    },
                                });
                            } else {
                                resolve(true);
                            }
                        })
                    })
                        .then(() => {
                            return new Promise((resolve, reject) => {
                                if (endDateAsInt < nowDateAsInt) {
                                    confirm({
                                        title: "คำเตือน!",
                                        icon: <ExclamationCircleOutlined />,
                                        content: "ไม่สามารถยืนยันใบจองที่มีวันที่ครบกำหนด ก่อนวันที่ " + nowDateStr + " ได้ ต้องการพิมพ์ใบสั่งงานหลังกำหนด",
                                        cancelText: "ยกเลิก",
                                        okText: "ยืนยัน",
                                        onOk() {
                                            resolve(true);
                                        },
                                        onCancel() {
                                            reject(false);
                                        },
                                    });
                                } else {
                                    resolve(true);
                                }
                            })
                        })
                        .then(() => {
                            return new Promise((resolve, reject) => {
                                if (startDateAsInt === nowDateAsInt && Number(tWorksheetHead.istartTime) < nowTimeAsInt) {
                                    confirm({
                                        title: "คำเตือน!",
                                        icon: <ExclamationCircleOutlined />,
                                        content: "ไม่สามารถยืนยันใบจองที่มีเวลาเริ่มก่อนเวลา " + nowTimeStr + " ได้ ต้องการพิมพ์ใบสั่งงานหลังกำหนด",
                                        cancelText: "ยกเลิก",
                                        okText: "ยืนยัน",
                                        onOk() {
                                            resolve(true);
                                        },
                                        onCancel() {
                                            reject(false);
                                        },
                                    });
                                } else {
                                    resolve(true);
                                }
                            })
                        })
                        .then(() => {
                            return new Promise((resolve, reject) => {
                                if (startDateAsInt === nowDateAsInt && Number(tWorksheetHead.iendTime) < nowTimeAsInt) {
                                    confirm({
                                        title: "คำเตือน!",
                                        icon: <ExclamationCircleOutlined />,
                                        content: "ไม่สามารถยืนยันใบจองที่มีเวลาครบกำหนดก่อนเวลา " + nowTimeStr + " ได้ ต้องการพิมพ์ใบสั่งงานหลังกำหนด",
                                        cancelText: "ยกเลิก",
                                        okText: "ยืนยัน",
                                        onOk() {
                                            resolve(true);
                                        },
                                        onCancel() {
                                            reject(false);
                                        },
                                    });
                                } else {
                                    resolve(true);
                                }
                            })
                        })
                        .then(() => {
                            return new Promise((resolve, reject) => {
                                if (startDateAsInt === nowDateAsInt && Number(tWorksheetHead.iendTime) < nowTimeAsInt) {
                                    confirm({
                                        title: "คำเตือน!",
                                        icon: <ExclamationCircleOutlined />,
                                        content: "ไม่สามารถยืนยันใบจองที่มีเวลาครบกำหนดก่อนเวลา " + nowTimeStr + " ได้ ต้องการพิมพ์ใบสั่งงานหลังกำหนด",
                                        cancelText: "ยกเลิก",
                                        okText: "ยืนยัน",
                                        onOk() {
                                            resolve(true);
                                        },
                                        onCancel() {
                                            reject(false);
                                        },
                                    });
                                } else {
                                    resolve(true);
                                }
                            })
                        })
                        .then(() => {
                            return new Promise((resolve, reject) => {
                                if (startDateAsInt > endDateAsInt) {
                                    confirm({
                                        title: "คำเตือน!",
                                        icon: <ExclamationCircleOutlined />,
                                        content: "ไม่สามารถยืนยันใบจองที่มวันที่เริ่ม มากกว่าวันที่ครบกำหนดได้ ต้องการพิมพ์ใบสั่งงานหลังกำหนด",
                                        cancelText: "ยกเลิก",
                                        okText: "ยืนยัน",
                                        onOk() {
                                            resolve(true);
                                        },
                                        onCancel() {
                                            reject(false);
                                        },
                                    });
                                } else {
                                    resolve(true);
                                }
                            })
                        })
                        .then(() => {
                            return new Promise((resolve, reject) => {
                                confirm({
                                    title: "ยืนยัน!",
                                    icon: <ExclamationCircleOutlined />,
                                    content: "ต้องการยืนยันการจองเลือก OK",
                                    onOk() {
                                        setLoading(true);
                                        tConfirmreserveHeadService.confirmReserveWorksheet(tWorksheetHead.ireserveCode!)
                                            .then(() => {
                                                printWorksheet(tWorksheetHead);
                                                showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลยืนยันใบจองเรียบร้อยแล้ว!");
                                                setLoading(false);
                                            }).catch(() => {
                                                setLoading(false);
                                                showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
                                            })
                                            .finally(() => {
                                                resolve(true);
                                            });
                                    }, onCancel() {
                                        reject(false);
                                    },
                                });
                            })
                        });
                }
            }).finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <PageHeader title="" onClose={() => history.push("/app/tConfirmreserveHead/list")}>
                <div className="d-flex flex-row">
                    <div className="action-container"></div>
                </div>
                <Button
                    size="small"
                    type="primary"
                    icon={<EditOutlined />}
                    onClick={onEditWorksheet}
                >
                    แก้ไขใบจอง
                </Button>

            </PageHeader>
            <Content className="app-page-content">
                <Descriptions
                    style={{ margin: "1em" }}
                    column={4}
                    title="รายการที่ยังไม่ได้รับการยืนยันใบจอง"
                    size="middle"
                />
                <div>
                    <Form
                        form={form}
                        name="nonConfirmReserve"
                        onFinish={initial}
                    >
                        <Form.Item
                            label="เลขที่ใบจอง/ชื่อลูกค้า"
                            name="reserveCodeCustname"
                            rules={[{ required: false }]}
                            style={{ width: "50%", margin: "1em" }}
                        >
                            <Input
                                allowClear
                                placeholder="ป้อนเลขที่ใบงาน/ชื่อลูกค้า"
                                onPressEnter={form.submit}
                            ></Input>
                        </Form.Item>
                    </Form>
                </div>
                <div>
                    <Table
                        columns={columns}
                        scroll={{ x: 1800, y: 500 }}
                        dataSource={tWorksheetHeadConfirmReserves}
                        size="small"
                        loading={loading}
                        rowClassName={(item, index) => getItemColor(item, index)}
                        pagination={false}
                        rowKey={(item: TWorksheetConfirmReserveView) => item.ireserveCode!}
                        onRow={(item: TWorksheetConfirmReserveView, index: any) => {
                            return {
                                onDoubleClick: () => onItemDoubleCliked(item),
                                onClick: () => {
                                    onItemCliked(item);
                                    if (selectHighlight === index) {
                                        setSelectHighlight(-1);
                                    } else {
                                        setSelectHighlight(index);
                                    }
                                },
                            };
                        }}
                    />
                </div>
            </Content>
            <div>
                {onDrawerClose && (
                    <ChartNewWorksheet
                        statePg={statePg}
                        selectedReserveCode={selectedTConfirmreserveHead.ireserveCode!}
                        selectedAsset={{} as AssetWorksheet}
                        onDrawerClose={onDrawerClose}
                        setOnDrawerClose={setOnDrawerClose}
                    />
                )}
            </div>
            <div> {
                visibleAddEditWorkSheetSteelHeadDrawer && <AddEditWorkSheetSteelHeadDrawer
                    ireserveCode={selectedTConfirmreserveHead.ireserveCode!}
                    visibleAddEditWorkSheetSteelHeadDrawer={visibleAddEditWorkSheetSteelHeadDrawer}
                    setVisibleAddEditWorkSheetSteelHeadDrawer={setVisibleAddEditWorkSheetSteelHeadDrawer} />
            }</div>
            <div>
                {isPreviewModalVisible && (
                    <Modal
                        style={{ top: 20 }}
                        width={"90vw"}
                        title="ตัวอย่างใบงาน"
                        bodyStyle={{ height: "85vh" }}
                        centered={true}
                        visible={isPreviewModalVisible}
                        okButtonProps={{ style: { display: 'none' } }}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        onCancel={() => setIsPreviewModalVisible(false)}
                    >
                        <iframe title=" " src={worksheetPreviewData} width={"100%"} height={"100%"}></iframe>
                    </Modal>
                )}
            </div>
        </>
    );
};

export default ViewTConfirmreserveHead;
