import { PlusSquareOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Select, Space, Spin, Table, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { TOtherHead } from 'src/domain/tOtherHead';
import { TWorksheetExtrapriceView } from 'src/domain/worksheet/view/tWorksheetExtrapriceView';
import tOtherHeadService from 'src/services/reference/tOtherHeadService';
import { useAppSelector } from 'src/stores/store';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber, textNumber } from '../../utils/numberUtils';

const { Option } = Select;
interface ModalProp {
	visibleModalExtraPrice: boolean;
	setVisibleModalExtraPrice: any;
	tWorksheetExtrapriceList: Array<TWorksheetExtrapriceView>;
	setTWorksheetExtrapriceList: any;
}

const AddExtraPriceModal: React.FC<ModalProp> = (props) => {
	const { visibleModalExtraPrice, setVisibleModalExtraPrice, tWorksheetExtrapriceList, setTWorksheetExtrapriceList } = props;
	const [form] = Form.useForm();
	const [submitTrigger, setSubmitTrigger] = useState<boolean>(false);
	const [showSpin, setShowSpin] = useState<boolean>(false);
	const [sumExtraPrice, setSumExtraPrice] = useState<number>(0);
	const [otherHeadRefs, setOtherHeadRefs] = useState<TOtherHead[]>([]); //ค่าบริการพิเศษ
	const [disableAddButton, setDisableAddButton] = useState<boolean>(true);
	const userInfo = useAppSelector((state) => state.authen.authenUser.user);
	const tWorksheetExtrapriceListTemp = tWorksheetExtrapriceList;

	const columns = [
		{
			title: "ลำดับ",
			dataIndex: "index",
			key: "index",
			align: "center" as "center",
			render: (value: any, item: TWorksheetExtrapriceView, index: number) => {
				return <span>{index + 1}</span>;
			},
		},
		{
			title: "รายการ",
			dataIndex: "idescName",
			key: "idescName",
		},
		{
			title: "จำนวนเงิน",
			dataIndex: "iamount",
			key: "iamount",
			align: "right" as "right",
			render: (value: any, item: TWorksheetExtrapriceView, index: number) => {
				return <span>{formatNumber(item.iamount)}</span>;
			},
		},
		{
			title: "หมายเหตุ",
			dataIndex: "iremark",
			key: "iremark",
		},
		{
			title: "",
			key: "action",
			align: "center" as "center",
			render: (value: any, item: TWorksheetExtrapriceView, index: number) => {
				return (
					<span>
						<a onClick={(e) => { onDelete(item, index); }} style={{ color: "red" }}
						>
							X
						</a>
					</span>
				);
			},
		},
	];

	useEffect(() => {
		setShowSpin(true);
		loadData();
		return () => { };
	}, []);

	useEffect(() => {
		if (submitTrigger) {
			form.submit();
		}
	}, [submitTrigger]);

	useEffect(() => {
		//for each to sum extraprice list
		let sumExtraPrice = 0;
		if ((tWorksheetExtrapriceList) && tWorksheetExtrapriceList.length > 0) {
			tWorksheetExtrapriceList.forEach((obj: TWorksheetExtrapriceView) => {
				sumExtraPrice += (obj.iamount) ? obj.iamount : 0;
			});
		}
		setSumExtraPrice(sumExtraPrice);
	}, [tWorksheetExtrapriceList]);

	const loadData = async () => {
		const otherHeadResRefs = await tOtherHeadService.getTOtherHeadList();
		setOtherHeadRefs(otherHeadResRefs.data);
		setShowSpin(false);
	};

	const handleAdd = () => {
		const foudExtraPrice = tWorksheetExtrapriceList.find((obj) => obj.idesc === form.getFieldValue("idesc"));
		if (foudExtraPrice) {
			showNotification(NotificationType.ERROR, "มีรายการนี้อยู่แล้ว!");
			form.resetFields();
			return;
		} else {
			const extraPriceSelected = otherHeadRefs.find((obj) => obj.iid === form.getFieldValue("idesc"));

			const temp: TWorksheetExtrapriceView = {
				iid: 0,
				idesc: form.getFieldValue("idesc").trim(),
				idescName: extraPriceSelected?.iname,
				iremark: form.getFieldValue("iremark"),
				iamount: parserNumber(form.getFieldValue("iamount")),
				isavStation: userInfo.saveStation,
				icreateUser: userInfo.userId,
				ilastUser: userInfo.userId,
			};
			const array = tWorksheetExtrapriceList ? [...tWorksheetExtrapriceList] : [];
			array?.push(temp);
			// reindex
			array.map((item, index) => {
				item.iid = index;
				return item;
			});

			setTWorksheetExtrapriceList(array);
			form.resetFields();
			setDisableAddButton(true);
		}
	};

	const onDelete = (item: TWorksheetExtrapriceView, index: number) => {
		const array = tWorksheetExtrapriceList ? [...tWorksheetExtrapriceList] : [];
		array?.splice(index, 1);
		setTWorksheetExtrapriceList(array);
	}

	const onFinish = (values: any) => {
		form.resetFields();
		setSubmitTrigger(false);
		setDisableAddButton(true);
		setVisibleModalExtraPrice(false);
	};

	const onFinishCancelled = () => {
		// set back to default
		setTWorksheetExtrapriceList(tWorksheetExtrapriceListTemp);
		form.resetFields();
		setDisableAddButton(true);
		setVisibleModalExtraPrice(false);
	};


	const onFinishFailed = (errorInfo: any) => {
		setSubmitTrigger(false);
	};

	return (
		<>
			<Modal
				title="ค่าบริการพิเศษอื่นๆ"
				visible={visibleModalExtraPrice}
				width={"50%"}
				bodyStyle={{ minHeight: "450px" }}
				closable={false}
				destroyOnClose
				maskClosable={true}
				onOk={() => setSubmitTrigger(true)}
				onCancel={() => onFinishCancelled()}
			>
				<Spin spinning={showSpin}>
					<>
						<Form form={form}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
						>
							<Row>
								<Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
									<Typography.Text>รหัสการบริการ: </Typography.Text>
								</Col>
								<Col span={18}>
									<Form.Item name="idesc" style={{ marginBottom: "3px" }}>
										<Select
											showSearch
											size="middle"
											placeholder="เลือกค่าบริการพิเศษอื่นๆ"
											optionFilterProp="children"
											filterOption={true}
											onSelect={() => setDisableAddButton(false)}
										>
											{otherHeadRefs.map((obj) => {
												return (
													<Option key={obj.iid} value={obj.iid}>{obj.iname}</Option>
												);
											})}
										</Select>
									</Form.Item>
								</Col>
							</Row>
							<Row>
								<Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
									<Typography.Text>ราคา: </Typography.Text>
								</Col>
								<Col span={18}>
									<Space>
										<Form.Item
											name="iamount"
											style={{ marginBottom: "3px" }}
										>
											<Input
												type="text"
												// controls={false}
												style={{ textAlign: "end" }}
												onKeyDown={(event: any) => textNumber(event)}
												onPressEnter={(e: any) => form.setFieldsValue({ iamount: formatNumber(parserNumber(e.target.value)) })}
												onBlur={(e: any) => form.setFieldsValue({ iamount: formatNumber(parserNumber(e.target.value)) })}
												onFocus={(e: any) => e.target.select()}
											></Input>
										</Form.Item>
										บาท
									</Space>
								</Col>
							</Row>
							<Row>
								<Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
									<Typography.Text>หมายเหตุ: </Typography.Text>
								</Col>
								<Col span={18}>
									<Form.Item name="iremark" style={{ marginBottom: "3px" }}>
										<TextArea
											rows={4}
										></TextArea>
									</Form.Item>
								</Col>
							</Row>
							<Row>
								<Col span={5}></Col>
								<Col span={18}>
									<Button
										size="small"
										type="primary"
										disabled={disableAddButton}
										icon={<PlusSquareOutlined />}
										onClick={handleAdd}
									>
										เพิ่ม
									</Button>
								</Col>
							</Row>
						</Form>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "baseline",
								justifyContent: "space-between",
							}}
						>
							<Typography></Typography>
							<Typography.Title
								type="danger"
								level={4}
								style={{ height: "22px", textAlign: "right" }}
							>
								{formatNumber(
									Number(sumExtraPrice).toFixed(2)
								)}
							</Typography.Title>
						</div>
						<Table
							columns={columns}
							dataSource={tWorksheetExtrapriceList}
							rowKey={(item: TWorksheetExtrapriceView) => item.iid}
							size="small"
							pagination={false} />
					</>
				</Spin>
			</Modal>
		</>
	);
};

export default AddExtraPriceModal