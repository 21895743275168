import http from "../lib/http";
import Constants from "../utils/constants";

//RW009 = 㺧ҹ��Ш��ѹ
//Get data
const getByCreateDateForSign = (entryDate: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetHead/getByCreateDateForSign/" + entryDate);
};

//Print PDF
const getReportByCreateDateForSignPreview = (tWorksheetHeadViews: any) => {
  return http.post(
    Constants.DEFAULT_URL + "/worksheetReport/printReportByCreateDateForSignPreview",
    tWorksheetHeadViews,
    {
      responseType: "blob",
    },
  );
};


//RS001 = ��§ҹ������ԡ�âͧ�١��� ˹�����������
//Get data Group
const getCustomerServiceUseReport = (fromDate: string,
                                      toDate: string,
                                      branch: string,
                                      customercode: string) => {
  return http.get(Constants.DEFAULT_URL + "/printReport/getCustomerServiceUseReport/", {
    params: {
      fromDate,
      toDate,
      branch,
      customercode
    },
  });
};

//Get data Detail
const getCustomerServiceUseReportList = (fromDate: string,
                                          toDate: string,
                                          branch: string,
                                          customercode: string) => {
  // console.log('--getCustomerServiceUseReportList--', getCustomerServiceUseReportList)
  return http.get(Constants.DEFAULT_URL + "/printReport/getCustomerServiceUseReportList/", {
    params: {
      fromDate,
      toDate,
      branch,
      customercode
    }
  });
};

//Print PDF �������ҹ
const getCustomerServiceUseReportPreview = (fromDate: string,
                                            toDate: string,
                                            branch: string,
                                            customercode: string) => {
  return http.get(
    Constants.DEFAULT_URL + "/printReport/printCustomerServiceUseReportPreview",
    {
      params: {
        fromDate,
        toDate,
        branch,
        customercode
      }
    }
  );
};

//Print RS001 = ��§ҹ������ԡ�âͧ�١��� to PDF 
const getCustomerServiceUseReportUrl = (fromDate: string,
    toDate: string,
    branch: string,
    customercode: string) => {

  return Constants.DEFAULT_URL + "/printReport/printCustomerServiceUseReportPreview?" + 
      `fromDate=${fromDate}` +
      `&toDate=${toDate}` + 
      `&branch=${branch}` +
      `&customercode=${customercode}`
};


//  RF012 = ��§ҹ��û�Ժѵԧҹ��Ш��ѹ ˹�Ҥ��Ң�����
const getWorksheetDateWorkReport = (fromDate: string,
                                    toDate: string,
                                    truckCode: string,
                                    customercode: string,
                                    typeWorksheetCode: string,
                                    typeWork: string,
                                    typePaymentCode: string,
                                    checkPay: boolean) => {
  return http.get(Constants.DEFAULT_URL + "/printReport/getWorksheetDateWorkReport/", {
                  params: {
                    fromDate,
                    toDate,
                    truckCode,
                    customercode,
                    typeWorksheetCode,
                    typeWork,
                    typePaymentCode,
                    checkPay
                  },
    });
};

//  Footer Summary ˹�Ҥ��Ң�����
const getWorksheetDateWorkSummaryReport = (fromDate: string,
                                            toDate: string,
                                            truckCode: string,
                                            customercode: string,
                                            typeWorksheetCode: string,
                                            typeWork: string,
                                            typePaymentCode: string,
                                            checkPay: boolean) => {
  return http.get(Constants.DEFAULT_URL + "/printReport/getWorksheetDateWorkSummaryReport/", {
                params: {
                  fromDate,
                  toDate,
                  truckCode,
                  customercode,
                  typeWorksheetCode,
                  typeWork,
                  typePaymentCode,
                  checkPay
                },
  });
};
//  Print RF012 = ��§ҹ��û�Ժѵԧҹ��Ш��ѹ to PDF 
const getWorksheetDateWorkReportPreview = (fromDate: string,
                                            toDate: string,
                                            truckCode: string,
                                            customercode: string,
                                            typeWorksheetCode: string,
                                            typeWork: string,
                                            typePaymentCode: string,
                                            checkPay: boolean) => {

  return Constants.DEFAULT_URL + "/printReport/getWorksheetDateWorkReportPreview?" + 
        `fromDate=${fromDate}` +
        `&toDate=${toDate}` + 
        `&truckCode=${truckCode}` +
        `&customercode=${customercode}` +
        `&typeWorksheetCode=${typeWorksheetCode}` +
        `&typeWork=${typeWork}` +
        `&typePaymentCode=${typePaymentCode}` +
        `&checkPay=${checkPay}`
};


//  RW007 = ��§ҹ�����ö ˹�����������������
const getTruckIncomeReport = (fromDate: string,
                                toDate: string,
                                truckCode: string,
                                typeTruckRefId: string,
                                branch: string) => {
  return http.get(Constants.DEFAULT_URL + "/printReport/getTruckIncomeReport/", {
                  params: {
                    fromDate,
                    toDate,
                    truckCode,
                    typeTruckRefId,
                    branch
                  },
  });
};
//  Print RW007 = ��§ҹ�����ö to PDF 
const getTruckIncomeReportPreview = (fromDate: string,
                                      toDate: string,
                                      truckCode: string,
                                      typeTruck: string,
                                      branch: string) => {

  return Constants.DEFAULT_URL + "/printReport/getTruckIncomeReportPreview?" + 
        `fromDate=${fromDate}` +
        `&toDate=${toDate}` + 
        `&truckCode=${truckCode}` +
        `&typeTruckRefId=${typeTruck}` +
        `&branch=${branch}`
};


//  RW012 = ��§ҹ�������١˹�� ˹�����������������
const getDebitorReport = (debitorName: string, debitorTel: string) => {
  return http.get(Constants.DEFAULT_URL + "/printReport/getDebitorReport/", {
    params: {
      debitorName, debitorTel
    },
  });
};
//  Print RW012 = ��§ҹ�������١˹�� to PDF 
const getDebitorReportPreview = (name: string, tel: string) => {
  return Constants.DEFAULT_URL + "/printReport/getDebitorReportPreview?" + 
                                  `name=${name}` +
                                  `&tel=${tel}`};


//  RR001 = ��§ҹ����Ѵ��ҧ���Թ ˹�����������������
const getRangradArrearsReport = (fromDate: string, toDate: string, branch: string) => {
  return http.get(Constants.DEFAULT_URL + "/printReport/getRangradArrearsReport/", {
    params: {
      fromDate, toDate, branch
    },
  });
};
//  Print RR001 = ��§ҹ����Ѵ��ҧ���Թ to PDF 
const getRangradArrearsReportPreview = (fromDate: string, toDate: string, branch: string) => {
  return Constants.DEFAULT_URL + "/printReport/getRangradArrearsReportPreview?" + 
                                  `fromDate=${fromDate}` +
                                  `&toDate=${toDate}` +
                                  `&branch=${branch}`};


//  RR002 = ��§ҹ����Ѵ���Թ������Թ ˹�����������������
const getRangradSendFnReport = (branch: string, empId: string, fromDate: string, toDate: string, selectDebitNote: boolean) => {
  return http.get(Constants.DEFAULT_URL + "/printReport/getRangradSendFnReport/", {
    params: {
      branch, empId, fromDate, toDate, selectDebitNote
    },
  });
};
//  Print RR002 = ��§ҹ����Ѵ���Թ������Թ to PDF 
const getRangradSendFnReportPreview = (branch: string, empId: string, fromDate: string, toDate: string, selectDebitNote: boolean) => {
  return Constants.DEFAULT_URL + "/printReport/getRangradSendFnReportPreview?" + 
                                  `branch=${branch}` +
                                  `&empId=${empId}` +
                                  `&fromDate=${fromDate}` +
                                  `&toDate=${toDate}` +
                                  `&selectDebitNote=${selectDebitNote}`};


//  RR003 = ��§ҹ��������͹������˹�� ˹�����������������
const getMovementDebitNoteReport = (debitnote: string, empId: string, fromDate: string, toDate: string) => {
  return http.get(Constants.DEFAULT_URL + "/printReport/getMovementDebitNoteReport/", {
    params: {
      debitnote, empId, fromDate, toDate
    },
  });
};
//  Print RR003 = ��§ҹ��������͹������˹�� to PDF 
const getRangradMovementReportPreview = (debitnote: string, empId: string, fromDate: string, toDate: string) => {
  return Constants.DEFAULT_URL + "/printReport/getRangradMovementReportPreview?" + 
                                  `debitnote=${debitnote}` +
                                  `&empId=${empId}` +
                                  `&fromDate=${fromDate}` +
                                  `&toDate=${toDate}`};
 

//  RR004 = ��§ҹ����Ѵ�͡���˹�� ˹�����������������
const getRangradDebitNoteReport = (fromAppDate: string, toAppDate: string,
                                    fromEntryDate: string, toEntryDate: string,
                                    branchId: string, debitorCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/printReport/getRangradDebitNoteReport/", {
    params: {
      fromAppDate, toAppDate, fromEntryDate, toEntryDate, branchId, debitorCode
    },
  });
};
//  Print RR004 = ��§ҹ����Ѵ�͡���˹�� to PDF 
const getRangradDebitNoteReportPreview = (fromAppDate: string, toAppDate: string,
                                          fromEntryDate: string, toEntryDate: string,
                                          branchId: string, debitorCode: string) => {
  return Constants.DEFAULT_URL + "/printReport/getRangradDebitNoteReportPreview?" + 
                                  `fromAppDate=${fromAppDate}` +
                                  `&toAppDate=${toAppDate}` +
                                  `&fromEntryDate=${fromEntryDate}` +
                                  `&toEntryDate=${toEntryDate}` +
                                  `&branchId=${branchId}` +
                                  `&debitorCode=${debitorCode}`};

        
//  RF007 = ��§ҹ��ػ����Ѻ�Թ��Ш��ѹ ˹�����������������
const getFnReceiveDailySummaryReport = (fromDate: string, toDate: string,
                                        paymentType: string, typeWorksheetCode: string,
                                        branchId: string, customercode: string) => {
  return http.get(Constants.DEFAULT_URL + "/printReport/getFnReceiveDailySummaryReport/", {
    params: {
      fromDate, toDate, paymentType, typeWorksheetCode, branchId, customercode
    },
  });
};
//  Print RF007 = ��§ҹ��ػ����Ѻ�Թ��Ш��ѹ to PDF 
const getFnReceiveDailySummaryReportPreview = (fromDate: string, toDate: string,
                                                paymentType: string, typeWorksheetCode: string,
                                                branchId: string, customercode: string) => {
  return Constants.DEFAULT_URL + "/printReport/getFnReceiveDailySummaryReportPreview?" + 
                                  `fromDate=${fromDate}` +
                                  `&toDate=${toDate}` +
                                  `&paymentType=${paymentType}` +
                                  `&typeWorksheetCode=${typeWorksheetCode}` +
                                  `&branchId=${branchId}` +
                                  `&customercode=${customercode}`};

                                  
//  RF013 = ��§ҹ�������� ˹�����������������
const getChequeReport = (fromDate: string, toDate: string,
                          chequeNumber: string, customerName: string) => {
  return http.get(Constants.DEFAULT_URL + "/printReport/getChequeReport/", {
    params: {
      fromDate, toDate, chequeNumber, customerName
    },
  });
};
//  Print RF013 = ��§ҹ�������� to PDF 
const getChequeReportPreview = (fromDate: string, toDate: string,
                                  chequeNumber: string, customerName: string) => {
    return Constants.DEFAULT_URL + "/printReport/getChequeReportPreview?" + 
                                    `fromDate=${fromDate}` +
                                    `&toDate=${toDate}` +
                                    `&chequeNumber=${chequeNumber}` +
                                    `&customerName=${customerName}`};


//  RF014 = ��§ҹ��������Թ�͹ ˹�����������������
const getPayinReport = (fromDate: string, accountName: string, customerName: string) => {
  return http.get(Constants.DEFAULT_URL + "/printReport/getPayinReport/", {
    params: {
      fromDate, accountName, customerName
    },
  });
};
//  Print RF014 = ��§ҹ�������Թ�͹ to PDF 
const getPayinReportPreview = (fromDate: string, accountName: string, customerName: string) => {
    return Constants.DEFAULT_URL + "/printReport/getPayinReportPreview?" + 
                                    `fromDate=${fromDate}` +
                                    `&accountName=${accountName}` +
                                    `&customerName=${customerName}`};


//  RF015 = ��§ҹ�����š�ê����Թ������ ˹�����������������
const getChequePayReport = (fromDate: string, toDate: string,
    chequeNumber: string, customerName: string) => {
  return http.get(Constants.DEFAULT_URL + "/printReport/getChequePayReport/", {
    params: {
      fromDate, toDate, chequeNumber, customerName
    },
  });
};
//  Print RF015 = ��§ҹ�����š�ê����Թ������ to PDF 
const getChequePayReportPreview = (fromDate: string, toDate: string, chequeNumber: string, customerName: string) => {
  return Constants.DEFAULT_URL + "/printReport/getChequePayReportPreview?" + 
                                  `fromDate=${fromDate}` +
                                  `&toDate=${toDate}` +
                                  `&chequeNumber=${chequeNumber}` +
                                  `&customerName=${customerName}`};


//  RF016 = ��§ҹ�����š�ê����Թ�����Թ�͹ ˹�����������������
const getPayinPayReport = (fromDate: string, toDate: string, accountName: string, customerName: string) => {
  return http.get(Constants.DEFAULT_URL + "/printReport/getPayinPayReport/", {
    params: {
      fromDate, toDate, accountName, customerName
    },
  });
};
//  Print RF016 = ��§ҹ�����š�ê����Թ�����Թ�͹ to PDF 
const getPayinPayReportPreview = (fromDate: string, toDate: string, accountName: string, customerName: string) => {
  return Constants.DEFAULT_URL + "/printReport/getPayinPayReportPreview?" + 
                                  `fromDate=${fromDate}` +
                                  `&toDate=${toDate}` +
                                  `&accountName=${accountName}` +
                                  `&customerName=${customerName}`};


//  RF009 = ��§ҹ��ýҡ�Թ ˹�����������������
const getTrushMoneyReport = (id: string, fromDate: string, toDate: string, 
                              branchId: string, accSeq: string) => {
  return http.get(Constants.DEFAULT_URL + "/printReport/getTrushMoneyReport/", {
    params: {
      id, fromDate, toDate, branchId, accSeq
    },
  });
};
//  Print RF009 = ��§ҹ��ýҡ�Թ to PDF 
const getTrushMoneyReportPreview = (id: string, fromDate: string, toDate: string, 
                                      branchId: string, accSeq: string) => {
  return Constants.DEFAULT_URL + "/printReport/getTrushMoneyReportPreview?" + 
                                  `id=${id}` +
                                  `&fromDate=${fromDate}` +
                                  `&toDate=${toDate}` +
                                  `&branchId=${branchId}` +
                                  `&accSeq=${accSeq}`};


//  RF011 = ��§ҹ㺧ҹ��ҧ���� ˹�����������������
const getWorksheetPaymentReport = (customerName: string, branchId: string, typeWorksheetCode: string, fromDate: string, toDate: string, 
                                    payDate: string, selectPaymentType: boolean, typePayment: string) => {
  return http.get(Constants.DEFAULT_URL + "/printReport/getWorksheetPaymentReport/", {
  params: {
      customerName, branchId, typeWorksheetCode, fromDate, toDate, payDate, selectPaymentType, typePayment
    },
  });
};

//  Print RF011 = ��§ҹ㺧ҹ��ҧ���� to PDF 
const getWorksheetPaymentReportPreview = (customerName: string, branchId: string, typeWorksheetCode: string, fromDate: string, toDate: string, 
                                          payDate: string, selectPaymentType: boolean, typePayment: string) => {
  return Constants.DEFAULT_URL + "/printReport/getWorksheetPaymentReportPreview?" + 
                                  `customerName=${customerName}` +
                                  `&branchId=${branchId}` +
                                  `&typeWorksheetCode=${typeWorksheetCode}` +
                                  `&fromDate=${fromDate}` +
                                  `&toDate=${toDate}` +
                                  `&payDate=${payDate}` +
                                  `&selectPaymentType=${selectPaymentType}` +
                                  `&typePayment=${typePayment}`};



const defaultApi = {
  
  getByCreateDateForSign,
  getReportByCreateDateForSignPreview,
  getCustomerServiceUseReport,
  getCustomerServiceUseReportList,
  getCustomerServiceUseReportPreview,
  getCustomerServiceUseReportUrl,
  getWorksheetDateWorkReport,
  getWorksheetDateWorkSummaryReport,
  getWorksheetDateWorkReportPreview,
  getTruckIncomeReport,
  getTruckIncomeReportPreview,
  getDebitorReport,
  getDebitorReportPreview,
  getRangradArrearsReport,
  getRangradArrearsReportPreview,
  getRangradSendFnReport,
  getRangradSendFnReportPreview,
  getMovementDebitNoteReport,
  getRangradMovementReportPreview,
  getRangradDebitNoteReport,
  getRangradDebitNoteReportPreview,
  getFnReceiveDailySummaryReport,
  getFnReceiveDailySummaryReportPreview,
  getChequeReport,
  getChequeReportPreview,
  getPayinReport,
  getPayinReportPreview,
  getChequePayReport,
  getChequePayReportPreview,
  getPayinPayReport,
  getPayinPayReportPreview,
  getTrushMoneyReport,
  getTrushMoneyReportPreview,
  getWorksheetPaymentReport,
  getWorksheetPaymentReportPreview
  
};

export default defaultApi;