import { TWorksheetHead } from "src/domain/worksheet/tWorksheetHead";
import http from "../../lib/http";
import Constants from "../../utils/constants";

const getChartData = (
  tTypeTruckRefId: string,
  jobDate: string,
  branchCode: string
) => {
  return http.get(Constants.DEFAULT_URL + "/chart/getChartData", {
    params: {
      tTypeTruckRefId,
      jobDate,
      branchCode,
    },
  });
};

const getWorksheetPreview = (tworksheethead: TWorksheetHead, showAllPage: boolean) => {
  return http.post(
    Constants.DEFAULT_URL + "/worksheetReport/printReport/"+showAllPage,
    tworksheethead,
    {
      responseType: "blob",
    },
  );
};

const defaultApi = {
  getChartData,
  getWorksheetPreview,
};
export default defaultApi;
