import { WarningOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, Form, Input, Modal, Row, Space, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { TWorksheetHeadView } from 'src/domain/worksheet/view/tWorksheetHeadView';
import PageHeader from 'src/layouts/PageHeader';
import tFinanceHead2Service from 'src/services/finance/tFinanceHead2Service';
import tWorksheetHeadService from 'src/services/tWorksheetHeadService';
import { toThDate } from 'src/utils/dateTimeUtils';
import { formatNumber } from 'src/utils/numberUtils';
import FinanceReceivableDrawer from './FinanceReceivableDrawer';

const { warning } = Modal;

const FinanceNonReceivable: React.FC<RouteComponentProps> = (props) => {
  const [form] = Form.useForm();
  const [page, setPage] = React.useState(1);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [worksheetHeads, setWorksheetHeads] = useState<TWorksheetHeadView[]>([]);
  const [selectWorksheetHead, setSelectWorksheetHead] = useState<TWorksheetHeadView>({} as TWorksheetHeadView);
  const [onDrawerVisible, setOnDrawerVisible] = useState<boolean>(false);
  const [selectHighlight, setSelectHighlight] = useState<number>(-1)

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: TWorksheetHeadView, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
    },
    {
      title: "วันที่สร้าง",
      dataIndex: "ientryDate",
      key: "ientryDate",
      width: 80,
      align: "center" as "center",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{item.ientryDate ? toThDate(item.ientryDate) : ""}</span>;
      },
    },
    {
      title: "เลขที่ใบสั่งงาน",
      dataIndex: "iworksheetCode",
      key: "iworksheetCode",
      width: 120,
      align: "center" as "center",
      render: (value: string, item: TWorksheetHeadView) => (
        <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.iworksheetCode}</Button>
      )
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "icustName",
      key: "icustName",
      width: 250,
    },
    {
      title: "ข้อมูลงาน",
      dataIndex: "serviceNameRemarks",
      key: "serviceNameRemarks",
      width: 250,
    },
    {
      title: "ประเภทการชำระ",
      dataIndex: "itypepaymentName",
      key: "itypepaymentName",
      width: 120,
    },
    {
      title: "ค่าบริการ",
      dataIndex: "itotal2Amt",
      key: "itotal2Amt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itotal2Amt)}</span>;
      },
    },
    {
      title: "ผู้สร้าง",
      dataIndex: "createUserName",
      key: "createUserName",
      width: 80,
    },
  ];

  const getItemColor = (item: TWorksheetHeadView, index: any) => {
    let rowStyle = '';
    if (index === selectHighlight) {
      rowStyle = "row-selected"
    }
    if (item.typeworksheetflag !== '02' && item.itypepaymentCode !== '04' && item.itypepaymentCode !== '05') {
      if (item.truckAssettype === '002') {
        if (item.itypepaymentCode === '03' && item.idepositFlg === 0 && (item.allinstalment ? item.allinstalment : 0) >= 1) {
          return rowStyle ? rowStyle + ' text-blue' : 'text-blue';
        }
      }
    }
    return rowStyle;
  }

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    doSearch(form);
  }, []);
  

  useEffect(() => {
    (async async => {
      if (!onDrawerVisible && selectWorksheetHead.iworksheetCode) {
        const worksheetHeadRes = await tWorksheetHeadService.getByWorksheetCode(selectWorksheetHead.iworksheetCode);
        const index = (worksheetHeads as Array<TWorksheetHeadView>).findIndex(object => object.iworksheetCode === selectWorksheetHead.iworksheetCode);
        if (index !== -1 && worksheetHeadRes.data.ipaymentCode) {
          const arraySplice = worksheetHeads ? [...worksheetHeads] : [];
          arraySplice?.splice(index, 1);
          setWorksheetHeads(arraySplice);
        }
          
        form.setFieldsValue({
          worksheetCodeCustname: "",
        });
      }
    })();
  }, [onDrawerVisible]);

  const doSearch = async (values: any) => {
    setIsLoading(true);
    const worksheetCodeCustname = (values.worksheetCodeCustname) ? values.worksheetCodeCustname : "";
    const nonTypePayment = "04";
    const startDate = "";
    const endDate = "";
    const check = false;
    const res = await tFinanceHead2Service.getByWorksheetPaymentStatusList(worksheetCodeCustname, nonTypePayment, startDate, endDate, check);

    setWorksheetHeads(res.data.WORKSHEET_HEADVIEW_LIST);
    form.setFieldsValue({
      sumTotal1Amt: formatNumber(res.data.SUM_TOTAL1AMT),
      sumTaxAmt: formatNumber(res.data.SUM_VATAMT),
      sumTotal2Amt: formatNumber(res.data.SUM_TATAL2AMT),
      sumWorksheetQty: formatNumber(res.data.COUNT_WORKSHEET),
    });
    setIsLoading(false);
  }

  const onItemCliked = (item: TWorksheetHeadView) => {
    if (item.typeworksheetflag !== '02' && item.itypepaymentCode !== '04' && item.itypepaymentCode !== '05') {
      if (item.truckAssettype === '002') {
        if (item.itypepaymentCode === '03' && item.idepositFlg === 0 && (item.allinstalment ? item.allinstalment : 0) >= 1) {
          warning({
            title: "รายงาน!",
            icon: <WarningOutlined />,
            content: "การเงินรับชำระเงินมัดจำเรียบร้อยและใบงานถูกส่งไปยังเร่งรัดแล้ว!",
            okText: "ตกลง"
          });
          return;
        }
      }
    }
    if (item.itypepaymentCode === '02' && (Number(item.iworksheetStatus) < 2)) {
      warning({
        title: "รายงาน!",
        icon: <WarningOutlined />,
        content: "ใบงานสดเมื่องานเสร็จยังไม่ได้ปล่อยรถไม่สามารรับเงินก่อนได้!",
        okText: "ตกลง"
      });
      return;
    }

    setSelectWorksheetHead(item);
    setOnDrawerVisible(true);
  };

  return (
    <>
      <PageHeader title="" onClose={() => history.push("/app/financeReceivable/list")}>
        <div className="d-flex flex-row">
          <div className="action-container"></div>
        </div>
      </PageHeader>
      <Content className="app-page-content">
        <Descriptions
          style={{ margin: "1em" }}
          column={4}
          title="ค้นหาใบงานสด"
          size="middle"
        />
        <div>
          <Form
            form={form}
            name="receiveCashWorksheet"
            onFinish={doSearch}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "baseline",
                margin: "1em 0 1em 0",
                padding: "1em",
                borderBlockEnd: "2px solid #c9c9c9",
              }}
            >
              <Form.Item
                label="เลขที่ใบงาน/ชื่อลูกค้า"
                name="worksheetCodeCustname"
                rules={[{ required: false }]}
                style={{ width: "50%" }}
              >
                <Input
                  allowClear
                  placeholder="ป้อนเลขที่ใบงาน/ชื่อลูกค้า"
                  onPressEnter={form.submit}
                ></Input>
              </Form.Item>
            </div>
            <div>
              <Table
                columns={columns}
                dataSource={worksheetHeads}
                size="small"
                loading={isLoading}
                rowClassName={(item, index) => getItemColor(item, index)}
                rowKey={(item: TWorksheetHeadView) => item.ireserveCode!}
                onRow={(item: TWorksheetHeadView, index: any) => {
                  return {
                    onClick: () => {
                      if (selectHighlight === index) {
                        setSelectHighlight(-1);
                      } else {
                        setSelectHighlight(index);
                      }
                    },
                  };
                }}
                pagination={{
                  pageSize: 15,
                  onChange(current) {
                    setPage(current);
                  }
                }}
              />
            </div>
            <Row style={{ padding: "10px 0px 0px" }}>
              <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text style={{ color: "red", fontWeight: "bold" }}>ค่าบริการ: </Typography.Text>
              </Col>
              <Col span={6}>
                <Space align='baseline'>
                  <Form.Item
                    // label="ค่าบริการ"
                    name="sumTotal1Amt"
                  >
                    <Input style={{ border: 0, fontWeight: "bold", textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                  </Form.Item>
                  <Typography.Text style={{ color: "red", fontWeight: "bold" }}>บาท </Typography.Text>
                </Space>
              </Col>
              <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text style={{ color: "red", fontWeight: "bold" }}>ภาษี: </Typography.Text>
              </Col>
              <Col span={6}>
                <Space align='baseline'>
                  <Form.Item
                    // label="ภาษี"
                    name="sumTaxAmt"
                  >
                    <Input style={{ border: 0, fontWeight: "bold", textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                  </Form.Item>
                  <Typography.Text style={{ color: "red", fontWeight: "bold" }}>บาท </Typography.Text>
                </Space>
              </Col>
              <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text style={{ color: "red", fontWeight: "bold" }}>ทั้งหมด: </Typography.Text>
              </Col>
              <Col span={6}>
                <Space align='baseline'>
                  <Form.Item
                    // label="ทั้งหมด"
                    name="sumTotal2Amt"
                  >
                    <Input style={{ border: 0, fontWeight: "bold", textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                  </Form.Item>
                  <Typography.Text style={{ color: "red", fontWeight: "bold" }}>บาท </Typography.Text>
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={8}></Col>
              <Col span={8}></Col>
              <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text style={{ color: "red", fontWeight: "bold" }}>รวมใบงาน: </Typography.Text>
              </Col>
              <Col span={6}>
                <Space align='baseline'>
                  <Form.Item
                    // label="รวมใบงาน"
                    name="sumWorksheetQty"
                  >
                    <Input style={{ border: 0, fontWeight: "bold", textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                  </Form.Item>
                </Space>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
      <div>
        {onDrawerVisible && (
          <FinanceReceivableDrawer
            onDrawerVisible={onDrawerVisible}
            setOnDrawerVisible={setOnDrawerVisible}
            selectWorksheetHead={selectWorksheetHead}
            setSelectWorksheetHead={setSelectWorksheetHead}
          ></FinanceReceivableDrawer>
        )}
      </div>
    </>
  )
}

export default FinanceNonReceivable