import { TWorksheetTruckDischargeView } from 'src/domain/worksheet/view/tWorksheetTruckDischargeView';
import { WorksheetHeadData } from "src/domain/worksheet/view/worksheetHeadData";
import http from "../lib/http";
import Constants from "../utils/constants";

const getNextReserveCode = (key: string, station: string) => {
  return http.get(Constants.DEFAULT_URL + "/worksheet/nextCode", {
    params: {
      key,
      station,
    },
    responseType: "text",
  });
};

const createUpdateWorksheet = (obj: WorksheetHeadData, statePg: string) => {
  return http.post(Constants.DEFAULT_URL + "/worksheet/createUpdate", obj,
  {
    params: {
      statePg,
    },
  });
};

const updateWorksheetWithSubMenuKey = (worksheetHeadData: any, userRemark: string, reporterName: string, keyUpdate: string) => {
  return http.post(
    Constants.DEFAULT_URL + "/worksheet/updateWorksheetWithSubMenuKey",
    {
        worksheetHeadData,
        userRemark,
        reporterName,
        keyUpdate,
    }
  );
};

const getWorksheetHeadData = (reserveCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/worksheet/" + reserveCode);
};

const getBills = (
  searchDateType: string,
  isSearchBillDate: boolean,
  selectedBillDateStart: string,
  selectedBillDateEnd: string,
  selectedRangePickerStart: string,
  selectedRangePickerEnd: string,
  truckCode: string,
  typeWorksheetCode: string,
  custCode: string,
  driverCode: string,
  worksheetCodeReserveCode: string,
  typePaymentCode: string,
  typeWork: string,
  typeTaxCode: string,
  statusGroup: string,
  location: string
) => {
  return http.get(Constants.DEFAULT_URL + "/worksheet/getBills", {
    params: {
      searchDateType,
      isSearchBillDate,
      selectedBillDateStart,
      selectedBillDateEnd,
      selectedRangePickerStart,
      selectedRangePickerEnd,
      truckCode,
      typeWorksheetCode,
      custCode,
      driverCode,
      worksheetCodeReserveCode,
      typePaymentCode,
      typeWork,
      typeTaxCode,
      statusGroup,
      location
    },
  });
};

const isExistCreateWorksheet = (startDate: any, startTime: any, endDate: any, endTime: any, truckCode: any, reseverCode: any) => {
  return http.get(Constants.DEFAULT_URL + "/worksheet/isExistCreateWorksheet", {
    params: {
      startDate,
      startTime,
      endDate,
      endTime,
      truckCode,
      reseverCode,
    },
  });
};

const calculateOtData = (worksheetHeadData: any, endDateStr: string, endTimeStr: string, isHourRelax12to13: boolean, isHourRelax17to18: boolean, isOtWorksheet: boolean) => {
  return http.post(
    Constants.DEFAULT_URL + "/worksheet/calculateOtData",
    worksheetHeadData,
    {
      params: {
        endDateStr,
        endTimeStr,
        isHourRelax12to13,
        isHourRelax17to18,
        isOtWorksheet
      },
    });
};

const calculateWshReturnWithOtData = (worksheetHead: any, endDateStr: string, endTimeStr: string, isHourRelax12to13: boolean, tAssignvaluetaxheadRate: number) => {
  return http.post(
    Constants.DEFAULT_URL + "/worksheet/calculateWshReturnWithOtData",
    worksheetHead,
    {
      params: {
        endDateStr,
        endTimeStr,
        isHourRelax12to13,
        tAssignvaluetaxheadRate,
      },
    });
};

const truckDischargeWorksheets = (truckDischargeWorksheets: TWorksheetTruckDischargeView[]) => {
  return http.post(Constants.DEFAULT_URL + "/worksheet/truckDischargeWorksheets", truckDischargeWorksheets);
};

const defaultApi = {
  getNextReserveCode,
  getWorksheetHeadData,
  createUpdateWorksheet,
  updateWorksheetWithSubMenuKey,
  getBills,
  isExistCreateWorksheet,
  calculateOtData,
  calculateWshReturnWithOtData,
  truckDischargeWorksheets,
};
export default defaultApi;
