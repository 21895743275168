import { Col, Form, Input, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { TWshConstructionworkView } from 'src/domain/worksheet/view/tWshConstructionworkView';
import tAssetHeadService from 'src/services/tasset/tAssetHeadService';
import tWorksheetDetailService from 'src/services/tWorksheetDetailService';
import tWorksheetHeadService from 'src/services/tWorksheetHeadService';
import { useAppSelector } from 'src/stores/store';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';

interface ModalProp {
	visibleContractorWorkModal: boolean;
	setVisibleContractorWorkModal: any;
	twshConstructionworkFormDataList: TWshConstructionworkView[];
	setTwshConstructionworkFormDataList: any;
	twshConstructionworkFormDataRemoveList: TWshConstructionworkView[];
	setTwshConstructionworkFormDataRemoveList: any;
	setRecalculateContractorWork: any;
}

const WorksheetCodeModal: React.FC<ModalProp> = (props) => {
	const { visibleContractorWorkModal, setVisibleContractorWorkModal, twshConstructionworkFormDataList, 
		setTwshConstructionworkFormDataList, twshConstructionworkFormDataRemoveList, setTwshConstructionworkFormDataRemoveList,
		setRecalculateContractorWork, } = props;
	const [form] = Form.useForm();
	const [submitTrigger, setSubmitTrigger] = useState<boolean>(false);
	const userInfo = useAppSelector((state) => state.authen.authenUser.user);

	useEffect(() => {
		if (submitTrigger) {
			form.submit();
		}
	}, [submitTrigger]);


	const checkExistWorksheetCode = async (worksheetCode: string) => {
		let isExist = false;
		const worksheetHeadRes = await tWorksheetHeadService.getByWorksheetCode(worksheetCode);
		if (worksheetHeadRes.data) {
			isExist = true;
		}
		return isExist;
	}

	const checkExistInList = (worksheetCode: string) => {
		let isExist = false;
		const formItem = (twshConstructionworkFormDataList as Array<TWshConstructionworkView>).find((obj) => obj.worksheetConstruction === worksheetCode);
		if (formItem) isExist = true;
		return isExist;
	}

	const checkExistInRemoveList = (worksheetCode: string) => {
		let isExist = false;
		const formItem = (twshConstructionworkFormDataRemoveList as Array<TWshConstructionworkView>).find((obj) => obj.worksheetConstruction === worksheetCode);
		if (formItem) isExist = true;
		return isExist;
	}

	const onFinish = async (values: any) => {
		if (values.worksheetCode.length != 13) {
			showNotification(NotificationType.ERROR, "เลขที่ใบสั่งงานไม่ถุกต้อง");
			setSubmitTrigger(false);
		} else if (!(await checkExistWorksheetCode(values.worksheetCode))) {
			showNotification(NotificationType.ERROR, "ไม่พบข้อมูลใบสั่งงาน");
			setSubmitTrigger(false);
		} else if (checkExistInList(values.worksheetCode)) {
			showNotification(NotificationType.ERROR, "มีใบงานเลขที่ " + values.worksheetCode + " อยู่ในรายการแล้ว");
			setSubmitTrigger(false);
		} else {
			const tWorksheetHeadRes = await tWorksheetHeadService.getByWorksheetCode(values.worksheetCode);
			let refWorksheetHead = tWorksheetHeadRes.data;

			if (refWorksheetHead.typeworksheetflag != "01") { //เพิ่มได้เฉพาะใบงานปกติ
				showNotification(NotificationType.ERROR, "ไม่สามารถเพิ่มรายการใบสั่งงานได้ ประเภทใบงานไม่ถูกต้อง");
				setSubmitTrigger(false);
			} else {
				const assetHeadRes = await tAssetHeadService.getTAssetHeadViewById(refWorksheetHead.itruckCode);
				let refAssetHead = assetHeadRes.data;

				const tworksheetDetailRes = await tWorksheetDetailService.getByReserveCode(refWorksheetHead.ireserveCode);
				let refWorksheetDetailFirst = tworksheetDetailRes.data[0];

				const temp: TWshConstructionworkView = {
					id: 0,
					worksheetControl: "",
					remark: "",
					reservecontrolcode: "",
					worksheetConstruction: refWorksheetHead.iworksheetCode,
					truckFullName: refAssetHead.assetFullName,
					driverName: refWorksheetHead.idriverName,
					remainAmt: refWorksheetHead.iremainAmt,
					total1Amt: refWorksheetHead.itotal1Amt,
					total2Amt: refWorksheetHead.itotal2Amt,
					serviceName: refWorksheetDetailFirst.iserviceName,
					savstation: userInfo.saveStation,
					createuser: userInfo.userId,
					lastuser: userInfo.userId,
					termno: userInfo.termNo,
				};

				const array = twshConstructionworkFormDataList ? [...twshConstructionworkFormDataList] : [];
				array?.push(temp);

				if (twshConstructionworkFormDataRemoveList.length > 0 && checkExistInRemoveList(refWorksheetHead.iworksheetCode)) {
					// arrayRemove?.splice(index, 1);
					const arrayRemove  = twshConstructionworkFormDataRemoveList.filter((obj) => {
						return obj.worksheetConstruction !==	refWorksheetHead.iworksheetCode;
					});
					setTwshConstructionworkFormDataRemoveList(arrayRemove);
				}

				setTwshConstructionworkFormDataList([...reindex(array)]);
				setRecalculateContractorWork(true);
				form.resetFields();
				setSubmitTrigger(false);
				setVisibleContractorWorkModal(false);
			}
		}
	};

	const reindex = (tWshConstructionworkView: TWshConstructionworkView[]) => {
		return tWshConstructionworkView.map((item, index) => {
			item.id = index;
			return item;
		});
	}

	const onFinishFailed = (errorInfo: any) => {
		showNotification(NotificationType.ERROR, "กรุณาระบุเลขที่ใบสั่งงาน !");
		form.resetFields();
		setSubmitTrigger(false);
	};

	return (
		<>
			<Modal
				title="ป้อนเลขที่ใบสั่งงาน"
				visible={visibleContractorWorkModal}
				width={"25%"}
				closable={true}
				maskClosable={false}
				footer={null}
				onOk={() => setSubmitTrigger(true)}
				onCancel={() => setVisibleContractorWorkModal(false)}
			>
				<Form form={form}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
				>
					<Row>
						<Col span={24}>
							<Form.Item
								name="worksheetCode"
								style={{ margin: 0 }}
								rules={[
									{ required: true, message: "กรุณาระบุเลขที่ใบสั่งงาน" }
								]}
							>
								<Input
									style={{ fontSize: "15pt", color: "blue", fontWeight: "bold", textAlign: "center" }}
								></Input>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	)
}

export default WorksheetCodeModal