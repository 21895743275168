import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import Flex from "src/components/container/Flex";


interface IProps {
  title: string;
  onClose?: () => void;
  childrend?: React.ReactNode;
}

const PageHeader: React.FC<IProps> = ({ title, onClose, children }) => {
  const closeHandler = () => {
    if (onClose) onClose();
  };

  return (
    <div
      className="app-page-header"
    // style={{ height: "65px", padding: "0px 20px" }}
    >
      <Flex justifyContent="between" alignItems="center">
        <div className="d-flex flex-row">
          <div style={{ paddingRight: "1em" }}>
            {onClose && (
              <Button
                className="btn-close"
                size="middle"
                onClick={closeHandler}
              >
                <ArrowLeftOutlined />
              </Button>
            )}
          </div>
          <h3 className="mb-0 mr-3 font-weight-semibold">
            {/* <IntlMessage id={title ? title : "home"} /> */}
            {title ? title : ""}
          </h3>
        </div>

        <div className="d-flex flex-row">
          <div className="action-container">{children}</div>
        </div>
      </Flex>
    </div>
  );
};

export default PageHeader;
