import { Form, Modal, Select, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { TEmployee2View } from 'src/domain/master/view/tEmployee2View';
import { TUserHead } from 'src/domain/tUserHead';
import { TWorksheetHead } from 'src/domain/worksheet/tWorksheetHead';
import { WorksheetHeadData } from 'src/domain/worksheet/view/worksheetHeadData';
import { default as employeeService, default as tEmployee2Service } from 'src/services/employee/tEmployee2Service';
import worksheetService from 'src/services/worksheetService';
import { useAppSelector } from 'src/stores/store';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
const { Option } = Select;

interface IProps {
  editDriverModalVisible: boolean;
  setEditDriverModalVisible: any;
  // setUpdateStatus: any;
  worksheetHeadData: WorksheetHeadData;
  setWorksheetHeadData: any;
  tUserHeadUpdate: TUserHead;
}

const EditDriverModal: React.FC<IProps> = (props) => {
  const { editDriverModalVisible, setEditDriverModalVisible, worksheetHeadData, setWorksheetHeadData, tUserHeadUpdate } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [drivers, setDrivers] = useState<TEmployee2View[]>([]);
  const [employees, setEmployees] = useState<TEmployee2View[]>([]);//ผู้แจ้ง
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  useEffect(() => {
    form.setFieldsValue({
      driverCode: worksheetHeadData.tworksheetHead.idriverCode,
    });

    loadData();
    return () => { };
  }, []);

  const loadData = async () => {
    if (editDriverModalVisible) {
      const driverRes = await tEmployee2Service.getDriverByName(worksheetHeadData.tworksheetHead.idriverName as string);
      setDrivers(driverRes.data);
    }
  };

  const handleSearchDriver = async (data: any) => {
    if (data.length < 3) return;
    const res = await tEmployee2Service.getDriverByName(data);
    setDrivers(res.data);
  };

  const handleSearchEmployee = async (data: any) => {
    if (data.length < 3) return;
    const res = await employeeService.getEmployee2ByParam(data);
    setEmployees(res.data);
  };

  const onFinish = async (values: any) => {
    if (worksheetHeadData.tassetHeadView?.assettype === "002" ||
      worksheetHeadData.tassetHeadView?.assettype === "003" ||
      worksheetHeadData.tassetHeadView?.assettype === "006") {
      showNotification(NotificationType.ERROR, "ไม่สามารถแก้ไขพนักงานขับรถ กับ ประเภทสินทรัพย์นี้ ตู้/แผ่นเหล็ก/ เครื่องจักร ได้");
      setEditDriverModalVisible(false);
      setTrigger(false);
    } else if (worksheetHeadData.tworksheetHead.idriverCode === values.driverCode) {
      showNotification(NotificationType.WARNING, "ไม่มีการเปลี่ยนแปลงพนักงานขับรถ");
      setTrigger(false);
    } else {
      const employeeArr = employees as Array<TEmployee2View>;
      const empSelected = employeeArr.find((obj) => obj.id === values.userInfoId);
      const driverArr = drivers as Array<TEmployee2View>;
      const driverSelected = driverArr.find((obj) => obj.id === values.driverCode);
      //กด OK แล้วค่อย update ค่าในตาราง
      const temp = {
        ...(worksheetHeadData as WorksheetHeadData),
        tworksheetHead: {
          ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
          idriverCode: values.driverCode,
          idriverName: driverSelected?.empname,
          isavStation: userInfo.saveStation,
          ilastUser: tUserHeadUpdate.iuserId,
          itermNo: userInfo.termNo,
        },
        updateUser: tUserHeadUpdate,
      };
      setWorksheetHeadData(temp);

      try {
        await worksheetService.updateWorksheetWithSubMenuKey(temp, values.userRemark, empSelected ? empSelected.empname : "", "MENU_DRIVER"); //363
        showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเรียบร้อยแล้ว!");
        setEditDriverModalVisible(false);
        // setUpdateStatus(true);
        form.resetFields();
        setTrigger(false);
      } catch (err) {
        showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        setTrigger(false);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาตรวจสอบข้อมูลให้ครบถ้วน!");
    setTrigger(false);
  };
  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>แก้ไขรายการพนักงานขับรถ</Typography.Title>
          </div>
        }
        visible={editDriverModalVisible}
        width={"50%"}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setEditDriverModalVisible(false)}
      >
        <Form
          form={form}
          name="driver"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="พนักงานขับรถ"
            name="driverCode"
            rules={[{ required: true, message: "กรุณาระบุ พนักงานขับรถ!" }]}
          >
            <Select
              showSearch
              allowClear
              size="middle"
              placeholder="เลือกพนักงานขับรถ"
              optionFilterProp="children"
              filterOption={true}
              onSearch={handleSearchDriver}
            >
              {drivers.map((obj) => {
                return (
                  <Option
                    key={obj.id}
                    value={obj.id}
                  >
                    {obj.empname}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="ผู้แจ้ง"
            name="userInfoId"
            rules={[{ required: true, message: "กรุณาระบุ ผู้แจ้ง!" }]}
          >
            <Select
              showSearch
              size="middle"
              placeholder="เลือกผู้แจ้ง"
              optionFilterProp="children"
              filterOption={true}
              onSearch={handleSearchEmployee}
            >
              {employees.map((obj) => {
                return (
                  <Option key={obj.id} value={obj.id}>
                    {obj.empname}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="หมายเหตุ"
            name="userRemark"
            validateTrigger="onBlur"
            rules={[{ required: true, message: "กรุณาระบุเหตุผล !" },
            {
              validator: (_, value) => {
                if (value && value.length <= 10) {
                  return Promise.reject(showNotification(NotificationType.ERROR, "กรุณาระบุเหตุผล ให้มากกว่า 10 ตัวอักษร"))
                } else {
                  return Promise.resolve()
                }
              },
            },
            ]}
          >
            <TextArea
              rows={4}
            ></TextArea>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default EditDriverModal