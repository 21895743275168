import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import Search from "antd/es/input/Search";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PageHeader from "src/layouts/PageHeader";
import { toThDate } from "src/utils/dateTimeUtils";
import { TEmployee2 } from "../../domain/master/tEmployee2";
import tEmployeeService from "../../services/employee/tEmployee2Service";
import AddEditEmployee2Drawer from "./AddEditEmployee2Drawer";


const Employee2List: React.FC<TEmployee2> = () => {
    const [employee2s, setEmployee2s] = useState<TEmployee2[]>([]);
    const [page, setPage] = React.useState(1);
    const history = useHistory();
    const [visibleAddEditEmployee2Drawer, setVisibleAddEditEmployee2Drawer] = useState<boolean>(false);

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            width: 50,
            render: (value: number, item: TEmployee2, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
        },
        {
            title: "รหัสพนักงาน",
            dataIndex: "id",
            key: "id",
            width: 150,
            render: (value: string, item: TEmployee2) => (
                <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.id}</Button>
            )
        },
        {
            title: "ชื่อ-สกุล",
            dataIndex: "empname",
            key: "empname"
        },
        {
            title: "วันที่เริ่มงาน",
            dataIndex: "empentrydate",
            key: "empentrydate",
            width: 150,
            render: (value: any, item: TEmployee2) => (<>{item.empentrydate ? toThDate(item.empentrydate) : ""}</>)
        },
        {
            title: "วันที่สิ้นสุดการทำงาน",
            dataIndex: "empendworkdate",
            key: "empendworkdate",
            width: 150,
            render: (value: any, item: TEmployee2) => (<>{(item.empendworkdate && item.empendworkdate.length >= 8) ? toThDate(item.empendworkdate) : ""}</>)
        }
    ];

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
        doSearch("");
    }, []);

    const doSearch = async (searchText: string) => {
        const res = await tEmployeeService.getEmployee2ByParam(searchText);
        setEmployee2s(res.data);
    }

    function onSearchTextChange(searchText: string) {
        if (!searchText) {
            doSearch("");
        } else if (searchText.length >= 2) {
            doSearch(searchText);
        }
    }

    function onAddEmployee2() {
        setVisibleAddEditEmployee2Drawer(true);
    }

    const onItemCliked = (item: TEmployee2) => {
        history.replace("/app/employee/view/" + item.id);
    };

    return (
        <>
            <PageHeader title="โปรแกรมข้อมูลพนักงาน">
                <Button
                    size="small"
                    type="primary"
                    icon={<PlusSquareOutlined />}
                    onClick={onAddEmployee2}
                >
                    เพิ่ม
                </Button>
            </PageHeader>
            <Content className="app-page-content">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "baseline",
                        margin: "1em 0 0 0",
                        borderBlockEnd: "2px solid #c9c9c9",
                    }}
                >
                    <label style={{ margin: "0 1em 1em 2em" }}>คำค้นหา</label>
                    <Search
                        allowClear
                        style={{ width: "350px" }}
                        size="middle"
                        placeholder="ป้อนชื่อ"
                        onChange={(event) => onSearchTextChange(event.target.value)}
                        onSearch={(value) => onSearchTextChange(value)}
                    ></Search>
                </div>
                <div>
                    <Table
                        columns={columns}
                        dataSource={employee2s}
                        size="small"
                        pagination={{
                            pageSize: 15,
                            onChange(current) {
                                setPage(current);
                            }
                        }}
                        rowKey={(item: TEmployee2) => item.id}
                        onRow={(item: TEmployee2) => {
                            return {
                                onDoubleClick: () => onItemCliked(item),
                            };
                        }}
                    />
                </div>
            </Content>
            <div> {
                visibleAddEditEmployee2Drawer && <AddEditEmployee2Drawer
                    id={""}
                    visibleAddEditEmployee2Drawer={visibleAddEditEmployee2Drawer}
                    setVisibleAddEditEmployee2Drawer={setVisibleAddEditEmployee2Drawer} />
            }</div>
        </>
    );
};

export default Employee2List;
