import { FinanceData } from "src/domain/finance/view/financeData";
import Constants from "src/utils/constants";
import http from "../../lib/http";

const getById = (id: number) => {
  return http.get(Constants.DEFAULT_URL + "/tFinanceHead2/" + id);
};

const getTfinanceHead2ByParam = (worksheetCodeCustname: string, fromDate: string, toDate: string) => {
  return http.get(Constants.DEFAULT_URL + "/tFinanceHead2/getFinanceHeadDataByParam", {
    params: {
      worksheetCodeCustname,
      fromDate,
      toDate,
    }
  });
};

const getByWorksheetPaymentStatusList = (
  worksheetCodeCustname: string,
  nonTypePayment: string,
  startDate: string,
  endDate: string,
  check: boolean
) => {
  return http.get(Constants.DEFAULT_URL + "/tFinanceHead2/getByWorksheetPaymentStatusList", {
    params: {
      worksheetCodeCustname,
      nonTypePayment,
      startDate,
      endDate,
      check
    },
  });
};

const getTFinanceHead2CashPaymentViewData = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/tFinanceHead2/getTFinanceHead2CashPaymentViewData/" + id);
};

const getTFinanceHead2Cash = (fromDate: string, trushId: string) => {
  return http.get(Constants.DEFAULT_URL + "/tFinanceHead2/getTFinanceHead2Cash/", {
    params: {
      fromDate,
      trushId,
    },
  });
};

const saveFinanceData = (financeData: FinanceData) => {
  return http.post(Constants.DEFAULT_URL + "/tFinanceHead2/saveFinanceData", financeData);
};

const defaultApi = {
  getById,
  getTfinanceHead2ByParam,
  getByWorksheetPaymentStatusList,
  getTFinanceHead2CashPaymentViewData,
  getTFinanceHead2Cash,
  saveFinanceData,
}
export default defaultApi;