import { Col, Descriptions, Form, Input, Row, Space, Spin, Table, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Content } from 'antd/lib/layout/layout';
import { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { TFinanceHead2View } from 'src/domain/finance/view/tFinanceHead2View';
import { TServiceHeadView } from 'src/domain/master/view/tServiceHeadView';
import { TWshtaxDetail } from 'src/domain/tWshtaxDetail';
import { TWorksheetDetail } from 'src/domain/worksheet/tWorksheetDetail';
import { TWorksheetHeadView } from 'src/domain/worksheet/view/tWorksheetHeadView';
import PageHeader from "src/layouts/PageHeader";
import tFinanceHead2Service from 'src/services/finance/tFinanceHead2Service';
import tServiceHeadService from 'src/services/tservice/tServiceHeadService';
import tWorksheetDetailService from 'src/services/tWorksheetDetailService';
import tWshtaxDetailService from 'src/services/tWshtaxDetailService';
import { formatNumber } from 'src/utils/numberUtils';

interface IParams {
  financeHead2Id: string;
}

const ViewFinanceReceivable: React.FC<RouteComponentProps<IParams>> = (props) => {
  const financeHead2Id = props.match.params.financeHead2Id;
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [tfinanceHead2View, setTfinanceHead2View] = useState<TFinanceHead2View>({} as TFinanceHead2View);
  const [tServiceHead, setTServiceHead] = useState<TServiceHeadView>({} as TServiceHeadView);
  const [tWshtaxDetail, setTWshtaxDetail] = useState<TWshtaxDetail>({} as TWshtaxDetail);
  const [isCal, setIsCal] = useState<boolean>(false);

  let sumservice = 0.00;
  let total = 0.00;
  let vat = 0.00;
  let total2 = 0.00;
  let remaint = 0.00;

  useEffect(() => {
    setLoading(true);
    (async () => {
      const res = await tFinanceHead2Service.getTFinanceHead2CashPaymentViewData(financeHead2Id);
      setTfinanceHead2View(res.data);
      const tWorksheetDetailRes = await tWorksheetDetailService.getByReserveCode(res.data.tworksheetHeadView.ireserveCode!);
      const tWshtaxDetailRes = await tWshtaxDetailService.getByWorksheetCode(res.data.tworksheetHeadView.iworksheetCode);
      if (tWorksheetDetailRes.data[0]) {
        const tWorksheetDetail = tWorksheetDetailRes.data[0] as TWorksheetDetail
        const tServiceHeadViewRes = await tServiceHeadService.getTServiceHeadByCode(tWorksheetDetail.iserviceCode!);
        setTServiceHead(tServiceHeadViewRes.data)
      }
      calSumserviceHead(res.data);
      setIsCal(true);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (isCal) {
      recalculate();
    }
  }, [isCal]);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: TWorksheetHeadView, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่ใบสั่งงาน",
      dataIndex: "iworksheetCode",
      key: "iworksheetCode",
      width: 120,
      align: "center" as "center",
    },
    {
      title: "รายการ",
      dataIndex: "serviceNameRemarks",
      key: "serviceNameRemarks",
      width: 350,
      align: "center" as "center",
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "iserviceAmt",
      key: "iserviceAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item?.iserviceAmt)}</span>;
      },
    },
    {
      title: "OT",
      dataIndex: "iotAmt",
      key: "iotAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item?.iotAmt)}</span>;
      },
    },
    {
      title: "ค่าพิเศษ",
      dataIndex: "iextraAmt",
      key: "iextraAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item?.iextraAmt)}</span>;
      },
    },
    {
      title: "ส่วนลด",
      dataIndex: "idiscount",
      key: "idiscount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item?.idiscount)}</span>;
      },
    },
    {
      title: "รวม",
      dataIndex: "total1Amt",
      key: "total1Amt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item?.itotal1Amt)}</span>;
      },
    },
    {
      title: "ภาษี",
      dataIndex: "vatAmt",
      key: "vatAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item?.itaxAmt)}</span>;
      },
    },
    {
      title: "สุทธิ",
      dataIndex: "total2Amt",
      key: "total2Amt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item?.itotal2Amt)}</span>;
      },
    },
    {
      title: "ชำระแล้ว",
      dataIndex: "idepositAmt",
      key: "idepositAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item?.idepositAmt)}</span>;
      },
    },
    {
      title: "คงเหลือ",
      dataIndex: "remainAmt",
      key: "remainAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item?.iremainAmt)}</span>;
      },
    },
    {
      title: "หมายเหตุ",
      dataIndex: "iremark",
      key: "iremark",
      width: 80,
      align: "center" as "center",
    },
  ];

  const calSumserviceHead = (tfinanceHead2View: TFinanceHead2View) => {
    const serviceamt = (tfinanceHead2View.tworksheetHeadView.iserviceAmt! + tfinanceHead2View.tworksheetHeadView.iotAmt! + tfinanceHead2View.tworksheetHeadView.iextraAmt!) - (tfinanceHead2View.tworksheetHeadView.idiscount!);
    if ("01" === tfinanceHead2View.tworksheetHeadView.itypetaxCode) {
      vat = serviceamt * 7 / 100;
      total = serviceamt;
    }
    if ("02" === tfinanceHead2View.tworksheetHeadView.itypetaxCode) {
      vat = serviceamt * 7 / 107;
      total = serviceamt - vat;
    }
    if ("03" === tfinanceHead2View.tworksheetHeadView.itypetaxCode) {
      vat = 0;
      total = serviceamt;
    }
    total2 = total + vat;
    remaint = total2 - (tfinanceHead2View.tworksheetHeadView.purchasetax! - tfinanceHead2View.tworksheetHeadView.payedamount!);
    sumservice += remaint;
  }

  // const recalculate = async (tfinanceHead2View: TFinanceHead2View) => {
  const recalculate = async () => {
    let amountBeforVat = 0.00;
    let vatamount = 0.00;
    let sumtotal = 0.00;
    let purchaseTax = 0.00;
    let sumremaint = 0.00;
    let total = 0.00;
    let serviceamt = 0.00;
    let vat = 0.00;
    let total2 = 0.00;
    if ((tfinanceHead2View.tworksheetHeadView.allinstalment ? tfinanceHead2View.tworksheetHeadView.allinstalment : 0) > 1 //มีจำนวนงวดมากกว่า 1 งวด
      && tfinanceHead2View.tworksheetHeadView.itypepaymentCode !== '01' //ไม่ใช่สดก่อนเริ่มงาน
      && tfinanceHead2View.tworksheetHeadView.itypepaymentCode !== '02'//ไม่ใช่สดเมื่องานเสร็จ
      && tfinanceHead2View.tworksheetHeadView.typeworksheetflag !== '02' //ไม่ใช่ใบงานเหมา
      && tfinanceHead2View.tworksheetHeadView.typeworksheetflag !== '05'//ไม่ใช่ใบงานซ่อม/ขายอื่นๆ
      && tfinanceHead2View.tworksheetHeadView.typeworksheetflag !== '04') {//ไม่ใช่ใบงานเหมาแผ่นเหล็ก
      if (tfinanceHead2View.tworksheetHeadView.idepositFlg !== 1) {//กรณีชำระเงินมัดจำเรียบร้อย
        if ((tfinanceHead2View.tworksheetHeadView.allinstalment ? tfinanceHead2View.tworksheetHeadView.allinstalment : 0) > 12) {//ชำระงวดที่มากกว่า 12
          serviceamt = tServiceHead.iunitPrice3;
        } else if (((tfinanceHead2View.tworksheetHeadView.allinstalment ? tfinanceHead2View.tworksheetHeadView.allinstalment : 0) > 3) && ((tfinanceHead2View.tworksheetHeadView.allinstalment ? tfinanceHead2View.tworksheetHeadView.allinstalment : 0) <= 12)) {
          serviceamt = tServiceHead.iunitPrice2;
        } else {//ชำระเงินงวดปกติ
          serviceamt = tServiceHead.iunitPrice;
        }
      } else {//กรณีชำระเงินมัดจำ
        serviceamt = tServiceHead.iunitPrice + (tfinanceHead2View.tworksheetHeadView.iextraAmt ? tfinanceHead2View.tworksheetHeadView.iextraAmt : 0) + (tfinanceHead2View.tworksheetHeadView.iotAmt ? tfinanceHead2View.tworksheetHeadView.iotAmt : 0);
      }
      serviceamt = serviceamt - ((tfinanceHead2View.tworksheetHeadView.idiscount ? tfinanceHead2View.tworksheetHeadView.idiscount : 0) / (tfinanceHead2View.tworksheetHeadView.allinstalment ? tfinanceHead2View.tworksheetHeadView.allinstalment : 1));
    } else {//กรณีเป็นสดก่อนเริ่มงาน
      serviceamt = (tfinanceHead2View.tworksheetHeadView.iserviceAmt ? tfinanceHead2View.tworksheetHeadView.iserviceAmt : 0) + (tfinanceHead2View.tworksheetHeadView.iotAmt ? tfinanceHead2View.tworksheetHeadView.iotAmt : 0)
        + (tfinanceHead2View.tworksheetHeadView.iextraAmt ? tfinanceHead2View.tworksheetHeadView.iextraAmt : 0) - (tfinanceHead2View.tworksheetHeadView.idiscount ? tfinanceHead2View.tworksheetHeadView.idiscount : 0);
    }
    if ("01" === tfinanceHead2View.tworksheetHeadView.itypetaxCode) {//กรณีเป็น Vat นอก
      vat = serviceamt * 7 / 100;
      total = serviceamt;
    }
    if ("02" === tfinanceHead2View.tworksheetHeadView.itypetaxCode) {//กรณีเป็น Vat  ใน
      vat = serviceamt * 7 / 107;
      total = serviceamt - vat;
    }
    if ("03" === tfinanceHead2View.tworksheetHeadView.itypetaxCode) {//กรณีไม่มี Vat
      vat = 0;
      total = serviceamt;
    }

    amountBeforVat += total;
    vatamount += vat;
    total2 = total + vat;
    sumtotal += total2;

    if ((tfinanceHead2View.tworksheetHeadView.allinstalment ? tfinanceHead2View.tworksheetHeadView.allinstalment : 0) > 1
      && tfinanceHead2View.tworksheetHeadView.itypepaymentCode !== '01' //กรณีจำนวนงวดมากกว่า 1งวด,ไม่ใช่สดก่อนเริ่มงาน,ถูกหักภาษีณที่จ่าย
      && tWshtaxDetail.id) {
      purchaseTax = (tWshtaxDetail.taxamount ? tWshtaxDetail.taxamount : 0);
    } else {
      purchaseTax = (tfinanceHead2View.tworksheetHeadView.purchasetax ? tfinanceHead2View.tworksheetHeadView.purchasetax : 0);
    }

    if (tfinanceHead2View.tworksheetHeadView.itypepaymentCode === "01") {
      sumremaint = sumtotal - purchaseTax;
    } else if (tfinanceHead2View.tworksheetHeadView.itypepaymentCode === "02") {
      sumremaint = sumtotal - purchaseTax;
    } else if (tfinanceHead2View.tworksheetHeadView.itypepaymentCode === "03") {
      if (tfinanceHead2View.tworksheetHeadView.typeworksheetflag !== "02" && tfinanceHead2View.tworksheetHeadView.typeworksheetflag !== "04" && tfinanceHead2View.tworksheetHeadView.typeworksheetflag !== "05") {
        if (tfinanceHead2View.tworksheetHeadView.truckAssettype === "002") {
          sumremaint = sumtotal - purchaseTax;
        } else {
          if (tfinanceHead2View.tworksheetHeadView.idepositFlg !== 1) {
            sumremaint = (tfinanceHead2View.tworksheetHeadView.iremainAmt ? tfinanceHead2View.tworksheetHeadView.iremainAmt : 0);
          } else {
            sumremaint = (tfinanceHead2View.tworksheetHeadView.idepositAmt ? tfinanceHead2View.tworksheetHeadView.idepositAmt : 0) - purchaseTax;
          }
        }
      } else {
        if (tfinanceHead2View.tworksheetHeadView.idepositFlg !== 1) {
          sumremaint = (tfinanceHead2View.tworksheetHeadView.iremainAmt ? tfinanceHead2View.tworksheetHeadView.iremainAmt : 0);
        } else {
          sumremaint = (tfinanceHead2View.tworksheetHeadView.idepositAmt ? tfinanceHead2View.tworksheetHeadView.idepositAmt : 0) - purchaseTax;
        }
      }
    }

    form.setFieldsValue({
      numServiceAmt: formatNumber(amountBeforVat),
      numVatAmt: formatNumber(vatamount),
      numTotal: formatNumber(sumtotal),
      numTaxAmount: formatNumber(purchaseTax),
      cashAmount: formatNumber(tfinanceHead2View.cashamount),
      chequeAmount: formatNumber(tfinanceHead2View.chequeamount),
      payinAmount: formatNumber(tfinanceHead2View.payinamount),
      otherReceiveAmount: formatNumber(tfinanceHead2View.otheramount),
      creditNoteDiscountAmt: formatNumber(tfinanceHead2View.creditnoteDiscount),
      numNetTotal: formatNumber(sumremaint),//ต้องตรวจสอบว่ามีค่า decimal หรือไม่ ถ้ามีให้บวกเพิ่มหรือเปล่า?
      numNetAmount: formatNumber(tfinanceHead2View.cashamount + tfinanceHead2View.chequeamount + tfinanceHead2View.payinamount + tfinanceHead2View.otheramount + tfinanceHead2View.creditnoteDiscount),
      remark: tfinanceHead2View.remarks,
    });
  }

  return (
    <>
      <PageHeader title="" onClose={() => history.push("/app/financeReceivable/list")} />
      <Spin spinning={loading}>
        <Content className="app-page-content">
          <Descriptions
            style={{ margin: "1em" }}
            column={4}
            title="ข้อมูลการเงินรับชำระใบงานสด"
            size="middle"
          />
          <div style={{ margin: "1em" }}>
            <Form
              form={form}
              name="viewFinanceReceivable"
              labelCol={{ span: 10 }}
            >
              <Space direction="vertical" size="middle">
                <Descriptions
                  column={6}
                  size="small"
                  bordered
                  style={{ paddingLeft: '10em' }}
                  labelStyle={{ textAlign: "right", fontWeight: "bold" }}
                >
                  <Descriptions.Item label="เลขที่การรับเงิน" span={6}>{tfinanceHead2View.id}</Descriptions.Item>
                  <Descriptions.Item label="เลขที่ใบสั่งงาน" span={3}>{tfinanceHead2View.tworksheetHeadView?.iworksheetCode}</Descriptions.Item>
                  <Descriptions.Item label="ประเภทการชำระเงิน" span={3}>{tfinanceHead2View.tworksheetHeadView?.itypepaymentName}</Descriptions.Item>
                  <Descriptions.Item label="ชื่อลูกค้า" span={3}>{tfinanceHead2View.tworksheetHeadView?.custFullName}</Descriptions.Item>
                  <Descriptions.Item label="ประเภทใบสั่งงาน" span={3}>{tfinanceHead2View.tworksheetHeadView?.itypeworksheetName}</Descriptions.Item>
                  <Descriptions.Item label="ยอดค้างชำระ" span={3}>{formatNumber(sumservice)} บาท</Descriptions.Item>
                  <Descriptions.Item label="รับเอกสารใบงาน" span={3}>{(tfinanceHead2View?.worksheetReturnStatus) === 1 ? "ได้รับเอกสาร" : "ไม่ได้รับเอกสาร"}</Descriptions.Item>
                </Descriptions>
                <Table
                  columns={columns}
                  dataSource={[tfinanceHead2View.tworksheetHeadView]}
                  size="small"
                  rowKey={(item: TWorksheetHeadView) => (item?.ireserveCode) ? item?.ireserveCode : 0}
                  pagination={false}
                />
                <Row>
                  <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>หมายเหตุ: </Typography.Text>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      name="remark"
                      rules={[{ required: true, message: "กรุณาระบุหมายเหตุ!" }]}
                    >
                      <TextArea
                        rows={2}
                        disabled
                      ></TextArea>
                    </Form.Item>
                  </Col>
                  <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>ส่วนลด(ใบลดหนี้): </Typography.Text>
                  </Col>
                  <Col span={7}>
                    <Space align='baseline' style={{ width: "80%" }}>
                      <Form.Item
                        name="creditNoteDiscountAmt"
                        style={{ margin: "0 0 3px 0px" }}
                      >
                        <Input style={{ fontWeight: "bold", border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                      </Form.Item>
                      <Typography.Text> บาท</Typography.Text>
                    </Space>
                  </Col>
                </Row>
              </Space>
              <Row style={{ padding: "5px 0px 0px" }}>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>จำนวนเงินก่อนภาษี: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="numServiceAmt"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ fontWeight: "bold", border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เงินสด: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="cashAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ fontWeight: "bold", border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
              </Row>

              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ภาษี: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="numVatAmt"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ fontWeight: "bold", border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly ></Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เช็ค: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="chequeAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ fontWeight: "bold", border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly ></Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เป็นเงิน: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="numTotal"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ fontWeight: "bold", border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เงินโอน: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="payinAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ fontWeight: "bold", border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>หัก ณ ที่จ่าย: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="numTaxAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ fontWeight: "bold", border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>รายรับอื่นๆ: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="otherReceiveAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ fontWeight: "bold", border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>คงเหลือต้องชำระ: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="numNetTotal"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ fontWeight: "bold", border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>รวมรับชำระ: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="numNetAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ fontWeight: "bold", border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
              </Row>
            </Form>
          </div>
        </Content>
      </Spin>
    </>
  );
};

export default ViewFinanceReceivable;