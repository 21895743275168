import { ArrowDownOutlined, ArrowUpOutlined, CloseCircleOutlined, ExclamationCircleOutlined, PlusSquareOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, Drawer, Form, Input, InputNumber, Modal, Radio, Row, Space, Spin, Table, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { TChequeHead } from 'src/domain/cheque/tChequeHead';
import { TFinanceChequedetail } from 'src/domain/finance/tFinanceChequedetail';
import { TFinanceHead2 } from 'src/domain/finance/tFinanceHead2';
import { TFinanceOtherworkdetail } from 'src/domain/finance/tFinanceOtherworkdetail';
import { TFinancePayindetail } from 'src/domain/finance/tFinancePayindetail';
import { TDecimal } from 'src/domain/tDecimal';
import { TPayinHead } from 'src/domain/tPayinHead';
import { TWshtaxDetailView } from 'src/domain/view/tWshtaxDetailView';
import { TWorksheetHeadView } from 'src/domain/worksheet/view/tWorksheetHeadView';
import tCreditnoteHeadService from 'src/services/finance/tCreditnoteHeadService';
import tRangradWangbillDetailService from 'src/services/tRangradWangbillDetailService';

import { TDebitorHead } from 'src/domain/finance/tDebitorHead';
import { TCreditnoteHead } from 'src/domain/tCreditnoteHead';
import { TRangradWangbillDetailData } from 'src/domain/view/tRangradWangbillDetailData';
import { TRangradWangbillHeadView } from 'src/domain/view/tRangradWangbillHeadView';
import { TWorksheetRangradWangbillView } from 'src/domain/worksheet/view/tWorksheetRangradWangbillView';
import tWshtaxDetailService from 'src/services/tWshtaxDetailService';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber, textNumber } from 'src/utils/numberUtils';
import tDebitorService from "../../services/debitor/tDebitorService";
import AddTChequeHeadModal from '../modals/AddTChequeHeadModal';
import AddTPayinHeadModal from '../modals/AddTPayinHeadModal';
import AddTWshtaxDetailModal from '../modals/AddTWshtaxDetailModal';
import SearchCreditNoteDiscountModal from '../modals/SearchCreditNoteDiscountModal';
import SearchOtherWorkSearchModal from '../modals/SearchOtherWorkSearchModal';
import SearchTChequeHeadModal from '../modals/SearchTChequeHeadModal';
import SearchTPayinHeadModal from '../modals/SearchTPayinHeadModal';

const { confirm } = Modal;

interface IProps {
  onDrawerVisible: boolean;
  setOnDrawerVisible: any;
  selectTRangradWangbillHeadView: TRangradWangbillHeadView;
}

const FinanceReceivableDrawer: React.FC<IProps> = (props) => {
  const { onDrawerVisible, setOnDrawerVisible, selectTRangradWangbillHeadView } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [showSpin, setShowSpin] = useState<boolean>(false);
  const [form] = Form.useForm();

  const [tFinanceChequeDetails, setTFinanceChequeDetails] = useState<TFinanceChequedetail[]>([]);
  const [tFinancePayinDetails, setTFinancePayinDetails] = useState<TFinancePayindetail[]>([]);
  const [tFinanceOtherWorkDetails, setTFinanceOtherWorkDetails] = useState<TFinanceOtherworkdetail[]>([]);

  const [addTChequeHeadModalVisible, setAddTChequeHeadModalVisible] = useState<boolean>(false);
  const [searchTChequeHeadModalVisible, setSearchTChequeHeadModalVisible] = useState<boolean>(false);
  const [addTPayinHeadModalVisible, setAddTPayinHeadModalVisible] = useState<boolean>(false);
  const [searchTPayinHeadModalVisible, setSearchTPayinHeadModalVisible] = useState<boolean>(false);
  const [searchOtherWorkModalVisible, setSearchOtherWorkModalVisible] = useState<boolean>(false);
  const [addTWshtaxDetailModalVisible, setAddTWshtaxDetailModalVisible] = useState<boolean>(false);
  const [searchCreditNoteModalVisible, setSearchCreditNoteModalVisible] = useState<boolean>(false);  

  const [isCreditNote, setIsCreditNote] = useState<boolean>(false);
  const [isWithholdingTax, setIsWithholdingTax] = useState<boolean>(false);
  const [newTWshtaxDetailView, setNewTWshtaxDetailView] = useState<TWshtaxDetailView>({} as TWshtaxDetailView);
  const [tWorksheetRangradWangbillViews, setTWorksheetRangradWangbillViews] = useState<TWorksheetRangradWangbillView[]>([]);
  const [tCreditnoteHeads, setTCreditnoteHeads] = useState<TCreditnoteHead[]>([]);

  const [tDebitorHead, setTDebitorHead] = useState<TDebitorHead>({} as TDebitorHead);
  const [tChequeHead, setTChequeHead] = useState<TChequeHead>({} as TChequeHead);
  const [tPayinHead, setTPayinHead] = useState<TPayinHead>({} as TPayinHead);
  const [tFinanceOtherworkdetail, setTFinanceOtherworkdetail] = useState<TFinanceOtherworkdetail>({ debitorcode: selectTRangradWangbillHeadView.idebitorCode! } as TFinanceOtherworkdetail)

  const [buttonRemainUpDisabled, setButtonRemainUpDisabled] = useState<boolean>(false);
  const [buttonRemainDownDisabled, setButtonRemainDownDisabled] = useState<boolean>(false);


  const [absAmountStr, setAbsAmountStr] = useState<string>("");
  const [decimal, setDecimal] = useState(0.00);
  const [decimalR, setDecimalR] = useState(0.00);

  const [isRecal, setIsRecal] = useState<boolean>(false);
  const [netTotal, setNetTotal] = useState(0.00);

  useEffect(() => {
    if (trigger) {
      if (isValid()) {
        setTrigger(false);
        return;
      }

      form.validateFields()
        .then(() => {
          confirm({
            title: "ยืนยัน!",
            icon: <ExclamationCircleOutlined />,
            content: "คุณต้องการที่จะบันทึกข้อมูลหรือไม่?",
            cancelText: "ยกเลิก",
            okText: "ยืนยัน",
            onOk() {
              form.submit();
            },
            onCancel() {
              setTrigger(false);
            }
          });
        }).catch(() => {
          setTrigger(false);
        });
    }
  }, [trigger]);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: TWorksheetRangradWangbillView, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่ใบงาน",
      dataIndex: "iworksheetCode",
      key: "iworksheetCode",
      width: 120,
      align: "center" as "center",
    },
    {
      title: "รายการ",
      dataIndex: "truckNumber",
      key: "truckNumber",
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "iserviceAmt",
      key: "iserviceAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        return <span>{formatNumber(item.iserviceAmt)}</span>;
      },
    },
    {
      title: "OT",
      dataIndex: "iotAmt",
      key: "iotAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        return <span>{formatNumber(item.iotAmt)}</span>;
      },
    },
    {
      title: "ค่าพิเศษ",
      dataIndex: "iextraAmt",
      key: "iextraAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        return <span>{formatNumber(item.iextraAmt)}</span>;
      },
    },
    {
      title: "ส่วนลด",
      dataIndex: "idiscount",
      key: "idiscount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        return <span>{formatNumber(item.idiscount)}</span>;
      },
    },
    {
      title: "รวม",
      dataIndex: "total1Amt",
      key: "total1Amt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        let total1 = 0.00;
        let vatamt = 0.00;
        let serviceamt = (item.iserviceAmt ? Number(item.iserviceAmt) : 0.00) + (item.iextraAmt ? Number(item.iextraAmt) : 0.00) +
          (item.iotAmt ? Number(item.iotAmt) : 0.00) + (item.idiscount ? Number(item.idiscount) : 0.00)
        if (item.itypetaxCode === '01') {
          vatamt = Math.round(serviceamt * 7 / 100);
          total1 = serviceamt;
        }
        if (item.itypetaxCode === '02') {
          vatamt = Math.round(serviceamt * 7 / 107);
          total1 = serviceamt - vatamt;
        }
        if (item.itypetaxCode === '03') {
          vatamt = 0;
          total1 = serviceamt;
        }
        return <span>{formatNumber(total1)}</span>;
      },
    },
    {
      title: "ภาษี",
      dataIndex: "vatAmt",
      key: "vatAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        let vatamt = 0.00;
        let serviceamt = (item.iserviceAmt ? Number(item.iserviceAmt) : 0.00) + (item.iextraAmt ? Number(item.iextraAmt) : 0.00) +
          (item.iotAmt ? Number(item.iotAmt) : 0.00) + (item.idiscount ? Number(item.idiscount) : 0.00)
        if (item.itypetaxCode === '01') {
          vatamt = Math.round(serviceamt * 7 / 100);
        }
        if (item.itypetaxCode === '02') {
          vatamt = Math.round(serviceamt * 7 / 107);
        }
        if (item.itypetaxCode === '03') {
          vatamt = 0;
        }

        return <span>{formatNumber(vatamt)}</span>;
      },
    },
    {
      title: "สุทธิ",
      dataIndex: "total2Amt",
      key: "total2Amt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        let total1 = 0.00;
        let vatamt = 0.00;
        let serviceamt = (item.iserviceAmt ? Number(item.iserviceAmt) : 0.00) + (item.iextraAmt ? Number(item.iextraAmt) : 0.00) +
          (item.iotAmt ? Number(item.iotAmt) : 0.00) + (item.idiscount ? Number(item.idiscount) : 0.00)
        if (item.itypetaxCode === '01') {
          vatamt = Math.round(serviceamt * 7 / 100);
          total1 = serviceamt;
        }
        if (item.itypetaxCode === '02') {
          vatamt = Math.round(serviceamt * 7 / 107);
          total1 = serviceamt - vatamt;
        }
        if (item.itypetaxCode === '03') {
          vatamt = 0;
          total1 = serviceamt;
        }

        return <span>{formatNumber(total1 + vatamt)}</span>;
      },
    },
    {
      title: "ยอดคงเเหลือ",
      dataIndex: "remaint",
      key: "remaint",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        let total1 = 0.00;
        let vatamt = 0.00;
        let serviceamt = (item.iserviceAmt ? Number(item.iserviceAmt) : 0.00) + (item.iextraAmt ? Number(item.iextraAmt) : 0.00) +
          (item.iotAmt ? Number(item.iotAmt) : 0.00) + (item.idiscount ? Number(item.idiscount) : 0.00)
        if (item.itypetaxCode === '01') {
          vatamt = Math.round(serviceamt * 7 / 100);
          total1 = serviceamt;
        }
        if (item.itypetaxCode === '02') {
          vatamt = Math.round(serviceamt * 7 / 107);
          total1 = serviceamt - vatamt;
        }
        if (item.itypetaxCode === '03') {
          vatamt = 0;
          total1 = serviceamt;
        }
        if (item.payedamount && item.payedamount === 0) {
          return <span>{formatNumber((total1 + vatamt) - (item.idepositAmt ? Number(item.idepositAmt) : 0) + (item.purchasetax ? Number(item.purchasetax) : 0))}</span>;
        } else {
          return <span>{formatNumber((total1 + vatamt) - (item.payedamount ? Number(item.payedamount) : 0))}</span>;
        }
      },
    },
    {
      title: "หมายเหตุ",
      dataIndex: "iremark",
      key: "iremark",
    },
  ];

  const initial = async () => {
    setShowSpin(true);
    const tDebitorHeadRes = await tDebitorService.getDebitorHeadById(selectTRangradWangbillHeadView.idebitorCode)
    const tWorksheetRangradWangbillViewRes = await tRangradWangbillDetailService.getWorksheetRangradByDebitNoteCode(selectTRangradWangbillHeadView.idebitnoteNo);    
    const tWorksheetRangradWangbills = tWorksheetRangradWangbillViewRes.data as TWorksheetRangradWangbillView[];
    setTDebitorHead(tDebitorHeadRes.data);
    setTWorksheetRangradWangbillViews(tWorksheetRangradWangbills);

    form.setFieldsValue({
      worksheetInOut: selectTRangradWangbillHeadView.typeDebitnote === "01" ? "OUT" : "IN",
    });
    setFinanceTypeAndCustomerCode(selectTRangradWangbillHeadView.typeDebitnote === "01" ? "OUT" : "IN");

    let result = false;
    for await (const tWorksheetRangradWangbill of tWorksheetRangradWangbills) {
      const tCreditnoteHeadRes = await tCreditnoteHeadService.isExistByWorksheetCode(tWorksheetRangradWangbill.iworksheetCode!)
      if (tCreditnoteHeadRes.data) {
        result = true;
        break;
      }
    }
    setIsCreditNote(result)
    setIsWithholdingTax(result || selectTRangradWangbillHeadView.tax > 0);

    form.setFieldsValue({
      chequeAmount: 0,
      payinAmount: 0,
      otherReceiveAmount: 0,
      numNetAmount: 0,
      cashAmount: formatNumber(0),
    });
    setIsRecal(true);
    setShowSpin(false);

  }

  useEffect(() => {
    if (isRecal) {
      sumationData();
    }
  }, [isRecal]);

  useEffect(() => {
    if (onDrawerVisible) {
      initial();
    }
  }, [onDrawerVisible]);

  useEffect(() => {
    if (!searchTChequeHeadModalVisible && isRecal) {

      let chequeAmount = 0;
      if (tFinanceChequeDetails.length > 0) {
        tFinanceChequeDetails.forEach(obj => {
          chequeAmount += (obj.amount) ? obj.amount : 0;
        });
      }
      form.setFieldsValue({
        chequeAmount: formatNumber(chequeAmount),
      });
      recalculate();
    }
  }, [searchTChequeHeadModalVisible]);

  useEffect(() => {
    if (!searchTPayinHeadModalVisible && isRecal) {

      let payinAmount = 0;
      if (tFinancePayinDetails.length > 0) {
        tFinancePayinDetails.forEach(obj => {
          payinAmount += (obj.amount) ? obj.amount : 0;
        });
      }
      form.setFieldsValue({
        payinAmount: formatNumber(payinAmount),
      });
      recalculate();
    }
  }, [searchTPayinHeadModalVisible]);

  useEffect(() => {
    if (!searchCreditNoteModalVisible && isRecal) {
      let creditnoteAmount = 0;
      if (tCreditnoteHeads.length > 0) {
        tCreditnoteHeads.forEach(obj => {
          creditnoteAmount += (obj.creditTotal) ? obj.creditTotal : 0;
        });
      }
      form.setFieldsValue({
        creditNoteDiscountAmt: formatNumber(creditnoteAmount),
      });
      setIsWithholdingTax(false);
      recalculate();
    }
  }, [searchCreditNoteModalVisible]);
  
  useEffect(() => {
    if (!addTWshtaxDetailModalVisible && isRecal) {
      if (newTWshtaxDetailView.remianAmountStr && newTWshtaxDetailView.worksheetcode) {
        sumationData();
        setDecimal(0.00);
        const fraction = Number('0' + newTWshtaxDetailView.remianAmountStr.substring(newTWshtaxDetailView.remianAmountStr.indexOf('.')));
        if (0 < fraction) {
          setButtonRemainUpDisabled(false);
          setButtonRemainDownDisabled(false);
        } else {
          setButtonRemainUpDisabled(true);
          setButtonRemainDownDisabled(true);
        }
        setIsWithholdingTax(newTWshtaxDetailView.taxamount > 0);
      }
    }
  }, [addTWshtaxDetailModalVisible]);

  useEffect(() => {
    if (!searchOtherWorkModalVisible && isRecal) {
      let otherReceiveAmount = 0;
      if (tFinanceOtherWorkDetails.length > 0) {
        tFinanceOtherWorkDetails.forEach(obj => {
          otherReceiveAmount += (obj.amount) ? obj.amount : 0;
        });
      }
      form.setFieldsValue({
        otherReceiveAmount: formatNumber(otherReceiveAmount),
      });
      recalculate();
    }
  }, [searchOtherWorkModalVisible]);

  const recalculate = async () => {
    //ดึงภาษี หัก ณ ที่จ่าย
    let numTaxAmount = selectTRangradWangbillHeadView.tax ? selectTRangradWangbillHeadView.tax : 0;
    if (newTWshtaxDetailView.taxamount > 0) numTaxAmount = newTWshtaxDetailView.taxamount;

    let netTotal = (selectTRangradWangbillHeadView.total ? selectTRangradWangbillHeadView.total : 0) + (selectTRangradWangbillHeadView.vat ? selectTRangradWangbillHeadView.vat : 0) - (numTaxAmount);
    const total = (selectTRangradWangbillHeadView.total ? selectTRangradWangbillHeadView.total : 0) + (selectTRangradWangbillHeadView.vat ? selectTRangradWangbillHeadView.vat : 0);
        
    form.setFieldsValue({
      numServiceAmt: formatNumber((selectTRangradWangbillHeadView.total ? selectTRangradWangbillHeadView.total : 0)),
      numVatAmt: formatNumber((selectTRangradWangbillHeadView.vat ? selectTRangradWangbillHeadView.vat : 0)),
      numTotal: formatNumber(total),
      numTaxAmount: formatNumber(numTaxAmount),
      numNetTotal: formatNumber(netTotal)
    });
    setNetTotal(netTotal);

    const cashAmount = form.getFieldValue('cashAmount') ? parserNumber(form.getFieldValue('cashAmount')) : 0;
    const chequeAmount = form.getFieldValue('chequeAmount') ? parserNumber(form.getFieldValue('chequeAmount')) : 0;
    const payinAmount = form.getFieldValue('payinAmount') ? parserNumber(form.getFieldValue('payinAmount')) : 0;
    const otherReceiveAmount = form.getFieldValue('otherReceiveAmount') ? parserNumber(form.getFieldValue('otherReceiveAmount')) : 0;
    
    let newcreditNoteDiscountAmt = form.getFieldValue("creditNoteDiscountAmt") ? parserNumber(form.getFieldValue("creditNoteDiscountAmt")) : 0;
    if (newcreditNoteDiscountAmt > 0) {
      const wshTaxDetailRes = await tWshtaxDetailService.getByDebitNoteCode(selectTRangradWangbillHeadView.idebitnoteNo);
      if (wshTaxDetailRes.data) {
        let numTaxCreditNote = 0;
        let taxPercent = wshTaxDetailRes.data.taxpercent;
        const vatCreditNote = Math.round((newcreditNoteDiscountAmt * 7 / 107) * 100) / 100;
        newcreditNoteDiscountAmt = newcreditNoteDiscountAmt - vatCreditNote;
        numTaxCreditNote = Math.round((newcreditNoteDiscountAmt * taxPercent / 100) * 100) / 100;
        newcreditNoteDiscountAmt = newcreditNoteDiscountAmt - numTaxCreditNote + vatCreditNote + newcreditNoteDiscountAmt;
      }
    }
    const sumPay = cashAmount + chequeAmount + payinAmount + otherReceiveAmount + newcreditNoteDiscountAmt;
    form.setFieldsValue({
      numNetAmount: formatNumber(sumPay), //จำนวนเงินที่ต้องรับชำระ
    });
  }

  const sumationData = () => {
    setDecimal(0.00);
    recalculate();
    const numNetTotalStr = form.getFieldValue('numNetTotal') ? String(form.getFieldValue('numNetTotal')) : "0.00";
    setAbsAmountStr(numNetTotalStr.substring(0, numNetTotalStr.indexOf('.')));
    const fraction = Number('0' + numNetTotalStr.substring(numNetTotalStr.indexOf('.')));
    setDecimalR(parserNumber(fraction));
    if (0 < fraction) {
      setButtonRemainUpDisabled(false);
      setButtonRemainDownDisabled(false);
    } else {
      setButtonRemainUpDisabled(true);
      setButtonRemainDownDisabled(true);
    }
  }

  const onButtonTWshtaxDetailClicked = () => {
    if (selectTRangradWangbillHeadView.idebitnoteNo) {
      setAddTWshtaxDetailModalVisible(true)
    } else {
      showNotification(NotificationType.ERROR, "กรุณาระบุเลขที่ใบสั่งงานใหม่อีกครั้ง!");
    }
  }

  const onChangeworksheetInOut = (value: string) => {
    setFinanceTypeAndCustomerCode(value);
  }

  const setFinanceTypeAndCustomerCode = (value: string) => {
    if ("IN" === value) {
      setTChequeHead({ financetype: "02", customercode: selectTRangradWangbillHeadView.idebitorCode! } as TChequeHead);
      setTPayinHead({ financetype: "02", customercode: selectTRangradWangbillHeadView.idebitorCode! } as TPayinHead);
    } else {
      setTChequeHead({ financetype: "01", customercode: selectTRangradWangbillHeadView.idebitorCode! } as TChequeHead);
      setTPayinHead({ financetype: "01", customercode: selectTRangradWangbillHeadView.idebitorCode! } as TPayinHead);
    }
  }

  const onButtonRemainUpClicked = () => {
    let sum = 0.00;
    let expectFraction = 0.00;
    const numNetTotalStr = form.getFieldValue('numNetTotal') ? String(form.getFieldValue('numNetTotal')) : "0.00";
    const fraction = Number('0' + numNetTotalStr.substring(numNetTotalStr.indexOf('.')));
    // console.log("************fraction==== : ", fraction);
    if (fraction > 0 && fraction < 1) {
      if (fraction < 0.25) {
        expectFraction = 0.25;
      } else if (fraction < 0.50) {
        expectFraction = 0.50;
      } else if (fraction < 0.75) {
        expectFraction = 0.75;
      } else {
        expectFraction = 1;
      }

      sum = expectFraction + parserNumber(absAmountStr);
      form.setFieldsValue({
        numNetTotal: formatNumber(sum),
      });

      if (sum === parserNumber(absAmountStr) + 1) {
        setButtonRemainUpDisabled(true);
      }
    } else if (fraction === 0) {
      expectFraction = 0.25;
      sum = expectFraction + parserNumber(absAmountStr);
      form.setFieldsValue({
        numNetTotal: formatNumber(sum)
      });
    }
    // console.log("*****UPPP******* parserNumber(expectFraction - Number(decimalR)) ==== : ", parserNumber(expectFraction - Number(decimalR)));
    setDecimal(parserNumber(expectFraction - Number(decimalR)));
  }

  const onButtonRemainDownClicked = () => {
    let sum = 0.00;
    let expectFraction = 0.00;
    const numNetTotalStr = form.getFieldValue('numNetTotal') ? String(form.getFieldValue('numNetTotal')) : "0.00";
    const fraction = Number('0' + numNetTotalStr.substring(numNetTotalStr.indexOf('.'), numNetTotalStr.length));
    if (fraction > 0 && fraction < 1) {
      if (fraction <= 0.25) {
        expectFraction = 0;
      } else if (fraction <= 0.50) {
        expectFraction = 0.25;
      } else if (fraction <= 0.75) {
        expectFraction = 0.50;
      } else {
        expectFraction = 0.75;
      }
      sum = expectFraction + parserNumber(absAmountStr);
      form.setFieldsValue({
        numNetTotal: formatNumber(sum),
      });

      if (sum === parserNumber(absAmountStr)) {
        setButtonRemainDownDisabled(true);
      }
    } else if (fraction === 0) {
      expectFraction = 0.75;
      sum = expectFraction + parserNumber(absAmountStr);
      form.setFieldsValue({
        numNetTotal: formatNumber(sum)
      });
    }
    // console.log("*****DOWN******* parserNumber(expectFraction - Number(decimalR)) ==== : ", parserNumber(expectFraction - Number(decimalR)));
    setDecimal(parserNumber(expectFraction - Number(decimalR)));
  }

  const onFinish = async (values: any) => {
    setShowSpin(true);
    // กำหนดภาษีหัก ณ ที่จ่าย จะอัพเดทค่ากลับไปที่ worksheetHead กรณีที่เลือก dropdown เป็น ใบงานเท่านั้น
    tWorksheetRangradWangbillViews.forEach((obj: TWorksheetRangradWangbillView) => {
      obj.purchasetax =  parserNumber(values.numTaxAmount)
    });

    const worksheetInOut = values.worksheetInOut;
    const _trangradWangbillHeadData: TRangradWangbillDetailData = {
      trangradWangbillHeadView: {
        ...selectTRangradWangbillHeadView,
        paid: parserNumber(values.numNetAmount),
        remain: parserNumber(values.numNetAmount),
        tax: parserNumber(values.numTaxAmount),
      },
      tfinanceHead2: {
        ...({} as TFinanceHead2),
        cashamount: parserNumber(values.cashAmount),//จำนวนเงินสด
        financetype: worksheetInOut === "IN" ? "02" : "01", // '02' การชำระเงินใบงานใน, '01' การชำระเงินใบงานนอก
        taxamount: worksheetInOut === "IN" ? 0.00 : parserNumber(values.numTaxAmount),//ภาษี
        customercode: selectTRangradWangbillHeadView.idebitorCode ? selectTRangradWangbillHeadView.idebitorCode : '',//รหัสลูกค้า
        customername: selectTRangradWangbillHeadView.debitorname ? selectTRangradWangbillHeadView.debitorname : '',//ชื่อลูกค้า
        chequeamount: parserNumber(values.chequeAmount),//จำนวนเงินจากเช็ค
        remarks: values.remark,//หมายเหตุการชำระเงิน
        payinamount: parserNumber(values.payinAmount),//จำนวนเงินจากเงินโอน
        paymenttype: '02', // ประเภทใบงาน-01,ใบแจ้งหนี้-02,รายการอื่นๆ-03
        worksheetReturnStatus: values.receiveDocument === 'YES' ? 1 : 0, //การรับเอกสาร '1' ได้รับเอกสาร, '0' ไม่ได้รับเอกสาร
        otheramount: parserNumber(values.otherReceiveAmount),
        creditcardamount: 0.0, // TODO: no CreditCard information
        creditnoteDiscount: parserNumber(values.creditNoteDiscountAmt),//จำนวนเงินจากใบลดหนี้
      },
      tdecimal: {
        ...({} as TDecimal),
        idecimal: decimal.toString()
      },
      tfinanceChequeDetailList: tFinanceChequeDetails,
      tfinancePayinDetailList: tFinancePayinDetails,
      tfinanceOtherWorkDetailList: tFinanceOtherWorkDetails,
      tworksheetRangradWangbillList: tWorksheetRangradWangbillViews,
      tcreditnoteHeadList: tCreditnoteHeads,
    }
    try {
      await tRangradWangbillDetailService.saveTRangradWangbillDetailData(_trangradWangbillHeadData);
      setShowSpin(false);
      showNotification(NotificationType.SUCCESS, "บันทึกรับชำระใบงานเร่งรัดเรียบร้อยแล้ว!");
      form.resetFields();
      setTrigger(false);
      setOnDrawerVisible(false);
    } catch (err) {
      showNotification(NotificationType.ERROR, "เกิดข้อผิดพลาด กรุณาติดต่อผู้แลระบบ !");
      setTrigger(false);
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน");
    setTrigger(false);
  };

  const isValid = () => {
    if (selectTRangradWangbillHeadView.idebitorCode === "") {
      showNotification(NotificationType.ERROR, "ไม่พบข้อมูลลูกค้า");
      return true;
    }

    const receiveDocument = form.getFieldValue('receiveDocument')
    if (!receiveDocument) {
      showNotification(NotificationType.ERROR, "กรุณาระบุการได้รับเอกสารใบงาน");
      return true;
    }

    const numNetTotal = form.getFieldValue('numNetTotal') ? parserNumber(form.getFieldValue('numNetTotal')) : 0;
    const numNetAmount = form.getFieldValue('numNetAmount') ? parserNumber(form.getFieldValue('numNetAmount')) : 0;
    if (numNetTotal !== numNetAmount) {
      showNotification(NotificationType.ERROR, "จำนวนเงินที่รับชำระไม่เท่ากับจำนวนเงินตามใบสั่งงาน");
      return true;
    }
    const creditnoteAmount = form.getFieldValue('creditNoteDiscountAmt') ? parserNumber(form.getFieldValue('creditNoteDiscountAmt')) : 0;
    if (!creditnoteAmount && isCreditNote) {
      showNotification(NotificationType.ERROR, "มีใบงานที่ออกใบลดหนี้ กรุณาระบุใบลดหนี้ให้ถูกต้อง")
      return true;
    }
    return false;
  }

  return (
    <>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Typography.Title level={4}>โปรแกรมการเงินรับชำระเงินเร่งรัด</Typography.Title>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "",
              }}
            >
              <Button
                type="primary"
                danger
                icon={<CloseCircleOutlined />}
                onClick={() => setOnDrawerVisible(false)}>
                ยกเลิก
              </Button>
              <Button
                onClick={() => setTrigger(true)}
                type="primary"
                icon={<SaveOutlined />}
                style={{ marginLeft: "5px" }}
              >
                บันทึก
              </Button>
            </div>
          </div>
        }
        width="90%"
        visible={onDrawerVisible}
        forceRender={true}
        placement="right"
        bodyStyle={{ paddingBottom: 80 }}
        closable={false}
        destroyOnClose={true}
      >
        <Content className="app-page-content">
          <Spin spinning={showSpin}>
            <Form
              form={form}
              name="receiveRangradWorksheet"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              labelCol={{ span: 10 }}
            >
              <Space direction="vertical" size="middle">
                <Descriptions
                  column={6}
                  size="small"
                  bordered
                  style={{ paddingLeft: '10em' }}
                  labelStyle={{ textAlign: "right", fontWeight: "bold" }}
                >
                  <Descriptions.Item label="ชื่อลูกหนี้" span={2}>({selectTRangradWangbillHeadView.idebitorCode}) {selectTRangradWangbillHeadView.debitorname}</Descriptions.Item>
                  <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fff" }}> </Descriptions.Item>
                  <Descriptions.Item label="เลขที่ใบแจ้งหนี้" span={2}>{selectTRangradWangbillHeadView.idebitnoteNo}</Descriptions.Item>
                  <Descriptions.Item label="เบอร์โทร" span={2}>{tDebitorHead.itelephoneNumber} {tDebitorHead.imobilenumber}</Descriptions.Item>
                  <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fff" }}> </Descriptions.Item>
                  <Descriptions.Item label="ยอดค้างชำระ" span={2}>{formatNumber(netTotal)}</Descriptions.Item>
                </Descriptions>
                <Descriptions
                  extra={
                    <Space align='baseline' direction='vertical'>
                      <Typography.Text strong>ประเภทใบแจ้งหนี้</Typography.Text>
                      <Form.Item
                        name="worksheetInOut"
                        style={{ margin: "0px" }}
                      >
                        <Radio.Group
                          style={{ margin: "0px" }}
                          onChange={(e) => onChangeworksheetInOut(e.target.value)}
                        >
                          <Space >
                            <Radio value="IN" style={{ padding: "4px 0px 0px 4px", borderRadius: "3px", backgroundColor: "blue", color: "#FFF" }}>ใบสั่งงานใน</Radio>
                            <Radio value="OUT" style={{ padding: "4px 0px 0px 4px", borderRadius: "3px", backgroundColor: "red", color: "#FFF" }}>ใบสั่งงานนอก</Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </Space>
                  }
                >
                </Descriptions>
                </Space>
                <div style={{marginBottom: 10}}>
                  <Table
                    columns={columns}
                    dataSource={tWorksheetRangradWangbillViews}
                    size="small"
                    scroll={{ x: 1300 }}
                    rowKey={(item: TWorksheetHeadView) => item.ireserveCode!}
                    pagination={false}
                  />
                </div>
                <Row>
                  <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>หมายเหตุ: </Typography.Text>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      name="remark"
                      rules={[{ required: true, message: "กรุณาระบุหมายเหตุ!" }]}
                    >
                      <TextArea
                        rows={2}
                      ></TextArea>
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    <Space align='baseline' direction='vertical'>
                      <Typography.Text strong>รับเอกสารใบงาน</Typography.Text>
                      <Form.Item
                        name="receiveDocument"
                        style={{ margin: "0px" }}
                      >
                        <Radio.Group
                          style={{ margin: "0px" }}
                        //onChange={() => onChangeRdInOut()}
                        >
                          <Radio value="NO" style={{ padding: "4px 0px 0px 4px", borderRadius: "3px", backgroundColor: "blue", color: "#FFF" }}>ไม่ได้รับเอกสาร</Radio>
                          <Radio value="YES" style={{ padding: "4px 0px 0px 4px", borderRadius: "3px", backgroundColor: "red", color: "#FFF" }}>ได้รับเอกสารแล้ว</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Space>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{ textAlign: "right", padding: "0.5em" }}>
                  </Col>
                  <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>ส่วนลด(ใบลดหนี้): </Typography.Text>
                  </Col>
                  <Col span={7}>
                    <Space align='baseline' style={{ width: "80%" }}>
                      <Form.Item
                        name="creditNoteDiscountAmt"
                        style={{ margin: "0 0 3px 0px" }}
                      >
                        <InputNumber
                          style={{ textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }} readOnly
                          controls={false}
                        >
                        </InputNumber>
                      </Form.Item>
                      <Button
                        id='btnCreditNote'
                        disabled={!isCreditNote}
                        onClick={() => setSearchCreditNoteModalVisible(true)}
                        type="primary"
                        icon={<SearchOutlined />}
                      >
                      </Button>
                    </Space>
                  </Col>
                </Row>
              <Row style={{ padding: "10px 0px 0px" }}>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>จำนวนเงินก่อนภาษี: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="numServiceAmt"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เงินสด: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="cashAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                    <Input
                      type="text"
                      style={{ textAlign: "end", color: "#FD1212", width: "100%" }}
                      onKeyDown={(event: any) => textNumber(event)}
                      onChange={recalculate}
                      onFocus={(e: any) => { e.target.select() }}
                      onBlur={(e: any) => { form.setFieldsValue({ cashAmount: formatNumber(parserNumber(e.target.value)) }) }}
                      onPressEnter={(e: any) => { form.setFieldsValue({ cashAmount: formatNumber(parserNumber(e.target.value)) }) }}
                    ></Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
              </Row>

              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ภาษี: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="numVatAmt"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input
                        style={{ border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly ></Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เช็ค: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="chequeAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        style={{ fontWeight: "bold", textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }} readOnly
                      >
                      </InputNumber>
                    </Form.Item>
                    <Button
                      id='btnSelectCheque'
                      onClick={() => setSearchTChequeHeadModalVisible(true)}
                      type="primary"
                      icon={<SearchOutlined />}
                    >
                    </Button>
                    <Button
                      id='btnAddTChequeHead'
                      onClick={() => setAddTChequeHeadModalVisible(true)}
                      type="primary"
                      icon={<PlusSquareOutlined />}
                    >
                    </Button>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เป็นเงิน: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="numTotal"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เงินโอน: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="payinAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        style={{ textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }} readOnly
                      >
                      </InputNumber>
                    </Form.Item>
                    <Button
                      id='btnSelectPayin'
                      onClick={() => setSearchTPayinHeadModalVisible(true)}
                      type="primary"
                      icon={<SearchOutlined />}
                    >
                    </Button>
                    <Button
                      id='btnAddTPayinHead'
                      onClick={() => setAddTPayinHeadModalVisible(true)}
                      type="primary"
                      icon={<PlusSquareOutlined />}
                    >
                    </Button>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>หัก ณ ที่จ่าย: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="numTaxAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                    </Form.Item>
                    <Button
                      id='btnTWshtaxDetail'
                      disabled={isWithholdingTax}
                      onClick={onButtonTWshtaxDetailClicked}
                      type="primary"
                      icon={<PlusSquareOutlined />}
                    >
                    </Button>
                  </Space>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>รายรับอื่นๆ: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="otherReceiveAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        style={{ textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }} readOnly
                        controls={false}
                      >
                      </InputNumber>
                    </Form.Item>
                    <Button
                      id='btnOtherWork'
                      onClick={() => setSearchOtherWorkModalVisible(true)}
                      type="primary"
                      icon={<SearchOutlined />}
                    >
                    </Button>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>คงเหลือต้องชำระ: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="numNetTotal"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input
                        style={{ textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }}
                        readOnly
                      >
                      </Input>
                    </Form.Item>
                    <Button
                      id='buttonRemainUp'
                      disabled={buttonRemainUpDisabled}
                      type="primary"
                      icon={<ArrowUpOutlined />}
                      onClick={onButtonRemainUpClicked}
                    >
                    </Button>
                    <Button
                      id='buttonRemainDown'
                      disabled={buttonRemainDownDisabled}
                      type="primary"
                      icon={<ArrowDownOutlined />}
                      onClick={onButtonRemainDownClicked}
                    >
                    </Button>
                  </Space>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>รวมรับชำระ: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="numNetAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        style={{ textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }}
                        readOnly
                        controls={false}
                      >
                      </InputNumber>
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Content>
      </Drawer>

      <div>
        {addTChequeHeadModalVisible && (
          <AddTChequeHeadModal
            addTChequeHeadModalVisible={addTChequeHeadModalVisible}
            setAddTChequeHeadModalVisible={setAddTChequeHeadModalVisible}
            tChequeHead={tChequeHead}
          ></AddTChequeHeadModal>
        )}
      </div>
      <div>
        {searchTChequeHeadModalVisible && (
          <SearchTChequeHeadModal
            searchTChequeHeadModalVisible={searchTChequeHeadModalVisible}
            setSearchTChequeHeadModalVisible={setSearchTChequeHeadModalVisible}
            tFinanceChequeDetails={tFinanceChequeDetails}
            setTFinanceChequeDetails={setTFinanceChequeDetails}
          ></SearchTChequeHeadModal>
        )}
      </div>
      <div>
        {addTPayinHeadModalVisible && (
          <AddTPayinHeadModal
            addTPayinHeadModalVisible={addTPayinHeadModalVisible}
            setAddTPayinHeadModalVisible={setAddTPayinHeadModalVisible}
            tPayinHead={tPayinHead}
          ></AddTPayinHeadModal>
        )}
      </div>

      <div>
        {searchTPayinHeadModalVisible && (
          <SearchTPayinHeadModal
            searchTPayinHeadModalVisible={searchTPayinHeadModalVisible}
            setSearchTPayinHeadModalVisible={setSearchTPayinHeadModalVisible}
            tFinancePayinDetails={tFinancePayinDetails}
            setTFinancePayinDetails={setTFinancePayinDetails}
          ></SearchTPayinHeadModal>
        )}
      </div>
      <div>
        {searchOtherWorkModalVisible && (
          <SearchOtherWorkSearchModal
            searchOtherWorkModalVisible={searchOtherWorkModalVisible}
            setSearchOtherWorkModalVisible={setSearchOtherWorkModalVisible}
            tFinanceOtherworkdetail={tFinanceOtherworkdetail}
            tFinanceOtherWorkDetails={tFinanceOtherWorkDetails}
            setTFinanceOtherWorkDetails={setTFinanceOtherWorkDetails}
          ></SearchOtherWorkSearchModal>
        )}
      </div>
      <div>
        {searchCreditNoteModalVisible && (
          <SearchCreditNoteDiscountModal
            searchCreditNoteModalVisible={searchCreditNoteModalVisible}
            setSearchCreditNoteModalVisible={setSearchCreditNoteModalVisible}
            iworksheetCode={undefined}
            wshRangradWangbills={tWorksheetRangradWangbillViews}
            tCreditnoteHeads={tCreditnoteHeads}
            setTCreditnoteHeads={setTCreditnoteHeads}
          ></SearchCreditNoteDiscountModal>
        )}
      </div>
      <div>
        {addTWshtaxDetailModalVisible && (
          <AddTWshtaxDetailModal
            addTWshtaxDetailModalVisible={addTWshtaxDetailModalVisible}
            setAddTWshtaxDetailModalVisible={setAddTWshtaxDetailModalVisible}
            tWshtaxDetailView={newTWshtaxDetailView}
            seTWshtaxDetailView={setNewTWshtaxDetailView}
          ></AddTWshtaxDetailModal>
        )}
      </div>
    </>
  )
}

export default FinanceReceivableDrawer