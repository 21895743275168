import { Col, Form, Input, Modal, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { WorksheetHeadData } from 'src/domain/worksheet/view/worksheetHeadData';
import tUserHeadService from 'src/services/tUserHeadService';
import { useAppSelector } from 'src/stores/store';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';

interface IProps {
	checkPermissionModalVisible: boolean;
	setCheckPermissionModalVisible: any;
	setPermissionFlg: any;
	event: any;
	setMenuItemSelectKey: any;
	worksheetHeadData: WorksheetHeadData;
	setWorksheetHeadData: any;
	setTUserHeadUpdate: any;
}
const CheckPermissionModal: React.FC<IProps> = (props) => {
	const { checkPermissionModalVisible, setCheckPermissionModalVisible, 
					setPermissionFlg, event, setMenuItemSelectKey, worksheetHeadData, 
					setWorksheetHeadData,	setTUserHeadUpdate } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  const [form] = Form.useForm();

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);


  const onFinish = async (values: any) => {
		const foundUser = await tUserHeadService.getUserByUserNamePassword(values.userName, values.password);
		if (!foundUser.data) {
			showNotification(NotificationType.ERROR, "ไม่พบข้อมูลผู้ใช้ระบบ");
			setPermissionFlg(false);
			setTrigger(false);
            return;
		} else {
			setTUserHeadUpdate(foundUser.data);
			setCheckPermissionModalVisible(false);
			setPermissionFlg(true);
			setMenuItemSelectKey(event.key);
			setTrigger(false);
		}
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาตรวจสอบข้อมูลให้ครบถ้วน!");
    setTrigger(false);
  };

	const handleCancel = () => {
		setCheckPermissionModalVisible(false);
		setMenuItemSelectKey("");
	}

	return (
		<>
			<Modal
				title={
					<div>
						<Typography.Title level={3}>ระบบตรวจสอบสิทธิ์</Typography.Title>
					</div>
				}
				visible={checkPermissionModalVisible}
				width={350}
				closable={false}
				destroyOnClose
				maskClosable={false}
				onCancel={handleCancel}
                onOk={() => setTrigger(true)}
			>
				<Form
					form={form}
					name="checkPermission"
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					initialValues={{ remember: true }}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
				>
					<Form.Item
						label="User Name"
						name="userName"
						rules={[{ required: true, message: "กรุณาระบุ User Name" }]}
					>
						<Input></Input>
					</Form.Item>
					<Form.Item
						label="Password"
						name="password"
						rules={[{ required: true, message: "กรุณาระบุ Password" }]}
					>
						<Input type="password"></Input>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default CheckPermissionModal