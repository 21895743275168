import http from "../lib/http";
import Constants from "../utils/constants";

const getByReserveCode = (reserveCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetMovement/getByReserveCode/" + reserveCode);
}

const defaultApi = {
  getByReserveCode,
};
export default defaultApi;