import { Modal, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { TRangradWangbillHead } from "src/domain/tRangradWangbillHead";
import { TRangradMovementView } from "src/domain/view/tRangradMovementView";
import tRangradMovementService from "src/services/tRangradMovementService";

interface IProps {
  tRangradMovementModalVisible: boolean;
  setTRangradMovementModalVisible: any;
  tRangradWangbillHead: TRangradWangbillHead;
}

const ReportWorksheeMovementModal: React.FC<IProps> = (props) => {
  const { tRangradMovementModalVisible, setTRangradMovementModalVisible, tRangradWangbillHead } = props;
  const [tRangradMovementViews, setTRangradMovementViews] = useState<TRangradMovementView[]>();
  const [loading, setLoading] = React.useState(true);

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      key: "index",
      align: "center" as "center",
      width: 70,
      render: (value: any, item: TRangradMovementView, index: number) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: "เลขที่ใบแจ้งหนี้",
      dataIndex: "idebitnote",
      key: "idebitnote",
      align: "center" as "center",
      width: 130,
    },
    {
      title: "หมายเหตุ",
      dataIndex: "isystemRemark",
      key: "isystemRemark",
      align: "center" as "center",
    },
    {
      title: "สถานะ",
      dataIndex: "idebitStatus",
      key: "idebitStatus",
      align: "center" as "center",
      render: (value: any, item: TRangradMovementView) => {
        return <span>{getDebitNoteStatus(item.idebitStatus)}</span>;
      },
    },
    {
      title: "พนักงาน",
      dataIndex: "icreateUserFullName",
      key: "icreateUserFullName",
      align: "center" as "center",
      width: 180,
    },
  ];

  const getItemColor = (item: TRangradMovementView) => {
    let foreColor = "";
    if ("98" === item.idebitStatus) {
      foreColor = "red";
    } else {
      foreColor = "black";
    }

    return 'text-' + foreColor;
  }

  const getDebitNoteStatus = (debitNoteStatus: string) => {
    let status = ''
    switch (debitNoteStatus) {
      case "00":
        status = "เร่งรัดออกใบแจ้งหนี้";
        break;
      case "01":
        status = "รับใบแจ้งหนี้กลับยังไม่ได้รับเงิน";
        break;
      case "02":
        status = "รับใบแจ้งหนี้กลับส่งให้การเงิน";
        break;
      case "03":
        status = "ส่งเงินให้การเงินบางส่วน";
        break;
      case "04":
        status = "ส่งเงินให้การเงินเต็มจำนวน";
        break;
      case "98":
        status = "ยกเลิกใบแจ้งหนี้";
        break;
      case "99":
        status = "ชำระเงินแล้ว(จบ)";
        break;
      default:
        status = 'ไม่พบข้อมูล';
        break;
    }

    return status;
  }

  useEffect(() => {
    setLoading(true);
    loadData();
    return () => { };
  }, []);

  const loadData = async () => {
    const tRangradMovementRes = await tRangradMovementService.getByDebitNote(tRangradWangbillHead.idebitnoteNo!);
    if (tRangradMovementRes.data) {
      setTRangradMovementViews(tRangradMovementRes.data);
    }
    setLoading(false);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>ความเคลื่อนไหวใบแจ้งหนี้</Typography.Title>
          </div>
        }
        visible={tRangradMovementModalVisible}
        width={"70%"}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTRangradMovementModalVisible(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >

        <div style={{ margin: 0, minHeight: 350 }}>
          <Table
            columns={columns}
            loading={loading}
            rowClassName={getItemColor}
            scroll={{ y: 360 }}
            dataSource={tRangradMovementViews}
            size="small"
            pagination={false}
            rowKey={(item: TRangradMovementView) => item.iseq}
          ></Table>
        </div>
      </Modal>
    </>
  )
}

export default ReportWorksheeMovementModal