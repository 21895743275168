import { PrinterOutlined } from '@ant-design/icons';
import { Button, Card, Descriptions, Form, Modal, Spin, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { TCreditnoteHeadView } from 'src/domain/view/tCreditnoteHeadView';
import PageHeader from 'src/layouts/PageHeader';
import tCreditnoteHeadService from 'src/services/finance/tCreditnoteHeadService';
import { useAppSelector } from 'src/stores/store';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber } from 'src/utils/numberUtils';

interface IParams {
  id: string;
}

const ViewCreditnote: React.FC<RouteComponentProps<IParams>> = (props) => {
  const creditnoteId = props.match.params.id;
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [creditNotePreviewBlob, setCreditNotePreviewBlob] = useState<any>();
  const [creditNotePreviewData, setCreditNotePreviewData] = useState<any>();
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false);
  const [showAllPage, setShowAllPage] = useState<boolean>(false);
  const [tCreditnoteHeadView, setTCreditnoteHeadView] = useState<TCreditnoteHeadView>({} as TCreditnoteHeadView);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const res = await tCreditnoteHeadService.getTCreditnoteHeadViewData(creditnoteId);
      setTCreditnoteHeadView(res.data);
      calculation(res.data);
      setLoading(false);
    })();
  }, []);

  const calculation = (creditNoteHeadView: TCreditnoteHeadView) => {
    let creditDiscount = creditNoteHeadView.creditDiscount;
    let serviceAfterDiscount = 0;
    let vatAfterDiscount = 0;
    let totalAfterDiscount = 0;
    if (creditNoteHeadView.tworksheetHeadView?.itypetaxCode === "01") {
      serviceAfterDiscount = creditNoteHeadView.tworksheetHeadView?.itotal1Amt! - creditDiscount;
      vatAfterDiscount = Math.round(((serviceAfterDiscount * 7) / 100) * 100) / 100;
    } else if (creditNoteHeadView.tworksheetHeadView?.itypetaxCode === "02") {
      serviceAfterDiscount = creditNoteHeadView.tworksheetHeadView?.itotal2Amt! - creditDiscount;
      vatAfterDiscount = Math.round(((serviceAfterDiscount * 7) / 107) * 100) / 100;
      serviceAfterDiscount = serviceAfterDiscount - vatAfterDiscount;
    } else {
      serviceAfterDiscount = creditNoteHeadView.tworksheetHeadView?.itotal2Amt! - creditDiscount;
      vatAfterDiscount = 0.0;
    }

    totalAfterDiscount = serviceAfterDiscount + vatAfterDiscount;

    setTCreditnoteHeadView({
      ...creditNoteHeadView,
      serviceAfterDiscount: serviceAfterDiscount,
      vatAfterDiscount: vatAfterDiscount,
      totalAfterDiscount: totalAfterDiscount
    });
  }

  const onPrint = async () => {
    setTCreditnoteHeadView({
      ...tCreditnoteHeadView,
      printNumber: tCreditnoteHeadView.printNumber + 1,
    });
    setLoading(true);
    const resultCreditNotePreview = await tCreditnoteHeadService.printPreviewAndSaveCreditnote(tCreditnoteHeadView, showAllPage);
    if (resultCreditNotePreview.data) {
      setCreditNotePreviewBlob(resultCreditNotePreview.data);
      const fileObj = URL.createObjectURL(resultCreditNotePreview.data);
      setCreditNotePreviewData(fileObj);
      setIsPreviewModalVisible(true);
    } else {
      showNotification(
        NotificationType.ERROR,
        "ไม่พบข้อมูลที่เกี่ยวข้อง ไม่สามารถแสดงข้อมูลตัวอย่างได้"
      );
    }
    setLoading(false);
  }

  return (
    <>
      <PageHeader title="" onClose={() => history.push("/app/creditNote/list")} />
      <Spin spinning={loading}>
        <Content className="app-page-content">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              justifyContent: "space-between",
              marginRight: "1rem",
            }}
          >
            <Descriptions
              style={{ margin: "1em" }}
              column={4}
              title="ข้อมูลใบลดหนี้"
              size="middle"
            />
            <Button
              type="primary"
              disabled={!tCreditnoteHeadView}
              icon={<PrinterOutlined />}
              onClick={onPrint}
            >
              พิมพ์ใบลดหนี้
            </Button>
            <Modal
              style={{ top: 20 }}
              width={"90vw"}
              title="CreditNote"
              bodyStyle={{ height: "85vh" }}
              centered={true}
              visible={isPreviewModalVisible}
              okButtonProps={{ style: { display: 'none' } }}
              cancelButtonProps={{ style: { display: 'none' } }}
              onCancel={() => setIsPreviewModalVisible(false)}
            >
              <iframe title=" " src={creditNotePreviewData} width={"100%"} height={"100%"}></iframe>
            </Modal>
          </div>
          <div style={{ margin: "1em" }}>
            <Form
              form={form}
              name="viewCreditnote"
              labelCol={{ span: 10 }}
            >
              <Card
                style={{
                  width: "auto",
                  borderRadius: "10px 10px 10px 10px",
                  margin: "5px 0px 5px 0px",
                }}
              >
                <Typography.Title level={4}>รายละเอียดใบงาน</Typography.Title>
                <Descriptions
                  column={6}
                  size="small"
                  bordered
                  style={{ paddingLeft: '10em' }}
                  labelStyle={{ textAlign: "right", fontWeight: "bold", width:"200px"}}
                >
                  <Descriptions.Item label="เลขที่ใบลดหนี้" span={6}>{tCreditnoteHeadView.id}</Descriptions.Item>
                  <Descriptions.Item label="เลขที่ใบงาน" span={6}>{tCreditnoteHeadView.workcode}</Descriptions.Item>
                  <Descriptions.Item label="ชื่อลูกค้า" span={6}>{tCreditnoteHeadView.customername}</Descriptions.Item>
                  <Descriptions.Item label="ใบกำกับภาษีเล่มที่" span={3}>{tCreditnoteHeadView.bookInVoice}</Descriptions.Item>
                  <Descriptions.Item label="เลขที่" span={3}>{tCreditnoteHeadView.bookNumberInVoice}</Descriptions.Item>
                  <Descriptions.Item label="ประเภทใบงาน" span={3}>{tCreditnoteHeadView.tworksheetHeadView?.itypeworkName}</Descriptions.Item>
                  <Descriptions.Item label="ค่าบริการ" span={3}>{formatNumber(tCreditnoteHeadView.tworksheetHeadView?.itotal1Amt)}</Descriptions.Item>
                  <Descriptions.Item label="ประเภทการชำระ" span={3}>{tCreditnoteHeadView.tworksheetHeadView?.itypepaymentName}</Descriptions.Item>
                  <Descriptions.Item label="ภาษี" span={3}>{formatNumber(tCreditnoteHeadView.tworksheetHeadView?.itaxAmt)}</Descriptions.Item>
                  <Descriptions.Item label="สถานะใบงาน" span={3}>{tCreditnoteHeadView.tworksheetHeadView?.iworksheetStatusName}</Descriptions.Item>
                  <Descriptions.Item label="ค่าบริการรวมภาษี" span={3}>{formatNumber(tCreditnoteHeadView.tworksheetHeadView?.itotal2Amt)}</Descriptions.Item>
                </Descriptions>
              </Card>
              <Card
                style={{
                  width: "auto",
                  borderRadius: "10px 10px 10px 10px",
                  margin: "5px 0px 5px 0px",
                }}
              >
                <Typography.Title level={4}>ข้อมูลลดหนี้</Typography.Title>
                <Descriptions
                  column={6}
                  size="small"
                  bordered
                  style={{ paddingLeft: '10em' }}
                  labelStyle={{ textAlign: "right", fontWeight: "bold", width:"200px" }}
                >
                  <Descriptions.Item label="ส่วนลด" span={3}>{formatNumber(tCreditnoteHeadView.creditDiscount)}</Descriptions.Item>
                  <Descriptions.Item label="ค่าบริการหลังให้ส่วนลด" span={3}>{formatNumber(tCreditnoteHeadView.serviceAfterDiscount)}</Descriptions.Item>
                  <Descriptions.Item label="ภาษีส่วนลด" span={3}>{formatNumber(tCreditnoteHeadView.creditVat)}</Descriptions.Item>
                  <Descriptions.Item label="ภาษี" span={3}>{formatNumber(tCreditnoteHeadView.vatAfterDiscount)}</Descriptions.Item>
                  <Descriptions.Item label="ส่วนลดรวมภาษี" span={3}>{formatNumber(tCreditnoteHeadView.creditTotal)}</Descriptions.Item>
                  <Descriptions.Item label="รวมค่าบริการ" span={3}>{formatNumber(tCreditnoteHeadView.totalAfterDiscount)}</Descriptions.Item>
                  <Descriptions.Item label="ผู้แจ้ง" span={6}>{tCreditnoteHeadView.userReportName}</Descriptions.Item>
                  <Descriptions.Item label="หมายเหตุ" span={6}>{tCreditnoteHeadView.creditRemark}</Descriptions.Item>
                </Descriptions>
              </Card>
            </Form>
          </div>
        </Content>
      </Spin>
    </>
  )
}

export default ViewCreditnote