import http from "src/lib/http";
import Constants from "src/utils/constants";

const getByReserveCode = (reserveCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWshPromiss/getByReserveCode", {
    params:{
      reserveCode
    }
  });
};

const getByReserveCodeAndWorksheetCode = (reserveCode: string, worksheetCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWshPromiss/getByReserveCodeAndWorksheetCode", {
    params:{
      reserveCode,
      worksheetCode
    }
  });
};

const defaultApi = {
  getByReserveCode,
  getByReserveCodeAndWorksheetCode
}
export default defaultApi;