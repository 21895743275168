import { CloseCircleOutlined, ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Drawer, Form, InputNumber, Modal, Row, Select, Tabs, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TChequeHeadView } from 'src/domain/cheque/view/tChequeHeadView';
import { TFinanceTrush } from 'src/domain/finance/tFinanceTrush';
import { TFinanceHead2View } from 'src/domain/finance/view/tFinanceHead2View';
import { TFinanceTrushData } from 'src/domain/finance/view/tFinanceTrushData';
import { RyAccountView } from 'src/domain/view/ryAccountView';
import ryAccountService from 'src/services/bank/ryAccountService';
import tFinanceTrushService from 'src/services/finance/tFinanceTrushService';
import { useAppSelector } from 'src/stores/store';
import Constants from 'src/utils/constants';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber } from 'src/utils/numberUtils';
import CashTrushTab from './tab/CashTrushTab';
import ChequeTrushTab from './tab/ChequeTrushTab';

const { Option } = Select;
const { confirm } = Modal;
const { TabPane } = Tabs;

interface IProps {
  onDrawerVisible: boolean;
  setOnDrawerVisible: any;
}

const TrushMoneyDrawer: React.FC<IProps> = (props) => {
  const { onDrawerVisible, setOnDrawerVisible } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [form] = Form.useForm();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [tfinanceTrushData, setTfinanceTrushData] = useState<TFinanceTrushData>({} as TFinanceTrushData);
  const [ryAccounts, setRyAccounts] = useState<RyAccountView[]>([]); //บัญชีธนาคาร
  const [tfinanceHead2Cashs, setTfinanceHead2Cashs] = useState<TFinanceHead2View[]>([]);//รายการเงินสด
  const [tchequeHeads, setTchequeHeads] = useState<TChequeHeadView[]>([]);//รายการเช็ค
  const [activeTab, setActiveTab] = useState<string>("cashTrushTab");
  const [cashTrushTabActive, setCashTrushTabActive] = useState(false);
  const [chequeTrushTabActive, setChequeTrushTabActive] = useState(false);

  useEffect(() => {
    if (trigger) {
      form.validateFields()
        .then(() => {
          confirm({
            title: "ยืนยัน!",
            icon: <ExclamationCircleOutlined />,
            content: "ต้องการบันทึกฝากเงินหรือไม่?",
            cancelText: "ยกเลิก",
            okText: "ยืนยัน",
            onOk() {
              form.submit();
            },
            onCancel() {
              setTrigger(false);
            }
          });
        }).catch(() => {
          setTrigger(false);
        });
    }
  }, [trigger]);

  useEffect(() => {
    initData();
  }, []);

  const initData = async () => {
    const ryAccountRes = await ryAccountService.getRyAccountViewList();
    setRyAccounts(ryAccountRes.data);

    form.setFieldsValue({
      trushdate: moment(new Date(), Constants.DATE_FORMAT),
      cashAmt: 0,
      chequeAmt: 0,
      totalAmt: 0,
    });
  }

  useEffect(() => {
    let cashSelectedAmt = 0;
    let chequeSelectedAmt = 0;
    if (tfinanceHead2Cashs.length) {
      tfinanceHead2Cashs.forEach((obj: TFinanceHead2View) => {
        cashSelectedAmt += (obj.cashamount) ? obj.cashamount : 0;
      });
    }
    if (tchequeHeads.length) {
      tchequeHeads.forEach((obj: TChequeHeadView) => {
        chequeSelectedAmt += (obj.chequeamt) ? obj.chequeamt : 0;
      });
    }

    form.setFieldsValue({
      cashAmt: cashSelectedAmt,
      chequeAmt: chequeSelectedAmt,
      totalAmt: cashSelectedAmt + chequeSelectedAmt,
    })
  }, [tfinanceHead2Cashs, tchequeHeads]);

  function onchangeTab(key: any) {
    setActiveTab(key);
    if (key === 'cashTrushTab') {
      setCashTrushTabActive(true);
      setCashTrushTabActive(false);
    } else { //chequeTrushTab
      setCashTrushTabActive(false);
      setCashTrushTabActive(true);
    }
  }

  const onFinish = async (values: any) => {
    const ryAccountSelected = (ryAccounts as Array<RyAccountView>).find((obj) => obj.accountnumber === values.accSeq);
    const temp: TFinanceTrushData = {
      ...(tfinanceTrushData as TFinanceTrushData),
      sumCashAmt: values.cashAmt ? values.cashAmt : 0,
      sumChequeAmt: values.chequeAmt ? values.chequeAmt : 0,
      totalAmt: values.totalAmt ? values.totalAmt : 0,
      ryAccount: ryAccountSelected!,
      tfinanceTrush: {
        ...(tfinanceTrushData.tfinanceTrush as TFinanceTrush),
        accseq: Number(ryAccountSelected?.seq),
        remark: values.remark.trim(),
        trushdate: moment(values.trushdate).format("yyyyMMDD"),
        trushusername: userInfo.fullName,
        createuser: userInfo.userId,
        lastuser: userInfo.userId,
        termno: userInfo.termNo,
        savstation: userInfo.saveStation,
        branchid: userInfo.branchId,
      },
      tfinanceHead2CashList: [...tfinanceHead2Cashs],
      tchequeHeadList: [...tchequeHeads],
    };

    try {
      const tfinanceTrushRes = await tFinanceTrushService.saveTfinanceTrushData(temp);
      showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเรียบร้อย!");
      form.resetFields();
      setTrigger(false);
      setOnDrawerVisible(false);
    } catch {
      showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ");
      setTrigger(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน !");
    setTrigger(false);
  };

  return (
    <>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Typography.Title level={4}>โปรแกรมการฝากเงินเข้าธนาคาร</Typography.Title>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "",
              }}
            >
              <Button
                type="primary"
                danger
                icon={<CloseCircleOutlined />}
                onClick={() => setOnDrawerVisible(false)}>
                ยกเลิก
              </Button>
              <Button
                onClick={() => setTrigger(true)}
                type="primary"
                icon={<SaveOutlined />}
                style={{ marginLeft: "5px" }}
              >
                บันทึก
              </Button>
            </div>
          </div>
        }
        width="90%"
        visible={onDrawerVisible}
        forceRender={true}
        placement="right"
        bodyStyle={{ paddingBottom: 80 }}
        closable={false}
        destroyOnClose={true}
      >
        <Content className="app-page-content">
          <Form
            form={form}
            name="trushMoney"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>วันที่นำฝากธนาคาร: </Typography.Text>
                <span style={{ color: "red" }}>*</span>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="trushdate"
                  rules={[{ required: true, message: "กรุณาระบุวันที่นำฝากธนาคาร" }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    style={{ width: "100%" }}
                    placeholder="เลือกวันที่"
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ยอดเงินสด: </Typography.Text>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="cashAmt"
                  style={{ margin: "0 0 3px 0px" }}
                >
                  <InputNumber
                    style={{ fontWeight: "bold", textAlign: "right", width: 200, backgroundColor: "#F7F7F8" }}
                    disabled
                    formatter={formatNumber}
                    parser={parserNumber}
                  ></InputNumber>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>นำเข้าบัญชี: </Typography.Text>
                <span style={{ color: "red" }}>*</span>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="accSeq"
                  rules={[{ required: true, message: "กรุณาระบุนำเข้าบัญชี" }]}
                >
                  <Select
                    showSearch
                    size="middle"
                    placeholder="เลือกธนาคาร"
                    optionFilterProp="children"
                    filterOption={true}
                  >
                    {ryAccounts.map((obj: RyAccountView) => {
                      return (
                        <Option key={obj.accountnumber} value={obj.accountnumber}>
                          {obj.accountnumber} : {obj.accountname}<br />
                          <span style={{ marginLeft: "2rem", fontSize: 12 }}>
                            {obj.bankname} - สาขา{obj.branchname}
                          </span>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ยอดเช็ค: </Typography.Text>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="chequeAmt"
                  style={{ margin: "0 0 3px 0px" }}
                >
                  <InputNumber
                    style={{ fontWeight: "bold", textAlign: "right", width: 200, backgroundColor: "#F7F7F8" }}
                    disabled
                    formatter={formatNumber}
                    parser={parserNumber}
                  ></InputNumber>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>หมายเหตุ: </Typography.Text>
                <span style={{ color: "red" }}>*</span>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="remark"
                  style={{ margin: "0 0 3px 0px" }}
                  validateTrigger="onBlur"
                  rules={[{ required: true, message: "กรุณาระบุหมายเหตุ" }]}
                >
                  <TextArea
                    rows={2}
                  ></TextArea>
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>รวมฝากเงิน: </Typography.Text>
                <span style={{ color: "red" }}>*</span>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="totalAmt"
                  style={{ margin: "0 0 3px 0px" }}
                  rules={[
                    {
                      required: true, message: "กรุณาระบุจำนวนเงินสดหรือ CHEQUE"
                    },
                    {
                      validator: (_, value) => {
                        if (!value || value <= 0) {
                          return Promise.reject(new Error('กรุณาระบุจำนวนเงินสดหรือ CHEQUE ก่อนบันทึก'))
                        } else {
                          return Promise.resolve()
                        }
                      },
                    },]}
                >
                  <InputNumber
                    style={{ fontWeight: "bold", textAlign: "right", width: 200, backgroundColor: "#F7F7F8" }}
                    disabled
                    formatter={formatNumber}
                    parser={parserNumber}
                  ></InputNumber>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Tabs
            type='line'
            activeKey={activeTab}
            onTabClick={onchangeTab}
          >
            <TabPane id="cashTrushTab" tab="เงินสด" key="cashTrushTab">
              <CashTrushTab
                setTfinanceHead2Cashs={setTfinanceHead2Cashs}
              ></CashTrushTab>
            </TabPane>
            <TabPane id="chequeTrushTab" tab="เช็ค" key="chequeTrushTab" >
              <ChequeTrushTab
                setTchequeHeads={setTchequeHeads}
              ></ChequeTrushTab>
            </TabPane>
          </Tabs>
        </Content>
      </Drawer>
    </>
  )
}

export default TrushMoneyDrawer