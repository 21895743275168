import http from "src/lib/http";
import Constants from "src/utils/constants";

const getRyRefsizetruckList = () => {
  return http.get(
    Constants.DEFAULT_URL + "/ryRefsizetruck"
  );
};

const getRyRefsizetruck = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/ryRefsizetruck/" + id);
}

const defaultApi = {
  getRyRefsizetruck,
  getRyRefsizetruckList,
};
export default defaultApi;
