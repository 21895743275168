import { CloseCircleOutlined, DeleteOutlined, ExclamationCircleOutlined, PlusSquareOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Drawer, Form, Input, InputNumber, Modal, Row, Space, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { TChequeHead } from 'src/domain/cheque/tChequeHead';
import { TFinanceChequedetail } from 'src/domain/finance/tFinanceChequedetail';
import { TFinanceHead2 } from 'src/domain/finance/tFinanceHead2';
import { TFinancePayindetail } from 'src/domain/finance/tFinancePayindetail';
import { TOtherpaymentHead } from 'src/domain/tOtherpaymentHead';
import { TPayinHead } from 'src/domain/tPayinHead';
import { TOtherpaymentHeadData } from 'src/domain/view/tOtherpaymentHeadData';
import otherPaymentHeadService from 'src/services/otherpayment/otherPaymentHeadService';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber, textNumber } from 'src/utils/numberUtils';
import AddTChequeHeadModal from '../modals/AddTChequeHeadModal';
import AddTPayinHeadModal from '../modals/AddTPayinHeadModal';
import SearchTChequeHeadModal from '../modals/SearchTChequeHeadModal';
import SearchTPayinHeadModal from '../modals/SearchTPayinHeadModal';
import FinanceOtherWorksheetModal from './FinanceOtherWorksheetModal';

const { confirm } = Modal;


interface IProps {
  onDrawerVisible: boolean;
  setOnDrawerVisible: any;
}

const FinanceOtherWorksheetDrawer: React.FC<IProps> = (props) => {
  const { onDrawerVisible, setOnDrawerVisible } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [form] = Form.useForm();

  const [tOtherPaymentHeads, setTOtherPaymentHeads] = useState<TOtherpaymentHead[]>([]);
  const [tFinanceChequeDetails, setTFinanceChequeDetails] = useState<TFinanceChequedetail[]>([]);
  const [tFinancePayinDetails, setTFinancePayinDetails] = useState<TFinancePayindetail[]>([]);

  const [financeOtherWorksheetModalVisible, setFinanceOtherWorksheetModalVisible] = useState<boolean>(false);
  const [addTChequeHeadModalVisible, setAddTChequeHeadModalVisible] = useState<boolean>(false);
  const [searchTChequeHeadModalVisible, setSearchTChequeHeadModalVisible] = useState<boolean>(false);
  const [addTPayinHeadModalVisible, setAddTPayinHeadModalVisible] = useState<boolean>(false);
  const [searchTPayinHeadModalVisible, setSearchTPayinHeadModalVisible] = useState<boolean>(false);

  const [newTChequeHead, setNewTChequeHead] = useState<TChequeHead>({} as TChequeHead);
  const [newTPayinHead, setNewTPayinHead] = useState<TPayinHead>({} as TPayinHead);

  useEffect(() => {
    if (trigger) {
      if (isValid()) {
        setTrigger(false);
        return;
      }

      form.validateFields()
        .then(() => {
          confirm({
            title: "ยืนยัน!",
            icon: <ExclamationCircleOutlined />,
            content: "คุณต้องการที่จะบันทึกข้อมูลหรือไม่?",
            cancelText: "ยกเลิก",
            okText: "ยืนยัน",
            onOk() {
              form.submit();
            },
            onCancel() {
              setTrigger(false);
            }
          });
        }).catch(() => {
          setTrigger(false);
        });
    }
  }, [trigger]);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: TOtherpaymentHead, index: number) => (<>{index + 1}</>)
    },

    {
      title: "รหัสลูกค้า",
      dataIndex: "icustCode",
      key: "icustCode",
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "icustName",
      key: "icustName",
      width: "auto"
    },
    {
      title: "รหัสรายการ",
      dataIndex: "itypeotherwsh",
      key: "itypeotherwsh",
      width: 120,
      align: "center" as "center",
    },
    {
      title: "รายการชำระเงิน",
      dataIndex: "iremark",
      key: "iremark",
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "iserviceAmt",
      key: "iserviceAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TOtherpaymentHead) => {
        return <span>{formatNumber(item.iserviceAmt)}</span>;
      },
    },
    {
      title: "ภาษี",
      dataIndex: "itaxAmt",
      key: "itaxAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TOtherpaymentHead) => {
        return <span>{formatNumber(item.itaxAmt)}</span>;
      },
    },
    {
      title: "รวมทั้งหมด",
      dataIndex: "itotalAmt",
      key: "itotalAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TOtherpaymentHead) => {
        return <span>{formatNumber(item.itotalAmt)}</span>;
      },
    },
    {
      title: "หมายเหตุ",
      dataIndex: "icomment",
      key: "icomment",
      align: "center" as "center",
    },
    {
      title: "",
      key: "action",
      width: 50,
      align: "center" as "center",
      render: (value: any, item: TOtherpaymentHead, index: number) => {
        return (
          < Button
            type="primary"
            size='small'
            danger
            shape="circle"
            icon={< DeleteOutlined />}
            onClick={() => onDelete(item, index)} />
        );
      },
    },
  ];

  const onDelete = (item: TOtherpaymentHead, index: number) => {
    const array = tOtherPaymentHeads ? [...tOtherPaymentHeads] : [];
    array?.splice(index, 1);
    setTOtherPaymentHeads(reindex(array));
  }

  const onAddFinanceOtherWorksheet = () => {
    if (tOtherPaymentHeads.length > 0) {
      showNotification(NotificationType.ERROR, "มีรายการแล้ว!");
      return
    }
    setFinanceOtherWorksheetModalVisible(true);
  }

  const reindex = (tOtherPaymentHeadTemps: TOtherpaymentHead[]) => {
    return tOtherPaymentHeadTemps.map((item, index) => {
      item.id = index.toString();
      return item;
    });
  }

  const initial = async () => {
    form.setFieldsValue({
      serviceTotal: 0,
      vatTotal: 0,
      amtTotal: 0,
      withHoldingAmt: 0,
      payAmt: 0,
    });
  }


  useEffect(() => {
    if (onDrawerVisible) {
      initial();
    }
  }, [onDrawerVisible]);

  useEffect(() => {
    if (tOtherPaymentHeads.length > 0) {
      recalculate();

      const totherpaymentHead = tOtherPaymentHeads[0];
      setNewTChequeHead({ financetype: '03', customercode: totherpaymentHead.icustCode! } as TChequeHead);
      setNewTPayinHead({ financetype: '03', customercode: totherpaymentHead.icustCode! } as TPayinHead);
    }
  }, [tOtherPaymentHeads]);

  useEffect(() => {
    if (!searchTChequeHeadModalVisible) {
      let chequeAmount = 0;
      if (tFinanceChequeDetails.length > 0) {
        tFinanceChequeDetails.forEach(obj => {
          chequeAmount += (obj.amount) ? obj.amount : 0;
        });
      }
      form.setFieldsValue({
        chequeAmount: formatNumber(chequeAmount),
      });
    }
  }, [searchTChequeHeadModalVisible]);

  useEffect(() => {
    if (!searchTPayinHeadModalVisible) {
      let payinAmount = 0;
      if (tFinancePayinDetails.length > 0) {
        tFinancePayinDetails.forEach(obj => {
          payinAmount += (obj.amount) ? obj.amount : 0;
        });
      }
      form.setFieldsValue({
        payinAmount: formatNumber(payinAmount),
      });
    }
  }, [searchTPayinHeadModalVisible]);

  const recalculate = () => {
    let dServiceTotal = 0.0;
    let dVatTotal = 0.0;
    let dAmtTotal = 0.0;
    if (tOtherPaymentHeads && tOtherPaymentHeads.length > 0) {
      tOtherPaymentHeads.forEach((item) => {
        dServiceTotal += (item.iserviceAmt ? item.iserviceAmt : 0)
        dVatTotal += (item.itaxAmt ? item.itaxAmt : 0)
        dAmtTotal += (item.itotalAmt ? item.itotalAmt : 0)
      })
    }
    form.setFieldsValue({
      serviceTotal: formatNumber(dServiceTotal),
      vatTotal: formatNumber(dVatTotal),
      amtTotal: formatNumber(dAmtTotal),
    });
  }

  const onFinish = async (values: any) => {
    const totherpaymentHead = tOtherPaymentHeads[0];
    const _tOtherpaymentHeadData: TOtherpaymentHeadData = {
      totherpaymentHead: {
        ...totherpaymentHead,
        istatus: '00'
      },
      tfinanceHead2: {
        ...({} as TFinanceHead2),
        cashamount: parserNumber(values.payAmt),//จำนวนเงินสด
        financetype: '03',
        taxamount: parserNumber(values.vatTotal),//ภาษี
        customercode: totherpaymentHead.icustCode ? totherpaymentHead.icustCode : '',//รหัสลูกค้า
        customername: totherpaymentHead.icustName ? totherpaymentHead.icustName : '',//ชื่อลูกค้า
        chequeamount: parserNumber(values.chequeAmount),//จำนวนเงินจากเช็ค
        remarks: totherpaymentHead.iremark,//หมายเหตุการชำระเงิน
        payinamount: parserNumber(values.payinAmount),//จำนวนเงินจากเงินโอน
        paymenttype: '03', // ประเภทใบงาน-01,ใบแจ้งหนี้-02,รายการอื่นๆ-03
        worksheetReturnStatus: 1,
        otheramount: parserNumber(values.payinAmount),
      },
      tfinanceChequedetails: tFinanceChequeDetails,
      tfinancePayindetails: tFinancePayinDetails,
    }
    try {
      await otherPaymentHeadService.saveOtherPaymentHeadData(_tOtherpaymentHeadData);
      showNotification(NotificationType.SUCCESS, "บันทึกการเงินรับชำระจากรายการอื่นๆเรียบร้อยแล้ว!");
      form.resetFields();
      setTrigger(false);
      setOnDrawerVisible(false);
    } catch (err) {
      showNotification(NotificationType.ERROR, "เกิดข้อผิดพลาด กรุณาติดต่อผู้แลระบบ !");
      setTrigger(false);
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน");
    setTrigger(false);
  };

  const isValid = () => {
    const amtTotal = form.getFieldValue('amtTotal') ? parserNumber(form.getFieldValue('amtTotal')) : 0;
    const payAmt = form.getFieldValue('payAmt') ? parserNumber(form.getFieldValue('payAmt')) : 0;
    const chequeAmount = form.getFieldValue('chequeAmount') ? parserNumber(form.getFieldValue('chequeAmount')) : 0;
    const payinAmount = form.getFieldValue('payinAmount') ? parserNumber(form.getFieldValue('payinAmount')) : 0;
    if (amtTotal !== (payAmt + chequeAmount + payinAmount)) {
      showNotification(NotificationType.ERROR, "จำนวนเงินรับชำระไม่ถูกต้อง!");
      return true;
    }
    return false;
  }

  return (
    <>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Typography.Title level={4}>โปรแกรมการเงินรับชำระจากรายการอื่นๆ</Typography.Title>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "",
              }}
            >
              <Button
                type="primary"
                danger
                icon={<CloseCircleOutlined />}
                onClick={() => setOnDrawerVisible(false)}>
                ยกเลิก
              </Button>
              <Button
                onClick={() => setTrigger(true)}
                type="primary"
                icon={<SaveOutlined />}
                style={{ marginLeft: "5px" }}
              >
                บันทึก
              </Button>
            </div>
          </div>
        }
        width="90%"
        visible={onDrawerVisible}
        forceRender={true}
        placement="right"
        bodyStyle={{ paddingBottom: 80 }}
        closable={false}
        destroyOnClose={true}
      >
        <Content className="app-page-content">
          <Form
            form={form}
            name="receiveCashWorksheet"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            labelCol={{ span: 10 }}
          >
            <Row style={{ padding: "5px 0px 0px" }}>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ค่าบริการ: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="serviceTotal"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                  </Form.Item>
                  <Typography.Text> บาท</Typography.Text>
                </Space>
              </Col>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เงินสด: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="payAmt"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input
                      type="text"
                      style={{ textAlign: "end", color: "#FD1212", width: "99%" }}
                      onKeyDown={(event: any) => textNumber(event)}
                      onFocus={(e: any) => { e.target.select() }}
                      onBlur={(e: any) => { form.setFieldsValue({ payAmt: formatNumber(parserNumber(e.target.value)) }) }}
                      onPressEnter={(e: any) => { form.setFieldsValue({ payAmt: formatNumber(parserNumber(e.target.value)) }) }}
                    ></Input>
                  </Form.Item>
                  <Typography.Text> บาท</Typography.Text>
                </Space>
              </Col>
            </Row>

            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ภาษี: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="vatTotal"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input
                      style={{ border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly ></Input>
                  </Form.Item>
                  <Typography.Text> บาท</Typography.Text>
                </Space>
              </Col>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เช็ค: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="chequeAmount"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <InputNumber
                      style={{ fontWeight: "bold", textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }} readOnly
                    >
                    </InputNumber>
                  </Form.Item>
                  <Button
                    id='btnSelectCheque'
                    disabled={(tOtherPaymentHeads.length === 0)}
                    onClick={() => setSearchTChequeHeadModalVisible(true)}
                    type="primary"
                    icon={<SearchOutlined />}
                  >
                  </Button>
                  <Button
                    id='btnAddTChequeHead'
                    disabled={(tOtherPaymentHeads.length === 0)}
                    onClick={() => setAddTChequeHeadModalVisible(true)}
                    type="primary"
                    icon={<PlusSquareOutlined />}
                  >
                  </Button>
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>หักภาษี: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="withHoldingAmt"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                  </Form.Item>
                  <Typography.Text> บาท</Typography.Text>
                </Space>
              </Col>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เงินโอน: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="payinAmount"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <InputNumber
                      style={{ textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }} readOnly
                    >
                    </InputNumber>
                  </Form.Item>
                  <Button
                    id='btnSelectPayin'
                    disabled={(tOtherPaymentHeads.length === 0)}
                    onClick={() => setSearchTPayinHeadModalVisible(true)}
                    type="primary"
                    icon={<SearchOutlined />}
                  >
                  </Button>
                  <Button
                    id='btnAddTPayinHead'
                    disabled={(tOtherPaymentHeads.length === 0)}
                    onClick={() => setAddTPayinHeadModalVisible(true)}
                    type="primary"
                    icon={<PlusSquareOutlined />}
                  >
                  </Button>
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>รวมทั้งหมด: </Typography.Text>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="amtTotal"
                  style={{ margin: "0 0 3px 0px" }}
                >
                  <InputNumber
                    style={{ textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }} readOnly
                  >
                  </InputNumber>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Content>
        <Divider />
        <Content className="app-page-content">
          <Button
            size="small"
            type="primary"
            icon={<PlusSquareOutlined />}

            onClick={onAddFinanceOtherWorksheet}
          >
            เพิ่ม
          </Button>
          <Table
            columns={columns}
            dataSource={tOtherPaymentHeads}
            size="small"
            rowKey={(item: TOtherpaymentHead) => item.id!}
            pagination={false}
          />
        </Content>
      </Drawer>

      <div>
        {financeOtherWorksheetModalVisible && (
          <FinanceOtherWorksheetModal
            financeOtherWorksheetModalVisible={financeOtherWorksheetModalVisible}
            setFinanceOtherWorksheetModalVisible={setFinanceOtherWorksheetModalVisible}
            setTOtherPaymentHeads={setTOtherPaymentHeads}
          ></FinanceOtherWorksheetModal>
        )}
      </div>
      <div>
        {addTChequeHeadModalVisible && (
          <AddTChequeHeadModal
            addTChequeHeadModalVisible={addTChequeHeadModalVisible}
            setAddTChequeHeadModalVisible={setAddTChequeHeadModalVisible}
            tChequeHead={newTChequeHead}
          ></AddTChequeHeadModal>
        )}
      </div>
      <div>
        {searchTChequeHeadModalVisible && (
          <SearchTChequeHeadModal
            searchTChequeHeadModalVisible={searchTChequeHeadModalVisible}
            setSearchTChequeHeadModalVisible={setSearchTChequeHeadModalVisible}
            tFinanceChequeDetails={tFinanceChequeDetails}
            setTFinanceChequeDetails={setTFinanceChequeDetails}
          ></SearchTChequeHeadModal>
        )}
      </div>
      <div>
        {addTPayinHeadModalVisible && (
          <AddTPayinHeadModal
            addTPayinHeadModalVisible={addTPayinHeadModalVisible}
            setAddTPayinHeadModalVisible={setAddTPayinHeadModalVisible}
            tPayinHead={newTPayinHead}
          ></AddTPayinHeadModal>
        )}
      </div>

      <div>
        {searchTPayinHeadModalVisible && (
          <SearchTPayinHeadModal
            searchTPayinHeadModalVisible={searchTPayinHeadModalVisible}
            setSearchTPayinHeadModalVisible={setSearchTPayinHeadModalVisible}
            tFinancePayinDetails={tFinancePayinDetails}
            setTFinancePayinDetails={setTFinancePayinDetails}
          ></SearchTPayinHeadModal>
        )}
      </div>
    </>
  )
}

export default FinanceOtherWorksheetDrawer