import { Button, Modal, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { TOtherpaymentHead } from 'src/domain/tOtherpaymentHead';
import otherPaymentHeadService from 'src/services/otherpayment/otherPaymentHeadService';
import { toThDate } from 'src/utils/dateTimeUtils';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber } from 'src/utils/numberUtils';


interface IProps {
  lookupTOtherPaymentHeadModalVisible: boolean;
  setLookupTOtherPaymentHeadModalVisible: any;
  lookupTOtherPaymentHead: TOtherpaymentHead;
  lookupTOtherPaymentHeadList: TOtherpaymentHead[];
  setLookupTOtherPaymentHeadSelected: any;
}

const LookupOtherPaymentHeadModal: React.FC<IProps> = (props) => {
  const { lookupTOtherPaymentHeadModalVisible, setLookupTOtherPaymentHeadModalVisible, lookupTOtherPaymentHead, lookupTOtherPaymentHeadList, setLookupTOtherPaymentHeadSelected } = props
  const [page, setPage] = useState(1);
  const [loading, setLoading] = React.useState(false);
  const [otherPaymentHeads, setOtherPaymentHeads] = useState<TOtherpaymentHead[]>([]);

  useEffect(() => {
    setLoading(true);
    initial();
  }, []);

  const initial = async () => {
    let otherPaymentHeadResult: TOtherpaymentHead[];
    if (lookupTOtherPaymentHead.icustCode) {// load by customer code
      const res = await otherPaymentHeadService.getOtherPaymentHeadByCustCode(lookupTOtherPaymentHead.icustCode);
      otherPaymentHeadResult = res.data as TOtherpaymentHead[];
    } else {// load by debitnote code
      const res = await otherPaymentHeadService.getOtherPaymentHeadByDebitorCode(lookupTOtherPaymentHead.idebitorCode);
      otherPaymentHeadResult = res.data as TOtherpaymentHead[];
    }
    setLoading(false);

    if (otherPaymentHeadResult.length > 0) {
      const filteredList = otherPaymentHeadResult.filter((item) => !lookupTOtherPaymentHeadList.find((selectedItem) => item.id === selectedItem.id));
      setOtherPaymentHeads(filteredList);
    } else {
      showNotification(NotificationType.ERROR, "ลูกค้าไม่มีข้อมูลการชำระเงินจากรายการอื่นๆ");
    }
  }

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: TOtherpaymentHead, index: number) => (
        <>{(page - 1) * 15 + index + 1}</>
      ),
    },
    {
      title: "เลขที่ใบรายการ",
      dataIndex: "code",
      key: "code",
      width: 40,
      align: "center" as "center",
      render: (value: string, item: TOtherpaymentHead) => (
        <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.id}</Button>
      )
    },
    {
      title: "รายการชำระ",
      dataIndex: "iremark",
      key: "iremark",
      width: 150,
      align: "center" as "center",
    },
    {
      title: "ราคารายการ",
      dataIndex: "serviceAmt",
      key: "serviceAmt",
      width: 80,
      align: "right" as "right",
      render: (value: any, item: TOtherpaymentHead) => {
        return <span>{formatNumber(item.iserviceAmt)}</span>;
      },
    },
    {
      title: "ภาษี",
      dataIndex: "taxAmt",
      key: "taxAmt",
      width: 80,
      align: "right" as "right",
      render: (value: any, item: TOtherpaymentHead) => {
        return <span>{formatNumber(item.itaxAmt)}</span>;
      },
    },
    {
      title: "รวมทั้งหมด",
      dataIndex: "totalAmt",
      key: "totalAmt",
      width: 80,
      align: "right" as "right",
      render: (value: any, item: TOtherpaymentHead) => {
        return <span>{formatNumber(item.itotalAmt)}</span>;
      },
    },
    {
      title: "ยอดคงเหลือ",
      dataIndex: "remainAmt",
      key: "remainAmt",
      width: 80,
      align: "right" as "right",
      render: (value: any, item: TOtherpaymentHead) => {
        return <span>{formatNumber(item.iremainAmt)}</span>;
      },
    },
    {
      title: "วันเดือนปี",
      dataIndex: "updateDate",
      key: "updateDate",
      width: 80,
      render: (value: any, item: TOtherpaymentHead) => {
        return <span>{item.iupdateDate ? toThDate(item.iupdateDate.toString()) : ""}</span>;
      },
    },
    {
      title: "หมายเหตุ",
      dataIndex: "icomment",
      key: "icomment",
      width: 150,
    },
  ]

  const onItemCliked = (item: TOtherpaymentHead) => {
    setLookupTOtherPaymentHeadSelected(item);
    setLookupTOtherPaymentHeadModalVisible(false);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>รายการรับเงินอื่นๆ</Typography.Title>
          </div>
        }
        visible={lookupTOtherPaymentHeadModalVisible}
        width={"65%"}
        style={{ minHeight: "70%" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setLookupTOtherPaymentHeadModalVisible(false)}
      >
        <Table
          columns={columns}
          dataSource={otherPaymentHeads}
          size="small"
          style={{ minHeight: 200 }}
          loading={loading}
          rowKey={(item: TOtherpaymentHead) => item.id}
          pagination={{
            pageSize: 15,
            onChange(current) {
              setPage(current);
            }
          }}
        />
      </Modal>
    </>
  )
}

export default LookupOtherPaymentHeadModal