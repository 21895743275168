import http from "src/lib/http";
import Constants from "src/utils/constants";

const getTTypeInsurancePaymentRefList = () => {
  return http.get(Constants.DEFAULT_URL + "/tTypeInsurancePaymentRef");
};

const findById = (iid: string) => {
  return http.get(Constants.DEFAULT_URL + "/tTypeInsurancePaymentRef/" + iid);
};

const defaultApi = {
  getTTypeInsurancePaymentRefList,
  findById,
};
export default defaultApi;