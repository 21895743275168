import { Form, Modal, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';

interface IProps {
  onModalRemarkVisible: boolean;
  setOnModalRemarkVisible: any;
  setRemark: any;
  setPageStage: any;
}

const CancelRemarkModal: React.FC<IProps> = (props) => {
  const { onModalRemarkVisible, setOnModalRemarkVisible, setRemark, setPageStage } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  const onFinish = (values: any) => {
    try {
      setRemark(values.remark);
      setPageStage("DEL");
      setOnModalRemarkVisible(false);
      form.resetFields();
      setTrigger(false);
    } catch (err) {
      setTrigger(false);
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    setTrigger(false);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>หมายเหตุการยกเลิก</Typography.Title>
          </div>
        }
        visible={onModalRemarkVisible}
        width={"50%"}
        style={{ minHeight: "70%" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setOnModalRemarkVisible(false)}
      >
        <Form
          form={form}
          name="cancelremark"
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="หมายเหตุ"
            name="remark"
            rules={[{ required: true, message: "กรุณาระบุเหตุผล !" },
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject(showNotification(NotificationType.ERROR, "กรุณาระบุเหตุผล"))
                } else {
                  return Promise.resolve()
                }
              },
            },
            ]}
          >
            <TextArea
              rows={4}
            ></TextArea>
          </Form.Item>
        </Form>
      </Modal >
    </>
  )
}

export default CancelRemarkModal