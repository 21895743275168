import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Form, Input, Modal, Select, Spin, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { TFinanceTrushView } from 'src/domain/finance/view/tFinanceTrushView';
import { RyAccountView } from 'src/domain/view/ryAccountView';
import ryAccountService from 'src/services/bank/ryAccountService';
import { useAppSelector } from 'src/stores/store';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';

const { Option } = Select;
const { confirm } = Modal;

interface IProps {
  onChangeAccseqModalVisible: boolean;
  setOnChangeAccseqModalVisible: any;
  tfinanceTrushViewSelected: TFinanceTrushView;
  setTfinanceTrushViewSelected: any;
  setRyAccountSelected: any;
  setIsUpdate: any;
}

const ChangeAccountModal: React.FC<IProps> = (props) => {
  const { onChangeAccseqModalVisible,
    setOnChangeAccseqModalVisible,
    tfinanceTrushViewSelected,
    setTfinanceTrushViewSelected,
    setRyAccountSelected,
    setIsUpdate } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<boolean>(false);
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [ryAccounts, setRyAccounts] = useState<RyAccountView[]>([]); //บัญชีธนาคาร

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (trigger) {
      form.validateFields()
        .then(() => {
          confirm({
            title: "ยืนยัน!",
            icon: <ExclamationCircleOutlined />,
            content: "ต้องการบันทึกการเปลี่ยนบัญชีหรือไม่ ?",
            cancelText: "ยกเลิก",
            okText: "ยืนยัน",
            onOk() {
              form.submit();
              setIsUpdate(true);
            },
            onCancel() {
              setTrigger(false);
              setIsUpdate(false);
            }
          });
        }).catch(() => {
          setTrigger(false);
          setIsUpdate(false);
        });
    }
  }, [trigger]);

  const initData = async () => {
    setLoading(true)
    const ryAccountRes = await ryAccountService.getRyAccountViewList();
    setRyAccounts(ryAccountRes.data);

    form.setFieldsValue({
      currentAccountNumber: tfinanceTrushViewSelected.accountNumber,
      currentAccountName: tfinanceTrushViewSelected.accountName,
      newAccSeq: undefined,
      remark: undefined,
    });

    setLoading(false);
  }

  const onFinish = async (values: any) => {
    const ryAccountSelected = (ryAccounts as Array<RyAccountView>).find((obj) => obj.seq === values.newAccSeq);
    setTfinanceTrushViewSelected({
      ...tfinanceTrushViewSelected,
      accseq: values.newAccSeq,
      remark: values.remark.trim(),
      accountNumber: ryAccountSelected?.accountnumber,
      accountName: ryAccountSelected?.accountname,
      lastuser: userInfo.userId,
      termno: userInfo.termNo,
      savstation: userInfo.saveStation,
    });

    setRyAccountSelected(ryAccountSelected);
    setOnChangeAccseqModalVisible(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน !");
    setTrigger(false);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>เปลี่ยนเลขที่บัญชีธนาคาร</Typography.Title>
          </div>
        }
        visible={onChangeAccseqModalVisible}
        width={"35%"}
        style={{ minHeight: "40%" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => {
          setIsUpdate(false);
          setOnChangeAccseqModalVisible(false)
        }}
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            labelCol={{ span: 5 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="บัญชีเดิม"
              name="currentAccountNumber"
              style={{ marginBottom: 3 }}>
              <Input
                disabled
              ></Input>
            </Form.Item>
            <Form.Item
              label="ชื่อบัญชีเดิม"
              name="currentAccountName"
              style={{ marginBottom: 3 }}>
              <Input
                disabled
              ></Input>
            </Form.Item>
            <Form.Item
              label="เลขที่บัญชีใหม่"
              name="newAccSeq"
              style={{ marginBottom: 3 }}
              rules={[
                { required: true, message: "กรุณาเลือกเลขที่บัญชีใหม่" },
                {
                  validator: (_, value) => {
                    if (value && value === tfinanceTrushViewSelected.accseq) {
                      return Promise.reject(new Error('ไม่มีการเปลี่ยนแปลง'))
                    } else {
                      return Promise.resolve()
                    }
                  },
                },
              ]}
            >
              <Select
                showSearch
                size="middle"
                placeholder="เลือกธนาคาร"
                optionFilterProp="children"
                filterOption={true}
              >
                {ryAccounts.map((obj: RyAccountView) => {
                  return (
                    <Option key={obj.accountnumber} value={obj.seq}>
                      {obj.accountnumber} : {obj.accountname}<br />
                      <span style={{ marginLeft: "2rem", fontSize: 12 }}>
                        {obj.bankname} - สาขา{obj.branchname}
                      </span>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label="หมายเหตุ"
              name="remark"
              style={{ marginBottom: 3 }}
              rules={[
                {
                  validator: (_, value) => {
                    if (!value && form.getFieldValue("newAccSeq") !== tfinanceTrushViewSelected.accseq) {
                      return Promise.reject(new Error('กรุณาระบุหมายเหตุ'))
                    } else {
                      return Promise.resolve()
                    }
                  },
                },
              ]}
            >
              <TextArea
                rows={2}
              ></TextArea>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  )
}

export default ChangeAccountModal