import { PlusSquareOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row, Space, Table, Tooltip, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TWorksheetReturnData } from 'src/domain/view/tWorksheetReturnData';
import PageHeader from 'src/layouts/PageHeader';
import tWorksheetReturnService from 'src/services/tWorksheetReturnService';
import { useAppSelector } from 'src/stores/store';
import Constants from 'src/utils/constants';
import { toThDate } from 'src/utils/dateTimeUtils';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumberInt } from 'src/utils/numberUtils';
import TWorksheetReturnDrawer from './TWorksheetReturnDrawer';

const WorksheetReturnList: React.FC<TWorksheetReturnData> = () => {
  const [page, setPage] = React.useState(1);
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [onDrawerVisible, setOnDrawerVisible] = useState<boolean>(false);
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [tWorksheetReturns, setTWorksheetReturns] = useState<TWorksheetReturnData[]>([]);

  function showWorksheetCode(item: any) {
    return (
      item.map((obj: string) => {
        return (
          <span>{obj}<br></br></span>
        );
      })
    );
  }

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 50,
      render: (value: number, item: TWorksheetReturnData, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
    },
    {
      title: "เลขที่กลุ่มการรับ",
      dataIndex: "ireturnNo",
      key: "ireturnNo",
      width: 150,
      render: (value: any, item: TWorksheetReturnData) => (
        <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.tworksheetreturnHeadView.ireturnNo}</Button>
      )
    },
    {
      title: "วันที่รับใบงานกลับ",
      dataIndex: "ientryDate",
      key: "ientryDate",
      width: 150,
      render: (value: any, item: TWorksheetReturnData) => (<>{toThDate(item.tworksheetreturnHeadView.ientryDate)}</>)
    },
    {
      title: "จำนวนใบงาน",
      dataIndex: "worksheetQty",
      key: "worksheetQty",
      width: 150,
      align: "center" as "center",
      render: (value: any, item: TWorksheetReturnData) => (<>{formatNumberInt(item.worksheetQty)}</>)
    },
    {
      title: "เงินสด(ใบ)",
      dataIndex: "worksheetSendfinanceQty",
      key: "worksheetSendfinanceQty",
      width: 150,
      align: "center" as "center",
      render: (value: any, item: TWorksheetReturnData) => {
        if (item.worksheetCodeSendfinanceList.length > 0) {
          return (
            <Tooltip placement="bottomRight" title={() => showWorksheetCode(item.worksheetCodeSendfinanceList)} >
              <Typography style={{ color: "red" }}>{item.worksheetSendfinanceQty}</Typography>
            </Tooltip>);
        } else {
          <Typography style={{ color: "red" }}>{item.worksheetSendfinanceQty}</Typography>
        }
      },
    },
    {
      title: "เร่งรัด(ใบ)",
      dataIndex: "worksheetSendRengradQty",
      key: "worksheetSendRengradQty",
      width: 150,
      align: "center" as "center",
      render: (value: any, item: TWorksheetReturnData) => {
        if (item.worksheetCodeSendRengradList.length > 0) {
          return (
            <Tooltip placement="bottomRight" title={() => showWorksheetCode(item.worksheetCodeSendRengradList)} >
              <Typography style={{ color: "red" }}>{formatNumberInt(item.worksheetSendRengradQty)}</Typography>
            </Tooltip>);
        } else {
          <Typography style={{ color: "red" }}>{item.worksheetSendRengradQty}</Typography>
        }
      },
    },
    {
      title: "ผู้บันทึก",
      dataIndex: "icreateUserName",
      key: "icreateUserName",
      width: 150,
      render: (value: any, item: TWorksheetReturnData) => (<>{item.tworksheetreturnHeadView.icreateUserName}</>)
    },
  ];

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    form.setFieldsValue({
      fromDate: moment(new Date(), Constants.DATE_FORMAT),
      toDate: moment(new Date(), Constants.DATE_FORMAT)
    });
    form.submit()
  }, []);

  const onItemCliked = (item: TWorksheetReturnData) => {
    history.replace("/app/worksheetReturn/view/" + item.tworksheetreturnHeadView.ireturnNo);
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    const branchId = userInfo.branchId;
    let worksheetCode: string = values.worksheetCode ? values.worksheetCode : "";
    let fromDate: string = values.fromDate ? moment(values.fromDate).format(Constants.DB_FORMAT) : "";
    let toDate: string = values.toDate ? moment(values.toDate).format(Constants.DB_FORMAT) : "";

    if (worksheetCode && worksheetCode.length >= 7) {
      fromDate = "";
      toDate = "";
    } else if (worksheetCode.length > 0 && worksheetCode.length < 7) {
      showNotification(NotificationType.ERROR, "กรุณาป้อนเลขที่ใบงานให้มากกว่า 7 ตัวอักษร");
      form.setFieldsValue({
        worksheetCode: "",
      });
      return;
    }

    const res = await tWorksheetReturnService.getWorksheetReturns(branchId, worksheetCode, fromDate, toDate);
    setTWorksheetReturns(res.data);
    setLoading(false);
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  return (
    <>
      <PageHeader title="โปรแกรมรับใบสั่งงานกลับ">
        <Button
          size="small"
          type="primary"
          icon={<PlusSquareOutlined />}
          onClick={() => setOnDrawerVisible(true)}
        >
          เพิ่ม
        </Button>
      </PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="worksheetReturn"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
              borderBlockEnd: "2px solid #c9c9c9",
            }}
          >
            <Row>
              <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เลขที่ใบงาน: </Typography.Text>
              </Col>
              <Col span={21}>
                <Form.Item
                  name="worksheetCode"
                  rules={[{ required: false }]}
                >
                  <Input
                    allowClear
                    style={{
                      width: 378,
                    }}
                    placeholder="ป้อนเลขที่ใบงาน"
                    onKeyDown={(evt: any) => {
                      if (evt.keyCode === 13) {
                        form.submit();
                      }
                    }}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>วันที่รับใบสั่งงานกลับ ตั้งแต่: </Typography.Text>
              </Col>
              <Col span={21}>
                <Space align="baseline" size={"large"}>
                  <Form.Item
                    name="fromDate"
                    rules={[{ required: false }]}
                  >
                    <DatePicker
                      format={Constants.DATE_FORMAT}
                      size="middle"
                      placeholder="เลือกวันที่"
                      onChange={form.submit}
                    />
                  </Form.Item>
                  <Form.Item
                    label="ถึง"
                    name="toDate"
                    rules={[{ required: false }]}
                  >
                    <DatePicker
                      format={Constants.DATE_FORMAT}
                      size="middle"
                      placeholder="เลือกวันที่"
                      onChange={form.submit}
                    />
                  </Form.Item>
                </Space>
              </Col>
            </Row>
          </div>
        </Form>
        <div>
          <Table
            columns={columns}
            dataSource={tWorksheetReturns}
            size="small"
            loading={loading}
            pagination={{
              pageSize: 15,
              onChange(current) {
                setPage(current);
              }
            }}
            rowKey={(item: TWorksheetReturnData) => item.tworksheetreturnHeadView.ireturnNo!}
            onRow={(item: TWorksheetReturnData) => {
              return {
                onDoubleClick: () => onItemCliked(item),
              };
            }}
          />
        </div>
      </Content>
      <div>
        {onDrawerVisible && (
          <TWorksheetReturnDrawer
            onDrawerVisible={onDrawerVisible}
            setOnDrawerVisible={setOnDrawerVisible}
          ></TWorksheetReturnDrawer>
        )}
      </div>
    </>
  )
}

export default WorksheetReturnList