import { Button, Form, Input, Modal, Space, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { TWorksheetSteelHeadView } from "src/domain/worksheet/view/tWorksheetSteelHeadView";
import { useAppSelector } from "src/stores/store";
import tWorksheetSteelHeadService from "../../services/tWorksheetSteelHeadService";

interface IParams {
  setSelectedSteelDischarge: any;
  visibleSteelUnDischargeModal: boolean;
  setVisibleSteelUnDischargeModal: any;
}

const SteelUnDischargeModal: React.FC<IParams> = (props) => {
  const { setSelectedSteelDischarge, visibleSteelUnDischargeModal, setVisibleSteelUnDischargeModal, } = props;
  const [tWorksheetSteelDischarges, setTWorksheetSteelDischarges] = useState<TWorksheetSteelHeadView[]>([]);
  const [page, setPage] = React.useState(1);
  const [form] = Form.useForm();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [loading, setLoading] = React.useState(false);


  useEffect(() => {
    loadTWorksheetSteelDischarges("");
  }, []);

  const loadTWorksheetSteelDischarges = async (worksheetCode: string) => {
    setLoading(true);
    const branchId = userInfo.branchId;
    const res = await tWorksheetSteelHeadService.getWorksheetSteelUnDischarges(branchId, worksheetCode);
    setTWorksheetSteelDischarges(res.data);
    setLoading(false);
  };

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 30,
      render: (value: number, item: TWorksheetSteelHeadView, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
    },
    {
      title: "เลขที่ใบงาน",
      dataIndex: "worksheetCode",
      width: 80,
      key: "worksheetCode", render: (value: string, item: TWorksheetSteelHeadView) => (
        <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.iworksheetCode}</Button>
      )
    },
    {
      title: "รหัส",
      dataIndex: "icustCode",
      key: "icustCode",
      width: 80,
    },
    {
      title: "ลูกค้า",
      dataIndex: "icustName",
      key: "icustName",
    },
    {
      title: "บริการ",
      dataIndex: "steelServiceName",
      key: "steelServiceName",
    },
  ];

  const onItemCliked = (item: TWorksheetSteelHeadView) => {
    setSelectedSteelDischarge(item);
    setVisibleSteelUnDischargeModal(false);
  };

  const onFinish = async (values: any) => {
    loadTWorksheetSteelDischarges(values.worksheetCode)
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  return (
    <>
      <Modal
        title="รายการใบงานแผ่นเหล็กรอปล่อย"
        cancelText={"ยกเลิก"}
        visible={visibleSteelUnDischargeModal}
        width={700}
        closable={false}
        destroyOnClose
        maskClosable={false}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setVisibleSteelUnDischargeModal(false)}
      >
        <Content className="app-page-content">
          <Form
            form={form}
            name="bankBranchHead"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <div
              style={{
                borderBlockEnd: "2px solid #c9c9c9",
              }}
            ><Space size={"small"}>
                <Form.Item
                  label="ค้นหา"
                  name="worksheetCode"
                  rules={[{ required: false }]}
                >
                  <Input
                    allowClear
                    placeholder="ป้อนเลขที่ใบงาน"
                    onChange={form.submit}
                  ></Input>
                </Form.Item>
              </Space>
            </div>
          </Form>
          <Table
            columns={columns}
            dataSource={tWorksheetSteelDischarges}
            size="small"
            loading={loading}
            pagination={{
              pageSize: 10,
              onChange(current) {
                setPage(current);
              }
            }}
            rowKey={(item: TWorksheetSteelHeadView) => item.steelId}
            onRow={(item: TWorksheetSteelHeadView) => {
              return {
                onDoubleClick: () => onItemCliked(item),
              };
            }}
          />
        </Content>
      </Modal>
    </>
  );
};

export default SteelUnDischargeModal;
