import http from "../../lib/http";
import Constants from "../../utils/constants";

const getTAssetHeadById = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/tAssetHead/" + id);
};

const getAssetByNameNumberDriverList = (nameOrNumberOrDriver: string, branch: string) => {
  return http.get(Constants.DEFAULT_URL + "/tAssetHead/getAssetByNameNumberDriverList", {
    params: {
      nameOrNumberOrDriver,
      branch,
    }
  }
  );
};

const getTAssetHeadByParam = (name: string) => {
  return http.get(Constants.DEFAULT_URL + "/tAssetHead/getTAssetHeadByParam", {
    params: {
      // name: encodeURI(name)
      name
    }
  });
};

const getTAssetHeadByParamAndBranch = (name: string, branch: string) => {
  return http.get(Constants.DEFAULT_URL + "/tAssetHead/getTAssetHeadByParamAndBranch", {
    params: {
      name,
      branch,
    }
  });
};

const getAssetByNameList = (name: string) => {
  return http.get(Constants.DEFAULT_URL + "/tAssetHead/getAssetByNameList", {
    params: {
      name
    }
  });
};

const getTAssetHeadViewById = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/tAssetHead/getTAssetHeadViewById/", {
    params: {
      id
    }
  });
};

const findByAssetType = (nameOrNumberOrDriver: string, assetType: string, status: string) => {
  return http.get(Constants.DEFAULT_URL + "/tAssetHead/findByAssetType", {
    params: {
      nameOrNumberOrDriver,
      assetType,
      status
    }
  });
};

const updateTAssetHead = (tAssetHead: any) => {
  return http.put(Constants.DEFAULT_URL + "/tAssetHead", tAssetHead);
}

const postTAssetHead = (tAssetHead: any) => {
  return http.post(Constants.DEFAULT_URL + "/tAssetHead", tAssetHead);
}

const getNextTAssetHeadCode = () => {
  return http.get(Constants.DEFAULT_URL + "/tAssetHead/nextCode");
}

const defaultApi = {
  getTAssetHeadById,
  getAssetByNameNumberDriverList,
  getTAssetHeadByParam,
  getTAssetHeadByParamAndBranch,
  getAssetByNameList,
  getTAssetHeadViewById,
  findByAssetType,
  updateTAssetHead,
  postTAssetHead,
  getNextTAssetHeadCode
};
export default defaultApi;