import { CloseCircleOutlined, ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Modal, Tabs, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TWorksheetHeadViewForReturnData } from 'src/domain/view/tWorksheetHeadViewForReturnData';
import { TWorksheetHeadView } from 'src/domain/worksheet/view/tWorksheetHeadView';
import tWorksheetHeadService from 'src/services/tWorksheetHeadService';
import tWorksheetReturnService from 'src/services/tWorksheetReturnService';
import { useAppSelector } from 'src/stores/store';
import Constants from 'src/utils/constants';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import ReturnWorksheetTab from './tab/ReturnWorksheetTab';
import WorkingWorksheetTab from './tab/WorkingWorksheetTab';

interface IProps {
  onDrawerVisible: boolean;
  setOnDrawerVisible: any;
}
const TWorksheetReturnDrawer: React.FC<IProps> = (props) => {
  const { onDrawerVisible, setOnDrawerVisible } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [loading, setLoading] = useState<boolean>(false);

  const [tworksheetHeadViewForReturnData, setTworksheetHeadViewForReturnData] = useState<TWorksheetHeadViewForReturnData>({} as TWorksheetHeadViewForReturnData);
  const [returnWorksheets, setReturnWorksheets] = useState<TWorksheetHeadView[]>([]);//รายการใบงานที่เลือก
  const [workingWorksheets10d, setWorkingWorksheets10d] = useState<TWorksheetHeadView[]>([]);//รายการใบงานค้างรับกลับ 10 วัน
  const [workingWorksheets30d, setWorkingWorksheets30d] = useState<TWorksheetHeadView[]>([]);//รายการใบงานค้างรับกลับ 30 วัน
  const [workingWorksheets90d, setWorkingWorksheets90d] = useState<TWorksheetHeadView[]>([]);//รายการใบงานค้างรับกลับ 90 วัน
  const [workingWorksheets1y, setWorkingWorksheets1y] = useState<TWorksheetHeadView[]>([]);//รายการใบงานค้างรับกลับ 1 ปี
  const [countWsh, setCountWsh] = useState(0);

  const [activeTab, setActiveTab] = useState<string>("returnWorksheetTab");
  const [workingWorksheetTabActive, setWorkingWorksheetTabActive] = useState(false);
  const [returnWorksheetTabActive, setReturnWorksheetTabActive] = useState(false);

  const { TabPane } = Tabs;
  const { confirm } = Modal;

  useEffect(() => {
    if (trigger) {
      form.validateFields()
        .then(() => {
          confirm({
            title: "ตรวจสอบข้อมูล",
            icon: <ExclamationCircleOutlined />,
            content: "ระบบจะทำการส่งเข้าการเงินสำหรับใบสั่งงานสดหลังเลิกงานหรือสดมีมัดจำ\nส่งใบสั่งงานให้เร่งรัดสำหรับใบสั่งงานประเภทเชื่อ \n กรุณาตรวจสอบยอดเงินและจำนวนใบงานก่อนการบันทึก \n เลือก ยืนยัน เพื่อบันทึกข้อมูลการรับใบงานกลับ",
            cancelText: "ยกเลิก",
            okText: "ยืนยัน",
            onOk() {
              form.submit();
            },
            onCancel() {
              setTrigger(false);
            }
          });
        }).catch(() => {
          setTrigger(false);
        });
    }
  }, [trigger]);

  useEffect(() => {
    if (onDrawerVisible) {
      setLoading(true);
      (async () => {
        const date10dTo = moment().format(Constants.DB_FORMAT);
        const date10dFrom = moment().subtract(9, "days").format(Constants.DB_FORMAT);
        const date30dTo = moment().subtract(10, "days").format(Constants.DB_FORMAT);
        const date30dFrom = moment().subtract(32, "days").format(Constants.DB_FORMAT);
        const date90dTo = moment().subtract(33, "days").format(Constants.DB_FORMAT);
        const date90dFrom = moment().subtract(62, "days").format(Constants.DB_FORMAT);
        const date1yTo = moment().subtract(63, "days").format(Constants.DB_FORMAT);
        // const date1yFrom = "20060606";
        const date1yFrom = moment().subtract(366, "days").format(Constants.DB_FORMAT);

        const listDataRes = await tWorksheetHeadService.getWorksheetForPrepareReturnAll(date10dFrom,
          date10dTo, date30dFrom, date30dTo, date90dFrom, date90dTo, date1yFrom,
          date1yTo, userInfo.branchId);

        if (listDataRes.data) {
          setWorkingWorksheets10d(listDataRes.data.WSH_10D_LIST);
          setWorkingWorksheets30d(listDataRes.data.WSH_30D_LIST);
          setWorkingWorksheets90d(listDataRes.data.WSH_90D_LIST);
          setWorkingWorksheets1y(listDataRes.data.WSH_OVER1Y_LIST);
          setCountWsh(listDataRes.data.COUNT_WSH);
          setWorkingWorksheetTabActive(true);
          setLoading(false);
        }
      })();
    }
  }, [onDrawerVisible]);

  function onchangeTab(key: any) {
    setActiveTab(key);
    if (key === 'returnWorksheetTab') {
      setReturnWorksheetTabActive(true);
      setWorkingWorksheetTabActive(false);
    } else { //workingWorksheetTab
      setReturnWorksheetTabActive(false);
      setWorkingWorksheetTabActive(true);
    }
  }

  const onFinish = async (values: any) => {
    if (returnWorksheets.length > 0) {
      const temp: TWorksheetHeadViewForReturnData = {
        ...(tworksheetHeadViewForReturnData as TWorksheetHeadViewForReturnData),
        tworksheetHeadViewList: [...returnWorksheets],
      };

      try {
        const res = await tWorksheetReturnService.createWorksheetReturn(temp);
        showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเรียบร้อย!");
        form.resetFields();
        setTrigger(false);
        setOnDrawerVisible(false);
        history.replace("/app/worksheetReturn/view/" + res.data.returnNo);
      } catch (err) {
        showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        setTrigger(false);
      }
    } else {
      showNotification(NotificationType.ERROR, "ไม่มีข้อมูลในรายการ!");
      form.resetFields();
      setTrigger(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน");
    setTrigger(false);
  };

  return (
    <>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              justifyContent: "space-between",
              borderColor: "red",
            }}
          >
            <Typography.Title level={3}>โปรแกรมรับใบสั่งงานกลับ</Typography.Title>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Button
                type="primary"
                danger
                icon={<CloseCircleOutlined />}
                onClick={() => setOnDrawerVisible(false)}>
                ยกเลิก
              </Button>
              <Button
                onClick={() => setTrigger(true)}
                type="primary"
                icon={<SaveOutlined />}
                style={{ marginLeft: "5px" }}
              >
                บันทึก
              </Button>
            </div>
          </div>
        }
        width="95%"
        visible={onDrawerVisible}
        forceRender={true}
        placement="right"
        bodyStyle={{ paddingBottom: 80 }}
        closable={false}
        destroyOnClose={true}
        headerStyle={{ border: 0, height: "25px" }}
      >
        <Form
          form={form}
          name="worsheetReturn"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
        </Form>
        <Tabs
          type='line'
          activeKey={activeTab}
          onTabClick={onchangeTab}
        >
          <TabPane id="returnWorksheetTab" tab="รับใบงานกลับ" key="returnWorksheetTab" >
            <ReturnWorksheetTab
              returnWorksheets={returnWorksheets}
              setReturnWorksheets={setReturnWorksheets}
              workingWorksheets10d={workingWorksheets10d}
              setWorkingWorksheets10d={setWorkingWorksheets10d}
              workingWorksheets30d={workingWorksheets30d}
              setWorkingWorksheets30d={setWorkingWorksheets30d}
              workingWorksheets90d={workingWorksheets90d}
              setWorkingWorksheets90d={setWorkingWorksheets90d}
              workingWorksheets1y={workingWorksheets1y}
              setWorkingWorksheets1y={setWorkingWorksheets1y}
              loading={loading}
            ></ReturnWorksheetTab>
          </TabPane>
          <TabPane id="workingWorksheetTab" tab="ใบงานค้างรับกลับ" key="workingWorksheetTab">
            <WorkingWorksheetTab
              workingWorksheets10d={workingWorksheets10d}
              workingWorksheets30d={workingWorksheets30d}
              workingWorksheets90d={workingWorksheets90d}
              workingWorksheets1y={workingWorksheets1y}
              countWsh={countWsh}
              activeTab={activeTab}
              loading={loading}
            ></WorkingWorksheetTab>
          </TabPane>
        </Tabs>
      </Drawer>
    </>
  )
}

export default TWorksheetReturnDrawer