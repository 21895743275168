import { PlusSquareOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Select, Space, Spin, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { TEmployee2View } from 'src/domain/master/view/tEmployee2View';
import { TUserHead } from 'src/domain/tUserHead';
import { TWshConstructionworkView } from 'src/domain/worksheet/view/tWshConstructionworkView';
import { WorksheetHeadData } from 'src/domain/worksheet/view/worksheetHeadData';
import { useAppSelector } from 'src/stores/store';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import employeeService from 'src/services/employee/tEmployee2Service';
import { formatNumber } from 'src/utils/numberUtils';
import TextArea from 'antd/lib/input/TextArea';
import WorksheetCodeModal from '../WorksheetCodeModal';
import { TWorksheetHead } from 'src/domain/worksheet/tWorksheetHead';
import worksheetService from 'src/services/worksheetService';

const { Option } = Select;

interface IProps {
  editWorksheetConstructionListVisible: boolean;
  setEditWorksheetConstructionListVisible: any;
  // setUpdateStatus: any;
  worksheetHeadData: WorksheetHeadData;
  setWorksheetHeadData: any;
  tUserHeadUpdate: TUserHead;
}
const EditWorksheetConstructionListModal: React.FC<IProps> = (props) => {
  const { editWorksheetConstructionListVisible, setEditWorksheetConstructionListVisible, worksheetHeadData, setWorksheetHeadData, tUserHeadUpdate } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
	const [visibleContractorWorkModal, setVisibleContractorWorkModal] = useState(false);
  const [showSpin, setShowSpin] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [employees, setEmployees] = useState<TEmployee2View[]>([]);//ผู้แจ้ง 
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [worksheetHeadDataTemp, setWorksheetHeadDataTemp] = useState<WorksheetHeadData>({} as WorksheetHeadData);
  const [recalculateContractorWork, setRecalculateContractorWork] = useState<boolean>(false);//ใช้ส่งเป็น parameter แต่ไม่ได้ทำอะไรในหน้า Edit Menu(ทำในหน้า)
  const [twshConstructionworkFormDataList, setTwshConstructionworkFormDataList] = useState<TWshConstructionworkView[]>([]);
  const [twshConstructionworkFormDataRemoveList, setTwshConstructionworkFormDataRemoveList] = useState<TWshConstructionworkView[]>([]);

  const columns = [
		{
			title: "ลำดับ",
			dataIndex: "index",
			key: "index",
			align: "center" as "center",
			render: (value: any, item: TWshConstructionworkView, index: number) => {
				return <span>{index + 1}</span>;
			},
		},
		{
			title: "เลขที่ใบงาน",
			dataIndex: "worksheetConstruction",
			key: "worksheetConstruction",
		},
		{
			title: "รถ",
			dataIndex: "truckFullName",
			key: "truckFullName",
		},
		{
			title: "จำนวนเงิน",
			dataIndex: "total2Amt",
			key: "total2Amt",
			align: "right" as "right",
			render: (value: any, item: TWshConstructionworkView, index: number) => {
				return <span>{formatNumber(item.total2Amt)}</span>;
			},
		},
		{
			title: "Action",
			key: "action",
			align: "center" as "center",
			render: (value: any, item: TWshConstructionworkView, index: number) => {
				return (
					<span>
						<a onClick={(e) => { onDelete(item, index); }} style={{ color: "red" }}
						>
							X
						</a>
					</span>
				);
			},
		},
	];
  
  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  useEffect(() => {
    setShowSpin(true);
    loadData();
    return () => { };
  }, []);

  const loadData = async () => {
    setWorksheetHeadDataTemp(worksheetHeadData);
    setTwshConstructionworkFormDataList(worksheetHeadData.tworksheetConstructionworkViewList!);
    setShowSpin(false);
  };

  useEffect(() => {
    // if (recalculateContractorWork) {
    if (worksheetHeadDataTemp.tworksheetHead) {
      const extraAmt = (!worksheetHeadDataTemp.tworksheetHead?.iextraAmt) ? 0 : worksheetHeadDataTemp.tworksheetHead?.iextraAmt;
      const otAmt = (!worksheetHeadDataTemp.tworksheetHead?.iotAmt) ? 0 : worksheetHeadDataTemp.tworksheetHead?.iotAmt;
      const discountAmt = (!worksheetHeadDataTemp.tworksheetHead?.idiscount) ? 0 : worksheetHeadDataTemp.tworksheetHead?.idiscount;
      let depositAmt = (!worksheetHeadDataTemp.tworksheetHead?.idepositAmt) ? 0 : worksheetHeadDataTemp.tworksheetHead?.idepositAmt;
      let paidAmt = (!worksheetHeadDataTemp.tworksheetHead?.payedamount) ? 0 : worksheetHeadDataTemp.tworksheetHead?.payedamount;
      let sumConstructionworkAmount = 0;
      let sumConstructionworkTotal2Amount = 0;
      let netSumService = 0.0;
      let remain = 0.0;

      twshConstructionworkFormDataList.forEach((obj) => {
        sumConstructionworkAmount += (obj.remainAmt) ? obj.remainAmt : 0;
        sumConstructionworkTotal2Amount += (obj.total2Amt) ? obj.total2Amt : 0;
      });

      //round at most 2 decimal places ->> Math.round(num * 100) / 100
      let amountBefTax = (sumConstructionworkTotal2Amount + extraAmt + otAmt) - discountAmt;
      let vatAmount = calVatAmt(amountBefTax);

      if (worksheetHeadData.tworksheetHead.itypetaxCode === "02") {
        amountBefTax = amountBefTax - vatAmount;
      }

      netSumService = amountBefTax + vatAmount;

      if (worksheetHeadDataTemp.tworksheetHead.itypepaymentCode === "01") {//สดก่อนเริ่มงาน
        remain = netSumService;
        depositAmt = netSumService;
      } else if (worksheetHeadDataTemp.tworksheetHead.itypepaymentCode === "03") {//สดมีมัดจำ
        if (worksheetHeadDataTemp.tworksheetHead.idepositFlg === 1) {//ยังไม่ได้รับเงินสดมัดจำ
          remain = netSumService;
        } else {//รับเงินมัดจำแล้ว
          remain = netSumService - paidAmt;
        }
      } else {//เชื่อ,สดเมื่องานเสร็จ
        remain = netSumService;
      }

      setWorksheetHeadDataTemp({
        ...(worksheetHeadData as WorksheetHeadData),
        tworksheetHead: {
          ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
          iserviceAmt: sumConstructionworkTotal2Amount ? sumConstructionworkTotal2Amount : 0,
          itotal1Amt: amountBefTax ? amountBefTax : 0,
          itaxAmt: vatAmount ? vatAmount : 0,
          itotal2Amt: netSumService,
          iremainAmt: remain,
          idepositAmt: depositAmt,
        },
        tworksheetConstructionworkViewList: [...reindex(twshConstructionworkFormDataList)],
        tworksheetConstructionworkViewRemoveList: [...reindex(twshConstructionworkFormDataRemoveList)],
        sumConstructionWorkAmt: sumConstructionworkAmount,
        sumConstructionWorkTotal2Amt: sumConstructionworkTotal2Amount,
        sumServiceLastAmt: sumConstructionworkTotal2Amount - discountAmt,
        netServiceAmt: amountBefTax,
      });
    }
  }, [twshConstructionworkFormDataList]);

  const calVatAmt = (serviceAmt: number) => {
    let vatAmt = 0;
    switch (worksheetHeadData.tworksheetHead.itypetaxCode) {
      case "01":
        vatAmt = Math.round(((serviceAmt * 7) / 100) * 100 ) / 100;
        break;
      case "02":
        vatAmt = Math.round(((serviceAmt * 7) / 107 ) * 100 ) / 100;
        break;
      case "03":
        break;
    }
    return vatAmt;
  }

	const reindex = (tWshConstructionworkView: TWshConstructionworkView[]) => {
		return tWshConstructionworkView.map((item, index) => {
			item.id = index;
			return item;
		});
	}

  const handleSearchEmployee = async (data: any) => {
    if (data.length < 3) return;
    const res = await employeeService.getEmployee2ByParam(data);
    setEmployees(res.data);
  };

	const onDelete = (item: TWshConstructionworkView, index: number) => {
    //remove item in ADD LIST
		const array = twshConstructionworkFormDataList ? [...twshConstructionworkFormDataList] : [];
		array?.splice(index, 1);
		setTwshConstructionworkFormDataList(array);

    //add item in REMOVE LIST
    if (!checkExistInList(item.worksheetConstruction)) {
      const arrayRemove = twshConstructionworkFormDataRemoveList ? [...twshConstructionworkFormDataRemoveList] : [];
      arrayRemove?.push(item);
      setTwshConstructionworkFormDataRemoveList(arrayRemove);
    }
	}
  
  const checkExistInList = (worksheetCode: string) => {
		let isExist = false;
		const formItem = (twshConstructionworkFormDataRemoveList as Array<TWshConstructionworkView>).find((obj) => obj.worksheetConstruction === worksheetCode);
		if (formItem) isExist = true;
		return isExist;
	}

  const onFinish = async (values: any) => {
    // console.log("Succes :", values);
    const employeeArr = employees as Array<TEmployee2View>;
      const empSelected = employeeArr.find((obj) => obj.id === values.userInfoId);
      try {
        const temp = {
          ...(worksheetHeadData as WorksheetHeadData),
          tworksheetHead: {
            ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
            iserviceAmt: worksheetHeadDataTemp.tworksheetHead.iserviceAmt,
            itotal1Amt: worksheetHeadDataTemp.tworksheetHead.itotal1Amt,
            itaxAmt: worksheetHeadDataTemp.tworksheetHead.itaxAmt,
            itotal2Amt: worksheetHeadDataTemp.tworksheetHead.itotal2Amt,
            iremainAmt: worksheetHeadDataTemp.tworksheetHead.iremainAmt,
            idepositAmt: worksheetHeadDataTemp.tworksheetHead.idepositAmt,
            ilastUser: tUserHeadUpdate.iuserId,
            itermNo: userInfo.termNo,
            isavStation: userInfo.saveStation,
          },          
          tworksheetConstructionworkViewList: worksheetHeadDataTemp.tworksheetConstructionworkViewList,
          tworksheetConstructionworkViewRemoveList: worksheetHeadDataTemp.tworksheetConstructionworkViewRemoveList,
          sumConstructionWorkAmt: worksheetHeadDataTemp.sumConstructionWorkAmt,
          sumConstructionWorkTotal2Amt: worksheetHeadDataTemp.sumConstructionWorkTotal2Amt,
          sumServiceLastAmt: worksheetHeadDataTemp.sumServiceLastAmt,
          netServiceAmt: worksheetHeadDataTemp.netServiceAmt,
          updateUser: tUserHeadUpdate,
        };
        setWorksheetHeadData(temp);
        await worksheetService.updateWorksheetWithSubMenuKey(temp, values.userRemark, empSelected ? empSelected.empname : "", "MENU_WORKSHEET_CONSTRUCTION");
        showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเรียบร้อย");
        setEditWorksheetConstructionListVisible(false);
        // setUpdateStatus(true);
        form.resetFields();
        setTrigger(false);
      } catch (err) {
        showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        setTrigger(false);
      }
  };


  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
    showNotification(NotificationType.ERROR, "กรุณาตรวจสอบข้อมูลให้ครบถ้วน!");
    setTrigger(false);
  };
  
  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>แก้ไขใบสั่งงานย่อย</Typography.Title>
          </div>
        }
        visible={editWorksheetConstructionListVisible}
        width={"50%"}
        style={{ minHeight: "80%" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setEditWorksheetConstructionListVisible(false)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
        >
          <Space align="baseline" direction="horizontal">
            <Button
              size="small"
              type="primary"
              icon={<PlusSquareOutlined />}
              onClick={() => setVisibleContractorWorkModal(true)}
            >
              เพิ่ม
            </Button>
          </Space>
          <Typography.Title
            type="danger"
            level={4}
            style={{ height: "22px", textAlign: "right" }}
          >
            จำนวนเงิน :{" "} {formatNumber(!worksheetHeadDataTemp?.sumConstructionWorkTotal2Amt ? 0 : worksheetHeadDataTemp?.sumConstructionWorkTotal2Amt)}
          </Typography.Title>
        </div>
        <Spin spinning={showSpin}>
          <div style={{ marginBottom: 20, minHeight: 80 }}>
            <Table
              columns={columns}
              dataSource={twshConstructionworkFormDataList}
              size="small"
              pagination={false}
              rowKey={(item: TWshConstructionworkView) => item.id}
            ></Table>
          </div>

          <Form
            form={form}
            name="worksheetDetail"
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="ผู้แจ้ง"
              name="userInfoId"
              rules={[{ required: true, message: "กรุณาระบุ ผู้แจ้ง!" }]}
            >
              <Select
                showSearch
                allowClear
                size="middle"
                placeholder="เลือกผู้แจ้ง"
                optionFilterProp="children"
                filterOption={true}
                onSearch={handleSearchEmployee}
              >
                {employees.map((obj) => {
                  return (
                    <Option key={obj.id} value={obj.id}>
                      {obj.empname}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label="หมายเหตุ"
              name="userRemark"
              rules={[{ required: true, message: "กรุณาระบุเหตุผล !" },
              {
                validator: (_, value) => {
                  if (value <= 10) {
                    return Promise.reject(showNotification(NotificationType.ERROR, "กรุณาระบุเหตุผล ให้มากกว่า 10 ตัวอักษร"))
                  } else {
                    return Promise.resolve()
                  }
                },
              },
              ]}
            >
              <TextArea
                rows={4}
              ></TextArea>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
      <div>
				{visibleContractorWorkModal && (
					<WorksheetCodeModal
						visibleContractorWorkModal={visibleContractorWorkModal}
						setVisibleContractorWorkModal={setVisibleContractorWorkModal}
						twshConstructionworkFormDataList={twshConstructionworkFormDataList}
						setTwshConstructionworkFormDataList={setTwshConstructionworkFormDataList}
            twshConstructionworkFormDataRemoveList={twshConstructionworkFormDataRemoveList}
            setTwshConstructionworkFormDataRemoveList={setTwshConstructionworkFormDataRemoveList}
						setRecalculateContractorWork={setRecalculateContractorWork}
					/>
				)}
			</div>
    </>
  )
}

export default EditWorksheetConstructionListModal