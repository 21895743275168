import http from "src/lib/http";
import Constants from "src/utils/constants";

const getTTypetaxRefList = () => {
  return http.get(
    Constants.DEFAULT_URL + "/tTypetaxRef"
  );
};

const getTTypetaxRef = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/tTypetaxRef/" + id);
}

const defaultApi = {
  getTTypetaxRef,
  getTTypetaxRefList,
};
export default defaultApi;