import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import Search from "antd/es/input/Search";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PageHeader from "src/layouts/PageHeader";
import { TBankHead } from "../../domain/master/tBankHead";
import tBankHeadService from "../../services/bank/tBankHeadService";
import AddEditBankHeadModal from "./AddEditBankHeadModal";


const BankHeadList: React.FC<TBankHead> = () => {
  const [bankHeads, setBankHeads] = useState<TBankHead[]>([]);
  const [page, setPage] = React.useState(1);
  const history = useHistory();

  const [selectedId, setSelectedId] = useState("");
  const [visibleBankHeadModal, setVisibleBankHeadModal] =
    useState<boolean>(false);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 100,
      render: (value: number, item: TBankHead, index: number) => (
        <>{(page - 1) * 15 + index + 1}</>
      ),
    },
    {
      title: "รหัส",
      dataIndex: "ibankCode",
      key: "ibankCode",
      width: 100,
      render: (value: string, item: TBankHead) => (
        <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)}>{item.ibankCode}</Button>
      )
    },
    {
      title: "รหัสย่อ",
      dataIndex: "shortname",
      key: "shortname",
    },
    {
      title: "ชื่อธนาคาร",
      dataIndex: "ibankName",
      key: "ibankName",
    },
  ];

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    (async () => {
      const res = await tBankHeadService.getBankHeads("");
      setBankHeads(res.data);
    })();
  }, []);

  const doSearch = async (searchText: string) => {
    const res = await tBankHeadService.getBankHeads(searchText);
    setBankHeads(res.data);
  };

  function onSearchTextChange(searchText: string) {
    if (!searchText) {
      doSearch("");
    } else if (searchText.length >= 2) {
      doSearch(searchText);
    }
  }

  function onAddBankHead() {
    setSelectedId("");
    setVisibleBankHeadModal(true);
  }

  const onItemCliked = (item: TBankHead) => {
    history.replace("/app/bankHead/view/" + item.ibankCode);
  };

  return (
    <>
      <PageHeader title="โปรแกรมข้อมูลธนาคาร">
        <Button
          size="small"
          type="primary"
          icon={<PlusSquareOutlined />}
          onClick={onAddBankHead}
        >
          เพิ่ม
        </Button>
      </PageHeader>
      <Content className="app-page-content">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            margin: "1em 0 0 0",
            borderBlockEnd: "2px solid #c9c9c9",
          }}
        >
          <label style={{ margin: "0 1em 1em 2em" }}>คำค้นหา</label>
          <Search
            allowClear
            style={{ width: "350px" }}
            size="middle"
            placeholder="ป้อนคำค้นหา"
            onChange={(event) => onSearchTextChange(event.target.value)}
          ></Search>
        </div>
        <div>
          <Table
            columns={columns}
            dataSource={bankHeads}
            size="small"
            pagination={{
              pageSize: 15,
              onChange(current) {
                setPage(current);
              },
            }}
            rowKey={(item: TBankHead) => item.ibankCode}
            onRow={(item: TBankHead) => {
              return {
                onDoubleClick: () => onItemCliked(item),
              };
            }}
          />
        </div>
      </Content>
      <div>
        {visibleBankHeadModal && (
          <AddEditBankHeadModal
            ibankCode={selectedId}
            visibleBankHeadModal={visibleBankHeadModal}
            setVisibleBankHeadModal={setVisibleBankHeadModal}
          />
        )}
      </div>
    </>
  );
};

export default BankHeadList;
