import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, DatePicker, Form, Row, Select, Space, Table, Typography, Modal, Radio, RadioChangeEvent } from 'antd';
import moment from 'moment';
import Constants from 'src/utils/constants';
import PageHeader from 'src/layouts/PageHeader';
import { Content } from 'antd/lib/layout/layout';
import { useAppSelector } from 'src/stores/store';
import tCustomerHeadService from "src/services/customer/tCustomerHeadService";
import { TCustomerHeadView } from 'src/domain/master/view/tCustomerHeadView';
import { PrinterOutlined, SearchOutlined } from '@ant-design/icons';
import { CustomerServiceUseReportData } from 'src/domain/worksheet/customerServiceUseReportData';
import tAssetHeadService from "src/services/tasset/tAssetHeadService";
import { TAssetHeadView } from 'src/domain/master/view/tAssetHeadView';
import tTypeworksheetRefService from "src/services/reference/tTypeworksheetRefService";
import { TTypeworksheetRef } from 'src/domain/tTypeworksheetRef';
import { TTypepaymentRef } from 'src/domain/tTypepaymentRef';
import { RyReftypework } from 'src/domain/ryReftypework';
import tTypepaymentRefService from "src/services/reference/tTypepaymentRefService";
import ryReftypeworkService from "src/services/reference/ryReftypeworkService";
import reportService from 'src/services/reportService';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { WorksheetDateWorkReportData } from 'src/domain/worksheet/worksheetDateWorkReportData';
import { formatNumber, formatNumberInt } from 'src/utils/numberUtils';
import { toThDate } from 'src/utils/dateTimeUtils';
import PdfViewPopup from './PdfViewPopup';
import { WorksheetDateWorkReportSummary } from 'src/domain/worksheet/worksheetDateWorkReportSummary';

const { Option } = Select;

const WorksheetDateWorkReport = () => {
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [customers, setCustomers] = useState<TCustomerHeadView[]>([]);
  const [assets, setAssets] = useState<TAssetHeadView[]>([]); //001 รถ,002 ตู้,003 แผ่นเหล็ก
  const [tTypeworksheetRefs, setTTypeworksheetRefs] = useState<TTypeworksheetRef[]>([]); //ประเภทงาน
  const [ryReftypeworks, setRyReftypeworks] = useState<RyReftypework[]>([]); //ประเภทใบงาน
  const [paymentTypeRefs, setPaymentTypeRefs] = useState<TTypepaymentRef[]>([]);  //การชำระ

  const [worksheetDateWorkList, setWorksheetDateWorkList] = useState<WorksheetDateWorkReportData[]>([]);
  const [worksheetDateWorkSummary, setWorksheetDateWorkSummary] = useState<WorksheetDateWorkReportSummary>({} as WorksheetDateWorkReportSummary);
  const [worksheetDateWorkPreview, setsetWorksheetDateWorkPreview] = useState<any>();

  const [selectPaymentType, setSelectPaymentType] = useState<boolean>(false);
  const [paymentType, setPaymentType] = useState<String>(null);
  const [isPaymentDisable, setIsPaymentDisable] = useState<boolean>(false);
  const [visiblePDF, setVisiblePDF] = useState(false);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false);

  const [fromDateParam, setFromDateParam] = useState<any>();
  const [toDateParam, setToDateParam] = useState<any>();
  const [truckCodeParam, setTruckCodeParam] = useState<any>();
  const [customercodeParam, setCustomercodeParam] = useState<any>();
  const [typeWorksheetCodeParam, setTypeWorksheetCodeParam] = useState<any>();
  const [typeWorkParam, setTypeWorkParam] = useState<any>();
  const [typePaymentCodeParam, setTypePaymentCodeParam] = useState<any>();
  const [selectPaymentTypeParam, setSelectPaymentTypeParam] = useState<any>();

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 10,
      align: "center" as "center",
      render: (value: number, item: CustomerServiceUseReportData, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
    },
    {
      title: "วันที่ปฏิบัติงาน",
      dataIndex: "startDate",
      key: "startDate",
      width: 100,
      align: "center" as "center",
      render: (value: any, item: WorksheetDateWorkReportData) => (<>{toThDate(item.startDate)}</>)
    },
    {
      title: "เลขที่ใบจอง",
      dataIndex: "reserveCode",
      key: "reserveCode",
      width: 70,
    },
    {
      title: "เลขที่ใบงาน",
      dataIndex: "worksheetCode",
      key: "worksheetCode",
      width: 70,
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "customerName",
      key: "customerName",
      width: 170,
    },
    {
      title: "รายการบริการ",
      dataIndex: "remark",
      key: "remark",
      width: 150,
    },
    {
      title: "ประเภทการชำระ",
      dataIndex: "typePaymentName",
      key: "typePaymentName",
      width: 90,
      align: "center" as "center",
    },
    {
      title: "ค่าบริการ",
      dataIndex: "total2Amt",
      key: "total2Amt",
      width: 50,
      align: "right" as "right",
      render: (value: any, item: WorksheetDateWorkReportData) => {
        return <span>{formatNumber(item.total2Amt)}</span>;
      },
    },
    {
      title: "สถานะใบงาน",
      dataIndex: "worksheetStatus",
      key: "worksheetStatus",
      width: 100,
      align: "center" as "center",
    },
  ]

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });

    (async () => {
      const tTypeworksheetRefsRes = await tTypeworksheetRefService.getTTypeworksheetRefList();
      const paymentTypeRefs = await tTypepaymentRefService.getTTypepaymentRefList();
      const ryReftypeworksRes = await ryReftypeworkService.getRyReftypeworkList();

      setTTypeworksheetRefs(tTypeworksheetRefsRes.data);
      setPaymentTypeRefs(paymentTypeRefs.data);
      setRyReftypeworks(ryReftypeworksRes.data);
    })();

    initData();
    form.submit();
  }, []);

  const initData = async () => {
    form.setFieldsValue({
      fromDate: moment(new Date(), Constants.DATE_FORMAT).startOf('month'),
      toDate: moment(new Date(), Constants.DATE_FORMAT).endOf('month'),
      truckCode: undefined,
      customercode: undefined,
      typeWorksheetCode: undefined,
      typeWork: undefined,
      typePaymentCode: undefined,
      selectPaymentType: false
    });
  }

  const handleSearchAsset = async (data: any) => {
    if (data.length < 3) return;
    const res = await tAssetHeadService.getAssetByNameNumberDriverList(data, userInfo.branchId);
    setAssets(res.data);
  };

  const handleSearchCustomer = async (data: any) => {
    if (data.length < 3) return;
    const res = await tCustomerHeadService.getCustomerByParam(data);
    setCustomers(res.data);
  };

 
   const onFinish = async (values: any) => {
    setLoading(true);
    const fromDate = moment(values.fromDate).format("yyyyMMDD");
    const toDate = moment(values.toDate).format("yyyyMMDD");
    const truckCode = values.truckCode ? values.truckCode : "";
    const customercode = values.customercode ? values.customercode : "";
    const typeWorksheetCode = values.typeWorksheetCode ? values.typeWorksheetCode : "";
    const typeWork = values.typeWork ? values.typeWork : "";
    const typePaymentCode = values.typePaymentCode ? values.typePaymentCode : "";

    const res = await reportService.getWorksheetDateWorkReport(
              fromDate, toDate, truckCode, customercode, typeWorksheetCode, typeWork,
              typePaymentCode, selectPaymentType);
    // console.log('----------res--------', res.data)
    setWorksheetDateWorkList(res.data);
    setLoading(false);

    const resSummary = await reportService.getWorksheetDateWorkSummaryReport(
      fromDate, toDate, truckCode, customercode, typeWorksheetCode, typeWork,
      typePaymentCode, selectPaymentType);
    // console.log('----------resSummary--------', res.data)
    setWorksheetDateWorkSummary(resSummary.data);

    setPage(1);
    setFromDateParam(fromDate);
    setToDateParam(toDate);
    setTruckCodeParam(truckCode);
    setCustomercodeParam(customercode);
    setTypeWorksheetCodeParam(typeWorksheetCode);
    setTypeWorkParam(typeWork);
    setTypePaymentCodeParam(typePaymentCode);
    setSelectPaymentTypeParam(selectPaymentType);

    // setLoading(false);
    
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  const onSelectPaymentType = (e: CheckboxChangeEvent) => {
    console.log("payment type : ", e.target.checked, paymentType, form.getFieldValue('rdInOut'))

    setSelectPaymentType(e.target.checked);
    
  }

  useEffect(() => {
    console.log('useEffect -> paymentType : ', paymentType);
  
    if (!selectPaymentType) {
      setIsPaymentDisable(true);
      form.setFieldsValue({
        rdInOut: '',
        typePaymentCode: null
      })

      return;
    } else {
      form.setFieldsValue({
        rdInOut: 'PAY_C',
        typePaymentCode: null
      })
    }

    if (paymentType === 'PAY_M') {
      setIsPaymentDisable(false);
      
    } else {
      setIsPaymentDisable(true);
      form.setFieldsValue({
        typePaymentCode: null
      })
    }

  }, [selectPaymentType, paymentType])

  const onPrint = async () => {
    setVisiblePDF(true);
  }


  return (
    <>
      <PageHeader title="รายงานการปฏิบัติงานประจำวัน"></PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="rengradWangbill"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
            }}
          >
           
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ตั้งแต่: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="fromDate"
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    // onChange={form.submit}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>หมายเลขรถ: </Typography.Text>
              </Col>
              <Col span={6}>
              <Form.Item name="truckCode" 
                rules={[{ required: false }]}
                style={{ marginBottom: "3px" }}
              >
                  <Select
                    // style={{ width: 200 }}
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกรถ/อุปกรณ์"
                    optionFilterProp="children"
                    filterOption={true}
                    onSearch={handleSearchAsset}
                  // onSelect={form.submit}
                  >
                    {assets.map((obj) => {
                      return (
                        <Option key={obj.id} value={obj.id}>
                          {obj.numbers} : {obj.assetname}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ถึง: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="toDate"
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    // onChange={form.submit}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ชื่อลูกค้า: </Typography.Text>
              </Col>
              <Col span={6}>
                {/* <Space align='baseline' style={{ width: "100%" }}> */}
                  <Form.Item
                    name="customercode"
                    style={{ marginBottom: "3px" }}
                  >
                    <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกลูกค้า"
                    optionFilterProp="children"
                    filterOption={false}
                    onSearch={handleSearchCustomer}
                  >
                    {customers.map((obj) => {
                      return (
                        <Option key={obj.icustCode} value={obj.icustCode}>
                          {obj.iprenameName}{obj.iname}  {obj.isurname ? obj.isurname : ""}
                        </Option>
                      );
                    })}
                  </Select>
                  </Form.Item>
                {/* </Space> */}
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Form.Item 
                  name="selectPayment"
                  style={{ marginBottom: "3px" }}
                >
                  <Checkbox style={{ marginRight: "0.5em" }}
                    checked={selectPaymentType}
                    onChange={onSelectPaymentType}
                  >
                  </Checkbox>
                  <Typography.Text>เลือกการชำระเงิน: </Typography.Text>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item 
                  name="rdInOut"
                  style={{ marginBottom: "3px" }}
                >
                  <Radio.Group 
                    style={{ marginTop: "8px" }} 
                    disabled = {!selectPaymentType}
                    onChange={(e:RadioChangeEvent) => {
                      console.log('onChange -> radio value: ', e.target.value);
                      setPaymentType(e.target.value);
                      form.setFieldsValue({
                        rdInOut: e.target.value
                      })
                    }}
                    >
                    <Radio value="PAY_C" >ใบสั่งงานจ่ายเงินสด</Radio>
                    <Radio value="PAY_M" >กำหนดเอง</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ประเภทงาน: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="typeWorksheetCode"
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกประเภทงาน"
                    optionFilterProp="children"
                  // onSelect={form.submit}
                  >
                    {tTypeworksheetRefs.map((obj) => {
                      return <Option value={obj.iid} key={obj.iid}>{obj.iname}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>การชำระ: </Typography.Text>
              </Col>
              <Col span={6}>
              <Form.Item
                  name="typePaymentCode"
                  rules={[ { required: false } ]}
                  style={{ marginBottom: "3px" }}
                >
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกการชำระเงิน"
                    optionFilterProp="children"
                    filterOption={true}
                    disabled={isPaymentDisable}
                    // onSelect={form.submit}
                  >
                    {paymentTypeRefs.map((obj) => {
                      return (
                        <Option key={obj.iid} value={obj.iid}>
                          {obj.iname}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ประเภทใบงาน: </Typography.Text>
              </Col>
              <Col span={6}>
              <Form.Item 
                name="typeWork" 
                rules={[{ required: false }]}
                style={{ marginBottom: "3px" }}
              >
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="ประเภทใบสั่งงาน"
                    optionFilterProp="children"
                    filterOption={true}
                  // onSelect={form.submit}
                  >
                    {ryReftypeworks.map((obj: RyReftypework) => {
                      return (
                        <Option key={obj.id} value={obj.id}>
                          {obj.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: 'center',
              paddingBottom: "1em",
              // borderBlockEnd: "2px solid #c9c9c9",
            }}
          >
            <Space>
              <Button
                size="small"
                type="primary"
                icon={<SearchOutlined />}
                onClick={form.submit}
              >
                ค้นหา
              </Button>
            </Space>
          </div>
        </Form>


           
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            // margin: '0 0 10px 0',
            padding: "0px 20px 10px 0px",
            borderBottom: '2px solid #c9c9c9'
          }}
        >
          <Button
          style={{
            marginLeft: "30px",}}
            size="small"
            type="primary"
            icon={<PrinterOutlined />}
            onClick={onPrint}
          >
            พิมพ์
          </Button>
          <Modal
            style={{ top: 20 }}
            width={"90vw"}
            title="RS001"
            bodyStyle={{ height: "85vh" }}
            centered={true}
            visible={isPreviewModalVisible}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={() => setIsPreviewModalVisible(false)}
          >
            <iframe title=" " src={worksheetDateWorkPreview} width={"100%"} height={"100%"}></iframe>
          </Modal>
        </div>

        <div>
          <Table
            columns={columns}
            dataSource={worksheetDateWorkList}
            size="small"
            loading={loading}
            rowKey={(item: WorksheetDateWorkReportData) => item.reserveCode!}
            pagination={{
              pageSize: 10,
              current: page,
              onChange(current) {
                setPage(current);
              }
            }}
          />
        </div>

        <div style={{
            padding: "20px 0px 0px 0px"
          }}>
          <Row>
            <Col span={19} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> จำนวนใบงาน: </Typography.Text>
            </Col>
            <Col span={3} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> {formatNumberInt(worksheetDateWorkList.length)} </Typography.Text>
            </Col>
            <Col span={1} style={{ textAlign: "left", marginLeft: "2em" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> ใบ </Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col span={19} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> จำนวนค่าบริการก่อนรวม: </Typography.Text>
            </Col>
            <Col span={3} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold", color: "red" }}> {formatNumber(worksheetDateWorkSummary.sumTotal1Amt)} </Typography.Text>
            </Col>
            <Col span={1} style={{ textAlign: "left", marginLeft: "2em" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> บาท </Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col span={19} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> ภาษี: </Typography.Text>
            </Col>
            <Col span={3} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold", color: "red" }}> {formatNumber(worksheetDateWorkSummary.sumTaxAmt)} </Typography.Text>
            </Col>
            <Col span={1} style={{ textAlign: "left", marginLeft: "2em" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> บาท </Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col span={19} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> จำนวนค่าบริการทั้งหมด: </Typography.Text>
            </Col>
            <Col span={3} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold", color: "red" }}> {formatNumber(worksheetDateWorkSummary.sumTotal2Amt)} </Typography.Text>
            </Col>
            <Col span={1} style={{ textAlign: "left", marginLeft: "2em" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> บาท </Typography.Text>
            </Col>
          </Row>
        </div>
      </Content>

      <Modal
        title= 'print'
        centered
        closable={true}
        visible={visiblePDF}
        onCancel={() => setVisiblePDF(false)}
        width="400"
      >
        <PdfViewPopup
          urlPath={reportService.getWorksheetDateWorkReportPreview(fromDateParam, toDateParam, truckCodeParam, customercodeParam,
            typeWorksheetCodeParam, typeWorkParam, typePaymentCodeParam, selectPaymentTypeParam)}
          onClose={() => setVisiblePDF(false)}
        />
      </Modal>
    </>
  )
}

export default WorksheetDateWorkReport