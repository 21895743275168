import { Table, Typography } from 'antd';
import React, { useState } from 'react'
import { TWorksheetSteelDetailView } from 'src/domain/worksheet/view/tWorksheetSteelDetailView';
import { WorksheetHeadData } from 'src/domain/worksheet/view/worksheetHeadData';
import { toThDate } from 'src/utils/dateTimeUtils';
import { formatNumber } from 'src/utils/numberUtils';

interface Iprops {
  worksheetHeadData: WorksheetHeadData;
}
const WorksheetSteelDetailViewTab: React.FC<Iprops> = (props) => {
  const { worksheetHeadData } = props;

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      key: "index",
      align: "center" as "center",
      render: (value: any, item: TWorksheetSteelDetailView, index: number) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: "รหัส",
      dataIndex: "itruckCode",
      key: "itruckCode",
    },
    {
      title: "หมายเลข",
      dataIndex: "itruckName",
      key: "itruckName",
    },
    {
      title: "วันที่เริ่มงาน",
      dataIndex: "istartDate",
      key: "istartDate",
      render: (value: any, item: TWorksheetSteelDetailView) => {
        return <span>{item.istartDate ? toThDate(item.istartDate) : ""}</span>;
      },
    },
    {
      title: "วันที่สิ้นสุดงาน",
      dataIndex: "iendDate",
      key: "iendDate",
      render: (value: any, item: TWorksheetSteelDetailView) => {
        return <span>{item.iendDate ? toThDate(item.iendDate) : ""}</span>;
      },
    },
  ];

  return (
    <>
      <Typography.Title
        type="danger"
        level={4}
        style={{ height: "22px", textAlign: "right" }}
      >
        จำนวน :
        {formatNumber(worksheetHeadData.tworksheetSteelDetailViewList ? worksheetHeadData.tworksheetSteelDetailViewList.length : 0)}
      </Typography.Title>
      <div>
        <Table
          columns={columns}
          dataSource={worksheetHeadData.tworksheetSteelDetailViewList}
          rowKey={(item) => item.iid}
          size="small"
          pagination={false}
        />
      </div>
    </>
  )
}
export default WorksheetSteelDetailViewTab