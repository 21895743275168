import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, Space, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TWorksheetTruckDischargeView } from "src/domain/worksheet/view/tWorksheetTruckDischargeView";
import PageHeader from "src/layouts/PageHeader";
import { useAppSelector } from "src/stores/store";
import Constants from "src/utils/constants";
import { NotificationType, showNotification } from "src/utils/notificationUtils";
import { formatNumberInt } from "src/utils/numberUtils";
import tWorksheetHeadService from "../../services/tWorksheetHeadService";
import { formatTime, toThDate } from "../../utils/dateTimeUtils";
import AddTruckDischargeDrawer from "./AddTruckDischargeDrawer";

const TruckDischargeList: React.FC<TWorksheetTruckDischargeView> = () => {
    const [truckDischarges, setTruckDischarges] = useState<TWorksheetTruckDischargeView[]>([]);
    const [selectedTruckDischarge, setSelectedTruckDischarge] = useState<TWorksheetTruckDischargeView>({} as TWorksheetTruckDischargeView);
    const [page, setPage] = React.useState(1);
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [visibleAddTruckDischargeDrawer, setVisibleAddTruckDischargeDrawer] = useState<boolean>(false);

    const userInfo = useAppSelector((state) => state.authen.authenUser.user);
    const [form] = Form.useForm();

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            width: 50,
            render: (value: number, item: TWorksheetTruckDischargeView, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
        },
        {
            title: "วัน-เวลาที่ปล่อยรถ",
            dataIndex: "truckDischargeEntryDate",
            key: "truckDischargeEntryDate",
            width: 150,
            render: (value: any, item: TWorksheetTruckDischargeView) => (<>{toThDate(item.truckDischargeEntryDate)} : {formatTime(item.truckDischargeEntryTime)}</>)
        },
        {
            title: "เลขที่ใบงาน",
            dataIndex: "worksheetCode",
            key: "worksheetCode", render: (value: string, item: TWorksheetTruckDischargeView) => (
                <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.iworksheetCode}</Button>
            )
        },
        {
            title: "รายการ",
            dataIndex: "serviceName",
            key: "serviceName",
        },
        {
            title: "พนักงานขับรถ",
            dataIndex: "idriverName",
            key: "idriverName",
            with: 120,
            // render: (value: any, item: TWorksheetTruckDischargeView) => (<>{item.idriverCode ? (item.idriverCode) : ""} {item.idriverName}</>)
        },
        {
            title: "เลขไมล์ออก",
            dataIndex: "truckMileLeave",
            key: "truckMileLeave",
            align: "right" as "right",
            render: (value: any, item: TWorksheetTruckDischargeView) => {
              return <span>{formatNumberInt(item.truckMileLeave)}</span>;
            },
            
        },
        {
            title: "หมายเหตุ",
            dataIndex: "truckDischargeRemark",
            key: "truckDischargeRemark",
        },
        {
            title: "ผู้ปล่อยรถ",
            dataIndex: "truckDischargeName",
            key: "truckDischargeName",
        },
    ];

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
        form.setFieldsValue({
            worksheetCode: "",
            fromDate: moment(new Date(), Constants.DATE_FORMAT),
            toDate: moment(new Date(), Constants.DATE_FORMAT)
        });
        form.submit();
    }, []);

    const onItemCliked = (item: TWorksheetTruckDischargeView) => {
        setSelectedTruckDischarge(item);
        setVisibleAddTruckDischargeDrawer(true);
    };

    const onFinish = async (values: any) => {
        setLoading(true);
        const branchId = userInfo.branchId;
        let worksheetCode: string = values.worksheetCode ? values.worksheetCode : "";
        let fromDate: string = values.fromDate ? moment(values.fromDate).format(Constants.DB_FORMAT) : "";
        let toDate: string = values.toDate ? moment(values.toDate).format(Constants.DB_FORMAT) : "";
        
        if(worksheetCode && worksheetCode.length >= 7) {
            fromDate = "";
            toDate= "";
        } else if (worksheetCode.length > 0 &&  worksheetCode.length < 7) {
            showNotification(NotificationType.WARNING, "กรุณาป้อนเลขที่ใบงานให้มากกว่า 7 ตัวอักษร");
            form.setFieldsValue({
                worksheetCode: "",
            });
            setLoading(false);
            return;
        }
        const res = await tWorksheetHeadService.getWorksheetTruckDischarges(branchId, worksheetCode, fromDate, toDate);
        setTruckDischarges(res.data);
        setLoading(false);
    }

    const onFinishFailed = (errorInfo: any) => {
    };

    const onAddTruckDischarge = () => {
        history.replace("/app/truckDischarge/truckUnDischargeList");
    }

    return (
        <>
            <PageHeader title="โปรแกรมปล่อยรถ">
                <Button
                    size="small"
                    type="primary"
                    icon={<PlusSquareOutlined />}
                    onClick={onAddTruckDischarge}
                >
                    เพิ่ม
                </Button>
            </PageHeader>
            <Content className="app-page-content">
                <Form
                    form={form}
                    name="bankBranchHead"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "baseline",
                            margin: "1em 0 1em 0",
                            paddingLeft: "1em",
                            borderBlockEnd: "2px solid #c9c9c9",
                        }}
                    >
                        <Space direction="vertical" size={"small"}>
                            <Form.Item
                                label="เลขที่ใบงาน"
                                name="worksheetCode"
                                rules={[{ required: false }]}
                            >
                                <Input
                                    allowClear
                                    placeholder="ป้อนเลขที่ใบงาน"
                                    // onChange={form.submit}
                                    onKeyDown={(evt: any) => {
                                        if(evt.keyCode == 13){
                                            form.submit()
                                        }
                                    }}
                                ></Input>
                            </Form.Item>
                            <Space align="baseline" size={"large"}>
                                <Form.Item
                                    label="วันที่ปล่อยรถตั้งแต่"
                                    name="fromDate"
                                    rules={[{ required: false }]}
                                >
                                    <DatePicker
                                        format={Constants.DATE_FORMAT}
                                        size="middle"
                                        placeholder="เลือกวันที่"
                                        onChange={form.submit}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="ถึง"
                                    name="toDate"
                                    rules={[{ required: false }]}
                                >
                                    <DatePicker
                                        format={Constants.DATE_FORMAT}
                                        size="middle"
                                        placeholder="เลือกวันที่"
                                        onChange={form.submit}
                                    />
                                </Form.Item>
                            </Space>
                        </Space>
                    </div>
                </Form>
                <div>
                    <Table
                        columns={columns}
                        dataSource={truckDischarges}
                        size="small"
                        loading={loading}
                        pagination={{
                            pageSize: 15,
                            onChange(current) {
                                setPage(current);
                            }
                        }}
                        rowKey={(item: TWorksheetTruckDischargeView) => item.ireserveCode!}
                        onRow={(item: TWorksheetTruckDischargeView) => {
                            return {
                                onDoubleClick: () => onItemCliked(item),
                            };
                        }}
                    />
                </div>
            </Content>
            <div> {
                visibleAddTruckDischargeDrawer && <AddTruckDischargeDrawer
                    visibleMode={"VIEW"}
                    selectedTruckDischarge={selectedTruckDischarge}
                    selectedTruckDischarges={[]}
                    visibleAddTruckDischargeDrawer={visibleAddTruckDischargeDrawer}
                    setVisibleAddTruckDischargeDrawer={setVisibleAddTruckDischargeDrawer} />
            }</div>
        </>
    );
};

export default TruckDischargeList;
