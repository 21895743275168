import { CloseCircleOutlined, ExclamationCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, InputNumber, Modal, Row, Select, Space, Spin, Switch, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BsAmphurH } from "src/domain/bsAmphurH";
import { BsPostcodeH } from "src/domain/bsPostcodeH";
import { BsProvinceH } from "src/domain/bsProvinceH";
import { TPrenameRef } from "src/domain/tPrenameRef";
import { TTypepaymentRef } from "src/domain/tTypepaymentRef";
import bsAmphurHService from "src/services/province/bsAmphurHService";
import bsPostalcodeHService from "src/services/province/bsPostalcodeHService";
import bsProvinceHService from "src/services/province/bsProvinceHService";
import tPrenameRefService from "src/services/reference/tPrenameRefService";
import tTypepaymentRefService from "src/services/reference/tTypepaymentRefService";
import { useAppSelector } from "src/stores/store";
import { getFlg } from "src/utils/rodyokUtils";
import { TCustomerHead } from "../../domain/master/tCustomerHead";
import tCustomerService from "../../services/customer/tCustomerHeadService";
import { NotificationType, showNotification } from "../../utils/notificationUtils";

const { Option } = Select;
const { confirm } = Modal;

interface IParams {
    icustCode: string;
    visibleAddEditCustomerHeadDrawer: boolean;
    setVisibleAddEditCustomerHeadDrawer: any;
}

const AddEditCustomerHeadDrawer: React.FC<IParams> = (props) => {
    const { icustCode, visibleAddEditCustomerHeadDrawer: visibleCustomerHeadDrawer, setVisibleAddEditCustomerHeadDrawer: setVisibleCustomerHeadDrawer } = props;
    const [trigger, setTrigger] = useState<boolean>(false);
    const [customerHead, setCustomerHead] = useState<TCustomerHead>({} as TCustomerHead);
    const [form] = Form.useForm();
    const history = useHistory();
    const [showSpin, setShowSpin] = useState<boolean>(false);

    const [prenames, setPrenames] = useState<TPrenameRef[]>([]);
    const [provinces, setProvinces] = useState<BsProvinceH[]>([]);
    const [amphurs, setAmphurs] = useState<BsAmphurH[]>([]);
    const [postCodeList, setPostCodeList] = useState<BsPostcodeH[]>([]);//BsPostcodeH
    const [paymentTypeRefs, setPaymentTypeRefs] = useState<TTypepaymentRef[]>([]);
    const userInfo = useAppSelector((state) => state.authen.authenUser.user);

    let title: string = "เพิ่มข้อมูลลูกค้า";
    if (icustCode) {
        title = "แก้ไขข้อมูลลูกค้า";
    }

    useEffect(() => {
        if (trigger) {
            form.validateFields()
                .then(() => {
                    confirm({
                        title: "ยืนยัน!",
                        icon: <ExclamationCircleOutlined />,
                        content: "คุณต้องการที่จะบันทึกข้อมูลหรือไม่?",
                        cancelText: "ยกเลิก",
                        okText: "ยืนยัน",
                        onOk() {
                            form.submit();
                        },
                        onCancel() {
                            setTrigger(false);
                        }
                    });
                }).catch(() => {
                    setTrigger(false);
                });
        }
    }, [trigger]);

    useEffect(() => {
        setShowSpin(true);
        initial();
        return () => {
        };
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            icustCode: customerHead.icustCode,
            iprenameCode: customerHead.iprenameCode,
            iname: customerHead.iname,
            isurname: customerHead.isurname,
            iphone: customerHead.iphone,
            imobile: customerHead.imobile,
            ifax: customerHead.ifax,
            iaddress: customerHead.iaddress,
            iprovincecode: customerHead.iprovincecode,
            iamphurcode: customerHead.iamphurcode,
            ipostcode: customerHead.ipostcode,
            icontractName: customerHead.icontractName,
            itypecustomerCode: customerHead.itypecustomerCode,
            itypepaymentCode: customerHead.itypepaymentCode,
            idebitorCode: customerHead.idebitorCode,
            iemail: customerHead.iemail,
            idltFlg: getFlg(customerHead.idltFlg),
            phonecontinue: customerHead.phonecontinue,
            tin: customerHead.tin,
            iremark: customerHead.iremark,
            idiscount: customerHead.idiscount ? customerHead.idiscount : 0,
            iidCard: customerHead.iidCard,
        });
        return () => {
        };
    }, [customerHead]);

    const initial = async () => {
        const prenamesRes = await tPrenameRefService.getTPrenameRefList();
        const provinceRes = await bsProvinceHService.getBsProvinceHs("");
        const amphurRes = await bsAmphurHService.getBsAmphurHs();
        const bsPostcodeHRes = await bsPostalcodeHService.getBsPostcodeHList();
        const paymentTypeRefs = await tTypepaymentRefService.getTTypepaymentRefList();

        if (icustCode) {
            const res = await tCustomerService.getCustomerHeadById(icustCode);
            setCustomerHead(res.data);
        } else {
            const nextCodeRes = await tCustomerService.getNextCustomerHeadCode();
            setCustomerHead({ ...customerHead, icustCode: nextCodeRes.data });
        }

        setPrenames(prenamesRes.data)
        setProvinces(provinceRes.data);
        setAmphurs(amphurRes.data);
        setPostCodeList(bsPostcodeHRes.data);
        setPaymentTypeRefs(paymentTypeRefs.data);
        setShowSpin(false);
    };

    const handleSearchAmphur = async (data: any) => {
        const iprovinceId = form.getFieldValue("iprovincecode");
        if (data.length < 3) return;
        if (!iprovinceId) {
            const res = await bsAmphurHService.getBsAmphurHByName(data);
            setAmphurs(res.data);
        } else {
            const res = await bsAmphurHService.getBsAmphurHByNameAndProvinceId(data, iprovinceId);
            setAmphurs(res.data);
        }
    };

    const handleSelectAmphur = async (amphurId: any) => {
        const foundAmphur = (amphurs as Array<BsAmphurH>).find((obj) => obj.iid === amphurId);
        const res = await bsPostalcodeHService.getBsPostcodeHByNumberAndProvinceId("", foundAmphur?.iprovinceId!);
        setPostCodeList(res.data);

        form.setFieldsValue({
            iprovincecode: foundAmphur?.iprovinceId,
            ipostcode: undefined
        });
    }
  
    const handleSelectProvince = async (provinceId: any) => {
      const bsAmphurHRes = await bsAmphurHService.getBsAmphurHByProviceId(provinceId);
      const res = await bsPostalcodeHService.getBsPostcodeHByNumberAndProvinceId("", provinceId);
      setAmphurs(bsAmphurHRes.data);
      setPostCodeList(res.data);
  
      form.setFieldsValue({
        iamphurcode: undefined,
        ipostcode: undefined
      });
    }
  
    const handleSearchPostalCode = async (searchText: any) => {
        const iprovinceId = form.getFieldValue("iprovincecode");
        if (searchText.length < 3) return;
    
        if(!iprovinceId) {
          const res = await bsPostalcodeHService.getBsPostcodeHs(searchText);
          setPostCodeList(res.data);
        } else {
          const res = await bsPostalcodeHService.getBsPostcodeHByNumberAndProvinceId(searchText, iprovinceId);
          setPostCodeList(res.data);
        }
      };
        
    // const handleChangePostCode = async (e: any) => {
    //     const ipostcode = form.getFieldValue("ipostcode");
    //     if (ipostcode.length < 5) return;
    //     const bsPostCodeRes = await bsPostalcodeHService.getBsPostcodeHs(ipostcode);
    //     const bsProvinceRes = await bsProvinceHService.getBsProvinceHById(bsPostCodeRes.data[0].iprovinceId);
    //     const bsAmphurHRes = await bsAmphurHService.getBsAmphurHByProviceId(bsProvinceRes.data.iid);
    //     setAmphurs(bsAmphurHRes.data);
    //     form.setFieldsValue({
    //         iprovincecode: bsProvinceRes.data.iid,
    //         // iamphurcode: undefined,
    //     });
    // }
  
    const handleSelectPostCode = async (ipostcodeNo: any) => {    
      const bsPostCodeRes = await bsPostalcodeHService.getBsPostcodeHs(ipostcodeNo);
      const bsProvinceRes = await bsProvinceHService.getBsProvinceHById(bsPostCodeRes.data[0].iprovinceId);
      const bsAmphurHRes = await bsAmphurHService.getBsAmphurHByProviceId(bsProvinceRes.data.iid);
      setAmphurs(bsAmphurHRes.data);
      form.setFieldsValue({
        iprovincecode: bsProvinceRes.data.iid,      
        // iamphurcode: undefined,
      });
    }

    const onFinish = async (values: any) => {
        const _customerHead: TCustomerHead = {
            icustCode: values.icustCode,
            iprenameCode: values.iprenameCode,
            iname: values.iname,
            isurname: values.isurname,
            iphone: values.iphone,
            imobile: values.imobile,
            ifax: values.ifax,
            iaddress: values.iaddress,
            iprovincecode: values.iprovincecode,
            iamphurcode: values.iamphurcode,
            ipostcode: values.ipostcode,
            icontractName: values.icontractName,
            itypecustomerCode: "00",
            itypepaymentCode: values.itypepaymentCode,
            idebitorCode: values.idebitorCode,
            iemail: values.iemail,
            idltFlg: values.idltFlg ? 0 : -1,
            phonecontinue: values.phonecontinue,
            tin: "",
            iremark: "",
            idiscount: values.idiscount,
            iidCard: values.iidCard ? values.iidCard.replaceAll("-", "") : "",
            isavStation: userInfo.saveStation,
            icreateUser: userInfo.userId,
            ilastUser: userInfo.userId,
            itermNo: userInfo.termNo
        };

        if (!icustCode) {
            try {
                const res = await tCustomerService.postCustomerHead(_customerHead);
                showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลลูกค้าเรียบร้อยแล้ว!");
                form.resetFields();
                setTrigger(false);
                setVisibleCustomerHeadDrawer(false);
                history.replace("/app/customer/view/" + res.data.icustCode);
            } catch (err) {
                showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
                setTrigger(false);
            }
        } else {
            try {
                await tCustomerService.updateCustomerHead(_customerHead);
                showNotification(NotificationType.SUCCESS, "แก้ไขข้อมูลลูกค้าเรียบร้อยแล้ว!");
                form.resetFields();
                setTrigger(false);
                setVisibleCustomerHeadDrawer(false);
                history.replace("/app/customer/view/" + customerHead.icustCode);
            } catch (err) {
                showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
                setTrigger(false);
            }
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน !");
        setTrigger(false);
    };

    return (
        <>
            <Drawer
                title={
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "baseline",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography.Title level={4}>{title}</Typography.Title>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "",
                            }}
                        >
                            <Button
                                type="primary"
                                danger
                                icon={<CloseCircleOutlined />}
                                onClick={() => setVisibleCustomerHeadDrawer(false)}>
                                ยกเลิก
                            </Button>
                            <Button
                                onClick={() => setTrigger(true)}
                                type="primary"
                                icon={<SaveOutlined />}
                                style={{ marginLeft: "5px" }}
                            >
                                บันทึก
                            </Button>
                        </div>
                    </div>
                }
                width="50%"
                visible={visibleCustomerHeadDrawer}
                forceRender={true}
                placement="right"
                bodyStyle={{ paddingBottom: 80 }}
                closable={false}
                destroyOnClose={true}
            >
                <Content className="app-page-content">
                    <div>
                        <Spin spinning={showSpin}>
                            <Form
                                form={form}
                                name="customerHead"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>รหัสลูกค้า: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={122}>
                                            <Form.Item
                                                name="icustCode"
                                                rules={[
                                                    { required: true }
                                                ]}
                                            >
                                                <Input disabled={true} />
                                            </Form.Item>

                                            <Form.Item
                                                label="เลขที่บัญชีลูกหนี้"
                                                name="idebitorCode"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Input disabled={true} />
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>ชื่อ-สกุล: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Space>
                                            <Form.Item
                                                name="iprenameCode"
                                                rules={[
                                                    { required: true, message: "กรุณาเลือกคำนำหน้า" }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    size="middle"
                                                    placeholder="เลือกคำนำหน้าชื่อ"
                                                    optionFilterProp="children"
                                                    filterOption={true}
                                                    style={{ width: "137px" }}
                                                >
                                                    {prenames.map((obj) => {
                                                        return (
                                                            <Option key={obj.iprenameCode} value={obj.iprenameCode}>
                                                                {obj.iprenameName}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                name="iname"
                                                rules={[
                                                    { required: true, message: "กรุณาป้อนชื่อ" },
                                                    {
                                                        validator: (_, value) => {
                                                            if (value && (value === " " || value === "-")) {
                                                                return Promise.reject(new Error('กรุณาป้อนข้อมูลชื่อลูกค้าให้ถูกต้อง!'))
                                                            } else {
                                                                return Promise.resolve()
                                                            }
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="ป้อนชื่อ" style={{ width: "240px" }}></Input>
                                            </Form.Item>
                                            <Form.Item
                                                name="isurname"
                                                rules={[
                                                    {
                                                        validator: (_, value) => {
                                                            const prenameValue = form.getFieldValue('iprenameCode');
                                                            if (value && prenameValue === "09" && (value === " " || value === "-")) {
                                                                return Promise.reject(new Error('กรุณาป้อนข้อมูลนามสกุลให้ถูกต้อง!'))
                                                            } if (value && prenameValue === "09" && value.length < 4) {
                                                                return Promise.reject(new Error('กรุณาป้อนข้อมูลนามสกุลมากกว่า 4 ตัวอักษร!'))
                                                            } else {
                                                                return Promise.resolve()
                                                            }
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="ป้อนนามสกุล" style={{ width: "235px" }}></Input>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>เลขที่บัตรประชาชน: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="iidCard"
                                            rules={[
                                                { required: false }
                                            ]}
                                        >
                                            <Input style={{ width: "200px" }} ></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>ที่อยู่/ที่ตั้ง: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="iaddress"
                                            rules={[
                                                { required: true, message: "กรุณาป้อนที่อยู่" },
                                                { min: 5, message: "กรุณาป้อนที่อยู่ให้ถูกต้อง" }
                                            ]}
                                        >
                                            <TextArea
                                                style={{ width: "625px" }}
                                                rows={3}
                                                name="iaddress"
                                            ></TextArea>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>อำเภอ: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Space>
                                            <Form.Item
                                                name="iamphurcode"
                                                rules={[
                                                    { required: true, message: "กรุณาเลือกอำเภอ" }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    size="middle"
                                                    placeholder="เลือกอำเภอ"
                                                    optionFilterProp="children"
                                                    filterOption={true}
                                                    style={{ width: "200px" }}
                                                    onSearch={handleSearchAmphur}
                                                    onSelect={handleSelectAmphur}
                                                >
                                                    {amphurs.map((obj) => {
                                                        return (
                                                            <Option key={obj.iid} value={obj.iid}>
                                                                {obj.iamphurName}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                label="จังหวัด"
                                                name="iprovincecode"
                                                rules={[
                                                    { required: true, message: "กรุณาเลือกจังหวัด" }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    size="middle"
                                                    placeholder="เลือกจังหวัด"
                                                    optionFilterProp="children"
                                                    filterOption={true}
                                                    style={{ width: "200px" }}
                                                    onSelect={handleSelectProvince}
                                                >
                                                    {provinces.map((obj) => {
                                                        return (
                                                            <Option key={obj.iid} value={obj.iid}>
                                                                {obj.iprovinceName}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>รหัสไปรษณีย์: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="ipostcode"
                                            style={{ marginBottom: "3px", width: 200 }}
                                            rules={[{ required: true, message: "กรุณาระบุ รหัสไปรษณีย์ !" }]}>
                                            <Select
                                                showSearch
                                                size="middle"
                                                placeholder="เลือกรหัสไปรษณีย์"
                                                optionFilterProp="children"
                                                filterOption={true}
                                                onSearch={handleSearchPostalCode}
                                                onSelect={handleSelectPostCode}
                                            >
                                                {postCodeList.map((obj) => {
                                                    return (
                                                        <Option key={obj.ipostcodeNo} value={obj.ipostcodeNo}>
                                                            {obj.ipostcodeNo}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>โทร: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={"middle"}>
                                            <Form.Item
                                                name="iphone"
                                                rules={[
                                                    { required: true, message: "กรุณาป้อนเบอร์โทร!" },
                                                    {
                                                        validator: (_, value) => {
                                                            if (value && (value === " " || value === "-")) {
                                                                return Promise.reject(new Error('กรุณาป้อนเบอร์โทรให้ถูกต้อง!'))
                                                            } else {
                                                                return Promise.resolve()
                                                            }
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="ป้อนเบอร์โทร" maxLength={20}></Input>
                                            </Form.Item>
                                            <Form.Item
                                                label="ต่อ"
                                                name="phonecontinue"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Input style={{ width: "220px" }} maxLength={5}></Input>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>มือถือ: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={"middle"}>
                                            <Form.Item
                                                name="imobile"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Input placeholder="ป้อนเบอร์มือถือ" style={{ width: "200px" }} maxLength={20}></Input>
                                            </Form.Item>
                                            <Form.Item
                                                label="Fax"
                                                name="ifax"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Input placeholder="ป้อน Fax" style={{ width: "213px" }} maxLength={20}></Input>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>Email: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="iemail"
                                            rules={[
                                                { required: false }
                                            ]}
                                        >
                                            <Input placeholder="ป้อน Email" style={{ width: "464px" }} maxLength={30}></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>การชำระเงิน: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="itypepaymentCode"
                                            rules={[
                                                { required: true, message: "กรุณาเลือกการชำระเงิน" }
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                size="middle"
                                                placeholder="เลือกการชำระเงิน"
                                                optionFilterProp="children"
                                                filterOption={true}
                                                style={{ width: "200px" }}
                                            >
                                                {paymentTypeRefs.map((obj) => {
                                                    return (
                                                        <Option key={obj.iid} value={obj.iid}>
                                                            {obj.iname}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>บุคคลอ้างอิง: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="icontractName"
                                            rules={[
                                                { required: false }
                                            ]}
                                        >
                                            <Input placeholder="ป้อนบุคคลอ้างอิง" style={{ width: "200px" }} maxLength={100}></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>ส่วนลด: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Space align="baseline">
                                            <Form.Item
                                                name="idiscount"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <InputNumber
                                                    controls={false}
                                                    onFocus={(e) => e.target.select()}
                                                ></InputNumber>
                                            </Form.Item>
                                            <Typography.Text> %</Typography.Text>
                                        </Space>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>สภานะ: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="idltFlg"
                                            rules={[
                                                { required: false }
                                            ]}
                                            valuePropName="checked"
                                        >
                                            <Switch checkedChildren="ใช้งาน" unCheckedChildren="ไม่ใช้งาน" defaultChecked={true} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Spin>
                    </div>
                </Content>
            </Drawer>
        </>
    );
};

export default AddEditCustomerHeadDrawer;
