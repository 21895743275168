import { CloseSquareOutlined, EllipsisOutlined, FormOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, DatePicker, Dropdown, Input, Menu, Row, Select, Spin, Tabs } from "antd";
import type { MenuProps } from 'antd';
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useEffect, useState } from "react";
import A001 from "src/assets/images/assetgroup/001/001_02.png"; //รถ
import A002 from "src/assets/images/assetgroup/002/002.png";
import { AssetWorksheet } from "src/domain/chart/assetWorksheet";
import { TTypetruckRef } from "src/domain/tTypetruckRef";
import { TWorksheetHead } from "src/domain/worksheet/tWorksheetHead";
import PageHeader from "src/layouts/PageHeader";
import tTypetruckRefService from "src/services/reference/tTypetruckRefService";
import { useAppSelector } from "src/stores/store";
import Constants from "src/utils/constants";
import { dateToString } from "src/utils/dateTimeUtils";
import { NotificationType, showNotification } from "src/utils/notificationUtils";
import { ChartTab } from "../../domain/chart/chartTab";
import chartviewService from "../../services/chart/chartviewService";
import ChartBookingForm from "./ChartBookingForm";
import ChartNewWorksheet from "./ChartNewWorksheet";
import WorksheetListByAssetModal from "./modal/WorksheetListByAssetModal";

const { Meta } = Card;
const { Option } = Select;
const { TabPane } = Tabs;

const ChartView: React.FC = (props) => {
  const [onDrawerClose, setOnDrawerClose] = useState(false);
  const [openDrawerView, setOpenDrawerView] = useState(false);
  const [selectedReserveCode, setSelectedReserveCode] = useState<string>();
  const [selectedAsset, setSelectedAsset] = useState<AssetWorksheet>({} as AssetWorksheet);
  const [selcteAssetWshList, setSelcteAssetWshList] = useState<TWorksheetHead[]>([]);
  const [tTypeTruckRefId, setTTypeTruckRefId] = useState("");
  const [jobDate, setJobDate] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [showSpin, setShowSpin] = useState<boolean>(false);
  const [visibleWorksheetListByAsset, setVisibleWorksheetListByAsset] = useState<boolean>(false);

  const [tTypeTruckRefList, setTTypeTruckRefList] = useState<TTypetruckRef[]>([]);
  const [searchResult, setSearchResult] = useState<ChartTab[]>([]);
  const [statePg, setStatePg] = useState<string>("ADD");
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  const tabs: Array<Object> = [];
  const colCount = 5;
  const now = moment(new Date(), Constants.DATE_FORMAT);

  const init = async () => {
    const assetGroupRes = await tTypetruckRefService.getTTypetruckRefList();
    setTTypeTruckRefList(assetGroupRes.data);

    // default date
    setJobDate(dateToString(now));
  };

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    if (!onDrawerClose) {
      init();
    }
  }, [onDrawerClose]);

  useEffect(() => {
    (async () => {
      if (isSearch) {
        const res = await chartviewService.getChartData(tTypeTruckRefId, jobDate, userInfo.branchId);
        const data = res.data;
        // data.forEach((chartTab: ChartTab) => {
        //   chartTab.chart.assetWorksheets.forEach((item: AssetWorksheet) => {
        //     const currentSize = item.worksheetSize;
        //     for (let j = 0; j < 3 - currentSize; j++) {
        //       let newWorksheetHead: TWorksheetHeadView = {} as TWorksheetHeadView;
        //       item.worksheetHeadList.push(newWorksheetHead);
        //     }
        //   })
        // })
        setSearchResult(data);
      }
      setIsSearch(false);
      setShowSpin(false);
    })();
  }, [isSearch]);

  const handleSearch = () => {
    setShowSpin(true);
    setIsSearch(true);
  };

  const handleChangeAssetGroup = (data: any) => {
    setTTypeTruckRefId(data);
  };

  const handleChangeDate = (data: any) => {
    if (data) {
      setJobDate(dateToString(data));
    }
  };

  const handleOpenWorksheetListByAsset = (assetWorksheetList: TWorksheetHead[]) => {
    if (assetWorksheetList) {
      setSelcteAssetWshList(assetWorksheetList);
      setVisibleWorksheetListByAsset(true);
    } else {
      showNotification(NotificationType.ERROR, "ไม่พบข้อมูลใบงาน");
      setVisibleWorksheetListByAsset(false);
    }
  }

  const handleOpenViewDrawer = (ireserveCode?: string) => {
    setSelectedReserveCode(ireserveCode);
    setOpenDrawerView(true);
  }
  const handleOpenDrawer = (assetWorksheet: AssetWorksheet) => {
    setSelectedAsset(assetWorksheet);
    setSelectedReserveCode("");
    setOnDrawerClose(true);
  }

  // const getItemColor = (worksheetHead: TWorksheetHeadView) => {
    const getItemColor = (worksheetHead: TWorksheetHead) => {
    let style = { backgroundColor: "white", width:"100%", margin: 3, color:"#253133", borderRadius:"3px" };

    style.backgroundColor = worksheetHead.chartStatusBgColor ? worksheetHead.chartStatusBgColor : "white";
    style.color = worksheetHead.chartStatusFntColor ? worksheetHead.chartStatusFntColor : "#253133";
    return style;
  }

  // const getbtnColor = (worksheetHead: TWorksheetHeadView) => {
  const getbtnColor = (worksheetHead: TWorksheetHead) => {
    let style = {backgroundColor: "red", width:"20%", height:"25px", margin: "3px 0px 2px 0px", color:"#253133"}
    style.backgroundColor = worksheetHead.chartStatusBgColor ? worksheetHead.chartStatusBgColor : "white";
    style.color = worksheetHead.chartStatusFntColor ? worksheetHead.chartStatusFntColor : "#253133";
    return style;
  }

  // const getShowButton = (disableAdd: boolean, worksheetHead: TWorksheetHead, selectedAsset: AssetWorksheet) => { 
  //   if (worksheetHead.chartStatusBgColor &&  worksheetHead.chartStatusBgColor !== "red") {
  //     <Button
  //       type="text"
  //       size="middle"
  //       style={getbtnColor(worksheetHead)}
  //       icon={<FormOutlined />}
  //       onClick={() => handleOpenViewDrawer(worksheetHead.ireserveCode)}
  //     ></Button>
  //   } else if (!worksheetHead.chartStatusBgColor) {
  //     <Button
  //       type="text"
  //       size="middle"
  //       disabled={disableAdd}
  //       style={getbtnColor(worksheetHead)}
  //       icon={<PlusSquareOutlined />}
  //       onClick={() => handleOpenDrawer(selectedAsset)}
  //     ></Button>
  //   }
  //  }

  //Row and Column
  searchResult?.forEach((chartTab) => {
    let cols: Array<Object> = [];
    tabs.push(
      <TabPane tab={chartTab.treferenceDetail.name} key={chartTab.treferenceDetail.id} >
        {
          chartTab.chart?.assetWorksheets.forEach((item) => {
            let metas: Array<Object> = [];
            cols.push(<Col key={item.id} span={24 / colCount}>
              <Card
                extra={
                  <Dropdown
                    overlay={
                      <Menu
                        style={{ backgroundColor: "#3178C6" }}
                      >
                        <Menu.Item
                          style={{ backgroundColor: "#3178C6", color: "#FFF" }}
                          key="MENU_1"
                          onClick={() => handleOpenDrawer(item)}
                        >
                          สร้างใบจอง
                        </Menu.Item>
                      </Menu>
                    }
                    placement="topLeft"
                    trigger={["click"]}
                    destroyPopupOnHide
                  >
                    <EllipsisOutlined key="ellipsis" style={{ color: "#FFF", fontSize: "1.25rem", cursor: "pointer" }} />
                  </Dropdown>
                }
                style={{
                  width: 250,
                  borderRadius: "21px 21px 21px 21px",
                  backgroundColor: "#F1F1F1",
                }}
                headStyle={{
                  fontSize: 14,
                  color: "#fff",
                  backgroundColor: "#052067",
                  borderRadius: "22px 22px 0 0",
                }}
                title={
                  <span>
                  <Button
                    type="primary"
                    danger
                    shape="circle"
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      margin: "0 5px 10px 0",
                    }}                    
                    onClick={() => handleOpenWorksheetListByAsset(item.worksheetHeadList)}
                  >
                    {item.worksheetSize}
                  </Button>
                  {item.numbers}
                  </span>
                }
                // cover={<img alt="example" src={path} style={{ padding: "4px" }} />}
                key={"card-" + item.id}
              // cover={()=>showImage(item)}
              >
                {item.worksheetHeadList.forEach((worksheetHead, index) => {
                  // const disableAdd = (item.assettype != "003") ? false : true;
                  const disableAdd = (worksheetHead.chartStatusBgColor === "red" || (worksheetHead.chartStatusBgColor && !worksheetHead.ireserveCode)) ? true : false;
                  metas.push(
                    <Meta
                      key={"meta-" + item.id + "-" + index}
                      style={{ fontWeight: "bolder" }}
                      description={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <p 
                            //style={{backgroundColor: "red", width:"100%", margin: 3, color:"#253133", borderRadius:"3px", textAlign:"center"}}
                            style={getItemColor(worksheetHead)}
                          >
                            {worksheetHead.chartRemarkRepairOrWorkCode ? worksheetHead.chartRemarkRepairOrWorkCode : "-" } <br/>{worksheetHead.chartRemarkCustName}
                          </p>
                          {/* {() => getShowButton(disableAdd, worksheetHead, item)} */}
                          {(worksheetHead.ireserveCode && worksheetHead.chartStatusBgColor) ? (
                            <Button
                              type="text"
                              size="middle"
                              // style={{backgroundColor: "red", width:"20%", height:"25px", margin: "3px 0px 2px 0px", color:"#253133", textAlign: "center"}}
                              style={getbtnColor(worksheetHead)}
                              icon={<FormOutlined />}
                              onClick={() => handleOpenViewDrawer(worksheetHead.ireserveCode)}
                            ></Button>
                          ) : (
                            <Button
                              type="text"
                              size="middle"
                              disabled={disableAdd}
                              // style={{backgroundColor: "red", width:"20%", height:"25px", margin: "3px 0px 2px 0px", color:"#304042", textAlign: "center"}}
                              style={getbtnColor(worksheetHead)}
                              icon={!worksheetHead.chartStatusBgColor ? <PlusSquareOutlined /> : <CloseSquareOutlined />}
                              onClick={() => handleOpenDrawer(item)}
                            ></Button>
                          )}
                        </div>
                      }
                    />
                  );
                })}
                {metas}
              </Card>
            </Col>)
          })
        }
        <Row gutter={[8, 8]} >
          {cols}
        </Row>
      </TabPane>
    )
  });
  return (
    <>
      <PageHeader title="Chart View"></PageHeader>
      <Content>
        <div
          style={{
            flexDirection: "row",
            alignItems: "baseline",
            margin: "2em 0 1em 0",
            padding:"1em",
            borderBlockEnd: "2px solid #c9c9c9",
          }}
        >
          <label style={{ margin: "0 1em 1em 2em" }}>เลือกประเภทรถ</label>
          <Select
            showSearch
            style={{ width: "250px" }}
            size="middle"
            placeholder="เลือกประเภทรถ"
            optionFilterProp="children"
            onChange={handleChangeAssetGroup}
            filterOption={true}
          >
            {tTypeTruckRefList.map((obj) => {
              return (
                <Option value={obj.iid} key={obj.iid}>
                  {obj.iid} - {obj.iname}
                </Option>
              );
            })}
          </Select>

          <label style={{ margin: "0 1em 1em 2em" }}>วันที่</label>
          <DatePicker
            defaultValue={now}
            // showNow
            format={Constants.DATE_FORMAT}
            size="middle"
            style={{ width: "200px" }}
            // onBlur={handleChangeDate}
            onChange={handleChangeDate}
          />
          <Button
            size="middle"
            type="primary"
            style={{ margin: "0 0 0 1em" }}
            onClick={handleSearch}
          >
            ค้นหา
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            // margin: "0em 1em 1em 1em",
            // borderBlockEnd: "2px solid #c9c9c9",
          }}
        >
          <Input style={{ backgroundColor: "red", width: 20, height: 20, margin: "0px 5px 10px 20px" }} readOnly></Input>
          <label>สถานะซ่อม</label>
          <Input style={{ backgroundColor: "#FFDE00", width: 20, height: 20, margin: "0px 5px 10px 20px" }} readOnly></Input>
          <label>สถานะจอง</label>          
          <Input style={{ backgroundColor: "pink", width: 20, height: 20, margin: "0px 5px 10px 20px" }} readOnly></Input>
          <label>สถานะรอโทร</label>
          <Input style={{ backgroundColor: "#71B200", width: 20, height: 20, margin: "0px 5px 10px 20px" }} readOnly></Input>
          <label>สถานะทำงาน</label>
        </div>

        <Spin spinning={showSpin}>
          <Tabs
            defaultActiveKey="0"
            type="card"
            style={{ marginLeft: "1em", marginRight: "1em" }}
          >
            {tabs}
          </Tabs>
        </Spin>
      </Content>
      <div>
        {onDrawerClose && (
          <ChartNewWorksheet
            statePg={statePg}
            selectedReserveCode={selectedReserveCode!}
            selectedAsset={selectedAsset}
            onDrawerClose={onDrawerClose}
            setOnDrawerClose={setOnDrawerClose}
          />
        )}
      </div>
      <div>
        {openDrawerView && (
          <ChartBookingForm
            selectedReserveCode={selectedReserveCode}
            openDrawerView={openDrawerView}
            setOpenDrawerView={setOpenDrawerView}
          />
        )}
      </div>
      <div>
        {visibleWorksheetListByAsset && (
          <WorksheetListByAssetModal
            visibleWorksheetListByAsset={visibleWorksheetListByAsset}
            setVisibleWorksheetListByAsset={setVisibleWorksheetListByAsset}
            selcteAssetWshList={selcteAssetWshList}
          />
        )}
      </div>
    </>
  );
};

export default ChartView;