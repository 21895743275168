import { DatePicker, Form, Input, InputNumber, Modal, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react'
import { TTaxinvoiceDetailView } from 'src/domain/view/tTaxinvoiceDetailView';
import Constants from 'src/utils/constants';
import { toMomentDate } from 'src/utils/dateTimeUtils';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber } from 'src/utils/numberUtils';

interface IProps {
  visibleModalEditDetail: boolean;
  setVisibleModalEditDetail: any;
  taxinvoiceDetailSelectEdit: TTaxinvoiceDetailView;
  setTaxinvoiceDetailSelectEdit: any
}

const EditTaxInvoiceDetailModal: React.FC<IProps> = (props) => {
  const { visibleModalEditDetail, setVisibleModalEditDetail, taxinvoiceDetailSelectEdit, setTaxinvoiceDetailSelectEdit } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  useEffect(() => {
    //Load ครั้งแรก ดึงค่ามาโชว์
    form.setFieldsValue({
      worksheetcode: taxinvoiceDetailSelectEdit.worksheetcode,
      debitnotecode: taxinvoiceDetailSelectEdit.debitnotecode,
      customername: taxinvoiceDetailSelectEdit.customername,
      serviceName: taxinvoiceDetailSelectEdit.serviceName,
      remarkDescriptions: taxinvoiceDetailSelectEdit.remarkDescriptions,
      startDate: toMomentDate(taxinvoiceDetailSelectEdit.startDate!),
      amount: taxinvoiceDetailSelectEdit.amount,
      paymentCode: taxinvoiceDetailSelectEdit.paymentCode,
    });
  }, []);

  const onFinish = async (values: any) => {
    setTaxinvoiceDetailSelectEdit({ ...taxinvoiceDetailSelectEdit, 
      remarkDescriptions: form.getFieldValue("remarkDescriptions")
    });
    form.resetFields();
    setTrigger(false);
    setVisibleModalEditDetail(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน !");
    setTrigger(false);
  };
  
  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>แก้ไขข้อความในใบกำกับภาษี</Typography.Title>
          </div>
        }
        visible={visibleModalEditDetail}
        width={"50%"}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setVisibleModalEditDetail(false)}
      >
        <Form
          form={form}
          name="taxInvoiceDetail"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="เลขที่ใบสั่งงาน"
            name="worksheetcode"
          >
            <Input
              disabled
              style={{ width: "50%" }}
            ></Input>
          </Form.Item>
          <Form.Item
            label="เลขที่ใบแจ้งหนี้"
            name="debitnotecode"
          >
            <Input
              disabled
              style={{ width: "50%" }}
            ></Input>
          </Form.Item>
          <Form.Item
            label="ชื่อลูกค้า"
            name="customername"
          >
            <Input disabled></Input>
          </Form.Item>
          <Form.Item
            label="รายการ"
            name="serviceName"
          >
            <TextArea
              disabled
              rows={3}
            ></TextArea>
          </Form.Item>
          <Form.Item
            label="ข้อความในใบกำกับ"
            name="remarkDescriptions"
            rules={[{ required: true, message: "กรุณาระบุข้อความในใบกำกับ !" }]}
          >
            <TextArea
              style={{ color: "blue" }}
              rows={3}
            ></TextArea>
          </Form.Item>
          <Form.Item
            label="วันที่ทำงาน"
            name="startDate"
          // style={{ margin: "0 0 3px 0px" }}
          >
            <DatePicker
              format={Constants.DATE_FORMAT}
              style={{ width: "50%" }}
              disabled
            />
          </Form.Item>
          <Form.Item
            label="จำนวนเงิน"
            name="amount"
          >
            <InputNumber
              controls={false}
              disabled
              formatter={(value) => formatNumber(value)}
              parser={(value) => parserNumber(value)}
              style={{
                width: "50%"
              }}
            ></InputNumber>
          </Form.Item>
          <Form.Item
            label="เลขที่การรับเงิน"
            name="paymentCode"
          >
            <Input
              style={{ width: "50%" }}
              disabled></Input>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default EditTaxInvoiceDetailModal