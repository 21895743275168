import { TChequeHead } from "src/domain/cheque/tChequeHead";
import http from "src/lib/http";
import Constants from "src/utils/constants";

const saveTChequeHead = (tChequeHead: TChequeHead) => {
  return http.post(Constants.DEFAULT_URL + "/tChequeHead/saveTChequeHead", tChequeHead);
};

const isExistChequeNumber = (chequeNumber: string, bankCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tChequeHead/isExistChequeNumber", {
    params: {
      chequeNumber: encodeURI(chequeNumber),
      bankCode,
    }
  });
};

const getByChequeNumber = (chequeNumber: string) => {
  return http.get(Constants.DEFAULT_URL + "/tChequeHead/getByChequeNumber", {
    params: {
      chequeNumber: encodeURI(chequeNumber),
    }
  });
};

const getByChequeStatus = (status: string, branchId: string) => {
  return http.get(Constants.DEFAULT_URL + "/tChequeHead/getByChequeStatus", {
    params: {
      status: status,
      branchId: branchId,
    }
  });
};

const defaultApi = {
  saveTChequeHead,
  isExistChequeNumber,
  getByChequeNumber,
  getByChequeStatus,
};
export default defaultApi;