import { ClearOutlined, PlusSquareOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Space, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TWorksheetInsuranceView } from 'src/domain/worksheet/view/tWorksheetInsuranceView';
import PageHeader from 'src/layouts/PageHeader';
import tWorksheetInsuranceService from 'src/services/tWorksheetInsuranceService';
import Constants from 'src/utils/constants';
import { toThDate } from 'src/utils/dateTimeUtils';

const FinanceInsuranceList: React.FC<TWorksheetInsuranceView> = () => {
  const [page, setPage] = React.useState(1);
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [tWorksheetInsuranceViews, setTWorksheetInsuranceViews] = useState<TWorksheetInsuranceView[]>([]);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 70,
      render: (value: number, item: TWorksheetInsuranceView, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
    },
    {
      title: "วันที่รับชำระ",
      dataIndex: "ientryDate",
      key: "ientryDate",
      width: 100,
      align: "center" as "center",
      render: (value: any, item: TWorksheetInsuranceView) => {
        return <span>{item.tfinanceHead2View.entrydate ? toThDate(item.tfinanceHead2View.entrydate) : ""}</span>;
      },
    },
    {
      title: "เลขที่การรับเงิน",
      dataIndex: "financeHead2Id",
      key: "financeHead2Id",
      width: 120,
      align: "center" as "center",
      render: (value: string, item: TWorksheetInsuranceView) => {
          return <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.tfinanceHead2View.id}</Button>
      }
    },
    {
      title: "เลขที่ใบงาน",
      dataIndex: "iworksheetStatusName",
      key: "iworksheetStatusName",
      width: 120,
      align: "center" as "center",
      render: (value: string, item: TWorksheetInsuranceView) => {
        if (isNaN(Number(item.iworksheetStatusName))) {
          return <span>{item.iworksheetStatusName}</span>;
        }
         else {
          // return <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.iworksheetStatusName}</Button>
          return <span>{item.iworksheetStatusName}</span>;
        }
      }
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "icustName",
      key: "icustName",
      width: 250,
    },
    {
      title: "รายการบริการ",
      dataIndex: "serviceName",
      key: "serviceName",
      width: 250,
    },
    {
      title: "ประเภทประกัน",
      dataIndex: "itypePaymentInsuranceName",
      key: "itypePaymentInsuranceName",
      width: 120,
    },
    {
      title: "หมายเหตุ",
      dataIndex: "iremark",
      key: "iremark",
      render: (value: string, item: TWorksheetInsuranceView) => {
        return <span>{getRemark(item)}</span>;
      }
    },
    {
      title: "ผู้บันทึก",
      dataIndex: "icreateUserName",
      key: "icreateUserName",
      width: 150,
    },
  ];

  const getRemark = (item: TWorksheetInsuranceView) => {
    let remark = item.iremark ? item.iremark : '';
    if (item.itypePaymentInsurance === '03') {
      remark = 'โอนเงินเข้าบัญชีเลขที่ ' + item.ipayinAccountnumber + ' - ' + (item.iremark ? item.iremark : '')
    } else if (item.itypePaymentInsurance !== '01' && item.itypePaymentInsurance !== '99') {
      remark = 'เลขที่ ' + item.iinsuranceNumber + ' - ' + (item.iremark ? item.iremark : '')
    }
    return remark;
  }

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    form.setFieldsValue({
      fromDate: moment(new Date(), Constants.DATE_FORMAT),
      toDate: moment(new Date(), Constants.DATE_FORMAT)
    });
    form.submit();
  }, []);

  const onItemCliked = (item: TWorksheetInsuranceView) => {
    history.replace("/app/financeInsurance/view/" + item.id);
  };

  const doSearch = async (values: any) => {
    setLoading(true);
    const fromDate = values.fromDate ? moment(values.fromDate).format("yyyyMMDD") : '';
    const toDate = values.toDate ? moment(values.toDate).format("yyyyMMDD") : '';
    const searchText = (values.worksheetCodeCustomerName) ? values.worksheetCodeCustomerName : "";
    const statusInsurances = ['01', '02'];
    if (searchText) {
      // const res = await tWorksheetInsuranceService.getPaymentInsuranceHeadByAll("", "", searchText, statusInsurances.toString());
      const res = await tWorksheetInsuranceService.getFinanceInsuranceList("", "", searchText);
      setTWorksheetInsuranceViews(res.data.WORKSHEET_INSURANCE_LIST);
    } else {
      // const res = await tWorksheetInsuranceService.getPaymentInsuranceHeadByAll(fromDate, toDate, "", statusInsurances.toString());
      const res = await tWorksheetInsuranceService.getFinanceInsuranceList(fromDate, toDate, "");
      setTWorksheetInsuranceViews(res.data.WORKSHEET_INSURANCE_LIST);
    }
    setLoading(false);
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  const onAdd = () => {
    history.replace("/app/financeInsurance/create/");
  }

  return (
    <>
      <PageHeader title="โปรแกรมการเงินรับชำระเงินประกัน" >
        <Button
          size="small"
          type="primary"
          icon={<PlusSquareOutlined />}
          onClick={onAdd}
        >
          เพิ่ม
        </Button>
      </PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="receiveCashWorksheet"
          onFinish={doSearch}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
            }}
          >
            <Space direction="vertical" size={"small"}>
              <Form.Item
                label="เลขที่ใบงาน/ชื่อลูกค้า"
                name="worksheetCodeCustomerName"
                rules={[{ required: false }]}
              >
                <Input
                  allowClear
                  placeholder="ป้อนเลขที่ใบงาน/ชื่อลูกค้า"
                ></Input>
              </Form.Item>
              <Space align="baseline" size={"large"}>
                <Form.Item
                  label="วันที่สร้างตั้งแต่"
                  name="fromDate"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    placeholder="เลือกวันที่"
                  />
                </Form.Item>
                <Form.Item
                  label="ถึง"
                  name="toDate"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    placeholder="เลือกวันที่"
                  />
                </Form.Item>
              </Space>
            </Space>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: 'space-between',
              padding: "1em",
              borderBlockEnd: "2px solid #c9c9c9",
            }}
          >
            <span></span>
            <Space>
              <Button
                size="small"
                type="primary"
                icon={<SearchOutlined />}
                onClick={form.submit}
              >
                ค้นหา
              </Button>
              <Button
                size="small"
                type="primary"
                icon={<ClearOutlined />}
                onClick={() => form.resetFields()}
              >
                ล้าง
              </Button>
            </Space>
          </div>
        </Form>
        <div>
          <Table
            columns={columns}
            dataSource={tWorksheetInsuranceViews}
            size="small"
            loading={loading}
            scroll={{ x: 1800 }}
            pagination={{
              pageSize: 15,
              onChange(current) {
                setPage(current);
              }
            }}
            rowKey={(item: TWorksheetInsuranceView) => item.id!}
            onRow={(item: TWorksheetInsuranceView) => {
              return {
                onDoubleClick: () => onItemCliked(item),
              };
            }}
          />
        </div>
      </Content>
    </>
  );
};

export default FinanceInsuranceList;