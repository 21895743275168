import { Button, Form, Input, Modal, Space, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "src/stores/store";
import { TAssetmovementAndDetailView } from "../../domain/movement/view/tAssetmovementAndDetailView";
import tAssetmovementService from "../../services/tAssetmovementService";
import { formatTime, toThDate } from "../../utils/dateTimeUtils";



interface IParams {
  setSelectedTAssetmovement?: any;
  visibleTAssetmovementModal: boolean;
  setVisibleTAssetmovementModal: any;
}

const TAssetmovementModal: React.FC<IParams> = (props) => {
  const {
    setSelectedTAssetmovement,
    visibleTAssetmovementModal,
    setVisibleTAssetmovementModal,
  } = props;
  const [tAssetmovements, setTAssetmovements] = useState<TAssetmovementAndDetailView[]>([]);
  const [page, setPage] = React.useState(1);
  const [form] = Form.useForm();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [loading, setLoading] = React.useState(false);


  useEffect(() => {
    loadTAssetmovements("");
  }, []);

  const loadTAssetmovements = async (worksheetCode: string) => {
    setLoading(true);
    const branchId = userInfo.branchId;
    const flg = "00"; // not return yet
    const res = await tAssetmovementService.getTAssetmovementByParam(branchId, flg, worksheetCode, "", "");
    setTAssetmovements(res.data);
    setLoading(false);
  };

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 30,
      render: (value: number, item: TAssetmovementAndDetailView, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
    },
    {
      title: "เลขที่ใบงาน",
      dataIndex: "worksheetCode",
      width: 100,
      key: "worksheetCode", render: (value: string, item: TAssetmovementAndDetailView) => (
        <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.worksheetCode}</Button>
      )
    },
    {
      title: "รายการ",
      dataIndex: "assetName",
      key: "assetName",
      render: (value: any, item: TAssetmovementAndDetailView) => (<>{item.assetName} : {item.serviceDetailName}</>)
    },
    {
      title: "วันที่สิ้นสุดงาน",
      dataIndex: "endDate",
      key: "endDate",
      width: 150,
      render: (value: any, item: TAssetmovementAndDetailView) => (<>{toThDate(item.endDate)} {formatTime(item.endTime)}</>)
    },
  ];

  const onItemCliked = (item: TAssetmovementAndDetailView) => {
    setSelectedTAssetmovement(item)
    setVisibleTAssetmovementModal(false);
  };

  const onFinish = async (values: any) => {
    loadTAssetmovements(values.worksheetCode)
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  return (
    <>
      <Modal
        title="รายการใบงานรถที่ค้างรับกลับ"
        cancelText={"ยกเลิก"}
        visible={visibleTAssetmovementModal}
        width={"50%"}
        bodyStyle={{ minHeight: "450px" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setVisibleTAssetmovementModal(false)}
      >
        <Content className="app-page-content">
          <Form
            form={form}
            name="assetMv"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <div
              style={{
                borderBlockEnd: "2px solid #c9c9c9",
              }}
            ><Space size={"small"}>
                <Form.Item
                  label="ค้นหา"
                  name="worksheetCode"
                  rules={[{ required: false }]}
                >
                  <Input
                    allowClear
                    placeholder="ป้อนเลขที่ใบงาน"
                    onChange={form.submit}
                  ></Input>
                </Form.Item>
              </Space>
            </div>
          </Form>
          <Table
            columns={columns}
            dataSource={tAssetmovements}
            size="small"
            loading={loading}
            pagination={{
              pageSize: 8,
              onChange(current) {
                setPage(current);
              }
            }}
            rowKey={(item: TAssetmovementAndDetailView) => item.seq}
            onRow={(item: TAssetmovementAndDetailView) => {
              return {
                onDoubleClick: () => onItemCliked(item),
              };
            }}
          />
        </Content>
      </Modal>
    </>
  );
};

export default TAssetmovementModal;
