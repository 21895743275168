import { TCancelreserveHeadView } from "src/domain/tCancelreserveHeadView";
import http from "../lib/http";
import Constants from "../utils/constants";

const getCancelReserveByReserveCodeAndEntryDate = (reserveCode: string, fromDate: string, toDate: string) => {
  return http.get(Constants.DEFAULT_URL + "/tCancelreserveHead/getCancelReserveByReserveCodeAndEntryDate", {
    params: {
      reserveCode,
      fromDate,
      toDate
    }
  });
};

const getCancelReserveByBranchId = (branchId: string) => {
  return http.get(Constants.DEFAULT_URL + "/tCancelreserveHead/getCancelReserveByBranchId", {
    params: {
      branchId
    }
  });
};

const confirmCancelReserveHead = (tCancelreserveHeadViews: TCancelreserveHeadView[]) => {
  return http.post(Constants.DEFAULT_URL + "/tCancelreserveHead/confirmCancelReserveHead", tCancelreserveHeadViews);
};

const defaultApi = {
  getCancelReserveByBranchId,
  getCancelReserveByReserveCodeAndEntryDate,
  confirmCancelReserveHead,
};
export default defaultApi;
