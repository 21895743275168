import http from "src/lib/http";
import Constants from "src/utils/constants";

const getTStatusworksheetRefList = () => {
  return http.get(
    Constants.DEFAULT_URL + "/tStatusworksheetRef"
  );
};


const getTStatusworksheetRef = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/tStatusworksheetRef/" + id);
}

const defaultApi = {
  getTStatusworksheetRef,
  getTStatusworksheetRefList,
};
export default defaultApi;