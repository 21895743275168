import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import Search from "antd/es/input/Search";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PageHeader from "src/layouts/PageHeader";
import { formatNumber } from "src/utils/numberUtils";
import { getFlgAugerDescription, getFlgIntownDescription } from "src/utils/stringUtils";
import { TServiceHeadView } from "../../domain/master/view/tServiceHeadView";
import tServiceHeadService from "../../services/tservice/tServiceHeadService";
import AddEditTServiceHeadDrawer from "./AddEditTServiceHeadDrawer";


const TServiceHeadList: React.FC<TServiceHeadView> = () => {
    const [tServiceHeads, setTServiceHeads] = useState<TServiceHeadView[]>([]);
    const [page, setPage] = React.useState(1);
    const history = useHistory();
    const [visibleAddEditTServiceHeadDrawer, setVisibleAddEditTServiceHeadDrawer] = useState<boolean>(false);

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            width: 20,
            render: (value: number, item: TServiceHeadView, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
        },
        {
            title: "รหัสบริการ",
            dataIndex: "iserviceCode",
            key: "iserviceCode",
            width: 80,
            render: (value: string, item: TServiceHeadView) => (
                <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.iserviceCode}</Button>
            )
        },
        {
            title: "ชื่อบริการ",
            dataIndex: "iserviceName",
            key: "iserviceName"
        },
        {
            title: "ประเภทรถ",
            dataIndex: "typetruckName",
            key: "typetruckName",
            width: 80
        },
        {
            title: "ขอบเขตให้บริการ",
            dataIndex: "flgIntownName",
            key: "flgIntownName",
            width: 150,
            render: (value: any, item: TServiceHeadView) => (<>{getFlgIntownDescription(item.flgIntown)}</>)
        },
        {
            title: "รายการติดหัวเจาะ",
            dataIndex: "flgAugerName",
            key: "flgAugerName",
            width: 140,
            render: (value: any, item: TServiceHeadView) => (<>{getFlgAugerDescription(item.flgAuger)}</>)
        },
        {
            title: "หน่วยนับ",
            dataIndex: "unitName",
            key: "unitName",
        },
        {
            title: "ราคาขาย 1",
            dataIndex: "iunitPrice",
            key: "iunitPrice",
            render: (value: any, item: TServiceHeadView) => (<>{formatNumber(item.iunitPrice)}</>)
        },
        {
            title: "ราคาขาย 2",
            dataIndex: "iunitPrice2",
            key: "iunitPrice2",
            render: (value: any, item: TServiceHeadView) => (<>{formatNumber(item.iunitPrice2)}</>)
        },
        {
            title: "ราคาขาย 3",
            dataIndex: "iunitPrice3",
            key: "iunitPrice3",
            render: (value: any, item: TServiceHeadView) => (<>{formatNumber(item.iunitPrice3)}</>)
        },
        {
            title: "OT/ชม.",
            dataIndex: "rateot",
            key: "rateot",
            render: (value: any, item: TServiceHeadView) => (<>{formatNumber(item.rateot)}</>)
        },
    ];

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
        (async () => {
            const res = await tServiceHeadService.getTServiceHeadByParam("");
            setTServiceHeads(res.data);
        })();
    }, []);

    const doSearch = async (searchText: string) => {
        const res = await tServiceHeadService.getTServiceHeadByParam(searchText);
        setTServiceHeads(res.data);
    }

    function onSearchTextChange(searchText: string) {
        if (!searchText) {
            doSearch("");
        } else if (searchText.length >= 2) {
            doSearch(searchText);
        }
    }

    function onAddTServiceHead() {
        setVisibleAddEditTServiceHeadDrawer(true);
    }

    const onItemCliked = (item: TServiceHeadView) => {
        history.replace("/app/tservice/view/" + item.iserviceCode);
    };

    return (
        <>
            <PageHeader title="โปรแกรมข้อมูลบริการ">
                <Button
                    size="small"
                    type="primary"
                    icon={<PlusSquareOutlined />}
                    onClick={onAddTServiceHead}
                >
                    เพิ่ม
                </Button>
            </PageHeader>
            <Content className="app-page-content">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "baseline",
                        margin: "1em 0 0 0",
                        borderBlockEnd: "2px solid #c9c9c9",
                    }}
                >
                    <label style={{ margin: "0 1em 1em 2em" }}>คำค้นหา</label>
                    <Search
                        allowClear
                        style={{ width: "350px" }}
                        size="middle"
                        placeholder="ป้อนชื่อบริการ"
                        onChange={(event) => onSearchTextChange(event.target.value)}
                        onSearch={(value) => onSearchTextChange(value)}
                    ></Search>
                </div>
                <div>
                    <Table
                        columns={columns}
                        dataSource={tServiceHeads}
                        size="small"
                        pagination={{
                            pageSize: 15,
                            onChange(current) {
                                setPage(current);
                            }
                        }}
                        rowKey={(item: TServiceHeadView) => item.iserviceCode}
                        onRow={(item: TServiceHeadView) => {
                            return {
                                onDoubleClick: () => onItemCliked(item),
                            };
                        }}
                    />
                </div>
            </Content>
            <div> {
                visibleAddEditTServiceHeadDrawer && <AddEditTServiceHeadDrawer
                    iserviceCode={""}
                    visibleAddEditTServiceHeadDrawer={visibleAddEditTServiceHeadDrawer}
                    setVisibleAddEditTServiceHeadDrawer={setVisibleAddEditTServiceHeadDrawer} />
            }</div>
        </>
    );
};

export default TServiceHeadList;
