import { TReference } from "src/domain/master/tReference";

export enum ReferenceType {
    // Reference
    VOUCHER_TYPE = "00",
    VOUCHER_STATUS = "01",
    PETTYCASH_STATUS = "02",
    PAY_STATUS = "03",
    PAY_TYPE = "04",
    TAX_TYPE = "05",
    BANK = "06",
    UNIT = "07",
    ASSET_SUBTYPE = "08",
    RECEIVE_TYPE = "09",
    VAT_TAX_RATE = "10",

    // Other master
    PERCENT_DEPOSIT = "11",
    BRANCH = "12",
    EDUCATE = "13",
    OWNER_ASSET = "14",
    POSITION = "15",
    TRUCK_SIZE = "16",
    ASSET_TYPE = "17",
    CUSTOMER_TYPE = "18",
    TYPE_EXTRA = "19",
    WORK_TYPE = "20",
    TRUCK_OPTIONS = "21",
    TRUCK_WHEEL = "22",
    ASSIGN_VALUE_TAX_HEAD = "23",
    EXTRA_PRICE = "24",
    PRENAME = "25",
    STATUS_FINANCE = "26",
    TRUCK_STATUS = "27",
    WORKSHEET_STATUS = "28",
    EMPLOYEE_TYPE = "29",
    TYPE_OF_DUTY = "30",
    TYPE_OTHER_PAYMENT = "31",
    PAYMENT_TYPE = "32",
    VAT_TYPE = "33",
    TRUCK_TYPE = "34",
    SERVICE_UNIT = "35",
    WORKSHEET_TYPE = "36",
    INSURANCE_PAYMENT_TYPE = "37",
    ASSET_BRAND = "38",

    //TypepaymentData
    BEFORE_PAY = "01",
    AFTER_PAY = "02",
    CREDIT_PAY = "04",
    DEPOSIT_PAY = "03",
}

export function isReference(reference: TReference): boolean {
    if (!reference.code) {
        return false;
    }
    return reference.code === ReferenceType.VOUCHER_TYPE
        || reference.code === ReferenceType.VOUCHER_STATUS
        || reference.code === ReferenceType.PETTYCASH_STATUS
        || reference.code === ReferenceType.PAY_STATUS
        || reference.code === ReferenceType.PAY_TYPE
        || reference.code === ReferenceType.TAX_TYPE
        || reference.code === ReferenceType.BANK
        || reference.code === ReferenceType.UNIT
        || reference.code === ReferenceType.ASSET_SUBTYPE
        || reference.code === ReferenceType.RECEIVE_TYPE
        || reference.code === ReferenceType.VAT_TAX_RATE;
}

export function showReferenceCode(reference: TReference): boolean {
    if (!reference.code) {
        return false;
    }
    return reference.code === ReferenceType.VOUCHER_TYPE
        || reference.code === ReferenceType.VOUCHER_STATUS
        || reference.code === ReferenceType.PETTYCASH_STATUS
        || reference.code === ReferenceType.PAY_STATUS
        || reference.code === ReferenceType.PAY_TYPE
        || reference.code === ReferenceType.TAX_TYPE
        || reference.code === ReferenceType.BANK
        || reference.code === ReferenceType.UNIT
        || reference.code === ReferenceType.ASSET_SUBTYPE
        || reference.code === ReferenceType.RECEIVE_TYPE
        || reference.code === ReferenceType.VAT_TAX_RATE

        || reference.code === ReferenceType.BRANCH
        || reference.code === ReferenceType.ASSET_TYPE
        || reference.code === ReferenceType.ASSIGN_VALUE_TAX_HEAD
}

export function showReferenceRemark(reference: TReference): boolean {
    if (!reference.code) {
        return false;
    }
    return reference.code === ReferenceType.PERCENT_DEPOSIT
        || reference.code === ReferenceType.BRANCH
        || reference.code === ReferenceType.EDUCATE
        || reference.code === ReferenceType.OWNER_ASSET
        || reference.code === ReferenceType.POSITION
        || reference.code === ReferenceType.TRUCK_SIZE
        || reference.code === ReferenceType.CUSTOMER_TYPE
        || reference.code === ReferenceType.TYPE_EXTRA
        || reference.code === ReferenceType.WORK_TYPE
        || reference.code === ReferenceType.TRUCK_OPTIONS
        || reference.code === ReferenceType.TRUCK_WHEEL

        || reference.code === ReferenceType.EXTRA_PRICE
        || reference.code === ReferenceType.TYPE_OF_DUTY
        || reference.code === ReferenceType.ASSET_BRAND
}

export function showReferenceFlg(reference: TReference): boolean {
    if (!reference.code) {
        return false;
    }
    return reference.code === ReferenceType.VOUCHER_TYPE
        || reference.code === ReferenceType.VOUCHER_STATUS
        || reference.code === ReferenceType.PETTYCASH_STATUS
        || reference.code === ReferenceType.PAY_STATUS
        || reference.code === ReferenceType.PAY_TYPE
        || reference.code === ReferenceType.TAX_TYPE
        || reference.code === ReferenceType.BANK
        || reference.code === ReferenceType.UNIT
        || reference.code === ReferenceType.ASSET_SUBTYPE
        || reference.code === ReferenceType.RECEIVE_TYPE
        || reference.code === ReferenceType.VAT_TAX_RATE

        || reference.code === ReferenceType.POSITION
        || reference.code === ReferenceType.ASSET_TYPE
        || reference.code === ReferenceType.TYPE_EXTRA
        || reference.code === ReferenceType.PRENAME
        || reference.code === ReferenceType.EMPLOYEE_TYPE
}

export function getReferenceCodeTitile(reference: TReference): string {
    let title = "รหัส";
    if (!reference.code) {
        return title;
    }
    if (reference.code === ReferenceType.BRANCH || reference.code === ReferenceType.ASSET_TYPE) {
        title = "ชื่อย่อ";
    } else if (reference.code === ReferenceType.ASSIGN_VALUE_TAX_HEAD) {

        title = "อัตรา";
    }
    return title;
}
