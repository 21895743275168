import { WarningOutlined } from '@ant-design/icons';
import { Button, Descriptions, Form, Input, Modal, Space, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { TWorksheetInsuranceView } from 'src/domain/worksheet/view/tWorksheetInsuranceView';
import PageHeader from 'src/layouts/PageHeader';
import tWorksheetInsuranceService from 'src/services/tWorksheetInsuranceService';
import { toThDate } from 'src/utils/dateTimeUtils';
import { formatNumber } from 'src/utils/numberUtils';
import FinanceInsuranceDrawer from './FinanceInsuranceDrawer';

const { Text, Title } = Typography;
const { warning } = Modal;

const FinanceNonInsurance: React.FC<RouteComponentProps> = (props) => {
  const [form] = Form.useForm();
  const [page, setPage] = React.useState(1);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [tWorksheetInsuranceViews, setTWorksheetInsuranceViews] = useState<TWorksheetInsuranceView[]>([]);
  const [selectTWorksheetInsuranceView, setSelectTWorksheetInsuranceView] = useState<TWorksheetInsuranceView>({} as TWorksheetInsuranceView);
  const [onDrawerVisible, setOnDrawerVisible] = useState<boolean>(false);
  const [selectHighlight, setSelectHighlight] = useState<number>(-1)

  const [sumInsuranceAmt, setSumInsuranceAmt] = useState<number>(0)
  const [insuranceCount, setInsuranceCount] = useState<number>(0)

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: TWorksheetInsuranceView, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
    },
    {
      title: "วันที่รับชำระ",
      dataIndex: "ientryDate",
      key: "ientryDate",
      width: 80,
      align: "center" as "center",
      render: (value: any, item: TWorksheetInsuranceView) => {
        return <span>{item.ientryDate ? toThDate(item.ientryDate) : ""}</span>;
      },
    },
    {
      title: "เลขที่ใบงาน",
      dataIndex: "iworksheetStatusName",
      key: "iworksheetStatusName",
      width: 120,
      align: "center" as "center",
      render: (value: string, item: TWorksheetInsuranceView) => {
        if (isNaN(Number(item.iworksheetStatusName))) {
          return <span>{item.iworksheetStatusName}</span>;
        } else {
          return <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.iworksheetStatusName}</Button>
        }
      }
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "icustName",
      key: "icustName",
      width: 250,
    },
    {
      title: "รายการบริการ",
      dataIndex: "serviceName",
      key: "serviceName",
      width: 250,
    },
    {
      title: "ประเภทประกัน",
      dataIndex: "itypePaymentInsuranceName",
      key: "itypePaymentInsuranceName",
      width: 120,
    },
    {
      title: "หมายเหตุ",
      dataIndex: "iremark",
      key: "iremark",
      render: (value: string, item: TWorksheetInsuranceView) => {
        return <span>{getRemark(item)}</span>;
      }
    },
  ];

  const getRemark = (item: TWorksheetInsuranceView) => {
    let remark = item.iremark ? item.iremark : '';
    if (item.itypePaymentInsurance === '03') {
      remark = 'โอนเงินเข้าบัญชีเลขที่ ' + item.ipayinAccountnumber + ' - ' + (item.iremark ? item.iremark : '')
    } else if (item.itypePaymentInsurance !== '01' && item.itypePaymentInsurance !== '99') {
      remark = 'เลขที่ ' + item.iinsuranceNumber + ' - ' + (item.iremark ? item.iremark : '')
    }
    return remark;
  }

  const getItemColor = (item: TWorksheetInsuranceView, index: any) => {
    let rowStyle = '';
    let rowTextStyle = '';
    if (index === selectHighlight) {
      rowStyle = "row-selected"
    }
    switch (item.istatusInsurance) {
      case "00":
        rowTextStyle = 'black';
        break;
      case "01":
        rowTextStyle = 'blue';
        break;
      case "02":
        rowTextStyle = 'red';
        break;
      default:
        rowTextStyle = 'black';
        break;
    }
    if (item.itypePaymentInsurance === '99' || item.tworksheetHeadView.iworksheetStatus === '98') {
      rowTextStyle = 'red';
    }

    return rowStyle + ' text-' + rowTextStyle;
  }

  useEffect(() => {
    doSearch(form);
  }, []);
  
  useEffect(() => {
    (async () => {
      if (!onDrawerVisible && selectTWorksheetInsuranceView.iworksheetStatusName) {
        const res = await tWorksheetInsuranceService.getPaymentInsuranceHeadViewById(String(selectTWorksheetInsuranceView.id));
        const index = (tWorksheetInsuranceViews as Array<TWorksheetInsuranceView>).findIndex(object => object.iworksheetStatusName === selectTWorksheetInsuranceView.iworksheetStatusName);
        if (index !== -1 && res.data.istatusInsurance !== '00') {
          const arraySplice = tWorksheetInsuranceViews ? [...tWorksheetInsuranceViews] : [];
          arraySplice?.splice(index, 1);
          setTWorksheetInsuranceViews(arraySplice);
        }
          
        form.setFieldsValue({
          worksheetCodeCustomerName: "",
        });
      }
    })();
  }, [onDrawerVisible]);

  const doSearch = async (values: any) => {
    setIsLoading(true);
    const worksheetCodeCustomerName = (values.worksheetCodeCustomerName) ? values.worksheetCodeCustomerName : "";
    const fromDate = "";
    const toDate = "";
    const statusInsurances = ['00'];
    const res = await tWorksheetInsuranceService.getPaymentInsuranceHeadByAll(fromDate, toDate, worksheetCodeCustomerName, statusInsurances.toString());

    setTWorksheetInsuranceViews(res.data.WORKSHEET_INSURANCE_LIST);
    setSumInsuranceAmt(res.data.SUM_INSURANCE_AMT);
    setInsuranceCount(res.data.COUNT_WORKSHEET_INSURANCE)
    setIsLoading(false);
  }

  const onItemCliked = (item: TWorksheetInsuranceView) => {
    if ((Number(item.tworksheetHeadView.iworksheetStatus) < 1)) {
      warning({
        title: "รายงาน!",
        icon: <WarningOutlined />,
        content: "สถานะยังไม่ได้ยืนยันใบงาน!",
        okText: "ตกลง"
      });
      return;
    }
    if ((Number(item.tworksheetHeadView.iworksheetStatus) === 98)) {
      warning({
        title: "รายงาน!",
        icon: <WarningOutlined />,
        content: "สถานะใบงานถูกยกเลิก!",
        okText: "ตกลง"
      });
      return;
    }

    if (item.istatusInsurance !== '00') {
      warning({
        title: "รายงาน!",
        icon: <WarningOutlined />,
        content: "การเงินรับชำระเงินประกันแล้ว!",
        okText: "ตกลง"
      });
      return;
    }
    if (item.itypePaymentInsurance === '99') {
      warning({
        title: "รายงาน!",
        icon: <WarningOutlined />,
        content: "ใบงานไม่มีเงินประกัน!",
        okText: "ตกลง"
      });
      return;
    }
    setSelectTWorksheetInsuranceView(item);
    setOnDrawerVisible(true);
  };

  return (
    <>
      <PageHeader title="" onClose={() => history.push("/app/financeInsurance/list")}>
        <div className="d-flex flex-row">
          <div className="action-container"></div>
        </div>
      </PageHeader>
      <Content className="app-page-content">
        <Descriptions
          style={{ margin: "1em" }}
          column={4}
          title="ค้นหาเงินประกัน"
          size="middle"
        />
        <div>
          <Form
            form={form}
            name="tWorksheetInsuranceForm"
            onFinish={doSearch}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "baseline",
                justifyContent: "space-between",
                margin: "1em 0 1em 0",
                padding: "1em",
                borderBlockEnd: "2px solid #c9c9c9",
              }}
            >
              <Form.Item
                label="เลขที่ใบงาน/ชื่อลูกค้า"
                name="worksheetCodeCustomerName"
                rules={[{ required: false }]}
                style={{ width: "50%" }}
              >
                <Input
                  allowClear
                  placeholder="ป้อนเลขที่ใบงาน/ชื่อลูกค้า"
                  onPressEnter={form.submit}
                ></Input>
              </Form.Item>
              <Space direction="horizontal" size={"middle"}>
                <Space direction="vertical" size={"small"} align='center'>
                  <Title level={3}>จำนวนใบงาน</Title>
                  <Title type="danger" level={3}>{Number(insuranceCount)}</Title>
                </Space>
                <Space direction="vertical" size={"small"} align='center'>
                  <Title level={3}>จำนวนเงินทั้งหมด</Title>
                  <Title type="danger" level={3}>{formatNumber(sumInsuranceAmt)}</Title>
                </Space>
              </Space>
            </div>
            <div>
              <Table
                columns={columns}
                dataSource={tWorksheetInsuranceViews}
                size="small"
                loading={isLoading}
                rowClassName={(item, index) => getItemColor(item, index)}
                rowKey={(item: TWorksheetInsuranceView) => item.id!}
                onRow={(item: TWorksheetInsuranceView, index: any) => {
                  return {
                    onClick: () => {
                      if (selectHighlight === index) {
                        setSelectHighlight(-1);
                      } else {
                        setSelectHighlight(index);
                      }
                    },
                  };
                }}
                pagination={{
                  pageSize: 15,
                  onChange(current) {
                    setPage(current);
                  }
                }}
              />
            </div>
          </Form>
        </div>
      </Content>
      <div>
        {onDrawerVisible && (
          <FinanceInsuranceDrawer
            onDrawerVisible={onDrawerVisible}
            setOnDrawerVisible={setOnDrawerVisible}
            selectTWorksheetInsuranceView={selectTWorksheetInsuranceView}
          ></FinanceInsuranceDrawer>
        )}
      </div>
    </>
  )
}

export default FinanceNonInsurance