import { ReturnFinancePaymentData } from "src/domain/view/returnFinancePaymentData";
import http from "src/lib/http";
import Constants from "src/utils/constants";

const findReturnFinancePaymentViews = (entrydateFrom: string, entrydateTo: string, customercode: string, code: string) => {
  return http.get(Constants.DEFAULT_URL + "/returnFinancePayment/findReturnFinancePaymentViews", {
    params: {
      entrydateFrom,
      entrydateTo,
      customercode,
      code,
    }
  });
};

const createReturnFinancePayment = (returnFnPaymentData: ReturnFinancePaymentData) => {
  return http.post(Constants.DEFAULT_URL + "/returnFinancePayment/createReturnFinancePayment", returnFnPaymentData);
};

const defaultApi = {
  findReturnFinancePaymentViews,
  createReturnFinancePayment,
}
export default defaultApi;