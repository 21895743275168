import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Form, Input, Modal } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "src/stores/store";
import { BsProvinceH } from "../../domain/bsProvinceH";
import bsProvinceHService from "../../services/province/bsProvinceHService";
import { NotificationType, showNotification } from "../../utils/notificationUtils";

const { confirm } = Modal;

interface IParams {
  iid: string;
  visibleBsProvinceHModal: boolean;
  setVisibleBsProvinceHModal: any;
}

const AddEditBsProvinceHModal: React.FC<IParams> = (props) => {
  const { iid, visibleBsProvinceHModal, setVisibleBsProvinceHModal } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [bsProvinceH, setBsProvinceH] = useState<BsProvinceH>(
    {} as BsProvinceH
  );
  const [form] = Form.useForm();
  const history = useHistory();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  let title: string = "เพิ่มข้อมูลจังหวัด";
  if (iid) {
    title = "แก้ไขข้อมูลจังหวัด";
  }

  useEffect(() => {
    if (trigger) {
      form.validateFields()
        .then(() => {
          confirm({
            title: "ยืนยัน!",
            icon: <ExclamationCircleOutlined />,
            content: "คุณต้องการที่จะบันทึกข้อมูลหรือไม่?",
            cancelText: "ยกเลิก",
            okText: "ยืนยัน",
            onOk() {
              form.submit();
            },
            onCancel() {
              setTrigger(false);
            }
          });
        }).catch(() => {
          setTrigger(false);
        });
    }
  }, [trigger]);

  useEffect(() => {
    loadBsProvinceH();
    return () => { };
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      iprovinceCode: bsProvinceH.iprovinceCode,
      iprovinceName: bsProvinceH.iprovinceName,
      iprovinceShname: bsProvinceH.iprovinceShname,
    });
    return () => { };
  }, [bsProvinceH]);

  const loadBsProvinceH = async () => {
    if (iid) {
      const res = await bsProvinceHService.getBsProvinceHById(Number(iid));
      setBsProvinceH(res.data);
    } else {
      const nextCodeRes = await bsProvinceHService.getNextBsProvinceHCode();
      setBsProvinceH({ ...bsProvinceH, iprovinceCode: nextCodeRes.data });
    }
  };

  const onFinish = async (values: any) => {
    const isExistRes = await bsProvinceHService.checkExistProvince(values.iprovinceShname, values.iprovinceName);
    if (!iid && isExistRes.data) {
      showNotification(NotificationType.ERROR, "อักษรย่อ " + values.iprovinceShname + " ชื่อจังหวัด " + values.iprovinceName + " นี้มีอยู่แล้ว ไม่สามารถเพิ่มข้อมูลได้");
      setTrigger(false);
      return;
    }

    const _bsProvinceH: BsProvinceH = {
      iid: Number(iid),
      iprovinceCode: values.iprovinceCode,
      iprovinceName: values.iprovinceName,
      iprovinceShname: values.iprovinceShname,
      ientryUser: userInfo.userId,
    };

    if (!iid) {
      try {
        const res = await bsProvinceHService.postBsProvinceH(_bsProvinceH);
        showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลจังหวัดเรียบร้อยแล้ว!");
        form.resetFields();
        setTrigger(false);
        setVisibleBsProvinceHModal(false);
        history.replace("/app/bsProvinceH/view/" + res.data.iid);
      } catch (err) {
        showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        setTrigger(false);
      }
    } else {
      try {
        await bsProvinceHService.updateBsProvinceH(_bsProvinceH);
        showNotification(NotificationType.SUCCESS, "แก้ไขข้อมูลจังหวัดเรียบร้อยแล้ว!");
        form.resetFields();
        setTrigger(false);
        setVisibleBsProvinceHModal(false);
        history.replace("/app/bsProvinceH/view/" + iid);
      } catch (err) {
        showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        setTrigger(false);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน");
    setTrigger(false);
  };

  const handleProvinceShortNameOnBlur = (iprovinceShnameValue: string) => {
    form.setFieldsValue({
      iprovinceShname: iprovinceShnameValue.toUpperCase(),
    });
  };

  return (
    <>
      <Modal
        title={title}
        cancelText={"ยกเลิก"}
        okText={"บันทึก"}
        visible={visibleBsProvinceHModal}
        width={700}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setVisibleBsProvinceHModal(false)}
      >
        <Content className="app-page-content">
          <Form
            form={form}
            name="bsProvinceH"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            // initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="รหัส"
              name="iprovinceCode"
              rules={[{ required: true }]}
            >
              <Input disabled={true} style={{ width: 100 }} />
            </Form.Item>

            <Form.Item
              label="รหัสย่อ"
              name="iprovinceShname"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input
                style={{ width: 200 }}
                placeholder={"ป้อนรหัสย่อ"}
                onBlur={(event) =>
                  handleProvinceShortNameOnBlur(event.target.value)
                }
              />
            </Form.Item>

            <Form.Item
              label="ชื่อจังหวัด"
              name="iprovinceName"
              rules={[
                {
                  required: true,
                  message: "กรุณาใส่ชื่อจังหวัด!",
                },
                {
                  validator: (_, value) => {
                    if (value && (value === " " || value === "-")) {
                      return Promise.reject(new Error('กรุณาป้อนข้อมูลจังหวัดให้ถูกต้อง!'))
                    } else {
                      return Promise.resolve()
                    }
                  },
                },
              ]}
            >
              <Input placeholder={"ป้อนชื่อจังหวัด"} />
            </Form.Item>
          </Form>
        </Content>
      </Modal>
    </>
  );
};

export default AddEditBsProvinceHModal;
