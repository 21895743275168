import http from "src/lib/http";
import Constants from "src/utils/constants";

const getTPrenameRefList = () => {
  return http.get(Constants.DEFAULT_URL + "/tPrenameRef");
};

const getTPrenameRef = (prenameCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tPrenameRef/" + prenameCode);
};

const defaultApi = {
  getTPrenameRefList,
  getTPrenameRef
};
export default defaultApi;