import http from "../../lib/http";
import Constants from "../../utils/constants";

const getRyRefbranchList = () => {
  return http.get(
    Constants.DEFAULT_URL + "/ryRefbranch"
  );
};

const getRyRefbranchById = (branchId: string) => {
  return http.get(Constants.DEFAULT_URL + "/ryRefbranch/" + branchId);
};

const defaultApi = {
  getRyRefbranchList,
  getRyRefbranchById,
};
export default defaultApi;
