import { Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Space, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { TEmployee2View } from 'src/domain/master/view/tEmployee2View';
import { TUserHead } from 'src/domain/tUserHead';
import { TWorksheetHead } from 'src/domain/worksheet/tWorksheetHead';
import { WorksheetHeadData } from 'src/domain/worksheet/view/worksheetHeadData';
import employeeService from 'src/services/employee/tEmployee2Service';
import worksheetService from 'src/services/worksheetService';
import { useAppSelector } from 'src/stores/store';
import Constants from 'src/utils/constants';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber, textNumber } from 'src/utils/numberUtils';

const { Option } = Select;

interface IProps {
  editWorksheetDiscountModalVisible: boolean;
  setEditWorksheetDiscountModalVisible: any;
  worksheetHeadData: WorksheetHeadData;
  setWorksheetHeadData: any;
  tUserHeadUpdate: TUserHead;
}

const EditDiscountModal: React.FC<IProps> = (props) => {
  const { editWorksheetDiscountModalVisible, setEditWorksheetDiscountModalVisible, worksheetHeadData, setWorksheetHeadData, tUserHeadUpdate } = props;

  const [trigger, setTrigger] = useState<boolean>(false);
  const [employees, setEmployees] = useState<TEmployee2View[]>([]);//ผู้แจ้ง
  const [form] = Form.useForm();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  useEffect(() => {
    form.setFieldsValue({
      discountDate: moment(new Date(), Constants.DATE_FORMAT),
      iserviceAmt: worksheetHeadData.tworksheetHead.iserviceAmt,
      iextraAmt: worksheetHeadData.tworksheetHead.iextraAmt,
      iotAmt: worksheetHeadData.tworksheetHead.iotAmt,
      olddiscount: worksheetHeadData.tworksheetHead.idiscount,
      newIdiscount: formatNumber(0),
      totalDiscountAmt: worksheetHeadData.tworksheetHead.idiscount,
      itotal1Amt: worksheetHeadData.tworksheetHead.itotal1Amt,
      itaxAmt: worksheetHeadData.tworksheetHead.itaxAmt,
      itotal2Amt: worksheetHeadData.tworksheetHead.itotal1Amt,
      purchasetax: worksheetHeadData.tworksheetHead.purchasetax,
      idepositAmt: worksheetHeadData.tworksheetHead.payedamount,
      iremainAmt: worksheetHeadData.tworksheetHead.iremainAmt,
      chkWasteTime: false,
    });
    return () => { };
  }, []);

  const handleSearchEmployee = async (data: any) => {
    if (data.length < 3) return;
    const res = await employeeService.getEmployee2ByParam(data);
    setEmployees(res.data);
  };

  //intigrate method txtDisCount_Leave() + CalMoney()
  const calculateMoney = () => {
    const iserviceAmt = form.getFieldValue("iserviceAmt");
    const iextraAmt = form.getFieldValue("iextraAmt");
    const iotAmt = form.getFieldValue("iotAmt");
    const idepositAmt = form.getFieldValue("idepositAmt");
    const olddiscount = form.getFieldValue("olddiscount");

    let discount = parserNumber(form.getFieldValue("newIdiscount"));
    let chkDiscountAmt = !parserNumber(form.getFieldValue("newIdiscount")) ? 0 : parserNumber(form.getFieldValue("newIdiscount"));
    let vatDiscount = 0;

    switch (worksheetHeadData.tworksheetHead.itypetaxCode) {
      case "01":
        vatDiscount = chkDiscountAmt * 7 / 100;
        break;
      case "02":
        vatDiscount = chkDiscountAmt * 7 / 107;
        chkDiscountAmt = chkDiscountAmt - vatDiscount;
        break;
      case "03":
        break;
    }
    chkDiscountAmt += vatDiscount;
    if (chkDiscountAmt > worksheetHeadData.tworksheetHead.iremainAmt!) {
      showNotification(NotificationType.ERROR, "ส่วนลดมากกว่ายอดเงินค้างชำระ");
      form.setFieldsValue({ newIdiscount: 0 });
    } else {
      // method CalMoney()
      let total1amt = 0;
      let vatamt = 0;
      let total2amt = 0;
      let remainamt = 0;

      total1amt = iserviceAmt + iextraAmt + iotAmt - discount - olddiscount;
      switch (worksheetHeadData.tworksheetHead.itypetaxCode) {
        case "01":
          vatamt = total1amt * 7 / 100;
          break;
        case "02":
          vatamt = total1amt * 7 / 107;
          total1amt = total1amt - vatamt;
          break;
        case "03":
          vatamt = 0;
          break;
      }
      total2amt = total1amt + vatamt;
      remainamt = total2amt - idepositAmt;

      form.setFieldsValue({
        itotal1Amt: total1amt,
        itaxAmt: vatamt,
        itotal2Amt: total2amt,
        iremainAmt: remainamt,
        totalDiscountAmt: discount + olddiscount,
      });
    }
  }

  const onFinish = async (values: any) => {
    const employeeArr = employees as Array<TEmployee2View>;
    const empSelected = employeeArr.find((obj) => obj.id === values.userInfoId);
    const netServiceAmt = (worksheetHeadData.tworksheetHead.iserviceAmt! + 
                          worksheetHeadData.tworksheetHead.iextraAmt! + 
                          worksheetHeadData.tworksheetHead.iotAmt!) - 
                          values.totalDiscountAmt
    const temp = {
      ...(worksheetHeadData as WorksheetHeadData),
      tworksheetHead: {
        ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
        idiscount: values.totalDiscountAmt,
        itotal1Amt: values.itotal1Amt,
        itaxAmt: values.itaxAmt,
        itotal2Amt: values.itotal2Amt,
        iremainAmt: values.iremainAmt,
        iflgWasteTime: values.chkWasteTime ? 1 : 0,
        isavStation: userInfo.saveStation,
        ilastUser: tUserHeadUpdate.iuserId,
        itermNo: userInfo.termNo,
      },
      netServiceAmt: netServiceAmt,
      updateUser: tUserHeadUpdate,
    };
    setWorksheetHeadData(temp);

    try {
      // console.log("*************** temp : ", temp);
      await worksheetService.updateWorksheetWithSubMenuKey(temp, values.userRemark, empSelected ? empSelected.empname : "", "MENU_WORKSHEET_DISCOUNT");
      showNotification(NotificationType.SUCCESS, "บันทึกข้อมูล ส่วนลด เรียบร้อย");
      setEditWorksheetDiscountModalVisible(false);
      form.resetFields();
      setTrigger(false);
    } catch (err) {
      showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
      setTrigger(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาตรวจสอบข้อมูลให้ครบถ้วน!");
    setTrigger(false);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>บันทึกส่วนลด</Typography.Title>
          </div>
        }
        visible={editWorksheetDiscountModalVisible}
        width={"50%"}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setEditWorksheetDiscountModalVisible(false)}
      >
        <Form
          form={form}
          name="editDiscount"
          // labelCol={{ span: 5 }}
          // wrapperCol={{ span: 19 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Col span={24}>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>วันที่ให้ส่วนลด: </Typography.Text>
              </Col>
              <Col span={18}>
                <Space align="baseline" direction="horizontal">
                  <Form.Item
                    name="discountDate"//date1
                    style={{ marginBottom: "2px" }}
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      disabled
                      format={Constants.DATE_FORMAT}
                      size="middle"
                      style={{ width: 205 }}
                    />
                  </Form.Item>
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ค่าบริการ: </Typography.Text>
              </Col>
              <Col span={18}>
                <Space align="baseline" direction="horizontal">
                  <Form.Item
                    name="iserviceAmt" //numBasicServiceAmt
                    style={{ marginBottom: "2px" }}
                  >
                    <InputNumber
                      controls={false}
                      formatter={(value) => formatNumber(value)}
                      parser={(value) => parserNumber(value)}
                      disabled={true}
                      style={{ backgroundColor: "#F8FC9D", color: "#000", width: "auto" }}
                    ></InputNumber>
                  </Form.Item>
                  บาท
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ค่าพิเศษ: </Typography.Text>
              </Col>
              <Col span={18}>
                <Space align="baseline" direction="horizontal">
                  <Form.Item
                    name="iextraAmt"
                    style={{ marginBottom: "2px" }}
                  >
                    <InputNumber
                      controls={false}
                      formatter={(value) => formatNumber(value)}
                      parser={(value) => parserNumber(value)}
                      disabled={true}
                      style={{ backgroundColor: "#F8FC9D", color: "#000", width: "auto", marginRight: 5 }}
                    ></InputNumber>
                  </Form.Item>
                  บาท
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>OT: </Typography.Text>
              </Col>
              <Col span={18}>
                <Space align="baseline" direction="horizontal">
                  <Form.Item
                    name="iotAmt"
                    style={{ marginBottom: "2px" }}
                  >
                    <InputNumber
                      controls={false}
                      formatter={(value) => formatNumber(value)}
                      parser={(value) => parserNumber(value)}
                      disabled={true}
                      style={{ backgroundColor: "#F8FC9D", color: "#000", width: "auto", marginRight: 5 }}
                    ></InputNumber>
                  </Form.Item>
                  บาท
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ส่วนลดเดิม: </Typography.Text>
              </Col>
              <Col span={18}>
                <Space align="baseline" direction="horizontal">
                  <Form.Item
                    name="olddiscount"
                    style={{ marginBottom: "2px" }}
                  >
                    <InputNumber
                      controls={false}
                      formatter={(value) => formatNumber(value)}
                      parser={(value) => parserNumber(value)}
                      disabled={true}
                      style={{ backgroundColor: "#F8FC9D", color: "#000", width: "auto", marginRight: 5 }}
                    ></InputNumber>
                  </Form.Item>
                  บาท
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>ส่วนลด: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={18}>
                <Space align="baseline" direction="horizontal">
                  <Form.Item
                    name="newIdiscount"
                    style={{ marginBottom: "2px" }}
                    rules={[{ required: true, message: "กรุณาระบุส่วนลด" },
                    {
                      validator: (_, value) => {
                        const discount = parserNumber(value);
                        if (value && discount <= 0) {
                          return Promise.reject(new Error('กรุณาระบุส่วนลดที่เป็นจำนวนที่ มากกว่า 0'))
                        } else {
                          return Promise.resolve()
                        }
                      },
                    },
                    ]}
                  >
                    <Input
                      type="text"
                      onKeyDown={(event: any) => textNumber(event)}
                      onChange={() => calculateMoney()}
                      onPressEnter={(e: any) => form.setFieldsValue({ newIdiscount: formatNumber(parserNumber(e.target.value)) })}
                      onBlur={(e: any) => form.setFieldsValue({ newIdiscount: formatNumber(parserNumber(e.target.value)) })}
                      onFocus={(e) => e.target.select()}
                      style={{ textAlign: "end", width: "auto", marginRight: 5 }}
                    ></Input>
                  </Form.Item>
                  บาท
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ส่วนลดทั้งหมด: </Typography.Text>
              </Col>
              <Col span={18}>
                <Space align="baseline" direction="horizontal">
                  <Form.Item
                    name="totalDiscountAmt"
                    style={{ marginBottom: "2px" }}
                  >
                    <InputNumber
                      controls={false}
                      formatter={(value) => formatNumber(value)}
                      parser={(value) => parserNumber(value)}
                      disabled={true}
                      style={{ backgroundColor: "#F8FC9D", color: "#000", width: "auto", marginRight: 5 }}
                    ></InputNumber>
                  </Form.Item>
                  บาท
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ราคาก่อนภาษี: </Typography.Text>
              </Col>
              <Col span={18}>
                <Space align="baseline" direction="horizontal">
                  <Form.Item
                    name="itotal1Amt"
                    style={{ marginBottom: "2px" }}
                  >
                    <InputNumber
                      controls={false}
                      formatter={(value) => formatNumber(value)}
                      parser={(value) => parserNumber(value)}
                      disabled={true}
                      style={{ backgroundColor: "#F8FC9D", color: "#000", width: "auto", marginRight: 5 }}
                    ></InputNumber>
                  </Form.Item>
                  บาท
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ภาษี 7%: </Typography.Text>
              </Col>
              <Col span={18}>
                <Space align="baseline" direction="horizontal">
                  <Form.Item
                    name="itaxAmt"
                    style={{ marginBottom: "2px" }}
                  >
                    <InputNumber
                      controls={false}
                      formatter={(value) => formatNumber(value)}
                      parser={(value) => parserNumber(value)}
                      disabled={true}
                      style={{ backgroundColor: "#F8FC9D", color: "#000", width: "auto", marginRight: 5 }}
                    ></InputNumber>
                  </Form.Item>
                  บาท
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ราคารวมภาษี: </Typography.Text>
              </Col>
              <Col span={18}>
                <Space align="baseline" direction="horizontal">
                  <Form.Item
                    name="itotal2Amt"
                    style={{ marginBottom: "2px" }}
                  >
                    <InputNumber
                      controls={false}
                      formatter={(value) => formatNumber(value)}
                      parser={(value) => parserNumber(value)}
                      disabled={true}
                      style={{ backgroundColor: "#F8FC9D", color: "#000", width: "auto", marginRight: 5 }}
                    ></InputNumber>
                  </Form.Item>
                  บาท
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>หัก ณ ทีจ่าย: </Typography.Text>
              </Col>
              <Col span={18}>
                <Space align="baseline" direction="horizontal">
                  <Form.Item
                    name="purchasetax"
                    style={{ marginBottom: "2px" }}
                  >
                    <InputNumber
                      controls={false}
                      formatter={(value) => formatNumber(value)}
                      parser={(value) => parserNumber(value)}
                      disabled={true}
                      style={{ backgroundColor: "#F8FC9D", color: "#000", width: "auto", marginRight: 5 }}
                    ></InputNumber>
                  </Form.Item>
                  บาท
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ชำระแล้ว/มัดจำ: </Typography.Text>
              </Col>
              <Col span={18}>
                <Space align="baseline" direction="horizontal">
                  <Form.Item
                    name="idepositAmt"
                    style={{ marginBottom: "2px" }}
                  >
                    <InputNumber
                      controls={false}
                      formatter={(value) => formatNumber(value)}
                      parser={(value) => parserNumber(value)}
                      disabled={true}
                      style={{ backgroundColor: "#F8FC9D", color: "#000", width: "auto", marginRight: 5 }}
                    ></InputNumber>
                  </Form.Item>
                  บาท
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ค้างจ่าย: </Typography.Text>
              </Col>
              <Col span={18}>
                <Space align="baseline" direction="horizontal">
                  <Form.Item
                    name="iremainAmt"
                  >
                    <InputNumber
                      controls={false}
                      formatter={(value) => formatNumber(value)}
                      parser={(value) => parserNumber(value)}
                      disabled={true}
                      style={{ backgroundColor: "#F8FC9D", color: "#000", width: "auto", marginRight: 5 }}
                    ></InputNumber>
                  </Form.Item>
                  บาท
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>ผู้แจ้ง: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={15}>
                <Form.Item
                  name="userInfoId"
                  rules={[{ required: true, message: "กรุณาระบุ ผู้แจ้ง" }]}
                >
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกผู้แจ้ง"
                    optionFilterProp="children"
                    filterOption={true}
                    onSearch={handleSearchEmployee}
                  >
                    {employees.map((obj) => {
                      return (
                        <Option key={obj.id} value={obj.id}>
                          {obj.empname}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>หมายเหตุ: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={15}>
                <Form.Item
                  name="userRemark"
                  rules={[{ required: true, message: "กรุณาระบุหมายเหตุก่อน" }]}
                >
                  <TextArea
                    rows={4}
                  ></TextArea>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}></Col>
              <Col span={15}>
                <Form.Item
                  name="chkWasteTime"
                  valuePropName="checked"
                  style={{ backgroundColor: "#FF0000", paddingLeft: 5, borderRadius: 5, width: 200 }}
                >
                  <Checkbox>
                    ส่วนลดมีผลกับ % พนักงาน
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Form>
      </Modal>
    </>
  )
}

export default EditDiscountModal