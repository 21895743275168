import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DatePicker, Form, Input, InputNumber, Modal, Select, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { TChequeHead } from 'src/domain/cheque/tChequeHead';
import { TBankBranchHead } from 'src/domain/master/tBankBranchHead';
import { TBankHead } from 'src/domain/master/tBankHead';
import { TCustomerHeadView } from "src/domain/master/view/tCustomerHeadView";
import { TTypeworksheetRef } from 'src/domain/tTypeworksheetRef';
import tBankBranchHeadService from 'src/services/bank/tBankBranchHeadService';
import tBankHeadService from 'src/services/bank/tBankHeadService';
import chequeHeadService from 'src/services/cheque/tChequeHeadService';
import tCustomerHeadService from 'src/services/customer/tCustomerHeadService';
import tTypeworksheetRefService from 'src/services/reference/tTypeworksheetRefService';
import Constants from 'src/utils/constants';
import { dateToString, toMomentDate } from 'src/utils/dateTimeUtils';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber, textNumber } from 'src/utils/numberUtils';

const { Option } = Select;
const { confirm } = Modal;

interface IProps {
  addTChequeHeadModalVisible: boolean;
  setAddTChequeHeadModalVisible: any;
  tChequeHead: TChequeHead;
}

const AddTChequeHeadModal: React.FC<IProps> = (props) => {
  const { addTChequeHeadModalVisible, setAddTChequeHeadModalVisible, tChequeHead } = props
  const [form] = Form.useForm();
  const [trigger, setTrigger] = useState<boolean>(false);
  const [tTypeworksheetRefs, setTTypeworksheetRefs] = useState<TTypeworksheetRef[]>([]); //ประเภทใบสั่งงาน
  const [customers, setCustomers] = useState<TCustomerHeadView[]>([]);
  const [custSelected, setCustSelected] = useState<TCustomerHeadView>();
  const [bankHeads, setBankHeads] = useState<TBankHead[]>([]);
  const [bankBranchs, setBankBranchs] = useState<TBankBranchHead[]>([]);

  useEffect(() => {
    loadData();
    return () => { };
  }, []);

  const loadData = async () => {
    if (addTChequeHeadModalVisible) {
      const tTypeworksheetRefRes = await tTypeworksheetRefService.getTTypeworksheetRefList();
      setTTypeworksheetRefs(tTypeworksheetRefRes.data);

      if (tChequeHead.customercode) {
        const worksheetCustomer = await tCustomerHeadService.getCustomerByCode(tChequeHead.customercode);
        setCustSelected(worksheetCustomer.data);
        setCustomers([worksheetCustomer.data]);
      }

      form.setFieldsValue({
        financeType: tChequeHead.financetype,
        customerCode: tChequeHead.customercode,
        bankCode: tChequeHead.bankcode,
        bankBranchCode: tChequeHead.bankbranchcode,
        chequeNumber: tChequeHead.chequenumber,
        chequeDate: tChequeHead.chequedate ? toMomentDate(tChequeHead.chequedate) : "",
        chequeAmount: tChequeHead.chequeamt ? formatNumber(tChequeHead.chequeamt) : 0.00,
        remark: tChequeHead.remark
      });
    }
  };

  useEffect(() => {
    if (trigger) {
      form.validateFields()
        .then(() => {
          confirm({
            title: "ยืนยัน!",
            icon: <ExclamationCircleOutlined />,
            content: "คุณต้องการที่จะบันทึกข้อมูลหรือไม่?",
            cancelText: "ยกเลิก",
            okText: "ยืนยัน",
            onOk() {
              form.submit();
            },
            onCancel() {
              setTrigger(false);
            }
          });
        }).catch(() => {
          setTrigger(false);
        });
    }
  }, [trigger]);

  const handleSearchCustomer = async (data: any) => {
    if (data.length < 3) return;
    const res = await tCustomerHeadService.getCustomerByParam(data);
    setCustomers(res.data);
  };

  const handleChangeCustomer = (icustCode: any) => {
    const custArray = customers as Array<TCustomerHeadView>;
    const findCust = custArray.find((ob) => ob.icustCode === icustCode);
    setCustSelected(findCust);
  }

  const handleSearchBankHead = async (data: any) => {
    if (data.length < 3) return;
    const res = await tBankHeadService.getBankHeads(data);
    setBankHeads(res.data);
  }

  const handleChangeBankHead = async (data: any) => {
    if (!data.length) return;
    const res = await tBankBranchHeadService.getBankBranchHeadByBankCode(data);
    setBankBranchs(res.data);
  }

  const isExistChequeNumber = async (chequeNumber: string, bankCode: string) => {
    const res = await chequeHeadService.isExistChequeNumber(chequeNumber.trim(), bankCode.trim());
    return (res.data) ? true : false;
  }

  const onFinish = async (values: any) => {
    if (await isExistChequeNumber(values.chequeNumber, values.bankCode)) {
      showNotification(NotificationType.ERROR, "มีเช็คอยู่แล้ว กรุณาระบุเลขที่เช็คใหม่!");
      setTrigger(false);
    } else {
      let _tChequeHead: TChequeHead = {
        ...tChequeHead,
        financetype: values.financeType,
        customercode: custSelected!.icustCode,
        chequenumber: values.chequeNumber,
        bankcode: values.bankCode,
        bankbranchcode: values.bankBranchCode,
        chequedate: dateToString(values.chequeDate),
        chequeamt: parserNumber(values.chequeAmount),
        remaintamt: parserNumber(values.chequeAmount),
        chequeoner: custSelected!.iprenameName.trim() + "" + custSelected!.iname.trim(),
        remark: values.remark.trim(),
      };
      try {
        await chequeHeadService.saveTChequeHead(_tChequeHead);
        setTrigger(false);
        showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเช็คเรียบร้อยแล้ว!");
        setAddTChequeHeadModalVisible(false);
      } catch (err) {
        setTrigger(false);
        showNotification(NotificationType.ERROR, "เกิดข้อผิดพลาด กรุณาติดต่อผู้แลระบบ !");
      }
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "เกิดข้อผิดพลาด กรุณาติดต่อผู้แลระบบ !");
    setTrigger(false);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>บันทึกรายการเช็คใหม่</Typography.Title>
          </div>
        }
        visible={addTChequeHeadModalVisible}
        width={"50%"}
        style={{ paddingTop: "5px" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setAddTChequeHeadModalVisible(false)}
      >
        <Form
          form={form}
          name="addTChequeHead"
          initialValues={{ remember: true }}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="ชำระสำหรับใบงาน"
            name="financeType"
            rules={[{ required: true, message: "กรุณาระบุประเภทใบสั่งงาน!" }]}
          >
            <Select
              showSearch
              size="middle"
              placeholder="เลือกประเภทใบสั่งงาน"
              optionFilterProp="children"
              filterOption={true}
            >
              {tTypeworksheetRefs.map((obj: TTypeworksheetRef) => {
                return (
                  <Option key={obj.iid.trim()} value={obj.iid}>
                    {obj.iname}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="ลูกค้า"
            name="customerCode"
            rules={[{ required: true, message: "กรุณาระบุลูกค้า!" }]}
          >
            <Select
              showSearch
              allowClear
              size="middle"
              placeholder="เลือกลูกค้า"
              optionFilterProp="children"
              filterOption={true}
              onSearch={handleSearchCustomer}
              onSelect={handleChangeCustomer}
            >
              {customers.map((obj) => {
                return (
                  <Option key={obj.icustCode} value={obj.icustCode}>
                    {obj.iprenameName}{obj.iname}  {obj.isurname ? obj.isurname : ""} : {obj.iphone}<br />
                    <span style={{ marginLeft: "1rem", fontSize: 12 }}>
                      {obj.iaddress}{'   '}อำเภอ/เขต{'   '}{obj.iamphurName}{'   '}จังหวัด{'   '}{obj.iprovinceName}{'   '}{obj.ipostcode}
                    </span>
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="ธนาคาร"
            name="bankCode"
            rules={[{ required: true, message: "กรุณาระบุธนาคาร!" }]}
          >
            <Select
              showSearch
              allowClear
              size="middle"
              placeholder="เลือกธนาคาร"
              optionFilterProp="children"
              filterOption={true}
              onSearch={handleSearchBankHead}
              onChange={handleChangeBankHead}
            >
              {bankHeads.map((obj) => {
                return (
                  <Option key={obj.ibankCode} value={obj.ibankCode}>
                    {obj.ibankName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label="สาขา"
            name="bankBranchCode"
            rules={[{ required: true, message: "กรุณาระบุสาขาธนาคาร!" }]}
          >
            <Select
              showSearch
              size="middle"
              placeholder="เลือกสาขา"
              optionFilterProp="children"
              filterOption={true}
            >
              {bankBranchs.map((obj) => {
                return (
                  <Option key={obj.ibankBranchCode} value={obj.ibankBranchCode}>
                    {obj.ibankBranchName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="เลขที่เช็ค"
            name="chequeNumber"
            rules={[
              { required: true, message: "กรุณาระบุเลขที่เช็ค!" },
              {
                validator: (_, value) => {

                  if (value && (value === " " || value === "-" || value.trim().length < 7)) {
                    return Promise.reject(new Error('กรุณาป้อนข้อมูลรหัสย่อให้ถูกต้อง!'))
                  } else {
                    return Promise.resolve()
                  }
                },
              },]}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            label="เช็คลงวันที่"
            name="chequeDate"
            rules={[{ required: true, message: "กรุณาระบุวันที่เช็ค!" }]}
          >
            <DatePicker
              format={Constants.DATE_FORMAT}
              size="middle"
              placeholder="เลือกวันที่เช็ค"
              style={{ width: 300 }}
            />
          </Form.Item>
          <Form.Item
            label="จำนวนเงิน"
            name="chequeAmount"
            rules={[
              { required: true, message: "กรุณาระบุจำนวนเงิน!" },
              {
                validator: (_, value) => {

                  if (value && (value === " " || value === "-" || Number(value) <= 0)) {
                    return Promise.reject(new Error('กรุณาระบุจำนวนเงินให้ถูกต้อง!'))
                  } else {
                    return Promise.resolve()
                  }
                },
              },]}
          >
            <Input
              type="text"
              onKeyDown={(event: any) => textNumber(event)}
              onPressEnter={(e: any) => form.setFieldsValue({ chequeAmount: formatNumber(parserNumber(e.target.value)) })}
              onBlur={(e: any) => form.setFieldsValue({ chequeAmount: formatNumber(parserNumber(e.target.value)) })}
              onFocus={(e) => e.target.select()}
              style={{ textAlign: "end", width: "100%" }}
            ></Input>
          </Form.Item>
          <Form.Item
            label="หมายเหตุ"
            name="remark"
            rules={[{ required: true, message: "กรุณาระบุหมายเหตุ!" }]}
          >
            <TextArea
              rows={2}
            ></TextArea>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AddTChequeHeadModal