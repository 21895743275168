import http from "src/lib/http";
import Constants from "src/utils/constants";

const getRyReftypecustomerList = () => {
  return http.get(
    Constants.DEFAULT_URL + "/ryReftypecustomer"
  );
};

const getRyReftypecustomer = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/ryReftypecustomer/" + id);
}

const defaultApi = {
  getRyReftypecustomer,
  getRyReftypecustomerList,
};
export default defaultApi;