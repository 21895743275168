import React, { useEffect, useState } from 'react'
import { Document, Page } from 'react-pdf'
import Constants from 'src/utils/constants';


interface IProps {
  urlPath: any,
  onClose: any
}

const PdfViewPopup: React.FC<IProps> = (props) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [url, setUrl] = useState('');

  useEffect(() => {
    
  }, [])



  const onDocumentLoadSuccess = async (successEvent: any) => {
    setNumPages(successEvent.numberPages);
    const data = await successEvent.getData();

    const blob = new Blob([data], { type: "application/pdf"});
    window.open(URL.createObjectURL(blob));

    if (props.onClose) {
      props.onClose();
    }
  }

  const userInfoObj = localStorage.getItem(Constants.AUTHEN_USER);
  let options = {}
  if (userInfoObj) {
    options = {
      ...options,
      httpHeaders: {
        "AUTHEN_USER": encodeURIComponent(userInfoObj)
      }
      
    };
  }

  return (
    <div>
      <Document
        options={options}
        file={{
          url: props.urlPath        }}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(error) => console.log(error)}
      >
        <Page scale={1.5} pageNumber={pageNumber} />
      </Document>
    </div>
  )
}

export default PdfViewPopup