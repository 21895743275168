import { CloseCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Drawer, Form, Input, InputNumber, Row, Space, Spin, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { TAssetHeadView } from "src/domain/master/view/tAssetHeadView";
import { TWorksheetTruckDischargeView } from "src/domain/worksheet/view/tWorksheetTruckDischargeView";
import { useAppSelector } from "src/stores/store";
import { formatTime, toThDate } from "src/utils/dateTimeUtils";
import tAssetHeadService from "../../services/tasset/tAssetHeadService";

import { dateToString, timeToString } from "src/utils/dateTimeUtils";
import { NotificationType, showNotification } from "../../utils/notificationUtils";

interface IParams {
    visibleMode: string;
    selectedTruckDischarge: TWorksheetTruckDischargeView;
    selectedTruckDischarges: TWorksheetTruckDischargeView[];
    setSelectedTruckDischarges?: any;
    visibleAddTruckDischargeDrawer: boolean;
    setVisibleAddTruckDischargeDrawer: any;
}

const AddTruckDischargeDrawer: React.FC<IParams> = (props) => {
    const { visibleMode, selectedTruckDischarge, selectedTruckDischarges, setSelectedTruckDischarges, visibleAddTruckDischargeDrawer, setVisibleAddTruckDischargeDrawer } = props;
    const [trigger, setTrigger] = useState<boolean>(false);
    const [tAssetHead, setTAssetHead] = useState<TAssetHeadView>({} as TAssetHeadView);
    const [form] = Form.useForm();
    const [showSpin, setShowSpin] = useState<boolean>(false);
    const userInfo = useAppSelector((state) => state.authen.authenUser.user);

    useEffect(() => {
        if (trigger) {
            form.submit();
        }
    }, [trigger]);

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        setShowSpin(false);

        form.setFieldsValue({
            truckMileLeave: selectedTruckDischarge.truckMileLeave ? selectedTruckDischarge.truckMileLeave : 0,
            truckMileLeaveCheck: false,
            truckDischargeRemark: selectedTruckDischarge.truckDischargeRemark,
            drivercode: selectedTruckDischarge.idriverCode
        });
        return () => {
        };
    }, [tAssetHead]);

    const init = async () => {
        if (selectedTruckDischarge.itruckCode) {
            const res = await tAssetHeadService.getTAssetHeadViewById(selectedTruckDischarge.itruckCode);
            setTAssetHead(res.data);
        }
    };

    const onFinish = async (values: any) => {
        if ("001" === tAssetHead.assettype || "007" === tAssetHead.assettype) {
            if (!values.truckMileLeaveCheck) {
                if (Number(values.milearrivenumber) > tAssetHead.truckmile && !values.drivercode) {
                    showNotification(NotificationType.ERROR, "กรุณาป้อนเลข mile หรือเลือกคนขับรถขณะปล่อยรถให้ถูกต้อง!");
                    setTrigger(false);
                    return;
                }
            } else {
                if (!values.drivercode) {
                    showNotification(NotificationType.ERROR, "กรุณาป้อนคนขับรถขณะปล่อยรถให้ถูกต้อง!");
                    setTrigger(false);
                    return;
                }
            }
        }

        const _tWorksheetTruckDischargeView: TWorksheetTruckDischargeView = {
            ...selectedTruckDischarge,
            truckMileLeave: Number(values.truckMileLeave),
            truckDischargeRemark: values.truckDischargeRemark,
            icreateUser: userInfo.userId,
            iupdateDate: dateToString(new Date()),
            iupdateTime: timeToString(new Date())
        };
        setSelectedTruckDischarges([...selectedTruckDischarges, _tWorksheetTruckDischargeView]);
        form.resetFields();
        setTrigger(false);
        setVisibleAddTruckDischargeDrawer(false);
    };

    const onFinishFailed = (errorInfo: any) => {
        setTrigger(false);
    };

    return (
        <>
            <Drawer
                title={
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "baseline",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography.Title level={4}>ข้อมูลรถ/ตู้/อุปกรณ์ ฯลฯ</Typography.Title>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "",
                            }}
                        >
                            <Button
                                type="primary"
                                danger
                                icon={<CloseCircleOutlined />}
                                onClick={() => setVisibleAddTruckDischargeDrawer(false)}>
                                {visibleMode !== "VIEW" ? "ยกเลิก" : "ปิด"}
                            </Button>
                            {visibleMode !== "VIEW" && <Button
                                onClick={() => setTrigger(true)}
                                type="primary"
                                icon={<SaveOutlined />}
                                style={{ marginLeft: "5px" }}
                            >
                                บันทึก
                            </Button>
                            }
                        </div>
                    </div>
                }
                width="90%"
                visible={visibleAddTruckDischargeDrawer}
                forceRender={true}
                placement="right"
                bodyStyle={{ paddingBottom: 80 }}
                closable={false}
                destroyOnClose={true}
            >
                <Content className="app-page-content">
                    <div>
                        <Spin spinning={showSpin}>
                            <Form
                                form={form}
                                name="selectedTruckDischarge"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <Form.Item name="drivercode" hidden={true}>
                                    <Input />
                                </Form.Item>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>เลขที่ใบงาน: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item>{selectedTruckDischarge.iworksheetCode}</Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>วัน-เวลาที่เริ่มงาน: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item>{toThDate(selectedTruckDischarge.istartDate) + ":" + formatTime(selectedTruckDischarge.istartTime)}</Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>วัน-เวลาที่สิ้นสุดงาน: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item>{toThDate(selectedTruckDischarge.iendDate) + ":" + formatTime(selectedTruckDischarge.iendTime)}</Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>สถานที่ทำงาน: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item>{selectedTruckDischarge.ilocation}</Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>รายการ: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item>{selectedTruckDischarge.serviceName}</Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>พนักงานขับรถ: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item> {selectedTruckDischarge.idriverCode ? "(" + selectedTruckDischarge.idriverCode + ")" : ""} {selectedTruckDischarge.idriverName}</Form.Item>
                                    </Col>
                                </Row>
                                {visibleMode === "VIEW" &&
                                    <>
                                        <Row>
                                            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                                <Typography.Text>เลขไมล์ออก: </Typography.Text>
                                            </Col>
                                            <Col span={20}>
                                                <Space size={"large"}>
                                                    <Form.Item>{selectedTruckDischarge.truckMileLeave}</Form.Item>
                                                    <Form.Item>
                                                        <Space align="baseline">
                                                            <Checkbox disabled></Checkbox>
                                                            <Typography.Text type="danger">ปล่อยใบสั่งงานซ้อน</Typography.Text>
                                                        </Space>
                                                    </Form.Item>
                                                </Space>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                                <Typography.Text>หมายเหตุ: </Typography.Text>
                                            </Col>
                                            <Col span={20}>
                                                <Form.Item>{selectedTruckDischarge.truckDischargeRemark}</Form.Item>
                                            </Col>
                                        </Row>
                                    </>
                                }
                                {visibleMode !== "VIEW" &&
                                    <>
                                        <Row>
                                            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                                <Typography.Text>เลขไมล์ออก: </Typography.Text>
                                            </Col>
                                            <Col span={20}>
                                                <Space size={"large"}>
                                                    <Form.Item
                                                        name="truckMileLeave"
                                                        rules={[
                                                            { required: false }
                                                        ]}
                                                    >
                                                        <InputNumber
                                                            controls={false}
                                                            style={{ width: "150px" }}
                                                            onFocus={(e) => e.target.select()}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="truckMileLeaveCheck"
                                                        rules={[
                                                            { required: false }
                                                        ]}
                                                        valuePropName="checked">
                                                        <Space align="baseline">
                                                            <Checkbox></Checkbox>
                                                            <Typography.Text type="danger">ปล่อยใบสั่งงานซ้อน</Typography.Text>
                                                        </Space>
                                                    </Form.Item>
                                                </Space>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                                <Typography.Text>หมายเหตุ: </Typography.Text>
                                            </Col>
                                            <Col span={20}>
                                                <Form.Item
                                                    name="truckDischargeRemark"
                                                    rules={[
                                                        { required: false }
                                                    ]}
                                                >
                                                    <TextArea
                                                        style={{ width: "500px" }}
                                                        rows={3}
                                                        maxLength={255}
                                                    ></TextArea>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </Form>
                        </Spin>
                    </div>
                </Content>
            </Drawer >
        </>
    );
};

export default AddTruckDischargeDrawer;
