import { Button, Modal, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import { RyRefbranch } from 'src/domain/ryRefbranch';
import ryRefbranchService from 'src/services/reference/ryRefbranchService';

interface IProps {
  lookupRyRefBranchModalVisible: boolean;
  setLookupRyRefBranchModalVisible: any;
  setRyRefBranchSelected: any;
}

const LookupRyRefBranchModal: React.FC<IProps> = (props) => {
  const { lookupRyRefBranchModalVisible, setLookupRyRefBranchModalVisible, setRyRefBranchSelected } = props;
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [ryRefbranchs, setRyRefbranchs] = useState<RyRefbranch[]>([]);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: RyRefbranch, index: number) => (<>{index + 1}</>)
    },
    {
      title: "รหัส",
      dataIndex: "id",
      key: "id",
      align: "center" as "center",
      render: (value: string, item: RyRefbranch) => (
        <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.id}</Button>
      )
    },
    {
      title: "Code",
      dataIndex: "codeName",
      key: "codeName",
    },
    {
      title: "ชื่อสาขา",
      dataIndex: "name",
      key: "name",
    },
  ]

  useEffect(() => {
    loadRyRefbranch();
  }, []);

  const loadRyRefbranch = async () => {
    const res = await ryRefbranchService.getRyRefbranchList();
    setRyRefbranchs(res.data);
  }

  const onItemCliked = (item: RyRefbranch) => {
    setRyRefBranchSelected(item);
    setLookupRyRefBranchModalVisible(false);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>รายการสาขา</Typography.Title>
          </div>
        }
        visible={lookupRyRefBranchModalVisible}
        width={"50%"}
        style={{ minHeight: "80%" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setLookupRyRefBranchModalVisible(false)}
      >
        <div style={{ padding: "10px 0px 0px" }}>
          <Table
            columns={columns}
            dataSource={ryRefbranchs}
            loading={loading}
            size="small"
            style={{ minHeight: 200 }}
            rowKey={(item: RyRefbranch) => item.id}
            pagination={{
              pageSize: 15,
              onChange(current) {
                setPage(current);
              }
            }}
          />
        </div>
      </Modal>
    </>
  )
}

export default LookupRyRefBranchModal