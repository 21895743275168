import { CheckOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Space, Table, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TAssetHead } from "src/domain/master/tAssetHead";
import { TWorksheetTruckDischargeView } from "src/domain/worksheet/view/tWorksheetTruckDischargeView";
import PageHeader from "src/layouts/PageHeader";
import tAssetHeadService from "src/services/tasset/tAssetHeadService";
import worksheetService from "src/services/worksheetService";
import { useAppSelector } from "src/stores/store";
import Constants from "src/utils/constants";
import { NotificationType, showNotification } from "src/utils/notificationUtils";
import { formatNumberInt } from "src/utils/numberUtils";
import tWorksheetHeadService from "../../services/tWorksheetHeadService";
import { formatTime, toThDate } from "../../utils/dateTimeUtils";
import AddTruckDischargeDrawer from "./AddTruckDischargeDrawer";

const { Text, Title } = Typography;

const { confirm } = Modal;

const TruckUnDischargeList: React.FC<TWorksheetTruckDischargeView> = () => {
    const [truckDischarges, setTruckDischarges] = useState<TWorksheetTruckDischargeView[]>([]);
    const [selectedTruckDischarges, setSelectedTruckDischarges] = useState<TWorksheetTruckDischargeView[]>([]);
    const [selectedTruckDischarge, setSelectedTruckDischarge] = useState<TWorksheetTruckDischargeView>({} as TWorksheetTruckDischargeView);
    const [page, setPage] = React.useState(1);
    const history = useHistory();
    const [loading, setLoading] = React.useState(true);

    const [visibleMode, setVisibleMode] = useState<string>("");
    const [visibleAddTruckDischargeDrawer, setVisibleAddTruckDischargeDrawer] = useState<boolean>(false);

    const userInfo = useAppSelector((state) => state.authen.authenUser.user);
    const [form] = Form.useForm();

    useEffect(() => {
        doSearch(userInfo.branchId, "")
    }, []);

    useEffect(() => {
        doFilter();
    }, [selectedTruckDischarges]);


    const dischargeColumns = [
        {
            title: "ลำดับ",
            key: "index",
            width: 70,
            render: (value: number, item: TWorksheetTruckDischargeView, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
        },
        {
            title: "เลขที่ใบสั่งงาน",
            dataIndex: "iworksheetCode",
            key: "iworksheetCode",
            width: 130,
        },
        {
            title: "วัน-เวลาที่เริ่มงาน",
            dataIndex: "istartDate",
            key: "istartDate",
            width: 150,
            render: (value: any, item: TWorksheetTruckDischargeView) => (<>{toThDate(item.istartDate)} : {formatTime(item.istartTime)}</>)
        },
        {
            title: "วัน-เวลาที่สิ้นสุดงาน",
            dataIndex: "iendDate",
            key: "iendDate",
            width: 150,
            render: (value: any, item: TWorksheetTruckDischargeView) => (<>{toThDate(item.iendDate)} : {formatTime(item.iendTime)}</>)
        },
        {
            title: "รายการ",
            dataIndex: "serviceName",
            key: "serviceName",
            width: 250,
        },
        {
            title: "ลูกค้า",
            dataIndex: "icustName",
            key: "icustName",
            width: 200,
        },
        {
            title: "สถานที่ทำงาน",
            dataIndex: "ilocation",
            key: "ilocation",
            width: 250,
        },
        {
            title: "เด็กท้าย",
            dataIndex: "workerNames",
            key: "workerNames",
            width: 100,
        },
        {
            title: "หมายเหตุ",
            dataIndex: "iremark",
            key: "iremark",
            width: 150,
        },
    ];

    const selectedDischargeColumns = [
        {
            title: "ลำดับ",
            key: "index",
            width: 70,
            render: (value: number, item: TWorksheetTruckDischargeView, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
        },
        {
            title: "เลขที่ใบสั่งงาน",
            dataIndex: "iworksheetCode",
            key: "iworksheetCode",
            width: 130,
        },
        {
            title: "วัน-เวลาที่เริ่มงาน",
            dataIndex: "istartDate",
            key: "istartDate",
            width: 150,
            render: (value: any, item: TWorksheetTruckDischargeView) => (<>{toThDate(item.istartDate)} : {formatTime(item.istartTime)}</>)
        },
        {
            title: "วัน-เวลาที่สิ้นสุดงาน",
            dataIndex: "iendDate",
            key: "iendDate",
            width: 150,
            render: (value: any, item: TWorksheetTruckDischargeView) => (<>{toThDate(item.iendDate)} : {formatTime(item.iendTime)}</>)
        },
        {
            title: "รายการ",
            dataIndex: "serviceName",
            key: "serviceName",
            width: 250,
        },
        {
            title: "สถานที่ทำงาน",
            dataIndex: "ilocation",
            key: "ilocation",
            width: 250,
        },
        {
            title: "พนักงานขับรถ",
            dataIndex: "idriverName",
            key: "idriverName",
            width: 130,
        },
        {
            title: "เด็กท้าย",
            dataIndex: "workerNames",
            key: "workerNames",
            width: 100,
        },
        {
            title: "เลขไมล์ออก",
            dataIndex: "truckMileLeave",
            key: "truckMileLeave",
            width: 100,
            align: "right" as "right",
            render: (value: any, item: TWorksheetTruckDischargeView) => (<>{formatNumberInt(item.truckMileLeave)}</>)
        },
        {
            title: "หมายเหตุ",
            dataIndex: "truckDischargeRemark",
            key: "truckDischargeRemark",
            width: 150,
        },
        {
            title: "ลบ",
            dataIndex: "delete",
            key: "delete",
            width: 50,
            render: (value: any, item: TWorksheetTruckDischargeView) => (
                <Button
                    type="primary"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => onDeleteDischarge(item)}>
                </Button>)
        },
    ];

    const onDischargeItemClicked = async (item: TWorksheetTruckDischargeView) => {
        if ("02" !== item.typeworksheetflag && "04" !== item.typeworksheetflag) {
            if (item.iworksheetStatus !== "98" || item.ireserveFlg !== -1) {
                const res = await tAssetHeadService.getTAssetHeadById(item.itruckCode!);
                const tAssetHead = res.data as TAssetHead;
                if ("00" === tAssetHead.assetstatus) {
                    setVisibleMode("ADD")
                    setSelectedTruckDischarge(item)
                    setVisibleAddTruckDischargeDrawer(true);
                } else {
                    showNotification(NotificationType.ERROR, "สถานะของรถ,ตู้ ฯลฯ ไม่พร้อมที่จะถูกใช้งาน!");
                }
            } else {
                showNotification(NotificationType.ERROR, "ใบสั่งงานนี้ถูกยกเลิกแล้วไม่สามารถปล่อยใบสั่งงานได้!");
            }
        } else {
            showNotification(NotificationType.ERROR, "ประเภทใบสั่งงานไม่ถูกต้อง กรุณาเลือกใบสั่งงานใหม่!");
        }
    };

    const onUnDischargeItemCliked = (item: TWorksheetTruckDischargeView) => {
        setVisibleMode("EDIT")
        setSelectedTruckDischarge(item)
        setVisibleAddTruckDischargeDrawer(true);
    };

    const onDeleteDischarge = (item: TWorksheetTruckDischargeView) => {
        setTruckDischarges([...truckDischarges, item]);
        setSelectedTruckDischarges(selectedTruckDischarges.filter((itemItem) => itemItem.iworksheetCode !== item.iworksheetCode));
    }

    const onSubmitDischarge = async () => {
        try {
            setLoading(true);
            await worksheetService.truckDischargeWorksheets(selectedTruckDischarges);
            showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลปล่อยรถเรียบร้อยแล้ว!");
            setSelectedTruckDischarges([]);
            setSelectedTruckDischarge({} as TWorksheetTruckDischargeView);
            form.resetFields();
            setLoading(false);
        } catch (err) {
            showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        }
    }
    const onConfirmDischarge = () => {
        confirm({
            title: "ยืนยัน!",
            icon: <ExclamationCircleOutlined />,
            content: "คุณต้องการที่จะบันทึกข้อมูลหรือไม่?",
            cancelText: "ยกเลิก",
            okText: "ยืนยัน",
            onOk() {
                onSubmitDischarge();
            }
        });
    }
    const doSearch = async (branchId: string, worksheetCode: string) => {
        setLoading(true);
        const res = await tWorksheetHeadService.getWorksheetTruckUnDischarges(branchId, worksheetCode);
        const truckDischargeList = res.data as TWorksheetTruckDischargeView[]
        const filteredList = truckDischargeList.filter((item) => !selectedTruckDischarges.find((selectedItem) => item.iworksheetCode === selectedItem.iworksheetCode));
        setTruckDischarges(filteredList);
        setLoading(false);
    }

    const doFilter = async () => {
        const temp = truckDischarges.filter((item) => !selectedTruckDischarges.find((selectedItem) => item.iworksheetCode === selectedItem.iworksheetCode));
        setTruckDischarges(temp);
        setSelectedTruckDischarge({} as TWorksheetTruckDischargeView);
    }

    const getItemColor = (item: TWorksheetTruckDischargeView) => {
        const now = moment().format(Constants.DB_FORMAT);
        let foreColor = "black";
        let backColor = ""
        if (Number(item.istartDate) < Number(now)) {
            foreColor = 'red';
        }
        if ("01" === item.typeworksheetflag || "03" === item.typeworksheetflag) {
            backColor = "darkkhaki";
        } else if ("04" === item.typeworksheetflag) {
            backColor = "whitesmoke";
        } else {
            backColor = "white";
        }

        return 'text-' + foreColor + '-bg-' + backColor;
    }

    const onFinish = async (values: any) => {
        const branchId = userInfo.branchId;
        const worksheetCode: string = values.worksheetCode ? values.worksheetCode : "";
        doSearch(branchId, worksheetCode)
    }

    const onFinishFailed = (errorInfo: any) => {
    };

    return (
        <>
            <PageHeader title="ใบงานที่ค้างรอปล่อยรถ" onClose={() => history.push("/app/truckDischarge/list")}>
                <Button
                    size="small"
                    type="primary"
                    disabled={selectedTruckDischarges.length === 0}
                    icon={<CheckOutlined />}
                    onClick={onConfirmDischarge}
                >
                    ตกลงรอปล่อย
                </Button>
            </PageHeader>
            <Content className="app-page-content">
                <Form
                    form={form}
                    name="bankBranchHead"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "1em 0 1em 0",
                            paddingLeft: "1em",
                            borderBlockEnd: "2px solid #c9c9c9",
                        }}
                    >
                        <Space direction="vertical" size={"small"}>
                            <Form.Item
                                label="เลขที่ใบสั่งงาน"
                                name="worksheetCode"
                                rules={[{ required: false }]}
                            >
                                <Input
                                    allowClear
                                    placeholder="ป้อนเลขที่ใบสั่งงาน"
                                    onChange={form.submit}
                                ></Input>
                            </Form.Item>
                        </Space>
                    </div>
                </Form>
                <div>
                    <Text type="danger" style={{
                        paddingLeft: "1.5em",
                    }}>***ดับเบิลคลิกเพื่อเลือกรายการ</Text>
                    <Table
                        columns={dischargeColumns}
                        dataSource={truckDischarges}
                        size="small"
                        loading={loading}
                        rowClassName={getItemColor}
                        scroll={{ x: 1800, y: 300}}
                        pagination={false}
                        rowKey={(item: TWorksheetTruckDischargeView) => item.ireserveCode!}
                        onRow={(item: TWorksheetTruckDischargeView) => {
                            return {
                                onDoubleClick: () => onDischargeItemClicked(item),
                            };
                        }}
                    />
                </div>
                <div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            margin: "1em",
                            paddingLeft: "1.5em",
                            borderBlockEnd: "2px solid #c9c9c9",
                        }}
                    >
                        <Text type="danger">***ดับเบิลคลิกเพื่อแก้ไขเลขไมล์</Text>

                        <Space direction="horizontal" size={"middle"}>
                            <Space direction="horizontal" size={"small"}>
                                <Title level={3}>จำนวนรถ:</Title>
                                <Title type="danger" level={3}>{selectedTruckDischarges.length}</Title>
                                <Title level={3}> คัน</Title>
                            </Space>
                            <Space direction="horizontal" size={"small"}>
                                <Title level={3}>จำนวนใบงาน:</Title>
                                <Title type="danger" level={3}>{selectedTruckDischarges.length}</Title>
                                <Title level={3}> ใบ</Title>
                            </Space>
                        </Space>
                    </div>
                    <Table
                        columns={selectedDischargeColumns}
                        dataSource={selectedTruckDischarges}
                        size="small"
                        scroll={{ x: 1800, y: 300}}
                        pagination={false}
                        rowKey={(item: TWorksheetTruckDischargeView) => item.ireserveCode!}
                        onRow={(item: TWorksheetTruckDischargeView) => {
                            return {
                                onDoubleClick: () => onUnDischargeItemCliked(item),
                            };
                        }}
                    />
                </div>
            </Content>
            <div> {
                visibleAddTruckDischargeDrawer && <AddTruckDischargeDrawer
                    visibleMode={visibleMode}
                    selectedTruckDischarge={selectedTruckDischarge}
                    selectedTruckDischarges={selectedTruckDischarges}
                    setSelectedTruckDischarges={setSelectedTruckDischarges}
                    visibleAddTruckDischargeDrawer={visibleAddTruckDischargeDrawer}
                    setVisibleAddTruckDischargeDrawer={setVisibleAddTruckDischargeDrawer} />
            }</div>
        </>
    );
};

export default TruckUnDischargeList;
