import http from "src/lib/http";
import Constants from "src/utils/constants";

const getTTypetruckRefList = () => {
  return http.get(
    Constants.DEFAULT_URL + "/tTypetruckRef"
  );
};

const getTTypetruckRef = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/tTypetruckRef/" + id);
}

const defaultApi = {
  getTTypetruckRef,
  getTTypetruckRefList,
};
export default defaultApi;