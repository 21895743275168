import { PlusSquareOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Select, Space, Table, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { TEmployee2View } from 'src/domain/master/view/tEmployee2View';
import { TUserHead } from 'src/domain/tUserHead';
import { TWorksheetHead } from 'src/domain/worksheet/tWorksheetHead';
import { TWorksheetWorkerView } from 'src/domain/worksheet/view/tWorksheetWorkerView';
import { WorksheetHeadData } from 'src/domain/worksheet/view/worksheetHeadData';
import employeeService from 'src/services/employee/tEmployee2Service';
import worksheetService from 'src/services/worksheetService';
import { useAppSelector } from 'src/stores/store';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import AddWorksheetWorkerModal from '../AddWorksheetWorkerModal';

const { Option } = Select;

interface IProps {
  editWorksheetWorkerListVisible: boolean;
  setEditWorksheetWorkerListVisible: any;
  // setUpdateStatus: any;
  worksheetHeadData: WorksheetHeadData;
  setWorksheetHeadData: any;
  tUserHeadUpdate: TUserHead;
}

export const EditWorksheetWorkerListModal: React.FC<IProps> = (props) => {
  const { editWorksheetWorkerListVisible, setEditWorksheetWorkerListVisible, worksheetHeadData, setWorksheetHeadData, tUserHeadUpdate } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [visibleWorksheetWorkerModal, setVisibleWorksheetWorkerModal] = useState(false);
  const [form] = Form.useForm();
  const [worksheetHeadDataTemp, setWorksheetHeadDataTemp] = useState<WorksheetHeadData>({} as WorksheetHeadData);
  const [tworksheetWorkerFormDataList, setTworksheetWorkerFormDataList] = useState<TWorksheetWorkerView[]>([]);

  const [employees, setEmployees] = useState<TEmployee2View[]>([]);//ผู้แจ้ง
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      key: "index",
      align: "center" as "center",
      width: 50,
      render: (value: any, item: TWorksheetWorkerView, index: number) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: "ชื่อ-นามสกุล",
      dataIndex: "iworkerName",
      key: "iworkerName",
      width: 150,
    },
    {
      title: "ที่อยู่",
      dataIndex: "iworkerAddress",
      key: "iworkerAddress",
      width: 250,
    },
    {
      title: "เบอร์โทร",
      dataIndex: "iworkerPhone",
      key: "iworkerPhone",
      width: 150,
    },
    {
      title: "ประเภทพนักงาน",
      dataIndex: "itypeworkerName",
      key: "itypeworkerName",
      width: 150,
    },
    {
      title: "",
      key: "action",
      align: "center" as "center",
      with: 20,
      render: (value: any, item: TWorksheetWorkerView, index: number) => {
        return (
          <span>
            <a onClick={(e) => { onDelete(item, index); }} style={{ color: "red" }}
            >
              X
            </a>
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  useEffect(() => {
    loadData();
    return () => { };
  }, []);

  const loadData = async () => {
    setWorksheetHeadDataTemp(worksheetHeadData);
    setTworksheetWorkerFormDataList(worksheetHeadData.tworksheetWorkerViewList);
  };

  const handleSearchEmployee = async (data: any) => {
    if (data.length < 3) return;
    const res = await employeeService.getEmployee2ByParam(data);
    setEmployees(res.data);
  };

  const onDelete = (item: TWorksheetWorkerView, index: number) => {
    const array = tworksheetWorkerFormDataList ? [...tworksheetWorkerFormDataList] : [];
    array?.splice(index, 1);
    setTworksheetWorkerFormDataList(array);
  }

  const onFinish = async (values: any) => {
    const employeeArr = employees as Array<TEmployee2View>;
    const empSelected = employeeArr.find((obj) => obj.id === values.userInfoId);
    try {
      const temp = {
        tworksheetHead: {
          ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
          ilastUser: tUserHeadUpdate.iuserId,
          itermNo: userInfo.termNo,
          isavStation: userInfo.saveStation,
        },
        tworksheetWorkerViewList: tworksheetWorkerFormDataList,
        updateUser: tUserHeadUpdate,
      };
      setWorksheetHeadData(temp);

      await worksheetService.updateWorksheetWithSubMenuKey(temp, values.userRemark, empSelected ? empSelected.empname : "", "MENU_WORKSHEET_WORKER");
      showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเรียบร้อย");
      setEditWorksheetWorkerListVisible(false);
      // setUpdateStatus(true);
      form.resetFields();
      setTrigger(false);
    } catch (err) {
      showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
      setTrigger(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาตรวจสอบข้อมูลให้ครบถ้วน!");
    setTrigger(false);
  };


  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>แก้ไขรายการเด็กติดรถ</Typography.Title>
          </div>
        }
        visible={editWorksheetWorkerListVisible}
        width={"50%"}
        style={{ minHeight: "70%" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setEditWorksheetWorkerListVisible(false)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
        >
          <Space align="baseline" direction="horizontal">
            <Button
              size="small"
              type="primary"
              icon={<PlusSquareOutlined />}
              onClick={() => setVisibleWorksheetWorkerModal(true)}
            >
              เพิ่ม
            </Button>
          </Space>
          <Typography.Title
            type="danger"
            level={4}
            style={{ height: "22px", textAlign: "right" }}
          >
            จำนวน :{" "}
            {!tworksheetWorkerFormDataList ? 0 : tworksheetWorkerFormDataList.length}
          </Typography.Title>
        </div>
        <div style={{ marginBottom: 20, minHeight: 80 }}>
          <Table
            columns={columns}
            dataSource={tworksheetWorkerFormDataList}
            size="small"
            pagination={false}
            rowKey={(item: TWorksheetWorkerView) => item.iid}
          ></Table>
        </div>

        <Form
          form={form}
          name="worksheetDetail"
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="ผู้แจ้ง"
            name="userInfoId"
            rules={[{ required: true, message: "กรุณาระบุ ผู้แจ้ง!" }]}
          >
            <Select
              showSearch
              allowClear
              size="middle"
              placeholder="เลือกผู้แจ้ง"
              optionFilterProp="children"
              filterOption={true}
              onSearch={handleSearchEmployee}
            >
              {employees.map((obj) => {
                return (
                  <Option key={obj.id} value={obj.id}>
                    {obj.empname}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="หมายเหตุ"
            name="userRemark"
            rules={[{ required: true, message: "กรุณาระบุเหตุผล !" },
            {
              validator: (_, value) => {
                if (value <= 10) {
                  return Promise.reject(showNotification(NotificationType.ERROR, "กรุณาระบุเหตุผล ให้มากกว่า 10 ตัวอักษร"))
                } else {
                  return Promise.resolve()
                }
              },
            },
            ]}
          >
            <TextArea
              rows={4}
            ></TextArea>
          </Form.Item>
        </Form>
      </Modal>
      <div>
        {visibleWorksheetWorkerModal && (
          <AddWorksheetWorkerModal
            visibleWorksheetWorkerModal={visibleWorksheetWorkerModal}
            setVisibleWorksheetWorkerModal={setVisibleWorksheetWorkerModal}
            tworksheetWorkerFormDataList={tworksheetWorkerFormDataList}
            setTworksheetWorkerFormDataList={setTworksheetWorkerFormDataList}
          ></AddWorksheetWorkerModal>
        )}
      </div>
    </>
  )
}
