import { TPayinHead } from "src/domain/tPayinHead";
import http from "src/lib/http";
import Constants from "src/utils/constants";

const createTPayinHead = (tPayinHead: TPayinHead) => {
  return http.post(Constants.DEFAULT_URL + "/tPayinHead", tPayinHead);
};
const updateTPayinHead = (tPayinHead: TPayinHead) => {
  return http.put(Constants.DEFAULT_URL + "/tPayinHead", tPayinHead);
};
const getTPayinHeadListByParam = (customerCode: string, branchCode: string, createDateFrom: string, createDateTo: string, depositDateFrom: string, depositDateTo: string) => {
  return http.get(Constants.DEFAULT_URL + "/tPayinHead/getTPayinHeadListByParam", {
    params: {
      customerCode,
      branchCode,
      createDateFrom,
      createDateTo,
      depositDateFrom,
      depositDateTo
    }
  });
};

const defaultApi = {
  getTPayinHeadListByParam,
  createTPayinHead,
  updateTPayinHead,
};
export default defaultApi;
