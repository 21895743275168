import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Card, Col, Form,
  Image,
  Input,
  Row,
  Select,
  Space,
  Typography
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router-dom";
import NIM_RODYOK from "src/assets/images/nim_rodyok.png";
import { RyRefbranch } from "src/domain/ryRefbranch";
import { TUserHead } from "src/domain/tUserHead";
import { UserInfo } from "src/domain/userInfo";
import ryRefbranchService from "src/services/reference/ryRefbranchService";
import tUserHeadService from "src/services/tUserHeadService";
import { loginAction, validateAction } from "src/stores/authen/authenAction";
import { useAppSelector } from "src/stores/store";
import { NotificationType, showNotification } from "src/utils/notificationUtils";
import "./Login.css";

const { Option } = Select;

const LoginForm: React.FC<RouteComponentProps> = (props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const isLogined = useAppSelector((state) => state.authen.authenUser.isLogin);
  const [ryRefbranches, setRyRefbranches] = useState<RyRefbranch[]>([]);


  useEffect(() => {
    initial();
  }, []);

  const initial = async () => {
    dispatch(validateAction());
    if (isLogined) {
      history.replace("/app");
    } else {
      const ryRefbranchesRes = await ryRefbranchService.getRyRefbranchList();
      setRyRefbranches(ryRefbranchesRes.data)
    }
  }

  useEffect(() => {
    if (ryRefbranches.length > 0) {
      form.setFieldsValue({
        branchId: "501"
      });
    }
  }, [ryRefbranches]);

  const onFinish = async (values: any) => {
    const { username, password, branchId } = values;

    try {
      const tUserHeadRes = await tUserHeadService.getUserByUserNamePassword(username, password);
      const ryRefbranchRes = await ryRefbranchService.getRyRefbranchById(branchId);
      if (tUserHeadRes.data && ryRefbranchRes.data) {
        const tUserHead = tUserHeadRes.data as TUserHead;
        const ryRefbranch = ryRefbranchRes.data as RyRefbranch;
        const _userInfo: UserInfo = {
          userId: tUserHead.iuserId,
          userName: tUserHead.iuserName,
          fullName: tUserHead.ifullName,
          employeeCode: tUserHead.employeecode,
          permissionCode: tUserHead.ipermissionCode,
          groupPgCode: tUserHead.igroupPgCode,
          termNo: 0,
          saveStation: "RY",
          branchId: ryRefbranch.id,
          branchName: ryRefbranch.name
        }
        form.resetFields();
        dispatch(loginAction(_userInfo));
        history.replace("/app");
      } else {
        showNotification(NotificationType.ERROR, "ไม่พบชื่อผู้ใช้และรหัสผ่าน!");
      }
    } catch (err) {
      showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("error x", errorInfo);
  };

  return (
    <>
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Col span={5}>
          <Space direction="vertical" align="center" >
            <Card className="login-form" bordered={false} style={{ textAlign: "center" }}>
              <Image width={120} src={NIM_RODYOK} style={{ paddingBottom: 50 }} />
              <Form
                form={form}
                name="loginForm"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  name="branchId"
                  rules={[
                    { required: true, message: "กรุณาเลือกสาขา" }
                  ]}
                >
                  <Select
                    placeholder="เลือกสาขา"
                  >
                    {ryRefbranches.map((obj) => {
                      return (
                        <Option key={obj.id} value={obj.id}>
                          {obj.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="username"
                  rules={[
                    { required: true, message: "กรุณาระบุชื่อผู้ใช้!" },
                  ]}
                >
                  <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="ป้อนชื่อผู้ใช้" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "กรุณาระบุรหัสผ่าน!" },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="ป้อนรหัสผ่าน"
                  />
                </Form.Item>

                <br />
                <Form.Item>
                  <Button type="primary" htmlType="submit" block danger>
                    Login
                  </Button>
                  <Typography.Text>NIMRODYOK db5 V1.0-20230805</Typography.Text>
                </Form.Item>
              </Form>
            </Card>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default LoginForm;
