import http from "../../lib/http";
import Constants from "../../utils/constants";

const postTServiceHistory = (tServiceHistory: any) => {
  return http.post(Constants.DEFAULT_URL + "/tServiceHistory", tServiceHistory);
}

const defaultApi = {
  postTServiceHistory,
};
export default defaultApi;
