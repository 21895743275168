import { ClearOutlined, PlusSquareOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TFinanceTrushView } from 'src/domain/finance/view/tFinanceTrushView';
import { RyRefbranch } from 'src/domain/ryRefbranch';
import { RyAccountView } from 'src/domain/view/ryAccountView';
import PageHeader from 'src/layouts/PageHeader';
import ryAccountService from 'src/services/bank/ryAccountService';
import tFinanceTrushService from 'src/services/finance/tFinanceTrushService';
import ryRefbranchService from 'src/services/reference/ryRefbranchService';
import { useAppSelector } from 'src/stores/store';
import Constants from 'src/utils/constants';
import { toThDate } from 'src/utils/dateTimeUtils';
import { formatNumber } from 'src/utils/numberUtils';
import TrushMoneyDrawer from './TrushMoneyDrawer';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';

const { Option } = Select;

const TrushMoneyList: React.FC<TFinanceTrushView> = () => {
  const [page, setPage] = React.useState(1);
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [onDrawerVisible, setOnDrawerVisible] = useState<boolean>(false);
  const [tfinanceTrushViews, setTfinanceTrushViews] = useState<TFinanceTrushView[]>([]);
  const [ryAccounts, setRyAccounts] = useState<RyAccountView[]>([]); //บัญชีธนาคาร
  const [ryRefbranches, setRyRefbranches] = useState<RyRefbranch[]>([]);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 50,
      render: (value: number, item: TFinanceTrushView, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
    },
    {
      title: "วันที่นำฝาก",
      dataIndex: "trushdate",
      key: "trushdate",
      width: 100,
      render: (value: any, item: TFinanceTrushView) => (<>{toThDate(item.trushdate)}</>)
    },
    {
      title: "เลขที่การนำฝาก",
      dataIndex: "id",
      key: "id",
      width: 80,
      render: (value: any, item: TFinanceTrushView) => (
        <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.id}</Button>
      )
    },
    {
      title: "เลขที่บัญชี",
      dataIndex: "accountNumber",
      key: "accountNumber",
      width: 50,
    },
    {
      title: "ชื่อบัญชี",
      dataIndex: "accountName",
      key: "accountName",
      width: 190,
      render: (value: any, item: TFinanceTrushView) => (<>{item.accountName} - {item.bankName}</>)
    },
    {
      title: "ประเภท",
      dataIndex: "type",
      key: "type",
      width: 50,
    },
    {
      title: "เลขที่เช็ค",
      dataIndex: "chquenumber",
      key: "chquenumber",
      width: 50,
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "amount",
      key: "amount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TFinanceTrushView) => (<>{formatNumber(item.amount)}</>)
    },
    {
      title: "พนักงานนำฝาก",
      dataIndex: "trushusername",
      key: "trushusername",
      width: 150,
    },
    {
      title: "หมายเหตุ",
      dataIndex: "remark",
      key: "remark",
      width: 150,
    },
    {
      title: "สาขาที่นำฝาก",
      dataIndex: "branchName",
      key: "branchName",
      width: 130,
    },
  ];

  const getItemColor = (item: TFinanceTrushView) => {
    let foreColor = "";
    if (item.trushstatus === -1) {
      foreColor = "red";
    } else if (item.trushstatus === 0) {
      foreColor = "black";
    } else {
      foreColor = "blue";
    }
    return 'text-' + foreColor;
  }

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    initData();
  }, []);

  const initData = async () => {
    const ryAccountRes = await ryAccountService.getRyAccountViewList();
    const ryRefbranchesRes = await ryRefbranchService.getRyRefbranchList();
    setRyAccounts(ryAccountRes.data);
    setRyRefbranches(ryRefbranchesRes.data);

    form.setFieldsValue({
      fromDate: moment(new Date(), Constants.DATE_FORMAT), //moment(new Date(), Constants.DATE_FORMAT).startOf('month'),
      toDate: moment(new Date(), Constants.DATE_FORMAT), //moment(new Date(), Constants.DATE_FORMAT).endOf('month'),
      id: undefined,
      branchid: undefined,
      accSeq: undefined,
    });
  }

  function onReset() {
    initData();
  }

  const onItemCliked = (item: TFinanceTrushView) => {
    history.replace("/app/trushMoney/view/" + item.id);
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    const id = values.id ? values.id : "";
    const branchid = values.branchid ? values.branchid : "";
    const accSeq = values.accSeq ? values.accSeq : "";
    let fromDate = moment(values.fromDate).format("yyyyMMDD");
    let toDate = moment(values.toDate).format("yyyyMMDD");

    if (id) {
      fromDate = "";
      toDate = "";
    }

    const res = await tFinanceTrushService.findTFinanceTrushViews(
      id,
      branchid,
      accSeq,
      fromDate,
      toDate);
    setTfinanceTrushViews(res.data);
    setLoading(false);
  }

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
    form.resetFields();
  };

  return (
    <>
      <PageHeader title="โปรแกรมฝากเงินเข้าธนาคาร">
        <Button
          size="small"
          type="primary"
          icon={<PlusSquareOutlined />}
          onClick={() => setOnDrawerVisible(true)}
        >
          เพิ่ม
        </Button>
      </PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="financeTrush"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
            }}
          >
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>วันที่นำฝาก ตั้งแต่: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="fromDate"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ธนาคาร: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="accSeq"
                  rules={[{ required: false }]}
                >
                  <Select
                    showSearch
                    size="middle"
                    placeholder="เลือกธนาคาร"
                    optionFilterProp="children"
                    filterOption={true}
                  >
                    {ryAccounts.map((obj: RyAccountView) => {
                      return (
                        <Option key={obj.accountnumber} value={obj.seq}>
                          {obj.accountnumber} : {obj.accountname}<br />
                          <span style={{ marginLeft: "2rem", fontSize: 12 }}>
                            {obj.bankname} - สาขา{obj.branchname}
                          </span>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ถึง: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="toDate"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>สาขาที่นำฝาก: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="branchid"
                  rules={[{ required: false }]}
                >
                  <Select
                    showSearch
                    size="middle"
                    placeholder="สาขาที่นำฝาก"
                    optionFilterProp="children"
                    filterOption={true}
                  >
                    {ryRefbranches.map((obj) => {
                      return (
                        <Option key={obj.id} value={obj.id}>
                          {obj.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เลขที่ฝาก: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="id"
                >
                  <Input
                    allowClear
                    placeholder="ป้อนเลขที่ฝาก"
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={10}></Col>
            </Row>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: 'center',
              paddingBottom: "1em",
              borderBlockEnd: "2px solid #c9c9c9",
            }}
          >
            <Space>
              <Button
                size="small"
                type="primary"
                icon={<SearchOutlined />}
                onClick={form.submit}
              >
                ค้นหา
              </Button>
              <Button
                size="small"
                type="primary"
                icon={<ClearOutlined />}
                onClick={onReset}
              >
                ล้าง
              </Button>
            </Space>
          </div>
        </Form>
        <div>
          <Table
            columns={columns}
            dataSource={tfinanceTrushViews}
            size="small"
            loading={loading}
            scroll={{ x: 1800 }}
            rowClassName={getItemColor}
            pagination={{
              pageSize: 10,
              onChange(current) {
                setPage(current);
              }
            }}
            rowKey={(item: TFinanceTrushView) => item.id!}
            onRow={(item: TFinanceTrushView) => {
              return {
                onDoubleClick: () => onItemCliked(item),
              };
            }}
          />
        </div>
        <div>
          {onDrawerVisible && (
            <TrushMoneyDrawer
              onDrawerVisible={onDrawerVisible}
              setOnDrawerVisible={setOnDrawerVisible}
            ></TrushMoneyDrawer>
          )}
        </div>
      </Content>
    </>
  )
}

export default TrushMoneyList