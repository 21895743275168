import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Form, Input, InputNumber, Modal, Select, Space, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { TServiceHeadView } from 'src/domain/master/view/tServiceHeadView';
import { TAssignvaluetaxhead } from 'src/domain/tAssignvaluetaxhead';
import { TRangradWangbillHead } from 'src/domain/tRangradWangbillHead';
import { TWshPromiss } from 'src/domain/tWshPromiss';
import { TWshtaxDetail } from 'src/domain/tWshtaxDetail';
import { TWshtaxDetailView } from "src/domain/view/tWshtaxDetailView";
import { TWorksheetDetail } from 'src/domain/worksheet/tWorksheetDetail';
import { TWorksheetHeadView } from 'src/domain/worksheet/view/tWorksheetHeadView';
import tAssignvaluetaxheadService from 'src/services/tAssignvaluetaxheadService';
import tRangradWangbillHeadService from 'src/services/tRangradWangbillHeadService';
import tServiceHeadService from 'src/services/tservice/tServiceHeadService';
import tWorksheetDetailService from 'src/services/tWorksheetDetailService';
import tWorksheetHeadService from 'src/services/tWorksheetHeadService';
import tWshPromissService from 'src/services/tWshPromissService';
import tWshtaxDetailService from 'src/services/tWshtaxDetailService';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber } from 'src/utils/numberUtils';

const { Option } = Select;
const { confirm } = Modal;

interface IProps {
  addTWshtaxDetailModalVisible: boolean;
  setAddTWshtaxDetailModalVisible: any;
  tWshtaxDetailView: TWshtaxDetailView;
  seTWshtaxDetailView: any;
  setTWshtaxDetail?: any;
}

const AddTWshtaxDetailModal: React.FC<IProps> = (props) => {
  const { addTWshtaxDetailModalVisible, setAddTWshtaxDetailModalVisible, tWshtaxDetailView, seTWshtaxDetailView, setTWshtaxDetail } = props;
  const [form] = Form.useForm();
  const [trigger, setTrigger] = useState<boolean>(false);
  const [isRecal, setIsRecal] = useState<boolean>(false);
  const [tAssignvaluetaxheads, setTAssignvaluetaxheads] = useState<TAssignvaluetaxhead[]>([]);
  const [selectedTRangradWangbillHead, setSelectedTRangradWangbillHead] = useState<TRangradWangbillHead>({} as TRangradWangbillHead);
  const [selectedTAssignvaluetaxhead, setSelectedTAssignvaluetaxhead] = useState<TAssignvaluetaxhead>({} as TAssignvaluetaxhead);
  const [selectedTWorksheetHeadView, setSelectedTWorksheetHeadView] = useState<TWorksheetHeadView>({} as TWorksheetHeadView);
  const [selectedTServiceHead, setSelectedTServiceHead] = useState<TServiceHeadView>();

  useEffect(() => {
    if (trigger) {
      form.validateFields()
        .then(() => {
          let strNumTax = "หักภาษี ณ ที่จ่าย " + selectedTAssignvaluetaxhead?.irate + " % เป็นเงิน " + form.getFieldValue('numTax') + " บาท"
          confirm({
            title: "ยืนยัน!",
            icon: <ExclamationCircleOutlined />,
            content: strNumTax + "\nคุณต้องการที่จะบันทึกข้อมูลหรือไม่?",
            cancelText: "ยกเลิก",
            okText: "ยืนยัน",
            onOk() {
              form.submit();
            },
            onCancel() {
              setTrigger(false);
            }
          });
        }).catch(() => {
          setTrigger(false);
        });
    }
  }, [trigger]);

  useEffect(() => {
    initial();
  }, []);

  useEffect(() => {
    calculatorChangePurchaseTax();
  }, [selectedTAssignvaluetaxhead]);

  useEffect(() => {
    if (isRecal) {
      if (selectedTWorksheetHeadView) {
        calculatorWorksheet();
      }
      if (selectedTRangradWangbillHead) {
        calculatorDebitCode();
      }
    }
    setIsRecal(false);
  }, [isRecal, selectedTWorksheetHeadView, selectedTRangradWangbillHead]);

  const initial = async () => {
    if (addTWshtaxDetailModalVisible) {
      const tAssignvaluetaxheadRes = await tAssignvaluetaxheadService.getTAssignvaluetaxheads();
      setTAssignvaluetaxheads(tAssignvaluetaxheadRes.data);

      form.setFieldsValue({
        tWshtaxDetailType: tWshtaxDetailView.worksheetcode ? 'worksheet' : 'debitNote',
        worksheetCode: tWshtaxDetailView.worksheetcode,
        customerName: "",
        promissNumber: "",
        numOfPromiss: 0,
        numVat: 0.00,
        numTaxPerInStallMent: 0.00,
        numTax: 0.00,
        numService: 0.00,
        numRemain: 0.00,
        numTotal: 0.00,
        remark: ""
      });
      if (tWshtaxDetailView.worksheetcode) {
        handleEnterWorksheetCode(tWshtaxDetailView.worksheetcode);
      }
    }
  };

  const handleChangePurchaseTax = (purchaseTax: any) => {
    const tAssignvaluetaxheadArr = tAssignvaluetaxheads as Array<TAssignvaluetaxhead>;
    const findTAssignvaluetaxhead = tAssignvaluetaxheadArr.find((ob) => ob.iid === purchaseTax);
    setSelectedTAssignvaluetaxhead(findTAssignvaluetaxhead!);
  }

  const handleEnterWorksheetCode = async (worksheetCode: string) => {
    const tWshtaxDetailType = form.getFieldValue('tWshtaxDetailType');
    if (tWshtaxDetailType === 'worksheet') {
      const isExistWorkSheetCode = await tWorksheetHeadService.isExistWorkSheetCode(worksheetCode);
      if (!isExistWorkSheetCode.data) return;
      await loadDataWorksheetCode();
      //if (await checkData()) return;
      // calculatorWorksheet();
    } else {
      const isExitDebitNoteNo = await tRangradWangbillHeadService.isExitByDebitNoteNo(worksheetCode);
      if (!isExitDebitNoteNo.data) return;
      await loadDeBitNoteCode();
      //if (await checkData()) return;
      // calculatorDebitCode();
    }
  }

  const calculatorChangePurchaseTax = () => {
    const tWshtaxDetailType = form.getFieldValue('tWshtaxDetailType');
    if (tWshtaxDetailType === 'worksheet') {
      calculatorWorksheet();
    } else {
      calculatorDebitCode();
    }

  }

  const loadDataWorksheetCode = async () => {
    const worksheetCode = form.getFieldValue('worksheetCode');
    const tWorksheetHeadRes = await tWorksheetHeadService.getByWorksheetCodeView(worksheetCode);
    const tWorksheetHead = tWorksheetHeadRes.data as TWorksheetHeadView;
    setSelectedTWorksheetHeadView(tWorksheetHead);
    form.setFieldsValue({
      customerName: tWorksheetHead.icustName!.trim(),
    });

    const tWorksheetDetailRes = await tWorksheetDetailService.getByReserveCode(worksheetCode);
    if (tWorksheetDetailRes.data[0]) {
      const tWorksheetDetail = tWorksheetDetailRes.data[0] as TWorksheetDetail
      const tServiceHeadViewRes = await tServiceHeadService.getTServiceHeadByCode(tWorksheetDetail.iserviceCode!);
      setSelectedTServiceHead(tServiceHeadViewRes.data)
    }

    const tWshPromissRes = await tWshPromissService.getByReserveCodeAndWorksheetCode(tWorksheetHead.ireserveCode!, tWorksheetHead.iworksheetCode!);
    const tWshPromiss = tWshPromissRes.data as TWshPromiss;
    if (tWshPromiss.seq) {
      form.setFieldsValue({
        promissNumber: tWshPromiss.promissnumber,
        numOfPromiss: tWshPromiss.instalment?.toString(),
      });
    } else {
      form.setFieldsValue({
        promissNumber: "",
        numOfPromiss: 1,
      });
    }
    if (await checkData(tWorksheetHead)) return;
    setIsRecal(true);
  }
  const calculatorWorksheet = () => {
    let vatamt = 0.00;
    let taxamt = 0.00;
    let total = 0.00;
    let remain = 0.00;
    let payAmt = 0.0;
    let serviceamt = 0.00;
    if (selectedTWorksheetHeadView?.typeworksheetflag !== "02" && selectedTWorksheetHeadView?.typeworksheetflag !== "04"
      && selectedTWorksheetHeadView?.typeworksheetflag !== "05") {
      if (selectedTWorksheetHeadView?.truckAssettype === '002' || selectedTWorksheetHeadView?.truckAssettype === '003') {
        payAmt = (selectedTWorksheetHeadView.itypepaymentCode === "02") ? (selectedTWorksheetHeadView.iremainAmt ? selectedTWorksheetHeadView.iremainAmt : 0.00) : (selectedTWorksheetHeadView.idepositAmt ? selectedTWorksheetHeadView.idepositAmt : 0.00);
        serviceamt = Math.round(((payAmt * 100) / 107) * 100) / 100;
        total = payAmt;
        vatamt = total - serviceamt;

        if (selectedTAssignvaluetaxhead?.iid) {
          taxamt = Math.round((serviceamt * (selectedTAssignvaluetaxhead.irate / 100)) * 100) / 100;
        }
        remain = total - taxamt;
        setSelectedTWorksheetHeadView({ ...selectedTWorksheetHeadView, purchasetax: taxamt });
        form.setFieldsValue({
          numService: formatNumber(serviceamt),
          numVat: formatNumber(vatamt),
          numTotal: formatNumber(total),
          numTax: formatNumber(taxamt),
          numRemain: formatNumber(remain),
          numTaxPerInStallMent: formatNumber(taxamt)
        });
        return;
      }
    }
    serviceamt = ((selectedTWorksheetHeadView?.iserviceAmt ? selectedTWorksheetHeadView?.iserviceAmt : 0.00) + (selectedTWorksheetHeadView?.iextraAmt ? selectedTWorksheetHeadView?.iextraAmt : 0.00) + (selectedTWorksheetHeadView?.iotAmt ? selectedTWorksheetHeadView?.iotAmt : 0.00)) - (selectedTWorksheetHeadView?.idiscount ? selectedTWorksheetHeadView?.idiscount : 0.00);

    if (selectedTWorksheetHeadView?.itypetaxCode === "02") {
      vatamt = Math.round((serviceamt * 7 / 107) * 100) / 100;
      serviceamt = serviceamt - vatamt;
    } else if (selectedTWorksheetHeadView?.itypetaxCode === "01") {
      vatamt = Math.round((serviceamt * 7 / 100) * 100) / 100;
    }

    total = serviceamt + vatamt;
    if (selectedTAssignvaluetaxhead?.iid) {
      taxamt = Math.round((serviceamt * (selectedTAssignvaluetaxhead.irate / 100)) * 100) / 100;
      let numtax = 0.0;
      const numOfPromiss = (form.getFieldValue('numOfPromiss') ? parserNumber(form.getFieldValue('numOfPromiss')) : 0)
      if (numOfPromiss > 1) {//Check Num Of Instalment
        if (selectedTWorksheetHeadView?.typeworksheetflag !== "02" && selectedTWorksheetHeadView?.typeworksheetflag !== "04"
          && selectedTWorksheetHeadView?.typeworksheetflag !== "05") {
          if (selectedTWorksheetHeadView?.itypepaymentCode === '03' || selectedTWorksheetHeadView?.truckAssettype === '002') {
            numtax = Math.round((((selectedTServiceHead?.iunitPrice ? selectedTServiceHead?.iunitPrice : 0.00) + (selectedTWorksheetHeadView?.iextraAmt ? selectedTWorksheetHeadView?.iextraAmt : 0.00) + (selectedTWorksheetHeadView?.iotAmt ? selectedTWorksheetHeadView?.iotAmt : 0.00)) * (selectedTAssignvaluetaxhead.irate / 100)) * 100) / 100;
          } else {
            numtax = taxamt;
          }
        }
        form.setFieldsValue({
          numTaxPerInStallMent: formatNumber(numtax),
        });
      }
      remain = total - taxamt;
      form.setFieldsValue({
        numService: formatNumber(serviceamt),
        numVat: formatNumber(vatamt),
        numTotal: formatNumber(total),
        numTax: formatNumber(taxamt),
        numRemain: formatNumber(remain),
        numTaxPerInStallMent: formatNumber(taxamt)
      });
    }
  }

  const loadDeBitNoteCode = async () => {
    const worksheetCode = form.getFieldValue('worksheetCode');
    // const tRangradWangbillHeadRes = await tRangradWangbillHeadService.getByDebitorCode(worksheetCode);
    const tRangradWangbillHeadRes = await tRangradWangbillHeadService.getByDebitNoteNo(worksheetCode);
    const tRangradWangbillHead = tRangradWangbillHeadRes.data as TRangradWangbillHead;
    setSelectedTRangradWangbillHead(tRangradWangbillHead);

    form.setFieldsValue({
      customerName: tRangradWangbillHead.debitorname ? tRangradWangbillHead.debitorname.trim() : "",
      numOfPromiss: 1,
      promissNumber: ""
    });

    if (await checkData(tRangradWangbillHead)) return;
    setIsRecal(true);
  }

  const calculatorDebitCode = () => {
    let vatamt = 0.00;
    let taxamt = 0.00;
    let total = 0.00;
    let remain = 0.00;
    let serviceamt = 0.00;
    serviceamt = (selectedTRangradWangbillHead?.nettotal ? selectedTRangradWangbillHead?.nettotal : 0.00) - (selectedTRangradWangbillHead?.vat ? selectedTRangradWangbillHead?.vat : 0.00);
    vatamt = (selectedTRangradWangbillHead?.vat ? selectedTRangradWangbillHead?.vat : 0.00);
    total = (selectedTRangradWangbillHead?.nettotal ? selectedTRangradWangbillHead?.nettotal : 0.00);

    if (selectedTAssignvaluetaxhead?.iid) {
      taxamt = Math.round((serviceamt * (selectedTAssignvaluetaxhead.irate / 100)) * 100) / 100;
    }
    remain = total - taxamt;

    // form.setFieldsValue({
    //   numService: formatNumber(serviceamt),
    //   numVat: formatNumber(vatamt),
    //   numTotal: formatNumber(total),
    //   numTax: formatNumber(remain),
    //   numTaxPerInStallMent: formatNumber(remain),
    //   numRemain: formatNumber(serviceamt),
    // });
    form.setFieldsValue({
      numService: formatNumber(serviceamt),
      numVat: formatNumber(vatamt),
      numTotal: formatNumber(total),
      numTax: formatNumber(taxamt),
      // numTaxPerInStallMent: formatNumber(taxamt),
      numRemain: formatNumber(remain),
    });
  }

  const checkData = async (checkData: any) => {
    const tWshtaxDetailType = form.getFieldValue('tWshtaxDetailType');
    const worksheetCode = form.getFieldValue('worksheetCode');
    if (tWshtaxDetailType === 'worksheet') {
      if (checkData?.itypepaymentCode === '04') {
        showNotification(NotificationType.ERROR, "ใบงานเชื่อไม่สามารถหักภาษี ณ ที่จ่ายได้!");
        return true;
      }
      if (checkData?.ipaymentStatus === '01') {
        showNotification(NotificationType.ERROR, "ใบงานนี้รับชำระเงินเรียบร้อย!");
        return true;
      }
      const result = await tWshtaxDetailService.isExistByWorksheetCode(worksheetCode);
      if (result.data) {
        showNotification(NotificationType.ERROR, "มีการบันทึกข้อมูลอยู่แล้ว!");
        return true;
      } else {
        if (checkData?.typeworksheetflag !== "01" && checkData?.itypeworksheetCode !== "02") {
          return false;
        } else {
          showNotification(NotificationType.ERROR, "ประเภทใบงานไม่ถูกต้อง!");
          return true;
        }
      }
    } else {
      if (checkData?.idebitnotestatus !== '02') {
        showNotification(NotificationType.ERROR, "สถานะต้องเป็นส่งการเงินเท่านั้น!");
        return true;
      }
      const result = await tWshtaxDetailService.isExistByDebitNoteCode(worksheetCode)
      if (result.data) {
        showNotification(NotificationType.ERROR, "มีการบันทึกข้อมูลอยู่แล้ว!");
        return true;
      } else {
        if (checkData.typeDebitnote !== '02') {
          return false;
        } else {
          showNotification(NotificationType.ERROR, "ประเภทใบแจ้งหนี้ไม่ถูกต้อง!");
          return true;
        }
      }
    }
  }

  const onFinish = async (values: any) => {
    let _tWshtaxDetail: TWshtaxDetail = {
      ...tWshtaxDetailView,
      statustax: '00',
      taxAssureDocumentid: '',
      taxpercent: selectedTAssignvaluetaxhead.irate,
      taxtype: selectedTAssignvaluetaxhead.iid
    };

    const tWshtaxDetailType = form.getFieldValue('tWshtaxDetailType');
    if (tWshtaxDetailType === 'worksheet') {
      _tWshtaxDetail.customercode = selectedTWorksheetHeadView.icustCode!;
      _tWshtaxDetail.worksheetcode = selectedTWorksheetHeadView.iworksheetCode!;
      _tWshtaxDetail.reservecode = selectedTWorksheetHeadView.ireserveCode!;
      _tWshtaxDetail.taxamount = parserNumber(values.numTax);
      _tWshtaxDetail.debitnotecode = '';
      _tWshtaxDetail.debitorcode = '';
      _tWshtaxDetail.debitnoteamt = 0.00;
      _tWshtaxDetail.worksheetamt = parserNumber(values.numTotal)
    } else {
      _tWshtaxDetail.customercode = '';
      _tWshtaxDetail.worksheetcode = '';
      _tWshtaxDetail.reservecode = '';
      _tWshtaxDetail.taxamount = parserNumber(values.numTax);
      _tWshtaxDetail.debitnotecode = selectedTRangradWangbillHead.idebitnoteNo!;      
      _tWshtaxDetail.debitorcode = selectedTRangradWangbillHead.idebitorCode;
      _tWshtaxDetail.debitnoteamt = parserNumber(values.numTotal);
      _tWshtaxDetail.worksheetamt = 0.00;
    }

    try {
      await tWshtaxDetailService.saveTWshtaxDetail(_tWshtaxDetail, values.remark);
      // callback
      const temp: TWshtaxDetailView = {
        ..._tWshtaxDetail,
        worksheetcode: values.worksheetCode,
        remianAmountStr: values.numRemain
      }
      seTWshtaxDetailView(temp);
      if (setTWshtaxDetail) {
        setTWshtaxDetail(_tWshtaxDetail);
      }
      setTrigger(false);
      setAddTWshtaxDetailModalVisible(false);
    } catch (err) {
      showNotification(NotificationType.ERROR, "เกิดข้อผิดพลาด กรุณาติดต่อผู้แลระบบ !");
      setTrigger(false);
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "เกิดข้อผิดพลาด กรุณาติดต่อผู้แลระบบ !");
    setTrigger(false);
  };

  return (
    <div>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>บันทึกการหักภาษี ณ ที่จ่าย</Typography.Title>
          </div>
        }
        visible={addTWshtaxDetailModalVisible}
        width={"50%"}
        style={{ paddingTop: "5px" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setAddTWshtaxDetailModalVisible(false)}
      >
        <Form
          form={form}
          name="assignCheque"
          initialValues={{ remember: true }}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="ลำดับที่"
            name="index"
          >
            <Input
              type="text"
              style={{ width: 300, fontWeight: "bold", color: "#FD1212", backgroundColor: "#F7F7F8" }}
              disabled
            >
            </Input>
          </Form.Item>
          <Form.Item
            label="เลขที่"
            style={{ marginBottom: "0px" }}
          >
            <Space align="baseline">
              <Form.Item
                name="tWshtaxDetailType"
                rules={[{ required: true, message: "กรุณาเลือกเลขที่!" }]}
              >
                <Select
                  size="middle"
                  placeholder="ใบสั่งงาน / ใบแจ้งหนี้"
                  optionFilterProp="children"
                  filterOption={true}
                >
                  <Option key='worksheet' value='worksheet'>ใบสั่งงาน</Option>
                  <Option key='debitNote' value='debitNote'>ใบแจ้งหนี้</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="worksheetCode"
                rules={[
                  {
                    validator: (_, value) => {
                      const tWshtaxDetailType = form.getFieldValue('tWshtaxDetailType');
                      if (tWshtaxDetailType) {
                        if (tWshtaxDetailType === 'worksheet') {
                          if (!value) return Promise.reject(new Error('กรุณาป้อนข้อมูลรหัสใบสั่งงานให้ถูกต้อง!'));
                          if (value && (value === " " || value === "-")) {
                            return Promise.reject(new Error('กรุณาป้อนข้อมูลรหัสใบสั่งงานให้ถูกต้อง!'))
                          } else {
                            return Promise.resolve()
                          }
                        } else {
                          if (!value) return Promise.reject(new Error('กรุณาป้อนข้อมูลรหัสใบแจ้งหนี้ให้ถูกต้อง!'));
                          if (value && (value === " " || value === "-")) {
                            return Promise.reject(new Error('กรุณาป้อนข้อมูลรหัสใบแจ้งหนี้ให้ถูกต้อง!'))
                          } else {
                            return Promise.resolve()
                          }
                        }
                      }
                    },
                  },]}
              >
                <Input
                  type="text"
                  onPressEnter={(event: any) => handleEnterWorksheetCode(event.target.value)}
                >
                </Input>
              </Form.Item>
            </Space>
          </Form.Item>

          <Form.Item
            label="ลูกค้า"
            name="customerName"
          >
            <Input
              type="text"
              style={{ fontWeight: "bold", color: "#FD1212", backgroundColor: "#F7F7F8" }}
              readOnly
            >
            </Input>
          </Form.Item>
          <Form.Item
            label="หักภาษี ณ ที่จ่าย"
            name="purchaseTax"
            rules={[{ required: true, message: "กรุณาระบุหักภาษี ณ ที่จ่าย!" }]}
          >
            <Select
              showSearch
              size="middle"
              placeholder="เลือกหักภาษี ณ ที่จ่าย"
              optionFilterProp="children"
              filterOption={true}
              onSelect={handleChangePurchaseTax}
            >
              {tAssignvaluetaxheads.map((obj: TAssignvaluetaxhead) => {
                return (
                  <Option key={obj.iid} value={obj.iid}>
                    {obj.iname} : {obj.irate}%<br />
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="เลขที่สัญญา"
            name="promissNumber"
          >
            <Input
              type="text"
              style={{ width: 300, fontWeight: "bold", color: "#FD1212", backgroundColor: "#F7F7F8" }}
              readOnly
            >
            </Input>
          </Form.Item>
          <Form.Item
            label="จำนวนงวด"
            name="numOfPromiss"
          >
            <InputNumber
              style={{ textAlign: "right", width: 300, fontWeight: "bold", color: "#FD1212", backgroundColor: "#F7F7F8" }}
              controls={false}
              readOnly
            >
            </InputNumber>
          </Form.Item>
          <Form.Item
            label="ค่าบริการก่อนภาษี"
            name="numService"
          >
            <InputNumber
              style={{ textAlign: "right", width: 300, fontWeight: "bold", color: "#FD1212", backgroundColor: "#F7F7F8" }}
              controls={false}
              precision={2}
              readOnly
            >
            </InputNumber>
          </Form.Item>
          <Form.Item
            label="ภาษี 7%"
            name="numVat"
          >
            <InputNumber
              style={{ textAlign: "right", width: 300, fontWeight: "bold", color: "#FD1212", backgroundColor: "#F7F7F8" }}
              controls={false}
              precision={2}
              readOnly
            >
            </InputNumber>
          </Form.Item>
          <Form.Item
            label="รวมเงิน"
            name="numTotal"
          >
            <InputNumber
              style={{ textAlign: "right", width: 300, fontWeight: "bold", color: "#FD1212", backgroundColor: "#F7F7F8" }}
              controls={false}
              precision={2}
              readOnly
            >
            </InputNumber>
          </Form.Item>
          <Form.Item
            label="จำนวนเงิน หัก ณ ที่จ่าย"
            name="numTax"
          >
            <InputNumber
              style={{ textAlign: "right", width: 300, fontWeight: "bold", color: "#FD1212", backgroundColor: "#F7F7F8" }}
              controls={false}
              precision={2}
              readOnly
            >
            </InputNumber>
          </Form.Item>
          <Form.Item
            label="จำนวนที่หักต่อครั้ง"
            name="numTaxPerInStallMent"
          >
            <InputNumber
              style={{ textAlign: "right", width: 300, fontWeight: "bold", color: "#FD1212", backgroundColor: "#F7F7F8" }}
              controls={false}
              precision={2}
              readOnly
            >
            </InputNumber>
          </Form.Item>
          <Form.Item
            label="คงเหลือ"
            name="numRemain"
          >
            <InputNumber
              style={{ textAlign: "right", width: 300, fontWeight: "bold", color: "#FD1212", backgroundColor: "#F7F7F8" }}
              controls={false}
              precision={2}
              readOnly
            >
            </InputNumber>
          </Form.Item>
          <Form.Item
            label="หมายเหตุ"
            name="remark"
            rules={[{ required: true, message: "กรุณาระบุหมายเหตุ!" }]}
          >
            <TextArea
              rows={2}
            ></TextArea>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default AddTWshtaxDetailModal