import http from "../lib/http";
import Constants from "../utils/constants";

const getByReserveControlCode = (reserveCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWshConstructionwork/getByReserveControlCode/" + reserveCode);
};

const defaultApi = {
  getByReserveControlCode,
};
export default defaultApi;