import http from "../../lib/http";
import Constants from "../../utils/constants";

const getTTypeemployeeRefList = () => {
  return http.get(
    Constants.DEFAULT_URL + "/tTypeemployeeRef"
  );
};

const getTTypeemployeeRef = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/tTypeemployeeRef/" + id);
}


const getWorksheetWorkerRefList = () => {
  return http.get(Constants.DEFAULT_URL + "/getWorksheetWorkerRefList");
}

const defaultApi = {
  getTTypeemployeeRef,
  getTTypeemployeeRefList,
  getWorksheetWorkerRefList,
};
export default defaultApi;
