import { PlusSquareOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Select, Space, Table, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { TAssetHeadView } from 'src/domain/master/view/tAssetHeadView';
import { TEmployee2View } from 'src/domain/master/view/tEmployee2View';
import { TUserHead } from 'src/domain/tUserHead';
import { TWorksheetDetail } from 'src/domain/worksheet/tWorksheetDetail';
import { TWorksheetHead } from 'src/domain/worksheet/tWorksheetHead';
import { WorksheetHeadData } from 'src/domain/worksheet/view/worksheetHeadData';
import employeeService from 'src/services/employee/tEmployee2Service';
import tAssetHeadService from 'src/services/tasset/tAssetHeadService';
import worksheetService from 'src/services/worksheetService';
import { useAppSelector } from 'src/stores/store';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber } from 'src/utils/numberUtils';
import AddEditWorksheetDetailModal from './AddEditWorksheetDetailModal';

const { Option } = Select;

interface IProps {
  editWorksheetDetailListModalVisible: boolean;
  setEditWorksheetDetailListModalVisible: any;
  // setUpdateStatus: any;
  worksheetHeadData: WorksheetHeadData;
  setWorksheetHeadData: any;
  tUserHeadUpdate: TUserHead;
}

const EditWorksheetDetailListModal: React.FC<IProps> = (props) => {
  const { editWorksheetDetailListModalVisible, setEditWorksheetDetailListModalVisible, worksheetHeadData, setWorksheetHeadData, tUserHeadUpdate } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [worksheetHeadDataTemp, setWorksheetHeadDataTemp] = useState<WorksheetHeadData>({} as WorksheetHeadData);
  const [worksheetDetailList, setWorksheetDetailList] = useState<TWorksheetDetail[]>();
  const [employees, setEmployees] = useState<TEmployee2View[]>([]);//ผู้แจ้ง
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  //FOR ADDEDITWORKSHEETDETAILMODAL
  const [addEditWorksheetDetailModalVisible, setAddEditWorksheetDetailModalVisible] = useState(false);
  const [assetSelected, setAssetSelected] = useState<TAssetHeadView>({} as TAssetHeadView);
  const [wshDetailData, setWshDetailData] = useState<TWorksheetDetail>({} as TWorksheetDetail);
  const [action, setAction] = useState<string>();
  const [selectIndex, setSelectIndex] = useState<any>();

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      key: "index",
      align: "center" as "center",
      render: (value: any, item: TWorksheetDetail, index: number) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: "รายการ",
      dataIndex: "iserviceName",
      key: "iserviceName",
      render: (value: any, item: TWorksheetDetail, index: number) => {
        return (
          <span>
            <a onClick={() => {
              setAction("EDIT");
              setSelectIndex(index);
              setWshDetailData(item);
              setAddEditWorksheetDetailModalVisible(true);
            }}>
              {item.iserviceName}
            </a>
          </span>
        );
      },
    },
    {
      title: "ราคา/หน่วย",
      dataIndex: "iunitPrice",
      key: "iunitPrice",
      align: "right" as "right",
      render: (value: any, item: TWorksheetDetail, index: number) => {
        return <span>{formatNumber(item.iunitPrice)}</span>;
      },
    },
    {
      title: "จำนวน",
      dataIndex: "iqty",
      key: "iqty",
      align: "right" as "right",
    },
    {
      title: "หน่วย",
      dataIndex: "itypeunitName",
      key: "itypeunitName",
    },
    {
      title: "ส่วนลด",
      dataIndex: "discount",
      key: "discount",
      align: "right" as "right",
      render: (value: any, item: TWorksheetDetail, index: number) => {
        return <span>{formatNumber(item.discount)}</span>;
      },
    },
    {
      title: "รวม",
      dataIndex: "iamount",
      key: "iamount",
      align: "right" as "right",
      render: (value: any, item: TWorksheetDetail, index: number) => {
        return <span>{formatNumber(item.iamount)}</span>;
      },
    },
    {
      title: "",
      key: "action",
      align: "center" as "center",
      render: (value: any, item: TWorksheetDetail, index: number) => {
        return (
          <span>
            <a onClick={(e) => { onDelete(item, index); }} style={{ color: "red" }}
            >
              X
            </a>
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  useEffect(() => {
    loadData();
    return () => { };
  }, []);

  useEffect(() => {
    if (wshDetailData.iserviceCode) {
      if (action === "ADD") {
        const array = worksheetDetailList ? [...worksheetDetailList] : [];
        array?.push(wshDetailData);
        setWorksheetDetailList([...reindex(array)]);
      } else {
        const array = worksheetDetailList ? [...worksheetDetailList] : [];
        array?.splice(selectIndex, 1, wshDetailData);
        setWorksheetDetailList([...reindex(array)]);
      }
    }
  }, [wshDetailData]);

  useEffect(() => {
    const extraAmt = (!worksheetHeadDataTemp.tworksheetHead?.iextraAmt) ? 0 : worksheetHeadDataTemp.tworksheetHead?.iextraAmt;
    const otAmt = (!worksheetHeadDataTemp.tworksheetHead?.iotAmt) ? 0 : worksheetHeadDataTemp.tworksheetHead?.iotAmt;
    let sumServiceAmount = 0;
    let sumServiceDiscount = 0;
    let sumServiceLastAmt = 0;
    if (worksheetDetailList && worksheetDetailList.length > 0) {
      worksheetDetailList.forEach((obj) => {
        sumServiceAmount += obj?.iqty * (obj?.iunitPrice ? obj.iunitPrice : 0);
        sumServiceDiscount += (obj?.discount ? obj.discount : 0);
      });
      sumServiceLastAmt = sumServiceAmount - sumServiceDiscount;
    }
    //round at most 2 decimal places ->> Math.round(num * 100) / 100
    let amountBefTax = (sumServiceAmount + extraAmt + otAmt) - sumServiceDiscount;
    let vatAmount = calVatAmt(amountBefTax);
    if (worksheetHeadData.tworksheetHead.itypetaxCode === "02") {
      amountBefTax = amountBefTax - vatAmount;
    }

    const temp = {
      ...(worksheetHeadData as WorksheetHeadData),
      tworksheetHead: {
        ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
        iserviceAmt: sumServiceAmount ? sumServiceAmount : 0,
        idiscount: sumServiceDiscount ? sumServiceDiscount : 0,
        itotal1Amt: amountBefTax ? amountBefTax : 0,
        itaxAmt: vatAmount ? vatAmount : 0,
        itotal2Amt: amountBefTax + vatAmount,
        iremainAmt: amountBefTax + vatAmount,
      },
      sumServiceLastAmt: sumServiceLastAmt,
      netServiceAmt: sumServiceAmount + extraAmt - sumServiceDiscount,
    };
    setWorksheetHeadDataTemp(temp);
  }, [worksheetDetailList]);

  const calVatAmt = (serviceAmt: number) => {
    let vatAmt = 0;
    switch (worksheetHeadData.tworksheetHead.itypetaxCode) {
      case "01":
        vatAmt = Math.round(((serviceAmt * 7) / 100) * 100) / 100;
        break;
      case "02":
        vatAmt = Math.round(((serviceAmt * 7) / 107) * 100) / 100;
        break;
      case "03":
        break;
    }
    return vatAmt;
  }

  const reindex = (tWorksheetDetail: TWorksheetDetail[]) => {
    return tWorksheetDetail.map((item, index) => {
      item.iid = index;
      return item;
    });
  }

  const loadData = async () => {
    const truckCode = worksheetHeadData.tworksheetHead.itruckCode as string;
    const assetResult = await tAssetHeadService.getTAssetHeadViewById(truckCode);

    setWorksheetHeadDataTemp(worksheetHeadData);
    setWorksheetDetailList(worksheetHeadData.tworksheetDetailList);
    setAssetSelected(assetResult.data);
  };

  const handleSearchEmployee = async (data: any) => {
    if (data.length < 3) return;
    const res = await employeeService.getEmployee2ByParam(data);
    setEmployees(res.data);
  };

  const onDelete = (item: TWorksheetDetail, index: number) => {
    const array = worksheetDetailList ? [...worksheetDetailList] : [];
    array?.splice(index, 1);
    setWorksheetDetailList(array);
  }

  const handleAdd = () => {
    setAction("ADD");
    setSelectIndex(null);
    setWshDetailData({} as TWorksheetDetail)
    setAddEditWorksheetDetailModalVisible(true);
  }

  const onFinish = async (values: any) => {
    if (worksheetHeadDataTemp.tworksheetHead.iserviceAmt! <= 0) {
      showNotification(NotificationType.WARNING, "รายการบริการไม่ถูกต้อง");
      setTrigger(false);
    } else if (worksheetHeadData.tworksheetHead.ipaymentStatus === "01" && tUserHeadUpdate.ipermissionCode != "999") {
      if (worksheetHeadDataTemp.tworksheetHead.iserviceAmt! !== worksheetHeadData.tworksheetHead.iserviceAmt) {
        showNotification(NotificationType.WARNING, "ไม่สามารถบันทึกได้ เนื่องจากการเงินรับชำระใบงานนี้แล้ว แต่ค่าบริการถูกแก้ไขไม่เท่ากับที่การเงินรับชำระ");
        setTrigger(false);
      }
    } else {
      const employeeArr = employees as Array<TEmployee2View>;
      const empSelected = employeeArr.find((obj) => obj.id === values.userInfoId);
      try {
        const temp = {
          ...(worksheetHeadData as WorksheetHeadData),
          tworksheetHead: {
            ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
            iserviceAmt: worksheetHeadDataTemp.tworksheetHead.iserviceAmt,
            idiscount: worksheetHeadDataTemp.tworksheetHead.idiscount,
            itotal1Amt: worksheetHeadDataTemp.tworksheetHead.itotal1Amt,
            itaxAmt: worksheetHeadDataTemp.tworksheetHead.itaxAmt,
            itotal2Amt: worksheetHeadDataTemp.tworksheetHead.itotal2Amt,
            iremainAmt: worksheetHeadDataTemp.tworksheetHead.iremainAmt,
            ilastUser: tUserHeadUpdate.iuserId,
            itermNo: userInfo.termNo,
            isavStation: userInfo.saveStation,
          },
          sumServiceLastAmt: worksheetHeadDataTemp.sumServiceLastAmt,
          netServiceAmt: worksheetHeadDataTemp.netServiceAmt,
          tworksheetDetailList: worksheetDetailList,
          updateUser: tUserHeadUpdate,
        };
        setWorksheetHeadData(temp);

        await worksheetService.updateWorksheetWithSubMenuKey(temp, values.userRemark, empSelected ? empSelected.empname : "", "MENU_WORKSHEET_DETAIL");
        showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเรียบร้อยแล้ว!");
        setEditWorksheetDetailListModalVisible(false);
        // setUpdateStatus(true);
        form.resetFields();
        setTrigger(false);
      } catch (err) {
        showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        setTrigger(false);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
    showNotification(NotificationType.ERROR, "กรุณาตรวจสอบข้อมูลให้ครบถ้วน!");
    setTrigger(false);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>แก้ไขรายการบริการ</Typography.Title>
          </div>
        }
        visible={editWorksheetDetailListModalVisible}
        width={"50%"}
        style={{ minHeight: "70%" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setEditWorksheetDetailListModalVisible(false)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
        >
          <Space align="baseline" direction="horizontal">
            <Button
              size="small"
              type="primary"
              icon={<PlusSquareOutlined />}
              onClick={() => handleAdd()}
            >
              เพิ่ม
            </Button>
          </Space>
          <Typography.Title
            type="danger"
            level={4}
            style={{ height: "22px", textAlign: "right" }}
          >
            จำนวนเงิน :{" "}
            {formatNumber(
              Number(
                !worksheetHeadDataTemp?.sumServiceLastAmt
                  ? 0
                  : worksheetHeadDataTemp?.sumServiceLastAmt
              ).toFixed(2)
            )}
          </Typography.Title>
        </div>
        <div style={{ marginBottom: 20, minHeight: 80 }}>
          <Table
            columns={columns}
            // rowSelection={rowSelection}
            dataSource={worksheetDetailList}
            size="small"
            pagination={false}
            rowKey={(item: TWorksheetDetail) => item.iid}
          ></Table>
        </div>

        <Form
          form={form}
          name="worksheetDetail"
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="ผู้แจ้ง"
            name="userInfoId"
            rules={[{ required: true, message: "กรุณาระบุ ผู้แจ้ง!" }]}
          >
            <Select
              showSearch
              allowClear
              size="middle"
              placeholder="เลือกผู้แจ้ง"
              optionFilterProp="children"
              filterOption={true}
              onSearch={handleSearchEmployee}
            >
              {employees.map((obj) => {
                return (
                  <Option key={obj.id} value={obj.id}>
                    {obj.empname}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="หมายเหตุ"
            name="userRemark"
            validateTrigger="onBlur"
            rules={[{ required: true, message: "กรุณาระบุเหตุผล !" },
            {
              validator: (_, value) => {
                if (value && value.length <= 10) {
                  return Promise.reject(showNotification(NotificationType.ERROR, "กรุณาระบุเหตุผล ให้มากกว่า 10 ตัวอักษร"))
                } else {
                  return Promise.resolve()
                }
              },
            },
            ]}
          >
            <TextArea
              rows={4}
            ></TextArea>
          </Form.Item>
        </Form>
      </Modal>
      <div>
        {addEditWorksheetDetailModalVisible && (
          <AddEditWorksheetDetailModal
            addEditWorksheetDetailModalVisible={addEditWorksheetDetailModalVisible}
            setAddEditWorksheetDetailModalVisible={setAddEditWorksheetDetailModalVisible}
            assetSelected={assetSelected}
            worksheetHeadData={worksheetHeadData}
            wshDetailData={wshDetailData}
            setWshDetailData={setWshDetailData}
          ></AddEditWorksheetDetailModal>
        )}
      </div>
    </>
  )
}

export default EditWorksheetDetailListModal