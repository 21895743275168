import { CloseCircleOutlined, DeleteOutlined, ExclamationCircleOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Drawer, Form, Input, InputNumber, Modal, Radio, Row, Space, Spin, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TDebitorHeadView } from 'src/domain/finance/view/tDebitorHeadView';
import { TServiceHeadView } from 'src/domain/master/view/tServiceHeadView';
import { TWorksheetDetail } from 'src/domain/worksheet/tWorksheetDetail';
import { TRangradWangbillHeadData } from 'src/domain/view/tRangradWangbillHeadData';
import { TWorksheetHeadView } from 'src/domain/worksheet/view/tWorksheetHeadView';
import tRangradWangbillHeadService from 'src/services/tRangradWangbillHeadService';
import tServiceHeadService from 'src/services/tservice/tServiceHeadService';
import tWorksheetDetailService from 'src/services/tWorksheetDetailService';
import tWorksheetHeadService from 'src/services/tWorksheetHeadService';
import { useAppSelector } from 'src/stores/store';
import Constants from 'src/utils/constants';
import { toThDate } from 'src/utils/dateTimeUtils';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, formatNumberInt, parserNumber } from 'src/utils/numberUtils';
import LookupTDebitorHeadModal from '../modals/lookups/LookupTDebitorHeadModal';
import DebitorWorksheetWaitWangbillListModal from './modal/DebitorWorksheetWaitWangbillListModal';

const { confirm } = Modal;

interface IProps {
  onDrawerVisible: boolean;
  setOnDrawerVisible: any;
}

const TRengRadWangBillDrawer: React.FC<IProps> = (props) => {
  const { onDrawerVisible, setOnDrawerVisible } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [lookupDebitorModalVisible, setLookupDebitorModalVisible] = useState<boolean>(false);
  const [debitorWorksheetModalVisible, setDebitorWorksheetModalVisible] = useState<boolean>(false);
  const [tDebitorHeadSelected, setTDebitorHeadSelected] = useState<TDebitorHeadView>({} as TDebitorHeadView);
  const [worksheetHeadSelecteds, setWorksheetHeadSelecteds] = useState<TWorksheetHeadView[]>([]);
  const [worksheetHeadShows, setWorksheetHeadShows] = useState<TWorksheetHeadView[]>([]);
  const [worksheetHeadAlls, setWorksheetHeadAlls] = useState<TWorksheetHeadView[]>([]);//ใบงานทั้งหมด
  const [worksheetHeadIns, setWorksheetHeadIns] = useState<TWorksheetHeadView[]>([]);//ใบงานใน
  const [worksheetHeadOuts, setWorksheetHeadOuts] = useState<TWorksheetHeadView[]>([]);//ใบงานนอก

  const getItemColor = (item: TWorksheetHeadView) => {
    let foreColor = "";
    if (item.itypeworksheetCode === "01") {
      foreColor = "red";
    } else {
      foreColor = "blue";
    }
    return 'text-' + foreColor;
  }

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: TWorksheetHeadView, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่ใบสั่งงาน",
      dataIndex: "iworksheetCode",
      key: "iworksheetCode",
      width: 120,
    },
    {
      title: "รายการ",
      dataIndex: "serviceDetailName",
      key: "serviceDetailName",
      width: 300,
    },
    {
      title: "วัน-เวลาที่ทำงาน",
      dataIndex: "workDate",
      key: "workDate",
      width: 250,
      render: (value: any, item: TWorksheetHeadView) => (<>{toThDate(item.istartDate)} - {toThDate(item.iendDate)}</>)
    },
    {
      title: "จำนวนเงินคงค้าง(บาท)",
      dataIndex: "remainAmt",
      key: "remainAmt",
      width: 200,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iremainAmt)}</span>;
      },
    },
    {
      title: "ค่าบริการ",
      dataIndex: "iserviceAmt",
      key: "iserviceAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iserviceAmt)}</span>;
      },
    },
    {
      title: "OT",
      dataIndex: "iotAmt",
      key: "iotAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iotAmt)}</span>;
      },
    },
    {
      title: "ค่าอื่นๆ",
      dataIndex: "iextraAmt",
      key: "iextraAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iextraAmt)}</span>;
      },
    },
    {
      title: "ส่วนลด",
      dataIndex: "idiscount",
      key: "idiscount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.idiscount)}</span>;
      },
    },
    {
      title: "รวม",
      dataIndex: "total1Amt",
      key: "total1Amt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itotal1Amt)}</span>;
      },
    },
    {
      title: "ภาษี",
      dataIndex: "vatAmt",
      key: "vatAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itaxAmt)}</span>;
      },
    },
    {
      title: "สุทธิ",
      dataIndex: "total2Amt",
      key: "total2Amt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itotal2Amt)}</span>;
      },
    },
    {
      title: "ชำระแล้ว",
      dataIndex: "payedamount",
      key: "payedamount",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.payedamount)}</span>
      },
    },
    {
      title: "",
      key: "action",
      width: 50,
      align: "center" as "center",
      render: (value: any, item: TWorksheetHeadView, index: number) => {
        return (
          < Button
            type="primary"
            size='small'
            danger
            shape="circle"
            icon={< DeleteOutlined />}
            onClick={() => onDelete(item, index)}
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (trigger) {
      confirm({
        title: "ตรวจสอบข้อมูล",
        icon: <ExclamationCircleOutlined />,
        content: "คุณต้องการที่จะบันทึกข้อมูลหรือไม่",
        cancelText: "ยกเลิก",
        okText: "ยืนยัน",
        onOk() {
          form.submit();
        },
        onCancel() {
          setTrigger(false);
        }
      });
    }
  }, [trigger]);

  useEffect(() => {
    if (!lookupDebitorModalVisible && tDebitorHeadSelected.iid) {
      setLoading(true);
      (async () => {
        const wshDebitRes = await tWorksheetHeadService.getWorksheetByDebitor(tDebitorHeadSelected.iid, "00", userInfo.branchId);
        if (wshDebitRes.data) {
          setWorksheetHeadAlls(wshDebitRes.data.WSH_ALL_LIST);
          setWorksheetHeadShows([]);
          setWorksheetHeadSelecteds([]);
          setWorksheetHeadIns([]);
          setWorksheetHeadOuts([]);
        }

        form.setFieldsValue({
          debitor: tDebitorHeadSelected.iid + " : " + tDebitorHeadSelected.iprename.trim() + tDebitorHeadSelected.iname + " " + (tDebitorHeadSelected.isurname ? tDebitorHeadSelected.isurname : ""),
          iaddress: tDebitorHeadSelected.iaddress,
          iamphurName: tDebitorHeadSelected.iamphurName,
          iprovinceName: tDebitorHeadSelected.iprovinceName,
          ipostcode: tDebitorHeadSelected.ipostcode,
          itelephoneNumber: tDebitorHeadSelected.itelephoneNumber,
          icreditamount: tDebitorHeadSelected.icreditamount,
          creditdate: tDebitorHeadSelected.creditdate,
          iextradiscountamount: tDebitorHeadSelected.iextradiscountamount,
          currentusage: tDebitorHeadSelected.currentusage,
          rdInOut: "WSH_OUT",
          iappointmentDate: moment().add(tDebitorHeadSelected.creditdate, 'days'),
        });
      })();
    }

  }, [lookupDebitorModalVisible, tDebitorHeadSelected]);

  useEffect(() => {
    if (loading && worksheetHeadAlls.length > 0) {
      setLoading(false);
    }
  }, [worksheetHeadAlls, loading]);

  const onDelete = (item: TWorksheetHeadView, index: number) => {
    const worksheetCode = item.iworksheetCode;
    const foundInSelected = (worksheetHeadSelecteds as Array<TWorksheetHeadView>).find((obj) => obj.iworksheetCode === worksheetCode);

    const array = worksheetHeadSelecteds ? [...worksheetHeadSelecteds] : [];
    array?.splice(index, 1);
    setWorksheetHeadSelecteds(array);

    if (foundInSelected) {
      const arrayAll = worksheetHeadAlls ? [...worksheetHeadAlls] : [];
      arrayAll.push(foundInSelected!);
      setWorksheetHeadAlls(arrayAll);
    }

    if (foundInSelected?.itypeworksheetCode === "01") {
      const index = (worksheetHeadOuts as Array<TWorksheetHeadView>).findIndex(object => object.iworksheetCode === worksheetCode?.trim());
      if (index !== -1) {
        const arrayOut = worksheetHeadOuts ? [...worksheetHeadOuts] : [];
        arrayOut?.splice(index, 1);
        setWorksheetHeadOuts(arrayOut);
        setWorksheetHeadShows(arrayOut);
      }
    } else if (foundInSelected?.itypeworksheetCode === "02") {
      const index = (worksheetHeadIns as Array<TWorksheetHeadView>).findIndex(object => object.iworksheetCode === worksheetCode?.trim());
      if (index !== -1) {
        const arrayIn = worksheetHeadIns ? [...worksheetHeadIns] : [];
        arrayIn?.splice(index, 1);
        setWorksheetHeadIns(arrayIn);
      }
    }

    calAmount(array);
  }

  const handleSelectWorksheetCode = () => {
    const rdInOut = form.getFieldValue("rdInOut");
    const typeWshcode = rdInOut === "WSH_IN" ? "02" : "01";
    const worksheetCode = form.getFieldValue("worksheetCode");

    if (worksheetCode && worksheetCode.length < 13) {
      showNotification(NotificationType.ERROR, "เลขที่ใบงานไม่ถุกต้อง");
      //form.resetFields();
    } else if (!rdInOut) {
      showNotification(NotificationType.ERROR, "กรุณาเลือกรายการใบสั่งงานในหรือใบสั่งงานนอก");
      return;
    } else {
      const foundInSelected = (worksheetHeadSelecteds as Array<TWorksheetHeadView>).find((obj) => obj.iworksheetCode === worksheetCode && obj.itypeworksheetCode === typeWshcode);
      const foundInAll = (worksheetHeadAlls as Array<TWorksheetHeadView>).find((obj) => obj.iworksheetCode === worksheetCode && obj.itypeworksheetCode === typeWshcode);

      if (foundInSelected) {
        showNotification(NotificationType.ERROR, "ใบงาน " + worksheetCode + " นี้ถูกเลือกไว้แล้ว ไม่สามารถเลือกซ้ำได้");
        return;
      } else if (!foundInAll && !foundInSelected) {
        showNotification(NotificationType.ERROR, "ไม่พบใบสั่งงานสำหรับลูกค้านี้");
        return;
      } else if (foundInAll?.iworksheetStatus?.trim() !== "07") {
        showNotification(NotificationType.ERROR, "กรุณารับใบสั่งงานกลับก่อนออกใบแจ้งหนี้");
        return;
      } else if (foundInAll.itypepaymentCode === "03" && foundInAll.payedamount === 0) {
        showNotification(NotificationType.ERROR, "กรุณารับเงินมัดจำก่อนออกใบแจ้งหนี้");
        return;
      } else {
        if (foundInAll) {
          const arrayOut = worksheetHeadOuts ? [...worksheetHeadOuts] : [];
          const arrayIn = worksheetHeadIns ? [...worksheetHeadIns] : [];
          if (foundInAll.itypeworksheetCode === "01") {
            arrayOut.push(foundInAll);
            setWorksheetHeadOuts(arrayOut);
            setWorksheetHeadShows(arrayOut);
          } else if (foundInAll.itypeworksheetCode === "02") {
            arrayIn.push(foundInAll);
            setWorksheetHeadIns(arrayIn);
            setWorksheetHeadShows(arrayIn);
          }

          //Add to selected list
          const arrayPush = worksheetHeadSelecteds ? [...worksheetHeadSelecteds] : [];
          arrayPush.push(foundInAll);
          setWorksheetHeadSelecteds(arrayPush);

          //delete from All list
          const index = (worksheetHeadAlls as Array<TWorksheetHeadView>).findIndex(object => object.iworksheetCode === worksheetCode.trim());
          if (index !== -1) {
            const arraySplice = worksheetHeadAlls ? [...worksheetHeadAlls] : [];
            arraySplice?.splice(index, 1);
            setWorksheetHeadAlls(arraySplice);
          }
          calAmount(arrayPush);
        }
      }
    }
  }

  const calAmount = async (worksheetHeadSelectedList: any) => {
    const rdInOut = form.getFieldValue("rdInOut");
    let amount = 0;
    let serviceAmt = 0;
    let vatamt = 0;
    let paided = 0;
    let remain = 0;
    let discount = 0;
    if (worksheetHeadSelectedList.length > 0) {
      for (const item of worksheetHeadSelectedList) {
        serviceAmt = 0;
        vatamt = 0;
        if (item.typeworksheetflag !== "02" && item.typeworksheetflag !== "04" && item.typeworksheetflag !== "05") {
          switch (item.truckAssettype) {
            case "002":
              serviceAmt = await getServiceAmount(item);
              serviceAmt = serviceAmt - Math.round((item.idiscount / item.allinstalment) * 100) / 100; //round 2 digit
              break;
            default:
              serviceAmt = item.iserviceAmt + item.iextraAmt + item.iotAmt - item.idiscount;
              paided = paided + item.idepositAmt;
              break;
          }
        } else {
          serviceAmt = item.iserviceAmt + item.iextraAmt + item.iotAmt - item.idiscount;
          paided = paided + item.idepositAmt;
        }

        if (item.itypetaxCode === "02") {
          vatamt = Math.round((serviceAmt * 7 / 107) * 100) / 100;
          serviceAmt = serviceAmt - vatamt;
        }
        amount = amount + serviceAmt;
        discount = discount +  Math.round((item.idiscount / item.allinstalment) * 100) / 100;
      }
    }

    let vatAmount = 0;
    if (rdInOut === "WSH_OUT") {
      vatAmount = Math.round((amount * 7 / 100) * 100) / 100;
    }
    remain = amount + vatAmount - paided;
    form.setFieldsValue({
      iamount: amount + discount,
      idiscount: discount,
      total: amount,
      vat: vatAmount,
      nettotal: amount + vatAmount,
      deposit: paided,
      remain: remain,
      worksheetCode: undefined,
    })
  }

  const getServiceAmount = async (tWorksheetHeadView: TWorksheetHeadView) => {
    let serviceAmt = 0;
    const atInstallment = (tWorksheetHeadView.atinstalment ? tWorksheetHeadView.atinstalment : 0) + 1;
    const tWorksheetDetailRes = await tWorksheetDetailService.getByReserveCode(tWorksheetHeadView.ireserveCode!);
    if (tWorksheetDetailRes.data) {
      const tWorksheetDetail = tWorksheetDetailRes.data[0] as TWorksheetDetail;
      const tServiceHeadRes = await tServiceHeadService.getTServiceHeadByCode(tWorksheetDetail.iserviceCode!)
      if (tServiceHeadRes.data) {
        const tServiceHead = tServiceHeadRes.data as TServiceHeadView;
        if (atInstallment > 12) {
          serviceAmt = tServiceHead.iunitPrice3;
        } else if (atInstallment > 3 && atInstallment <= 12) {
          serviceAmt = tServiceHead.iunitPrice2;
        } else {
          serviceAmt = tServiceHead.iunitPrice;
        }
      }
    }

    return serviceAmt;
  }

  const onFinish = async (values: any) => {
    if (worksheetHeadSelecteds.length > 0) {

      const temp: TRangradWangbillHeadData = {
        trangradWangbillHead: {
          idebitnoteNo: "0",
          idebitorCode: tDebitorHeadSelected.iid,
          debitorname: tDebitorHeadSelected.iprename.trim() + tDebitorHeadSelected.iname + " " + (tDebitorHeadSelected.isurname ? tDebitorHeadSelected.isurname : ""),
          idebitnotestatus: "00",
          ivatRate: values.rdInOut === "WSH_IN" ? 0 : 7,
          typeDebitnote: values.rdInOut === "WSH_IN" ? "02" : "01",
          numofreprint: 0,
          numofsendtoemployee: 0,
          iamount: values.iamount,
          idiscount: values.idiscount,
          total: values.total,
          vat: values.vat,
          nettotal: values.nettotal,
          ibookseq: "",
          paided: 0,
          paid: 0,
          tax: 0,
          taxRequese: 0,
          witholdingrateId: "00",
          remain: values.remain,
          iappointmentDate: values.iappointmentDate.format("yyyyMMDD"),
          employeeCode: "",
          remark: values.remark,
          branchid: userInfo.branchId,
          paymentcode: 0,
          deposit: values.deposit,
          icreateUser: userInfo.userId,
          ilastUser: userInfo.userId,
          itermNo: userInfo.termNo,
          isavStation: userInfo.saveStation,
        },
        tworksheetList: [...worksheetHeadSelecteds],
      }

      try {
        const res = await tRangradWangbillHeadService.createRangradWangBill(temp);
        showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเรียบร้อย!");
        form.resetFields();
        setTrigger(false);
        setOnDrawerVisible(false);
        history.replace("/app/rengradWangbill/view/" + res.data.trangradWangbillHead.idebitnoteNo);
      } catch (err) {
        showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        setTrigger(false);
      }
    } else {
      showNotification(NotificationType.ERROR, "ไม่มีข้อมูลในรายการ!");
      form.resetFields();
      setTrigger(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน");
    setTrigger(false);
  };
  
  const onChangeRdInOut = () => {
    const arrayAll = worksheetHeadAlls ? [...worksheetHeadAlls] : [];
    arrayAll.push(...worksheetHeadSelecteds);
    setWorksheetHeadAlls(arrayAll);
    setWorksheetHeadShows([]);
    setWorksheetHeadSelecteds([]);
    setWorksheetHeadIns([]);
    setWorksheetHeadOuts([]);

    form.setFieldsValue({
      iamount: 0,
      idiscount: 0,
      total: 0,
      vat: 0,
      nettotal:  0,
      deposit: 0,
      remain: 0,
      remark: "",
    });
   }

  return (
    <>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Typography.Title level={4}>โปรแกรมเร่งรัดออกใบแจ้งหนี้</Typography.Title>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "",
              }}
            >
              <Button
                type="primary"
                danger
                icon={<CloseCircleOutlined />}
                onClick={() => setOnDrawerVisible(false)}>
                ยกเลิก
              </Button>
              <Button
                onClick={() => setTrigger(true)}
                type="primary"
                icon={<SaveOutlined />}
                style={{ marginLeft: "5px" }}
              >
                บันทึก
              </Button>
            </div>
          </div>
        }
        width="90%"
        visible={onDrawerVisible}
        forceRender={true}
        placement="right"
        bodyStyle={{ paddingBottom: 80 }}
        closable={false}
        destroyOnClose={true}
      >
        <Content className="app-page-content">
          <Spin spinning={loading}>
            {/* <> */}
            <Form
              form={form}
              name="rengeadWangbill"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              labelCol={{ span: 10 }}
            >
              <Card
                style={{
                  width: "auto",
                  marginBottom: 5,
                }}
                headStyle={{
                  fontSize: 14,
                  paddingBottom: 5,
                }}
                title="ข้อมูลลูกหนี้"
              >
                <Row>
                  <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>บัญชีลูกหนี้เลขที่: </Typography.Text>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name="debitor"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input
                        style={{ width: "99%", marginRight: 5, backgroundColor: "#F7F7F8" }} readOnly
                      >
                      </Input>
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    <Button
                      onClick={() => setLookupDebitorModalVisible(true)}
                      type="primary"
                      icon={<SearchOutlined />}
                    >
                    </Button>
                  </Col>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>วงเงิน: </Typography.Text>
                  </Col>
                  <Col span={6}>
                    <Space align='baseline' style={{ width: "80%" }}>
                      <Form.Item
                        name="icreditamount"
                        style={{ margin: "0 0 3px 0px" }}
                      >
                        <InputNumber
                          controls={false}
                          formatter={(value) => formatNumber(value)}
                          readOnly={true}
                          style={{
                            backgroundColor: "#F7F7F8",
                            width: "auto",
                          }}
                        ></InputNumber>
                      </Form.Item>
                      <Typography.Text> บาท</Typography.Text>
                    </Space>
                  </Col>
                </Row>
                <Row>
                  <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>ที่อยู่: </Typography.Text>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      name="iaddress"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input
                        style={{ backgroundColor: "#F7F7F8" }} readOnly
                      >
                      </Input>
                    </Form.Item>
                  </Col>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>จำนวนวันเครดิต: </Typography.Text>
                  </Col>
                  <Col span={6}>
                    <Space align='baseline' style={{ width: "80%" }}>
                      <Form.Item
                        name="creditdate"
                        style={{ margin: "0 0 3px 0px" }}
                      >
                        <InputNumber
                          controls={false}
                          formatter={(value) => formatNumberInt(value)}
                          readOnly={true}
                          style={{
                            textAlign: "end",
                            backgroundColor: "#F7F7F8",
                            width: "auto",
                          }}
                        ></InputNumber>
                      </Form.Item>
                      <Typography.Text> วัน</Typography.Text>
                    </Space>
                  </Col>
                </Row>
                <Row>
                  <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>อำเภอ: </Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="iamphurName"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input
                        style={{ backgroundColor: "#F7F7F8" }}
                        readOnly
                      >
                      </Input>
                    </Form.Item>
                  </Col>
                  <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>จังหวัด: </Typography.Text>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      name="iprovinceName"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input
                        style={{ backgroundColor: "#F7F7F8" }} readOnly
                      >
                      </Input>
                    </Form.Item>
                  </Col>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>ส่วนลดพิเศษ: </Typography.Text>
                  </Col>
                  <Col span={6}>
                    <Space align='baseline' style={{ width: "80%" }}>
                      <Form.Item
                        name="iextradiscountamount"
                        style={{ margin: "0 0 3px 0px" }}
                      >
                        <InputNumber
                          controls={false}
                          formatter={(value) => formatNumber(value)}
                          readOnly={true}
                          style={{
                            backgroundColor: "#F7F7F8",
                            width: "auto",
                          }}
                        ></InputNumber>
                      </Form.Item>
                      <Typography.Text> % </Typography.Text>
                    </Space>
                  </Col>
                </Row>
                <Row>
                  <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>รหัสไปรษณีย์: </Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="ipostcode"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input
                        style={{ backgroundColor: "#F7F7F8" }}
                        readOnly
                      >
                      </Input>
                    </Form.Item>
                  </Col>
                  <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>โทร/มือถือ/FAX: </Typography.Text>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      name="itelephoneNumber"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input
                        style={{ backgroundColor: "#F7F7F8" }} readOnly
                      >
                      </Input>
                    </Form.Item>
                  </Col>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>ยอดค้างชำระ: </Typography.Text>
                  </Col>
                  <Col span={6}>
                    <Space align='baseline' style={{ width: "80%" }}>
                      <Form.Item
                        name="currentusage"
                        style={{ margin: "0 0 3px 0px" }}
                      >
                        <InputNumber
                          controls={false}
                          formatter={(value) => formatNumber(value)}
                          readOnly={true}
                          style={{
                            backgroundColor: "#F7F7F8",
                            width: "auto",
                          }}
                        ></InputNumber>
                      </Form.Item>
                      <Typography.Text> บาท</Typography.Text>
                    </Space>
                  </Col>
                </Row>
              </Card>

              <Card
                style={{
                  width: "auto",
                  marginBottom: 5,
                }}
                headStyle={{
                  fontSize: 14,
                  paddingBottom: 5,
                }}
                title="รายการใบสั่งงาน"
              >
                <Row>
                  <Col span={16}>
                    <Form.Item
                      name="rdInOut"
                    >
                      <Radio.Group 
                      style={{ margin: "0px" }} 
                      onChange = {() => onChangeRdInOut()}
                      >
                        <Radio value="WSH_IN" style={{ backgroundColor: "blue", color: "#FFF", borderRadius: 3 }}>ใบสั่งงานใน</Radio>
                        <Radio value="WSH_OUT" style={{ backgroundColor: "red", color: "#FFF", borderRadius: 3 }}>ใบสั่งงานนอก</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={5} style={{ textAlign: "end" }}>
                    <Space align="center" direction='vertical'>
                      <Typography.Text strong>รายการที่เลือก</Typography.Text>
                      <Button
                        type="text"
                        danger
                        style={{ margin: 0, width: 60, fontWeight: "bold" }}>

                        {formatNumberInt((worksheetHeadIns ? worksheetHeadIns.length : 0) + (worksheetHeadOuts ? worksheetHeadOuts.length : 0))}
                      </Button>
                    </Space>
                  </Col>
                  <Col span={3} style={{ textAlign: "center" }}>
                    <Space align="center" direction='vertical'>
                      <Typography.Text strong>ใบงานทั้งหมด</Typography.Text>
                      <Button
                        type="text"
                        danger
                        style={{ margin: 0, width: 60, fontWeight: "bold" }}
                        onClick={() => setDebitorWorksheetModalVisible(true)}
                      >
                        {formatNumberInt(worksheetHeadAlls.length)}
                      </Button>
                    </Space>
                  </Col>
                </Row>
                <Row>
                  <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>เลขที่ใบสั่งงาน: </Typography.Text>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      name="worksheetCode"
                      style={{ margin: "0 0 10 0" }}
                    >
                      <Input
                        allowClear={false}
                        onFocus={(e: any) => e.target.select()}
                        style={{ width: "50%" }}
                        onKeyDown={(evt: any) => {
                          if (evt.keyCode === 13) {
                            handleSelectWorksheetCode();
                          }
                        }}
                      ></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Table
                  columns={columns}
                  dataSource={worksheetHeadShows}
                  size="small"
                  rowKey={(item: TWorksheetHeadView) => item.ireserveCode!}
                  pagination={false}
                  scroll={{ x: 1800 }}
                  rowClassName={getItemColor}
                />
              </Card>

              <Card
                style={{
                  width: "auto",
                  marginBottom: 5,
                  border: "0"
                }}
                headStyle={{
                  fontSize: 14,
                  paddingBottom: 5,
                }}
                title="จำนวนเงิน"
              >
                <Row>
                  <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>เลขที่ใบแจ้งหนี้: </Typography.Text>
                  </Col>
                  <Col span={22}>
                    <Form.Item
                      name="idebitnoteNo"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input
                        style={{ width: "18%", backgroundColor: "#F7F7F8" }}
                        readOnly
                        placeholder='ระบบสร้างเลขที่ใบแจ้งหนี้อัตโนมัติ'
                      >
                      </Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>จำนวนเงิน: </Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="iamount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        parser={(value) => parserNumber(value)}
                        readOnly={true}
                        style={{
                          backgroundColor: "#F7F7F8",
                          width: "99%",
                        }}
                      >
                      </InputNumber>
                    </Form.Item>
                  </Col>
                  <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>ส่วนลดใบวางบิล: </Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="idiscount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        parser={(value) => parserNumber(value)}
                        readOnly={true}
                        style={{
                          backgroundColor: "#F7F7F8",
                          width: "99%",
                        }}
                      >
                      </InputNumber>
                    </Form.Item>
                  </Col>
                  <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>ครบกำหนดชำระเงิน: </Typography.Text>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      name="iappointmentDate"
                      rules={[{ required: false }]}
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <DatePicker
                        format={Constants.DATE_FORMAT}
                        placeholder="เลือกวันที่"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>ชำระแล้ว: </Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="deposit"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        parser={(value) => parserNumber(value)}
                        readOnly={true}
                        style={{
                          backgroundColor: "#F7F7F8",
                          width: "99%",
                        }}
                      >
                      </InputNumber>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>รวมหลังลด: </Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="total"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        parser={(value) => parserNumber(value)}
                        readOnly={true}
                        style={{
                          backgroundColor: "#F7F7F8",
                          width: "99%",
                        }}
                      >
                      </InputNumber>
                    </Form.Item>
                  </Col>
                  <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>ภาษี: </Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="vat"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        parser={(value) => parserNumber(value)}
                        readOnly={true}
                        style={{
                          backgroundColor: "#F7F7F8",
                          width: "99%",
                        }}
                      >
                      </InputNumber>
                    </Form.Item>
                  </Col>
                  <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>เป็นเงิน: </Typography.Text>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      name="nettotal"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        parser={(value) => parserNumber(value)}
                        readOnly={true}
                        style={{
                          backgroundColor: "#F7F7F8",
                          width: "99%",
                        }}
                      >
                      </InputNumber>
                    </Form.Item>
                  </Col>
                  <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>ค้างชำระ: </Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="remain"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        parser={(value) => parserNumber(value)}
                        readOnly={true}
                        style={{
                          backgroundColor: "#F7F7F8",
                          width: "99%",
                        }}
                      >
                      </InputNumber>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>หมายเหตุ: </Typography.Text>
                  </Col>
                  <Col span={22}>
                    <Form.Item
                      name="remark"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input
                        style={{ width: "73%" }}
                      >
                      </Input>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Form>
            {/* </> */}
          </Spin>
        </Content>
      </Drawer>

      <div>
        {lookupDebitorModalVisible && (
          <LookupTDebitorHeadModal
            lookupDebitorModalVisible={lookupDebitorModalVisible}
            setLookupDebitorModalVisible={setLookupDebitorModalVisible}
            setTDebitorHeadSelected={setTDebitorHeadSelected}
          ></LookupTDebitorHeadModal>
        )}
      </div>
      <div>
        {debitorWorksheetModalVisible && (
          <DebitorWorksheetWaitWangbillListModal
            debitorWorksheetModalVisible={debitorWorksheetModalVisible}
            setDebitorWorksheetModalVisible={setDebitorWorksheetModalVisible}
            worksheetHeadAlls={worksheetHeadAlls}
          ></DebitorWorksheetWaitWangbillListModal>
        )}
      </div>
    </>
  )
}

export default TRengRadWangBillDrawer