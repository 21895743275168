import { DeleteOutlined, ExclamationCircleOutlined, NodeIndexOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Card, Col, Descriptions, Form, Input, Modal, Row, Space, Spin, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { TDebitorHead } from 'src/domain/finance/tDebitorHead';
import { TServiceHeadView } from 'src/domain/master/view/tServiceHeadView';
import { TRangradWangbillDetail } from 'src/domain/tRangradWangbillDetail';
import { TRangradWangbillHead } from 'src/domain/tRangradWangbillHead';
import { TWorksheetDetail } from 'src/domain/worksheet/tWorksheetDetail';
import { TWorksheetHead } from 'src/domain/worksheet/tWorksheetHead';
import { TRangradWangbillHeadData } from 'src/domain/view/tRangradWangbillHeadData';
import { TWorksheetRangradWangbillView } from 'src/domain/worksheet/view/tWorksheetRangradWangbillView';
import PageHeader from 'src/layouts/PageHeader';
import tDebitorHeadService from 'src/services/debitor/tDebitorService';
import tRangradWangbillDetailService from 'src/services/tRangradWangbillDetailService';
import tRangradWangbillHeadService from 'src/services/tRangradWangbillHeadService';
import tWorksheetDetailService from 'src/services/tWorksheetDetailService';
import tWorksheetHeadService from 'src/services/tWorksheetHeadService';
import tServiceHeadService from 'src/services/tservice/tServiceHeadService';
import { useAppSelector } from 'src/stores/store';
import { toThDate } from 'src/utils/dateTimeUtils';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber } from 'src/utils/numberUtils';
import WangBillReformMovementModal from './WangBillReformMovementModal';

const { Title } = Typography;
const { confirm } = Modal;

interface Summary {
  serviceTotal: number;
  discountTotal: number;
  noVatTotal: number;
  vatTotal: number;
  netTotal: number;
}

const FinanceOtherWorksheetDrawer: React.FC<TWorksheetRangradWangbillView> = (props) => {
  const [showSpin, setShowSpin] = useState<boolean>(false);
  const [form] = Form.useForm();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [mode, setMode] = useState('');

  const [tRangradMovementModalVisible, setTRangradMovementModalVisible] = useState<boolean>(false);  
  const [debitNotePreviewBlob, setDebitNotePreviewBlob] = useState<any>();
  const [debitNotePreviewData, setDebitNotePreviewData] = useState<any>();
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false);
  const [showAllPage, setShowAllPage] = useState<boolean>(false);

  const [summary, setSummary] = useState<Summary>({ serviceTotal: 0.0, discountTotal: 0.00, noVatTotal: 0.00, vatTotal: 0.0, netTotal: 0.0 } as Summary);
  const [tDebitorHead, setTDebitorHead] = useState<TDebitorHead>({} as TDebitorHead);
  const [tWorksheetHead, setTWorksheetHead] = useState<TWorksheetHead>({} as TWorksheetHead);
  const [tRangradWangbillHead, setTRangradWangbillHead] = useState<TRangradWangbillHead>({} as TRangradWangbillHead);
  const [tRangradWangbillHeadData, setTRangradWangbillHeadData] = useState<TRangradWangbillHeadData>({} as TRangradWangbillHeadData);
  const [tWorksheetRangradWangbillViews, setTWorksheetRangradWangbillViews] = useState<TWorksheetRangradWangbillView[]>([]);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: TWorksheetRangradWangbillView, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่ใบสั่งงาน",
      dataIndex: "iworksheetCode",
      key: "iworksheetCode",
    },
    {
      title: "วันที่ใช้บริการ",
      dataIndex: "istartDate",
      key: "istartDate",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        return <span>{item.istartDate ? toThDate(item.istartDate) : ""}</span>;
      },
    },
    {
      title: "รายการ",
      dataIndex: "truckNumber",
      key: "truckNumber",
    },

    {
      title: "ค่าบริการ",
      dataIndex: "iserviceAmt",
      key: "iserviceAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        return <span>{formatNumber(item.iserviceAmt)}</span>;
      },
    },
    {
      title: "ค่าอื่นๆ",
      dataIndex: "iextraAmt",
      key: "iextraAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        return <span>{formatNumber(item.iextraAmt)}</span>;
      },
    },
    {
      title: "OT",
      dataIndex: "iotAmt",
      key: "iotAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        return <span>{formatNumber(item.iotAmt)}</span>;
      },
    },
    {
      title: "ส่วนลดใบสั่งงาน",
      dataIndex: "idiscount",
      key: "idiscount",
      width: 120,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        return <span>{formatNumber(item.idiscount)}</span>;
      },
    },
    {
      title: "รวมก่อนภาษี",
      dataIndex: "total1",
      key: "total1",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        let total1 = 0.00;
        let vatamt = 0.00;
        let serviceamt = (item.iserviceAmt ? Number(item.iserviceAmt) : 0.00) + (item.iextraAmt ? Number(item.iextraAmt) : 0.00) +
          (item.iotAmt ? Number(item.iotAmt) : 0.00) - (item.idiscount ? Number(item.idiscount) : 0.00)
        if (item.itypetaxCode === '01') {
          vatamt = Math.round(serviceamt * 7 / 100);
          total1 = serviceamt;
        }
        if (item.itypetaxCode === '02') {
          vatamt = Math.round(serviceamt * 7 / 107);
          total1 = serviceamt - vatamt;
        }
        if (item.itypetaxCode === '03') {
          vatamt = 0;
          total1 = serviceamt;
        }
        return <span>{formatNumber(total1)}</span>;
      },
    },
    {
      title: "ภาษี(7%)",
      dataIndex: "vatamt",
      key: "vatamt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        let vatamt = 0.00;
        let serviceamt = (item.iserviceAmt ? Number(item.iserviceAmt) : 0.00) + (item.iextraAmt ? Number(item.iextraAmt) : 0.00) +
          (item.iotAmt ? Number(item.iotAmt) : 0.00) - (item.idiscount ? Number(item.idiscount) : 0.00)
        if (item.itypetaxCode === '01') {
          vatamt = Math.round(serviceamt * 7 / 100);
        }
        if (item.itypetaxCode === '02') {
          vatamt = Math.round(serviceamt * 7 / 107);
        }
        if (item.itypetaxCode === '03') {
          vatamt = 0;
        }

        return <span>{formatNumber(vatamt)}</span>;
      },
    },
    {
      title: "รวมภาษี",
      dataIndex: "total2",
      key: "total2",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        let total1 = 0.00;
        let vatamt = 0.00;
        let serviceamt = (item.iserviceAmt ? Number(item.iserviceAmt) : 0.00) + (item.iextraAmt ? Number(item.iextraAmt) : 0.00) +
          (item.iotAmt ? Number(item.iotAmt) : 0.00) - (item.idiscount ? Number(item.idiscount) : 0.00)
        if (item.itypetaxCode === '01') {
          vatamt = Math.round(serviceamt * 7 / 100);
          total1 = serviceamt;
        }
        if (item.itypetaxCode === '02') {
          vatamt = Math.round(serviceamt * 7 / 107);
          total1 = serviceamt - vatamt;
        }
        if (item.itypetaxCode === '03') {
          vatamt = 0;
          total1 = serviceamt;
        }

        return <span>{formatNumber(total1 + vatamt)}</span>;
      },
    },

    {
      title: "ชำระแล้ว",
      dataIndex: "payedamount",
      key: "payedamount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        return <span>{formatNumber(item.payedamount)}</span>;
      },
    },
    {
      title: "ยอดคงเเหลือ",
      dataIndex: "remaint",
      key: "remaint",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        let total1 = 0.00;
        let vatamt = 0.00;
        let serviceamt = (item.iserviceAmt ? Number(item.iserviceAmt) : 0.00) + (item.iextraAmt ? Number(item.iextraAmt) : 0.00) +
          (item.iotAmt ? Number(item.iotAmt) : 0.00) - (item.idiscount ? Number(item.idiscount) : 0.00)
        if (item.itypetaxCode === '01') {
          vatamt = Math.round(serviceamt * 7 / 100);
          total1 = serviceamt;
        }
        if (item.itypetaxCode === '02') {
          vatamt = Math.round(serviceamt * 7 / 107);
          total1 = serviceamt - vatamt;
        }
        if (item.itypetaxCode === '03') {
          vatamt = 0;
          total1 = serviceamt;
        }

        return <span>{formatNumber((total1 + vatamt) - (item.payedamount ? Number(item.payedamount) : 0))}</span>;
      },
    },

    {
      title: "หัก ณ ที่จ่าย",
      dataIndex: "purchasetax",
      key: "purchasetax",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        return <span>{formatNumber(item.purchasetax)}</span>;
      },
    },
    {
      title: "สาขา",
      dataIndex: "branchName",
      key: "branchName",
    },
    {
      title: "",
      key: "action",
      width: 50,
      align: "center" as "center",
      render: (value: any, item: TWorksheetRangradWangbillView, index: number) => {
        return (
          < Button
            type="primary"
            size='small'
            danger
            shape="circle"
            icon={< DeleteOutlined />}
            onClick={() => onDeleteItem(item)} />
        );
      },
    },
  ];

  const initial = () => {
    form.setFieldsValue({
      debitNoteNumber: '',
      worksheetCode: '',
      remark: '',
    });
  }

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    initial();
  }, []);

  useEffect(() => {
    saveOrDeleteTRangradWangbill();
  }, [tRangradWangbillHeadData]);

  useEffect(() => {
    recalculate();
  }, [tWorksheetRangradWangbillViews]);


  useEffect(() => {
    if (tRangradWangbillHead.idebitnoteNo) {
      loadDebitorHeadAndRangradWangbillDetail();
    }
  }, [tRangradWangbillHead]);

  const recalculate = async () => {
    let serviceamt = 0.00;
    let vatamt = 0.00;
    let amount = 0.00;
    let discount = 0.0;
    const summaryTemp = { serviceTotal: 0.0, discountTotal: 0.00, noVatTotal: 0.00, vatTotal: 0.0, netTotal: 0.0 } as Summary;
    form.setFieldsValue({
      worksheetCode: '',
      remark: '',
    });

    for (const tWorksheetRangradWangbillView of tWorksheetRangradWangbillViews) {
      serviceamt = (tWorksheetRangradWangbillView.iserviceAmt ? Number(tWorksheetRangradWangbillView.iserviceAmt) : 0.00) +
        (tWorksheetRangradWangbillView.iextraAmt ? Number(tWorksheetRangradWangbillView.iextraAmt) : 0.00) + (tWorksheetRangradWangbillView.iotAmt ? Number(tWorksheetRangradWangbillView.iotAmt) : 0.00)
      if (tWorksheetRangradWangbillView?.typeworksheetflag !== "02" && tWorksheetRangradWangbillView?.typeworksheetflag !== "04" && tWorksheetRangradWangbillView?.typeworksheetflag !== "05") {
        if (tWorksheetRangradWangbillView.truckAssettype === "002") {
          amount = await getServiceAmount(tWorksheetRangradWangbillView, (tWorksheetRangradWangbillView.iatinstalment ? Number(tWorksheetRangradWangbillView.iatinstalment) : 0))
          discount = Math.round((tWorksheetRangradWangbillView.idiscount ? Number(tWorksheetRangradWangbillView.idiscount) : 0.00) / (tWorksheetRangradWangbillView.allinstalment ? Number(tWorksheetRangradWangbillView.allinstalment) : 1.00))
          summaryTemp.discountTotal = summaryTemp.discountTotal + discount;
        } else {
          amount = serviceamt;
          discount = (tWorksheetRangradWangbillView.idiscount ? Number(tWorksheetRangradWangbillView.idiscount) : 0.00)
          summaryTemp.discountTotal = summaryTemp.discountTotal + discount;
        }
      } else {
        amount = serviceamt;
        discount = (tWorksheetRangradWangbillView.idiscount ? Number(tWorksheetRangradWangbillView.idiscount) : 0.00)
        summaryTemp.discountTotal = summaryTemp.discountTotal + discount;
      }
      if (tWorksheetRangradWangbillView.itypetaxCode === '01') {
        vatamt = Math.round((amount - discount) * 7 / 100);
        summaryTemp.serviceTotal = (summaryTemp.serviceTotal + amount);
        summaryTemp.noVatTotal = (summaryTemp.noVatTotal + (amount - discount));
        summaryTemp.netTotal = (summaryTemp.netTotal + ((amount - discount) + vatamt));
      }
      if (tWorksheetRangradWangbillView.itypetaxCode === '02') {
        vatamt = Math.round((amount - discount) * 7 / 107);
        summaryTemp.serviceTotal = (summaryTemp.serviceTotal + amount);
        summaryTemp.noVatTotal = (summaryTemp.noVatTotal + ((amount - discount) - vatamt));
        summaryTemp.netTotal = (summaryTemp.netTotal + (amount - discount));
      }
      if (tWorksheetRangradWangbillView.itypetaxCode === '03') {
        vatamt = 0;
        summaryTemp.serviceTotal = (summaryTemp.serviceTotal + amount);
        summaryTemp.noVatTotal = (summaryTemp.noVatTotal + (amount - discount));
        summaryTemp.netTotal = (summaryTemp.netTotal + (amount - discount));
      }
      summaryTemp.vatTotal = summaryTemp.vatTotal + vatamt;
    }

    const _tRangradWangbillHeadData = {
      ...tRangradWangbillHeadData,
      trangradWangbillHead: {
        ...tRangradWangbillHead,
        iamount: summaryTemp.serviceTotal,
        idiscount: summaryTemp.discountTotal,
        total: summaryTemp.noVatTotal,
        vat: summaryTemp.vatTotal,
        nettotal: summaryTemp.netTotal,
        remain: summaryTemp.netTotal,
        paymentcode: 0,
      },
      tworksheetHead: tWorksheetHead,
      remark: form.getFieldValue('remark')
    }
    setTRangradWangbillHeadData(_tRangradWangbillHeadData);

    setSummary(summaryTemp);
  }

  const getServiceAmount = async (tWorksheetRangradWangbillView: TWorksheetRangradWangbillView, atInstallment: number) => {
    let serviceAmt = 0.0;
    const tWorksheetDetailRes = await tWorksheetDetailService.getByReserveCode(tWorksheetRangradWangbillView.ireserveCode!);
    if (tWorksheetDetailRes.data) {
      const tWorksheetDetail = tWorksheetDetailRes.data[0] as TWorksheetDetail;
      const tServiceHeadRes = await tServiceHeadService.getTServiceHeadByCode(tWorksheetDetail.iserviceCode!)
      if (tServiceHeadRes.data) {
        const tServiceHead = tServiceHeadRes.data as TServiceHeadView;
        if ((atInstallment) > 12) {
          serviceAmt = tServiceHead.iunitPrice3;
        } else if (((atInstallment) > 3) && ((tWorksheetRangradWangbillView.atinstalment ? Number(tWorksheetRangradWangbillView.atinstalment) : 0) <= 12)) {
          serviceAmt = tServiceHead.iunitPrice2;
        } else {
          serviceAmt = tServiceHead.iunitPrice;
        }
      }
    }
    return serviceAmt;
  }

  const loadDebitorHeadAndRangradWangbillDetail = async () => {
    setShowSpin(true);
    const tDebitorHeadRes = await tDebitorHeadService.getDebitorHeadById(tRangradWangbillHead.idebitorCode);
    const tWorksheetRangradWangbillViewRes = await tRangradWangbillDetailService.getWorksheetReformByDebitNoteCode(tRangradWangbillHead.idebitnoteNo);
    setTDebitorHead(tDebitorHeadRes.data);
    setTWorksheetRangradWangbillViews(tWorksheetRangradWangbillViewRes.data);
    setShowSpin(false);
  }

  const handleChangeDebitNoteNumber = async () => {
    const debitNoteNumber = form.getFieldValue("debitNoteNumber");
    const isExitDebitNoteCode = await tRangradWangbillHeadService.isExitByDebitNoteNo(debitNoteNumber);
    if (!isExitDebitNoteCode.data) {
      showNotification(NotificationType.ERROR, "ไม่พบข้อมูลใบแจ้งหนี้เลขที่ " + debitNoteNumber);
      form.resetFields();
      setTWorksheetRangradWangbillViews([]);
      return;
    } else {
      const tRangradWangbillHeadRes = await tRangradWangbillHeadService.getByDebitNoteNo(debitNoteNumber);
      setTRangradWangbillHead(tRangradWangbillHeadRes.data);
    }
  }

  const handleChangeWorksheetCode = async () => {
    const worksheetCode = form.getFieldValue('worksheetCode').toString();
    if (!worksheetCode) {
      return;
    }
    const worksheetHeadRes = await tWorksheetHeadService.getByWorksheetCode(worksheetCode);
    if (!worksheetHeadRes.data) {
      showNotification(NotificationType.ERROR, "ไม่พบข้อมูล เลขที่ใบงาน " + worksheetCode + "!");
      return;
    }
    const worksheetHead = worksheetHeadRes.data as TWorksheetHead

    if (tRangradWangbillHead.typeDebitnote !== worksheetHead.itypeworksheetCode) {
      showNotification(NotificationType.ERROR, "ประเภทใบงานไม่สัมพันธ์กับประเภทใบแจ้งหนี้!");
      return;
    }

    if (tRangradWangbillHead.idebitnotestatus !== '00') {
      showNotification(NotificationType.ERROR, "สถานะใบแจ้งหนี้ไม่ถูกต้อง!");
      return;
    }

    if (worksheetHead.iworksheetStatus !== '07') {
      showNotification(NotificationType.ERROR, "สถานะใบงานไม่ถูกต้อง!");
      return;
    }

    const isWorksheetCodeFound = tWorksheetRangradWangbillViews.find((item) => item.iworksheetCode === worksheetCode);
    if (isWorksheetCodeFound) {
      showNotification(NotificationType.ERROR, "ใบแจ้งหนี้มีใบสั่งงานอยู่แล้ว!");
      return;
    }

    const tWorksheetRangradWangbillRes = await tRangradWangbillDetailService.getByWorksheetCode(worksheetCode);
    const tRangradWangbillDetais = tWorksheetRangradWangbillRes.data as TRangradWangbillDetail[];
    if (tRangradWangbillDetais.length > 0 && (worksheetHead.allinstalment ? Number(worksheetHead.allinstalment) : 0) <= 1) {
      showNotification(NotificationType.ERROR, "ใบงานนี้ออกใบแจ้งหนี้แล้ว\nอยู่ในใบแจ้งหนี้เลขที่ " + tRangradWangbillDetais[0].idebitnoteNo + " !");
      return;
    }

    try {
      setMode("ADD");
      setTWorksheetHead(worksheetHead);
      // save TRangradWangbillDetail
      const _tRangradWangbillDetail: TRangradWangbillDetail = {
        iid: 0,
        idebitnoteNo: tRangradWangbillHead.idebitnoteNo,
        iworksheetCode: worksheetHead.iworksheetCode!,
        amount: worksheetHead.iremainAmt ? worksheetHead.iremainAmt : 0,
        iatinstalment: worksheetHead.atinstalment ? (worksheetHead.atinstalment + 1).toString() : "0",
        isavStation: userInfo.saveStation,
        icreateUser: userInfo.userId,
        ilastUser: userInfo.userId,
        itermNo: userInfo.termNo,
      }
      await tRangradWangbillDetailService.saveTRangradWangbillDetail(_tRangradWangbillDetail);
      await loadDebitorHeadAndRangradWangbillDetail();
    } catch (err) {
      showNotification(NotificationType.ERROR, "เกิดข้อผิดพลาด กรุณาติดต่อผู้แลระบบ !");
    }
  }

  const onDeleteItem = async (item: TWorksheetRangradWangbillView) => {
    if (tRangradWangbillHead.idebitnotestatus !== '00') {
      showNotification(NotificationType.ERROR, "สถานะใบแจ้งหนี้ไม่ถูกต้อง!");
      return;
    }
    const worksheetHeadRes = await tWorksheetHeadService.getByWorksheetCode(item.iworksheetCode!);
    if (!worksheetHeadRes.data) {
      showNotification(NotificationType.ERROR, "ไม่พบข้อมูล เลขที่ใบงาน " + item.iworksheetCode + "!");
      return;
    }
    confirm({
      title: "ยืนยัน!",
      icon: <ExclamationCircleOutlined />,
      content: "คุณต้องการที่จะลบข้อมูลหรือไม่?",
      cancelText: "ยกเลิก",
      okText: "ยืนยัน",
      onOk() {
        onDeleteItemConfirmed(item);
      }
    });
  }

  const onDeleteItemConfirmed = async (item: TWorksheetRangradWangbillView) => {
    try {
      setMode("DELETE");
      setTWorksheetHead(item);

      await tRangradWangbillDetailService.deleteByDebitNoteCodeAndWorksheetCode(tRangradWangbillHead.idebitnoteNo, item.iworksheetCode!);
      await loadDebitorHeadAndRangradWangbillDetail();
    } catch (err) {
      showNotification(NotificationType.ERROR, "เกิดข้อผิดพลาด กรุณาติดต่อผู้แลระบบ !");
    }
  }

  const saveOrDeleteTRangradWangbill = async () => {
    try {
      if (mode === "ADD") {
        await tRangradWangbillHeadService.saveTRangradWangbillHeadData(tRangradWangbillHeadData);
      }
      if (mode === "DELETE") {
        await tRangradWangbillHeadService.deleteTRangradWangbillHeadData(tRangradWangbillHeadData);
      }
      setMode("");
    } catch (err) {
      showNotification(NotificationType.ERROR, "เกิดข้อผิดพลาด กรุณาติดต่อผู้แลระบบ !");
    }
  }

  const getBillStatusLabelColor = (debitnoteStatus: string) => {
    let foreColor = "";
    switch (debitnoteStatus) {
      case "98":
        foreColor = "red";
        break;
      case "99":
        foreColor = 'blue';
        break;
      default:
        foreColor = "black";
        break;
    }

    return 'text-' + foreColor;
  }

  const getBillStatus = (debitnoteStatus: string) => {
    let status = ''
    switch (debitnoteStatus) {
      case "00":
        status = "เร่งรัดออกใบแจ้งหนี้";
        break;
      case "01":
        status = "รับใบแจ้งหนี้กลับยังไม่ได้รับเงิน";
        break;
      case "02":
        status = "รับใบแจ้งหนี้กลับส่งให้การเงิน";
        break;
      case "03":
        status = "ส่งเงินให้การเงินบางส่วน";
        break;
      case "04":
        status = "ส่งเงินให้การเงินเต็มจำนวน";
        break;
      case "05":
        status = "ส่งเร่งรัดขนส่ง";
        break;
      case "06":
        status = "ดำเนินการหนี้สูญ";
        break;
      case "07":
        status = "หนี้สูญ";
        break;
      case "97":
        status = "รับใบแจ้งหนี้กลับ";
        break;
      case "98":
        status = "ยกเลิกใบแจ้งหนี้";
        break;
      case "99":
        status = "ชำระเงินแล้ว(จบ)";
        break;
      default:
        status = 'ไม่พบข้อมูล';
        break;
    }

    return status;
  }

  const onPrint = async () => {
    const debitNoteNumber = form.getFieldValue("debitNoteNumber");
    if (!debitNoteNumber) {
      showNotification(NotificationType.WARNING, "ไม่สามารถสั่งพิมพ์ได้ กรุณาระบุใบแจ้งหนี้");
      return;
    } else if (tWorksheetRangradWangbillViews.length === 0) {
      showNotification(NotificationType.WARNING, "ไม่สามารถสั่งพิมพ์ได้ ไม่พบรายการใบงานในใบแจ้งหนี้");
      return;
    } else if (tRangradWangbillHead.idebitnotestatus !== "00") {
      showNotification(NotificationType.WARNING, "ไม่สามารถสั่งพิมพ์ได้ เนื่องจากสถานะใบแจ้งหนี้ไม่ถูกต้อง");
      return;
    } else {
      const isExitDebitNoteCode = await tRangradWangbillHeadService.isExitByDebitNoteNo(debitNoteNumber);
      if (!isExitDebitNoteCode.data) {
        showNotification(NotificationType.ERROR, "ไม่พบข้อมูลใบแจ้งหนี้เลขที่ " + debitNoteNumber);
        return;
      } else {
        const temp = {
          ...(tRangradWangbillHeadData as TRangradWangbillHeadData),
          trangradWangbillHead: {
            ...(tRangradWangbillHeadData.trangradWangbillHead as TRangradWangbillHead),
            numofreprint: tRangradWangbillHead.numofreprint + 1,
            isavStation: userInfo.saveStation,
            ilastUser: userInfo.userId,
            itermNo: userInfo.termNo
          },
          tworksheetList: [...tWorksheetRangradWangbillViews],
        }
        setTRangradWangbillHeadData(temp);
        setShowSpin(true);
        const resultDebitNotePreview = await tRangradWangbillHeadService.printPreviewAndSaveRangradWangbill(temp, showAllPage);
        if (resultDebitNotePreview.data) {
          setDebitNotePreviewBlob(resultDebitNotePreview.data);
          const fileObj = URL.createObjectURL(resultDebitNotePreview.data);
          setDebitNotePreviewData(fileObj);
          setIsPreviewModalVisible(true);
        } else {
          showNotification(
            NotificationType.ERROR,
            "ไม่พบข้อมูลที่เกี่ยวข้อง ไม่สามารถแสดงข้อมูลตัวอย่างได้"
          );
        }
        setShowSpin(false);
      }
    }
  }

  return (
    <>
      <PageHeader title="โปรแกรมการปรับปรุงใบแจ้งหนี้" >
        <Space align='baseline' size={'small'}>
          <Button
            size="middle"
            type="primary"
            disabled={!tRangradWangbillHead.idebitnoteNo}
            icon={<NodeIndexOutlined />}
            onClick={() => setTRangradMovementModalVisible(true)}
          >ความเคลื่อนไหว</Button>

          <Button
            size="middle"
            type="primary"
            icon={<PrinterOutlined />}
            disabled={!tWorksheetRangradWangbillViews.length}
            onClick={onPrint}
          >พิมพ์ใบแจ้งหนี้</Button>          
          <Modal
            style={{ top: 20 }}
            width={"90vw"}
            title="DebitNote"
            bodyStyle={{ height: "85vh" }}
            centered={true}
            visible={isPreviewModalVisible}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={() => setIsPreviewModalVisible(false)}
          >
            <iframe title=" " src={debitNotePreviewData} width={"100%"} height={"100%"}></iframe>
          </Modal>
        </Space>
      </PageHeader>
      <Content className="app-page-content">
        <div
          style={{
            margin: "0.5em 0.5em 0 0.5em",
          }}
        >
          <Spin spinning={showSpin}>
            <Form
              form={form}
              name="receiveCashWorksheet"
              labelCol={{ span: 10 }}
            >
              <Row gutter={[10, 0]}>
                <Col span={15}>
                  <Card type="inner" title="รายการใบแจ้งหนี้">
                    <div style={{ display: "flex", justifyContent: "flex-start", margin: "1em" }}>
                      <Descriptions
                        column={4}
                        size="middle"
                        bordered
                        labelStyle={{ textAlign: "right", fontWeight: "bold" }}
                      >
                        <Descriptions.Item label="เลขที่ใบแจ้งหนี้" span={6}>
                          <Form.Item name="debitNoteNumber"
                            style={{ width: '200px', paddingBottom: '0px' }}
                            rules={[
                              {
                                validator: (_, value) => {
                                  if (value && (value === " " || value === "-" || value.length !== 13)) {
                                    return Promise.reject(new Error('กรุณาป้อนเลขที่ใบแจ้งหนี้ให้ถูกต้อง!'))
                                  } else {
                                    return Promise.resolve()
                                  }
                                },
                              },
                            ]}>
                            <Input onKeyDown={(evt: any) => {
                              if (evt.keyCode === 13) {
                                handleChangeDebitNoteNumber()
                              }
                            }}></Input>
                          </Form.Item>
                        </Descriptions.Item>
                        <Descriptions.Item label="สถานะใบแจ้งหนี้" span={4} className={getBillStatusLabelColor(tRangradWangbillHead.idebitnotestatus)}>{tRangradWangbillHead.idebitnoteNo ? getBillStatus(tRangradWangbillHead.idebitnotestatus) : ""}</Descriptions.Item>
                        <Descriptions.Item label="วันที่ใบแจ้งหนี้" span={2}>{toThDate(tRangradWangbillHead.ientryDate)}</Descriptions.Item>
                        <Descriptions.Item label="กำหนดชำระเงิน" span={2}>{toThDate(tRangradWangbillHead.iappointmentDate)}</Descriptions.Item>
                        <Descriptions.Item label="ค่าบริการ" span={2}><Typography.Text type="danger" strong>{formatNumber(summary.serviceTotal)}</Typography.Text></Descriptions.Item>
                        <Descriptions.Item label="ส่วนลดรวม" span={2}><Typography.Text type="danger" strong>{formatNumber(summary.discountTotal)}</Typography.Text></Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#ffff" }}> </Descriptions.Item>
                        <Descriptions.Item label="รวมก่อนภาษี" span={2}><Typography.Text type="danger" strong>{formatNumber(summary.noVatTotal)}</Typography.Text></Descriptions.Item>
                        <Descriptions.Item label="ภาษี 7%" span={2}><Typography.Text type="danger" strong>{formatNumber(summary.vatTotal)}</Typography.Text></Descriptions.Item>
                        <Descriptions.Item label="รวมยอดใบแจ้งหนี้" span={2}><Typography.Text type="danger" strong>{formatNumber(summary.netTotal)}</Typography.Text></Descriptions.Item>
                      </Descriptions>
                    </div>
                  </Card>
                </Col>
                <Col span={9}>
                  <Card type="inner" title="ข้อมูลลูกหนี้">
                    <div style={{ display: "flex", justifyContent: "flex-start", margin: "1em" }}>
                      <Descriptions
                        column={2}
                        size="middle"
                        bordered
                        labelStyle={{ textAlign: "right", fontWeight: "bold" }}
                        style={{ paddingBottom: '45px' }}
                      >
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#ffff" }}> </Descriptions.Item>
                        <Descriptions.Item label="บัญชีลูกหนี้เลขที่" span={2}> {tDebitorHead.iid}</Descriptions.Item>
                        <Descriptions.Item label="ชื่อ-สกุล" span={2}>{tDebitorHead.iname} {tDebitorHead.isurname}</Descriptions.Item>
                        <Descriptions.Item label="วงเงิน" span={2}>
                          <Space size={'middle'}>
                            <Typography.Text>{formatNumber(tDebitorHead.icreditamount)}</Typography.Text>
                            <Typography.Text>บาท</Typography.Text>
                          </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="เครดิต" span={2}>
                          <Space size={'middle'}>
                            <Typography.Text>{tDebitorHead.creditdate ? tDebitorHead.creditdate : 0}</Typography.Text>
                            <Typography.Text>วัน</Typography.Text>
                          </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="ค้างชำระ" span={2}>
                          <Space size={'middle'}>
                            <Typography.Text>{formatNumber(tDebitorHead.currentusage)}</Typography.Text>
                            <Typography.Text>บาท</Typography.Text>
                          </Space>
                        </Descriptions.Item>
                      </Descriptions>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row gutter={[10, 0]}>
                <Col span={24}>
                  <Card type="inner" title="รายการใบสั่งงาน">
                    <Typography.Text style={{ color: "red", fontWeight: "bold" }}>**กด enter เพื่อเพิ่มรายการ</Typography.Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        margin: "0 1em",
                      }}
                    >
                      <Space size={"small"} style={{ margin: "0 0 3px 0px", width: "80%" }}>
                        <Typography.Text>เลขที่ใบงาน: </Typography.Text>
                        <Form.Item
                          name="worksheetCode"
                          style={{ margin: "0 0 3px 0px" }}
                          rules={[
                            {
                              validator: (_, value) => {
                                if (value && (value === " " || value === "-" || value.length !== 14)) {
                                  return Promise.reject(new Error('กรุณาป้อนเลขที่ใบงานให้ถูกต้อง!'))
                                } else {
                                  return Promise.resolve()
                                }
                              },
                            },
                          ]}>
                          <Input onKeyDown={(evt: any) => {
                            if (evt.keyCode === 13) {
                              handleChangeWorksheetCode()
                            }
                          }}></Input>
                        </Form.Item>
                        <Typography.Text>หมายเหตุ: </Typography.Text>
                        <Form.Item
                          name="remark"
                          style={{ margin: "0 0 3px 0px", width: '400px' }}
                        >
                          <Input></Input>
                        </Form.Item>
                      </Space>

                      <Space direction="vertical" size={"small"} align={'center'}>
                        <Title level={4}>ใบงานทั้งหมด</Title>
                        <Title type="danger" level={4}>{tWorksheetRangradWangbillViews.length}</Title>
                      </Space>
                    </div>
                    <Table
                      columns={columns}
                      scroll={{ x: 1800 }}
                      dataSource={tWorksheetRangradWangbillViews}
                      size="small"
                      rowKey={(item: TWorksheetRangradWangbillView) => item.ireserveCode!}
                      pagination={false}
                    />
                  </Card>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </Content>

      <div>
        {tRangradMovementModalVisible && (
          <WangBillReformMovementModal
            tRangradMovementModalVisible={tRangradMovementModalVisible}
            setTRangradMovementModalVisible={setTRangradMovementModalVisible}
            tRangradWangbillHead={tRangradWangbillHead}
          ></WangBillReformMovementModal>
        )}
      </div>
    </>
  )
}

export default FinanceOtherWorksheetDrawer