import http from "../../lib/http";
import Constants from "../../utils/constants";
import { TReferenceDetail } from './../../domain/master/tReferenceDetail';

const getTReferenceDetailByCode = (referenceKey: string) => {
    return http.get(Constants.DEFAULT_URL + "/tReferenceDetail/getByCode/" + referenceKey);
};

const getTReferenceDetailByKeyAndCode = (key: string, code: string) => {
    return http.get(Constants.DEFAULT_URL + "/tReferenceDetail/getByKeyAndCode", {
        params: {
            key,
            code
        }
    });
};

const getTReferenceDetailByKeyAndId = (key: string, id: string) => {
    return http.get(Constants.DEFAULT_URL + "/tReferenceDetail/getByKeyAndId", {
        params: {
            key,
            id
        }
    });
};

const isExistTReferenceDetailByName = (key: string, id: string) => {
    return http.get(Constants.DEFAULT_URL + "/tReferenceDetail/isExistByName", {
        params: {
            key,
            id
        }
    });
};

const getNextTReferenceDetailCode = (key: number) => {
    return http.get(Constants.DEFAULT_URL + "/tReferenceDetail/nextCode", {
        params: {
            key
        }
    });
}

const updateTReferenceDetail = (tReferenceDetail: TReferenceDetail) => {
    return http.put(Constants.DEFAULT_URL + "/tReferenceDetail", tReferenceDetail);
}

const postTReferenceDetail = (tReferenceDetail: TReferenceDetail) => {
    return http.post(Constants.DEFAULT_URL + "/tReferenceDetail", tReferenceDetail);
}

const getTReferenceDetailByKey = (referenceId: number) => {
    return http.get(Constants.DEFAULT_URL + "/tReferenceDetail/getTReferenceDetailByKey", {
        params: {
            referenceId
        }
    });
};


const defaultApi = {
    getTReferenceDetailByCode,
    getTReferenceDetailByKeyAndCode,
    getTReferenceDetailByKeyAndId,
    getNextTReferenceDetailCode,
    isExistTReferenceDetailByName,
    postTReferenceDetail,
    updateTReferenceDetail,
    getTReferenceDetailByKey,
}
export default defaultApi;