import http from "../../lib/http";
import Constants from "../../utils/constants";

const getDebitorHeadById = (iid: string) => {
  return http.get(Constants.DEFAULT_URL + "/tDebitorHead/" + iid);
};

const getDebitorByParam = (queryStr: string) => {
  return http.get(Constants.DEFAULT_URL + "/tDebitorHead/getDebitorHeadByParam", {
    params: {
      queryStr
    }
  });
};

const getDebitorByCode = (code: string) => {
  return http.get(Constants.DEFAULT_URL + "/tDebitorHead/getDebitorByCode/" + code);
};

const updateDebitorHead = (tDebitorHeadDetailView: any) => {
  return http.put(Constants.DEFAULT_URL + "/tDebitorHead", tDebitorHeadDetailView);
}

const postDebitorHead = (tDebitorHeadDetailView: any) => {
  return http.post(Constants.DEFAULT_URL + "/tDebitorHead", tDebitorHeadDetailView);
}

const getNextDebitorHeadCode = () => {
  return http.get(Constants.DEFAULT_URL + "/tDebitorHead/nextCode");
}

const defaultApi = {
  getDebitorHeadById,
  getDebitorByParam,
  getDebitorByCode,
  updateDebitorHead,
  postDebitorHead,
  getNextDebitorHeadCode
};
export default defaultApi;
