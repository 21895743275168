import { CloseCircleOutlined, ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Col, Drawer, Form, Input, InputNumber, Modal, Radio, Row, Select, Spin, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { TFinanceDetails } from 'src/domain/finance/tFinanceDetails';
import { TFinanceHead2 } from 'src/domain/finance/tFinanceHead2';
import { TEmployee2View } from 'src/domain/master/view/tEmployee2View';
import { TRangradWangbillHead } from 'src/domain/tRangradWangbillHead';
import { ReturnFinancePaymentData } from 'src/domain/view/returnFinancePaymentData';
import { TWorksheetHead } from 'src/domain/worksheet/tWorksheetHead';
import { default as employeeService } from 'src/services/employee/tEmployee2Service';
import tFinanceDetailsService from 'src/services/finance/tFinanceDetailsService';
import tFinanceHead2Service from 'src/services/finance/tFinanceHead2Service';
import returnFinancePaymentService from 'src/services/returnFinancePaymentService';
import tRangradWangbillHeadService from 'src/services/tRangradWangbillHeadService';
import tWorksheetHeadService from 'src/services/tWorksheetHeadService';
import { useAppSelector } from 'src/stores/store';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber } from 'src/utils/numberUtils';

const { Option } = Select;
const { confirm } = Modal;

interface IProps {
  onDrawerVisible: boolean;
  setOnDrawerVisible: any;
}

const ReturnFinancePaymentDrawer: React.FC<IProps> = (props) => {
  const { onDrawerVisible, setOnDrawerVisible } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  const [returnFnPaymentData, setReturnFnPaymentData] = useState<ReturnFinancePaymentData>({} as ReturnFinancePaymentData);
  const [tworksheetHead, setTworksheetHead] = useState<TWorksheetHead>({} as TWorksheetHead);
  const [trangradWangbillHead, setTrangradWangbillHead] = useState<TRangradWangbillHead>({} as TRangradWangbillHead);
  const [tfinanceHead2, setTfinanceHead2] = useState<TFinanceHead2>({} as TFinanceHead2);

  const [employees, setEmployees] = useState<TEmployee2View[]>([]);//ผู้แจ้ง

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (trigger) {
      form.validateFields()
        .then(() => {
          confirm({
            title: "ยืนยัน!",
            icon: <ExclamationCircleOutlined />,
            content: "ต้องการบันทึกการยกเลิกการรับเงินหรือไม่",
            cancelText: "ยกเลิก",
            okText: "ยืนยัน",
            onOk() {
              form.submit();
            },
            onCancel() {
              setTrigger(false);
            }
          });
        }).catch(() => {
          setTrigger(false);
        });
    }
  }, [trigger]);

  const initData = async () => {
    form.setFieldsValue({
      returnType: "WSH_CODE",
      code: undefined,
      idpayment: undefined,
      custName: undefined,
      cashAmt: 0,
      payinAmt: 0,
      serviceAmt: 0,
      chequeAmt: 0,
      vatAmt: 0,
      refOtherPaymentAmt: 0,
      netAmt: 0,
      creditNoteAmt: 0,
      userInfoId: undefined,
      retrunRemark: undefined,
    });
  }

  const onChangeReturnType = () => {
    setReturnFnPaymentData({} as ReturnFinancePaymentData);
    form.setFieldsValue({
      code: undefined,
      idpayment: undefined,
      custName: undefined,
      cashAmt: 0,
      payinAmt: 0,
      serviceAmt: 0,
      chequeAmt: 0,
      vatAmt: 0,
      refOtherPaymentAmt: 0,
      netAmt: 0,
      creditNoteAmt: 0,
      userInfoId: undefined,
      retrunRemark: undefined,
    });
  }

  const handleSearchEmployee = async (data: any) => {
    if (data.length < 3) return;
    const res = await employeeService.getEmployee2ByParam(data);
    setEmployees(res.data);
  };

  const handleSelectCode = async () => {
    const rturnType = form.getFieldValue("returnType"); //WSH_CODE,DEBITNOTE_CODE
    const code = form.getFieldValue("code");

    let customerName = "";
    let paymentCode = "";
    let serviceAmt = 0;
    let vatAmt = 0;
    let netAmt = 0;
    let cashAmt = 0;
    let payInAmt = 0;
    let chequeAmt = 0;
    let refOtherPaymentAmt = 0;
    let creditNoteAmt = 0;
    let financeDetail: TFinanceDetails = {} as TFinanceDetails;

    if (rturnType === "DEBITNOTE_CODE") {
      const rdHeadRes = await tRangradWangbillHeadService.getByDebitNoteNo(code);
      const rdHead: TRangradWangbillHead = rdHeadRes.data;
      const fnDetailRes = await tFinanceDetailsService.getByDebitNoteCode(code);
      financeDetail = fnDetailRes.data;
      if (!rdHead || !fnDetailRes.data) {
        showNotification(NotificationType.ERROR, "ไม่พบใบแจ้งหนี้ เลขที่ " + code);
        return;
      } else if (rdHead.idebitnotestatus !== "99") {
        showNotification(NotificationType.ERROR, "สถานะใบแจ้งหนี้ไม่ถูกต้อง");
        return;
      } else {
        customerName = rdHead.debitorname;
        serviceAmt = rdHead.iamount - rdHead.idiscount;
        vatAmt = rdHead.vat;
        netAmt = rdHead.nettotal
        paymentCode = rdHead.paymentcode.toString();

        setTrangradWangbillHead(rdHead);
      }
    } else { //WSH_CODE
      const worksheetRes = await tWorksheetHeadService.getByWorksheetCode(code);
      const worksheetHead: TWorksheetHead = worksheetRes.data;
      const fnDetailRes = await tFinanceDetailsService.getByWorksheetCode(code);
      financeDetail = fnDetailRes.data;
      if (!worksheetHead || !fnDetailRes.data) {
        showNotification(NotificationType.ERROR, "ไม่พบใบสั่งงาน เลขที่ " + code + " หรือ ใบงานยังไม่ได้รับชำระ");
        return;
      } else if (worksheetHead.ipaymentStatus !== "01") {
        showNotification(NotificationType.ERROR, "สถานะใบงานไม่ถูกต้อง");
        return;
      } else {
        customerName = worksheetHead.icustName!;
        serviceAmt = worksheetHead.itotal1Amt!;
        vatAmt = worksheetHead.itaxAmt!;
        netAmt = worksheetHead.itotal2Amt!;
        paymentCode = worksheetHead.ipaymentCode!;

        setTworksheetHead(worksheetHead);
      }
    }

    const tfinanceHeadRes = await tFinanceHead2Service.getById(financeDetail.parentid);
    const financeHead: TFinanceHead2 = tfinanceHeadRes.data;
    cashAmt = financeHead.cashamount;
    chequeAmt = financeHead.chequeamount;
    payInAmt = financeHead.payinamount;
    refOtherPaymentAmt = financeHead.otheramount;
    creditNoteAmt = financeHead.creditnoteDiscount;

    form.setFieldsValue({
      idpayment: paymentCode,
      custName: customerName,
      cashAmt: cashAmt,
      payinAmt: payInAmt,
      serviceAmt: serviceAmt,
      chequeAmt: chequeAmt,
      vatAmt: vatAmt,
      refOtherPaymentAmt: refOtherPaymentAmt,
      netAmt: netAmt,
      creditNoteAmt: creditNoteAmt,
    });
    setTfinanceHead2(financeHead);
  }

  const onFinish = async (values: any) => {
    const empSelected = (employees as Array<TEmployee2View>).find((obj) => obj.id === values.userInfoId);
    const temp: ReturnFinancePaymentData = {
      tworksheetHead: { ...tworksheetHead },
      rangradWangbillHead: { ...trangradWangbillHead },
      tfinanceHead2: { ...tfinanceHead2 },
      reformUser: { ...empSelected! },
      returnType: values.returnType,
      retrunRemark: values.retrunRemark,
    }

    try {
      // console.log(" ********** temp : ", temp);
      const returnRes = await returnFinancePaymentService.createReturnFinancePayment(temp);
      showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเรียบร้อย!");
      form.resetFields();
      setTrigger(false);
      setOnDrawerVisible(false);
    } catch {
      showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ");
      setTrigger(false);
    }

  }

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน!");
    setTrigger(false);
  };

  return (
    <>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Typography.Title level={4}>โปรแกรมยกเลิกการรับเงิน</Typography.Title>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "",
              }}
            >
              <Button
                type="primary"
                danger
                icon={<CloseCircleOutlined />}
                onClick={() => setOnDrawerVisible(false)}>
                ยกเลิก
              </Button>
              <Button
                onClick={() => setTrigger(true)}
                type="primary"
                icon={<SaveOutlined />}
                style={{ marginLeft: "5px" }}
              >
                บันทึก
              </Button>
            </div>
          </div>
        }
        width="90%"
        visible={onDrawerVisible}
        forceRender={true}
        placement="right"
        bodyStyle={{ paddingBottom: 80 }}
        closable={false}
        destroyOnClose={true}
      >
        <Content className="app-page-content">
          <Spin spinning={loading}>
            <Form
              form={form}
              name="returnFinancePayment"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Card
                style={{
                  width: "auto",
                  marginBottom: 5,
                }}
                headStyle={{
                  fontSize: 14,
                  paddingBottom: 5,
                }}
                title="รายการ"
              >
                <Row>
                  <Col>
                    <Form.Item
                      name="returnType"
                    >
                      <Radio.Group
                        style={{ margin: "0px" }}
                        onChange={() => onChangeReturnType()}
                      >
                        <Radio value="WSH_CODE" style={{ backgroundColor: "blue", color: "#FFF", borderRadius: 3 }}>ใบสั่งงาน</Radio>
                        <Radio value="DEBITNOTE_CODE" style={{ backgroundColor: "red", color: "#FFF", borderRadius: 3 }}>ใบแจ้งหนี้</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>เลขที่ใบงาน/เลขที่ใบแจ้งหนี้: </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="code"
                      style={{ margin: "0 0 3px 0" }}
                    >
                      <Input
                        style={{ width: "50%" }}
                        allowClear={false}
                        onFocus={(e: any) => e.target.select()}
                        onKeyDown={(evt: any) => {
                          if (evt.keyCode === 13) {
                            handleSelectCode();
                          }
                        }}
                      ></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>เลขที่การหัก: </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="idpayment"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input
                        disabled
                        style={{ width: "50%", marginRight: 5 }}
                      >
                      </Input>
                    </Form.Item>
                  </Col>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>เงินสด: </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="cashAmt"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        disabled
                        style={{
                          width: "50%",
                        }}
                      >
                      </InputNumber>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>ชื่อลูกค้า: </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="custName"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input
                        disabled
                        style={{ width: "99%", marginRight: 5 }}
                      >
                      </Input>
                    </Form.Item>
                  </Col>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>เงินโอน: </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="payinAmt"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        disabled
                        style={{
                          width: "50%",
                        }}
                      >
                      </InputNumber>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>ค่าบริการ: </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="serviceAmt"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        disabled
                        style={{
                          width: "50%",
                        }}
                      >
                      </InputNumber>
                    </Form.Item>
                  </Col>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>เช็ค: </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="chequeAmt"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        disabled
                        style={{
                          width: "50%",
                        }}
                      >
                      </InputNumber>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>ภาษี: </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="vatAmt"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        disabled
                        style={{
                          width: "50%",
                        }}
                      >
                      </InputNumber>
                    </Form.Item>
                  </Col>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>อ้างอิงการรับเงินอื่นๆ: </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="refOtherPaymentAmt"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        disabled
                        style={{
                          width: "50%",
                        }}
                      >
                      </InputNumber>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>ค่าบริการรวม: </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="netAmt"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        disabled
                        style={{
                          width: "50%",
                        }}
                      >
                      </InputNumber>
                    </Form.Item>
                  </Col>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>ใบลดหนี้: </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="creditNoteAmt"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        disabled
                        style={{
                          width: "50%",
                        }}
                      >
                      </InputNumber>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>ผู้แจ้ง: </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="userInfoId"
                      style={{ margin: "0 0 3px 0px" }}
                      rules={[{ required: true, message: "กรุณาระบุผู้แจ้ง" }]}
                    >
                      <Select
                        showSearch
                        size="middle"
                        placeholder="เลือกผู้แจ้ง"
                        optionFilterProp="children"
                        filterOption={true}
                        onSearch={handleSearchEmployee}
                      >
                        {employees.map((obj) => {
                          return (
                            <Option key={obj.id} value={obj.id}>
                              {obj.empname}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>หมายเหตุ: </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="retrunRemark"
                      style={{ margin: "0 0 3px 0px" }}
                      validateTrigger="onBlur"
                      rules={[{ required: true, message: "กรุณาระบุหมายเหตุการยกเลิก" }]}
                    >
                      <TextArea
                        rows={4}
                      ></TextArea>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Form>
          </Spin>
        </Content>
      </Drawer>
    </>
  )
}

export default ReturnFinancePaymentDrawer