import http from "../../lib/http";
import Constants from "../../utils/constants";

const getEmployee2ById = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/tEmployee2/" + id);
};

const getEmployee2ByParam = (name: string) => {
  return http.get(Constants.DEFAULT_URL + "/tEmployee2/getEmployee2NameList", {
    params: {
      name
    }
  });
};

const getEmployee2ByCode = (employeeCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tEmployee2/getEmployee2ByCode", {
    params: {
      employeeCode
    }
  });
};

const getEmployee2ViewByName = (name: string) => {
  return http.get(Constants.DEFAULT_URL + "/tEmployee2/getEmployee2ViewByName", {
    params: {
      name
    }
  });
};

const updateEmployee2 = (tEmployee2: any) => {
  return http.put(Constants.DEFAULT_URL + "/tEmployee2", tEmployee2);
}

const postEmployee2 = (tEmployee2: any) => {
  return http.post(Constants.DEFAULT_URL + "/tEmployee2", tEmployee2);
}

const getNextEmployee2Code = () => {
  return http.get(Constants.DEFAULT_URL + "/tEmployee2/nextCode");
}

const getDriverByName = (name: string) => {
  return http.get(
    Constants.DEFAULT_URL + "/tEmployee2/getDriverByNameList/" + name
  );
};

const getDriverByEmpCode = (code: any) => {
  return http.get(
    Constants.DEFAULT_URL + "/tEmployee2/getDriverByEmpCodeList/" + code
  );
};

const defaultApi = {
  getEmployee2ById,
  getEmployee2ByParam,
  getEmployee2ByCode,
  getEmployee2ViewByName,
  updateEmployee2,
  postEmployee2,
  getNextEmployee2Code,
  getDriverByName,
  getDriverByEmpCode,
};
export default defaultApi;
