import http from "../lib/http";
import Constants from "../utils/constants";

const getByReserveCode = (reserveCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetDetail/getByReserveCode", {
    params: {
      reserveCode,
    },
  });
};

const defaultApi = {
  getByReserveCode,
};
export default defaultApi;