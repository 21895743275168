import { Col, Form, Input, InputNumber, Modal, Row, Space, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { TWorksheetOtherservice } from 'src/domain/worksheet/tWorksheetOtherservice';
import { useAppSelector } from 'src/stores/store';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber, textNumber } from 'src/utils/numberUtils';

const { error } = Modal;

interface ModalProp {
  visibleWorksheetOtherserviceModal: boolean;
  setVisibleWorksheetOtherserviceModal: any;
  tWorksheetOtherserviceFormDataList: TWorksheetOtherservice[];
  setTWorksheetOtherserviceFormDataList: any;
  setRecalculateOtherService: any;
}

const AddWorksheetOtherserviceModal: React.FC<ModalProp> = (props) => {
  const { visibleWorksheetOtherserviceModal, setVisibleWorksheetOtherserviceModal, tWorksheetOtherserviceFormDataList,
    setTWorksheetOtherserviceFormDataList, setRecalculateOtherService } = props;
  const [form] = Form.useForm();
  const [submitTrigger, setSubmitTrigger] = useState<boolean>(false);
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  useEffect(() => {
    if (submitTrigger) {
      form.submit();
    }
  }, [submitTrigger]);

  const calculateServiceAmount = () => {
    const iunit = !form.getFieldValue("iunit") ? 0 : form.getFieldValue("iunit");
    const iunitPrice = !parserNumber(form.getFieldValue("iunitPrice")) ? 0 : parserNumber(form.getFieldValue("iunitPrice"));
    const itotalPrice = iunit * iunitPrice;

    form.setFieldsValue({
      itotalPrice: itotalPrice,
    });
  }

  const onFinish = async (values: any) => {
    const temp: TWorksheetOtherservice = {
      iid: 0,
      ireserveCode: "",
      iremark: values.iremark,
      iunitPrice: parserNumber(values.iunitPrice),
      iunit: values.iunit,
      itotalPrice: values.itotalPrice,
      icreateUser: userInfo.userId,
      ilastUser: userInfo.userId,
    };

    const array = tWorksheetOtherserviceFormDataList ? [...tWorksheetOtherserviceFormDataList] : [];
    array?.push(temp);
    setTWorksheetOtherserviceFormDataList(array);
    setRecalculateOtherService(true);
    form.resetFields();
    setSubmitTrigger(false);
    setVisibleWorksheetOtherserviceModal(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน!");
    setSubmitTrigger(false);
  };

  return (
    <>
      <Modal
        title="รายการซ่อม/ขาย/อื่นๆ"
        visible={visibleWorksheetOtherserviceModal}
        width={"50%"}
        closable={false}
        maskClosable={false}
        onOk={() => setSubmitTrigger(true)}
        onCancel={() => setVisibleWorksheetOtherserviceModal(false)}
      >
        <Form form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Col span={24}>

            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>รายละเอียดรายการ: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={19}>
                <Form.Item
                  name="iremark"
                  style={{ marginBottom: "3px" }}
                  rules={[
                    { required: true, message: "กรุณาระบุ รายละเอียดรายการ" }
                  ]}
                >
                  <TextArea
                    rows={4}
                  ></TextArea>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>จำนวน: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={19}>
                <Space align="baseline" direction="horizontal">
                  <Form.Item
                    name="iunit"
                    style={{ marginBottom: "2px" }}
                    rules={[
                      { required: true, message: "กรุณาระบุ จำนวน" },
                      {
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.reject(new Error('กรุณาระบุ จำนวน'))
                          } else {
                            return Promise.resolve()
                          }
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      controls={false}
                      style={{ width: "auto" }}
                      onChange={() => calculateServiceAmount()}
                      onFocus={(e: any) => e.target.select()}
                    ></InputNumber>
                  </Form.Item>
                  หน่วย
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>จำนวนเงินต่อหน่วย: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={19}>
                <Space align="baseline" direction="horizontal">
                  <Form.Item
                    name="iunitPrice"
                    style={{ marginBottom: "2px" }}
                    rules={[
                      {
                        required: true,
                        message: "กรุณาระบุ จำนวนเงินต่อหน่วย"
                      },
                      {
                        validator: (_, value) => {
                          if (!parserNumber(value)) {
                            return Promise.reject(new Error('กรุณาระบุ จำนวนเงินต่อหน่วย'))
                          } else {
                            return Promise.resolve()
                          }
                        },
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      // controls={false}
                      style={{ textAlign: "end" }}
                      onKeyDown={(event: any) => textNumber(event)}
                      onChange={() => calculateServiceAmount()}
                      onPressEnter={(e: any) => form.setFieldsValue({ iunitPrice: formatNumber(parserNumber(e.target.value))})}
                      onBlur={(e: any) => form.setFieldsValue({ iunitPrice: formatNumber(parserNumber(e.target.value)) })}
                      onFocus={(e: any) => e.target.select()}
                    ></Input>
                  </Form.Item>
                  บาท
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>รวมค่าบริการ: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={19}>
                <Space align="baseline" direction="horizontal">
                  <Form.Item
                    name="itotalPrice"
                    style={{ marginBottom: "2px" }}
                    rules={[
                      { required: true, message: "กรุณาระบุ รวมค่าบริการ" }
                    ]}
                  >
                    <InputNumber
                      disabled={true}
                      controls={false}
                      formatter={(value) => formatNumber(value)}
                      parser={(value) => parserNumber(value)}
                      style={{ backgroundColor: "#F8FC9D", color: "#000", width: "auto" }}
                    ></InputNumber>
                  </Form.Item>
                  บาท
                </Space>
              </Col>
            </Row>
          </Col>
        </Form >
      </Modal >
    </>
  )
}

export default AddWorksheetOtherserviceModal