import { ClearOutlined, PlusSquareOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Space, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TRangradWangbillHeadView } from 'src/domain/view/tRangradWangbillHeadView';
import PageHeader from 'src/layouts/PageHeader';
import tRangradWangbillHeadService from 'src/services/tRangradWangbillHeadService';
import Constants from 'src/utils/constants';
import { toThDate } from 'src/utils/dateTimeUtils';
import { formatNumber } from 'src/utils/numberUtils';

const FinanceReciveableFromRangRadList: React.FC<TRangradWangbillHeadView> = () => {
  const [page, setPage] = React.useState(1);
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [tRangradWangbillHeadViews, setTRangradWangbillHeadViews] = useState<TRangradWangbillHeadView[]>([]);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: TRangradWangbillHeadView, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
    },
    {
      title: "วันที่รับชำระ",
      dataIndex: "ientryDate",
      key: "ientryDate",
      width: 100,
      align: "center" as "center",
      render: (value: any, item: TRangradWangbillHeadView) => {
        return <span>{item.ientryDate ? toThDate(item.tfinanceHead2View.entrydate) : ""}</span>;
      },
    },
    {
      title: "เลขที่การรับเงิน",
      dataIndex: "financeHead2Id",
      key: "financeHead2Id",
      width: 120,
      align: "center" as "center",
      render: (value: string, item: TRangradWangbillHeadView) => {
          return <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.tfinanceHead2View.id}</Button>
      }
    },
    {
      title: "เลขที่ใบแจ้งหนี้",
      dataIndex: "idebitnoteNo",
      key: "idebitnoteNo",
      width: 120,
      align: "center" as "center",
      // render: (value: string, item: TRangradWangbillHeadView) => {
      //   return <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.idebitnoteNo}</Button>
      // }
    },
    {
      title: "ชื่อลูกหนี้",
      dataIndex: "debitorname",
      key: "debitorname",
      width: 250,
    },
    {
      title: "ค่าบริการ",
      dataIndex: "nettotal",
      key: "nettotal",
      align: "right" as "right",
      render: (value: any, item: TRangradWangbillHeadView) => {
        return <span>{formatNumber(item.nettotal)}</span>;
      },
    },
    {
      title: "สถานะใบแจ้งหนี้",
      dataIndex: "idebitnotestatusName",
      key: "idebitnotestatusName",
      width: 150,
    },
    {
      title: "ผู้บันทึก",
      dataIndex: "icreateUserName",
      key: "icreateUserName",
    },
  ];

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    form.setFieldsValue({
      fromDate: moment(new Date(), Constants.DATE_FORMAT),
      toDate: moment(new Date(), Constants.DATE_FORMAT)
    });
    form.submit();
  }, []);

  const onItemCliked = (item: TRangradWangbillHeadView) => {
    history.replace("/app/financeReciveableFromRangRad/view/" + item.idebitnoteNo);
  };

  const doSearch = async (values: any) => {
    setLoading(true);
    const fromDate = values.fromDate ? moment(values.fromDate).format("yyyyMMDD") : '';
    const toDate = values.toDate ? moment(values.toDate).format("yyyyMMDD") : '';
    const searchText = (values.debitNoteCodeDebitorName) ? values.debitNoteCodeDebitorName : "";
    const debitNoteStatuses = ['99'];
    if (searchText) {
      // const res = await tRangradWangbillHeadService.getByDateStatus("", "", searchText, debitNoteStatuses.toString());
      // setTRangradWangbillHeadViews(res.data.RANGRAD_WANGBILL_LIST);
      const res = await tRangradWangbillHeadService.getFinanceRangradWangbillList("", "", searchText, debitNoteStatuses.toString());
      setTRangradWangbillHeadViews(res.data);
    } else {
      // const res = await tRangradWangbillHeadService.getByDateStatus(fromDate, toDate, "", debitNoteStatuses.toString());
      // setTRangradWangbillHeadViews(res.data.RANGRAD_WANGBILL_LIST);
      const res = await tRangradWangbillHeadService.getFinanceRangradWangbillList(fromDate, toDate, "", debitNoteStatuses.toString());
      setTRangradWangbillHeadViews(res.data);
    }
    setLoading(false);
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  const onAdd = () => {
    history.replace("/app/financeReciveableFromRangRad/create/");
  }

  return (
    <>
      <PageHeader title="โปรแกรมการเงินรับชำระเงินเร่งรัด" >
        <Button
          size="small"
          type="primary"
          icon={<PlusSquareOutlined />}
          onClick={onAdd}
        >
          เพิ่ม
        </Button>
      </PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="receiveCashWorksheet"
          onFinish={doSearch}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
            }}
          >
            <Space direction="vertical" size={"small"}>
              <Form.Item
                label="เลขที่ใบแจ้งหนี้/ชื่อลูกหนี้"
                name="debitNoteCodeDebitorName"
                rules={[{ required: false }]}
              >
                <Input
                  allowClear
                  placeholder="ป้อนเลขที่ใบแจ้งหนี้/ชื่อลูกหนี้"
                ></Input>
              </Form.Item>
              <Space align="baseline" size={"large"}>
                <Form.Item
                  label="วันที่สร้างตั้งแต่"
                  name="fromDate"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    placeholder="เลือกวันที่"
                  />
                </Form.Item>
                <Form.Item
                  label="ถึง"
                  name="toDate"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    placeholder="เลือกวันที่"
                  />
                </Form.Item>
              </Space>
            </Space>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: 'space-between',
              padding: "1em",
              borderBlockEnd: "2px solid #c9c9c9",
            }}
          >
            <span></span>
            <Space>
              <Button
                size="small"
                type="primary"
                icon={<SearchOutlined />}
                onClick={form.submit}
              >
                ค้นหา
              </Button>
              <Button
                size="small"
                type="primary"
                icon={<ClearOutlined />}
                onClick={() => form.resetFields()}
              >
                ล้าง
              </Button>
            </Space>
          </div>
        </Form>
        <div>
          <Table
            columns={columns}
            dataSource={tRangradWangbillHeadViews}
            size="small"
            loading={loading}
            pagination={{
              pageSize: 15,
              onChange(current) {
                setPage(current);
              }
            }}
            rowKey={(item: TRangradWangbillHeadView) => item.idebitnoteNo!}
            onRow={(item: TRangradWangbillHeadView) => {
              return {
                onDoubleClick: () => onItemCliked(item),
              };
            }}
          />
        </div>
      </Content>
    </>
  );
};

export default FinanceReciveableFromRangRadList