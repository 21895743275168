import { Col, Form, Input, InputNumber, Modal, Row, Select, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { TAssetHeadView } from 'src/domain/master/view/tAssetHeadView';
import { TServiceHeadView } from 'src/domain/master/view/tServiceHeadView';
import { TWorksheetDetail } from 'src/domain/worksheet/tWorksheetDetail';
import tServiceHeadService from 'src/services/tservice/tServiceHeadService';
import { useAppSelector } from 'src/stores/store';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber, textNumber } from '../../../utils/numberUtils';

const { Option } = Select;

interface ModalProp {
  visibleServiceDetailModal: boolean;
  setVisibleOtherServiceModal: any;
  assetSelected: TAssetHeadView;
  wshDetailFormDataList: TWorksheetDetail[];
  setWshDetailFormDataList: any;
  setRecalculateServiceDet: any;
}

const AddServiceDetailModal: React.FC<ModalProp> = (props) => {
  const { visibleServiceDetailModal, setVisibleOtherServiceModal, assetSelected, wshDetailFormDataList, setWshDetailFormDataList, setRecalculateServiceDet } = props;
  const [form] = Form.useForm();
  const [submitTrigger, setSubmitTrigger] = useState<boolean>(false);
  const [tServiceHeads, setTServiceHeads] = useState<TServiceHeadView[]>([]);//ค่าบริการอื่นๆ
  const [tServiceHeadSelected, setTServiceHeadSelected] = useState<TServiceHeadView>();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  useEffect(() => {
    loadData();
    return () => { };
  }, []);

  useEffect(() => {
    if (submitTrigger) {
      form.submit();
    }
  }, [submitTrigger]);

  const loadData = async () => {
    //this.numPerCenDiscouont.Text = this.__customerData.I_DISCOUNT.ToString("#,##0.00");
    const assetType = assetSelected.assettype;
    const truckType = (assetSelected.assettype === "003") ? "10" : assetSelected.trucktype;
    const truckSize = (assetSelected.assettype === "003") ? "000" : assetSelected.trucksizecode;
    const truckWheel = (assetSelected.assettype === "003") ? "0000" : assetSelected.truckwheelcode;
    const res = await tServiceHeadService.getServiceByTypeAsset(assetType, truckType, truckSize, truckWheel);

    form.setFieldsValue({
      unitPrice: 0,
      qty: 0,
      amount: 0,
      discount: 0,
      netAmount: 0,
    });

    setTServiceHeads(res.data);
  };

  useEffect(() => {
    calculateServiceAmount();
  }, [tServiceHeadSelected]);

  const handleSelectService = (iserviceCode: any) => {
    const serviceArr = tServiceHeads as Array<TServiceHeadView>;
    const foundService = serviceArr.find((obj) => obj.iserviceCode === iserviceCode);
    setTServiceHeadSelected(foundService as TServiceHeadView);
  };

  const calServiceForCabinetRender = () => {
    let result = 0;
    let qty = !form.getFieldValue("qty") ? 0 : form.getFieldValue("qty");
    if (qty > 12) {
      result += (qty - 12) * tServiceHeadSelected!.iunitPrice3;
      qty = 12;
    }

    if (qty > 3) {
      result += (qty - 3) * tServiceHeadSelected!.iunitPrice2;
      qty = 3;
    }
    if (qty > 0) {
      result += qty * tServiceHeadSelected!.iunitPrice;
    }
    return result;
  }

  const calServiceForIronsheet = () => {
    let result = 0;
    let qty = !form.getFieldValue("qty") ? 0 : form.getFieldValue("qty");
    if (qty > 1) {
      result += (qty - 1) * tServiceHeadSelected!.iunitPrice2;
      qty = 1;
    }
    if (qty > 0) {
      result += qty * tServiceHeadSelected!.iunitPrice;
    }
    return result;
  }

  const calculateServiceAmount = () => {
    if (!tServiceHeadSelected) return;
    //discount ตัดออก ไม่ได้ใช้ สูตร Discount = (percentDiscount * NetAmt) / 100;
    const qty = !form.getFieldValue("qty") ? 0 : form.getFieldValue("qty");
    const discount = !parserNumber(form.getFieldValue("discount")) ? 0 : parserNumber(form.getFieldValue("discount"));
    let amount = 0;
    if ("002" === assetSelected.assettype) {//ตู้
      amount = calServiceForCabinetRender();
    } else if ("003" === assetSelected.assettype) {//แผ่นเหล็ก
      amount = calServiceForIronsheet();
    } else {
      amount = qty * tServiceHeadSelected.iunitPrice;
    }
    const netAmount = amount - discount;

    form.setFieldsValue({
      serviceName: tServiceHeadSelected?.iserviceName,
      unitPrice: tServiceHeadSelected.iunitPrice,
      qty: qty,
      amount: amount,
      typeunitName: tServiceHeadSelected?.unitName,
      netAmount: netAmount,
    });
  }

  const onFinish = (values: any) => {
    const temp: TWorksheetDetail = {
      iid: 0,
      ireserveCode: "",
      iserviceCode: tServiceHeadSelected!.iserviceCode,
      iserviceName: tServiceHeadSelected!.iserviceName,
      iremark: values.remark,
      iqty: values.qty,
      itypeunitCode: tServiceHeadSelected!.iunitCode,
      itypeunitName: tServiceHeadSelected!.unitName,
      iunitPrice: values.unitPrice,
      iamount: values.netAmount,
      //itruckCode, itruckdriverCode getจาก หน้า worksheet ตอน create
      itruckCode: "",
      itruckdriverCode: "",
      discount: parserNumber(values.discount),
      extrapercendiscount: values.percendiscount,
      isavStation: userInfo.saveStation,
      icreateUser: userInfo.userId,
      ilastUser: userInfo.userId,
      itermNo: userInfo.termNo,
      remarkDiscount: values.remarkDiscount,
    };

    const array = wshDetailFormDataList ? [...wshDetailFormDataList] : [];
    array?.push(temp);
    setWshDetailFormDataList(array);
    setRecalculateServiceDet(true);
    form.resetFields();
    setSubmitTrigger(false);
    setVisibleOtherServiceModal(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน");
    setSubmitTrigger(false);
  };

  return (
    <>
      <Modal
        title="รายการบริการ"
        visible={visibleServiceDetailModal}
        width={"50%"}
        closable={false}
        maskClosable={false}
        onOk={() => setSubmitTrigger(true)}
        onCancel={() => setVisibleOtherServiceModal(false)}
      >
        <Form form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}>
          <Col span={24}>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>รหัสการบริการ: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={20}>
                <Form.Item
                  name="serviceCode"
                  style={{ marginBottom: "3px" }}
                  rules={[
                    { required: true, message: "กรุณาระบุ การบริการ" },
                  ]}
                >
                  <Select
                    showSearch
                    size="middle"
                    placeholder="เลือกบริการ"
                    optionFilterProp="children"
                    filterOption={true}
                    onSelect={handleSelectService}
                  >
                    {tServiceHeads.map((obj) => {
                      return (
                        <Option key={obj.iserviceCode} value={obj.iserviceCode}>
                          [{obj.iserviceCode}] {obj.iserviceName} ( {obj.unitName} )
                          <br />
                          <span style={{ marginLeft: "1rem", fontSize: 13 }}>
                            ราคา1: {formatNumber(obj.iunitPrice)}
                          </span>
                          <span style={{ marginLeft: "1rem", fontSize: 13 }}>
                            ราคา2: {formatNumber(obj.iunitPrice2)}
                          </span>
                          <span style={{ marginLeft: "1rem", fontSize: 13 }}>
                            ราคา3: {formatNumber(obj.iunitPrice3)}
                          </span>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>ชื่อ: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={20}>
                <Form.Item
                  name="serviceName"
                  style={{ marginBottom: "3px" }}
                  rules={[
                    { required: true, message: "กรุณาระบุ ชื่อ" },
                  ]}
                >
                  <Input></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ลักษณะงาน: </Typography.Text>
              </Col>
              <Col span={20}>
                <Form.Item
                  name="remark"
                  style={{ marginBottom: "3px" }}
                >
                  <Input></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>ราคา/หน่วย: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={20}>
                <Space align="baseline" direction="horizontal">
                  <Form.Item
                    name="unitPrice"
                    style={{ marginBottom: "3px" }}
                    rules={[
                      { required: true, message: "กรุณาระบุ ราคา/หน่วย" },
                    ]}
                  >
                    <InputNumber
                      controls={false}
                      disabled={true}
                      formatter={(value) => formatNumber(value)}
                      parser={(value) => parserNumber(value)}
                      style={{ backgroundColor: "#F8FC9D", color: "#000", width: "auto" }}
                    ></InputNumber>
                  </Form.Item>
                  บาท
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>จำนวน: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={20}>
                <Space align="baseline" direction="horizontal">
                  <Form.Item
                    name="qty"
                    style={{ marginBottom: "2px" }}
                    rules={[
                      { required: true, message: "กรุณาระบุจำนวน" },
                      {
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.reject(new Error('กรุณาระบุจำนวน !'))
                          } else {
                            return Promise.resolve()
                          }
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      controls={false}
                      style={{ width: "auto" }}
                      onChange={() => calculateServiceAmount()}
                      onFocus={(e) => e.target.select()}
                    ></InputNumber>
                  </Form.Item>
                  <Form.Item
                    name="typeunitName"
                    style={{ marginBottom: "2px" }}
                  >
                    <Input style={{ border: 0 }} readOnly></Input>
                  </Form.Item>
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>จำนวนเงิน: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={20}>
                <Space align="baseline" direction="horizontal">
                  <Form.Item
                    name="amount"
                    style={{ marginBottom: "2px" }}
                    rules={[
                      { required: true, message: "กรุณาระบุ จำนวนเงิน" },
                    ]}
                  >
                    <InputNumber
                      controls={false}
                      formatter={(value) => formatNumber(value)}
                      parser={(value) => parserNumber(value)}
                      disabled={true}
                      style={{ backgroundColor: "#F8FC9D", color: "#000", width: "auto" }}
                    ></InputNumber>
                  </Form.Item>
                  บาท
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ส่วนลด(%): </Typography.Text>
              </Col>
              <Col span={20}>
                <Space align="baseline" direction="horizontal">
                  <Form.Item
                    name="percendiscount"
                    style={{ marginBottom: "2px" }}
                  >
                    <InputNumber
                      controls={false}
                      formatter={(value) => formatNumber(value)}
                      parser={(value) => parserNumber(value)}
                      disabled={true}
                      style={{ width: "auto" }}
                    ></InputNumber>
                  </Form.Item>
                  %
                </Space>
              </Col>
            </Row>

            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ส่วนลด(บาท): </Typography.Text>
              </Col>
              <Col span={20}>
                <Space align="baseline" direction="horizontal">
                  <Form.Item
                    name="discount"
                    style={{ marginBottom: "2px" }}
                  >
                    <Input
                      type="text"
                      onKeyDown={(event: any) => textNumber(event)}
                      onChange={() => calculateServiceAmount()}
                      onPressEnter={(e: any) => form.setFieldsValue({ discount: formatNumber(parserNumber(e.target.value))})}
                      onBlur={(e: any) => form.setFieldsValue({ discount: formatNumber(parserNumber(e.target.value)) })}
                      onFocus={(e) => e.target.select()}
                      // formatter={(value) => formatNumber(value)}
                      // parser={(value) => parserNumber(value)}
                      style={{ textAlign: "end" }}
                    // controls={false}
                    ></Input>
                  </Form.Item>
                  บาท
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>หมายเหตุส่วนลด: </Typography.Text>
              </Col>
              <Col span={20}>
                <Form.Item
                  name="remarkDiscount"
                  style={{ marginBottom: "3px" }}
                  rules={[
                    { required: false, message: "กรุณาระบุจำนวน" },
                    {
                      validator: (_, value) => {
                        if (!value && parserNumber(form.getFieldValue("discount"))) {
                          return Promise.reject(new Error('กรุณาระบุหมายเหตุการให้สวนลด !'))
                        } else {
                          return Promise.resolve()
                        }
                      },
                    },
                  ]}
                >
                  <Input
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row></Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>รวมค่าบริการ: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={20}>
                <Space align="baseline" direction="horizontal">
                  <Form.Item
                    name="netAmount"
                    style={{ marginBottom: "2px" }}
                    rules={[
                      { required: true, message: "กรุณาระบุ รวมค่าบริการ" },
                    ]}
                  >
                    <InputNumber
                      controls={false}
                      formatter={(value) => formatNumber(value)}
                      parser={(value) => parserNumber(value)}
                      disabled={true}
                      style={{ backgroundColor: "#F8FC9D", color: "#000", width: "auto" }}
                    ></InputNumber>
                  </Form.Item>
                  บาท
                </Space>
              </Col>
            </Row>
          </Col>
        </Form >
      </Modal >
    </>
  );
}

export default AddServiceDetailModal