import { TTaxinvoiceData } from "src/domain/view/tTaxinvoiceData";
import http from "src/lib/http";
import Constants from "src/utils/constants";

const findTTaxinvoiceHeadViews = (invDateFrom: string, invDateTo: string, customercode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tTaxinvoiceHead/findTTaxinvoiceHeadViews", {
    params: {
      invDateFrom,
      invDateTo,
      customercode,
    }
  });
};
const findTTaxinvoiceData = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/tTaxinvoiceHead/findTTaxinvoiceData", {
    params: {
      id,
    }
  });
};

const createUpdateTaxInvoiceData = (ttaxinvoiceData: TTaxinvoiceData, pageStage: string) => {
  return http.post(Constants.DEFAULT_URL + "/tTaxinvoiceHead/createUpdateTaxInvoiceData",
    ttaxinvoiceData,
    {
      params: {
        pageStage
      },
    });
};

const printPreviewAndUpdateTaxInvoice = (ttaxinvoiceData: TTaxinvoiceData, showAllPage: boolean) => {
  return http.post(
    Constants.DEFAULT_URL + "/worksheetReport/printPreviewAndUpdateTaxInvoice/"+showAllPage,
    ttaxinvoiceData,
    {
      responseType: "blob",
    },
  );
};

const defaultApi = {
  findTTaxinvoiceHeadViews,
  findTTaxinvoiceData,
  createUpdateTaxInvoiceData,
  printPreviewAndUpdateTaxInvoice,
}
export default defaultApi;