import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import Search from "antd/es/input/Search";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PageHeader from "src/layouts/PageHeader";
import { formatNumber } from "src/utils/numberUtils";
import { TDebitorHeadView } from "../../domain/finance/view/tDebitorHeadView";
import tDebitorService from "../../services/debitor/tDebitorService";
import AddEditDebitorHeadDrawer from "./AddEditDebitorHeadDrawer";


const DebitorHeadList: React.FC<TDebitorHeadView> = () => {
    const [debitorHeads, setDebitorHeads] = useState<TDebitorHeadView[]>([]);
    const [page, setPage] = React.useState(1);
    const history = useHistory();
    const [visibleAddEditDebitorHeadDrawer, setVisibleAddEditDebitorHeadDrawer] = useState<boolean>(false);

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            width: 50,
            render: (value: number, item: TDebitorHeadView, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
        },
        {
            title: "รหัสลูกหนี้",
            dataIndex: "iid",
            key: "iid",
            width: 100,
            render: (value: string, item: TDebitorHeadView) => (
                <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.iid}</Button>
            )
        },
        {
            title: "ชื่อ",
            dataIndex: "iname",
            key: "iname",
            render: (value: any, item: TDebitorHeadView) => (<>{item.iprename} {item.iname}</>)
        },
        {
            title: "นามสกุล",
            dataIndex: "isurname",
            key: "isurname",
        },
        {
            title: "เบอร์โทร",
            dataIndex: "itelephoneNumber",
            key: "itelephoneNumber",
            width: 100
        },
        {
            title: "วงเงิน",
            dataIndex: "icreditamount",
            key: "icreditamount",
            width: 100,
            render: (value: any, item: TDebitorHeadView) => (<>{formatNumber(item.icreditamount)}</>)
        },
        {
            title: "ใช้วงเงิน",
            dataIndex: "currentusage",
            key: "currentusage",
            width: 100,
            render: (value: any, item: TDebitorHeadView) => (<>{formatNumber(item.currentusage)}</>)
        },
        {
            title: "เครดิต",
            dataIndex: "creditdate",
            key: "creditdate",
            width: 50
        },
        {
            title: "หมายเหตุ",
            dataIndex: "iremarkMessage",
            key: "iremarkMessage",
        },
    ];

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
    }, []);

    const doSearch = async (searchText: string) => {
        const res = await tDebitorService.getDebitorByParam(searchText);
        setDebitorHeads(res.data);
    }

    function onSearchTextChange(searchText: string) {
        if (!searchText) {
            doSearch("");
        } else if (searchText.length >= 2) {
            doSearch(searchText);
        }
    }

    function onAddDebitorHead() {
        setVisibleAddEditDebitorHeadDrawer(true);
    }

    const onItemCliked = (item: TDebitorHeadView) => {
        history.replace("/app/debitor/view/" + item.iid);
    };

    return (
        <>
            <PageHeader title="โปรแกรมข้อมูลลูกหนี้">
                <Button
                    size="small"
                    type="primary"
                    icon={<PlusSquareOutlined />}
                    onClick={onAddDebitorHead}
                >
                    เพิ่ม
                </Button>
            </PageHeader>
            <Content className="app-page-content">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "baseline",
                        margin: "1em 0 0 0",
                        borderBlockEnd: "2px solid #c9c9c9",
                    }}
                >
                    <label style={{ margin: "0 1em 1em 2em" }}>คำค้นหา</label>
                    <Search
                        allowClear
                        style={{ width: "350px" }}
                        size="middle"
                        placeholder="ป้อนรหัสลูกหนี้/ชื่อ/เบอร์โทร"
                        onChange={(event) => onSearchTextChange(event.target.value)}
                        onSearch={(value) => onSearchTextChange(value)}
                    ></Search>
                </div>
                <div>
                    <Table
                        columns={columns}
                        dataSource={debitorHeads}
                        size="small"
                        pagination={{
                            pageSize: 15,
                            onChange(current) {
                                setPage(current);
                            }
                        }}
                        rowKey={(item: TDebitorHeadView) => item.iid}
                        onRow={(item: TDebitorHeadView) => {
                            return {
                                onDoubleClick: () => onItemCliked(item),
                            };
                        }}
                    />
                </div>
            </Content>
            <div> {
                visibleAddEditDebitorHeadDrawer && <AddEditDebitorHeadDrawer
                    iid={""}
                    visibleAddEditDebitorHeadDrawer={visibleAddEditDebitorHeadDrawer}
                    setVisibleAddEditDebitorHeadDrawer={setVisibleAddEditDebitorHeadDrawer} />
            }</div>
        </>
    );
};

export default DebitorHeadList;
