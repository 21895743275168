import { DeleteOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Table, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { TChequeHeadView } from 'src/domain/cheque/view/tChequeHeadView';
import { TFinanceChequedetail } from 'src/domain/finance/tFinanceChequedetail';
import tChequeHeadService from 'src/services/cheque/tChequeHeadService';
import { toThDate } from 'src/utils/dateTimeUtils';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber, textNumber } from 'src/utils/numberUtils';

const { Option } = Select;

interface IProps {
  searchTChequeHeadModalVisible: boolean;
  setSearchTChequeHeadModalVisible: any;
  tFinanceChequeDetails: TFinanceChequedetail[];
  setTFinanceChequeDetails: any;
}

const SearchTChequeHeadModal: React.FC<IProps> = (props) => {
  const { searchTChequeHeadModalVisible, setSearchTChequeHeadModalVisible, tFinanceChequeDetails, setTFinanceChequeDetails } = props
  const [form] = Form.useForm();
  const [chequeHeads, setChequeHeads] = useState<TChequeHeadView[]>([]);
  const [chequeSelected, setChequeSelected] = useState<TChequeHeadView>({} as TChequeHeadView);
  const [tfinanceChequeDetailsForm, setTfinanceChequeDetailsForm] = useState<TFinanceChequedetail[]>([]);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: TFinanceChequedetail, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่เช็ค",
      dataIndex: "chequenumber",
      key: "chequenumber",
      width: 120,
      align: "center" as "center",
    },
    {
      title: "ลงวันที่",
      dataIndex: "chequedate",
      key: "chequedate",
      width: 80,
      align: "center" as "center",
      render: (value: any, item: TFinanceChequedetail) => {
        return <span>{item.chequedate ? toThDate(item.chequedate) : ""}</span>;
      },
    },
    {
      title: "จำนวนเงินที่ชำระ",
      dataIndex: "amount",
      key: "amount",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TFinanceChequedetail) => {
        return <span>{formatNumber(item.amount)}</span>;
      },
    },
    {
      title: "หมายเหตุ",
      dataIndex: "remarks",
      key: "remarks",
      width: 150,
      align: "center" as "center",
    },
    {
      title: "",
      key: "action",
      width: 50,
      align: "center" as "center",
      render: (value: any, item: TFinanceChequedetail, index: number) => {
        return (
          < Button
            type="primary"
            size='small'
            danger
            shape="circle"
            icon={< DeleteOutlined />}
            onClick={() => onDelete(item, index)} />
        );
      },
    },
  ]



  useEffect(() => {
    loadData();
    return () => { };
  }, []);

  const loadData = async () => {
    if (searchTChequeHeadModalVisible) {
      if (tFinanceChequeDetails) {
        setTfinanceChequeDetailsForm(tFinanceChequeDetails ? [...tFinanceChequeDetails] : []);
      }
    }
  };

  const checkExistInList = (chequeNo: any) => {
    const findChequeNo = tfinanceChequeDetailsForm.find(element => element.chequenumber === chequeNo);
    return (findChequeNo) ? true : false;
  }

  const handleSearchChequeHead = async (data: any) => {
    if (data.length < 3) return;
    const res = await tChequeHeadService.getByChequeNumber(data);
    const tChequeHeadResult = res.data as TChequeHeadView[];
    const filteredList = tChequeHeadResult.filter((item) => !tfinanceChequeDetailsForm.find((selectedItem) => item.id === selectedItem.id));
    setChequeHeads(filteredList);
  }

  const handleSelectChequeHead = async (data: any) => {
    if (checkExistInList(data)) {
      showNotification(NotificationType.ERROR, "ไม่สามารถเเลือกเช็คใบเดิมซ้ำได้กรุณาเลือกใหม่อีกครั้ง!");
      form.resetFields();
    } else {
      const chequeArray = chequeHeads as Array<TChequeHeadView>;
      const foundCheque = chequeArray.find((ob) => ob.chequenumber === data);
      setChequeSelected(foundCheque!);

      form.setFieldsValue({
        chequeAmount: formatNumber(foundCheque?.chequeamt),
        chequeOwner: foundCheque!.chequeoner!.trim(),
        chequeRemainAmount: formatNumber(foundCheque?.remaintamt),
        amount: 0.00,
      });
    }
  }

  const handleChangeAmount = (data: number) => {
    const remaintAmt = (chequeSelected?.remaintamt ? chequeSelected?.remaintamt : 0)
    if (data > remaintAmt) {
      showNotification(NotificationType.ERROR, "จำนวนเงินจะมากกว่ายอดคงเหลือเช็คไม่ได้!");
      form.setFieldsValue({
        amount: 0.00,
      });
    } else {
      let remainAmtTemp = remaintAmt - data;
      form.setFieldsValue({
        chequeRemainAmount: formatNumber((remainAmtTemp >= 0) ? remainAmtTemp : remaintAmt),
      });
    }
  }

  const handleAdd = () => {
    if (Number(parserNumber(form.getFieldValue("amount"))) <= 0) {
      showNotification(NotificationType.ERROR, "กรุณาระบุจำนวนเงินใหม่อีกครั้งจำนวนเงินต้องมากกกว่า 0 !");
      form.setFieldsValue({
        amount: 0.00,
      });
    } else {
      let financeChequeDetailTemp = {} as TFinanceChequedetail;
      const temp: TFinanceChequedetail = {
        ...(financeChequeDetailTemp as TFinanceChequedetail),
        chequenumber: form.getFieldValue("chequeNumber"),
        chequeowner: form.getFieldValue("chequeOwner"),
        chequedate: chequeSelected.chequedate,
        chequeamount: Number(parserNumber(form.getFieldValue("chequeAmount"))),
        amount: Number(parserNumber(form.getFieldValue("amount"))),
        customercode: chequeSelected.customercode,
        financetype: chequeSelected.financetype,
        branchid: chequeSelected.branchid,
        remarks: form.getFieldValue("remarks"),
        bank: chequeSelected?.bankName + " / " + chequeSelected?.bankBranchName,
      }
      const array = tfinanceChequeDetailsForm ? [...tfinanceChequeDetailsForm] : [];
      array.push(temp);
      setTfinanceChequeDetailsForm(reindex(array));
      setChequeHeads(chequeHeads.filter((item) => item.id !== chequeSelected.id));
      setChequeSelected({} as TChequeHeadView)
      form.resetFields();

    }
  };

  const onDelete = (item: TFinanceChequedetail, index: number) => {
    const array = tfinanceChequeDetailsForm ? [...tfinanceChequeDetailsForm] : [];
    array?.splice(index, 1);
    setTfinanceChequeDetailsForm(reindex(array));
  }

  const onFinish = () => {
    setTFinanceChequeDetails([...tfinanceChequeDetailsForm])
    setSearchTChequeHeadModalVisible(false);
  }

  const reindex = (tFinanceChequedetail: TFinanceChequedetail[]) => {
    return tFinanceChequedetail.map((item, index) => {
      item.id = index;
      return item;
    });
  }

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>รับชำระเงินด้วยเช็ค</Typography.Title>
          </div>
        }
        visible={searchTChequeHeadModalVisible}
        width={"55%"}
        style={{ minHeight: "70%" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={onFinish}
        onCancel={() => setSearchTChequeHeadModalVisible(false)}
      >
        <Form
          form={form}
          name="financeChequeDetails"
          labelCol={{ span: 4 }}
          initialValues={{ remember: true }}
        >
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>เลขที่เช็ค: </Typography.Text>
            </Col>
            <Col span={8}>
              <Form.Item
                name="chequeNumber"
                rules={[{ required: true, message: "กรุณาระบุเลขที่เช็ค!" }]}
              >
                <Select
                  showSearch
                  allowClear
                  size="middle"
                  placeholder="ระบุเลขที่เช็ค"
                  optionFilterProp="children"
                  filterOption={true}
                  onSearch={handleSearchChequeHead}
                  onSelect={handleSelectChequeHead}
                >
                  {chequeHeads.map((obj: TChequeHeadView) => {
                    return (
                      <Option key={obj.chequenumber} value={obj.chequenumber}>
                        {obj.chequenumber}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>ยอดเช็ค: </Typography.Text>
            </Col>
            <Col span={8}>
              <Form.Item
                name="chequeAmount"
                rules={[{ required: true, message: "กรุณาระบุยอดเช็ค!" }]}
              >
                <InputNumber
                  style={{ textAlign: "right", width: 203, backgroundColor: "#F7F7F8" }} readOnly
                >
                </InputNumber>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>ลูกค้า: </Typography.Text>
            </Col>
            <Col span={19}>
              <Form.Item
                label=""
                name="chequeOwner"
                rules={[{ required: true, message: "กรุณาระบุลูกค้า!" }]}
              >
                <Input style={{ backgroundColor: "#F7F7F8" }} readOnly>
                </Input>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>ยอดชำระ: </Typography.Text>
            </Col>
            <Col span={8}>
              <Form.Item
                name="amount"
                rules={[{ required: true, message: "กรุณาระบุยอดชำระ!" }]}
              >
                <Input
                  type="text"
                  onKeyDown={(event: any) => textNumber(event)}
                  onPressEnter={(e: any) => form.setFieldsValue({ amount: formatNumber(parserNumber(e.target.value)) })}
                  onBlur={(e: any) => form.setFieldsValue({ amount: formatNumber(parserNumber(e.target.value)) })}
                  onFocus={(e) => e.target.select()}
                  style={{ textAlign: "end", width: "100%" }}
                  onChange={(event: any) => handleChangeAmount(parserNumber(event.target.value))}
                ></Input>
              </Form.Item>
            </Col>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>จำนวนคงเหลือ: </Typography.Text>
            </Col>
            <Col span={8}>
              <Form.Item
                name="chequeRemainAmount"
                rules={[{ required: true, message: "กรุณาระบุจำนวนคงเหลือ!" }]}
              >
                <InputNumber
                  style={{ textAlign: "right", width: 203, backgroundColor: "#F7F7F8" }} readOnly
                >
                </InputNumber>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>หมายเหตุ: </Typography.Text>
            </Col>
            <Col span={19}>
              <Form.Item
                name="remarks"
                rules={[{ required: false }]}
              >
                <TextArea
                  rows={2}
                ></TextArea>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={11}></Col>
            <Col span={13}>
              <Button
                size="small"
                type="primary"
                style={{ marginBottom: "15px" }}
                icon={<PlusSquareOutlined />}
                onClick={handleAdd}
              >
                เพิ่ม
              </Button>
            </Col>
          </Row>

          <div style={{ padding: "10px 0px 0px" }}>
            <Table
              columns={columns}
              dataSource={tfinanceChequeDetailsForm}
              size="small"
              rowKey={(item: TFinanceChequedetail) => item.id}
              pagination={false}
              style={{ minHeight: 200 }}
            />
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default SearchTChequeHeadModal