import { ClearOutlined, PlusSquareOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TCustomerHeadView } from 'src/domain/master/view/tCustomerHeadView';
import { ConcludeWorksheetData } from 'src/domain/worksheet/view/concludeWorksheetData';
import PageHeader from 'src/layouts/PageHeader';
import concludeWorksheetService from 'src/services/concludeWorksheetService';
import tCustomerHeadService from 'src/services/customer/tCustomerHeadService';
import Constants from 'src/utils/constants';
import { toThDate } from 'src/utils/dateTimeUtils';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber } from 'src/utils/numberUtils';
import ConcludeWorksheetDrawer from './ConcludeWorksheetDrawer';

const { Option } = Select;

const ConcludeWorksheetList: React.FC<ConcludeWorksheetData> = () => {
  const [page, setPage] = React.useState(1);
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [onDrawerVisible, setOnDrawerVisible] = useState<boolean>(false);
  const [concludeWorksheetDatas, setConcludeWorksheetDatas] = useState<ConcludeWorksheetData[]>([]);
  const [customers, setCustomers] = useState<TCustomerHeadView[]>([]);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 15,
      render: (value: number, item: ConcludeWorksheetData, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
    },
    {
      title: "วันที่ตัดจบ",
      dataIndex: "ientryDate",
      key: "ientryDate",
      width: 80,
      render: (value: any, item: ConcludeWorksheetData) => (<>{toThDate(item.tworksheetMovementView.ientryDate)}</>)
    },
    {
      title: "เลขที่ใบงาน",
      dataIndex: "iworksheetCode",
      key: "iworksheetCode",
      width: 100,
      render: (value: any, item: ConcludeWorksheetData) => (
        <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.tworksheetHeadView.iworksheetCode}</Button>
      )
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "icustName",
      key: "icustName",
      width: 200,
      render: (value: any, item: ConcludeWorksheetData) => (<>{item.tworksheetHeadView.icustName}</>)
    },
    {
      title: "ประเภทใบงาน",
      dataIndex: "itypeworksheetName",
      key: "itypeworksheetName",
      width: 100,
      render: (value: any, item: ConcludeWorksheetData) => (<>{item.tworksheetHeadView.itypeworksheetName}</>)
    },
    {
      title: "ประเภทการชำระเงิน",
      dataIndex: "itypepaymentName",
      key: "itypepaymentName",
      width: 150,
      render: (value: any, item: ConcludeWorksheetData) => (<>{item.tworksheetHeadView.itypepaymentName}</>)
    },
    {
      title: "รายการบริการ",
      dataIndex: "serviceDetailName",
      key: "serviceDetailName",
      width: 200,
      render: (value: any, item: ConcludeWorksheetData) => (<>{item.tworksheetHeadView.serviceDetailName}</>)
    },
    {
      title: "ค่าบริการ",
      dataIndex: "itotal1Amt",
      key: "itotal1Amt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: ConcludeWorksheetData) => (<>{formatNumber(item.tworksheetHeadView.itotal1Amt)}</>)
    },
    {
      title: "ภาษี",
      dataIndex: "itaxAmt",
      key: "itaxAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: ConcludeWorksheetData) => (<>{formatNumber(item.tworksheetHeadView.itaxAmt)}</>)
    },
    {
      title: "ค่าบริการรวมภาษี",
      dataIndex: "itotal2Amt",
      key: "itotal2Amt",
      width: 120,
      align: "right" as "right",
      render: (value: any, item: ConcludeWorksheetData) => (<>{formatNumber(item.tworksheetHeadView.itotal2Amt)}</>)
    },
    {
      title: "หมายเหตุ",
      dataIndex: "iuserRemark",
      key: "iuserRemark",
      width: 200,
      render: (value: any, item: ConcludeWorksheetData) => (<>{item.tworksheetMovementView.iuserRemark}</>)
    },
    {
      title: "ผู้แจ้ง",
      dataIndex: "isystemRemark",
      key: "isystemRemark",
      width: 350,
      render: (value: any, item: ConcludeWorksheetData) => (<>{item.tworksheetMovementView.isystemRemark}</>)
    },
    {
      title: "ผู้บันทึก",
      dataIndex: "icreateUserFullName",
      key: "icreateUserFullName",
      width: 150,
      render: (value: any, item: ConcludeWorksheetData) => (<>{item.tworksheetMovementView.icreateUserFullName}</>)
    },
  ];

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    initData();
  }, []);

  const initData = async () => {
    form.setFieldsValue({
      entryDateFrom: moment(new Date(), Constants.DATE_FORMAT),
      entryDateTo: moment(new Date(), Constants.DATE_FORMAT),
      customercode: undefined,
      worksheetcode: undefined,
    });
  }

  function onReset() {
    initData();
  }

  const handleSearchCustomer = async (data: any) => {
    if (data.length < 3) return;
    const res = await tCustomerHeadService.getCustomerByParam(data);
    setCustomers(res.data);
  };

  const onItemCliked = (item: ConcludeWorksheetData) => {
    history.replace("/app/concludeWorksheet/view/" + item.tworksheetHeadView.iworksheetCode);
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    let entryDateFrom = moment(values.entryDateFrom).format("yyyyMMDD");
    let entryDateTo = moment(values.entryDateTo).format("yyyyMMDD");
    const customercode = values.customercode ? values.customercode : "";
    const worksheetcode = values.worksheetcode ? values.worksheetcode : "";

    const res = await concludeWorksheetService.findConcludeWorksheetData(
      entryDateFrom,
      entryDateTo,
      customercode,
      worksheetcode);
    setConcludeWorksheetDatas(res.data);
    setLoading(false);
  }

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
    form.resetFields();
  };

  return (
    <>
      <PageHeader title="โปรแกรมตัดจบใบงาน">
        <Button
          size="small"
          type="primary"
          icon={<PlusSquareOutlined />}
          onClick={() => setOnDrawerVisible(true)}
        >
          เพิ่ม
        </Button>
      </PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="concludeWorksheet"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
            }}
          >
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>วันที่ตัดจบ ตั้งแต่: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="entryDateFrom"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ชื่อลูกค้า: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item name="customercode" rules={[{ required: false }]}>
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกลูกค้า"
                    optionFilterProp="children"
                    filterOption={false}
                    onSearch={handleSearchCustomer}
                  >
                    {customers.map((obj) => {
                      return (
                        <Option key={obj.icustCode} value={obj.icustCode}>
                          {obj.iprenameName}{obj.iname}  {obj.isurname ? obj.isurname : ""}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ถึง: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="entryDateTo"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เลขที่ใบงาน: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="worksheetcode"
                  rules={[{ required: false }]}
                >
                  <Input
                    style={{ marginRight: 5 }}
                  >
                  </Input>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: 'center',
              paddingBottom: "1em",
              borderBlockEnd: "2px solid #c9c9c9",
            }}
          >
            <Space>
              <Button
                size="small"
                type="primary"
                icon={<SearchOutlined />}
                onClick={form.submit}
              >
                ค้นหา
              </Button>
              <Button
                size="small"
                type="primary"
                icon={<ClearOutlined />}
                onClick={onReset}
              >
                ล้าง
              </Button>
            </Space>
          </div>
        </Form>
        <div>
          <Table
            columns={columns}
            dataSource={concludeWorksheetDatas}
            size="small"
            loading={loading}
            scroll={{ x: 2000 }}
            pagination={{
              pageSize: 7,
              onChange(current) {
                setPage(current);
              }
            }}
            rowKey={(item: ConcludeWorksheetData) => item.tworksheetHeadView.iworksheetCode!}
            onRow={(item: ConcludeWorksheetData) => {
              return {
                onDoubleClick: () => onItemCliked(item),
              };
            }}
          />
        </div>
        <div>
          {onDrawerVisible && (
            <ConcludeWorksheetDrawer
              onDrawerVisible={onDrawerVisible}
              setOnDrawerVisible={setOnDrawerVisible}
            ></ConcludeWorksheetDrawer>
          )}
        </div>
      </Content>
    </>
  )
}

export default ConcludeWorksheetList