import { Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { TWshConstructionworkView } from 'src/domain/worksheet/view/tWshConstructionworkView';
import { WorksheetHeadData } from 'src/domain/worksheet/view/worksheetHeadData';
import tAssetHeadService from 'src/services/tasset/tAssetHeadService';
import tWorksheetDetailService from 'src/services/tWorksheetDetailService';
import tWorksheetHeadService from 'src/services/tWorksheetHeadService';
import { formatNumber } from 'src/utils/numberUtils';

interface Iprops {
  worksheetHeadData: WorksheetHeadData;
}

const ContractorWorkDetailViewTab: React.FC<Iprops> = (props) => {
  const { worksheetHeadData } = props;
  const [twshConstructionworkViewList, setTwshConstructionworkViewList] = useState<TWshConstructionworkView[]>([]);
  const [sumConstructionworkAmt, setSumConstructionworkAmt] = useState(0);

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      key: "index",
      align: "center" as "center",
      width: 20,
      render: (value: any, item: TWshConstructionworkView, index: number) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: "เลขที่ใบสั่งงาน",
      dataIndex: "worksheetConstruction",
      key: "worksheetConstruction",
      width: 150,
    },
    {
      title: "รถ",
      dataIndex: "truckFullName",
      key: "truckFullName",
      width: 200,
    },
    {
      title: "พนักงานขับรถ",
      dataIndex: "driverName",
      key: "driverName",
      width: 150,
    },
    {
      title: "ราคาบริการ",
      dataIndex: "remainAmt",
      key: "remainAmt",
      align: "right" as "right",
      with: 150,
      render: (value: any, item: TWshConstructionworkView, index: number) => {
        return <span>{formatNumber(item.total1Amt)}</span>;
      },
    },
    {
      title: "งาน",
      dataIndex: "serviceName",
      key: "serviceName",
    },
  ];

  useEffect(() => {
    if (worksheetHeadData.tworksheetConstructionworkViewList) {
      init();
    }
  }, []);

  const init = async () => {
    const temps: Array<TWshConstructionworkView> = [];
    const sumConAmt = worksheetHeadData.sumConstructionWorkAmt ? worksheetHeadData.sumConstructionWorkAmt : 0;;
    if (worksheetHeadData.tworksheetConstructionworkViewList && worksheetHeadData.tworksheetConstructionworkViewList.length > 0) {
      await Promise.all(
        worksheetHeadData.tworksheetConstructionworkViewList.map(async (obj) => {
          const tWorksheetHeadRes = await tWorksheetHeadService.getByWorksheetCode(obj.worksheetConstruction);
          let refWorksheetHead = tWorksheetHeadRes.data;

          const assetHeadRes = await tAssetHeadService.getTAssetHeadViewById(refWorksheetHead.itruckCode);
          let refAssetHead = assetHeadRes.data;

          const tworksheetDetailRes = await tWorksheetDetailService.getByReserveCode(refWorksheetHead.ireserveCode);
          let refWorksheetDetailFirst = tworksheetDetailRes.data[0];

          const view: TWshConstructionworkView = { ...obj };
          const newObj = {
            ...(view as TWshConstructionworkView),
            truckFullName: refAssetHead.assetFullName,
            driverName: refWorksheetHead.idriverName,
            //remainAmt: refWorksheetHead.iremainAmt,
            remainAmt: refWorksheetHead.itotal2Amt,
            serviceName: refWorksheetDetailFirst.iserviceName,
          }
          temps.push(newObj);
          // sumConAmt += refWorksheetHead.iremainAmt;
        })
      );

      // setTwshConstructionworkViewList(temps);
      setTwshConstructionworkViewList([...worksheetHeadData.tworksheetConstructionworkViewList])
      setSumConstructionworkAmt(sumConAmt);
    }
  }

  return (
    <>
      <Typography.Title
        type="danger"
        level={4}
        style={{ height: "22px", textAlign: "right" }}
      >
        จำนวนเงิน :
        {formatNumber(sumConstructionworkAmt)}
      </Typography.Title>
      <div>
        <Table
          columns={columns}
          scroll={{ x: 1000 }}
          dataSource={twshConstructionworkViewList}
          rowKey={(item) => item.id}
          size="small"
          pagination={false}
        />
      </div>
    </>
  )
}

export default ContractorWorkDetailViewTab;