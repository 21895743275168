import { Modal, Table, Typography } from 'antd';
import React from 'react'
import { AssetWorksheet } from 'src/domain/chart/assetWorksheet';
import { TWorksheetHead } from 'src/domain/worksheet/tWorksheetHead';
import { formatTime, toThDate } from 'src/utils/dateTimeUtils';


interface ModalProp {
	visibleWorksheetListByAsset: boolean;
	setVisibleWorksheetListByAsset: any;
	selcteAssetWshList: TWorksheetHead[];
}

const WorksheetListByAssetModal: React.FC<ModalProp> = (props) => {
	const { visibleWorksheetListByAsset, setVisibleWorksheetListByAsset, selcteAssetWshList } = props;
  const [page, setPage] = React.useState(1);
  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 50,
      
      render: (value: number, item: TWorksheetHead, index: number) => {
        if(item.ireserveCode) {
        // (<>{(page - 1) * 5 + index + 1}</>)
        return <span>{(page - 1) * 5 + index + 1}</span>;
        } else {
          return <span>{""}</span>;
        }
      }
    },
    {
      title: "วัน/เวลาที่ทำงาน",
      dataIndex: "istartDate",
      key: "istartDate",
      width: 200,
      render: (value: any, item: TWorksheetHead) => {
        if(item.ireserveCode) {
          return <span>{item.istartDate ? toThDate(item.istartDate) : ""} : {item.istartTime ? formatTime(item.istartTime) : ""} - {item.iendDate ? toThDate(item.iendDate) : ""} : {item.iendTime ? formatTime(item.iendTime) : ""}</span>;
        } else {
          return <span>{""}</span>;
        }
      },
    },
    {
      title: "เลขที่ใบจอง",
      dataIndex: "ireserveCode",
      key: "ireserveCode",
      width: 100,
    },
    {
      title: "เลขที่ใบงาน",
      dataIndex: "iworksheetCode",
      key: "iworksheetCode",
      width: 100,
    },
    {
      title: "ลูกค้า",
      dataIndex: "icustName",
      key: "icustName",
      width: 200,
    },
  ];
  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>รายการใบงาน</Typography.Title>
          </div>
        }
        visible={visibleWorksheetListByAsset}
        width={"70%"}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setVisibleWorksheetListByAsset(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >

        <div style={{ margin: 0, minHeight: 350 }}>
          <Table
            columns={columns}
            // loading={loading}
            // rowClassName={getItemColor}
            scroll={{ y: 360 }}
            dataSource={selcteAssetWshList}
            size="small"
            pagination={false}
            rowKey={(item: TWorksheetHead) => item.ireserveCode!}
          ></Table>
        </div>
      </Modal>
    </>
  )
}

export default WorksheetListByAssetModal