import http from "../../lib/http";
import Constants from "../../utils/constants";

const getBsPostcodeHList = () => {
    return http.get(Constants.DEFAULT_URL + "/bsPostcodeH");
};

const getBsPostcodeHs = (searchText: string) => {
    return http.get(Constants.DEFAULT_URL + "/bsPostcodeH/search", {
        params: {
            searchText
        }
    });
};

const getBsPostcodeHByNumberAndProvinceId = (searchText: string, iprovinceId: number) => {
    return http.get(Constants.DEFAULT_URL + "/bsPostcodeH/searchByProvinceId", {
        params: {
            searchText,
            iprovinceId,
        }
    });
};

const defaultApi = {
    getBsPostcodeHList: getBsPostcodeHList,
    getBsPostcodeHs: getBsPostcodeHs,
    getBsPostcodeHByNumberAndProvinceId: getBsPostcodeHByNumberAndProvinceId
}
export default defaultApi;