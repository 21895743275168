import { Form, Input, Modal, Select, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { TEmployee2 } from 'src/domain/master/tEmployee2';
import { TAssetHeadView } from 'src/domain/master/view/tAssetHeadView';
import { TEmployee2View } from 'src/domain/master/view/tEmployee2View';
import { TUserHead } from 'src/domain/tUserHead';
import { TWorksheetHead } from 'src/domain/worksheet/tWorksheetHead';
import { WorksheetHeadData } from 'src/domain/worksheet/view/worksheetHeadData';
import tEmployee2Service from 'src/services/employee/tEmployee2Service';
import tAssetHeadService from 'src/services/tasset/tAssetHeadService';
import worksheetService from 'src/services/worksheetService';
import { useAppSelector } from 'src/stores/store';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';

const { Option } = Select;

interface IProps {
  editWorksheetAssetModalVisible: boolean;
  setEditWorksheetAssetModalVisible: any;
  // setUpdateStatus: any;
  worksheetHeadData: WorksheetHeadData;
  setWorksheetHeadData: any;
  tUserHeadUpdate: TUserHead;
}
const EditWorksheetAssetModal: React.FC<IProps> = (props) => {
  const { editWorksheetAssetModalVisible, setEditWorksheetAssetModalVisible, worksheetHeadData, setWorksheetHeadData, tUserHeadUpdate } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [assets, setAssets] = useState<TAssetHeadView[]>([]); //001 รถ,002 ตู้,003 แผ่นเหล็ก
  const [drivers, setDrivers] = useState<TEmployee2[]>([]);
  const [employees, setEmployees] = useState<TEmployee2[]>([]);//ผู้แจ้ง
  const [form] = Form.useForm();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  useEffect(() => {
    form.setFieldsValue({
      truckCode: worksheetHeadData.tworksheetHead.itruckCode,
      drivercode: worksheetHeadData.tworksheetHead.idriverName,
      promissnumber: worksheetHeadData.twshPromiss?.promissnumber,
      allInstalment: worksheetHeadData.twshPromiss?.instalment,
    });

    loadData();
    return () => { };
  }, []);

  const loadData = async () => {
    if (editWorksheetAssetModalVisible) {
      const driverRes = await tEmployee2Service.getDriverByName(worksheetHeadData.tworksheetHead.idriverCode as string);
      const assetSelectedRes = await tAssetHeadService.getTAssetHeadViewById(worksheetHeadData.tworksheetHead.itruckCode as string);
      const assetRes = await tAssetHeadService.getAssetByNameNumberDriverList(assetSelectedRes.data.numbers, userInfo.branchId);

      setDrivers(driverRes.data);
      setAssets(assetRes.data);
    }
  };

  const handleSearchAsset = async (data: any) => {
    if (data.length < 3) return;
    const branch: string = userInfo.branchId;
    const res = await tAssetHeadService.getAssetByNameNumberDriverList(data, branch);
    setAssets(res.data);
  };

  const handleSelectAsset = async (assetCode: any) => {
    const findAsset = (assets as Array<TAssetHeadView>).find((obj) => obj.id === assetCode);
    if(findAsset?.assettype !== "002" && findAsset?.assettype !== "003" && findAsset?.assettype !== "006") {
      const driverRes = await tEmployee2Service.getDriverByEmpCode(findAsset?.truckdrivercode as string);
      setDrivers(driverRes.data);

      form.setFieldsValue({
        truckCode: findAsset?.id,
        drivercode: findAsset?.truckdrivercode,
        driverName: findAsset?.truckdriverName,
      });
    } else {
      form.setFieldsValue({
        truckCode: findAsset?.id,
        drivercode: "",
        driverName: "",
      });
    }
  };

  const handleSearchDriver = async (data: any) => {
    if (data.length < 3) return;
    const res = await tEmployee2Service.getDriverByName(data);
    setDrivers(res.data);
  };

  const handleSearchEmployee = async (data: any) => {
    if (data.length < 3) return;
    const res = await tEmployee2Service.getEmployee2ByParam(data);
    setEmployees(res.data);
  };

  //TODO AT API side
  const onFinish = async (values: any) => {
    const selectedAsset = (assets as Array<TAssetHeadView>).find((obj) => obj.id === values.truckCode);
    const checkAddDataRes = await worksheetService.isExistCreateWorksheet(worksheetHeadData.tworksheetHead.istartDate,
      worksheetHeadData.tworksheetHead.istartTime,
      worksheetHeadData.tworksheetHead.iendDate,
      worksheetHeadData.tworksheetHead.iendTime,
      selectedAsset?.id,
      "");
    if (checkAddDataRes.data) {
      showNotification(NotificationType.ERROR, "มีการสร้างรายการจองไว้แล้ว" + <br /> + "กรุณาดูที่โปรแกรมยืนยันใบจองหรือโปรแกรมพิมพ์ซ้ำก่อนการบันทึกครั้งต่อไป");
      setEditWorksheetAssetModalVisible(false);
      setTrigger(false);
    }  else if (worksheetHeadData.tworksheetHead.itruckCode === values.truckCode) {
      showNotification(NotificationType.WARNING, "ไม่มีการเปลี่ยนแปลง รถ/ตู้/แผ่นเหล็ก");
      setTrigger(false);
    } else {
      const employeeArr = employees as Array<TEmployee2View>;
      const empSelected = employeeArr.find((obj) => obj.id === values.userInfoId);
      const driverArr = drivers as Array<TEmployee2>;
      const driverSelected = driverArr.find((obj) => obj.id === values.drivercode);
      const temp = {
        ...(worksheetHeadData as WorksheetHeadData),
        tworksheetHead: {
          ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
          itruckCode: values.truckCode,
          idriverCode: values.drivercode,
          idriverName: driverSelected?.empname,
          isavStation: userInfo.saveStation,
          ilastUser: tUserHeadUpdate.iuserId,
          itermNo: userInfo.termNo,
        },
        updateUser: tUserHeadUpdate,
      };
      setWorksheetHeadData(temp);

      try {
        await worksheetService.updateWorksheetWithSubMenuKey(temp, values.userRemark, empSelected ? empSelected.empname : "", "MENU_WORKSHEET_ASSET");
        showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเรียบร้อย");
        setEditWorksheetAssetModalVisible(false);
        // setUpdateStatus(true);
        form.resetFields();
        setTrigger(false);
      } catch (err) {
        showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        setTrigger(false);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาตรวจสอบข้อมูลให้ครบถ้วน!");
    setTrigger(false);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>แก้ไขรายการรถ/ตู้/แผ่นเหล็ก</Typography.Title>
          </div>
        }
        visible={editWorksheetAssetModalVisible}
        width={"50%"}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setEditWorksheetAssetModalVisible(false)}
      >
        <Form
          form={form}
          name="worksheetAsset"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="รถ/ตู้/แผ่นเหล็ก"
            name="truckCode"
            rules={[{ required: true, message: "กรุณาระบุ รถ/ตู้/แผ่นเหล็ก !" }]}
          >
            <Select
              showSearch
              allowClear
              size="middle"
              placeholder="เลือก รถ/ตู้/แผ่นเหล็ก"
              optionFilterProp="children"
              filterOption={true}
              onSearch={handleSearchAsset}
              onSelect={handleSelectAsset}
            >
              {assets.map((obj) => {
                return (
                  <Option
                    key={obj.assetFullName}
                    value={obj.id}
                  >
                    {obj.id} - {obj.assetFullName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="พนักงานขับรถ"
            name="drivercode"
          >
            <Select
              // showSearch
              // allowClear
              size="middle"
              placeholder="เลือกพนักงานขับรถ"
              optionFilterProp="children"
              disabled
              // filterOption={true}
              // onSearch={handleSearchDriver}
            >
              {drivers.map((obj) => {
                return (
                  <Option
                    key={obj.id}
                    value={obj.id}
                  >
                    {obj.empname}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="เลขที่สัญญา"
            name="promissnumber">
            <Input
              readOnly={true}
              style={{
                width: "50%",
                backgroundColor: "#F8FC9D",
              }}
            ></Input>
          </Form.Item>
          <Form.Item
            label="จำนวน"
            name="allInstalment">
            <Input
              readOnly={true}
              style={{
                width: "50%",
                marginRight: 5,
                backgroundColor: "#F8FC9D",
              }}
            ></Input>เดือน/วัน
          </Form.Item>
          <Form.Item
            label="ผู้แจ้ง"
            name="userInfoId"
            rules={[{ required: true, message: "กรุณาระบุ ผู้แจ้ง!" }]}
          >
            <Select
              showSearch
              allowClear
              size="middle"
              placeholder="เลือกผู้แจ้ง"
              optionFilterProp="children"
              filterOption={true}
              onSearch={handleSearchEmployee}
            >
              {employees.map((obj) => {
                return (
                  <Option key={obj.id} value={obj.id}>
                    {obj.empname}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="หมายเหตุ"
            name="userRemark"
            rules={[{ required: true, message: "กรุณาระบุเหตุผล !" },
            {
              validator: (_, value) => {
                if (value <= 10) {
                  return Promise.reject(showNotification(NotificationType.ERROR, "กรุณาระบุเหตุผล ให้มากกว่า 10 ตัวอักษร"))
                } else {
                  return Promise.resolve()
                }
              },
            },
            ]}
          >
            <TextArea
              rows={4}
            ></TextArea>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default EditWorksheetAssetModal