import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Form, Input, Modal } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TBankHead } from "src/domain/master/tBankHead";
import { useAppSelector } from "src/stores/store";
import { TBankBranchHead } from "../../domain/master/tBankBranchHead";
import tBankBranchHeadService from "../../services/bank/tBankBranchHeadService";
import { NotificationType, showNotification } from "../../utils/notificationUtils";

const { confirm } = Modal;

interface IParams {
  ibankCode: string;
  ibankBranchCode: string;
  bankHead: TBankHead;
  visibleBankBranchHeadModal: boolean;
  setVisibleBankBranchHeadModal: any;
}

const AddEditBankBranchHeadModal: React.FC<IParams> = (props) => {
  const { ibankCode, ibankBranchCode, bankHead, visibleBankBranchHeadModal, setVisibleBankBranchHeadModal, } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [bankBranchHead, setBankBranchHead] = useState<TBankBranchHead>({} as TBankBranchHead);
  const [form] = Form.useForm();
  const history = useHistory();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  let title: string = "เพิ่มข้อมูลสาขา";
  if (ibankBranchCode) {
    title = "แก้ไขข้อมูลสาขา";
  }

  useEffect(() => {
    if (trigger) {
      form.validateFields()
        .then(() => {
          confirm({
            title: "ยืนยัน!",
            icon: <ExclamationCircleOutlined />,
            content: "คุณต้องการที่จะบันทึกข้อมูลหรือไม่?",
            cancelText: "ยกเลิก",
            okText: "ยืนยัน",
            onOk() {
              form.submit();
            },
            onCancel() {
              setTrigger(false);
            }
          });
        }).catch(() => {
          setTrigger(false);
        });
    }
  }, [trigger]);

  useEffect(() => {
    loadBankBranchHead();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      id: bankBranchHead.id,
      ibankCode: bankHead.ibankCode,
      ibankBranchCode: bankBranchHead.ibankBranchCode,
      ibankBranchName: bankBranchHead.ibankBranchName,
      ibankBranchTel: bankBranchHead.ibankBranchTel,
      ibankBranchAddress: bankBranchHead.ibankBranchAddress,
    });
  }, [bankBranchHead]);

  const loadBankBranchHead = async () => {
    if (ibankBranchCode) {
      const res = await tBankBranchHeadService.getByBankCodeAndBankBranchCode(ibankCode, ibankBranchCode);
      setBankBranchHead(res.data);
    } else {
      setBankBranchHead({ ...bankBranchHead, ibankCode: bankHead.ibankCode });
    }
  };

  const onFinish = async (values: any) => {
    const _bankBranchHead: TBankBranchHead = {
      id: bankBranchHead.id,
      ibankCode: bankHead.ibankCode,
      ibankBranchCode: values.ibankBranchCode,
      ibankBranchName: values.ibankBranchName,
      ibankBranchTel: values.ibankBranchTel,
      ibankBranchAddress: values.ibankBranchAddress,
      isavStation: userInfo.saveStation,
      icreateUser: userInfo.userId,
      ilastUser: userInfo.userId,
      itermNo: userInfo.termNo
    };

    if (!ibankBranchCode) {
      try {
        const res = await tBankBranchHeadService.postBankBranchHead(_bankBranchHead);
        showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลสาขาเรียบร้อยแล้ว!");
        form.resetFields();
        setTrigger(false);
        setVisibleBankBranchHeadModal(false);
        history.replace("/app/bankHead/view/" + bankHead.ibankCode);
      } catch (err) {
        showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        setTrigger(false);
      }
    } else {
      try {
        await tBankBranchHeadService.updateBankBranchHead(_bankBranchHead);
        showNotification(NotificationType.SUCCESS, "แก้ไขข้อมูลสาขาเรียบร้อยแล้ว!");
        form.resetFields();
        setTrigger(false);
        setVisibleBankBranchHeadModal(false);
        history.replace("/app/bankHead/view/" + bankHead.ibankCode);
      } catch (err) {
        showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        setTrigger(false);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    setTrigger(false);
  };

  return (
    <>
      <Modal
        title={title}
        cancelText={"ยกเลิก"}
        okText={"บันทึก"}
        visible={visibleBankBranchHeadModal}
        width={700}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setVisibleBankBranchHeadModal(false)}
      >
        <Content className="app-page-content">
          <Form
            form={form}
            name="bankBranchHead"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="รหัส"
              name="ibankCode"
              rules={[{ required: true }]}
            >
              <Input disabled={true} style={{ width: 100 }} />
            </Form.Item>
            <Form.Item
              label="รหัสสาขา"
              name="ibankBranchCode"
              rules={[{ required: true, message: "กรุณาใส่รหัสสาขา!" }]}
            >
              <Input style={{ width: 100 }} placeholder={"ป้อนรหัสสาขา"} maxLength={4} />
            </Form.Item>

            <Form.Item
              label="ชื่อสาขา"
              name="ibankBranchName"
              rules={[
                {
                  required: true,
                  message: "กรุณาใส่ชื่อสาขา!",
                },
                {
                  validator: (_, value) => {
                    if (value && (value === " " || value === "-")) {
                      return Promise.reject(new Error('กรุณาป้อนข้อมูลสาขาให้ถูกต้อง!'))
                    } else {
                      return Promise.resolve()
                    }
                  },
                },
              ]}
            >
              <Input maxLength={100} placeholder={"ป้อนชื่อสาขา"} />
            </Form.Item>

            <Form.Item
              label="ที่อยู่"
              rules={[{ required: false }]}
              name="ibankBranchAddress"
            >
              <Input.TextArea rows={3} maxLength={200} placeholder={"ป้อนที่อยู่"} />
            </Form.Item>
            <Form.Item
              label="เบอร์โทร"
              rules={[{ required: false }]}
              name="ibankBranchTel"
            >
              <Input maxLength={100} placeholder={"ป้อนเบอร์โทร"} />
            </Form.Item>
          </Form>
        </Content>
      </Modal>
    </>
  );
};

export default AddEditBankBranchHeadModal;
