import { Modal, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { TWorksheetMovementView } from "src/domain/worksheet/view/tWorksheetMovementView";
import { WorksheetHeadData } from "src/domain/worksheet/view/worksheetHeadData";
import tWorksheetMovementService from "src/services/tWorksheetMovementService";
import { formatTime, toThDate } from "src/utils/dateTimeUtils";

interface IProps {
  reportWorksheeMovementModalVisible: boolean;
  setReportWorksheeMovementModalVisible: any;
  worksheetHeadData: WorksheetHeadData;
}

const ReportWorksheeMovementModal: React.FC<IProps> = (props) => {
  const { reportWorksheeMovementModalVisible, setReportWorksheeMovementModalVisible, worksheetHeadData } = props;
  const [worksheetMovementViewList, setWorksheetMovementViewList] = useState<TWorksheetMovementView[]>();
  const [loading, setLoading] = React.useState(true);

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      key: "index",
      align: "center" as "center",
      width: 70,
      render: (value: any, item: TWorksheetMovementView, index: number) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: "สถานะใบสั่งงาน",
      dataIndex: "iworksheetStatusName",
      key: "iworksheetStatusName",
      width: 130,
    },
    {
      title: "หมายเหตุระบบ",
      dataIndex: "isystemRemark",
      key: "isystemRemark",
    },
    {
      title: "หมายเหตุผู้ใช้",
      dataIndex: "iuserRemark",
      key: "iuserRemark",
    },
    {
      title: "พนักงาน",
      dataIndex: "icreateUserFullName",
      key: "icreateUserFullName",
      width: 180,
    },
    {
      title: "วันที่",
      dataIndex: "icreateDateTime",
      key: "icreateDateTime",
      width: 150,
      render: (value: any, item: TWorksheetMovementView, index: number) => {
        return <span>{toThDate(item.ientryDate)}{" : "}{formatTime(item.ientryTime)}</span>;
      },
    },
  ];

  const getItemColor = (item: TWorksheetMovementView) => {
    let foreColor = "";
    if ("B" === item.icolorFlg) {
      foreColor = 'blue';
    } else if ("R" === item.icolorFlg) {
      foreColor = "red";
    } else {
      foreColor = "black";
    }

    return 'text-' + foreColor;
  }

  useEffect(() => {
    setLoading(true);
    loadData();
    return () => { };
  }, []);

  const loadData = async () => {
    const wshMovementViewRes = await tWorksheetMovementService.getByReserveCode(worksheetHeadData.tworksheetHead.ireserveCode!);
    if (wshMovementViewRes.data) {
      setWorksheetMovementViewList(wshMovementViewRes.data);
    }
    setLoading(false);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>ความเคลื่อนไหวใบสั่งงาน</Typography.Title>
          </div>
        }
        visible={reportWorksheeMovementModalVisible}
        width={"70%"}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setReportWorksheeMovementModalVisible(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >

        <div style={{ margin: 0, minHeight: 350 }}>
          <Table
            columns={columns}
            loading={loading}
            rowClassName={getItemColor}
            scroll={{ y: 360 }}
            dataSource={worksheetMovementViewList}
            size="small"
            pagination={false}
            rowKey={(item: TWorksheetMovementView) => item.iid}
          ></Table>
        </div>
      </Modal>
    </>
  )
}

export default ReportWorksheeMovementModal