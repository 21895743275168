import { UserInfo } from "src/domain/userInfo";
import Constants from "src/utils/constants";
import type { AppThunk } from "../store";
import { login, logout, validate } from "./authenSlice";
import { AuthenUser } from "./authenUser";

export const loginAction = (userInfo: UserInfo): AppThunk =>
  dispatch => {
    const now = Date.now();
    localStorage.setItem(Constants.AUTHEN_USER, JSON.stringify(userInfo));
    localStorage.setItem(Constants.AUTHEN_EXPIRED_TIME, JSON.stringify(now));

    const authenUser: AuthenUser = {
      user: userInfo,
      isLogin: true,
      expiredTime: now
    }
    dispatch(login(authenUser));
  };

export const logoutAction = (): AppThunk =>
  dispatch => {
    localStorage.removeItem(Constants.AUTHEN_USER);
    localStorage.removeItem(Constants.AUTHEN_EXPIRED_TIME);
    const authenUser: AuthenUser = {
      user: {} as UserInfo, isLogin: false, expiredTime: 0
    };
    dispatch(logout(authenUser));
  };

export const validateAction = (): AppThunk =>
  dispatch => {
    const userInfoObj = localStorage.getItem(Constants.AUTHEN_USER);
    const userInfo = userInfoObj ? JSON.parse(userInfoObj) as UserInfo : {} as UserInfo;
    const expiredTimeObj = localStorage.getItem(Constants.AUTHEN_EXPIRED_TIME);
    const expiredTime = expiredTimeObj ? Number(expiredTimeObj) : Date.now();
    const diffDate = (Date.now() - expiredTime) / (1000 * 60 * 60);

    const authenUser: AuthenUser = {
      user: userInfo,
      isLogin: false,
      expiredTime: expiredTime,
    };
    if (expiredTimeObj) {
      if (diffDate > 8) {// work hours
        logoutAction();
        return;
      } else {
        authenUser.isLogin = true;
        dispatch(validate(authenUser));
      }
    } else {
      dispatch(validate(authenUser));
    }
  };

