import { DatePicker, Form, Modal, Select, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { TEmployee2View } from 'src/domain/master/view/tEmployee2View';
import { TUserHead } from 'src/domain/tUserHead';
import { TWorksheetHead } from 'src/domain/worksheet/tWorksheetHead';
import { WorksheetHeadData } from 'src/domain/worksheet/view/worksheetHeadData';
import employeeService from 'src/services/employee/tEmployee2Service';
import worksheetService from 'src/services/worksheetService';
import { useAppSelector } from 'src/stores/store';
import Constants from 'src/utils/constants';
import { toMomentDateTime } from 'src/utils/dateTimeUtils';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {
  editWorktimeModalVisible: boolean;
  setEditWorktimeModalVisible: any;
  // setUpdateStatus: any;
  worksheetHeadData: WorksheetHeadData;
  setWorksheetHeadData: any;
  tUserHeadUpdate: TUserHead;
}

const EditWorktimeModal: React.FC<IProps> = (props) => {
  const { editWorktimeModalVisible, setEditWorktimeModalVisible, worksheetHeadData, setWorksheetHeadData, tUserHeadUpdate } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [employees, setEmployees] = useState<TEmployee2View[]>([]);//ผู้แจ้ง
  const [form] = Form.useForm();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  useEffect(() => {
    const start = toMomentDateTime(worksheetHeadData.tworksheetHead.istartDate ? worksheetHeadData.tworksheetHead.istartDate : "",
      worksheetHeadData.tworksheetHead.istartTime ? worksheetHeadData.tworksheetHead.istartTime : "");
    const end = toMomentDateTime(worksheetHeadData.tworksheetHead.iendDate ? worksheetHeadData.tworksheetHead.iendDate : "",
      worksheetHeadData.tworksheetHead.iendTime ? worksheetHeadData.tworksheetHead.iendTime : "");

    form.setFieldsValue({
      worktime: [start, end],
    });

    return () => { };
  }, []);

  const handleSearchEmployee = async (data: any) => {
    if (data.length < 3) return;
    const res = await employeeService.getEmployee2ByParam(data);
    setEmployees(res.data);
  };

  const chkTimeWork = async (worktime: any) => {
    let isExist = true;
    const std = worktime[0].format("yyyyMMDD");
    const ed = worktime[1].format("yyyyMMDD");
    const stt = worktime[0].format("HH0000");
    const et = worktime[1].format("HH0000");

    const checkAddDataRes = await worksheetService.isExistCreateWorksheet(std, stt, ed, et, worksheetHeadData.tassetHeadView?.id, "");
    if (checkAddDataRes.data) {
      showNotification(NotificationType.ERROR, "มีการสร้างรายการจองไว้แล้ว" + <br /> + "กรุณาดูที่โปรแกรมยืนยันใบจองหรือโปรแกรมพิมพ์ซ้ำก่อนการบันทึกครั้งต่อไป");
      isExist = false;
    }
    return isExist;
  }

  const onFinish = async (values: any) => {
    const employeeArr = employees as Array<TEmployee2View>;
    const empSelected = employeeArr.find((obj) => obj.id === values.userInfoId);
    const worktime = values.worktime;

    let std = worktime[0].format("yyyyMMDD");
    let ed = worktime[1].format("yyyyMMDD");
    let stt = worktime[0].format("HH0000");
    let et = worktime[1].format("HH0000");

    if (await chkTimeWork(worktime)) {
      const temp = {
        ...(worksheetHeadData as WorksheetHeadData),
        tworksheetHead: {
          ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
          istartDate: std,
          istartTime: stt,
          iendDate: ed,
          iendTime: et,
          isavStation: userInfo.saveStation,
          ilastUser: tUserHeadUpdate.iuserId,
          itermNo: userInfo.termNo,
        },
        updateUser: tUserHeadUpdate,
      };
      setWorksheetHeadData(temp);
      try {
        await worksheetService.updateWorksheetWithSubMenuKey(temp, values.userRemark, empSelected ? empSelected.empname : "", "MENU_WORKTIME");
        showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเรียบร้อย");
        setEditWorktimeModalVisible(false);
        // setUpdateStatus(true);
        form.resetFields();
        setTrigger(false);
      } catch (err) {
        showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        setTrigger(false);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
    showNotification(NotificationType.ERROR, "กรุณาตรวจสอบข้อมูลให้ครบถ้วน!");
    setTrigger(false);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>แก้ไขเวลาการทำงาน</Typography.Title>
          </div>
        }
        visible={editWorktimeModalVisible}
        width={"50%"}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setEditWorktimeModalVisible(false)}
      >
        <Form
          form={form}
          name="worksheetWorktime"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="ระหว่างวันที่"
            name="worktime"
            rules={[{ required: true, message: "กรุณาระบุ เวลาการทำงาน!" }]}
          >
            <RangePicker
              showTime={{ format: "HH:00:00" }}
              allowClear
              format={Constants.DATE_FORMAT_SKIP_SEC}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            label="ผู้แจ้ง"
            name="userInfoId"
            rules={[{ required: true, message: "กรุณาระบุ ผู้แจ้ง!" }]}
          >
            <Select
              showSearch
              allowClear
              size="middle"
              placeholder="เลือกผู้แจ้ง"
              optionFilterProp="children"
              filterOption={true}
              onSearch={handleSearchEmployee}
            >
              {employees.map((obj) => {
                return (
                  <Option key={obj.id} value={obj.id}>
                    {obj.empname}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="หมายเหตุ"
            name="userRemark"
            rules={[{ required: true, message: "กรุณาระบุเหตุผล !" }]}
          >
            <TextArea
              rows={4}
            ></TextArea>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default EditWorktimeModal