import { EditOutlined } from "@ant-design/icons";
import { Button, Descriptions, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { TCustomerHeadView } from "src/domain/master/view/tCustomerHeadView";
import PageHeader from "src/layouts/PageHeader";
import { getFlg } from "src/utils/rodyokUtils";
import tCustomerHeadService from "../../services/customer/tCustomerHeadService";
import AddEditCustomerHeadDrawer from "./AddEditCustomerHeadDrawer";

interface IParams {
    icustCode: string;
}

const ViewCustomerHead: React.FC<RouteComponentProps<IParams>> = (props) => {
    const icustCode = props.match.params.icustCode;
    const [customerHead, setCustomerHead] = useState<TCustomerHeadView>({} as TCustomerHeadView);
    const history = useHistory();
    const [visibleAddEditCustomerHeadDrawer, setVisibleAddEditCustomerHeadDrawer] = useState<boolean>(false);

    useEffect(() => {
        if (!visibleAddEditCustomerHeadDrawer) {
            initial();
        }
    }, [visibleAddEditCustomerHeadDrawer]);

    const initial = async () => {
        const res = await tCustomerHeadService.getCustomerByCode(icustCode);
        setCustomerHead(res.data);
    };

    const onEditButtonClicked = () => {
        setVisibleAddEditCustomerHeadDrawer(true);
    }

    return (
        <>
            <PageHeader title="" onClose={() => history.push("/app/customer/list")} />
            <Content className="app-page-content">
                <Descriptions
                    style={{ margin: "1em" }}
                    column={4}
                    title="ข้อมูลลูกค้า"
                    size="middle"
                    extra={
                        <Button
                            size="middle"
                            type="primary"
                            icon={<EditOutlined />}
                            onClick={onEditButtonClicked}

                        >
                            แก้ไข
                        </Button>
                    }
                />
                <div style={{ display: "flex", justifyContent: "flex-start", margin: "1em" }}>
                    <Descriptions
                        column={6}
                        size="middle"
                        bordered
                        labelStyle={{ textAlign: "right", fontWeight: "bold" }}
                    >
                        <Descriptions.Item label="รหัสลูกค้า" span={2}>{customerHead.icustCode}</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="เลขที่บัญชีลูกหนี้" span={2}>{customerHead.idebitorCode}</Descriptions.Item>
                        <Descriptions.Item label="ชื่อ-สกุล" span={6}>{customerHead.iprenameName} {customerHead.iname} {customerHead.isurname}</Descriptions.Item>
                        <Descriptions.Item label="เลขที่บัตรประชาชน" span={6}>{customerHead.iidCard}</Descriptions.Item>
                        <Descriptions.Item label="ที่อยู่/ที่ตั้ง" span={6}>{customerHead.iaddress}</Descriptions.Item>
                        <Descriptions.Item label="อำเภอ" span={2}>({customerHead.iamphurcode}) {customerHead.iamphurName}</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="จังหวัด" span={2}>({customerHead.iprovincecode}) {customerHead.iprovinceName}</Descriptions.Item>
                        <Descriptions.Item label="รหัสไปรษณีย์" span={6}>{customerHead.ipostcode}</Descriptions.Item>
                        <Descriptions.Item label="โทร" span={2}>{customerHead.iphone}</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="ต่อ" span={2}>{customerHead.phonecontinue}</Descriptions.Item>
                        <Descriptions.Item label="มือถือ" span={2}>{customerHead.imobile}</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="Fax" span={2}>{customerHead.ifax}</Descriptions.Item>
                        <Descriptions.Item label="Email" span={6}>{customerHead.iemail}</Descriptions.Item>
                        <Descriptions.Item label="การชำระเงิน" span={6}>({customerHead.itypepaymentCode}) {customerHead.itypepaymentName}</Descriptions.Item>
                        <Descriptions.Item label="บุคคลอ้างอิง" span={6}>{customerHead.icontractName}</Descriptions.Item>
                        <Descriptions.Item label="ส่วนลด" span={6}>{customerHead.idiscount}%</Descriptions.Item>
                        <Descriptions.Item label="สถานะ" span={6}>{getFlg(customerHead.idltFlg) ? <Typography.Text type="success">ใช้งาน</Typography.Text> : <Typography.Text type="danger">ไม่ใช้งาน</Typography.Text>}</Descriptions.Item>
                    </Descriptions>
                </div>
            </Content>
            <div> {
                visibleAddEditCustomerHeadDrawer && <AddEditCustomerHeadDrawer
                    icustCode={String(customerHead.icustCode)}
                    visibleAddEditCustomerHeadDrawer={visibleAddEditCustomerHeadDrawer}
                    setVisibleAddEditCustomerHeadDrawer={setVisibleAddEditCustomerHeadDrawer} />
            }</div>
        </>
    );
};

export default ViewCustomerHead;
