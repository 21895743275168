import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Row, Select, Table, Typography, Modal, Checkbox } from 'antd';
import moment from 'moment';
import Constants from 'src/utils/constants';
import PageHeader from 'src/layouts/PageHeader';
import { Content } from 'antd/lib/layout/layout';
import { useAppSelector } from 'src/stores/store';
import { PrinterOutlined, SearchOutlined } from '@ant-design/icons';
import reportService from 'src/services/reportService';
import { formatNumber, formatNumberInt } from 'src/utils/numberUtils';
import PdfViewPopup from './PdfViewPopup';
import { RyRefbranch } from 'src/domain/ryRefbranch';
import ryRefbranchService from 'src/services/reference/ryRefbranchService';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import employeeService from 'src/services/employee/tEmployee2Service';
import { TEmployee2View } from 'src/domain/master/view/tEmployee2View';
import { RangradSendFnReportData } from 'src/domain/worksheet/rangradSendFnReportData';

const { Option } = Select;

const RangradSendFnReport = () => {
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  const [ryRefBranchSelected, setRyRefBranchSelected] = useState<RyRefbranch>({} as RyRefbranch);
  const [ryRefBranchs, setRyRefBranchs] = useState<RyRefbranch[]>([]);  //สาขา
  const [employees, setEmployees] = useState<TEmployee2View[]>([]);     //ผู้แจ้ง

  const [selectDebitNote, setSelectDebitNote] = useState<boolean>(false);
  const [rangradSendFnList, setRangradSendFnList] = useState<RangradSendFnReportData[]>([]);
  const [rangradSendFnPreview, setRangradSendFnPreview] = useState<any>();

  const [visiblePDF, setVisiblePDF] = useState(false);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false);

  const [sumNetTotalAmt, setSumNetTotalAmt] = useState<number>(0);
  const [empIdParam, setEmpIdParam] = useState<any>();
  const [branchParam, setBranchParam] = useState<any>();
  const [fromDateParam, setFromDateParam] = useState<any>();
  const [toDateParam, setToDateParam] = useState<any>();

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 10,
      align: "center" as "center",
      render: (value: number, item: RangradSendFnReportData, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
    },
    {
      title: "เลขที่ใบแจ้งหนี้",
      dataIndex: "debitnoteNo",
      key: "debitnoteNo",
      width: 30,
    },
    {
      title: "ลูกหนี้",
      dataIndex: "debitorName",
      key: "debitorName",
      width: 210,
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "amount",
      key: "amount",
      width: 80,
      align: "right" as "right",
      render: (value: any, item: RangradSendFnReportData) => {
        return <span>{formatNumber(item.amount)}</span>;
      },
    },
    {
      title: "ส่วนลด",
      dataIndex: "discount",
      key: "discount",
      width: 80,
      align: "right" as "right",
      render: (value: any, item: RangradSendFnReportData) => {
        return <span>{formatNumber(item.discount)}</span>;
      },
    },
    {
      title: "ภาษี",
      dataIndex: "vat",
      key: "vat",
      width: 80,
      align: "right" as "right",
      render: (value: any, item: RangradSendFnReportData) => {
        return <span>{formatNumber(item.vat)}</span>;
      },
    },
    {
      title: "ใบลดหนี้",
      dataIndex: "creditTotal",
      key: "creditTotal",
      width: 80,
      align: "right" as "right",
      render: (value: any, item: RangradSendFnReportData) => {
        return <span>{formatNumber(item.creditTotal)}</span>;
      },
    },
    {
      title: "ส่งการเงิน",
      dataIndex: "netTotal",
      key: "netTotal",
      width: 90,
      align: "right" as "right",
      render: (value: any, item: RangradSendFnReportData) => {
        return <span>{formatNumber(item.netTotal)}</span>;
      },
    },
  ]

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });

    initData();
    form.submit();
  }, []);

  const handleSearchEmployee = async (data: any) => {
    if (data.length < 3) return;
    const res = await employeeService.getEmployee2ByParam(data);
    setEmployees(res.data);
  };

  const initData = async () => {
    const refbranchRes = await ryRefbranchService.getRyRefbranchList();
    setRyRefBranchs(refbranchRes.data);

    form.setFieldsValue({
      branch: "",
      userInfoId: "",
      fromDate: moment(new Date(), Constants.DATE_FORMAT).startOf('month'),
      toDate: moment(new Date(), Constants.DATE_FORMAT).endOf('month'),
      selectDebitNote: false,
    });
  }

  const onSelectDebitNote = (e: CheckboxChangeEvent) => {
    setSelectDebitNote(e.target.checked);    
  }

  const onFinish = async (values: any) => {
    setLoading(true);
    const branch = values.branch ? values.branch : "";
    const empId = values.userInfoId ? values.userInfoId : "";
    const fromDate = moment(values.fromDate).format("yyyyMMDD");
    const toDate = moment(values.toDate).format("yyyyMMDD");

    const res = await reportService.getRangradSendFnReport(branch, empId, fromDate, toDate, selectDebitNote);
    // console.log('----------res--------', res.data)

    setRangradSendFnList(res.data);
    setLoading(false);

    setPage(1);
    setBranchParam(branch);
    setEmpIdParam(empId);
    setFromDateParam(fromDate);
    setToDateParam(toDate);     
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  const onPrint = async () => {
    setVisiblePDF(true);
  }

  return (
    <>
      <PageHeader title="รายงานเร่งรัดส่งเงินให้การเงิน"></PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="rengradWangbill"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
            }}
          >
           
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>สาขา: </Typography.Text>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="branch"
                  style={{ marginBottom: "3px" }}
                >
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกสาขา"
                    optionFilterProp="children"
                    filterOption={true}
                    onClear={() => setRyRefBranchSelected({} as RyRefbranch)}
                  >
                    {ryRefBranchs.map((obj) => {
                      return (
                        <Option key={obj.id} value={obj.id}>
                          {obj.id}: {obj.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ผู้แจ้ง: </Typography.Text>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="userInfoId"
                  style={{ marginBottom: "3px" }}
                 >
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกผู้แจ้ง"
                    optionFilterProp="children"
                    filterOption={true}
                    onSearch={handleSearchEmployee}
                  >
                    {employees.map((obj) => {
                      return (
                        <Option key={obj.id} value={obj.id}>
                          {obj.empname}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>วันที่ตั้งแต่: </Typography.Text>
              </Col>
              <Col span={3}>
                <Form.Item
                  name="fromDate"
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={1} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ถึง: </Typography.Text>
              </Col>
              <Col span={3}>
                <Form.Item
                  name="toDate"
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={6}></Col>
              <Col span={3}>
                <Form.Item 
                  name="selectDocument" 
                  style={{ marginBottom: "3px" }}
                >
                  <Checkbox style={{ marginRight: "0.5em" }}
                    checked={selectDebitNote}
                    onChange={onSelectDebitNote}
                  >
                  </Checkbox>
                  <Typography.Text> ใบแจ้งหนี้ที่มีภาษี </Typography.Text>
                </Form.Item>
              </Col>
              <Col span={12}></Col>
            </Row>
            <Row>
              <Col span={13} style={{ textAlign: "right"}}>
                <Button
                  size="small"
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={form.submit}
                >
                  ค้นหา
                </Button>
              </Col>
              <Col span={8}></Col>
            </Row>
          </div>
        </Form>
           
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "0px 20px 10px 0px",
            borderBottom: '2px solid #c9c9c9'
          }}
        >
          <Button
          style={{
            marginLeft: "30px",}}
            size="small"
            type="primary"
            icon={<PrinterOutlined />}
            onClick={onPrint}
          >
            พิมพ์
          </Button>
          <Modal
            style={{ top: 20 }}
            width={"90vw"}
            title="RR002"
            bodyStyle={{ height: "85vh" }}
            centered={true}
            visible={isPreviewModalVisible}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={() => setIsPreviewModalVisible(false)}
          >
            <iframe title=" " src={rangradSendFnPreview} width={"100%"} height={"100%"}></iframe>
          </Modal>
        </div>

        <div>
          <Table
            columns={columns}
            dataSource={rangradSendFnList}
            size="small"
            loading={loading}
            rowKey={(item: RangradSendFnReportData) => item.debitnoteNo!}
            // scroll={{ x: 1500 }}
            pagination={{
              pageSize: 10,
              current: page,
              onChange(current) {
                setPage(current);
              }
            }}
            summary={(pageData: any) => {
              let netTotal = 0.0;
              rangradSendFnList.forEach((item) =>{
                netTotal += item.netTotal;
              });
              
              setSumNetTotalAmt(netTotal);

              return (
                <></>
              )
            }}
          />
        </div>

        <div style={{
            padding: "20px 0px 0px 0px"
          }}>
          <Row>
            <Col span={12}></Col>
            <Col span={6} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> รวมส่งการเงิน: </Typography.Text>
            </Col>
            <Col span={6} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold", color: "red" }}> {formatNumber(sumNetTotalAmt)} </Typography.Text>
              <Typography.Text style={{ fontWeight: "bold", margin: "0.5em" }}> บาท </Typography.Text>
            </Col>
          </Row>
        </div>
      </Content>

      <Modal
        title= 'print'
        centered
        closable={true}
        visible={visiblePDF}
        onCancel={() => setVisiblePDF(false)}
        width="400"
      >
        <PdfViewPopup
          urlPath={reportService.getRangradSendFnReportPreview(branchParam, empIdParam, fromDateParam, toDateParam, selectDebitNote)}
          onClose={() => setVisiblePDF(false)}
        />
      </Modal>
    </>
  )
}

export default RangradSendFnReport