import { Descriptions, Form, Space, Spin, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { TFinanceHead2View } from 'src/domain/finance/view/tFinanceHead2View';
import { TOtherpaymentHead } from 'src/domain/tOtherpaymentHead';
import PageHeader from 'src/layouts/PageHeader';
import otherPaymentHeadService from 'src/services/otherpayment/otherPaymentHeadService';
import { formatNumber } from 'src/utils/numberUtils';

interface IParams {
  tOtherpaymentHeadId: string;
}
const ViewFinanceOtherWorksheet: React.FC<RouteComponentProps<IParams>> = (props) => {
  const tOtherpaymentHeadId = props.match.params.tOtherpaymentHeadId;
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [tOtherPaymentHead, setTOtherPaymentHead] = useState<TOtherpaymentHead>({} as TOtherpaymentHead);
  const [tfinanceHead2View, setTfinanceHead2View] = useState<TFinanceHead2View>({} as TFinanceHead2View);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: TOtherpaymentHead, index: number) => (<>{index + 1}</>)
    },

    {
      title: "รหัสลูกค้า",
      dataIndex: "icustCode",
      key: "icustCode",
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "icustName",
      key: "icustName",
      width: "auto"
    },
    {
      title: "รหัสรายการ",
      dataIndex: "itypeotherwsh",
      key: "itypeotherwsh",
      width: 120,
      align: "center" as "center",
    },
    {
      title: "รายการชำระเงิน",
      dataIndex: "iremark",
      key: "iremark",
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "iserviceAmt",
      key: "iserviceAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TOtherpaymentHead) => {
        return <span>{formatNumber(item.iserviceAmt)}</span>;
      },
    },
    {
      title: "ภาษี",
      dataIndex: "itaxAmt",
      key: "itaxAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TOtherpaymentHead) => {
        return <span>{formatNumber(item.itaxAmt)}</span>;
      },
    },
    {
      title: "รวมทั้งหมด",
      dataIndex: "itotalAmt",
      key: "itotalAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TOtherpaymentHead) => {
        return <span>{formatNumber(item.itotalAmt)}</span>;
      },
    },
    {
      title: "หมายเหตุ",
      dataIndex: "icomment",
      key: "icomment",
      align: "center" as "center",
    },
  ];

  useEffect(() => {
    setLoading(true);
    (async () => {
      const res = await otherPaymentHeadService.getOtherPaymentHeadById(tOtherpaymentHeadId);
      const tFinanceHead2Res = await otherPaymentHeadService.getTFinanceHead2ViewByTOtherpaymentHeadId(tOtherpaymentHeadId);
      setTOtherPaymentHead(res.data);
      setTfinanceHead2View(tFinanceHead2Res.data);
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <PageHeader title="" onClose={() => history.push("/app/financeOtherWorksheet/list")} />
      <Spin spinning={loading}>
        <Content className="app-page-content">
          <Descriptions
            style={{ margin: "1em" }}
            column={4}
            title="ข้อมูลการเงินรับชำระจากรายการอื่นๆ"
            size="middle"
          />
          <div style={{ margin: "1em" }}>
            <Form
              form={form}
              name="viewFinanceOtherPaymentHead"
              labelCol={{ span: 10 }}
            >
              <Space direction="vertical" size="middle">
                <Descriptions
                  column={6}
                  size="small"
                  bordered
                  style={{ paddingLeft: '10em' }}
                  labelStyle={{ textAlign: "right", fontWeight: "bold" }}
                >
                  <Descriptions.Item label="ค่าบริการ" span={3}>{formatNumber(tOtherPaymentHead.iserviceAmt)} บาท</Descriptions.Item>
                  <Descriptions.Item label="เงินสด" span={3}>{formatNumber(tfinanceHead2View.cashamount)} บาท</Descriptions.Item>
                  <Descriptions.Item label="ภาษี" span={3}>{formatNumber(tfinanceHead2View.taxamount)} บาท</Descriptions.Item>
                  <Descriptions.Item label="เช็ค" span={3}>{formatNumber(tfinanceHead2View.chequeamount)} บาท</Descriptions.Item>
                  <Descriptions.Item label="หักภาษี" span={3}>{formatNumber(0)} บาท</Descriptions.Item>
                  <Descriptions.Item label="เงินโอน" span={3}>{formatNumber(tfinanceHead2View.payinamount)} บาท</Descriptions.Item>
                  <Descriptions.Item label="รวมทั้งหมด" span={6}>{formatNumber(tOtherPaymentHead.itotalAmt)} บาท</Descriptions.Item>
                </Descriptions>
                <Table
                  columns={columns}
                  dataSource={[tOtherPaymentHead]}
                  size="small"
                  rowKey={(item: TOtherpaymentHead) => item.id!}
                  pagination={false}
                />
              </Space>
            </Form>
          </div>
        </Content>
      </Spin>
    </>
  );
};

export default ViewFinanceOtherWorksheet;