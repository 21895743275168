import Constants from "src/utils/constants";
import http from "../../lib/http";

const getByFinanceHeadId = (financeHeadId: number) => {
  return http.get(Constants.DEFAULT_URL + "/tFinanceDetails/getTFinanceDetails", {
    params: {
      financeHeadId,
    }
  });
};

const getByWorksheetCode = (worksheetCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tFinanceDetails/getByWorksheetCode", {
    params: {
      worksheetCode,
    }
  });
};

const loadFnDetailForTaxInvoidByWorksheetCode = (worksheetCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tFinanceDetails/loadFnDetailForTaxInvoidByWorksheetCode", {
    params: {
      worksheetCode,
    }
  });
};

const isExistByWorksheetCode = (worksheetCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tFinanceDetails/isExistByWorksheetCode", {
    params: {
      worksheetCode,
    }
  });
};

const getByDebitNoteCode = (debitNoteCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tFinanceDetails/getByDebitNoteCode", {
    params: {
      debitNoteCode,
    }
  });
};

const loadFnDetailForTaxInvoidByDebitNoteCode = (debitNoteCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tFinanceDetails/loadFnDetailForTaxInvoidByDebitNoteCode", {
    params: {
      debitNoteCode,
    }
  });
};

const isExistByDebitNoteCode = (debitNoteCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tFinanceDetails/isExistByDebitNoteCode", {
    params: {
      debitNoteCode,
    }
  });
};
const defaultApi = {
  getByFinanceHeadId,
  getByWorksheetCode,
  isExistByWorksheetCode,
  getByDebitNoteCode,
  isExistByDebitNoteCode,
  loadFnDetailForTaxInvoidByWorksheetCode,
  loadFnDetailForTaxInvoidByDebitNoteCode
}
export default defaultApi;