import { CloseCircleOutlined, ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Drawer, Form, Input, InputNumber, Modal, Radio, Select, Spin, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { TEmployee2View } from 'src/domain/master/view/tEmployee2View';
import { TRangradWangbillHead } from 'src/domain/tRangradWangbillHead';
import { TWshtaxDetail } from 'src/domain/tWshtaxDetail';
import { ReturnForWitholdingTaxData } from 'src/domain/view/returnForWitholdingTaxData';
import { ReturnForWitholdingTaxView } from 'src/domain/view/returnForWitholdingView';
import { TWorksheetHead } from 'src/domain/worksheet/tWorksheetHead';
import tCustomerHeadService from 'src/services/customer/tCustomerHeadService';
import tDebitorService from 'src/services/debitor/tDebitorService';
import tEmployee2Service from 'src/services/employee/tEmployee2Service';
import returnForWitholdingTaxService from 'src/services/returnForWitholdingTaxService';
import tRangradWangbillHeadService from 'src/services/tRangradWangbillHeadService';
import tWorksheetHeadService from 'src/services/tWorksheetHeadService';
import tWshtaxDetailService from 'src/services/tWshtaxDetailService';
import { useAppSelector } from 'src/stores/store';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber } from 'src/utils/numberUtils';

const { Option } = Select;
const { confirm } = Modal;

interface IProps {
  onDrawerVisible: boolean;
  setOnDrawerVisible: any;
}

const ReturnForWitholdingTaxDrawer: React.FC<IProps> = (props) => {
  const { onDrawerVisible, setOnDrawerVisible } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [returnForWitholdingTaxData, setReturnForWitholdingTaxData] = useState<ReturnForWitholdingTaxData>({} as ReturnForWitholdingTaxData);
  const [returnForWitholdingTaxView, setReturnForWitholdingTaxView] = useState<ReturnForWitholdingTaxView>({} as ReturnForWitholdingTaxView);
  const [tworksheetHead, setTworksheetHead] = useState<TWorksheetHead>({} as TWorksheetHead);
  const [trangradWangbillHead, setTrangradWangbillHead] = useState<TRangradWangbillHead>({} as TRangradWangbillHead);
  const [twshtaxDetail, setTwshtaxDetail] = useState<TWshtaxDetail>({} as TWshtaxDetail);
  const [employees, setEmployees] = useState<TEmployee2View[]>([]);//ผู้แจ้ง

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (trigger) {
      form.validateFields()
        .then(() => {
          confirm({
            title: "ยืนยัน!",
            icon: <ExclamationCircleOutlined />,
            content: "ต้องการบันทึกการยกเลิกขอหักภาษี ณ ที่จ่ายหรือไม่",
            cancelText: "ยกเลิก",
            okText: "ยืนยัน",
            onOk() {
              form.submit();
            },
            onCancel() {
              setTrigger(false);
            }
          });
        }).catch(() => {
          setTrigger(false);
        });
    }
  }, [trigger]);

  const initData = async () => {
    form.setFieldsValue({
      returnType: "WSH_CODE",
      code: undefined,
      idToAskWitholdingTax: undefined,
      custName: undefined,
      serviceAmt: 0,
      vatAmt: 0,
      witholdingTaxType: undefined,
      witholdingTaxAmount: 0,
      totalAmount: 0,
      witholdingTaxCreateUserFullName: undefined,
      userInfoId: undefined,
      returnRemark: undefined,
    });
  }

  const onChangeReturnType = () => {
    setReturnForWitholdingTaxData({} as ReturnForWitholdingTaxData);
    form.setFieldsValue({
      code: undefined,
      idToAskWitholdingTax: undefined,
      custName: undefined,
      serviceAmt: 0,
      vatAmt: 0,
      witholdingTaxType: undefined,
      witholdingTaxAmount: 0,
      totalAmount: 0,
      witholdingTaxCreateUserFullName: undefined,
      userInfoId: undefined,
      returnRemark: undefined,
    });
  }

  const handleSearchEmployee = async (data: any) => {
    if (data.length < 3) return;
    const res = await tEmployee2Service.getEmployee2ByParam(data);
    setEmployees(res.data);
  };

  const dispayData = async (wshTaxdetail: TWshtaxDetail, rturnType: string) => {
    let wshCodeDebitnoteCode = "";
    let idToAskWitholdingTax = undefined;
    let custName = "";
    let serviceAmt = 0;
    let vatAmt = 0;
    let witholdingTaxType = "";
    let witholdingTaxAmount = 0;
    let totalAmount = 0;
    let witholdingTaxCreateUserFullName = "";

    if (wshTaxdetail.customercode) {
      const custRes = await tCustomerHeadService.getCustomerByCustcode(wshTaxdetail.customercode.trim())
      custName = custRes.data.iname;
    } else {
      const debitorRes = await tDebitorService.getDebitorHeadById(wshTaxdetail.debitorcode.trim())
      custName = debitorRes.data.iname;
    }

    wshCodeDebitnoteCode = (rturnType === "WSH_CODE") ? wshTaxdetail.worksheetcode : wshTaxdetail.debitnotecode;
    idToAskWitholdingTax = wshTaxdetail.id;
    serviceAmt = wshTaxdetail.worksheetamt + wshTaxdetail.debitnoteamt;
    vatAmt = Math.round((serviceAmt * 7 / 107) * 100) / 100;
    totalAmount = serviceAmt - vatAmt;
    witholdingTaxAmount = wshTaxdetail.taxamount;
    witholdingTaxType = wshTaxdetail.taxtypeName + " : " + wshTaxdetail.taxpercent + " %"
    witholdingTaxCreateUserFullName = wshTaxdetail.createuserFullname!;

    form.setFieldsValue({
      code: wshCodeDebitnoteCode,
      idToAskWitholdingTax: idToAskWitholdingTax,
      custName: custName,
      serviceAmt: serviceAmt,
      vatAmt: vatAmt,
      witholdingTaxType: witholdingTaxType,
      witholdingTaxAmount: witholdingTaxAmount,
      totalAmount: totalAmount,
      witholdingTaxCreateUserFullName: witholdingTaxCreateUserFullName,
    });
  }

  const handleSelectCode = async () => {
    const rturnType = form.getFieldValue("returnType"); //WSH_CODE,DEBITNOTE_CODE
    const code = form.getFieldValue("code");
    if (rturnType === "WSH_CODE") {
      const worksheetRes = await tWorksheetHeadService.getByWorksheetCode(code);
      const worksheetHead: TWorksheetHead = worksheetRes.data;
      const wshTaxdetailWshRes = await tWshtaxDetailService.getByWorksheetCode(code);
      const wshTaxdetailWsh: TWshtaxDetail = wshTaxdetailWshRes.data;
      if (!worksheetHead) {
        showNotification(NotificationType.ERROR, "ไม่พบข้อมูลใบงาน เลขที่ " + code);
        return;
      } else if (worksheetHead && worksheetHead.ipaymentStatus !== "00") {
        showNotification(NotificationType.ERROR, "ใบงานนี้ชำระเงินแล้วไม่สามารถยกเลิกการหักภาษี ณ ที่จ่ายได้");
        return;
      } else if (worksheetHead && !wshTaxdetailWshRes.data) {
        showNotification(NotificationType.ERROR, "ไม่พบข้อมูลการหักภาษี ณ ที่จ่ายของ ใบงานเลขที่ " + worksheetHead.iworksheetCode);
      } else {
        setTworksheetHead(worksheetHead);
        setTwshtaxDetail(wshTaxdetailWsh);
        dispayData(wshTaxdetailWsh, rturnType);
      }
    } else {//DEBITNOTE_CODE
      const rdHeadRes = await tRangradWangbillHeadService.getByDebitNoteNo(code);
      const rdHead: TRangradWangbillHead = rdHeadRes.data;
      const wshTaxdetailDebitRes = await tWshtaxDetailService.getByDebitNoteCode(code);
      const wshTaxdetailDebit: TWshtaxDetail = wshTaxdetailDebitRes.data;
      if (!rdHead) {
        showNotification(NotificationType.ERROR, "ไม่พบข้อมูลใบแจ้งหนี้ เลขที่ " + code);
        return;
      } else if (rdHead && rdHead.idebitnotestatus === "99") {
        showNotification(NotificationType.ERROR, "ใบแจ้งหนี้นี้ชำระเงินแล้วไม่สามารถยกเลิกการหักภาษี ณ ที่จ่ายได้");
        return;
      } else if (rdHead && !wshTaxdetailDebitRes.data) {
        showNotification(NotificationType.ERROR, "ไม่พบข้อมูลการหักภาษี ณ ที่จ่ายของใบแจ้งหนี้ เลขที่ " + rdHead.idebitnoteNo);
      } else {
        setTrangradWangbillHead(rdHead);
        setTwshtaxDetail(wshTaxdetailDebit);
        dispayData(wshTaxdetailDebit, rturnType);
      }
    }
  }

  const onFinish = async (values: any) => {
    const empSelected = (employees as Array<TEmployee2View>).find((obj) => obj.id === values.userInfoId);
    const temp: ReturnForWitholdingTaxData = {
      returnForWitholdingTaxView: {
        ...returnForWitholdingTaxView,
        idToAskWitholdingTax: values.idToAskWitholdingTax,
        code: values.code,
        returnType: values.returnType,
        customerName: values.custName,
        serviceAmount: values.serviceAmt,
        taxAmount: values.vatAmt,
        witholdingTaxAmount: values.witholdingTaxAmount,
        totalAmount: values.totalAmount,
        userRemark: values.returnRemark,
        informName: empSelected?.empname,
      },
      twshxaxDetail: { ...twshtaxDetail },
      tworksheetHead: { ...tworksheetHead },
      rangradWangbillHead: { ...trangradWangbillHead },
      reformUser: { ...empSelected! },
    }

    try {
      const returnRes = await returnForWitholdingTaxService.createReturnForWitholdingTax(temp);
      showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเรียบร้อย!");
      form.resetFields();
      setTrigger(false);
      setOnDrawerVisible(false);
    } catch {
      showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ");
      setTrigger(false);
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน!");
    setTrigger(false);
  };

  return (
    <>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Typography.Title level={4}>โปรแกรมยกเลิกการหักภาษี ณ ที่จ่าย</Typography.Title>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "",
              }}
            >
              <Button
                type="primary"
                danger
                icon={<CloseCircleOutlined />}
                onClick={() => setOnDrawerVisible(false)}>
                ยกเลิก
              </Button>
              <Button
                onClick={() => setTrigger(true)}
                type="primary"
                icon={<SaveOutlined />}
                style={{ marginLeft: "5px" }}
              >
                บันทึก
              </Button>
            </div>
          </div>
        }
        width="90%"
        visible={onDrawerVisible}
        forceRender={true}
        placement="right"
        closable={false}
        destroyOnClose={true}
      >
        <Content className="app-page-content">
          <Spin spinning={loading}>
            <Form
              form={form}
              name="returnForWitholdingTax"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 10 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Card
                style={{
                  width: "auto",
                  marginBottom: 5,
                }}
                headStyle={{
                  fontSize: 14,
                  paddingBottom: 5,
                }}
                title="รายการ"
              >
                <Form.Item
                  name="returnType"
                >
                  <Radio.Group
                    style={{ margin: "0px" }}
                    onChange={() => onChangeReturnType()}
                  >
                    <Radio value="WSH_CODE" style={{ backgroundColor: "blue", color: "#FFF", borderRadius: 3 }}>ใบงาน</Radio>
                    <Radio value="DEBITNOTE_CODE" style={{ backgroundColor: "red", color: "#FFF", borderRadius: 3 }}>ใบแจ้งหนี้</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="เลขที่ใบงาน/เลขที่ใบแจ้งหนี้"
                  name="code"
                  style={{ margin: "0 0 3px 0" }}
                >
                  <Input
                    style={{ width: "50%" }}
                    allowClear={false}
                    onFocus={(e: any) => e.target.select()}
                    onKeyDown={(evt: any) => {
                      if (evt.keyCode === 13) {
                        handleSelectCode();
                      }
                    }}
                  ></Input>
                </Form.Item>
                <Form.Item
                  label="เลขที่การหัก"
                  name="idToAskWitholdingTax"
                  style={{ margin: "0 0 3px 0px" }}
                >
                  <Input
                    disabled
                    style={{ width: "50%", marginRight: 5 }}
                  >
                  </Input>
                </Form.Item>
                <Form.Item
                  label="ค่าบริการ"
                  name="serviceAmt"
                  style={{ margin: "0 0 3px 0px" }}
                >
                  <InputNumber
                    controls={false}
                    formatter={(value) => formatNumber(value)}
                    parser={(value) => parserNumber(value)}
                    disabled
                    style={{
                      width: "50%",
                    }}
                  >
                  </InputNumber>
                </Form.Item>
                <Form.Item
                  label="ภาษีมูลค่าเพิ่ม"
                  name="vatAmt"
                  style={{ margin: "0 0 3px 0px" }}
                >
                  <InputNumber
                    controls={false}
                    formatter={(value) => formatNumber(value)}
                    parser={(value) => parserNumber(value)}
                    disabled
                    style={{
                      width: "50%",
                    }}
                  >
                  </InputNumber>
                </Form.Item>
                <Form.Item
                  label="จำนวนเงินที่หัก"
                  name="witholdingTaxAmount"
                  style={{ margin: "0 0 3px 0px" }}
                >
                  <InputNumber
                    controls={false}
                    formatter={(value) => formatNumber(value)}
                    parser={(value) => parserNumber(value)}
                    disabled
                    style={{
                      width: "50%",
                    }}
                  >
                  </InputNumber>
                </Form.Item>
                <Form.Item
                  label="รวมค่าบริการ"
                  name="totalAmount"
                  style={{ margin: "0 0 3px 0px" }}
                >
                  <InputNumber
                    controls={false}
                    formatter={(value) => formatNumber(value)}
                    parser={(value) => parserNumber(value)}
                    disabled
                    style={{
                      width: "50%",
                    }}
                  >
                  </InputNumber>
                </Form.Item>
                <Form.Item
                  label="ชื่อลูกค้า"
                  name="custName"
                  style={{ margin: "0 0 3px 0px" }}
                >
                  <Input
                    disabled
                    style={{ width: "99%", marginRight: 5 }}
                  >
                  </Input>
                </Form.Item>
                <Form.Item
                  label="ประเภทภาษี ณ ที่จ่าย"
                  name="witholdingTaxType"
                  style={{ margin: "0 0 3px 0px" }}
                >
                  <Input
                    disabled
                    style={{ width: "99%", marginRight: 5 }}
                  >
                  </Input>
                </Form.Item>
                <Form.Item
                  label="ผู้หักภาษี ณ ที่จ่าย"
                  name="witholdingTaxCreateUserFullName"
                  style={{ margin: "0 0 3px 0px" }}
                >
                  <Input
                    disabled
                    style={{ width: "99%", marginRight: 5 }}
                  >
                  </Input>
                </Form.Item>
                <Form.Item
                  label="ผู้แจ้ง"
                  name="userInfoId"
                  style={{ margin: "0 0 3px 0px" }}
                  rules={[{ required: true, message: "กรุณาระบุผู้แจ้ง" }]}
                >
                  <Select
                    showSearch
                    size="middle"
                    placeholder="เลือกผู้แจ้ง"
                    optionFilterProp="children"
                    filterOption={true}
                    onSearch={handleSearchEmployee}
                  >
                    {employees.map((obj) => {
                      return (
                        <Option key={obj.id} value={obj.id}>
                          {obj.empname}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="หมายเหตุ"
                  name="userRemark"
                  style={{ margin: "0 0 3px 0px" }}
                  validateTrigger="onBlur"
                  rules={[{ required: true, message: "กรุณาระบุหมายเหตุการยกเลิก" }]}
                >
                  <TextArea
                    rows={4}
                  ></TextArea>
                </Form.Item>
              </Card>
            </Form>
          </Spin>
        </Content>
      </Drawer>
    </>
  )
}

export default ReturnForWitholdingTaxDrawer
