import { EditOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Button, Descriptions, Divider, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import PageHeader from "src/layouts/PageHeader";
import { BsAmphurH } from "../../domain/bsAmphurH";
import { BsProvinceH } from "../../domain/bsProvinceH";
import bsAmphurHService from "../../services/province/bsAmphurHService";
import bsProvinceHService from "../../services/province/bsProvinceHService";
import AddEditBsAmphurHModal from "./AddEditBsAmphurHModal";
import AddEditBsProvinceHModal from "./AddEditBsProvinceHModal";

interface IParams {
  iid: string;
}

const ViewBsProvinceH: React.FC<RouteComponentProps<IParams>> = (props) => {
  const iid: string = props.match.params.iid;
  const [bsProvinceH, setBsProvinceH] = useState<BsProvinceH>(
    {} as BsProvinceH
  );
  const [bsAmphurHs, setBsAmphurHs] = useState<BsAmphurH[]>([]);
  const [page, setPage] = React.useState(1);
  const history = useHistory();
  const [visibleBsProvinceHModal, setVisibleBsProvinceHModal] =
    useState<boolean>(false);

  const [selectedId, setSelectedId] = useState("");
  const [visibleBsAmphurHModal, setVisibleBsAmphurHModal] =
    useState<boolean>(false);

    useEffect(() => {
      (async () => {
        const res = await bsProvinceHService.getBsProvinceHById(Number(iid));
          setBsProvinceH(res.data);
      })();
    }, []);

  useEffect(() => {
    (async () => {
      if (!visibleBsProvinceHModal || !visibleBsAmphurHModal) {
        const res = await bsProvinceHService.getBsProvinceHById(Number(iid));
        setBsProvinceH(res.data);
      }
    })();
  }, [visibleBsProvinceHModal, visibleBsAmphurHModal]);

  useEffect(() => {
    (async () => {
        const res = await bsAmphurHService.getBsAmphurHByProviceId(String(bsProvinceH.iid));
        setBsAmphurHs(res.data);
    })();
  }, [bsProvinceH]);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 100,
      render: (value: number, item: BsAmphurH, index: number) => (
        <>{(page - 1) * 15 + index + 1}</>
      ),
    },
    {
      title: "ชื่ออำเภอ",
      dataIndex: "iamphurName",
      key: "iamphurName",
      render: (value: string, item: BsAmphurH) => (
        <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.iamphurName}</Button>
      )
    },
  ];

  function onEditBsProvinceH() {
    setVisibleBsProvinceHModal(true);
  }

  function onAddBsAmphurH() {
    setSelectedId("");
    setVisibleBsAmphurHModal(true);
  }

  function onItemCliked(item: BsAmphurH) {
    setSelectedId(String(item.iid));
    setVisibleBsAmphurHModal(true);
  }

  return (
    <>
      <PageHeader title="" onClose={() => history.push("/app/bsProvinceH/list")} />
      <Content className="app-page-content">
        <Descriptions
          style={{ margin: "1em" }}
          column={2}
          title="ข้อมูลจังหวัด"
          size="middle"
          extra={
            <Button
              size="middle"
              type="primary"
              icon={<EditOutlined />}
              onClick={() => onEditBsProvinceH()}
            >แก้ไข</Button>
          }
        />
        <div style={{ display: "flex", justifyContent: "flex-start", margin: "1em" }}>
          <Descriptions
            column={2}
            size="middle"
            bordered
            labelStyle={{ textAlign: "right", fontWeight: "bold" }}
          >
            <Descriptions.Item label="รหัส" span={2}>
              {bsProvinceH.iprovinceCode}
            </Descriptions.Item>
            <Descriptions.Item label="รหัสย่อ" span={2}>
              {bsProvinceH.iprovinceShname}
            </Descriptions.Item>
            <Descriptions.Item label="ชื่อจังหวัด" span={2}>
              {bsProvinceH.iprovinceName}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </Content>
      <Divider />

      <br />
      <Content className="app-page-content">
        <div style={{ padding: "0px 20px" }}>
          <div style={{ marginBottom: 16 }}>
            <Button
              size="small"
              type="primary"
              icon={<PlusSquareOutlined />}
              onClick={onAddBsAmphurH}
            >
              เพิ่ม
            </Button>
          </div>
          <Table
            style={{ borderBlockStart: "2px solid #c9c9c9" }}
            columns={columns}
            dataSource={bsAmphurHs}
            size="small"
            pagination={{
              pageSize: 15,
              onChange(current) {
                setPage(current);
              },
            }}
            rowKey={(item: BsAmphurH) => item.iid}
            onRow={(item: BsAmphurH) => {
              return {
                onDoubleClick: () => onItemCliked(item),
              };
            }}
          />
        </div>
      </Content>

      <div>
        {visibleBsProvinceHModal && (
          <AddEditBsProvinceHModal
            iid={iid}
            visibleBsProvinceHModal={visibleBsProvinceHModal}
            setVisibleBsProvinceHModal={setVisibleBsProvinceHModal}
          />
        )}
      </div>
      <div>
        {visibleBsAmphurHModal && (
          <AddEditBsAmphurHModal
            iid={selectedId}
            bsProvinceH={bsProvinceH}
            visibleBsAmphurHModal={visibleBsAmphurHModal}
            setVisibleBsAmphurHModal={setVisibleBsAmphurHModal}
          />
        )}
      </div>
    </>
  );
};
export default ViewBsProvinceH;
