import http from "../../lib/http";
import Constants from "../../utils/constants";
import { TReference } from './../../domain/master/tReference';

const getTReferences = (searchText: string) => {
    return http.get(Constants.DEFAULT_URL + "/tReference/search", {
        params: {
            searchText
        }
    });
};

const getTReferenceById = (id: number) => {
    return http.get(Constants.DEFAULT_URL + "/tReference/" + id);
}

const updateTReference = (tReference: TReference) => {
    return http.put(Constants.DEFAULT_URL + "/tReference", tReference);
}

const postTReference = (tReference: TReference) => {
    return http.post(Constants.DEFAULT_URL + "/tReference", tReference);
}

const getNextTReferenceCode = () => {
    return http.get(Constants.DEFAULT_URL + "/tReference/nextCode");
}

const defaultApi = {
    getTReferences,
    getTReferenceById,
    updateTReference,
    postTReference,
    getNextTReferenceCode
}
export default defaultApi;