import { ArrowLeftOutlined, DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Affix, Button, Card, Checkbox, Col, Drawer, Dropdown,
  Layout, Menu, Modal, Row, Space, Spin, Steps, Tabs, Typography
} from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { TCustomerHeadView } from "src/domain/master/view/tCustomerHeadView";
import { RyPercentpayindeposit } from "src/domain/ryPercentpayindeposit";
import { RyReftypework } from "src/domain/ryReftypework";
import { TStatusworksheetRef } from "src/domain/tStatusworksheetRef";
import { TTypepaymentRef } from "src/domain/tTypepaymentRef";
import { TTypetaxRef } from "src/domain/tTypetaxRef";
import { TTypeworksheetRef } from "src/domain/tTypeworksheetRef";
import { TUserHead } from "src/domain/tUserHead";
import { TWorksheetHead } from "src/domain/worksheet/tWorksheetHead";
import tCustomerHeadService from "src/services/customer/tCustomerHeadService";
import tFinanceDetailsService from "src/services/finance/tFinanceDetailsService";
import ryReftypeworkService from "src/services/reference/ryReftypeworkService";
import tTypepaymentRefService from "src/services/reference/tTypepaymentRefService";
import tTypeworksheetRefService from "src/services/reference/tTypeworksheetRefService";
import tUserHeadService from "src/services/tUserHeadService";
import worksheetService from "src/services/worksheetService";
import { useAppSelector } from "src/stores/store";
import { NotificationType, showNotification } from "src/utils/notificationUtils";
import styled from "styled-components";
import "../../custom-style.css";
import { WorksheetHeadData } from "../../domain/worksheet/view/worksheetHeadData";
import chartviewService from "../../services/chart/chartviewService";
import ryPercentpayindepositService from "../../services/reference/ryPercentpayindepositService";
import tStatusworksheetRefService from "../../services/reference/tStatusworksheetRefService";
import tTypetaxRefService from "../../services/reference/tTypetaxRefService";
import { formatTime, toThDate } from "../../utils/dateTimeUtils";
import { formatNumber } from "../../utils/numberUtils";
import CheckPermissionModal from "./modal/editWorksheet/CheckPermissionModal";
import EditDiscountModal from "./modal/editWorksheet/EditDiscountModal";
import EditDriverModal from "./modal/editWorksheet/EditDriverModal";
import EditPaymentTypeModal from "./modal/editWorksheet/EditPaymentTypeModal";
import EditTaxTypeModal from "./modal/editWorksheet/EditTaxTypeModal";
import EditWorksheetAssetModal from "./modal/editWorksheet/EditWorksheetAssetModal";
import EditWorksheetConstructionListModal from "./modal/editWorksheet/EditWorksheetConstructionListModal";
import EditWorksheetDetailListModal from "./modal/editWorksheet/EditWorksheetDetailListModal";
import EditWorksheetExtraPriceModal from "./modal/editWorksheet/EditWorksheetExtraPriceModal";
import EditWorksheetInstallmentModal from "./modal/editWorksheet/EditWorksheetInstallmentModal";
import EditWorksheetPromiseModal from "./modal/editWorksheet/EditWorksheetPromiseModal";
import EditWorksheetTypeModal from "./modal/editWorksheet/EditWorksheetTypeModal";
import { EditWorksheetWorkerListModal } from "./modal/editWorksheet/EditWorksheetWorkerListModal";
import EditWorktimeModal from "./modal/editWorksheet/EditWorktimeModal";
import EditWshWasteTimeOvertimeModal from "./modal/editWorksheet/EditWshWasteTimeOvertimeModal";
import ReportWorksheeMovementModal from "./modal/editWorksheet/ReportWorksheeMovementModal";
import ViewWorksheetOTModal from "./modal/editWorksheet/ViewWorksheetOTModal";
import ContractorWorkDetailViewTab from "./tabs/ContractorWorkDetailViewTab";
import ServiceDetailViewTab from "./tabs/ServiceDetailViewTab";
import WorkerDetailViewTab from "./tabs/WorkerDetailViewTab";
import WorksheetExtrapriceViewTab from "./tabs/WorksheetExtrapriceViewTab";
import WorksheetOtherserviceViewTab from "./tabs/WorksheetOtherserviceViewTab";
import WorksheetSteelDetailViewTab from "./tabs/WorksheetSteelDetailViewTab";

const { Step } = Steps;
const { TabPane } = Tabs;
const { confirm } = Modal;


const ButtonContainer = styled.span`
  .ant-btn-default {
    color: #fff !important;
    background-color: #1ce8a6 !important;
  }
  .ant-btn[disabled] {
    color: #d0d4d7 !important;
    background: #f7f7f8 !important;
    visibility: hidden;
  }
  .ant-btn-dangerous.ant-btn-text {
    color: #fff !important;
    background-color: #ff6b72 !important;
  }
`;

interface IProps {
  selectedReserveCode: any;
  // setSelectedWshId: any,
  openDrawerView: boolean;
  setOpenDrawerView: any;
}

const ChartBookingForm: React.FC<IProps> = (props) => {
  const { selectedReserveCode, openDrawerView, setOpenDrawerView } = props;
  const [worksheetHeadData, setWorksheetHeadData] = useState<WorksheetHeadData>({} as WorksheetHeadData);
  const [tTypetaxRef, setTTypetaxRef] = useState<TTypetaxRef>({} as TTypetaxRef);
  const [tTypeworksheetRef, setTTypeworksheetRef] = useState<TTypeworksheetRef>({} as TTypeworksheetRef);
  const [ryReftypework, setRyReftypework] = useState<RyReftypework>({} as RyReftypework);
  const [tTypepaymentRef, setTTypepaymentRef] = useState<TTypepaymentRef>({} as TTypepaymentRef);
  const [ryPercentpayindeposit, setRyPercentpayindeposit] = useState<RyPercentpayindeposit>({} as RyPercentpayindeposit);
  const [tStatusworksheetRef, setTStatusworksheetRef] = useState<TStatusworksheetRef>({} as TStatusworksheetRef);
  const [createUser, setCreateUser] = useState<TUserHead>({} as TUserHead);
  const [lastUser, setLastUser] = useState<TUserHead>({} as TUserHead);
  const [customer, setCustomer] = useState<TCustomerHeadView>({} as TCustomerHeadView);
  // const [asset, setAsset] = useState<TAssetHeadView>({} as TAssetHeadView);
  const [showSpin, setShowSpin] = useState<boolean>(false);
  const [permissionFlg, setPermissionFlg] = useState<boolean>(false);
  const [tUserHeadUpdate, setTUserHeadUpdate] = useState<TUserHead>({} as TUserHead);

  const [worksheetPreviewData, setWorksheetPreviewData] = useState<any>();
  const [worksheetPreviewBlob, setWorksheetPreviewBlob] = useState<any>();
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false);

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [checkPermissionModalVisible, setCheckPermissionModalVisible] = useState(false);
  const [menuItemEvent, setMenuItemEvent] = useState<any>();
  const [menuItemSelectKey, setMenuItemSelectKey] = useState<string>();
  const [editPaymentModalVisible, setEditPaymentModalVisible] = useState(false);
  const [editTaxModalVisible, setEditTaxModalVisible] = useState(false);
  const [editWorksheetTypeModalVisible, setEditWorksheetTypeModalVisible] = useState(false);
  const [editWorksheetAssetModalVisible, setEditWorksheetAssetModalVisible] = useState(false);
  const [editWorksheetDetailListModalVisible, setEditWorksheetDetailListModalVisible] = useState(false);
  const [editDriverModalVisible, setEditDriverModalVisible] = useState(false);
  const [editWorksheetWorkerListVisible, setEditWorksheetWorkerListVisible] = useState(false);
  const [editWorksheetConstructionListVisible, setEditWorksheetConstructionListVisible] = useState(false);
  const [editWorktimeModalVisible, setEditWorktimeModalVisible] = useState(false);
  const [editWorksheetPromiseModalVisible, setEditWorksheetPromiseModalVisible] = useState(false);
  const [editWorksheetInstallmentModalVisible, setEditWorksheetInstallmentModalVisible] = useState(false);
  const [editWorksheetExtraPriceModalVisible, setEditWorksheetExtraPriceModalVisible] = useState(false);
  // const [editWorksheetOTModalVisible, setEditWorksheetOTModalVisible] = useState(false);
  const [viewWorksheetOTModalVisible, setViewWorksheetOTModalVisible] = useState(false);
  const [editWshWasteTimeOvertimeModalVisible, setEditWshWasteTimeOvertimeModalVisible] = useState(false);
  const [editWorksheetDiscountModalVisible, setEditWorksheetDiscountModalVisible] = useState(false);
  const [reportWorksheeMovementModalVisible, setReportWorksheeMovementModalVisible] = useState(false);

  const [disableMenuPayment, setDisableMenuPayment] = useState<boolean>(false);
  const [disableMenuTax, setDisableMenuTax] = useState<boolean>(false);
  const [disableMenuWorksheetType, setDisableMenuWorksheetType] = useState<boolean>(false);
  const [disableMenuWorksheetAsset, setDisableMenuWorksheetAsset] = useState<boolean>(false);
  const [disableMenuDriver, setDisableMenuDriver] = useState<boolean>(false);
  const [disableMenuWorksheetWorker, setDisableMenuWorksheetWorker] = useState<boolean>(false);
  const [disableMenuWorksheetConstruction, setDisableMenuWorksheetConstruction] = useState<boolean>(false);
  const [disableMenuWorktime, setDisableMenuWorktime] = useState<boolean>(false);
  const [disableMenuWorksheetDetail, setDisableMenuWorksheetDetail] = useState<boolean>(false);
  const [disableMenuWorksheetPromiss, setDisableMenuWorksheetPromiss] = useState<boolean>(false);
  const [disableMenuWorksheetInstallment, setDisableMenuWorksheetInstallment] = useState<boolean>(false);
  const [disableMenuWorksheetOt, setDisableMenuWorksheetOt] = useState<boolean>(false);
  const [disableMenuDiscount, setDisableMenuDiscount] = useState<boolean>(false);
  const [disableMenuWorksheetExtraprice, setDisableMenuWorksheetExtraprice] = useState<boolean>(false);
  const [disableMenuWorksheetWshwasteTimeOvertime, setDisableMenuWorksheetWshwasteTimeOvertime] = useState<boolean>(false);
  const [disableMenuReportWorksheetMovement, setDisableMenuReportWorksheetMovement] = useState<boolean>(false);

  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  // const [checkReceiptDepositModalVisible, setCheckReceiptDepositModalVisible] = useState<boolean>(false); //nest editPaymentModal

  const menuEditGroupMain1 = (
    <Menu>
      <Menu.Item
        disabled={disableMenuPayment}
        key="MENU_PAYMENT"
        onClick={(e: any) => handleClickMenuItem(e)}
      >
        แก้ไขประเภทการชำระเงิน
      </Menu.Item>
      <Menu.Item
        disabled={disableMenuTax}
        key="MENU_TAX"
        onClick={(e: any) => handleClickMenuItem(e)}
      >
        แก้ไขประเภทการชำระภาษี
      </Menu.Item>
      <Menu.Item
        disabled={disableMenuWorksheetType}
        key="MENU_WORKSHEET_TYPE"
        onClick={(e: any) => handleClickMenuItem(e)}>
        แก้ไขประเภทใบงาน
      </Menu.Item>
    </Menu>
  );
  const menuEditGroupMain2 = (
    <Menu>
      <Menu.Item
      disabled={disableMenuWorksheetAsset}
        key="MENU_WORKSHEET_ASSET"
        onClick={(e: any) => handleClickMenuItem(e)}>
        แก้ไขรายการรถ/ตู้/แผ่นเหล็ก
      </Menu.Item>
      <Menu.Item
        disabled={disableMenuDriver}
        key="MENU_DRIVER"
        onClick={(e: any) => handleClickMenuItem(e)}>
        แก้ไขรายการพนักงานขับรถ
      </Menu.Item>
      <Menu.Item
        disabled={disableMenuWorksheetWorker}
        key="MENU_WORKSHEET_WORKER"
        onClick={(e: any) => handleClickMenuItem(e)}>
        แก้ไขรายการเด็กท้ายรถ
      </Menu.Item>
      <Menu.Item
        disabled={disableMenuWorksheetConstruction}
        key="MENU_WORKSHEET_CONSTRUCTION"
        onClick={(e: any) => handleClickMenuItem(e)}>
        แก้ไขรายการใบงานย่อย
      </Menu.Item>
    </Menu>
  );
  const menuEditGroupMain3 = (
    <Menu>
      <Menu.Item
        disabled={disableMenuWorktime}
        key="MENU_WORKTIME"
        onClick={(e: any) => handleClickMenuItem(e)}>
        แก้ไขเวลาการทำงาน
      </Menu.Item>
      <Menu.Item
        disabled={disableMenuWorksheetDetail}
        key="MENU_WORKSHEET_DETAIL"
        onClick={(e: any) => handleClickMenuItem(e)}>
        แก้ไขรายการบริการ
      </Menu.Item>
      <Menu.Item
        disabled={disableMenuWorksheetPromiss}
        key="MENU_WORKSHEET_PROMISE"
        onClick={(e: any) => handleClickMenuItem(e)}
      >
        แก้ไขเลขที่สัญญา
      </Menu.Item>
      <Menu.Item
        disabled={disableMenuWorksheetInstallment}
        key="MENU_WORKSHEET_INSTALLMENT"
        onClick={(e: any) => handleClickMenuItem(e)}
      >
        แก้ไขจำนวนงวดของสัญญา
      </Menu.Item>
      {/* OT มี code เพิ่ม + แก้ไข อยู่ในหน้า EditWorksheetOTModal.tsx  */}
      <Menu.Item
        disabled={disableMenuWorksheetOt}
        key="MENU_WORKSHEET_OT"
        onClick={(e: any) => handleClickMenuItem(e)}
      >
        {/* บันทึก OT */}
        ดูข้อมูล OT
      </Menu.Item>
      <Menu.Item
        disabled={disableMenuDiscount}
        key="MENU_WORKSHEET_DISCOUNT"
        onClick={(e: any) => handleClickMenuItem(e)}
      >
        ให้ส่วนลด
      </Menu.Item>
      <Menu.Item
        disabled={disableMenuWorksheetExtraprice}
        key="MENU_WORKSHEET_EXTRAPRICE"
        onClick={(e: any) => handleClickMenuItem(e)}>
        เพิ่มค่าบริการอื่น ๆ
      </Menu.Item>
      <Menu.Item
        disabled={disableMenuWorksheetWshwasteTimeOvertime}
        key="MENU_WORKSHEET_WSHWASTETIME_OVERTIME"
        onClick={(e: any) => handleClickMenuItem(e)}>
        แก้ไขประเภทใบงานค่าเสียเวลา/ใบงาน OT
      </Menu.Item>
    </Menu>
  );
  const menuReportGroup = (
    <Menu>
      <Menu.Item
        disabled={disableMenuReportWorksheetMovement}
        key="MENU_REPORT_WORKSHEET_MOVEMENT"
        onClick={(e: any) => handleClickMenuItem(e)}>
        รายงานความเคลื่อนไหว
      </Menu.Item>
    </Menu>
  );

  const init = async () => {
    setShowSpin(true);

    const worksheetHeadDataResult = await worksheetService.getWorksheetHeadData(selectedReserveCode);
    const worksheetHeadDataTemp = worksheetHeadDataResult.data as WorksheetHeadData;

    if (worksheetHeadDataTemp.tworksheetHead?.itypetaxCode) {
      const tTypetaxRefRes = await tTypetaxRefService.getTTypetaxRef(worksheetHeadDataTemp.tworksheetHead?.itypetaxCode);
      setTTypetaxRef(tTypetaxRefRes.data);
    }

    if (worksheetHeadDataTemp.tworksheetHead?.typeworksheetflag) {
      const ryReftypeworkRes = await ryReftypeworkService.getRyReftypework(worksheetHeadDataTemp.tworksheetHead?.typeworksheetflag);
      setRyReftypework(ryReftypeworkRes.data);
    }

    if (worksheetHeadDataTemp.tworksheetHead?.itypeworksheetCode) {
      const tTypeworksheetRefRes = await tTypeworksheetRefService.getTTypeworksheetRef(worksheetHeadDataTemp.tworksheetHead?.itypeworksheetCode);
      setTTypeworksheetRef(tTypeworksheetRefRes.data);
    }
    if (worksheetHeadDataTemp.tworksheetHead?.itypepaymentCode) {
      const tTypepaymentRefRes = await tTypepaymentRefService.getTTypepaymentRef(worksheetHeadDataTemp.tworksheetHead?.itypepaymentCode);
      setTTypepaymentRef(tTypepaymentRefRes.data);
    }
    if (worksheetHeadDataTemp.tworksheetHead?.depositpercentId) {
      const ryPercentpayindepositRes = await ryPercentpayindepositService.getRyPercentpayindeposit(worksheetHeadDataTemp.tworksheetHead?.depositpercentId);
      setRyPercentpayindeposit(ryPercentpayindepositRes.data);
    }
    if (worksheetHeadDataTemp.tworksheetHead?.iworksheetStatus) {
      const tStatusworksheetRefRes = await tStatusworksheetRefService.getTStatusworksheetRef(worksheetHeadDataTemp.tworksheetHead?.iworksheetStatus);
      setTStatusworksheetRef(tStatusworksheetRefRes.data);
    }
    if (worksheetHeadDataTemp.tworksheetHead?.icustCode) {
      const customerResult = await tCustomerHeadService.getCustomerByCode(
        worksheetHeadDataTemp.tworksheetHead?.icustCode
      );
      setCustomer(customerResult.data);
    }
    if (worksheetHeadDataTemp.tworksheetHead?.icreateUser) {
      const createUserRes = await tUserHeadService.getUserById(worksheetHeadDataTemp.tworksheetHead?.icreateUser);
      setCreateUser(createUserRes.data);
    }
    if (worksheetHeadDataTemp.tworksheetHead?.ilastUser) {
      const lastUserRes = await tUserHeadService.getUserById(worksheetHeadDataTemp.tworksheetHead?.ilastUser);
      setLastUser(lastUserRes.data);
    }
    //กำหนดค่า cerrent select ให้ step
    let step = 0;
    const step1 = ["01", "05"];
    const step2 = ["02"];
    const step3 = ["03"];
    const step4 = ["06"];
    const step5 = ["07", "08", "09", "10", "11", "13", "14", "15", "16"];
    const step6 = ["12"];
    const step7 = ["17", "20", "97"];
    const step8 = ["98"];
    const step9 = ["99"];
    if (step1.indexOf(worksheetHeadDataTemp.tworksheetHead?.iworksheetStatus as string) > -1) {
      step = 1;
    } else if (step2.indexOf(worksheetHeadDataTemp.tworksheetHead?.iworksheetStatus as string) > -1) {
      step = 2;
    } else if (step3.indexOf(worksheetHeadDataTemp.tworksheetHead?.iworksheetStatus as string) > -1) {
      step = 3;
    } else if (step4.indexOf(worksheetHeadDataTemp.tworksheetHead?.iworksheetStatus as string) > -1) {
      step = 4;
    } else if (step5.indexOf(worksheetHeadDataTemp.tworksheetHead?.iworksheetStatus as string) > -1) {
      step = 5;
    } else if (step6.indexOf(worksheetHeadDataTemp.tworksheetHead?.iworksheetStatus as string) > -1) {
      step = 6;
    } else if (step7.indexOf(worksheetHeadDataTemp.tworksheetHead?.iworksheetStatus as string) > -1) {
      step = 7;
    } else if (step8.indexOf(worksheetHeadDataTemp.tworksheetHead?.iworksheetStatus as string) > -1) {
      step = 8;
    } else if (step9.indexOf(worksheetHeadDataTemp.tworksheetHead?.iworksheetStatus as string) > -1) {
      step = 9;
    }

    let sumServiceLastAmt = 0;
    if (worksheetHeadDataTemp.tworksheetDetailList && worksheetHeadDataTemp.tworksheetDetailList.length > 0) {
      sumServiceLastAmt = Number(
        (!worksheetHeadDataTemp.tworksheetHead?.iserviceAmt
          ? 0
          : worksheetHeadDataTemp.tworksheetHead?.iserviceAmt) -
        (!worksheetHeadDataTemp.tworksheetHead?.idiscount
          ? 0
          : worksheetHeadDataTemp.tworksheetHead?.idiscount)
      );
    }
    let netServiceAmt = Number(
      (!worksheetHeadDataTemp.tworksheetHead?.iserviceAmt
        ? 0
        : worksheetHeadDataTemp.tworksheetHead?.iserviceAmt) +
      (!worksheetHeadDataTemp.tworksheetHead?.iextraAmt
        ? 0
        : worksheetHeadDataTemp.tworksheetHead?.iextraAmt) -
      (!worksheetHeadDataTemp.tworksheetHead?.idiscount
        ? 0
        : worksheetHeadDataTemp.tworksheetHead?.idiscount)
    );
    worksheetHeadDataTemp.netServiceAmt = netServiceAmt;
    worksheetHeadDataTemp.sumServiceLastAmt = sumServiceLastAmt;

    setWorksheetHeadData(worksheetHeadDataTemp);
    setCurrentStep(step);
    setEnableMenu(worksheetHeadDataTemp.tworksheetHead);
    setShowSpin(false);
  };

  useEffect(() => {
    if (openDrawerView) {
      init();
    }
  }, [openDrawerView]);

  useEffect(() => {
    if (!checkPermissionModalVisible && permissionFlg) {
      if (worksheetHeadData.tworksheetHead?.itransferSalary == null) {
        if (!editPaymentModalVisible && menuItemSelectKey === "MENU_PAYMENT") {
          (async () => {
            const fn = await tFinanceDetailsService.isExistByWorksheetCode(worksheetHeadData.tworksheetHead.iworksheetCode!);
            if (fn.data && worksheetHeadData.tworksheetHead.itypepaymentCode === "01") {
              showNotification(NotificationType.ERROR, "ไม่สามารถทำรายการได้เนื่องจากเป็นใบสั่งงานสดก่อนเริ่มงานและการเงินรับชำระแล้ว\n กรุณาติดต่อผู้ดูแลระบบ");
              return;
            } else if (Number(worksheetHeadData.tworksheetHead.iworksheetStatus) <= 7 || chkUserEditWorksheet(tUserHeadUpdate)) {
              setEditPaymentModalVisible(true);
            } else {
              showNotification(NotificationType.ERROR, "สถานะใบงานไม่สามารถแก้ไขได้ กรุณาแจ้งผู้ดูแลระบบ");
              return;
            }
          })();
          setPermissionFlg(false);
        } else if (!editTaxModalVisible && menuItemSelectKey === "MENU_TAX") {
          (async () => {
            switch (worksheetHeadData.tworksheetHead.itypepaymentCode) {
              case "01":
                const fn = await tFinanceDetailsService.isExistByWorksheetCode(worksheetHeadData.tworksheetHead.iworksheetCode!);
                if (fn.data) {
                  showNotification(NotificationType.ERROR, "ไม่สามารถทำรายการได้เนื่องจากเป็นใบสั่งงานสดก่อนเริ่มงานและการเงินรับชำระไปแล้ว\n กรุณาติดต่อผู้ดูแลระบบ");
                  return;
                } else {
                  setEditTaxModalVisible(true);
                }
                break;
              case "04":
                if (worksheetHeadData.tworksheetHead.iworksheetStatus === "00") {
                  showNotification(NotificationType.ERROR, "ไม่สามารถทำรายการได้เนื่องจาก :สถานะใบงานคือ : " + tStatusworksheetRef.iname.trim() + " \nสามารถแก้ไขก่อนการยืนยันใบงานได้");
                  return;
                } else if (Number(worksheetHeadData.tworksheetHead.iworksheetStatus) <= 7 || chkUserEditWorksheet(tUserHeadUpdate)) {
                  setEditTaxModalVisible(true);
                } else {
                  showNotification(NotificationType.ERROR, "ไม่สามารถทำรายการได้เนื่องจาก :สถานะใบงานคือ : " + tStatusworksheetRef.iname.trim());
                  return;
                }
                break;
              default:
                if (worksheetHeadData.tworksheetHead.iworksheetStatus === "00") {
                  showNotification(NotificationType.ERROR, "ไม่สามารถทำรายการได้เนื่องจาก :สถานะใบงานคือ : " + tStatusworksheetRef.iname.trim() + " \nสามารถแก้ไขก่อนการยืนยันใบงานได้");
                  return;
                } else if ((Number(worksheetHeadData.tworksheetHead.iworksheetStatus) <= 7 && worksheetHeadData.tworksheetHead.ipaymentCode === "00") || chkUserEditWorksheet(tUserHeadUpdate)) {
                  setEditTaxModalVisible(true);
                } else {
                  showNotification(NotificationType.ERROR, "ไม่สามารถทำรายการได้เนื่องจาก :สถานะใบงานคือ : " + tStatusworksheetRef.iname.trim());
                  return;
                }
                break;
            }
          })();

        } else if (!editWorksheetTypeModalVisible && menuItemSelectKey === "MENU_WORKSHEET_TYPE") {
          switch (worksheetHeadData.tworksheetHead.itypepaymentCode) {
            case "01":
              if (worksheetHeadData.tworksheetHead.ipaymentStatus === "01") {
                showNotification(NotificationType.ERROR, "ไม่สามารถทำรายการได้เนื่องจากเป็นใบสั่งงานสดก่อนเริ่มงานและการเงินรับชำระไปแล้ว\n กรุณาติดต่อผู้ดูแลระบบ");
                return;
              } else {
                setEditWorksheetTypeModalVisible(true);
              }
              break;
            case "04":
              if (worksheetHeadData.tworksheetHead.iworksheetStatus === "00") {
                showNotification(NotificationType.ERROR, "ไม่สามารถทำรายการได้เนื่องจาก :สถานะใบงานคือ : " + tStatusworksheetRef.iname.trim() + " \nสามารถแก้ไขก่อนการยืนยันใบงานได้");
                return;
              } else if (Number(worksheetHeadData.tworksheetHead.iworksheetStatus) <= 7 || chkUserEditWorksheet(tUserHeadUpdate)) {
                setEditWorksheetTypeModalVisible(true);
              } else {
                showNotification(NotificationType.ERROR, "ไม่สามารถทำรายการได้เนื่องจาก :สถานะใบงานคือ : " + tStatusworksheetRef.iname.trim());
                return;
              }
              break;
            default:
              if (worksheetHeadData.tworksheetHead.iworksheetStatus === "00") {
                showNotification(NotificationType.ERROR, "ไม่สามารถทำรายการได้เนื่องจาก :สถานะใบงานคือ : " + tStatusworksheetRef.iname.trim() + " \nสามารถแก้ไขก่อนการยืนยันใบงานได้");
                return;
              } else if ((Number(worksheetHeadData.tworksheetHead.iworksheetStatus) <= 7 && worksheetHeadData.tworksheetHead.ipaymentCode === "00") || chkUserEditWorksheet(tUserHeadUpdate)) {
                setEditWorksheetTypeModalVisible(true);
              } else {
                showNotification(NotificationType.ERROR, "ไม่สามารถทำรายการได้เนื่องจาก :สถานะใบงานคือ : " + tStatusworksheetRef.iname.trim());
                return;
              }
              break;
          }
          setPermissionFlg(false);
        } else if (!editWorksheetAssetModalVisible && menuItemSelectKey === "MENU_WORKSHEET_ASSET") {
          if (Number(worksheetHeadData.tworksheetHead.iworksheetStatus) <= 7 || chkUserEditWorksheet(tUserHeadUpdate)) {
            setEditWorksheetAssetModalVisible(true);
          } else {
            showNotification(NotificationType.ERROR, "ไม่สามารถทำรายการได้เนื่องจาก :สถานะใบงานคือ : " + tStatusworksheetRef.iname.trim());
            return;
          }
        } else if (!editDriverModalVisible && menuItemSelectKey === "MENU_DRIVER") {
          setEditDriverModalVisible(true);
          setPermissionFlg(false);
        } else if (!editWorksheetDetailListModalVisible && menuItemSelectKey === "MENU_WORKSHEET_DETAIL") {
          if (Number(worksheetHeadData.tworksheetHead.iworksheetStatus) <= 7 || chkUserEditWorksheet(tUserHeadUpdate)) {
            setEditWorksheetDetailListModalVisible(true);
          } else {
            showNotification(NotificationType.ERROR, "สถานะใบงานไม่สามารถแก้ไขได้ กรุณาแจ้งผู้ดูแลระบบ");
            return;
          }
          setPermissionFlg(false);
        } else if (!editWorksheetWorkerListVisible && menuItemSelectKey === "MENU_WORKSHEET_WORKER") {
          setEditWorksheetWorkerListVisible(true);
          setPermissionFlg(false);
        } else if (!editWorksheetConstructionListVisible && menuItemSelectKey === "MENU_WORKSHEET_CONSTRUCTION") {
          if (Number(worksheetHeadData.tworksheetHead.iworksheetStatus) <= 7 || chkUserEditWorksheet(tUserHeadUpdate)) {
            setEditWorksheetConstructionListVisible(true);
          } else {
            showNotification(NotificationType.ERROR, "สถานะใบงานไม่สามารถแก้ไขได้ กรุณาแจ้งผู้ดูแลระบบ");
            return;
          }
          setPermissionFlg(false);
        } else if (!editWorktimeModalVisible && menuItemSelectKey === "MENU_WORKTIME") {
          if (Number(worksheetHeadData.tworksheetHead.iworksheetStatus) <= 7 || chkUserEditWorksheet(tUserHeadUpdate)) {
            setEditWorktimeModalVisible(true);
          } else {
            showNotification(NotificationType.ERROR, "สถานะใบงานไม่สามารถแก้ไขได้ กรุณาแจ้งผู้ดูแลระบบ");
            return;
          }
          setPermissionFlg(false);
        } else if (!editWorksheetPromiseModalVisible && menuItemSelectKey === "MENU_WORKSHEET_PROMISE") {
          if (Number(worksheetHeadData.tworksheetHead.iworksheetStatus) <= 7 || chkUserEditWorksheet(tUserHeadUpdate)) {
            setEditWorksheetPromiseModalVisible(true);
          } else {
            showNotification(NotificationType.ERROR, "สถานะใบงานไม่สามารถแก้ไขได้ กรุณาแจ้งผู้ดูแลระบบ");
            return;
          }
          setPermissionFlg(false);
        } else if (!editWorksheetInstallmentModalVisible && menuItemSelectKey === "MENU_WORKSHEET_INSTALLMENT") {
          if (Number(worksheetHeadData.tworksheetHead.iworksheetStatus) <= 7 || chkUserEditWorksheet(tUserHeadUpdate)) {
            setEditWorksheetInstallmentModalVisible(true);
          } else {
            showNotification(NotificationType.ERROR, "สถานะใบงานไม่สามารถแก้ไขได้ กรุณาแจ้งผู้ดูแลระบบ");
            setEditWorksheetInstallmentModalVisible(false);
            return;
          }
          setPermissionFlg(false);
        } else if (menuItemSelectKey === "MENU_WORKSHEET_EXTRAPRICE") {
          setEditWorksheetExtraPriceModalVisible(true);
          setPermissionFlg(false);
        } //else if (menuItemSelectKey === "MENU_WORKSHEET_OT") {
        // if (worksheetHeadData.tworksheetHead.typeworksheetflag !== "00" &&
        //   worksheetHeadData.tworksheetHead.typeworksheetflag !== "01" &&
        //   worksheetHeadData.tworksheetHead.typeworksheetflag !== "03") {
        //   showNotification(NotificationType.ERROR, "ประเภทใบงานไม่ถูกต้อง ไม่สามารถเพิ่มค่าล่วงเวลาได้");
        //   return;
        // } else if (worksheetHeadData.tassetHeadView!.assettype !== "001" && worksheetHeadData.tassetHeadView!.assettype !== "007") {
        //   showNotification(NotificationType.ERROR, "ประเภทรถไม่ถูกต้อง ไม่สามารถเพิ่มค่าล่วงเวลาได้");
        //   return;
        // } else if (worksheetHeadData.tworksheetHead.flagtaxinvoid === "1") {
        //   showNotification(NotificationType.ERROR, "ใบสั่งงานออกใบกำกับแล้ว ไม่สามารถแก้ไขได้");
        //   return;
        // } else if (worksheetHeadData.tworksheetHead.ipaymentStatus !== "00") {
        //   showNotification(NotificationType.ERROR, "การเงินรับชำระเงินแล้ว ไม่สามารถเพิ่มค่าล่วงเวลาได้");
        //   return;
        // } else if (worksheetHeadData.totHead?.iid || (worksheetHeadData.tworksheetHead.iflgWshOt)) {
        //   showNotification(NotificationType.ERROR, "ใบสั่งงานมีค่าล่วงเวลาอยู่แล้ว ไม่สามารถเพิ่มค่าล่วงเวลาได้");
        //   return;
        // } else {
        //   setViewWorksheetOTModalVisible(true)
        // }
        //setViewWorksheetOTModalVisible(true);
        //}
        else if (!editWorksheetDiscountModalVisible && menuItemSelectKey === "MENU_WORKSHEET_DISCOUNT") {
          setEditWorksheetDiscountModalVisible(true);
        } else if (!editWshWasteTimeOvertimeModalVisible && menuItemSelectKey === "MENU_WORKSHEET_WSHWASTETIME_OVERTIME") {
          setEditWshWasteTimeOvertimeModalVisible(true);
        }
        setPermissionFlg(false);
      } else {
        showNotification(NotificationType.ERROR, "ใบงานนี้ถูกบันทึกข้อมูลเงินเดือนของพนักงานแล้ว กรุณาแจ้งเจ้าหน้าที่ฝ่ายบุคคล");
        setPermissionFlg(false);
      }
    }
  }, [checkPermissionModalVisible, menuItemSelectKey]);

  useEffect(() => {
    // init();

    if (!checkPermissionModalVisible && (
      !editPaymentModalVisible || !editTaxModalVisible || !editWorksheetTypeModalVisible || !editWorksheetAssetModalVisible || 
      !editDriverModalVisible || !editWorksheetDetailListModalVisible || !editWorksheetWorkerListVisible || 
      !editWorksheetConstructionListVisible || !editWorksheetInstallmentModalVisible || !editWorksheetPromiseModalVisible || 
      !editWorktimeModalVisible || !editWorksheetDiscountModalVisible || !editWshWasteTimeOvertimeModalVisible)) {
      init();
    }
  }, [editPaymentModalVisible, editTaxModalVisible,
    editWorksheetTypeModalVisible, editWorksheetAssetModalVisible, editDriverModalVisible,
    editWorksheetDetailListModalVisible, editWorksheetWorkerListVisible, editWorksheetConstructionListVisible,
    editWorksheetInstallmentModalVisible, editWorksheetPromiseModalVisible, editWorktimeModalVisible,
    // editWorksheetOTModalVisible,updateStatus, menuItemSelectKey, 
    editWorksheetDiscountModalVisible, editWshWasteTimeOvertimeModalVisible
  ]);

  const setEnableMenu = (tworksheetHad: TWorksheetHead) => {    
    if ("001" === userInfo.permissionCode || "999" === userInfo.permissionCode) {
      setDisableMenuPayment(false);
      setDisableMenuTax(false);
      setDisableMenuWorksheetType(false);
      setDisableMenuDiscount(false);
      setDisableMenuWorksheetDetail(false);
      setDisableMenuWorksheetExtraprice(false);
      setDisableMenuDriver(false);
      setDisableMenuWorksheetOt(false);
      setDisableMenuWorksheetPromiss(false);
      setDisableMenuWorksheetWorker(false);
      setDisableMenuWorksheetAsset(false);
      setDisableMenuWorksheetInstallment(false);
      setDisableMenuWorktime(false);
    } else {
      setDisableMenuPayment(true);//แก้ไขประเภทการชำระเงิน
      setDisableMenuTax(true);//แก้ไขประเภทภาษี
      setDisableMenuWorksheetType(true);//แก้ไขประเภทใบงาน
      setDisableMenuDiscount(true);//แก้ไขการให้ส่วนลด
      setDisableMenuWorksheetDetail(false);//แก้ไขการบริการ
      setDisableMenuWorksheetExtraprice(true);//แก้ไขค่าบริการพิเศษ
      setDisableMenuDriver(false);//แก้ไขคนขับรถ//->false->true->50-09-03
      setDisableMenuWorksheetOt(true);//แก้ไขค่าล่วงเวลา
      setDisableMenuWorksheetPromiss(true);//แก้ไขเลขที่สัญญา
      setDisableMenuWorksheetWorker(false);//แก้ไขพนักงานท้ายรถ
      setDisableMenuWorksheetAsset(false);//แก้ไขปรับเปลี่ยนรถ//->false->true->50-10-31
      setDisableMenuWorksheetInstallment(true);//กำหนดจำนวนงวด
      setDisableMenuWorktime(true);
    }

    if (tworksheetHad?.typeworksheetflag !== "02") {
      setDisableMenuWorksheetConstruction(true);
    }
    if (tworksheetHad?.typeworksheetflag === "05") {
      setDisableMenuTax(true);//แก้ไขประเภทภาษี
      setDisableMenuWorksheetType(true);//แก้ไขประเภทใบงาน
      setDisableMenuDriver(true);//แก้ไขคนขับรถ//->false->true->50-09-03
      setDisableMenuWorksheetOt(true);//แก้ไขค่าล่วงเวลา
      setDisableMenuWorksheetPromiss(true);//แก้ไขเลขที่สัญญา
      setDisableMenuWorksheetWorker(true);//แก้ไขพนักงานท้ายรถ
      setDisableMenuWorksheetAsset(true);//แก้ไขปรับเปลี่ยนรถ//->false->true->50-10-31
      setDisableMenuWorksheetInstallment(true);//กำหนดจำนวนงวด
      setDisableMenuWorktime(true);
      setDisableMenuWorksheetDetail(true);//แก้ไขการบริการ
      setDisableMenuWorksheetWshwasteTimeOvertime(false);
    }
    if (tworksheetHad?.iworksheetStatus === "00" || tworksheetHad?.iworksheetStatus === "98" || tworksheetHad?.iworksheetStatus === "99") {
      setDisableMenuWorksheetWshwasteTimeOvertime(true);
      setDisableMenuPayment(true);//แก้ไขประเภทการชำระเงิน
      setDisableMenuTax(true);//แก้ไขประเภทภาษี
      setDisableMenuWorksheetType(true);//แก้ไขประเภทใบงาน
      setDisableMenuDiscount(true);//แก้ไขการให้ส่วนลด
      setDisableMenuWorksheetDetail(true);//แก้ไขการบริการ
      setDisableMenuWorksheetExtraprice(true);//แก้ไขค่าบริการพิเศษ
      setDisableMenuDriver(true);//แก้ไขคนขับรถ//->false->true->50-09-03
      setDisableMenuWorksheetOt(true);//แก้ไขค่าล่วงเวลา
      setDisableMenuWorksheetPromiss(true);//แก้ไขเลขที่สัญญา
      if (tworksheetHad?.iworksheetStatus === "99") {
        setDisableMenuWorksheetWorker(false);//แก้ไขพนักงานท้ายรถ
      }
      setDisableMenuWorksheetAsset(true);//แก้ไขปรับเปลี่ยนรถ//->false->true->50-10-31
      setDisableMenuWorksheetInstallment(true);//กำหนดจำนวนงวด
      setDisableMenuWorktime(true);
    }
  }

  const chkUserEditWorksheet = (tUserHeadUpdate: TUserHead) => {
    return tUserHeadUpdate.ipermissionCode.trim() === "999";
  }

  //ปุ่ม พิมพ์ แสดงในสถานะ ยืนยัน/ปล่อย
  const checkDisableButtonPrint = (): boolean => {
    let isDisable: boolean = false;
    if (worksheetHeadData.tworksheetHead?.iworksheetStatus === "00") {
      isDisable = true;
    }
    return isDisable;
  };

  const handlePrintWorksheet = async () => {
    if (worksheetHeadData.tworksheetHead.iworksheetStatus == '00') {
      showNotification(NotificationType.ERROR, "ยังไม่ได้ยื่นยันใบงาน กรุณายืนยันใบงานก่อน");
    } else if (!worksheetHeadData.tworksheetHead.iworksheetCode) {
      showNotification(NotificationType.ERROR, "ไม่มีเลขที่ใบงาน ไม่สามารถพิมพ์ใบงานได้");
    } else {
      const resultWorksheetPreview = await chartviewService.getWorksheetPreview(worksheetHeadData.tworksheetHead, true);
      if (resultWorksheetPreview.data) {
        setWorksheetPreviewBlob(resultWorksheetPreview.data);
        const fileObj = URL.createObjectURL(resultWorksheetPreview.data);
        setWorksheetPreviewData(fileObj);
        setIsPreviewModalVisible(true);
      } else {
        showNotification(
          NotificationType.ERROR,
          "ไม่พบข้อมูลที่เกี่ยวข้อง ไม่สามารถแสดงข้อมูลตัวอย่างได้"
        );
      }
    }
  };

  const checkTransferSalary = async () => {
    const userhead = await tUserHeadService.getUserByEmployeeCode(userInfo.employeeCode);
    return new Promise((resolve, reject) => {
      if (worksheetHeadData.tworksheetHead.itransferSalary) {
        showNotification(NotificationType.ERROR, "ใบงานนี้ถูกบันทึกข้อมูลเงินเดือนของพนักงานแล้ว กรุณาแจ้งเจ้าหน้าที่ฝ่ายบุคคล");
        reject(false);
      }
      if (chkUserEditWorksheet(userhead.data)) {
        confirm({
          title: "ยืนยัน",
          icon: <ExclamationCircleOutlined />,
          content: "ต้องการแก้ไขใบสั่งงานหรือไม่",
          cancelText: "ยกเลิก",
          okText: "ยืนยัน",
          onOk() {
            resolve(true);
          },
          onCancel() {
            reject(false);
          },
        });
      } else {
        resolve(true);
      }
    })
  }

  const handleEditMenuPayment = (e: any) => {
    if (worksheetHeadData.tworksheetHead.flagtaxinvoid === "1") {
      showNotification(NotificationType.ERROR, "ใบสั่งงานออกใบกำกับแล้ว ไม่สามารถแก้ไขได้");
      return;
    } else if (worksheetHeadData.tworksheetHead.ipaymentStatus === "01") {
      showNotification(NotificationType.ERROR, "การเงินรับชำระใบงานสั่งงานนี้แล้ว");
      return;
    } else if (worksheetHeadData.tworksheetHead.typeworksheetflag === "01") {
      showNotification(NotificationType.ERROR, "ใบงานย่อยไม่สามารถแก้ไขประเภทการชำระเงินได้");
      return;
    } else {
      setCheckPermissionModalVisible(true);
      setMenuItemEvent(e);
    }
  }

  const handleEditMenuTax = async (e: any) => {
    if (worksheetHeadData.tworksheetHead.flagtaxinvoid === "1") {
      showNotification(NotificationType.ERROR, "ใบสั่งงานออกใบกำกับแล้ว ไม่สามารถแก้ไขได้");
      return;
    } else {
      checkTransferSalary()
        .then(() => {
          return new Promise((resolve, reject) => {
            setCheckPermissionModalVisible(true);
            setMenuItemEvent(e);
            resolve(true);
          });
        });
    }
  }

  const handleEditMenuWorksheetType = async (e: any) => {
    checkTransferSalary()
      .then(() => {
        return new Promise((resolve, reject) => {
          if (worksheetHeadData.tworksheetHead.flagtaxinvoid === "1") {
            showNotification(NotificationType.ERROR, "ใบสั่งงานออกใบกำกับแล้ว ไม่สามารถแก้ไขได้");
            reject(false);
          } else {
            setCheckPermissionModalVisible(true);
            setMenuItemEvent(e);
            resolve(true);
          }
        });
      });
  }

  const handleEditMenuWorksheetAsset = async (e: any) => {
    checkTransferSalary()
      .then(() => {
        return new Promise((resolve, reject) => {
          if (worksheetHeadData.tworksheetHead.typeworksheetflag === "02" ||
            worksheetHeadData.tworksheetHead.typeworksheetflag === "04" ||
            worksheetHeadData.tworksheetHead.typeworksheetflag === "05") {
            showNotification(NotificationType.ERROR, "ประเภทใบงานไม่ถูกต้อง");
            reject(false);
          } else {
            setCheckPermissionModalVisible(true);
            setMenuItemEvent(e);
            resolve(true);
          }
        });
      });
  }

  const handleEditMenuDriver = async (e: any) => {
    checkTransferSalary()
      .then(() => {
        return new Promise((resolve, reject) => {
          if (worksheetHeadData.tworksheetHead.typeworksheetflag === "02" ||
            worksheetHeadData.tworksheetHead.typeworksheetflag === "04" ||
            worksheetHeadData.tworksheetHead.typeworksheetflag === "05") {
            showNotification(NotificationType.ERROR, "ประเภทใบงานไม่ถูกต้อง");
            reject(false);
          } else {
            setCheckPermissionModalVisible(true);
            setMenuItemEvent(e);
            resolve(true);
          }
        });
      });
  }

  const handleEditMenuWorksheetDetail = async (e: any) => {
    const userhead = await tUserHeadService.getUserByEmployeeCode(userInfo.employeeCode);
    checkTransferSalary()
      .then(() => {
        return new Promise((resolve, reject) => {
          if (worksheetHeadData.tworksheetHead.flagtaxinvoid === "1") {
            showNotification(NotificationType.ERROR, "ใบสั่งงานออกใบกำกับแล้ว ไม่สามารถแก้ไขจำนวนเงินได้");
            reject(false);
          } else if (worksheetHeadData.tworksheetHead.itypepaymentCode === "01" && chkUserEditWorksheet(userhead.data)) {
            showNotification(NotificationType.ERROR, "ใบสั่งงานสดก่อน ไม่สามารถแก้ไขจำนวนเงินได้");
            reject(false);
          } else if (worksheetHeadData.tworksheetHead.typeworksheetflag === "01" && worksheetHeadData.tworksheetHead.constructionworkCode) {
            showNotification(NotificationType.ERROR, "ใบงานย่อยอยู่ในใบงานเหมาคุมใบย่อยแล้ว ไม่สามารถแก้ไขรายการบริการได้");
            reject(false);
          } else if (worksheetHeadData.tworksheetHead.typeworksheetflag === "02" || worksheetHeadData.tworksheetHead.typeworksheetflag === "04" || worksheetHeadData.tworksheetHead.typeworksheetflag === "05") {
            showNotification(NotificationType.ERROR, "ประเภทใบงานไม่ถูกต้อง");
            reject(false);
          } else {
            setCheckPermissionModalVisible(true);
            setMenuItemEvent(e);
            resolve(true);
          }
        });
      });
  }

  const handleEditMenuWorksheetWorker = (e: any) => {
    checkTransferSalary()
      .then(() => {
        return new Promise((resolve, reject) => {
          if (worksheetHeadData.tworksheetHead.typeworksheetflag === "04" || worksheetHeadData.tworksheetHead.typeworksheetflag === "05") {
            showNotification(NotificationType.ERROR, "ประเภทใบงานไม่ถูกต้อง");
            reject(false);
          } else {
            setCheckPermissionModalVisible(true);
            setMenuItemEvent(e);
            resolve(true);
          }
        });
      });
  }

  const handleEditMenuWorksheetConstruction = (e: any) => {
    if (worksheetHeadData.tworksheetHead.typeworksheetflag !== "02") {
      showNotification(NotificationType.ERROR, "ประเภทใบงานไม่ถูกต้อง");
      return;
    } else {
      setCheckPermissionModalVisible(true);
      setMenuItemEvent(e);
    }
  }

  const handleEditMenuWorksheetInstallment = async (e: any) => {       
    checkTransferSalary()
      .then(() => {
        return new Promise((resolve, reject) => {
          if (worksheetHeadData.tworksheetHead.typeworksheetflag === "02" || worksheetHeadData.tworksheetHead.typeworksheetflag === "04" || worksheetHeadData.tworksheetHead.typeworksheetflag === "05") {
            showNotification(NotificationType.ERROR, "ประเภทใบงานไม่ถูกต้อง");
            reject(false);
          } else if (!(worksheetHeadData.tassetHeadView?.assettype === "002" || worksheetHeadData.tassetHeadView?.assettype === "003")) {
            showNotification(NotificationType.ERROR, "ประเภทรถไม่ถูกต้อง");
            reject(false);
           } else {
            setCheckPermissionModalVisible(true);
            setMenuItemEvent(e);
            resolve(true);
          }
        });
      });
  }

  const handleEditMenuWorksheetWorktime = async (e: any) => {    
    checkTransferSalary()
      .then(() => {
        return new Promise((resolve, reject) => {
          if (worksheetHeadData.tworksheetHead.typeworksheetflag === "02" || worksheetHeadData.tworksheetHead.typeworksheetflag === "05") {
            showNotification(NotificationType.ERROR, "ประเภทใบงานไม่ถูกต้อง");
            reject(false);
          } else {
            setCheckPermissionModalVisible(true);
            setMenuItemEvent(e);
            resolve(true);
          }
        });
      });
  }

  const handleEditMenuWorksheetPromissNumber = async (e: any) => {
    checkTransferSalary()
      .then(() => {
        return new Promise((resolve, reject) => {
          if (worksheetHeadData.tworksheetHead.typeworksheetflag === "02" || worksheetHeadData.tworksheetHead.typeworksheetflag === "04" || worksheetHeadData.tworksheetHead.typeworksheetflag === "05") {
            showNotification(NotificationType.ERROR, "ประเภทใบงานไม่ถูกต้อง");
            reject(false);
          } else if (!(worksheetHeadData.tassetHeadView?.assettype === "002" || worksheetHeadData.tassetHeadView?.assettype === "003")) {
            showNotification(NotificationType.ERROR, "ประเภทรถไม่ถูกต้อง");
            reject(false);
          } else {
            setCheckPermissionModalVisible(true);
            setMenuItemEvent(e);
            resolve(true);
          }
        });
      });
  }

  // const handleEditMenuWorksheetOT = async (e: any) => {
  // const checkTransfer = await checkTransferSalary();
  // if (!checkTransfer) {
  //   return;
  //   } else if (!worksheetHeadData.tworksheetHead.iworksheetCode) {
  //     showNotification(NotificationType.ERROR, "ไม่พบใบสั่งงาน");
  //     return;
  //   } else {
  //     showConfirmEditDialog(e);
  //   }
  // }

  const handleEditMenuWorksheetOT = (e: any) => {
    if (!worksheetHeadData.tworksheetHead.iworksheetCode) {
      showNotification(NotificationType.ERROR, "ไม่พบใบสั่งงาน");
      return;
    } else if (worksheetHeadData.tworksheetHead.iotAmt! <= 0) {
      showNotification(NotificationType.ERROR, "ไม่พบว่ามีการคิด OT สำหรับใบสั่งงานนี้");
      return;
    } else {
      // showConfirmEditDialog(e);
      setViewWorksheetOTModalVisible(true);
    }
  }

  const handleEditMenuWorksheetDiscount = async (e: any) => {
    checkTransferSalary()
      .then(() => {
        return new Promise((resolve, reject) => {
          if (worksheetHeadData.tworksheetHead.flagtaxinvoid === "1") {
            showNotification(NotificationType.ERROR, "ใบสั่งงานออกใบกำกับแล้ว ไม่สามารถแก้ไขจำนวนเงินได้");
            reject(false);
          } else if (worksheetHeadData.tworksheetHead.typeworksheetflag === "01" && worksheetHeadData.tworksheetHead.constructionworkCode !== "") {
            showNotification(NotificationType.ERROR, "ใบงานย่อยอยู่ในใบงานเหมาคุมใบย่อยแล้ว ไม่สามารถให้ส่วนลดได้");
            reject(false);
          } else if (worksheetHeadData.tworksheetHead.itypepaymentCode !== "04") {
            if ((worksheetHeadData.tworksheetHead.itypepaymentCode !== "01" && worksheetHeadData.tworksheetHead.ipaymentStatus === "01") ||
              worksheetHeadData.tworksheetHead.ipaymentStatus === "01") {
              showNotification(NotificationType.ERROR, "ใบสั่งงานนี้การเงินรับชำระแล้ว ไม่สามารถแก้ไขจำนวนเงินได้");
              reject(false);
            } else {
              setCheckPermissionModalVisible(true);
              setMenuItemEvent(e);
              resolve(true);
            }
          } else {
            if (worksheetHeadData.tworksheetHead.iworksheetStatus !== "02" &&
              worksheetHeadData.tworksheetHead.iworksheetStatus !== "03" &&
              worksheetHeadData.tworksheetHead.iworksheetStatus !== "04" &&
              worksheetHeadData.tworksheetHead.iworksheetStatus !== "06" &&
              worksheetHeadData.tworksheetHead.iworksheetStatus !== "07") {
              showNotification(NotificationType.ERROR, "ไม่สามารถทำรายการได้เนื่องจาก :สถานะใบงานคือ " + tStatusworksheetRef.iname.trim());
              reject(false);
            } else {
              setCheckPermissionModalVisible(true);
              setMenuItemEvent(e);
              resolve(true);
            }
          }
        });
      });
  }

  const handleEditMenuWorksheetExtraprice = async (e: any) => {
    checkTransferSalary()
      .then(() => {
        return new Promise((resolve, reject) => {
          if (worksheetHeadData.tworksheetHead.flagtaxinvoid === "1") {
            showNotification(NotificationType.ERROR, "ใบสั่งงานออกใบกำกับแล้ว ไม่สามารถแก้ไขจำนวนเงินได้");
            reject(false);
          } else if (worksheetHeadData.tworksheetHead.ipaymentStatus === "01") {
            showNotification(NotificationType.ERROR, "การเงินรับชำระแล้ว ไม่สามารถแก้ไขได้");
            reject(false);
          } else if (worksheetHeadData.tworksheetHead.typeworksheetflag === "01" && worksheetHeadData.tworksheetHead.constructionworkCode) {
            showNotification(NotificationType.ERROR, "ใบงานย่อยอยู่ในใบงานเหมาคุมใบย่อยแล้ว ไม่สามารถแก้ไขรายการบริการอื่นๆได้");
            reject(false);
          } else {
            setCheckPermissionModalVisible(true);
            setMenuItemEvent(e);
            resolve(true);
          }
        });
      });
  }

  const handleEditWshwastetimeOt = async (e: any) => {
    const checkTransfer = await checkTransferSalary();
    if (worksheetHeadData.tworksheetHead.iworksheetStatus === "00") {
      showNotification(NotificationType.ERROR, "สถานะใบงานไม่สามารถแก้ไขได้ กรุณาแจ้งผู้ดูแลระบบ");
      return;
    } else {
      checkTransferSalary()
        .then(() => {
          return new Promise((resolve, reject) => {
            setCheckPermissionModalVisible(true);
            setMenuItemEvent(e);
            resolve(true);
          });
        });
    }
  }

  const handleClickMenuItem = (e: any) => {
    //File EditWorksheetHead IN RODYOK
    switch (e.key) {
      case "MENU_PAYMENT":
        //menuItem2_Click
        handleEditMenuPayment(e);
        break;
      case "MENU_TAX":
        //menuItem3_Click
        handleEditMenuTax(e);
        break;
      case "MENU_WORKSHEET_TYPE":
        //menuItem4_Click
        handleEditMenuWorksheetType(e);
        break;
      case "MENU_WORKSHEET_ASSET":
        //menuItem24_Click
        //ChangeType = EA
        handleEditMenuWorksheetAsset(e);
        break;
      case "MENU_DRIVER":
        //menuItem10_Click
        //ChangeType = ED
        handleEditMenuDriver(e);
        break;
      case "MENU_WORKSHEET_DETAIL":
        //menuItem6_Click
        //ChangeType = "ES"
        handleEditMenuWorksheetDetail(e);
        break;
      //TODO แก้ไขรายการเด็กติดรถ
      case "MENU_WORKSHEET_WORKER":
        //menuItem22_Click_1
        //ChangeType = "EW"
        handleEditMenuWorksheetWorker(e);
        break;
      //แก้ไขรายการใบงานย่อย
      //mEditWshContruction_Click
      case "MENU_WORKSHEET_CONSTRUCTION":
        handleEditMenuWorksheetConstruction(e);
        break;
      case "MENU_WORKTIME":
        //mEditTimer_Click
        handleEditMenuWorksheetWorktime(e);
        break;
      case "MENU_WORKSHEET_PROMISE":
        //menuItem14_Click
        //ChangeType = "EP"
        handleEditMenuWorksheetPromissNumber(e);
        break;
      //แก้ไขจำนวนวันรายการบริการ
      case "MENU_WORKSHEET_INSTALLMENT":
        // menuItem25_Click
        // ChangeType = "EI"
        handleEditMenuWorksheetInstallment(e);
        break;
      case "MENU_WORKSHEET_OT":
        //menuItem12_Click
        handleEditMenuWorksheetOT(e);
        break;
      //ให้ส่วนลด
      case "MENU_WORKSHEET_DISCOUNT":
        //menuItem5_Click
        handleEditMenuWorksheetDiscount(e);
        break;
      case "MENU_WORKSHEET_EXTRAPRICE":
        //menuItem7_Click
        handleEditMenuWorksheetExtraprice(e);
        break;
      //แก้ไขประเภทใบงานค่าเสียเวลา/ใบงาน OT
      case "MENU_WORKSHEET_WSHWASTETIME_OVERTIME":
        //editWshWasteTimeOvertime_Click
        handleEditWshwastetimeOt(e);
        break;
      //ความเคลื่อนไหวบิล
      case "MENU_REPORT_WORKSHEET_MOVEMENT":
        //menuItem21_Click
        setReportWorksheeMovementModalVisible(true);
        break;
    }
  }

  return (
    <>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Button
              type="text"
              // danger
              size="small"
              icon={<ArrowLeftOutlined />}
              style={{
                backgroundColor: "#EBEDEF",
                margin: "0 0 0 10em",
                fontWeight: "bold",
                width: "50px",
              }}
              onClick={() => setOpenDrawerView(false)}
            ></Button>
            <Space align="baseline" direction="horizontal" size="small">
              <span>
                <ButtonContainer>
                  <Button
                    type="text"
                    size="small"
                    style={{ margin: "0 0 0 0.5em", fontWeight: "bold" }}
                    onClick={() => handlePrintWorksheet()}
                    disabled={checkDisableButtonPrint()}
                  >
                    พิมพ์
                  </Button>
                  <Modal
                    style={{ top: 20 }}
                    width={"90vw"}
                    title="ตัวอย่างใบงาน"
                    bodyStyle={{ height: "85vh" }}
                    centered={true}
                    visible={isPreviewModalVisible}
                    okButtonProps={{ style: { display: 'none' } }}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    onCancel={() => setIsPreviewModalVisible(false)}
                  >
                    <iframe title=" " src={worksheetPreviewData} width={"100%"} height={"100%"}></iframe>
                  </Modal>
                </ButtonContainer>
              </span>
            </Space>
          </div>
        }
        width="95%"
        // onClose={this.onClose}
        visible={openDrawerView}
        // visible={true}
        forceRender={true}
        placement="right"
        bodyStyle={{ paddingBottom: 80, padding: "10px 24px 24px 24px" }}
        closable={false}
      >
        <Spin spinning={showSpin}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              // alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Affix>
              <Steps
                current={currentStep}
                size="small"
                direction="vertical"
                progressDot
              >
                <Step title="จอง" />
                <Step title="ยืนยันใบงาน" />
                <Step title="ปล่อยใบงาน" />
                <Step title="รับใบงานกลับ" />
                <Step title="ส่งเร่งรัด" />
                <Step title="อยู่เร่งรัด" />
                <Step title="รับชำระเงิน" />
                <Step title="หนี้สงสัยจะสูญ" />
                <Step title="ยกเลิกใบงาน" />
                <Step title="จบ" />
              </Steps>
            </Affix>
            <Layout style={{ padding: "0 0 0px 16px" }}>
              <Space style={{ marginBottom: "8px" }}>
                <Dropdown
                  overlayStyle={{ border: "3px solid #EBEDEF" }}
                  overlay={menuEditGroupMain1}
                  placement="bottomLeft"
                  trigger={["click"]}
                //disabled
                >
                  <Button>
                    แก้ไขประเภท <DownOutlined />
                  </Button>
                </Dropdown>
                <Dropdown
                  overlayStyle={{ border: "3px solid #EBEDEF" }}
                  overlay={menuEditGroupMain2}
                  placement="bottomLeft"
                  trigger={["click"]}
                >
                  <Button>
                    แก้ไขรายการ <DownOutlined />
                  </Button>
                </Dropdown>
                <Dropdown
                  overlayStyle={{ border: "3px solid #EBEDEF" }}
                  overlay={menuEditGroupMain3}
                  placement="bottomLeft"
                  trigger={["click"]}
                // disabled
                >
                  <Button>
                    แก้ไขอื่นๆ <DownOutlined />
                  </Button>
                </Dropdown>
                <Dropdown
                  overlay={menuReportGroup}
                  placement="bottomLeft"
                  trigger={["click"]}
                // disabled
                >
                  <Button>
                    รายงาน <DownOutlined />
                  </Button>
                </Dropdown>
              </Space>
              <Content
                style={{
                  padding: 10,
                  margin: 0,
                  minHeight: 280,
                  backgroundColor: "#EBEDEF",
                }}
              >
                {/* ส่วนบน สีเทา*/}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {/* บน-ซ้าย */}
                  <Content
                    style={{
                      padding: "15px 0 10px 24px",
                      margin: 0,
                      minHeight: 180,
                      minWidth: 700,
                    }}
                  >
                    <Row>
                      <Col span={12}>ใบจอง :</Col>
                      <Col span={12}>ใบงาน : </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Typography.Title level={4} style={{ color: "black" }}>
                          {worksheetHeadData.tworksheetHead?.ireserveCode}
                        </Typography.Title>
                        <Typography.Title
                          level={4}
                          style={{ marginTop: 0, color: "black" }}
                        >
                          {ryReftypework?.name}
                        </Typography.Title>
                      </Col>
                      <Col span={12}>
                        <Typography.Title
                          level={4}
                          style={{ marginBottom: 0, color: "black" }}
                        >
                          {worksheetHeadData.tworksheetHead?.iworksheetCode == null
                            ? "-"
                            : worksheetHeadData.tworksheetHead?.iworksheetCode}
                        </Typography.Title>
                        <Typography.Title
                          level={4}
                          style={{ marginTop: 0, color: "black" }}
                        >
                          {tTypeworksheetRef?.iname}
                        </Typography.Title>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={12}>วัน - เวลาที่ทำงาน :</Col>
                      <Col span={12} />
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Typography.Title level={4}>
                          ตั้งแต่
                          {toThDate(
                            worksheetHeadData.tworksheetHead?.istartDate
                              ? worksheetHeadData.tworksheetHead?.istartDate
                              : ""
                          )}
                          {"  - "}
                          {formatTime(
                            worksheetHeadData.tworksheetHead?.istartTime
                              ? worksheetHeadData.tworksheetHead?.istartTime
                              : ""
                          )}
                          น.
                        </Typography.Title>
                      </Col>
                      <Col span={12}>
                        <Typography.Title level={4} style={{ marginBottom: 0 }}>
                          ถึง
                          {toThDate(
                            worksheetHeadData.tworksheetHead?.iendDate
                              ? worksheetHeadData.tworksheetHead?.iendDate
                              : ""
                          )}
                          {"  - "}
                          {formatTime(
                            worksheetHeadData.tworksheetHead?.iendTime
                              ? worksheetHeadData.tworksheetHead?.iendTime
                              : ""
                          )}
                          น.
                        </Typography.Title>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Typography.Title level={4}>
                          {worksheetHeadData.tworksheetHead?.ilocation}
                        </Typography.Title>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Typography.Title level={4}>
                          {worksheetHeadData.tworksheetHead?.icontactName}
                          {worksheetHeadData.tworksheetHead?.icontactPhone}
                        </Typography.Title>
                      </Col>
                    </Row>
                  </Content>

                  {/* บน-ขวา */}
                  <Content
                    style={{
                      padding: "15px 0 10px 24px",
                      margin: 0,
                      minHeight: 180,
                      minWidth: 200,
                    }}
                  >
                    <Row>
                      <Col span={12}></Col>
                      <Col span={12}>
                        <Row> สถานะ : </Row>
                        <Row>
                          <Typography.Title keyboard level={2}>
                            {tStatusworksheetRef?.iname}
                          </Typography.Title>
                        </Row>
                      </Col>
                    </Row>
                  </Content>
                </div>

                {/* ส่วงล่าง สีขาว*/}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "baseline",
                    justifyContent: "space-between",
                  }}
                >
                  {/* ล่าง-ฝั่งซ้าย */}
                  <Content
                    style={{
                      minHeight: 380,
                      minWidth: "75%",
                    }}
                  >
                    <Card
                      style={{
                        width: "auto",
                        borderRadius: "10px 10px 10px 10px",
                        marginBottom: 5,
                      }}
                    >
                      <Col span={24}>
                        <p>
                          <Typography.Title level={3} style={{ margin: 0 }}>
                            {worksheetHeadData.tworksheetHead?.icustName} (
                            {worksheetHeadData.tworksheetHead?.icustCode})
                          </Typography.Title>
                          <Typography.Title level={4} style={{ margin: 0 }}>
                            {customer?.itypecustomerName}
                          </Typography.Title>
                        </p>
                        <p>
                          <Typography.Title level={4} style={{ margin: 0 }}>
                            {worksheetHeadData.tworksheetHead?.icustAddress}
                          </Typography.Title>
                          <Typography.Title level={4} style={{ margin: 0 }}>
                            {worksheetHeadData.tworksheetHead?.icustPhone}
                          </Typography.Title>
                        </p>

                        <Space align="baseline">
                          <Typography.Title level={4}>
                            วงเงิน
                          </Typography.Title>
                          <Typography.Title level={4} style={{ margin: 0 }}>
                            {formatNumber(customer.icreditamount)}
                          </Typography.Title>
                          <Typography.Title
                            level={4}
                          >
                            บาท
                          </Typography.Title>

                          <Typography.Title
                            level={4}
                            style={{ margin: "0 0 0 24px" }}
                          >
                            ค้างชำระ
                          </Typography.Title>
                          <Typography.Title level={4} type="danger">
                            {formatNumber(customer.currentusage)}
                          </Typography.Title>
                          <Typography.Title level={4}>บาท</Typography.Title>
                        </Space>
                      </Col>
                    </Card>

                    <Card
                      style={{
                        width: "auto",
                        borderRadius: "10px 10px 10px 10px",
                      }}
                    >
                      <Col span={24}>
                        <p>
                          <Typography.Title level={5} style={{ margin: 0 }}>
                            รถ/ตู้/แผ่นเหล็ก :
                          </Typography.Title>
                          <Typography.Title level={3} style={{ margin: 0 }}>
                            {worksheetHeadData.tassetHeadView?.assetFullName} {worksheetHeadData.tassetHeadView?.id ? " (" + worksheetHeadData.tassetHeadView?.id + ")" : ""}

                          </Typography.Title>
                        </p>
                        <p>
                          <Typography.Title level={5} style={{ margin: 0 }}>
                            พนักงานขับรถ :
                          </Typography.Title>
                          <Typography.Title level={3} style={{ margin: 0 }}>
                            {worksheetHeadData.tworksheetHead?.idriverName}
                            {worksheetHeadData.tworksheetHead?.idriverCode ? " (" + worksheetHeadData.tworksheetHead?.idriverCode + ")" : ""}
                          </Typography.Title>
                        </p>

                        <Space align="baseline" direction="horizontal">
                          <Typography.Title level={4} style={{ margin: 0 }}>
                            เลขที่สัญญา :
                          </Typography.Title>
                          <Typography.Title level={4} style={{ margin: 0 }}>
                            {worksheetHeadData.twshPromiss?.promissnumber}
                          </Typography.Title>
                          <Typography.Title level={4} style={{ margin: "0 0 0 24px" }}>
                            จำนวน :
                          </Typography.Title>
                          <Typography.Title level={4} style={{ margin: 0 }}>
                            {worksheetHeadData.twshPromiss?.instalment}
                          </Typography.Title>
                          <Typography.Title level={4}>
                            เดือน/วัน
                          </Typography.Title>
                        </Space>

                        {/* Tab */}
                        {/* <Divider dashed orientation="center"/> */}
                        <Tabs>
                          <TabPane tab="รายการบริการ" key="1">
                            <ServiceDetailViewTab
                              worksheetHeadData={worksheetHeadData}
                            ></ServiceDetailViewTab>
                          </TabPane>
                          <TabPane tab="รายการใบสั่งงานของเหมา" key="2">
                            <ContractorWorkDetailViewTab
                              worksheetHeadData={worksheetHeadData}
                            ></ContractorWorkDetailViewTab>
                          </TabPane>
                          <TabPane tab="รายการแผ่นเหล็ก" key="3">
                            <WorksheetSteelDetailViewTab
                              worksheetHeadData={worksheetHeadData}
                            ></WorksheetSteelDetailViewTab>
                          </TabPane>
                          <TabPane tab="รายการซ่อม/ขาย/อื่นๆ" key="4">
                            <WorksheetOtherserviceViewTab
                              worksheetHeadData={worksheetHeadData}
                            ></WorksheetOtherserviceViewTab>
                          </TabPane>
                          <TabPane tab="รายการพนักงานติดรถ" key="5">
                            <WorkerDetailViewTab
                              worksheetHeadData={worksheetHeadData}
                            >
                            </WorkerDetailViewTab>
                          </TabPane>
                          <TabPane tab="ค่าบริการพิเศษ" key="6">
                            <WorksheetExtrapriceViewTab
                              worksheetHeadData={worksheetHeadData}
                            >
                            </WorksheetExtrapriceViewTab>
                          </TabPane>
                        </Tabs>
                      </Col>
                    </Card>
                  </Content>

                  {/* ล่าง-ฝั่งขวา */}
                  <Content
                    style={{
                      margin: 4,
                      minHeight: 380,
                      minWidth: "25%",
                    }}
                  >
                    <Card
                      style={{
                        width: "auto",
                        borderRadius: "10px 10px 10px 10px",
                      }}
                    >
                      <Row>
                        <Col span={12}>ค่าบริการ :</Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                          <Typography.Title level={4}>
                            {formatNumber(worksheetHeadData.tworksheetHead?.iserviceAmt)}
                          </Typography.Title>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>ค่าบริการพิเศษอื่น ๆ :</Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                          <Typography.Title level={4}>
                            {formatNumber(worksheetHeadData.tworksheetHead?.iextraAmt)}
                          </Typography.Title>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>OT :</Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                          <Typography.Title level={4}>
                            {formatNumber(worksheetHeadData.tworksheetHead?.iotAmt)}
                          </Typography.Title>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>ส่วนลด :</Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                          <Typography.Title level={4}>
                            {formatNumber(worksheetHeadData.tworksheetHead?.idiscount)}
                          </Typography.Title>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>รวม :</Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                          <Typography.Title level={4}>
                            {formatNumber(worksheetHeadData?.netServiceAmt)}
                          </Typography.Title>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>ค่าบริการก่อนภาษี :</Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                          <Typography.Title level={4} type="danger">
                            {formatNumber(worksheetHeadData.tworksheetHead?.itotal1Amt)}
                          </Typography.Title>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>ภาษี :</Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                          <Typography.Title level={4}>
                            {tTypetaxRef?.iname}
                          </Typography.Title>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>จำนวนเงินภาษี :</Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                          <Typography.Title level={4}>
                            {formatNumber(worksheetHeadData.tworksheetHead?.itaxAmt)}
                          </Typography.Title>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>รวมทั้งหมด :</Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                          <Typography.Title level={4} type="danger">
                            {formatNumber(worksheetHeadData.tworksheetHead?.itotal2Amt)}
                          </Typography.Title>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>ชำระเงินแบบ :</Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                          <Typography.Title level={4}>
                            {tTypepaymentRef?.iname}
                          </Typography.Title>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>จำนวนเงินที่ชำระ :</Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                          <Typography.Title level={4}>
                            {ryPercentpayindeposit?.remark} {ryPercentpayindeposit?.value} %
                          </Typography.Title>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>รับชำระ :</Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                          <Typography.Title level={4}>
                            {formatNumber(worksheetHeadData.tworksheetHead?.idepositAmt)}
                          </Typography.Title>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>ค้างชำระ :</Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                          <Typography.Title level={4} type="danger">
                            {formatNumber(worksheetHeadData.tworksheetHead?.iremainAmt)}
                          </Typography.Title>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>การเงินรับชำระ :</Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                          <Typography.Title level={4} type="danger">
                            {formatNumber(worksheetHeadData.tworksheetHead?.payedamount)}
                          </Typography.Title>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>จำนวนเงินประกัน :</Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                          <Typography.Title level={4}>
                            {formatNumber(worksheetHeadData.tworksheetHead?.iinsuranceAmt)}
                          </Typography.Title>
                        </Col>
                      </Row>

                      <div
                        style={{
                          alignItems: "flex-start",
                          margin: "2em 0 1em 0",
                          paddingLeft: "1em",
                          wordWrap: "break-word",
                        }}
                      >
                        <Checkbox style={{ margin: "0px 0px 5px 0px", width: "100%" }}
                          checked={
                            worksheetHeadData.tworksheetHead?.flgDisplaydetail
                              ? true
                              : false
                          }
                        >
                          แสดงรายละเอียดค่าใช้บริการ
                        </Checkbox>
                        <Checkbox style={{ margin: "0px 0px 5px 0px", width: "100%" }}
                          checked={
                            worksheetHeadData.tworksheetHead?.iflgWaitforcall
                              ? true
                              : false
                          }
                        >
                          รอลูกค้ายืนยัน
                        </Checkbox>
                        <Checkbox style={{ margin: "0px 0px 5px 0px", width: "100%" }}
                          checked={
                            worksheetHeadData.tworksheetHead?.iflgWshOt
                              ? true
                              : false
                          }
                        >
                          ใบงานโอทีอย่างเดียว
                        </Checkbox>
                      </div>
                      <Row>
                        <Col span={24} style={{ textAlign: "left" }}>
                          <Typography.Title level={5} type="danger">
                            พนักงาน(สร้าง)   : {createUser.ifullName}
                          </Typography.Title>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} style={{ textAlign: "left" }}>
                          <Typography.Title level={5} style={{ marginLeft: 0 }} type="danger">
                            พนักงาน(แก้ไข)   : {lastUser.ifullName}
                          </Typography.Title>
                        </Col>
                      </Row>
                    </Card>
                  </Content>
                </div>
              </Content>
            </Layout>
          </div>
        </Spin>
      </Drawer>
      <div>
        {checkPermissionModalVisible && (
          <CheckPermissionModal
            checkPermissionModalVisible={checkPermissionModalVisible}
            setCheckPermissionModalVisible={setCheckPermissionModalVisible}
            setPermissionFlg={setPermissionFlg}
            event={menuItemEvent}
            setMenuItemSelectKey={setMenuItemSelectKey}
            worksheetHeadData={worksheetHeadData}
            setWorksheetHeadData={setWorksheetHeadData}
            setTUserHeadUpdate={setTUserHeadUpdate}
          ></CheckPermissionModal>
        )}
      </div>
      <div>
        {editPaymentModalVisible && (
          <EditPaymentTypeModal
            editPaymentModalVisible={editPaymentModalVisible}
            setEditPaymentModalVisible={setEditPaymentModalVisible}
            worksheetHeadData={worksheetHeadData}
            setWorksheetHeadData={setWorksheetHeadData}
            tUserHeadUpdate={tUserHeadUpdate}
          ></EditPaymentTypeModal>
        )}
      </div>
      {/* <div>
        {checkReceiptDepositModalVisible && (
          <ReceiptDepositModal
            checkReceiptDepositModalVisible={checkReceiptDepositModalVisible}
            setCheckReceiptDepositModalVisible={setCheckReceiptDepositModalVisible}
          ></ReceiptDepositModal>
        )}
      </div> */}
      <div>
        {editTaxModalVisible && (
          <EditTaxTypeModal
            editTaxModalVisible={editTaxModalVisible}
            setEditTaxModalVisible={setEditTaxModalVisible}
            // setUpdateStatus={setUpdateStatus}
            worksheetHeadData={worksheetHeadData}
            setWorksheetHeadData={setWorksheetHeadData}
            tUserHeadUpdate={tUserHeadUpdate}
          ></EditTaxTypeModal>
        )}
      </div>
      <div>
        {editWorksheetTypeModalVisible && (
          <EditWorksheetTypeModal
            editWorksheetTypeModalVisible={editWorksheetTypeModalVisible}
            setEditWorksheetTypeModalVisible={setEditWorksheetTypeModalVisible}
            // setUpdateStatus={setUpdateStatus}
            worksheetHeadData={worksheetHeadData}
            setWorksheetHeadData={setWorksheetHeadData}
            tUserHeadUpdate={tUserHeadUpdate}
          ></EditWorksheetTypeModal>
        )}
      </div>
      <div>
        {editWorksheetAssetModalVisible && (
          <EditWorksheetAssetModal
            editWorksheetAssetModalVisible={editWorksheetAssetModalVisible}
            setEditWorksheetAssetModalVisible={setEditWorksheetAssetModalVisible}
            // setUpdateStatus={setUpdateStatus}
            worksheetHeadData={worksheetHeadData}
            setWorksheetHeadData={setWorksheetHeadData}
            tUserHeadUpdate={tUserHeadUpdate}
          ></EditWorksheetAssetModal>
        )}
      </div>
      <div>
        {editWorksheetDetailListModalVisible && (
          <EditWorksheetDetailListModal
            editWorksheetDetailListModalVisible={editWorksheetDetailListModalVisible}
            setEditWorksheetDetailListModalVisible={setEditWorksheetDetailListModalVisible}
            // setUpdateStatus={setUpdateStatus}
            worksheetHeadData={worksheetHeadData}
            setWorksheetHeadData={setWorksheetHeadData}
            tUserHeadUpdate={tUserHeadUpdate}
          ></EditWorksheetDetailListModal>
        )}
      </div>
      <div>
        {editWorksheetWorkerListVisible && (
          <EditWorksheetWorkerListModal
            editWorksheetWorkerListVisible={editWorksheetWorkerListVisible}
            setEditWorksheetWorkerListVisible={setEditWorksheetWorkerListVisible}
            // setUpdateStatus={setUpdateStatus}
            worksheetHeadData={worksheetHeadData}
            setWorksheetHeadData={setWorksheetHeadData}
            tUserHeadUpdate={tUserHeadUpdate}
          ></EditWorksheetWorkerListModal>
        )}
      </div>

      <div>
        {editWorksheetConstructionListVisible && (
          <EditWorksheetConstructionListModal
            editWorksheetConstructionListVisible={editWorksheetConstructionListVisible}
            setEditWorksheetConstructionListVisible={setEditWorksheetConstructionListVisible}
            // setUpdateStatus={setUpdateStatus}
            worksheetHeadData={worksheetHeadData}
            setWorksheetHeadData={setWorksheetHeadData}
            tUserHeadUpdate={tUserHeadUpdate}
          ></EditWorksheetConstructionListModal>
        )}
      </div>
      <div>
        {editWorksheetExtraPriceModalVisible && (
          <EditWorksheetExtraPriceModal
            editWorksheetExtraPriceModalVisible={editWorksheetExtraPriceModalVisible}
            setEditWorksheetExtraPriceModalVisible={setEditWorksheetExtraPriceModalVisible}
            // setUpdateStatus={setUpdateStatus}
            worksheetHeadData={worksheetHeadData}
            setWorksheetHeadData={setWorksheetHeadData}
            tUserHeadUpdate={tUserHeadUpdate}
          ></EditWorksheetExtraPriceModal>
        )}
      </div>
      <div>
        {editDriverModalVisible && (
          <EditDriverModal
            editDriverModalVisible={editDriverModalVisible}
            setEditDriverModalVisible={setEditDriverModalVisible}
            // setUpdateStatus={setUpdateStatus}
            worksheetHeadData={worksheetHeadData}
            setWorksheetHeadData={setWorksheetHeadData}
            tUserHeadUpdate={tUserHeadUpdate}
          ></EditDriverModal>
        )}
      </div>
      <div>
        {editWorktimeModalVisible && (
          <EditWorktimeModal
            editWorktimeModalVisible={editWorktimeModalVisible}
            setEditWorktimeModalVisible={setEditWorktimeModalVisible}
            // setUpdateStatus={setUpdateStatus}
            worksheetHeadData={worksheetHeadData}
            setWorksheetHeadData={setWorksheetHeadData}
            tUserHeadUpdate={tUserHeadUpdate}
          ></EditWorktimeModal>
        )}
      </div>
      <div>
        {editWorksheetPromiseModalVisible && (
          <EditWorksheetPromiseModal
            editWorksheetPromiseModalVisible={editWorksheetPromiseModalVisible}
            setEditWorksheetPromiseModalVisible={setEditWorksheetPromiseModalVisible}
            // setUpdateStatus={setUpdateStatus}
            worksheetHeadData={worksheetHeadData}
            setWorksheetHeadData={setWorksheetHeadData}
            tUserHeadUpdate={tUserHeadUpdate}
          ></EditWorksheetPromiseModal>
        )}
      </div>
      <div>
        {editWorksheetInstallmentModalVisible && (
          <EditWorksheetInstallmentModal
            editWorksheetInstallmentModalVisible={editWorksheetInstallmentModalVisible}
            setEditWorksheetInstallmentModalVisible={setEditWorksheetInstallmentModalVisible}
            // setUpdateStatus={setUpdateStatus}
            worksheetHeadData={worksheetHeadData}
            setWorksheetHeadData={setWorksheetHeadData}
            tUserHeadUpdate={tUserHeadUpdate}
          ></EditWorksheetInstallmentModal>
        )}
      </div>
      {/* <div>
        {editWorksheetOTModalVisible && (
          <EditWorksheetOTModal
            editWorksheetOTModalVisible={editWorksheetOTModalVisible}
            setEditWorksheetOTModalVisible={setEditWorksheetOTModalVisible}
            worksheetHeadData={worksheetHeadData}
            setWorksheetHeadData={setWorksheetHeadData}
            asset={worksheetHeadData.tassetHeadView!}
            vatType={tTypetaxRef}
            tUserHeadUpdate={tUserHeadUpdate}
          ></EditWorksheetOTModal>
        )}
      </div> */}
      <div>
        {viewWorksheetOTModalVisible && (
          <ViewWorksheetOTModal
            viewWorksheetOTModalVisible={viewWorksheetOTModalVisible}
            setViewWorksheetOTModalVisible={setViewWorksheetOTModalVisible}
            worksheetHeadData={worksheetHeadData}
            setWorksheetHeadData={setWorksheetHeadData}
            asset={worksheetHeadData.tassetHeadView!}
            vatType={tTypetaxRef}
          ></ViewWorksheetOTModal>
        )}
      </div>
      <div>
        {editWorksheetDiscountModalVisible && (
          <EditDiscountModal
            editWorksheetDiscountModalVisible={editWorksheetDiscountModalVisible}
            setEditWorksheetDiscountModalVisible={setEditWorksheetDiscountModalVisible}
            worksheetHeadData={worksheetHeadData}
            setWorksheetHeadData={setWorksheetHeadData}
            // vatType={tTypetaxRef}
            tUserHeadUpdate={tUserHeadUpdate}
          ></EditDiscountModal>
        )}
      </div>
      <div>
        {editWshWasteTimeOvertimeModalVisible && (
          <EditWshWasteTimeOvertimeModal
            editWshWasteTimeOvertimeModalVisible={editWshWasteTimeOvertimeModalVisible}
            setEditWshWasteTimeOvertimeModalVisible={setEditWshWasteTimeOvertimeModalVisible}
            worksheetHeadData={worksheetHeadData}
            setWorksheetHeadData={setWorksheetHeadData}
            tUserHeadUpdate={tUserHeadUpdate}
          ></EditWshWasteTimeOvertimeModal>
        )}
      </div>
      <div>
        {reportWorksheeMovementModalVisible && (
          <ReportWorksheeMovementModal
            reportWorksheeMovementModalVisible={reportWorksheeMovementModalVisible}
            setReportWorksheeMovementModalVisible={setReportWorksheeMovementModalVisible}
            worksheetHeadData={worksheetHeadData}
          ></ReportWorksheeMovementModal>
        )}
      </div>
    </>
  );
};

export default ChartBookingForm;