import { ClearOutlined, PlusSquareOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { TCustomerHeadView } from 'src/domain/master/view/tCustomerHeadView';
import { ReturnForWitholdingTaxView } from 'src/domain/view/returnForWitholdingView';
import PageHeader from 'src/layouts/PageHeader';
import tCustomerHeadService from 'src/services/customer/tCustomerHeadService';
import returnForWitholdingTaxService from 'src/services/returnForWitholdingTaxService';
import Constants from 'src/utils/constants';
import { toThDate } from 'src/utils/dateTimeUtils';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber } from 'src/utils/numberUtils';
import ReturnForWitholdingTaxDrawer from './ReturnForWitholdingTaxDrawer';

const { Option } = Select;

const ReturnForWitholdingTaxList: React.FC<ReturnForWitholdingTaxView> = () => {
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [onDrawerVisible, setOnDrawerVisible] = useState<boolean>(false);
  const [returnForWitholdingTaxViews, setReturnForWitholdingTaxViews] = useState<ReturnForWitholdingTaxView[]>([]);
  const [customers, setCustomers] = useState<TCustomerHeadView[]>([]);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 10,
      render: (value: number, item: ReturnForWitholdingTaxView, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
    },
    {
      title: "วันที่ยกเลิก",
      dataIndex: "entrydate",
      key: "entrydate",
      width: 60,
      render: (value: any, item: ReturnForWitholdingTaxView) => (<>{toThDate(item.entrydate)}</>)
    },
    {
      title: "เลขที่ใบงาน/เลขที่ใบแจ้งหนี้",
      dataIndex: "code",
      key: "code",
      width: 150,
    },
    {
      title: "ประเภท",
      dataIndex: "returnType",
      key: "returnType",
      width: 80,
      render: (value: any, item: ReturnForWitholdingTaxView) => (<>{item.returnType === "DEBITNOTE" ? "ใบแจ้งหนี้" : "ใบงาน"}</>)
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "customerName",
      key: "customerName",
      width: 150,
    },
    {
      title: "ค่าบริการ",
      dataIndex: "serviceAmount",
      key: "serviceAmount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: ReturnForWitholdingTaxView) => (<>{formatNumber(item.serviceAmount)}</>)
    },
    {
      title: "ภาษีมูลค่าเพิ่ม",
      dataIndex: "taxAmount",
      key: "taxAmount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: ReturnForWitholdingTaxView) => (<>{formatNumber(item.taxAmount)}</>)
    },
    {
      title: "รวมค่าบริการ",
      dataIndex: "totalAmount",
      key: "totalAmount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: ReturnForWitholdingTaxView) => (<>{formatNumber(item.totalAmount)}</>)
    },
    {
      title: "ผู้บันทึก",
      dataIndex: "createUserFullName",
      key: "createUserFullName",
      width: 130,
      align: "center" as "center",
      render: (value: any, item: ReturnForWitholdingTaxView) => (<>{item.createUserFullName}</>)
    },
    {
      title: "หมายเหตุ",
      dataIndex: "userRemark",
      key: "userRemark",
      width: 350,
      render: (value: any, item: ReturnForWitholdingTaxView) => (<>{item.userRemark}</>)
    },
  ];

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    initData();
  }, []);

  function onReset() {
    initData();
  }

  const initData = async () => {
    form.setFieldsValue({
      entrydateFrom: moment(new Date(), Constants.DATE_FORMAT),
      entrydateTo: moment(new Date(), Constants.DATE_FORMAT),
      customercode: undefined,
      code: undefined,
    });
  }

  const handleSearchCustomer = async (data: any) => {
    if (data.length < 3) return;
    const res = await tCustomerHeadService.getCustomerByParam(data);
    setCustomers(res.data);
  };
  const onFinish = async (values: any) => {
    setLoading(true);

    const customercode = values.customercode ? values.customercode : "";
    const code = values.code ? values.code : "";
    let entrydateFrom = moment(values.entrydateFrom).format("yyyyMMDD");
    let entrydateTo = moment(values.entrydateTo).format("yyyyMMDD");

    const res = await returnForWitholdingTaxService.findReturnForWitholdingTaxViews(
      entrydateFrom,
      entrydateTo,
      customercode,
      code);
    setReturnForWitholdingTaxViews(res.data);
    setLoading(false);
  }

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
    form.resetFields();
  };

  return (
    <>
      <PageHeader title="โปรแกรมยกเลิกการหักภาษี ณ ที่จ่าย">
        <Button
          size="small"
          type="primary"
          icon={<PlusSquareOutlined />}
          onClick={() => setOnDrawerVisible(true)}
        >
          เพิ่ม
        </Button>
      </PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="returnFnPayment"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
            }}
          >
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>วันที่ยกเลิก ตั้งแต่: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="entrydateFrom"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ชื่อลูกค้า: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item name="customercode" rules={[{ required: false }]}>
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกลูกค้า"
                    optionFilterProp="children"
                    filterOption={false}
                    onSearch={handleSearchCustomer}
                  >
                    {customers.map((obj) => {
                      return (
                        <Option key={obj.icustCode} value={obj.icustCode}>
                          {obj.iprenameName}{obj.iname}  {obj.isurname ? obj.isurname : ""}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ถึง: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="entrydateTo"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เลขที่ใบงาน/เลขที่ใบแจ้งหนี้: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="code"
                  rules={[{ required: false }]}
                >
                  <Input
                    allowClear
                    placeholder="ป้อนเลขที่ใบงาน/เลขที่ใบแจ้งหนี้"
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: 'center',
              paddingBottom: "1em",
              borderBlockEnd: "2px solid #c9c9c9",
            }}
          >
            <Space>
              <Button
                size="small"
                type="primary"
                icon={<SearchOutlined />}
                onClick={form.submit}
              >
                ค้นหา
              </Button>
              <Button
                size="small"
                type="primary"
                icon={<ClearOutlined />}
                onClick={onReset}
              >
                ล้าง
              </Button>
            </Space>
          </div>
        </Form>

        <div>
          <Table
            columns={columns}
            dataSource={returnForWitholdingTaxViews}
            size="small"
            loading={loading}
            scroll={{ x: 1800 }}
            pagination={{
              pageSize: 10,
              onChange(current) {
                setPage(current);
              }
            }}
            rowKey={(item: ReturnForWitholdingTaxView) => item.code!}
          />
        </div>
        <div>
          {onDrawerVisible && (
            <ReturnForWitholdingTaxDrawer
              onDrawerVisible={onDrawerVisible}
              setOnDrawerVisible={setOnDrawerVisible}
            ></ReturnForWitholdingTaxDrawer>
          )}
        </div>
      </Content>
    </>
  )
}

export default ReturnForWitholdingTaxList