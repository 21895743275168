import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { UserInfo } from 'src/domain/userInfo';
import { AuthenUser } from "./authenUser";

export interface AuthState {
  authenUser: AuthenUser;
}

const initialState: AuthState = {
  authenUser: { user: {} as UserInfo, isLogin: false, expiredTime: 0 },
};

const authenSlice = createSlice({
  name: "authen",
  initialState: initialState,
  reducers: {
    login: (state: AuthState, action: PayloadAction<AuthenUser>) => {
      state.authenUser = action.payload;
    },
    logout: (state: AuthState, action: PayloadAction<AuthenUser>) => {
      state.authenUser = action.payload;
    },
    validate: (state: AuthState, action: PayloadAction<AuthenUser>) => {
      state.authenUser = action.payload;
    },
  },
});

export const { login, logout, validate } = authenSlice.actions;

export default authenSlice;
