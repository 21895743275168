import http from "../../lib/http";
import Constants from "../../utils/constants";

const getTBankHeadList = () => {
    return http.get(Constants.DEFAULT_URL + "/tBankHead");
};

const getBankHeads = (searchText: string) => {
    return http.get(Constants.DEFAULT_URL + "/tBankHead/search", {
        params: {
            searchText
        }
    });
};

const getBankHeadByIBankCode = (ibankCode: string) => {
    return http.get(Constants.DEFAULT_URL + "/tBankHead/" + ibankCode);
}

const checkExistData = (shortName: string, bankName: string) => {
    return http.get(Constants.DEFAULT_URL + "/tBankHead/checkExistData", {
        params: {
            shortName,
            bankName,
        }
    });
}

const updateBankHead = (bankHead: any) => {
    return http.put(Constants.DEFAULT_URL + "/tBankHead", bankHead);
}

const postBankHead = (bankHead: any) => {
    return http.post(Constants.DEFAULT_URL + "/tBankHead", bankHead);
}

const getNextBankHeadCode = () => {
    return http.get(Constants.DEFAULT_URL + "/tBankHead/nextSeq");
}

const defaultApi = {
    getTBankHeadList,
    getBankHeads,
    getBankHeadByIBankCode,
    checkExistData,
    updateBankHead,
    postBankHead,
    getNextBankHeadCode
}
export default defaultApi;