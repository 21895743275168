import http from "../../lib/http";
import Constants from "../../utils/constants";

const isExistCustomer = (custCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tDebitorDetail/isExistCustomer", {
    params: {
      custCode
    }
  });
};

const getByCustomerCode = (custCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tDebitorDetail/getByCustomerCode", {
    params: {
      custCode
    }
  });
};

const getDebitorDetailByCode = (code: string) => {
  return http.get(Constants.DEFAULT_URL + "/tDebitorDetail/getDebitorDetailByCode/" + code);
};

const postDebitorDetail = (tDebitorDetail: any) => {
  return http.post(Constants.DEFAULT_URL + "/tDebitorDetail", tDebitorDetail);
}

const deleteDebitorDetail = (id: number) => {
  return http.delete(Constants.DEFAULT_URL + "/tDebitorDetail/" + id);
}

const defaultApi = {
  isExistCustomer,
  getByCustomerCode,
  getDebitorDetailByCode,
  postDebitorDetail,
  deleteDebitorDetail
};
export default defaultApi;
