import { ExclamationCircleOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, InputNumber, Modal, Row, Select, Space, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { TCustomerHeadView } from "src/domain/master/view/tCustomerHeadView";
import { TAssignvaluetaxhead } from 'src/domain/tAssignvaluetaxhead';
import { TOtherpaymentHead } from 'src/domain/tOtherpaymentHead';
import { TTypeotherpaymentRef } from 'src/domain/tTypeotherpaymentRef';
import { TTypetaxRef } from 'src/domain/tTypetaxRef';
import { TTypeworksheetRef } from 'src/domain/tTypeworksheetRef';
import tCustomerHeadService from 'src/services/customer/tCustomerHeadService';
import tTypeotherpaymentRefService from 'src/services/reference/tTypeotherpaymentRefService';
import tTypetaxRefService from 'src/services/reference/tTypetaxRefService';
import tAssignvaluetaxheadService from 'src/services/tAssignvaluetaxheadService';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber, textNumber } from 'src/utils/numberUtils';
import AddCustomerModal from '../modals/AddCustomerModal';

const { Option } = Select;
const { confirm } = Modal;

interface IProps {
  financeOtherWorksheetModalVisible: boolean;
  setFinanceOtherWorksheetModalVisible: any;
  setTOtherPaymentHeads: any;
}

const FinanceOtherWorksheetModal: React.FC<IProps> = (props) => {
  const { financeOtherWorksheetModalVisible, setFinanceOtherWorksheetModalVisible, setTOtherPaymentHeads } = props;
  const [form] = Form.useForm();
  const [trigger, setTrigger] = useState<boolean>(false);
  const [visibleModalAddCust, setVisibleModalAddCust] = useState<boolean>(false);

  const [customers, setCustomers] = useState<TCustomerHeadView[]>([]);
  const [tTypeotherpaymentRefs, setTTypeotherpaymentRefs] = useState<TTypeotherpaymentRef[]>([]);
  const [tTypetaxRefs, setTTypetaxRefs] = useState<TTypetaxRef[]>([]); //ภาษีขาย
  const [tAssignvaluetaxheads, setTAssignvaluetaxheads] = useState<TAssignvaluetaxhead[]>([]);

  const [selectedTCustomerHead, setSelectedTCustomerHead] = useState<TCustomerHeadView>();
  const [selectedTTypeotherpaymentRef, setSelectedTTypeotherpaymentRef] = useState<TTypeotherpaymentRef>();
  const [selectedTTypetaxRef, setSelectedTTypetaxRef] = useState<TTypetaxRef>({} as TTypetaxRef);
  const [selectedTAssignvaluetaxhead, setSelectedTAssignvaluetaxhead] = useState<TAssignvaluetaxhead>({} as TAssignvaluetaxhead);

  useEffect(() => {
    if (trigger) {
      form.validateFields()
        .then(() => {
          confirm({
            title: "ยืนยัน!",
            icon: <ExclamationCircleOutlined />,
            content: "คุณต้องการเพิ่มรายการหรือไม่?",
            cancelText: "ยกเลิก",
            okText: "ยืนยัน",
            onOk() {
              form.submit();
            },
            onCancel() {
              setTrigger(false);
            }
          });
        }).catch(() => {
          setTrigger(false);
        });
    }
  }, [trigger]);

  useEffect(() => {
    loadData();
    return () => { };
  }, []);

  useEffect(() => {
    if (Object.keys(selectedTTypetaxRef).length > 0) {
      calTaxData();
    }
  }, [selectedTTypetaxRef]);

  useEffect(() => {
    if (Object.keys(selectedTAssignvaluetaxhead).length > 0) {
      calTaxData();
    }
  }, [selectedTAssignvaluetaxhead]);

  const loadData = async () => {
    if (financeOtherWorksheetModalVisible) {
      const tTypeotherpaymentRefRes = await tTypeotherpaymentRefService.getTTypeotherpaymentRefList();
      const tTypetaxRefRes = await tTypetaxRefService.getTTypetaxRefList();
      const tAssignvaluetaxheadRes = await tAssignvaluetaxheadService.getTAssignvaluetaxheads();

      setTTypeotherpaymentRefs(tTypeotherpaymentRefRes.data);
      setTTypetaxRefs(tTypetaxRefRes.data)
      setTAssignvaluetaxheads(tAssignvaluetaxheadRes.data);

      onClear();
    }
  };

  const handleSearchCustomer = async (data: any) => {
    if (data.length < 3) return;
    const res = await tCustomerHeadService.getCustomerByParam(data);
    setCustomers(res.data);
  };

  const handleChangeTTypeotherpaymentRef = (tTypeotherpaymentCode: any) => {
    const tTypeotherpaymentRefArray = tTypeotherpaymentRefs as Array<TTypeotherpaymentRef>;
    const findTTypeotherpaymentRef = tTypeotherpaymentRefArray.find((ob) => ob.iid === tTypeotherpaymentCode);
    setSelectedTTypeotherpaymentRef(findTTypeotherpaymentRef);
  }

  const handleChangeCustomer = (custCode: any) => {
    const custArray = customers as Array<TCustomerHeadView>;
    const findCust = custArray.find((ob) => ob.icustCode === custCode);
    setSelectedTCustomerHead(findCust);
  }

  const handleChangetTTypetaxRef = (iid: any) => {
    const tTypetaxRefArr = tTypetaxRefs as Array<TTypetaxRef>;
    const findTTypetaxRef = tTypetaxRefArr.find((ob) => ob.iid === iid);
    if (findTTypetaxRef?.iid === '03') {
      form.setFieldsValue({
        withHoldingCode: undefined,
      });
      setSelectedTAssignvaluetaxhead({} as TAssignvaluetaxhead)
    }
    setSelectedTTypetaxRef(findTTypetaxRef!);
  }

  const handleChangeTAssignvaluetaxhead = (iid: any) => {
    const tAssignvaluetaxheadArr = tAssignvaluetaxheads as Array<TAssignvaluetaxhead>;
    const findTAssignvaluetaxhead = tAssignvaluetaxheadArr.find((ob) => ob.iid === iid);
    setSelectedTAssignvaluetaxhead(findTAssignvaluetaxhead!);
  }


  const handleChangeAmount = (amount: number) => {
    const taxCode = form.getFieldValue('taxCode');
    if (taxCode) {
      calTaxData();
    }
  }

  const calTaxData = () => {
    let amt = form.getFieldValue('amt') ? parserNumber(form.getFieldValue('amt')) : 0;
    let dServiceAmt = calPurchaseTax(amt);
    let dPurchaseAmt = calPurchaseTaxAmt(dServiceAmt);
    let dVatAmt = calVatAmt(dServiceAmt);
    let dAmtTotal = calAmtTotal(dServiceAmt, dVatAmt) - dPurchaseAmt;
    let dAmtBeforeVat = amt;
    if (selectedTTypetaxRef && selectedTTypetaxRef.iid === '02') {
      dAmtBeforeVat = dAmtTotal - dVatAmt;
    }
    form.setFieldsValue({
      numTaxWithHoldingAmt: formatNumber(dPurchaseAmt),
      taxValue: formatNumber(dVatAmt),
      total: formatNumber(dAmtTotal),
      amtBeforeVat: formatNumber(dAmtBeforeVat),
    });
  }

  const calPurchaseTax = (dServiceAmt: number): number => {
    let payNonVatAmt = dServiceAmt;
    if (Object.keys(selectedTAssignvaluetaxhead).length <= 0 || selectedTAssignvaluetaxhead.irate === 0) {
      return dServiceAmt;
    }
    if (Object.keys(selectedTTypetaxRef).length <= 0) {
      return dServiceAmt;
    }
    if (selectedTTypetaxRef.iid === '02') {
      payNonVatAmt = (dServiceAmt * 100 / (107 - (selectedTAssignvaluetaxhead.irate ? selectedTAssignvaluetaxhead.irate : 0)));
    }
    return payNonVatAmt;
  }

  const calPurchaseTaxAmt = (dServiceAmt: number): number => {
    let purchaseAmt = 0.0;
    if (Object.keys(selectedTAssignvaluetaxhead).length <= 0) {
      return 0.0;
    }
    if (Object.keys(selectedTTypetaxRef).length <= 0) {
      return 0.0;
    }
    purchaseAmt = (dServiceAmt * (selectedTAssignvaluetaxhead.irate)) / 100;
    return purchaseAmt;
  }

  const calVatAmt = (serviceAmt: number): number => {
    if (null == (selectedTTypetaxRef.iid) || selectedTTypetaxRef.iid === '03') {
      return 0;
    }
    let vatAmt = 0.00;
    switch (selectedTTypetaxRef.iid) {
      case "01":
        vatAmt = serviceAmt * 7 / 100;
        break;
      case "02":
        vatAmt = serviceAmt * 7 / 107;
        break;
      case "03":
        break;
    }
    return vatAmt;
  }

  const calAmtTotal = (serviceAmt: number, vatAmt: number): number => {
    let amtTotal = serviceAmt;
    switch (selectedTTypetaxRef.iid) {
      case "01":
        amtTotal = serviceAmt + vatAmt;
        break;
      case "02":
      case "03":
        break;
    }

    return amtTotal;
  }

  const onClear = () => {
    form.setFieldsValue({
      amt: formatNumber(0),
      amtBeforeVat: 0.0,
      numTaxWithHoldingAmt: 0.0,
      taxValue: 0.0,
      total: 0.0,
    });
  }

  const onFinish = async (values: any) => {
    let _tOtherpaymentHead = {
      icustCode: selectedTCustomerHead?.icustCode,
      icustName: selectedTCustomerHead?.iname + (selectedTCustomerHead?.isurname ? " " + selectedTCustomerHead?.isurname : ''),
      itypeotherwsh: selectedTTypeotherpaymentRef?.iid,
      iremark: selectedTTypeotherpaymentRef?.iname,
      iserviceAmt: parserNumber(values.amtBeforeVat),
      itaxAmt: parserNumber(values.taxValue),
      itotalAmt: parserNumber(values.total),
      icomment: values.remark,
      iremainAmt: parserNumber(values.amtBeforeVat),
      idebitorCode: selectedTCustomerHead?.idebitorCode,
      iisPayRef: values.isPayRef ? 1 : 0,
    } as TOtherpaymentHead;
    setTOtherPaymentHeads([_tOtherpaymentHead]);
    form.resetFields();
    setTrigger(false);
    setFinanceOtherWorksheetModalVisible(false)
  }

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "เกิดข้อผิดพลาด กรุณาติดต่อผู้แลระบบ !");
    setTrigger(false);
  };

  return (
    <div>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>เพิ่มการเงินรับชำระจากรายการอื่นๆ</Typography.Title>
          </div>
        }
        visible={financeOtherWorksheetModalVisible}
        width={"50%"}
        style={{ paddingTop: "5px" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        footer={[
          <Button key="clear" onClick={onClear}>
            Clear
          </Button>,
          <Button key="cancel" onClick={() => setFinanceOtherWorksheetModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="ok" onClick={() => setTrigger(true)}>
            OK
          </Button>
        ]}
      >
        <Form
          form={form}
          name="assignFinanceOtherWorksheet"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row>
            <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
              <span>
                <Typography.Text>ลูกค้า: </Typography.Text>
                <label className="text-red">*</label>
              </span>
            </Col>
            <Col span={19}>
              <Form.Item
                name="customerCode"
                rules={[{ required: true, message: "กรุณาระบุลูกค้า !" }]}
              >
                <Space align='baseline' size={'small'}>
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกลูกค้า"
                    optionFilterProp="children"
                    filterOption={true}
                    style={{ width: "350px" }}
                    onSearch={handleSearchCustomer}
                    onSelect={handleChangeCustomer}
                  >
                    {customers.map((obj) => {
                      return (
                        <Option key={obj.icustCode} value={obj.icustCode}>
                          {obj.iprenameName}{obj.iname}   {obj.isurname ? obj.isurname : ""}: {obj.iphone}<br />
                          <span style={{ marginLeft: "1rem", fontSize: 12 }}>
                            {obj.iaddress}{'   '}อำเภอ/เขต{'   '}{obj.iamphurName}{'   '}จังหวัด{'   '}{obj.iprovinceName}{'   '}{obj.ipostcode}
                          </span>
                        </Option>
                      );
                    })}
                  </Select>
                  <Button
                    id='btnAddCUstomer'
                    onClick={() => setVisibleModalAddCust(true)}
                    type="primary"
                    icon={<PlusSquareOutlined />}
                  >
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
              <span>
                <Typography.Text>รายการรับเงิน: </Typography.Text>
                <label className="text-red">*</label>
              </span>
            </Col>
            <Col span={19}>
              <Form.Item
                name="typeOtherPaymentCode"
                rules={[{ required: true, message: "กรุณาระบุรายการรับเงิน!" }]}
              >
                <Select
                  showSearch
                  size="middle"
                  placeholder="เลือกรายการรับเงิน"
                  optionFilterProp="children"
                  filterOption={true}
                  style={{ width: "350px" }}
                  onSelect={handleChangeTTypeotherpaymentRef}
                >
                  {tTypeotherpaymentRefs.map((obj: TTypeworksheetRef) => {
                    return (
                      <Option key={obj.iid} value={obj.iid}>
                        {obj.iname}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
              <span>
                <Typography.Text>จำนวนเงินชำระ: </Typography.Text>
                <label className="text-red">*</label>
              </span>
            </Col>
            <Col span={19}>
              <Space size={"large"}>
                <Form.Item
                  name="amt"
                  rules={[{ required: true, message: "กรุณาระบุจำนวนเงิน !" }]}
                >
                  <Input
                      type="text"
                      style={{ textAlign: "end", color: "#FD1212", width: "100%" }}
                      onKeyDown={(event: any) => textNumber(event)}
                      onChange={(e: any) => handleChangeAmount(parserNumber(e.target.value))}
                      onFocus={(e: any) => { e.target.select() }}
                      onBlur={(e: any) => { form.setFieldsValue({ amt: formatNumber(parserNumber(e.target.value)) }) }}
                      onPressEnter={(e: any) => { form.setFieldsValue({ amt: formatNumber(parserNumber(e.target.value)) }) }}
                    ></Input>
                </Form.Item>
                <Form.Item
                  name="isPayRef"
                  rules={[{ required: false }]}
                >
                  <Checkbox
                  >
                    <label className="text-red">ใช้อ้างอิงการรับเงินใบงาน/ใบแจ้งหนี้</label>
                  </Checkbox>
                </Form.Item>
              </Space>
            </Col>
          </Row>

          <Row>
            <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
              <span>
                <Typography.Text>ภาษี: </Typography.Text>
                <label className="text-red">*</label>
              </span>
            </Col>
            <Col span={19}>
              <Form.Item
                name="taxCode"
                rules={[{ required: true, message: "กรุณาระบุภาษี !" }]}
              >
                <Select
                  showSearch
                  size="middle"
                  placeholder="เลือกภาษี"
                  optionFilterProp="children"
                  filterOption={true}
                  style={{ width: "350px" }}
                  onSelect={handleChangetTTypetaxRef}
                >
                  {tTypetaxRefs.map((obj: TTypetaxRef) => {
                    return (
                      <Option key={obj.iid} value={obj.iid}>
                        {obj.iid}: {obj.iname}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
              <span>
                <Typography.Text>ภาษี ณ ที่จ่าย: </Typography.Text>
                <label className="text-red"></label>
              </span>
            </Col>
            <Col span={19}>
              <Form.Item
                name="withHoldingCode"
                rules={[{ required: false }]}
              >
                <Select
                  disabled
                  showSearch
                  size="middle"
                  placeholder="เลือกหักภาษี ณ ที่จ่าย"
                  optionFilterProp="children"
                  filterOption={true}
                  style={{ width: "350px" }}
                  onSelect={handleChangeTAssignvaluetaxhead}
                >
                  {tAssignvaluetaxheads.map((obj: TAssignvaluetaxhead) => {
                    return (
                      <Option key={obj.iid} value={obj.iid}>
                        {obj.iname} : {obj.irate}%<br />
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
              <span>
                <Typography.Text>ก่อนรวมภาษี: </Typography.Text>
              </span>
            </Col>
            <Col span={19}>
              <Space size={"large"}>
                <Form.Item
                  name="amtBeforeVat"
                >
                  <InputNumber
                    style={{ textAlign: "right", width: 150, fontWeight: "bold", color: "#FD1212", backgroundColor: "#F8FC9D" }}
                    controls={false}
                    precision={2}
                    onChange={handleChangeAmount}
                  >
                  </InputNumber>
                </Form.Item>
                <Form.Item
                  label="ภาษีที่ต้องชำระ"
                  name="taxValue"
                >
                  <InputNumber
                    style={{ textAlign: "right", width: 150, fontWeight: "bold", color: "#FD1212", backgroundColor: "#F8FC9D" }}
                    controls={false}
                    precision={2}
                  >
                  </InputNumber>
                </Form.Item>
              </Space>
            </Col>
          </Row>

          <Row>
            <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
              <span>
                <Typography.Text>หักภาษี ณ ที่จ่าย: </Typography.Text>
              </span>
            </Col>
            <Col span={19}>
              <Space size={45}>
                <Form.Item
                  name="numTaxWithHoldingAmt"
                >
                  <InputNumber
                    style={{ textAlign: "right", width: 150, fontWeight: "bold", color: "#FD1212", backgroundColor: "#F8FC9D" }}
                    controls={false}
                    precision={2}
                  >
                  </InputNumber>
                </Form.Item>
                <Form.Item
                  label="รวมทั้งหมด"
                  name="total"
                >
                  <InputNumber
                    style={{ textAlign: "right", width: 150, fontWeight: "bold", color: "#FD1212", backgroundColor: "#F8FC9D" }}
                    controls={false}
                    precision={2}
                  >
                  </InputNumber>
                </Form.Item>
              </Space>
            </Col>
          </Row>
          <Row>
            <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
              <span>
                <Typography.Text>หมายเหตุ: </Typography.Text>
                <label className="text-red">*</label>
              </span>
            </Col>
            <Col span={19}>
              <Form.Item
                label=""
                name="remark"
                rules={[{ required: true, message: "กรุณาระบุหมายเหตุ !" }]}
              >
                <TextArea
                  style={{ width: "418px" }}
                  rows={2}
                ></TextArea>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <div>
        {visibleModalAddCust && (
          <AddCustomerModal
            visibleModalAddCust={visibleModalAddCust}
            setVisibleModalAddCust={setVisibleModalAddCust}
          ></AddCustomerModal>
        )}
      </div>
    </div >
  )
}

export default FinanceOtherWorksheetModal