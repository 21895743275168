import { DeleteOutlined, PlusSquareOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Modal, Row, Space, Table, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { TFinanceOtherworkdetail } from 'src/domain/finance/tFinanceOtherworkdetail';
import { TOtherpaymentHead } from 'src/domain/tOtherpaymentHead';
import otherPaymentHeadService from 'src/services/otherpayment/otherPaymentHeadService';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber, textNumber } from 'src/utils/numberUtils';
import LookupOtherPaymentHeadModal from './lookups/LookupOtherPaymentHeadModal';

interface IProps {
  searchOtherWorkModalVisible: boolean;
  setSearchOtherWorkModalVisible: any;
  tFinanceOtherworkdetail: TFinanceOtherworkdetail;
  tFinanceOtherWorkDetails: TFinanceOtherworkdetail[];
  setTFinanceOtherWorkDetails: any;
}

const SearchOtherWorkSearchModal: React.FC<IProps> = (props) => {
  const { searchOtherWorkModalVisible, setSearchOtherWorkModalVisible, tFinanceOtherworkdetail, tFinanceOtherWorkDetails, setTFinanceOtherWorkDetails } = props
  const [form] = Form.useForm();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [numTotalRfAmt, setNumTotalRfAmt] = useState("0.00");
  const [tFinanceOtherWorkDetailList, setTFinanceOtherWorkDetailList] = useState<TFinanceOtherworkdetail[]>([]);
  const [lookupTOtherPaymentHeadModalVisible, setLookupTOtherPaymentHeadModalVisible] = useState<boolean>(false);
  const [lookupTOtherPaymentHeadSelected, setLookupTOtherPaymentHeadSelected] = useState<TOtherpaymentHead>({} as TOtherpaymentHead);
  const [lookupTOtherPaymentHead, setLookupTOtherPaymentHead] = useState<TOtherpaymentHead>({} as TOtherpaymentHead);
  const [lookupTOtherpaymentHeadList, setLookupTOtherpaymentHeadList] = useState<TOtherpaymentHead[]>([]);

  useEffect(() => {
    initial();
  }, []);

  const initial = async () => {
    if (tFinanceOtherworkdetail.customercode) {
      setLookupTOtherPaymentHead({ icustCode: tFinanceOtherworkdetail.customercode } as TOtherpaymentHead);
    }
    if (tFinanceOtherworkdetail.debitorcode) {
      setLookupTOtherPaymentHead({ ...(lookupTOtherPaymentHead as TOtherpaymentHead), idebitorCode: tFinanceOtherworkdetail.debitorcode });
    }

    if (tFinanceOtherworkdetail.otherworksheetcode) {
      setIsEditMode(true);
      const res = await otherPaymentHeadService.getOtherPaymentHeadById(tFinanceOtherworkdetail.otherworksheetcode.trim());
      setLookupTOtherPaymentHeadSelected(res.data);
      form.setFieldsValue({
        payAmtRf: formatNumber(tFinanceOtherworkdetail.amount),
      });
      sumation();
    }

    if (tFinanceOtherWorkDetails) {
      setTFinanceOtherWorkDetailList([...reindex(tFinanceOtherWorkDetails)]);
    }
  }

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: TFinanceOtherworkdetail, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่รายการ",
      dataIndex: "otherworksheetcode",
      key: "otherworksheetcode",
      width: 85,
      align: "center" as "center",
    },
    {
      title: "รายละเอียด",
      dataIndex: "remarks",
      key: "remarks",
      width: 150,
      align: "center" as "center",
    },
    {
      title: "จำนวนเงินรายการ",
      dataIndex: "otherworksheetamt",
      key: "otherworksheetamt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TFinanceOtherworkdetail) => {
        return <span>{formatNumber(item.otherworksheetamt)}</span>;
      },
    },
    {
      title: "จำนวนเงินที่หัก",
      dataIndex: "amount",
      key: "amount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TFinanceOtherworkdetail) => {
        return <span>{formatNumber(item.amount)}</span>;
      },
    },
    {
      title: "",
      key: "action",
      width: 50,
      align: "center" as "center",
      render: (value: any, item: TFinanceOtherworkdetail, index: number) => {
        return (
          < Button
            type="primary"
            size='small'
            danger
            shape="circle"
            icon={< DeleteOutlined />}
            onClick={() => onDelete(item, index)} />
        );
      },
    },
  ]


  useEffect(() => {
    if (!lookupTOtherPaymentHeadModalVisible) {
      if (lookupTOtherPaymentHeadSelected && lookupTOtherPaymentHeadSelected.id) {
        displayData();
      }
    }
  }, [lookupTOtherPaymentHeadModalVisible]);

  useEffect(() => {
    if (tFinanceOtherWorkDetailList && tFinanceOtherWorkDetailList.length > 0) {
      let totalRfAmt = 0.0;
      const tOtherpaymentHeads: TOtherpaymentHead[] = [];
      tFinanceOtherWorkDetailList.forEach((item) => {
        const tOtherpaymentHead = {
          id: item.otherworksheetcode,
          iserviceAmt: item.otherworksheetamt,
          icustCode: item.customercode,
          idebitorCode: item.debitorcode
        } as TOtherpaymentHead
        tOtherpaymentHeads.push(tOtherpaymentHead);
        totalRfAmt += Number(item.amount)
      })
      setNumTotalRfAmt(formatNumber(totalRfAmt));
      // prepare the object for TOtherpaymentHead filter
      setLookupTOtherpaymentHeadList(tOtherpaymentHeads);
    }
  }, [tFinanceOtherWorkDetailList]);

  const handleChangeAmount = (data: number) => {
    const otherWshRemainAmt = ((lookupTOtherPaymentHeadSelected && lookupTOtherPaymentHeadSelected.iremainAmt) ? lookupTOtherPaymentHeadSelected!.iremainAmt : 0)
    if (data > otherWshRemainAmt) {
      showNotification(NotificationType.ERROR, "จำนวนเงินที่หักมากกว่าจำนวนเงินคงค้างไม่ได้!");
      form.setFieldsValue({
        otherPayRemainAmount: formatNumber(otherWshRemainAmt),
      });
    } else {
      sumation();
    }
  }

  const sumation = () => {
    const payAmtRf = (form.getFieldValue('payAmtRf') ? parserNumber(form.getFieldValue('payAmtRf')) : 0)
    const otherWshRemainAmt = ((lookupTOtherPaymentHeadSelected && lookupTOtherPaymentHeadSelected.iremainAmt) ? lookupTOtherPaymentHeadSelected!.iremainAmt : 0)
    form.setFieldsValue({
      otherPayRemainAmount: formatNumber((otherWshRemainAmt - payAmtRf)),
    });
  }

  const displayData = () => {
    if (lookupTOtherPaymentHeadSelected && lookupTOtherPaymentHeadSelected.id) {
      const remainAmt = isEditMode ? (lookupTOtherPaymentHeadSelected.iremainAmt ? lookupTOtherPaymentHeadSelected.iremainAmt : 0.00) - (form.getFieldValue('payAmtRf') ? parserNumber(form.getFieldValue('payAmtRf')) : 0.00) : (lookupTOtherPaymentHeadSelected.iremainAmt ? lookupTOtherPaymentHeadSelected.iremainAmt : 0.00);
      form.setFieldsValue({
        otherPaymentHeadCode: lookupTOtherPaymentHeadSelected.id,
        customerCode: lookupTOtherPaymentHeadSelected.icustName,
        otherPayDetail: lookupTOtherPaymentHeadSelected.iremark + " " + lookupTOtherPaymentHeadSelected.icomment,
        otherPayAmount: formatNumber(lookupTOtherPaymentHeadSelected.iserviceAmt),
        otherPayRemainAmount: formatNumber(remainAmt),
      });
    }
  }

  const onDelete = (item: TFinanceOtherworkdetail, index: number) => {
    const array = tFinanceOtherWorkDetailList ? [...tFinanceOtherWorkDetailList] : [];
    array?.splice(index, 1);
    setTFinanceOtherWorkDetailList(reindex(array));
  }

  const onFinish = (values: any) => {
    const _TFinanceOtherworkdetail: TFinanceOtherworkdetail = {
      ...({} as TFinanceOtherworkdetail),
      otherworksheetcode: lookupTOtherPaymentHeadSelected.id,
      otherworksheetamt: lookupTOtherPaymentHeadSelected.iserviceAmt,
      customercode: lookupTOtherPaymentHeadSelected.icustCode,
      debitorcode: lookupTOtherPaymentHeadSelected.idebitorCode,
      amount: parserNumber(values.payAmtRf),
      remarks: values.remarks,
    }
    setTFinanceOtherWorkDetailList(reindex([...tFinanceOtherWorkDetailList, _TFinanceOtherworkdetail]))
    form.resetFields();
  }

  const onOk = () => {
    setTFinanceOtherWorkDetails([...tFinanceOtherWorkDetailList]);
    setSearchOtherWorkModalVisible(false);
  }

  const reindex = (tFinanceOtherworkdetail: TFinanceOtherworkdetail[]) => {
    return tFinanceOtherworkdetail.map((item, index) => {
      item.id = index;
      return item;
    });
  }

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>กำหนดข้อมูลอ้างอิงจากรายการอื่นๆ</Typography.Title>
          </div>
        }
        visible={searchOtherWorkModalVisible}
        width={"55%"}
        style={{ minHeight: "70%" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={onOk}
        onCancel={() => setSearchOtherWorkModalVisible(false)}
      >
        <Form
          form={form}
          name="financeOtherWorkDetails"
          onFinish={onFinish}
          labelCol={{ span: 6 }}
          initialValues={{ remember: true }}
        >
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>เลขที่การรับเงินอื่นๆ: </Typography.Text>
            </Col>
            <Col span={8}>
              <Space align='baseline' style={{ width: "80%" }}>
                <Form.Item
                  name="otherPaymentHeadCode"
                  rules={[{ required: true, message: "กรุณาระบุเลขที่การรับเงินอื่นๆ!" }]}
                >
                  <Input style={{ width: 230, backgroundColor: "#F8FC9D" }} readOnly></Input>
                </Form.Item>

                <Button
                  id='btnLookupOtherPaymentHead'
                  type="primary"
                  onClick={() => setLookupTOtherPaymentHeadModalVisible(true)}
                  icon={<SearchOutlined />}
                />
              </Space>
            </Col>
          </Row>

          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>ชื่อลูกค้า: </Typography.Text>
            </Col>
            <Col span={19}>
              <Form.Item
                label=""
                name="customerCode"
                rules={[{ required: true, message: "กรุณาระบุลูกค้า!" }]}
              >
                <Input style={{ backgroundColor: "#F8FC9D" }} readOnly></Input>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>รายละเอียด: </Typography.Text>
            </Col>
            <Col span={19}>
              <Form.Item
                name="otherPayDetail"
                rules={[{ required: true, message: "กรุณาระบุลูกค้า!" }]}
              >
                <Input style={{ backgroundColor: "#F8FC9D" }} readOnly></Input>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>จำนวนเงินที่ได้รับ: </Typography.Text>
            </Col>
            <Col span={8}>
              <Form.Item
                name="otherPayAmount"
                rules={[{ required: true, message: "กรุณาระบุจำนวนเงินที่ได้รับ!" }]}
              >
                <InputNumber
                  style={{ textAlign: "right", width: 230, backgroundColor: "#F8FC9D" }} readOnly
                  controls={false}
                  precision={2}
                >
                </InputNumber>
              </Form.Item>
            </Col>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>จำนวนเงินคงค้าง: </Typography.Text>
            </Col>
            <Col span={8}>
              <Form.Item
                name="otherPayRemainAmount"
                rules={[{ required: true, message: "กรุณาระบุจำนวนเงินคงค้าง!" }]}
              >
                <InputNumber
                  style={{ textAlign: "right", width: 203, backgroundColor: "#F8FC9D" }} readOnly
                  controls={false}
                  precision={2}
                >
                </InputNumber>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>จำนวนเงินที่หัก: </Typography.Text>
            </Col>
            <Col span={8}>
              <Form.Item
                name="payAmtRf"
                rules={[{ required: true, message: "กรุณาระบุจำนวนเงินที่หัก!" }]}
              >
                <Input
                  type="text"
                  onKeyDown={(event: any) => textNumber(event)}
                  onPressEnter={(e: any) => form.setFieldsValue({ payAmtRf: formatNumber(parserNumber(e.target.value)) })}
                  onBlur={(e: any) => form.setFieldsValue({ payAmtRf: formatNumber(parserNumber(e.target.value)) })}
                  onFocus={(e) => e.target.select()}
                  style={{ textAlign: "end", width: "100%" }}
                  onChange={(e: any) => handleChangeAmount(parserNumber(e.target.value))}
                ></Input>
              </Form.Item>
            </Col>
            <Col span={12}></Col>
          </Row>
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>หมายเหตุ: </Typography.Text>
            </Col>
            <Col span={19}>
              <Form.Item
                name="remarks"
                rules={[{ required: true, message: "กรุณากรอกหมายเหตุการอ้างอิง" }]}
              >
                <TextArea
                  rows={2}
                ></TextArea>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={11}></Col>
            <Col span={13}>
              <Button
                size="small"
                type="primary"
                style={{ marginBottom: "15px" }}
                icon={<PlusSquareOutlined />}
                onClick={form.submit}
              >
                เพิ่ม
              </Button>
            </Col>
          </Row>

          <div style={{ padding: "10px 0px 0px" }}>
            <Row>
              <Col span={24}>
                <Form.Item
                  labelCol={{ span: 18 }}
                  label="รวมเงินหักทั้งหมด"

                  style={{ margin: "0 0 3px 0px" }}
                >
                  <Input value={numTotalRfAmt} style={{ margin: 0, textAlign: "right", fontWeight: "bold", backgroundColor: "#F8FC9D" }} readOnly></Input>
                </Form.Item>
              </Col>
            </Row>

            <Table
              columns={columns}
              dataSource={tFinanceOtherWorkDetailList}
              size="small"
              pagination={false}
              rowKey={(item: TFinanceOtherworkdetail) => item.id}
              style={{ minHeight: 200, padding: "10px 0px 0px" }}
            />
          </div>
        </Form>
      </Modal>
      <div>
        {lookupTOtherPaymentHeadModalVisible && (
          <LookupOtherPaymentHeadModal
            lookupTOtherPaymentHeadModalVisible={lookupTOtherPaymentHeadModalVisible}
            setLookupTOtherPaymentHeadModalVisible={setLookupTOtherPaymentHeadModalVisible}
            lookupTOtherPaymentHead={lookupTOtherPaymentHead}
            lookupTOtherPaymentHeadList={lookupTOtherpaymentHeadList}
            setLookupTOtherPaymentHeadSelected={setLookupTOtherPaymentHeadSelected}
          ></LookupOtherPaymentHeadModal>
        )}
      </div>
    </>
  )
}

export default SearchOtherWorkSearchModal