import { TRangradWangbillDetail } from "src/domain/tRangradWangbillDetail";
import { TRangradWangbillDetailData } from "src/domain/view/tRangradWangbillDetailData";
import http from "../lib/http";
import Constants from "../utils/constants";

const getByDebitNoteCode = (debitNoteCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tRangradWangbillDetail/getByDebitNoteCode", {
    params: {
      debitNoteCode,
    },
  });
};

const getWorksheetReformByDebitNoteCode = (debitNoteCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tRangradWangbillDetail/getWorksheetReformByDebitNoteCode", {
    params: {
      debitNoteCode,
    },
  });
};

const getWorksheetRangradByDebitNoteCode = (debitNoteCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tRangradWangbillDetail/getWorksheetRangradByDebitNoteCode", {
    params: {
      debitNoteCode,
    },
  });
};

const getByDate = (fromDate: string, toDate: string) => {
  return http.get(Constants.DEFAULT_URL + "/tRangradWangbillDetail/getByDate", {
    params: {
      fromDate,
      toDate
    },
  });
};

const getByWorksheetCode = (worksheetCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tRangradWangbillDetail/getByWorksheetCode", {
    params: {
      worksheetCode,
    },
  });
};

const getByWorksheetCodeAndDate = (worksheetCode: string, date: string) => {
  return http.get(Constants.DEFAULT_URL + "/tRangradWangbillDetail/getByWorksheetCodeAndDate", {
    params: {
      worksheetCode,
      date
    },
  });
};

const getByDebitNoteCodeAndWorksheetCode = (debitNoteCode: string, worksheetCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tRangradWangbillDetail/getByDebitNoteCodeAndWorksheetCode", {
    params: {
      debitNoteCode,
      worksheetCode
    },
  });
};

const getByUser = (userId: string) => {
  return http.get(Constants.DEFAULT_URL + "/tRangradWangbillDetail/getByUser", {
    params: {
      userId,
    },
  });
};

const saveTRangradWangbillDetail = (tRangradWangbillDetail: TRangradWangbillDetail) => {
  return http.post(Constants.DEFAULT_URL + "/tRangradWangbillDetail", tRangradWangbillDetail);
};

const saveTRangradWangbillDetailData = (tRangradWangbillDetailData: TRangradWangbillDetailData) => {
  return http.post(Constants.DEFAULT_URL + "/tRangradWangbillDetail/saveTRangradWangbillDetailData", tRangradWangbillDetailData);
};

const deleteByDebitNoteCode = (debitNoteCode: string) => {
  return http.delete(Constants.DEFAULT_URL + "/tRangradWangbillDetail/deleteByDebitNoteCode", {
    params: {
      debitNoteCode,
    },
  });
};

const deleteByDebitNoteCodeAndWorksheetCode = (debitNoteCode: string, worksheetCode: string) => {
  return http.delete(Constants.DEFAULT_URL + "/tRangradWangbillDetail/deleteByDebitNoteCodeAndWorksheetCode", {
    params: {
      debitNoteCode,
      worksheetCode
    },
  });
};

const defaultApi = {
  getByDebitNoteCode,
  getWorksheetRangradByDebitNoteCode,
  getWorksheetReformByDebitNoteCode,
  getByDate,
  getByWorksheetCode,
  getByWorksheetCodeAndDate,
  getByDebitNoteCodeAndWorksheetCode,
  getByUser,
  saveTRangradWangbillDetail,
  saveTRangradWangbillDetailData,
  deleteByDebitNoteCode,
  deleteByDebitNoteCodeAndWorksheetCode,
};

export default defaultApi;