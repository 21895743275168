import { ClearOutlined, PlusSquareOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Row, Select, Space, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TCustomerHeadView } from 'src/domain/master/view/tCustomerHeadView';
import { TTaxinvoiceHeadView } from 'src/domain/view/tTaxinvoiceHeadView';
import PageHeader from 'src/layouts/PageHeader';
import tCustomerHeadService from 'src/services/customer/tCustomerHeadService';
import tTaxinvoiceHeadService from 'src/services/tTaxinvoiceHeadService';
import Constants from 'src/utils/constants';
import { toThDate } from 'src/utils/dateTimeUtils';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber } from 'src/utils/numberUtils';
import TaxInvoiceDrawer from './TaxInvoiceDrawer';

const { Option } = Select;

const TaxInvoiceList: React.FC<TTaxinvoiceHeadView> = () => {
  const [page, setPage] = React.useState(1);
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [onDrawerVisible, setOnDrawerVisible] = useState<boolean>(false);
  const [tTaxinvoiceHeadViews, setTTaxinvoiceHeadViews] = useState<TTaxinvoiceHeadView[]>([]);
  const [customers, setCustomers] = useState<TCustomerHeadView[]>([]);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 15,
      render: (value: number, item: TTaxinvoiceHeadView, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
    },
    {
      title: "วันที่ใบกำกับ",
      dataIndex: "invoicedate",
      key: "invoicedate",
      width: 80,
      render: (value: any, item: TTaxinvoiceHeadView) => (<>{toThDate(item.invoicedate)}</>)
    },
    {
      title: "เล่มที่ /เลขที่",
      dataIndex: "booknoAndNumber",
      key: "booknoAndNumber",
      width: 80,
      render: (value: any, item: TTaxinvoiceHeadView) => (
      <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.bookno}/{item.booknumber}</Button>
      )
    },
    {
      title: "ลูกค้า",
      dataIndex: "customername",
      key: "customername",
      width: 200,
    },
    {
      title: "จำนวนรายการ",
      dataIndex: "countDetail",
      key: "countDetail",
      width: 90,
      align: "center" as "center",
      render: (value: any, item: TTaxinvoiceHeadView) => (<>{item.countDetail}</>)
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "amount",
      key: "amount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TTaxinvoiceHeadView) => (<>{formatNumber(item.amount)}</>)
    },
    {
      title: "ภาษี",
      dataIndex: "type",
      key: "type",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TTaxinvoiceHeadView) => (<>{formatNumber(item.tax)}</>)
    },
    {
      title: "รวม",
      dataIndex: "totalAmt",
      key: "totalAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TTaxinvoiceHeadView) => (<>{formatNumber(item.totalAmt)}</>)
    },
    {
      title: "สาขา",
      dataIndex: "branchName",
      key: "branchName",
      width: 150,
      align: "center" as "center",
      render: (value: any, item: TTaxinvoiceHeadView) => (<>{item.branchName}</>)
    },
    {
      title: "ผู้บันทึก",
      dataIndex: "createUserName",
      key: "createUserName",
      width: 150,
      render: (value: any, item: TTaxinvoiceHeadView) => (<>{item.createUserName}</>)
    },
  ];

  const getItemColor = (item: TTaxinvoiceHeadView) => {
    let foreColor = "black";
    if (item.flg === -1) {
      foreColor = "red";
    }
    return 'text-' + foreColor;
  }

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    initData();
  }, []);

  const initData = async () => {
    form.setFieldsValue({
      invDateFrom: moment(new Date(), Constants.DATE_FORMAT),
      invDateTo: moment(new Date(), Constants.DATE_FORMAT),
      customercode: undefined,
    });
  }

  function onReset() {
    initData();
  }

  const handleSearchCustomer = async (data: any) => {
    if (data.length < 3) return;
    const res = await tCustomerHeadService.getCustomerByParam(data);
    setCustomers(res.data);
  };

  const onItemCliked = (item: TTaxinvoiceHeadView) => {
    history.replace("/app/taxInvoice/view/" + item.id);
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    const customercode = values.customercode ? values.customercode : "";
    let invDateFrom = moment(values.invDateFrom).format("yyyyMMDD");
    let invDateTo = moment(values.invDateTo).format("yyyyMMDD");

    if(customercode) {
      invDateFrom = "";
      invDateTo = "";
    }
    
    const res = await tTaxinvoiceHeadService.findTTaxinvoiceHeadViews(
      invDateFrom,
      invDateTo,
      customercode);
    setTTaxinvoiceHeadViews(res.data);
    setLoading(false);
  }

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
    form.resetFields();
  };

  return (
    <>
      <PageHeader title="โปรแกรมออกใบกำกับภาษี">
        <Button
          size="small"
          type="primary"
          icon={<PlusSquareOutlined />}
          onClick={() => setOnDrawerVisible(true)}
        >
          เพิ่ม
        </Button>
      </PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="taxInvoice"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
            }}
          >
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>วันที่ใบกำกับ ตั้งแต่: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="invDateFrom"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ชื่อลูกค้า: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item name="customercode" rules={[{ required: false }]}>
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกลูกค้า"
                    optionFilterProp="children"
                    filterOption={false}
                    onSearch={handleSearchCustomer}
                  >
                    {customers.map((obj) => {
                      return (
                        <Option key={obj.icustCode} value={obj.icustCode}>
                          {obj.iprenameName}{obj.iname}  {obj.isurname ? obj.isurname : ""}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ถึง: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="invDateTo"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={10} style={{ textAlign: "right", padding: "0.5em" }}>
              </Col>
            </Row>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: 'center',
              paddingBottom: "1em",
              borderBlockEnd: "2px solid #c9c9c9",
            }}
          >
            <Space>
              <Button
                size="small"
                type="primary"
                icon={<SearchOutlined />}
                onClick={form.submit}
              >
                ค้นหา
              </Button>
              <Button
                size="small"
                type="primary"
                icon={<ClearOutlined />}
                onClick={onReset}
              >
                ล้าง
              </Button>
            </Space>
          </div>
        </Form>
        <div>
          <Table
            columns={columns}
            dataSource={tTaxinvoiceHeadViews}
            size="small"
            loading={loading}
            scroll={{ x: 1500 }}
            rowClassName={getItemColor}
            pagination={{
              pageSize: 10,
              onChange(current) {
                setPage(current);
              }
            }}
            rowKey={(item: TTaxinvoiceHeadView) => item.id!}
            onRow={(item: TTaxinvoiceHeadView) => {
              return {
                onDoubleClick: () => onItemCliked(item),
              };
            }}
          />
        </div>
        <div>
          {onDrawerVisible && (
            <TaxInvoiceDrawer
              onDrawerVisible={onDrawerVisible}
              setOnDrawerVisible={setOnDrawerVisible}
              pageStage="ADD"
              id={""}
            ></TaxInvoiceDrawer>
          )}
        </div>
      </Content>
    </>
  )
}

export default TaxInvoiceList
