import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, Table, Typography } from "antd";
import Search from "antd/es/input/Search";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PageHeader from "src/layouts/PageHeader";
import { getFlg } from "src/utils/rodyokUtils";
import { TAssetHeadView } from "../../domain/master/view/tAssetHeadView";
import tAssetHeadService from "../../services/tasset/tAssetHeadService";
import AddEditTAssetHeadTab1Drawer from "./AddEditTAssetHeadTab1Drawer";


const TAssetHeadList: React.FC<TAssetHeadView> = () => {
    const [tAssetHeads, setTAssetHeads] = useState<TAssetHeadView[]>([]);
    const [page, setPage] = React.useState(1);
    const history = useHistory();
    const [visibleAddEditTAssetHeadTab1Drawer, setVisibleAddEditTAssetHeadTab1Drawer] = useState<boolean>(false);

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            width: 20,
            render: (value: number, item: TAssetHeadView, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
        },
        {
            title: "รหัสสินทรัพย์",
            dataIndex: "id",
            key: "id",
            width: 150,
            render: (value: string, item: TAssetHeadView) => (
                <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.id}</Button>
            )
        },
        {
            title: "เบอร์",
            dataIndex: "numbers",
            key: "numbers",
            width: 80
        },
        {
            title: "ชื่อสินทรัพย์",
            dataIndex: "assetname",
            key: "assetname"
        },
        {
            title: "รายละเอียด",
            dataIndex: "assetdescription",
            key: "assetdescription",
        },
        {
            title: "พนักงานขับรถ",
            dataIndex: "truckdriverName",
            key: "truckdriverName",
            width: 120,
        },
        {
            title: "สถานะ",
            dataIndex: "assetflgdel",
            key: "assetflgdel",
            width: 80,
            render: (value: string, item: TAssetHeadView) => (<>{getFlg(item.assetflgdel) ? <Typography.Text type="success">ใช้งาน</Typography.Text> : <Typography.Text type="danger">ไม่ใช้งาน</Typography.Text>}</>)
        },
        {
            title: "หมายเหตุ",
            dataIndex: "assetremark",
            key: "assetremark",
        }
    ];

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
    }, []);

    const doSearch = async (searchText: string) => {
        const res = await tAssetHeadService.getTAssetHeadByParam(searchText);
        setTAssetHeads(res.data);
    }

    function onSearchTextChange(searchText: string) {
        if (!searchText) {
            doSearch("");
        } else if (searchText.length >= 3) {
            doSearch(searchText);
        }
    }

    function onAddTAssetHead() {
        setVisibleAddEditTAssetHeadTab1Drawer(true);
    }

    const onItemCliked = (item: TAssetHeadView) => {
        history.replace("/app/tasset/view/" + item.id);
    };

    return (
        <>
            <PageHeader title="โปรแกรมข้อมูลสินทรัพย์">
                <Button
                    size="small"
                    type="primary"
                    icon={<PlusSquareOutlined />}
                    onClick={onAddTAssetHead}
                >
                    เพิ่ม
                </Button>
            </PageHeader>
            <Content className="app-page-content">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "baseline",
                        margin: "1em 0 0 0",
                        borderBlockEnd: "2px solid #c9c9c9",
                    }}
                >
                    <label style={{ margin: "0 1em 1em 2em" }}>คำค้นหา</label>
                    <Search
                        allowClear
                        style={{ width: "350px" }}
                        size="middle"
                        placeholder="ป้อนชื่อ/เบอร์/ประเภท/พนักงานขับรถ"
                        onChange={(event) => onSearchTextChange(event.target.value)}
                        onSearch={(value) => onSearchTextChange(value)}
                    ></Search>
                </div>
                <div>
                    <Table
                        columns={columns}
                        dataSource={tAssetHeads}
                        size="small"
                        pagination={{
                            pageSize: 15,
                            onChange(current) {
                                setPage(current);
                            }
                        }}
                        rowKey={(item: TAssetHeadView) => item.id}
                        onRow={(item: TAssetHeadView) => {
                            return {
                                onDoubleClick: () => onItemCliked(item),
                            };
                        }}
                    />
                </div>
            </Content>
            <div> {
                visibleAddEditTAssetHeadTab1Drawer && <AddEditTAssetHeadTab1Drawer
                    id={""}
                    visibleAddEditTAssetHeadTab1Drawer={visibleAddEditTAssetHeadTab1Drawer}
                    setVisibleAddEditTAssetHeadTab1Drawer={setVisibleAddEditTAssetHeadTab1Drawer} />
            }</div>
        </>
    );
};

export default TAssetHeadList;
