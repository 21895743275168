import http from "src/lib/http";
import Constants from "src/utils/constants";

const getRyAccountList = () => {
  return http.get(Constants.DEFAULT_URL + "/ryAccount");
};

const getRyAccountViewList = () => {
  return http.get(Constants.DEFAULT_URL + "/ryAccount/getRyAccountViewList");
};

const getByAccountNumber = (accountnumber: string) => {
  return http.get(Constants.DEFAULT_URL + "/ryAccount/getByAccountNumber/" + accountnumber);
};

const defaultApi = {
  getRyAccountList,
  getRyAccountViewList,
  getByAccountNumber,
};
export default defaultApi;