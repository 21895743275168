import { PrinterOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Modal, PageHeader, Row, Space, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { TWorksheetHeadView } from 'src/domain/worksheet/view/tWorksheetHeadView';
import reportService from 'src/services/reportService';
import Constants from 'src/utils/constants';
import { toThDate } from 'src/utils/dateTimeUtils';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber } from 'src/utils/numberUtils';

const ReportWorksheetFlowList: React.FC<TWorksheetHeadView> = () => {
  const [wshCreateDateForSigns, setWshCreateDateForSigns] = useState<TWorksheetHeadView[]>([]);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [reportWorksheetFlowPreviewBlob, setReportWorksheetFlowPreviewBlob] = useState<any>();
  const [reportWorksheetFlowPreviewData, setReportWorksheetFlowPreviewData] = useState<any>();
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: TWorksheetHeadView, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
    },
    {
      title: "วันที่สร้าง",
      dataIndex: "ientryDate",
      key: "ientryDate",
      width: 50,
      align: "center" as "center",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{item.ientryDate ? toThDate(item.ientryDate) : ""}</span>;
      },
    },
    {
      title: "วันที่ทำงาน",
      dataIndex: "startDate",
      key: "startDate",
      width: 130,
      render: (value: any, item: TWorksheetHeadView) => (<>{toThDate(item.istartDate)} : {toThDate(item.iendDate)}</>)
    },
    {
      title: "เลขที่ใบงาน",
      dataIndex: "iworksheetCode",
      key: "iworksheetCode",
      width: 50,
      align: "center" as "center",
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "icustName",
      key: "icustName",
      width: 150,
    },
    {
      title: "รายการบริการ",
      dataIndex: "serviceDetailName",
      key: "serviceDetailName",
      width: 170,
      align: "center" as "center",
    },
    {
      title: "ประเภทการชำระ",
      dataIndex: "itypepaymentName",
      key: "itypepaymentName",
      width: 90,
      align: "center" as "center",
    },
    {
      title: "ค่าบริการ",
      dataIndex: "total2Amt",
      key: "total2Amt",
      width: 50,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itotal2Amt)}</span>;
      },
    },
  ]

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    form.setFieldsValue({
      entryDate: moment(new Date(), Constants.DATE_FORMAT)
    });
    form.submit();
  }, []);


  const onPrint = async () => {
    if (!wshCreateDateForSigns) {
      showNotification(NotificationType.INFO, "ไม่มีข้อมูลของวันที่ " + toThDate(form.getFieldValue("entryDate")));
    } else {
      const resultPreview = await reportService.getReportByCreateDateForSignPreview(wshCreateDateForSigns);
      if (resultPreview.data) {
        // setReportWorksheetFlowPreviewBlob(resultPreview.data);
        // const fileObj = URL.createObjectURL(resultPreview.data);
        // setReportWorksheetFlowPreviewData(fileObj);
        // setIsPreviewModalVisible(true);

        const blob = new Blob([resultPreview.data], {type: "application/pdf"})
        window.open(URL.createObjectURL(blob))
      } else {
        showNotification(
          NotificationType.ERROR,
          "ไม่มีข้อมูลของวันที่ "+ toThDate(form.getFieldValue("entryDate"))
        );
      }
    }
  }

  const onFinish = async (values: any) => {
    setLoading(true);
    let entryDate: string = values.entryDate ? moment(values.entryDate).format(Constants.DB_FORMAT) : "";
    const res = await reportService.getByCreateDateForSign(entryDate);
    setWshCreateDateForSigns(res.data);
    setLoading(false);
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  return (
    <>
      <PageHeader title="โปรแกรมพิมพ์ใบงานประจำวัน"></PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="reportWorksheetFlow"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
            }}
          >
            <Row>
              <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ค้นหาจากวันที่เริ่มงาน: </Typography.Text>
              </Col>
              <Col span={21}>
                <Space align="baseline" size={"large"}>
                  <Form.Item
                    name="entryDate"
                    rules={[{ required: false }]}
                  >
                    <DatePicker
                      format={Constants.DATE_FORMAT}
                      size="middle"
                      placeholder="เลือกวันที่"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      icon={<SearchOutlined />}
                      onClick={form.submit}
                    >
                      ค้นหา
                    </Button>
                  </Form.Item>
                </Space>
              </Col>
            </Row>

          </div>
        </Form>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            // margin: '0 0 10px 0',
            padding: "0px 20px 10px 0px",
            borderBottom: '2px solid #c9c9c9'
          }}
        >
          <Button
          style={{
            marginLeft: "25px",}}
            size="small"
            type="primary"
            icon={<PrinterOutlined />}
            onClick={onPrint}
          >
            พิมพ์
          </Button>
          <Modal
            style={{ top: 20 }}
            width={"90vw"}
            title="RW0009"
            bodyStyle={{ height: "85vh" }}
            centered={true}
            visible={isPreviewModalVisible}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={() => setIsPreviewModalVisible(false)}
          >
            <iframe title=" " src={reportWorksheetFlowPreviewData} width={"100%"} height={"100%"}></iframe>
          </Modal>
        </div>
        <Table
          columns={columns}
          dataSource={wshCreateDateForSigns}
          size="small"
          loading={loading}
          rowKey={(item: TWorksheetHeadView) => item.ireserveCode!}
          pagination={{
            pageSize: 15,
            onChange(current) {
              setPage(current);
            }
          }}
        />
      </Content>
    </>
  )
}

export default ReportWorksheetFlowList