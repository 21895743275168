import { DeleteOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Modal, Row, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { TCreditnoteHead } from 'src/domain/tCreditnoteHead';
import { TWorksheetRangradWangbillView } from 'src/domain/worksheet/view/tWorksheetRangradWangbillView';
import tCreditnoteHeadService from 'src/services/finance/tCreditnoteHeadService';
import { useAppSelector } from 'src/stores/store';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber } from 'src/utils/numberUtils';

interface IProps {
  searchCreditNoteModalVisible: boolean;
  setSearchCreditNoteModalVisible: any;
  iworksheetCode: any;
  wshRangradWangbills: any;
  tCreditnoteHeads: any;
  setTCreditnoteHeads: any;
}
const SearchCreditNoteDiscountModal: React.FC<IProps> = (props) => {
  const { searchCreditNoteModalVisible, setSearchCreditNoteModalVisible, iworksheetCode, wshRangradWangbills, tCreditnoteHeads, setTCreditnoteHeads } = props
  const [form] = Form.useForm();
  const [submitTrigger, setSubmitTrigger] = useState<boolean>(false);
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [disableAddButton, setDisableAddButton] = useState<boolean>(true);
  const [tCreditnoteHead, setTCreditnoteHead] = useState<TCreditnoteHead>({} as TCreditnoteHead);
  const [tCreditnoteHeadTempList, setTCreditnoteHeadTempList] = useState<TCreditnoteHead[]>([]);

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      key: "index",
      align: "center" as "center",
      render: (value: any, item: TCreditnoteHead, index: number) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: "เลขที่ใบลดหนี้",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "เลขที่ใบงาน",
      dataIndex: "workcode",
      key: "workcode",
    },
    {
      title: "ส่วนลด",
      dataIndex: "creditDiscount",
      key: "creditDiscount",
      align: "right" as "right",
      render: (value: any, item: TCreditnoteHead, index: number) => {
        return <span>{formatNumber(item.creditDiscount)}</span>;
      },
    },
    {
      title: "ภาษี",
      dataIndex: "creditVat",
      key: "creditVat",
      align: "right" as "right",
      render: (value: any, item: TCreditnoteHead, index: number) => {
        return <span>{formatNumber(item.creditVat)}</span>;
      },
    },
    {
      title: "รวมภาษี",
      dataIndex: "creditTotal",
      key: "creditTotal",
      align: "right" as "right",
      render: (value: any, item: TCreditnoteHead, index: number) => {
        return <span>{formatNumber(item.creditTotal)}</span>;
      },
    },
    {
      title: "",
      key: "action",
      align: "center" as "center",
      render: (value: any, item: TCreditnoteHead, index: number) => {
        return (
          < Button
            type="primary"
            size='small'
            danger
            shape="circle"
            icon={< DeleteOutlined />}
            onClick={() => onDelete(item, index)}
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (submitTrigger) {
      form.submit();
    }
  }, [submitTrigger]);

  const onChangeCreditnoteId = async () => {
    const id = form.getFieldValue("id");
    const tCreditnoteHeadRes = await tCreditnoteHeadService.getById(id);
    const tCreditnoteHead: TCreditnoteHead = tCreditnoteHeadRes.data;
    if (tCreditnoteHead) {
      if (iworksheetCode && !wshRangradWangbills) { //กรณีเป็นใบงานสด มีใบงานเดียว
        if (tCreditnoteHead.workcode !== iworksheetCode) {
          showNotification(NotificationType.ERROR, "เลขที่ใบลดหนี้ไม่สัมพันธ์กับใบงานที่จะชำระ");
          form.resetFields();
        } else {
          form.setFieldsValue({
            workcode: tCreditnoteHead.workcode,
            creditDiscount: tCreditnoteHead.creditDiscount,
          });
          setDisableAddButton(false);
          setTCreditnoteHead(tCreditnoteHead);
        }
      } else if (!iworksheetCode && wshRangradWangbills) { //กรณีเป็นรับชำระจากเร่งรัด มีใบงานมากกว่า 1 ใบ
        const foudwsh = wshRangradWangbills.find((obj: TWorksheetRangradWangbillView) => obj.iworksheetCode === tCreditnoteHead.workcode);
        if (foudwsh && tCreditnoteHead.workcode !== foudwsh.iworksheetCode) {
          showNotification(NotificationType.ERROR, "เลขที่ใบลดหนี้ไม่สัมพันธ์กับใบงานที่จะชำระ");
          form.resetFields();
        } else {
          form.setFieldsValue({
            workcode: foudwsh.iworksheetCode,
            creditDiscount: tCreditnoteHead.creditDiscount,
          });
          setDisableAddButton(false);
          setTCreditnoteHead(tCreditnoteHead);
        }
      }
    } else {
      showNotification(NotificationType.ERROR, "ไม่พบเลขที่ใบลดหนี้ " + id);
      form.resetFields();
    }
  }

  const handleAdd = () => {
    if (!tCreditnoteHead) {
      showNotification(NotificationType.ERROR, "ไม่พบข้อมูลใบลดหนี้");
      return;
    } else {
      const foudData = tCreditnoteHeadTempList.find((obj) => obj.id === form.getFieldValue("id"));
      if (foudData) {
        showNotification(NotificationType.ERROR, "มีใบลดหนี้เลขที่ " + foudData.id + " อยู่แล้ว");
        return;
      } else {
        const temp: TCreditnoteHead = {
          ...tCreditnoteHead,
          crediteStatus: "02",
        };

        const array = tCreditnoteHeadTempList ? [...tCreditnoteHeadTempList] : [];
        array?.push(temp);
        setTCreditnoteHeadTempList([...array]);
        form.resetFields();
        setDisableAddButton(true);
      }
    }
  }

  const onDelete = (item: TCreditnoteHead, index: number) => {
    const array = tCreditnoteHeadTempList ? [...tCreditnoteHeadTempList] : [];
    array?.splice(index, 1);
    setTCreditnoteHeadTempList([...array]);
  }

  const onFinish = (values: any) => {
    setTCreditnoteHeads(tCreditnoteHeadTempList);
    form.resetFields();
    setSubmitTrigger(false);
    setDisableAddButton(true);
    setSearchCreditNoteModalVisible(false);
  };

  const onCancel = () => {
    setDisableAddButton(true);
    setSearchCreditNoteModalVisible(false);
  }

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน");
    setSubmitTrigger(false);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>อ้างอิงใบลดหนี้</Typography.Title>
          </div>
        }
        visible={searchCreditNoteModalVisible}
        width={"50%"}
        style={{ minHeight: "70%" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setSubmitTrigger(true)}
        onCancel={() => onCancel()}
      >
        <Form
          form={form}
          name="selectCreditnote"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 12 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="เลขที่ใบลดหนี้"
            name="id"
          >
            <Input placeholder="ป้อนเลขที่ใบลดหนี้"
              onKeyDown={(evt: any) => {
                if (evt.keyCode == 13) {
                  onChangeCreditnoteId()
                }
              }}
            ></Input>
          </Form.Item>
          <Form.Item
            label="อ้างอิงเลขที่ใบงาน"
            name="workcode"
          >
            <Input disabled></Input>
          </Form.Item>
          <Form.Item
            label="ส่วนลด"
            name="creditDiscount"
          >
            <InputNumber
              style={{ textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }} readOnly
              controls={false}
              formatter={formatNumber}
              parser={parserNumber}
            >
            </InputNumber>
          </Form.Item>

          <Row>
            <Col span={7} style={{ textAlign: "right" }}>
            </Col>
            <Col span={17}>
              <Button
                size="small"
                type="primary"
                disabled={disableAddButton}
                style={{ marginBottom: "15px" }}
                icon={<PlusSquareOutlined />}
                onClick={handleAdd}
              >
                เพิ่ม
              </Button>
            </Col>
          </Row>
        </Form>
        <div style={{ marginBottom: 20, minHeight: 100 }}>
          <Table
            columns={columns}
            dataSource={tCreditnoteHeadTempList}
            size="small"
            pagination={false}
            rowKey={(item: TCreditnoteHead) => item.id}
          ></Table>
        </div>
      </Modal>
    </>
  )
}

export default SearchCreditNoteDiscountModal