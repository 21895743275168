import { Drawer, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react'
import { TFinanceDetails } from 'src/domain/finance/tFinanceDetails';
import { TFinanceHead2View } from 'src/domain/finance/view/tFinanceHead2View';
import tFinanceHead2Service from 'src/services/finance/tFinanceHead2Service';
import { formatTime, toThDate } from 'src/utils/dateTimeUtils';
import { formatNumber } from 'src/utils/numberUtils';

interface IProps {
  onDrawerCashVisible: boolean;
  setOnDrawerCashVisible: any;
  trushId: string;
}
const ViewTrushMonetCashListDrawer: React.FC<IProps> = (props) => {
  const { onDrawerCashVisible, setOnDrawerCashVisible, trushId } = props;
  const [loading, setLoading] = useState(false);
  const [tfinanceHead2CashList, setTfinanceHead2CashList] = useState<TFinanceHead2View[]>([]);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 30,
      align: "center" as "center",
      render: (value: number, item: TFinanceHead2View, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่การรับเงิน",
      dataIndex: "id",
      key: "id",
      width: 60
    },
    {
      title: "ประเภทรายการ",
      dataIndex: "typePayment",
      key: "typePayment",
      width: 60,
      render: (value: any, item: TFinanceHead2View) => {
        const tFinanceDetail: TFinanceDetails = item.tfinanceDetailList[0];
        if (tFinanceDetail.othercode) {
          return <span>รายรับอื่นๆ</span>;
        } else if (tFinanceDetail.debitnotecode) {
          return <span>ใบแจ้งหนี้</span>;
        } else {
          return <span>ใบงาน</span>;
        }
      },
    },
    {
      title: "เลขที่รายการ",
      dataIndex: "codePayment",
      key: "codePayment",
      width: 80,
      render: (value: any, item: TFinanceHead2View) => {
        const tFinanceDetail: TFinanceDetails = item.tfinanceDetailList[0];
        if (tFinanceDetail.othercode) {
          return <span>{tFinanceDetail.othercode}</span>;
        } else if (tFinanceDetail.debitnotecode) {
          return <span>{tFinanceDetail.debitnotecode}</span>;
        } else {
          return <span>{tFinanceDetail.worksheetcode}</span>;
        }
      },
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "customername",
      key: "customername",
      width: 100
    },
    {
      title: "จำนวนเงินสด",
      dataIndex: "cashamount",
      key: "cashamount",
      width: 50,
      render: (value: any, item: TFinanceHead2View) => {
        return <span>{formatNumber(item.cashamount)}</span>;
      },
    },
    {
      title: "ผู้รับชำระ",
      dataIndex: "createuserName",
      key: "createuserName",
      width: 90,
    },
    {
      title: "วันที่รับชำระ",
      dataIndex: "entryDate",
      key: "entryDate",
      width: 80,
      render: (value: any, item: TFinanceHead2View) => {
        return <span>{toThDate(item.entrydate) + " : " + formatTime(item.entrytime)}</span>;
      },
    },
  ];

  useEffect(() => {
    initial();
  }, []);

  const initial = async () => {
    setLoading(true);
    const res = await tFinanceHead2Service.getTFinanceHead2Cash("", trushId);
    setTfinanceHead2CashList(res.data);
    setLoading(false);
  }

  return (
    <>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Typography.Title level={4}>รายการเงินสด</Typography.Title>
          </div>
        }
        width="50%"
        visible={onDrawerCashVisible}
        forceRender={true}
        placement="right"
        bodyStyle={{ paddingBottom: 80 }}
        maskClosable
        destroyOnClose={true}
        onClose={() => setOnDrawerCashVisible(false)
        }
      >
        <Content className="app-page-content">
          <div style={{ marginBottom: 0 }}>
            <Table
              columns={columns}
              dataSource={tfinanceHead2CashList}
              loading={loading}
              size="small"
              scroll={{ x: 1200 }}
              pagination={false}
              rowKey={(item: TFinanceHead2View) => item.id!}
            />
          </div>
        </Content>
      </Drawer>
    </>
  )
}

export default ViewTrushMonetCashListDrawer