import { CloseCircleOutlined, DeleteOutlined, ExclamationCircleOutlined, PlusSquareOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, InputNumber, Modal, Row, Select, Space, Spin, Switch, Table, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BsAmphurH } from "src/domain/bsAmphurH";
import { BsProvinceH } from "src/domain/bsProvinceH";
import { TDebitorHead } from "src/domain/finance/tDebitorHead";
import { TDebitorDetailView } from "src/domain/finance/view/tDebitorDetailView";
import { TDebitorHeadDetailView } from "src/domain/finance/view/tDebitorHeadDetailView";
import { TCustomerHeadView } from "src/domain/master/view/tCustomerHeadView";
import { TPrenameRef } from "src/domain/tPrenameRef";
import tCustomerHeadService from "src/services/customer/tCustomerHeadService";
import tDebitorDetailService from "src/services/debitor/tDebitorDetailService";
import bsAmphurHService from "src/services/province/bsAmphurHService";
import bsProvinceHService from "src/services/province/bsProvinceHService";
import tPrenameRefService from "src/services/reference/tPrenameRefService";
import { useAppSelector } from "src/stores/store";
import { formatNumber, parserNumber, textNumber } from "src/utils/numberUtils";
import { getFlg } from "src/utils/rodyokUtils";
import tDebitorService from "../../services/debitor/tDebitorService";
import { NotificationType, showNotification } from "../../utils/notificationUtils";

const { Option } = Select;
const { confirm } = Modal;
const { Title } = Typography;

interface IParams {
    iid: string;
    visibleAddEditDebitorHeadDrawer: boolean;
    setVisibleAddEditDebitorHeadDrawer: any;
}

const AddEditDebitorHeadDrawer: React.FC<IParams> = (props) => {
    const { iid, visibleAddEditDebitorHeadDrawer: visibleDebitorHeadDrawer, setVisibleAddEditDebitorHeadDrawer: setVisibleDebitorHeadDrawer } = props;

    const [page, setPage] = React.useState(1);
    const [trigger, setTrigger] = useState<boolean>(false);
    const [debitorHead, setDebitorHead] = useState<TDebitorHead>({} as TDebitorHead);
    const [form] = Form.useForm();
    const history = useHistory();
    const [showSpin, setShowSpin] = useState<boolean>(false);

    const [prenames, setPrenames] = useState<TPrenameRef[]>([]);
    const [provinces, setProvinces] = useState<BsProvinceH[]>([]);
    const [amphurs, setAmphurs] = useState<BsAmphurH[]>([]);
    const [tDebitorDetails, setDebitorDetails] = useState<TDebitorDetailView[]>([]);
    const [customers, setCustomers] = useState<TCustomerHeadView[]>([]);
    const [selectedCustomer, setSelectedCustomer] = useState("");
    const userInfo = useAppSelector((state) => state.authen.authenUser.user);

    let title: string = "เพิ่มข้อมูลลูกหนี้";
    if (iid) {
        title = "แก้ไขข้อมูลลูกหนี้";
    }

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            width: 100,
            render: (value: number, item: TDebitorDetailView, index: number) => (
                <>{(page - 1) * 15 + index + 1}</>
            ),
        },
        {
            title: "รหัสลูกค้า",
            dataIndex: "icustomerCode",
            key: "icustomerCode",
            width: 150,
        },
        {
            title: "ชื่อ-สกุล",
            dataIndex: "fullName",
            key: "fullName",
            render: (value: any, item: TDebitorDetailView) => (<>{item.iprename} {item.iname} {item.isurname}</>)
        },
        {
            title: "ที่อยู่",
            dataIndex: "fullAddress",
            key: "fullAddress",
            render: (value: any, item: TDebitorDetailView) => (<>{item.iaddress} {item.iamphurName} {item.iprovinceName} {item.ipostcode}</>)
        },
        {
            title: "เบอร์โทร",
            dataIndex: "fullPhone",
            key: "fullPhone",
            width: 150,
            render: (value: any, item: TDebitorDetailView) => (<>{item.iphone} {item.iphoneContinue}</>)
        },
        {
            title: "",
            dataIndex: "delete",
            key: "delete",
            width: 100,
            render: (value: any, item: TDebitorDetailView) => (<>
                <Button
                    type="primary"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => onDeleteCustomer(item.icustomerCode)}>
                    ลบ
                </Button>
            </>)
        },
    ];

    useEffect(() => {
        if (trigger) {
            if (tDebitorDetails.length <= 0) {
                showNotification(NotificationType.ERROR, "กรุณากำหนดข้อมูลลูกค้าก่อนการบันทึกข้อมูล!");
                setTrigger(false);
                return;
            }

            form.validateFields()
                .then(() => {
                    confirm({
                        title: "ยืนยัน!",
                        icon: <ExclamationCircleOutlined />,
                        content: "คุณต้องการที่จะบันทึกข้อมูลหรือไม่?",
                        cancelText: "ยกเลิก",
                        okText: "ยืนยัน",
                        onOk() {
                            form.submit();
                        },
                        onCancel() {
                            setTrigger(false);
                        }
                    });
                }).catch(() => {
                    setTrigger(false);
                });
        }
    }, [trigger]);

    useEffect(() => {
        setShowSpin(true);

        initial();
        return () => {
        };
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            iid: debitorHead.iid,
            iprenameCode: debitorHead.iprenameCode,
            iname: debitorHead.iname,
            isurname: debitorHead.isurname,
            itelephoneNumber: debitorHead.itelephoneNumber,
            imobilenumber: debitorHead.imobilenumber,
            ifaxNumber: debitorHead.ifaxNumber,
            iaddress: debitorHead.iaddress,
            iprovinceCode: debitorHead.iprovinceCode,
            iamphurcode: debitorHead.iamphurcode,
            ipostcode: debitorHead.ipostcode,
            iorganizername: debitorHead.iorganizername,
            iorganizerphone: debitorHead.iorganizerphone,
            iorganizerposition: debitorHead.iorganizerposition,
            inameconsent: debitorHead.inameconsent,
            icreditamount: formatNumber(debitorHead.icreditamount),
            iextradiscountamount: debitorHead.iextradiscountamount,
            creditdate: debitorHead.creditdate,
            iemailaddress: debitorHead.iemailaddress,
            telephonecontinue: debitorHead.telephonecontinue,
            currentusage: debitorHead.currentusage ? debitorHead.currentusage : 0,
            iflgCreateWork: getFlg(debitorHead.iflgCreatework),
            iremarkMessage: debitorHead.iremarkMessage,
        });
        return () => {
        };
    }, [debitorHead]);

    const initial = async () => {
        const prenamesRes = await tPrenameRefService.getTPrenameRefList();
        const provinceRes = await bsProvinceHService.getBsProvinceHs("");
        const amphurRes = await bsAmphurHService.getBsAmphurHs();

        if (iid) {
            const res = await tDebitorService.getDebitorHeadById(iid);
            const debitorDetailRes = await tDebitorDetailService.getDebitorDetailByCode(res.data.iid);

            setDebitorHead(res.data);
            setDebitorDetails(debitorDetailRes.data)
        } else {
            const nextCodeRes = await tDebitorService.getNextDebitorHeadCode();
            setDebitorHead({ ...debitorHead, iid: nextCodeRes.data });
        }

        setPrenames(prenamesRes.data)
        setProvinces(provinceRes.data);
        setAmphurs(amphurRes.data);
        setShowSpin(false);
    };

    const onFinish = async (values: any) => {
        const _tDebitorHead: TDebitorHead = {
            iid: values.iid,
            iprenameCode: values.iprenameCode,
            iname: values.iname,
            isurname: values.isurname,
            itelephoneNumber: values.itelephoneNumber,
            imobilenumber: values.imobilenumber,
            ifaxNumber: values.ifaxNumber,
            iaddress: values.iaddress,
            iprovinceCode: values.iprovinceCode,
            iamphurcode: values.iamphurcode,
            ipostcode: values.ipostcode,
            iorganizername: values.iorganizername,
            iorganizerphone: values.iorganizerphone,
            iorganizerposition: values.iorganizerposition,
            inameconsent: values.inameconsent,
            icreditamount: parserNumber(values.icreditamount),
            iextradiscountamount: values.iextradiscountamount,
            creditdate: Number(values.creditdate),
            iemailaddress: values.iemailaddress,
            telephonecontinue: values.telephonecontinue,
            currentusage: 0.00,
            iflgCreatework: values.iflgCreateWork ? 0 : 1,
            iremarkMessage: values.iremarkMessage,
            isavStation: userInfo.saveStation,
            icreateUser: userInfo.userId,
            ilastUser: userInfo.userId,
            itermNo: userInfo.termNo
        };

        const _tDebitorHeadDetailView: TDebitorHeadDetailView = {
            tdebitorHead: _tDebitorHead,
            tdebitorDetailViews: tDebitorDetails
        }

        if (!iid) {
            try {
                const res = await tDebitorService.postDebitorHead(_tDebitorHeadDetailView);
                showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลลูกหนี้เรียบร้อยแล้ว!");
                form.resetFields();
                setTrigger(false);
                setVisibleDebitorHeadDrawer(false);
                history.replace("/app/debitor/view/" + res.data.iid);
            } catch (err) {
                showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
                setTrigger(false);
            }
        } else {
            try {
                await tDebitorService.updateDebitorHead(_tDebitorHeadDetailView);
                showNotification(NotificationType.SUCCESS, "แก้ไขข้อมูลลูกหนี้เรียบร้อยแล้ว!");
                form.resetFields();
                setTrigger(false);
                setVisibleDebitorHeadDrawer(false);
                history.replace("/app/debitor/view/" + debitorHead.iid);
            } catch (err) {
                showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
                setTrigger(false);
            }
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน");
        setTrigger(false);
    };

    const handleSearchAmphur = async (data: any) => {
        if (data.length < 3) return;
        const res = await bsAmphurHService.getBsAmphurHByName(data);
        setAmphurs(res.data);
    };

    const handleSearchCustomer = async (data: any) => {
        if (data.length < 3) return;
        const res = await tCustomerHeadService.getCustomerByParam(data);
        setCustomers(res.data);
    };

    const handleSelectCustomer = (icustCode: any) => {
        setSelectedCustomer(icustCode);
    };

    const onAddCustomer = () => {
        if (!selectedCustomer) {
            return
        }
        const debitorDetailArray = tDebitorDetails as Array<TDebitorDetailView>;
        const findDebitorDetail = debitorDetailArray.find((ob) => ob.icustomerCode === selectedCustomer);
        if (findDebitorDetail) {
            showNotification(NotificationType.ERROR, "มีลูกค้านี้อยู่แล้ว!");
            return
        }

        const custArray = customers as Array<TCustomerHeadView>;
        const customer = custArray.find((ob) => ob.icustCode === selectedCustomer);
        if (!customer) {
            return
        }
        const debitorDetail: TDebitorDetailView = {
            iautoid: 0,
            iid: debitorHead.iid,
            icustomerCode: customer.icustCode,
            iprename: customer.iprenameName,
            iname: customer.iname,
            isurname: customer.isurname,
            iaddress: customer.iaddress,
            iprovinceName: customer.iprovinceName,
            iamphurName: customer.iamphurName,
            ipostcode: customer.ipostcode,
            iphone: customer.iphone,
            iphoneContinue: customer.phonecontinue,
            iflgDel: 0,
            isavStation: userInfo.saveStation,
            icreateUser: userInfo.userId,
            ilastUser: userInfo.userId,
            itermNo: userInfo.termNo
        }

        setDebitorDetails(reindex(tDebitorDetails.concat(debitorDetail)));
        setSelectedCustomer('');
    }

    const reindex = (tDebitorDetailViews: TDebitorDetailView[]) => {
        return tDebitorDetailViews.map((item, index) => {
            item.iautoid = index;
            return item;
        });
    }

    const onDeleteCustomer = (customerCode: String) => {
        const debitorDetailArray = tDebitorDetails as Array<TDebitorDetailView>;
        const newdebitorDetails = debitorDetailArray.filter((obj) => obj.icustomerCode !== customerCode)
        setDebitorDetails(reindex(newdebitorDetails))
    }

    return (
        <>
            <Drawer
                title={
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "baseline",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography.Title level={4}>{title}</Typography.Title>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "",
                            }}
                        >
                            <Button
                                type="primary"
                                danger
                                icon={<CloseCircleOutlined />}
                                onClick={() => setVisibleDebitorHeadDrawer(false)}>
                                ยกเลิก
                            </Button>
                            <Button
                                onClick={() => setTrigger(true)}
                                type="primary"
                                icon={<SaveOutlined />}
                                style={{ marginLeft: "5px" }}
                            >
                                บันทึก
                            </Button>
                        </div>
                    </div>
                }
                width="90%"
                visible={visibleDebitorHeadDrawer}
                forceRender={true}
                placement="right"
                bodyStyle={{ paddingBottom: 80 }}
                closable={false}
                destroyOnClose={true}
            >
                <Content className="app-page-content">
                    <div>
                        <Spin spinning={showSpin}>
                            <Form
                                form={form}
                                name="debitorHead"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>รหัสลูกหนี้: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="iid"
                                            rules={[
                                                { required: true }
                                            ]}
                                        >
                                            <Input disabled={true} style={{ width: "200px" }} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>ชื่อลูกหนี้: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Space>
                                            <Form.Item
                                                name="iprenameCode"
                                                rules={[
                                                    { required: true, message: "กรุณาเลือกคำนำหน้า" }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    size="middle"
                                                    placeholder="เลือกคำนำหน้าชื่อ"
                                                    optionFilterProp="children"
                                                    filterOption={true}
                                                    style={{ width: "137px" }}
                                                >
                                                    {prenames.map((obj) => {
                                                        return (
                                                            <Option key={obj.iprenameCode} value={obj.iprenameCode}>
                                                                {obj.iprenameName}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                name="iname"
                                                rules={[
                                                    { required: true, message: "กรุณาป้อนชื่อ" },
                                                    {
                                                        validator: (_, value) => {
                                                            if (value && (value === " " || value === "-")) {
                                                                return Promise.reject(new Error('กรุณาป้อนข้อมูลชื่อให้ถูกต้อง!'))
                                                            } else {
                                                                return Promise.resolve()
                                                            }
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="ป้อนชื่อ" style={{ width: "240px" }} maxLength={100}></Input>
                                            </Form.Item>
                                            <Form.Item
                                                name="isurname"
                                                rules={[
                                                    {
                                                        validator: (_, value) => {
                                                            const prenameValue = form.getFieldValue('iprenameCode');
                                                            if (value && prenameValue === "09" && (value === " " || value === "-")) {
                                                                return Promise.reject(new Error('กรุณาป้อนข้อมูลนามสกุลให้ถูกต้อง!'))
                                                            } if (value && prenameValue === "09" && value.length < 4) {
                                                                return Promise.reject(new Error('กรุณาป้อนข้อมูลนามสกุลมากกว่า 4 ตัวอักษร!'))
                                                            } else {
                                                                return Promise.resolve()
                                                            }
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="ป้อนนามสกุล" style={{ width: "235px" }} maxLength={50}></Input>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>ที่อยู่: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="iaddress"
                                            rules={[
                                                { required: true, message: "กรุณาป้อนที่อยู่" },
                                                {
                                                    validator: (_, value) => {
                                                        if (value && (value === " " || value === "-")) {
                                                            return Promise.reject(new Error('กรุณาป้อนที่อยู่ให้ถูกต้อง!'))
                                                        } if (value && value.length < 5) {
                                                            return Promise.reject(new Error('กรุณาป้อนข้อมูลที่อยู่มากกว่า 5 ตัวอักษร!'))
                                                        } else {
                                                            return Promise.resolve()
                                                        }
                                                    },
                                                },
                                            ]}
                                        >
                                            <TextArea
                                                style={{ width: "625px" }}
                                                rows={3}
                                                name="iaddress"
                                                maxLength={200}
                                            ></TextArea>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>อำเภอ: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Space>
                                            <Form.Item
                                                name="iamphurcode"
                                                rules={[{ required: true, message: "กรุณาเลือกอำเภอ!" }]}
                                            >
                                                <Select
                                                    showSearch
                                                    size="middle"
                                                    placeholder="เลือกอำเภอ"
                                                    optionFilterProp="children"
                                                    filterOption={true}
                                                    onSearch={handleSearchAmphur}
                                                    style={{ width: "200px" }}
                                                >
                                                    {amphurs.map((obj) => {
                                                        return (
                                                            <Option key={obj.iid} value={obj.iid}>
                                                                {obj.iamphurName}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                label="จังหวัด"
                                                name="iprovinceCode"
                                                rules={[{ required: true, message: "กรุณาเลือกจังหวัด!" }]}
                                            >
                                                <Select
                                                    showSearch
                                                    size="middle"
                                                    placeholder="เลือกจังหวัด"
                                                    optionFilterProp="children"
                                                    filterOption={true}
                                                    style={{ width: "200px" }}
                                                >
                                                    {provinces.map((obj) => {
                                                        return (
                                                            <Option key={obj.iid} value={obj.iid}>
                                                                {obj.iprovinceName}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>รหัสไปรษณีย์: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="ipostcode"
                                            rules={[{ required: true, message: "กรุณาป้อนรหัสไปรษณีย์!" }]}
                                        >
                                            <Input placeholder="ป้อนรหัสไปรษณีย์" style={{ width: "200px" }} ></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>โทร: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={25}>
                                            <Form.Item
                                                name="itelephoneNumber"
                                                rules={[
                                                    { required: true, message: "กรุณาป้อนเบอร์โทร" },
                                                    {
                                                        validator: (_, value) => {
                                                            if (value && (value === " " || value === "-")) {
                                                                return Promise.reject(new Error('กรุณาป้อนเบอร์โทรให้ถูกต้อง!'))
                                                            } if (value && value.length < 9) {
                                                                return Promise.reject(new Error('กรุณาป้อนข้อมูลเบอร์โทรมากกว่า 9 ตัวอักษร!'))
                                                            } else {
                                                                return Promise.resolve()
                                                            }
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="ป้อนเบอร์โทร" maxLength={20}></Input>
                                            </Form.Item>
                                            <Form.Item
                                                label="ต่อ"
                                                name="telephonecontinue"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Input style={{ width: "215px" }} maxLength={5}></Input>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>มือถือ: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={25}>
                                            <Form.Item
                                                name="imobilenumber"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Input placeholder="ป้อนเบอร์มือถือ" style={{ width: "200px" }} maxLength={20}></Input>
                                            </Form.Item>
                                            <Form.Item
                                                label="Fax"
                                                name="ifaxNumber"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Input placeholder="ป้อน Fax" style={{ width: "210px" }} maxLength={20}></Input>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>Email: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="iemailaddress"
                                            rules={[
                                                { required: false }
                                            ]}
                                        >
                                            <Input placeholder="ป้อน Email" style={{ width: "300px" }} maxLength={50}></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>บุคคลอ้างอิง: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="iorganizername"
                                            rules={[
                                                { required: true, message: "กรุณาป้อนบุคคลอ้างอิง" },
                                                {
                                                    validator: (_, value) => {
                                                        if (value && (value === " " || value === "-")) {
                                                            return Promise.reject(new Error('กรุณาป้อนบุคคลอ้างอิงให้ถูกต้อง!'))
                                                        } if (value && value.length < 5) {
                                                            return Promise.reject(new Error('กรุณาป้อนข้อมูลบุคคลอ้างอิงมากกว่า 5 ตัวอักษร!'))
                                                        } else {
                                                            return Promise.resolve()
                                                        }
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input placeholder="ป้อนบุคคลอ้างอิง กรณีบริษัทห้างร้าน" style={{ width: "300px" }} maxLength={100}></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>ตำแหน่ง: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={"middle"}>
                                            <Form.Item
                                                name="iorganizerposition"
                                                rules={[
                                                    { required: true, message: "กรุณาป้อนตำแหน่ง" },
                                                    {
                                                        validator: (_, value) => {
                                                            if (value && (value === " " || value === "-")) {
                                                                return Promise.reject(new Error('กรุณาป้อนตำแหน่งให้ถูกต้อง!'))
                                                            } if (value && value.length < 5) {
                                                                return Promise.reject(new Error('กรุณาป้อนข้อมูลตำแหน่งมากกว่า 5 ตัวอักษร!'))
                                                            } else {
                                                                return Promise.resolve()
                                                            }
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="ป้อนตำแหน่ง" style={{ width: "200px" }} maxLength={50}></Input>
                                            </Form.Item>
                                            <Form.Item
                                                label="เบอร์โทร"
                                                name="iorganizerphone"
                                                rules={[
                                                    { required: true, message: "กรุณาป้อนเบอร์โทร" },
                                                    {
                                                        validator: (_, value) => {
                                                            if (value && (value === " " || value === "-")) {
                                                                return Promise.reject(new Error('กรุณาป้อนเบอร์โทรให้ถูกต้อง!'))
                                                            } if (value && value.length < 9) {
                                                                return Promise.reject(new Error('กรุณาป้อนข้อมูลเบอร์โทรมากกว่า 9 ตัวอักษร!'))
                                                            } else {
                                                                return Promise.resolve()
                                                            }
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="ป้อนเบอร์โทร" style={{ width: "185px" }} maxLength={20}></Input>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>ผู้อนุมัติ: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="inameconsent"
                                            rules={[
                                                { required: true, message: "กรุณาป้อนผู้อนุมัติ" },
                                                {
                                                    validator: (_, value) => {
                                                        if (value && (value === " " || value === "-")) {
                                                            return Promise.reject(new Error('กรุณาป้อนผู้อนุมัติให้ถูกต้อง!'))
                                                        } if (value && value.length < 5) {
                                                            return Promise.reject(new Error('กรุณาป้อนข้อมูลผู้อนุมัติมากกว่า 5 ตัวอักษร!'))
                                                        } else {
                                                            return Promise.resolve()
                                                        }
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input placeholder="ป้อนผู้อนุมัติ" style={{ width: "300px" }} maxLength={100}></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>วงเงิน: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={45}>
                                            <Space align="baseline">
                                                <Form.Item
                                                    name="icreditamount"
                                                    rules={[
                                                        { required: true, message: "กรุณาป้อนวงเงิน" },
                                                        {
                                                            validator: (_, value) => {
                                                                const creditamount = value ? parserNumber(value) : 0.0;
                                                                if (creditamount && creditamount <= 0) {
                                                                    return Promise.reject(new Error('จำนวนเงินเครดิตไม่ถูกต้อง!'))
                                                                } else {
                                                                    return Promise.resolve()
                                                                }
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        onKeyDown={(event: any) => textNumber(event)}
                                                        onPressEnter={(e: any) => form.setFieldsValue({ icreditamount: formatNumber(parserNumber(e.target.value)) })}
                                                        onBlur={(e: any) => form.setFieldsValue({ icreditamount: formatNumber(parserNumber(e.target.value)) })}
                                                        onFocus={(e) => e.target.select()}
                                                        style={{ textAlign: "end", width: "100%" }}
                                                    ></Input>
                                                </Form.Item>
                                                <Typography.Text> บาท</Typography.Text>
                                            </Space>
                                            <Space align="baseline">
                                                <Form.Item
                                                    label="ส่วนลดพิเศษ"
                                                    name="iextradiscountamount"
                                                    rules={[
                                                        { required: true, message: "กรุณาป้อนส่วนลดพิเศษ!" },
                                                        {
                                                            validator: (_, value) => {
                                                                if (value && (value === " " || value === "-")) {
                                                                    return Promise.reject(new Error('กรุณาป้อนส่วนลดพิเศษให้ถูกต้อง!'))
                                                                } else if (value && (Number(value) < 0 || Number(value) > 100)) {
                                                                    return Promise.reject(new Error('ส่วนลดพิเศษควรอยู่ระหว่าง 0 - 100!'))
                                                                } else {
                                                                    return Promise.resolve()
                                                                }
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber
                                                        controls={false}
                                                        precision={2}
                                                        // formatter={(value) => formatNumber(value)}
                                                        parser={(value) => parserNumber(value)}
                                                        style={{ width: "150px" }}
                                                        onFocus={(e) => e.target.select()}
                                                    ></InputNumber>
                                                </Form.Item>
                                                <Typography.Text> %</Typography.Text>
                                            </Space>
                                        </Space>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>เวลาการชำระเงิน: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={80}>
                                            <Space align="baseline">
                                                <Form.Item
                                                    name="creditdate"
                                                    rules={[
                                                        { required: true, message: "ป้อนเวลาชำระเงิน!" }
                                                    ]}
                                                >
                                                    <InputNumber
                                                        controls={false}
                                                        onFocus={(e) => e.target.select()}
                                                    ></InputNumber>
                                                </Form.Item>
                                                <Typography.Text> วัน</Typography.Text>
                                            </Space>
                                        </Space>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>สภานะ: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="iflgCreateWork"
                                            rules={[
                                                { required: false }
                                            ]}
                                            valuePropName="checked"
                                        >
                                            <Switch checkedChildren="ใช้งาน" unCheckedChildren="ไม่ใช้งาน" defaultChecked={true} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>หมายเหตุแจ้งเตือน: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="iremarkMessage"
                                            rules={[
                                                { required: false }
                                            ]}
                                        >
                                            <TextArea
                                                style={{ width: "625px" }}
                                                rows={3}
                                                maxLength={255}
                                            ></TextArea>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Spin>
                    </div>
                </Content>
                <br />
                <br />
                <Content className="app-page-content">
                    <div style={{ padding: "0px 20px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Space>
                                <Select
                                    style={{ width: "500px" }}
                                    showSearch
                                    allowClear
                                    size="middle"
                                    placeholder="ค้นหาลูกค้า"
                                    optionFilterProp="children"
                                    filterOption={true}
                                    value={selectedCustomer}
                                    onSearch={handleSearchCustomer}
                                    onSelect={handleSelectCustomer}
                                >
                                    {customers.map((obj) => {
                                        return (
                                            <Option key={obj.icustCode} value={obj.icustCode}>
                                                {obj.iprenameName} {obj.iname} {obj.isurname} {obj.iphone}
                                            </Option>
                                        );
                                    })}
                                </Select>
                                <Button
                                    size="small"
                                    type="primary"
                                    icon={<PlusSquareOutlined />}
                                    onClick={onAddCustomer}
                                >
                                    เพิ่ม
                                </Button>
                            </Space>
                            <Title level={3}>จำนวนลูกค้า: <span style={{ color: "red" }}>{tDebitorDetails.length}</span></Title>
                        </div>
                        <Table
                            style={{ borderBlockStart: "2px solid #c9c9c9" }}
                            columns={columns}
                            dataSource={tDebitorDetails}
                            size="small"
                            rowKey={(item: TDebitorDetailView) => item.iautoid}
                            pagination={{
                                pageSize: 15,
                                onChange(current) {
                                    setPage(current);
                                },
                            }}
                        />
                    </div>
                </Content>
            </Drawer>
        </>
    );
};

export default AddEditDebitorHeadDrawer;
