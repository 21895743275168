import http from "../../lib/http";
import Constants from "../../utils/constants";

const getRyRefbranassetsList = () => {
  return http.get(
    Constants.DEFAULT_URL + "/ryRefbranassets"
  );
};

const getRyRefbranassets = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/ryRefbranassets/" + id);
}

const defaultApi = {
  getRyRefbranassets,
  getRyRefbranassetsList,
};
export default defaultApi;
