import { Descriptions } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { TAssetmovementAndDetailView } from "src/domain/movement/view/tAssetmovementAndDetailView";
import PageHeader from "src/layouts/PageHeader";
import { formatFullTime } from "src/utils/dateTimeUtils";
import tAssetmovementService from "../../services/tAssetmovementService";

interface IParams {
    seq: string;
}

const ViewTAssetmovement: React.FC<RouteComponentProps<IParams>> = (props) => {
    const seq = props.match.params.seq;
    const [tAssetmovement, setTAssetmovement] = useState<TAssetmovementAndDetailView>({} as TAssetmovementAndDetailView);
    const history = useHistory();

    useEffect(() => {
        initial();
    }, []);

    const initial = async () => {
        const res = await tAssetmovementService.getTAssetmovementById(Number(seq));
        setTAssetmovement(res.data);
    };

    return (
        <>
            <PageHeader title="" onClose={() => history.push("/app/tAssetmovement/list")} />
            <Content className="app-page-content">
                <Descriptions
                    style={{ margin: "1em" }}
                    column={4}
                    title="ข้อมูลรับรถกลับ"
                    size="middle"
                />
                <div style={{ display: "flex", justifyContent: "flex-start", margin: "1em" }}>
                    <Descriptions
                        column={6}
                        size="middle"
                        bordered
                        labelStyle={{ textAlign: "right", fontWeight: "bold" }}
                    >
                        <Descriptions.Item label="เลขที่ใบงาน" span={6}>{tAssetmovement.worksheetCode}</Descriptions.Item>
                        <Descriptions.Item label="รายการ" span={6}>({tAssetmovement.assetcode}) {tAssetmovement.assetName}</Descriptions.Item>
                        <Descriptions.Item label="พนักงานขับรก" span={6}>{tAssetmovement.drivercode ? <>({tAssetmovement.drivercode}) {tAssetmovement.driverName}</> : ""}</Descriptions.Item>
                        <Descriptions.Item label="เลขไมล์ออก" span={6}>{tAssetmovement.milearrivenumber}</Descriptions.Item>
                        <Descriptions.Item label="เวลาที่รับกลับ" span={6}>{formatFullTime(tAssetmovement.endTime)}</Descriptions.Item>
                        <Descriptions.Item label="หมายเหตุ" span={6}>{tAssetmovement.userremark}</Descriptions.Item>
                    </Descriptions>
                </div>
            </Content>
        </>
    );
};

export default ViewTAssetmovement;
