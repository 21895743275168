import { ArrowLeftOutlined, ClearOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, Modal, Row, Select, Space, Spin, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react'
import { TFinanceTrushView } from 'src/domain/finance/view/tFinanceTrushView';
import { RyAccountView } from 'src/domain/view/ryAccountView';
import ryAccountService from 'src/services/bank/ryAccountService';
import tFinanceTrushService from 'src/services/finance/tFinanceTrushService';
import { toThDate } from 'src/utils/dateTimeUtils';
import { formatNumber } from 'src/utils/numberUtils';
import TrushMoneyConfirmModal from './TrushMoneyConfirmModal';

const { Option } = Select;
const { Title } = Typography;

interface IProps {
  onDrawerVisible: boolean;
  setOnDrawerVisible: any;
}
const TrushMoneyConfirmDrawer: React.FC<IProps> = (props) => {
  const { onDrawerVisible, setOnDrawerVisible } = props;
  const [form] = Form.useForm();
  const [showSpin, setShowSpin] = useState<boolean>(false);
  const [onConfirmModalVisible, setOnConfirmModalVisible] = useState<boolean>(false);
  const [onChangeAccseqModalVisible, setOnChangeAccseqModalVisible] = useState<boolean>(false);
  const [ryAccounts, setRyAccounts] = useState<RyAccountView[]>([]); //บัญชีธนาคาร
  const [tfinanceTrushViews, setTfinanceTrushViews] = useState<TFinanceTrushView[]>([]);
  const [tfinanceTrushViewSelected, setTfinanceTrushViewSelected] = useState<TFinanceTrushView>({} as TFinanceTrushView);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 70,
      render: (value: number, item: TFinanceTrushView, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่บัญชี",
      dataIndex: "accountNumber",
      key: "accountNumber",
      width: 250,
      render: (value: any, item: TFinanceTrushView) => (<>{item.accountNumber} - {item.accountName}</>)
    },
    {
      title: "ธนาคาร",
      dataIndex: "bankName",
      key: "bankName",
      width: 130,
    },
    {
      title: "สาขา",
      dataIndex: "bankBranchName",
      key: "bankBranchName",
      width: 130,
    },
    {
      title: "เลขที่การนำฝาก",
      dataIndex: "id",
      key: "id",
      width: 140,
      render: (value: any, item: TFinanceTrushView) => (
        <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.id}</Button>
      )
    },
    {
      title: "วันที่นำฝาก",
      dataIndex: "trushdate",
      key: "trushdate",
      width: 100,
      render: (value: any, item: TFinanceTrushView) => (<>{toThDate(item.trushdate)}</>)
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "amount",
      key: "amount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TFinanceTrushView) => (<>{formatNumber(item.amount)}</>)
    },
    {
      title: "ประเภทการฝาก",
      dataIndex: "type",
      key: "type",
      width: 110,
    },
    {
      title: "เลขที่เช็ค",
      dataIndex: "chquenumber",
      key: "chquenumber",
      width: 170,
    },
    {
      title: "ผู้ฝาก",
      dataIndex: "trushusername",
      key: "trushusername",
      width: 170,
    },
    {
      title: "หมายเหตุ",
      dataIndex: "remark",
      key: "remark",
      width: 150,
    },
    {
      title: "สาขาที่นำฝาก",
      dataIndex: "branchName",
      key: "branchName",
      width: 160,
    },
  ];

  useEffect(() => {
    initData();
  }, []);

  const initData = async () => {
    const ryAccountRes = await ryAccountService.getRyAccountViewList();
    setRyAccounts(ryAccountRes.data);

    form.setFieldsValue({
      accSeq: undefined,
      type: "ALL",
      trushmoneyid: undefined,
      chquenumber: undefined,
    });
  }

  function onReset() {
    initData();
  }

  const onItemCliked = (item: TFinanceTrushView) => {
    setOnConfirmModalVisible(true);
    setTfinanceTrushViewSelected(item);
  };

  const onFinish = async (values: any) => {
    setShowSpin(true);
    const type: string = values.type === "ALL" ? "" : values.type;
    const accSeq: string = values.accSeq ? values.accSeq : "";    
    const trushmoneyid: string = values.trushmoneyid ? values.trushmoneyid : "";
    const chquenumber: string = values.chquenumber ? values.chquenumber : "";
    const res = await tFinanceTrushService.findTFinanceTrushWaitConfirmViews(accSeq, type, trushmoneyid, chquenumber);
    setTfinanceTrushViews(res.data);
    setShowSpin(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    // showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน !");
    // setTrigger(false);
  };

  return (
    <>
      <Drawer
        title={
          <div>
            <Typography.Title level={4}>โปรแกรมยืนยันการนำฝาก</Typography.Title>
            <Button
              type="primary"
              icon={<ArrowLeftOutlined />}
              onClick={() => setOnDrawerVisible(false)}>
              กลับ
            </Button>
          </div>
        }
        width="90%"
        visible={onDrawerVisible}
        forceRender={true}
        placement="right"
        bodyStyle={{ paddingBottom: 80 }}
        closable={false}
        destroyOnClose={true}
      >
        <Content className="app-page-content">
          <Form
            form={form}
            name="trushMoneyconfirm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>นำเข้าบัญชี: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="accSeq"
                >
                  <Select
                    showSearch
                    size="middle"
                    placeholder="เลือกธนาคาร"
                    optionFilterProp="children"
                    filterOption={true}
                  >
                    {ryAccounts.map((obj: RyAccountView) => {
                      return (
                        <Option key={obj.accountnumber} value={obj.seq}>
                          {obj.accountnumber} : {obj.accountname}<br />
                          <span style={{ marginLeft: "2rem", fontSize: 12 }}>
                            {obj.bankname} - สาขา{obj.branchname}
                          </span>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ประเภทการฝาก: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="type"
                >
                  <Select
                    showSearch
                    size="middle"
                    placeholder="เลือกประเภทการฝาก"
                    optionFilterProp="children"
                    filterOption={true}
                  >
                    <Option key="ALL" value="ALL">ทั้งหมด</Option>
                    <Option key="CHQ" value="CHQ">ยืนยันเฉพาะเช็ค</Option>
                    <Option key="CASH" value="CASH">ยืนยันเฉพาะเงินสด</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เลขที่การนำฝาก: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="trushmoneyid"
                >
                  <Input
                    allowClear
                    placeholder="ป้อนเลขที่การนำฝาก"
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เลขที่เช็ค: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="chquenumber"
                >
                  <Input
                    allowClear
                    placeholder="ป้อนเลขที่เช็ค"
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <div
              style={{
                display: "flex",
                justifyContent: 'center',
                paddingBottom: "1em",
              }}
            >
              <Space>
                <Button
                  size="small"
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={form.submit}
                >
                  ค้นหา
                </Button>
                <Button
                  size="small"
                  type="primary"
                  icon={<ClearOutlined />}
                  onClick={onReset}
                >
                  ล้าง
                </Button>
              </Space>
            </div>
          </Form>
          <div
              style={{
                paddingLeft: "1em",
                borderBlockEnd: "2px solid #c9c9c9",
              }}
            >
            <Title level={5} type="danger">Double Click เพื่อยืนยันการฝากเงิน</Title>
            </div>
          <div style={{ marginBottom: 0 }}>
            <Spin spinning={showSpin}>
              <Table
                columns={columns}
                dataSource={tfinanceTrushViews}
                size="small"
                scroll={{ x: 1800, y: 400 }}
                pagination={false}
                rowKey={(item: TFinanceTrushView) => item.id}
                onRow={(item: TFinanceTrushView) => {
                  return {
                    onDoubleClick: () => onItemCliked(item),
                  };
                }}
              />
            </Spin>
          </div>
          <div>
            {onConfirmModalVisible && tfinanceTrushViewSelected && (
              <TrushMoneyConfirmModal
                onConfirmModalVisible={onConfirmModalVisible}
                setOnConfirmModalVisible={setOnConfirmModalVisible}
                onChangeAccseqModalVisible={onChangeAccseqModalVisible}
                setOnChangeAccseqModalVisible={setOnChangeAccseqModalVisible}
                tfinanceTrushViewSelected={tfinanceTrushViewSelected}
                setTfinanceTrushViewSelected={setTfinanceTrushViewSelected}
                tfinanceTrushViews={tfinanceTrushViews}
                setTfinanceTrushViews={setTfinanceTrushViews}
              ></TrushMoneyConfirmModal>
            )}
          </div>
        </Content>
      </Drawer>
    </>
  )
}

export default TrushMoneyConfirmDrawer