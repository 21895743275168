import { CloseCircleOutlined, DeleteOutlined, ExclamationCircleOutlined, PlusSquareOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Radio, Row, Select, Space, Spin, Table, Typography } from 'antd';
import Drawer from 'antd/lib/drawer';
import TextArea from 'antd/lib/input/TextArea';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { TFinanceDetails } from 'src/domain/finance/tFinanceDetails';
import { TFinanceHead2 } from 'src/domain/finance/tFinanceHead2';
import { TCustomerHeadView } from 'src/domain/master/view/tCustomerHeadView';
import { RyRefbranch } from 'src/domain/ryRefbranch';
import { TRangradWangbillDetail } from 'src/domain/tRangradWangbillDetail';
import { TRangradWangbillHead } from 'src/domain/tRangradWangbillHead';
import { TTaxinvoiceData } from 'src/domain/view/tTaxinvoiceData';
import { TTaxinvoiceDetailView } from 'src/domain/view/tTaxinvoiceDetailView';
import { TTaxinvoiceHeadView } from 'src/domain/view/tTaxinvoiceHeadView';
import { TWorksheetHeadView } from 'src/domain/worksheet/view/tWorksheetHeadView';
import tCustomerHeadService from 'src/services/customer/tCustomerHeadService';
import tFinanceDetailsService from 'src/services/finance/tFinanceDetailsService';
import tFinanceHead2Service from 'src/services/finance/tFinanceHead2Service';
import ryRefbranchService from 'src/services/reference/ryRefbranchService';
import tRangradWangbillDetailService from 'src/services/tRangradWangbillDetailService';
import tRangradWangbillHeadService from 'src/services/tRangradWangbillHeadService';
import tTaxinvoiceHeadService from 'src/services/tTaxinvoiceHeadService';
import tWorksheetHeadService from 'src/services/tWorksheetHeadService';
import { useAppSelector } from 'src/stores/store';
import Constants from 'src/utils/constants';
import { toMomentDate, toThDate } from 'src/utils/dateTimeUtils';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber, textNumber } from 'src/utils/numberUtils';
import AddCustomerModal from '../modals/AddCustomerModal';
import EditTaxInvoiceDetailModal from './EditTaxInvoiceDetailModal';
import { TWorksheetInstalmentDetailView } from 'src/domain/worksheet/view/tWorksheetInstalmentDetailView';
import SelectWorksheetInstallmentModal from './SelectWorksheetInstallmentModal';

const { Option } = Select;
const { confirm } = Modal;

interface IProps {
  onDrawerVisible: boolean;
  setOnDrawerVisible: any;
  pageStage: string;
  id: string;
}

const TaxInvoiceDrawer: React.FC<IProps> = (props) => {
  const { onDrawerVisible, setOnDrawerVisible, pageStage, id } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);

  const [checkAutoNumber, setCheckAutoNumber] = React.useState(false);
  const [checkIsHeadOffice, setCheckIsHeadOffice] = React.useState(false);
  const [visibleModalAddCust, setVisibleModalAddCust] = useState<boolean>(false);
  const [visibleModalEditDetail, setVisibleModalEditDetail] = useState<boolean>(false);
  const [visibleModalWshInstallment, setVisibleModalWshInstallment] = useState<boolean>(false);
  const [form] = Form.useForm();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  const [ryRefbranches, setRyRefbranches] = useState<RyRefbranch[]>([]);
  const [customers, setCustomers] = useState<TCustomerHeadView[]>([]);
  const [checkTaxinvDetailViews, setCheckTaxinvDetailViews] = useState<TTaxinvoiceDetailView[]>([]);
  const [taxinvDetailViews, setTaxinvDetailViews] = useState<TTaxinvoiceDetailView[]>([]);
  const [taxinvDetailViewsRemoveList, setTaxinvDetailViewsRemoveList] = useState<TTaxinvoiceDetailView[]>([]);
  const [ryRefbranchSelected, setRyRefbranchSelected] = useState<RyRefbranch>();
  const [ttaxinvoiceData, setTtaxinvoiceData] = useState<TTaxinvoiceData>({} as TTaxinvoiceData);
  const [isBuildTaxInvoiceDetail, setIsBuildTaxInvoiceDetail] = useState(false);
  const [wshHeadViewSelected, setWshHeadViewSelected] = useState<TWorksheetHeadView>({} as TWorksheetHeadView);
  const [rdHeadSelected, setRdHeadSelected] = useState<TRangradWangbillHead>({} as TRangradWangbillHead);
  const [taxinvoiceDetailBuild, setTaxinvoiceDetailBuild] = useState<TTaxinvoiceDetailView>({} as TTaxinvoiceDetailView);
  const [taxinvoiceDetailSelectEdit, setTaxinvoiceDetailSelectEdit] = useState<TTaxinvoiceDetailView>({} as TTaxinvoiceDetailView);
  const [wshInstallmentSelected, setWshInstallmentSelected] = useState<TWorksheetInstalmentDetailView>({} as TWorksheetInstalmentDetailView);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 60,
      align: "center" as "center",
      render: (value: number, item: TTaxinvoiceDetailView, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่ใบสั่งงาน",
      dataIndex: "worksheetcode",
      key: "worksheetcode",
      width: 120,
    },
    {
      title: "เลขที่ใบแจ้งหนี้",
      dataIndex: "debitnotecode",
      key: "debitnotecode",
      width: 120,
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "customername",
      key: "customername",
      width: 200,
    },
    {
      title: "รายการ",
      dataIndex: "serviceName",
      key: "serviceName",
      width: 180,
    },
    {
      title: "ข้อความในใบกำกับ",
      dataIndex: "remarkDescriptions",
      key: "remarkDescriptions",
      width: 180,
      render: (value: string, item: TTaxinvoiceDetailView) => (
        <a onClick={() => onItemCliked(item)} >{item.remarkDescriptions}</a>
      )
    },
    {
      title: "วันที่ทำงาน",
      dataIndex: "startDate",
      key: "startDate",
      width: 80,
      render: (value: any, item: TTaxinvoiceDetailView) => (<>{toThDate(item.startDate)}</>)
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "amount",
      key: "amount",
      width: 90,
      align: "right" as "right",
      render: (value: any, item: TTaxinvoiceDetailView) => {
        return <span>{formatNumber(item.amount)}</span>;
      },
    },
    {
      title: "เลขที่การรับเงิน",
      dataIndex: "paymentCode",
      key: "paymentCode",
      width: 100,
    },
    {
      title: "",
      key: "action",
      width: 50,
      align: "center" as "center",
      render: (value: any, item: TTaxinvoiceDetailView, index: number) => {
        // render: () => {
        return (
          < Button
            type="primary"
            size='small'
            danger
            shape="circle"
            icon={< DeleteOutlined />}
            onClick={() => onDelete(item, index)}
          />
        );
      },
    },
  ];

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    let serviceamt = 0;
    let vatamt = 0;
    let totalamt = 0;
    if (taxinvDetailViews && taxinvDetailViews.length > 0) {
      taxinvDetailViews.forEach((obj: TTaxinvoiceDetailView) => {
        serviceamt = serviceamt + obj.amount
      });

      vatamt = Math.round(((serviceamt * 7) / 100) * 100) / 100;
      totalamt = serviceamt + vatamt;
    }
    form.setFieldsValue({
      amount: serviceamt,
      tax: vatamt,
      totalAmt: totalamt,
    });
  }, [taxinvDetailViews]);

  useEffect(() => {
    if (isBuildTaxInvoiceDetail) {
      const array = taxinvDetailViews ? [...taxinvDetailViews] : [];
      array.push(taxinvoiceDetailBuild);

      setTaxinvDetailViews(array);
      setIsBuildTaxInvoiceDetail(false);
    }
  }, [isBuildTaxInvoiceDetail]);

  useEffect(() => {
    (async () => {
      if (!visibleModalWshInstallment && wshInstallmentSelected.iworksheetCode) {
        const taxTypeCode = form.getFieldValue("taxTypeCode"); //WSH_CODE,DEBITNOTE_CODE,WSH_INSURANCE
        await loadWorksheetAmount(wshHeadViewSelected.iworksheetCode!, "ADD");
        if (taxTypeCode === "WSH_CODE") {
          buildTaxInvoiceDetailListByWshcode(wshHeadViewSelected, {} as TRangradWangbillHead, "ADD");
        } else if (taxTypeCode === "DEBITNOTE_CODE") {
          // const rdHeadRes = await tRangradWangbillHeadService.getByDebitNoteNo(form.getFieldValue("code"));
          // const rdHead: TRangradWangbillHead = rdHeadRes.data;
          const rdDetailRes = await tRangradWangbillDetailService.getByDebitNoteCode(rdHeadSelected.idebitnoteNo);
          for (let i = 0; i < rdDetailRes.data.length; i++) {
            let obj1: TRangradWangbillDetail = rdDetailRes.data[i];
            const worksheetHeadViewRes = await tWorksheetHeadService.getByWorksheetCodeViewForTaxInvoice(obj1.iworksheetCode, taxTypeCode);
            let worksheetHeadView: TWorksheetHeadView = worksheetHeadViewRes.data;
            const serviceName = worksheetHeadView.serviceNameRemarks + "งวดที่ " + wshInstallmentSelected?.iatinstalment + "/" + worksheetHeadView.allinstalment + " รอบวันที่ " + wshInstallmentSelected?.dateFrom + " - " + wshInstallmentSelected?.dateTo;

            worksheetHeadView = {
              ...worksheetHeadView,
              debitnotecode: obj1.idebitnoteNo,
              atinstalment: Number(obj1.iatinstalment),
              serviceNameRemarks: serviceName,
            }
            setWshHeadViewSelected(worksheetHeadView);
            // setRdHeadSelected(rdHead);
            buildTaxInvoiceDetailListByWshcode(worksheetHeadView, rdHeadSelected, "ADD");
          }
          await loadDebitNoteAmount(rdHeadSelected.idebitnoteNo!, "ADD");
        }
        // else if (taxTypeCode === "WSH_INSURANCE") {
        //   if (wshHeadViewSelected.iinsuranceAmt! <= 0) {
        //     showNotification(NotificationType.ERROR, "ใบสั่งงาน เลขที่  " + code + "  ไม่มีเงินค้ำประกัน");
        //     return;
        //   } else {
        //     buildTaxInvoiceDetailListInsurance(wshHeadViewSelected);
        //   }
        // }
      }
    })();
  }, [visibleModalWshInstallment]);

  useEffect(() => {
    //if (!visibleModalEditDetail && taxinvoiceDetailSelectEdit.remarkDescriptions && form.getFieldValue("taxTypeCode") === "DEBITNOTE_CODE") {
    if (!visibleModalEditDetail && taxinvoiceDetailSelectEdit.remarkDescriptions) {
      taxinvDetailViews.forEach((obj: TTaxinvoiceDetailView) => {
        if (taxinvoiceDetailSelectEdit.debitnotecode && taxinvoiceDetailSelectEdit.debitnotecode === obj.debitnotecode) {
          obj.remarkDescriptions = taxinvoiceDetailSelectEdit.remarkDescriptions;
        } else if (!taxinvoiceDetailSelectEdit.debitnotecode && taxinvoiceDetailSelectEdit.worksheetcode === obj.worksheetcode) {
          obj.remarkDescriptions = taxinvoiceDetailSelectEdit.remarkDescriptions;
        }
      });

      const array = taxinvDetailViews ? [...taxinvDetailViews] : [];
      setTaxinvDetailViews(array);
    }
  }, [visibleModalEditDetail]);
  
  useEffect(() => {
    if (trigger) {
      form.validateFields()
        .then(() => {
          confirm({
            title: "ยืนยัน!",
            icon: <ExclamationCircleOutlined />,
            content: "ต้องการบันทึกใบกำกับภาษีของสาขา " + ryRefbranchSelected?.name + " หรือไม่",
            cancelText: "ยกเลิก",
            okText: "ยืนยัน",
            onOk() {
              form.submit();
            },
            onCancel() {
              setTrigger(false);
            }
          });
        }).catch(() => {
          setTrigger(false);
        });
    }
  }, [trigger]);

  const initData = async () => {
    const ryRefbranchesRes = await ryRefbranchService.getRyRefbranchList();
    setRyRefbranches(ryRefbranchesRes.data);
    if (!id && pageStage === "ADD") {
      const findStation = (ryRefbranchesRes.data as Array<RyRefbranch>).find((ob) => ob.id === userInfo.branchId);
      setRyRefbranchSelected(findStation);
      setCheckIsHeadOffice(true);
      form.setFieldsValue({
        invoicedate: moment(new Date(), Constants.DATE_FORMAT),
        branch: userInfo.branchId,
        taxTypeCode: "WSH_CODE",
        cash: 0,
        amount: 0,
        chequeamount: 0,
        tax: 0,
        payinamount: 0,
        totalAmt: 0,
        checkIsHeadOffice: true,
      });
    } else {
      const res = await tTaxinvoiceHeadService.findTTaxinvoiceData(id);
      const custres = await tCustomerHeadService.getCustomerByCode(res.data.ttaxinvoiceHeadView.customercode);
      const findStation = (ryRefbranchesRes.data as Array<RyRefbranch>).find((ob) => ob.id === res.data.ttaxinvoiceHeadView.branch);

      setRyRefbranchSelected(findStation);
      setTtaxinvoiceData(res.data);
      setTaxinvDetailViews(res.data.ttaxinvoiceDetailViewList);
      setCheckTaxinvDetailViews(res.data.ttaxinvoiceDetailViewList);
      setCustomers([custres.data]);
      setCheckIsHeadOffice(!res.data.ttaxinvoiceHeadView.customerBranchName)

      form.setFieldsValue({
        bookno: res.data.ttaxinvoiceHeadView.bookno,
        booknumber: res.data.ttaxinvoiceHeadView.booknumber,
        customercode: res.data.ttaxinvoiceHeadView.customercode,
        customernametax: res.data.ttaxinvoiceHeadView.customername,
        customeraddress: res.data.ttaxinvoiceHeadView.customeraddress,
        customertaxid: res.data.ttaxinvoiceHeadView.customerTaxId,
        customerbranchname: res.data.ttaxinvoiceHeadView.customerBranchName,
        invoicedate: res.data.ttaxinvoiceHeadView.invoicedate ? toMomentDate(res.data.ttaxinvoiceHeadView.invoicedate) : "",
        branch: findStation?.id,
        taxTypeCode: res.data.ttaxinvoiceHeadView.types === "00" ? "WSH_CODE" : "DEBITNOTE_CODE",
        checkAutoNumber: false,
        cash: res.data.ttaxinvoiceHeadView.cash,
        chequeamount: res.data.ttaxinvoiceHeadView.chequeamount,
        payinamount: res.data.ttaxinvoiceHeadView.payinamount,
        tax: res.data.ttaxinvoiceHeadView.tax,
        amount: res.data.ttaxinvoiceHeadView.amount,
        totalAmt: res.data.ttaxinvoiceHeadView.amount + res.data.ttaxinvoiceHeadView.tax,
        checkIsHeadOffice: !res.data.ttaxinvoiceHeadView.customerBranchName,
        checkIsBranch: res.data.ttaxinvoiceHeadView.customerBranchName,
      });

      setLoading(false);
    }
  }

  const onItemCliked = (item: TTaxinvoiceDetailView) => {
    setTaxinvoiceDetailSelectEdit(item);
    setVisibleModalEditDetail(true);
  };

  const onDelete = async (item: TTaxinvoiceDetailView, index: number) => {
    const taxTypeCode = form.getFieldValue("taxTypeCode"); //WSH_CODE,DEBITNOTE_CODE
    const array = taxinvDetailViews ? [...taxinvDetailViews] : [];
    array?.splice(index, 1);
    setTaxinvDetailViews([...array]);
    if (taxTypeCode === "WSH_CODE") {
      await loadWorksheetAmount(item.worksheetcode, "REMOVE");
    } else {
      await loadDebitNoteAmount(item.debitnotecode, "REMOVE");
    }

    //ADD to remove list
    if (pageStage === "EDIT") {
      const findItemCheck = (checkTaxinvDetailViews as Array<TTaxinvoiceDetailView>).find((check) => check.worksheetcode === item.worksheetcode); // origin list
      const findItemRem = (taxinvDetailViewsRemoveList as Array<TTaxinvoiceDetailView>).find((ob) => ob.worksheetcode === item.worksheetcode);
      if (!findItemRem && findItemCheck) {
        const arrayRem = taxinvDetailViewsRemoveList ? [...taxinvDetailViewsRemoveList] : [];
        arrayRem?.push(item);
        setTaxinvDetailViewsRemoveList([...arrayRem]);
      }
    }
  }

  const handleSearchCustomer = async (data: any) => {
    if (data.length < 3) return;
    const res = await tCustomerHeadService.getCustomerByParam(data);
    setCustomers(res.data);
  };

  const handleChangeCustomer = (icustCode: any) => {
    const custArray = customers as Array<TCustomerHeadView>;
    const findCust = custArray.find((ob) => ob.icustCode === icustCode);
    const fullName = `${findCust?.iprenameName.trim()}  ${findCust?.iname} ${(findCust?.isurname) ? findCust?.isurname : ""}`;
    const strPreAmp = findCust?.iprovincecode === Number("10") ? "เขต" : "อำเภอ";
    const strPreProvince = findCust?.iprovincecode === Number("10") ? "" : "จังหวัด";
    const fullAddress = `${findCust?.iaddress} ${strPreAmp}${findCust?.iamphurName} ${strPreProvince}${findCust?.iprovinceName} ${findCust?.ipostcode}`;

    if (findCust?.icustCode) {
      form.setFieldsValue({
        customercode: findCust?.icustCode,
        customernametax: fullName,
        customeraddress: fullAddress,
      });
    }
  };

  const handleChangeBranch = (branchCode: any) => {
    const ryRefbranchArray = ryRefbranches as Array<RyRefbranch>;
    const findStation = ryRefbranchArray.find((ob) => ob.id === branchCode);
    setRyRefbranchSelected(findStation);
  }

  const onChangeTaxTypeCode = () => {
    setTaxinvDetailViews([]);
    form.setFieldsValue({
      remarks: "",
      code: "",
      cash: 0,
      chequeamount: 0,
      payinamount: 0,
      amount: 0,
      tax: 0,
      totalAmt: 0,
    });
  }

  const loadWorksheetAmount = async (worksheetcode: string, state: string) => {
    let taxamount = form.getFieldValue("tax") ? form.getFieldValue("tax") : 0;
    let cashamount = form.getFieldValue("cash") ? form.getFieldValue("cash") : 0;
    let chequeamount = form.getFieldValue("chequeamount") ? form.getFieldValue("chequeamount") : 0;
    let payinamount = form.getFieldValue("payinamount") ? form.getFieldValue("payinamount") : 0;

    const financeDetailRes = await tFinanceDetailsService.loadFnDetailForTaxInvoidByWorksheetCode(worksheetcode);
    // if (!financeDetailRes.data) {
    //   showNotification(NotificationType.ERROR, "ไม่พบใบสั่งงาน เลขที่ " + worksheetcode + " ในรายการรับชำระเงิน");
    //   return;
    // } else if (financeDetailRes.data && financeDetailRes.data.length > 0) {
    if (financeDetailRes.data && financeDetailRes.data.length > 0) {
      financeDetailRes.data.forEach(async (obj: TFinanceDetails) => {
        const tfinanceHeadRes = await tFinanceHead2Service.getById(obj.parentid);
        const tfinanceHead: TFinanceHead2 = tfinanceHeadRes.data;
        if (tfinanceHead) {
          if (state === "ADD") {
            taxamount = taxamount + tfinanceHead.taxamount;
            cashamount = cashamount + tfinanceHead.cashamount;
            chequeamount = chequeamount + tfinanceHead.chequeamount;
            payinamount = payinamount + tfinanceHead.payinamount;
          } else {
            taxamount = taxamount - tfinanceHead.taxamount;
            cashamount = cashamount - tfinanceHead.cashamount;
            chequeamount = chequeamount - tfinanceHead.chequeamount;
            payinamount = payinamount - tfinanceHead.payinamount;
          }
          form.setFieldsValue({
            cash: cashamount,
            chequeamount: chequeamount,
            payinamount: payinamount,
          });
        }
      });
    }
  }

  const loadDebitNoteAmount = async (idebitnoteNo: string, state: string) => {
    let taxamount = form.getFieldValue("tax") ? form.getFieldValue("tax") : 0;
    let cashamount = form.getFieldValue("cash") ? form.getFieldValue("cash") : 0;
    let chequeamount = form.getFieldValue("chequeamount") ? form.getFieldValue("chequeamount") : 0;
    let payinamount = form.getFieldValue("payinamount") ? form.getFieldValue("payinamount") : 0;

    const financeDetailRes = await tFinanceDetailsService.loadFnDetailForTaxInvoidByDebitNoteCode(idebitnoteNo);
    // if (!financeDetailRes.data) {
    //   showNotification(NotificationType.WARNING, "เป็นใบแจ้งหนี้ที่ไม่ได้รับเข้าระบบ");
    //   return;
    // } else {
    if (financeDetailRes.data) {
      const tfinanceHeadRes = await tFinanceHead2Service.getById(financeDetailRes.data[0].parentid);
      const tfinanceHead: TFinanceHead2 = tfinanceHeadRes.data;
      if (tfinanceHead) {
        if (state === "ADD") {
          taxamount = taxamount + tfinanceHead.taxamount;
          cashamount = cashamount + tfinanceHead.cashamount;
          chequeamount = chequeamount + tfinanceHead.chequeamount;
          payinamount = payinamount + tfinanceHead.payinamount;
        } else {
          taxamount = taxamount - tfinanceHead.taxamount;
          cashamount = cashamount - tfinanceHead.cashamount;
          chequeamount = chequeamount - tfinanceHead.chequeamount;
          payinamount = payinamount - tfinanceHead.payinamount;
        }

        form.setFieldsValue({
          cash: cashamount,
          chequeamount: chequeamount,
          payinamount: payinamount,
          tax: taxamount,
        });
      }
    }
  }

  const buildTaxInvoiceDetailListByWshcode = (worksheetHeadView: TWorksheetHeadView, rdHead: TRangradWangbillHead, state: string) => {
    const serviceAmt = worksheetHeadView.truckAssettype === "002" ? worksheetHeadView.iserviceAmt : worksheetHeadView.itotal1Amt;
    // let serviceName =  worksheetHeadView.serviceNameRemarks ? worksheetHeadView.serviceNameRemarks : "";

    //กรณีเป็นใบแจ้งหนี้ที่มีใบงานตู้หลายใบ ให้แสดง remark เป็นงวดเดียวกัน ** บังคับ**
    // if(wshInstallmentSelected.iworksheetCode) {
    // serviceName = worksheetHeadView.serviceNameRemarks + "งวดที่ " + wshInstallmentSelected?.iatinstalment + "/" +  worksheetHeadView.allinstalment + " รอบวันที่ " + wshInstallmentSelected?.dateFrom + " - " + wshInstallmentSelected?.dateTo;
    // }
    const temp: TTaxinvoiceDetailView = {
      seq: 0,
      taxinvoiceId: '',
      worksheetcode: worksheetHeadView.iworksheetCode ? worksheetHeadView.iworksheetCode : "",
      debitnotecode: worksheetHeadView.debitnotecode ? worksheetHeadView.debitnotecode : "",
      amount: serviceAmt ? serviceAmt : 0,
      customername: worksheetHeadView.icustName ? worksheetHeadView.icustName : "",
      serviceName: worksheetHeadView.serviceNameRemarks ? worksheetHeadView.serviceNameRemarks : "",
      remarkDescriptions: worksheetHeadView.serviceNameRemarks ? worksheetHeadView.serviceNameRemarks : "",
      startDate: worksheetHeadView.istartDate ? worksheetHeadView.istartDate : "",
      paymentCode: worksheetHeadView.ipaymentCode ? worksheetHeadView.ipaymentCode : "",
      createuser: userInfo.userId,
      termno: userInfo.termNo,
      savstation: userInfo.saveStation,
      descriptions: '',
      itemType: 'NORMAL',
      tworksheetHeadView: worksheetHeadView,
      trangradWangbillHead: rdHead,
      // wshInstallmentView: wshInstallmentSelected,
    }
    setTaxinvoiceDetailBuild(temp)
    setIsBuildTaxInvoiceDetail(true);
  }

  const buildTaxInvoiceDetailListInsurance = (worksheetHeadView: TWorksheetHeadView) => {
    let serviceAmt = worksheetHeadView.iinsuranceAmt ? worksheetHeadView.iinsuranceAmt : 0;
    if (worksheetHeadView.itypetaxCode === "02") {
      serviceAmt = serviceAmt - (Math.round(((serviceAmt * 7) / 107) * 100) / 100);
    }
    const temp: TTaxinvoiceDetailView = {
      seq: 2,
      taxinvoiceId: '',
      worksheetcode: worksheetHeadView.iworksheetCode ? worksheetHeadView.iworksheetCode : "",
      debitnotecode: worksheetHeadView.debitnotecode ? worksheetHeadView.debitnotecode : "",
      amount: serviceAmt ? serviceAmt : 0,
      customername: worksheetHeadView.icustName ? worksheetHeadView.icustName : "",
      serviceName: "เงินค้ำประกันตู้ออฟฟิศ",
      remarkDescriptions: "เงินค้ำประกันตู้ออฟฟิศ",
      startDate: worksheetHeadView.istartDate ? worksheetHeadView.istartDate : "",
      paymentCode: worksheetHeadView.ipaymentCode ? worksheetHeadView.ipaymentCode : "",
      createuser: userInfo.userId,
      termno: userInfo.termNo,
      savstation: userInfo.saveStation,
      descriptions: '',
      itemType: 'INSURANCE',
      tworksheetHeadView: worksheetHeadView,
      trangradWangbillHead: {} as TRangradWangbillHead,
    }
    setTaxinvoiceDetailBuild(temp)
    setIsBuildTaxInvoiceDetail(true);
    return temp;
  }

  const handleSelectWorksheetCode = async () => {
    const taxTypeCode = form.getFieldValue("taxTypeCode"); //WSH_CODE,DEBITNOTE_CODE,WSH_INSURANCE
    const code = form.getFieldValue("code");
    // setWshInstallmentSelected({} as TWorksheetInstalmentDetailView);

    form.setFieldsValue({
      code: undefined,
    });

    if (taxTypeCode === "WSH_CODE" || taxTypeCode === "WSH_INSURANCE") {
      const existWorkSheetCode = await tWorksheetHeadService.getByWorksheetCodeViewForTaxInvoice(code, taxTypeCode);
      const worksheetHeadView: TWorksheetHeadView = existWorkSheetCode.data;
      const foundInSelected = (taxinvDetailViews as Array<TTaxinvoiceDetailView>).find((obj) => obj.worksheetcode === code);

      if (!worksheetHeadView) {
        showNotification(NotificationType.ERROR, "ไม่พบใบสั่งงาน เลขที่ " + code);
        return;
      } else if (foundInSelected) {
        showNotification(NotificationType.ERROR, "ใบสั่งงาน เลขที่ " + code + " นี้ถูกเลือกไว้แล้ว ไม่สามารถเลือกซ้ำได้");
        return;
      } else if (userInfo.branchId !== worksheetHeadView.ibranch) {
        showNotification(NotificationType.ERROR, "ใบสั่งงาน เลขที่  " + code + "  ไม่ได้อยู่ที่สาขานี้");
        return;
      } else {
        //if (worksheetHeadView.ipaymentStatus === "01") { //T_STATUSFINANCE_REF 00	ยังไม่ชำระ, 01	ชำระแล้ว
        if (worksheetHeadView.itypepaymentCode !== "04") {
          if (worksheetHeadView.flagtaxinvoid === "0") {
            if (worksheetHeadView.itypeworksheetCode === "01") {
              if (!foundInSelected) {
                setWshHeadViewSelected(worksheetHeadView);

                if (worksheetHeadView.allinstalment! > 1 && taxTypeCode === "WSH_CODE") {
                  setVisibleModalWshInstallment(true)
                } else {
                  await loadWorksheetAmount(worksheetHeadView.iworksheetCode!, "ADD");
                  if (taxTypeCode === "WSH_CODE") {
                    buildTaxInvoiceDetailListByWshcode(worksheetHeadView, {} as TRangradWangbillHead, "ADD");
                  } else if (taxTypeCode === "WSH_INSURANCE") {
                    if (worksheetHeadView.iinsuranceAmt! <= 0) {
                      showNotification(NotificationType.ERROR, "ใบสั่งงาน เลขที่  " + code + "  ไม่มีเงินค้ำประกัน");
                      return;
                    } else {
                      buildTaxInvoiceDetailListInsurance(worksheetHeadView);
                    }
                  }
                }
              } else {
                showNotification(NotificationType.ERROR, "ไม่สามารถเพิ่มใบสั่งงานได้");
                return;
              }
            } else {
              showNotification(NotificationType.ERROR, "ไม่สามารถสร้างใบกำกับภาษีจากใบงานในได้");
              return;
            }
          } else {
            showNotification(NotificationType.ERROR, "ใบสั่งงานนี้ออกใบกำกับไปแล้ว");
            return;
          }
        } else {
          showNotification(NotificationType.ERROR, "ประเภทใบสั่งงานไม่ถูกต้อง");
          return;
        }
        // } else {
        //   //สดมีมัดจำ และ รับเงินมัดจำแล้ว
        //   if (worksheetHeadView.itypepaymentCode === ReferenceType.DEPOSIT_PAY && worksheetHeadView.idepositFlg === 0) {
        //     if (!foundInSelected) {
        //       await loadWorksheetAmount(worksheetHeadView.iworksheetCode!, "ADD");
        //       buildTaxInvoiceDetailListByWshcode(worksheetHeadView, {} as TRangradWangbillHead, "ADD");
        //     } else {
        //       showNotification(NotificationType.ERROR, "ไม่สามารถเพิ่มใบสั่งงานได้");
        //       return;
        //     }
        //   } else {
        //     showNotification(NotificationType.ERROR, "ใบสั่งงาน เลขที่  " + code + "  ยังไม่ได้ชำระเงินมัดจำ");
        //     return;
        //   }
        // }
      }
    } else { //DEBITNOTE_CODE
      const rdHeadRes = await tRangradWangbillHeadService.getByDebitNoteNo(code);
      const rdHead: TRangradWangbillHead = rdHeadRes.data;
      if (!rdHead) {
        showNotification(NotificationType.ERROR, "ไม่พบใบแจ้งหนี้ เลขที่ " + code);
        return;
      } else if (userInfo.branchId !== rdHead.branchid) {
        showNotification(NotificationType.ERROR, "ใบแจ้งหนี้ เลขที่  " + code + "  ไม่ได้อยู่ที่สาขานี้");
        return;
        //} else if (rdHead.idebitnotestatus !== "99") {
        //showNotification(NotificationType.ERROR, "กรุณาชำระเงินก่อน");
        //return;
      } else if (rdHead.typeDebitnote !== "01") {
        showNotification(NotificationType.ERROR, "ประเภทใบแจ้งหนี้ไม่ถูกต้อง");
        return;
      } else if (rdHead.flagtaxinvoice !== "0") {
        showNotification(NotificationType.ERROR, "ใบแจ้งหนี้ออกใบกำกับไปแล้ว");
        return;
      } else {
        const rdDetailRes = await tRangradWangbillDetailService.getByDebitNoteCode(rdHead.idebitnoteNo);
        let isExist = false;
        if (rdDetailRes.data && rdDetailRes.data.length > 0) {
          //rdDetailRes.data.forEach(async (obj: TRangradWangbillDetail) => {
          // rdDetailRes.data.map(async (obj: TRangradWangbillDetail) => {
          if (await checkIsInstallment(rdDetailRes.data, taxTypeCode)) {
            const worksheetHeadViewRes = await tWorksheetHeadService.getByWorksheetCodeViewForTaxInvoice(rdDetailRes.data[0].iworksheetCode, taxTypeCode);
            const worksheetHeadView: TWorksheetHeadView = worksheetHeadViewRes.data;
            setWshHeadViewSelected(worksheetHeadView);
            setRdHeadSelected(rdHead);
            setVisibleModalWshInstallment(true);
          } else {
            for (let i = 0; i < rdDetailRes.data.length; i++) {
              let obj: TRangradWangbillDetail = rdDetailRes.data[i];
              const worksheetHeadViewRes = await tWorksheetHeadService.getByWorksheetCodeViewForTaxInvoice(obj.iworksheetCode, taxTypeCode);
              let worksheetHeadView: TWorksheetHeadView = worksheetHeadViewRes.data;
              worksheetHeadView = {
                ...worksheetHeadView,
                debitnotecode: obj.idebitnoteNo,
                atinstalment: Number(obj.iatinstalment),
              }

              const foundInSelected = (taxinvDetailViews as Array<TTaxinvoiceDetailView>).find((obj) => obj.worksheetcode === worksheetHeadView.iworksheetCode);
              if (foundInSelected) {
                isExist = true;
                showNotification(NotificationType.ERROR, "ใบสั่งงาน เลขที่ " + worksheetHeadView.iworksheetCode + " นี้ถูกเลือกไว้แล้ว ไม่สามารถเลือกซ้ำได้");
                return;
              } else {
                setWshHeadViewSelected(worksheetHeadView);
                setRdHeadSelected(rdHead);
                buildTaxInvoiceDetailListByWshcode(worksheetHeadView, rdHead, "ADD");
              }
            }//)
          }
          if (!isExist) {
            await loadDebitNoteAmount(rdHead.idebitnoteNo!, "ADD");
          }
        }
      }
    }
  }

  const checkIsInstallment = async (data: any, taxTypeCode: string) => {
    let isWshInstallment = false;
    for (let i = 0; i < data.length; i++) {
      let obj: TRangradWangbillDetail = data[i];
      const worksheetHeadViewRes = await tWorksheetHeadService.getByWorksheetCodeViewForTaxInvoice(obj.iworksheetCode, taxTypeCode);
      if (worksheetHeadViewRes.data.allinstalment! > 1) {
        // console.log("------TRangradWangbillDetail -- obj.iatinstalment : ", obj.iatinstalment)
        // if (Number(obj.iatinstalment) > 0) {
        isWshInstallment = true;
        //break;
        return isWshInstallment;
      }
    }
  }

  const onFinish = async (values: any) => {
    const custArray = customers as Array<TCustomerHeadView>;
    const findCust = custArray.find((ob) => ob.icustCode === values.customercode);
    const fullName = `${findCust?.iprenameName.trim()}  ${findCust?.iname} ${(findCust?.isurname) ? findCust?.isurname : ""}`;

    const temp: TTaxinvoiceData = {
      ttaxinvoiceHeadView: {
        ...(ttaxinvoiceData.ttaxinvoiceHeadView as TTaxinvoiceHeadView),
        id: pageStage === "ADD" ? "" : ttaxinvoiceData.ttaxinvoiceHeadView.id,
        flg: pageStage === "ADD" ? 0 : ttaxinvoiceData.ttaxinvoiceHeadView.flg,
        bookno: (checkAutoNumber || pageStage === "EDIT") ? values.bookno : "",
        booknumber: (checkAutoNumber || pageStage === "EDIT") ? values.booknumber : "",
        branch: values.branch,
        customercode: values.customercode,
        customername: values.customernametax,
        customeraddress: values.customeraddress,
        customerTaxId: values.customertaxid,
        customerBranchName: values.customerbranchname,
        invoicedate: moment(values.invoicedate).format("yyyyMMDD"),
        numofprint: pageStage === "ADD" ? 0 : ttaxinvoiceData.ttaxinvoiceHeadView.numofprint,
        typeofdistri: pageStage === "ADD" ? "00" : ttaxinvoiceData.ttaxinvoiceHeadView.typeofdistri,//ใช้ ออกใบกำกับแบบแยกรายละเอียด ทั้งหมด เพราะหน้าจอเก่า disable อันอื่นไว้
        description: pageStage === "ADD" ? "" : ttaxinvoiceData.ttaxinvoiceHeadView.description,//ใช้ ออกใบกำกับแบบแยกรายละเอียด ทั้งหมด เพราะหน้าจอเก่า disable อันอื่นไว้
        types: values.taxTypeCode === "WSH_CODE" ? "00" : "01",
        cash: values.cash,
        amount: values.amount,
        chequeamount: values.chequeamount,
        payinamount: values.payinamount,
        creditamount: 0,
        tax: values.tax,
        remarks: values.remarks,
        branchName: ryRefbranchSelected?.name,
        totalAmt: values.totalAmt,
        createuser: pageStage === "ADD" ? userInfo.userId : ttaxinvoiceData.ttaxinvoiceHeadView.createuser,
        lastuser: userInfo.userId,
        termno: userInfo.termNo,
        savstation: userInfo.saveStation,
      },      
      wshInstallmentView: wshInstallmentSelected,
      ttaxinvoiceDetailViewList: [...taxinvDetailViews],
      taxinvDetailViewsRemoveList: [...taxinvDetailViewsRemoveList]
    }

    try {
      // console.log(" ********** temp : ", temp);
      const tTaxinvoiceRes = await tTaxinvoiceHeadService.createUpdateTaxInvoiceData(temp, pageStage);
      showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเรียบร้อย!");
      form.resetFields();
      setTrigger(false);
      setOnDrawerVisible(false);
    } catch {
      showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ");
      setTrigger(false);
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน!");
    setTrigger(false);
  };

  return (
    <>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Typography.Title level={4}>โปรแกรมออกใบกำกับภาษี</Typography.Title>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "",
              }}
            >
              <Button
                type="primary"
                danger
                icon={<CloseCircleOutlined />}
                onClick={() => setOnDrawerVisible(false)}>
                ยกเลิก
              </Button>
              <Button
                onClick={() => setTrigger(true)}
                type="primary"
                icon={<SaveOutlined />}
                style={{ marginLeft: "5px" }}
              >
                บันทึก
              </Button>
            </div>
          </div>
        }
        width="90%"
        visible={onDrawerVisible}
        forceRender={true}
        placement="right"
        bodyStyle={{ paddingBottom: 80 }}
        closable={false}
        destroyOnClose={true}
      >
        <Content className="app-page-content">
          <Spin spinning={loading}>
            <Form
              form={form}
              name="taxInvoiceHead"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Card
                style={{
                  width: "auto",
                  marginBottom: 5,
                }}
                headStyle={{
                  fontSize: 14,
                  paddingBottom: 5,
                }}
                title="ข้อมูลหลัก"
              >
                <Row>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>วันที่ออกใบกำกับ: </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="invoicedate"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <DatePicker
                        format={Constants.DATE_FORMAT}
                        style={{ width: "50%" }}
                        disabled={pageStage === "EDIT"}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>เล่มที่: </Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="bookno"
                      style={{ margin: "0 0 3px 0px" }}
                      rules={[{
                        validator: (_, value) => {
                          if (checkAutoNumber && !value) {
                            return Promise.reject(new Error('กรุณาระบุเลขที่/เล่มที่ของใบกำกับภาษี'))
                          } else {
                            return Promise.resolve()
                          }
                        }
                      },]}
                    >
                      <Input
                        disabled={!checkAutoNumber}
                        style={{ width: "99%", marginRight: 5 }}
                      >
                      </Input>
                    </Form.Item>
                  </Col>
                  <Col span={5} style={{ paddingLeft: "0.5em" }}>
                    {/* <Form.Item
                      name="checkAutoNumber"
                      valuePropName="checked"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Checkbox
                        onChange={(e) => {
                          form.setFieldsValue({
                            checkAutoNumber: Boolean(e.target.checked)
                          });
                          setCheckAutoNumber(Boolean(e.target.checked));
                        }
                        }
                      >
                        กำหนดเลขที่ใบกำกับเอง
                      </Checkbox>
                    </Form.Item> */}
                  </Col>
                </Row>
                <Row>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>สาขา: </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="branch"
                      rules={[{ required: false }]}
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Select
                        showSearch
                        size="middle"
                        placeholder="เลือกสาขา"
                        optionFilterProp="children"
                        filterOption={true}
                        onSelect={handleChangeBranch}
                      >
                        {ryRefbranches.map((obj) => {
                          return (
                            <Option key={obj.id} value={obj.id}>
                              {obj.id} : {obj.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>เลขที่: </Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="booknumber"
                      style={{ margin: "0 0 3px 0px" }}
                      rules={[{
                        validator: (_, value) => {
                          if (checkAutoNumber && !value) {
                            return Promise.reject(new Error('กรุณาระบุเลขที่/เล่มที่ของใบกำกับภาษี'))
                          } else {
                            return Promise.resolve()
                          }
                        }
                      },]}
                    >
                      <Input
                        disabled={!checkAutoNumber}
                        style={{ width: "99%", marginRight: 5 }}
                      >
                      </Input>
                    </Form.Item>
                  </Col>
                  <Col span={5}></Col>
                </Row>
                <Row>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>ได้รับเงินจาก: </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="customercode"
                      style={{ margin: "0 0 3px 0px" }}
                      rules={[{ required: true, message: "กรุณาระบุลูกค้าก่อน" }]}
                    >
                      <Select
                        showSearch
                        allowClear
                        size="middle"
                        placeholder="เลือกลูกค้า"
                        optionFilterProp="children"
                        filterOption={false}
                        onSearch={handleSearchCustomer}
                        onSelect={handleChangeCustomer}
                      >
                        {customers.map((obj) => {
                          return (
                            <Option key={obj.icustCode} value={obj.icustCode}>
                              {obj.icustCode}: {obj.iprenameName}{obj.iname}   {obj.isurname ? obj.isurname : ""}: {obj.iphone}<br />
                              <span style={{ marginLeft: "1rem", fontSize: 12 }}>
                                {obj.iaddress}{'   '}อำเภอ/เขต{'   '}{obj.iamphurName}{'   '}จังหวัด{'   '}{obj.iprovinceName}{'   '}{obj.ipostcode}
                              </span>
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={3} style={{ padding: "0.1em" }}>
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => setVisibleModalAddCust(true)}
                      icon={<PlusSquareOutlined />}
                    >
                      เพิ่ม
                    </Button>
                  </Col>
                  <Col span={3} style={{ paddingLeft: "0.5em" }}>
                    <Form.Item
                      name="checkIsHeadOffice"
                      valuePropName="checked"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Checkbox
                        disabled={!checkIsHeadOffice}
                        onChange={(e) => {
                          form.setFieldsValue({
                            checkIsHeadOffice: Boolean(e.target.checked),
                            checkIsBranch: Boolean(!e.target.checked)
                          });
                          setCheckIsHeadOffice(Boolean(e.target.checked));
                        }
                        }
                      >
                        สำนักงานใหญ่
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={6} style={{ paddingLeft: "0.5em" }}>
                    <Form.Item
                      name="checkIsBranch"
                      valuePropName="checked"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Checkbox
                        disabled={checkIsHeadOffice}
                        onChange={(e) => {
                          form.setFieldsValue({
                            checkIsBranch: Boolean(e.target.checked),
                            checkIsHeadOffice: Boolean(!e.target.checked)
                          });
                          setCheckIsHeadOffice(Boolean(!e.target.checked));
                        }
                        }
                      >
                        สาขา
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>ชื่อที่ออกใบกำกับภาษี: </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="customernametax"
                      style={{ margin: "0 0 3px 0px" }}
                      rules={[{ required: true, message: "กรุณาระบุชื่อที่ออกใบกำกับภาษี" }]}
                    >
                      <Input
                        style={{ width: "99%", marginRight: 5 }}
                      >
                      </Input>
                    </Form.Item>
                  </Col>
                  <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>เลขผู้เสียภาษีอากร: </Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="customertaxid"
                      style={{ margin: "0 0 3px 0px" }}
                      rules={[
                        { required: true, message: "กรุณาระบุหมายเลขผู้เสียภาษีอากร" },
                      ]}
                    >
                      <Input
                        onKeyDown={(event: any) => textNumber(event)}
                        maxLength={13}
                        style={{ width: "99%", marginRight: 5 }}
                      >
                      </Input>
                    </Form.Item>
                  </Col>
                  <Col span={5}></Col>
                </Row>
                <Row>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>ที่อยู่: </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="customeraddress"
                      style={{ margin: "0 0 3px 0px" }}
                      rules={[{ required: true, message: "กรุณาระบุที่อยู่ลูกค้าก่อน" }]}
                    >
                      <TextArea
                        rows={2}
                        style={{ width: "99%", marginRight: 5 }}
                      >
                      </TextArea>
                    </Form.Item>
                  </Col>
                  <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>ชื่อสาขา: </Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      name="customerbranchname"
                      style={{ margin: "0 0 3px 0px" }}
                      rules={[{
                        validator: (_, value) => {
                          if (!checkIsHeadOffice && !value) {
                            return Promise.reject(new Error('กรุณาระบุสาขาของลูกค้า'))
                          } else {
                            return Promise.resolve()
                          }
                        }
                      },]}
                    >
                      <Input
                        disabled={checkIsHeadOffice}
                        style={{ width: "99%", marginRight: 5 }}
                      >
                      </Input>
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                  </Col>
                </Row>
              </Card>

              <Card
                style={{
                  width: "auto",
                  marginBottom: 5,
                }}
                headStyle={{
                  fontSize: 14,
                  paddingBottom: 5,
                }}
                title="รายละเอียด"
              >
                <Row>
                  <Col>
                    <Form.Item
                      name="taxTypeCode"
                    >
                      <Radio.Group
                        style={{ margin: "0px" }}
                        onChange={() => onChangeTaxTypeCode()}
                      >
                        <Radio value="WSH_CODE" style={{ backgroundColor: "blue", color: "#FFF", borderRadius: 3 }}>ใบสั่งงาน</Radio>
                        <Radio value="DEBITNOTE_CODE" style={{ backgroundColor: "red", color: "#FFF", borderRadius: 3 }}>ใบแจ้งหนี้</Radio>
                        <Radio value="WSH_INSURANCE" style={{ backgroundColor: "green", color: "#FFF", borderRadius: 3 }}>เงินคำ้ประกัน</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>เลขที่ใบงาน/เลขที่ใบแจ้งหนี้: </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="code"
                      style={{ margin: "0 0 10 0" }}
                    >
                      <Input
                        allowClear={false}
                        onFocus={(e: any) => e.target.select()}
                        onKeyDown={(evt: any) => {
                          if (evt.keyCode === 13) {
                            handleSelectWorksheetCode();
                          }
                        }}
                      ></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <div>
                  <Table
                    style={{ marginBottom: 6 }}
                    columns={columns}
                    dataSource={taxinvDetailViews}
                    size="small"
                    scroll={{ x: 1500, y:800 }}
                    rowKey={(item: TTaxinvoiceDetailView) => item.worksheetcode}
                    // onRow={(item: TTaxinvoiceDetailView) => {
                    //   return {
                    //     onDoubleClick: () => onItemCliked(item),
                    //   };
                    // }}
                    pagination={false}
                  />
                </div>
                <Row>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>หมายเหตุ: </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="remarks"
                      rules={[{
                        validator: (_, value) => {
                          if (pageStage !== "ADD" && !value) {
                            return Promise.reject(new Error('กรุณาระบุหมายเหตุ'))
                          } else {
                            return Promise.resolve()
                          }
                        },
                      }]}
                    >
                      <TextArea
                        rows={2}
                      ></TextArea>
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ padding: "5px 0px 0px" }}>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>เงินสด: </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Space align='baseline' style={{ width: "80%" }}>
                      <Form.Item
                        name="cash"
                        style={{ margin: "0 0 3px 0px" }}
                      >
                        <InputNumber
                          controls={false}
                          formatter={(value) => formatNumber(value)}
                          parser={(value) => parserNumber(value)}
                          readOnly={true}
                          style={{
                            color: "#FD1212",
                            backgroundColor: "#F7F7F8",
                            width: "99%",
                          }}
                        >
                        </InputNumber>
                      </Form.Item>
                      <Typography.Text> บาท</Typography.Text>
                    </Space>
                  </Col>
                  <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>มูลค่าสินค้า/บริการ: </Typography.Text>
                  </Col>
                  <Col span={9}>
                    <Space align='baseline' style={{ width: "80%" }}>

                      <Form.Item
                        name="amount"
                        style={{ margin: "0 0 3px 0px" }}
                        rules={[{
                          validator: (_, value) => {
                            if (!value || value <= 0) {
                              return Promise.reject(new Error('กรุณาระบุใบงานหรือใบแจ้งหนี้ก่อน'))
                            } else {
                              return Promise.resolve()
                            }
                          }
                        },]}
                      >
                        <InputNumber
                          controls={false}
                          formatter={(value) => formatNumber(value)}
                          parser={(value) => parserNumber(value)}
                          readOnly={true}
                          style={{
                            color: "#FD1212",
                            backgroundColor: "#F7F7F8",
                            width: "99%",
                          }}
                        >
                        </InputNumber>
                      </Form.Item>
                      <Typography.Text> บาท</Typography.Text>
                    </Space>
                  </Col>
                </Row>
                <Row style={{ padding: "5px 0px 0px" }}>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>เช็ค: </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Space align='baseline' style={{ width: "80%" }}>
                      <Form.Item
                        name="chequeamount"
                        style={{ margin: "0 0 3px 0px" }}
                      >
                        <InputNumber
                          controls={false}
                          formatter={(value) => formatNumber(value)}
                          parser={(value) => parserNumber(value)}
                          readOnly={true}
                          style={{
                            color: "#FD1212",
                            backgroundColor: "#F7F7F8",
                            width: "99%",
                          }}
                        >
                        </InputNumber>
                      </Form.Item>
                      <Typography.Text> บาท</Typography.Text>
                    </Space>
                  </Col>
                  <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>ภาษีมูลค่าเพิ่ม 7%: </Typography.Text>
                  </Col>
                  <Col span={9}>
                    <Space align='baseline' style={{ width: "80%" }}>

                      <Form.Item
                        name="tax"
                        style={{ margin: "0 0 3px 0px" }}
                      >
                        <InputNumber
                          controls={false}
                          formatter={(value) => formatNumber(value)}
                          parser={(value) => parserNumber(value)}
                          readOnly={true}
                          style={{
                            color: "#FD1212",
                            backgroundColor: "#F7F7F8",
                            width: "99%",
                          }}
                        >
                        </InputNumber>
                      </Form.Item>
                      <Typography.Text> บาท</Typography.Text>
                    </Space>
                  </Col>
                </Row>
                <Row style={{ padding: "5px 0px 0px" }}>
                  <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>เงินโอน: </Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Space align='baseline' style={{ width: "80%" }}>
                      <Form.Item
                        name="payinamount"
                        style={{ margin: "0 0 3px 0px" }}
                      >
                        <InputNumber
                          controls={false}
                          formatter={(value) => formatNumber(value)}
                          parser={(value) => parserNumber(value)}
                          readOnly={true}
                          style={{
                            color: "#FD1212",
                            backgroundColor: "#F7F7F8",
                            width: "99%",
                          }}
                        >
                        </InputNumber>
                      </Form.Item>
                      <Typography.Text> บาท</Typography.Text>
                    </Space>
                  </Col>
                  <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>รวมจำนวนเงินทั้งสิ้น: </Typography.Text>
                  </Col>
                  <Col span={9}>
                    <Space align='baseline' style={{ width: "80%" }}>

                      <Form.Item
                        name="totalAmt"
                        style={{ margin: "0 0 3px 0px" }}
                      >
                        <InputNumber
                          controls={false}
                          formatter={(value) => formatNumber(value)}
                          parser={(value) => parserNumber(value)}
                          readOnly={true}
                          style={{
                            color: "#FD1212",
                            backgroundColor: "#F7F7F8",
                            width: "99%",
                          }}
                        >
                        </InputNumber>
                      </Form.Item>
                      <Typography.Text> บาท</Typography.Text>
                    </Space>
                  </Col>
                </Row>
              </Card>
            </Form>
          </Spin>
        </Content>
      </Drawer>

      <div>
        {visibleModalAddCust && (
          <AddCustomerModal
            visibleModalAddCust={visibleModalAddCust}
            setVisibleModalAddCust={setVisibleModalAddCust}
          ></AddCustomerModal>
        )}
      </div>
      <div>
        {visibleModalEditDetail && (
          <EditTaxInvoiceDetailModal
            visibleModalEditDetail={visibleModalEditDetail}
            setVisibleModalEditDetail={setVisibleModalEditDetail}
            taxinvoiceDetailSelectEdit={taxinvoiceDetailSelectEdit}
            setTaxinvoiceDetailSelectEdit={setTaxinvoiceDetailSelectEdit}
          ></EditTaxInvoiceDetailModal>
        )}
      </div>
      <div>
        {visibleModalWshInstallment && (
          <SelectWorksheetInstallmentModal
            visibleModalWshInstallment={visibleModalWshInstallment}
            setVisibleModalWshInstallment={setVisibleModalWshInstallment}
            wshHeadViewSelected={wshHeadViewSelected}
            setWshHeadViewSelected={setWshHeadViewSelected}
            wshInstallmentSelected={wshInstallmentSelected}
            setWshInstallmentSelected={setWshInstallmentSelected}
          ></SelectWorksheetInstallmentModal>
        )}
      </div>
    </>
  )
}

export default TaxInvoiceDrawer
