import http from "../../lib/http";
import Constants from "../../utils/constants";

const getTServiceHeadById = (id: number) => {
  return http.get(Constants.DEFAULT_URL + "/tServiceHead/" + id);
};

const getTServiceHeadByParam = (name: string) => {
  return http.get(Constants.DEFAULT_URL + "/tServiceHead/getTServiceHeadByParam", {
    params: {
      name
    }
  });
};

const getServiceByTypeAsset = (assetType: string, truckType: string, truckSize: string, truckWheel: string
) => {
  return http.get(Constants.DEFAULT_URL + "/tServiceHead/getServiceByTypeAssetList", {
    params: {
      assetType,
      truckType,
      truckSize,
      truckWheel,
    },
  });
};

const getTServiceHeadByCode = (code: string) => {
  return http.get(Constants.DEFAULT_URL + "/tServiceHead/getTServiceHeadByCode/" + code);
};

const updateTServiceHead = (tServiceHead: any) => {
  return http.put(Constants.DEFAULT_URL + "/tServiceHead", tServiceHead);
}

const postTServiceHead = (tServiceHead: any) => {
  return http.post(Constants.DEFAULT_URL + "/tServiceHead", tServiceHead);
}

const getNextTServiceHeadCode = () => {
  return http.get(Constants.DEFAULT_URL + "/tServiceHead/nextCode");
}

const defaultApi = {
  getTServiceHeadById,
  getTServiceHeadByParam,
  getServiceByTypeAsset,
  getTServiceHeadByCode,
  updateTServiceHead,
  postTServiceHead,
  getNextTServiceHeadCode
};
export default defaultApi;
