import { Modal, Table } from "antd";
import Search from "antd/lib/input/Search";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { TAssetHead } from "src/domain/master/tAssetHead";
import tAssetHeadService from "../../services/tasset/tAssetHeadService";

interface IParams {
  selectedTAssetHeads: TAssetHead[];
  setSelectedTAssetHeads?: any;
  visibleSteelDischargeAssetModal: boolean;
  setVisibleSteelDischargeAssetModal: any;
}

const SteelDischargeAssetModal: React.FC<IParams> = (props) => {
  const { selectedTAssetHeads, setSelectedTAssetHeads, visibleSteelDischargeAssetModal, setVisibleSteelDischargeAssetModal } = props;
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [tAssetHeads, setTAssetHeads] = useState<TAssetHead[]>([]);
  const [selectedRows, setSelectedRows] = useState<TAssetHead[]>([]);

  useEffect(() => {
    doSearch("");
  }, []);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      render: (value: number, item: TAssetHead, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
    },
    {
      title: "รหัสสินทรัพย์",
      dataIndex: "id",
      key: "id",
      width: 150,
    },
    {
      title: "เบอร์",
      dataIndex: "numbers",
      key: "numbers",
      width: 80
    },
    {
      title: "ชื่อสินทรัพย์",
      dataIndex: "assetname",
      key: "assetname"
    },
    {
      title: "รายละเอียด",
      dataIndex: "assetdescription",
      key: "assetdescription",
    }
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: TAssetHead[]) => {
      setSelectedRows(selectedRows);
    },

  };

  const doSearch = async (searchText: string) => {
    setLoading(true);
    const res = await tAssetHeadService.findByAssetType(searchText, "003", "00");
    // exclude selected asset
    const tAssetHeadList = res.data as TAssetHead[];
    const filteredList = tAssetHeadList.filter((item) => !selectedTAssetHeads.find((selectedItem) => item.id === selectedItem.id));
    setTAssetHeads(filteredList);
    setLoading(false);
  }

  function onSearchTextChange(searchText: string) {
    if (!searchText) {
      doSearch("");
    } else if (searchText.length >= 2) {
      doSearch(searchText);
    }
  }

  const onOkButtonCliked = () => {
    setSelectedTAssetHeads([...selectedTAssetHeads, ...selectedRows])
    setVisibleSteelDischargeAssetModal(false);
  };

  return (
    <>
      <Modal
        title="รายการแผ่นเหล็ก"
        okText={"ตกลง"}
        cancelText={"ยกเลิก"}
        visible={visibleSteelDischargeAssetModal}
        width={700}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => onOkButtonCliked()}
        onCancel={() => setVisibleSteelDischargeAssetModal(false)}
      >
        <Content className="app-page-content">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              margin: "1em 0 0 0",
              borderBlockEnd: "2px solid #c9c9c9",
            }}
          >
            <label style={{ margin: "0 1em 1em 2em" }}>คำค้นหา</label>
            <Search
              allowClear
              style={{ width: "350px" }}
              size="middle"
              placeholder="ป้อนชื่อ/เบอร์/ประเภท/พนักงานขับรถ"
              onChange={(event) => onSearchTextChange(event.target.value)}
              onSearch={(value) => onSearchTextChange(value)}
            ></Search>
          </div>
          <div>
            <Table
              columns={columns}
              dataSource={tAssetHeads}
              size="small"
              loading={loading}
              pagination={{
                pageSize: 10,
                onChange(current) {
                  setPage(current);
                }
              }}
              rowSelection={rowSelection}
              rowKey={(item: TAssetHead) => item.id}
            />
          </div>
        </Content>
      </Modal>
    </>
  );
};

export default SteelDischargeAssetModal;
