import { Form, Modal, Select, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { TEmployee2View } from 'src/domain/master/view/tEmployee2View';
import { TTypepaymentRef } from 'src/domain/tTypepaymentRef';
import { TUserHead } from 'src/domain/tUserHead';
import { TWorksheetHead } from 'src/domain/worksheet/tWorksheetHead';
import { WorksheetHeadData } from 'src/domain/worksheet/view/worksheetHeadData';
import employeeService from 'src/services/employee/tEmployee2Service';
import tTypepaymentRefService from 'src/services/reference/tTypepaymentRefService';
import worksheetService from 'src/services/worksheetService';
import { useAppSelector } from 'src/stores/store';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';

const { Option } = Select;

interface IProps {
  editPaymentModalVisible: boolean;
  setEditPaymentModalVisible: any;
  // checkReceiptDepositModalVisible: boolean;
  // setCheckReceiptDepositModalVisible: any;
  worksheetHeadData: WorksheetHeadData;
  setWorksheetHeadData: any;
  tUserHeadUpdate: TUserHead;
}
const EditPaymentTypeModal: React.FC<IProps> = (props) => {
  const { editPaymentModalVisible, setEditPaymentModalVisible, worksheetHeadData, setWorksheetHeadData, tUserHeadUpdate } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [paymentTypeRefs, setPaymentTypeRefs] = useState<TTypepaymentRef[]>([]);//ประเภทการชำระเงิน
  const [currentPaymentType, setCurrentPaymentType] = useState<TTypepaymentRef>({} as TTypepaymentRef);//ประเภทการชำระเงิน
  const [employees, setEmployees] = useState<TEmployee2View[]>([]);//ผู้แจ้ง
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  const [form] = Form.useForm();

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  useEffect(() => {
    //Load ครั้งแรก ดึงค่ามาโชว์
    form.setFieldsValue({
      typePaymentCode: worksheetHeadData.tworksheetHead.itypepaymentCode
    });

    loadData();
    return () => { };
  }, []);

  const loadData = async () => {
    if (editPaymentModalVisible) {
      const paymentTypeRefs = await tTypepaymentRefService.getTTypepaymentRefList();
      const oldPaymentType = (paymentTypeRefs.data as Array<TTypepaymentRef>).find((obj) => obj.iid === worksheetHeadData.tworksheetHead.itypepaymentCode);

      setPaymentTypeRefs(paymentTypeRefs.data);
      setCurrentPaymentType(oldPaymentType!);
    }
  };

  const onFinish = async (values: any) => {
    if (currentPaymentType.iid === values.typePaymentCode) {
      showNotification(NotificationType.WARNING, "ไม่มีการเปลี่ยนแปลงประเภทการชำระเงิน");
      setTrigger(false);
    } else if (currentPaymentType.iid === "01") {
      showNotification(NotificationType.ERROR, "ไม่อนุญาตให้มีการเปลี่ยนแปลงประเภทการชำระเงิน");
      setTrigger(false);
    } else {
      // comment ไว้ เพราะ code เก่าไม่ได้ทำอะไร แค่เปิด UI เฉยๆ
      // if (currentPaymentType.iid === "04" && values.typePaymentCode === "01") {
      //   setCheckReceiptDepositModalVisible(true);
      //  }
      const employeeArr = employees as Array<TEmployee2View>;
      const foundEmp = employeeArr.find((obj) => obj.id === values.userInfoId);
      const temp = {
        ...(worksheetHeadData as WorksheetHeadData),
        tworksheetHead: {
          ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
          itypepaymentCode: values.typePaymentCode,
          ilastUser: tUserHeadUpdate.iuserId,
          itermNo: userInfo.termNo,
          isavStation: userInfo.saveStation,
        },
        updateUser: tUserHeadUpdate,
      };
      setWorksheetHeadData(temp);

      try {
        await worksheetService.updateWorksheetWithSubMenuKey(temp, values.userRemark, foundEmp ? foundEmp.empname : "", "MENU_PAYMENT");
        if (values.typePaymentCode === "04") {
          showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลและส่งการเงินเรียบร้อยแล้ว!");
        } else { //02,03
          showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเรียบร้อยแล้ว!");
        }
        setEditPaymentModalVisible(false);
        form.resetFields();
        setTrigger(false);
      } catch (err) {
        showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        setTrigger(false);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาตรวจสอบข้อมูลให้ครบถ้วน!");
    setTrigger(false);
  };

  const handleSearchEmployee = async (data: any) => {
    if (data.length < 3) return;
    const res = await employeeService.getEmployee2ByParam(data);
    setEmployees(res.data);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>แก้ไขประเภทการชำระเงิน</Typography.Title>
          </div>
        }
        visible={editPaymentModalVisible}
        width={"50%"}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setEditPaymentModalVisible(false)}
      >
        <Form
          form={form}
          name="paymentType"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="ประเภทการชำระเงิน"
            name="typePaymentCode"
            rules={[
              { required: true, message: "กรุณาระบุ ประเภทการชำระเงิน !" },
              {
                validator: (_, value) => {
                  if ((currentPaymentType.iid === "04" && worksheetHeadData.tcustomerHeadView.itypecustomerCode === "00") ||
                    (value === "02" && currentPaymentType.iid === "03") ||
                    (value === "03" && currentPaymentType.iid === "02") ||
                    (value === "04" && currentPaymentType.iid === "03")) {
                    return Promise.reject(showNotification(NotificationType.ERROR, "ไม่อนุญาตให้ทำรายการ"))
                  } else {
                    if (currentPaymentType.iid === "03" && worksheetHeadData.tworksheetHead.idepositFlg == 0) {
                      return Promise.reject(showNotification(NotificationType.ERROR, "การเงินรับเงินมัดจำแล้ว ไม่อนุญาตให้ทำรายการ"))
                    } else {
                      return Promise.resolve()
                    }
                  }
                },
              },
            ]}
          >
            <Select
              showSearch
              size="middle"
              placeholder="เลือกชำระเงินแบบ"
              optionFilterProp="children"
              filterOption={true}
            >
              {paymentTypeRefs.map((obj) => {
                return (
                  <Option key={obj.iid} value={obj.iid}>
                    {obj.iname}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label="ผู้แจ้ง"
            name="userInfoId"
            rules={[{ required: true, message: "กรุณาระบุ ผู้แจ้ง!" }]}
          >
            <Select
              showSearch
              allowClear
              size="middle"
              placeholder="เลือกผู้แจ้ง"
              optionFilterProp="children"
              filterOption={true}
              onSearch={handleSearchEmployee}
            >
              {employees.map((obj) => {
                return (
                  <Option key={obj.id} value={obj.id}>
                    {obj.empname}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label="หมายเหตุ"
            name="userRemark"
            rules={[{ required: true, message: "กรุณาระบุหมายเหตุ !" }]}
          >
            <TextArea
              rows={4}
            ></TextArea>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default EditPaymentTypeModal