import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Space, Spin, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import AddWorksheetReturnModal from '../modal/AddWorksheetReturnModal';

import { TAssetmovementDetail } from 'src/domain/movement/tAssetmovementDetail';
import { TWorksheetHeadView } from 'src/domain/worksheet/view/tWorksheetHeadView';
import tDebitorDetailService from 'src/services/debitor/tDebitorDetailService';
import tAssetmovementDetailService from 'src/services/tAssetmovementDetailService';
import tWorksheetHeadService from "src/services/tWorksheetHeadService";
import { toThDate } from 'src/utils/dateTimeUtils';
import { formatNumber, formatNumberInt } from 'src/utils/numberUtils';

interface IProp {
  returnWorksheets: TWorksheetHeadView[];
  setReturnWorksheets: any;
  workingWorksheets10d: TWorksheetHeadView[];
  setWorkingWorksheets10d: any;
  workingWorksheets30d: TWorksheetHeadView[];
  setWorkingWorksheets30d: any;
  workingWorksheets90d: TWorksheetHeadView[];
  setWorkingWorksheets90d: any;
  workingWorksheets1y: TWorksheetHeadView[];
  setWorkingWorksheets1y: any;
  loading: boolean;
}

const ReturnWorksheetTab: React.FC<IProp> = (props) => {
  const { returnWorksheets, setReturnWorksheets, workingWorksheets10d, setWorkingWorksheets10d, 
    workingWorksheets30d, setWorkingWorksheets30d, workingWorksheets90d, setWorkingWorksheets90d, 
    workingWorksheets1y, setWorkingWorksheets1y, loading
  } = props;
  const [form] = Form.useForm();
  const [worksheetReturnModalVisible, setWorksheetReturnModalVisible] = useState<boolean>(false);
  const [worksheetHeadSelected, setWorksheetHeadSelected] = useState<TWorksheetHeadView>();

  const [countWshAcc, setCountWshAcc] = useState(0);//นับจำนวนใบงาน การเงินและบัญชี
  const [countWshRengrad, setCountWshRengrad] = useState(0);//นับจำนวนใบงาน เร่งรัดหนี้สิน
  const [flagRemove, setFlagRemove] = useState<string>();
  const [isAdd, setIsAdd] = useState<boolean>(false);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 70,
      align: "center" as "center",
      render: (value: number, item: TWorksheetHeadView, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่ใบงาน",
      dataIndex: "iworksheetCode",
      key: "iworksheetCode",
      width: 150
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "icustName",
      key: "icustName",
      width: 250
    },
    {
      title: "วันเวลาที่ทำงาน",
      dataIndex: "istartDate",
      key: "istartDate",
      width: 190,
      align: "center" as "center",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{item.istartDate ? toThDate(item.istartDate) : ""} - {item.iendDate ? toThDate(item.iendDate) : ""}</span>;
      },
    },
    {
      title: "รถ",
      dataIndex: "truckNumber",
      key: "truckNumber",
      width: 150,
    },
    {
      title: "บริการ",
      dataIndex: "serviceDetailName",
      key: "serviceDetailName",
      width: 500,
    },
    {
      title: "พนักงานขับรถ",
      dataIndex: "idriverName",
      key: "idriverName",
      width: 150,
    },
    {
      title: "ค่าบริการ",
      dataIndex: "iserviceAmt",
      key: "iserviceAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iserviceAmt)}</span>;
      },
    },
    {
      title: "ค่าล่วงเวลา",
      dataIndex: "iotAmt",
      key: "iotAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iotAmt)}</span>;
      },
    },
    {
      title: "ค่าบริการอื่นๆ",
      dataIndex: "iextraAmt",
      key: "iextraAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iextraAmt)}</span>;
      },
    },
    {
      title: "ส่วนลดค่าบริการ",
      dataIndex: "idiscount",
      key: "idiscount",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.idiscount)}</span>;
      },
    },
    {
      title: "รวมก่อนภาษี",
      dataIndex: "itotal1Amt",
      key: "itotal1Amt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itotal1Amt)}</span>;
      },
    },
    {
      title: "ภาษี 7%",
      dataIndex: "itaxAmt",
      key: "itaxAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itaxAmt)}</span>;
      },
    },
    {
      title: "รวมค่าบริการ",
      dataIndex: "itotal2Amt",
      key: "itotal2Amt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itotal2Amt)}</span>;
      },
    },
    {
      title: "มัดจำ/ชำระล่วงหน้า",
      dataIndex: "idepositAmt",
      key: "idepositAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.idepositAmt)}</span>;
      },
    },
    {
      title: "คงเหลือ",
      dataIndex: "iremainAmt",
      key: "iremainAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber((item.itotal2Amt ? item.itotal2Amt : 0) - (item.idepositAmt ? item.idepositAmt : 0))}</span>;
      },
    },
    {
      title: "สถานะใบสั่งงาน",
      dataIndex: "iworksheetStatusName",
      key: "iworksheetStatusName",
      width: 130,
      align: "center" as "center",
    },
    {
      title: "เลขที่ใบแจ้งหนี้",
      dataIndex: "debitnotecode",
      key: "debitnotecode",
      width: 130,
      align: "center" as "center",
    },
    {
      title: "พนักงาน",
      dataIndex: "createUserName",
      key: "createUserName",
      width: 200,
    },
    {
      title: "วันที่สร้างบิล",
      dataIndex: "icomment",
      key: "icomment",
      width: 100,
      align: "center" as "center",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{item.ientryDate ? toThDate(item.ientryDate) : ""}</span>;
      },
    },
    {
      title: "",
      key: "action",
      width: 50,
      align: "center" as "center",
      render: (value: any, item: TWorksheetHeadView, index: number) => {
        return (
          < Button
            type="primary"
            size='small'
            danger
            shape="circle"
            icon={< DeleteOutlined />}
            onClick={() => onDelete(item, index)}
          />
        );
      },
    },
  ];
  
  useEffect(() => {
    if ( !worksheetReturnModalVisible && isAdd && flagRemove?.length ) {
      const worksheetCode = form.getFieldValue("worksheetCode");
      if (flagRemove === "10d") {
          const index = (workingWorksheets10d as Array<TWorksheetHeadView>).findIndex(object => object.iworksheetCode === worksheetCode.trim());

          if (index !== -1) {
          const array = workingWorksheets10d ? [...workingWorksheets10d] : [];
		      array?.splice(index, 1);
            setWorkingWorksheets10d(array);
          }
      } else if (flagRemove === "30d") {
        const index = (workingWorksheets30d as Array<TWorksheetHeadView>).findIndex(object => object.iworksheetCode === worksheetCode.trim());

        if (index !== -1) {
          const array = workingWorksheets30d ? [...workingWorksheets30d] : [];
		      array?.splice(index, 1);
          setWorkingWorksheets30d(array);
        }

      } else if (flagRemove === "90d") {
        const index = (workingWorksheets90d as Array<TWorksheetHeadView>).findIndex(object => object.iworksheetCode === worksheetCode.trim());

        if (index !== -1) {
          const array = workingWorksheets90d ? [...workingWorksheets90d] : [];
		      array?.splice(index, 1);
          setWorkingWorksheets90d(array);
        }

      } else if (flagRemove === "1y") {
        const index = (workingWorksheets1y as Array<TWorksheetHeadView>).findIndex(object => object.iworksheetCode === worksheetCode.trim());

        if (index !== -1) {
          const array = workingWorksheets1y ? [...workingWorksheets1y] : [];
		      array?.splice(index, 1);
          setWorkingWorksheets1y(array);
        }
      }
      let countR = countWshRengrad;
      let countA = countWshAcc;
      if (worksheetHeadSelected?.itypepaymentCode === "04") {
        countR = countR + 1;
        setCountWshRengrad(countR);
      } else if (worksheetHeadSelected?.itypepaymentCode !== "04") { 
        countA = countA + 1;
        setCountWshAcc(countA);
      }

      const returnArr = [...returnWorksheets];
      returnArr.push(worksheetHeadSelected!);
      setReturnWorksheets(returnArr);
      form.resetFields();

    }
   }, [worksheetReturnModalVisible]);

	const onDelete = (item: TWorksheetHeadView, index: number) => {
    const worksheetCode = item.iworksheetCode;
		
    const array = returnWorksheets ? [...returnWorksheets] : [];
		array?.splice(index, 1);
		setReturnWorksheets(array);

    const foundInworkingWorksheets10d = (workingWorksheets10d as Array<TWorksheetHeadView>).find((obj) => obj.iworksheetCode === worksheetCode);
    const foundInworkingWorksheets30d = (workingWorksheets30d as Array<TWorksheetHeadView>).find((obj) => obj.iworksheetCode === worksheetCode);
    const foundInworkingWorksheets90d = (workingWorksheets90d as Array<TWorksheetHeadView>).find((obj) => obj.iworksheetCode === worksheetCode);
    const foundInworkingWorksheets1y = (workingWorksheets1y as Array<TWorksheetHeadView>).find((obj) => obj.iworksheetCode === worksheetCode);

      if (foundInworkingWorksheets10d) {
          const array = workingWorksheets10d ? [...workingWorksheets10d] : [];
		      array.push(foundInworkingWorksheets10d);
          setWorkingWorksheets10d(array);
      } else if (foundInworkingWorksheets30d) {
        const array = workingWorksheets30d ? [...workingWorksheets30d] : [];
		      array.push(foundInworkingWorksheets30d);
          setWorkingWorksheets30d(array);
      } else if (foundInworkingWorksheets90d) {
        const array = workingWorksheets90d ? [...workingWorksheets90d] : [];
		      array.push(foundInworkingWorksheets90d);
          setWorkingWorksheets90d(array);
      } else if (foundInworkingWorksheets1y) {
        const array = workingWorksheets1y ? [...workingWorksheets1y] : [];
		      array.push(foundInworkingWorksheets1y);
          setWorkingWorksheets1y(array);
      }
      let countR = countWshRengrad;
      let countA = countWshAcc;
      if (worksheetHeadSelected?.itypepaymentCode === "04") {
        countR = countR - 1;
        setCountWshRengrad(countR);
      } else if (worksheetHeadSelected?.itypepaymentCode !== "04") { 
        countA = countA - 1;
        setCountWshAcc(countA);
      }
	}

  const handleOpenWorksheetReturnModal = async () => {
    // let fromDate: string = "20060606";
    // let toDate: string = moment(new Date).format(Constants.DB_FORMAT);
    const worksheetCode = form.getFieldValue("worksheetCode");
    // const branchId = userInfo.branchId;
    // const flg = "00"; // not return yet
    if (worksheetCode && worksheetCode.length < 13) {
      showNotification(NotificationType.ERROR, "เลขที่ใบงานไม่ถุกต้อง");
      form.resetFields();
    } else {
      const isExistWorkSheetCode = await tWorksheetHeadService.isExistWorkSheetCode(worksheetCode);
      if (!isExistWorkSheetCode.data) {
        showNotification(NotificationType.ERROR, "ไม่พบข้อมูลใบสั่งงาน");
        return;
      }

      const foundInReturn = (returnWorksheets as Array<TWorksheetHeadView>).find((obj) => obj.iworksheetCode === worksheetCode);
      const foundInworkingWorksheets10d = (workingWorksheets10d as Array<TWorksheetHeadView>).find((obj) => obj.iworksheetCode === worksheetCode);
      const foundInworkingWorksheets30d = (workingWorksheets30d as Array<TWorksheetHeadView>).find((obj) => obj.iworksheetCode === worksheetCode);
      const foundInworkingWorksheets90d = (workingWorksheets90d as Array<TWorksheetHeadView>).find((obj) => obj.iworksheetCode === worksheetCode);
      const foundInworkingWorksheets1y = (workingWorksheets1y as Array<TWorksheetHeadView>).find((obj) => obj.iworksheetCode === worksheetCode);
      let selectedwsh = {} as TWorksheetHeadView

      if (foundInReturn) {
        showNotification(NotificationType.ERROR, "ใบงาน " + worksheetCode + " นี้ถูกเลือกไว้แล้ว ไม่สามารถเลือกซ้ำได้");
        return;
      } else if (!foundInworkingWorksheets10d && !foundInworkingWorksheets30d && !foundInworkingWorksheets90d && !foundInworkingWorksheets1y) {
        showNotification(NotificationType.ERROR, "ไม่พบข้อมูลในรายการ หรือในช่วงวันที่เวลาที่เลือกไว้\n กรุณาตรวจสอบข้อมูลใหม่");
        return;
      } else {
        if (foundInworkingWorksheets10d) {
          selectedwsh = { ...foundInworkingWorksheets10d }
          setFlagRemove("10d");
        } else if (foundInworkingWorksheets30d) {
          selectedwsh = { ...foundInworkingWorksheets30d }
          setFlagRemove("30d");
        } else if (foundInworkingWorksheets90d) {
          selectedwsh = { ...foundInworkingWorksheets90d }
          setFlagRemove("90d");
        } else if (foundInworkingWorksheets1y) {
          selectedwsh = { ...foundInworkingWorksheets1y }
          setFlagRemove("1y");
        }

        const checkBack = await checkAssetBack(selectedwsh);
        if (checkBack) {
          if ((selectedwsh.itypepaymentCode !== "04" || selectedwsh.iworksheetStatus === "14")
            || (selectedwsh.itypepaymentCode === "04" && await checkDebitor(selectedwsh))
          ) {
            setWorksheetHeadSelected(selectedwsh);
            setWorksheetReturnModalVisible(true);
          }
        }
      }
    }
  }

  const checkAssetBack = async (tWorksheetHeadView: TWorksheetHeadView) => {
    let result = true;
    if (tWorksheetHeadView.iworksheetStatus === "14") {
      result = true;
    } else if (tWorksheetHeadView.typeworksheetflag === "00" || tWorksheetHeadView.typeworksheetflag === "03") {
      const tAssetMovementDetailRes = await tAssetmovementDetailService.getByWorksheetCode(tWorksheetHeadView.iworksheetCode!);
      if (tAssetMovementDetailRes.data) {
        const tAsetMovementDetail: TAssetmovementDetail = tAssetMovementDetailRes.data[0];
        if (tAsetMovementDetail.flg !== "01") {
          if (tWorksheetHeadView.truckAssettype !== "002" && tWorksheetHeadView.truckAssettype !== "003") {
            showNotification(NotificationType.ERROR, "ต้องรับรถ /ตู้ /แผ่นเหล็ก เบอร์ : " + tAsetMovementDetail.assetname.trim() + " กลับก่อน");
            result = false;
          }
        }
      }
    } else if (tWorksheetHeadView.typeworksheetflag === "02") {
      const woksheetViewConsRes = await tWorksheetHeadService.getWorksheetByConstructionWorkReserveFlg(tWorksheetHeadView.iworksheetCode!);
      if (woksheetViewConsRes.data) {
        woksheetViewConsRes.data.forEach(async (item: TWorksheetHeadView) => {
          const tAssetMovementDetailConRes = await tAssetmovementDetailService.getByWorksheetCode(item.iworksheetCode!);
          if (tAssetMovementDetailConRes.data) {
            const tAsetMovementDetail: TAssetmovementDetail = tAssetMovementDetailConRes.data[0];
            if (tAsetMovementDetail.flg !== "01") {
              if (tWorksheetHeadView.truckAssettype !== "002" && tWorksheetHeadView.truckAssettype !== "003") {
                showNotification(NotificationType.ERROR, "ต้องรับรถ /ตู้ /แผ่นเหล็ก เบอร์ : " + tAsetMovementDetail.assetname + " กลับก่อน");
                result = false;
                return;
              }
            }
          } else {
            showNotification(NotificationType.ERROR, "ต้องปล่อยรถ /ตู้ /แผ่นเหล็ก ใบงานที่ : " + item.iworksheetCode + " เบอร์ :" + item.truckNumber + " กลับก่อน");
            result = false;
            return;
          }
        });
      }
    } else if (tWorksheetHeadView.typeworksheetflag === "04") {
      const tAssetMovementDetailConRes = await tAssetmovementDetailService.getByWorksheetCode(tWorksheetHeadView.iworksheetCode!);
      if (tAssetMovementDetailConRes.data) {
        tAssetMovementDetailConRes.data.forEach((item: TAssetmovementDetail) => {
          if (item.flg === "00") {
            showNotification(NotificationType.ERROR, "ต้องรับรถ /ตู้ /แผ่นเหล็ก เบอร์ :" + item.assetname + " กลับก่อน");
            result = false;
            return;
          }
        })
      }
    }

    return result;
  }

  const checkDebitor = async (tWorksheetHeadView: TWorksheetHeadView) => {
    let isExistCustomer = false;
    const debitorDettailRes = await tDebitorDetailService.isExistCustomer(tWorksheetHeadView.icustCode!);
    if (debitorDettailRes.data) {
      isExistCustomer = true;
    } else {
      showNotification(NotificationType.ERROR, "กรุณาเพิ่มรายการลูกหนี้ก่อน");
      isExistCustomer = false;
    }
    return isExistCustomer;
  }

  return (
    <>
      <Content className="app-page-content">
        <Spin spinning={loading}>
          <>
            <Form
              form={form}
              name="returnWorksheetTab"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                  margin: "1em 0 0 0",
                  paddingLeft: "1em",
                }}
              >
                <Col span={14} style={{ textAlign: "left", paddingLeft: "3.5em" }}>
                  <Space align="start" direction='vertical'>
                    <Typography.Title level={4}>
                      <span>เลขที่ใบงาน <label className="text-red">*</label>
                      </span>
                    </Typography.Title>
                    <Form.Item
                      name="worksheetCode"
                      rules={[{ required: false }]}
                      style={{ width: 200 }}
                    >
                      <Input
                        allowClear={false}
                        onFocus={(e: any) => e.target.select()}
                        onKeyDown={(evt: any) => {
                          if (evt.keyCode === 13) {
                            handleOpenWorksheetReturnModal();
                          }
                        }}
                      ></Input>
                    </Form.Item>
                  </Space>
                </Col>
                <Col span={3} style={{ textAlign: "center" }}>
                  <Space align="center" direction='vertical'>
                    <Typography.Title level={4}>ส่งการเงิน</Typography.Title>
                    <Typography.Title level={5} style={{ margin: 0, color: "#FD1212" }}>
                      {formatNumberInt(countWshAcc)}
                    </Typography.Title>
                  </Space>
                </Col>
                <Col span={3} style={{ textAlign: "center" }}>
                  <Space align="center" direction='vertical'>
                    <Typography.Title level={4}>ส่งเร่งรัด</Typography.Title>
                    <Typography.Title level={5} style={{ margin: 0, color: "#FD1212" }}>
                      {formatNumberInt(countWshRengrad)}
                    </Typography.Title>
                  </Space>
                </Col>
                <Col span={4}></Col>
              </div>
            </Form>
            <div style={{ marginBottom: 0 }}>
              <Table
                columns={columns}
                dataSource={returnWorksheets}
                size="small"
                scroll={{ x: 2400, y: 400 }}
                pagination={false}
                rowKey={(item: TWorksheetHeadView) => item.ireserveCode!}
              />
            </div>
          </>
        </Spin>
      </Content>
      <div>
        {worksheetReturnModalVisible && (
          <AddWorksheetReturnModal
            worksheetReturnModalVisible={worksheetReturnModalVisible}
            setWorksheetReturnModalVisible={setWorksheetReturnModalVisible}
            worksheetHeadSelected={worksheetHeadSelected!}
            setWorksheetHeadSelected={setWorksheetHeadSelected}
            setIsAdd={setIsAdd}
          ></AddWorksheetReturnModal>
        )}
      </div>
    </>
  )
}

export default ReturnWorksheetTab