
export const formatNumber = (number: any) => {
	if (number === undefined || number === null || number.toString().trim().length === 0) return 0.00;
	number = Number(number).toFixed(2)
	let numberFormat = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	return numberFormat;
	// number = Number(number).toFixed(0)
	// return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}
export const formatNumberInt = (number: any) => {
	if (number === undefined || number === null || number.toString().trim().length === 0) return 0.00;
	number = Number(number)
	let numberFormat = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	return numberFormat;
	// number = Number(number).toFixed(0)
	// return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export const parserNumber = (number: any) => {
	if (!number) return 0;
	// return Number(number.toString().replace(/(,*)/g, '')).toFixed(2)
	return Number(number.toString().replace(/(,*)/g, ''))
	// return Number(number.toString().replace(/[^0-9.-]+/g, '')).toFixed(2)
}

export const textNumber = (event : any) => {
	//allow only number/./Backspace/Delete/RightArrow/LeftArrow/Tab
	var key = event.keyCode || event.which;
	if (key === 8 || key === 46
		|| key === 37 || key === 39|| key === 9) {
			 return;
	 } else if (/^[0-9\.]+$/.test(event.key)) {
		return;
	 }else {
		event.preventDefault();
	 }
}