import http from "../../lib/http";
import Constants from "../../utils/constants";

const getTAssetImages = (assetCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tAssetImage/findAllByAssetCode", {
    params: {
      assetCode
    }
  });
};

const postTAssetImage = (tAssetImage: any) => {
  return http.post(Constants.DEFAULT_URL + "/tAssetImage", tAssetImage);
}

const deletetTAssetImage = (id: number) => {
  return http.delete(Constants.DEFAULT_URL + "/tAssetImage/" + id);
}

const defaultApi = {
  getTAssetImages,
  postTAssetImage,
  deletetTAssetImage,
};
export default defaultApi;
