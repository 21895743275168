import { Button, Form, Input, Modal, Table, Typography } from 'antd';
import React, { useState } from 'react';
import { TDebitorHeadView } from 'src/domain/finance/view/tDebitorHeadView';
import tDebitorService from 'src/services/debitor/tDebitorService';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber } from 'src/utils/numberUtils';

interface IProps {
  lookupDebitorModalVisible: boolean;
  setLookupDebitorModalVisible: any;
  setTDebitorHeadSelected: any;
}

const LookupTDebitorHeadModal: React.FC<IProps> = (props) => {
  const { lookupDebitorModalVisible, setLookupDebitorModalVisible, setTDebitorHeadSelected } = props;
  const [form] = Form.useForm();
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [tDebitorHeads, setTDebitorHeads] = useState<TDebitorHeadView[]>([]);
    
  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: TDebitorHeadView, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่บัญชีลูกหนี้",
      dataIndex: "iid",
      key: "iid",
      width: 120,
      align: "center" as "center",
      render: (value: string, item: TDebitorHeadView) => (
        <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.iid}</Button>
      )
    },
    {
      title: "ชื่อ",
      dataIndex: "iname",
      key: "iname",
      width: 200,
      render: (value: any, item: TDebitorHeadView) => {
        return <span>{item.iprename.trim() + item.iname}</span>;
      },
    },
    {
      title: "สกุล",
      dataIndex: "isurname",
      key: "isurname",
      width: 100,
    },
    {
      title: "โทร",
      dataIndex: "telNumber",
      key: "telNumber",
      width: 150,
      align: "center" as "center",
      render: (value: any, item: TDebitorHeadView) => {
        return <span>{item.itelephoneNumber ? item.itelephoneNumber : "" + " " + item.imobilenumber? item.imobilenumber : ""}</span>;
      },
    },
    {
      title: "วงเงิน",
      dataIndex: "icreditamount",
      key: "icreditamount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TDebitorHeadView) => {
        return <span>{formatNumber(item.icreditamount)}</span>;
      },
    },
    {
      title: "ใช้วงเงิน",
      dataIndex: "currentusage",
      key: "currentusage",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TDebitorHeadView) => {
        return <span>{formatNumber(item.currentusage)}</span>;
      },
    },
    {
      title: "เครดิต",
      dataIndex: "creditdate",
      key: "creditdate",
      width: 100,
      align: "center" as "center",
    },
    {
      title: "หมายเหตุ",
      dataIndex: "iremarkMessage",
      key: "iremarkMessage",
      width: 150,
    },
  ]
  
  const onItemCliked = (item: TDebitorHeadView) => {
    setTDebitorHeadSelected(item);
    setLookupDebitorModalVisible(false);
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    let nameNumber: string = values.nameNumber;

    if (!nameNumber) {
      showNotification(NotificationType.ERROR, "กรุณาป้อนชื่อลูกหนี้ หรือ เบอร์โทร");
      setLoading(false);
      return;
    }

    const res = await tDebitorService.getDebitorByParam(nameNumber);
    setTDebitorHeads(res.data);
    setLoading(false);
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>ค้นหาลูกหนี่้</Typography.Title>
          </div>
        }
        visible={lookupDebitorModalVisible}
        width={"80%"}
        style={{ minHeight: "80%" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setLookupDebitorModalVisible(false)}
      >
        <Form
          form={form}
          name="lookupDebitorModal"
          labelCol={{ span: 5 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="nameNumber"
            rules={[{ required: false }]}
          >
            <Input
              allowClear
              style={{
                width: 378,
              }}
              placeholder="ป้อนชื่อลูกหนี้ เบอร์โทร"
              onKeyDown={(evt: any) => {
                if (evt.keyCode === 13) {
                  form.submit();
                }
              }}
            ></Input>
          </Form.Item>
          <div style={{ padding: "10px 0px 0px" }}>
            <Table
              columns={columns}
              dataSource={tDebitorHeads}
              loading={loading}
              size="small"
              style={{ minHeight: 200 }}
              rowKey={(item: TDebitorHeadView) => item.iid}
              pagination={{
                pageSize: 15,
                onChange(current) {
                  setPage(current);
                }
              }}
            />
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default LookupTDebitorHeadModal