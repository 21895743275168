import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment";
import Constants from "./constants";

export const toThDate = (dateStr?: string) => {
  if (!dateStr) {
    return ""
  }
  return moment(dateStr, Constants.DB_FORMAT).isValid() ? moment(dateStr, Constants.DB_FORMAT).format(Constants.DATE_FORMAT) : "";

};

export const getYearOlds = (dateStr: string) => {
  return moment().diff(moment(dateStr, Constants.DB_FORMAT), "years");
};

export const toMomentDate = (dateStr: string) => {
  return moment(toThDate(dateStr), Constants.DATE_FORMAT);
};

export const toMomentDateTime = (dateStr: string, timeStr: string) => {
  return moment(toThDate(dateStr) + formatFullTime(timeStr), Constants.DATE_FORMAT_FULL);
};

export const formatTime = (time?: string) => {
  if (!time) {
    return ""
  }

  let hh = time.substring(0, 2);
  let mm = time.substring(2, 4);
  return hh + ":" + mm;
};

export const formatFullTime = (time?: string) => {
  if (!time) {
    return ""
  }

  let hh = time.substring(0, 2);
  let mm = time.substring(2, 4);
  let ss = time.substring(4, 6);
  return hh + ":" + mm + ":" + ss;
};

export const dateToString = (date?: any) => {
  if (!date) {
    return ""
  }

  return moment(date).format(Constants.DB_FORMAT).toString();
};

export const timeToString = (date?: any) => {
  if (!date) {
    return ""
  }

  return moment(date).format(Constants.DB_TIME_FORMAT).toString();
};


const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

// Can not select days before today
// eslint-disable-next-line arrow-body-style
export const disabledDate: RangePickerProps['disabledDate'] = current => {
  return current && current < moment().subtract(1, 'days').endOf('day');
};

// show result this format HH:00:00 --> (08-17):00:00
export const disabledDateTimeExceptWorkHour = () => ({
  disabledHours: () => range(0, 8).concat(range(18, 24)),
  disabledMinutes: () => range(1, 59),
  disabledSeconds: () => range(1, 59),
});
