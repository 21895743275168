import http from "../../lib/http";
import Constants from "../../utils/constants";

const getTBankBranchHeadList = () => {
    return http.get(Constants.DEFAULT_URL + "/tBankBranchHead");
};

const getBankBranchHeadByBankCode = (bankCode: string) => {
    return http.get(Constants.DEFAULT_URL + "/tBankBranchHead/getByBankCode/" + bankCode);
};

const getByBankCodeAndBankBranchCode = (ibankCode: string, ibankBranchCode: string) => {
    return http.get(Constants.DEFAULT_URL + "/tBankBranchHead/getByBankCodeAndBankBranchCode", {
        params: {
            ibankCode,
            ibankBranchCode
        }
    });
}

const updateBankBranchHead = (bankBranchHead: any) => {
    return http.put(Constants.DEFAULT_URL + "/tBankBranchHead", bankBranchHead);
}

const postBankBranchHead = (bankBranchHead: any) => {
    return http.post(Constants.DEFAULT_URL + "/tBankBranchHead", bankBranchHead);
}

const defaultApi = {
    getTBankBranchHeadList,
    getBankBranchHeadByBankCode,
    getByBankCodeAndBankBranchCode,
    postBankBranchHead,
    updateBankBranchHead,
};
export default defaultApi;