import http from "src/lib/http";
import Constants from "src/utils/constants";

const getTAssignvaluetaxheads = () => {
  return http.get(Constants.DEFAULT_URL + "/tAssignvaluetaxhead");
};

const getTAssignvaluetaxheadById = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/tAssignvaluetaxhead/" + id);
};

const defaultApi = {
  getTAssignvaluetaxheads,
  getTAssignvaluetaxheadById,
}
export default defaultApi;