import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Table, Typography, Modal, DatePicker } from 'antd';
import PageHeader from 'src/layouts/PageHeader';
import { Content } from 'antd/lib/layout/layout';
import { useAppSelector } from 'src/stores/store';
import { PrinterOutlined, SearchOutlined } from '@ant-design/icons';
import reportService from 'src/services/reportService';
import { formatNumber, formatNumberInt } from 'src/utils/numberUtils';
import PdfViewPopup from './PdfViewPopup';
import Constants from 'src/utils/constants';
import moment from 'moment';
import { TChequeHeadView } from 'src/domain/cheque/view/tChequeHeadView';
import { TFinanceChequedetail } from 'src/domain/finance/tFinanceChequedetail';

const { Option } = Select;

const ChequePayReport = () => {
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  const [dataList, setDataList] = useState<TFinanceChequedetail[]>([]);
  const [dataPreview, setDataPreview] = useState<any>();

  const [visiblePDF, setVisiblePDF] = useState(false);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false);

  const [fromDateParam, setFromDateParam] = useState<any>();
  const [toDateParam, setToDateParam] = useState<any>();
  const [chequeNumberParam, setChequeNumberParam] = useState<any>();
  const [customerNameParam, setCustomerNameParam] = useState<any>();

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: '1%',
      align: "center" as "center",
      render: (value: number, item: TFinanceChequedetail, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
    },
    {
      title: "เช็คลงวันที่",
      dataIndex: "chequedate",
      key: "chequedate",
      width: '10%',
      align: "center" as "center",
    },
    {
      title: "เลขที่เช็ค",
      dataIndex: "chequenumber",
      key: "chequenumber",
      width: '12%',
    },
    {
      title: "เลขที่ใบงาน",
      dataIndex: "workCode",
      key: "workCode",
      width: '16%',
    },
    {
      title: "ลูกค้า/ลูกหนี้",
      dataIndex: "customerName",
      key: "customerName",
      width: '15%',
    },
    {
      title: "จำนวนเงินเช็ค",
      dataIndex: "chequeamount",
      key: "chequeamount",
      width: '8%',
      align: "right" as "right",
      render: (value: any, item: TFinanceChequedetail) => {
        return <span>{formatNumber(item.chequeamount)}</span>;
      },
    },
    {
      title: "จำนวนเงินชำระ",
      dataIndex: "fnChequeamount",
      key: "fnChequeamount",
      width: '8%',
      align: "right" as "right",
      render: (value: any, item: TFinanceChequedetail) => {
        return <span>{formatNumber(item.fnChequeamount)}</span>;
      },
    },
    {
      title: "ผู้ชำระ",
      dataIndex: "userName",
      key: "userName",
      width: '12%',
    },
  ]

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });

    initData();
    form.submit();
  }, []);

  const initData = async () => {
    form.setFieldsValue({
      fromDate: moment(new Date(), Constants.DATE_FORMAT),
      toDate: moment(new Date(), Constants.DATE_FORMAT),
      chequeNumber: "",
      customerName: "",
    });
  }

  const onFinish = async (values: any) => {
    // setLoading(true);
    const fromDate = moment(values.fromDate).format("yyyyMMDD");
    const toDate = moment(values.toDate).format("yyyyMMDD");
    const chequeNumber = values.chequeNumber ? values.chequeNumber : "";
    const customerName = values.customerName ? values.customerName : "";

    const res = await reportService.getChequePayReport(fromDate, toDate, chequeNumber, customerName);
    // console.log('----------res--------', res.data)
    setDataList(res.data);
    setLoading(false);
    setPage(1);

    setFromDateParam(fromDate);
    setToDateParam(toDate);
    setChequeNumberParam(chequeNumber);
    setCustomerNameParam(customerName);    
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  const onPrint = async () => {
    setVisiblePDF(true);
  }


  return (
    <>
      <PageHeader title="รายงานข้อมูลการชำระเงินด้วยเช็ค"></PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="cheque"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
            }}
          >
           
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ระหว่างวันที่: </Typography.Text>
              </Col>
              <Col span={3}>
                <Form.Item
                  name="fromDate"
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={1} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ถึง: </Typography.Text>
              </Col>
              <Col span={3}>
                <Form.Item
                  name="toDate"
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เลขที่เช็ค: </Typography.Text>
              </Col>
              <Col span={7}>
                <Form.Item name="chequeNumber" style={{ marginBottom: "3px" }}>
                  <Input style={{ marginRight: 5 }}></Input>
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ชื่อลูกค้า: </Typography.Text>
              </Col>
              <Col span={7}>
                <Form.Item name="customerName" rules={[{ required: false }]}>
                  <Input style={{ marginRight: 5 }}></Input>
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={13} style={{ textAlign: "right"}}>
                <Button
                  size="small"
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={form.submit}
                >
                  ค้นหา
                </Button>
              </Col>
              <Col span={7}></Col>
            </Row>
          </div>
        </Form>
           
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            // margin: '0 0 10px 0',
            padding: "0px 20px 10px 0px",
            borderBottom: '2px solid #c9c9c9'
          }}
        >
          <Button
          style={{
            marginLeft: "30px",}}
            size="small"
            type="primary"
            icon={<PrinterOutlined />}
            onClick={onPrint}
          >
            พิมพ์
          </Button>
          <Modal
            style={{ top: 20 }}
            width={"90vw"}
            title="RS001"
            bodyStyle={{ height: "85vh" }}
            centered={true}
            visible={isPreviewModalVisible}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={() => setIsPreviewModalVisible(false)}
          >
            <iframe title=" " src={dataPreview} width={"100%"} height={"100%"}></iframe>
          </Modal>
        </div>

        <div>
          <Table
            columns={columns}
            dataSource={dataList}
            size="small"
            loading={loading}
            rowKey={(item: TFinanceChequedetail) => item.id!}
            scroll={{ x: 1500 }}
            pagination={{
              pageSize: 10,
              current: page,
              onChange(current) {
                setPage(current);
              }
            }}
          />
        </div>
      </Content>

      <Modal
        title= 'print'
        centered
        closable={true}
        visible={visiblePDF}
        onCancel={() => setVisiblePDF(false)}
        width="400"
      >
        <PdfViewPopup
          urlPath={reportService.getChequePayReportPreview(fromDateParam, toDateParam, chequeNumberParam, customerNameParam)}
          onClose={() => setVisiblePDF(false)}
        />
      </Modal>
    </>
  )
}

export default ChequePayReport