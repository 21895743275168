import { TWorksheetHeadView } from "src/domain/worksheet/view/tWorksheetHeadView";
import http from "../lib/http";
import Constants from "../utils/constants";

const getWorksheetHeadForAdvanceDate = (worksheetCode: string, fromDate: string, toDate: string, branchId: string) => {
  return http.get(Constants.DEFAULT_URL + "/worksheetAdvanceDate/getWorksheetHeadForAdvanceDate", {
    params: {
      worksheetCode,
      fromDate,
      toDate,
      branchId
    },
  });
};

const updateWorksheetHead = (tworksheetHeadView: TWorksheetHeadView) => {
  return http.post(Constants.DEFAULT_URL + "/worksheetAdvanceDate/updateWorksheetHead", tworksheetHeadView)
};

const defaultApi = {
  getWorksheetHeadForAdvanceDate,
  updateWorksheetHead
};
export default defaultApi;