import axios from "axios";
import Constants from "src/utils/constants";

let axiosInstance = axios.create();
const userInfoObj = localStorage.getItem(Constants.AUTHEN_USER);
if (userInfoObj) {
  axiosInstance = axios.create({
    headers: {
      "AUTHEN_USER": encodeURIComponent(userInfoObj)
    }
  });
}
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // console.log("axios >> " + error);
    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

// export const mock = new AxiosMockAdapter(axiosInstance, { delayResponse: 0 });

export default axiosInstance;
