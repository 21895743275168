import { TFinanceTrush } from "src/domain/finance/tFinanceTrush";
import { TFinanceTrushData } from "src/domain/finance/view/tFinanceTrushData";
import http from "src/lib/http";
import Constants from "src/utils/constants";

const findTFinanceTrushViews = (id: string, branchid: string, accseq: string, fromDate: string, toDate: string) => {
  return http.get(Constants.DEFAULT_URL + "/tFinanceTrush/findTFinanceTrushViews", {
    params: {
      id,
      branchid,
      accseq,
      fromDate,
      toDate,
    }
  });
};

const findTFinanceTrushWaitConfirmViews = (accSeq: string, type: string, trushmoneyid: string, chquenumber: string) => {
  return http.get(Constants.DEFAULT_URL + "/tFinanceTrush/findTFinanceTrushWaitConfirmViews", {
    params: {
      accSeq,
      type,
      trushmoneyid,
      chquenumber,
    }
  });
};

const saveTfinanceTrushData = (trushMoneyData: TFinanceTrushData) => {
  return http.post(Constants.DEFAULT_URL + "/tFinanceTrush/saveTfinanceTrushData", trushMoneyData);
};

const updateTFinanceTrush = (tfinanceTrushUpd: TFinanceTrush) => {
  return http.put(Constants.DEFAULT_URL + "/tFinanceTrush", tfinanceTrushUpd);
};

const defaultApi = {
  findTFinanceTrushViews,
  findTFinanceTrushWaitConfirmViews,
  saveTfinanceTrushData,
  updateTFinanceTrush,
}
export default defaultApi;