import { Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Space, Spin, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { TAssignvaluetaxhead } from 'src/domain/tAssignvaluetaxhead';
import { TWorksheetHeadView } from 'src/domain/worksheet/view/tWorksheetHeadView';
import tAssignvaluetaxheadService from 'src/services/tAssignvaluetaxheadService';
import worksheetService from 'src/services/worksheetService';
import Constants from 'src/utils/constants';
import { toMomentDateTime } from 'src/utils/dateTimeUtils';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber, textNumber } from 'src/utils/numberUtils';

const { Option } = Select;

interface IProp {
  worksheetReturnModalVisible: boolean;
  setWorksheetReturnModalVisible: any;
  worksheetHeadSelected: TWorksheetHeadView;
  setWorksheetHeadSelected: any;
  setIsAdd: any;
}
const AddWorksheetReturnModal: React.FC<IProp> = (props) => {
  const { worksheetReturnModalVisible, setWorksheetReturnModalVisible, worksheetHeadSelected, setWorksheetHeadSelected, setIsAdd } = props;
  const [form] = Form.useForm();
  const [showSpin, setShowSpin] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [tAssignvaluetaxheads, setTAssignvaluetaxheads] = useState<TAssignvaluetaxhead[]>([]); //ภาษีหัก ณ ที่จ่าย
  const [selectedTAssignvaluetaxhead, setSelectedTAssignvaluetaxhead] = useState<TAssignvaluetaxhead>({} as TAssignvaluetaxhead);
  const [disableStartDate, setDisableStartDate] = useState<boolean>(false);
  const [disableEndDate, setDisableEndDate] = useState<boolean>(false);
  const [disableActualReceive, setDisableActualReceive] = useState<boolean>(false);
  const [disableIremark, setDisableIremark] = useState<boolean>(false);
  const [disableTypeVat, setDisableTypeVat] = useState<boolean>(false);

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  useEffect(() => {
    (async () => {
      const tAssignvaluetaxheadRes = await tAssignvaluetaxheadService.getTAssignvaluetaxheads();
      setTAssignvaluetaxheads(tAssignvaluetaxheadRes.data);

      const defaultAssignvaluetax = (tAssignvaluetaxheadRes.data as Array<TAssignvaluetaxhead>).find((obj) => obj.iid === "00");
      setShowSpin(true);

      const start = toMomentDateTime(worksheetHeadSelected.istartDate ? worksheetHeadSelected.istartDate : "",
        worksheetHeadSelected.istartTime ? worksheetHeadSelected.istartTime : "");
      const end = toMomentDateTime(worksheetHeadSelected.iendDate ? worksheetHeadSelected.iendDate : "",
        worksheetHeadSelected.iendTime ? worksheetHeadSelected.iendTime : "");

      form.setFieldsValue({
        iworksheetCode: worksheetHeadSelected?.iworksheetCode,
        startDate: start,
        endDate: end,
        chkWshWasteTime: true,
        otHour: 0,
        icustName: worksheetHeadSelected?.icustName,
        serviceDetailName: worksheetHeadSelected?.serviceDetailName,
        iserviceAmt: worksheetHeadSelected?.iserviceAmt,
        iotAmt: worksheetHeadSelected?.iotAmt,
        iextraAmt: worksheetHeadSelected?.iextraAmt,
        idriverName: worksheetHeadSelected?.idriverName,
        idiscount: worksheetHeadSelected?.idiscount,
        itotal1Amt: worksheetHeadSelected?.itotal1Amt,
        truckNumber: worksheetHeadSelected?.truckNumber,
        witholdingrateId: defaultAssignvaluetax?.iid,
        purchasetax: worksheetHeadSelected?.purchasetax,
        itaxAmt: worksheetHeadSelected?.itaxAmt,
        serviceUnitName: worksheetHeadSelected?.serviceUnitName,
        idepositAmt: worksheetHeadSelected?.idepositAmt,
        iremainAmt: (worksheetHeadSelected.itotal2Amt! - worksheetHeadSelected.idepositAmt!),
        iremark: worksheetHeadSelected?.iremark,
        actualReceive: formatNumber(0),

      });
      setEnableByStatus();
      setShowSpin(false);
    })();
  }, []);

  const setEnableByStatus = () => {
    if (worksheetHeadSelected.iworksheetStatus === "15") {
      setDisableStartDate(true);
      setDisableEndDate(true);
      setDisableActualReceive(true);
      setDisableIremark(true);
      setDisableTypeVat(true);
    } else {
      setDisableTypeVat(true);
      setDisableStartDate(false);
      setDisableEndDate(false);
      setDisableActualReceive(false);
      setDisableIremark(false);

      if (worksheetHeadSelected.itypepaymentCode === "04") {
        setDisableActualReceive(true);
      }
      if (worksheetHeadSelected.itypeworksheetCode === "01" && worksheetHeadSelected.itypepaymentCode === "02") {
        setDisableTypeVat(false);
      }
    }
  }

  const handleChangePurchaseTax = async (witholdingrateId: any) => {
    const tAssignvaluetaxheadArr = tAssignvaluetaxheads as Array<TAssignvaluetaxhead>;
    const findTAssignvaluetaxhead = tAssignvaluetaxheadArr.find((ob) => ob.iid === witholdingrateId);
    setSelectedTAssignvaluetaxhead(findTAssignvaluetaxhead!);

    const finishDate = form.getFieldValue("endDate");
    let endDateStr = moment(finishDate).format("yyyyMMDD");
    let endTimeStr = moment(finishDate).format("HHmm00");
    const isHourRelax12to13 = form.getFieldValue("chkWshWasteTime");
    const otDateRes = await worksheetService.calculateWshReturnWithOtData(worksheetHeadSelected, endDateStr, endTimeStr, isHourRelax12to13, findTAssignvaluetaxhead!.irate);

    form.setFieldsValue({
      otHour: otDateRes.data.OT_HOUR,
      iotAmt: otDateRes.data.OT_PAY_AMOUNT,
      itotal1Amt: otDateRes.data.TOTAL2_AMT,
      purchasetax: otDateRes.data.WITHOLDINGTAX_AMT,
      itaxAmt: otDateRes.data.VAT_AMT,
      iremainAmt: otDateRes.data.REMAIN_AMT,//คงเหลือชำระ
    })
  }  

  const onFinish = async (values: any) => {
    if ("04" !== worksheetHeadSelected.itypepaymentCode && parserNumber(values.actualReceive) !== values.iremainAmt) {
      showNotification(NotificationType.ERROR, "จำนวนเงินที่ส่งการเงินไม่ตรงกับจำนวนเงินที่ลูกค้าค้างชำระ!");
      setTrigger(false);
    } else {
      const sDate = values.startDate;
      const eDate = values.endDate;

      setWorksheetHeadSelected({
        ...worksheetHeadSelected,
        iremark: values.iremark,
        iotAmt: values.iotAmt,
        itotal1Amt: values.itotal1Amt,
        itaxAmt: values.itaxAmt,
        iremainAmt: values.iremainAmt,
        istartDate: sDate.format("yyyyMMDD"),
        istartTime: sDate.format("HHmm00"),
        iendDate: eDate.format("yyyyMMDD"),
        iendTime: eDate.format("HHmm00"),
        witholdingrateId: selectedTAssignvaluetaxhead.iid,
        atinstalment: worksheetHeadSelected.allinstalment,
        purchasetax: (worksheetHeadSelected.itypeworksheetCode === "01" && values.purchasetax > 0) ? values.purchasetax : 0,
        flgwitholdingtax: (worksheetHeadSelected.itypeworksheetCode === "01" && values.purchasetax > 0) ? 1 : 0,
      });

      try {
        setIsAdd(true);
        setWorksheetReturnModalVisible(false);
        form.resetFields();
        setTrigger(false);
      } catch (err) {
        showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        setTrigger(false);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    setTrigger(false);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>บันทึกรับใบสั่งงานกลับ</Typography.Title>
          </div>
        }
        visible={worksheetReturnModalVisible}
        width={"65%"}
        style={{ minHeight: "70%" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setWorksheetReturnModalVisible(false)}
      >
        <Spin spinning={showSpin}>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row>
              <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เลขที่ใบงาน: </Typography.Text>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="iworksheetCode"
                  style={{ marginBottom: 3 }}>
                  <Input
                    readOnly={true}
                    style={{ backgroundColor: "#F7F7F8" }}
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>วัน-เวลาทำงานตั้งแต่: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="startDate"
                  style={{ marginBottom: 3 }}
                >
                  <DatePicker
                    showTime={{ format: "HH:00:00" }}
                    disabled={disableStartDate}
                    format={Constants.DATE_FORMAT_SKIP_MINUTE_SEC}
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="chkWshWasteTime"
                  valuePropName="checked"
                  style={{ marginBottom: 2, backgroundColor: "#FD1212", paddingLeft: 5, borderRadius: 5 }}
                >
                  <Checkbox
                    disabled
                    style={{ color: "black !important" }}
                  >
                    คิดเวลารวมเที่ยงวัน
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ลูกค้า: </Typography.Text>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="icustName"
                  style={{ marginBottom: 3 }}>
                  <Input
                    readOnly={true}
                    style={{ backgroundColor: "#F7F7F8" }}
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เวลาเลิก: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="endDate"
                  style={{ marginBottom: 3 }}
                >
                  <DatePicker
                    showTime={{ format: "HH:00:00" }}
                    disabled={disableEndDate}
                    format={Constants.DATE_FORMAT_SKIP_MINUTE_SEC}
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Space align='baseline' style={{ width: "85%" }}>
                <Form.Item
                  name="otHour"
                  style={{ marginBottom: 2, borderRadius: 5 }}
                >
                  <InputNumber
                    controls={false}
                    parser={(value) => parserNumber(value)}
                    readOnly={true}
                    style={{
                      textAlign: "center",
                      backgroundColor: "black",
                      color: "red",
                      width: 60,
                      marginRight: 5,
                      paddingRight: 9,
                      fontSize: 20,
                      fontWeight: "bolder"
                    }}
                  ></InputNumber>
                </Form.Item>
                  <label>ชั่วโมง OT</label>
                </Space>
              </Col>
            </Row>

            <Row>
              <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>รถ/รายการ: </Typography.Text>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="truckNumber"
                  style={{ marginBottom: 3 }}>
                  <Input
                    readOnly={true}
                    style={{ backgroundColor: "#F7F7F8" }}
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ค่าบริการ: </Typography.Text>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="iserviceAmt"
                  style={{ marginBottom: 2 }}
                >
                  <InputNumber
                    controls={false}
                    formatter={(value) => formatNumber(value)}
                    parser={(value) => parserNumber(value)}
                    readOnly={true}
                    style={{
                      textAlign: "end",
                      backgroundColor: "#F7F7F8",
                      width: "auto",
                    }}
                  ></InputNumber>
                </Form.Item>
              </Col>
              <Col span={1} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>OT: </Typography.Text>
              </Col>
              <Col span={2}>
                <Form.Item
                  name="iotAmt"
                  style={{ marginBottom: 2 }}
                >
                  <InputNumber
                    controls={false}
                    formatter={(value) => formatNumber(value)}
                    parser={(value) => parserNumber(value)}
                    readOnly={true}
                    style={{
                      textAlign: "end",
                      backgroundColor: "#F7F7F8",
                      width: "auto",
                    }}
                  ></InputNumber>
                </Form.Item>
              </Col>
              <Col span={1} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>อื่นๆ: </Typography.Text>
              </Col>
              <Col span={2}>
                <Form.Item
                  name="iextraAmt"
                  style={{ marginBottom: 2 }}
                >
                  <InputNumber
                    controls={false}
                    formatter={(value) => formatNumber(value)}
                    parser={(value) => parserNumber(value)}
                    readOnly={true}
                    style={{
                      textAlign: "end",
                      backgroundColor: "#F7F7F8",
                      width: "auto",
                    }}
                  ></InputNumber>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>คนขับรถ: </Typography.Text>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="idriverName"
                  style={{ marginBottom: 3 }}>
                  <Input
                    readOnly={true}
                    style={{ backgroundColor: "#F7F7F8" }}
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ส่วนลด: </Typography.Text>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="idiscount"
                  style={{ marginBottom: 2 }}
                >
                  <InputNumber
                    controls={false}
                    formatter={(value) => formatNumber(value)}
                    parser={(value) => parserNumber(value)}
                    readOnly={true}
                    style={{
                      textAlign: "end",
                      backgroundColor: "#F7F7F8",
                      width: "auto",
                    }}
                  ></InputNumber>
                </Form.Item>
              </Col>
              <Col span={1} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>รวม: </Typography.Text>
              </Col>
              <Col span={5}>
                <Form.Item
                  name="itotal1Amt"
                  style={{ marginBottom: 2 }}
                >
                  <InputNumber
                    controls={false}
                    formatter={(value) => formatNumber(value)}
                    parser={(value) => parserNumber(value)}
                    readOnly={true}
                    style={{
                      textAlign: "end",
                      backgroundColor: "#F7F7F8",
                      width: "auto",
                    }}
                  ></InputNumber>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={10} style={{ height: 74 }}>
                <Row>
                  <Col span={7} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>บริการ: </Typography.Text>
                  </Col>
                  <Col span={17}>
                    <Form.Item
                      name="serviceDetailName"
                      style={{ margin: " 0px 0px 3px 3px" }}>
                      <TextArea
                        readOnly
                        rows={3}
                      ></TextArea>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={14} style={{ height: 74 }}>
                <Row style={{ height: 37 }}>
                  <Col span={7} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>หัก ณ ที่จ่าย: </Typography.Text>
                  </Col>
                  <Col span={17}>
                    <Form.Item
                      name="witholdingrateId"
                    >
                      <Select
                        showSearch
                        allowClear
                        size="middle"
                        placeholder="เลือกภาษีหัก ณ ที่จ่าย"
                        optionFilterProp="children"
                        filterOption={true}
                        disabled={disableTypeVat}
                      onSelect={handleChangePurchaseTax}
                      >
                        {tAssignvaluetaxheads.map((obj: TAssignvaluetaxhead) => {
                          return (
                            <Option key={obj.iid} value={obj.iid}>
                              {obj.iname} : {obj.irate}%<br />
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ height: 37 }}>
                  <Col span={7} style={{ textAlign: "right", padding: "0.5em" }}></Col>
                  <Col span={7}>
                    <Form.Item
                      name="purchasetax"
                      style={{ marginBottom: 2 }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        parser={(value) => parserNumber(value)}
                        readOnly={true}
                        style={{
                          textAlign: "end",
                          backgroundColor: "#F7F7F8",
                          width: "auto",
                        }}
                      ></InputNumber>
                    </Form.Item>
                  </Col>
                  <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>ภาษี: </Typography.Text>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      name="itaxAmt"
                      style={{ marginBottom: 2 }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        parser={(value) => parserNumber(value)}
                        readOnly={true}
                        style={{
                          textAlign: "end",
                          backgroundColor: "#F7F7F8",
                          width: "auto",
                        }}
                      ></InputNumber>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>หน่วยบริการ: </Typography.Text>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="serviceUnitName"
                  style={{ marginBottom: 3 }}>
                  <Input
                    readOnly={true}
                    style={{ backgroundColor: "#F7F7F8" }}
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ชำระเงินแล้ว: </Typography.Text>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="idepositAmt"
                  style={{ marginBottom: 2 }}
                >
                  <InputNumber
                    controls={false}
                    formatter={(value) => formatNumber(value)}
                    parser={(value) => parserNumber(value)}
                    readOnly={true}
                    style={{
                      textAlign: "end",
                      backgroundColor: "#F7F7F8",
                      width: 165,
                    }}
                  ></InputNumber>
                </Form.Item>
              </Col>
              <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>คงเหลือ: </Typography.Text>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="iremainAmt"
                  style={{ marginBottom: 2 }}
                >
                  <InputNumber
                    controls={false}
                    formatter={(value) => formatNumber(value)}
                    parser={(value) => parserNumber(value)}
                    readOnly={true}
                    style={{
                      textAlign: "end",
                      backgroundColor: "#F7F7F8",
                      width: "auto",
                    }}
                  ></InputNumber>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={10}></Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>หมายเหตุ: </Typography.Text>
              </Col>
              <Col span={10}>
                <Form.Item
                  name="iremark"
                  style={{ marginBottom: 3 }}
                >
                  <TextArea
                    disabled={disableIremark}
                    rows={2}
                  ></TextArea>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={10}></Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>จำนวนเงินที่ได้รับจริง: </Typography.Text>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="actualReceive"
                  style={{ marginBottom: 2 }}
                >
                  <Input
                    type="text"
                    onKeyDown={(event: any) => textNumber(event)}
                    onPressEnter={(e: any) => form.setFieldsValue({ actualReceive: formatNumber(parserNumber(e.target.value)) })}
                    onBlur={(e: any) => form.setFieldsValue({ actualReceive: formatNumber(parserNumber(e.target.value)) })}
                    onFocus={(e) => e.target.select()}
                    style={{ textAlign: "end", width: "100%" }}
                    disabled={disableActualReceive}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  )
}

export default AddWorksheetReturnModal