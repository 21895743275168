import Constants from "src/utils/constants";
import http from "../lib/http";
import { TWshtaxDetail } from './../domain/tWshtaxDetail';

const getByWorksheetCode = (worksheetCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWshtaxDetail/getByWorksheetCode", {
    params: {
      worksheetCode,
    }
  });
};

const isExistByWorksheetCode = (worksheetCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWshtaxDetail/isExistByWorksheetCode", {
    params: {
      worksheetCode,
    }
  });
};


const getByDebitNoteCode = (debitNoteCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWshtaxDetail/getByDebitNoteCode", {
    params: {
      debitNoteCode,
    }
  });
};

const isExistByDebitNoteCode = (debitNoteCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWshtaxDetail/isExistByDebitNoteCode", {
    params: {
      debitNoteCode,
    }
  });
};

const saveTWshtaxDetail = (tWshtaxDetail: TWshtaxDetail, remark: string) => {
  return http.post(Constants.DEFAULT_URL + "/tWshtaxDetail/saveTWshtaxDetail",
    {
      tWshtaxDetail,
      remark,
    }
  );
};

const defaultApi = {
  getByWorksheetCode,
  isExistByWorksheetCode,
  getByDebitNoteCode,
  isExistByDebitNoteCode,
  saveTWshtaxDetail,
}
export default defaultApi;