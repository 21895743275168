import { TOtherpaymentHeadData } from "src/domain/view/tOtherpaymentHeadData";
import http from "src/lib/http";
import Constants from "src/utils/constants";

const getOtherPaymentHeadById = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/tOtherpaymentHead/" + id);
};

const getOtherPaymentHeadByDate = (fromDate: string, toDate: string) => {
  return http.get(Constants.DEFAULT_URL + "/tOtherpaymentHead/getOtherPaymentHeadByDate", {
    params: {
      fromDate,
      toDate
    }
  });
};

const getOtherPaymentHeadByStatusAndDate = (status: string, fromDate: string, toDate: string) => {
  return http.get(Constants.DEFAULT_URL + "/tOtherpaymentHead/getOtherPaymentHeadByStatusAndDate", {
    params: {
      status,
      fromDate,
      toDate
    }
  });
};

const getOtherPaymentHeadByBranchAndDate = (branchId: string, fromDate: string, toDate: string) => {
  return http.get(Constants.DEFAULT_URL + "/tOtherpaymentHead/getOtherPaymentHeadByBranchAndDate", {
    params: {
      branchId,
      fromDate,
      toDate
    }
  });
};

const getOtherPaymentHeadByCustomerAndDate = (customerName: string, fromDate: string, toDate: string) => {
  return http.get(Constants.DEFAULT_URL + "/tOtherpaymentHead/getOtherPaymentHeadByCustomerAndDate", {
    params: {
      customerName,
      fromDate,
      toDate
    }
  });
};

const getOtherPaymentHeadByCustomerAndStatusAndDate = (customerName: string, status: string, fromDate: string, toDate: string) => {
  return http.get(Constants.DEFAULT_URL + "/tOtherpaymentHead/getOtherPaymentHeadByCustomerAndStatusAndDate", {
    params: {
      customerName,
      status,
      fromDate,
      toDate
    }
  });
};

const getOtherPaymentHeadByCustomerAndBranchAndDate = (customerName: string, branchId: string, fromDate: string, toDate: string) => {
  return http.get(Constants.DEFAULT_URL + "/tOtherpaymentHead/getOtherPaymentHeadByCustomerAndBranchAndDate", {
    params: {
      customerName,
      branchId,
      fromDate,
      toDate
    }
  });
};

const getOtherPaymentHeadByCustCode = (customerCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tOtherpaymentHead/getOtherPaymentHeadByCustCode", {
    params: {
      customerCode
    }
  });
};

const getOtherPaymentHeadByDebitorCode = (debitorCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tOtherpaymentHead/getOtherPaymentHeadByDebitorCode", {
    params: {
      debitorCode
    }
  });
};

const getTFinanceHead2ViewByTOtherpaymentHeadId = (tOtherpaymentHeadId: string) => {
  return http.get(Constants.DEFAULT_URL + "/tOtherpaymentHead/getTFinanceHead2ViewByTOtherpaymentHeadId", {
    params: {
      tOtherpaymentHeadId
    }
  });
};

const saveOtherPaymentHeadData = (otherPaymentHeadData: TOtherpaymentHeadData) => {
  return http.post(Constants.DEFAULT_URL + "/tOtherpaymentHead/saveTOtherPaymentHeadData", otherPaymentHeadData);
};

const defaultApi = {
  getOtherPaymentHeadById,
  getOtherPaymentHeadByDate,
  getOtherPaymentHeadByStatusAndDate,
  getOtherPaymentHeadByBranchAndDate,
  getOtherPaymentHeadByCustomerAndDate,
  getOtherPaymentHeadByCustomerAndStatusAndDate,
  getOtherPaymentHeadByCustomerAndBranchAndDate,
  getOtherPaymentHeadByCustCode,
  getOtherPaymentHeadByDebitorCode,
  getTFinanceHead2ViewByTOtherpaymentHeadId,
  saveOtherPaymentHeadData,
};
export default defaultApi;