import { ClearOutlined, PlusSquareOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, Row, Space, Table, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TCancelreserveHeadView } from "src/domain/tCancelreserveHeadView";
import PageHeader from "src/layouts/PageHeader";
import Constants from "src/utils/constants";
import { NotificationType, showNotification } from "src/utils/notificationUtils";
import tCancelreserveHeadService from "../../services/tCancelreserveHeadService";
import { toThDate } from "../../utils/dateTimeUtils";

const TCancelreserveHeadList: React.FC<TCancelreserveHeadView> = () => {
    const [tCancelreserveHeads, setTCancelreserveHeads] = useState<TCancelreserveHeadView[]>([]);
    const [page, setPage] = React.useState(1);
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [form] = Form.useForm();

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            width: 50,
            render: (value: number, item: TCancelreserveHeadView, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
        },
        {
            title: "วันที่ยกเลิก",
            dataIndex: "cancelReserveEntryDate",
            key: "cancelReserveEntryDate",
            width: 150,
            render: (value: any, item: TCancelreserveHeadView) => (<>{toThDate(item.cancelReserveEntryDate)}</>)
        },
        {
            title: "วันที่จอง",
            dataIndex: "istartDate",
            key: "istartDate",
            width: 150,
            render: (value: any, item: TCancelreserveHeadView) => (<>{toThDate(item.istartDate)}</>)
        },
        {
            title: "เลขที่ใบจอง",
            dataIndex: "ireserveCode",
            key: "ireserveCode",
        },
        {
            title: "ชื่อลูกค้า",
            dataIndex: "icustName",
            key: "icustName",
        },
        {
            title: "ผู้ยกเลิก",
            dataIndex: "cancelReserveCreateUsername",
            key: "cancelReserveCreateUsername",
        },
        {
            title: "หมายเหตุ",
            dataIndex: "cancelReserveRemark",
            key: "cancelReserveRemark",
        },
    ];

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
        form.setFieldsValue({
            reserveCode: "",
            fromDate: moment(new Date(), Constants.DATE_FORMAT),
            toDate: moment(new Date(), Constants.DATE_FORMAT)
        });
        form.submit();
    }, []);

    const onReset = () => {
        form.setFieldsValue({
            reserveCode: "",
            fromDate: moment(new Date(), Constants.DATE_FORMAT),
            toDate: moment(new Date(), Constants.DATE_FORMAT)
        });
        form.submit();
    };

    const onFinish = async (values: any) => {
        setLoading(true);
        let reserveCode: string = values.reserveCode ? values.reserveCode : "";
        let fromDate: string = values.fromDate ? moment(values.fromDate).format(Constants.DB_FORMAT) : "";
        let toDate: string = values.toDate ? moment(values.toDate).format(Constants.DB_FORMAT) : "";

        if (reserveCode && reserveCode.length >= 7) {
            fromDate = "";
            toDate = "";
        } else if (reserveCode.length > 0 && reserveCode.length < 7) {
            showNotification(NotificationType.WARNING, "กรุณาป้อนเลขที่ใบงานให้มากกว่า 7 ตัวอักษร");
            form.setFieldsValue({
                reserveCode: "",
            });
            setLoading(false);
            return;
        }
        const res = await tCancelreserveHeadService.getCancelReserveByReserveCodeAndEntryDate(reserveCode, fromDate, toDate);
        setTCancelreserveHeads(res.data);
        setLoading(false);
    }

    const onFinishFailed = (errorInfo: any) => {
    };

    const onAddTCancelreserveHead = () => {
        history.replace("/app/tCancelreserveHead/view");
    }

    return (
        <>
            <PageHeader title="โปรแกรมยกเลิกใบจอง">
                <Button
                    size="small"
                    type="primary"
                    icon={<PlusSquareOutlined />}
                    onClick={onAddTCancelreserveHead}
                >
                    เพิ่ม
                </Button>
            </PageHeader>
            <Content className="app-page-content">
                <Form
                    form={form}
                    name="bankBranchHead"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <div
                        style={{
                            alignItems: "baseline",
                            margin: "1em 0 1em 0",
                            paddingLeft: "1em",
                            borderBlockEnd: "2px solid #c9c9c9",
                        }}
                    >
                        <Row>
                            <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                                <Typography.Text>เลขที่ใบจอง: </Typography.Text>
                            </Col>
                            <Col span={21}>
                                <Form.Item
                                    name="reserveCode"
                                    rules={[{ required: false }]}
                                >
                                    <Input
                                        allowClear
                                        style={{
                                            width: 378,
                                        }}
                                        placeholder="ป้อนเลขที่ใบงาน"
                                    ></Input>
                                </Form.Item>

                            </Col>
                        </Row>
                        <Row>
                            <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                                <Typography.Text>วันที่ยกเลิกใบจอง ตั้งแต่: </Typography.Text>
                            </Col>
                            <Col span={21}>
                                <Space align="baseline" size={"large"}>
                                    <Form.Item
                                        name="fromDate"
                                        rules={[{ required: false }]}
                                    >
                                        <DatePicker
                                            format={Constants.DATE_FORMAT}
                                            size="middle"
                                            placeholder="เลือกวันที่"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="ถึง"
                                        name="toDate"
                                        rules={[{ required: false }]}
                                    >
                                        <DatePicker
                                            format={Constants.DATE_FORMAT}
                                            size="middle"
                                            placeholder="เลือกวันที่"
                                        />
                                    </Form.Item>
                                </Space>

                            </Col>
                        </Row>
                        <Row>
                            <Col span={20}></Col>
                            <Col span={4}>
                                <Space align="baseline" size={"small"}>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            icon={<SearchOutlined />}
                                            onClick={form.submit}
                                        >
                                            ค้นหา
                                        </Button>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            htmlType="button"
                                            type="primary"
                                            onClick={onReset}
                                            icon={<ClearOutlined />}
                                        >
                                            ล้าง
                                        </Button>
                                    </Form.Item>
                                </Space>
                            </Col>
                        </Row>
                    </div>
                </Form>
                <div>
                    <Table
                        columns={columns}
                        dataSource={tCancelreserveHeads}
                        size="small"
                        loading={loading}
                        pagination={{
                            pageSize: 15,
                            onChange(current) {
                                setPage(current);
                            }
                        }}
                        rowKey={(item: TCancelreserveHeadView) => item.ireserveCode!}
                    />
                </div>
            </Content>
        </>
    );
};

export default TCancelreserveHeadList;
