import { Col, Descriptions, Form, Space, Spin, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { TWorksheetReturnData } from 'src/domain/view/tWorksheetReturnData';
import { TWorksheetHeadView } from 'src/domain/worksheet/view/tWorksheetHeadView';
import PageHeader from 'src/layouts/PageHeader';
import tWorksheetReturnService from 'src/services/tWorksheetReturnService';
import { toThDate } from 'src/utils/dateTimeUtils';
import { formatNumber, formatNumberInt } from 'src/utils/numberUtils';

interface IParams {
  returnNo: string;
}

const ViewWorksheetReturn: React.FC<RouteComponentProps<IParams>> = (props) => {
  const returnNo = props.match.params.returnNo;
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [tworksheetReturnData, setTworksheetReturnData] = useState<TWorksheetReturnData>({} as TWorksheetReturnData);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 70,
      align: "center" as "center",
      render: (value: number, item: TWorksheetHeadView, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่ใบงาน",
      dataIndex: "iworksheetCode",
      key: "iworksheetCode",
      width: 150
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "icustName",
      key: "icustName",
      width: 250
    },
    {
      title: "วันเวลาที่ทำงาน",
      dataIndex: "istartDate",
      key: "istartDate",
      width: 190,
      align: "center" as "center",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{item.istartDate ? toThDate(item.istartDate) : ""} - {item.iendDate ? toThDate(item.iendDate) : ""}</span>;
      },
    },
    {
      title: "รถ",
      dataIndex: "truckNumber",
      key: "truckNumber",
      width: 150,
    },
    {
      title: "บริการ",
      dataIndex: "serviceDetailName",
      key: "serviceDetailName",
      width: 500,
    },
    {
      title: "พนักงานขับรถ",
      dataIndex: "idriverName",
      key: "idriverName",
      width: 150,
    },
    {
      title: "ค่าบริการ",
      dataIndex: "iserviceAmt",
      key: "iserviceAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iserviceAmt)}</span>;
      },
    },
    {
      title: "ค่าล่วงเวลา",
      dataIndex: "iotAmt",
      key: "iotAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iotAmt)}</span>;
      },
    },
    {
      title: "ค่าบริการอื่นๆ",
      dataIndex: "iextraAmt",
      key: "iextraAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iextraAmt)}</span>;
      },
    },
    {
      title: "ส่วนลดค่าบริการ",
      dataIndex: "idiscount",
      key: "idiscount",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.idiscount)}</span>;
      },
    },
    {
      title: "รวมก่อนภาษี",
      dataIndex: "itotal1Amt",
      key: "itotal1Amt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itotal1Amt)}</span>;
      },
    },
    {
      title: "ภาษี 7%",
      dataIndex: "itaxAmt",
      key: "itaxAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itaxAmt)}</span>;
      },
    },
    {
      title: "รวมค่าบริการ",
      dataIndex: "itotal2Amt",
      key: "itotal2Amt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itotal2Amt)}</span>;
      },
    },
    {
      title: "มัดจำ/ชำระล่วงหน้า",
      dataIndex: "idepositAmt",
      key: "idepositAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.idepositAmt)}</span>;
      },
    },
    {
      title: "คงเหลือ",
      dataIndex: "iremainAmt",
      key: "iremainAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        // return <span>{formatNumber(item.iremainAmt)}</span>;
        return <span>{formatNumber((item.itotal2Amt ? item.itotal2Amt : 0) - (item.idepositAmt ? item.idepositAmt : 0))}</span>;
      },
    },
    {
      title: "สถานะใบสั่งงาน",
      dataIndex: "iworksheetStatusName",
      key: "iworksheetStatusName",
      width: 130,
      align: "center" as "center",
    },
    {
      title: "เลขที่ใบแจ้งหนี้",
      dataIndex: "debitnotecode",
      key: "debitnotecode",
      width: 130,
      align: "center" as "center",
    },
    {
      title: "พนักงาน",
      dataIndex: "createUserName",
      key: "createUserName",
      width: 200,
    },
    {
      title: "วันที่สร้างบิล",
      dataIndex: "icomment",
      key: "icomment",
      width: 100,
      align: "center" as "center",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{item.ientryDate ? toThDate(item.ientryDate) : ""}</span>;
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    (async () => {
      const res = await tWorksheetReturnService.getTworksheetReturnDataByReturnno(Number(returnNo));
      setTworksheetReturnData(res.data);
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <PageHeader title="" onClose={() => history.push("/app/worksheetReturn/list")} />
      <Spin spinning={loading}>
        <Content className="app-page-content">
          <Descriptions
            style={{ margin: "1em" }}
            column={4}
            title="ข้อมูลรับใบสั่งงานกลับ"
            size="middle"
          />
          <div style={{ margin: "1em" }}>
            <Form
              form={form}
              name="returnWorksheet"
              labelCol={{ span: 10 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                  margin: "1em 0 0 0",
                  paddingLeft: "1em",
                }}
              >
                <Col span={14} style={{ textAlign: "left", paddingLeft: "3.5em" }}>
                  <div style={{ display: "flex", justifyContent: "flex-start", margin: "1em" }}>
                    <Descriptions
                      column={6}
                      size="middle"
                      bordered
                      labelStyle={{ textAlign: "right", fontWeight: "bold" }}
                    >
                      <Descriptions.Item label="เลขที่รับกลับ" span={6}>{tworksheetReturnData.tworksheetreturnHeadView?.ireturnNo}</Descriptions.Item>
                      <Descriptions.Item label="วันที่รับกลับ" span={6}>{toThDate(tworksheetReturnData.tworksheetreturnHeadView?.ientryDate)}</Descriptions.Item>
                      <Descriptions.Item label="พนักงานรับกลับ" span={6}>{tworksheetReturnData.tworksheetreturnHeadView?.icreateUserName}</Descriptions.Item>
                    </Descriptions>
                  </div>
                </Col>
                <Col span={3} style={{ textAlign: "center" }}>
                  <Space align="center" direction='vertical'>
                    <Typography.Title level={4}>ส่งการเงิน</Typography.Title>
                    <Typography.Title level={5} style={{ margin: 0, color: "#FD1212" }}>
                      {formatNumberInt(tworksheetReturnData.worksheetSendfinanceQty)}
                    </Typography.Title>
                  </Space>
                </Col>
                <Col span={3} style={{ textAlign: "center" }}>
                  <Space align="center" direction='vertical'>
                    <Typography.Title level={4}>ส่งเร่งรัด</Typography.Title>
                    <Typography.Title level={5} style={{ margin: 0, color: "#FD1212" }}>
                      {formatNumberInt(tworksheetReturnData.worksheetSendRengradQty)}
                    </Typography.Title>
                  </Space>
                </Col>
                <Col span={4}></Col>
              </div>
            </Form>
            <div style={{ marginBottom: 0 }}>
              <Table
                columns={columns}
                dataSource={tworksheetReturnData.tworksheetHeadViewList}
                size="small"
                scroll={{ x: 1800, y: 300 }}
                pagination={false}
                rowKey={(item: TWorksheetHeadView) => item.ireserveCode!}
                style={{ minHeight: "800px" }}
              />
            </div>
          </div>
        </Content>
      </Spin>
    </>
  )
};

export default ViewWorksheetReturn;