import { EditOutlined } from "@ant-design/icons";
import { Button, Descriptions, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { TServiceHeadView } from "src/domain/master/view/tServiceHeadView";
import PageHeader from "src/layouts/PageHeader";
import { formatNumber } from "src/utils/numberUtils";
import { getFlg } from "src/utils/rodyokUtils";
import tServiceHeadService from "../../services/tservice/tServiceHeadService";
import { getFlgAugerDescription, getFlgIntownDescription } from "../../utils/stringUtils";
import AddEditTServiceHeadDrawer from "./AddEditTServiceHeadDrawer";

interface IParams {
    iserviceCode: string;
}

const ViewTServiceHead: React.FC<RouteComponentProps<IParams>> = (props) => {
    const iserviceCode = props.match.params.iserviceCode;
    const [tServiceHead, setTServiceHead] = useState<TServiceHeadView>({} as TServiceHeadView);
    const history = useHistory();
    const [visibleAddEditTServiceHeadDrawer, setVisibleAddEditTServiceHeadDrawer] = useState<boolean>(false);

    useEffect(() => {
        if (!visibleAddEditTServiceHeadDrawer) {
            initial();
        }
    }, [visibleAddEditTServiceHeadDrawer]);

    const initial = async () => {
        const res = await tServiceHeadService.getTServiceHeadByCode(iserviceCode);
        setTServiceHead(res.data);
    };

    const onEditButtonClicked = () => {
        setVisibleAddEditTServiceHeadDrawer(true);
    }

    return (
        <>
            <PageHeader title="" onClose={() => history.push("/app/tservice/list")} />
            <Content className="app-page-content">
                <Descriptions
                    style={{ margin: "1em" }}
                    column={4}
                    title="ข้อมูลบริการ"
                    size="middle"
                    extra={
                        <Button
                            size="middle"
                            type="primary"
                            icon={<EditOutlined />}
                            onClick={onEditButtonClicked}

                        >
                            แก้ไข
                        </Button>
                    }
                />
                <div style={{ display: "flex", justifyContent: "flex-start", margin: "1em" }}>
                    <Descriptions
                        column={6}
                        size="middle"
                        bordered
                        labelStyle={{ textAlign: "right", fontWeight: "bold" }}
                    >
                        <Descriptions.Item label="รหัสบริการ" span={6}>{tServiceHead.iserviceCode}</Descriptions.Item>
                        <Descriptions.Item label="ชื่อบริการ" span={6}>{tServiceHead.iserviceName}</Descriptions.Item>
                        <Descriptions.Item label="รายการ" span={2}>({tServiceHead.assettype}) {tServiceHead.assettypeName}</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="ประเภทรถ" span={2}>({tServiceHead.typetruck}) {tServiceHead.typetruckName}</Descriptions.Item>
                        <Descriptions.Item label="จำนวนล้อรถ" span={2}>({tServiceHead.truckwheel}) {tServiceHead.truckwheelName}</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="ขนาด/รุ่น" span={2}>({tServiceHead.trucksize}) {tServiceHead.trucksizeName}</Descriptions.Item>
                        <Descriptions.Item label="ขอบเขตการให้บริการ" span={2}>{getFlgIntownDescription(tServiceHead.flgIntown)}</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="รายการหัวเจาะ" span={2}>{getFlgAugerDescription(tServiceHead.flgAuger)}</Descriptions.Item>
                        <Descriptions.Item label="หน่วยนับ" span={6}>({tServiceHead.iunitCode}) {tServiceHead.unitName}</Descriptions.Item>
                        <Descriptions.Item label="ราคาขาย 1" span={6}>{formatNumber(tServiceHead.iunitPrice)} บาท</Descriptions.Item>
                        <Descriptions.Item label="ราคาขาย 2" span={6}>{formatNumber(tServiceHead.iunitPrice2)} บาท</Descriptions.Item>
                        <Descriptions.Item label="ราคาขาย 3" span={6}>{formatNumber(tServiceHead.iunitPrice3)} บาท</Descriptions.Item>
                        <Descriptions.Item label="คิดทำ OT/ชม." span={2}>{formatNumber(tServiceHead.rateot)}</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="คิดเพิ่มในหน่วยเต็มวัน" span={2}>{tServiceHead.nextServicecode}</Descriptions.Item>
                        <Descriptions.Item label="สถานะ" span={6}>{getFlg(tServiceHead.idltFlg) ? <Typography.Text type="success">ใช้งาน</Typography.Text> : <Typography.Text type="danger">ไม่ใช้งาน</Typography.Text>}</Descriptions.Item>
                        <Descriptions.Item label="หมายเหตุ" span={6}>{tServiceHead.iremark}</Descriptions.Item>
                    </Descriptions>
                </div>
            </Content>
            <div> {
                visibleAddEditTServiceHeadDrawer && <AddEditTServiceHeadDrawer
                    iserviceCode={tServiceHead.iserviceCode}
                    visibleAddEditTServiceHeadDrawer={visibleAddEditTServiceHeadDrawer}
                    setVisibleAddEditTServiceHeadDrawer={setVisibleAddEditTServiceHeadDrawer} />
            }</div>
        </>
    );
};

export default ViewTServiceHead;
