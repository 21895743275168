import { CloseCircleOutlined, DeleteOutlined, ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Col, Drawer, Form, Input, Modal, Select, Space, Table, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { TEmployee2View } from 'src/domain/master/view/tEmployee2View';
import { TWorksheetHeadView } from 'src/domain/worksheet/view/tWorksheetHeadView';
import concludeWorksheetService from 'src/services/concludeWorksheetService';
import employeeService from 'src/services/employee/tEmployee2Service';
import tWorksheetHeadService from 'src/services/tWorksheetHeadService';
import { useAppSelector } from 'src/stores/store';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, formatNumberInt } from 'src/utils/numberUtils';

const { Option } = Select;
const { confirm } = Modal;

interface IProps {
  onDrawerVisible: boolean;
  setOnDrawerVisible: any;
}

const ConcludeWorksheetDrawer: React.FC<IProps> = (props) => {
  const { onDrawerVisible, setOnDrawerVisible } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [form] = Form.useForm();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [worksheetHeadViews, setWorksheetHeadViews] = useState<TWorksheetHeadView[]>([]);
  const [employees, setEmployees] = useState<TEmployee2View[]>([]);//ผู้แจ้ง
  const [countWorksheet, setCountWorksheet] = useState(0);//นับจำนวนใบงาน
  const [worksheetAmtTotal, setWorksheetAmtTotal] = useState(0);//จำนวนเงินทั้งหมด

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 80,
      align: "center" as "center",
      render: (value: number, item: TWorksheetHeadView, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่ใบงาน",
      dataIndex: "iworksheetCode",
      key: "iworksheetCode",
      width: 150,
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "icustName",
      key: "icustName",
      width: 300,
    },
    {
      title: "ประเภทใบงาน",
      dataIndex: "itypeworksheetName",
      key: "itypeworksheetName",
      width: 100,
    },
    {
      title: "ประเภทการชำระเงิน",
      dataIndex: "itypepaymentName",
      key: "itypepaymentName",
      width: 150,
    },
    {
      title: "รายการบริการ",
      dataIndex: "serviceDetailName",
      key: "serviceDetailName",
      width: 300,
    },
    {
      title: "ค่าบริการ",
      dataIndex: "iserviceAmt",
      key: "iserviceAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iserviceAmt)}</span>;
      },
    },
    {
      title: "ค่าบริการพิเศษ",
      dataIndex: "iextraAmt",
      key: "iextraAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iextraAmt)}</span>;
      },
    },
    {
      title: "ค่าล่วงเวลา",
      dataIndex: "iotAmt",
      key: "iotAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iotAmt)}</span>;
      },
    },
    {
      title: "ส่วนลด",
      dataIndex: "idiscount",
      key: "idiscount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.idiscount)}</span>;
      },
    },
    {
      title: "ค่าบริการรวม",
      dataIndex: "itotal1Amt",
      key: "itotal1Amt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itotal1Amt)}</span>;
      },
    },
    {
      title: "ภาษี",
      dataIndex: "itaxAmt",
      key: "itaxAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itaxAmt)}</span>;
      },
    },
    {
      title: "ค่าบริการรวมภาษี",
      dataIndex: "itotal2Amt",
      key: "itotal2Amt",
      width: 130,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itotal2Amt)}</span>;
      },
    },
    {
      title: "",
      key: "action",
      width: 50,
      align: "center" as "center",
      render: (value: any, item: TWorksheetHeadView, index: number) => {
        // render: () => {
        return (
          < Button
            type="primary"
            size='small'
            danger
            shape="circle"
            icon={< DeleteOutlined />}
            onClick={() => onDelete(item, index)}
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (trigger) {
      form.validateFields()
        .then(() => {
          confirm({
            title: "ยืนยัน!",
            icon: <ExclamationCircleOutlined />,
            content: "ต้องการบันทึกการตัดจบใบงานหรือไม่?",
            cancelText: "ยกเลิก",
            okText: "ยืนยัน",
            onOk() {
              form.submit();
            },
            onCancel() {
              setTrigger(false);
            }
          });
        }).catch(() => {
          setTrigger(false);
        });
    }
  }, [trigger]);

  useEffect(() => {
    let wshTotal2Amt = 0;
    if (worksheetHeadViews.length > 0) {
      worksheetHeadViews.forEach(async (obj: TWorksheetHeadView) => {
        wshTotal2Amt = wshTotal2Amt + obj.itotal2Amt!;
      });
    }
    setCountWorksheet(worksheetHeadViews.length);
    setWorksheetAmtTotal(wshTotal2Amt);
  }, [worksheetHeadViews]);

  const handleSearchEmployee = async (data: any) => {
    if (data.length < 3) return;
    const res = await employeeService.getEmployee2ByParam(data);
    setEmployees(res.data);
  };

  const onDelete = async (item: TWorksheetHeadView, index: number) => {
    const array = worksheetHeadViews ? [...worksheetHeadViews] : [];
    array?.splice(index, 1);
    setWorksheetHeadViews([...array]);
  }

  const onWorksheetCodeChange = async (value: string) => {
    const findWorkSheetCodeRes = await tWorksheetHeadService.getByWorksheetCodeViewForConclude(value);
    const selectedWsh: TWorksheetHeadView = findWorkSheetCodeRes.data;
    const foundInList = (worksheetHeadViews as Array<TWorksheetHeadView>).find((obj) => obj.iworksheetCode === value);

    if (!findWorkSheetCodeRes.data) {
      showNotification(NotificationType.ERROR, "ไม่พบข้อมูลใบสั่งงาน");
      return;
    } else if (foundInList) {
      showNotification(NotificationType.ERROR, "มีใบงานเลขที่ " + value + " อยู่ในรายการแล้ว");
      return;
    } else if (selectedWsh.itypepaymentCode === "04" && Number(selectedWsh.iworksheetStatus) > 7) {
      showNotification(NotificationType.ERROR, "สถานะใบงานไม่ถูกต้อง");
      return;
    } else if (selectedWsh.iworksheetStatus === "98"
      || selectedWsh.iworksheetStatus === "99"
      || selectedWsh.iworksheetStatus === "20") {
      showNotification(NotificationType.ERROR, "สถานะใบงานไม่ถูกต้อง");
      return;
    } else {
      const array = worksheetHeadViews ? [...worksheetHeadViews] : [];
      array.push(selectedWsh);
      setWorksheetHeadViews(array);
    }
  }

  const onFinish = async (values: any) => {
    const empSelected = (employees as Array<TEmployee2View>).find((obj) => obj.id === values.userInfoId);
    if (worksheetHeadViews.length <= 0) {
      showNotification(NotificationType.ERROR, "กรุณาระบุใบงานที่ต้องการตัดจบ");
      setTrigger(false);
    } else {
      try {
        await concludeWorksheetService.saveConcludeWorksheet(worksheetHeadViews, values.userRemark, empSelected ? empSelected.empname : "");
        showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเรียบร้อย");
        setTrigger(false);
        setOnDrawerVisible(false);
      } catch (err) {
        showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        setTrigger(false);
      }
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาตรวจสอบข้อมูลให้ครบถ้วน!");
    setTrigger(false);
  };

  return (
    <>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Typography.Title level={4}>โปรแกรมตัดจบใบงาน</Typography.Title>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "",
              }}
            >
              <Button
                type="primary"
                danger
                icon={<CloseCircleOutlined />}
                onClick={() => setOnDrawerVisible(false)}>
                ยกเลิก
              </Button>
              <Button
                onClick={() => setTrigger(true)}
                type="primary"
                icon={<SaveOutlined />}
                style={{ marginLeft: "5px" }}
              >
                บันทึก
              </Button>
            </div>
          </div>
        }
        width="90%"
        visible={onDrawerVisible}
        forceRender={true}
        placement="right"
        bodyStyle={{ paddingBottom: 80 }}
        closable={false}
        destroyOnClose={true}
      >
        <Content className="app-page-content">
          <Form
            form={form}
            name="taxInvoiceHead"
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 8 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="ผู้แจ้ง"
              name="userInfoId"
              rules={[{ required: true, message: "กรุณาระบุ ผู้แจ้ง" }]}
            >
              <Select
                showSearch
                allowClear
                size="middle"
                placeholder="เลือกผู้แจ้ง"
                optionFilterProp="children"
                filterOption={true}
                onSearch={handleSearchEmployee}
              >
                {employees.map((obj) => {
                  return (
                    <Option key={obj.id} value={obj.id}>
                      {obj.empname}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label="หมายเหตุ"
              name="userRemark"
              rules={[{ required: true, message: "กรุณาระบุหมายเหตุก่อน" }]}
            >
              <TextArea
                rows={3}
              ></TextArea>
            </Form.Item>
            <Card
              style={{
                width: "auto",
                marginBottom: 5,
              }}
              headStyle={{
                fontSize: 14,
                paddingBottom: 5,
              }}
              title="รายการ"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                  paddingLeft: "7em",
                  borderBlockEnd: "2px solid #c9c9c9",
                }}
              >
                <Col span={17} style={{ textAlign: "left" }}>
                  <Space align="start" direction='vertical'>
                    <Typography.Title level={4}>
                      <span>เลขที่ใบงาน <label className="text-red">*</label>
                      </span>
                    </Typography.Title>
                    <Form.Item
                      name="worksheetCode"
                      rules={[{ required: false }]}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input
                        allowClear={false}
                        onFocus={(e: any) => e.target.select()}
                        onKeyDown={(evt: any) => {
                          if (evt.keyCode === 13) {
                            onWorksheetCodeChange(evt.target.value);
                          }
                        }}
                      ></Input>
                    </Form.Item>
                  </Space>
                </Col>
                <Col span={3} style={{ textAlign: "center" }}>
                  <Space align="center" direction='vertical'>
                    <Typography.Title level={4}>ใบงานทั้งหมด</Typography.Title>
                    <Typography.Title level={5} style={{ margin: 0, color: "#FD1212" }}>
                      {formatNumberInt(countWorksheet)}
                    </Typography.Title>
                  </Space>
                </Col>
                <Col span={3} style={{ textAlign: "center" }}>
                  <Space align="center" direction='vertical'>
                    <Typography.Title level={4}>จำนวนเงินทั้งหมด</Typography.Title>
                    <Typography.Title level={5} style={{ margin: 0, color: "#FD1212" }}>
                      {formatNumber(worksheetAmtTotal)}
                    </Typography.Title>
                  </Space>
                </Col>
                <Col span={1}></Col>
              </div>
              <div style={{ marginBottom: 0 }}>
                <Table
                  columns={columns}
                  dataSource={worksheetHeadViews}
                  size="small"
                  scroll={{ x: 1500, y: 250 }}
                  pagination={false}
                  rowKey={(item: TWorksheetHeadView) => item.iworksheetCode!}
                />
              </div>
            </Card>
          </Form>
        </Content>
      </Drawer>
    </>
  )
}

export default ConcludeWorksheetDrawer