import React, { useEffect, useState } from 'react';
import { Input, Button, Col, DatePicker, Form, Row, Select, Table, Typography, Modal } from 'antd';
import moment from 'moment';
import Constants from 'src/utils/constants';
import PageHeader from 'src/layouts/PageHeader';
import { Content } from 'antd/lib/layout/layout';
import { useAppSelector } from 'src/stores/store';
import { PrinterOutlined, SearchOutlined } from '@ant-design/icons';
import reportService from 'src/services/reportService';
import { formatNumber, formatNumberInt } from 'src/utils/numberUtils';
import PdfViewPopup from './PdfViewPopup';
import { RyRefbranch } from 'src/domain/ryRefbranch';
import ryRefbranchService from 'src/services/reference/ryRefbranchService';
import tCustomerHeadService from "src/services/customer/tCustomerHeadService";
import { FnReceiveDailySummaryReportData } from 'src/domain/worksheet/fnReceiveDailySummaryReportData';
import { RyAccountView } from 'src/domain/view/ryAccountView';
import ryAccountService from 'src/services/bank/ryAccountService';
import { TFinanceTrush } from 'src/domain/finance/tFinanceTrush';
import { TFinanceTrushView } from 'src/domain/finance/view/tFinanceTrushView';


const { Option } = Select;

const TrushMoneyReport = () => {
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  const [dataList, setDataList] = useState<TFinanceTrush[]>([]);
  const [dataPreview, setDataPreview] = useState<any>();
  const [ryRefBranchSelected, setRyRefBranchSelected] = useState<RyRefbranch>({} as RyRefbranch);
  const [ryRefBranchs, setRyRefBranchs] = useState<RyRefbranch[]>([]);  //สาขา
  const [ryAccounts, setRyAccounts] = useState<RyAccountView[]>([]); //บัญชีธนาคาร

  const [visiblePDF, setVisiblePDF] = useState(false);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false);

  const [fromDateParam, setFromDateParam] = useState<any>();
  const [toDateParam, setToDateParam] = useState<any>();
  const [idParam, setIdParam] = useState<any>();
  const [branchIdParam, setBranchIdParam] = useState<any>();
  const [accSeqParam, setAccSeqParam] = useState<any>();

  
  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: '1%',
      align: "center" as "center",
      render: (value: number, item: TFinanceTrushView, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
    },
    {
      title: "วันที่นำฝาก",
      dataIndex: "trushdate",
      key: "trushdate",
      width: '5%',
      align: "center" as "center",
    },
    {
      title: "เลขที่บัญชี",
      dataIndex: "accountNumber",
      key: "accountNumber",
      width: '7%',
    },
    {
      title: "ชื่อบัญชี",
      dataIndex: "accountName",
      key: "accountName",
      width: '11%',
    },
    {
      title: "ประเภท",
      dataIndex: "type",
      key: "type",
      width: '5%',
    },
    {
      title: "เลขที่เช็ค",
      dataIndex: "chquenumber",
      key: "chquenumber",
      width: '5%',
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "amount",
      key: "amount",
      width: '7%',
      align: "right" as "right",
      render: (value: any, item: TFinanceTrushView) => {
        return <span>{formatNumber(item.amount)}</span>;
      },
    },
    {
      title: "พนักงานนำฝาก",
      dataIndex: "trushusername",
      key: "trushusername",
      width: '8%',
    },
    {
      title: "เลขที่การนำฝาก",
      dataIndex: "id",
      key: "id",
      width: '8%',
      align: "center" as "center",
    },
    {
      title: "หมายเหตุ",
      dataIndex: "remark",
      key: "remark",
      width: '11%',
    },
    {
      title: "สาขาที่นำฝาก",
      dataIndex: "branchName",
      key: "branchName",
      width: '10%',
    },
  ]

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });

    initData();
    form.submit();
  }, []);


  const initData = async () => {
    const ryAccountRes = await ryAccountService.getRyAccountViewList();
    const refbranchRes = await ryRefbranchService.getRyRefbranchList();

    setRyAccounts(ryAccountRes.data);
    setRyRefBranchs(refbranchRes.data);

    form.setFieldsValue({
      // fromDate: moment(new Date(), Constants.DATE_FORMAT).startOf('month'),
      // toDate: moment(new Date(), Constants.DATE_FORMAT).endOf('month'),
      id: "",
      fromDate: moment(new Date(), Constants.DATE_FORMAT),
      toDate: moment(new Date(), Constants.DATE_FORMAT),
      branchId: "",
      accSeq: "",
    });
  }

  const rowClassNameFn = (record: TFinanceTrushView, index) => {
    if(record.trushstatus === 0){
      return "row-black"
    } else if(record.trushstatus === -1){
      return "row-red"
    } else {
      return "row-blue"
    }
    return null;
  }

  const onFinish = async (values: any) => {
    setLoading(true);
    const id = values.id ? values.id : "";
    const fromDate = moment(values.fromDate).format("yyyyMMDD");
    const toDate = moment(values.toDate).format("yyyyMMDD");
    const branchId = values.branchId ? values.branchId : "";
    const accSeq = values.accSeq ? values.accSeq : "";

    const res = await reportService.getTrushMoneyReport(id, fromDate, toDate, branchId, accSeq);
    // console.log('----------res--------', res.data)
    setDataList(res.data);
    setLoading(false);
    setPage(1);

    setIdParam(id);
    setFromDateParam(fromDate);
    setToDateParam(toDate);
    setBranchIdParam(branchId);
    setAccSeqParam(accSeq);
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  const onPrint = async () => {
    setVisiblePDF(true);
  }

  return (
    <>
      <PageHeader title="รายงานการฝากเงิน"></PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="trushMoney"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
            }}
          >
           
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เลขที่การนำฝาก: </Typography.Text>
              </Col>
              <Col span={7}>
                <Form.Item name="id" style={{ marginBottom: "3px" }}>
                  <Input style={{ marginRight: 5 }}></Input>
                </Form.Item>
              </Col>
              <Col span={9}></Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>วันที่นำฝาก: </Typography.Text>
              </Col>
              <Col span={3}>
                <Form.Item
                  name="fromDate"
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={1} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ถึง: </Typography.Text>
              </Col>
              <Col span={3}>
                <Form.Item
                  name="toDate"
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ธนาคาร: </Typography.Text>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="accSeq"
                  style={{ marginBottom: "3px" }}
                >
                  <Select
                    showSearch
                    size="middle"
                    placeholder="เลือกธนาคาร"
                    optionFilterProp="children"
                    filterOption={true}
                  >
                    {ryAccounts.map((obj: RyAccountView) => {
                      return (
                        <Option key={obj.accountnumber} value={obj.seq}>
                          {obj.accountnumber} : {obj.accountname}<br />
                          <span style={{ marginLeft: "2rem", fontSize: 12 }}>
                            {obj.bankname} - สาขา{obj.branchname}
                          </span>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>สาขาที่นำฝาก: </Typography.Text>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="branchId"
                  style={{ marginBottom: "3px" }}
                >
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกสาขา"
                    optionFilterProp="children"
                    filterOption={true}
                    onClear={() => setRyRefBranchSelected({} as RyRefbranch)}
                  >
                    {ryRefBranchs.map((obj) => {
                      return (
                        <Option key={obj.id} value={obj.id}>
                          {obj.id}: {obj.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={13} style={{ textAlign: "right"}}>
                <Button
                  size="small"
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={form.submit}
                >
                  ค้นหา
                </Button>
              </Col>
              <Col span={8}></Col>
            </Row>
          </div>
        </Form>
           
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "0px 20px 10px 0px",
            borderBottom: '2px solid #c9c9c9'
          }}
        >
          <Button
          style={{
            marginLeft: "30px",}}
            size="small"
            type="primary"
            icon={<PrinterOutlined />}
            onClick={onPrint}
          >
            พิมพ์
          </Button>
          <Modal
            style={{ top: 20 }}
            width={"90vw"}
            title="RS001"
            bodyStyle={{ height: "85vh" }}
            centered={true}
            visible={isPreviewModalVisible}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={() => setIsPreviewModalVisible(false)}
          >
            <iframe title=" " src={dataPreview} width={"100%"} height={"100%"}></iframe>
          </Modal>
        </div>

        <div>
          <Table
            rowClassName={rowClassNameFn}
            columns={columns}
            dataSource={dataList}
            size="small"
            loading={loading}
            rowKey={(item: TFinanceTrushView) => item.id!}
            scroll={{ x: 1700 }}
            pagination={{
              pageSize: 10,
              current: page,
              onChange(current) {
                setPage(current);
              }
            }}
          />
        </div>
      </Content>

      <Modal
        title= 'print'
        centered
        closable={true}
        visible={visiblePDF}
        onCancel={() => setVisiblePDF(false)}
        width="400"
      >
        <PdfViewPopup
          urlPath={reportService.getTrushMoneyReportPreview(idParam, fromDateParam, toDateParam, branchIdParam, accSeqParam)}
          onClose={() => setVisiblePDF(false)}
        />
      </Modal>
    </>
  )
}

export default TrushMoneyReport