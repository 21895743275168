import { Table, Typography } from 'antd';
import React from 'react'
import { TWorksheetExtrapriceView } from 'src/domain/worksheet/view/tWorksheetExtrapriceView';
import { WorksheetHeadData } from 'src/domain/worksheet/view/worksheetHeadData';
import { formatNumber } from 'src/utils/numberUtils';

interface Iprops {
  worksheetHeadData: WorksheetHeadData;
}
const WorksheetExtrapriceViewTab: React.FC<Iprops> = (props) => {
  const { worksheetHeadData } = props;

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      key: "index",
      align: "center" as "center",
      render: (value: any, item: TWorksheetExtrapriceView, index: number) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: "รายการ",
      dataIndex: "idescName",
      key: "idescName",
      render: (value: any, item: TWorksheetExtrapriceView) => {
        return <span>{item.idescName} {item.iremark ?  ", " + item.iremark : ""}</span>;
      },
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "iamount",
      key: "iamount",
      align: "right" as "right",
      render: (value: any, item: TWorksheetExtrapriceView) => {
        return <span>{formatNumber(item.iamount)}</span>;
      },
    },
  ];

  return (
    <>
      <Typography.Title
        type="danger"
        level={4}
        style={{ height: "22px", textAlign: "right" }}
      >
        จำนวนเงิน :
        {formatNumber(worksheetHeadData.tworksheetHead.iextraAmt)}
      </Typography.Title>
      <div>
        <Table
          columns={columns}
          dataSource={worksheetHeadData.tworksheetExtrapriceViewList}
          rowKey={(item) => item.iid}
          size="small"
          pagination={false}
        />
      </div>
    </>
  )
}

export default WorksheetExtrapriceViewTab