import http from "../lib/http";
import Constants from "../utils/constants";

const getTAssetmovementById = (id: number) => {
  return http.get(Constants.DEFAULT_URL + "/tAssetmovement/getTAssetmovementById", {
    params: {
      id,
    }
  });
};

const getTAssetmovementByParam = (branchId: string, flg: string, worksheetCode?: string, fromDate?: string, toDate?: string) => {
  return http.get(Constants.DEFAULT_URL + "/tAssetmovement/getTAssetmovementByParam", {
    params: {
      branchId,
      flg,
      worksheetCode,
      fromDate,
      toDate
    }
  });
};

const getTAssetmovementReturned = (branchId: string, flg: string, worksheetCode?: string, fromDate?: string, toDate?: string) => {
  return http.get(Constants.DEFAULT_URL + "/tAssetmovement/getTAssetmovementReturned", {
    params: {
      branchId,
      flg,
      worksheetCode,
      fromDate,
      toDate
    }
  });
};

const postTAssetmovement = (tAssetmovement: any) => {
  return http.post(Constants.DEFAULT_URL + "/tAssetmovement/returnTruck", tAssetmovement);
}

const getByWorksheetCode = (worksheetCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tAssetmovement/getByWorksheetCode/" + worksheetCode);
}

const defaultApi = {
  getTAssetmovementById,
  getTAssetmovementByParam,
  getTAssetmovementReturned,
  getByWorksheetCode,
  postTAssetmovement,
};
export default defaultApi;
