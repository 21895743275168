import { Form, Modal, Select, Spin, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { TWorksheetHeadView } from 'src/domain/worksheet/view/tWorksheetHeadView';
import { TWorksheetInstalmentDetailView } from 'src/domain/worksheet/view/tWorksheetInstalmentDetailView';
import tWorksheetInstallmentDetailService from 'src/services/tWorksheetInstallmentDetailService';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';

interface IProps {
  visibleModalWshInstallment: boolean;
  setVisibleModalWshInstallment: any;
  wshHeadViewSelected: TWorksheetHeadView;
  setWshHeadViewSelected: any;
  wshInstallmentSelected: TWorksheetInstalmentDetailView;
  setWshInstallmentSelected: any;
}

const { Option } = Select;

const SelectWorksheetInstallmentModal: React.FC<IProps> = (props) => {
  const { visibleModalWshInstallment, setVisibleModalWshInstallment, wshHeadViewSelected, setWshHeadViewSelected, wshInstallmentSelected, setWshInstallmentSelected } = props;
  const [form] = Form.useForm();
  const [showSpin, setShowSpin] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [wshInstallmentDetailList, setWshInstallmentDetailList] = useState<TWorksheetInstalmentDetailView[]>([]);
  const [wshInstallmentDetailDefault, setWshInstallmentDetailDefault] = useState<TWorksheetInstalmentDetailView>({} as TWorksheetInstalmentDetailView);

  useEffect(() => {
    setShowSpin(true);
    (async () => {
      const installmentViewRes = await tWorksheetInstallmentDetailService.getLessThanEqualsAtInstallment(
        wshHeadViewSelected.iworksheetCode!, wshHeadViewSelected.atinstalment!.toString());
      if (installmentViewRes.data) {
        const currentInstallment = (installmentViewRes.data as Array<TWorksheetInstalmentDetailView>).find((obj) => obj.iatinstalment === wshHeadViewSelected.atinstalment!.toString());
        setWshInstallmentDetailList(installmentViewRes.data);
        setWshInstallmentDetailDefault(currentInstallment ? currentInstallment : {} as TWorksheetInstalmentDetailView);
        form.setFieldsValue({
          atinstallment: currentInstallment?.iid,
        })
      }
    setShowSpin(false);
    })();
  }, []);

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  const onFinish = async (values: any) => {
    const foundInstallment = (wshInstallmentDetailList as Array<TWorksheetInstalmentDetailView>).find((ob) => ob.iid === form.getFieldValue("atinstallment"));
    setWshInstallmentSelected({ ...foundInstallment, 
      flagtaxinvoid: "1" 
    });
    setWshHeadViewSelected({...wshHeadViewSelected, 
      serviceNameRemarks: wshHeadViewSelected.serviceNameRemarks + "งวดที่ " + foundInstallment?.iatinstalment + "/" +  wshHeadViewSelected.allinstalment + " รอบวันที่ " + foundInstallment?.dateFrom + " - " + foundInstallment?.dateTo,
    })
    form.resetFields();
    setTrigger(false);
    setVisibleModalWshInstallment(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน !");
    setTrigger(false);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>ระบุงวดที่ต้องการออกใบกำกับภาษีของใบงาน</Typography.Title>
          </div>
        }
        visible={visibleModalWshInstallment}
        width={"40%"}
        style={{ minHeight: "70%" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setVisibleModalWshInstallment(false)}
      >
        <Spin spinning={showSpin}>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="งวดที่"
              name="atinstallment"
              rules={[{ required: true, message: "กรุณาระบุงวดก่อน" }]}
            >
              <Select
                showSearch
                allowClear
                size="middle"
                placeholder="เลือกงวด"
                optionFilterProp="children"
                filterOption={true}
              >
                {wshInstallmentDetailList.map((obj) => {
                  return (
                    <Option key={obj.iid} value={obj.iid}>
                      งวดที่{'   '}{obj.iatinstalment} / {wshHeadViewSelected.allinstalment}{'   '}รอบวันที่{'   '}{obj.dateFrom}{'   '}-{'   '}{obj.dateTo}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  )
}

export default SelectWorksheetInstallmentModal