import { Form, Input, Modal, Select, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { TEmployee2View } from 'src/domain/master/view/tEmployee2View';
import { TUserHead } from 'src/domain/tUserHead';
import { TWshPromiss } from 'src/domain/tWshPromiss';
import { TWshPromissMovement } from 'src/domain/tWshPromissMovement';
import { TWorksheetHead } from 'src/domain/worksheet/tWorksheetHead';
import { TWorksheetMovement } from 'src/domain/worksheet/tWorksheetMovement';
import { WorksheetHeadData } from 'src/domain/worksheet/view/worksheetHeadData';
import employeeService from 'src/services/employee/tEmployee2Service';
import worksheetService from 'src/services/worksheetService';
import { useAppSelector } from 'src/stores/store';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';

const { Option } = Select;

interface IProps {
  editWorksheetPromiseModalVisible: boolean;
  setEditWorksheetPromiseModalVisible: any;
  // setUpdateStatus: any;
  worksheetHeadData: WorksheetHeadData;
  setWorksheetHeadData: any;
  tUserHeadUpdate: TUserHead;
}

const EditWorksheetPromiseModal: React.FC<IProps> = (props) => {
  const { editWorksheetPromiseModalVisible, setEditWorksheetPromiseModalVisible, worksheetHeadData, setWorksheetHeadData, tUserHeadUpdate } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [employees, setEmployees] = useState<TEmployee2View[]>([]);//ผู้แจ้ง
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  useEffect(() => {
    //Load ครั้งแรก ดึงค่ามาโชว์
    form.setFieldsValue({
      promissNumber: (worksheetHeadData.twshPromiss) ? worksheetHeadData.twshPromiss?.promissnumber : "",
    });
    return () => { };
  }, []);

  const handleSearchEmployee = async (data: any) => {
    if (data.length < 3) return;
    const res = await employeeService.getEmployee2ByParam(data);
    setEmployees(res.data);
  };

  const onFinish = async (values: any) => {
    const employeeArr = employees as Array<TEmployee2View>;
    const empSelected = employeeArr.find((obj) => obj.id === values.userInfoId);

    try {
      const temp = {
        ...(worksheetHeadData as WorksheetHeadData),
        twshPromiss: {
          ...(worksheetHeadData.twshPromiss as TWshPromiss),
          promissnumber: values.promissNumber,
          instalment: worksheetHeadData.twshPromiss ? worksheetHeadData.twshPromiss.instalment : "",
          savstation: userInfo.saveStation,
          lastuser: tUserHeadUpdate.iuserId,
          termno: userInfo.termNo,
        },
        updateUser: tUserHeadUpdate,
      };
      setWorksheetHeadData(temp);
      // console.log("**** promise = ", temp)
      await worksheetService.updateWorksheetWithSubMenuKey(temp, values.userRemark, empSelected ? empSelected.empname : "", "MENU_WORKSHEET_PROMISE"); //467
      showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเรียบร้อย");
      setEditWorksheetPromiseModalVisible(false);
      // setUpdateStatus(true);
      form.resetFields();
      setTrigger(false);
    } catch (err) {
      showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
      setTrigger(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาตรวจสอบข้อมูลให้ครบถ้วน!");
    setTrigger(false);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>แก้ไขเลขที่สัญญา</Typography.Title>
          </div>
        }
        visible={editWorksheetPromiseModalVisible}
        width={"50%"}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setEditWorksheetPromiseModalVisible(false)}
      >
        <Form
          form={form}
          name="promise"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="เลขที่สัญญา"
            name="promissNumber"
            rules={[{ required: true, message: "กรุณาระบุ เลขที่สัญญา" }]}
          >
            <Input
              style={{ width: 200 }}
            ></Input>
          </Form.Item>

          <Form.Item
            label="ผู้แจ้ง"
            name="userInfoId"
            rules={[{ required: true, message: "กรุณาระบุ ผู้แจ้ง" }]}
          >
            <Select
              showSearch
              allowClear
              size="middle"
              placeholder="เลือกผู้แจ้ง"
              optionFilterProp="children"
              filterOption={true}
              onSearch={handleSearchEmployee}

            >
              {employees.map((obj) => {
                return (
                  <Option key={obj.id} value={obj.id}>
                    {obj.empname}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label="หมายเหตุ"
            name="userRemark"
            validateTrigger="onBlur"
            rules={[{ required: true, message: "กรุณาระบุเหตุผล" },
            {
              validator: (_, value) => {
                if (value && value.length <= 10) {
                  return Promise.reject(showNotification(NotificationType.ERROR, "กรุณาระบุเหตุผล ให้มากกว่า 10 ตัวอักษร"))
                } else {
                  return Promise.resolve()
                }
              },
            },
            ]}
          >
            <TextArea
              rows={4}
            ></TextArea>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
};

export default EditWorksheetPromiseModal;