import { CheckOutlined, DeleteOutlined, ExclamationCircleOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Button, Descriptions, Divider, Form, Modal, Space, Spin, Table, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { TAssetHead } from "src/domain/master/tAssetHead";
import { TWorksheetSteelHeadView } from "src/domain/worksheet/view/tWorksheetSteelHeadView";
import PageHeader from "src/layouts/PageHeader";
import { useAppSelector } from "src/stores/store";
import { NotificationType, showNotification } from "src/utils/notificationUtils";
import { formatNumber } from "src/utils/numberUtils";
import tWorksheetSteelDetailService from "../../services/tWorksheetSteelDetailService";
import tWorksheetSteelHeadService from "../../services/tWorksheetSteelHeadService";
import { toThDate } from "../../utils/dateTimeUtils";
import SteelDischargeAssetModal from "./SteelDischargeAssetModal";

const { Text, Title } = Typography;

const { confirm } = Modal;

interface IParams {
    ireserveCode: string;
}

const ViewSteelDischarge: React.FC<RouteComponentProps<IParams>> = (props) => {
    const ireserveCode = props.match.params.ireserveCode;
    const [page, setPage] = React.useState(1);
    const history = useHistory();
    const [form] = Form.useForm();
    const [showSpin, setShowSpin] = useState<boolean>(false);
    const [title, setTitle] = useState<string>("รายละเอียดใบงานแผ่นเหล็ก");
    const [tWorksheetSteelHeadView, setTWorksheetSteelHeadView] = useState<TWorksheetSteelHeadView>({} as TWorksheetSteelHeadView);
    const [tAssetHeads, setTAssetHeads] = useState<TAssetHead[]>([]);
    const [visibleSteelDischargeAssetModal, setVisibleSteelDischargeAssetModal] = React.useState(false);
    const userInfo = useAppSelector((state) => state.authen.authenUser.user);

    const columns_view = [
        {
            title: "ลำดับ",
            key: "index",
            width: 20,
            render: (value: number, item: TAssetHead, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
        },
        {
            title: "รหัสสินทรัพย์",
            dataIndex: "id",
            key: "id",
            width: 150,
        },
        {
            title: "เบอร์",
            dataIndex: "numbers",
            key: "numbers",
            width: 80
        },
        {
            title: "ชื่อสินทรัพย์",
            dataIndex: "assetname",
            key: "assetname"
        },
        {
            title: "รายละเอียด",
            dataIndex: "assetdescription",
            key: "assetdescription",
        }
    ];

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            width: 20,
            render: (value: number, item: TAssetHead, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
        },
        {
            title: "รหัสสินทรัพย์",
            dataIndex: "id",
            key: "id",
            width: 150,
        },
        {
            title: "เบอร์",
            dataIndex: "numbers",
            key: "numbers",
            width: 80
        },
        {
            title: "ชื่อสินทรัพย์",
            dataIndex: "assetname",
            key: "assetname"
        },
        {
            title: "รายละเอียด",
            dataIndex: "assetdescription",
            key: "assetdescription",
        },
        {
            title: "ลบ",
            dataIndex: "delete",
            key: "delete",
            render: (value: any, item: TAssetHead) => (<Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={() => onDeleteDischarge(item)}>
            </Button>)
        },
    ];


    useEffect(() => {
        initial();
    }, []);

    const initial = async () => {
        setShowSpin(true);
        const tworksheetSteelRes = await tWorksheetSteelHeadService.getWorksheetSteel(userInfo.branchId, ireserveCode);
        const tWorksheetSteelDetailTAssetHeadRes = await tWorksheetSteelDetailService.getSteelDetailTAssetHeadsByCode(ireserveCode);

        setTWorksheetSteelHeadView(tworksheetSteelRes.data);
        setTAssetHeads(tWorksheetSteelDetailTAssetHeadRes.data);
        if ((tworksheetSteelRes.data.iworksheetStatus !== "02")) {
            setTitle("ใบงานที่ค้างรอปล่อยแผ่นเหล็ก");
        }
        setShowSpin(false);
    };

    const onAddSteelDischarge = () => {
        setVisibleSteelDischargeAssetModal(true);
    }

    const onDeleteDischarge = (item: TAssetHead) => {
        setTAssetHeads(tAssetHeads.filter((itemItem) => itemItem.id !== item.id));
    }

    const onSubmitDischarge = async () => {
        try {
            setShowSpin(true);
            await tWorksheetSteelHeadService.steelDischargeWorksheets(tWorksheetSteelHeadView.ireserveCode!, tAssetHeads);
            showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลปล่อยแผ่นเหล็กเรียบร้อยแล้ว!");
            form.resetFields();
            initial();
            setShowSpin(false);
        } catch (err) {
            showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        }
    }

    const onConfirmDischarge = () => {
        if (tAssetHeads.length !== Number(tWorksheetSteelHeadView.steelNumSteel)) {
            const message = "จำนวนแผ่นเหล็กค้างปล่อย: " + (Math.abs(Number(tWorksheetSteelHeadView.steelNumSteel) - tAssetHeads.length)) + " และ จำนวนแผ่นเหล็กถูกปล่อย: " + tAssetHeads.length;
            showNotification(NotificationType.ERROR, message);
            return;
        } else {
            confirm({
                title: "ยืนยัน!",
                icon: <ExclamationCircleOutlined />,
                content: "คุณต้องการที่จะบันทึกข้อมูลหรือไม่?",
                cancelText: "ยกเลิก",
                okText: "ยืนยัน",
                onOk() {
                    onSubmitDischarge();
                }
            });
        }
    }

    return (
        <>
            <PageHeader title={title} onClose={() => history.push("/app/steelDischarge/list")}>
                {(tWorksheetSteelHeadView.iworksheetStatus === "01" || tWorksheetSteelHeadView.iworksheetStatus === "04") && <Button
                    size="small"
                    type="primary"
                    disabled={tAssetHeads.length === 0}
                    icon={<CheckOutlined />}
                    onClick={onConfirmDischarge}
                >
                    ตกลงรอปล่อย
                </Button>}
            </PageHeader>
            <Spin spinning={showSpin}>
                <Content className="app-page-content">
                    <div style={{ display: "flex", justifyContent: "flex-start", margin: "1em" }}>
                        <Descriptions
                            column={2}
                            size="middle"
                            bordered
                            labelStyle={{ textAlign: "right", fontWeight: "bold" }}
                        >
                            <Descriptions.Item label="เลขที่ใบงาน" span={2}>
                                {tWorksheetSteelHeadView.iworksheetCode}
                            </Descriptions.Item>
                            <Descriptions.Item label="ชื่อลูกค้า" span={2}>
                                ({tWorksheetSteelHeadView.icustCode}) {tWorksheetSteelHeadView.icustName}
                            </Descriptions.Item>
                            <Descriptions.Item label="รายการบริการ" span={2}>
                                {tWorksheetSteelHeadView.steelServiceName}
                            </Descriptions.Item>
                            <Descriptions.Item label="วันที่ทำงาน" span={2}>
                                {toThDate(tWorksheetSteelHeadView.steelStartDate)} - {toThDate(tWorksheetSteelHeadView.steelEndDate)}
                            </Descriptions.Item>
                            <Descriptions.Item label="จำนวนแผ่นเหล็ก" span={2}>
                                {tWorksheetSteelHeadView.steelNumSteel}
                            </Descriptions.Item>
                            <Descriptions.Item label="จำนวนวัน" span={2}>
                                {tWorksheetSteelHeadView.steelNumDate}
                            </Descriptions.Item>
                            <Descriptions.Item label="ค่าบริการ" span={2}>
                                {formatNumber(tWorksheetSteelHeadView.iserviceAmt)}
                            </Descriptions.Item>
                            <Descriptions.Item label="ค่าบริการพิเศษ" span={2}>
                                {formatNumber(tWorksheetSteelHeadView.iextraAmt)}
                            </Descriptions.Item>
                            <Descriptions.Item label="ส่วนลด" span={2}>
                                {formatNumber(tWorksheetSteelHeadView.idiscount)}
                            </Descriptions.Item>
                            <Descriptions.Item label="ภาษีมูลค่าเพิ่ม" span={2}>
                                {formatNumber(tWorksheetSteelHeadView.itaxAmt)}
                            </Descriptions.Item>
                            <Descriptions.Item label="รวมค่าบริการ" span={2}>
                                {formatNumber(tWorksheetSteelHeadView.itotal2Amt)}
                            </Descriptions.Item>
                            <Descriptions.Item label="ค่าประกัน" span={2}>
                                {formatNumber(tWorksheetSteelHeadView.iinsuranceAmt)}
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                </Content>
                <Divider />

                <br />
                <Content className="app-page-content">

                    <div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                margin: "1em",
                                paddingLeft: "1.5em",
                                borderBlockEnd: "2px solid #c9c9c9",
                            }}
                        >
                            {(tWorksheetSteelHeadView.iworksheetStatus === "01" || tWorksheetSteelHeadView.iworksheetStatus === "04") ? <Button
                                size="small"
                                type="primary"
                                icon={<PlusSquareOutlined />}
                                onClick={onAddSteelDischarge}
                            >
                                เพิ่ม
                            </Button> : <Text></Text>}

                            <Space direction="horizontal" size={"small"}>
                                <Space direction="horizontal" size={"small"}>
                                    <Title level={4}>จำนวนแผ่นเหล็กค้างปล่อย:</Title>
                                    <Title type="danger" level={4}>{(Math.abs(Number(tWorksheetSteelHeadView.steelNumSteel) - tAssetHeads.length))}</Title>
                                    <Title level={4}> รายการ</Title>
                                </Space>
                                <Space direction="horizontal" size={"small"}>
                                    <Title level={4}>จำนวนแผ่นเหล็กถูกปล่อย:</Title>
                                    <Title type="danger" level={4}>{tAssetHeads.length}</Title>
                                    <Title level={4}> รายการ</Title>
                                </Space>
                            </Space>
                        </div>
                        <Table
                            columns={tWorksheetSteelHeadView.steelId ? columns_view : columns}
                            dataSource={tAssetHeads}
                            size="small"
                            pagination={{
                                pageSize: 10,
                                onChange(current) {
                                    setPage(current);
                                }
                            }}
                            rowKey={(item: TAssetHead) => item.id}
                        />
                    </div>
                </Content>
            </Spin>
            <div> {
                visibleSteelDischargeAssetModal && <SteelDischargeAssetModal
                    selectedTAssetHeads={tAssetHeads}
                    setSelectedTAssetHeads={setTAssetHeads}
                    visibleSteelDischargeAssetModal={visibleSteelDischargeAssetModal}
                    setVisibleSteelDischargeAssetModal={setVisibleSteelDischargeAssetModal} />
            }</div>
        </>
    );
};

export default ViewSteelDischarge;
