import { EditOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Button, Descriptions, Divider, Table, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import PageHeader from "src/layouts/PageHeader";
import { TReference } from "../../domain/master/tReference";
import { TReferenceDetail } from "../../domain/master/tReferenceDetail";
import tReferenceDetailService from "../../services/reference/tReferenceDetailService";
import tReferenceService from "../../services/reference/tReferenceService";
import { getReferenceCodeTitile, isReference, ReferenceType, showReferenceCode } from "../../utils/referenceUtils";
import AddEditReferenceDetailModal from "./AddEditReferenceDetailModal";
import AddEditReferenceModal from "./AddEditReferenceModal";

const { Text } = Typography;

interface IParams {
    id: string;
}

const ViewReference: React.FC<RouteComponentProps<IParams>> = (props) => {
    const id: string = props.match.params.id;
    const [reference, setReference] = useState<TReference>({} as TReference);
    const [tReferenceDetails, setReferenceDetails] = useState<TReferenceDetail[]>([]);
    const [page, setPage] = React.useState(1);
    const history = useHistory();
    const [visibleReferenceModal, setVisibleReferenceModal] = useState<boolean>(false);

    const [selectedId, setSelectedId] = useState("");
    const [codeTitle, setCodeTitle] = useState("รหัส");
    const [showCode, setShowCode] = useState(true);
    const [visibleReferenceDetailModal, setVisibleReferenceDetailModal] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            if (!visibleReferenceModal || !visibleReferenceDetailModal) {
                const res = await tReferenceService.getTReferenceById(Number(id));
                setReference(res.data);
            }
        })();
    }, [visibleReferenceModal, visibleReferenceDetailModal]);

    useEffect(() => {
        (async () => {
            if (reference.code) {
                const res = await tReferenceDetailService.getTReferenceDetailByCode(reference.code);
                setReferenceDetails(res.data);
            }
        })();
        setCodeTitle(getReferenceCodeTitile(reference));
        setShowCode(showReferenceCode(reference))
    }, [reference]);

    const columnKeyAndCodes = [
        {
            title: "ลำดับ",
            key: "index",
            width: 100,
            render: (value: number, item: TReferenceDetail, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
        },
        {
            title: "รหัส",
            dataIndex: "id",
            key: "id",
            width: 100,
            render: (value: string, item: TReferenceDetail) => (
                <Button style={{ padding: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.id}</Button>
            )
        },
        {
            title: codeTitle,
            dataIndex: "code",
            key: "code",
            width: 100,
        },
        {
            title: "ชื่อ",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "หมายเหตุ",
            dataIndex: "value1",
            key: "value1",
        },
    ];

    const columnCodes = [
        {
            title: "ลำดับ",
            key: "index",
            width: 100,
            render: (value: number, item: TReferenceDetail, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
        },
        {
            title: codeTitle,
            dataIndex: "code",
            key: "code",
            width: 100,
            render: (value: string, item: TReferenceDetail) => (
                <Button style={{ padding: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.code}</Button>
            )
        },
        {
            title: "ชื่อ",
            dataIndex: "name",
            key: "name",
        },
        {
            title: isReference(reference) ? "Value 1" : "หมายเหตุ",
            dataIndex: "value1",
            key: "value1",
        },
    ];

    const columnNoCodes = [
        {
            title: "ลำดับ",
            key: "index",
            width: 100,
            render: (value: number, item: TReferenceDetail, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
        },
        {
            title: "รหัส",
            dataIndex: "id",
            key: "id",
            width: 100,
            render: (value: string, item: TReferenceDetail) => (
                <Button style={{ padding: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.id}</Button>
            )
        },
        {
            title: "ชื่อ",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "หมายเหตุ",
            dataIndex: "value1",
            key: "value1",
        },
    ];

    function getColumns() {
        if (reference.code === ReferenceType.OWNER_ASSET || reference.code === ReferenceType.BRANCH
            || reference.code === ReferenceType.ASSET_TYPE || reference.code === ReferenceType.ASSIGN_VALUE_TAX_HEAD) {
            return columnKeyAndCodes;
        }

        if (showCode) {
            return columnCodes;
        } else {
            return columnNoCodes;
        }
    }

    function onEditReference() {
        setVisibleReferenceModal(true);
    }

    function onAddReferenceDetail() {
        setSelectedId("");
        setVisibleReferenceDetailModal(true);
    }

    function onItemCliked(item: TReferenceDetail) {
        setSelectedId(String(item.id));
        setVisibleReferenceDetailModal(true);
    }

    return (
        <>
            <PageHeader title="" onClose={() => history.push("/app/reference/list")} />
            <div>
                <Content className="app-page-content">
                    <Descriptions
                        style={{ margin: "1em" }}
                        column={2}
                        title="ข้อมูลอ้าอิง"
                        size="middle"
                        extra={
                            <Button
                                size="middle"
                                type="primary"
                                icon={<EditOutlined />}
                                onClick={() => onEditReference()}
                            >แก้ไข</Button>
                        }
                    />
                    <div style={{ display: "flex", justifyContent: "flex-start", margin: "1em" }}>
                        <Descriptions
                            column={2}
                            size="middle"
                            bordered
                            labelStyle={{ textAlign: "right", fontWeight: "bold" }}
                        >
                            <Descriptions.Item label="รหัส" span={2}> {reference.code}</Descriptions.Item>
                            <Descriptions.Item label="ชื่อ" span={2}>{reference.name}</Descriptions.Item>
                        </Descriptions>
                    </div>
                </Content>
                <Divider />

                <br />
                <Content className="app-page-content">
                    <div style={{ padding: "0px 20px" }}>
                        <div style={{ marginBottom: 16 }}>
                            <Button
                                size="small"
                                type="primary"
                                icon={<PlusSquareOutlined />}
                                onClick={onAddReferenceDetail}
                            >
                                เพิ่ม
                            </Button>
                        </div>
                        <Table
                            style={{ borderBlockStart: "2px solid #c9c9c9" }}
                            columns={getColumns()}
                            dataSource={tReferenceDetails}
                            size="small"
                            pagination={{
                                pageSize: 15,
                                onChange(current) {
                                    setPage(current);
                                }
                            }}
                            rowKey={(item: TReferenceDetail) => item.id}
                            onRow={(item: TReferenceDetail) => {
                                return {
                                    onDoubleClick: () => onItemCliked(item),
                                };
                            }}
                        />
                    </div>
                </Content>
            </div>
            <div> {
                visibleReferenceModal && <AddEditReferenceModal
                    id={id}
                    visibleReferenceModal={visibleReferenceModal}
                    setVisibleReferenceModal={setVisibleReferenceModal} />
            }
            </div>
            <div> {
                visibleReferenceDetailModal && <AddEditReferenceDetailModal
                    id={selectedId}
                    reference={reference}
                    visibleReferenceDetailModal={visibleReferenceDetailModal}
                    setVisibleReferenceDetailModal={setVisibleReferenceDetailModal} />
            }
            </div>
        </>
    );
}
    ;

export default ViewReference;
