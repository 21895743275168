import http from "src/lib/http";
import Constants from "src/utils/constants";

const getRyTruckwheelList = () => {
  return http.get(
    Constants.DEFAULT_URL + "/ryTruckwheel"
  );
};

const getRyTruckwheel = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/ryTruckwheel/" + id);
}

const defaultApi = {
  getRyTruckwheel,
  getRyTruckwheelList,
};
export default defaultApi;
