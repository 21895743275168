import http from "src/lib/http";
import Constants from "src/utils/constants";

const getTTypeotherpaymentRefList = () => {
  return http.get(
    Constants.DEFAULT_URL + "/tTypeotherpaymentRef"
  );
};

const getTTypeotherpaymentRef = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/tTypeotherpaymentRef/" + id);
}

const defaultApi = {
  getTTypeotherpaymentRef,
  getTTypeotherpaymentRefList,
};
export default defaultApi;
