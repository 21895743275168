import http from "../lib/http";
import Constants from "../utils/constants";

const getTConfirmreserveHeadByEntryDateAndBranchId = (branchId: string, fromDate: string, toDate: string) => {
  return http.get(Constants.DEFAULT_URL + "/tConfirmreserveHead/getConfirmReserveByBranchIdAndEntryDate", {
    params: {
      branchId,
      fromDate,
      toDate
    }
  });
};

const confirmReserveWorksheet = (reserveCode: string) => {
  return http.post(Constants.DEFAULT_URL + "/tConfirmreserveHead/confirmReserveWorksheet/" + reserveCode);
};

const defaultApi = {
  getTConfirmreserveHeadByEntryDateAndBranchId,
  confirmReserveWorksheet,
};
export default defaultApi;
