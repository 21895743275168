import { Col, Form, Input, Modal, Row, Select, Space, Spin, Typography } from "antd";
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { BsAmphurH } from "src/domain/bsAmphurH";
import { BsPostcodeH } from "src/domain/bsPostcodeH";
import { BsProvinceH } from "src/domain/bsProvinceH";
import { TCustomerHead } from "src/domain/master/tCustomerHead";
import { RyReftypecustomer } from "src/domain/ryReftypecustomer";
import { TPrenameRef } from "src/domain/tPrenameRef";
import { TTypepaymentRef } from "src/domain/tTypepaymentRef";
import tCustomerHeadService from "src/services/customer/tCustomerHeadService";
import bsAmphurHService from "src/services/province/bsAmphurHService";
import bsPostalcodeHService from "src/services/province/bsPostalcodeHService";
import bsProvinceHService from "src/services/province/bsProvinceHService";
import ryReftypecustomerService from "src/services/reference/ryReftypecustomerService";
import tPrenameRefService from "src/services/reference/tPrenameRefService";
import tTypepaymentRefService from "src/services/reference/tTypepaymentRefService";
import { useAppSelector } from "src/stores/store";
import { NotificationType, showNotification } from "src/utils/notificationUtils";
import { parserNumber } from "src/utils/numberUtils";

const { Option } = Select;

interface IProps {
  visibleModalAddCust: boolean;
  setVisibleModalAddCust: any;
  setNewCustomer?: any;
}
const AddCustomerModal: React.FC<IProps> = (props) => {
  const { visibleModalAddCust, setVisibleModalAddCust, setNewCustomer } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [showSpin, setShowSpin] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [prenameList, setPrenameList] = useState<TPrenameRef[]>([]); //TPrenameRef
  const [amphurList, setAmphurList] = useState<BsAmphurH[]>([]);//BsAmphurH
  const [provinceList, setProvinceList] = useState<BsProvinceH[]>([]);//BsProvinceH
  const [postCodeList, setPostCodeList] = useState<BsPostcodeH[]>([]);//BsPostcodeH
  const [typeCustList, setTypeCustList] = useState<RyReftypecustomer[]>([]);//RyReftypecustomer
  const [typePaymentList, setTypePaymentList] = useState<TTypepaymentRef[]>([]);//TTypepaymentRef
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  useEffect(() => {
    setShowSpin(true);
    loadData();
    return () => { };
  }, []);

  const loadData = async () => {
    if (visibleModalAddCust) {
      const prenameRes = await tPrenameRefService.getTPrenameRefList();
      const bsProvinceHRes = await bsProvinceHService.getBsProvinceHList();
      const bsAmphurHRes = await bsAmphurHService.getBsAmphurHs();
      const bsPostcodeHRes = await bsPostalcodeHService.getBsPostcodeHList();
      const ryReftypecustomerRes = await ryReftypecustomerService.getRyReftypecustomerList();
      const tTypepaymentRefRes = await tTypepaymentRefService.getTTypepaymentRefList();
      const customerCodeRest = await tCustomerHeadService.getNextCustomerHeadCode();
      setPrenameList(prenameRes.data);
      setProvinceList(bsProvinceHRes.data);
      setAmphurList(bsAmphurHRes.data);
      setPostCodeList(bsPostcodeHRes.data);
      setTypeCustList(ryReftypecustomerRes.data);
      setTypePaymentList(tTypepaymentRefRes.data);

      form.setFieldsValue({
        icustCode: customerCodeRest.data,
      })

      setShowSpin(false);
    }
  };

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  const handleSearchAmphur = async (data: any) => {
    const iprovinceId = form.getFieldValue("iprovincecode");
    if (data.length < 3) return;
    if (!iprovinceId) {
      const res = await bsAmphurHService.getBsAmphurHByName(data);
      setAmphurList(res.data);
    } else {
      const res = await bsAmphurHService.getBsAmphurHByNameAndProvinceId(data, iprovinceId);
      setAmphurList(res.data);
    }
  };
  
  const handleSelectAmphur = async (amphurId: any) => {    
    const foundAmphur =  (amphurList as Array<BsAmphurH>).find((obj) => obj.iid === amphurId);
    const res = await bsPostalcodeHService.getBsPostcodeHByNumberAndProvinceId("", foundAmphur?.iprovinceId!);
    setPostCodeList(res.data);
    form.setFieldsValue({
      iprovincecode: foundAmphur?.iprovinceId,
      ipostcode: undefined
    });
  }
  
  const handleSelectProvince = async (provinceId: any) => {
    const bsAmphurHRes = await bsAmphurHService.getBsAmphurHByProviceId(provinceId);
    setAmphurList(bsAmphurHRes.data);    
    const res = await bsPostalcodeHService.getBsPostcodeHByNumberAndProvinceId("", provinceId);
    setPostCodeList(res.data);

    form.setFieldsValue({
      iamphurcode: undefined,
      ipostcode: undefined
    });
  }

  const handleSearchPostalCode = async (searchText: any) => {
    const iprovinceId = form.getFieldValue("iprovincecode");
    if (searchText.length < 3) return;

    if(!iprovinceId) {
      const res = await bsPostalcodeHService.getBsPostcodeHs(searchText);
      setPostCodeList(res.data);
    } else {
      const res = await bsPostalcodeHService.getBsPostcodeHByNumberAndProvinceId(searchText, iprovinceId);
      setPostCodeList(res.data);
    }
  };
  
  const handleSelectPostCode = async (ipostcodeNo: any) => {    
    const bsPostCodeRes = await bsPostalcodeHService.getBsPostcodeHs(ipostcodeNo);
    const bsProvinceRes = await bsProvinceHService.getBsProvinceHById(bsPostCodeRes.data[0].iprovinceId);
    const bsAmphurHRes = await bsAmphurHService.getBsAmphurHByProviceId(bsProvinceRes.data.iid);
    setAmphurList(bsAmphurHRes.data);
    form.setFieldsValue({
      iprovincecode: bsProvinceRes.data.iid,      
    });
  }

  const checkSave = (values: any) => {
    let isValid = true;

    if (values.iname.trim() === "" || values.iname.trim() === "-") {
      showNotification(NotificationType.ERROR, "กรุณากรอกข้อมูลชื่อลูกค้าให้ถูกต้อง!");
      isValid = false;
      setTrigger(false);
    } else if ((values.iprenameCode === "09") && (values.isurname.trim() === "" || values.isurname.trim() === "-" || values.isurname.trim().length < 4)) {
      showNotification(NotificationType.ERROR, "กรุณากรอกข้อมูลนามสกุลลูกค้าให้ถูกต้อง !");
      isValid = false;
      setTrigger(false);
    } else if (values.iaddress.trim() === "" || values.iaddress.trim() === "-" || values.isurname.trim().length < 5) {
      showNotification(NotificationType.ERROR, "กรุณาระบุที่อยู่ของลูกค้าก่อน !");
      isValid = false;
      setTrigger(false);
    } else if (values.iphone.trim('-') === "" || values.iphone.trim('-') === "-") {
      showNotification(NotificationType.ERROR, "กรุณาระบุหมายเลขโทรศัพท์ให้ถูกต้อง !");
      isValid = false;
      setTrigger(false);
    }
    return isValid;
  }

  const onFinish = async (values: any) => {
    if (!checkSave(values)) return;

    let tcustHead = {} as TCustomerHead;
    const temp = {
      ...(tcustHead as TCustomerHead),
      icustCode: values.icustCode, //generate at api
      iprenameCode: values.iprenameCode,
      iname: values.iname,
      isurname: values.isurname,
      iphone: values.iphone,
      imobile: values.imobile,
      ifax: values.ifax,
      iaddress: values.iaddress,
      iprovincecode: values.iprovincecode,
      iamphurcode: values.iamphurcode,
      ipostcode: values.ipostcode,
      icontractName: values.icontractName,
      itypecustomerCode: "00",
      itypepaymentCode: values.itypepaymentCode,
      idebitorCode: "",
      iemail: values.iemail,
      idltFlg: 0,
      icreateUser: userInfo.userId,
      ilastUser: userInfo.userId,
      itermNo: userInfo.termNo,
      isavStation: userInfo.saveStation,
      phonecontinue: values.phonecontinue,
      tin: "",
      iremark: "",
      idiscount: parserNumber(values.idiscount),
      iidCard: values.iidCard ? values.iidCard.replaceAll("-", "") : "",
    }

    tcustHead = temp;
    try {
      const customerHeadRes = await tCustomerHeadService.postCustomerHead(tcustHead);
      if (setNewCustomer) {
        setNewCustomer(customerHeadRes.data);
      }
      showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลลูกค้าเรียบร้อยแล้ว!");
      setVisibleModalAddCust(false);
      setTrigger(false);
    } catch (err) {
      showNotification(NotificationType.ERROR, "เกิดข้อผิดพลาด กรุณาติดต่อผู้แลระบบ !");
      setTrigger(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน !");
    setTrigger(false);
  };

  return (
    <>
      <Modal
        title="เพิ่มรายการลูกค้า"
        visible={visibleModalAddCust}
        width={"60%"}
        bodyStyle={{ minHeight: "400px" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setVisibleModalAddCust(false)}
      >
        <Spin spinning={showSpin}>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            {/* <Col span={24}> */}
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>รหัสลูกค้า: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="icustCode"
                  style={{ marginBottom: "3px" }}>
                  <Input
                    readOnly={true}
                    style={{ backgroundColor: "#F8FC9D" }}
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เลขที่บัญชีลูกหนี้: </Typography.Text>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="idebitorCode"
                  style={{ marginBottom: "3px" }}>
                  <Input
                    readOnly={true}
                    style={{ backgroundColor: "#F8FC9D" }}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>ชื่อ-สกุล: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="iprenameCode"
                  style={{ marginBottom: "3px" }}
                  rules={[{ required: true, message: "กรุณาระบุ คำนำหน้าชื่อ !" }]}>
                  <Select
                    showSearch
                    size="middle"
                    placeholder="เลือกคำนำหน้าชื่อ"
                    optionFilterProp="children"
                    filterOption={true}
                  >
                    {prenameList.map((obj) => {
                      return (
                        <Option key={obj.iprenameCode} value={obj.iprenameCode}>{obj.iprenameName}</Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="iname"
                  style={{ margin: "0 3px 0 3px" }}
                  rules={[{ required: true, message: "กรุณาระบุ ชื่อ !" }]}>
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="isurname"
                  style={{ marginBottom: "3px" }}
                  rules={[{ required: false }]}>
                  <Input></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เลขที่บัตรประชาชน: </Typography.Text>
              </Col>
              <Col span={20}>
                <Form.Item
                  name="iidCard"
                  style={{ marginBottom: "3px" }}>
                  <Input></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>ทีอยู่/ที่ตั้ง: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={20}>
                <Form.Item
                  name="iaddress"
                  style={{ marginBottom: "3px" }}
                  rules={[{ required: true, message: "กรุณาระบุ ที่อยู่/ที่ตั้ง !" }]}>
                  <TextArea
                    rows={3}
                  ></TextArea>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>อำเภอ: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="iamphurcode"
                  style={{ marginBottom: "3px" }}
                  rules={[{ required: true, message: "กรุณาระบุ อำเภอ !" }]}>
                  <Select
                    showSearch
                    size="middle"
                    placeholder="เลือกอำเภอ"
                    optionFilterProp="children"
                    filterOption={true}
                    onSearch={handleSearchAmphur}
                    onSelect={handleSelectAmphur}
                  >
                    {amphurList.map((obj) => {
                      return (
                        <Option key={obj.iid} value={obj.iid}>{obj.iamphurName}</Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>จังหวัด: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="iprovincecode"
                  style={{ marginBottom: "3px" }}
                  rules={[{ required: true, message: "กรุณาระบุ จังหวัด !" }]}>
                  <Select
                    showSearch
                    size="middle"
                    placeholder="เลือกจังหวัด"
                    optionFilterProp="children"
                    filterOption={true}
                    onSelect={handleSelectProvince}
                  >
                    {provinceList.map((obj) => {
                      return (
                        <Option key={obj.iid} value={obj.iid}>{obj.iprovinceName}</Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>รหัสไปรษณีย์: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="ipostcode"
                  style={{ marginBottom: "3px" }}
                  rules={[{ required: true, message: "กรุณาระบุ รหัสไปรษณีย์ !" }]}>
                  <Select
                    showSearch
                    size="middle"
                    placeholder="เลือกรหัสไปรษณีย์"
                    optionFilterProp="children"
                    filterOption={true}
                    onSearch={handleSearchPostalCode}
                    onSelect={handleSelectPostCode}
                  >
                    {postCodeList.map((obj) => {
                      return (
                        <Option key={obj.ipostcodeNo} value={obj.ipostcodeNo}>
                          {obj.ipostcodeNo}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>โทร: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="iphone"
                  style={{ marginBottom: "3px" }}
                  rules={[{ required: true, message: "กรุณาระบุ เบอร์โทร !" }]}>
                  <Input maxLength={20}></Input>
                </Form.Item>
              </Col>
              <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ต่อ: </Typography.Text>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="phonecontinue"
                  style={{ marginBottom: "3px" }}
                  rules={[{ required: false }]}>
                  <Input maxLength={5}></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>มือถือ: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="imobile"
                  style={{ marginBottom: "3px" }}
                  rules={[{ required: false }]}>
                  <Input maxLength={20}></Input>
                </Form.Item>
              </Col>
              <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>Fax: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="ifax"
                  style={{ marginBottom: "3px" }}
                  rules={[{ required: false }]}>
                  <Input maxLength={20}></Input>
                </Form.Item>
              </Col>
              <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>Email: </Typography.Text>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="iemail"
                  style={{ marginBottom: "3px" }}
                  rules={[{ required: false }]}>
                  <Input maxLength={30}></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>การชำระเงิน: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="itypepaymentCode"
                  style={{ marginBottom: "3px" }}
                  rules={[{ required: true, message: "กรุณาระบุ ประเภทการชำระเงิน !" }]}>
                  <Select
                    showSearch
                    size="middle"
                    placeholder="เลือกการชำระเงิน"
                    optionFilterProp="children"
                    filterOption={true}
                  >
                    {typePaymentList.map((obj) => {
                      return (
                        <Option key={obj.iid} value={obj.iid}>{obj.iname}</Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}></Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>บุคคลอ้างอิง: </Typography.Text>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="icontractName"
                  style={{ marginBottom: "3px" }}
                  rules={[{ required: false }]}>
                  <Input maxLength={100}></Input>
                </Form.Item>
              </Col>
              <Col span={12}></Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ส่วนลด: </Typography.Text>
              </Col>
              <Col span={8}>
                <Space>
                  <Form.Item
                    name="idiscount"
                    style={{ marginBottom: "3px" }}
                    rules={[{ required: false }]}>
                    <Input
                      style={{ textAlign: "end" }}
                      onFocus={(e: any) => { e.target.select() }}
                    ></Input>
                  </Form.Item>
                  %
                </Space>
              </Col>
              <Col span={12}></Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default AddCustomerModal;