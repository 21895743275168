import { ClearOutlined, PlusSquareOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TDebitorHeadView } from 'src/domain/finance/view/tDebitorHeadView';
import { RyRefbranch } from 'src/domain/ryRefbranch';
import { TRangradWangbillHeadView } from 'src/domain/view/tRangradWangbillHeadView';
import PageHeader from 'src/layouts/PageHeader';
import ryRefbranchService from 'src/services/reference/ryRefbranchService';
import tRangradWangbillHeadService from 'src/services/tRangradWangbillHeadService';
import { useAppSelector } from 'src/stores/store';
import Constants from 'src/utils/constants';
import { toThDate } from 'src/utils/dateTimeUtils';
import { formatNumber } from 'src/utils/numberUtils';
import LookupTDebitorHeadModal from '../modals/lookups/LookupTDebitorHeadModal';
import TRengRadWangBillDrawer from './TRengRadWangBillDrawer';

const { Option } = Select;

const TRengRadWangBillList = () => {
  const [page, setPage] = React.useState(1);
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [onDrawerVisible, setOnDrawerVisible] = useState<boolean>(false);
  const [lookupDebitorModalVisible, setLookupDebitorModalVisible] = useState<boolean>(false);

  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [trangradWangbillHeadViews, setTrangradWangbillHeadViews] = useState<TRangradWangbillHeadView[]>([]);
  const [ryRefBranchs, setRyRefBranchs] = useState<RyRefbranch[]>([]);
  const [tDebitorHeadSelected, setTDebitorHeadSelected] = useState<TDebitorHeadView>({} as TDebitorHeadView);
  const [ryRefBranchSelected, setRyRefBranchSelected] = useState<RyRefbranch>({} as RyRefbranch);

  const getItemColor = (item: TRangradWangbillHeadView) => {
    let foreColor = "";
    if (item.idebitnotestatus === "98") {
      foreColor = "red";
    } else if (item.idebitnotestatus === "99") {
      foreColor = "blue";
    } else {
      foreColor = "black";
    }
    return 'text-' + foreColor;
  }

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 50,
      render: (value: number, item: TRangradWangbillHeadView, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
    },
    {
      title: "วันที่ครบกำหนดชำระเงิน",
      dataIndex: "iappointmentDate",
      key: "iappointmentDate",
      width: 190,
      render: (value: any, item: TRangradWangbillHeadView) => (<>{toThDate(item.iappointmentDate)}</>)
    },
    {
      title: "เลขที่ใบแจ้งหนี้",
      dataIndex: "idebitnoteNo",
      key: "idebitnoteNo",
      width: 150,
      render: (value: any, item: TRangradWangbillHeadView) => (
        <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.idebitnoteNo}</Button>
      )
    },
    {
      title: "ลูกหนี้",
      dataIndex: "debitorname",
      key: "debitorname",
      width: 250,
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "total",
      key: "total",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TRangradWangbillHeadView) => (<>{formatNumber(item.total)}</>)
    },
    {
      title: "ภาษี 7 %",
      dataIndex: "vat",
      key: "vat",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TRangradWangbillHeadView) => (<>{formatNumber(item.vat)}</>)
    },
    {
      title: "สุทธิ",
      dataIndex: "nettotal",
      key: "nettotal",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TRangradWangbillHeadView) => (<>{formatNumber(item.nettotal)}</>)
    },
    {
      title: "สถานะใบแจ้งหนี้",
      dataIndex: "idebitnotestatusName",
      key: "idebitnotestatusName",
      width: 150,
    },
    {
      title: "หมายเหตุ",
      dataIndex: "remark",
      key: "remark",
      width: 200,
    },
    {
      title: "ผู้บันทึก",
      dataIndex: "icreateUserName",
      key: "icreateUserName",
      width: 150,
    },
  ];

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    initData();
  }, []);

  useEffect(() => {
    if (!lookupDebitorModalVisible && tDebitorHeadSelected.iid) {
      form.setFieldsValue({
        debitorName: tDebitorHeadSelected.iprename.trim() + tDebitorHeadSelected.iname + " " + (tDebitorHeadSelected.isurname ? tDebitorHeadSelected.isurname : ""),
      });
    }
  }, [lookupDebitorModalVisible]);

  const initData = async () => {
    const refbranchRes = await ryRefbranchService.getRyRefbranchList();
    const foundDefaultBanch = (refbranchRes.data as Array<RyRefbranch>).find((obj) => obj.id === userInfo.branchId);

    setRyRefBranchs(refbranchRes.data);
    setRyRefBranchSelected(foundDefaultBanch!);
    setTDebitorHeadSelected({} as TDebitorHeadView);

    form.setFieldsValue({
      fromDate: moment(new Date(), Constants.DATE_FORMAT).startOf('month'),
      toDate: moment(new Date(), Constants.DATE_FORMAT).endOf('month'),
      branch: userInfo?.branchId,
      selectedDateType: "DATE_ENTRY",
      wshCodeDebitnoteCode: undefined,
      debitorName: undefined,

    });
  }

  function onReset() {
    initData();
  }

  const onItemCliked = (item: TRangradWangbillHeadView) => {
    history.replace("/app/rengradWangbill/view/" + item.idebitnoteNo);
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    const branch = values.branch;
    const wshCodeDebitnoteCode = values.wshCodeDebitnoteCode ? values.wshCodeDebitnoteCode : "";
    const debitorId = tDebitorHeadSelected.iid ? tDebitorHeadSelected.iid : "";
    const selectedDateType = values.selectedDateType;
    const fromDate = moment(values.fromDate).format("yyyyMMDD");
    const toDate = moment(values.toDate).format("yyyyMMDD");

    let dateAppointMentStart: string = "";
    let dateAppointMentFinish: string = "";
    let dStartEntry: string = "";
    let dFinishEntry: string = "";

    if ("DATE_ENTRY" === selectedDateType) {
      dStartEntry = fromDate;
      dFinishEntry = toDate;
    } else {
      dateAppointMentStart = fromDate;
      dateAppointMentFinish = toDate;
    }

    const res = await tRangradWangbillHeadService.getTrengradWangbillHeadList(
      branch,
      wshCodeDebitnoteCode,
      debitorId,
      dateAppointMentStart,
      dateAppointMentFinish,
      dStartEntry,
      dFinishEntry);
    //this.PrintDebit();
    setTrangradWangbillHeadViews(res.data);
    setLoading(false);
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  return (
    <>
      <PageHeader title="โปรแกรมเร่งรัดออกใบแจ้งหนี้">
        <Button
          size="small"
          type="primary"
          icon={<PlusSquareOutlined />}
          onClick={() => setOnDrawerVisible(true)}
        >
          เพิ่ม
        </Button>
      </PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="rengradWangbill"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
            }}
          >
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ประเภทวันที่: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="selectedDateType"
                  rules={[{ required: false }]}
                >
                  <Select
                    showSearch
                    size="middle"
                    placeholder="เลือกประเภทวันที่"
                    optionFilterProp="children"
                  >
                    <Option value="DATE_ENTRY">วันที่สร้าง</Option>
                    <Option value="DATE_APPOINTMENT">วันที่ครบกำหนดชำระ</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เลขที่ใบงาน/ใบเจ้งหนี้: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="wshCodeDebitnoteCode"
                  rules={[{ required: false }]}
                >
                  <Input
                    allowClear
                    placeholder="ป้อนเลขที่ใบงาน/ใบเจ้งหนี้"
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ตั้งแต่: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="fromDate"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    // onChange={form.submit}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>สาขา: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="branch"
                  style={{ marginBottom: "3px" }}
                >
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกสาขา"
                    optionFilterProp="children"
                    filterOption={true}
                    onClear={() => setRyRefBranchSelected({} as RyRefbranch)}
                  >
                    {ryRefBranchs.map((obj) => {
                      return (
                        <Option key={obj.id} value={obj.id}>
                          {obj.id}: {obj.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ถึง: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="toDate"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    // onChange={form.submit}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ลูกหนี้: </Typography.Text>
              </Col>
              <Col span={6}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="debitorName"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input
                      style={{ width: 290, backgroundColor: "#F7F7F8" }} readOnly
                    >
                    </Input>
                  </Form.Item>
                  <Button
                    id='btnDebitor'
                    onClick={() => setLookupDebitorModalVisible(true)}
                    type="primary"
                    icon={<SearchOutlined />}
                  >
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: 'center',
              paddingBottom: "1em",
              borderBlockEnd: "2px solid #c9c9c9",
            }}
          >
            <Space>
              <Button
                size="small"
                type="primary"
                icon={<SearchOutlined />}
                onClick={form.submit}
              >
                ค้นหา
              </Button>
              <Button
                size="small"
                type="primary"
                icon={<ClearOutlined />}
                onClick={onReset}
              >
                ล้าง
              </Button>
            </Space>
          </div>
        </Form>
        <div>
          <Table
            columns={columns}
            dataSource={trangradWangbillHeadViews}
            size="small"
            loading={loading}
            scroll={{ x: 1800 }}
            pagination={{
              pageSize: 15,
              onChange(current) {
                setPage(current);
              }
            }}
            rowClassName={getItemColor}
            rowKey={(item: TRangradWangbillHeadView) => item.idebitnoteNo!}
            onRow={(item: TRangradWangbillHeadView) => {
              return {
                onDoubleClick: () => onItemCliked(item),
              };
            }}
          />
        </div>
      </Content>
      <div>
        {lookupDebitorModalVisible && (
          <LookupTDebitorHeadModal
            lookupDebitorModalVisible={lookupDebitorModalVisible}
            setLookupDebitorModalVisible={setLookupDebitorModalVisible}
            setTDebitorHeadSelected={setTDebitorHeadSelected}
          ></LookupTDebitorHeadModal>
        )}
      </div>
      <div>
        {onDrawerVisible && (
          <TRengRadWangBillDrawer
            onDrawerVisible={onDrawerVisible}
            setOnDrawerVisible={setOnDrawerVisible}
          ></TRengRadWangBillDrawer>
        )}
      </div>
    </>
  )
}

export default TRengRadWangBillList