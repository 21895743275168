export const getFlgIntownDescription = (flgIntown: string) => {
  var description: string;
  switch (flgIntown) {
    case "00":
      description = "นอกเขตอำเภอเมือง";
      break;
    case "01":
      description = "เขตอำเภอเมือง";
      break;
    default:
      description = "";
      break;
  }
  return description;
};

export const getFlgAugerDescription = (flgAuger: string) => {
  var description: string;
  switch (flgAuger) {
    case "00":
      description = "ติดหัวเจาะ";
      break;
    case "01":
      description = "ไม่ติดหัวเจาะ";
      break;
    default:
      description = "";
      break;
  }
  return description;
};

export const isEmpty = (value: any) => {
  return (value === undefined || value === null || value.length <= 0) ? true : false;
}