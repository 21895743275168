import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Button, Col, Descriptions, Image, Row, Table, Tabs, Typography, Upload } from "antd";
import { Content } from "antd/lib/layout/layout";
import confirm from "antd/lib/modal/confirm";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { TAssetBranch } from "src/domain/master/tAssetBranch";
import { TAssetImage } from "src/domain/master/tAssetImage";
import { TAssetBranchView } from "src/domain/master/view/tAssetBranchView";
import { TAssetHeadView } from "src/domain/master/view/tAssetHeadView";
import { RyRefbranch } from "src/domain/ryRefbranch";
import PageHeader from "src/layouts/PageHeader";
import tAssetBranchService from "src/services/tasset/tAssetBranchService";
import tAssetImageService from "src/services/tasset/tAssetImageService";
import { toThDate } from "src/utils/dateTimeUtils";
import { NotificationType, showNotification } from "src/utils/notificationUtils";
import { formatNumber } from "src/utils/numberUtils";
import { getFlg } from "src/utils/rodyokUtils";
import tAssetHeadService from "../../services/tasset/tAssetHeadService";
import LookupRyRefBranchModal from "../modals/lookups/LookupRyRefBranchModal";
import AddEditTAssetHeadTab1Drawer from "./AddEditTAssetHeadTab1Drawer";
import AddEditTAssetHeadTab2Drawer from "./AddEditTAssetHeadTab2Drawer";

interface IParams {
    id: string;
}

const { TabPane } = Tabs;

const ViewTAssetHead: React.FC<RouteComponentProps<IParams>> = (props) => {
    const id = props.match.params.id;
    const [tAssetHead, setTAssetHead] = useState<TAssetHeadView>({} as TAssetHeadView);
    const [tAssetImages, setTAssetImages] = useState<TAssetImage[]>([]);
    const [tAssetBranchViews, setTAssetBranchViews] = useState<TAssetBranchView[]>([]);
    const [ryRefBranchSelected, setRyRefBranchSelected] = useState<RyRefbranch>({} as RyRefbranch);
    const history = useHistory();
    const [visibleAddEditTAssetHeadTab1Drawer, setVisibleAddEditTAssetHeadTab1Drawer] = useState<boolean>(false);
    const [visibleAddEditTAssetHeadTab2Drawer, setVisibleAddEditTAssetHeadTab2Drawer] = useState<boolean>(false);
    const [lookupRyRefBranchModalVisible, setLookupRyRefBranchModalVisible] = useState<boolean>(false);

    const cols: Array<Object> = [];
    const bracnhColumns = [
		{
			title: "ลำดับ",
			dataIndex: "index",
			key: "index",
			align: "center" as "center",
			render: (value: any, item: TAssetBranchView, index: number) => {
				return <span>{index + 1}</span>;
			},
		},
		{
			title: "รหัสสาขา",
			dataIndex: "branch",
			key: "branch",
		},
		{
			title: "ชื่อย่อ",
			dataIndex: "branchCodeName",
			key: "branchCodeName",
		},
		{
			title: "ชื่อสาขา",
			dataIndex: "branchName",
			key: "branchName",
		},
		{
			title: "ลบ",
			key: "action",
			align: "center" as "center",
			render: (value: any, item: TAssetBranchView, index: number) => {
				return (
					<span>
                        <Button danger
                        type="primary"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        size={"small"}
                        onClick={() => onDeleteAssetBranch(item, index)}
                    />
					</span>
				);
			},
		},
	];

    useEffect(() => {
        if (!visibleAddEditTAssetHeadTab1Drawer || !visibleAddEditTAssetHeadTab2Drawer) {
            initial();
        }
    }, [visibleAddEditTAssetHeadTab1Drawer, visibleAddEditTAssetHeadTab2Drawer]);
        
    useEffect(() => {
        (async () => {
            if (!lookupRyRefBranchModalVisible && ryRefBranchSelected.id) {
                if (tAssetBranchViews) {
                    const foudBranch = tAssetBranchViews.find((obj) => obj.branch === ryRefBranchSelected.id);
                    if (foudBranch) {
                        showNotification(NotificationType.ERROR, "มีรายการนี้อยู่แล้ว!");
                        return;
                    } else {
                        try {
                            const temp: TAssetBranch = {
                                seq: 0,
                                assetcode: tAssetHead.id,
                                branch: ryRefBranchSelected.id
                            };
                            await tAssetBranchService.postTAssetBranch(temp);
                            const resBranch = await tAssetBranchService.getTAssetBranchViews(tAssetHead.id);
                            setTAssetBranchViews(resBranch.data);
                            showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเรียบร้อย");
                        } catch (err) {
                            showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
                        }
                    }
                }
            }
        })();
    }, [lookupRyRefBranchModalVisible]);

    useEffect(() => {
        initialtAssetImages();
        initialtAssetBranchViews();
    }, []);

    const initial = async () => {
        const res = await tAssetHeadService.getTAssetHeadViewById(id);
        setTAssetHead(res.data);
    };

    const initialtAssetImages = async () => {
        const res = await tAssetImageService.getTAssetImages(id);
        setTAssetImages(res.data);
    };

    const initialtAssetBranchViews = async () => {
        const res = await tAssetBranchService.getTAssetBranchViews(id);
        setTAssetBranchViews(res.data);
    };

    const onEditTAssetHeadTab1ButtonClicked = () => {
        setVisibleAddEditTAssetHeadTab1Drawer(true);
    }

    const onEditTAssetHeadTab2ButtonClicked = () => {
        setVisibleAddEditTAssetHeadTab2Drawer(true);
    }

    function getBase64(file: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    function beforeUpload(file: any) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            showNotification(NotificationType.ERROR, 'รองรับไฟล์ชนิด JPG/PNG เท่านั้น!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            showNotification(NotificationType.ERROR, 'ขนาดรูปต้องไม่เกิน 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    const onUploadImage = async (options: any) => {
        const { file } = options;
        const base64Str = await getBase64(file)
        const _tAssetImage: TAssetImage = {
            id: 0,
            assetCode: id,
            image1: base64Str,
        }
        try {
            await tAssetImageService.postTAssetImage(_tAssetImage);
            showNotification(NotificationType.SUCCESS, "เพิ่มรูปภาพเรียบร้อยแล้ว!");
            initialtAssetImages();
        } catch (err) {
            showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        }
    };

    const onDeleteImage = (id: number) => {
        confirm({
            title: 'ยืนยันการลบรูป?',
            icon: <ExclamationCircleOutlined />,
            okText: 'ตกลง',
            okType: 'danger',
            cancelText: 'ยกเลิก',
            onOk() {
                (async () => {
                    try {
                        await tAssetImageService.deletetTAssetImage(id);
                        showNotification(NotificationType.SUCCESS, "ลบรูปภาพเรียบร้อยแล้ว!");
                        initialtAssetImages();
                    } catch (err) {
                        showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
                    }
                })()
            },
            onCancel() {
            },
        });
    }

	const onDeleteAssetBranch = async (item: TAssetBranchView, index: number) => {
        confirm({
            title: 'ยืนยันการลบสาขา?',
            icon: <ExclamationCircleOutlined />,
            okText: 'ตกลง',
            okType: 'danger',
            cancelText: 'ยกเลิก',
            onOk() {
                (async () => {
                    try {
                        await tAssetBranchService.deletetTAssetBranch(item.seq);
                        const array = tAssetBranchViews ? [...tAssetBranchViews] : [];
                        array?.splice(index, 1);
                        setTAssetBranchViews(array);
                        showNotification(NotificationType.SUCCESS, "ลบสาขาเรียบร้อยแล้ว!");
                    } catch (err) {
                        showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
                    }
                })()
            },
            onCancel() {
            },
        });
	}

    const handleAddBranch = () => {
        setLookupRyRefBranchModalVisible(true);
    }

    tAssetImages.forEach(tAssetImage => {
        const base64StrDecode = atob(tAssetImage.image1)
        cols.push(<Col span={8} key={tAssetImage.id}>
            <div style={{ padding: "1em" }}>
                <Image width={150} height={150} src={base64StrDecode}
                />
                <div style={{
                    display: "block",
                    position: "absolute",
                    bottom: "21px",
                    right: "28px",
                    width: "40px",
                    height: "40px"
                }}>
                    <Button danger
                        type="primary"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        size={"large"}
                        onClick={() => onDeleteImage(tAssetImage.id)}
                    />
                </div>
            </div>
        </Col>)
    })
    return (
        <>
            <PageHeader title="" onClose={() => history.push("/app/tasset/list")} />
            <Content className="app-page-content">
                <Descriptions
                    style={{ margin: "1em 1em 0 1em" }}
                    title="ข้อมูลสินทรัพย์"
                />
                <Tabs
                    defaultActiveKey="0"
                    style={{ marginLeft: "1em", marginRight: "1em" }}
                >
                    <TabPane tab="รายละเอียดทั่วไป" key="1">
                        <Descriptions
                            extra={
                                <Button
                                    size="middle"
                                    type="primary"
                                    icon={<EditOutlined />}
                                    onClick={onEditTAssetHeadTab1ButtonClicked}
                                >
                                    แก้ไข
                                </Button>
                            }
                        />
                        <div style={{ display: "flex", justifyContent: "flex-start", margin: "1em" }}>
                            <Descriptions
                                column={6}
                                size="middle"
                                bordered
                                labelStyle={{ textAlign: "right", fontWeight: "bold" }}
                            >
                                <Descriptions.Item label="รหัสสินทรัพย์" span={6}>{tAssetHead.id}</Descriptions.Item>
                                <Descriptions.Item label="เบอร์" span={6}>{tAssetHead.numbers}</Descriptions.Item>
                                <Descriptions.Item label="สาขา" span={2}>({tAssetHead.branchcode}) {tAssetHead.branchName}</Descriptions.Item>
                                <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                                <Descriptions.Item label="ประเภทสินทรัพย์" span={2}>({tAssetHead.assettype}) {tAssetHead.assettypeName}</Descriptions.Item>
                                <Descriptions.Item label="ผู้ถือกรรมสิทธิ์" span={2}>({tAssetHead.assetowner}) {tAssetHead.assetownerName}</Descriptions.Item>
                                <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                                <Descriptions.Item label="ยี่ห้อ" span={2}>({tAssetHead.assetbrand}) {tAssetHead.assetbrandName}</Descriptions.Item>
                                <Descriptions.Item label="วันที่ซื้อ" span={6}>{tAssetHead.assetdatebuy ? toThDate(tAssetHead.assetdatebuy) : ""}</Descriptions.Item>
                                <Descriptions.Item label="ราคา" span={2}>{formatNumber(tAssetHead.assetprice)} บาท</Descriptions.Item>
                                <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                                <Descriptions.Item label="น้ำหนัก" span={2}>{formatNumber(tAssetHead.assetweight)} kg.</Descriptions.Item>
                                <Descriptions.Item label="สูง" span={2}>{formatNumber(tAssetHead.assetheight)} cm.</Descriptions.Item>
                                <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                                <Descriptions.Item label="ยาว" span={2}>{formatNumber(tAssetHead.assetlong)} cm.</Descriptions.Item>
                                <Descriptions.Item label="กว้าง" span={2}>{formatNumber(tAssetHead.assetwidth)} cm.</Descriptions.Item>
                                <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                                <Descriptions.Item label="สี" span={2}>{tAssetHead.assetcolor}</Descriptions.Item>
                                <Descriptions.Item label="ชื่อสินทรัพย์" span={6}>{tAssetHead.assetname}</Descriptions.Item>
                                <Descriptions.Item label="รายละเอียด" span={6}>{tAssetHead.assetdescription}</Descriptions.Item>
                                <Descriptions.Item label="สถานะ" span={6}>{getFlg(tAssetHead.assetflgdel) ? <Typography.Text type="success">ใช้งาน</Typography.Text> : <Typography.Text type="danger">ไม่ใช้งาน</Typography.Text>}</Descriptions.Item>
                                <Descriptions.Item label="หมายเหตุ" span={6}>{tAssetHead.assetremark}</Descriptions.Item>
                            </Descriptions>
                        </div>
                    </TabPane>
                    <TabPane tab="รายละเอียดเกี่ยวกับรถ" key="2">
                        <Descriptions
                            extra={
                                <Button
                                    size="middle"
                                    type="primary"
                                    icon={<EditOutlined />}
                                    onClick={onEditTAssetHeadTab2ButtonClicked}
                                >
                                    แก้ไข
                                </Button>
                            }
                        />
                        <div style={{ display: "flex", justifyContent: "flex-start", margin: "1em" }}>
                            <Descriptions
                                column={6}
                                size="middle"
                                bordered
                                labelStyle={{ textAlign: "right", fontWeight: "bold" }}
                            >
                                <Descriptions.Item label="ทะเบียน" span={2}>{tAssetHead.truckregister}</Descriptions.Item>
                                <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                                <Descriptions.Item label="รหัสตรวจสภาพรถ" span={2}>{tAssetHead.truckinspeccode}</Descriptions.Item>
                                <Descriptions.Item label="หมายเลขรุ่น" span={2}>{tAssetHead.truckclassnumber}</Descriptions.Item>
                                <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                                <Descriptions.Item label="เลขไมล์" span={2}>{tAssetHead.truckmile}</Descriptions.Item>
                                <Descriptions.Item label="หมายเลขตัวรถ/โครงคัท" span={2}>{tAssetHead.truckcructnumber}</Descriptions.Item>
                                <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                                <Descriptions.Item label="ตำแหน่ง" span={2}>{tAssetHead.truckpositioncrutnumber}</Descriptions.Item>
                                <Descriptions.Item label="หมายเลขเครื่องยนต์" span={2}>{tAssetHead.truckmotornumber}</Descriptions.Item>
                                <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                                <Descriptions.Item label="ตำแหน่ง" span={2}>{tAssetHead.truckmotorpositionnumber}</Descriptions.Item>
                                <Descriptions.Item label="ขนาดแรงม้า" span={2}>{tAssetHead.truckhostpower} แรงม้า</Descriptions.Item>
                                <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                                <Descriptions.Item label="จำนวนสูบ" span={2}>{tAssetHead.truckpump} สูบ</Descriptions.Item>
                                <Descriptions.Item label="ชนิดรถ" span={6}>({tAssetHead.trucktype}) {tAssetHead.trucktypeName}</Descriptions.Item>
                                <Descriptions.Item label="ประเภทย่อย" span={6}>({tAssetHead.assetsubtype}) {tAssetHead.assetsubtypeName}</Descriptions.Item>
                                <Descriptions.Item label="ขนาด" span={6}>({tAssetHead.trucksizecode}) {tAssetHead.trucksizeName}</Descriptions.Item>
                                <Descriptions.Item label="ประเภทล้อ" span={6}>({tAssetHead.truckwheelcode}) {tAssetHead.truckwheelName}</Descriptions.Item>
                                <Descriptions.Item label="พนักงานขับรถ" span={6}>({tAssetHead.truckdrivercode}) {tAssetHead.truckdriverName}</Descriptions.Item>
                            </Descriptions>
                        </div>
                    </TabPane>
                    <TabPane tab="รูป" key="3">
                        <div style={{ display: "flex", justifyContent: "flex-start", margin: "1em" }}>
                            <div>
                                <Upload
                                    name="tAssetImages"
                                    accept="image/*"
                                    listType="picture-card"
                                    showUploadList={false}
                                    beforeUpload={beforeUpload}
                                    customRequest={onUploadImage}
                                >
                                    <div>
                                        <PlusSquareOutlined />
                                        <div style={{ marginTop: 8 }}>เลือกไฟล์</div>
                                    </div>
                                </Upload>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-start", margin: "1em" }}>
                            <div style={{ width: "50%" }}>
                                <Row gutter={[0, 0]}>
                                    {cols}
                                </Row>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab="กำหนดสาขาให้รถ" key="4">
                        <div>
                            <Button
                                size="small"
                                type="primary"
                                icon={<PlusSquareOutlined />}
                                onClick={() => handleAddBranch()}
                            >
                                เพิ่ม
                            </Button>
                        </div>
                        <div>
                            <Table
                                columns={bracnhColumns}
                                dataSource={tAssetBranchViews}
                                size="small"
                                pagination={false}
                                rowKey={(item: TAssetBranchView) => item.seq}
                            />
                        </div>
                    </TabPane>
                </Tabs>
            </Content>
            <div> {
                visibleAddEditTAssetHeadTab1Drawer && <AddEditTAssetHeadTab1Drawer
                    id={String(tAssetHead.id)}
                    visibleAddEditTAssetHeadTab1Drawer={visibleAddEditTAssetHeadTab1Drawer}
                    setVisibleAddEditTAssetHeadTab1Drawer={setVisibleAddEditTAssetHeadTab1Drawer} />
            }</div>
            <div> {
                visibleAddEditTAssetHeadTab2Drawer && <AddEditTAssetHeadTab2Drawer
                    id={String(tAssetHead.id)}
                    visibleAddEditTAssetHeadTab2Drawer={visibleAddEditTAssetHeadTab2Drawer}
                    setVisibleAddEditTAssetHeadTab2Drawer={setVisibleAddEditTAssetHeadTab2Drawer} />
            }</div>
            <div> {
                lookupRyRefBranchModalVisible && <LookupRyRefBranchModal
                lookupRyRefBranchModalVisible={lookupRyRefBranchModalVisible}
                setLookupRyRefBranchModalVisible={setLookupRyRefBranchModalVisible}
                setRyRefBranchSelected={setRyRefBranchSelected} />
            }</div>
        </>
    );
};

export default ViewTAssetHead;

