import { Col, Descriptions, Form, Radio, Row, Spin } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { ReturnFinancePaymentView } from 'src/domain/view/returnFinancePaymentView';
import PageHeader from 'src/layouts/PageHeader';
import returnFinancePaymentService from 'src/services/returnFinancePaymentService';
import { formatNumber } from 'src/utils/numberUtils';

interface IParams {
  code: string;
}
const ViewReturnFinancePayment: React.FC<RouteComponentProps<IParams>> = (props) => {
  const code = props.match.params.code;
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [returnFinancePaymentView, setReturnFinancePaymentView] = useState<ReturnFinancePaymentView>({} as ReturnFinancePaymentView);

  useEffect(() => {
    setLoading(true);
    initData();
  }, []);

  const initData = async () => {
    const res = await returnFinancePaymentService.findReturnFinancePaymentViews(
      "",
      "",
      "",
      code);
    const temp: ReturnFinancePaymentView = res.data[0];
    setReturnFinancePaymentView(res.data[0]);

    form.setFieldsValue({
      returnType: temp.returnType === "DEBITNOTE" ? "DEBITNOTE_CODE" : "WSH_CODE",
    });

    setLoading(false);
  }

  return (
    <>
      <PageHeader title="" onClose={() => history.push("/app/returnFinancePayment/list")} />
      <Spin spinning={loading}>
        <Content className="app-page-content">
          <Descriptions
            style={{ margin: "1em" }}
            column={2}
            title="ข้อมูลยกเลิกการรับเงิน"
            size="middle"
          />
          <div style={{ margin: "1em" }}>
            <Form
              form={form}
              name="viewReturnFinancePayment"
              labelCol={{ span: 5 }}
            >
              <Row>
                <Col>
                  <Form.Item
                    name="returnType"
                  >
                    <Radio.Group
                      disabled
                      style={{ margin: "0px" }}
                    >
                      <Radio value="WSH_CODE" style={{ backgroundColor: "blue", color: "#FFF", borderRadius: 3 }}>ใบสั่งงาน</Radio>
                      <Radio value="DEBITNOTE_CODE" style={{ backgroundColor: "red", color: "#FFF", borderRadius: 3 }}>ใบแจ้งหนี้</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Descriptions
                column={3}
                size="small"
                bordered
                style={{ paddingLeft: '1em' }}
                labelStyle={{ textAlign: "right", fontWeight: "bold" }}
              >
                <Descriptions.Item label="เลขที่ใบงาน/เลขที่ใบแจ้งหนี้" span={3}>{returnFinancePaymentView.code}</Descriptions.Item>
                <Descriptions.Item label="ชือลูกค้า" span={3}>{returnFinancePaymentView.customerName}</Descriptions.Item>
                <Descriptions.Item label="ค่าบริการ" span={1}>{formatNumber(returnFinancePaymentView.serviceAmount)}</Descriptions.Item>
                <Descriptions.Item label="ภาษี" span={1}>{formatNumber(returnFinancePaymentView.taxAmount)}</Descriptions.Item>
                <Descriptions.Item label="ค่าบริการรวม" span={1}>{formatNumber(returnFinancePaymentView.totalAmount)}</Descriptions.Item>
                <Descriptions.Item label="ผู้แจ้ง" span={3}>{returnFinancePaymentView.informName}</Descriptions.Item>
                <Descriptions.Item label="หมายเหตุ" span={3}>{returnFinancePaymentView.userRemark}</Descriptions.Item>
                <Descriptions.Item label="หมายเหตุ(ระบบ)" span={3}>{returnFinancePaymentView.remark}</Descriptions.Item>
              </Descriptions>
            </Form>
          </div>
        </Content>
      </Spin>
    </>
  )
}

export default ViewReturnFinancePayment