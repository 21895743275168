import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, DatePicker, Form, Modal, Radio, Row, Select, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { TAssetHeadView } from 'src/domain/master/view/tAssetHeadView';
import { TTruckRepair } from 'src/domain/tTruckRepair';
import { TTruckRepairView } from 'src/domain/view/tTruckRepairView';
import tAssetHeadService from 'src/services/tasset/tAssetHeadService';
import tTruckRepairService from 'src/services/tTruckRepairService';
import { useAppSelector } from 'src/stores/store';
import Constants from 'src/utils/constants';
import { dateToString, toMomentDateTime } from 'src/utils/dateTimeUtils';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';

const { Option } = Select;
const { confirm } = Modal;

interface IProps {
  onAddEditModalVisible: boolean;
  setOnAddEditModalVisible: any;
  ttruckRepairViewSelected: TTruckRepairView;
  tAssetHeadViewSelected: TAssetHeadView;
}
const TruckRepairModal: React.FC<IProps> = (props) => {
  const { onAddEditModalVisible,
    setOnAddEditModalVisible,
    ttruckRepairViewSelected,
    tAssetHeadViewSelected,
  } = props;
  const [form] = Form.useForm();
  const [trigger, setTrigger] = useState<boolean>(false);
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [tAssetHeadViews, setTAssetHeadViews] = useState<TAssetHeadView[]>([]);

  useEffect(() => {
    if (trigger) {
      form.validateFields()
        .then(() => {
          confirm({
            title: "ยืนยัน!",
            icon: <ExclamationCircleOutlined />,
            content: "ต้องการบันทึกการกำหนดเวลาซ่อมรถ หรือไม่?",
            cancelText: "ยกเลิก",
            okText: "ยืนยัน",
            onOk() {
              form.submit();
            },
            onCancel() {
              setTrigger(false);
            }
          });
        }).catch(() => {
          setTrigger(false);
        });
    }
  }, [trigger]);

  useEffect(() => {
    initial();
    return () => { };
  }, []);

  const initial = async () => {
    const defaultAssetRes = await tAssetHeadService.getTAssetHeadByParamAndBranch(tAssetHeadViewSelected.id, userInfo.branchId);
    const foundDefaultAsset = (defaultAssetRes.data as Array<TAssetHeadView>).find((obj) => obj.id === tAssetHeadViewSelected.id);

    const now = dateToString(moment(new Date()));
    const start = ttruckRepairViewSelected.iid ? toMomentDateTime(ttruckRepairViewSelected.istartRepair, ttruckRepairViewSelected.istimeRepair) : toMomentDateTime(now, "080000");
    const end = ttruckRepairViewSelected.iid ? toMomentDateTime(ttruckRepairViewSelected.iendRepair, ttruckRepairViewSelected.ietimeRepair) : toMomentDateTime(now, "170000");

    let rdVal = "RD_LOCK";
    if (ttruckRepairViewSelected.iid) {
      if (ttruckRepairViewSelected.istatusRepair === 0) {
        rdVal = "RD_UNLOCK";
      } else {
        rdVal = "RD_LOCK";
      }
    }

    setTAssetHeadViews(defaultAssetRes.data);
    form.setFieldsValue({
      itruckCode: foundDefaultAsset?.id,
      istartRepair: start,
      iendRepair: end,
      iremarkRepair: ttruckRepairViewSelected.iid ? ttruckRepairViewSelected.iremarkRepair : "",
      rdLockOrUnlock: rdVal,
    })
  };

  const onFinish = async (values: any) => {
    let std = values.istartRepair.format("yyyyMMDD");
    let stt = values.istartRepair.format("HH0000");
    let ed = values.iendRepair.format("yyyyMMDD");
    let et = values.iendRepair.format("HH0000");

    if (!ttruckRepairViewSelected.iid) {
      const truckrepair: TTruckRepair = {
        ...ttruckRepairViewSelected,
        iid: 0,
        itruckCode: tAssetHeadViewSelected.id,
        itruckNumber: tAssetHeadViewSelected.numbers,
        istartRepair: std,
        iendRepair: ed,
        istimeRepair: stt,
        ietimeRepair: et,
        iremarkRepair: values.iremarkRepair,
        istatusRepair: values.rdLockOrUnlock === "RD_LOCK" ? 1 : 0,
        iflgDel: 0,
        istatusSaveOrUpdate: 2, //เพื่อใช่ฟังก์ชัน SaveAdd -> แต่ อันใหม่ไม่ได้ใช้ แค่ set ไว้เฉยๆ   
        savstation: userInfo.saveStation,
        createuser: userInfo.userId,
        lastuser: userInfo.userId,
        termno: userInfo.termNo
      };
      try {
        await tTruckRepairService.postTTruckRepair(truckrepair);
        showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเรียบร้อย");
        setOnAddEditModalVisible(false);
        form.resetFields();
        setTrigger(false);
      } catch (err) {
        showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        setTrigger(false);
      }
    } else {
      const truckrepair: TTruckRepair = {
        ...ttruckRepairViewSelected,
        istartRepair: std,
        iendRepair: ed,
        istimeRepair: stt,
        ietimeRepair: et,
        iremarkRepair: values.iremarkRepair,
        istatusRepair: values.rdLockOrUnlock === "RD_LOCK" ? 1 : 0,
        istatusSaveOrUpdate: 1, //เพื่อใช่ฟังก์ชัน SaveAdd -> แต่ อันใหม่ไม่ได้ใช้ แค่ set ไว้เฉยๆ    
        savstation: userInfo.saveStation,
        createuser: userInfo.userId,
        lastuser: userInfo.userId,
        termno: userInfo.termNo
      };
      try {
        await tTruckRepairService.updateTTruckRepair(truckrepair);
        showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเรียบร้อย");
        setOnAddEditModalVisible(false);
        form.resetFields();
        setTrigger(false);
      } catch (err) {
        showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        setTrigger(false);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน");
    setTrigger(false);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>เพิ่มตารางเวลาการซ่อม</Typography.Title>
          </div>
        }
        visible={onAddEditModalVisible}
        width={"50%"}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setOnAddEditModalVisible(false)}
      >
        <Form
          form={form}
          name="ttruckRepair"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>รหัสรถ: </Typography.Text>
            </Col>
            <Col span={20}>
              <Form.Item
                name="itruckCode"
                rules={[
                  { required: true, message: "กรุณาเลือกรหัสรถ" }
                ]}
              >
                <Select
                  size="middle"
                  optionFilterProp="children"
                  disabled
                  style={{ width: "110%" }}
                >
                  {tAssetHeadViews.map((obj) => {
                    return (
                      <Option key={obj.id} value={obj.id}>
                        {obj.id}: {obj.assetFullName}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>วันที่เริ่ม: </Typography.Text>
            </Col>
            <Col span={8}>
              <Form.Item
                name="istartRepair"
                rules={[{ required: true, message: "กรุณาเลือกวันที่เริ่ม" }]}
              >
                <DatePicker
                  showTime={{ format: "HH:00:00" }}
                  allowClear
                  placement="topRight"
                  format={Constants.DATE_FORMAT_SKIP_MINUTE_SEC}
                />
              </Form.Item>
            </Col>
            <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>วันที่สิ้นสุด: </Typography.Text>
            </Col>
            <Col span={8}>
              <Form.Item
                name="iendRepair"
                rules={[{ required: true, message: "กรุณาเลือกวันที่สิ้นสุด" }]}
              >
                <DatePicker
                  showTime={{ format: "HH:00:00" }}
                  allowClear
                  placement="topRight"
                  format={Constants.DATE_FORMAT_SKIP_MINUTE_SEC}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
            </Col>
            <Col span={20}>
              <Form.Item
                name="rdLockOrUnlock"
                rules={[{ required: true, message: "กรุณาเลือก ล๊อค หรือ ปลดล๊อค การทำงาน" }]}
              >
                <Radio.Group>
                  <Radio value="RD_LOCK">ล็อคการทำงาน</Radio>
                  <Radio value="RD_UNLOCK">ปลดล็อคการทำงาน</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>หมายเหตุ: </Typography.Text>
            </Col>
            <Col span={20}>
              <Form.Item
                name="iremarkRepair"
                validateTrigger="onBlur"
              >
                <TextArea
                  rows={4}
                ></TextArea>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default TruckRepairModal