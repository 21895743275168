import { EditOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Button, Descriptions, Divider, Table, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import PageHeader from "src/layouts/PageHeader";
import { TBankBranchHead } from "../../domain/master/tBankBranchHead";
import { TBankHead } from "../../domain/master/tBankHead";
import tBankBranchHeadService from "../../services/bank/tBankBranchHeadService";
import tBankHeadService from "../../services/bank/tBankHeadService";
import AddEditBankBranchHeadModal from "./AddEditBankBranchHeadModal";
import AddEditBankHeadModal from "./AddEditBankHeadModal";

const { Text } = Typography;

interface IParams {
  ibankCode: string;
}

const ViewBankHead: React.FC<RouteComponentProps<IParams>> = (props) => {
  const ibankCode: string = props.match.params.ibankCode;
  const [bankHead, setBankHead] = useState<TBankHead>({} as TBankHead);
  const [bankBranchHeads, setBankBranchHeads] = useState<TBankBranchHead[]>([]);
  const [page, setPage] = React.useState(1);
  const history = useHistory();
  const [visibleBankHeadModal, setVisibleBankHeadModal] =
    useState<boolean>(false);

  const [selectedIbankCode, setSelectedIbankCode] = useState("");
  const [selectedIbankBranchCode, setSelectedIbankBranchCode] = useState("");
  const [visibleBankBranchHeadModal, setVisibleBankBranchHeadModal] =
    useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (!visibleBankHeadModal || !visibleBankBranchHeadModal) {
        const res = await tBankHeadService.getBankHeadByIBankCode(ibankCode);
        setBankHead(res.data);
      }
    })();
  }, [visibleBankHeadModal, visibleBankBranchHeadModal]);

  useEffect(() => {
    (async () => {
      if (bankHead.ibankCode) {
        const res = await tBankBranchHeadService.getBankBranchHeadByBankCode(
          bankHead.ibankCode
        );
        setBankBranchHeads(res.data);
      }
    })();
  }, [bankHead]);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      wibankCodeth: 100,
      render: (value: number, item: TBankBranchHead, index: number) => (
        <>{(page - 1) * 15 + index + 1}</>
      ),
    },
    {
      title: "รหัสสาขา",
      dataIndex: "ibankBranchCode",
      key: "ibankBranchCode",
      width: 150,
      render: (value: string, item: TBankBranchHead) => (
        <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)}>{item.ibankBranchCode}</Button>
      )
    },
    {
      title: "ชื่อสาขา",
      dataIndex: "ibankBranchName",
      key: "ibankBranchName",
    },
    {
      title: "ที่อยู่",
      dataIndex: "ibankBranchAddress",
      key: "ibankBranchAddress",
    },
    {
      title: "เบอร์โทร",
      dataIndex: "ibankBranchTel",
      key: "ibankBranchTel",
      width: 200,
    }
  ];

  function onEditBankHead() {
    setVisibleBankHeadModal(true);
  }

  function onAddBankBranchHead() {
    setSelectedIbankCode("");
    setSelectedIbankBranchCode("");
    setVisibleBankBranchHeadModal(true);
  }

  function onItemCliked(item: TBankBranchHead) {
    setSelectedIbankCode(item.ibankCode);
    setSelectedIbankBranchCode(item.ibankBranchCode);
    setVisibleBankBranchHeadModal(true);
  }

  return (
    <>
      <PageHeader title="" onClose={() => history.push("/app/bankHead/list")} />
      <Content className="app-page-content">
        <Descriptions
          style={{ margin: "1em" }}
          column={2}
          title="ข้อมูลธนาคาร"
          size="middle"
          extra={
            <Button
              size="middle"
              type="primary"
              icon={<EditOutlined />}
              onClick={() => onEditBankHead()}
            >แก้ไข</Button>
          }
        />
        <div style={{ display: "flex", justifyContent: "flex-start", margin: "1em" }}>
          <Descriptions
            column={2}
            size="middle"
            bordered
            labelStyle={{ textAlign: "right", fontWeight: "bold" }}
          >
            <Descriptions.Item label="รหัส" span={2}>
              {bankHead.ibankCode}
            </Descriptions.Item>
            <Descriptions.Item label="รหัสย่อ" span={2}>
              {bankHead.shortname}
            </Descriptions.Item>
            <Descriptions.Item label="ชื่อธนาคาร" span={2}>
              {bankHead.ibankName}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </Content>
      <Divider />

      <br />
      <Content className="app-page-content">
        <div style={{ padding: "0px 20px" }}>
          <div style={{ marginBottom: 16 }}>
            <Button
              size="small"
              type="primary"
              icon={<PlusSquareOutlined />}
              onClick={onAddBankBranchHead}
            >
              เพิ่ม
            </Button>
          </div>
          <Table
            style={{ borderBlockStart: "2px solid #c9c9c9" }}
            columns={columns}
            dataSource={bankBranchHeads}
            size="small"
            pagination={{
              pageSize: 15,
              onChange(current) {
                setPage(current);
              },
            }}
            rowKey={(item: TBankBranchHead) => item.ibankCode}
            onRow={(item: TBankBranchHead) => {
              return {
                onDoubleClick: () => onItemCliked(item),
              };
            }}
          />
        </div>
      </Content>

      <div>
        {visibleBankHeadModal && (
          <AddEditBankHeadModal
            ibankCode={ibankCode}
            visibleBankHeadModal={visibleBankHeadModal}
            setVisibleBankHeadModal={setVisibleBankHeadModal}
          />
        )}
      </div>
      <div>
        {visibleBankBranchHeadModal && (
          <AddEditBankBranchHeadModal
            ibankCode={selectedIbankCode}
            ibankBranchCode={selectedIbankBranchCode}
            bankHead={bankHead}
            visibleBankBranchHeadModal={visibleBankBranchHeadModal}
            setVisibleBankBranchHeadModal={setVisibleBankBranchHeadModal}
          />
        )}
      </div>
    </>
  );
};
export default ViewBankHead;
