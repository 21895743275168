import { Table, Typography } from 'antd';
import React from 'react';
import { TWorksheetWorkerView } from 'src/domain/worksheet/view/tWorksheetWorkerView';
import { WorksheetHeadData } from 'src/domain/worksheet/view/worksheetHeadData';
import { formatNumber } from 'src/utils/numberUtils';

interface Iprops {
  worksheetHeadData: WorksheetHeadData;
}

const WorkerDetailViewTab: React.FC<Iprops> = (props) => {
  const { worksheetHeadData } = props;

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      key: "index",
      align: "center" as "center",
      width: 50,
      render: (value: any, item: TWorksheetWorkerView, index: number) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: "ชื่อ-นามสกุล",
      dataIndex: "iworkerName",
      key: "iworkerName",
      width: 150,
    },
    {
      title: "ที่อยู่",
      dataIndex: "iworkerAddress",
      key: "iworkerAddress",
      width: 250,
    },
    {
      title: "เบอร์โทร",
      dataIndex: "iworkerPhone",
      key: "iworkerPhone",
      width: 150,
    },
    {
      title: "ประเภทพนักงาน",
      dataIndex: "itypeworkerName",
      key: "itypeworkerName",
      width: 150,
    },
  ];

  return (
    <>
      <Typography.Title
        type="danger"
        level={4}
        style={{ height: "22px", textAlign: "right" }}
      >
        จำนวน :
        {formatNumber(worksheetHeadData.tworksheetWorkerViewList.length)}
      </Typography.Title>
      <div>
        <Table
          columns={columns}
          dataSource={worksheetHeadData.tworksheetWorkerViewList}
          rowKey={(item) => item.iid}
          size="small"
          pagination={false}
        />
      </div>
    </>
  )
}

export default WorkerDetailViewTab