import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Space, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PageHeader from "src/layouts/PageHeader";
import { useAppSelector } from "src/stores/store";
import Constants from "src/utils/constants";
import { TConfirmreserveHeadView } from "../../domain/TConfirmreserveHeadView";
import tConfirmreserveHeadService from "../../services/tConfirmreserveHeadService";
import { toThDate } from "../../utils/dateTimeUtils";


const TConfirmreserveHeadList: React.FC<TConfirmreserveHeadView> = () => {
    const [tConfirmreserveHeads, setTConfirmreserveHeads] = useState<TConfirmreserveHeadView[]>([]);
    const [page, setPage] = React.useState(1);
    const [loading, setLoading] = React.useState(false);
    const history = useHistory();
    const userInfo = useAppSelector((state) => state.authen.authenUser.user);
    const [form] = Form.useForm();

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            width: 50,
            render: (value: number, item: TConfirmreserveHeadView, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
        },
        {
            title: "วันที่ยืนยัน",
            dataIndex: "endDate",
            key: "endDate",
            width: 100,
            render: (value: any, item: TConfirmreserveHeadView) => (<>{toThDate(item.ientryDate)}</>)
        },
        {
            title: "เลขที่ใบจอง",
            dataIndex: "ireserveCode",
            key: "ireserveCode",
            width: 100
        },
        {
            title: "เลขที่ใบงาน",
            dataIndex: "iworksheetCode",
            key: "iworksheetCode",
            width: 100
        },
        {
            title: "ลูกค้า",
            dataIndex: "customer",
            key: "customer",
            render: (value: any, item: TConfirmreserveHeadView) => (<>{item.customerName}</>)
        },
        {
            title: "รถ",
            dataIndex: "truck",
            key: "truck",
            render: (value: any, item: TConfirmreserveHeadView) => (<>{item.truckNumbers}</>)
        }
    ];

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
        form.setFieldsValue({
            fromDate: moment(new Date(), Constants.DATE_FORMAT),
            toDate: moment(new Date(), Constants.DATE_FORMAT)
        });
        form.submit();
    }, []);

    const onFinish = (values: any) => {
        setLoading(true);
        const branchId = userInfo.branchId;
        let fromDate: string = values.fromDate ? moment(values.fromDate).format(Constants.DB_FORMAT) : moment().format(Constants.DB_FORMAT);
        let toDate: string = values.toDate ? moment(values.toDate).format(Constants.DB_FORMAT) : moment().format(Constants.DB_FORMAT);
        tConfirmreserveHeadService.getTConfirmreserveHeadByEntryDateAndBranchId(branchId, fromDate, toDate)
            .then((res) => {
                setTConfirmreserveHeads(res.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const onFinishFailed = (errorInfo: any) => {
    };

    const onAddTConfirmreserveHead = () => {
        history.replace("/app/tConfirmreserveHead/view/");
    }

    return (
        <>
            <PageHeader title="โปรแกรมยืนยันใบจอง" >
                <Button
                    size="small"
                    type="primary"
                    icon={<PlusSquareOutlined />}
                    onClick={onAddTConfirmreserveHead}
                >
                    เพิ่ม
                </Button>
            </PageHeader>
            <Content className="app-page-content">
                <Form
                    form={form}
                    name="bankBranchHead"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "baseline",
                            margin: "1em 0 1em 0",
                            paddingLeft: "1em",
                            borderBlockEnd: "2px solid #c9c9c9",
                        }}
                    >
                        <Space direction="vertical" size={"small"}>
                            <Space align="baseline" size={"large"}>
                                <Form.Item
                                    label="ข้อมูลตั้งแต่วันที่"
                                    name="fromDate"
                                    rules={[{ required: false }]}
                                >
                                    <DatePicker
                                        format={Constants.DATE_FORMAT}
                                        size="middle"
                                        placeholder="เลือกวันที่"
                                        onChange={form.submit}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="ถึง"
                                    name="toDate"
                                    rules={[{ required: false }]}
                                >
                                    <DatePicker
                                        format={Constants.DATE_FORMAT}
                                        size="middle"
                                        placeholder="เลือกวันที่"
                                        onChange={form.submit}
                                    />
                                </Form.Item>
                            </Space>
                        </Space>
                    </div>
                </Form>
                <div>
                    <Table
                        columns={columns}
                        dataSource={tConfirmreserveHeads}
                        size="small"
                        loading={loading}
                        pagination={{
                            pageSize: 15,
                            onChange(current) {
                                setPage(current);
                            }
                        }}
                        rowKey={(item: TConfirmreserveHeadView) => item.iid}
                    />
                </div>
            </Content>
        </>
    );
};

export default TConfirmreserveHeadList;
