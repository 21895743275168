import { ClearOutlined, PlusSquareOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, Row, Space, Switch, Table, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PageHeader from "src/layouts/PageHeader";
import { useAppSelector } from "src/stores/store";
import Constants from "src/utils/constants";
import { toThDate } from "src/utils/dateTimeUtils";
import { NotificationType, showNotification } from "src/utils/notificationUtils";
import { formatNumber } from "src/utils/numberUtils";
import { TWorksheetSteelHeadView } from "../../domain/worksheet/view/tWorksheetSteelHeadView";
import tWorksheetSteelHeadService from "../../services/tWorksheetSteelHeadService";
import AddEditWorkSheetSteelHeadDrawer from "./AddEditWorkSheetSteelHeadDrawer";

const WorkSheetSteelHeadList: React.FC<TWorksheetSteelHeadView> = () => {
    const [workSheetSteelHeads, setWorkSheetSteelHeads] = useState<TWorksheetSteelHeadView[]>([]);
    const [page, setPage] = React.useState(1);
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [isSearchDate, setIsSearchDate] = useState<boolean>(true);
    const [visibleAddEditWorkSheetSteelHeadDrawer, setVisibleAddEditWorkSheetSteelHeadDrawer] = useState<boolean>(false);

    const userInfo = useAppSelector((state) => state.authen.authenUser.user);
    const [form] = Form.useForm();

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            width: 50,
            render: (value: number, item: TWorksheetSteelHeadView, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
        },
        {
            title: "เลขที่ใบจอง",
            dataIndex: "ireserveCode",
            key: "ireserveCode",
            render: (value: string, item: TWorksheetSteelHeadView) => (
                <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.ireserveCode}</Button>
            )
        },
        {
            title: "วันที่จอง",
            dataIndex: "ientryDate",
            key: "ientryDate",
            render: (value: any, item: TWorksheetSteelHeadView) => (<>{toThDate(item.ientryDate)}</>)
        },
        {
            title: "ชื่อลูกค้า",
            dataIndex: "icustName",
            key: "icustName",
        },
        {
            title: "วันที่ใช้งาน",
            dataIndex: "istartDate",
            key: "istartDate",
            render: (value: any, item: TWorksheetSteelHeadView) => (<>{toThDate(item.istartDate)}</>)
        },
        {
            title: "วันที่สิ้นสุด",
            dataIndex: "iendDate",
            key: "iendDate",
            render: (value: any, item: TWorksheetSteelHeadView) => (<>{toThDate(item.iendDate)}</>)
        },
        {
            title: "รายการบริการ",
            dataIndex: "steelServiceName",
            key: "steelServiceName",
        },
        {
            title: "จำนวนแผ่น",
            dataIndex: "steelNumSteel",
            key: "steelNumSteel",
        },
        {
            title: "ค่าบริการ",
            dataIndex: "iserviceAmt",
            key: "iserviceAmt",
            align: "right" as "right",
            render: (value: any, item: TWorksheetSteelHeadView) => {
                return <span>{formatNumber(item.iserviceAmt)}</span>;
            },
        },
        {
            title: "ผู้สร้าง",
            dataIndex: "steelCreateUserName",
            key: "steelCreateUserName",
        },
    ];

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
        form.setFieldsValue({
            reserveCode: "",
            fromDate: moment(new Date(), Constants.DATE_FORMAT),
            toDate: moment(new Date(), Constants.DATE_FORMAT)
        });
        form.submit();
    }, []);

    const onFinish = async (values: any) => {
        setLoading(true);
        const branchId = userInfo.branchId;
        let reserveCode: string = values.reserveCode ? values.reserveCode : "";
        let fromDate: string = values.fromDate ? moment(values.fromDate).format(Constants.DB_FORMAT) : ""; // moment().startOf('month').format(Constants.DB_FORMAT);
        let toDate: string = values.toDate ? moment(values.toDate).format(Constants.DB_FORMAT) : "";//moment().endOf('month').format(Constants.DB_FORMAT);

        if (reserveCode && reserveCode.length >= 7) {
            fromDate = "";
            toDate = "";
        } else if (reserveCode.length > 0 && reserveCode.length < 7) {
            showNotification(NotificationType.ERROR, "กรุณาป้อนเลขที่ใบจองให้มากกว่า 7 ตัวอักษร");
            form.setFieldsValue({
                reserveCode: "",
            });
            return;
        }

        const res = await tWorksheetSteelHeadService.getWorksheetSteels(branchId, reserveCode, fromDate, toDate);
        setWorkSheetSteelHeads(res.data);
        setLoading(false);
    }

    const onFinishFailed = (errorInfo: any) => {
    };

    const onReset = () => {
        form.setFieldsValue({
            reserveCode: "",
            fromDate: moment(new Date(), Constants.DATE_FORMAT),
            toDate: moment(new Date(), Constants.DATE_FORMAT)
        });
        form.submit();
    };

    function onAddWorkSheetSteelHead() {
        setVisibleAddEditWorkSheetSteelHeadDrawer(true);
    }

    const onItemCliked = (item: TWorksheetSteelHeadView) => {
        history.replace("/app/workSheetSteel/view/" + item.ireserveCode);
    };

    return (
        <>
            <PageHeader title="โปรแกรมสร้างใบจองเหมาแผ่นเหล็ก">
                <Button
                    size="small"
                    type="primary"
                    icon={<PlusSquareOutlined />}
                    onClick={onAddWorkSheetSteelHead}
                >
                    เพิ่ม
                </Button>
            </PageHeader>
            <Content className="app-page-content">
                <Form
                    form={form}
                    name="bankBranchHead"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <div
                        style={{
                            // display: "flex",
                            // flexDirection: "row",
                            alignItems: "baseline",
                            margin: "1em 0 1em 0",
                            paddingLeft: "1em",
                            borderBlockEnd: "2px solid #c9c9c9",
                        }}
                    >
                        <Row>
                            <Col span={2}>
                                <Switch
                                    checkedChildren="วันที่จอง"
                                    unCheckedChildren=""
                                    checked={isSearchDate}
                                    onClick={() => setIsSearchDate(!isSearchDate)}
                                    style={{ width: 85 }}
                                />
                            </Col>
                            <Col span={22}>
                                <Space align="baseline" size={"large"}>
                                    <Form.Item
                                        name="fromDate"
                                        rules={[{ required: false }]}
                                    >
                                        <DatePicker
                                            format={Constants.DATE_FORMAT}
                                            size="middle"
                                            placeholder="เลือกวันที่"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="ถึง"
                                        name="toDate"
                                        rules={[{ required: false }]}
                                    >
                                        <DatePicker
                                            format={Constants.DATE_FORMAT}
                                            size="middle"
                                            placeholder="เลือกวันที่"
                                        />
                                    </Form.Item>
                                </Space>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                                <Typography.Text>เลขที่ใบจอง: </Typography.Text>
                            </Col>
                            <Col span={22}>
                                <Form.Item
                                    name="reserveCode"
                                    rules={[{ required: false }]}
                                >
                                    <Input
                                        allowClear
                                        placeholder="ป้อนเลขที่ใบจอง"
                                        style={{
                                            width: 378,
                                        }}
                                    ></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={20}></Col>
                            <Col span={4}>
                                <Space align="baseline" size={"small"}>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            size="small"
                                            icon={<SearchOutlined />}
                                        >
                                            ค้นหา
                                        </Button>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            size="small"
                                            type="primary"
                                            htmlType="button"
                                            icon={<ClearOutlined />}
                                            onClick={onReset}
                                        >
                                            ล้าง
                                        </Button>
                                    </Form.Item>
                                </Space>
                            </Col>
                        </Row>
                    </div>
                </Form>
                <div>
                    <Table
                        columns={columns}
                        dataSource={workSheetSteelHeads}
                        size="small"
                        loading={loading}
                        pagination={{
                            pageSize: 15,
                            onChange(current) {
                                setPage(current);
                            }
                        }}
                        rowKey={(item: TWorksheetSteelHeadView) => item.steelId}
                        onRow={(item: TWorksheetSteelHeadView) => {
                            return {
                                onDoubleClick: () => onItemCliked(item),
                            };
                        }}
                    />
                </div>
            </Content>
            <div> {
                visibleAddEditWorkSheetSteelHeadDrawer && <AddEditWorkSheetSteelHeadDrawer
                    ireserveCode=""
                    visibleAddEditWorkSheetSteelHeadDrawer={visibleAddEditWorkSheetSteelHeadDrawer}
                    setVisibleAddEditWorkSheetSteelHeadDrawer={setVisibleAddEditWorkSheetSteelHeadDrawer} />
            }</div>
        </>
    );
};

export default WorkSheetSteelHeadList;
