import { Col, Form, Modal, Row, Select, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { TEmployee2View } from 'src/domain/master/view/tEmployee2View';
import { TWorksheetWorkerView } from 'src/domain/worksheet/view/tWorksheetWorkerView';
import tEmployee2Service from 'src/services/employee/tEmployee2Service';
import { useAppSelector } from 'src/stores/store';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';

const { Option } = Select;
interface ModalProp {
  visibleWorksheetWorkerModal: boolean;
  setVisibleWorksheetWorkerModal: any;
  tworksheetWorkerFormDataList: TWorksheetWorkerView[];
  setTworksheetWorkerFormDataList: any;
}

const AddWorksheetWorkerModal: React.FC<ModalProp> = (props) => {
  const { visibleWorksheetWorkerModal, setVisibleWorksheetWorkerModal, tworksheetWorkerFormDataList, setTworksheetWorkerFormDataList } = props;
  const [form] = Form.useForm();
  const [submitTrigger, setSubmitTrigger] = useState<boolean>(false);
  const [showSpin, setShowSpin] = useState<boolean>(false);
  const [worksheetWorkers, setWorksheetWorkers] = useState<TEmployee2View[]>([]);
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  useEffect(() => {
    setShowSpin(true);
    loadData();
    return () => { };
  }, []);

  useEffect(() => {
    if (submitTrigger) {
      form.submit();
    }
  }, [submitTrigger]);

  const loadData = async () => {
    form.setFieldsValue({
      itypeworker: 0,
    })
    setShowSpin(false);
  };

  const handleSearchWorker = async (data: any) => {
    if (data.length < 3) return;
    const res = await tEmployee2Service.getEmployee2ViewByName(data);
    setWorksheetWorkers(res.data);
  };

  const checkExistInList = (iworkerCode: string) => {
    let isExist = false;
    const formItem = (tworksheetWorkerFormDataList as Array<TWorksheetWorkerView>).find((obj) => obj.iworkerCode === iworkerCode);
    if (formItem) isExist = true;
    return isExist;
  }

  const onFinish = async (values: any) => {
    const worker = (worksheetWorkers as Array<TEmployee2View>).find((obj) => obj.id === values.iworkerCode);
    if (!worker) {
      return;
    } else {
      if (checkExistInList(values.iworkerCode)) {
        showNotification(NotificationType.ERROR, "รายการเด็กติดรถคนนี้มีอยู่แล้ว ไม่สามารถ เพิ่มซ้ำได้");
        setSubmitTrigger(false);
      } else {

        const fullAddress = `${worker?.addresshomenumber} ${worker?.addresshosename} ห้อง ${worker?.addressroomnumber} หมู่ที่ ${worker?.addressmoo}  ตรอก ${worker?.addresslane} ถนน ${worker?.addressroad} ตำบล ${worker?.addresstumbol} อำเภอ/เขต ${worker?.addressamphurName} จังหวัด${worker?.addressprovinceName} ${worker?.addresspostcode}`;
        const fullPhone = `โทรศัพท์ ${worker?.addresstelephonenumber} ต่อ ${worker?.addresstelephonenumberplus} มือถือ ${worker?.addressmobilenumber}`;
        // const driver = (worksheetWorkers as Array<TEmployee2>).find((obj) => obj.id === values.iworkerCode);
        const temp: TWorksheetWorkerView = {
          iid: 0,
          ireserveCode: "",
          iworkerCode: values.iworkerCode,
          iworkerName: (worker) ? worker.empname : "",
          itypeworker: values.itypeworker,
          itypeworkerName: (values.itypeworker === 0 ? "พนักงานท้ายรถ" : "พนักงานขับรถ"),
          iworkerAddress: fullAddress,
          iworkerPhone: fullPhone,
          icreateUser: userInfo.userId,
          ilastUser: userInfo.userId,
          itermNo: userInfo.termNo,
          isavStation: userInfo.saveStation
        };

        const array = tworksheetWorkerFormDataList ? [...tworksheetWorkerFormDataList] : [];
        array?.push(temp);
        setTworksheetWorkerFormDataList(array);
        // setRecalculateOtherService(true);
        form.resetFields();
        setSubmitTrigger(false);
        setVisibleWorksheetWorkerModal(false);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาตรวจสอบข้อมูลให้ครบถ้วน !");
    form.resetFields();
    setSubmitTrigger(false);
  };

  return (
    <>
      <Modal
        title="เพิ่มเด็กติดรถ"
        visible={visibleWorksheetWorkerModal}
        width={"50%"}
        bodyStyle={{ minHeight: "200px" }}
        closable={false}
        destroyOnClose
        maskClosable={true}
        onOk={() => setSubmitTrigger(true)}
        onCancel={() => setVisibleWorksheetWorkerModal(false)}
      >
        <Spin spinning={showSpin}>
          <>
            <Form form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>พนักงานติดรถ: </Typography.Text>
                </Col>
                <Col span={18}>
                  <Form.Item name="iworkerCode" style={{ marginBottom: "3px" }}>
                    <Select
                      showSearch
                      allowClear
                      size="middle"
                      placeholder="เลือกพนักงานติดรถ"
                      optionFilterProp="children"
                      filterOption={true}
                      onSearch={handleSearchWorker}
                    // onSelect={handleChangeDriver}
                    >
                      {worksheetWorkers.map((obj) => {
                        return (
                          <Option key={obj.id} value={obj.id}>{obj.empname}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ประเภทพนักงานท้ายรถ: </Typography.Text>
                </Col>
                <Col span={18}>
                  <Form.Item name="itypeworker" style={{ marginBottom: "3px" }}>
                    <Select
                      showSearch
                      size="middle"
                      placeholder="เลือกประเภทพนักงานติดรถ"
                      optionFilterProp="children"
                      filterOption={true}
                    >
                      {/* {tTypeemployeeRefs.map((obj) => {
												return ( */}
                      <Option key={0} value={0}>พนักงานท้ายรถ</Option>
                      <Option key={1} value={1}>พนักงานขับรถ</Option>
                      {/* ); */}
                      {/* })} */}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </>
        </Spin>
      </Modal>
    </>
  )
}

export default AddWorksheetWorkerModal