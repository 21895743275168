import { Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Space, Spin, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { TFinanceTrushconfirm } from 'src/domain/finance/tFinanceTrushconfirm';
import { TFinanceTrushconfirmData } from 'src/domain/finance/view/TFinanceTrushconfirmData';
import { TFinanceTrushView } from 'src/domain/finance/view/tFinanceTrushView';
import { RyAccountView } from 'src/domain/view/ryAccountView';
import tFinanceTrushService from 'src/services/finance/tFinanceTrushService';
import tFinanceTrushconfirmService from 'src/services/finance/tFinanceTrushconfirmService';
import { useAppSelector } from 'src/stores/store';
import Constants from 'src/utils/constants';
import { toMomentDate } from 'src/utils/dateTimeUtils';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber } from 'src/utils/numberUtils';
import ChangeAccountModal from '../modals/ChangeAccountModal';

interface IProps {
  onConfirmModalVisible: boolean;
  setOnConfirmModalVisible: any;
  onChangeAccseqModalVisible: boolean;
  setOnChangeAccseqModalVisible: any;
  tfinanceTrushViewSelected: TFinanceTrushView;
  setTfinanceTrushViewSelected: any
  tfinanceTrushViews: TFinanceTrushView[];
  setTfinanceTrushViews: any;
}

const TrushMoneyConfirmModal: React.FC<IProps> = (props) => {
  const { onConfirmModalVisible,
    setOnConfirmModalVisible,
    onChangeAccseqModalVisible,
    setOnChangeAccseqModalVisible,
    tfinanceTrushViewSelected,
    setTfinanceTrushViewSelected,
    tfinanceTrushViews,
    setTfinanceTrushViews } = props;
  const [form] = Form.useForm();
  const [showSpin, setShowSpin] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<boolean>(false);
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [ryAccountSelected, setRyAccountSelected] = useState<RyAccountView>({} as RyAccountView); //บัญชีธนาคาร
  const [tfinanceTrushconfirmData, setTfinanceTrushconfirmData] = useState<TFinanceTrushconfirmData>({} as TFinanceTrushconfirmData);

  useEffect(() => {
    initData();
  }, []);

  const initData = async () => {
    setShowSpin(true);

    form.setFieldsValue({
      id: tfinanceTrushViewSelected.id,
      accseq: tfinanceTrushViewSelected.accountNumber,
      type: tfinanceTrushViewSelected.type,
      chquenumber: tfinanceTrushViewSelected.chquenumber,
      amount: tfinanceTrushViewSelected.amount,
      trushusername: tfinanceTrushViewSelected.trushusername,
      trushdate: toMomentDate(tfinanceTrushViewSelected.trushdate),
      payinnumber: undefined,
      remark: undefined,
    });

    setShowSpin(false);
  }

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  useEffect(() => {
    (async () => {
      if (!onChangeAccseqModalVisible && Object.keys(ryAccountSelected).length != 0 && isUpdate) {
        try {
          const tfinanceTrushUpd = { ...tfinanceTrushViewSelected }
          const res = await tFinanceTrushService.updateTFinanceTrush(tfinanceTrushUpd);
          showNotification(NotificationType.SUCCESS, "บันทึกบันทึกการเปลี่ยนบัญชีเรียบร้อย!");

          form.setFieldsValue({
            accseq: tfinanceTrushViewSelected.accountNumber,
          });
        } catch {
          showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ");
        }
      }
    })();

  }, [onChangeAccseqModalVisible, ryAccountSelected]);

  const onFinish = async (values: any) => {
    const temp: TFinanceTrushconfirmData = {
      ...(tfinanceTrushconfirmData as TFinanceTrushconfirmData),
      tfinanceTrushConfirm: {
        ...(tfinanceTrushconfirmData.tfinanceTrushConfirm as TFinanceTrushconfirm),
        trushmoneyid: values.id,
        confirmtype: values.type,
        amount: values.amount,
        payinnumber: values.payinnumber,
        remark: values.remark,
        createuser: userInfo.userId,
        termno: userInfo.termNo,
        savstation: userInfo.saveStation,
      },
      tfinanceTrushView: {
        ...tfinanceTrushViewSelected,
        confirmdate: moment().format(Constants.DB_FORMAT),
        confirmtime: moment().format(Constants.DB_TIME_FORMAT),
        confirmuser: userInfo.fullName,
        payinnumber: values.payinnumber,
        trushstatus: 1,
        remark: tfinanceTrushViewSelected.remark ? tfinanceTrushViewSelected.remark : "" + "->" + values.remark,
      }
    };

    try {
      const tfinanceTrushDataRes = await tFinanceTrushconfirmService.saveTfinanceTrushconfirmData(temp);
      const index = (tfinanceTrushViews as Array<TFinanceTrushView>).findIndex(object => object.id === tfinanceTrushViewSelected.id);
      if (index !== -1) {
        const arraySplice = tfinanceTrushViews ? [...tfinanceTrushViews] : [];
        arraySplice?.splice(index, 1);
        setTfinanceTrushViews(arraySplice);
      }

      showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเรียบร้อย!");
      form.resetFields();
      setTrigger(false);
      setOnConfirmModalVisible(false);
    } catch {
      showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ");
      setTrigger(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน !");
    setTrigger(false);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>ยืนยันการฝากเงิน</Typography.Title>
          </div>
        }
        visible={onConfirmModalVisible}
        width={"45%"}
        style={{ minHeight: "70%" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setOnConfirmModalVisible(false)}
      >
        <Spin spinning={showSpin}>
          <Form
            form={form}
            // labelCol={{ span: 5 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เลขที่การนำฝาก: </Typography.Text>
              </Col>
              <Col span={17}>
                <Form.Item
                  name="id"
                  style={{ marginBottom: 3 }}>
                  <Input
                    disabled
                    // style={{ width: "70%" }}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เลขที่บัญชี: </Typography.Text>
              </Col>
              <Col span={17}>
                <Space align='baseline' direction='horizontal'>
                  <Form.Item
                    name="accseq"
                    // labelCol={{ span: 9 }}
                    style={{ marginBottom: 3, width: "230px" }}
                    >
                    <Input
                      disabled
                    ></Input>
                  </Form.Item>
                  <Button
                    id='btnChangeAccseq'
                    onClick={() => setOnChangeAccseqModalVisible(true)}
                    type="link"
                  >เปลี่ยนเลขที่บัญชี
                  </Button>
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ประเภทการฝากเงิน: </Typography.Text>
              </Col>
              <Col span={17}>
                <Form.Item
                  name="type"
                  style={{ marginBottom: 3 }}>
                  <Input
                    disabled
                    // style={{ width: "70%" }}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เลขที่เช็ค: </Typography.Text>
              </Col>
              <Col span={17}>
                <Form.Item
                  name="chquenumber"
                  style={{ marginBottom: 3 }}>
                  <Input
                    disabled
                    // style={{ width: "70%" }}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>จำนวนเงินที่ฝาก: </Typography.Text>
              </Col>
              <Col span={17}>
                <Form.Item
                  name="amount"
                  style={{ marginBottom: 3 }}
                >
                  <InputNumber
                    controls={false}
                    formatter={(value) => formatNumber(value)}
                    parser={(value) => parserNumber(value)}
                    disabled
                    style={{
                      textAlign: "start",
                      width: "50%",
                    }}
                  ></InputNumber>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ผู้นำฝาก: </Typography.Text>
              </Col>
              <Col span={17}>
                <Form.Item
                  name="trushusername"
                  style={{ marginBottom: 3 }}>
                  <Input
                    disabled
                    // style={{ width: "70%" }}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>วันที่นำฝาก: </Typography.Text>
              </Col>
              <Col span={17}>
                <Form.Item
                  name="trushdate"
                  style={{ marginBottom: 3 }}
                >
                  <DatePicker
                    disabled
                    format={Constants.DATE_FORMAT}
                    style={{ width: "50%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เลขที่ใบ Payin: </Typography.Text>
              </Col>
              <Col span={17}>
                <Form.Item
                  name="payinnumber"
                  style={{ marginBottom: 3 }}>
                  <Input
                    // style={{ width: "70%" }}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>หมายเหตุ: </Typography.Text>
              </Col>
              <Col span={17}>
                <Form.Item
                  label=""
                  name="remark"
                  style={{ marginBottom: 3 }}
                >
                  <TextArea
                    // style={{ width: "70%" }}
                    rows={2}
                  ></TextArea>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
      <div>
        {onChangeAccseqModalVisible && (
          <ChangeAccountModal
            onChangeAccseqModalVisible={onChangeAccseqModalVisible}
            setOnChangeAccseqModalVisible={setOnChangeAccseqModalVisible}
            tfinanceTrushViewSelected={tfinanceTrushViewSelected}
            setTfinanceTrushViewSelected={setTfinanceTrushViewSelected}
            setRyAccountSelected={setRyAccountSelected}
            setIsUpdate={setIsUpdate}
          ></ChangeAccountModal>
        )}
      </div>
    </>
  )
}

export default TrushMoneyConfirmModal