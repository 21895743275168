import { Col, Form, Input, Space, Spin, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { TChequeHeadView } from 'src/domain/cheque/view/tChequeHeadView';
import tChequeHeadService from 'src/services/cheque/tChequeHeadService';
import { useAppSelector } from 'src/stores/store';
import Constants from 'src/utils/constants';
import { toThDate } from 'src/utils/dateTimeUtils';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, formatNumberInt } from 'src/utils/numberUtils';

const { Title } = Typography;

interface IProp {
  setTchequeHeads: any;
}

const ChequeTrushTab: React.FC<IProp> = (props) => {
  const { setTchequeHeads } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [countCheque, setCountCheque] = useState(0);//นับจำนวนเช็ค
  const [chequeAmt, setChequeAmt] = useState(0);//จำนวนเงินเช็คทั้งหมด
  const [selectedTChequeHeads, setSelectedTChequeHeads] = useState<TChequeHeadView[]>([]);
  const [tchequeHeadViewFormList, setTchequeHeadViewFormList] = useState<TChequeHeadView[]>([]);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 30,
      align: "center" as "center",
      render: (value: number, item: TChequeHeadView, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่เช็ค",
      dataIndex: "chequenumber",
      key: "chequenumber",
      width: 100
    },
    {
      title: "ธนาคาร",
      dataIndex: "bankName",
      key: "bankName",
      width: 150
    },
    {
      title: "สาขา",
      dataIndex: "bankBranchName",
      key: "bankBranchName",
      width: 100
    },
    {
      title: "ลงวันที่",
      dataIndex: "chequedate",
      key: "chequedate",
      width: 60,
      render: (value: any, item: TChequeHeadView) => {
        return <span>{toThDate(item.chequedate)}</span>;
      },
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "chequeamt",
      key: "chequeamt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TChequeHeadView) => {
        return <span>{formatNumber(item.chequeamt)}</span>;
      },
    },
    {
      title: "จำนวนครั้งที่นำฝาก",
      dataIndex: "chequetimetobank",
      key: "chequetimetobank",
      width: 100,
      align: "center" as "center",
      render: (value: any, item: TChequeHeadView) => {
        return <span>{item.chequetimetobank > 0 ? formatNumberInt(item.chequetimetobank) : "ยังไม่เคยนำฝาก"}</span>;
      },
    },
  ];

  const getItemColor = (item: TChequeHeadView) => {
    const now = moment().format(Constants.DB_FORMAT);
    let foreColor = "black";
    let backColor = "white";
    if (Number(item.chequedate) < Number(now)) {
      foreColor = 'red';
    }
    if (item.chequetimetobank >= 3) {
      backColor = "red";
    }

    return 'text-' + foreColor + '-bg-' + backColor;
  }
  
  useEffect(() => {
    initial();
  }, []);

  useEffect(() => {
    if (selectedTChequeHeads) {
      setTchequeHeads([...selectedTChequeHeads]);
    }
  }, [selectedTChequeHeads]);
  
  const initial = async () => {
    let chequeamountAll = 0;
    setLoading(true);
    const res = await tChequeHeadService.getByChequeStatus("00", userInfo.branchId);
    if (res.data.length) {
      res.data.forEach((obj: TChequeHeadView) => {
        chequeamountAll += (obj.chequeamt) ? obj.chequeamt : 0;
      });
    }
    setTchequeHeadViewFormList(res.data);
    setCountCheque(res.data.length);
    setChequeAmt(chequeamountAll);
    setLoading(false);
  }
  
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: TChequeHeadView[]) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedTChequeHeads(selectedRows);
      setTchequeHeads([...selectedRows]);
    },
  };

  const onChequeNoChange = (chequeno: string) => {
    if (!chequeno) {
      return;
    }

    const tChequeHeadView = tchequeHeadViewFormList.find((obj) => obj.chequenumber === chequeno);
    if(!tChequeHeadView) {
      showNotification(NotificationType.ERROR, "ไม่พบเช๊ค เลขที่ " + chequeno);
    } else if (tChequeHeadView!.chequestatus === "00" && tChequeHeadView!.chequetimetobank < 3) {
      setSelectedTChequeHeads([...selectedTChequeHeads, tChequeHeadView!]);
      const selectedRowKeysTemp = [...selectedRowKeys, tChequeHeadView!.id];
      setSelectedRowKeys(selectedRowKeysTemp);
    } else {
      showNotification(NotificationType.ERROR, "ไม่พบเช๊ค หรือ ฝากเช๊คเกิน 3 ครั้ง");
    }

    form.setFieldsValue({
      chequeNo: undefined,
    })
  }

  return (
    <>
      <Content className="app-page-content">
        <Spin spinning={loading}>
          <>
            <Form
              form={form}
              name="chequeTrushTab"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                  margin: "1em 0 0 0",
                  paddingLeft: "1em",
                }}
              >
                <Col span={14} style={{ textAlign: "left", paddingLeft: "3.5em" }}>
                  <Space align="start" direction='vertical'>
                    <Typography.Title level={4}>
                      <span>เลขที่เช็ค <label className="text-red">*</label>
                      </span>
                    </Typography.Title>
                    <Form.Item
                      name="chequeNo"
                      rules={[{ required: false }]}
                      style={{ width: 200 }}
                    >
                      <Input
                        allowClear={false}
                        onFocus={(e: any) => e.target.select()}
                        onKeyDown={(evt: any) => {
                          if (evt.keyCode === 13) {
                            onChequeNoChange(evt.target.value);
                          }
                        }}
                      ></Input>
                    </Form.Item>
                  </Space>
                </Col>
                <Col span={3} style={{ textAlign: "center" }}>
                  <Space align="center" direction='vertical'>
                    <Typography.Title level={4}>เช็คทั้งหมด</Typography.Title>
                    <Typography.Title level={5} style={{ margin: 0, color: "#FD1212" }}>
                      {formatNumberInt(countCheque)}
                    </Typography.Title>
                  </Space>
                </Col>
                <Col span={3} style={{ textAlign: "center" }}>
                  <Space align="center" direction='vertical'>
                    <Typography.Title level={4}>จำนวนเงินทั้งหมด</Typography.Title>
                    <Typography.Title level={5} style={{ margin: 0, color: "#FD1212" }}>
                      {formatNumber(chequeAmt)}
                    </Typography.Title>
                  </Space>
                </Col>
                <Col span={4}></Col>
              </div>
            </Form>
            <div
              style={{
                paddingLeft: "1em",
                borderBlockEnd: "2px solid #c9c9c9",
              }}
            >
            <Title level={5} type="danger">หมายเหตุ : รายการสีแดงคือรายการเช็คที่เลยเวลานำฝาก</Title>
            </div>
            <div style={{ marginBottom: 0 }}>
              <Table
                columns={columns}
                dataSource={tchequeHeadViewFormList}
                size="small"
                rowClassName={getItemColor}
                scroll={{ y: 250 }}
                pagination={false}
                rowSelection={rowSelection}
                rowKey={(item: TChequeHeadView) => item.id}
              />
            </div>
          </>
        </Spin>
      </Content>
    </>
  )
}

export default ChequeTrushTab