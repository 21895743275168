import { PlusSquareOutlined } from '@ant-design/icons';
import { Button, Space, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { TWorksheetHead } from 'src/domain/worksheet/tWorksheetHead';
import { TWshConstructionworkView } from 'src/domain/worksheet/view/tWshConstructionworkView';
import { WorksheetHeadData } from 'src/domain/worksheet/view/worksheetHeadData';
import { formatNumber } from 'src/utils/numberUtils';
import WorksheetCodeModal from '../modal/WorksheetCodeModal';

interface ModalProp {
	worksheetHeadData: WorksheetHeadData;
	setWorksheetHeadData: any;
	twshConstructionworkFormDataList: TWshConstructionworkView[];
	setTwshConstructionworkFormDataList: any;
	recalculateContractorWork: boolean;
	setRecalculateContractorWork: any;
}

const ContractorWorkDetailTab: React.FC<ModalProp> = (props) => {
	const { worksheetHeadData, setWorksheetHeadData, twshConstructionworkFormDataList, setTwshConstructionworkFormDataList
		, recalculateContractorWork, setRecalculateContractorWork } = props;
	const [visibleContractorWorkModal, setVisibleContractorWorkModal] = useState(false);
  const [twshConstructionworkFormDataRemoveList, setTwshConstructionworkFormDataRemoveList] = useState<TWshConstructionworkView[]>([]);

	const columns = [
		{
			title: "ลำดับ",
			dataIndex: "index",
			key: "index",
			align: "center" as "center",
			render: (value: any, item: TWshConstructionworkView, index: number) => {
				return <span>{index + 1}</span>;
			},
		},
		{
			title: "เลขที่ใบสั่งงาน",
			dataIndex: "worksheetConstruction",
			key: "worksheetConstruction",
		},
		{
			title: "รถ",
			dataIndex: "truckFullName",
			key: "truckFullName",
		},
		{
			title: "พนักงานขับรถ",
			dataIndex: "driverName",
			key: "driverName",
		},
		{
			title: "ราคาบริการ",
			dataIndex: "remainAmt",
			key: "remainAmt",
			align: "right" as "right",
			render: (value: any, item: TWshConstructionworkView, index: number) => {
				return <span>{formatNumber(item.remainAmt)}</span>;
			},
		},
		{
			title: "งาน",
			dataIndex: "serviceName",
			key: "serviceName",
		},
		{
			title: "",
			key: "action",
			align: "center" as "center",
			render: (value: any, item: TWshConstructionworkView, index: number) => {
				return (
					<span>
						<a onClick={(e) => { onDelete(item, index); }} style={{ color: "red" }}
						>
							X
						</a>
					</span>
				);
			},
		},
	];

	useEffect(() => {
		let sumConstructionworkAmount = 0;
		twshConstructionworkFormDataList.forEach((obj) => {
			sumConstructionworkAmount += (obj.remainAmt) ? obj.remainAmt : 0;
		});
		setWorksheetHeadData({
			...(worksheetHeadData as WorksheetHeadData),
			tworksheetHead: {
				...(worksheetHeadData.tworksheetHead as TWorksheetHead),
				iserviceAmt: sumConstructionworkAmount ? sumConstructionworkAmount : 0,
			},
			tworksheetConstructionworkViewList: [...reindex(twshConstructionworkFormDataList)],
			sumConstructionWorkAmt: sumConstructionworkAmount,
		});

		setRecalculateContractorWork(true);
	}, [twshConstructionworkFormDataList]);

	useEffect(() => {
		if (Object.keys(worksheetHeadData).length > 0 && recalculateContractorWork) {
			setRecalculateContractorWork(false);
		}
	}, [worksheetHeadData]);

	const reindex = (tWshConstructionworkView: TWshConstructionworkView[]) => {
		return tWshConstructionworkView.map((item, index) => {
			item.id = index;
			return item;
		});
	}

	const onDelete = (item: TWshConstructionworkView, index: number) => {
		const array = twshConstructionworkFormDataList ? [...twshConstructionworkFormDataList] : [];
		array?.splice(index, 1);
		setTwshConstructionworkFormDataList(array);
	}

	return (
		<>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "baseline",
					justifyContent: "space-between",
				}}
			>
				<Space align="baseline" direction="horizontal">
					<Button
						size="small"
						type="primary"
						icon={<PlusSquareOutlined />}
						onClick={() => setVisibleContractorWorkModal(true)}
					>
						เพิ่ม
					</Button>
				</Space>
				<Typography.Title
					type="danger"
					level={4}
					style={{ height: "22px", textAlign: "right" }}
				>
					จำนวนเงิน :{" "}
					{formatNumber(!worksheetHeadData?.sumConstructionWorkAmt ? 0 : worksheetHeadData?.sumConstructionWorkAmt)}
				</Typography.Title>
			</div>
			<div>
				<Table
					columns={columns}
					dataSource={twshConstructionworkFormDataList}
					size="small"
					pagination={false}
					rowKey={(item: TWshConstructionworkView) => item.id}
				/>
			</div>
			<div>
				{visibleContractorWorkModal && (
					<WorksheetCodeModal
						visibleContractorWorkModal={visibleContractorWorkModal}
						setVisibleContractorWorkModal={setVisibleContractorWorkModal}
						twshConstructionworkFormDataList={twshConstructionworkFormDataList}
						setTwshConstructionworkFormDataList={setTwshConstructionworkFormDataList}
            twshConstructionworkFormDataRemoveList={twshConstructionworkFormDataRemoveList}
            setTwshConstructionworkFormDataRemoveList={setTwshConstructionworkFormDataRemoveList}
						setRecalculateContractorWork={setRecalculateContractorWork}
					/>
				)}
			</div>
		</>
	)
}

export default ContractorWorkDetailTab