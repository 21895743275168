import { DeleteOutlined, PlusSquareOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Modal, Row, Space, Table, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { TFinancePayindetail } from 'src/domain/finance/tFinancePayindetail';
import { TPayinHead } from 'src/domain/tPayinHead';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber, textNumber } from 'src/utils/numberUtils';
import LookupTPayinHeadModal from './lookups/LookupPayinHeadModal';

interface IProps {
  searchTPayinHeadModalVisible: boolean;
  setSearchTPayinHeadModalVisible: any;
  tFinancePayinDetails: TFinancePayindetail[];
  setTFinancePayinDetails: any;
}

const SearchTPayinHeadModal: React.FC<IProps> = (props) => {
  const { searchTPayinHeadModalVisible, setSearchTPayinHeadModalVisible, tFinancePayinDetails, setTFinancePayinDetails } = props
  const [form] = Form.useForm();
  const [lookupTPayinHeadModalVisible, setLookupTPayinHeadModalVisible] = useState<boolean>(false);
  const [lookupTPayinHeadSelected, setLookupTPayinHeadSelected] = useState<TPayinHead>({} as TPayinHead);
  const [lookupTfinancePayinDetailList, setLookupTfinancePayinDetailList] = useState<TFinancePayindetail[]>([]);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: TFinancePayindetail, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่โอนเงิน",
      dataIndex: "payinid",
      key: "payinid",
      width: 85,
      align: "center" as "center",
    },
    {
      title: "ชื่อบัญชี",
      dataIndex: "accountname",
      key: "accountname",
      width: 150,
      align: "center" as "center",
    },
    {
      title: "เลขที่บัญชี",
      dataIndex: "accountnumber",
      key: "accountnumber",
      width: 120,
      align: "center" as "center",
    },
    {
      title: "จำนวนเงินที่ชำระ",
      dataIndex: "amount",
      key: "amount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TFinancePayindetail) => {
        return <span>{formatNumber(item.amount)}</span>;
      },
    },
    {
      title: "หมายเหตุ",
      dataIndex: "remarks",
      key: "remarks",
      width: 150,
      align: "center" as "center",
    },
    {
      title: "",
      key: "action",
      width: 50,
      align: "center" as "center",
      render: (value: any, item: TFinancePayindetail, index: number) => {
        return (< Button
          type="primary"
          size='small'
          danger
          shape="circle"
          icon={< DeleteOutlined />}
          onClick={() => onDelete(item, index)} />
        );
      },
    },
  ]

  useEffect(() => {
    loadData();
    return () => { };
  }, []);

  const loadData = async () => {
    if (searchTPayinHeadModalVisible) {
      if (tFinancePayinDetails) {
        setLookupTfinancePayinDetailList(tFinancePayinDetails ? [...tFinancePayinDetails] : []);
      }
    }
  };

  useEffect(() => {
    if (!lookupTPayinHeadModalVisible) {
      if (lookupTPayinHeadSelected && lookupTPayinHeadSelected.id) {
        form.setFieldsValue({
          payinId: lookupTPayinHeadSelected.id,
          payinAmount: formatNumber(lookupTPayinHeadSelected.amount),
          accountName: lookupTPayinHeadSelected.toaccountname + " - " + lookupTPayinHeadSelected.toaccountnumber,
          payinOwner: lookupTPayinHeadSelected.customername,
          amount: 0.00,
          payinRemainAmt: formatNumber(lookupTPayinHeadSelected.payinremainamt),

        });
      }
    }
  }, [lookupTPayinHeadModalVisible]);


  const handleChangeAmount = (data: number) => {
    const payinRemainAmt = lookupTPayinHeadSelected?.payinremainamt ? lookupTPayinHeadSelected?.payinremainamt : 0;
    if (data > payinRemainAmt) {
      showNotification(NotificationType.ERROR, "จำนวนเงินจะมากกว่ายอดคงเหลือไม่ได้!");
      form.setFieldsValue({
        amount: 0.00,
      });
    } else {
      let remainAmtTemp = payinRemainAmt - data;
      form.setFieldsValue({
        payinRemainAmt: formatNumber((remainAmtTemp >= 0) ? remainAmtTemp : payinRemainAmt),
      });
    }
  }

  const handleAdd = () => {
    if (Number(parserNumber(form.getFieldValue("amount"))) <= 0) {
      showNotification(NotificationType.ERROR, "กรุณาระบุจำนวนเงินใหม่อีกครั้งจำนวนเงินต้องมากกกว่า 0!");
      form.setFieldsValue({
        amount: 0.00,
      });
    } else {
      let financePayinDetailTemp = {} as TFinancePayindetail;
      const temp: TFinancePayindetail = {
        ...(financePayinDetailTemp as TFinancePayindetail),
        payinid: form.getFieldValue("payinId"),
        payinowner: form.getFieldValue("payinOwner"),
        payindate: lookupTPayinHeadSelected.depositdate,
        payinamount: Number(parserNumber(form.getFieldValue("payinAmount"))),
        amount: Number(parserNumber(form.getFieldValue("amount"))),
        customercode: lookupTPayinHeadSelected.customercode,
        financetype: lookupTPayinHeadSelected.financetype,
        branchid: lookupTPayinHeadSelected.branchid,
        remarks: form.getFieldValue("remarks"),
        accountnumber: lookupTPayinHeadSelected.toaccountnumber,
        accountname: lookupTPayinHeadSelected.toaccountname,
      }
      const array = lookupTfinancePayinDetailList ? [...lookupTfinancePayinDetailList] : [];
      array.push(temp);
      setLookupTfinancePayinDetailList(array);
      form.resetFields();
    }
  };

  const onDelete = (item: TFinancePayindetail, index: number) => {
    const array = lookupTfinancePayinDetailList ? [...lookupTfinancePayinDetailList] : [];
    array?.splice(index, 1);
    setLookupTfinancePayinDetailList(array);
  }

  const onFinish = () => {
    setTFinancePayinDetails([...lookupTfinancePayinDetailList]);
    setSearchTPayinHeadModalVisible(false);
  }

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>รับชำระเงินด้วยเงินโอน</Typography.Title>
          </div>
        }
        visible={searchTPayinHeadModalVisible}
        width={"55%"}
        style={{ minHeight: "70%" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={onFinish}
        onCancel={() => setSearchTPayinHeadModalVisible(false)}
      >
        <Form
          form={form}
          name="financePayinDetails"
          labelCol={{ span: 5 }}
          initialValues={{ remember: true }}
        >
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>เลขที่อ้างอิงการโอน: </Typography.Text>
            </Col>
            <Col span={8}>
              <Space align='baseline' style={{ width: "80%" }}>
                <Form.Item
                  name="payinId"
                  rules={[{ required: true, message: "กรุณาระบุเลขที่อ้างอิงการโอน!" }]}
                >
                  <Input style={{ width: 200, backgroundColor: "#F7F7F8" }} readOnly></Input>
                </Form.Item>

                <Button
                  id='btnSearchPayinHead'
                  onClick={() => setLookupTPayinHeadModalVisible(true)}
                  type="primary"
                  icon={<SearchOutlined />}
                >
                </Button>
              </Space>
            </Col>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>ยอดเงินโอน: </Typography.Text>
            </Col>
            <Col span={8}>
              <Form.Item
                name="payinAmount"
                rules={[{ required: true, message: "กรุณาระบุยอดเงินโอน!" }]}
                style={{ margin: "0 0 3px 0px" }}
              >
                <InputNumber
                  style={{ textAlign: "right", width: 203, backgroundColor: "#F7F7F8" }} readOnly
                >
                </InputNumber>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>ชื่อ-เลขที่บัญชี: </Typography.Text>
            </Col>
            <Col span={19}>
              <Form.Item
                label=""
                name="accountName"
                rules={[{ required: true, message: "กรุณาระบุ ชื่อ-เลขที่บัญชี !" }]}
              >
                <Input style={{ backgroundColor: "#F7F7F8" }} readOnly></Input>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>ลูกค้า: </Typography.Text>
            </Col>
            <Col span={19}>
              <Form.Item
                label=""
                name="payinOwner"
                rules={[{ required: true, message: "กรุณาระบุ ลูกค้า !" }]}
              >
                <Input style={{ backgroundColor: "#F7F7F8" }} readOnly></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>ยอดชำระ: </Typography.Text>
            </Col>
            <Col span={8}>
              <Form.Item
                name="amount"
                rules={[{ required: true, message: "กรุณาระบุ ยอดชำระ !" }]}
              >
                <Input
                  type="text"
                  onKeyDown={(event: any) => textNumber(event)}
                  onPressEnter={(e: any) => form.setFieldsValue({ amount: formatNumber(parserNumber(e.target.value)) })}
                  onBlur={(e: any) => form.setFieldsValue({ amount: formatNumber(parserNumber(e.target.value)) })}
                  onFocus={(e) => e.target.select()}
                  style={{ textAlign: "end", width: "100%" }}
                  onChange={(e: any) => handleChangeAmount(parserNumber(e.target.value))}
                ></Input>
              </Form.Item>
            </Col>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>จำนวนคงเหลือ: </Typography.Text>
            </Col>
            <Col span={8}>
              <Form.Item
                name="payinRemainAmt"
                rules={[{ required: true, message: "กรุณาระบุ จำนวนคงเหลือ !" }]}
              >
                <InputNumber
                  style={{ textAlign: "right", width: 203, backgroundColor: "#F7F7F8" }} readOnly
                  controls={false}
                  precision={2}
                >
                </InputNumber>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>หมายเหตุ: </Typography.Text>
            </Col>
            <Col span={19}>
              <Form.Item
                name="remarks"
                rules={[{ required: false }]}
              >
                <TextArea
                  rows={2}
                ></TextArea>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={11}></Col>
            <Col span={13}>
              <Button
                size="small"
                type="primary"
                style={{ marginBottom: "15px" }}
                icon={<PlusSquareOutlined />}
                onClick={handleAdd}
              >
                เพิ่ม
              </Button>
            </Col>
          </Row>

          <div style={{ padding: "10px 0px 0px" }}>
            <Table
              columns={columns}
              dataSource={lookupTfinancePayinDetailList}
              size="small"
              rowKey={(item: TFinancePayindetail) => item.payinid}
              pagination={false}
              style={{ minHeight: 200 }}
            />
          </div>
        </Form>
      </Modal>
      <div>
        {lookupTPayinHeadModalVisible && (
          <LookupTPayinHeadModal
            lookupTPayinHeadModalVisible={lookupTPayinHeadModalVisible}
            setLookupTPayinHeadModalVisible={setLookupTPayinHeadModalVisible}
            setLookupTPayinHeadSelected={setLookupTPayinHeadSelected}
            lookupTfinancePayinDetailList={lookupTfinancePayinDetailList}
          ></LookupTPayinHeadModal>
        )}
      </div>
    </>
  )
}

export default SearchTPayinHeadModal