import http from "../../lib/http";
import Constants from "../../utils/constants";

const getTAssetBranchViews = (assetCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tAssetBranch/findAllByAssetCode", {
    params: {
      assetCode
    }
  });
};

const postTAssetBranch = (tAssetBranch: any) => {
  return http.post(Constants.DEFAULT_URL + "/tAssetBranch", tAssetBranch);
}

const deletetTAssetBranch = (id: number) => {
  return http.delete(Constants.DEFAULT_URL + "/tAssetBranch/" + id);
}

const defaultApi = {
  getTAssetBranchViews,
  postTAssetBranch,
  deletetTAssetBranch,
};
export default defaultApi;