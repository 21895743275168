import { Modal, Table, Typography } from 'antd';
import React from 'react';
import { TWorksheetHeadView } from 'src/domain/worksheet/view/tWorksheetHeadView';
import { toThDate } from 'src/utils/dateTimeUtils';
import { formatNumber } from 'src/utils/numberUtils';

interface IProps {
  debitorWorksheetModalVisible: boolean;
  setDebitorWorksheetModalVisible: any;
  worksheetHeadAlls: TWorksheetHeadView[];
}

const DebitorWorksheetWaitWangbillListModal: React.FC<IProps> = (props) => {
  const { debitorWorksheetModalVisible, setDebitorWorksheetModalVisible, worksheetHeadAlls } = props;

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 50,
      align: "center" as "center",
      render: (value: number, item: TWorksheetHeadView, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่ใบสั่งงาน",
      dataIndex: "iworksheetCode",
      key: "iworksheetCode",
      width: 120,
    },
    {
      title: "รายการ",
      dataIndex: "serviceDetailName",
      key: "serviceDetailName",
      width: 300,
    },
    {
      title: "วัน-เวลาที่ทำงาน",
      dataIndex: "workDate",
      key: "workDate",
      width: 190,
      render: (value: any, item: TWorksheetHeadView) => (<>{toThDate(item.istartDate)} - {toThDate(item.iendDate)}</>)
    },
    {
      title: "จำนวนเงินคงค้าง(บาท)",
      dataIndex: "remainAmt",
      key: "remainAmt",
      width: 140,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iremainAmt)}</span>;
      },
    },
    {
      title: "ค่าบริการ",
      dataIndex: "iserviceAmt",
      key: "iserviceAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iserviceAmt)}</span>;
      },
    },
    {
      title: "OT",
      dataIndex: "iotAmt",
      key: "iotAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iotAmt)}</span>;
      },
    },
    {
      title: "ค่าอื่นๆ",
      dataIndex: "iextraAmt",
      key: "iextraAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iextraAmt)}</span>;
      },
    },
    {
      title: "ส่วนลด",
      dataIndex: "idiscount",
      key: "idiscount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.idiscount)}</span>;
      },
    },
    {
      title: "รวม",
      dataIndex: "total1Amt",
      key: "total1Amt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itotal1Amt)}</span>;
      },
    },
    {
      title: "ภาษี",
      dataIndex: "vatAmt",
      key: "vatAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itaxAmt)}</span>;
      },
    },
    {
      title: "สุทธิ",
      dataIndex: "total2Amt",
      key: "total2Amt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itotal2Amt)}</span>;
      },
    },
    {
      title: "ชำระแล้ว",
      dataIndex: "payedamount",
      key: "payedamount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.payedamount)}</span>
      },
    },
  ];

  const getItemColor = (item: TWorksheetHeadView) => {
    let foreColor = "";
    if (item.itypeworksheetCode === "01") {
      foreColor = "red";
    } else {
      foreColor = "blue";
    }
    return 'text-' + foreColor;
  }
  
  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>ใบงานทั้งหมด</Typography.Title>
          </div>
        }
        visible={debitorWorksheetModalVisible}
        width={"75%"}
        style={{height: "60%"}}
        closable={true}
        destroyOnClose
        maskClosable={true}
        okButtonProps={{ style: { display: 'none' } }}        
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setDebitorWorksheetModalVisible(false)}
      >
        <div style={{ padding: "10px 0px 0px" }}>
          <Table
            columns={columns}
            dataSource={worksheetHeadAlls}
            rowClassName={getItemColor}
            size="small"
            style={{ minHeight: 300 }}
            scroll={{ x: 1800, y: 400 }}
            pagination={false}
            rowKey={(item: TWorksheetHeadView) => item.ireserveCode!}
          />
        </div>
      </Modal>
    </>
  )
}

export default DebitorWorksheetWaitWangbillListModal