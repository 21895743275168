import http from "../../lib/http";
import Constants from "../../utils/constants";

const postEmployeeChange = (employeeChange: any) => {
  return http.post(Constants.DEFAULT_URL + "/tEmployeechange", employeeChange);
}

const defaultApi = {
  postEmployeeChange,
};
export default defaultApi;
