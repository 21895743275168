import { ExceptionOutlined } from '@ant-design/icons';
import { Button, Card, Descriptions, Form, Spin } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { TFinanceTrushView } from 'src/domain/finance/view/tFinanceTrushView';
import PageHeader from 'src/layouts/PageHeader';
import tFinanceTrushService from 'src/services/finance/tFinanceTrushService';
import { toThDate } from 'src/utils/dateTimeUtils';
import { formatNumber } from 'src/utils/numberUtils';
import ViewTrushMonetCashListDrawer from './ViewTrushMonetCashListDrawer';

interface IParams {
  id: string;
}
const ViewTrushMoney: React.FC<RouteComponentProps<IParams>> = (props) => {
  const trushId = props.match.params.id;
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [tfinanceTrushView, setTfinanceTrushView] = useState<TFinanceTrushView>({} as TFinanceTrushView);
  const [onDrawerCashVisible, setOnDrawerCashVisible] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const res = await tFinanceTrushService.findTFinanceTrushViews(trushId, "", "", "", "");
      setTfinanceTrushView(res.data[0]);
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <PageHeader title="" onClose={() => history.push("/app/trushMoney/list")} />
      <Spin spinning={loading}>
        <Content className="app-page-content">
          <Descriptions
            style={{ margin: "1em" }}
            column={4}
            title="ข้อมูลการฝากเงินเข้าธนาคาร"
            size="middle"
          />
          <div style={{ margin: "1em" }}>
            <Form
              form={form}
              name="viewTrushMoney"
            >
              <Card
                style={{
                  width: "auto",
                  borderRadius: "10px 10px 10px 10px",
                  margin: "5px 0px 5px 0px",
                }}
              >
                <Descriptions
                  column={6}
                  size="small"
                  bordered
                  style={{ paddingLeft: '10em' }}
                  labelStyle={{ textAlign: "right", fontWeight: "bold", width: "200px" }}
                >
                  <Descriptions.Item label="วันที่นำฝาก" span={6}>{toThDate(tfinanceTrushView.trushdate)}</Descriptions.Item>
                  <Descriptions.Item label="เลขที่การนำฝาก" span={6}>{tfinanceTrushView.id}
                    <Button
                      style={{
                        marginLeft: "20px"
                      }}
                      type="primary"
                      disabled={tfinanceTrushView.type === "CHQ"}
                      icon={<ExceptionOutlined />}
                      onClick={() => setOnDrawerCashVisible(true)}
                    >
                      รายละเอียดรายการเงินสด
                    </Button>
                  </Descriptions.Item>
                  <Descriptions.Item label="เลขที่บัญชี" span={6}>{tfinanceTrushView.accountNumber} : {tfinanceTrushView.accountName}</Descriptions.Item>
                  <Descriptions.Item label="ประเภทการฝากเงิน" span={6}>{tfinanceTrushView.type}</Descriptions.Item>
                  <Descriptions.Item label="เลขที่เช็ค" span={6}>{tfinanceTrushView.chquenumber ? tfinanceTrushView.chquenumber : "-"}</Descriptions.Item>
                  <Descriptions.Item label="จำนวนเงินที่ฝาก" span={6}>{formatNumber(tfinanceTrushView.amount)}</Descriptions.Item>
                  <Descriptions.Item label="ผู้นำฝาก" span={6}>{tfinanceTrushView.trushusername}</Descriptions.Item>
                  <Descriptions.Item label="สาขานำฝาก" span={6}>{tfinanceTrushView.branchName}</Descriptions.Item>
                  <Descriptions.Item label="หมายเหตุนำฝาก" span={6}>{tfinanceTrushView.remark}</Descriptions.Item>
                </Descriptions>
              </Card>
            </Form>
          </div>
          <div>
            {onDrawerCashVisible && (
              <ViewTrushMonetCashListDrawer
                onDrawerCashVisible={onDrawerCashVisible}
                setOnDrawerCashVisible={setOnDrawerCashVisible}
                trushId={trushId}
              ></ViewTrushMonetCashListDrawer>
            )}
          </div>
        </Content>
      </Spin>
    </>
  )
}

export default ViewTrushMoney