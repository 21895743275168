import http from "src/lib/http";
import Constants from "src/utils/constants";

const getTOtherHeadList = () => {
  return http.get(
    Constants.DEFAULT_URL + "/tOtherHead"
  );
};

const getTOtherHead = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/tOtherHead/" + id);
}

const defaultApi = {
  getTOtherHead,
  getTOtherHeadList,
};
export default defaultApi;