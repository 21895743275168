import { Col, Descriptions, Form, Input, Radio, Row, Space, Spin, Table, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import PageHeader from "src/layouts/PageHeader";
import tFinanceHead2Service from "src/services/finance/tFinanceHead2Service";

import { TDebitorHead } from 'src/domain/finance/tDebitorHead';
import { TFinanceHead2View } from 'src/domain/finance/view/tFinanceHead2View';
import { TRangradWangbillHeadView } from 'src/domain/view/tRangradWangbillHeadView';
import { TWorksheetRangradWangbillView } from 'src/domain/worksheet/view/tWorksheetRangradWangbillView';
import tRangradWangbillDetailService from 'src/services/tRangradWangbillDetailService';
import tRangradWangbillHeadService from 'src/services/tRangradWangbillHeadService';
import { toThDate } from 'src/utils/dateTimeUtils';
import { formatNumber } from 'src/utils/numberUtils';
import tDebitorService from "../../services/debitor/tDebitorService";

interface IParams {
  idebitnoteNo: string;
}

const ViewFinanceReciveableFromRangRad: React.FC<RouteComponentProps<IParams>> = (props) => {
  const idebitnoteNo = props.match.params.idebitnoteNo;
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [netTotal, setNetTotal] = useState(0.00);

  const [tDebitorHead, setTDebitorHead] = useState<TDebitorHead>({} as TDebitorHead);
  const [tFinanceHead2, setTFinanceHead2] = useState<TFinanceHead2View>({} as TFinanceHead2View);
  const [tRangradWangbillHeadView, setTRangradWangbillHeadView] = useState<TRangradWangbillHeadView>({} as TRangradWangbillHeadView);
  const [tWorksheetRangradWangbillViews, setTWorksheetRangradWangbillViews] = useState<TWorksheetRangradWangbillView[]>([]);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: TWorksheetRangradWangbillView, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่ใบงาน",
      dataIndex: "iworksheetCode",
      key: "iworksheetCode",
      width: 120,
      align: "center" as "center",
    },
    {
      title: "รายการ",
      dataIndex: "truckNumber",
      key: "truckNumber",
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "iserviceAmt",
      key: "iserviceAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        return <span>{formatNumber(item.iserviceAmt)}</span>;
      },
    },
    {
      title: "OT",
      dataIndex: "iotAmt",
      key: "iotAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        return <span>{formatNumber(item.iotAmt)}</span>;
      },
    },
    {
      title: "ค่าพิเศษ",
      dataIndex: "iextraAmt",
      key: "iextraAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        return <span>{formatNumber(item.iextraAmt)}</span>;
      },
    },
    {
      title: "ส่วนลด",
      dataIndex: "idiscount",
      key: "idiscount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        return <span>{formatNumber(item.idiscount)}</span>;
      },
    },
    {
      title: "รวม",
      dataIndex: "total1Amt",
      key: "total1Amt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        let total1 = 0.00;
        let vatamt = 0.00;
        let serviceamt = (item.iserviceAmt ? Number(item.iserviceAmt) : 0.00) + (item.iextraAmt ? Number(item.iextraAmt) : 0.00) +
          (item.iotAmt ? Number(item.iotAmt) : 0.00) + (item.idiscount ? Number(item.idiscount) : 0.00)
        if (item.itypetaxCode === '01') {
          vatamt = Math.round(serviceamt * 7 / 100);
          total1 = serviceamt;
        }
        if (item.itypetaxCode === '02') {
          vatamt = Math.round(serviceamt * 7 / 107);
          total1 = serviceamt - vatamt;
        }
        if (item.itypetaxCode === '03') {
          vatamt = 0;
          total1 = serviceamt;
        }
        return <span>{formatNumber(total1)}</span>;
      },
    },
    {
      title: "ภาษี",
      dataIndex: "vatAmt",
      key: "vatAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        let vatamt = 0.00;
        let serviceamt = (item.iserviceAmt ? Number(item.iserviceAmt) : 0.00) + (item.iextraAmt ? Number(item.iextraAmt) : 0.00) +
          (item.iotAmt ? Number(item.iotAmt) : 0.00) + (item.idiscount ? Number(item.idiscount) : 0.00)
        if (item.itypetaxCode === '01') {
          vatamt = Math.round(serviceamt * 7 / 100);
        }
        if (item.itypetaxCode === '02') {
          vatamt = Math.round(serviceamt * 7 / 107);
        }
        if (item.itypetaxCode === '03') {
          vatamt = 0;
        }

        return <span>{formatNumber(vatamt)}</span>;
      },
    },
    {
      title: "สุทธิ",
      dataIndex: "total2Amt",
      key: "total2Amt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        let total1 = 0.00;
        let vatamt = 0.00;
        let serviceamt = (item.iserviceAmt ? Number(item.iserviceAmt) : 0.00) + (item.iextraAmt ? Number(item.iextraAmt) : 0.00) +
          (item.iotAmt ? Number(item.iotAmt) : 0.00) + (item.idiscount ? Number(item.idiscount) : 0.00)
        if (item.itypetaxCode === '01') {
          vatamt = Math.round(serviceamt * 7 / 100);
          total1 = serviceamt;
        }
        if (item.itypetaxCode === '02') {
          vatamt = Math.round(serviceamt * 7 / 107);
          total1 = serviceamt - vatamt;
        }
        if (item.itypetaxCode === '03') {
          vatamt = 0;
          total1 = serviceamt;
        }

        return <span>{formatNumber(total1 + vatamt)}</span>;
      },
    },
    {
      title: "ยอดคงเเหลือ",
      dataIndex: "remaint",
      key: "remaint",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetRangradWangbillView) => {
        let total1 = 0.00;
        let vatamt = 0.00;
        let serviceamt = (item.iserviceAmt ? Number(item.iserviceAmt) : 0.00) + (item.iextraAmt ? Number(item.iextraAmt) : 0.00) +
          (item.iotAmt ? Number(item.iotAmt) : 0.00) + (item.idiscount ? Number(item.idiscount) : 0.00)
        if (item.itypetaxCode === '01') {
          vatamt = Math.round(serviceamt * 7 / 100);
          total1 = serviceamt;
        }
        if (item.itypetaxCode === '02') {
          vatamt = Math.round(serviceamt * 7 / 107);
          total1 = serviceamt - vatamt;
        }
        if (item.itypetaxCode === '03') {
          vatamt = 0;
          total1 = serviceamt;
        }
        if (item.payedamount && item.payedamount === 0) {
          return <span>{formatNumber((total1 + vatamt) - (item.idepositAmt ? Number(item.idepositAmt) : 0) + (item.purchasetax ? Number(item.purchasetax) : 0))}</span>;
        } else {
          return <span>{formatNumber((total1 + vatamt) - (item.payedamount ? Number(item.payedamount) : 0))}</span>;
        }
      },
    },
    {
      title: "หมายเหตุ",
      dataIndex: "iremark",
      key: "iremark",
    },
  ];

  useEffect(() => {
    initial();
  }, []);

  useEffect(() => {
    dispalyData();
  }, [tRangradWangbillHeadView]);

  useEffect(() => {
    recalculate();
  }, [tFinanceHead2]);

  const initial = async () => {
    setLoading(true);
    const tRangradWangbillHeadRes = await tRangradWangbillHeadService.getViewByDebitNoteNo(idebitnoteNo);
    const tWorksheetRangradWangbillViewRes = await tRangradWangbillDetailService.getWorksheetRangradByDebitNoteCode(idebitnoteNo);

    setTRangradWangbillHeadView(tRangradWangbillHeadRes.data)
    setTWorksheetRangradWangbillViews(tWorksheetRangradWangbillViewRes.data);
  }

  const dispalyData = async () => {
    if (tRangradWangbillHeadView.idebitorCode) {
      const tDebitorHeadRes = await tDebitorService.getDebitorHeadById(tRangradWangbillHeadView.idebitorCode)
      const tFinanceHead2Res = await tFinanceHead2Service.getTFinanceHead2CashPaymentViewData(tRangradWangbillHeadView.paymentcode.toString());
      setTDebitorHead(tDebitorHeadRes.data);
      setTFinanceHead2(tFinanceHead2Res.data);
      setLoading(false);
    }
  }

  const recalculate = async () => {
    let netTotal = (tRangradWangbillHeadView.total ? tRangradWangbillHeadView.total : 0) + (tRangradWangbillHeadView.vat ? tRangradWangbillHeadView.vat : 0) - (tRangradWangbillHeadView.tax ? tRangradWangbillHeadView.tax : 0);
    const total = (tRangradWangbillHeadView.total ? tRangradWangbillHeadView.total : 0) + (tRangradWangbillHeadView.vat ? tRangradWangbillHeadView.vat : 0);

    form.setFieldsValue({
      worksheetInOut: tRangradWangbillHeadView.typeDebitnote === "01" ? "OUT" : "IN",
      numServiceAmt: formatNumber((tRangradWangbillHeadView.total ? tRangradWangbillHeadView.total : 0)),
      numVatAmt: formatNumber((tRangradWangbillHeadView.vat ? tRangradWangbillHeadView.vat : 0)),
      numTotal: formatNumber(total),
      numNetTotal: formatNumber(netTotal),
      cashAmount: formatNumber(tFinanceHead2.cashamount),
      chequeAmount: formatNumber(tFinanceHead2.chequeamount),
      payinAmount: formatNumber(tFinanceHead2.payinamount),
      otherReceiveAmount: formatNumber(tFinanceHead2.otheramount),
      creditNoteDiscountAmt: formatNumber(tFinanceHead2.creditnoteDiscount),
      numTaxAmount: formatNumber(tFinanceHead2.taxamount),
      remark: tFinanceHead2.remarks,
      receiveDocument: tFinanceHead2.worksheetReturnStatus === 1 ? "YES" : "NO",
      numNetAmount: formatNumber(tRangradWangbillHeadView.paid),
    });
    setNetTotal(netTotal);
  }

  return (
    <>
      <PageHeader title="" onClose={() => history.push("/app/financeReciveableFromRangRad/list")} />
      <Spin spinning={loading}>
        <Content className="app-page-content">
          <Descriptions
            style={{ margin: "1em" }}
            column={4}
            title="ข้อมูลการเงินรับชำระเงินเร่งรัด"
            size="middle"
          />
          <div style={{ margin: "1em" }}>
            <Form
              form={form}
              name="receiveRangradWorksheet"
              labelCol={{ span: 10 }}
            >
              <Space direction="vertical" size="middle">
                <Descriptions
                  column={6}
                  size="small"
                  bordered
                  style={{ paddingLeft: '10em' }}
                  labelStyle={{ textAlign: "right", fontWeight: "bold" }}
                >
                  <Descriptions.Item label="ชื่อลูกหนี้" span={2}>({tRangradWangbillHeadView.idebitorCode}) {tRangradWangbillHeadView.debitorname}</Descriptions.Item>
                  <Descriptions.Item label="วันที่รับชำระ" span={2}>{tRangradWangbillHeadView.tfinanceHead2View ? toThDate(tRangradWangbillHeadView.tfinanceHead2View.entrydate) : ""}</Descriptions.Item>
                  <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fff" }}> </Descriptions.Item>
                  <Descriptions.Item label="เบอร์โทร" span={2}>{tDebitorHead.itelephoneNumber} {tDebitorHead.imobilenumber}</Descriptions.Item>
                  <Descriptions.Item label="เลขที่ใบแจ้งหนี้" span={2}>{tRangradWangbillHeadView.idebitnoteNo}</Descriptions.Item>
                  <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fff" }}> </Descriptions.Item>
                  <Descriptions.Item label="ยอดรับชำระ" span={2}>{formatNumber(netTotal)}</Descriptions.Item>
                </Descriptions>
                <Descriptions
                  extra={
                    <Space align='baseline' direction='vertical'>
                      <Typography.Text strong>ประเภทใบแจ้งหนี้</Typography.Text>
                      <Form.Item
                        name="worksheetInOut"
                        style={{ margin: "0px" }}
                      >
                        <Radio.Group
                          style={{ margin: "0px" }}
                          disabled
                        >
                          <Space >
                            <Radio disabled value="IN" style={{ padding: "4px 0px 0px 4px", borderRadius: "3px", backgroundColor: "blue", color: "#FFF" }}>ใบสั่งงานใน</Radio>
                            <Radio disabled value="OUT" style={{ padding: "4px 0px 0px 4px", borderRadius: "3px", backgroundColor: "red", color: "#FFF" }}>ใบสั่งงานนอก</Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </Space>
                  }
                >
                </Descriptions>
                </Space>
                <Table
                  columns={columns}
                  dataSource={tWorksheetRangradWangbillViews}
                  size="small"
                  scroll={{ x: 1300 }}
                  rowKey={(item: TWorksheetRangradWangbillView) => item.ireserveCode!}
                  pagination={false}
                />
                <Row>
                  <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                    <Typography.Text>หมายเหตุ: </Typography.Text>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      name="remark"
                      rules={[{ required: true, message: "กรุณาระบุหมายเหตุ!" }]}
                    >
                      <TextArea
                        style={{ border: 0, backgroundColor: "#ffff" }}
                        rows={2}
                        readOnly
                      ></TextArea>
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    <Space align='baseline' direction='vertical'>
                      <Typography.Text strong>รับเอกสารใบงาน</Typography.Text>
                      <Form.Item
                        name="receiveDocument"
                        style={{ margin: "0px" }}
                      >
                        <Radio.Group
                          style={{ margin: "0px" }}
                          disabled
                        >
                          <Radio disabled value="NO" style={{ padding: "4px 0px 0px 4px", borderRadius: "3px", backgroundColor: "blue", color: "#FFF" }}>ไม่ได้รับเอกสาร</Radio>
                          <Radio disabled value="YES" style={{ padding: "4px 0px 0px 4px", borderRadius: "3px", backgroundColor: "red", color: "#FFF" }}>ได้รับเอกสารแล้ว</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Space>
                  </Col>
              </Row>
              <Row>
                <Col span={12} style={{ textAlign: "right", padding: "0.5em" }}>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ส่วนลด(ใบลดหนี้): </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                        name="creditNoteDiscountAmt"
                        style={{ margin: "0 0 3px 0px" }}
                      >
                        <Input style={{ border: 0, textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }} readOnly></Input>
                      </Form.Item>
                      <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
              </Row>
              <Row style={{ padding: "10px 0px 0px" }}>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>จำนวนเงินก่อนภาษี: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="numServiceAmt"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เงินสด: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="cashAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input
                        style={{ border: 0, textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }} readOnly
                      >
                      </Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
              </Row>

              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ภาษี: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="numVatAmt"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input
                        style={{ border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly ></Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เช็ค: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="chequeAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input
                        style={{ border: 0, textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }} readOnly
                      >
                      </Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เป็นเงิน: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="numTotal"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เงินโอน: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="payinAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input
                        style={{ border: 0, textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }} readOnly
                      >
                      </Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>หัก ณ ที่จ่าย: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="numTaxAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>รายรับอื่นๆ: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="otherReceiveAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input
                        style={{ border: 0, textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }} readOnly
                      >
                      </Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>คงเหลือต้องชำระ: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="numNetTotal"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input
                        style={{ border: 0, textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }}
                        readOnly
                      >
                      </Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>รวมรับชำระ: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="numNetAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input
                        style={{ border: 0, textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }}
                        readOnly
                      >
                      </Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
              </Row>
            </Form>
          </div>
        </Content>
      </Spin>
    </>
  );
};

export default ViewFinanceReciveableFromRangRad