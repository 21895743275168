import http from "src/lib/http";
import Constants from "src/utils/constants";

const getRyReftypeworkList = () => {
  return http.get(
    Constants.DEFAULT_URL + "/ryReftypework"
  );
};

const getRyReftypework = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/ryReftypework/" + id);
}

const defaultApi = {
  getRyReftypework,
  getRyReftypeworkList,
};
export default defaultApi;