import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Form, Input, Modal } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BsProvinceH } from "src/domain/bsProvinceH";
import { useAppSelector } from "src/stores/store";
import { BsAmphurH } from "../../domain/bsAmphurH";
import bsAmphurHService from "../../services/province/bsAmphurHService";
import { NotificationType, showNotification } from "../../utils/notificationUtils";

const { confirm } = Modal;

interface IParams {
  iid: string;
  bsProvinceH: BsProvinceH;
  visibleBsAmphurHModal: boolean;
  setVisibleBsAmphurHModal: any;
}

const AddEditBsAmphurHModal: React.FC<IParams> = (props) => {
  const { iid, bsProvinceH, visibleBsAmphurHModal, setVisibleBsAmphurHModal } =
    props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [bsAmphurH, setBsAmphurH] = useState<BsAmphurH>({} as BsAmphurH);
  const [form] = Form.useForm();
  const history = useHistory();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  let title: string = "เพิ่มข้อมูลอำเภอ";
  if (iid) {
    title = "แก้ไขข้อมูลอำเภอ";
  }

  useEffect(() => {
    if (trigger) {
      form.validateFields()
        .then(() => {
          confirm({
            title: "ยืนยัน!",
            icon: <ExclamationCircleOutlined />,
            content: "คุณต้องการที่จะบันทึกข้อมูลหรือไม่?",
            cancelText: "ยกเลิก",
            okText: "ยืนยัน",
            onOk() {
              form.submit();
            },
            onCancel() {
              setTrigger(false);
            }
          });
        }).catch(() => {
          setTrigger(false);
        });
    }
  }, [trigger]);

  useEffect(() => {
    loadBsAmphurH();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      iprovinceId: bsProvinceH.iid,
      iamphurName: bsAmphurH.iamphurName,
    });
  }, [bsAmphurH]);

  const loadBsAmphurH = async () => {
    if (iid) {
      const res = await bsAmphurHService.getBsAmphurHById(Number(iid));
      setBsAmphurH(res.data);
    } else {
      setBsAmphurH({ ...bsAmphurH, iprovinceId: bsProvinceH.iid });
    }
  };

  const onFinish = async (values: any) => {
    const _bsAmphurH: BsAmphurH = {
      iid: Number(iid),
      iprovinceId: bsProvinceH.iid,
      iamphurName: values.iamphurName,
      ientryUser: userInfo.userId,
    };

    if (!iid) {
      try {
        await bsAmphurHService.postBsAmphurH(_bsAmphurH);
        showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลอำเภอเรียบร้อยแล้ว!");
        form.resetFields();
        setTrigger(false);
        setVisibleBsAmphurHModal(false);
        history.replace("/app/bsProvinceH/view/" + bsProvinceH.iid);
      } catch (err) {
        showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        setTrigger(false);
      }
    } else {
      try {
        await bsAmphurHService.updateBsAmphurH(_bsAmphurH);
        showNotification(NotificationType.SUCCESS, "แก้ไขข้อมูลอำเภอเรียบร้อยแล้ว!");
        form.resetFields();
        setTrigger(false);
        setVisibleBsAmphurHModal(false);
        history.replace("/app/bsProvinceH/view/" + bsProvinceH.iid);
      } catch (err) {
        showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        setTrigger(false);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน");
    setTrigger(false);
  };

  return (
    <>
      <Modal
        title={title}
        cancelText={"ยกเลิก"}
        okText={"บันทึก"}
        visible={visibleBsAmphurHModal}
        width={700}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setVisibleBsAmphurHModal(false)}
      >
        <Content className="app-page-content">
          <Form
            form={form}
            name="bsAmphurH"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="รหัส"
              name="iprovinceId"
              rules={[{ required: true }]}
            >
              <Input disabled={true} style={{ width: 100 }} />
            </Form.Item>

            <Form.Item
              label="ชื่ออำเภอ"
              name="iamphurName"
              rules={[
                {
                  required: true,
                  message: "กรุณาใส่ชื่ออำเภอ!",
                },
                {
                  validator: (_, value) => {
                    if (value && (value === " " || value === "-")) {
                      return Promise.reject(new Error('กรุณาป้อนข้อมูลอำเภอให้ถูกต้อง!'))
                    } else {
                      return Promise.resolve()
                    }
                  },
                },
              ]}
            >
              <Input placeholder={"ป้อนชื่ออำเภอ"} />
            </Form.Item>
          </Form>
        </Content>
      </Modal>
    </>
  );
};

export default AddEditBsAmphurHModal;
