import { Table, Typography } from "antd";
import React from "react";
import { TWorksheetDetail } from "src/domain/worksheet/tWorksheetDetail";
import { WorksheetHeadData } from "src/domain/worksheet/view/worksheetHeadData";
import { formatNumber } from '../../../utils/numberUtils';

interface WorksheetDataProp {
    worksheetHeadData: WorksheetHeadData;
}

const ServiceDetailViewTab: React.FC<WorksheetDataProp> = (props) => {
    const { worksheetHeadData } = props; //input param
    //sending param to otherServices modal
    const columns = [
        {
            title: "ลำดับ",
            dataIndex: "index",
            key: "index",
            align: "center" as "center",
            render: (value: any, item: TWorksheetDetail, index: number) => {
                return <span>{index + 1}</span>;
            },
        },
        {
            title: "รายการ",
            dataIndex: "iserviceName",
            key: "iserviceName",
        },
        {
            title: "ราคา/หน่วย",
            dataIndex: "iunitPrice",
            key: "iunitPrice",
            align: "right" as "right",
            render: (value: any, item: TWorksheetDetail, index: number) => {
                return <span>{formatNumber(item.iunitPrice)}</span>;
            },
        },
        {
            title: "จำนวน",
            dataIndex: "iqty",
            key: "iqty",
            align: "right" as "right",
        },
        {
            title: "หน่วย",
            dataIndex: "itypeunitName",
            key: "itypeunitName",
        },
        {
            title: "ส่วนลด",
            dataIndex: "discount",
            key: "discount",
            align: "right" as "right",
            render: (value: any, item: TWorksheetDetail, index: number) => {
                return <span>{formatNumber(item.discount)}</span>;
            },
        },
        {
            title: "รวม",
            dataIndex: "iamount",
            key: "iamount",
            align: "right" as "right",
            render: (value: any, item: TWorksheetDetail, index: number) => {
                return <span>{formatNumber(item.iamount)}</span>;
            },
        },
    ];

    return (
        <>
            <Typography.Title
                type="danger"
                level={4}
                style={{ height: "22px", textAlign: "right" }}
            >
                จำนวนเงิน :
                {formatNumber(!worksheetHeadData?.sumServiceLastAmt ? 0 : worksheetHeadData?.sumServiceLastAmt)}
            </Typography.Title>
            <div>
                <Table
                    columns={columns}
                    dataSource={worksheetHeadData.tworksheetDetailList}
                    rowKey={(item) => item.iid}
                    size="small"
                    pagination={false}
                />
            </div>
        </>
    );
};

export default ServiceDetailViewTab;