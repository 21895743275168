import { Card, Col, Descriptions, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { TWorksheetSteelHeadData } from "src/domain/worksheet/view/tWorksheetSteelHeadData";
import PageHeader from "src/layouts/PageHeader";
import { formatFullTime, toThDate } from "src/utils/dateTimeUtils";
import { formatNumber } from "src/utils/numberUtils";
import tWorksheetSteelHeadService from "../../services/tWorksheetSteelHeadService";

interface IParams {
    ireserveCode: string;
}

const ViewWorkSheetSteelHead: React.FC<RouteComponentProps<IParams>> = (props) => {
    const ireserveCode = props.match.params.ireserveCode;
    const [workSheetSteelHeadData, setWorkSheetSteelHeadData] = useState<TWorksheetSteelHeadData>({} as TWorksheetSteelHeadData);
    const history = useHistory();

    useEffect(() => {
        initial();
    }, []);

    const initial = async () => {
        const res = await tWorksheetSteelHeadService.getTWorksheetSteelHeadData(ireserveCode);
        setWorkSheetSteelHeadData(res.data);
    };

    return (
        <>
            <PageHeader title="" onClose={() => history.push("/app/workSheetSteel/list")} />
            <Content className="app-page-content">
                <Descriptions
                    style={{ margin: "1em" }}
                    column={4}
                    title="ข้อมูลใบจองเหมาแผ่นเหล็ก"
                    size="middle"
                />
                <div style={{ margin: "1em" }}>
                    <Row gutter={[10, 0]}>
                        <Col span={15}>
                            <Card type="inner" title="ข้อมูลลูกค้า">
                                <Descriptions
                                    column={6}
                                    size="middle"
                                    bordered
                                    labelStyle={{ textAlign: "right", fontWeight: "bold" }}
                                >
                                    <Descriptions.Item label="ชื่อลูกค้า" span={6}>({workSheetSteelHeadData?.tworksheetHeadView?.icustCode}) {workSheetSteelHeadData?.tworksheetHeadView?.icustName}</Descriptions.Item>
                                    <Descriptions.Item label="ที่อยู่" span={6}>{workSheetSteelHeadData?.tworksheetHeadView?.icustAddress}</Descriptions.Item>
                                    <Descriptions.Item label="โทรศัพท์" span={2}>{workSheetSteelHeadData?.tworksheetHeadView?.icustPhone}</Descriptions.Item>
                                    <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#ffffff" }}> </Descriptions.Item>
                                    <Descriptions.Item label="ประเภทลูกค้า" span={2}>{(workSheetSteelHeadData?.tworksheetHeadView?.debitorcode) ? "ลูกค้าเครดิต" : "ลูกค้าทั่วไป"}</Descriptions.Item>
                                </Descriptions>
                            </Card>
                            <Card type="inner" title="ข้อมูลทั่วไป">
                                <Descriptions
                                    column={6}
                                    size="middle"
                                    bordered
                                    labelStyle={{ textAlign: "right", fontWeight: "bold" }}
                                >
                                    <Descriptions.Item label="ใบงาน" span={2}>{workSheetSteelHeadData?.tworksheetHeadView?.itypeworksheetName}</Descriptions.Item>
                                    <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#ffffff" }}> </Descriptions.Item>
                                    <Descriptions.Item label="เลขที่สัญญา" span={2}>{workSheetSteelHeadData?.twshPromiss?.promissnumber}</Descriptions.Item>
                                    <Descriptions.Item label="สถานที่" span={6}>{workSheetSteelHeadData?.tworksheetHeadView?.ilocation}</Descriptions.Item>
                                    <Descriptions.Item label="ติดต่อคุณ" span={2}>{workSheetSteelHeadData?.tworksheetHeadView?.icontactName}</Descriptions.Item>
                                    <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#ffffff" }}> </Descriptions.Item>
                                    <Descriptions.Item label="หมายเลขโทรศัพท์" span={2}>{workSheetSteelHeadData?.tworksheetHeadView?.icontactPhone}</Descriptions.Item>
                                </Descriptions>
                            </Card>
                            <Card type="inner" title="ข้อมูลรายการบริการ">
                                <Descriptions
                                    column={6}
                                    size="middle"
                                    bordered
                                    labelStyle={{ textAlign: "right", fontWeight: "bold" }}
                                >
                                    <Descriptions.Item label="การบริการ" span={6}>({workSheetSteelHeadData?.tworksheetSteelHead?.iserviceCode}) {workSheetSteelHeadData?.tworksheetSteelHead?.iserviceName}</Descriptions.Item>
                                    <Descriptions.Item label="ลักษณะงาน" span={6}>{workSheetSteelHeadData?.tworksheetSteelHead?.iremark}</Descriptions.Item>
                                    <Descriptions.Item label="ราคา/หน่วย" span={6}>{formatNumber(workSheetSteelHeadData?.tworksheetSteelHead?.iunitPrice)}</Descriptions.Item>
                                    <Descriptions.Item label="วันที่เริ่มงาน" span={6}>{toThDate(workSheetSteelHeadData?.tworksheetSteelHead?.istartDate)} {formatFullTime(workSheetSteelHeadData?.tworksheetSteelHead?.istartTime)}</Descriptions.Item>
                                    <Descriptions.Item label="วันที่สิ้นสุดงาน" span={6}>{toThDate(workSheetSteelHeadData?.tworksheetSteelHead?.iendDate)} {formatFullTime(workSheetSteelHeadData?.tworksheetSteelHead?.iendTime)}</Descriptions.Item>
                                    <Descriptions.Item label="ระยะเวลา" span={6}>{workSheetSteelHeadData?.tworksheetSteelHead?.inumDate} วัน</Descriptions.Item>
                                    <Descriptions.Item label="จำนวนแผ่นเหล็ก" span={6}>{workSheetSteelHeadData?.tworksheetSteelHead?.inumSteel} แผ่น</Descriptions.Item>
                                    <Descriptions.Item label="จำนวนเงิน" span={6}>{formatNumber(workSheetSteelHeadData?.tworksheetSteelHead?.iamount)}</Descriptions.Item>
                                    <Descriptions.Item label="ส่วนลด" span={6}>{formatNumber(workSheetSteelHeadData?.tworksheetSteelHead?.idiscount)}</Descriptions.Item>
                                    <Descriptions.Item label="หมายเหตุส่วนลด" span={6}>{workSheetSteelHeadData?.treportDiscount?.iremark}</Descriptions.Item>
                                </Descriptions>
                            </Card>
                        </Col>
                        <Col span={9}>
                            <Card type="inner" title="ข้อมูลการชำระ">
                                <Descriptions
                                    column={6}
                                    size="middle"
                                    bordered
                                    labelStyle={{ textAlign: "right", fontWeight: "bold" }}
                                >
                                    <Descriptions.Item label="ค่าบริการ" span={6}>{formatNumber(workSheetSteelHeadData?.tworksheetHeadView?.iserviceAmt)}</Descriptions.Item>
                                    <Descriptions.Item label="ค่าอื่นๆ" span={6}>{formatNumber(workSheetSteelHeadData?.tworksheetHeadView?.iextraAmt)}</Descriptions.Item>
                                    <Descriptions.Item label="ส่วนลด" span={6}>{formatNumber(workSheetSteelHeadData?.tworksheetHeadView?.idiscount)}</Descriptions.Item>
                                    <Descriptions.Item label="รวม" span={6}>{formatNumber(workSheetSteelHeadData?.tworksheetHeadView?.itotal1Amt)}</Descriptions.Item>
                                    <Descriptions.Item label="ภาษี" span={6}>{workSheetSteelHeadData?.tworksheetHeadView?.itypetaxName}</Descriptions.Item>
                                    <Descriptions.Item label=" " span={6}>{formatNumber(workSheetSteelHeadData?.tworksheetHeadView?.itaxAmt)}</Descriptions.Item>
                                    <Descriptions.Item label="รวมภาษี" span={6}>{formatNumber(workSheetSteelHeadData?.tworksheetHeadView?.itotal2Amt)}</Descriptions.Item>
                                    <Descriptions.Item label="การชำระ" span={6}>{workSheetSteelHeadData?.tworksheetHeadView?.itypepaymentName}</Descriptions.Item>
                                    <Descriptions.Item label="เงินชำระ" span={6}>{workSheetSteelHeadData?.tworksheetHeadView?.depositpercentName}</Descriptions.Item>
                                    <Descriptions.Item label=" " span={6}>{formatNumber(workSheetSteelHeadData?.tworksheetHeadView?.idepositAmt)}</Descriptions.Item>
                                    <Descriptions.Item label="รับชำระ" span={6}>{formatNumber(workSheetSteelHeadData?.tworksheetHeadView?.idepositAmt)}</Descriptions.Item>
                                    <Descriptions.Item label="ค้างชำระ" span={6}>{formatNumber(workSheetSteelHeadData?.tworksheetHeadView?.iremainAmt)}</Descriptions.Item>
                                    <Descriptions.Item label="การเงินรับชำระ" span={6}>{formatNumber(workSheetSteelHeadData?.tworksheetHeadView?.payedamount)}</Descriptions.Item>
                                    <Descriptions.Item label="เงินประกัน" span={6}>{formatNumber(workSheetSteelHeadData?.tworksheetHeadView?.iinsuranceAmt)}</Descriptions.Item>
                                </Descriptions>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Content>
        </>
    );
};

export default ViewWorkSheetSteelHead;
