import { Button, Col, DatePicker, Form, Modal, Row, Select, Space, Table, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { TFinancePayindetail } from 'src/domain/finance/tFinancePayindetail';
import { TCustomerHeadView } from "src/domain/master/view/tCustomerHeadView";
import { RyRefbranch } from 'src/domain/ryRefbranch';
import { TPayinHead } from 'src/domain/tPayinHead';
import tCustomerHeadService from 'src/services/customer/tCustomerHeadService';
import tPayinHeadService from 'src/services/payin/tPayinHeadService';
import ryRefbranchService from 'src/services/reference/ryRefbranchService';
import Constants from 'src/utils/constants';
import { toThDate } from 'src/utils/dateTimeUtils';
import { formatNumber } from 'src/utils/numberUtils';

const { Option } = Select;

interface IProps {
  lookupTPayinHeadModalVisible: boolean;
  setLookupTPayinHeadModalVisible: any;
  setLookupTPayinHeadSelected: any;
  lookupTfinancePayinDetailList: TFinancePayindetail[];
}

const LookupTPayinHeadModal: React.FC<IProps> = (props) => {
  const { lookupTPayinHeadModalVisible, setLookupTPayinHeadModalVisible, setLookupTPayinHeadSelected, lookupTfinancePayinDetailList } = props
  const [form] = Form.useForm();
  const [page, setPage] = React.useState(1);
  const [customers, setCustomers] = useState<TCustomerHeadView[]>([]);
  const [custSelected, setCustSelected] = useState<TCustomerHeadView>();
  const [ryRefbranches, setRyRefbranches] = useState<RyRefbranch[]>([]);
  const [ryRefbranchSelected, setRyRefbranchSelected] = useState<RyRefbranch>();
  const [tPayinHeads, setTPayinHeads] = useState<TPayinHead[]>([]);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: TPayinHead, index: number) => (<>{index + 1}</>)
    },
    {
      title: "รายการโอนเงิน",
      dataIndex: "id",
      key: "id",
      width: 85,
      align: "center" as "center",
      render: (value: string, item: TPayinHead) => (
        <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.id}</Button>
      )
    },
    {
      title: "วันที่โอน",
      dataIndex: "depositDate",
      key: "depositDate",
      width: 50,
      align: "center" as "center",
      render: (value: any, item: TPayinHead) => {
        return <span>{item.depositdate ? toThDate(item.depositdate) : ""}</span>;
      },
    },
    {
      title: "โอนเข้าบัญชี",
      dataIndex: "toaccountname",
      key: "toaccountname",
      width: 150,
      align: "center" as "center",
    },
    {
      title: "เลขที่บัญชี",
      dataIndex: "toaccountnumber",
      key: "toaccountnumber",
      width: 100,
      align: "center" as "center",
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "amount",
      key: "amount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TPayinHead) => {
        return <span>{formatNumber(item.amount)}</span>;
      },
    },
    {
      title: "จำนวนเงินคงเหลือ",
      dataIndex: "payinremainamt",
      key: "payinremainamt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TPayinHead) => {
        return <span>{formatNumber(item.payinremainamt)}</span>;
      },
    },
    {
      title: "ลูกค้า",
      dataIndex: "customername",
      key: "customername",
      width: 150,
      align: "center" as "center",
    },
    {
      title: "หมายเหตุ",
      dataIndex: "remark",
      key: "remark",
      width: 150,
      align: "center" as "center",
    },
  ]
  useEffect(() => {
    (async () => {
      if (lookupTPayinHeadModalVisible) {
        const ryRefbranchesRes = await ryRefbranchService.getRyRefbranchList();
        setRyRefbranches(ryRefbranchesRes.data);
        setLookupTPayinHeadSelected({} as TPayinHead);

        form.setFieldsValue({
          createDateFrom: moment(new Date(), Constants.DATE_FORMAT),
          createDateTo: moment(new Date(), Constants.DATE_FORMAT),
          depositDateFrom: moment(new Date(), Constants.DATE_FORMAT),
          depositDateTo: moment(new Date(), Constants.DATE_FORMAT)
        });
      }
    })();
  }, [lookupTPayinHeadModalVisible]);

  const onItemCliked = (item: TPayinHead) => {
    setLookupTPayinHeadSelected(item);
    setLookupTPayinHeadModalVisible(false);
  };

  const handleSearchCustomer = async (data: any) => {
    if (data.length < 3) return;
    const res = await tCustomerHeadService.getCustomerByParam(data);
    setCustomers(res.data);
  };

  const handleChangeCustomer = (icustCode: any) => {
    const custArray = customers as Array<TCustomerHeadView>;
    const findCust = custArray.find((ob) => ob.icustCode === icustCode);
    setCustSelected(findCust);
  }

  const handleChangeBranch = (branchCode: any) => {
    const ryRefbranchArray = ryRefbranches as Array<RyRefbranch>;
    const findStation = ryRefbranchArray.find((ob) => ob.id === branchCode);
    setRyRefbranchSelected(findStation);
  }

  const handleSearch = async () => {
    const customerCode = (custSelected && custSelected.icustCode) ? custSelected.icustCode : "";
    const branchCode = (ryRefbranchSelected && ryRefbranchSelected.id) ? ryRefbranchSelected.id : "";
    const createDateFrom = (form.getFieldValue("createDateFrom")) ? moment(form.getFieldValue("createDateFrom")).format("yyyyMMDD") : "";
    const createDateTo = (form.getFieldValue("createDateTo")) ? moment(form.getFieldValue("createDateTo")).format("yyyyMMDD") : "";
    const depositDateFrom = (form.getFieldValue("depositDateFrom")) ? moment(form.getFieldValue("depositDateFrom")).format("yyyyMMDD") : "";
    const depositDateTo = (form.getFieldValue("depositDateTo")) ? moment(form.getFieldValue("depositDateTo")).format("yyyyMMDD") : "";
    const tPayinHeadsRes = await tPayinHeadService.getTPayinHeadListByParam(customerCode, branchCode, createDateFrom, createDateTo, depositDateFrom, depositDateTo);
    if (tPayinHeadsRes.data) {
      const tPayinHeadResult = tPayinHeadsRes.data as TPayinHead[];
      const filteredList = tPayinHeadResult.filter((item) => !lookupTfinancePayinDetailList.find((selectedItem) => item.id === selectedItem.payinid));

      setTPayinHeads(filteredList);
    }
  }

  const handleClearParam = () => {
    setCustSelected({} as TCustomerHeadView);
    setRyRefbranchSelected({} as RyRefbranch);
    form.setFieldsValue({
      customerCode: "",
      btanchId: "",
      createDateFrom: moment(new Date(), Constants.DATE_FORMAT),
      createDateTo: moment(new Date(), Constants.DATE_FORMAT),
      depositDateFrom: moment(new Date(), Constants.DATE_FORMAT),
      depositDateTo: moment(new Date(), Constants.DATE_FORMAT)
    });
    setTPayinHeads([]);
  }

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>รายการโอนเงิน</Typography.Title>
          </div>
        }
        visible={lookupTPayinHeadModalVisible}
        width={"80%"}
        style={{ minHeight: "80%" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setLookupTPayinHeadModalVisible(false)}
      >
        <Form
          form={form}
          name="lookupTPayinHeadModal"
          labelCol={{ span: 5 }}
          initialValues={{ remember: true }}
        >
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>ลูกค้า: </Typography.Text>
            </Col>
            <Col span={18}>
              <Form.Item
                name="customerCode"
                rules={[{ required: false }]}
              >
                <Select
                  showSearch
                  allowClear
                  size="middle"
                  placeholder="เลือกลูกค้า"
                  optionFilterProp="children"
                  filterOption={true}
                  onSearch={handleSearchCustomer}
                  onSelect={handleChangeCustomer}
                >
                  {customers.map((obj) => {
                    return (
                      <Option key={obj.icustCode} value={obj.icustCode}>
                        {obj.iprenameName}{obj.iname}  {obj.isurname ? obj.isurname : ""}  {obj.isurname ? obj.isurname : ""} : {obj.iphone}<br />
                        <span style={{ marginLeft: "1rem", fontSize: 12 }}>
                          {obj.iaddress}{'   '}อำเภอ/เขต{'   '}{obj.iamphurName}{'   '}จังหวัด{'   '}{obj.iprovinceName}{'   '}{obj.ipostcode}
                        </span>
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>สาขา: </Typography.Text>
            </Col>
            <Col span={18}>
              <Form.Item
                name="btanchId"
                rules={[{ required: false }]}
              >
                <Select
                  showSearch
                  size="middle"
                  placeholder="เลือกสาขา"
                  optionFilterProp="children"
                  filterOption={true}
                  onSelect={handleChangeBranch}
                >
                  {ryRefbranches.map((obj) => {
                    return (
                      <Option key={obj.id} value={obj.id}>
                        {obj.id} : {obj.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>วันที่บันทึก: </Typography.Text>
            </Col>
            <Col span={8}>
              <Form.Item
                name="createDateFrom"
              >
                <DatePicker
                  format={Constants.DATE_FORMAT}
                  size="middle"
                  placeholder="จากวันที่"
                  style={{ width: 255 }}
                />
              </Form.Item>
            </Col>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>ถึงวันที่: </Typography.Text>
            </Col>
            <Col span={8}>
              <Form.Item
                name="createDateTo"
              >
                <DatePicker
                  format={Constants.DATE_FORMAT}
                  size="middle"
                  placeholder="ถึงวันที่"
                  style={{ width: 255 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>วันที่โอนตั้งแต่: </Typography.Text>
            </Col>
            <Col span={8}>
              <Form.Item
                name="depositDateFrom"
              >
                <DatePicker
                  format={Constants.DATE_FORMAT}
                  size="middle"
                  placeholder="จากวันที่"
                  style={{ width: 255 }}
                />
              </Form.Item>
            </Col>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>ถึงวันที่: </Typography.Text>
            </Col>
            <Col span={8}>
              <Form.Item
                name="depositDateTo"
              >
                <DatePicker
                  format={Constants.DATE_FORMAT}
                  size="middle"
                  placeholder="ถึงวันที่"
                  style={{ width: 255 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={11}></Col>
            <Col span={13}>
              <Space>
                <Button
                  size="small"
                  type="primary"
                  style={{ marginBottom: "15px" }}
                  onClick={handleSearch}
                >
                  ค้นหา
                </Button>
                <Button
                  size="small"
                  type="primary"
                  style={{ marginBottom: "15px" }}
                  onClick={handleClearParam}
                >
                  เคลียร์
                </Button>
              </Space>
            </Col>
          </Row>

          <div style={{ padding: "10px 0px 0px" }}>
            <Table
              columns={columns}
              dataSource={tPayinHeads}
              size="small"
              style={{ minHeight: 200 }}
              rowKey={(item: TPayinHead) => item.id}
              pagination={{
                pageSize: 15,
                onChange(current) {
                  setPage(current);
                }
              }}
            />
          </div>
        </Form>

      </Modal>
    </>
  )
}

export default LookupTPayinHeadModal