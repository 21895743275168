import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, Space, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PageHeader from "src/layouts/PageHeader";
import { useAppSelector } from "src/stores/store";
import Constants from "src/utils/constants";
import { NotificationType, showNotification } from "src/utils/notificationUtils";
import { TAssetmovementAndDetailView } from "../../domain/movement/view/tAssetmovementAndDetailView";
import tAssetmovementService from "../../services/tAssetmovementService";
import { formatTime, toThDate } from "../../utils/dateTimeUtils";
import AddTAssetmovementDrawer from "./AddTAssetmovementDrawer";

const TAssetmovementList: React.FC<TAssetmovementAndDetailView> = () => {
    const [tAssetmovements, setTAssetmovements] = useState<TAssetmovementAndDetailView[]>([]);
    const [page, setPage] = React.useState(1);
    const history = useHistory();
    const [visibleAddTAssetmovementDrawer, setVisibleAddTAssetmovementDrawer] = useState<boolean>(false);

    const userInfo = useAppSelector((state) => state.authen.authenUser.user);
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState(false);

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            width: 50,
            render: (value: number, item: TAssetmovementAndDetailView, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
        },
        {
            title: "วันที่รับกลับ",
            dataIndex: "endDate",
            key: "endDate",
            width: 100,
            render: (value: any, item: TAssetmovementAndDetailView) => (<>{toThDate(item.endDate)}</>)
        },
        {
            title: "เวลาที่รับกลับ",
            dataIndex: "endTime",
            key: "endTime",
            width: 100,
            render: (value: any, item: TAssetmovementAndDetailView) => (<>{formatTime(item.endTime)}</>)
        },
        {
            title: "เลขที่ใบงาน",
            dataIndex: "worksheetCode",
            key: "worksheetCode", render: (value: string, item: TAssetmovementAndDetailView) => (
                <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.worksheetCode}</Button>
            )
        },
        {
            title: "รายการ",
            dataIndex: "assetName",
            key: "assetName",
            render: (value: any, item: TAssetmovementAndDetailView) => (<>{item.assetName} : {item.serviceDetailName}</>)
        },
        {
            title: "พนักงานขับรก",
            dataIndex: "driverName",
            key: "driverName"
        },
        {
            title: "หมายเหตุ",
            dataIndex: "userremark",
            key: "userremark",
        },
        {
            title: "ผู้รับกลับ",
            dataIndex: "lastuserName",
            key: "lastuserName",
        },
    ];

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
        form.setFieldsValue({
            worksheetCode: "",
            fromDate: moment(new Date(), Constants.DATE_FORMAT),
            toDate: moment(new Date(), Constants.DATE_FORMAT)
        });
        form.submit();
    }, []);

    const onItemCliked = (item: TAssetmovementAndDetailView) => {
        history.replace("/app/tAssetmovement/view/" + item.seq);
    };

    const onFinish = async (values: any) => {
        setLoading(true);
        const branchId = userInfo.branchId;
        const flg = "01"; // already return
        let worksheetCode: string = values.worksheetCode ? values.worksheetCode : "";
        let fromDate: string = values.fromDate ? moment(values.fromDate).format(Constants.DB_FORMAT) : "";
        let toDate: string = values.toDate ? moment(values.toDate).format(Constants.DB_FORMAT) : "";
        
        if(worksheetCode && worksheetCode.length >= 7) {
            fromDate = "";
            toDate= "";
        } else if (worksheetCode.length > 0 &&  worksheetCode.length < 7) {
            showNotification(NotificationType.ERROR, "กรุณาป้อนเลขที่ใบงานให้มากกว่า 7 ตัวอักษร");
            form.setFieldsValue({
                worksheetCode: "",
            });
            return;
        }

        const res = await tAssetmovementService.getTAssetmovementReturned(branchId, flg, worksheetCode, fromDate, toDate);
        setTAssetmovements(res.data);
        setLoading(false);
    }

    const onFinishFailed = (errorInfo: any) => {
    };

    const onAddTAssetmovement = () => {
        setVisibleAddTAssetmovementDrawer(true);
    }

    return (
        <>
            <PageHeader title="โปรแกรมรับรถกลับ" >
                <Button
                    size="small"
                    type="primary"
                    icon={<PlusSquareOutlined />}
                    onClick={onAddTAssetmovement}
                >
                    เพิ่ม
                </Button>
            </PageHeader>
            <Content className="app-page-content">
                <Form
                    form={form}
                    name="bankBranchHead"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "baseline",
                            margin: "1em 0 1em 0",
                            paddingLeft: "1em",
                            borderBlockEnd: "2px solid #c9c9c9",
                        }}
                    >
                        <Space direction="vertical" size={"small"}>
                            <Form.Item
                                label="เลขที่ใบงาน"
                                name="worksheetCode"
                                rules={[{ required: false }]}
                            >
                                <Input
                                    allowClear
                                    placeholder="ป้อนเลขที่ใบงาน"
                                    // onChange={form.submit}
                                    onKeyDown={(evt: any) => {
                                        if(evt.keyCode == 13){
                                            form.submit()
                                        }
                                    }}
                                ></Input>
                            </Form.Item>
                            <Space align="baseline" size={"large"}>
                                <Form.Item
                                    label="วันที่รับกลับตั้งแต่"
                                    name="fromDate"
                                    rules={[{ required: false }]}
                                >
                                    <DatePicker
                                        format={Constants.DATE_FORMAT}
                                        size="middle"
                                        placeholder="เลือกวันที่"
                                        onChange={form.submit}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="ถึง"
                                    name="toDate"
                                    rules={[{ required: false }]}
                                >
                                    <DatePicker
                                        format={Constants.DATE_FORMAT}
                                        size="middle"
                                        placeholder="เลือกวันที่"
                                        onChange={form.submit}
                                    />
                                </Form.Item>
                            </Space>
                        </Space>
                    </div>
                </Form>
                <div>
                    <Table
                        columns={columns}
                        dataSource={tAssetmovements}
                        size="small"
                        loading={loading}
                        pagination={{
                            pageSize: 15,
                            onChange(current) {
                                setPage(current);
                            }
                        }}
                        rowKey={(item: TAssetmovementAndDetailView) => item.seq}
                        onRow={(item: TAssetmovementAndDetailView) => {
                            return {
                                onDoubleClick: () => onItemCliked(item),
                            };
                        }}
                    />
                </div>
            </Content>
            <div> {
                visibleAddTAssetmovementDrawer && <AddTAssetmovementDrawer
                    visibleAddTAssetmovementDrawer={visibleAddTAssetmovementDrawer}
                    setVisibleAddTAssetmovementDrawer={setVisibleAddTAssetmovementDrawer} />
            }</div>
        </>
    );
};

export default TAssetmovementList;
