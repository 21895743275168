import { Button, Space, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { TWorksheetHeadView } from 'src/domain/worksheet/view/tWorksheetHeadView';
import Constants from 'src/utils/constants';
import { toThDate } from 'src/utils/dateTimeUtils';
import { formatNumber, formatNumberInt } from 'src/utils/numberUtils';
import styled from 'styled-components';

interface IProp {
  workingWorksheets10d: TWorksheetHeadView[];
  workingWorksheets30d: TWorksheetHeadView[];
  workingWorksheets90d: TWorksheetHeadView[];
  workingWorksheets1y: TWorksheetHeadView[];
  countWsh: number;
  activeTab: string;
  loading: boolean;
}
const ButtonContainer = styled.span`
.ant-btn:checked {
  background-color: #9dcbf0 !important;
  border-color: #268ddd !important;
}
  .ant-btn:hover {
    background-color: #e1effa !important;
    border-color: #9dcbf0 !important;
  }
  .ant-btn:focus, .ant-btn:active {
    background-color: #9dcbf0 !important;
    border-color: #268ddd !important;
  }
`;

const WorkingWorksheetTab: React.FC<IProp> = (props) => {
  const { workingWorksheets10d, workingWorksheets30d, workingWorksheets90d, workingWorksheets1y, countWsh, activeTab, loading, } = props;
  const [workingWorksheets, setWorkingWorksheets] = useState<TWorksheetHeadView[]>([]);

  const getItemColor = (item: TWorksheetHeadView) => {
    let foreColor = "";
    const currentDate = moment().format(Constants.DB_FORMAT);

    if ((Number(currentDate) - Number(item.iendDate)) > 3) {
      foreColor = "red";
    } else {
      foreColor = "black";
    }
    if (item.iworksheetStatus === "15") {
      foreColor = "green";
    }
    return 'text-' + foreColor;
  }

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 70,
      align: "center" as "center",
      render: (value: number, item: TWorksheetHeadView, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่ใบงาน",
      dataIndex: "iworksheetCode",
      key: "iworksheetCode",
      width: 150
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "icustName",
      key: "icustName",
      width: 250
    },
    {
      title: "วันเวลาที่ทำงาน",
      dataIndex: "istartDate",
      key: "istartDate",
      width: 190,
      align: "center" as "center",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{item.istartDate ? toThDate(item.istartDate) : ""} - {item.iendDate ? toThDate(item.iendDate) : ""}</span>;
      },
    },
    {
      title: "รถ",
      dataIndex: "truckNumber",
      key: "truckNumber",
      width: 150,
    },
    {
      title: "บริการ",
      dataIndex: "serviceDetailName",
      key: "serviceDetailName",
      width: 500,
    },
    {
      title: "พนักงานขับรถ",
      dataIndex: "idriverName",
      key: "idriverName",
      width: 150,
    },
    {
      title: "ค่าบริการ",
      dataIndex: "iserviceAmt",
      key: "iserviceAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iserviceAmt)}</span>;
      },
    },
    {
      title: "ค่าล่วงเวลา",
      dataIndex: "iotAmt",
      key: "iotAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iotAmt)}</span>;
      },
    },
    {
      title: "ค่าบริการอื่นๆ",
      dataIndex: "iextraAmt",
      key: "iextraAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iextraAmt)}</span>;
      },
    },
    {
      title: "ส่วนลดค่าบริการ",
      dataIndex: "idiscount",
      key: "idiscount",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.idiscount)}</span>;
      },
    },
    {
      title: "รวมก่อนภาษี",
      dataIndex: "itotal1Amt",
      key: "itotal1Amt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itotal1Amt)}</span>;
      },
    },
    {
      title: "ภาษี 7%",
      dataIndex: "itaxAmt",
      key: "itaxAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itaxAmt)}</span>;
      },
    },
    {
      title: "รวมค่าบริการ",
      dataIndex: "itotal2Amt",
      key: "itotal2Amt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itotal2Amt)}</span>;
      },
    },
    {
      title: "มัดจำ/ชำระล่วงหน้า",
      dataIndex: "idepositAmt",
      key: "idepositAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.idepositAmt)}</span>;
      },
    },
    {
      title: "คงเหลือ",
      dataIndex: "iremainAmt",
      key: "iremainAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        // return <span>{formatNumber(item.iremainAmt)}</span>;
        return <span>{formatNumber((item.itotal2Amt ? item.itotal2Amt : 0) - (item.idepositAmt ? item.idepositAmt : 0))}</span>;
      },
    },
    {
      title: "สถานะใบสั่งงาน",
      dataIndex: "iworksheetStatusName",
      key: "iworksheetStatusName",
      width: 130,
      align: "center" as "center",
    },
    {
      title: "เลขที่ใบแจ้งหนี้",
      dataIndex: "debitnotecode",
      key: "debitnotecode",
      width: 130,
      align: "center" as "center",
    },
    {
      title: "พนักงาน",
      dataIndex: "createUserName",
      key: "createUserName",
      width: 200,
    },
    {
      title: "วันที่สร้างบิล",
      dataIndex: "icomment",
      key: "icomment",
      width: 100,
      align: "center" as "center",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{item.ientryDate ? toThDate(item.ientryDate) : ""}</span>;
      },
    },
  ];

  useEffect(() => {
    if (activeTab === "workingWorksheetTab") {
      setWorkingWorksheets(workingWorksheets10d);
    }
  }, [activeTab]);

  return (
    <>
      <Content className="app-page-content">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            justifyContent: "space-between",
            borderColor: "red",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              margin: "1em 0 0 0",
              paddingLeft: "1em",
              position: "relative"
            }}
          >

            <ButtonContainer>
              <Button
                defaultChecked={true}
                autoFocus={true}
                style={{
                  width: "170px",
                  height: "auto",
                  borderRadius: "8px 8px 8px 8px",
                  margin: "5px 5px 10px 5px",
                }}
                onClick={() => setWorkingWorksheets(workingWorksheets10d)}
              >
                <Space align="center" direction='vertical'>
                  <Typography.Title level={5}>
                    ใบงานค้าง 10 วัน
                  </Typography.Title>
                  <Typography.Title level={5} style={{ margin: 0, color: "#FD1212" }}>
                    {formatNumberInt(workingWorksheets10d.length)}
                  </Typography.Title>
                </Space>
              </Button>
              <Button
                style={{
                  width: "170px",
                  height: "auto",
                  borderRadius: "8px 8px 8px 8px",
                  margin: "5px 5px 10px 5px",
                }}
                onClick={() => setWorkingWorksheets(workingWorksheets30d)}
              >
                <Space align="center" direction='vertical'>
                  <Typography.Title level={5}>
                    ใบงานค้าง 30 วัน
                  </Typography.Title>
                  <Typography.Title level={5} style={{ margin: 0, color: "#FD1212" }}>
                    {formatNumberInt(workingWorksheets30d.length)}
                  </Typography.Title>
                </Space>
              </Button>
              <Button
                style={{
                  width: "170px",
                  height: "auto",
                  borderRadius: "8px 8px 8px 8px",
                  margin: "5px 5px 10px 5px",
                }}
                onClick={() => setWorkingWorksheets(workingWorksheets90d)}
              >
                <Space align="center" direction='vertical'>
                  <Typography.Title level={5}>
                    ใบงานค้าง 90 วัน
                  </Typography.Title>
                  <Typography.Title level={5} style={{ margin: 0, color: "#FD1212" }}>
                    {formatNumberInt(workingWorksheets90d.length)}
                  </Typography.Title>
                </Space>
              </Button>
              <Button
                style={{
                  width: "170px",
                  height: "auto",
                  borderRadius: "8px 8px 8px 8px",
                  margin: "5px 5px 10px 5px",
                }}
                onClick={() => setWorkingWorksheets(workingWorksheets1y)}
              >
                <Space align="center" direction='vertical'>
                  <Typography.Title level={5}>
                    ใบงานค้างไม่เกิน 1 ปี
                  </Typography.Title>
                  <Typography.Title level={5} style={{ margin: 0, color: "#FD1212" }}>
                    {formatNumberInt(workingWorksheets1y.length)}
                  </Typography.Title>
                </Space>
              </Button>
            </ButtonContainer>
          </div>
          <ButtonContainer>
            <Button
              style={{
                width: "170px",
                height: "auto",
                borderRadius: "8px 8px 8px 8px",
                margin: "5px 5px 10px 5px",
                textAlign: "center"
              }}
            >
              <Space align="center" direction='vertical'>
                <Typography.Title level={5}>
                  ใบงานทั้งหมด
                </Typography.Title>
                <Typography.Title level={5} style={{ margin: 0, color: "#FD1212" }}>
                  {formatNumberInt(countWsh)}
                </Typography.Title>
              </Space>
            </Button>
          </ButtonContainer>
        </div>
        <div style={{ marginBottom: 0 }}>
          <Table
            columns={columns}
            dataSource={workingWorksheets}
            loading={loading}
            rowClassName={getItemColor}
            size="small"
            scroll={{ x: 2400, y: 400 }}
            pagination={false}
            rowKey={(item: TWorksheetHeadView) => item.ireserveCode!}
          />
        </div>
      </Content>
    </>
  )
}

export default WorkingWorksheetTab