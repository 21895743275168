import http from "../../lib/http";
import Constants from "../../utils/constants";

const getRyRefownerAssets = () => {
  return http.get(Constants.DEFAULT_URL + "/ryRefownerAsset/findAllViews");
};

const postRyRefownerAsset = (ryRefownerAsset: any) => {
  return http.post(Constants.DEFAULT_URL + "/ryRefownerAsset", ryRefownerAsset);
}

const getNextRyRefownerAssetCode = () => {
  return http.get(Constants.DEFAULT_URL + "/ryRefownerAsset/nextCode");
}

const defaultApi = {
  getRyRefownerAssets,
  postRyRefownerAsset,
  getNextRyRefownerAssetCode,
};
export default defaultApi;
