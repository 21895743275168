import http from "../../lib/http";
import Constants from "../../utils/constants";
import { BsProvinceH } from './../../domain/bsProvinceH';

const getBsProvinceHList = () => {
    return http.get(Constants.DEFAULT_URL + "/bsProvinceH");
};

const getBsProvinceHs = (searchText: string) => {
    return http.get(Constants.DEFAULT_URL + "/bsProvinceH/search", {
        params: {
            searchText
        }
    });
};

const getBsProvinceHById = (iid: number) => {
    return http.get(Constants.DEFAULT_URL + "/bsProvinceH/" + iid);
}

const updateBsProvinceH = (bsProvinceH: BsProvinceH) => {
    return http.put(Constants.DEFAULT_URL + "/bsProvinceH", bsProvinceH);
}

const postBsProvinceH = (bsProvinceH: BsProvinceH) => {
    return http.post(Constants.DEFAULT_URL + "/bsProvinceH", bsProvinceH);
}

const getNextBsProvinceHCode = () => {
    return http.get(Constants.DEFAULT_URL + "/bsProvinceH/nextCode");
}
const checkExistProvince = (provinceShname: string, provinceName: string) => { 
    return http.get(Constants.DEFAULT_URL + "/bsProvinceH/checkExistProvince", {
        params: {
            provinceShname,
            provinceName
        }
    });
 }

const defaultApi = {
    getBsProvinceHList: getBsProvinceHList,
    getBsProvinceHs: getBsProvinceHs,
    getBsProvinceHById: getBsProvinceHById,
    updateBsProvinceH: updateBsProvinceH,
    postBsProvinceH: postBsProvinceH,
    getNextBsProvinceHCode: getNextBsProvinceHCode,
    checkExistProvince: checkExistProvince
}
export default defaultApi;