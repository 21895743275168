import { PlusSquareOutlined } from '@ant-design/icons';
import { Button, Space, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { TWorksheetHead } from 'src/domain/worksheet/tWorksheetHead';
import { TWorksheetOtherservice } from 'src/domain/worksheet/tWorksheetOtherservice';
import { WorksheetHeadData } from 'src/domain/worksheet/view/worksheetHeadData';
import { formatNumber } from 'src/utils/numberUtils';
import AddWorksheetOtherserviceModal from '../modal/AddWorksheetOtherserviceModal';

interface ModalProp {
  worksheetHeadData: WorksheetHeadData;
  setWorksheetHeadData: any;
  tWorksheetOtherserviceFormDataList: TWorksheetOtherservice[];
  setTWorksheetOtherserviceFormDataList: any;
  recalculateOtherService: boolean;
  setRecalculateOtherService: any;
}

const WorksheetOtherserviceTab: React.FC<ModalProp> = (props) => {
  const { worksheetHeadData, setWorksheetHeadData, tWorksheetOtherserviceFormDataList, setTWorksheetOtherserviceFormDataList
    , recalculateOtherService, setRecalculateOtherService } = props;
  const [visibleWorksheetOtherserviceModal, setVisibleWorksheetOtherserviceModal] = useState(false);

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      key: "index",
      align: "center" as "center",
      render: (value: any, item: TWorksheetOtherservice, index: number) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: "รายการ",
      dataIndex: "iremark",
      key: "iremark",
    },
    {
      title: "หน่วย",
      dataIndex: "iunit",
      key: "iunit",
      align: "right" as "right",
      render: (value: any, item: TWorksheetOtherservice, index: number) => {
        return <span>{formatNumber(item.iunit)}</span>;
      },
    },
    {
      title: "ราคาต่อหน่วย",
      dataIndex: "iunitPrice",
      key: "iunitPrice",
      align: "right" as "right",
      render: (value: any, item: TWorksheetOtherservice, index: number) => {
        return <span>{formatNumber(item.iunitPrice)}</span>;
      },
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "itotalPrice",
      key: "itotalPrice",
      align: "right" as "right",
      render: (value: any, item: TWorksheetOtherservice, index: number) => {
        return <span>{formatNumber(item.itotalPrice)}</span>;
      },
    },
    {
      title: "",
      key: "action",
      align: "center" as "center",
      render: (value: any, item: TWorksheetOtherservice, index: number) => {
        return (
          <span>
            <a onClick={(e) => { onDelete(item, index); }} style={{ color: "red" }}
            >
              X
            </a>
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    let sumWorksheetOtherserviceAmt = 0;
    tWorksheetOtherserviceFormDataList.forEach((obj) => {
      sumWorksheetOtherserviceAmt += (obj.itotalPrice) ? obj.itotalPrice : 0;
    });
    setWorksheetHeadData({
      ...(worksheetHeadData as WorksheetHeadData),
      tworksheetHead: {
        ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
        iserviceAmt: sumWorksheetOtherserviceAmt ? sumWorksheetOtherserviceAmt : 0,
      },
      tworksheetOtherserviceList: [...reindex(tWorksheetOtherserviceFormDataList)],
      sumWorksheetOtherserviceAmt: sumWorksheetOtherserviceAmt,
    });

    setRecalculateOtherService(true);

  }, [tWorksheetOtherserviceFormDataList]);

  const reindex = (tWorksheetOtherserviceFormDataList: TWorksheetOtherservice[]) => {
    return tWorksheetOtherserviceFormDataList.map((item, index) => {
      item.iid = index;
      return item;
    });
  }

  useEffect(() => {
    if (Object.keys(worksheetHeadData).length > 0 && recalculateOtherService) {
      setRecalculateOtherService(false);
    }
  }, [worksheetHeadData]);

  const onDelete = (item: TWorksheetOtherservice, index: number) => {
    const array = tWorksheetOtherserviceFormDataList ? [...tWorksheetOtherserviceFormDataList] : [];
    array?.splice(index, 1);
    setTWorksheetOtherserviceFormDataList(array);
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
          justifyContent: "space-between",
        }}
      >
        <Space align="baseline" direction="horizontal">
          <Button
            size="small"
            type="primary"
            icon={<PlusSquareOutlined />}
            onClick={() => setVisibleWorksheetOtherserviceModal(true)}
          >
            เพิ่ม
          </Button>
        </Space>
        <Typography.Title
          type="danger"
          level={4}
          style={{ height: "22px", textAlign: "right" }}
        >
          จำนวนเงิน :{" "}
          {formatNumber(!worksheetHeadData?.sumWorksheetOtherserviceAmt ? 0 : worksheetHeadData?.sumWorksheetOtherserviceAmt)}
        </Typography.Title>
      </div>
      <div>
        <Table
          columns={columns}
          dataSource={tWorksheetOtherserviceFormDataList}
          size="small"
          pagination={false}
          rowKey={(item: TWorksheetOtherservice) => item.iid}
        />
      </div>
      <div>
        {visibleWorksheetOtherserviceModal && (
          <AddWorksheetOtherserviceModal
            visibleWorksheetOtherserviceModal={visibleWorksheetOtherserviceModal}
            setVisibleWorksheetOtherserviceModal={setVisibleWorksheetOtherserviceModal}
            tWorksheetOtherserviceFormDataList={tWorksheetOtherserviceFormDataList}
            setTWorksheetOtherserviceFormDataList={setTWorksheetOtherserviceFormDataList}
            setRecalculateOtherService={setRecalculateOtherService}
          />
        )}
      </div>
    </>
  )
}

export default WorksheetOtherserviceTab