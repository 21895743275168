import http from "src/lib/http";
import Constants from "src/utils/constants";

const postTTruckRepair = (truckRepair: any) => {
  return http.post(Constants.DEFAULT_URL + "/tTruckRepair", truckRepair);
}

const updateTTruckRepair = (truckRepair: any) => {
  return http.put(Constants.DEFAULT_URL + "/tTruckRepair", truckRepair);
}

const getByTruck2Day = (truckCode: any, startDate: any, endDate: any) => {
  return http.get(Constants.DEFAULT_URL + "/tTruckRepair/getRepairTruck2Day", {
    params: {
      truckCode,
      startDate,
      endDate
    },
  });
};

const getByTruckId = (truckId: any) => {
  return http.get(Constants.DEFAULT_URL + "/tTruckRepair/getByTruckId/" + truckId);
};

const defaultApi = {
  postTTruckRepair,
  updateTTruckRepair,
  getByTruck2Day,
  getByTruckId,
}
export default defaultApi;