import { CloseCircleOutlined, ExclamationCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, Modal, Row, Select, Space, Spin, Switch, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TServiceHistory } from "src/domain/master/tServiceHistory";
import { TServiceHeadView } from "src/domain/master/view/tServiceHeadView";
import { RyRefsizetruck } from "src/domain/ryRefsizetruck";
import { RyReftypeasset } from "src/domain/ryReftypeasset";
import { RyTruckwheel } from "src/domain/ryTruckwheel";
import { TTypetruckRef } from "src/domain/tTypetruckRef";
import { TTypeunitRef } from "src/domain/tTypeunitRef";
import ryRefsizetruckService from "src/services/reference/ryRefsizetruckService";
import ryReftypeassetService from "src/services/reference/ryReftypeassetService";
import ryTruckwheelService from "src/services/reference/ryTruckwheelService";
import tTypetruckRefService from "src/services/reference/tTypetruckRefService";
import tTypeunitRefService from "src/services/reference/tTypeunitRefService";
import tServiceHistoryService from "src/services/tservice/tServiceHistoryService";
import { useAppSelector } from "src/stores/store";
import { formatNumber, parserNumber, textNumber } from "src/utils/numberUtils";
import { getFlg } from "src/utils/rodyokUtils";
import { TServiceHead } from "../../domain/master/tServiceHead";
import tServiceHeadService from "../../services/tservice/tServiceHeadService";
import { NotificationType, showNotification } from "../../utils/notificationUtils";

const { Option } = Select;
const { confirm } = Modal;

interface IParams {
    iserviceCode: string;
    visibleAddEditTServiceHeadDrawer: boolean;
    setVisibleAddEditTServiceHeadDrawer: any;
}

const AddEditTServiceHeadDrawer: React.FC<IParams> = (props) => {
    const { iserviceCode, visibleAddEditTServiceHeadDrawer: visibleTServiceHeadDrawer, setVisibleAddEditTServiceHeadDrawer: setVisibleTServiceHeadDrawer } = props;
    const [trigger, setTrigger] = useState<boolean>(false);
    const [tServiceHead, setTServiceHead] = useState<TServiceHead>({} as TServiceHead);
    const [form] = Form.useForm();
    const history = useHistory();
    const [showSpin, setShowSpin] = useState<boolean>(false);
    const [tServiceHeads, setTServiceHeads] = useState<TServiceHeadView[]>([]);

    const [tTypeunitRefs, setTTypeunitRefs] = useState<TTypeunitRef[]>([]);
    const [tTypetruckRefs, setTTypetruckRefs] = useState<TTypetruckRef[]>([]);
    const [ryReftypeassets, setRyReftypeassets] = useState<RyReftypeasset[]>([]);
    const [ryTruckwheels, setRyTruckwheels] = useState<RyTruckwheel[]>([]);
    const [ryRefsizetrucks, setRyRefsizetrucks] = useState<RyRefsizetruck[]>([]);
    const userInfo = useAppSelector((state) => state.authen.authenUser.user);

    let title: string = "เพิ่มข้อมูลบริการ";
    if (iserviceCode) {
        title = "แก้ไขข้อมูลบริการ";
    }

    useEffect(() => {
        if (trigger) {
            form.validateFields()
                .then(() => {
                    confirm({
                        title: "ยืนยัน!",
                        icon: <ExclamationCircleOutlined />,
                        content: "คุณต้องการที่จะบันทึกข้อมูลหรือไม่?",
                        cancelText: "ยกเลิก",
                        okText: "ยืนยัน",
                        onOk() {
                            form.submit();
                        },
                        onCancel() {
                            setTrigger(false);
                        }
                    });
                }).catch(() => {
                    setTrigger(false);
                });
        }
    }, [trigger]);

    useEffect(() => {
        setShowSpin(true);

        initial();
        return () => {
        };
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            iserviceCode: tServiceHead.iserviceCode,
            iserviceName: tServiceHead.iserviceName ? tServiceHead.iserviceName : 'ค่าบริการ',
            iunitCode: tServiceHead.iunitCode,
            iunitPrice: formatNumber(tServiceHead.iunitPrice),
            iremark: tServiceHead.iremark,
            idltFlg: getFlg(tServiceHead.idltFlg),
            ieditFlg: getFlg(tServiceHead.ieditFlg),
            typetruck: tServiceHead.typetruck,
            assettype: tServiceHead.assettype,
            iunitPrice2: formatNumber(tServiceHead.iunitPrice2),
            iunitPrice3: formatNumber(tServiceHead.iunitPrice3),
            trucksize: tServiceHead.trucksize,
            rateot: formatNumber(tServiceHead.rateot),
            nextServicecode: tServiceHead.nextServicecode,
            flgIntown: tServiceHead.flgIntown,
            flgAuger: tServiceHead.flgAuger,
            truckwheel: tServiceHead.truckwheel
        });

        // get next service code 
        if (tServiceHead.nextServicecode) {
            onSearchTextChange(tServiceHead.nextServicecode)
        }
        return () => {
        };
    }, [tServiceHead]);

    const initial = async () => {
        const tTypeunitRefsRes = await tTypeunitRefService.getTTypeunitRefList();
        const tTypetruckRefsRes = await tTypetruckRefService.getTTypetruckRefList();
        const ryReftypeassetsRes = await ryReftypeassetService.getRyReftypeassetList();
        const ryTruckwheelsRes = await ryTruckwheelService.getRyTruckwheelList();
        const ryRefsizetrucksRes = await ryRefsizetruckService.getRyRefsizetruckList();

        if (iserviceCode) {
            const res = await tServiceHeadService.getTServiceHeadById(Number(iserviceCode));
            setTServiceHead(res.data);
        } else {
            const nextCodeRes = await tServiceHeadService.getNextTServiceHeadCode();
            setTServiceHead({ ...tServiceHead, iserviceCode: nextCodeRes.data });
        }

        setTTypeunitRefs(tTypeunitRefsRes.data)
        setTTypetruckRefs(tTypetruckRefsRes.data);
        setRyReftypeassets(ryReftypeassetsRes.data);
        setRyTruckwheels(ryTruckwheelsRes.data);
        setRyRefsizetrucks(ryRefsizetrucksRes.data);
        setShowSpin(false);
    };

    const onFinish = async (values: any) => {
        const _tServiceHead: TServiceHead = {
            iserviceCode: values.iserviceCode,
            iserviceName: values.iserviceName,
            iunitCode: values.iunitCode,
            iunitPrice: parserNumber(values.iunitPrice),
            iremark: values.iremark,
            idltFlg: values.idltFlg ? 0 : 1,
            ieditFlg: 0,
            typetruck: values.typetruck,
            assettype: values.assettype,
            iunitPrice2: parserNumber(values.iunitPrice2),
            iunitPrice3: parserNumber(values.iunitPrice3),
            trucksize: values.trucksize,
            rateot: parserNumber(values.rateot),
            nextServicecode: values.nextServicecode,
            flgIntown: values.flgIntown,
            flgAuger: values.flgAuger,
            truckwheel: values.truckwheel ? values.truckwheel : '0000',
            isavStation: userInfo.saveStation,
            icreateUser: userInfo.userId,
            ilastUser: userInfo.userId,
            itermNo: userInfo.termNo
        };

        const _tServiceHistory: TServiceHistory = {
            id: 0,
            servicecode: values.iserviceCode,
            servicename: values.iserviceName,
            sysremark: !iserviceCode ? 'ADD' : "UPD",
            userremark: values.iremark,
            flg: values.idltFlg ? 0 : 1,
            price1: parserNumber(values.iunitPrice),
            price2: parserNumber(values.iunitPrice2),
            price3: parserNumber(values.iunitPrice3),
            otrate: parserNumber(values.rateot),
            nextservicecode: values.nextServicecode,
            flgtown: values.flgIntown,
            flgauger: values.flgAuger,
            savstation: userInfo.saveStation,
            createuser: userInfo.userId,
            termno: userInfo.termNo
        };

        if (!iserviceCode) {
            try {
                const res = await tServiceHeadService.postTServiceHead(_tServiceHead);
                await tServiceHistoryService.postTServiceHistory(_tServiceHistory)
                showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลบริการเรียบร้อยแล้ว!");
                form.resetFields();
                setTrigger(false);
                setVisibleTServiceHeadDrawer(false);
                history.replace("/app/tservice/view/" + res.data.iserviceCode);
            } catch (err) {
                showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
                setTrigger(false);
            }
        } else {
            try {
                await tServiceHeadService.updateTServiceHead(_tServiceHead);
                await tServiceHistoryService.postTServiceHistory(_tServiceHistory)
                showNotification(NotificationType.SUCCESS, "แก้ไขข้อมูลบริการเรียบร้อยแล้ว!");
                form.resetFields();
                setTrigger(false);
                setVisibleTServiceHeadDrawer(false);
                history.replace("/app/tservice/view/" + tServiceHead.iserviceCode);
            } catch (err) {
                showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
                setTrigger(false);
            }
        }
    };

    const generateServiceName = () => {
        let serviceName = "ค่าบริการ";
        if (form.getFieldValue('typeAsset')) {
            const typeAsset = form.getFieldValue('typeAsset')
            if (typeAsset != null && (typeAsset !== "001" && typeAsset !== "004" && typeAsset !== "007")) {
                serviceName = "ค่าเช่า";
            }
        }

        let truckType = "";
        if (form.getFieldValue('typetruck')) {
            const tTypetruckRef = tTypetruckRefs.find((item) => item.iid === form.getFieldValue('typetruck'));
            truckType = tTypetruckRef?.iname.toString() ? tTypetruckRef?.iname.toString() : '';
        }

        let truckSize = "";
        if (form.getFieldValue('trucksize') !== "000") {
            const ryRefsizetruck = ryRefsizetrucks.find((item) => item.id === form.getFieldValue('trucksize'));
            truckSize = ryRefsizetruck?.tsize.toString() ? ryRefsizetruck?.tsize.toString() : '';
        }

        let truckWheel = "";
        if (form.getFieldValue('truckwheel') !== "0000") {
            const ryTruckwheel = ryTruckwheels.find((item) => item.id === form.getFieldValue('truckwheel'));
            truckWheel = ryTruckwheel?.wheel.toString() ? ryTruckwheel?.wheel.toString() : '';
        }

        let auger = "";
        if (form.getFieldValue('flgAuger') === '00') {
            auger = "ติดหัวเจาะ";
        }

        serviceName = serviceName + " " + truckType + " " + truckSize + " " + truckWheel + " " + auger;
        form.setFieldsValue({ iserviceName: serviceName });
    };

    const onFinishFailed = (errorInfo: any) => {
        showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน");
        setTrigger(false);
    };

    const onSearchTextChange = async (searchText: string) => {
        if (searchText.length >= 2) {
            const res = await tServiceHeadService.getTServiceHeadByParam(searchText);
            setTServiceHeads(res.data);
        }
    }

    return (
        <>
            <Drawer
                title={
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "baseline",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography.Title level={4}>{title}</Typography.Title>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "",
                            }}
                        >
                            <Button
                                type="primary"
                                danger
                                icon={<CloseCircleOutlined />}
                                onClick={() => setVisibleTServiceHeadDrawer(false)}>
                                ยกเลิก
                            </Button>
                            <Button
                                onClick={() => setTrigger(true)}
                                type="primary"
                                icon={<SaveOutlined />}
                                style={{ marginLeft: "5px" }}
                            >
                                บันทึก
                            </Button>
                        </div>
                    </div>
                }
                width="90%"
                visible={visibleTServiceHeadDrawer}
                forceRender={true}
                placement="right"
                bodyStyle={{ paddingBottom: 80 }}
                closable={false}
                destroyOnClose={true}
            >
                <Content className="app-page-content">
                    <div>
                        <Spin spinning={showSpin}>
                            <Form
                                form={form}
                                name="tServiceHead"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>รหัสบริการ: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="iserviceCode"
                                            rules={[
                                                { required: false }
                                            ]}
                                        >
                                            <Input disabled={true} style={{ width: "200px" }} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>ชื่อบริการ: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="iserviceName"
                                            rules={[
                                                { required: true, message: "กรุณาป้อนชื่อบริการ" },
                                            ]}
                                        >
                                            <Input disabled={true} style={{ width: "550px" }} maxLength={200}></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>รายการ: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={80}>
                                            <Form.Item
                                                name="assettype"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Select
                                                    size="middle"
                                                    placeholder="เลือกรายการ"
                                                    optionFilterProp="children"
                                                    filterOption={true}
                                                    style={{ width: "200px" }}
                                                >
                                                    {ryReftypeassets.map((obj) => {
                                                        return (
                                                            <Option key={obj.id} value={obj.id}>
                                                                {obj.name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                label="ประเภทรถ: "
                                                name="typetruck"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Select
                                                    size="middle"
                                                    placeholder="เลือกประเภทรถ"
                                                    optionFilterProp="children"
                                                    filterOption={true}
                                                    onChange={generateServiceName}
                                                    style={{ width: "200px" }}
                                                >
                                                    {tTypetruckRefs.map((obj) => {
                                                        return (
                                                            <Option key={obj.iid} value={obj.iid}>
                                                                {obj.iname}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>จำนวนล้อรถ: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={85}>
                                            <Form.Item
                                                name="truckwheel"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Select
                                                    size="middle"
                                                    placeholder="เลือกจำนวนล้อรถ"
                                                    optionFilterProp="children"
                                                    filterOption={true}
                                                    onChange={generateServiceName}
                                                    style={{ width: "200px" }}
                                                >
                                                    {ryTruckwheels.map((obj) => {
                                                        return (
                                                            <Option key={obj.id} value={obj.id}>
                                                                {obj.wheel}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item
                                                label="ขนาด/รุ่น: "
                                                name="trucksize"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Select
                                                    size="middle"
                                                    placeholder="เลือกขนาด/รุ่น"
                                                    optionFilterProp="children"
                                                    filterOption={true}
                                                    onChange={generateServiceName}
                                                    style={{ width: "200px" }}
                                                >
                                                    {ryRefsizetrucks.map((obj) => {
                                                        return (
                                                            <Option key={obj.id} value={obj.id}>
                                                                {obj.tsize}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>ขอบเขตการให้บริการ: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={40}>
                                            <Form.Item
                                                name="flgIntown"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Select
                                                    size="middle"
                                                    placeholder="เลือกขอบเขตการให้บริการ"
                                                    optionFilterProp="children"
                                                    filterOption={true}
                                                    style={{ width: "200px" }}
                                                >
                                                    <Option key="00" value="00">นอกเขตอำเภอเมือง</Option>
                                                    <Option key="01" value="01">เขตอำเภอเมือง</Option>
                                                </Select>
                                            </Form.Item>

                                            <Form.Item
                                                label="รายการติดหัวเจาะ: "
                                                name="flgAuger"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Select
                                                    size="middle"
                                                    placeholder="เลือกรายการติดหัวเจาะ"
                                                    optionFilterProp="children"
                                                    filterOption={true}
                                                    onChange={generateServiceName}
                                                    style={{ width: "200px" }}
                                                >
                                                    <Option key="00" value="00">ติดหัวเจาะ</Option>
                                                    <Option key="01" value="01">ไม่ติดหัวเจาะ</Option>
                                                </Select>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>หน่วยนับ: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="iunitCode"
                                            rules={[
                                                { required: true, message: "กรุณาเลือกหน่วยนับ" }
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                size="middle"
                                                placeholder="เลือกหน่วยนับ"
                                                optionFilterProp="children"
                                                filterOption={true}
                                                style={{ width: "200px" }}
                                            >
                                                {tTypeunitRefs.map((obj) => {
                                                    return (
                                                        <Option key={obj.iid} value={obj.iid}>
                                                            {obj.iname}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>ราคาขาย 1: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Space align="baseline">
                                            <Form.Item
                                                name="iunitPrice"
                                                rules={[
                                                    { required: true, message: "กรุณาป้อนราคาขาย 1" },
                                                    {
                                                        validator: (_, value) => {
                                                            const iunitPrice = value ? parserNumber(value) : 0.0;
                                                            if (iunitPrice && (iunitPrice <= 0)) {
                                                                return Promise.reject(new Error('ราคาขาย 1 ไม่ถูกต้อง!'))
                                                            } else {
                                                                return Promise.resolve()
                                                            }
                                                        },
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    onKeyDown={(event: any) => textNumber(event)}
                                                    onPressEnter={(e: any) => form.setFieldsValue({ iunitPrice: formatNumber(parserNumber(e.target.value)) })}
                                                    onBlur={(e: any) => form.setFieldsValue({ iunitPrice: formatNumber(parserNumber(e.target.value)) })}
                                                    onFocus={(e) => e.target.select()}
                                                    style={{ textAlign: "end", width: "100%" }}
                                                ></Input>
                                            </Form.Item>
                                            <Typography.Text>บาท</Typography.Text>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>ราคาขาย 2: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Space align="baseline">
                                            <Form.Item
                                                name="iunitPrice2"
                                                rules={[
                                                    { required: true, message: "กรุณาป้อนราคาขาย 2" },
                                                    {
                                                        validator: (_, value) => {
                                                            const iunitPrice2 = value ? parserNumber(value) : 0.0;
                                                            if (iunitPrice2 && (iunitPrice2 <= 0)) {
                                                                return Promise.reject(new Error('ราคาขาย 2 ไม่ถูกต้อง!'))
                                                            } else {
                                                                return Promise.resolve()
                                                            }
                                                        },
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    onKeyDown={(event: any) => textNumber(event)}
                                                    onPressEnter={(e: any) => form.setFieldsValue({ iunitPrice2: formatNumber(parserNumber(e.target.value)) })}
                                                    onBlur={(e: any) => form.setFieldsValue({ iunitPrice2: formatNumber(parserNumber(e.target.value)) })}
                                                    onFocus={(e) => e.target.select()}
                                                    style={{ textAlign: "end", width: "100%" }}
                                                ></Input>
                                            </Form.Item>
                                            <Typography.Text>บาท</Typography.Text>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>ราคาขาย 3: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={"middle"}>
                                            <Space align="baseline">
                                                <Form.Item
                                                    name="iunitPrice3"
                                                    rules={[
                                                        { required: true, message: "กรุณาป้อนราคาขาย 3" },
                                                        {
                                                            validator: (_, value) => {
                                                                const iunitPrice3 = value ? parserNumber(value) : 0.0;
                                                                if (iunitPrice3 && (iunitPrice3 <= 0)) {
                                                                    return Promise.reject(new Error('ราคาขาย 3 ไม่ถูกต้อง!'))
                                                                } else {
                                                                    return Promise.resolve()
                                                                }
                                                            },
                                                        }
                                                    ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        onKeyDown={(event: any) => textNumber(event)}
                                                        onPressEnter={(e: any) => form.setFieldsValue({ iunitPrice3: formatNumber(parserNumber(e.target.value)) })}
                                                        onBlur={(e: any) => form.setFieldsValue({ iunitPrice3: formatNumber(parserNumber(e.target.value)) })}
                                                        onFocus={(e) => e.target.select()}
                                                        style={{ textAlign: "end", width: "100%" }}
                                                    ></Input>
                                                </Form.Item>
                                                <Typography.Text>บาท</Typography.Text>
                                            </Space>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>คิด OT/ชั่วโมง: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Space align="baseline">
                                            <Form.Item
                                                name="rateot"
                                                rules={[
                                                    { required: true, message: "กรุณาป้อนคิด OT/ชั่วโมง" },
                                                    {
                                                        validator: (_, value) => {
                                                            const rateot = value ? parserNumber(value) : 0.0;
                                                            if (rateot && (rateot <= 0)) {
                                                                return Promise.reject(new Error('เรต OT ไม่ถูกต้อง!'))
                                                            } else {
                                                                return Promise.resolve()
                                                            }
                                                        },
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    onKeyDown={(event: any) => textNumber(event)}
                                                    onPressEnter={(e: any) => form.setFieldsValue({ rateot: formatNumber(parserNumber(e.target.value)) })}
                                                    onBlur={(e: any) => form.setFieldsValue({ rateot: formatNumber(parserNumber(e.target.value)) })}
                                                    onFocus={(e) => e.target.select()}
                                                    style={{ textAlign: "end", width: "100%" }}
                                                ></Input>
                                            </Form.Item>
                                            <Typography.Text>บาท</Typography.Text>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>คิดเพิ่มในหน่วยเต็มวัน: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="nextServicecode"
                                            rules={[
                                                { required: false }
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                allowClear
                                                size="middle"
                                                placeholder="เลือกคิดเพิ่มในหน่วยเติมวัน"
                                                optionFilterProp="children"
                                                filterOption={true}
                                                style={{ width: "550px" }}
                                                onSearch={onSearchTextChange}
                                            >
                                                {tServiceHeads.map((obj) => {
                                                    return (
                                                        <Option key={obj.iserviceCode} value={obj.iserviceCode}>
                                                            ({obj.iserviceCode}) {obj.iserviceName}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>สภานะ: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="idltFlg"
                                            rules={[
                                                { required: false }
                                            ]}
                                            valuePropName="checked"
                                        >
                                            <Switch checkedChildren="ใช้งาน" unCheckedChildren="ไม่ใช้งาน" defaultChecked={true} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>หมายเหตุ: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="iremark"
                                            rules={[
                                                { required: false }
                                            ]}
                                        >
                                            <TextArea
                                                style={{ width: "545px" }}
                                                rows={3}
                                                name="address"
                                                maxLength={255}
                                            ></TextArea>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Spin>
                    </div>
                </Content>
            </Drawer>
        </>
    );
};

export default AddEditTServiceHeadDrawer;
