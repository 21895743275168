export function getPropValue<T>(obj: T, propName: string): any {
  const props = propName.split(".");
  // console.log("props ", props);

  let instance: any = obj;
  for (let prop of props) {
    // console.log(instance, prop);
    instance = getPropDetailValue(instance, prop);
  }

  return instance;
}

function getPropDetailValue<T>(instance: T, propName: string): any {
  type propType = keyof typeof instance;
  const value = getProperty(instance, propName as propType);
  return value;
}

export function getProperty<T, K extends keyof T>(
  obj: T,
  propertyName: K
): T[K] {
  return obj[propertyName]; // o[propertyName] is of type T[K]
}

export function getFlg(flg: number | string): boolean {
  // null or undefined or empty in case of insert
  if (flg === null || flg === undefined || flg === '') {
    return true
  }

  // 0 or '0' when read from DB
  if (typeof flg === 'number' && flg === 0) {
    return true
  } else if (typeof flg === 'string' && flg === '0') {
    return true
  }

  return false;
}

