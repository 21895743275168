
import { Layout } from "antd";
import React from "react";
import SiderNav from "src/layouts/SiderNav";
import HeaderNav from "./HeaderNav";

interface IProps {
  children?: React.ReactNode;
}

const AppLayout: React.FC<IProps> = (props) => {
  const { Content } = Layout;
  const { children } = props;

  return (
    <Layout>
      <HeaderNav />
      <Layout>
        <SiderNav />
        <Layout className="app-layout">
          <div className="app-content">
            <Content>{children}</Content>
            {/* <Footer>Footer</Footer> */}
          </div>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
