import http from "src/lib/http";
import Constants from "src/utils/constants";

const getTTypeworksheetRefList = () => {
  return http.get(
    Constants.DEFAULT_URL + "/tTypeworksheetRef"
  );
};

const getTTypeworksheetRef = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/tTypeworksheetRef/" + id);
}

const defaultApi = {
  getTTypeworksheetRef,
  getTTypeworksheetRefList,
};
export default defaultApi;