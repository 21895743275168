import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DatePicker, Form, Input, InputNumber, Modal, Select, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { TCustomerHeadView } from "src/domain/master/view/tCustomerHeadView";
import { RyAccount } from 'src/domain/ryAccount';
import { TPayinHead } from 'src/domain/tPayinHead';
import { TTypeworksheetRef } from 'src/domain/tTypeworksheetRef';
import ryAccountService from 'src/services/bank/ryAccountService';
import tCustomerHeadService from 'src/services/customer/tCustomerHeadService';
import tPayinHeadService from 'src/services/payin/tPayinHeadService';
import tTypeworksheetRefService from 'src/services/reference/tTypeworksheetRefService';
import { useAppSelector } from 'src/stores/store';
import Constants from 'src/utils/constants';
import { dateToString, toMomentDate } from 'src/utils/dateTimeUtils';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber, textNumber } from 'src/utils/numberUtils';

const { Option } = Select;
const { confirm } = Modal;

interface IProps {
  addTPayinHeadModalVisible: boolean;
  setAddTPayinHeadModalVisible: any;
  tPayinHead: TPayinHead;
}

const AddTPayinHeadModal: React.FC<IProps> = (props) => {
  const { addTPayinHeadModalVisible, setAddTPayinHeadModalVisible, tPayinHead } = props;
  const [form] = Form.useForm();
  const [trigger, setTrigger] = useState<boolean>(false);
  const [tTypeworksheetRefs, setTTypeworksheetRefs] = useState<TTypeworksheetRef[]>([]); //ประเภทใบสั่งงาน
  const [ryAccounts, setRyAccounts] = useState<RyAccount[]>([]); //บัญชีธนาคาร
  const [ryAccountSelected, setRyAccountSelected] = useState<RyAccount>({} as RyAccount);
  const [customers, setCustomers] = useState<TCustomerHeadView[]>([]);
  const [custSelected, setCustSelected] = useState<TCustomerHeadView>();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  useEffect(() => {
    if (trigger) {
      form.validateFields()
        .then(() => {
          confirm({
            title: "ยืนยัน!",
            icon: <ExclamationCircleOutlined />,
            content: "คุณต้องการที่จะบันทึกข้อมูลหรือไม่?",
            cancelText: "ยกเลิก",
            okText: "ยืนยัน",
            onOk() {
              form.submit();
            },
            onCancel() {
              setTrigger(false);
            }
          });
        }).catch(() => {
          setTrigger(false);
        });
    }
  }, [trigger]);

  useEffect(() => {
    loadData();
    return () => { };
  }, []);

  const loadData = async () => {
    if (addTPayinHeadModalVisible) {
      const tTypeworksheetRefRes = await tTypeworksheetRefService.getTTypeworksheetRefList();
      const ryAccountRes = await ryAccountService.getRyAccountList();
      setTTypeworksheetRefs(tTypeworksheetRefRes.data);
      setRyAccounts(ryAccountRes.data)

      if (tPayinHead.customercode) {
        const worksheetCustomer = await tCustomerHeadService.getCustomerByCode(tPayinHead.customercode);
        setCustSelected(worksheetCustomer.data);
        setCustomers([worksheetCustomer.data]);
      }

      form.setFieldsValue({
        financeType: tPayinHead.financetype,
        customerCode: tPayinHead.customercode,
        toAccountNumber: tPayinHead.toaccountnumber,
        chequeDate: tPayinHead.depositdate ? toMomentDate(tPayinHead.depositdate) : "",
        amount: tPayinHead.amount ? formatNumber(tPayinHead.amount) : 0.00,
        payinFeeAmt: tPayinHead.payinfeeamt ? formatNumber(tPayinHead.payinfeeamt) : 0.00,
        payinTotalAmt: tPayinHead.payintotalamt ? formatNumber(tPayinHead.payintotalamt) : 0.00,
        remark: tPayinHead.remark,
      });
    }
  };

  const handleSearchCustomer = async (data: any) => {
    if (data.length < 3) return;
    const res = await tCustomerHeadService.getCustomerByParam(data);
    setCustomers(res.data);
  };

  const handleChangeCustomer = (custCode: any) => {
    const custArray = customers as Array<TCustomerHeadView>;
    const findCust = custArray.find((ob) => ob.icustCode === custCode);
    setCustSelected(findCust);
  }

  const handleChangetoAccountNumber = (accountNumber: any) => {
    const ryAccountArr = ryAccounts as Array<RyAccount>;
    const findRyAccount = ryAccountArr.find((ob) => ob.accountnumber === accountNumber);
    setRyAccountSelected(findRyAccount!);
  }

  const handleChangeAmount = (data: number) => {
    const payinFeeAmt = form.getFieldValue("payinFeeAmt") ? parserNumber(form.getFieldValue("payinFeeAmt")) : 0;
    const payinTotalAmt = data - payinFeeAmt;
    form.setFieldsValue({
      payinTotalAmt: formatNumber(payinTotalAmt),
    });
  }

  const handleChangePayinFeeAmt = (data: number) => {
    const amount = form.getFieldValue("amount") ? parserNumber(form.getFieldValue("amount")) : 0;
    const payinTotalAmt = amount - data;
    form.setFieldsValue({
      amount: formatNumber(amount),
      payinTotalAmt: formatNumber(payinTotalAmt),
    });
  }

  const onFinish = async (values: any) => {
    let _tPayinHead: TPayinHead = {
      ...tPayinHead,
      depositdate: dateToString(values.depositDate),
      toaccountname: ryAccountSelected.accountname,
      toaccountnumber: ryAccountSelected.accountnumber,
      amount: parserNumber(values.amount),
      remark: values.remark.trim(),
      financetype: values.financeType,
      customercode: custSelected!.icustCode,
      customername: custSelected!.iprenameName?.trim() + custSelected!.iname.trim() + " " + (custSelected?.isurname ? custSelected?.isurname.trim() : ""),
      payinremainamt: parserNumber(values.amount),
      payinfeeamt: parserNumber(values.payinFeeAmt),
      payintotalamt: parserNumber(values.payinTotalAmt),
      branchid: userInfo.branchId,
      lastuser: userInfo.userId,
      termno: userInfo.termNo,
      savstation: userInfo.saveStation,
    }
    if (!tPayinHead.id) {
      _tPayinHead = {
        ..._tPayinHead,
        parentid: "",
        frombankcode: "",
        frombankbranchcode: "",
        tobankcode: "",
        tobankbranchcode: "",
        status: "00",
        referencenumber: "",
        payinconfirmpersonId: 0,
        payinconfirmdate: "",
        payinconfirmno: "",
        createuser: userInfo.userId,
      }
      try {
        await tPayinHeadService.createTPayinHead(_tPayinHead);
        showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลการเงินโอนเรียบร้อยแล้ว!");
        setAddTPayinHeadModalVisible(false);
        setTrigger(false);
      } catch (err) {
        showNotification(NotificationType.ERROR, "เกิดข้อผิดพลาด กรุณาติดต่อผู้แลระบบ !");
        setTrigger(false);
      }
    } else {
      try {
        await tPayinHeadService.updateTPayinHead(_tPayinHead);
        showNotification(NotificationType.SUCCESS, "แก้ไขข้อมูลการเงินโอนเรียบร้อยแล้ว!");
        setAddTPayinHeadModalVisible(false);
        setTrigger(false);
      } catch (err) {
        showNotification(NotificationType.ERROR, "เกิดข้อผิดพลาด กรุณาติดต่อผู้แลระบบ !");
        setTrigger(false);
      }
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "เกิดข้อผิดพลาด กรุณาติดต่อผู้แลระบบ !");
    setTrigger(false);
  };

  return (
    <div>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>บันทึกรายการเงินโอน</Typography.Title>
          </div>
        }
        visible={addTPayinHeadModalVisible}
        width={"50%"}
        style={{ paddingTop: "5px" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setAddTPayinHeadModalVisible(false)}
      >
        <Form
          form={form}
          name="assignCheque"
          initialValues={{ remember: true }}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >

          <Form.Item
            label="ชำระสำหรับใบงาน"
            name="financeType"
            rules={[{ required: true, message: "กรุณาระบุประเภทใบสั่งงาน!" }]}
          >
            <Select
              showSearch
              size="middle"
              placeholder="เลือกประเภทใบสั่งงาน"
              optionFilterProp="children"
              filterOption={true}
            >
              {tTypeworksheetRefs.map((obj: TTypeworksheetRef) => {
                return (
                  <Option key={obj.iid} value={obj.iid}>
                    {obj.iname}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="ลูกค้า"
            name="customerCode"
            rules={[{ required: true, message: "กรุณาระบุลูกค้า !" }]}
          >
            <Select
              showSearch
              allowClear
              size="middle"
              placeholder="เลือกลูกค้า"
              optionFilterProp="children"
              filterOption={true}
              onSearch={handleSearchCustomer}
              onSelect={handleChangeCustomer}
            >
              {customers.map((obj) => {
                return (
                  <Option key={obj.icustCode} value={obj.icustCode}>
                    {obj.iprenameName}{obj.iname}  {obj.isurname ? obj.isurname : ""} : {obj.iphone}<br />
                    <span style={{ marginLeft: "1rem", fontSize: 12 }}>
                      {obj.iaddress}{'   '}อำเภอ/เขต{'   '}{obj.iamphurName}{'   '}จังหวัด{'   '}{obj.iprovinceName}{'   '}{obj.ipostcode}
                    </span>
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="โอนเข้าบัญชี"
            name="toAccountNumber"
            rules={[{ required: true, message: "กรุณาระบุบัญชีธนาคารที่ลูกค้าโอนเงินเข้า !" }]}
          >
            <Select
              showSearch
              size="middle"
              placeholder="เลือกโอนเข้าบัญชี"
              optionFilterProp="children"
              filterOption={true}
              onSelect={handleChangetoAccountNumber}
            >
              {ryAccounts.map((obj: RyAccount) => {
                return (
                  <Option key={obj.accountnumber} value={obj.accountnumber}>
                    {obj.accountnumber} : {obj.accountname}<br />
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="วันที่โอน"
            name="depositDate"
            rules={[{ required: true, message: "กรุณาระบุวันที่โอน !" }]}
          >
            <DatePicker
              format={Constants.DATE_FORMAT}
              size="middle"
              placeholder="เลือกวันที่โอน"
              style={{ width: 300 }}
            />
          </Form.Item>
          <Form.Item
            label="จำนวนเงิน"
            name="amount"
            rules={[{ required: true, message: "กรุณาระบุจำนวนเงิน !" }]}
          >
            <Input
              type="text"
              onKeyDown={(event: any) => textNumber(event)}
              onPressEnter={(e: any) => form.setFieldsValue({ amount: formatNumber(parserNumber(e.target.value)) })}
              onBlur={(e: any) => form.setFieldsValue({ amount: formatNumber(parserNumber(e.target.value)) })}
              onFocus={(e) => e.target.select()}
              style={{ textAlign: "end", width: "63%" }}
              onChange={(e: any) => handleChangeAmount(parserNumber(e.target.value))}
            ></Input>
          </Form.Item>
          <Form.Item
            label="หักค่าธรรมเนียมการโอน"
            name="payinFeeAmt"
            rules={[{ required: false }]}
          >
            <Input
              type="text"
              onKeyDown={(event: any) => textNumber(event)}
              onPressEnter={(e: any) => form.setFieldsValue({ payinFeeAmt: formatNumber(parserNumber(e.target.value)) })}
              onBlur={(e: any) => form.setFieldsValue({ payinFeeAmt: formatNumber(parserNumber(e.target.value)) })}
              onFocus={(e: any) => e.target.select()}
              style={{ textAlign: "end", width: "63%" }}
              onChange={(e: any) => handleChangePayinFeeAmt(parserNumber(e.target.value)) }
            ></Input>
          </Form.Item>
          <Form.Item
            label="คงเหลือหลังหักค่าธรรมเนียม"
            name="payinTotalAmt"
            rules={[{ required: true, message: "กรุณาระบุคงเหลือหลังหักค่าธรรมเนียม !" }]}
          >
            <InputNumber
              style={{ textAlign: "right", width: 300, fontWeight: "bold", color: "#FD1212", backgroundColor: "#F7F7F8" }}
              controls={false}
              precision={2}
            >
            </InputNumber>
          </Form.Item>
          <Form.Item
            label="หมายเหตุ"
            name="remark"
            rules={[{ required: true, message: "กรุณาระบุหมายเหตุ !" }]}
          >
            <TextArea
              rows={2}
            ></TextArea>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default AddTPayinHeadModal