import { TRangradWangbillHeadData } from "src/domain/view/tRangradWangbillHeadData";
import http from "../lib/http";
import Constants from "../utils/constants";

const isExitByDebitNoteNo = (debitNoteNo: string) => {
  return http.get(Constants.DEFAULT_URL + "/tRangradWangbillHead/isExitByDebitNoteNo", {
    params: {
      debitNoteNo,
    },
  });
};

const isExitByDebitNoteNoAndType = (debitNoteNo: string, type: string) => {
  return http.get(Constants.DEFAULT_URL + "/tRangradWangbillHead/isExitByDebitNoteNoAndType", {
    params: {
      debitNoteNo,
      type
    },
  });
};

const getByDebitorCode = (debitorCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tRangradWangbillHead/getByDebitorCode", {
    params: {
      debitorCode,
    },
  });
};

const getByDebitNoteNo = (debitNoteNo: string) => {
  return http.get(Constants.DEFAULT_URL + "/tRangradWangbillHead/getByDebitNoteNo", {
    params: {
      debitNoteNo,
    },
  });
};

const getViewByDebitNoteNo = (debitNoteNo: string) => {
  return http.get(Constants.DEFAULT_URL + "/tRangradWangbillHead/getViewByDebitNoteNo", {
    params: {
      debitNoteNo,
    },
  });
};

const getTRangradWangbillHeadDataByDebitnoteNo = (debitnoteNo: string) => {
  return http.get(Constants.DEFAULT_URL + "/tRangradWangbillHead/getTRangradWangbillHeadDataByDebitnoteNo/" + debitnoteNo);
};

const getByDebitNoteNoAndType = (debitorCode: string, type: string) => {
  return http.get(Constants.DEFAULT_URL + "/tRangradWangbillHead/getByDebitNoteNoAndType", {
    params: {
      debitorCode,
      type
    },
  });
};

const getByDateStatus = (fromDate: string,  toDate: string,  debitNoteCodeDebitorName: string,  debitNoteStatuses: string) => {
  return http.get(Constants.DEFAULT_URL + "/tRangradWangbillHead/getByDateStatus", {
    params: {
      fromDate,
      toDate,
      debitNoteCodeDebitorName,
      debitNoteStatuses
    },
  });
};

const getFinanceRangradWangbillList = (fromDate: string,  toDate: string,  debitNoteCodeDebitorName: string,  debitNoteStatuses: string) => {
  return http.get(Constants.DEFAULT_URL + "/tRangradWangbillHead/getFinanceRangradWangbillList", {
    params: {
      fromDate,
      toDate,
      debitNoteCodeDebitorName,
      debitNoteStatuses
    },
  });
};

const printPreviewAndSaveRangradWangbill = (tRangradWangbillHeadData: TRangradWangbillHeadData, showAllPage: boolean) => {
  return http.post(
    Constants.DEFAULT_URL + "/worksheetReport/printPreviewAndSaveRangradWangbill/"+showAllPage,
    tRangradWangbillHeadData,
    {
      responseType: "blob",
    },
  );
};

const getTrengradWangbillHeadList = (branch: string,
  wshCodeDebitnoteCode: string,
  debitorId: string,
  dateAppointMentStart: string,
  dateAppointMentFinish: string,
  dStartEntry: string,
  dFinishEntry: string) => {
  return http.get(Constants.DEFAULT_URL + "/tRangradWangbillHead/getTrengradWangbillHeadList", {
    params: {
      branch,
      wshCodeDebitnoteCode,
      debitorId,
      dateAppointMentStart,
      dateAppointMentFinish,
      dStartEntry,
      dFinishEntry
    },
  });
};

const createRangradWangBill = (tRangradWangbillHeadData: TRangradWangbillHeadData) => {
  return http.post(Constants.DEFAULT_URL + "/tRangradWangbillHead/createRangradWangBill", tRangradWangbillHeadData);
};

const saveTRangradWangbillHeadData = (tRangradWangbillHeadData: TRangradWangbillHeadData) => {
  return http.post(Constants.DEFAULT_URL + "/tRangradWangbillHead/saveTRangradWangbillHeadData", tRangradWangbillHeadData);
};

const deleteTRangradWangbillHeadData = (tRangradWangbillHeadData: TRangradWangbillHeadData) => {
  return http.post(Constants.DEFAULT_URL + "/tRangradWangbillHead/deleteTRangradWangbillHeadData", tRangradWangbillHeadData);
};

const defaultApi = {
  isExitByDebitNoteNo,
  isExitByDebitNoteNoAndType,
  getByDebitorCode,
  getByDebitNoteNo,
  getViewByDebitNoteNo,
  getTRangradWangbillHeadDataByDebitnoteNo,
  getByDebitNoteNoAndType,
  getByDateStatus,
  getFinanceRangradWangbillList,
  printPreviewAndSaveRangradWangbill,
  getTrengradWangbillHeadList,
  createRangradWangBill,
  saveTRangradWangbillHeadData,
  deleteTRangradWangbillHeadData
};
export default defaultApi;