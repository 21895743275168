import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Row, Select, Table, Typography, Modal } from 'antd';
import moment from 'moment';
import Constants from 'src/utils/constants';
import PageHeader from 'src/layouts/PageHeader';
import { Content } from 'antd/lib/layout/layout';
import { useAppSelector } from 'src/stores/store';
import { PrinterOutlined, SearchOutlined } from '@ant-design/icons';
import reportService from 'src/services/reportService';
import { formatNumber, formatNumberInt } from 'src/utils/numberUtils';
import { toThDate } from 'src/utils/dateTimeUtils';
import PdfViewPopup from './PdfViewPopup';
import { RyRefbranch } from 'src/domain/ryRefbranch';
import ryRefbranchService from 'src/services/reference/ryRefbranchService';
import { RangradArrearsReportData } from 'src/domain/worksheet/rangradArrearsReportData';

const { Option } = Select;

const RangradArrearsReport = () => {
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  const [ryRefBranchSelected, setRyRefBranchSelected] = useState<RyRefbranch>({} as RyRefbranch);
  const [ryRefBranchs, setRyRefBranchs] = useState<RyRefbranch[]>([]);  //สาขา

  const [rangradArrearsList, setRangradArrearsList] = useState<RangradArrearsReportData[]>([]);
  const [rangradArrearsPreview, setRangradArrearsPreview] = useState<any>();


  const [visiblePDF, setVisiblePDF] = useState(false);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false);

  const [countRowOver, setCountRowOver] = useState<number>(0);
  const [sumNetTotalAmt, setSumNetTotalAmt] = useState<any>();
  const [sumOverNetTotalAmt, setSumOverNetTotalAmt] = useState<any>();
  const [sumNotOverNetTotalAmt, setSumNotOverNetTotalAmt] = useState<any>();
  
  const [fromDateParam, setFromDateParam] = useState<any>();
  const [toDateParam, setToDateParam] = useState<any>();
  const [branchParam, setBranchParam] = useState<any>();

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 8,
      align: "center" as "center",
      render: (value: number, item: RangradArrearsReportData, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
    },
    {
      title: "ครบกำหนดชำระเงิน",
      dataIndex: "appointmentDate",
      key: "appointmentDate",
      width: 80,
      align: "center" as "center",
      render: (value: any, item: RangradArrearsReportData) => (<>{toThDate(item.appointmentDate)}</>)
    },
    {
      title: "เลขที่ใบแจ้งหนี้",
      dataIndex: "debitnoteNo",
      key: "debitnoteNo",
      width: 90,
    },
    {
      title: "ลูกหนี้",
      dataIndex: "debitorName",
      key: "debitorName",
      width: 200,
    },
    {
      title: "จำนวนเงินก่อนภาษี",
      dataIndex: "amount",
      key: "amount",
      width: 80,
      align: "right" as "right",
      render: (value: any, item: RangradArrearsReportData) => {
        return <span>{formatNumber(item.amount)}</span>;
      },
    },
    {
      title: "ส่วนลดใบแจ้งหนี้",
      dataIndex: "discount",
      key: "discount",
      width: 80,
      align: "right" as "right",
      render: (value: any, item: RangradArrearsReportData) => {
        return <span>{formatNumber(item.discount)}</span>;
      },
    },
    {
      title: "รวม",
      dataIndex: "total",
      key: "total",
      width: 80,
      align: "right" as "right",
      render: (value: any, item: RangradArrearsReportData) => {
        return <span>{formatNumber(item.total)}</span>;
      },
    },
    {
      title: "ภาษี",
      dataIndex: "vat",
      key: "vat",
      width: 60,
      align: "right" as "right",
      render: (value: any, item: RangradArrearsReportData) => {
        return <span>{formatNumber(item.vat)}</span>;
      },
    },
    {
      title: "สุทธิ",
      dataIndex: "netTotal",
      key: "netTotal",
      width: 60,
      align: "right" as "right",
      render: (value: any, item: RangradArrearsReportData) => {
        return <span>{formatNumber(item.netTotal)}</span>;
      },
    },
    {
      title: "จำนวนครั้งที่ออกใบเก็บเงิน",
      dataIndex: "numOfSendToEmp",
      key: "numOfSendToEmp",
      width: 120,
      align: "right" as "right",
      render: (value: any, item: RangradArrearsReportData) => {
        return <span>{formatNumberInt(item.numOfSendToEmp)}</span>;
      },
    },
    {
      title: "หมายเหตุ",
      dataIndex: "remark",
      key: "remark",
      width: 150,
    },
  ]

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });

    initData();
    form.submit();
  }, []);

  useEffect(() => {
    countRowOverData(rangradArrearsList);
  }, [rangradArrearsList])

  const initData = async () => {
    const refbranchRes = await ryRefbranchService.getRyRefbranchList();
    setRyRefBranchs(refbranchRes.data);

    form.setFieldsValue({
      fromDate: moment(new Date(), Constants.DATE_FORMAT).startOf('month'),
      toDate: moment(new Date(), Constants.DATE_FORMAT).endOf('month'),
      branch: "",
    });
  }

  const onFinish = async (values: any) => {
    setLoading(true);
    const fromDate = moment(values.fromDate).format("yyyyMMDD");
    const toDate = moment(values.toDate).format("yyyyMMDD");
    const branch = values.branch ? values.branch : "";

    const res = await reportService.getRangradArrearsReport(fromDate, toDate, branch);
    // console.log('----------res--------', res.data) 

    setRangradArrearsList(res.data);
    setPage(1);

    setFromDateParam(fromDate);
    setToDateParam(toDate); 
    setBranchParam(branch);
    setLoading(false);
    
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  const onPrint = async () => {
    setVisiblePDF(true);
  }

  const rowClassNameFn = (record, index) => {
    let nowDate: string = moment(new Date(), Constants.DATE_FORMAT).format(Constants.DB_FORMAT);
    let condition = nowDate >= record.appointmentDate;
    
    return condition ? "row-red" : null;
  }

  const countRowOverData = (rangradArrearsList) => {
    let filterResult = rangradArrearsList.filter((record) => {
      let nowDate: string = moment(new Date(), Constants.DATE_FORMAT).format(Constants.DB_FORMAT);

      return nowDate >= record.appointmentDate;
    })
    
    let showCount = filterResult.length;
    setCountRowOver(showCount);
    
  }

  return (
    <>
      <PageHeader title="รายงานเร่งรัดค้างเก็บเงิน"></PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="rengradWangbill"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
            }}
          >
           
            <Row>
              <Col span={2} />
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>สาขา: </Typography.Text>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="branch"
                  style={{ marginBottom: "3px" }}
                >
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกสาขา"
                    optionFilterProp="children"
                    filterOption={true}
                    onClear={() => setRyRefBranchSelected({} as RyRefbranch)}
                  >
                    {ryRefBranchs.map((obj) => {
                      return (
                        <Option key={obj.id} value={obj.id}>
                          {obj.id}: {obj.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={2} />
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>วันที่ตั้งแต่: </Typography.Text>
              </Col>
              <Col span={3}>
                <Form.Item
                  name="fromDate"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={1} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ถึง: </Typography.Text>
              </Col>
              <Col span={3}>
                <Form.Item
                  name="toDate"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={2} />
              <Col span={11} style={{ textAlign: "right", paddingTop: "0.5em"}}>
                <Button
                  size="small"
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={form.submit}
                >
                  ค้นหา
                </Button>
              </Col>
              <Col span={8}></Col>
            </Row>
          </div>
        </Form>
           
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "0px 20px 10px 0px",
            borderBottom: '2px solid #c9c9c9'
          }}
        >
          <Button
          style={{
            marginLeft: "30px",}}
            size="small"
            type="primary"
            icon={<PrinterOutlined />}
            onClick={onPrint}
          >
            พิมพ์
          </Button>
          <Modal
            style={{ top: 20 }}
            width={"90vw"}
            title="RS001"
            bodyStyle={{ height: "85vh" }}
            centered={true}
            visible={isPreviewModalVisible}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={() => setIsPreviewModalVisible(false)}
          >
            <iframe title=" " src={rangradArrearsPreview} width={"100%"} height={"100%"}></iframe>
          </Modal>
        </div>

        <div>
          <Table
            rowClassName={rowClassNameFn}
            columns={columns}
            dataSource={rangradArrearsList}
            size="small"
            loading={loading}
            rowKey={(item: RangradArrearsReportData) => item.debitnoteNo!}
            scroll={{ x: 1800 }}
            pagination={{
              pageSize: 10,
              current: page,
              onChange(current) {
                setPage(current);
              }
            }}
            summary={(pageData: any) => {
              let sumNetTotal = 0.0;
              let sumOverNetTotal = 0.0;
              let sumNotOverNetTotal = 0.0;
              rangradArrearsList.forEach((item) =>{
                sumNetTotal += item.netTotal;

                let nowDate: string = moment(new Date(), Constants.DATE_FORMAT).format(Constants.DB_FORMAT);
                let condition = nowDate >= item.appointmentDate;
                if(condition) {
                  sumOverNetTotal += item.netTotal;
                }

                sumNotOverNetTotal = sumNetTotal - sumOverNetTotal;
              });

              setSumNetTotalAmt(sumNetTotal);
              setSumOverNetTotalAmt(sumOverNetTotal);
              setSumNotOverNetTotalAmt(sumNotOverNetTotal);
              
              return (
                <></>
              )
            }}
          />
        </div>

        <div style={{
            padding: "20px 0px 0px 0px"
          }}>
          <Row>
            <Col span={7} style={{ marginLeft: "2em" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> จำนวนเงินค้างเก็บทั้งหมด: </Typography.Text>
            </Col>
            <Col span={4} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold", color: "red" }}> {formatNumber(sumNetTotalAmt)} </Typography.Text>
              <Typography.Text style={{ fontWeight: "bold", margin: "0.5em" }}> บาท </Typography.Text>
            </Col>
            <Col span={1}></Col>
            <Col span={6}>
              <Typography.Text style={{ fontWeight: "bold" }}> จำนวนใบแจ้งหนี้ค้างเก็บเงินทั้งหมด: </Typography.Text>
            </Col>
            <Col span={5} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold", color: "red" }}> {formatNumberInt(rangradArrearsList.length)} </Typography.Text>
              <Typography.Text style={{ fontWeight: "bold", margin: "0.5em" }}> รายการ </Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col span={7} style={{ marginLeft: "2em" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> จำนวนเงินที่เลยกำหนดชำระเงิน: </Typography.Text>
            </Col>
            <Col span={4} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold", color: "red" }}> {formatNumber(sumOverNetTotalAmt)} </Typography.Text>
              <Typography.Text style={{ fontWeight: "bold", margin: "0.5em" }}> บาท </Typography.Text>
            </Col>
            <Col span={1}></Col>
            <Col span={6}>
              <Typography.Text style={{ fontWeight: "bold" }}> จำนวนใบแจ้งหนี้เลยกำหนดชำระ: </Typography.Text>
            </Col>
            <Col span={5} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold", color: "red" }}> {formatNumberInt(countRowOver)} </Typography.Text>
              <Typography.Text style={{ fontWeight: "bold", margin: "0.5em" }}> รายการ </Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col span={7} style={{ marginLeft: "2em" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> จำนวนเงินที่ยังไม่ครบกำหนดชำระเงิน: </Typography.Text>
            </Col>
            <Col span={4} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold", color: "red" }}> {formatNumber(sumNotOverNetTotalAmt)} </Typography.Text>
              <Typography.Text style={{ fontWeight: "bold", margin: "0.5em" }}> บาท </Typography.Text>
            </Col>
            <Col span={1}></Col>
            <Col span={6}>
              <Typography.Text style={{ fontWeight: "bold" }}> จำนวนใบแจ้งหนี้ยังไม่ครบกำหนดชำระ: </Typography.Text>
            </Col>
            <Col span={5} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold", color: "red" }}> {formatNumberInt(rangradArrearsList.length - countRowOver)} </Typography.Text>
              <Typography.Text style={{ fontWeight: "bold", margin: "0.5em" }}> รายการ </Typography.Text>
            </Col>
          </Row>
        </div>
      </Content>

      <Modal
        title= 'print'
        centered
        closable={true}
        visible={visiblePDF}
        onCancel={() => setVisiblePDF(false)}
        width="400"
      >
        <PdfViewPopup
          urlPath={reportService.getRangradArrearsReportPreview(fromDateParam, toDateParam, branchParam)}
          onClose={() => setVisiblePDF(false)}
        />
      </Modal>
    </>
  )
}

export default RangradArrearsReport