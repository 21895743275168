import http from "src/lib/http";
import Constants from "src/utils/constants";

const getRyReftypeassetList = () => {
  return http.get(
    Constants.DEFAULT_URL + "/ryReftypeasset"
  );
};

const getRyReftypeasset = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/ryReftypeasset/" + id);
}

const defaultApi = {
  getRyReftypeasset,
  getRyReftypeassetList,
};
export default defaultApi;