import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Table, Typography, Modal } from 'antd';
import PageHeader from 'src/layouts/PageHeader';
import { Content } from 'antd/lib/layout/layout';
import { useAppSelector } from 'src/stores/store';
import { PrinterOutlined, SearchOutlined } from '@ant-design/icons';
import reportService from 'src/services/reportService';
import { formatNumber, formatNumberInt } from 'src/utils/numberUtils';
import PdfViewPopup from './PdfViewPopup';
import { DebitorReportData } from 'src/domain/worksheet/debitorReportData';

const { Option } = Select;

const DebitorReport = () => {
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  const [debitorList, setDebitorList] = useState<DebitorReportData[]>([]);
  const [debitorPreview, setDebitorPreview] = useState<any>();

  const [visiblePDF, setVisiblePDF] = useState(false);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false);

  const [nameParam, setNameParam] = useState<any>();
  const [telParam, setTelParam] = useState<any>();

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 8,
      align: "center" as "center",
      render: (value: number, item: DebitorReportData, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
    },
    {
      title: "เลขที่บัญชีลูกหนี้",
      dataIndex: "id",
      key: "id",
      width: 50,
      align: "center" as "center",
    },
    {
      title: "ชื่อ - สกุล",
      dataIndex: "fullName",
      key: "fullName",
      width: 160,
    },
    {
      title: "เบอร์โทร",
      dataIndex: "fullNumber",
      key: "fullNumber",
      width: 130,
    },
    {
      title: "วงเงิน",
      dataIndex: "creditAmount",
      key: "creditAmount",
      width: 80,
      align: "right" as "right",
      render: (value: any, item: DebitorReportData) => {
        return <span>{formatNumber(item.creditAmount)}</span>;
      },
    },
    {
      title: "ใช้วงเงิน",
      dataIndex: "currentUsage",
      key: "currentUsage",
      width: 80,
      align: "right" as "right",
      render: (value: any, item: DebitorReportData) => {
        return <span>{formatNumber(item.currentUsage)}</span>;
      },
    },
    {
      title: "เครดิต",
      dataIndex: "creditDate",
      key: "creditDate",
      width: 30,
      align: "right" as "right",
      render: (value: any, item: DebitorReportData) => {
        return <span>{formatNumberInt(item.creditDate)}</span>;
      },
    },
    {
      title: "หมายเหตุ",
      dataIndex: "remark",
      key: "remark",
      width: 150,
    },
  ]

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });

    initData();
    form.submit();
  }, []);

  const initData = async () => {
    form.setFieldsValue({
      debitorName: "",
      debitorTel: "",
    });
  }

  const onFinish = async (values: any) => {
    setLoading(true);
    const debitorName = values.debitorName ? values.debitorName : "";
    const debitorTel = values.debitorTel ? values.debitorTel : "";

    const res = await reportService.getDebitorReport(debitorName, debitorTel);
    // console.log('----------res--------', res.data)
    setDebitorList(res.data);
    setLoading(false);
    setPage(1);

    setNameParam(debitorName);
    setTelParam(debitorTel);    
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  const onPrint = async () => {
    setVisiblePDF(true);
  }


  return (
    <>
      <PageHeader title="รายงานข้อมูลลูกหนี้"></PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="rengradWangbill"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
            }}
          >
           
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ชื่อ - สกุล: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item name="debitorName" style={{ marginBottom: "3px" }}>
                  <Input style={{ marginRight: 5 }}></Input>
                </Form.Item>
              </Col>
              <Col span={9}></Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เบอร์โทร: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item name="debitorTel" rules={[{ required: false }]}>
                  <Input style={{ marginRight: 5 }}></Input>
                </Form.Item>
              </Col>
              <Col span={9}></Col>
            </Row>
            <Row>
              <Col span={12} style={{ textAlign: "right"}}>
                <Button
                  size="small"
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={form.submit}
                >
                  ค้นหา
                </Button>
              </Col>
              <Col span={8}></Col>
            </Row>
          </div>
        </Form>
           
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            // margin: '0 0 10px 0',
            padding: "0px 20px 10px 0px",
            borderBottom: '2px solid #c9c9c9'
          }}
        >
          <Button
          style={{
            marginLeft: "30px",}}
            size="small"
            type="primary"
            icon={<PrinterOutlined />}
            onClick={onPrint}
          >
            พิมพ์
          </Button>
          <Modal
            style={{ top: 20 }}
            width={"90vw"}
            title="RS001"
            bodyStyle={{ height: "85vh" }}
            centered={true}
            visible={isPreviewModalVisible}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={() => setIsPreviewModalVisible(false)}
          >
            <iframe title=" " src={debitorPreview} width={"100%"} height={"100%"}></iframe>
          </Modal>
        </div>

        <div>
          <Table
            columns={columns}
            dataSource={debitorList}
            size="small"
            loading={loading}
            rowKey={(item: DebitorReportData) => item.id!}
            scroll={{ x: 1800 }}
            pagination={{
              pageSize: 10,
              current: page,
              onChange(current) {
                setPage(current);
              }
            }}
          />
        </div>
      </Content>

      <Modal
        title= 'print'
        centered
        closable={true}
        visible={visiblePDF}
        onCancel={() => setVisiblePDF(false)}
        width="400"
      >
        <PdfViewPopup
          urlPath={reportService.getDebitorReportPreview(nameParam, telParam)}
          onClose={() => setVisiblePDF(false)}
        />
      </Modal>
    </>
  )
}

export default DebitorReport