import { ExclamationCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Space, Table, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TCancelreserveHeadView } from "src/domain/tCancelreserveHeadView";
import PageHeader from "src/layouts/PageHeader";
import { useAppSelector } from "src/stores/store";
import Constants from "src/utils/constants";
import { NotificationType, showNotification } from "src/utils/notificationUtils";
import tCancelreserveHeadService from "../../services/tCancelreserveHeadService";
import { toThDate } from "../../utils/dateTimeUtils";

const { Title } = Typography;

const { confirm } = Modal;

const ViewTCancelreserveHead: React.FC<TCancelreserveHeadView> = () => {
    const [tCancelreserveHeads, setTCancelreserveHeads] = useState<TCancelreserveHeadView[]>([]);
    const [selectedTCancelreserveHeads, setSelectedTCancelreserveHeads] = useState<TCancelreserveHeadView[]>([]);
    const history = useHistory();
    const [loading, setLoading] = React.useState(true);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const userInfo = useAppSelector((state) => state.authen.authenUser.user);
    const [form] = Form.useForm();

    useEffect(() => {
        initial()
    }, []);

    const dischargeColumns = [
        {
            title: "เลขที่ใบสั่งจอง",
            dataIndex: "ireserveCode",
            key: "ireserveCode",
            width: 130,
        },
        {
            title: "ลูกค้า",
            dataIndex: "icustName",
            key: "icustName",
            width: 200,
        },
        {
            title: "วันที่เริ่มงาน-วันที่สิ้นสุดงาน",
            dataIndex: "istartDate",
            key: "istartDate",
            width: 150,
            render: (value: any, item: TCancelreserveHeadView) => (<>{toThDate(item.istartDate)} - {toThDate(item.iendDate)}</>)
        },
        {
            title: "วันที่ครบกำหนดยืนยัน",
            dataIndex: "iconfirmDate",
            key: "iconfirmDate",
            width: 150,
            render: (value: any, item: TCancelreserveHeadView) => (<> {toThDate(item.iconfirmDate)}</>)
        },
        {
            title: "ที่ลูกค้าทำการจอง",
            dataIndex: "ientryDate",
            key: "ientryDate",
            width: 150,
            render: (value: any, item: TCancelreserveHeadView) => (<> {toThDate(item.ientryDate)}</>)
        },
        {
            title: "เบอร์ติดต่อลูกค้า",
            dataIndex: "icustPhone",
            key: "icustPhone",
            width: 250,
        },
    ];

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[], selectedRows: TCancelreserveHeadView[]) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedTCancelreserveHeads(selectedRows);
        },

    };

    const onConfirmCancelreserve = () => {
        if (selectedTCancelreserveHeads.length === 0) {
            showNotification(NotificationType.ERROR, "ไม่พบข้อมูลใด ๆ ที่จะบันทึก!");
            return;
        }
        if (form.getFieldValue('remark').length === 0) {
            showNotification(NotificationType.ERROR, "กรุณาใส่หมายเหตุการยกเลิก!");
            return;
        }

        selectedTCancelreserveHeads.map((item) => {
            item.cancelReserveRemark = form.getFieldValue('remark');
            return item;
        });

        confirm({
            title: "ยืนยัน!",
            icon: <ExclamationCircleOutlined />,
            content: "คุณต้องการที่จะบันทึกข้อมูลหรือไม่?",
            cancelText: "ยกเลิก",
            okText: "ยืนยัน",
            onOk() {
                form.submit();
            }
        });
    }
    const initial = async () => {
        form.setFieldsValue({
            remark: ""
        });

        setLoading(true);
        const branchId = userInfo.branchId;
        const res = await tCancelreserveHeadService.getCancelReserveByBranchId(branchId);
        setTCancelreserveHeads(res.data);
        setLoading(false);
    }

    const onReserveCodeChanged = (reserveCode: string) => {
        if (reserveCode.length !== 13) {
            return;
        }
        const selectedRowKeysTemp = [...selectedRowKeys, reserveCode];
        setSelectedRowKeys(selectedRowKeysTemp);

        const tCancelreserveHeadView = tCancelreserveHeads.find((obj) => obj.ireserveCode === reserveCode);
        setSelectedTCancelreserveHeads([...selectedTCancelreserveHeads, tCancelreserveHeadView!]);
    }

    const getItemColor = (item: TCancelreserveHeadView) => {
        const now = moment().format(Constants.DB_FORMAT);
        let foreColor = "black";
        if (Number(now) > Number(item.iconfirmDate)) {
            foreColor = 'red';
        }
        return 'text-' + foreColor;
    }

    const onFinish = async () => {
        try {
            setLoading(true);
            await tCancelreserveHeadService.confirmCancelReserveHead(selectedTCancelreserveHeads);
            showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลยกเลิกเรียบร้อยแล้ว!");
            setSelectedTCancelreserveHeads([]);
            setSelectedRowKeys([]);
            form.resetFields();
            setLoading(false);
            history.replace("/app/tCancelreserveHead/list");
        } catch (err) {
            showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        }
    }

    const onFinishFailed = (errorInfo: any) => {
    };

    return (
        <>
            <PageHeader title="รายการจอง" onClose={() => history.push("/app/tCancelreserveHead/list")}>
                <Button
                    size="small"
                    type="primary"
                    icon={<SaveOutlined />}
                    onClick={onConfirmCancelreserve}
                >
                    บันทึก
                </Button>
            </PageHeader>
            <Content className="app-page-content">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "1em 0 1em 0",
                        paddingLeft: "1em",
                        borderBlockEnd: "2px solid #c9c9c9",
                    }}
                >
                    <Space direction="vertical" size={"small"}>
                        <Form.Item
                            label="เลขที่ใบสั่งจอง"
                            name="reserveCode"
                            rules={[{ required: false }]}
                        >
                            <Input
                                allowClear
                                placeholder="ป้อนเลขที่ใบสั่งจอง"
                                onChange={(event) => onReserveCodeChanged(event.target.value)}
                            ></Input>
                        </Form.Item>
                    </Space>
                </div>
                <div>
                    <Space direction="horizontal" style={{ padding: "1em" }}>
                        <Space direction="horizontal" size={"small"}>
                            <Title level={5}>*** ตัวหนังสือสีดำ    หมายถึง  ใบจองที่ยังไม่ครบกำหนดยืนยัน, </Title>
                        </Space>
                        <Space direction="horizontal" size={"small"}>
                            <Title level={5} type="danger">***ตัวหนังสือสีแดง  หมายถึง  ใบจองที่เลยกำหนดยืนยัน</Title>
                        </Space>
                    </Space>
                    <Table
                        columns={dischargeColumns}
                        dataSource={tCancelreserveHeads}
                        size="small"
                        loading={loading}
                        rowClassName={getItemColor}
                        scroll={{ x: 1500, y: 300 }}
                        pagination={false}
                        rowSelection={rowSelection}
                        rowKey={(item: TCancelreserveHeadView) => item.ireserveCode!}
                    />
                </div>
                <div>
                    <Form
                        form={form}
                        name="tCancelreserveHeadSearch"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                margin: "1em",
                                paddingLeft: "1.5em",
                            }}
                        >
                            <Space direction="vertical">
                                <Form.Item
                                    name="remark"
                                    label="หมายเหตุ:"
                                    rules={[
                                        { required: true, message: "กรุณาระบุ หมายเหตุ" },
                                    ]}
                                >
                                    <TextArea
                                        rows={3}
                                        style={{ width: 550 }}
                                    ></TextArea>
                                </Form.Item>
                            </Space>
                        </div>
                    </Form>
                </div>
            </Content>
        </>
    );
};

export default ViewTCancelreserveHead;
