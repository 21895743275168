import { ClearOutlined, PlusSquareOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Space, Table, Tooltip, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TFinanceHead2View } from 'src/domain/finance/view/tFinanceHead2View';
import PageHeader from 'src/layouts/PageHeader';
import tfinanceHead2Service from 'src/services/finance/tFinanceHead2Service';
import Constants from 'src/utils/constants';
import { toThDate } from 'src/utils/dateTimeUtils';
import { formatNumber } from 'src/utils/numberUtils';

const FinanceReceivableList: React.FC<TFinanceHead2View> = () => {
  const [page, setPage] = React.useState(1);
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [tfinanceHead2Views, setTfinanceHead2Views] = useState<TFinanceHead2View[]>([]);

  function showWorksheetCode(item: TFinanceHead2View) {
    return (
      item.tfinanceDetailList.map((obj: any) => {
        return (
          <span>{obj.worksheetcode}<br></br></span>
        );
      })
    );
  }

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 70,
      render: (value: number, item: TFinanceHead2View, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
    },
    {
      title: "เลขที่การรับเงิน",
      dataIndex: "id",
      key: "id",
      width: 110,
      render: (value: string, item: TFinanceHead2View) => (
        <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.id}</Button>
      )
    },
    {
      title: "วันที่สร้าง",
      dataIndex: "entrydate",
      key: "entrydate",
      width: 80,
      render: (value: any, item: TFinanceHead2View) => {
        return <span>{item.entrydate ? toThDate(item.entrydate) : ""}</span>;
      },
    },
    {
      title: "จำนวนใบงาน",
      dataIndex: "worksheetQty",
      key: "worksheetQty",
      width: 100,
      align: "center" as "center",
      render: (value: any, item: TFinanceHead2View) => {
        return (
          <Tooltip placement="topRight" title={() => showWorksheetCode(item)} >
            <Typography style={{ color: "red" }}>{item.worksheetQty}</Typography>
          </Tooltip>);
      },
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "customername",
      key: "customername",
    },
    {
      title: "ข้อมูลงาน",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "ประเภทการรับชำระ",
      dataIndex: "paymentTypeName",
      key: "paymentTypeName",
      width: 130,
    },
    {
      title: "ค่าบริการ",
      dataIndex: "totalServiceAmt",
      key: "totalServiceAmt",
      width: 120,
      render: (value: any, item: TFinanceHead2View) => {
        return <span>{formatNumber(item.totalServiceAmt)}</span>;
      },
    },
    {
      title: "ผู้สร้าง",
      dataIndex: "createuserName",
      key: "createuserName",
      width: 150,
    },
  ];

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    form.setFieldsValue({
      fromDate: moment(new Date(), Constants.DATE_FORMAT),
      toDate: moment(new Date(), Constants.DATE_FORMAT)
    });

    // doSearch(form);
    form.submit();
  }, []);

  const onItemCliked = (item: TFinanceHead2View) => {
    history.replace("/app/financeReceivable/view/" + item.id);
  };

  const doSearch = async (values: any) => {
    setLoading(true);
    let fromDate = moment(values.fromDate).format("yyyyMMDD");
    let toDate = moment(values.toDate).format("yyyyMMDD");
    let searchText = values.worksheetCodeCustname;

    if (!searchText) {
      const res = await tfinanceHead2Service.getTfinanceHead2ByParam("", fromDate, toDate);
      setTfinanceHead2Views(res.data);
    } else {
      const res = await tfinanceHead2Service.getTfinanceHead2ByParam(searchText, "", "");
      setTfinanceHead2Views(res.data);
    }
    setLoading(false);
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  const onAdd = () => {
    history.replace("/app/financeReceivable/create/");
  }

  return (
    <>
      <PageHeader title="โปรแกรมการเงินรับชำระใบงานสด" >
        <Button
          size="small"
          type="primary"
          icon={<PlusSquareOutlined />}
          onClick={onAdd}
        >
          เพิ่ม
        </Button>
      </PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="receiveCashWorksheet"
          onFinish={doSearch}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
            }}
          >
            <Space direction="vertical" size={"small"}>
              <Form.Item
                label="เลขที่ใบงาน/ชื่อลูกค้า"
                name="worksheetCodeCustname"
                rules={[{ required: false }]}
              >
                <Input
                  allowClear
                  placeholder="ป้อนเลขที่ใบงาน/ชื่อลูกค้า"
                ></Input>
              </Form.Item>
              <Space align="baseline" size={"large"}>
                <Form.Item
                  label="วันที่สร้างตั้งแต่"
                  name="fromDate"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    placeholder="เลือกวันที่"
                  />
                </Form.Item>
                <Form.Item
                  label="ถึง"
                  name="toDate"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    placeholder="เลือกวันที่"
                  />
                </Form.Item>
              </Space>
            </Space>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: 'space-between',
              padding: "1em",
              borderBlockEnd: "2px solid #c9c9c9",
            }}
          >
            <span></span>
            <Space>
              <Button
                size="small"
                type="primary"
                icon={<SearchOutlined />}
                onClick={form.submit}
              >
                ค้นหา
              </Button>
              <Button
                size="small"
                type="primary"
                icon={<ClearOutlined />}
                onClick={() => form.resetFields()}
              >
                ล้าง
              </Button>
            </Space>
          </div>
          <Typography.Text style={{ color: "red", fontWeight: "bold" }}>**ต้องการดูเลขที่ใบงาน ให้เลื่อนเมาส์ไปไว้ในช่อง จำนวนใบงาน</Typography.Text>
        </Form>
        <div>
          <Table
            columns={columns}
            dataSource={tfinanceHead2Views}
            size="small"
            loading={loading}
            pagination={{
              pageSize: 15,
              onChange(current) {
                setPage(current);
              }
            }}
            rowKey={(item: TFinanceHead2View) => item.id}
            onRow={(item: TFinanceHead2View) => {
              return {
                onDoubleClick: () => onItemCliked(item),
              };
            }}
          />
        </div>
      </Content>
    </>
  );
};

export default FinanceReceivableList;