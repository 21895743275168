import http from "../../lib/http";
import Constants from "../../utils/constants";
import { BsAmphurH } from './../../domain/bsAmphurH';

const getBsAmphurHs = () => {
    return http.get(Constants.DEFAULT_URL + "/bsAmphurH");
};

const getBsAmphurHByProviceId = (iprovinceId: string) => {
    return http.get(Constants.DEFAULT_URL + "/bsAmphurH/getByProvinceId/" + iprovinceId);
};

const getBsAmphurHById = (iid: number) => {
    return http.get(Constants.DEFAULT_URL + "/bsAmphurH/" + iid);
}

const getBsAmphurHByName = (name: string) => {
    return http.get(Constants.DEFAULT_URL + "/bsAmphurH/getBsAmphurHByName/" + name);
}

const getBsAmphurHByNameAndProvinceId = (name: string, iprovinceId: number) => {
    return http.get(Constants.DEFAULT_URL + "/bsAmphurH/getBsAmphurHByNameAndProvinceId", {
        params: {
            name,
            iprovinceId,
        }
    });    
}

const updateBsAmphurH = (bsAmphurH: BsAmphurH) => {
    return http.put(Constants.DEFAULT_URL + "/bsAmphurH", bsAmphurH);
}

const postBsAmphurH = (bsAmphurH: BsAmphurH) => {
    return http.post(Constants.DEFAULT_URL + "/bsAmphurH", bsAmphurH);
}

const defaultApi = {
    getBsAmphurHs: getBsAmphurHs,
    getBsAmphurHByProviceId: getBsAmphurHByProviceId,
    getBsAmphurHById: getBsAmphurHById,
    getBsAmphurHByName: getBsAmphurHByName,
    postBsAmphurH: postBsAmphurH,
    getBsAmphurHByNameAndProvinceId: getBsAmphurHByNameAndProvinceId,
    updateBsAmphurH: updateBsAmphurH,
}
export default defaultApi;