import { Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Space, Spin, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { TBankBranchHead } from 'src/domain/master/tBankBranchHead';
import { TBankHead } from 'src/domain/master/tBankHead';
import { RyAccount } from 'src/domain/ryAccount';
import { TTypeInsurancePaymentRef } from 'src/domain/tTypeInsurancePaymentRef';
import { TWorksheetInsurance } from 'src/domain/worksheet/tWorksheetInsurance';
import ryAccountService from 'src/services/bank/ryAccountService';
import tBankBranchHeadService from 'src/services/bank/tBankBranchHeadService';
import tBankHeadService from 'src/services/bank/tBankHeadService';
import tTypeInsurancePaymentRefService from 'src/services/reference/tTypeInsurancePaymentRefService';
import { useAppSelector } from 'src/stores/store';
import Constants from 'src/utils/constants';
import { toMomentDate } from 'src/utils/dateTimeUtils';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber, textNumber } from 'src/utils/numberUtils';
const { Option } = Select;
const { TextArea } = Input;

interface ModalProp {
  custName: string;
  visibleInsurance: boolean;
  setVisibleInsurance: any;
  tWorksheetInsurance: TWorksheetInsurance;
  setTWorksheetInsurance: any;
}
const AddInsuranceModal: React.FC<ModalProp> = (props) => {
  const { custName, visibleInsurance, setVisibleInsurance, tWorksheetInsurance, setTWorksheetInsurance } = props; //input param
  const [form] = Form.useForm();
  const [submitTrigger, setSubmitTrigger] = useState<boolean>(false);
  const [showSpin, setShowSpin] = useState<boolean>(false);
  const [tTypeInsurancePaymentRefs, setTTypeInsurancePaymentRefs] = useState<TTypeInsurancePaymentRef[]>([]); //ประเภทการคำประกัน
  const [ryAccounts, setRyAccounts] = useState<RyAccount[]>([]);//โอนเข้าบัญชี
  const [tBankHeads, setTBankHeads] = useState<TBankHead[]>([]);//ธนาคาร
  const [tBankBranchHeads, setTBankBranchHeads] = useState<TBankBranchHead[]>([]);//สาขา
  const [tTypeInsurancePaymentRefSelected, setTTypeInsurancePaymentRefSelected] = useState<TTypeInsurancePaymentRef>({} as TTypeInsurancePaymentRef);

  //For set disable form item
  const [disableInsuranceAmt, setDisableInsuranceAmt] = useState<boolean>(true);
  const [disablePayDate, setDisablePayDate] = useState<boolean>(true);
  const [disablePayinAccountnumber, setDisablePayinAccountnumber] = useState<boolean>(true);
  const [disableBankCodeCheque, setDisableBankCodeCheque] = useState<boolean>(true);
  const [disableBankBranchCheque, setDisableBankBranchCheque] = useState<boolean>(true);
  const [disableInsuranceNumber, setDisableInsuranceNumber] = useState<boolean>(true);
  const [disableInsuranceExpire, setDisableInsuranceExpire] = useState<boolean>(true);
  const [disableInsuranceExpireMonth, setDisableInsuranceExpireMonth] = useState<boolean>(true);
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const tWorksheetInsuranceTemp = tWorksheetInsurance;

  useEffect(() => {
    setShowSpin(true);
    loadData();
    return () => { };
  }, []);

  useEffect(() => {
    checkEnable();
  }, [tTypeInsurancePaymentRefSelected]);

  useEffect(() => {
    if (submitTrigger) {
      form.submit();
    }
  }, [submitTrigger]);

  const loadData = async () => {
    let ryAccountSelected: RyAccount = {} as RyAccount;
    let tBankHeadSelected: TBankHead = {} as TBankHead;
    let tBankBranchHeadSelected: TBankBranchHead = {} as TBankBranchHead;

    const tTypeInsurancePaymentRefRes = await tTypeInsurancePaymentRefService.getTTypeInsurancePaymentRefList();
    const ryAccountsRes = await ryAccountService.getRyAccountList();
    const tBankHeadsRes = await tBankHeadService.getTBankHeadList();

    setTTypeInsurancePaymentRefs(tTypeInsurancePaymentRefRes.data);
    setRyAccounts(ryAccountsRes.data);
    setTBankHeads(tBankHeadsRes.data);
    if (tWorksheetInsurance?.itypePaymentInsurance) {
      const tTypeInsPayRefSelectedRes = await tTypeInsurancePaymentRefService.findById(tWorksheetInsurance.itypePaymentInsurance);
      setTTypeInsurancePaymentRefSelected(tTypeInsPayRefSelectedRes.data);
      if (tWorksheetInsurance.ipayinAccountnumber) {
        const ryAccountSelectedRes = await ryAccountService.getByAccountNumber(tWorksheetInsurance.ipayinAccountnumber)
        ryAccountSelected = ryAccountSelectedRes.data;
      }
      if (tWorksheetInsurance.ibankCodeCheque) {
        const bankBranchHeadRes = await tBankBranchHeadService.getBankBranchHeadByBankCode(tWorksheetInsurance.ibankCodeCheque);
        const tBankHeadSelectedRes = await tBankHeadService.getBankHeadByIBankCode(tWorksheetInsurance.ibankCodeCheque);
        const tBankBranchHeadSelectedRes = await tBankBranchHeadService.getByBankCodeAndBankBranchCode(tWorksheetInsurance.ibankCodeCheque, tWorksheetInsurance.ibankBranchCheque);

        tBankHeadSelected = tBankHeadSelectedRes.data;
        tBankBranchHeadSelected = tBankBranchHeadSelectedRes.data;
        setTBankBranchHeads(bankBranchHeadRes.data);
      }

      form.setFieldsValue({
        iinsuranceAmt: (tWorksheetInsurance?.iinsuranceAmt) ? formatNumber(tWorksheetInsurance?.iinsuranceAmt) : 0,
        itypePaymentInsurance: tTypeInsPayRefSelectedRes.data.iid,
        ipayDate: tWorksheetInsurance.ipayDate ? toMomentDate(tWorksheetInsurance.ipayDate) : moment(new Date(), Constants.DATE_FORMAT),
        ipayinAccountnumber: ryAccountSelected.accountnumber,
        ibankCodeCheque: tBankHeadSelected.ibankCode,
        ibankBranchCheque: tBankBranchHeadSelected.ibankBranchCode,
        iinsuranceNumber: tWorksheetInsurance?.iinsuranceNumber,
        iinsuranceExpire: (tWorksheetInsurance?.iinsuranceExpire) ? tWorksheetInsurance?.iinsuranceExpire : 0,
        iinsuranceExpireMonth: (tWorksheetInsurance?.iinsuranceExpireMonth) ? tWorksheetInsurance?.iinsuranceExpireMonth : 0,
        iremark: tWorksheetInsurance?.iremark
      });
    }

    setShowSpin(false);
  };

  const handleChangeBankhead = async (data: any) => {
    if (data.length < 2) return;
    const res = await tBankBranchHeadService.getBankBranchHeadByBankCode(data);
    setTBankBranchHeads(res.data);
  };

  const checkEnable = () => {
    if (tTypeInsurancePaymentRefSelected.iid) {
      switch (tTypeInsurancePaymentRefSelected.iid) {
        case "01"://เงินสด
          setDisableInsuranceAmt(false);
          setDisablePayDate(true);
          setDisablePayinAccountnumber(true);
          setDisableBankCodeCheque(true);
          setDisableBankBranchCheque(true);
          setDisableInsuranceNumber(true);
          setDisableInsuranceExpire(true);
          setDisableInsuranceExpireMonth(true);
          break;
        case "02"://เช็ค
          setDisableInsuranceAmt(false);
          setDisablePayDate(false);
          setDisablePayinAccountnumber(true);
          setDisableBankCodeCheque(false);
          setDisableBankBranchCheque(false);
          setDisableInsuranceNumber(false);
          setDisableInsuranceExpire(true);
          setDisableInsuranceExpireMonth(true);

          form.setFieldsValue({
            ipayinAccountnumber: ""
          });
          break;
        case "03"://เงินโอน
          setDisableInsuranceAmt(false);
          setDisablePayDate(false);
          setDisablePayinAccountnumber(false);
          setDisableBankCodeCheque(true);
          setDisableBankBranchCheque(true);
          setDisableInsuranceNumber(true);
          setDisableInsuranceExpire(true);
          setDisableInsuranceExpireMonth(true);
          break;
        case "04"://ตั๋วแลกเงิน
          setDisableInsuranceAmt(false);
          setDisablePayDate(false);
          setDisablePayinAccountnumber(true);
          setDisableBankCodeCheque(false);
          setDisableBankBranchCheque(false);
          setDisableInsuranceNumber(false);
          setDisableInsuranceExpire(false);
          setDisableInsuranceExpireMonth(false);
          break;
        case "05"://ดร๊าฟ
          setDisableInsuranceAmt(false);
          setDisablePayDate(false);
          setDisablePayinAccountnumber(true);
          setDisableBankCodeCheque(false);
          setDisableBankBranchCheque(false);
          setDisableInsuranceNumber(false);
          setDisableInsuranceExpire(false);
          setDisableInsuranceExpireMonth(false);
          break;
        case "06"://หนังสือค้ำประกัน
          setDisableInsuranceAmt(false);
          setDisablePayDate(false);
          setDisablePayinAccountnumber(true);
          setDisableBankCodeCheque(false);
          setDisableBankBranchCheque(false);
          setDisableInsuranceNumber(false);
          setDisableInsuranceExpire(false);
          setDisableInsuranceExpireMonth(false);
          break;
        case "07"://เงินสด/ATM
          setDisableInsuranceAmt(false);
          setDisablePayDate(true);
          setDisablePayinAccountnumber(true);
          setDisableBankCodeCheque(true);
          setDisableBankBranchCheque(true);
          setDisableInsuranceNumber(true);
          setDisableInsuranceExpire(true);
          setDisableInsuranceExpireMonth(true);
          break;
        case "99"://ไม่มีเงินประกัน
          setDisableInsuranceAmt(true);
          setDisablePayDate(true);
          setDisablePayinAccountnumber(true);
          setDisableBankCodeCheque(true);
          setDisableBankBranchCheque(true);
          setDisableInsuranceNumber(true);
          setDisableInsuranceExpire(true);
          setDisableInsuranceExpireMonth(true);

          form.setFieldsValue({
            iinsuranceAmt: 0
          })
          break;
      }
    }
  }

  const handleChangeTypePaymentInsuranceRef = (iid: any) => {
    const typePaymentInsuranceRefArr = tTypeInsurancePaymentRefs as Array<TTypeInsurancePaymentRef>;
    const foundItem = typePaymentInsuranceRefArr.find((obj) => obj.iid === iid);
    setTTypeInsurancePaymentRefSelected((foundItem) ? foundItem : {} as TTypeInsurancePaymentRef);
  };

  const checkSave = (values: any) => {
    let isValid = true;
    if (!values.itypePaymentInsurance) {
      showNotification(NotificationType.ERROR, "กรุณาระบุประเภทการค้ำประกัน");
      isValid = false;
      setSubmitTrigger(false);
    } else {
      switch (values.itypePaymentInsurance) {
        case "01"://เงินสด
          if (parserNumber(values.iinsuranceAmt) === 0) {
            showNotification(NotificationType.ERROR, "กรุณาระบุจำนวนเงินประกัน");
            isValid = false;
            setSubmitTrigger(false);
          }
          break;
        case "02"://เช็ค
          if (parserNumber(values.iinsuranceAmt) === 0) {
            showNotification(NotificationType.ERROR, "กรุณาระบุจำนวนเงินประกัน");
            isValid = false;
            setSubmitTrigger(false);
          } else if (!values.ibankCodeCheque) {
            showNotification(NotificationType.ERROR, "กรุณาระบุธนาคาร");
            isValid = false;
            setSubmitTrigger(false);
          } else if (!values.ibankBranchCheque) {
            showNotification(NotificationType.ERROR, "กรุณาระบุสาขาธนาคาร");
            isValid = false;
            setSubmitTrigger(false);
          } else if (!values.iinsuranceNumber) {
            showNotification(NotificationType.ERROR, "กรุณาระบุเลขที่เช็ค");
            isValid = false;
            setSubmitTrigger(false);
          }
          break;
        case "03"://เงินโอน
          if (parserNumber(values.iinsuranceAmt) === 0) {
            showNotification(NotificationType.ERROR, "กรุณาระบุจำนวนเงินประกัน");
            isValid = false;
            setSubmitTrigger(false);
          } else if (!values.ipayinAccountnumber) {
            showNotification(NotificationType.ERROR, "กรุณาระบุบัญชีเงินโอนเข้า");
            isValid = false;
            setSubmitTrigger(false);
          }
          break;
        case "04"://ตั๋วแลกเงิน
          if (parserNumber(values.iinsuranceAmt) === 0) {
            showNotification(NotificationType.ERROR, "กรุณาระบุจำนวนเงินประกัน");
            isValid = false;
            setSubmitTrigger(false);
          } else if (!values.ibankCodeCheque) {
            showNotification(NotificationType.ERROR, "กรุณาระบุธนาคาร");
            isValid = false;
            setSubmitTrigger(false);
          } else if (!values.ibankBranchCheque) {
            showNotification(NotificationType.ERROR, "กรุณาระบุสาขาธนาคาร");
            isValid = false;
            setSubmitTrigger(false);
          } else if (!values.iinsuranceNumber) {
            showNotification(NotificationType.ERROR, "กรุณาระบุเลขที่ตั๋วแลกเงิน");
            isValid = false;
            setSubmitTrigger(false);
          } else if (parserNumber(values.iinsuranceExpireMonth) <= 0) {
            showNotification(NotificationType.ERROR, "กรุณาระบุจำนวนเดือนอายุตั๋วแลกเงิน");
            isValid = false;
            setSubmitTrigger(false);
          } else if (parserNumber(values.iinsuranceExpire) <= 0) {
            showNotification(NotificationType.ERROR, "กรุณาระบุจำนวนวันอายุตั๋วแลกเงิน");
            isValid = false;
            setSubmitTrigger(false);
          }
          break;
        case "05"://ดร๊าฟ
          if (parserNumber(values.iinsuranceAmt) === 0) {
            showNotification(NotificationType.ERROR, "กรุณาระบุจำนวนเงินประกัน");
            isValid = false;
            setSubmitTrigger(false);
          } else if (!values.ibankCodeCheque) {
            showNotification(NotificationType.ERROR, "กรุณาระบุธนาคาร");
            isValid = false;
            setSubmitTrigger(false);
          } else if (!values.ibankBranchCheque) {
            showNotification(NotificationType.ERROR, "กรุณาระบุสาขาธนาคาร");
            isValid = false;
            setSubmitTrigger(false);
          } else if (!values.iinsuranceNumber) {
            showNotification(NotificationType.ERROR, "กรุณาระบุเลขที่ดร๊าฟ");
            isValid = false;
            setSubmitTrigger(false);
          } else if (parserNumber(values.iinsuranceExpireMonth) <= 0) {
            showNotification(NotificationType.ERROR, "กรุณาระบุจำนวนเดือนอายุดร๊าฟ");
            isValid = false;
            setSubmitTrigger(false);
          } else if (parserNumber(values.iinsuranceExpire) <= 0) {
            showNotification(NotificationType.ERROR, "กรุณาระบุจำนวนวันอายุดร๊าฟ");
            isValid = false;
            setSubmitTrigger(false);
          }
          break;
        case "06"://หนังสือค้ำประกัน
          if (parserNumber(values.iinsuranceAmt) === 0) {
            showNotification(NotificationType.ERROR, "กรุณาระบุจำนวนเงินประกัน");
            isValid = false;
            setSubmitTrigger(false);
          } else if (!values.ibankCodeCheque) {
            showNotification(NotificationType.ERROR, "กรุณาระบุธนาคาร");
            isValid = false;
            setSubmitTrigger(false);
          } else if (!values.ibankBranchCheque) {
            showNotification(NotificationType.ERROR, "กรุณาระบุสาขาธนาคาร");
            isValid = false;
            setSubmitTrigger(false);
          } else if (!values.iinsuranceNumber) {
            showNotification(NotificationType.ERROR, "กรุณาระบุเลขที่หนังสือค้ำประกัน");
            isValid = false;
            setSubmitTrigger(false);
          } else if (parserNumber(values.iinsuranceExpireMonth) <= 0) {
            showNotification(NotificationType.ERROR, "กรุณาระบุจำนวนเดือนอายุหนังสือค้ำประกัน");
            isValid = false;
            setSubmitTrigger(false);
          } else if (parserNumber(values.iinsuranceExpire) <= 0) {
            showNotification(NotificationType.ERROR, "กรุณาระบุจำนวนวันอายุหนังสือค้ำประกัน");
            isValid = false;
            setSubmitTrigger(false);
          }
          break;
        case "07"://เงินสด/ATM
          if (parserNumber(values.iinsuranceAmt) === 0) {
            showNotification(NotificationType.ERROR, "กรุณาระบุจำนวนเงินประกัน");
            isValid = false;
            setSubmitTrigger(false);
          }
          break;
        case "99":
          break;
      }
    }
    return isValid;
  }

  const onFinish = (values: any) => {
    if (!checkSave(values)) return;
    let tworksheetInsurance: TWorksheetInsurance = {
      // id: 0,
      itypePaymentInsurance: values.itypePaymentInsurance,
      iinsuranceAmt: parserNumber(values.iinsuranceAmt),
      iremark: values.iremark,
      istatusInsurance: "00",
      icustName: custName,
      ipayDate: (values.ipayDate) ? values.ipayDate.format(Constants.DB_FORMAT) : "",
      ipayinAccountnumber: values.ipayinAccountnumber,
      ibankCodeCheque: values.ibankCodeCheque,
      ibankBranchCheque: values.ibankBranchCheque,
      iinsuranceNumber: values.iinsuranceNumber,
      iinsuranceExpire: values.iinsuranceExpire,
      iinsuranceExpireMonth: values.iinsuranceExpireMonth,

      icreateUser: userInfo.userId,
      ilastUser: userInfo.userId,
      itermNo: userInfo.termNo,
      ibranch: userInfo.branchId,
      id: 0,
      iworksheetCode: '',
      ireservecode: ''
    };

    setTWorksheetInsurance(tworksheetInsurance);
    form.resetFields();
    setSubmitTrigger(false);
    setVisibleInsurance(false);
  };

  const onFinishCancelled = () => {
    // set back to default
    setTWorksheetInsurance(tWorksheetInsuranceTemp);
    form.resetFields();
    setVisibleInsurance(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน");
    setSubmitTrigger(false);
  };

  return (
    <>
      <Modal
        title="กำหนดข้อมูลการคำ้ประกัน"
        visible={visibleInsurance}
        width={"50%"}
        bodyStyle={{ minHeight: "450px" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setSubmitTrigger(true)}
        onCancel={() => onFinishCancelled()}
      >
        <Spin spinning={showSpin}>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>ประเภทการค้ำประกัน: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={17}>
                <Form.Item
                  name="itypePaymentInsurance"
                  style={{ marginBottom: "3px" }}
                  rules={[
                    { required: true, message: "กรุณาระบุ กรุณาระบุประเภทการค้ำประกัน" },
                  ]}
                >
                  <Select
                    showSearch
                    size="middle"
                    placeholder="เลือกประเภทการค้ำประกัน"
                    optionFilterProp="children"
                    filterOption={true}
                    onSelect={handleChangeTypePaymentInsuranceRef}
                  >
                    {tTypeInsurancePaymentRefs.map((obj) => {
                      return <Option key={obj.iid} value={obj.iid}>{obj.iname}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>จำนวนเงินประกัน: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={17}>
                <Form.Item
                  name="iinsuranceAmt"
                  style={{ marginBottom: "2px" }}
                  rules={[
                    { required: true, message: "กรุณาระบุจำนวนเงินประกัน" }
                  ]}
                >
                  <Input
                    type="text"
                    disabled={disableInsuranceAmt}
					onKeyDown={(event: any) => textNumber(event)}
                    onPressEnter={(e: any) => form.setFieldsValue({ iinsuranceAmt: formatNumber(parserNumber(e.target.value)) })}
                    onBlur={(e: any) => form.setFieldsValue({ iinsuranceAmt: formatNumber(parserNumber(e.target.value)) })}
                    onFocus={(e) => e.target.select()}
                    style={{ textAlign: "end", width: "60%" }}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ลงวันที่: </Typography.Text>
              </Col>
              <Col span={17}>
                <Form.Item
                  name="ipayDate"
                  style={{ marginBottom: "2px" }}
                >
                  <DatePicker
                    disabled={disablePayDate}
                    format={Constants.DATE_FORMAT}
                    style={{ width: "60%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>โอนเข้าบัญชี: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={17}>
                <Form.Item
                  style={{ marginBottom: "3px" }}
                  name="ipayinAccountnumber"
                >
                  <Select
                    showSearch
                    size="middle"
                    placeholder="เลือกโอนเข้าบัญชี"
                    optionFilterProp="children"
                    filterOption={true}
                    disabled={disablePayinAccountnumber}
                  >
                    {ryAccounts.map((obj) => {
                      return <Option key={obj.accountnumber} value={obj.accountnumber}>{obj.accountnumber} : {obj.accountname}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>ธนาคาร: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={17}>
                <Form.Item
                  style={{ marginBottom: "3px" }}
                  name="ibankCodeCheque"
                >
                  <Select
                    showSearch
                    size="middle"
                    placeholder="เลือกธนาคาร"
                    optionFilterProp="children"
                    filterOption={true}
                    disabled={disableBankCodeCheque}
                    onSelect={handleChangeBankhead}
                  >
                    {tBankHeads.map((obj) => {
                      return <Option key={obj.ibankCode} value={obj.ibankCode}>{obj.ibankCode} : {obj.ibankName}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>สาขา: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={17}>
                <Form.Item
                  style={{ marginBottom: "3px" }}
                  name="ibankBranchCheque"
                >
                  <Select
                    showSearch
                    size="middle"
                    placeholder="เลือกสาขา"
                    optionFilterProp="children"
                    filterOption={true}
                    disabled={disableBankBranchCheque}
                  >
                    {tBankBranchHeads.map((obj) => {
                      return <Option key={obj.ibankBranchCode} value={obj.ibankBranchCode}>{obj.ibankBranchCode} : {obj.ibankBranchName}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>เลขที่เช็ค/สัญญา: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={17}>
                <Form.Item
                  name="iinsuranceNumber"
                  style={{ marginBottom: "3px" }}
                >
                  <Input
                    disabled={disableInsuranceNumber}
                    style={{ width: "60%" }}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>อายุสัญญา: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={17}>
                <Space>
                  <Form.Item
                    style={{ marginBottom: "3px" }}
                    name="iinsuranceExpire"
                  >
                    <InputNumber
                      controls={false}
                      style={{ textAlign: "end" }}
                      disabled={disableInsuranceExpire}
                    // onChange={(e) => {
                    //   setInsuranceFormData({ ...insuranceFormData, insuranceExpire: Number(e.target.value), })
                    // }
                    // }
                    ></InputNumber>
                  </Form.Item>
                  วัน
                  <Form.Item
                    style={{ marginBottom: "3px" }}
                    name="iinsuranceExpireMonth"
                  >
                    <InputNumber
                      controls={false}
                      style={{ textAlign: "end" }}
                      disabled={disableInsuranceExpireMonth}
                    ></InputNumber>
                  </Form.Item>
                  เดือน
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <span>
                  <Typography.Text>หมายเหตุการค้ำประกัน: </Typography.Text>
                  <label className="text-red">*</label>
                </span>
              </Col>
              <Col span={17}>
                <Form.Item
                  style={{ marginBottom: "3px" }}
                  name="iremark"
                  rules={[
                    { required: true, message: "กรุณาระบุ หมายเหตุเงินประกัน" },
                  ]}
                >
                  <TextArea
                    rows={3}
                  ></TextArea>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
}

export default AddInsuranceModal