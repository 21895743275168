import {
  MenuFoldOutlined
} from "@ant-design/icons";
import { Button, Col, Input, Row, Space, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import PageHeader from "src/layouts/PageHeader";


const Regex = () => {
  const [address, setAddress] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const [id, setId] = React.useState<string>("");
  const [subDistrict, setSubDistrict] = React.useState<string>("");
  const [district, setDistrict] = React.useState<string>("");
  const [province, setProvince] = React.useState<string>("");
  const [zipcode, setZipcode] = React.useState<string>("");

  const handleProcess = () => {
    if (address === "") return;
    // console.log("Process");
    let namepattern = /(\W+[(\d)||\W]+ [จำกัด||ห้างหุ้นส่วน]+ (\W)+)/gm;
    let zipcodePattern = /\d{5}/gm;

    let name: any = address.match(namepattern);
    setName(name[0]);
    // console.log(name);
    let zipcode: any = address.match(zipcodePattern);
    setZipcode(zipcode[0]);
    // console.log(zipcode);
  };

  return (
    <>
      <PageHeader title="Regex" />
      <Content>
        <Row justify="center">
          <Col span={7}></Col>
          <Col span={10}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Typography.Title
                level={4}
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                Address information
              </Typography.Title>
              <Input.TextArea
                rows={3}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <Input.Group compact>
                <Input
                  value={name}
                  placeholder="Name"
                  style={{ width: "50%" }}
                  prefix={<MenuFoldOutlined />}
                />
                <Input
                  value={id}
                  placeholder="Id"
                  style={{ width: "20%" }}
                  prefix={<MenuFoldOutlined />}
                />
                <Input
                  value={subDistrict}
                  placeholder="Sub-district"
                  style={{ width: "30%" }}
                  prefix={<MenuFoldOutlined />}
                />
              </Input.Group>
              <Input.Group>
                <Input
                  value={district}
                  placeholder="District"
                  style={{ width: "30%" }}
                  prefix={<MenuFoldOutlined />}
                />
                <Input
                  value={province}
                  placeholder="Province"
                  style={{ width: "40%" }}
                  prefix={<MenuFoldOutlined />}
                />
                <Input
                  value={zipcode}
                  placeholder="Zipcode"
                  style={{ width: "30%" }}
                  prefix={<MenuFoldOutlined />}
                />
              </Input.Group>
              <div style={{ textAlign: "center" }}>
                <Button type="primary" onClick={() => handleProcess()}>
                  PROCESS
                </Button>
              </div>
            </Space>
          </Col>
          <Col span={7}></Col>
        </Row>
      </Content>
    </>
  );
};

export default Regex;
