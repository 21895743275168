import { PlusSquareOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Select, Space, Table, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { TOtherHead } from 'src/domain/tOtherHead';
import { TWorksheetExtrapriceView } from 'src/domain/worksheet/view/tWorksheetExtrapriceView';
import { WorksheetHeadData } from 'src/domain/worksheet/view/worksheetHeadData';
import tOtherHeadService from 'src/services/reference/tOtherHeadService';
import { useAppSelector } from 'src/stores/store';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber, textNumber } from 'src/utils/numberUtils';

const { Option } = Select;
interface IProps {
  addEditWshExtraPriceModalVisible: boolean;
  setAddEditWshExtraPriceModalVisible: any;
  worksheetHeadData: WorksheetHeadData;
  extraPriceDataTempList: any;
  setExtraPriceDataTempList: any;
}

const AddEditWorksheetExtraPriceModal: React.FC<IProps> = (props) => {
  const { addEditWshExtraPriceModalVisible,
    setAddEditWshExtraPriceModalVisible,
    worksheetHeadData,
    extraPriceDataTempList,
    setExtraPriceDataTempList } = props;
  const [form] = Form.useForm();
  const [submitTrigger, setSubmitTrigger] = useState<boolean>(false);
  const [worksheetHeadDataTemp, setWorksheetHeadDataTemp] = useState<WorksheetHeadData>({} as WorksheetHeadData);
  const [formExtraPriceDataTempList, setFormExtraPriceDataTempList] = useState<TWorksheetExtrapriceView[]>([]);
  const [showSpin, setShowSpin] = useState<boolean>(false);
  const [sumExtraPrice, setSumExtraPrice] = useState<number>(0);
  const [otherHeadRefs, setOtherHeadRefs] = useState<TOtherHead[]>([]); //ค่าบริการพิเศษ
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [disableAddButton, setDisableAddButton] = useState<boolean>(true);

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      key: "index",
      align: "center" as "center",
      render: (value: any, item: TWorksheetExtrapriceView, index: number) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: "รายการ",
      dataIndex: "idescName",
      key: "idescName",
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "iamount",
      key: "iamount",
      align: "right" as "right",
      render: (value: any, item: TWorksheetExtrapriceView, index: number) => {
        return <span>{formatNumber(item.iamount)}</span>;
      },
    },
    {
      title: "หมายเหตุ",
      dataIndex: "iremark",
      key: "iremark",
    },
    {
      title: "",
      key: "action",
      align: "center" as "center",
      render: (value: any, item: TWorksheetExtrapriceView, index: number) => {
        return (
          <span>
            <a onClick={(e) => { onDelete(item, index); }} style={{ color: "red" }}
            >
              X
            </a>
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    setShowSpin(true);
    loadData();
    return () => { };
  }, []);

  useEffect(() => {
    if (submitTrigger) {
      form.submit();
    }
  }, [submitTrigger]);

  useEffect(() => {
    //sum extraprice list
    let sumExtraPrice = 0;
    if ((formExtraPriceDataTempList) && formExtraPriceDataTempList.length > 0) {
      formExtraPriceDataTempList.forEach((obj: TWorksheetExtrapriceView) => {
        sumExtraPrice += (obj.iamount) ? obj.iamount : 0;
      });
    }
    setSumExtraPrice(sumExtraPrice);
  }, [formExtraPriceDataTempList]);

  const loadData = async () => {
    const otherHeadResRefs = await tOtherHeadService.getTOtherHeadList();

    setWorksheetHeadDataTemp(worksheetHeadData);
    setOtherHeadRefs(otherHeadResRefs.data);
    setFormExtraPriceDataTempList(extraPriceDataTempList);
    setShowSpin(false);

  };

  const reindex = (tWorksheetExtraprice: TWorksheetExtrapriceView[]) => {
    return tWorksheetExtraprice.map((item, index) => {
      item.iid = index;
      return item;
    });
  }

  const handleAdd = () => {
    if (!form.getFieldValue("description")) {
      showNotification(NotificationType.ERROR, "กรุณาเลือกค่าบริการพิเศษอื่นๆ");
      return;
    } else if (!form.getFieldValue("amount")) {
      showNotification(NotificationType.ERROR, "กรุณาระบุราคา");
      return;
    } else if (parserNumber(form.getFieldValue("amount")) <= 0) {
      showNotification(NotificationType.ERROR, "กรุณาระบุราคาที่เป็นจำนวนที่ มากกว่า 0");
      return;
    }
    if (formExtraPriceDataTempList) {
      const foudExtraPrice = formExtraPriceDataTempList.find((obj) => obj.idesc === form.getFieldValue("description"));
      if (foudExtraPrice) {
        showNotification(NotificationType.ERROR, "มีรายการนี้อยู่แล้ว!");
        form.resetFields();
        return;
      }
    }

    const extraPriceSelected = otherHeadRefs.find((obj) => obj.iid === form.getFieldValue("description"));
    const temp: TWorksheetExtrapriceView = {
      iid: 0,
      ireserveCode: worksheetHeadDataTemp.tworksheetHead.ireserveCode,
      idesc: form.getFieldValue("description"),
      idescName: extraPriceSelected?.iname,
      iremark: form.getFieldValue("remark"),
      iamount: parserNumber(form.getFieldValue("amount")),
      icreateUser: userInfo.userId,
      isavStation: userInfo.saveStation,
      ilastUser: userInfo.userId,
    };
    const array = formExtraPriceDataTempList ? [...formExtraPriceDataTempList] : [];
    array?.push(temp);
    setFormExtraPriceDataTempList([...reindex(array)]);
    form.resetFields();
    setDisableAddButton(true);
  };

  const onDelete = (item: TWorksheetExtrapriceView, index: number) => {
    const array = formExtraPriceDataTempList ? [...formExtraPriceDataTempList] : [];
    array?.splice(index, 1);
    setFormExtraPriceDataTempList([...reindex(array)]);
  }

  const onFinish = (values: any) => {
    setExtraPriceDataTempList(formExtraPriceDataTempList);
    setAddEditWshExtraPriceModalVisible(false);
    form.resetFields();
    setSubmitTrigger(false);
    setDisableAddButton(true);
  };

  const onCancel = () => {
    setDisableAddButton(true);
    setAddEditWshExtraPriceModalVisible(false);
    setExtraPriceDataTempList(extraPriceDataTempList);
  }
  const onFinishFailed = (errorInfo: any) => {
    setSubmitTrigger(false);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>ค่าบริการพิเศษอื่น ๆ</Typography.Title>
          </div>
        }
        visible={addEditWshExtraPriceModalVisible}
        width={"50%"}
        style={{ minHeight: "70%" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setSubmitTrigger(true)}
        onCancel={() => onCancel()}
      >
        <Form
          form={form}
          name="worksheetExtraPrice"
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row>
            <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
              <span>
                <Typography.Text>รหัสค่าบริการพิเศษอื่นๆ: </Typography.Text>
                <label className="text-red">*</label>
              </span>
            </Col>
            <Col span={18}>
              <Form.Item
                name="description"
                style={{ marginBottom: "3px" }}
              >
                <Select
                  showSearch
                  size="middle"
                  placeholder="เลือกค่าบริการพิเศษอื่นๆ"
                  optionFilterProp="children"
                  filterOption={true}
                  onSelect={() => setDisableAddButton(false)}
                >
                  {otherHeadRefs.map((obj) => {
                    return (
                      <Option key={obj.iid} value={obj.iid}>{obj.iname}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
              <span>
                <Typography.Text>ราคา: </Typography.Text>
                <label className="text-red">*</label>
              </span>
            </Col>
            <Col span={18}>
              <Space>
                <Form.Item
                  name="amount"
                  style={{ marginBottom: "2px" }}
                >
                  <Input
                    type="text"
                    onKeyDown={(event: any) => textNumber(event)}
                    onPressEnter={(e: any) => form.setFieldsValue({ amount: formatNumber(parserNumber(e.target.value)) })}
                    onBlur={(e: any) => form.setFieldsValue({ amount: formatNumber(parserNumber(e.target.value)) })}
                    onFocus={(e) => e.target.select()}
                    style={{ textAlign: "end", width: "auto", marginRight: 5 }}
                  ></Input>
                </Form.Item>
                บาท
              </Space>
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>หมายเหตุ: </Typography.Text>
            </Col>
            <Col span={18}>
              <Form.Item
                name="remark"
              >
                <TextArea
                  rows={4}
                ></TextArea>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
            </Col>
            <Col span={18}>
              <Button
                size="small"
                type="primary"
                disabled={disableAddButton}
                style={{ marginBottom: "15px" }}
                icon={<PlusSquareOutlined />}
                onClick={handleAdd}
              >
                เพิ่ม
              </Button>
            </Col>
          </Row>
        </Form>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
        >
          <Space align="baseline" direction="horizontal">
            <label></label>
          </Space>
          <Typography.Title
            type="danger"
            level={4}
            style={{ height: "22px", textAlign: "right" }}
          >
            จำนวนเงิน :{" "}
            {formatNumber(
              Number(sumExtraPrice).toFixed(2)
            )}
          </Typography.Title>
        </div>
        <div style={{ marginBottom: 20, minHeight: 80 }}>
          <Table
            columns={columns}
            dataSource={formExtraPriceDataTempList}
            size="small"
            pagination={false}
            rowKey={(item: TWorksheetExtrapriceView) => item.iid}
          ></Table>
        </div>
      </Modal>
    </>
  )
}

export default AddEditWorksheetExtraPriceModal