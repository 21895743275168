import { TCreditnoteHeadView } from "src/domain/view/tCreditnoteHeadView";

import Constants from "src/utils/constants";
import http from "../../lib/http";

const getByWorksheetCode = (worksheetCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tCreditnoteHead/getByWorksheetCode", {
    params: {
      worksheetCode,
    }
  });
};

const isExistByWorksheetCode = (worksheetCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tCreditnoteHead/isExistByWorksheetCode", {
    params: {
      worksheetCode,
    }
  });
};

const findTCreditnoteHeadViews = (wshCode: string, creditnoteCode: string, fromDate: string, toDate: string) => {
  return http.get(Constants.DEFAULT_URL + "/tCreditnoteHead/findTCreditnoteHeadViews", {
    params: {
      wshCode,
      creditnoteCode,
      fromDate,
      toDate,
    }
  });
};

const getById = (creditnoteId: string) => {
  return http.get(Constants.DEFAULT_URL + "/tCreditnoteHead/" + creditnoteId);
};

const getTCreditnoteHeadViewData = (creditnoteId: string) => {
  return http.get(Constants.DEFAULT_URL + "/tCreditnoteHead/getTCreditnoteHeadViewData/" + creditnoteId);
};

const createTcreditnote = (tcreditHeadView: TCreditnoteHeadView) => {
  return http.post(Constants.DEFAULT_URL + "/tCreditnoteHead/createTcreditnote" , tcreditHeadView);
};

const printPreviewAndSaveCreditnote = (creditNoteHeadView: TCreditnoteHeadView, showAllPage: boolean) => {
  return http.post(
    Constants.DEFAULT_URL + "/worksheetReport/printPreviewAndSaveCreditnote/"+showAllPage,
    creditNoteHeadView,
    {
      responseType: "blob",
    },
  );
};

const defaultApi = {
  getByWorksheetCode,
  isExistByWorksheetCode,
  findTCreditnoteHeadViews,
  getById,
  getTCreditnoteHeadViewData,
  createTcreditnote,
  printPreviewAndSaveCreditnote
}
export default defaultApi;