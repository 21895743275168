import http from "../lib/http";
import Constants from "../utils/constants";

const getByWorksheetCode = (worksheetCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tAssetmovementDetail/getByWorksheetCode/" + worksheetCode);
}

const defaultApi = {
  getByWorksheetCode,
};
export default defaultApi;
