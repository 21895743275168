import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Descriptions, Form, Input, Modal, Select, Switch } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TReference } from "src/domain/master/tReference";
import { TPrenameRef } from "src/domain/tPrenameRef";
import tPrenameRefService from "src/services/reference/tPrenameRefService";
import { useAppSelector } from "src/stores/store";
import { getFlg } from "src/utils/rodyokUtils";
import { TReferenceDetail } from "../../domain/master/tReferenceDetail";
import tReferenceDetailService from "../../services/reference/tReferenceDetailService";
import { NotificationType, showNotification } from "../../utils/notificationUtils";
import { getReferenceCodeTitile, isReference, ReferenceType, showReferenceCode, showReferenceFlg, showReferenceRemark } from "../../utils/referenceUtils";

const { Option } = Select;
const { confirm } = Modal;

interface IParams {
    id: string;
    reference: TReference;
    visibleReferenceDetailModal: boolean;
    setVisibleReferenceDetailModal: any;
}

const AddEditReferenceDetailModal: React.FC<IParams> = (props) => {
    const { id, reference, visibleReferenceDetailModal, setVisibleReferenceDetailModal } = props;
    const [trigger, setTrigger] = useState<boolean>(false);
    const [inUseFlg, setInUseFlg] = useState<boolean>(true);
    const [prenames, setPrenames] = useState<TPrenameRef[]>([]);
    const [tReferenceDetail, setReferenceDetail] = useState<TReferenceDetail>({} as TReferenceDetail);
    const [form] = Form.useForm();
    const history = useHistory();
    const userInfo = useAppSelector((state) => state.authen.authenUser.user);

    let title: string = "เพิ่มข้อมูลอ้างอิงย่อย";
    if (id) {
        title = "แก้ไขข้อมูลอ้างอิงย่อย";
    }

    useEffect(() => {
        if (trigger) {
            form.validateFields()
                .then(() => {
                    confirm({
                        title: "ยืนยัน!",
                        icon: <ExclamationCircleOutlined />,
                        content: "คุณต้องการที่จะบันทึกข้อมูลหรือไม่?",
                        cancelText: "ยกเลิก",
                        okText: "ยืนยัน",
                        onOk() {
                            form.submit();
                        },
                        onCancel() {
                            setTrigger(false);
                        }
                    });
                }).catch(() => {
                    setTrigger(false);
                });
        }
    }, [trigger]);

    useEffect(() => {
        loadReferenceDetail();
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            id: tReferenceDetail.id,
            referenceId: reference.id,
            code: tReferenceDetail.code,
            name: tReferenceDetail.name,
            value1: tReferenceDetail.value1,
            value2: tReferenceDetail.value2,
            value3: tReferenceDetail.value3,
            value4: tReferenceDetail.value4,
            value5: tReferenceDetail.value5
        });
        setInUseFlg(getFlg(tReferenceDetail.inUseFlg));
    }, [tReferenceDetail]);

    const loadReferenceDetail = async () => {
        const prenamesRes = await tPrenameRefService.getTPrenameRefList();
        if (id) {
            const res = await tReferenceDetailService.getTReferenceDetailByKeyAndId(reference.code, id);
            setReferenceDetail(res.data);
        } else {
            const nextCodeRes = await tReferenceDetailService.getNextTReferenceDetailCode(reference.id);
            if (showReferenceCode(reference) && reference.code !== ReferenceType.ASSET_TYPE
                && reference.code !== ReferenceType.ASSIGN_VALUE_TAX_HEAD) {
                setReferenceDetail(
                    { ...tReferenceDetail, referenceId: reference.id, code: nextCodeRes.data, inUseFlg: 0 }
                );
            } else {
                setReferenceDetail(
                    { ...tReferenceDetail, referenceId: reference.id, id: nextCodeRes.data, inUseFlg: 0 }
                );
            }
        }
        setPrenames(prenamesRes.data)
    };

    const onFinish = async (values: any) => {
        if (!id && values.name) {
            const res = await tReferenceDetailService.isExistTReferenceDetailByName(reference.code, values.name);
            if (Boolean(res.data)) {
                showNotification(NotificationType.ERROR, "ชื่ออ้างอิงย่อย '" + values.name + "' นี้มีอยู่แล้ว!");
                setTrigger(false);
                return;
            }
        }
        let idTemp = id;
        if (!showReferenceCode(reference) || reference.code === ReferenceType.BRANCH
            || reference.code === ReferenceType.ASSET_TYPE || reference.code === ReferenceType.ASSIGN_VALUE_TAX_HEAD) {
            idTemp = values.id
        }
        const _tReferenceDetail: TReferenceDetail = {
            id: idTemp,
            referenceId: reference.id,
            code: values.code,
            name: values.name,
            value1: values.value1,
            value2: values.value2,
            value3: values.value3,
            value4: values.value4,
            value5: values.value5,
            inUseFlg: inUseFlg ? 0 : 1,
            savStation: userInfo.saveStation,
            createUser: userInfo.userId,
            lastUser: userInfo.userId,
            termNo: userInfo.termNo
        };

        if (!id) {
            try {
                await tReferenceDetailService.postTReferenceDetail(_tReferenceDetail);
                showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลอ้างอิงย่อยเรียบร้อยแล้ว!");
                form.resetFields();
                setTrigger(false);
                setVisibleReferenceDetailModal(false);
                history.replace("/app/reference/view/" + reference.id);
            } catch (err) {
                showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
                setTrigger(false);
            }
        } else {
            try {
                await tReferenceDetailService.updateTReferenceDetail(_tReferenceDetail);
                showNotification(NotificationType.SUCCESS, "แก้ไขข้อมูลอ้างอิงย่อยรียบร้อยแล้ว!");
                form.resetFields();
                setTrigger(false);
                setVisibleReferenceDetailModal(false);
                history.replace("/app/reference/view/" + reference.id);
            } catch (err) {
                showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
                setTrigger(false);
            }
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        setTrigger(false);
    };

    const onSwitchClick = (inUseFlg: boolean) => {
        setInUseFlg(inUseFlg);
    }

    const isIdEnable = (): boolean => {
        return reference.code === ReferenceType.BRANCH ? false : true
    }

    const isCodeEnable = (): boolean => {
        if (reference.code === ReferenceType.BRANCH || reference.code === ReferenceType.ASSET_TYPE
            || reference.code === ReferenceType.ASSIGN_VALUE_TAX_HEAD || !tReferenceDetail.code) {
            return false
        }
        return true
    }

    return (
        <>
            <Modal
                title={title}
                cancelText={"ยกเลิก"}
                okText={"บันทึก"}
                visible={visibleReferenceDetailModal}
                width={700}
                closable={false}
                destroyOnClose
                maskClosable={false}
                onOk={() => setTrigger(true)}
                onCancel={() => setVisibleReferenceDetailModal(false)}
            >
                <Content className="app-page-content">
                    {showReferenceFlg(reference) && <Descriptions
                        size="middle"
                        extra={
                            <Switch checkedChildren="ใช้งาน" unCheckedChildren="ไม่ใช้งาน" checked={inUseFlg}
                                onClick={onSwitchClick} />
                        }
                    />}
                    <Form
                        form={form}
                        name="tReferenceDetail"
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            label="เลขที่อ้างอิง"
                            name="referenceId"
                            rules={[
                                { required: true }
                            ]}
                        >
                            <Input disabled={true} style={{ width: 100 }} />
                        </Form.Item>
                        {
                            (!showReferenceCode(reference) || reference.code === ReferenceType.BRANCH
                                || reference.code === ReferenceType.ASSET_TYPE || reference.code === ReferenceType.ASSIGN_VALUE_TAX_HEAD) && <Form.Item
                                    label="รหัส"
                                    name="id"
                                    rules={[
                                        { required: true }
                                    ]}
                                >
                                <Input disabled={isIdEnable()} style={{ width: 100 }} />
                            </Form.Item>
                        }
                        {
                            showReferenceCode(reference) && <Form.Item
                                label={getReferenceCodeTitile(reference)}
                                name="code"
                                rules={[
                                    { required: true }
                                ]}
                            >
                                <Input disabled={isCodeEnable()} style={{ width: 100 }} />
                            </Form.Item>
                        }
                        {reference.code === ReferenceType.OWNER_ASSET &&
                            <Form.Item
                                label="คำนำหน้า"
                                name="code"
                                rules={[
                                    { required: true, message: "กรุณาเลือกคำนำหน้า" }
                                ]}
                            >
                                <Select
                                    showSearch
                                    size="middle"
                                    placeholder="เลือกคำนำหน้าชื่อ"
                                    optionFilterProp="children"
                                    filterOption={true}
                                    style={{ width: "137px" }}
                                >
                                    {prenames.map((obj) => {
                                        return (
                                            <Option key={obj.iprenameCode} value={obj.iprenameCode}>
                                                {obj.iprenameName}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        }
                        <Form.Item
                            label="ชื่อ"
                            name="name"
                            rules={[
                                {
                                    required: true, message: "กรุณาใส่ชื่อ!",
                                },
                                {
                                    validator: (_, value) => {
                                        if (value && (value === " " || value === "-")) {
                                            return Promise.reject(new Error('กรุณาป้อนข้อมูลชื่อให้ถูกต้อง!'))
                                        } else {
                                            return Promise.resolve()
                                        }
                                    },
                                },
                            ]}
                        >
                            <Input placeholder={"ป้อนชื่อ"} />
                        </Form.Item>
                        {
                            showReferenceRemark(reference) && <Form.Item
                                label="หมายเหตุ"
                                name="value1"
                                rules={[{ required: false, },]}
                            >
                                <Input.TextArea rows={3} placeholder={"ป้อนหมายเหตุ"} />
                            </Form.Item>
                        }
                        {isReference(reference) && <div>
                            <Form.Item
                                label="Value1"
                                rules={[{ required: false }]}
                                name="value1"
                            >
                                <Input placeholder={"ป้อน Value1"} />
                            </Form.Item>
                            <Form.Item
                                label="Value2"
                                rules={[{ required: false }]}
                                name="value2"
                            >
                                <Input placeholder={"ป้อน Value2"} />
                            </Form.Item>
                            <Form.Item
                                label="Value3"
                                rules={[{ required: false }]}
                                name="value3"
                            >
                                <Input placeholder={"ป้อน Value3"} />
                            </Form.Item>
                            <Form.Item
                                label="Value4"
                                rules={[{ required: false }]}
                                name="value4"
                            >
                                <Input placeholder={"ป้อน Value4"} />
                            </Form.Item>
                            <Form.Item
                                label="Value5"
                                rules={[{ required: false }]}
                                name="value5"
                            >
                                <Input placeholder={"ป้อน Value5"} />
                            </Form.Item>
                        </div>
                        }
                    </Form>
                </Content>
            </Modal>
        </>
    );
};

export default AddEditReferenceDetailModal;
