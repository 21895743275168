import { CloseCircleOutlined, CopyOutlined, ExclamationCircleOutlined, PlusSquareFilled, PlusSquareOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button, Checkbox, Col, Collapse, DatePicker, Divider, Drawer, Form, Input,
  InputNumber, Modal, Row, Select, Space, Spin, Tabs, Typography
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AssetWorksheet } from "src/domain/chart/assetWorksheet";
import { TEmployee2 } from "src/domain/master/tEmployee2";
import { TAssetHeadView } from "src/domain/master/view/tAssetHeadView";
import { TCustomerHeadView } from "src/domain/master/view/tCustomerHeadView";
import { RyPercentpayindeposit } from "src/domain/ryPercentpayindeposit";
import { RyReftypework } from "src/domain/ryReftypework";
import { TTypepaymentRef } from "src/domain/tTypepaymentRef";
import { TTypetaxRef } from "src/domain/tTypetaxRef";
import { TTypeworksheetRef } from "src/domain/tTypeworksheetRef";
import { TWshPromiss } from "src/domain/tWshPromiss";
import { TWshTransportDetail } from "src/domain/tWshTransportDetail";
import { TWshTransportExprice } from "src/domain/tWshTransportExprice";
import { TWorksheetDetail } from "src/domain/worksheet/tWorksheetDetail";
import { TWorksheetHead } from "src/domain/worksheet/tWorksheetHead";
import { TWorksheetInsurance } from "src/domain/worksheet/tWorksheetInsurance";
import { TWorksheetMovement } from "src/domain/worksheet/tWorksheetMovement";
import { TWorksheetOtherservice } from "src/domain/worksheet/tWorksheetOtherservice";
import { TWorksheetExtrapriceView } from "src/domain/worksheet/view/tWorksheetExtrapriceView";
import { TWorksheetWorkerView } from "src/domain/worksheet/view/tWorksheetWorkerView";
import { TWshConstructionworkView } from "src/domain/worksheet/view/tWshConstructionworkView";
import tCustomerHeadService from "src/services/customer/tCustomerHeadService";
import tEmployee2Service from "src/services/employee/tEmployee2Service";
import ryPercentpayindepositService from "src/services/reference/ryPercentpayindepositService";
import ryReftypeworkService from "src/services/reference/ryReftypeworkService";
import tTypepaymentRefService from "src/services/reference/tTypepaymentRefService";
import tTypetaxRefService from "src/services/reference/tTypetaxRefService";
import tTypeworksheetRefService from "src/services/reference/tTypeworksheetRefService";
import tAssetHeadService from "src/services/tasset/tAssetHeadService";
import tTruckRepairService from "src/services/tTruckRepairService";
import tWorksheetHeadService from "src/services/tWorksheetHeadService";
import worksheetService from "src/services/worksheetService";
import { useAppSelector } from "src/stores/store";
import Constants from "src/utils/constants";
import { dateToString, toMomentDateTime, toThDate } from "src/utils/dateTimeUtils";
import { NotificationType, showNotification } from "src/utils/notificationUtils";
import { formatNumber, parserNumber, textNumber } from "src/utils/numberUtils";
import "../../custom-style.css";
import { WorksheetHeadData } from "../../domain/worksheet/view/worksheetHeadData";
import AddCustomerModal from "../modals/AddCustomerModal";
import AddExtraPriceModal from "../modals/AddExtraPriceModal";
import AddInsuranceModal from "../modals/AddInsuranceModal";
import ContractorWorkDetailTab from "./tabs/ContractorWorkDetailTab";
import ServiceDetailTab from "./tabs/ServiceDetailTab";
import WorkerDetailTab from "./tabs/WorkerDetailTab";
import WorksheetOtherserviceTab from "./tabs/WorksheetOtherserviceTab";

const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TabPane } = Tabs;
const { confirm } = Modal;

interface ModalProp {
  statePg: string;
  selectedReserveCode: string;
  selectedAsset: AssetWorksheet;
  onDrawerClose: boolean;
  setOnDrawerClose: any;
}

const ChartNewWorksheet: React.FC<ModalProp> = (props) => {
  const { statePg, selectedReserveCode, selectedAsset, onDrawerClose, setOnDrawerClose } = props;
  const [submit, setSubmit] = useState(false);
  const [form] = Form.useForm();
  const [showSpin, setShowSpin] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("serviceDetailTab");
  const [recalculateWsh, setRecalculateWsh] = useState<boolean>(false);
  //open modal state
  const [visibleModalAddCust, setVisibleModalAddCust] = useState(false);
  const [visibleModalExtraPrice, setVisibleModalExtraPrice] = useState(false);
  const [visibleInsurance, setVisibleInsurance] = useState(false);

  const [worksheetHeadData, setWorksheetHeadData] = useState<WorksheetHeadData>({} as WorksheetHeadData);
  const [tCustomerHeadViews, setTCustomerHeadViews] = useState<TCustomerHeadView[]>([]);
  const [tTypeworksheetRefs, setTTypeworksheetRefs] = useState<TTypeworksheetRef[]>([]); //ประเภทใบงาน(ปกติ,ใบงานย่อย,ใบงานคุม)
  const [ryReftypeworks, setRyReftypeworks] = useState<RyReftypework[]>([]); //ประเภทงาน(ใบงานนอก,ใบงานใน)
  const [tTypetaxRefs, setTTypetaxRefs] = useState<TTypetaxRef[]>([]); //ภาษีขาย
  const [tTypepaymentRefs, setTTypepaymentRefs] = useState<TTypepaymentRef[]>([]); //ประเภทการชำระเงิน
  const [ryPercentpayindeposits, setRyPercentpayindeposits] = useState<RyPercentpayindeposit[]>([]); //จำนวนเงินที่ชำระ
  const [ryPercentpayindepositSelected, setRyPercentpayindepositSelected] = useState<RyPercentpayindeposit>({} as RyPercentpayindeposit);
  const [tAssetHeadViews, setTAssetHeadViews] = useState<TAssetHeadView[]>([]); //001 รถ,002 ตู้,003 แผ่นเหล็ก
  const [tWorksheetExtrapriceList, setTWorksheetExtrapriceList] = useState<TWorksheetExtrapriceView[]>([]);
  const [tAssetHeadViewSelected, setTAssetHeadViewSelected] = useState<TAssetHeadView>({} as TAssetHeadView);
  const [selectedTWorksheetInsurance, setSelectedTWorksheetInsurance] = useState<TWorksheetInsurance>({} as TWorksheetInsurance);
  const [drivers, setDrivers] = useState<TEmployee2[]>([]);
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  ////For set disable form item
  const [disableAsset, setDisableAsset] = useState<boolean>(false);
  const [disableDriver, setDisableDriver] = useState<boolean>(false);
  const [disableTypeworksheet, setDisableTypeworksheet] = useState<boolean>(false);
  const [disableTypevat, setDisableTypevat] = useState<boolean>(false);
  const [disableTypepayment, setDisableTypepayment] = useState<boolean>(false);
  const [disableDepositpercent, setDisableDepositpercent] = useState<boolean>(false);
  const [disablePromissNumber, setDisablePromissNumber] = useState<boolean>(false);
  const [disablePromissQty, setDisablePromissQty] = useState<boolean>(false);
  const [disableReceiptAmt, setDisableReceiptAmt] = useState<boolean>(false);
  const [disableBtnWshTransportSelect, setDisableBtnWshTransportSelect] = useState<boolean>(true);

  const [disableServiceDetailTab, setDisableServiceDetailTab] = useState<boolean>(false);//ucServiceDetail
  const [disableContractorWorkDetailTab, setDisableContractorWorkDetailTab] = useState<boolean>(true);//ucContractorWorkDetail
  const [disableWorkerDetailTab, setDisableWorkerDetailTab] = useState<boolean>(true); //ucWorkerDetail
  const [disableOtherServiceTab, setDisableOtherServiceTab] = useState<boolean>(true);//ucOtherService

  const [recalculateContractorWork, setRecalculateContractorWork] = useState<boolean>(false);
  const [recalculateOtherService, setRecalculateOtherService] = useState<boolean>(false);

  const [wshDetailFormDataList, setWshDetailFormDataList] = useState<TWorksheetDetail[]>([]); //รายการบริการใน tab ServiceDetailTab
  const [twshConstructionworkFormDataList, setTwshConstructionworkFormDataList] = useState<TWshConstructionworkView[]>([]); //รายการใบสั่งงานของเหมาใน tab ContractorWorkDetailTab
  const [tWorksheetOtherserviceFormDataList, setTWorksheetOtherserviceFormDataList] = useState<TWorksheetOtherservice[]>([]); //รายการซ่อม ขาย อื่นๆ tab WorksheetOtherserviceTab
  const [tworksheetWorkerFormDataList, setTworksheetWorkerFormDataList] = useState<TWorksheetWorkerView[]>([]); //รายการเด็กท้ายรถ tab workerDetailTab

  const [newCustomer, setNewCustomer] = useState({} as TCustomerHeadView);
  // this.ucCustomer.Clear();//ลูกค้า
  // this.ucTypeWork.Clear();//ประเภทงาน(ปกติ,เหมาย่อย,เหมาใหญ่,แผ่นเหล็ก,ซ่อม/ซื้อ/ขาย)
  // this.ucTypeSheet.Clear();//ประเภทใบงาน(นอก,ใน,อื่นๆ)
  // this.ucAsset.Clear();//รถ
  // this.ucDriver.Clear();//คนขับ
  // this.ucServiceDetail.Clear();//รายการบริการ
  // this.ucWorkerDetail.Clear();//รายละเอียดการบริการ
  // this.ucContractorWorkDetail.Clear();
  // this.ucTypePayment.Clear();//การชำระ
  // this.ucExtraprice.Clear();//ค่าบริการพิเศษ
  // this.ucTypeTax.Clear();//ประเภทภาษี
  // this.ucPurChaseTax.Clear();//การหักภาษี ณ ที่จ่าย
  // this.ucPercentDeposit.Clear();//เงินมัดจำ
  // this.ucOtherService.Clear();//ค่าบริการอื่นๆ(ใบงานซ่อม/ซื้อ/ขาย)

  useEffect(() => {
    if (onDrawerClose) {
      setShowSpin(true);
      if (statePg === "ADD") {
        init();
      } else if (statePg === "UPD" && selectedReserveCode) {
        initEditdata();
      }
    }
  }, [onDrawerClose]);

  useEffect(() => {
    if (submit) {
      form.submit();
    }
  }, [submit]);

  useEffect(() => {
    if (!visibleInsurance) {
      setWorksheetHeadData({
        ...(worksheetHeadData as WorksheetHeadData),
        tworksheetHead: {
          ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
          iinsuranceAmt: selectedTWorksheetInsurance.iinsuranceAmt,
        },
        tworksheetInsurance: { ...selectedTWorksheetInsurance },
      });
    }
  }, [visibleInsurance]);

  useEffect(() => {
    if (!visibleModalExtraPrice) {
      let sumExtraPrice = 0;
      if ((tWorksheetExtrapriceList) && tWorksheetExtrapriceList.length > 0) {
        tWorksheetExtrapriceList.forEach((obj: TWorksheetExtrapriceView) => {
          sumExtraPrice += (obj.iamount) ? obj.iamount : 0;
        });
      }
      setWorksheetHeadData({
        ...(worksheetHeadData as WorksheetHeadData),
        tworksheetHead: {
          ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
          iextraAmt: sumExtraPrice,
        },
        tworksheetExtrapriceViewList: [...tWorksheetExtrapriceList],
      });
      setRecalculateWsh(true);
    }
  }, [visibleModalExtraPrice]);

  const init = async () => {
    const ryReftypeworkRes = await ryReftypeworkService.getRyReftypeworkList();
    const tTypeworksheetRefRes = await tTypeworksheetRefService.getTTypeworksheetRefList();
    const tTypetaxRefRes = await tTypetaxRefService.getTTypetaxRefList();
    const tTypepaymentRefRes = await tTypepaymentRefService.getTTypepaymentRefList();
    const ryPercentpayindepositRes = await ryPercentpayindepositService.getRyPercentpayindepositList();

    //set ค่า default รถ ตามที่เลือกในหน้า ChartView
    const defaultAssetRes = await tAssetHeadService.getAssetByNameList(selectedAsset.assetname);
    const defaultAssetArr = defaultAssetRes.data as Array<TAssetHeadView>;
    const foundDefaultAsset = defaultAssetArr.find((obj) => obj.id === selectedAsset.id);
    const defaultDriverRes = await tEmployee2Service.getDriverByEmpCode(selectedAsset.truckdrivercode);

    setRyReftypeworks(ryReftypeworkRes.data);
    setTTypeworksheetRefs(tTypeworksheetRefRes.data);
    setTTypetaxRefs(tTypetaxRefRes.data);
    setTTypepaymentRefs(tTypepaymentRefRes.data);
    setRyPercentpayindeposits(ryPercentpayindepositRes.data);
    setTAssetHeadViews(defaultAssetRes.data);
    setTAssetHeadViewSelected(foundDefaultAsset ? foundDefaultAsset : {} as TAssetHeadView);
    setDrivers(defaultDriverRes.data);

    const now = dateToString(moment(new Date()))
    const start = toMomentDateTime(now, "080000");
    const end = toMomentDateTime(now, "170000");

    form.setFieldsValue({
      itruckCode: selectedAsset.id,
      idriverCode: selectedAsset.truckdrivercode,
      itypevatCode: tTypetaxRefRes.data[0].iid,
      itypework: ryReftypeworkRes.data[0].id,
      typeworksheetflag: ryReftypeworkRes.data[0].id,
      itypeworksheetCode: tTypeworksheetRefRes.data[0].iid,
      flgDisplaydetail: false,
      flgWaitForCall: false,
      flgWshOt: false,
      promissQty: 0,
      workDate: [start, end],
    });

    setWorksheetHeadData({
      ...(worksheetHeadData as WorksheetHeadData),
      tworksheetHead: {
        ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
        itruckCode: selectedAsset.id,
        idriverCode: selectedAsset.truckdrivercode,
        idriverName: (defaultDriverRes.data.length > 0) ? defaultDriverRes.data[0].empname : "",
        itypetaxCode: tTypetaxRefRes.data[0].iid,
        itypework: ryReftypeworkRes.data[0].id,
        typeworksheetflag: ryReftypeworkRes.data[0].id,
        itypeworksheetCode: tTypeworksheetRefRes.data[0].iid,
        istartDate: now,
        istartTime: "080000",
        iendDate: now,
        iendTime: "170000",
      },
      tassetHeadView: foundDefaultAsset,
    });
    // setEnable("WORK", ryReftypeworkRes.data[0].id);// ทำบรรทัดนี้กรณี Edit
    setShowSpin(false);
    //ดู line 488 ในรถยก
  };

  const initEditdata = async () => {
    const worksheetHeadDataRes = await worksheetService.getWorksheetHeadData(selectedReserveCode);
    const worksheetHeadDataTemp: WorksheetHeadData = worksheetHeadDataRes.data as WorksheetHeadData;
    setEnable("WORK", worksheetHeadDataTemp.tworksheetHead.itypework);
    setWorksheetHeadData(worksheetHeadDataTemp);

    const ryReftypeworkRes = await ryReftypeworkService.getRyReftypeworkList();
    const tTypeworksheetRefRes = await tTypeworksheetRefService.getTTypeworksheetRefList();
    const tTypetaxRefRes = await tTypetaxRefService.getTTypetaxRefList();
    const tTypepaymentRefRes = await tTypepaymentRefService.getTTypepaymentRefList();
    const ryPercentpayindepositRes = await ryPercentpayindepositService.getRyPercentpayindepositList();

    //set ค่า default รถ ตามที่เลือกในหน้า ChartView
    const defaultAssetRes = await tAssetHeadService.getAssetByNameList(worksheetHeadDataTemp.tassetHeadView?.assetname ? worksheetHeadDataTemp.tassetHeadView.assetname : "");
    const defaultAssetArr = defaultAssetRes.data as Array<TAssetHeadView>;
    const foundDefaultAsset = defaultAssetArr.find((obj) => obj.id === worksheetHeadDataTemp.tworksheetHead.itruckCode);
    const defaultDriverRes = await tEmployee2Service.getDriverByEmpCode(worksheetHeadDataTemp.tworksheetHead.idriverCode);

    setRyReftypeworks(ryReftypeworkRes.data);
    setTTypeworksheetRefs(tTypeworksheetRefRes.data);
    setTTypetaxRefs(tTypetaxRefRes.data);
    setTTypepaymentRefs(tTypepaymentRefRes.data);
    setRyPercentpayindeposits(ryPercentpayindepositRes.data);
    setTAssetHeadViews(defaultAssetRes.data);
    setTAssetHeadViewSelected(foundDefaultAsset ? foundDefaultAsset : {} as TAssetHeadView);
    setDrivers(defaultDriverRes.data);
    setNewCustomer(worksheetHeadDataTemp.tcustomerHeadView);

    const start = toMomentDateTime(worksheetHeadDataTemp.tworksheetHead.istartDate!, worksheetHeadDataTemp.tworksheetHead.istartTime!);
    const end = toMomentDateTime(worksheetHeadDataTemp.tworksheetHead.iendDate!, worksheetHeadDataTemp.tworksheetHead.iendTime!);
    const iserviceAmt = worksheetHeadDataTemp.tworksheetHead.iserviceAmt ? worksheetHeadDataTemp.tworksheetHead.iserviceAmt : 0;
    const iextraAmt = worksheetHeadDataTemp.tworksheetHead.iextraAmt ? worksheetHeadDataTemp.tworksheetHead.iextraAmt : 0;
    const iotAmt = worksheetHeadDataTemp.tworksheetHead.iotAmt ? worksheetHeadDataTemp.tworksheetHead.iotAmt : 0;
    const idiscount = worksheetHeadDataTemp.tworksheetHead.idiscount ? worksheetHeadDataTemp.tworksheetHead.idiscount : 0;

    setWshDetailFormDataList(worksheetHeadDataTemp.tworksheetDetailList ? [...worksheetHeadDataTemp.tworksheetDetailList] : []);
    setTWorksheetExtrapriceList(worksheetHeadDataTemp.tworksheetExtrapriceViewList ? [...worksheetHeadDataTemp.tworksheetExtrapriceViewList] : []);
    setTwshConstructionworkFormDataList(worksheetHeadDataTemp.tworksheetConstructionworkViewList ? [...worksheetHeadDataTemp.tworksheetConstructionworkViewList] : []);
    setTWorksheetOtherserviceFormDataList(worksheetHeadDataTemp.tworksheetOtherserviceList ? [...worksheetHeadDataTemp.tworksheetOtherserviceList] : []);
    setTworksheetWorkerFormDataList(worksheetHeadDataTemp.tworksheetWorkerViewList ? [...worksheetHeadDataTemp.tworksheetWorkerViewList] : []);

    // const [ryPercentpayindepositSelected, setRyPercentpayindepositSelected] = useState<RyPercentpayindeposit>({} as RyPercentpayindeposit);
    // const [selectedTWorksheetInsurance, setSelectedTWorksheetInsurance] = useState<TWorksheetInsurance>({} as TWorksheetInsurance);

    form.setFieldsValue({
      icustCode: worksheetHeadDataTemp.tworksheetHead.icustCode,
      icustAddress: worksheetHeadDataTemp.tworksheetHead.icustAddress,
      icustPhone: worksheetHeadDataTemp.tworksheetHead.icustPhone,
      itypecustomerName: worksheetHeadDataTemp.tcustomerHeadView.itypecustomerName,
      icreditamount: worksheetHeadDataTemp.tcustomerHeadView.icreditamount,
      currentusage: worksheetHeadDataTemp.tcustomerHeadView.currentusage,
      creditdate: worksheetHeadDataTemp.tcustomerHeadView.creditdate,
      itypework: worksheetHeadDataTemp.tworksheetHead.typeworksheetflag,
      itypeworksheetCode: worksheetHeadDataTemp.tworksheetHead.itypeworksheetCode,
      workDate: [start, end],
      ilocation: worksheetHeadDataTemp.tworksheetHead.ilocation,
      icontactName: worksheetHeadDataTemp.tworksheetHead.icontactName,
      icontactPhone: worksheetHeadDataTemp.tworksheetHead.icontactPhone,
      itruckCode: worksheetHeadDataTemp.tworksheetHead.itruckCode,
      idriverCode: worksheetHeadDataTemp.tworksheetHead.idriverCode,
      promissNumber: worksheetHeadDataTemp.twshPromiss?.promissnumber,
      promissQty: worksheetHeadDataTemp.tworksheetHead.allinstalment,
      itypevatCode: worksheetHeadDataTemp.tworksheetHead.itypetaxCode,
      typeworksheetflag: worksheetHeadDataTemp.tworksheetHead.typeworksheetflag,
      itypepaymentCode: worksheetHeadDataTemp.tworksheetHead.itypepaymentCode,
      depositpercentId: worksheetHeadDataTemp.tworksheetHead.depositpercentId,
      amtToPaid: worksheetHeadDataTemp.tworksheetHead.idepositAmt,
      receiptAmt: formatNumber(worksheetHeadDataTemp.tworksheetHead.idepositAmt),
      remainAmt: worksheetHeadDataTemp.tworksheetHead.iremainAmt,
      iinsuranceAmt: worksheetHeadDataTemp.tworksheetHead.iinsuranceAmt,
      iserviceAmt: iserviceAmt,
      iextraAmt: iextraAmt,
      iotAmt: iotAmt,
      idiscount: idiscount,
      netServiceAmt: iserviceAmt! + iextraAmt + iotAmt - idiscount,
      total1Amt: worksheetHeadDataTemp.tworksheetHead.itotal1Amt,
      vatAmt: worksheetHeadDataTemp.tworksheetHead.itaxAmt,
      total2Amt: worksheetHeadDataTemp.tworksheetHead.itotal2Amt,
      flgDisplaydetail: worksheetHeadDataTemp.tworksheetHead.flgDisplaydetail === 1 ? true : false,
      flgWaitForCall: worksheetHeadDataTemp.tworksheetHead.iflgWaitforcall === 1 ? true : false,
      flgWshOt: worksheetHeadDataTemp.tworksheetHead.iflgWshOt === 1 ? true : false,
    });
    setShowSpin(false);
  }

  useEffect(() => {
    if (worksheetHeadData.tworksheetInsurance) {
      const insuranceAmt = worksheetHeadData.tworksheetHead.iinsuranceAmt;
      form.setFieldsValue({
        iinsuranceAmt: insuranceAmt ? insuranceAmt : 0,
      });
    }
  }, [worksheetHeadData.tworksheetInsurance]);

  useEffect(() => {
    if (Object.keys(newCustomer).length > 0) {
      (async () => {
        let newCustViews = [];
        const newTcustomerHeadViewRes = await tCustomerHeadService.getCustomerByCode(newCustomer.icustCode);
        newCustViews.push(newTcustomerHeadViewRes.data);

        setTCustomerHeadViews(newCustViews);
      })();
    }
  }, [newCustomer]);

  useEffect(() => {
    if (Object.keys(newCustomer).length > 0 && tCustomerHeadViews.length > 0) {
      handleChangeCustomer(tCustomerHeadViews[0].icustCode);
    }
  }, [tCustomerHeadViews]);

  useEffect(() => {
    if (recalculateWsh) {
      recalculate();
      setRecalculateWsh(false);
    }
    if (recalculateContractorWork) {
      recalculate();
      setRecalculateContractorWork(false);
    }

    if (recalculateOtherService) {
      recalculate();
      setRecalculateOtherService(false);
    }

  }, [recalculateWsh, recalculateContractorWork, recalculateOtherService]);

  const recalculate = () => {
    // if (recalculateWsh) {
    let serviceAmt = 0; //ค่าบริการ
    let netServiceAmt = 0;//ค่าบริการทั้งหมด
    let amountBefVat = 0;//ราคาก่อนภาษี : vat = ภาษีมูลค่าเพิ่ม tax = ภาษีหัก ณ ที่จ่าย
    let vatAmt = 0;//ภาษี
    let netSumServiceAmt = 0;
    let amtToPaid = 0;
    let remainAmt = 0;
    let receiveCashAmt = !parserNumber(form.getFieldValue("receiptAmt")) ? 0 : parserNumber(form.getFieldValue("receiptAmt"));

    let itypework = form.getFieldValue("itypework");
    let itypevatCode = form.getFieldValue("itypevatCode");
    // let itypework = worksheetHeadData.tworksheetHead.typeworksheetflag;
    // let itypevatCode = worksheetHeadData.tworksheetHead.itypetaxCode;

    if (!itypework) return;
    if (!itypevatCode) return;

    const iextraAmt = !(worksheetHeadData.tworksheetHead.iextraAmt) ? 0 : worksheetHeadData.tworksheetHead.iextraAmt;
    const iotAmt = !(worksheetHeadData.tworksheetHead.iotAmt) ? 0 : worksheetHeadData.tworksheetHead.iotAmt;
    const iserviceAmt = !(worksheetHeadData.tworksheetHead.iserviceAmt) ? 0 : worksheetHeadData.tworksheetHead.iserviceAmt;
    const idiscount = !(worksheetHeadData.tworksheetHead.idiscount) ? 0 : worksheetHeadData.tworksheetHead.idiscount;

    //worksheetDetail default
    let sumServiceAmount = 0; //รวมค่าบริการไม่หักส่วนลด
    let sumServiceDiscount = 0;//รวมส่วนลด
    let sumServiceLastAmt = 0;//รวมค่าบริการหักส่วนลด
    let sumQty = 0;//รวมวัน
    if (worksheetHeadData.tworksheetDetailList && worksheetHeadData.tworksheetDetailList.length > 0) {
      worksheetHeadData.tworksheetDetailList.forEach((obj) => {
        sumQty += obj.iqty;
        sumServiceAmount += ((obj.iamount ? obj.iamount : 0) + (obj.discount ? obj.discount : 0));
        sumServiceDiscount += (obj.discount) ? obj.discount : 0;
        sumServiceLastAmt += (obj.iamount) ? obj.iamount : 0;
      });
    }

    if ("02" !== itypework && "05" !== itypework) { //02=ใบงานเหมา [ใบคุมใบย่อย], 05=ใบงานซ่อม/ขาย/อื่นๆ
      serviceAmt = sumServiceAmount;
      netServiceAmt = sumServiceLastAmt + iextraAmt + iotAmt;
    } else if ("05" === itypework) {
      // serviceAmt = 0;//this.ucOtherService.GetOtherServicePrice; รายการซ่อม ขาย อื่นๆ
      sumServiceAmount = worksheetHeadData.sumWorksheetOtherserviceAmt;
      sumServiceLastAmt = worksheetHeadData.sumWorksheetOtherserviceAmt;
      serviceAmt = worksheetHeadData.sumWorksheetOtherserviceAmt;
      netServiceAmt = serviceAmt + iextraAmt + iotAmt;
    } else {
      //serviceAmt = 0;//this.ucContractorWorkDetail.GetSumation รายการใบสั่งงานของเหมา
      sumServiceAmount = worksheetHeadData.sumConstructionWorkAmt;
      sumServiceLastAmt = worksheetHeadData.sumConstructionWorkAmt;
      serviceAmt = worksheetHeadData.sumConstructionWorkAmt;
      netServiceAmt = sumServiceLastAmt + iextraAmt + iotAmt - idiscount;
    }

    vatAmt = calVatAmt(netServiceAmt, itypevatCode);
    amountBefVat = netServiceAmt;
    if (itypevatCode === "02") amountBefVat = netServiceAmt - vatAmt;

    netSumServiceAmt = amountBefVat + vatAmt;
    amtToPaid = calDepositAmt(netSumServiceAmt, sumQty, sumServiceDiscount, sumServiceLastAmt, itypework, itypevatCode);
    // remainAmt = netSumServiceAmt - receiveCashAmt;
    // remainAmt = netSumServiceAmt - (receiveCashAmt > 0 ? receiveCashAmt : amtToPaid);
    remainAmt = netSumServiceAmt - amtToPaid;

    form.setFieldsValue({
      iserviceAmt: serviceAmt,
      idiscount: sumServiceDiscount,
      iextraAmt: iextraAmt,
      iotAmt: iotAmt,
      netServiceAmt: netServiceAmt,
      vatAmt: vatAmt,
      total1Amt: amountBefVat,
      total2Amt: netSumServiceAmt,
      amtToPaid: amtToPaid,
      receiptAmt: formatNumber(amtToPaid),
      remainAmt: remainAmt,
    });
    setWorksheetHeadData({
      ...(worksheetHeadData as WorksheetHeadData),
      tworksheetHead: {
        ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
        iserviceAmt: serviceAmt,
        idiscount: sumServiceDiscount,
        iextraAmt: iextraAmt,
        iotAmt: iotAmt,
        itaxAmt: vatAmt,
        itotal1Amt: amountBefVat,
        itotal2Amt: netSumServiceAmt,
        idepositAmt: (receiveCashAmt ? receiveCashAmt : amtToPaid),
        iremainAmt: remainAmt,
      },
    });
    // }
  }

  const calVatAmt = (netServiceAmt: number, itypevatCode: string) => {
    let taxAmt = 0;
    let vatRate = 7;
    if (itypevatCode === "01") {
      taxAmt = Math.round(((netServiceAmt * vatRate) / 100) * 100) / 100;
    } else if (itypevatCode === "02") {
      taxAmt = Math.round(((netServiceAmt * vatRate) / (100 + vatRate)) * 100) / 100;
    }
    return taxAmt;
  }

  const calDepositAmt = (netSumServiceAmt: number, sumQty: number, sumServiceDiscount: number, sumServiceLastAmt: number, itypework: string, itypevatCode: string) => {
    let deposit = 0;
    // if (!worksheetHeadData.tworksheetDetailList || worksheetHeadData.tworksheetDetailList.length <= 0) {
    //   deposit = 0;
    //   console.log("----- calDepositAmt deposit : ", deposit);
    // } else  {
      let depositpercentId = form.getFieldValue("depositpercentId");
      if (!depositpercentId) return 0;
      if ("02" !== itypework && "05" !== itypework && !tAssetHeadViewSelected) return 0;
      if (!worksheetHeadData.tworksheetDetailList
       && !worksheetHeadData.tworksheetConstructionworkViewList
       && !worksheetHeadData.tworksheetOtherserviceList) return 0;

      if ("00" === depositpercentId) return 0;
      if ("01" === depositpercentId) return netSumServiceAmt;
      
      const worksheetDetailArr: any = worksheetHeadData.tworksheetDetailList;
      // const unitPrice = worksheetDetailArr[0].iamount + worksheetDetailArr[0].discount;

      if ("02" !== itypework && "05" !== itypework && "002" === tAssetHeadViewSelected.assettype) {
        const unitPrice = worksheetDetailArr[0].iunitPrice;
        if ("01" === itypevatCode) {
          deposit = unitPrice - (sumServiceDiscount / sumQty);
          //double tax = Deposit * ((RyPurchaseTaxData)this.ucPurChaseTax.ObjectInfo).Percent / 100; //รายการภาษีหัก ณ ที่จ่าย
          deposit += (worksheetHeadData.tworksheetExtrapriceViewList && worksheetHeadData.tworksheetExtrapriceViewList.length > 0) ? worksheetHeadData.tworksheetExtrapriceViewList[0].iamount : 0;
          deposit += (deposit * 7 / 100);
          //deposit -= tax;
        } else if ("02" === itypevatCode) {
          deposit = unitPrice - (sumServiceDiscount / sumQty) - (unitPrice * 7 / 107);
          // double tax = Deposit * ((RyPurchaseTaxData)this.ucPurChaseTax.ObjectInfo).Percent / 100;
          deposit += (worksheetHeadData.tworksheetExtrapriceViewList && worksheetHeadData.tworksheetExtrapriceViewList.length > 0) ? worksheetHeadData.tworksheetExtrapriceViewList[0].iamount : 0;
          deposit += (deposit * 7 / 100);
          // Deposit -= tax;
        } else {
          deposit = unitPrice - (sumServiceDiscount / sumQty);
        }
      } else if ("02" !== itypework && "05" !== itypework && "003" === tAssetHeadViewSelected.assettype) {
        const unitPrice = worksheetDetailArr[0].iunitPrice;
        if ("01" === itypevatCode) {
          deposit = unitPrice - (sumServiceDiscount / sumQty);
          // double tax = Deposit * ((RyPurchaseTaxData)this.ucPurChaseTax.ObjectInfo).Percent / 100;
          deposit += (deposit * 7 / 100);
          // Deposit -= tax;
        } else if ("02" === itypevatCode) {
          deposit = unitPrice - (sumServiceDiscount / sumQty) - (unitPrice * 7 / 107);
          // double tax = Deposit * ((RyPurchaseTaxData)this.ucPurChaseTax.ObjectInfo).Percent / 100;
          deposit += (deposit * 7 / 100);
          // Deposit -= tax;
        } else {
          deposit = unitPrice - (sumServiceDiscount / sumQty);
        }
      } else {
        deposit = (ryPercentpayindepositSelected.value / 100) * netSumServiceAmt;
        if (deposit % 100 > 0) {
          deposit += (100 - (deposit % 100));
        }
        // this.txtNumToPaid.Text = Deposit.ToString("#,##0.00");
        // numcash = deposit // numcash = รับชำระ
        // txtNumToPaid = //txtNumToPaid = เงินคำนวณตอนเลือก จำนวนเงินที่ชำระ
      }
    //}
    return deposit;
  }

  const handleSearchCustomer = async (data: any) => {
    if (data.length < 3) return;
    const searchCustRes = await tCustomerHeadService.getCustomerByParam(data);
    setTCustomerHeadViews(searchCustRes.data.length > 0 ? searchCustRes.data : []);
  };

  const handleChangeCustomer = (icustCode: any) => {
    const custArray = tCustomerHeadViews as Array<TCustomerHeadView>;
    const findCust = custArray.find((ob) => ob.icustCode === icustCode);
    const mobile = (findCust?.imobile) ? findCust?.imobile : "-"
    const fullName = `${findCust?.iprenameName.trim()}  ${findCust?.iname} ${(findCust?.isurname) ? findCust?.isurname : ""}`;
    const fullAddress = `${findCust?.iaddress} อำเภอ/เขต ${findCust?.iamphurName} จังหวัด${findCust?.iprovinceName} ${findCust?.ipostcode}`;
    const fullPhone = `${findCust?.iphone} มือถือ ${mobile}`;
    let fullTypecustomerName = `${findCust?.itypecustomerName} ส่วนลด ${findCust?.idiscount}%`;
    const chequeNonTrush = (findCust?.chequeNonTrush ? findCust?.chequeNonTrush : 0);

    if (!findCust?.idebitorCode) {
      fullTypecustomerName = "ลูกค้าทั่วไป";
      form.setFieldsValue({
        icustCode: findCust?.icustCode,
        icustAddress: fullAddress,
        icustPhone: fullPhone,
        itypecustomerName: fullTypecustomerName,
        icreditamount: findCust?.icreditamount,
        currentusage: findCust?.currentusage,
        creditdate: findCust?.creditdate,
        receiptAmt: 0,
      });
      //set object value
      setWorksheetHeadData({
        ...(worksheetHeadData as WorksheetHeadData),
        tworksheetHead: {
          ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
          icustCode: findCust?.icustCode,
          icustName: fullName,
          icustAddress: fullAddress,
          icustPhone: fullPhone,
          debitorcode: findCust?.idebitorCode,
          // payedamount: 0,
        },
        tcustomerHeadView: (findCust) ? findCust : {} as TCustomerHeadView,
        twshTransportExtraPrice: {} as TWshTransportExprice,
        twshTransportDetail: {} as TWshTransportDetail,
      });
      setRecalculateWsh(true);
      setDisableReceiptAmt(false);
    } else {
      const chkCredit = (findCust?.icreditamount ? findCust?.icreditamount : 0) - (findCust?.currentusage ? findCust?.currentusage : 0);//วงเงิน ลบ จำนวนเงินที่ใช้
      if (chkCredit < 2000 || findCust?.iremarkMessage) {//ถ้าใกล้ถึงหรือเกินหรือมีข้อความ remark
        const overCredit = (findCust?.currentusage ? findCust?.currentusage : 0) - (findCust?.icreditamount ? findCust?.icreditamount : 0);

        let errMessage = `ลูกค้ามีวงเงิน :  ${formatNumber(findCust?.icreditamount)} บาท <br /> ยอดค้างชำระรวมทั้งสิ้น : ${formatNumber(findCust?.currentusage)} บาท <br />`;
        if (overCredit > 0) errMessage += `!!! ขณะนี้ใช้เกินวงเงินแล้วเป็นเงิน : ${formatNumber(overCredit)} บาท <br />`;
        if (chequeNonTrush > 0) errMessage += `มีเช็ครับชำระแต่ค้างนำฝากเป็นเงิน : ${formatNumber(chequeNonTrush)} บาท <br />`;
        if (findCust?.iremarkMessage) errMessage += `${findCust.iremarkMessage.trim()}`;
        errMessage += "คุณต้องการสร้างใบงานหรือไม่ ?";

        confirm({
          title: "ยืนยัน",
          content:
            (<div dangerouslySetInnerHTML={{ __html: errMessage }}></div>),
          icon: <ExclamationCircleOutlined />,
          onOk() {
            //set form value
            form.setFieldsValue({
              icustCode: findCust?.icustCode,
              icustAddress: fullAddress,
              icustPhone: fullPhone,
              itypecustomerName: fullTypecustomerName,
              icreditamount: findCust?.icreditamount,
              currentusage: findCust?.currentusage,
              creditdate: findCust?.creditdate,
              receiptAmt: 0,
            });
            //set object value
            setWorksheetHeadData({
              ...(worksheetHeadData as WorksheetHeadData),
              tworksheetHead: {
                ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
                icustCode: findCust?.icustCode,
                icustName: fullName,
                icustAddress: fullAddress,
                icustPhone: fullPhone,
                debitorcode: findCust?.idebitorCode,
                // payedamount: 0,
              },
              tcustomerHeadView: (findCust) ? findCust : {} as TCustomerHeadView,
              twshTransportExtraPrice: {} as TWshTransportExprice,
              twshTransportDetail: {} as TWshTransportDetail,
            });
            setRecalculateWsh(true);
            setDisableReceiptAmt(false);
          },
          onCancel() {
            form.setFieldsValue({
              icustCode: '',
              icustAddress: '',
              icustPhone: '',
              itypecustomerName: '',
              icreditamount: '',
              currentusage: '',
              creditdate: ''
            });
          },
        });
      } else {
        form.setFieldsValue({
          icustCode: findCust?.icustCode,
          icustAddress: fullAddress,
          icustPhone: fullPhone,
          itypecustomerName: fullTypecustomerName,
          icreditamount: findCust?.icreditamount,
          currentusage: findCust?.currentusage,
          creditdate: findCust?.creditdate,
          receiptAmt: 0,
        });
        //set object value
        setWorksheetHeadData({
          ...(worksheetHeadData as WorksheetHeadData),
          tworksheetHead: {
            ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
            icustCode: findCust?.icustCode,
            icustName: fullName,
            icustAddress: fullAddress,
            icustPhone: fullPhone,
            debitorcode: findCust?.idebitorCode,
            // payedamount: 0,
          },
          tcustomerHeadView: (findCust) ? findCust : {} as TCustomerHeadView,
          twshTransportExtraPrice: {} as TWshTransportExprice,
          twshTransportDetail: {} as TWshTransportDetail,
        });
      }
    }
  };

  const handleClearCustomer = () => {
    form.setFieldsValue({
      icustCode: undefined,
      icustAddress: undefined,
      icustPhone: undefined,
      itypecustomerName: undefined,
      icreditamount: undefined,
      currentusage: undefined,
      creditdate: undefined
    });

    setWorksheetHeadData({
      ...(worksheetHeadData as WorksheetHeadData),
      tworksheetHead: {
        ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
        icustCode: undefined,
        icustName: undefined,
        icustAddress: undefined,
        icustPhone: undefined,
        debitorcode: undefined,
      },
    });
  }

  const handleChangeWorksheetType = (value: any) => {
    //01=ใบงานนอก 02=ใบงานใน 03=รายการอื่นๆ 04=เงินประกัน
    if (value !== "03" && value !== "04") {
      //อันเก่าคือ SetAutoVat
      let tTypetaxSelected = {} as any;
      // let tTypetaxSelected: TTypetaxRef = {} as TTypetaxRef;
      if ("02" === value) {
        tTypetaxSelected = (tTypetaxRefs as Array<TTypetaxRef>).find((obj) => obj.iid === "03");
      } else {
        tTypetaxSelected = (tTypetaxRefs as Array<TTypetaxRef>).find((obj) => obj.iid === "01");
      }

      form.setFieldsValue({
        itypevatCode: tTypetaxSelected.iid,
      });
      setWorksheetHeadData({
        ...(worksheetHeadData as WorksheetHeadData),
        tworksheetHead: {
          ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
          itypeworksheetCode: value,
          itypetaxCode: tTypetaxSelected.iid
        },
      });
      setRecalculateWsh(true);
    } else {
      showNotification(NotificationType.ERROR, "ประเภทใบงานไม่ถูกต้อง !");
      return;
    }
  };

  const handleChangeWorkType = (value: any) => {
    if (value === "04") {
      showNotification(NotificationType.ERROR, "ไม่สามารถเลือก ใบงานเหมาแผ่นเหล็กได้ กรุณาสร้างใบงานที่เมนู ใบงานจองแผ่นเหล็ก");
      form.setFieldsValue({
        itypework: "00",
      });
      return;
    } else {
      form.setFieldsValue({
        itypework: value,
      });

      setWorksheetHeadData({
        ...(worksheetHeadData as WorksheetHeadData),
        tworksheetHead: {
          ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
          itypework: value,
          typeworksheetflag: value,
        },
        // tworksheetDetailList: [],
        // tworksheetConstructionworkViewList: [],
      });


      setWshDetailFormDataList([]);
      setTwshConstructionworkFormDataList([]);
      setTWorksheetOtherserviceFormDataList([]);
      setTworksheetWorkerFormDataList([]);

      setEnable("WORK", value);
      setRecalculateWsh(true);
    }
  };

  const onChangeVatType = (value: any) => {
    setWorksheetHeadData({
      ...(worksheetHeadData as WorksheetHeadData),
      tworksheetHead: {
        ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
        itypetaxCode: value,
      },
    });
    setRecalculateWsh(true);
  };

  const onChangePaymentType = (value: any) => {
    let depositFlg = (value === "03") ? 1 : 0;
    setWorksheetHeadData({
      ...(worksheetHeadData as WorksheetHeadData),
      tworksheetHead: {
        ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
        itypepaymentCode: value,
        idepositFlg: depositFlg,
      },
    });
    setEnable("PAYMENT", value);
    setRecalculateWsh(true);
  };

  const checkErrorPaidByDeposit = (typepayment: string, percentpayindeposit: string) => {
    let isValid = false;
    if ("01" === typepayment && "01" !== percentpayindeposit) {
      isValid = true;
    }
    if (("02" === typepayment || "04" === typepayment) && "00" !== percentpayindeposit) {
      isValid = true;
    }
    if ("03" === typepayment &&
      !("02" === percentpayindeposit || "03" === percentpayindeposit || "04" === percentpayindeposit) &&
      !("05" === percentpayindeposit && "002" === tAssetHeadViewSelected.assettype)) {
      isValid = true;
    }
    return isValid;
  }

  const onChangePercentDepositType = (value: any) => {
    // const ryPercentpayindepositArr = ryPercentpayindeposits as Array<RyPercentpayindeposit>;
    const ryPercentpayindepositSelected = (ryPercentpayindeposits as Array<RyPercentpayindeposit>).find((obj) => obj.id === value);
    setRyPercentpayindepositSelected(ryPercentpayindepositSelected ? ryPercentpayindepositSelected : {} as RyPercentpayindeposit);

    const typework = worksheetHeadData.tworksheetHead.typeworksheetflag;
    // const typepayment = worksheetHeadData.tworksheetHead.itypepaymentCode;
    const typepayment = form.getFieldValue("itypepaymentCode");
    if (!typework) {
      showNotification(NotificationType.ERROR, "กรุณาเลือก ประเภทงาน !");
      return;// or return message
    }
    if (!typepayment) {
      showNotification(NotificationType.ERROR, "กรุณาเลือก ชำระเงินแบบ !");
      return;// or return message
    }
    // if (!worksheetHeadData.tworksheetHead.itypepaymentCode) return;
    if (!ryPercentpayindepositSelected) {
      showNotification(NotificationType.ERROR, "กรุณาเลือก จำนวนเงินที่ชำระ !");
      return;
    }

    if ("02" !== typework && "05" !== typework) {
      if (!tAssetHeadViewSelected) {
        showNotification(NotificationType.ERROR, "กรุณาเลือก รถ/ตู้/แผ่นเหล็ก !");
        return;
      }
    }
    if (checkErrorPaidByDeposit(typepayment, ryPercentpayindepositSelected.id)) {
      // this.ucPercentDeposit.Clear();
      showNotification(NotificationType.ERROR, "ประเภทการชำระเงินไม่ถูกต้อง !");
      form.setFieldsValue({
        depositpercentId: "",
        depositAmt: 0,
        receiptAmt: 0,
        remainAmt: 0,
      });
      return;
    }
    // this.CalDepositAmt(Convert.ToDouble(this.txtNumSumService.Text));
    // this.txtNumCash.Focus();
    setWorksheetHeadData({
      ...(worksheetHeadData as WorksheetHeadData),
      tworksheetHead: {
        ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
        depositpercentId: ryPercentpayindepositSelected.id,
        depositpercent: ryPercentpayindepositSelected.value
      },
    });
    setRecalculateWsh(true);
  };

  const onCalculateRemain = () => {
    const receiptAmt = parserNumber(form.getFieldValue("receiptAmt"));
    const total2Amt = form.getFieldValue("total2Amt");
    const remainAmt = total2Amt - receiptAmt;

    form.setFieldsValue({
      remainAmt: remainAmt,
    });
    setWorksheetHeadData({
      ...(worksheetHeadData as WorksheetHeadData),
      tworksheetHead: {
        ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
        iremainAmt: remainAmt,
      },
    });
  }

  const onchangedate = (value: any) => {
    if (value !== null) {
      let std = value[0].format("yyyyMMDD");
      let ed = value[1].format("yyyyMMDD");
      let stt = value[0].format("HHmm00");
      let et = value[1].format("HHmm00");

      setWorksheetHeadData({
        ...(worksheetHeadData as WorksheetHeadData),
        tworksheetHead: {
          ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
          istartDate: std,
          istartTime: stt,
          iendDate: ed,
          iendTime: et,
        },
      });
    }
  };

  const handleSearchAsset = async (data: any) => {
    if (data.length < 3) return;
    const branch: string = userInfo.branchId;
    const res = await tAssetHeadService.getAssetByNameNumberDriverList(data, branch);
    setTAssetHeadViews(res.data);
  };

  const handleChangeAsset = async (assetCode: any) => {
    const assetArr = tAssetHeadViews as Array<TAssetHeadView>;
    const findAsset = assetArr.find((obj) => obj.id === assetCode);
    const checkTruckIsRepairRes = await tTruckRepairService.getByTruck2Day(findAsset?.id,
      (worksheetHeadData.tworksheetHead.istartDate) ? worksheetHeadData.tworksheetHead.istartDate : "",
      (worksheetHeadData.tworksheetHead.iendDate) ? worksheetHeadData.tworksheetHead.iendDate : "");
    if (checkTruckIsRepairRes.data.length > 0) {
      showNotification(NotificationType.ERROR, "รถอยู่ในสถานะซ่อมตั้งแต่ " + toThDate(checkTruckIsRepairRes.data[0].istartRepair) + " ถึง " + toThDate(checkTruckIsRepairRes.data[0].iendRepair) + "ไม่สามารถใช้งานได้");
      form.setFieldsValue({
        itruckCode: undefined,
      });
    } else {
      if (findAsset?.truckdrivercode) {
        const res = await tEmployee2Service.getDriverByEmpCode(findAsset.truckdrivercode);
        setDrivers(res.data);
      }

      setTAssetHeadViewSelected(findAsset ? findAsset : {} as TAssetHeadView);
      form.setFieldsValue({
        idriverCode: findAsset?.truckdrivercode,
      });

      setWorksheetHeadData({
        ...(worksheetHeadData as WorksheetHeadData),
        tworksheetHead: {
          ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
          itruckCode: findAsset?.id,
          idriverCode: findAsset?.truckdrivercode,
          idriverName: findAsset?.truckdriverName
        },
        tassetHeadView: findAsset
      });

      setEnable("ASSET", findAsset?.assettype);
    }
  };

  const handleSearchDriver = async (data: any) => {
    if (data.length < 3) return;
    const res = await tEmployee2Service.getDriverByName(data);
    setDrivers(res.data);
  };

  const handleChangeDriver = async (id: any) => {
    const findDriver = (drivers as Array<TEmployee2>).find((obj) => obj.id === id);
    setWorksheetHeadData({
      ...(worksheetHeadData as WorksheetHeadData),
      tworksheetHead: {
        ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
        idriverCode: findDriver?.id,
        idriverName: findDriver?.empname
      },
    });
  };

  const setEnable = (setBy: string, status: any) => {
    switch (setBy) {
      case "CUSTOMER":
        break;
      case "WORK":
        if ("00" === status || "03" === status) {
          setDisableAsset(false);
          setDisableDriver(false);
          setDisableServiceDetailTab(false);
          setDisableWorkerDetailTab(false);
          setDisableContractorWorkDetailTab(true);
          setDisableOtherServiceTab(true);
          setDisableTypeworksheet(false);
          setDisableTypevat(false);
          setDisableTypepayment(false);
          setDisableDepositpercent(false);
          setDisablePromissNumber(false);
          setDisablePromissQty(false);
          setActiveTab("serviceDetailTab");
          const tTypeworksheetSelected = (tTypeworksheetRefs as Array<TTypeworksheetRef>).find((obj) => obj.iid === "01");
          const tTypetaxSelected = (tTypetaxRefs as Array<TTypetaxRef>).find((obj) => obj.iid === "01");

          form.setFieldsValue({
            itruckCode: undefined,
            idriverCode: undefined,
            // itypevatCode: undefined,
            // itypeworksheetCode: undefined,
            itypevatCode: tTypetaxSelected?.iid,
            itypeworksheetCode: tTypeworksheetSelected?.iid,
            itypework: status,
            typeworksheetflag: status,
            itypepaymentCode: undefined,
            depositpercentId: undefined,
            promissNumber: undefined,
            promissQty: 0
          });
          setWorksheetHeadData({
            ...(worksheetHeadData as WorksheetHeadData),
            tworksheetHead: {
              ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
              itruckCode: undefined,
              idriverCode: undefined,
              idriverName: undefined,
              // itypetaxCode: undefined,
              // itypeworksheetCode: undefined,
              itypetaxCode: tTypetaxSelected?.iid,
              itypeworksheetCode: tTypeworksheetSelected?.iid,
              itypework: status,
              typeworksheetflag: status,
              itypepaymentCode: undefined,
              depositpercentId: undefined,
            },
            sumConstructionWorkAmt: 0,
            sumWorksheetOtherserviceAmt: 0,
            // tworksheetDetailList: [],
            // worksheetWorkerList: [],
            tworksheetConstructionworkViewList: [],
            tworksheetOtherserviceList: [],
          });
        } else if ("01" === status) {
          setDisableAsset(false);
          setDisableDriver(false);
          setDisableServiceDetailTab(false);
          setDisableWorkerDetailTab(true);
          setDisableContractorWorkDetailTab(true);
          setDisableOtherServiceTab(true);
          setDisablePromissQty(true);
          setDisablePromissNumber(true);
          setActiveTab("serviceDetailTab");

          const tTypeworksheetSelected = (tTypeworksheetRefs as Array<TTypeworksheetRef>).find((obj) => obj.iid === "02");
          setDisableTypeworksheet(true);
          const tTypetaxSelected = (tTypetaxRefs as Array<TTypetaxRef>).find((obj) => obj.iid === "03");
          setDisableTypevat(true);
          const tTypepaymentSelected = (tTypepaymentRefs as Array<TTypepaymentRef>).find((obj) => obj.iid === "04");
          setDisableTypepayment(true);
          const ryPercentpayindepositSelected = (ryPercentpayindeposits as Array<RyPercentpayindeposit>).find((obj) => obj.id === "00");
          setDisableDepositpercent(true);

          form.setFieldsValue({
            itruckCode: undefined,
            idriverCode: undefined,
            itypevatCode: tTypetaxSelected?.iid,
            itypeworksheetCode: tTypeworksheetSelected?.iid,
            itypepaymentCode: tTypepaymentSelected?.iid,
            depositpercentId: ryPercentpayindepositSelected?.id,
            promissNumber: undefined,
            promissQty: 0
          });
          setWorksheetHeadData({
            ...(worksheetHeadData as WorksheetHeadData),
            tworksheetHead: {
              ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
              itruckCode: undefined,
              idriverCode: undefined,
              idriverName: undefined,
              // itypetaxCode: tTypetaxSelected?.iid,
              // itypeworksheetCode: tTypeworksheetSelected?.iid,
              itypetaxCode: tTypetaxSelected?.iid,
              itypeworksheetCode: tTypeworksheetSelected?.iid,
              itypepaymentCode: tTypepaymentSelected?.iid,
              depositpercentId: ryPercentpayindepositSelected?.id,
            },
            tworksheetDetailList: [],
            tworksheetWorkerViewList: [],
            tworksheetConstructionworkViewList: [],
            tworksheetOtherserviceList: [],
            sumServiceLastAmt: 0,
            netServiceAmt: 0,
            sumConstructionWorkAmt: 0,
            sumWorksheetOtherserviceAmt: 0,
          });
        } else if ("02" === status) {
          setDisableAsset(true);
          setDisableDriver(true);
          setDisableServiceDetailTab(true);
          setDisableWorkerDetailTab(true);
          setDisableContractorWorkDetailTab(false);
          setDisableTypeworksheet(false);
          setDisableTypevat(false);
          setDisableTypepayment(false);
          setDisableDepositpercent(false);
          setDisablePromissQty(false);
          setDisablePromissNumber(false);
          setDisableOtherServiceTab(true);
          setActiveTab("contractorWorkDetailTab");
          const tTypeworksheetSelected = (tTypeworksheetRefs as Array<TTypeworksheetRef>).find((obj) => obj.iid === "01");
          const tTypetaxSelected = (tTypetaxRefs as Array<TTypetaxRef>).find((obj) => obj.iid === "01");

          form.setFieldsValue({
            itruckCode: undefined,
            idriverCode: undefined,
            // itypevatCode: undefined,
            // itypeworksheetCode: undefined,
            itypevatCode: tTypetaxSelected?.iid,
            itypeworksheetCode: tTypeworksheetSelected?.iid,
            itypepaymentCode: undefined,
            depositpercentId: undefined,
            promissNumber: undefined,
            promissQty: 0
          });
          setWorksheetHeadData({
            ...(worksheetHeadData as WorksheetHeadData),
            tworksheetHead: {
              ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
              itruckCode: undefined,
              idriverCode: undefined,
              idriverName: undefined,
              // itypetaxCode: undefined,
              // itypeworksheetCode: undefined,
              itypetaxCode: tTypetaxSelected?.iid,
              itypeworksheetCode: tTypeworksheetSelected?.iid,
              itypepaymentCode: undefined,
              depositpercentId: undefined,
            },
            tworksheetDetailList: [],
            tworksheetWorkerViewList: [],
            tworksheetConstructionworkViewList: [],
            tworksheetOtherserviceList: [],
            sumServiceLastAmt: 0,
            netServiceAmt: 0,
            sumConstructionWorkAmt: 0,
            sumWorksheetOtherserviceAmt: 0,
          });
        } else if ("05" === status) {
          setDisableAsset(true);
          setDisableDriver(true);
          setDisableServiceDetailTab(true);
          setDisableWorkerDetailTab(false);
          setDisableContractorWorkDetailTab(true);
          setDisableTypeworksheet(false);
          setDisableTypevat(false);
          setDisableTypepayment(false);
          setDisableDepositpercent(false);
          setDisablePromissQty(true);
          setDisablePromissNumber(true);
          setDisableOtherServiceTab(false);
          setActiveTab("otherServiceTab");

          const tTypeworksheetSelected = (tTypeworksheetRefs as Array<TTypeworksheetRef>).find((obj) => obj.iid === "01");
          const tTypetaxSelected = (tTypetaxRefs as Array<TTypetaxRef>).find((obj) => obj.iid === "01");
          form.setFieldsValue({
            itruckCode: undefined,
            idriverCode: undefined,
            // itypevatCode: undefined,
            // itypeworksheetCode: undefined,
            itypevatCode: tTypetaxSelected?.iid,
            itypeworksheetCode: tTypeworksheetSelected?.iid,
            itypepaymentCode: undefined,
            depositpercentId: undefined,
            promissNumber: undefined,
            promissQty: 0,
            //อาจจะต้อง set tWorksheetHead data แทน แล้วสั่ง recal
            iserviceAmt: 0,
            depositAmt: 0,
            netServiceAmt: 0,
            iinsuranceAmt: 0,
            remainAmt: 0,
            receiptAmt: 0,
            vatAmt: 0,
            total1Amt: 0,
            iotAmt: 0,
            total2Amt: 0,
            idiscount: 0,
            iextraAmt: 0,
          });
          setWorksheetHeadData({
            ...(worksheetHeadData as WorksheetHeadData),
            tworksheetHead: {
              ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
              itruckCode: undefined,
              idriverCode: undefined,
              idriverName: undefined,
              itypetaxCode: undefined,
              itypeworksheetCode: undefined,
              itypepaymentCode: undefined,
              depositpercentId: undefined,
              iserviceAmt: 0,
              idepositAmt: 0,
              iinsuranceAmt: 0,
              iremainAmt: 0,
              itaxAmt: 0,
              itotal1Amt: 0,
              iotAmt: 0,
              itotal2Amt: 0,
              idiscount: 0,
              iextraAmt: 0,
              iwastOftimeamt: 0,
            },
            tworksheetDetailList: [],
            tworksheetWorkerViewList: [],
            tworksheetConstructionworkViewList: [],
            tworksheetOtherserviceList: [],
            sumServiceLastAmt: 0,
            netServiceAmt: 0,
            sumConstructionWorkAmt: 0,
            sumWorksheetOtherserviceAmt: 0,
          });
        }
        break;

      case "ASSET":
        if ("001" !== status && "007" !== status) {
          setDisableDriver(true);
          setDisablePromissNumber(false);
          setDisableWorkerDetailTab(false);
          setDisableContractorWorkDetailTab(true);
          setDisablePromissQty(false);
          form.setFieldsValue({
            idriverCode: undefined,
            promissNumber: "",
            promissQty: 0,
          });
          setWorksheetHeadData({
            ...(worksheetHeadData as WorksheetHeadData),
            tworksheetHead: {
              ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
              idriverCode: undefined,
              idriverName: undefined,
            },
          });
        } else {
          setDisableDriver(false);
          setDisablePromissNumber(true);
          setDisableWorkerDetailTab(false)
          setDisableContractorWorkDetailTab(false);
          setDisablePromissQty(true);
          form.setFieldsValue({
            promissNumber: "",
            promissQty: 0,
          });
        }

        if (worksheetHeadData.tcustomerHeadView) {
          let typePaymentCust = "";
          let typePercentDep = "";
          if (worksheetHeadData.tcustomerHeadView.itypecustomerCode === "00") {
            typePaymentCust = "03";
            typePercentDep = "03";
          } else {
            typePaymentCust = "04";
            typePercentDep = "00";
          }
          if (!worksheetHeadData.tworksheetHead.itypepaymentCode) {
            const tTypepaymentSelected = (tTypepaymentRefs as Array<TTypepaymentRef>).find((obj) => obj.iid === typePaymentCust);
            const ryPercentpayindepositSelected = (ryPercentpayindeposits as Array<RyPercentpayindeposit>).find((obj) => obj.id === typePercentDep);
            setDisableDepositpercent(ryPercentpayindepositSelected?.id === "03" ? true : false);

            if (statePg === "ADD" || statePg === "") {
              if (tTypepaymentSelected?.iid === "01") {
                setDisableBtnWshTransportSelect(false);
              } else {
                setDisableBtnWshTransportSelect(true);
              }
            } else {
              setDisableBtnWshTransportSelect(true);
            }

            form.setFieldsValue({
              itypepaymentCode: tTypepaymentSelected?.iid,
              depositpercentId: ryPercentpayindepositSelected?.id,
            });
            setWorksheetHeadData({
              ...(worksheetHeadData as WorksheetHeadData),
              tworksheetHead: {
                ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
                itypepaymentCode: tTypepaymentSelected?.iid,
                depositpercentId: ryPercentpayindepositSelected?.id,
              },
            });
          }
        }
        break;
      case "PAYMENT":
        if ("02" === status || "04" === status) {
          setDisableReceiptAmt(true);
          const ryPercentpayindepositSelected = (ryPercentpayindeposits as Array<RyPercentpayindeposit>).find((obj) => obj.id === "00");
          setRyPercentpayindepositSelected(ryPercentpayindepositSelected ? ryPercentpayindepositSelected : {} as RyPercentpayindeposit);
          setDisableDepositpercent(true);
          setDisableBtnWshTransportSelect(true);

          form.setFieldsValue({
            depositpercentId: ryPercentpayindepositSelected?.id,
            receiptAmt: 0,
          });
          setWorksheetHeadData({
            ...(worksheetHeadData as WorksheetHeadData),
            tworksheetHead: {
              ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
              depositpercentId: ryPercentpayindepositSelected?.id,
            },
          });
        } else if ("01" === status) {
          setDisableReceiptAmt(false);
          const ryPercentpayindepositSelected = (ryPercentpayindeposits as Array<RyPercentpayindeposit>).find((obj) => obj.id === "01");
          setRyPercentpayindepositSelected(ryPercentpayindepositSelected ? ryPercentpayindepositSelected : {} as RyPercentpayindeposit);
          setDisableDepositpercent(true);
          setDisableBtnWshTransportSelect(false);

          form.setFieldsValue({
            depositpercentId: ryPercentpayindepositSelected?.id,
          });
          setWorksheetHeadData({
            ...(worksheetHeadData as WorksheetHeadData),
            tworksheetHead: {
              ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
              depositpercentId: ryPercentpayindepositSelected?.id,
            },
          });
        } else {
          setDisableReceiptAmt(false);
          const ryPercentpayindepositSelected = (ryPercentpayindeposits as Array<RyPercentpayindeposit>).find((obj) => obj.id === "02");
          setRyPercentpayindepositSelected(ryPercentpayindepositSelected ? ryPercentpayindepositSelected : {} as RyPercentpayindeposit);
          setDisableDepositpercent(false);
          setDisableBtnWshTransportSelect(true);

          form.setFieldsValue({
            depositpercentId: ryPercentpayindepositSelected?.id,
          });
          setWorksheetHeadData({
            ...(worksheetHeadData as WorksheetHeadData),
            tworksheetHead: {
              ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
              depositpercentId: ryPercentpayindepositSelected?.id,
            },
          });
        }

        // setRecalculateWsh(true);
        if (statePg !== "ADD") {
          setDisableBtnWshTransportSelect(true);
        }
        break;
      case "ADD":
        break;
    }
  }

  const checkErrorAllDataIsExit = (values: any) => {
    let isValid = false;
    if (!values.icustCode) {
      showNotification(NotificationType.ERROR, "กรุณาระบุชื่อลูกค้า");
      isValid = true;
    } else if (!values.icustAddress) {
      showNotification(NotificationType.ERROR, "กรุณาระบุที่อยู่ลูกค้า");
      isValid = true;
    } else if (!values.itypework) {
      showNotification(NotificationType.ERROR, "ข้อมูลประเภทงานไม่ถูกต้อง");
      isValid = true;
    } else if (!values.itypeworksheetCode) {
      showNotification(NotificationType.ERROR, "ข้อมูลประเภทใบสั่งงานไม่ถูกต้อง");
      isValid = true;
    } else if (!values.workDate) {
      showNotification(NotificationType.ERROR, "วันที่ทำงานไม่ถูกต้อง");
      isValid = true;
    } else if ("02" !== values.itypework && "05" !== values.itypework && !values.itruckCode) {
      showNotification(NotificationType.ERROR, "ข้อมูลรถ/ตู้/อุปกรณ์ไม่ถูกต้อง");
      isValid = true;
    } else if ("02" !== values.itypework && "05" !== values.itypework && checkErrorDriver(values, worksheetHeadData?.tassetHeadView)) {
      showNotification(NotificationType.ERROR, "ข้อมูลพนักงานขับรถไม่ถูกต้อง");
      isValid = true;
    } else if ("02" !== values.itypework && "05" !== values.itypework && ("02" === worksheetHeadData.tassetHeadView?.assetstatus || "03" === worksheetHeadData.tassetHeadView?.assetstatus)) {
      showNotification(NotificationType.ERROR, "สถานะของรถที่เลือก ถูกพักงานหรือ กำลังซ่อมอยู่");
      isValid = true;
    } else if (checkErrorByAssetTypeAndCustomerType(worksheetHeadData.tassetHeadView, worksheetHeadData.tcustomerHeadView, values.itypework)) {
      isValid = true;
    } else if ("02" !== values.itypework && "05" !== values.itypework && checkErrorPromiss(values, worksheetHeadData.tassetHeadView)) {
      // showNotification(NotificationType.ERROR, "ข้อมูลเลขที่สัญญา ไม่ถูกต้อง");
      isValid = true;
    } else if (checkErrorService(values, values.itypework)) {
      // showNotification(NotificationType.ERROR, "ข้อมูลเลขที่สัญญา ไม่ถูกต้อง");
      isValid = true;
    } else if (checkErrorInConsisWorks(values.itypework)) {
      isValid = true;
    } else if (!values.itypevatCode) {
      showNotification(NotificationType.ERROR, "ข้อมูลประเภทภาษี ไม่ถูกต้อง");
      isValid = true;
    } else if (checkErrorVat(values.itypeworksheetCode, values.itypevatCode)) {
      // showNotification(NotificationType.ERROR, "ข้อมูลประเภทภาษี ไม่ถูกต้อง");
      isValid = true;
    }
    // if(!this.ucPurChaseTax.ChkValid()) {
    //     this.ucPurChaseTax.SetFocus();
    //     this.errorProvider.SetError(this.ucPurChaseTax, "ข้อมูลประเภทการหักภาษี ณ ที่จ่าย ไม่ถูกต้อง");
    //     return true;
    // }
    // if(this.CheckErrortax((TypeworksheetData)this.ucTypeSheet.ObjectInfo, this.ucPurChaseTax.PurchaseTaxData)) {
    //     this.ucPurChaseTax.SetFocus();
    //     this.errorProvider.SetError(this.ucPurChaseTax, "ข้อมูลประเภทการหักภาษี ณ ที่จ่าย ไม่ถูกต้อง");
    //     return true;
    // }
    else if (!values.itypepaymentCode) {
      showNotification(NotificationType.ERROR, "กรุณาระบุ ประเภทการชำระเงิน");
      isValid = true;
    } else if (checkErrorPayMentType(worksheetHeadData.tcustomerHeadView, values.itypepaymentCode, values.itypework)) {
      // showNotification(NotificationType.ERROR, "ข้อมูลประเภทการชำระเงิน ไม่ถูกต้อง");
      isValid = true;
    } else if (!values.depositpercentId) {
      showNotification(NotificationType.ERROR, "ข้อมูลประเภทการชำระเงินแบบมัดจำ ไม่ถูกต้อง");
      isValid = true;
    } else if (checkErrorPayMentWithAsset(worksheetHeadData.tassetHeadView, worksheetHeadData.tcustomerHeadView, values.itypepaymentCode, values.itypework, values.promissQty)) {
      isValid = true;
    } else if (checkErrorPaided(values, values.depositpercentId)) {
      // showNotification(NotificationType.ERROR, "จำนวนเงินที่รับชำระไม่ถูกต้อง");
      // this.txtNumCash.Focus();
      // this.txtNumCash.SelectAll();
      isValid = true;
    } else if (checkErrorColleteralPaid(values, worksheetHeadData.tassetHeadView)) {
      isValid = true;
    }
    /******************ใบงานสดก่อนสร้างย้อนหลัง*******************/
    else if (values.itypepaymentCode === "01") {
      if (worksheetHeadData.twshTransportExtraPrice) {
        const workDate = values.workDate[0].format("yyyyMMDD");
        const nowDate = dateToString(moment(new Date()));
        if (workDate < nowDate) {
          showNotification(NotificationType.ERROR, "ไม่สามารถสร้างใบงานสดก่อนเริ่มงานย้อนหลังได้");
          isValid = true;
        }
      }
    } else if ("05" === values.itypework) {
      if (!values.ilocation) {
        // this.txtLocation.Focus();
        showNotification(NotificationType.ERROR, "กรุณาระบุ รายระเอียด/หมายเหตุ ของใบงานซ่อม/ขาย/อื่นๆ ตรงสถานที่");
        isValid = true;
      }
    }
    return isValid;
  }

  const checkErrorDriver = (values: any, tAssetHeadView: any) => {
    if ("001" !== tAssetHeadView.assettype) {
      return false;
    } else {
      if (!values.idriverCode || "0000000000000" === values.idriverCode) {
        return true;
      } else {
        return false;
      }
    }
  }

  const checkErrorByAssetTypeAndCustomerType = (tAssetHeadView: any, tCustomerHeadView: any, typework: string) => {
    let result = false;
    switch (typework) {
      case "00":
        if ("002" === tAssetHeadView.assettype && "00" === tCustomerHeadView.itypecustomerCode) {
          showNotification(NotificationType.ERROR, "การเช่าตู้ต้องเป็นลูกค้าเครดิต เท่านั้น");
          result = true;
        }
        break;
      case "01":
        if ("002" === tAssetHeadView.assettype) {
          showNotification(NotificationType.ERROR, "ไม่อนุญาตให้ทำรายการเหมากับตู้");
          result = true;
        }
        break;
      case "02":
        break;
    }
    return result;
  }

  const checkErrorPromiss = (values: any, tAssetHeadView: any) => {
    if (!("002" === tAssetHeadView.assettype || "003" === tAssetHeadView.assettype)) {
      return false;
    } else {
      if (!values.promissNumber) {
        showNotification(NotificationType.ERROR, "กรุณาระบุเลขที่สัญญา");
        return true;
      } else if (values.promissQty <= 0) {
        showNotification(NotificationType.ERROR, "กรุณาระบุระยะเวลาการเช่า");
        return true;
      }
    }
  }

  const checkErrorService = (values: any, typework: string) => {
    if (("00" === typework && worksheetHeadData.sumServiceLastAmt <= 0) || ("01" === typework && worksheetHeadData.sumServiceLastAmt <= 0)) {
      showNotification(NotificationType.ERROR, "กรุณาระบุรายการบริการก่อน");
      return true;
    } else if (("02" === typework && worksheetHeadData.sumServiceLastAmt > 0)) {
      showNotification(NotificationType.ERROR, "ประเภทใบงานเป็น ใบคุมใบเหมา ไม่ควรระบุรายการบริการ");
      return true;
    }
    return false;
  }

  const checkErrorInConsisWorks = (typework: string) => {
    let worksheetConstructionworkListSize = (worksheetHeadData.tworksheetConstructionworkViewList) ? worksheetHeadData.tworksheetConstructionworkViewList.length : 0;
    if (("00" === typework && worksheetConstructionworkListSize > 0) || ("01" === typework && worksheetConstructionworkListSize > 0)) {
      showNotification(NotificationType.ERROR, "ประเภทงานไม่ถูกต้อง");
      return true;
    } else if (("02" === typework && worksheetConstructionworkListSize <= 0)) {
      showNotification(NotificationType.ERROR, "ประเภทงานไม่ถูกต้อง");
      return true;
    }
    return false;
  }

  const checkErrorVat = (typeworksheetCode: string, typevatCode: string) => {
    let result = false;
    if (("01" === typeworksheetCode && "03" === typevatCode)
      || ("02" === typeworksheetCode && "03" !== typevatCode)) {
      result = true;
      showNotification(NotificationType.ERROR, "ประเภทการชำระภาษีไม่ถูกต้อง");
    }
    return result;
  }

  const checkErrorPayMentType = (tCustomerHeadView: TCustomerHeadView, typepaymentCode: string, typework: string) => {
    let result = false;
    switch (typework) {
      case "00":
        if ("00" === tCustomerHeadView.itypecustomerCode && "04" === typepaymentCode) {
          result = true;
          showNotification(NotificationType.ERROR, "ประเภทการชำระเงินไม่ถูกต้อง");
        }
        break;
      case "01":
        if ("04" !== typepaymentCode) {
          result = true;
          showNotification(NotificationType.ERROR, "ประเภทการชำระเงินไม่ถูกต้อง");
        }
        break;
      case "02":
        if ("00" === tCustomerHeadView.itypecustomerCode && "04" === typepaymentCode) {
          result = true;
          showNotification(NotificationType.ERROR, "ประเภทการชำระเงินไม่ถูกต้อง");
        }
        break;
      case "03":
        if ("00" === tCustomerHeadView.itypecustomerCode && "04" === typepaymentCode) {
          result = true;
          showNotification(NotificationType.ERROR, "ประเภทการชำระเงินไม่ถูกต้อง");
        }
        break;
      case "05":
        if ("00" === tCustomerHeadView.itypecustomerCode && "04" === typepaymentCode) {
          result = true;
          showNotification(NotificationType.ERROR, "ประเภทการชำระเงินไม่ถูกต้อง");
        }
        break;
    }
    return result;
  }

  const checkErrorExistWorksheet = async (statePg: string, values: any) => {
    let isExist = false;
    const checkAddDataRes = await worksheetService.isExistCreateWorksheet(worksheetHeadData.tworksheetHead.istartDate,
      worksheetHeadData.tworksheetHead.istartTime,
      worksheetHeadData.tworksheetHead.iendDate,
      worksheetHeadData.tworksheetHead.iendTime,
      (worksheetHeadData.tassetHeadView?.id) ? worksheetHeadData.tassetHeadView.id : "",
      "");

    if ("02" !== values.itypework && "04" !== values.itypework && "05" !== values.itypework && checkAddDataRes.data) {
      showNotification(NotificationType.ERROR, "รายการรถหรืออุปกรณ์นี้ได้รับการยืนยันว่าใช้แล้ว ในช่วงเวลาที่เลือกไว้" + <br /> + "กรุณาตรวจสอบข้อมูล");
      isExist = true;
    } else if ("02" !== values.itypework && "05" !== values.itypework && checkAddDataRes.data) {
      showNotification(NotificationType.ERROR, "มีการสร้างรายการจองไว้แล้ว" + <br /> + "กรุณาดูที่โปรแกรมยืนยันใบจองหรือโปรแกรมพิมพ์ซ้ำก่อนการบันทึกครั้งต่อไป");
      isExist = true;
    }

    if ("UPD" === statePg) {
      const checkEditDataRes = await worksheetService.isExistCreateWorksheet(worksheetHeadData.tworksheetHead.istartDate,
        worksheetHeadData.tworksheetHead.istartTime,
        worksheetHeadData.tworksheetHead.iendDate,
        worksheetHeadData.tworksheetHead.iendTime,
        (worksheetHeadData.tassetHeadView?.id) ? worksheetHeadData.tassetHeadView.id : "",
        ("UPD" === statePg) ? worksheetHeadData.tworksheetHead.ireserveCode : "");
      if ("02" !== values.itypework && "05" !== values.itypework && checkEditDataRes.data) {
        showNotification(NotificationType.ERROR, "มีการสร้างรายการจองไว้แล้ว" + <br /> + "กรุณาดูที่โปรแกรมยืนยันใบจองหรือโปรแกรมพิมพ์ซ้ำก่อนการบันทึกครั้งต่อไป");
        isExist = true;
      }
    }
    return isExist;
  }

  const checkErrorTruckRepair = async (values: any) => {
    let isRepair = false;
    if ("02" !== values.itypework && "05" !== values.itypework && ("02" === worksheetHeadData.tassetHeadView?.assetstatus || "03" === worksheetHeadData.tassetHeadView?.assetstatus)) {
      const checkTruckIsRepairRes = await tTruckRepairService.getByTruck2Day(worksheetHeadData.tassetHeadView?.id, worksheetHeadData.tworksheetHead.istartDate, worksheetHeadData.tworksheetHead.iendDate);
      if (checkTruckIsRepairRes.data.length > 0) {
        showNotification(NotificationType.ERROR, "รถอยู่ในสถานะซ่อมตั้งแต่ " + toThDate(checkTruckIsRepairRes.data[0].istartRepair) + " ถึง " + toThDate(checkTruckIsRepairRes.data[0].iendRepair) + "ไม่สามารถใช้งานได้");
        isRepair = true;
      }
    }
    return isRepair;
  }

  const checkAlreadyConfirm = async (statePg: string, values: any) => {
    let result = false;
    if ("UPD" === statePg) {
      const reserveCode = worksheetHeadData.tworksheetHead?.ireserveCode;
      const chkWshDataRes = await tWorksheetHeadService.getByReserveCode(reserveCode ? reserveCode : "");
      if (chkWshDataRes.data && "00" !== chkWshDataRes.data.iworksheetStatus) {
        showNotification(NotificationType.ERROR, "ใบงานนี้มีการยืนยันการทำงานแล้ว");
        result = true;
      }
    }
    return result;
  }

  const checkErrorPayMentWithAsset = (tAssetHeadView: any, tCustomerHeadView: any, typepaymentCode: string, typework: string, promissQty: number) => {
    if ("02" !== typework && "05" !== typework) {
      switch (typepaymentCode) {
        case "02":
          if ("002" === tAssetHeadView.assettype) {
            if (promissQty > 1) {
              showNotification(NotificationType.ERROR, "ประเภทการชำระเงินไม่ถูกต้อง");
              // this.ucCustomer.setFocus();
              return true;
            }
          }
          break;
        case "04":
          if ("002" !== tAssetHeadView.assettype) {
            if (("003" === tAssetHeadView.assettype) && ("00" === tCustomerHeadView.itypecustomerCode)) {
              showNotification(NotificationType.ERROR, "ประเภทการชำระเงินไม่ถูกต้อง");
              // this.ucCustomer.setFocus();
              return true;
            }
          } else {
            showNotification(NotificationType.ERROR, "ประเภทการชำระเงินไม่ถูกต้อง");
            // this.ucCustomer.setFocus();
            return true;
          }
          break;
      }
    }
    return false;
  }

  const checkErrorPaided = (values: any, depositpercentId: string) => {
    let result = false;
    if (("00" === depositpercentId || "01" === depositpercentId || "02" === depositpercentId || "03" === depositpercentId || "05" === depositpercentId) &&
      (parserNumber(values.receiptAmt) !== values.amtToPaid)) {
      result = true;
      showNotification(NotificationType.ERROR, "จำนวนเงินที่รับชำระไม่ถูกต้อง");
      return true;
    } else if ("04" === depositpercentId && parserNumber(values.receiptAmt) <= values.amtToPaid) {
      result = true;
      showNotification(NotificationType.ERROR, "จำนวนเงินที่รับชำระไม่ถูกต้อง");
      return true;
    }
    return result;
  }

  const checkErrorColleteralPaid = (values: any, tAssetHeadView: any) => {
    let result = false;
    let typework = values.itypework;
    if ("02" === typework || "05" === typework) {
      result = false;
    } else if ("002" !== tAssetHeadView.assettype && "003" !== tAssetHeadView.assettype) {
      result = false;
    } else {
      if (!worksheetHeadData.tworksheetInsurance) {
        showNotification(NotificationType.ERROR, "กรุณาระบุจำนวนเงิน ประกันอุปกรณ์");
        result = true;
      } else if (worksheetHeadData.tworksheetInsurance.itypePaymentInsurance !== "99") {
        if (values.insuranceAmt <= 0) {
          showNotification(NotificationType.ERROR, "กรุณาระบุจำนวนเงิน ประกันอุปกรณ์");
          result = true;
        }
      }
    }
    return result;
  }

  const onFinish = async (values: any) => {
    if (checkErrorAllDataIsExit(values)) {
      setSubmit(false);
    } else if (await checkErrorExistWorksheet(statePg, values)) {
      setSubmit(false);
    } else if (await checkErrorTruckRepair(values)) {
      setSubmit(false);
    } else if (await checkAlreadyConfirm(statePg, values)) {
      setSubmit(false);
    } else {
      try {
        //set ค่าเพิ่มกรณเป็นช่องข้อมูลที่ไม่มีการ set ค่าที่ไหนเลย

        const result: WorksheetHeadData = {
          ...(worksheetHeadData as WorksheetHeadData),
          tworksheetHead: {
            ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
            itypework: values.itypework,
            typeworksheetflag: values.itypework,
            itypeworksheetCode: values.itypeworksheetCode,
            allinstalment: values.promissQty,
            idepositAmt: parserNumber(values.receiptAmt),
            flgDisplaydetail: values.flgDisplayDetail ? 1 : 0,
            iflgWaitforcall: values.flgWaitForCall ? 1 : 0,
            iflgWshOt: values.flgWshOt ? 1 : 0,
            ilocation: values.ilocation,
            icontactName: values.icontactName,
            icontactPhone: values.icontactPhone,
            itypepaymentCode: values.itypepaymentCode,
            itypetaxCode: values.itypevatCode,//set ซำ้เพราะมันชอบไม่ set ค่าให้ตอน save
            witholdingrateId: "00",//อันเก่ามีให้เลือก อันใหม่ไม่มี เลย default เป็น 00 = ไม่มีหัก ณ ที่จ่าย
            ibranch: userInfo.branchId,
            isavStation: userInfo.saveStation,
            icreateUser: userInfo.userId,
            ilastUser: userInfo.userId,
            itermNo: userInfo.termNo
          },
          twshPromiss: {
            ...(worksheetHeadData.twshPromiss as TWshPromiss),
            promissnumber: values.promissNumber,
            instalment: values.promissQty,
          },
          tworksheetMovement: {
            ...(worksheetHeadData.tworksheetMovement as TWorksheetMovement),
            iuserinfoId: userInfo.userId,
            iuserinfoName: userInfo.fullName,
          },
          userInfo: userInfo,
        };

        await worksheetService.createUpdateWorksheet(result, statePg);
        showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเรียบร้อยแล้ว !!!");
        setOnDrawerClose(false);
        setSubmit(false);
      } catch (err) {
        showNotification(NotificationType.ERROR, "เกิดข้อผิดพลาด กรุณาติดต่อผู้แลระบบ !");
        setSubmit(false);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "เกิดข้อผิดพลาด กรุณาติดต่อผู้แลระบบ !");
    setSubmit(false);
  };

  function onchangeTab(key: any) {
    setActiveTab(key);
  }

  const setTitle = () => {
    let title = "สร้างใบจอง"
    if (statePg === "UPD") {
      title = "แก้ไขใบจอง : " + selectedReserveCode;
    }
    return title;
  }

  return (
    <>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Typography.Title level={4}>{setTitle()}</Typography.Title>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Button
                type="primary"
                danger
                icon={<CloseCircleOutlined />}
                onClick={() => setOnDrawerClose(false)}>
                ยกเลิก
              </Button>
              <Button
                // onClick={() => setSubmit(true)}
                onClick={() => {
                  confirm({
                    title: 'ต้องการบันทึกข้อมูลใบจองหรือไม่ ?',
                    icon: <ExclamationCircleOutlined />,
                    onOk() {
                      setSubmit(true);
                    },
                    onCancel() {
                      // do nothing, just close
                    },
                  });
                }}
                type="primary"
                icon={<SaveOutlined />}
                style={{ marginLeft: "5px" }}
              >
                บันทึก
              </Button>
            </div>
          </div>
        }
        width="90%"
        visible={onDrawerClose}
        forceRender={true}
        placement="right"
        bodyStyle={{ paddingBottom: 80 }}
        closable={false}
        destroyOnClose={true}
      >
        <Spin spinning={showSpin}>
          <Form form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row gutter={5}>
              <Col span={16}>
                <Collapse
                  defaultActiveKey={["1", "2", "3"]}
                  bordered={true}
                  expandIcon={() => <PlusSquareFilled />}
                  style={{ backgroundColor: "#235A97" }}
                  className="my-collapse-header"
                >
                  <Panel header="ลูกค้า - ลูกหนี้" key="1" showArrow>
                    <Col span={24}>
                      <Row>
                        <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>ลูกค้า/ลูกหนี้: </Typography.Text>
                        </Col>
                        <Col span={20}>
                          <Space align='baseline' style={{ width: "80%" }}>
                            <Form.Item
                              name="icustCode"
                              style={{ margin: "0 0 3px 0px" }}
                            >
                              <Select
                                showSearch
                                allowClear
                                style={{ width: 571 }}
                                size="middle"
                                placeholder="เลือกลูกค้า"
                                optionFilterProp="children"
                                filterOption={false}
                                onSearch={handleSearchCustomer}
                                onSelect={handleChangeCustomer}
                                onClear={handleClearCustomer}
                              >
                                {tCustomerHeadViews.map((obj) => {
                                  return (
                                    <Option key={obj.icustCode} value={obj.icustCode}>
                                      {obj.icustCode}: {obj.iprenameName}{obj.iname}   {obj.isurname ? obj.isurname : ""}: {obj.iphone}<br />
                                      <span style={{ marginLeft: "1rem", fontSize: 12 }}>
                                        {obj.iaddress}{'   '}อำเภอ/เขต{'   '}{obj.iamphurName}{'   '}จังหวัด{'   '}{obj.iprovinceName}{'   '}{obj.ipostcode}
                                      </span>
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                            <Button
                              size="small"
                              type="primary"
                              onClick={() => setVisibleModalAddCust(true)}
                              icon={<PlusSquareOutlined />}
                            >
                              เพิ่ม
                            </Button>
                            <CopyOutlined 
                              onClick={() =>  {
                                const fullName = `${worksheetHeadData.tcustomerHeadView?.iprenameName.trim()}  ${worksheetHeadData.tcustomerHeadView?.iname} ${(worksheetHeadData.tcustomerHeadView?.isurname) ? worksheetHeadData.tcustomerHeadView?.isurname : ""}`;
                                navigator.clipboard.writeText(fullName);
                                showNotification(NotificationType.SUCCESS, "คัดลอกแล้ว");
                              }}
                            />
                          </Space>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>ที่อยู่: </Typography.Text>
                        </Col>
                        <Col span={20}>
                          <Form.Item
                            name="icustAddress"
                            style={{ margin: "0 0 3px 0px" }}
                          >
                            <Input></Input>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>โทรศัพท์: </Typography.Text>
                        </Col>
                        <Col span={10}>
                          <Form.Item
                            name="icustPhone"
                            style={{ marginBottom: "2px" }}
                          >
                            <Input
                            ></Input>
                          </Form.Item>
                        </Col>
                        <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>ประเภท: </Typography.Text>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="itypecustomerName"
                            style={{ marginBottom: "2px" }}
                          >
                            <Input></Input>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>วงเงิน: </Typography.Text>
                        </Col>
                        <Col span={7} >
                          <Form.Item
                            name="icreditamount"
                            style={{ marginBottom: "2px" }}
                          >
                            <InputNumber
                              controls={false}
                              formatter={(value) => formatNumber(value)}
                              parser={(value) => parserNumber(value)}
                              readOnly={true}
                              style={{
                                textAlign: "end",
                                backgroundColor: "#F8FC9D",
                                width: "auto",
                              }}
                            ></InputNumber>
                          </Form.Item>
                        </Col>
                        <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>ค้างชำระ: </Typography.Text>
                        </Col>
                        <Col span={5}>
                          <Form.Item
                            name="currentusage"
                            style={{ marginBottom: "2px" }}
                          >
                            <InputNumber
                              controls={false}
                              formatter={(value) => formatNumber(value)}
                              parser={(value) => parserNumber(value)}
                              readOnly={true}
                              style={{
                                textAlign: "end",
                                color: "red",
                                backgroundColor: "#F8FC9D",
                                width: "auto",
                              }}
                            ></InputNumber>
                          </Form.Item>
                        </Col>
                        <Col span={5} style={{ marginLeft: 20 }}>
                          <Space align='baseline'>
                            <Form.Item
                              name="creditdate"
                            >
                              <InputNumber
                                controls={false}
                                parser={(value) => parserNumber(value)}
                                style={{
                                  textAlign: "end",
                                  color: "red",
                                  backgroundColor: "#F8FC9D",
                                  width: "auto",
                                }}
                              ></InputNumber>
                            </Form.Item>
                            วัน
                          </Space>
                        </Col>
                      </Row>
                    </Col>
                  </Panel>
                  <Panel header="ข้อมูลทั่วไป" key="2">
                    <Col span={24}>
                      <Row>
                        <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>งาน: </Typography.Text>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="itypework"
                            style={{ margin: "0 0 3px 0px" }}
                          >
                            <Select
                              showSearch
                              size="middle"
                              placeholder="เลือกงาน"
                              optionFilterProp="children"
                              filterOption={true}
                              onSelect={handleChangeWorkType}
                            >
                              {ryReftypeworks.map((obj) => {
                                return (
                                  <Option key={obj.id} value={obj.id}>{obj.id}: {obj.name}</Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>ใบงาน: </Typography.Text>
                        </Col>
                        <Col span={9}>
                          <Form.Item
                            name="itypeworksheetCode"
                            style={{
                              marginBottom: "1px",
                            }}
                          >
                            <Select
                              disabled={disableTypeworksheet}
                              showSearch
                              size="middle"
                              placeholder="เลือกใบงาน"
                              optionFilterProp="children"
                              filterOption={true}
                              onSelect={handleChangeWorksheetType}
                            >
                              {tTypeworksheetRefs.map((obj) => {
                                return (
                                  <Option key={obj.iid} value={obj.iid}>{obj.iid}: {obj.iname}</Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>ระหว่างวันที่: </Typography.Text>
                        </Col>
                        <Col span={20}>
                          <Form.Item
                            name="workDate"
                            style={{ marginBottom: "3px" }}
                          >
                            <RangePicker
                              showTime={{ format: "HH:mm:00" }}
                              allowClear
                              placement="topRight"
                              format={Constants.DATE_FORMAT_SKIP_SEC}
                              style={{ width: "100%" }}
                              onChange={onchangedate}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>สถานที่: </Typography.Text>
                        </Col>
                        <Col span={20}>
                          <Form.Item
                            name="ilocation"
                            style={{ marginBottom: "3px" }}
                          >
                            <Input></Input>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>ติดต่อคุณ: </Typography.Text>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="icontactName"
                            style={{ marginBottom: "2px" }}
                          >
                            <Input></Input>
                          </Form.Item>

                        </Col>
                        <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>โทร: </Typography.Text>
                        </Col>
                        <Col span={9}>
                          <Form.Item
                            name="icontactPhone"
                            style={{
                              marginBottom: "1px",
                            }}
                          >
                            <Input></Input>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Panel>
                  <Panel header="ข้อมูลเกี่ยวกับการบริการ" key="3">
                    <Col span={24}>
                      <Row>
                        <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>รถ/ตู้/แผ่นเหล็ก: </Typography.Text>
                        </Col>
                        <Col span={20}>
                          <Form.Item
                            name="itruckCode"
                            style={{ marginBottom: "3px" }}
                          >
                            <Select
                              disabled={disableAsset}
                              showSearch
                              allowClear
                              size="middle"
                              placeholder="เลือกรถ/ตู้/แผ่นเหล็ก"
                              optionFilterProp="children"
                              filterOption={true}
                              onSearch={handleSearchAsset}
                              onSelect={handleChangeAsset}
                            >
                              {tAssetHeadViews.map((obj) => {
                                return (
                                  <Option key={obj.id} value={obj.id}>
                                    {obj.id}: {obj.assetFullName}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>พนักงานขับรถ: </Typography.Text>
                        </Col>
                        <Col span={20}>
                          <Form.Item
                            name="idriverCode"
                            style={{ marginBottom: "3px" }}
                          >
                            <Select
                              disabled={disableDriver}
                              showSearch
                              allowClear
                              size="middle"
                              placeholder="เลือกพนักงานขับรถ"
                              optionFilterProp="children"
                              filterOption={true}
                              onSearch={handleSearchDriver}
                              onSelect={handleChangeDriver}
                            >
                              {drivers.map((obj) => {
                                return (
                                  <Option
                                    key={obj.id} value={obj.id}
                                  >
                                    {obj.empname}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>เลขที่สัญญา: </Typography.Text>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="promissNumber"
                            style={{ marginBottom: "2px" }}
                          >
                            <Input
                              disabled={disablePromissNumber}
                              // readOnly={false}
                              style={{
                                backgroundColor: "#F8FC9D",
                              }}
                            ></Input>
                          </Form.Item>
                        </Col>
                        <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>จำนวน: </Typography.Text>
                        </Col>
                        <Col span={9}>
                          <Space align='baseline'>
                            <Form.Item
                              name="promissQty"
                            >
                              <InputNumber
                                disabled={disablePromissQty}
                                type="number"
                                controls={false}
                                onFocus={(e) => e.target.select()}
                              ></InputNumber>
                            </Form.Item>
                            เดือน/วัน
                          </Space>
                        </Col>
                      </Row>

                      <Tabs
                        activeKey={activeTab}
                        onTabClick={onchangeTab}
                      //   onChange={(activeKey) => {
                      //   setActiveTab(activeKey);

                      //  }}
                      //   onTabClick={(activeKey) => {
                      //   setActiveTab(activeKey);

                      //  }}
                      >
                        <TabPane id="serviceDetailTab" tab="รายการบริการ" key="serviceDetailTab" disabled={disableServiceDetailTab}>
                          {/* <TabPane tab="รายการบริการ" key="serviceDetailTab"> */}
                          <ServiceDetailTab
                            worksheetHeadData={worksheetHeadData}
                            setWorksheetHeadData={setWorksheetHeadData}
                            assetSelected={tAssetHeadViewSelected}
                            setRecalculateWsh={setRecalculateWsh}
                            wshDetailFormDataList={wshDetailFormDataList}
                            setWshDetailFormDataList={setWshDetailFormDataList}
                          ></ServiceDetailTab>
                        </TabPane>
                        {/* ใช้กรณีเป็นใบงานเหมา[ใบคุมงานใบย่อย] TypeWorkSheetFlag == "02" */}
                        <TabPane id="contractorWorkDetailTab" tab="รายการใบสั่งงานของงงานเหมา" key="contractorWorkDetailTab" disabled={disableContractorWorkDetailTab}>
                          <ContractorWorkDetailTab
                            worksheetHeadData={worksheetHeadData}
                            setWorksheetHeadData={setWorksheetHeadData}
                            twshConstructionworkFormDataList={twshConstructionworkFormDataList}
                            setTwshConstructionworkFormDataList={setTwshConstructionworkFormDataList}
                            recalculateContractorWork={recalculateContractorWork}
                            setRecalculateContractorWork={setRecalculateContractorWork}
                          ></ContractorWorkDetailTab>
                        </TabPane>
                        {/* ใช้กรณีเป็นใบงานซ่อม/ขาย/อื่นๆ TypeWorkSheetFlag == "05" */}
                        <TabPane id="otherServiceTab" tab="รายการซ่อม/ขาย/อื่นๆ" key="otherServiceTab" disabled={disableOtherServiceTab}>
                          <WorksheetOtherserviceTab
                            worksheetHeadData={worksheetHeadData}
                            setWorksheetHeadData={setWorksheetHeadData}
                            tWorksheetOtherserviceFormDataList={tWorksheetOtherserviceFormDataList}
                            setTWorksheetOtherserviceFormDataList={setTWorksheetOtherserviceFormDataList}
                            recalculateOtherService={recalculateOtherService}
                            setRecalculateOtherService={setRecalculateOtherService}
                          ></WorksheetOtherserviceTab>
                        </TabPane>
                        <TabPane id="workerDetailTab" tab="รายการพนักงานติดรถ" key="workerDetailTab" disabled={disableWorkerDetailTab}>
                          <WorkerDetailTab
                            worksheetHeadData={worksheetHeadData}
                            setWorksheetHeadData={setWorksheetHeadData}
                            tworksheetWorkerFormDataList={tworksheetWorkerFormDataList}
                            setTworksheetWorkerFormDataList={setTworksheetWorkerFormDataList}
                          ></WorkerDetailTab>
                          {/* tworksheetWorkerFormDataList */}
                        </TabPane>
                      </Tabs>
                    </Col>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={8}>
                <Collapse
                  defaultActiveKey={["4"]}
                  bordered={true}
                  expandIcon={() => <PlusSquareFilled />}
                  style={{ backgroundColor: "#235A97" }}
                  className="my-collapse-header"
                >
                  <Panel
                    header="รายการการเงิน"
                    key="4"
                    showArrow
                    disabled={false}
                  >
                    <Col span={24}>
                      <Row>
                        <Col span={8} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>ค่าบริการ: </Typography.Text>
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            name="iserviceAmt"
                            style={{ marginBottom: "2px" }}
                          >
                            <InputNumber
                              controls={false}
                              formatter={(value) => formatNumber(value)}
                              parser={(value) => parserNumber(value)}
                              style={{
                                textAlign: "end",
                                backgroundColor: "#F8FC9D",
                                color: "#000",
                                width: "100%"
                              }}
                            ></InputNumber>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>ค่าบริการพิเศษอื่นๆ: </Typography.Text>
                        </Col>
                        <Col span={14}>
                          <Form.Item
                            name="iextraAmt"
                            style={{ marginBottom: "2px" }}
                          >
                            <InputNumber
                              controls={false}
                              readOnly={true}
                              formatter={(value) => formatNumber(value)}
                              parser={(value) => parserNumber(value)}
                              style={{
                                textAlign: "end",
                                backgroundColor: "#F8FC9D",
                                color: "#000",
                                width: "100%"
                              }}
                            ></InputNumber>
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Button
                            style={{ margin: "2px 0 0 5px" }}
                            size="small"
                            type="primary"
                            icon={<PlusSquareOutlined />}
                            onClick={() => setVisibleModalExtraPrice(true)}
                          ></Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>OT: </Typography.Text>
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            name="iotAmt"
                            style={{ marginBottom: "2px" }}
                          >
                            <InputNumber
                              controls={false}
                              formatter={(value) => formatNumber(value)}
                              parser={(value) => parserNumber(value)}
                              readOnly={true}
                              style={{
                                textAlign: "end",
                                backgroundColor: "#F8FC9D",
                                color: "#000",
                                width: "100%"
                              }}
                            ></InputNumber>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>ส่วนลด: </Typography.Text>
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            name="idiscount"
                            style={{ marginBottom: "2px" }}
                          >
                            <InputNumber
                              controls={false}
                              formatter={(value) => formatNumber(value)}
                              parser={(value) => parserNumber(value)}
                              style={{
                                textAlign: "end",
                                backgroundColor: "#F8FC9D",
                                color: "#000",
                                width: "100%"
                              }}
                            ></InputNumber>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>รวม: </Typography.Text>
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            name="netServiceAmt"
                            style={{ marginBottom: "2px" }}
                          >
                            <InputNumber
                              controls={false}
                              formatter={(value) => formatNumber(value)}
                              parser={(value) => parserNumber(value)}
                              style={{
                                textAlign: "end",
                                backgroundColor: "#F8FC9D",
                                color: "#000",
                                width: "100%"
                              }}
                            ></InputNumber>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>ค่าบริการก่อนภาษี: </Typography.Text>
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            name="total1Amt"
                            style={{ marginBottom: "2px" }}
                          >
                            <InputNumber
                              controls={false}
                              formatter={(value) => formatNumber(value)}
                              parser={(value) => parserNumber(value)}
                              style={{
                                textAlign: "end",
                                backgroundColor: "#F8FC9D",
                                color: "#000",
                                width: "100%"
                              }}
                            ></InputNumber>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>ภาษี: </Typography.Text>
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            name="itypevatCode"
                            style={{ marginBottom: "2px" }}
                          >
                            <Select
                              disabled={disableTypevat}
                              showSearch
                              size="middle"
                              placeholder="เลือกประเภทภาษี"
                              optionFilterProp="children"
                              filterOption={true}
                              onChange={onChangeVatType}
                            >
                              {tTypetaxRefs.map((obj) => {
                                return (
                                  <Option key={obj.iid} value={obj.iid}>
                                    {obj.iid}: {obj.iname}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}></Col>
                        <Col span={16}>
                          <Form.Item
                            name="vatAmt"
                            colon={false}
                            style={{ marginBottom: "2px" }}
                          >
                            <InputNumber
                              controls={false}
                              formatter={(value) => formatNumber(value)}
                              parser={(value) => parserNumber(value)}
                              style={{
                                textAlign: "end",
                                backgroundColor: "#F8FC9D",
                                color: "#000",
                                width: "100%"
                              }}
                            ></InputNumber>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>รวมทั้งหมด: </Typography.Text>
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            name="total2Amt"
                            style={{ marginBottom: "2px" }}
                          >
                            <InputNumber
                              controls={false}
                              formatter={(value) => formatNumber(value)}
                              parser={(value) => parserNumber(value)}
                              style={{
                                textAlign: "end",
                                backgroundColor: "#F8FC9D",
                                color: "#000",
                                width: "100%"
                              }}
                            ></InputNumber>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>ชำระเงินแบบ: </Typography.Text>
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            name="itypepaymentCode"
                            style={{ marginBottom: "2px" }}
                          >
                            <Select
                              disabled={disableTypepayment}
                              showSearch
                              size="middle"
                              placeholder="เลือกชำระเงินแบบ"
                              optionFilterProp="children"
                              filterOption={true}
                              onSelect={onChangePaymentType}
                            >
                              {tTypepaymentRefs.map((obj) => {
                                return (
                                  <Option key={obj.iid} value={obj.iid}>
                                    {obj.iid}: {obj.iname}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>จำนวนเงินที่ชำระ: </Typography.Text>
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            name="depositpercentId"
                            style={{ marginBottom: "2px" }}
                          >
                            <Select
                              disabled={disableDepositpercent}
                              showSearch
                              size="middle"
                              placeholder="เลือกจำนวนเงินที่ชำระ"
                              optionFilterProp="children"
                              filterOption={true}
                              onSelect={onChangePercentDepositType}
                            >
                              {ryPercentpayindeposits.map((obj) => {
                                return (
                                  <Option key={obj.id} value={obj.id}>
                                    {obj.id}: {obj.remark} {obj.value} %
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}></Col>
                        <Col span={16}>
                          <Form.Item
                            name="amtToPaid"
                            colon={false}
                            style={{ marginBottom: "2px" }}
                          >
                            <InputNumber
                              controls={false}
                              formatter={(value) => formatNumber(value)}
                              parser={(value) => parserNumber(value)}
                              style={{
                                textAlign: "end",
                                backgroundColor: "#F8FC9D",
                                color: "#000",
                                width: "100%"
                              }}
                              readOnly={true}
                            ></InputNumber>
                          </Form.Item>
                        </Col>
                      </Row>
                      {/* row นี้ ใช้กรณีเป็นใบงานในเท่านั้น */}
                      <Row>
                        <Col span={8} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>รับชำระ: </Typography.Text>
                        </Col>
                        <Col span={14}>
                          <Form.Item
                            name="receiptAmt"
                            style={{ marginBottom: "2px" }}
                          >
                            <Input
                              type="text"
                              // controls={false}
                              // pattern="^[0-9]+$"
                              // precision={2}
                              // decimalSeparator={"."}
                              // formatter={(value) => formatNumber(value)}
                              // parser={(value) => parserNumber(value)}
                              disabled={disableReceiptAmt}
                              style={{
                                textAlign: "end",
                                // backgroundColor: "#F8FC9D",
                                color: "#000",
                                width: "100%"
                              }}
                              // onChange={onChangeReceiptAmt}
                              // onKeyDown={(event) => {if (!/^[0-9\.\,]+$/.test(event.key)) {event.preventDefault();}}}
                              onKeyDown={(event: any) => textNumber(event)}
                              onChange={onCalculateRemain}
                              // onChange={()=> setRecalculateWsh(true)}
                              onFocus={(e: any) => { e.target.select() }}
                              onBlur={(e: any) => { form.setFieldsValue({ receiptAmt: formatNumber(parserNumber(e.target.value)) }) }}
                              onPressEnter={(e: any) => { form.setFieldsValue({ receiptAmt: formatNumber(parserNumber(e.target.value)) }) }}
                            ></Input>
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Button
                            style={{ margin: "2px 0 0 5px" }}
                            size="small"
                            type="primary"
                            disabled={disableBtnWshTransportSelect}
                            icon={<PlusSquareOutlined />}>
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>ค้างชำระ: </Typography.Text>
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            name="remainAmt"
                            style={{ marginBottom: "2px" }}
                          >
                            <InputNumber
                              controls={false}
                              formatter={(value) => formatNumber(value)}
                              parser={(value) => parserNumber(value)}
                              readOnly={true}
                              style={{
                                textAlign: "end",
                                backgroundColor: "#F8FC9D",
                                color: "#000",
                                width: "100%"
                              }}
                            ></InputNumber>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8} style={{ textAlign: "right", padding: "0.5em" }}>
                          <Typography.Text>จำนวนเงินประกัน: </Typography.Text>
                        </Col>
                        <Col span={14}>
                          <Form.Item
                            name="iinsuranceAmt"
                            style={{ marginBottom: "2px" }}
                          >
                            <InputNumber
                              controls={false}
                              formatter={(value) => formatNumber(value)}
                              parser={(value) => parserNumber(value)}
                              readOnly={true}
                              style={{
                                // textAlign: "end",
                                backgroundColor: "#F8FC9D",
                                color: "#000",
                                width: "100%"
                              }}
                            ></InputNumber>
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Button
                            style={{ margin: "2px 0 0 5px" }}
                            size="small"
                            type="primary"
                            icon={<PlusSquareOutlined />}
                            onClick={() => setVisibleInsurance(true)}
                          ></Button>
                        </Col>
                      </Row>
                      <Divider style={{ marginTop: "2em" }} />
                      <Row>
                        <Col span={8}></Col>
                        <Col span={16}>
                          <Form.Item
                            name="flgDisplayDetail"
                            valuePropName="checked"
                            style={{ marginBottom: "2px" }}
                          >
                            <Checkbox
                            >
                              แสดงรายละเอียดค่าใช้บริการ
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}></Col>
                        <Col span={16}>
                          <Form.Item
                            name="flgWaitForCall"
                            valuePropName="checked"
                            style={{ marginBottom: "2px" }}
                          >
                            <Checkbox>
                              รอลูกค้ายืนยัน
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8}></Col>
                        <Col span={16}>
                          <Form.Item
                            name="flgWshOt"
                            valuePropName="checked"
                            style={{ marginBottom: "2px" }}
                          >
                            <Checkbox
                            // onChange={(e) => {
                            //   setWorksheetHeadData({
                            //     ...(worksheetHeadData as WorksheetHeadData),
                            //     tworksheetHead: {
                            //       ...(worksheetHeadData.tWorksheetHead as TWorksheetHead),
                            //       iflgWshOt: Number(e.target.checked),
                            //     },
                            //   });
                            // }}
                            >
                              ใบงานโอทีอย่างเดียว
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Drawer>
      <div>
        {visibleModalAddCust && (
          <AddCustomerModal
            visibleModalAddCust={visibleModalAddCust}
            setVisibleModalAddCust={setVisibleModalAddCust}
            setNewCustomer={setNewCustomer}
          ></AddCustomerModal>
        )}
      </div>
      <div>
        {visibleModalExtraPrice && (
          <AddExtraPriceModal
            visibleModalExtraPrice={visibleModalExtraPrice}
            setVisibleModalExtraPrice={setVisibleModalExtraPrice}
            tWorksheetExtrapriceList={tWorksheetExtrapriceList}
            setTWorksheetExtrapriceList={setTWorksheetExtrapriceList}
          ></AddExtraPriceModal>
        )}
      </div>
      <div>
        {visibleInsurance && (
          <AddInsuranceModal
            custName={(worksheetHeadData.tworksheetHead.icustName) ? worksheetHeadData.tworksheetHead.icustName : ""}
            visibleInsurance={visibleInsurance}
            setVisibleInsurance={setVisibleInsurance}
            tWorksheetInsurance={selectedTWorksheetInsurance}
            setTWorksheetInsurance={setSelectedTWorksheetInsurance}
          ></AddInsuranceModal>
        )}
      </div>
    </>
  );
};

export default ChartNewWorksheet;