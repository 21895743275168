import http from "src/lib/http";
import Constants from "src/utils/constants";

const getMenuByGroupPgCode = (groupPgCode: string) => {
  return http.get(
    Constants.DEFAULT_URL + "/tGroupPgDetail/getMenuByGroupPgCode",
    {
      params: {
        groupPgCode,
      },
    }
  );
};

const defaultApi = {
  getMenuByGroupPgCode,
};
export default defaultApi;
