import { Card, Checkbox, Col, DatePicker, Form, Input, Modal, Row, Space, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { TAssetHeadView } from 'src/domain/master/view/tAssetHeadView';
import { TServiceHeadView } from 'src/domain/master/view/tServiceHeadView';
import { TTypetaxRef } from 'src/domain/tTypetaxRef';
import { WorksheetHeadData } from 'src/domain/worksheet/view/worksheetHeadData';
import tServiceHeadService from 'src/services/tservice/tServiceHeadService';
import Constants from 'src/utils/constants';
import { toMomentDateTime } from 'src/utils/dateTimeUtils';
import { formatNumber } from 'src/utils/numberUtils';

interface IProps {
  viewWorksheetOTModalVisible: boolean;
  setViewWorksheetOTModalVisible: any;
  worksheetHeadData: WorksheetHeadData;
  setWorksheetHeadData: any;
  asset: TAssetHeadView;
  vatType: TTypetaxRef;
}

const ViewWorksheetOTModal: React.FC<IProps> = (props) => {
  const { viewWorksheetOTModalVisible, setViewWorksheetOTModalVisible, worksheetHeadData, setWorksheetHeadData, asset, vatType } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    if (viewWorksheetOTModalVisible) {
      (async () => {
        let tServiceHead = {} as TServiceHeadView;
        if (worksheetHeadData.tworksheetDetailList.length) {
          const tServiceHeadRes = await tServiceHeadService.getTServiceHeadByCode(worksheetHeadData.tworksheetDetailList[0].iserviceCode);
          tServiceHead = tServiceHeadRes.data;
        }

        const start = toMomentDateTime(worksheetHeadData.tworksheetHead.istartDate ? worksheetHeadData.tworksheetHead.istartDate : "",
          worksheetHeadData.tworksheetHead.istartTime ? worksheetHeadData.tworksheetHead.istartTime : "");
        const end = toMomentDateTime(worksheetHeadData.tworksheetHead.iendDate ? worksheetHeadData.tworksheetHead.iendDate : "",
          worksheetHeadData.tworksheetHead.iendTime ? worksheetHeadData.tworksheetHead.iendTime : "");
        const startTimeHour = moment(start).format("HH");

        form.setFieldsValue({
          worksheetCode: worksheetHeadData.tworksheetHead.iworksheetCode,
          custName: "(" + worksheetHeadData.tworksheetHead.icustCode + ") " + worksheetHeadData.tworksheetHead.icustName,
          truckName: "(" + asset.id + ") " + asset.assetFullName,
          serviceName: (worksheetHeadData.tworksheetDetailList) ? "(" + worksheetHeadData.tworksheetDetailList[0].iserviceCode + ") " + worksheetHeadData.tworksheetDetailList[0].iserviceName : "",
          serviceAmt: formatNumber(worksheetHeadData.tworksheetHead.iserviceAmt),
          unitName: (worksheetHeadData.tworksheetDetailList) ? worksheetHeadData.tworksheetDetailList[0].itypeunitName : "",
          driverName: "(" + worksheetHeadData.tworksheetHead.idriverCode + ") " + worksheetHeadData.tworksheetHead.idriverName,
          startDate: start,
          endDate: end,
          basicServiceAmt: formatNumber(worksheetHeadData.tworksheetHead.iserviceAmt),
          extraAmt: formatNumber(worksheetHeadData.tworksheetHead.iextraAmt),
          otPerHour: formatNumber(worksheetHeadData.totHead!.irateOt),
          otHour: formatNumber(worksheetHeadData.totHead!.ihour),
          otAmount: formatNumber(worksheetHeadData.totHead!.iotAmt),
          discountAmount: formatNumber(worksheetHeadData.tworksheetHead.idiscount),
          amountBeforeVat: formatNumber(worksheetHeadData.tworksheetHead.itotal1Amt),
          vatAmt: formatNumber(worksheetHeadData.tworksheetHead.itaxAmt),//ภาษี
          amountTotal: formatNumber(worksheetHeadData.tworksheetHead.itotal2Amt),
          taxAmount: formatNumber(worksheetHeadData.tworksheetHead.purchasetax),//หัก ณ ที่จ่าย
          // depositAmount: formatNumber(worksheetHeadData.tworksheetHead.idepositAmt),//ชำระแล้ว
          depositAmount: worksheetHeadData.tworksheetHead.payedamount! > 0 ? formatNumber(worksheetHeadData.tworksheetHead.payedamount) : worksheetHeadData.tworksheetHead.idepositAmt,//ชำระแล้ว เอามาจากเงินรับชำระ
          remainAmount: formatNumber(worksheetHeadData.tworksheetHead.iremainAmt),//คงเหลือชำระ
          chkHourRelax12to13: false,
          chkHourRelax17to18: false,
          chkOTWsh: false,
        });
      })();
      return () => { };
    }
  }, [viewWorksheetOTModalVisible]);

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>ดูข้อมูล OT</Typography.Title>
          </div>
        }
        visible={viewWorksheetOTModalVisible}
        width={"50%"}
        style={{ paddingTop: "5px" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => setViewWorksheetOTModalVisible(false)}
      >
        <Form
          form={form}
          name="ot"
          initialValues={{ remember: true }}
        >
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>เลขที่ใบสั่งงาน: </Typography.Text>
            </Col>
            <Col span={20}>
              <Form.Item
                name="worksheetCode"
                style={{ margin: 0 }}
              >
                <Input style={{ border: 0, fontWeight: "bold" }} readOnly></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>ชื่อ-สกุลลูกค้า: </Typography.Text>
            </Col>
            <Col span={20}>
              <Form.Item
                name="custName"
                style={{ margin: 0 }}
              >
                <Input style={{ border: 0, fontWeight: "bold" }} readOnly></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>รถ: </Typography.Text>
            </Col>
            <Col span={20}>
              <Form.Item
                name="truckName"
                style={{ margin: 0 }}
              >
                <Input style={{ border: 0, fontWeight: "bold" }} readOnly></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>พนักงานขับรถ: </Typography.Text>
            </Col>
            <Col span={20}>
              <Form.Item
                name="driverName"
                style={{ margin: 0 }}
              >
                <Input style={{ border: 0, fontWeight: "bold" }} readOnly></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>รายการบริการ: </Typography.Text>
            </Col>
            <Col span={20}>
              <Form.Item
                name="serviceName"
                style={{ marginBottom: "3px" }}
              >
                <Input style={{ border: 0, fontWeight: "bold" }} readOnly></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <Typography.Text>ราคา/หน่วย: </Typography.Text>
            </Col>
            <Col span={20}>
              <Space align='baseline' style={{ padding: "0px 0px 8px" }}>
                <Form.Item
                  name="serviceAmt"
                  style={{ margin: "0 0 3px 0px" }}
                >
                  <Input style={{ border: 0, fontWeight: "bold", textAlign: "right", color: "#FD1212", backgroundColor: "#F8FC9D" }} readOnly></Input>
                </Form.Item>
                <Form.Item
                  name="unitName"
                >
                  <Input style={{ margin: 0, border: 0, fontWeight: "bold" }} readOnly></Input>
                </Form.Item>
              </Space>
            </Col>
          </Row>
          {/* วัน-เวลาทำงาน */}
          <Card
            style={{
              width: "auto",
              borderRadius: "10px 10px 10px 10px",
              margin: "5px 0px 5px 0px",
              backgroundColor: "#f3f3f3",
            }}
          >
            <Typography.Title level={4} style={{ color: "#FD1212", }}>วัน-เวลาทำงาน</Typography.Title>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เริ่มวันที่: </Typography.Text>
              </Col>
              <Col span={20}>
                <Form.Item
                  name="startDate"
                  style={{ marginBottom: "3px" }}
                >
                  <DatePicker
                    showTime={{ format: "HH:00:00" }}
                    disabled
                    format={Constants.DATE_FORMAT_SKIP_SEC}
                    style={{ width: "80%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>แล้วเสร็จ: </Typography.Text>
              </Col>
              <Col span={20}>
                <Form.Item
                  name="endDate"
                  style={{ marginBottom: "3px" }}
                >
                  <DatePicker
                    showTime={{ format: "HH:00:00" }}
                    disabled
                    // allowClear
                    format={Constants.DATE_FORMAT_SKIP_SEC}
                    style={{ width: "80%" }}
                    // onChange={onCalculateOT}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}></Col>
              <Col span={20}>
                <Form.Item
                  name="chkHourRelax12to13"
                  hidden={true}
                  style={{ marginBottom: "3px" }}
                >
                  <Checkbox
                    style={{ color: "blue" }}
                  >
                    รวมเวลาพักเที่ยงวัน (12:00-13:00 น.)
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}></Col>
              <Col span={20}>
                <Form.Item
                  name="chkHourRelax17to18"
                  // hidden={isDisableHourRelax17to18}
                  style={{ marginBottom: "3px" }}

                >
                  <Checkbox
                    style={{ color: "blue" }}
                  >
                    รวมเวลาพักตอนเย็น (17.00-18.00 น.)
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Card>

          {/* รายการ */}
          <Card
            style={{
              width: "auto",
              borderRadius: "10px 10px 10px 10px",
              backgroundColor: "#EDEDED",
            }}
          >
            <Typography.Title level={4} style={{ color: "#FD1212" }}>รายการ</Typography.Title>
            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ค่าบริการเบื้องต้น: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="basicServiceAmt"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, fontWeight: "bold", textAlign: "right", color: "#FD1212", backgroundColor: "#F8FC9D" }} readOnly></Input>
                  </Form.Item>
                  <label>บาท</label>
                </Space>
              </Col>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
              </Col>
              <Col span={7} >
                <Form.Item
                  name="chkOTWsh"
                  style={{ marginBottom: "3px" }}
                >
                  <Checkbox
                    style={{ color: "blue" }}
                  >
                    ใบงานคิดค่าบริการเฉพาะ OT
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ค่าบริการพิเศษ: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="extraAmt"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, fontWeight: "bold", textAlign: "right", color: "#FD1212", backgroundColor: "#F8FC9D" }} readOnly></Input>
                  </Form.Item>
                  <label>บาท</label>
                </Space>
              </Col>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>จำนวนเงินรวมก่อนภาษี: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="amountBeforeVat"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, fontWeight: "bold", textAlign: "right", color: "#FD1212", backgroundColor: "#F8FC9D" }} readOnly></Input>
                  </Form.Item>
                  <label>บาท</label>
                </Space>
              </Col>
            </Row>

            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>OT ชั่วโมงละ: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="otPerHour"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, fontWeight: "bold", textAlign: "right", color: "#004269", backgroundColor: "#F8FC9D" }} readOnly></Input>
                  </Form.Item>
                  <label>บาท</label>
                </Space>
              </Col>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ภาษี 7%: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="vatAmt"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, fontWeight: "bold", textAlign: "right", color: "#FD1212", backgroundColor: "#F8FC9D" }} readOnly></Input>
                  </Form.Item>
                  <label>บาท</label>
                </Space>
              </Col>
            </Row>

            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>จำนวน: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "85%" }}>
                  <Form.Item
                    name="otHour"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, fontWeight: "bold", textAlign: "right", color: "#004269", backgroundColor: "#F8FC9D" }} readOnly></Input>
                  </Form.Item>
                  <label>ชั่วโมง</label>
                </Space>
              </Col>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>รวมเป็นเงิน: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="amountTotal"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, fontWeight: "bold", textAlign: "right", color: "#FD1212", backgroundColor: "#F8FC9D" }} readOnly></Input>
                  </Form.Item>
                  <label>บาท</label>
                </Space>
              </Col>
            </Row>

            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>รวมเป็นเงิน OT: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="otAmount"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, fontWeight: "bold", textAlign: "right", color: "#004269", backgroundColor: "#F8FC9D" }} readOnly></Input>
                  </Form.Item>
                  <label>บาท</label>
                </Space>
              </Col>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>หัก ณ ที่จ่าย: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="taxAmount"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, fontWeight: "bold", textAlign: "right", color: "#FD1212", backgroundColor: "#F8FC9D" }} readOnly></Input>
                  </Form.Item>
                  <label>บาท</label>
                </Space>
              </Col>
            </Row>

            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ส่วนลด: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="discountAmount"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, fontWeight: "bold", textAlign: "right", color: "#FD1212", backgroundColor: "#F8FC9D" }} readOnly></Input>
                  </Form.Item>
                  <label>บาท</label>
                </Space>
              </Col>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ชำระแล้ว: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="depositAmount"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, fontWeight: "bold", textAlign: "right", color: "#FD1212", backgroundColor: "#F8FC9D" }} readOnly></Input>
                  </Form.Item>
                  <label>บาท</label>
                </Space>
              </Col>
            </Row>

            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ประเภทการคิดภาษี: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "100%" }}>
                  <Form.Item
                    name="vatType"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Typography.Text strong style={{ color: "#FD1212", marginRight: "0px" }}>{vatType.iname}</Typography.Text>
                  </Form.Item>
                </Space>
              </Col>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>คงเหลือค้างชำระ: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="remainAmount"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, fontWeight: "bold", textAlign: "right", color: "#004269", backgroundColor: "#F8FC9D" }} readOnly></Input>
                  </Form.Item>
                  <label style={{ marginLeft: "10px" }}>บาท</label>
                </Space>
              </Col>
            </Row>
          </Card>
        </Form>
      </Modal>
    </>
  )
}

export default ViewWorksheetOTModal