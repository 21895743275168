import { CloseCircleOutlined, SaveOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Drawer, Form, Input, InputNumber, Row, Space, Spin, TimePicker, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TAssetHead } from "src/domain/master/tAssetHead";
import { TAssetmovementAndDetailView } from "src/domain/movement/view/tAssetmovementAndDetailView";
import tAssetHeadService from "src/services/tasset/tAssetHeadService";
import { useAppSelector } from "src/stores/store";
import Constants from "src/utils/constants";
import { formatFullTime, timeToString } from "src/utils/dateTimeUtils";
import tAssetmovementService from "../../services/tAssetmovementService";
import { NotificationType, showNotification } from "../../utils/notificationUtils";
import TAssetmovementModal from "./TAssetmovementModal";

interface IParams {
    visibleAddTAssetmovementDrawer: boolean;
    setVisibleAddTAssetmovementDrawer: any;
}

const AddTAssetmovementDrawer: React.FC<IParams> = (props) => {
    const { visibleAddTAssetmovementDrawer, setVisibleAddTAssetmovementDrawer } = props;
    const [trigger, setTrigger] = useState<boolean>(false);
    const [tAssetmovement, setTAssetmovement] = useState<TAssetmovementAndDetailView>({} as TAssetmovementAndDetailView);
    const [form] = Form.useForm();
    const history = useHistory();
    const [tAssetHead, setTAssetHead] = useState<TAssetHead>({} as TAssetHead);
    const [showSpin, setShowSpin] = useState<boolean>(false);
    const [selectedTAssetmovement, setSelectedTAssetmovement] = useState<TAssetmovementAndDetailView>({} as TAssetmovementAndDetailView);
    const [visibleTAssetmovementModal, setVisibleTAssetmovementModal] = useState<boolean>(false);
    const userInfo = useAppSelector((state) => state.authen.authenUser.user);

    useEffect(() => {
        if (trigger) {
            form.submit();
        }
    }, [trigger]);

    useEffect(() => {
        if (selectedTAssetmovement.worksheetCode) {
            (async () => {
                setShowSpin(true);

                const tAssetmovementRes = await tAssetmovementService.getTAssetmovementById(selectedTAssetmovement!!.seq);
                const tAssetHeadRes = await tAssetHeadService.getTAssetHeadViewById(tAssetmovementRes.data.assetcode)

                setTAssetHead(tAssetHeadRes.data)
                setTAssetmovement(tAssetmovementRes.data);
            })()
        }
    }, [selectedTAssetmovement]);

    useEffect(() => {
        setShowSpin(false);

        form.setFieldsValue({
            worksheetCode: tAssetmovement.worksheetCode,
            assetcode: tAssetmovement.assetcode ? "(" + tAssetmovement.assetcode + ") " + tAssetmovement.assetName : "",
            drivercode: tAssetmovement.drivercode ? "(" + tAssetmovement.drivercode + ") " + tAssetmovement.driverName : "",
            milearrivenumber: tAssetmovement.milearrivenumber ? Number(tAssetmovement.milearrivenumber) : 0,
            endTime: tAssetmovement.endTime ? moment(formatFullTime(tAssetmovement.endTime), Constants.TIME_FORMAT) : moment('17:00:00', Constants.TIME_FORMAT),
            userremark: tAssetmovement.userremark,
            drivercodeCheck: false
        });
        return () => {
        };
    }, [tAssetmovement]);

    const onFinish = async (values: any) => {
        if ("001" === tAssetHead.assettype || "007" === tAssetHead.assettype) {
            if (!values.drivercodeCheck) {
                if (Number(values.milearrivenumber) < tAssetHead.truckmile || !values.drivercode) {
                    showNotification(NotificationType.ERROR, "กรุณาป้อนเลข mile หรือเลือกคนขับรถขณะปล่อยรถให้ถูกต้อง!");
                    setTrigger(false);
                    return;
                }
            } else {
                if (!values.drivercode) {
                    showNotification(NotificationType.ERROR, "กรุณาป้อนคนขับรถขณะปล่อยรถให้ถูกต้อง!");
                    setTrigger(false);
                    return;
                }
            }
        }

        const _tAssetmovement: TAssetmovementAndDetailView = {
            ...tAssetmovement,
            milearrivenumber: Number(values.milearrivenumber),
            endTime: timeToString(values.endTime),
            userremark: values.userremark ? values.userremark : ""
        };

        try {
            await tAssetmovementService.postTAssetmovement(_tAssetmovement);
            showNotification(NotificationType.SUCCESS, "เพิ่มข้อมูลรับรถกลับเรียบร้อยแล้ว!");
            form.resetFields();
            setTrigger(false);
            setVisibleAddTAssetmovementDrawer(false);
            history.replace("/app/tAssetmovement/view/" + tAssetmovement.seq);
        } catch (err) {
            showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
            setTrigger(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน");
        setTrigger(false);
    };

    const handleSearchClicked = () => {
        setVisibleTAssetmovementModal(true);
    }

    const handleChangeWorksheetCode = async () => {
        const worksheetCode = form.getFieldValue("worksheetCode");
        const branchId = userInfo.branchId;
        const flg = "00"; // not return yet
        if (worksheetCode && worksheetCode.length < 13) {
            showNotification(NotificationType.ERROR, "เลขที่ใบงานไม่ถุกต้อง");
            form.resetFields();
        } else {
            const res = await tAssetmovementService.getTAssetmovementByParam(branchId, flg, worksheetCode, "", "");
            if (res.data.length === 0) {
                showNotification(NotificationType.ERROR, "ไม่พบเลขที่ใบงานนี้ ใน สถานะรอรับรถกลับ");
                form.resetFields();
            } else {
                setSelectedTAssetmovement(res.data[0]);
            }
        }
    }

    return (
        <>
            <Drawer
                title={
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "baseline",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography.Title level={4}>เพิ่มข้อมูลรับรถกลับ</Typography.Title>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "",
                            }}
                        >
                            <Button
                                type="primary"
                                danger
                                icon={<CloseCircleOutlined />}
                                onClick={() => setVisibleAddTAssetmovementDrawer(false)}>
                                ยกเลิก
                            </Button>
                            <Button
                                onClick={() => setTrigger(true)}
                                type="primary"
                                icon={<SaveOutlined />}
                                style={{ marginLeft: "5px" }}
                            >
                                บันทึก
                            </Button>
                        </div>
                    </div>
                }
                width="90%"
                visible={visibleAddTAssetmovementDrawer}
                forceRender={true}
                placement="right"
                bodyStyle={{ paddingBottom: 80 }}
                closable={false}
                destroyOnClose={true}
            >
                <Content className="app-page-content">
                    <div>
                        <Spin spinning={showSpin}>
                            <Form
                                form={form}
                                name="tAssetmovement"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>เลขที่ใบงาน: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={9}>
                                        <Form.Item
                                            name="worksheetCode"
                                            rules={[
                                                { required: true, message: 'กรุณาระบุ เลขที่ใบงาน' }
                                            ]}
                                        >
                                            <Input
                                                onKeyDown={(evt: any) => {
                                                    if (evt.keyCode == 13) {
                                                        handleChangeWorksheetCode()
                                                    }
                                                }}
                                            >
                                            </Input>
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Button
                                            style={{ margin: "2px 0 0 5px" }}
                                            size="small"
                                            type="primary"
                                            icon={<SearchOutlined />}
                                            onClick={() => handleSearchClicked()}
                                        ></Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>รายการ: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="assetcode"
                                            rules={[
                                                { required: false }
                                            ]}
                                        >
                                            <Input disabled style={{ width: "500px" }} maxLength={13}></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>พนักงานขับรก: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="drivercode"
                                            rules={[
                                                { required: false }
                                            ]}
                                        >
                                            <Input disabled style={{ width: "500px" }} maxLength={13}></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>เลขไมล์ออก: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={"large"}>
                                            <Form.Item
                                                name="milearrivenumber"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <InputNumber
                                                    controls={false}
                                                    style={{ width: "150px" }}
                                                    onFocus={(e) => e.target.select()}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="drivercodeCheck"
                                                rules={[
                                                    { required: false }
                                                ]}
                                                valuePropName="checked">
                                                <Space align="baseline">
                                                    <Checkbox></Checkbox>
                                                    <Typography.Text type="danger">รับรถหรือรถตู้ในขณะที่รถหรือรถตู้ไม่ได้กลับมาจริง</Typography.Text>
                                                </Space>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>เวลารับรถกลับ: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="endTime"
                                            rules={[
                                                { required: false }
                                            ]}
                                        >
                                            <TimePicker ></TimePicker>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>หมายเหตุ: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="userremark"
                                            rules={[
                                                { required: false }
                                            ]}
                                        >
                                            <TextArea
                                                style={{ width: "500px" }}
                                                rows={3}
                                                maxLength={255}
                                            ></TextArea>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Spin>
                    </div>
                </Content>
            </Drawer >

            <div> {
                visibleTAssetmovementModal && <TAssetmovementModal
                    setSelectedTAssetmovement={setSelectedTAssetmovement}
                    visibleTAssetmovementModal={visibleTAssetmovementModal}
                    setVisibleTAssetmovementModal={setVisibleTAssetmovementModal} />
            }</div>
        </>
    );
};

export default AddTAssetmovementDrawer;
