import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row, Space, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { TAssetmovementDetail } from 'src/domain/movement/tAssetmovementDetail';
import { TWorksheetHeadView } from 'src/domain/worksheet/view/tWorksheetHeadView';
import { TWshConstructionworkView } from 'src/domain/worksheet/view/tWshConstructionworkView';
import PageHeader from 'src/layouts/PageHeader';
import tAssetmovementDetailService from 'src/services/tAssetmovementDetailService';
import tWshConstructionworkService from 'src/services/tWshConstructionworkService';
import worksheetAdvanceDateService from 'src/services/worksheetAdvanceDateService';
import { useAppSelector } from 'src/stores/store';
import Constants from 'src/utils/constants';
import { toThDate } from 'src/utils/dateTimeUtils';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber } from 'src/utils/numberUtils';
import WorksheetAdvanceDateModal from './WorksheetAdvanceDateModal';

const WorksheetAdvanceDateList: React.FC<TWorksheetHeadView> = () => {
  const [worksheetHeadForAdvanceDates, setWorksheetHeadForAdvanceDates] = useState<TWorksheetHeadView[]>([]);
  const [selectedWorksheet, setSelectedWorksheet] = useState({} as TWorksheetHeadView);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [onModalVisible, setOnModalVisible] = useState<boolean>(false);
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 70,
      align: "center" as "center",
      render: (value: number, item: TWorksheetHeadView, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่ใบงาน",
      dataIndex: "iworksheetCode",
      key: "iworksheetCode",
      width: 150,
      align: "center" as "center",
    },
    {
      title: "ลูกค้า",
      dataIndex: "icustName",
      key: "icustName",
      width: 250,
    },
    {
      title: "วัน-เวลาที่ทำงาน",
      dataIndex: "startDate",
      key: "startDate",
      width: 190,
      render: (value: any, item: TWorksheetHeadView) => (<>{toThDate(item.istartDate)} : {toThDate(item.iendDate)}</>)
    },
    {
      title: "ราคาบริการ",
      dataIndex: "iserviceAmt",
      key: "iserviceAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iserviceAmt)}</span>;
      },
    },
    {
      title: "ค่าล่วงเวลา",
      dataIndex: "iotAmt",
      key: "iotAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iotAmt)}</span>;
      },
    },
    {
      title: "ค่าบริการอื่นๆ",
      dataIndex: "iextraAmt",
      key: "iextraAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iextraAmt)}</span>;
      },
    },
    {
      title: "ส่วนลดบริการ",
      dataIndex: "idiscount",
      key: "idiscount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.idiscount)}</span>;
      },
    },
    {
      title: "รวมก่อนภาษี",
      dataIndex: "itotal1Amt",
      key: "itotal1Amt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itotal1Amt)}</span>;
      },
    },
    {
      title: "ภาษี 7%",
      dataIndex: "itaxAmt",
      key: "itaxAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itaxAmt)}</span>;
      },
    },
    {
      title: "รวมค่าบริการ",
      dataIndex: "total2Amt",
      key: "total2Amt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itotal2Amt)}</span>;
      },
    },
    {
      title: "มัดจำ/ชำระล่วงหน้า",
      dataIndex: "idepositAmt",
      key: "idepositAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.idepositAmt)}</span>;
      },
    },
    {
      title: "คงเหลือ",
      dataIndex: "iremainAmt",
      key: "iremainAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iremainAmt)}</span>;
      },
    },
    {
      title: "รถ",
      dataIndex: "truckNumber",
      key: "truckNumber",
      width: 150,
      align: "center" as "center",
    },
    {
      title: "บริการ",
      dataIndex: "serviceDetailName",
      key: "serviceDetailName",
      width: 350,
      align: "center" as "center",
    },
    {
      title: "พนักงานขับรถ",
      dataIndex: "idriverName",
      key: "idriverName",
      width: 250,
      align: "center" as "center",
    },
    {
      title: "สถานะใบสั่งงาน",
      dataIndex: "iworksheetStatusName",
      key: "iworksheetStatusName",
      width: 130,
      align: "center" as "center",
    },
    {
      title: "เลขที่ใบแจ้งหนี้",
      dataIndex: "debitnotecode",
      key: "debitnotecode",
      width: 130,
      align: "center" as "center",
    },
    {
      title: "พนักงานสร้างบิล",
      dataIndex: "createUserName",
      key: "createUserName",
      width: 200,
      align: "center" as "center",
    },
    {
      title: "วันที่สร้างบิล",
      dataIndex: "ientryDate",
      key: "ientryDate",
      width: 150,
      align: "center" as "center",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{item.ientryDate ? toThDate(item.ientryDate) : ""}</span>;
      },
    },
  ]

  const getItemColor = (item: TWorksheetHeadView) => {
    let foreColor = "";
    if (item.iworksheetStatus === "98") {
      foreColor = "red";
    } else {
      foreColor = "black";
    }
    return 'text-' + foreColor;
  }

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    form.setFieldsValue({
      worksheetCode: "",
      fromDate: moment(new Date(), Constants.DATE_FORMAT),
      toDate: moment(new Date(), Constants.DATE_FORMAT)
    });
    form.submit();
  }, []);

  const onReset = () => {
    form.setFieldsValue({
      worksheetCode: "",
      fromDate: moment(new Date(), Constants.DATE_FORMAT),
      toDate: moment(new Date(), Constants.DATE_FORMAT)
    });
    form.submit();
  };

  const checkData = (wshData: TWorksheetHeadView) => {
    let has = false;
    if (wshData.transferflg == 1) {
      has = true;
    }

    if (wshData.ireserveCode) {
      has = true;
    }
    return has;
    // return wshData.transferflg == 1 ? true : false;
  }

  const checkAssetBack = async (tWorksheetHeadView: TWorksheetHeadView) => {
    let result = true;
    if (tWorksheetHeadView.iworksheetStatus === "01" || tWorksheetHeadView.iworksheetStatus === "04") {
      result = true;
    } else if (tWorksheetHeadView.typeworksheetflag === "00" || tWorksheetHeadView.typeworksheetflag === "04") {
      const tAssetMovementDetailRes = await tAssetmovementDetailService.getByWorksheetCode(tWorksheetHeadView.iworksheetCode!);
      if (tAssetMovementDetailRes.data) {
        const tAsetMovementDetail: TAssetmovementDetail = tAssetMovementDetailRes.data[0];
        if (tAsetMovementDetail.flg !== "01") {
          // if (tWorksheetHeadView.truckAssettype !== "002" && tWorksheetHeadView.truckAssettype !== "003") {
          showNotification(NotificationType.ERROR, "ต้องรับรถ /ตู้ /แผ่นเหล็ก เบอร์ : " + tAsetMovementDetail.assetname.trim() + " กลับก่อน");
          result = false;
          return;
          // }
        }
      }
    } else if (tWorksheetHeadView.typeworksheetflag === "02") {
      const tWshConstructionworkRes = await tWshConstructionworkService.getByReserveControlCode(tWorksheetHeadView.ireserveCode!);
      if (tWshConstructionworkRes.data) {
        tWshConstructionworkRes.data.forEach(async (item: TWshConstructionworkView) => {
          const tAssetMovementDetailConRes = await tAssetmovementDetailService.getByWorksheetCode(item.worksheetConstruction);
          if (tAssetMovementDetailConRes.data) {
            const tAsetMovementDetail: TAssetmovementDetail = tAssetMovementDetailConRes.data[0];
            if (tAsetMovementDetail.flg !== "01") {
              // if (tWorksheetHeadView.truckAssettype !== "002" && tWorksheetHeadView.truckAssettype !== "003") {
              showNotification(NotificationType.ERROR, "ต้องรับรถ /ตู้ /แผ่นเหล็ก เบอร์ : " + tAsetMovementDetail.assetname + " กลับก่อน");
              result = false;
              return;
              // }
            }
          }
        });
      }
    } else if (tWorksheetHeadView.typeworksheetflag === "04") {
      const tAssetMovementDetailConRes = await tAssetmovementDetailService.getByWorksheetCode(tWorksheetHeadView.iworksheetCode!);
      if (tAssetMovementDetailConRes.data) {
        tAssetMovementDetailConRes.data.forEach((item: TAssetmovementDetail) => {
          if (item.flg !== "01") {
            showNotification(NotificationType.ERROR, "ต้องรับรถ /ตู้ /แผ่นเหล็ก เบอร์ :" + item.assetname + " กลับก่อน");
            result = false;
            return;
          }
        })
      }
    }

    return result;
  }

  const onItemCliked = async (item: TWorksheetHeadView) => {
    if (checkData(item)) {
      if (await checkAssetBack(item)) {
        setSelectedWorksheet(item);
        setOnModalVisible(true);
      }
    } else {
      showNotification(NotificationType.ERROR, "ไม่พบข้อมูลในรายการ หรือในช่วงวันที่เวลาที่เลือกไว้ \n กรุณาตรวจสอบข้อมูลใหม่");
    }
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    let worksheetCode: string = values.worksheetCode ? values.worksheetCode : "";
    let fromDate: string = values.fromDate ? moment(values.fromDate).format(Constants.DB_FORMAT) : "";
    let toDate: string = values.toDate ? moment(values.toDate).format(Constants.DB_FORMAT) : "";

    if (worksheetCode && worksheetCode.length >= 7) {
      fromDate = "";
      toDate = "";
    } else if (worksheetCode.length > 0 && worksheetCode.length < 7) {
      showNotification(NotificationType.WARNING, "กรุณาป้อนเลขที่ใบงานให้มากกว่า 7 ตัวอักษร");
      form.setFieldsValue({
        reserveCode: "",
      });
      setLoading(false);
      return;
    }
    const res = await worksheetAdvanceDateService.getWorksheetHeadForAdvanceDate(worksheetCode, fromDate, toDate, userInfo.branchId);
    setWorksheetHeadForAdvanceDates(res.data);
    setLoading(false);
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  return (
    <>
      <PageHeader title="โปรแกรมเลื่อนการทำงาน"></PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="worksheetAdvanceDate"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
              borderBlockEnd: "2px solid #c9c9c9",
            }}
          >
            <Row>
              <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เลขที่ใบงาน: </Typography.Text>
              </Col>
              <Col span={21}>
                <Form.Item
                  name="worksheetCode"
                  rules={[{ required: false }]}
                >
                  <Input
                    allowClear
                    style={{
                      width: 378,
                    }}
                    placeholder="ป้อนเลขที่ใบงาน"
                  ></Input>
                </Form.Item>

              </Col>
            </Row>
            <Row>
              <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>วันที่ทำงาน ตั้งแต่: </Typography.Text>
              </Col>
              <Col span={21}>
                <Space align="baseline" size={"large"}>
                  <Form.Item
                    name="fromDate"
                    rules={[{ required: false }]}
                  >
                    <DatePicker
                      format={Constants.DATE_FORMAT}
                      size="middle"
                      placeholder="เลือกวันที่"
                    />
                  </Form.Item>
                  <Form.Item
                    label="ถึง"
                    name="toDate"
                    rules={[{ required: false }]}
                  >
                    <DatePicker
                      format={Constants.DATE_FORMAT}
                      size="middle"
                      placeholder="เลือกวันที่"
                    />
                  </Form.Item>
                </Space>

              </Col>
            </Row>
            <Row>
              <Col span={20}></Col>
              <Col span={4}>
                <Space align="baseline" size={"small"}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      icon={<SearchOutlined />}
                      onClick={form.submit}
                    >
                      ค้นหา
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="button"
                      type="primary"
                      onClick={onReset}
                      icon={<ClearOutlined />}
                    >
                      ล้าง
                    </Button>
                  </Form.Item>
                </Space>
              </Col>
            </Row>
          </div>
        </Form>
        <div>
          <Table
            columns={columns}
            dataSource={worksheetHeadForAdvanceDates}
            size="small"
            loading={loading}
            rowClassName={getItemColor}
            scroll={{ x: 2400, y: 400 }}
            pagination={false}
            rowKey={(item: TWorksheetHeadView) => item.ireserveCode!}
            onRow={(item: TWorksheetHeadView) => {
              return {
                onDoubleClick: () => onItemCliked(item),
              };
            }}
          />
        </div>
      </Content>
      <div>
        {onModalVisible && (
          <WorksheetAdvanceDateModal
            onModalVisible={onModalVisible}
            setOnModalVisible={setOnModalVisible}
            selectedWorksheet={selectedWorksheet}
            worksheetHeadForAdvanceDates={worksheetHeadForAdvanceDates}
            setWorksheetHeadForAdvanceDates={setWorksheetHeadForAdvanceDates}
          ></WorksheetAdvanceDateModal>
        )}
      </div>
    </>
  )
}

export default WorksheetAdvanceDateList