import { ExceptionOutlined } from '@ant-design/icons';
import { Button, Card, Descriptions, Form, Spin } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { TFinanceTrushconfirmData } from 'src/domain/finance/view/TFinanceTrushconfirmData';
import PageHeader from 'src/layouts/PageHeader';
import tFinanceTrushconfirmService from 'src/services/finance/tFinanceTrushconfirmService';
import { toThDate } from 'src/utils/dateTimeUtils';
import { formatNumber } from 'src/utils/numberUtils';
import ViewTrushMonetCashListDrawer from '../trushMoney/ViewTrushMonetCashListDrawer';

interface IParams {
  id: string;
}

const ViewTrushMoneyConfirm: React.FC<RouteComponentProps<IParams>> = (props) => {
  const trushconfirmId = props.match.params.id;
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [tfinanceTrushconfirmData, setTfinanceTrushconfirmData] = useState<TFinanceTrushconfirmData>({} as TFinanceTrushconfirmData);
  const [onDrawerCashVisible, setOnDrawerCashVisible] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const res = await tFinanceTrushconfirmService.findTFinanceTrushconfirmDatas(trushconfirmId, "", "", "", "", "");
      setTfinanceTrushconfirmData(res.data[0]);
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <PageHeader title="" onClose={() => history.push("/app/trushMoneyConfirm/list")} />
      <Spin spinning={loading}>
        <Content className="app-page-content">
          <Descriptions
            style={{ margin: "1em" }}
            column={4}
            title="ข้อมูลยืนยันการนำฝาก"
            size="middle"
          />
          <div style={{ margin: "1em" }}>
            <Form
              form={form}
              name="viewTrushMoneyconfirm"
            >
              <Card
                style={{
                  width: "auto",
                  borderRadius: "10px 10px 10px 10px",
                  margin: "5px 0px 5px 0px",
                }}
              >
                <Descriptions
                  column={6}
                  size="small"
                  bordered
                  style={{ paddingLeft: '10em' }}
                  labelStyle={{ textAlign: "right", fontWeight: "bold", width: "200px" }}
                >
                  <Descriptions.Item label="วันที่ยืนยันนำฝาก" span={6}>{tfinanceTrushconfirmData.tfinanceTrushView ? toThDate(tfinanceTrushconfirmData.tfinanceTrushView.confirmdate) : ""}</Descriptions.Item>
                  <Descriptions.Item label="เลขที่การนำฝาก" span={6}>{tfinanceTrushconfirmData.tfinanceTrushConfirm?.trushmoneyid}
                    <Button
                      style={{
                        marginLeft: "20px"
                      }}
                      type="primary"
                      disabled={tfinanceTrushconfirmData.tfinanceTrushConfirm?.confirmtype === "CHQ"}
                      icon={<ExceptionOutlined />}
                      onClick={() => setOnDrawerCashVisible(true)}
                    >
                      รายละเอียดรายการเงินสด
                    </Button>
                  </Descriptions.Item>
                  <Descriptions.Item label="เลขที่บัญชี" span={6}>{tfinanceTrushconfirmData.tfinanceTrushView?.accountNumber} : {tfinanceTrushconfirmData.tfinanceTrushView?.accountName}</Descriptions.Item>
                  <Descriptions.Item label="ประเภทการฝากเงิน" span={6}>{tfinanceTrushconfirmData.tfinanceTrushConfirm?.confirmtype}</Descriptions.Item>
                  <Descriptions.Item label="เลขที่เช็ค" span={6}>{tfinanceTrushconfirmData.tfinanceTrushView?.chquenumber ? tfinanceTrushconfirmData.tfinanceTrushView?.chquenumber : "-"}</Descriptions.Item>
                  <Descriptions.Item label="จำนวนเงินที่ฝาก" span={6}>{formatNumber(tfinanceTrushconfirmData.tfinanceTrushConfirm?.amount)}</Descriptions.Item>
                  <Descriptions.Item label="ผู้นำฝาก" span={6}>{tfinanceTrushconfirmData.tfinanceTrushView?.trushusername}</Descriptions.Item>
                  <Descriptions.Item label="สาขานำฝาก" span={6}>{tfinanceTrushconfirmData.tfinanceTrushView?.branchName}</Descriptions.Item>
                  <Descriptions.Item label="เลขที่ใบ Payin" span={6}>{tfinanceTrushconfirmData.tfinanceTrushConfirm?.payinnumber ? tfinanceTrushconfirmData.tfinanceTrushConfirm?.payinnumber : "-"}</Descriptions.Item>
                  <Descriptions.Item label="หมายเหตุนำฝาก" span={6}>{tfinanceTrushconfirmData.tfinanceTrushView?.remark}</Descriptions.Item>
                  <Descriptions.Item label="เลขที่ใบ Payin" span={6}>{tfinanceTrushconfirmData.tfinanceTrushConfirm?.remark}</Descriptions.Item>
                </Descriptions>
              </Card>
            </Form>
          </div>
          <div>
            {onDrawerCashVisible && (
              <ViewTrushMonetCashListDrawer
                onDrawerCashVisible={onDrawerCashVisible}
                setOnDrawerCashVisible={setOnDrawerCashVisible}
                trushId={tfinanceTrushconfirmData.tfinanceTrushConfirm.trushmoneyid}
              ></ViewTrushMonetCashListDrawer>
            )}
          </div>
        </Content>
      </Spin>
    </>
  )
}

export default ViewTrushMoneyConfirm