
import { TWorksheetHeadViewForReturnData } from "src/domain/view/tWorksheetHeadViewForReturnData";
import http from "../lib/http";
import Constants from "../utils/constants";

const createWorksheetReturn = (obj: TWorksheetHeadViewForReturnData) => {
  return http.post(Constants.DEFAULT_URL + "/tWorksheetreturnHead/createWorksheetReturn", obj);
};

const getTworksheetReturnDataByReturnno = (returnNo: number) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetreturnHead/getTworksheetReturnDataByReturnno/" + returnNo);
};

const getWorksheetReturns = (branchId: string, worksheetCode?: string, fromDate?: string, toDate?: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetreturnHead/getWorksheetReturns", {
    params: {
      branchId,
      worksheetCode,
      fromDate,
      toDate,
    },
  });
};

const defaultApi = {
  createWorksheetReturn,
  getWorksheetReturns,
  getTworksheetReturnDataByReturnno,
};
export default defaultApi;