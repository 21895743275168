import { Form, Modal, Select, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { TEmployee2View } from 'src/domain/master/view/tEmployee2View';
import { TTypetaxRef } from 'src/domain/tTypetaxRef';
import { TTypeworksheetRef } from 'src/domain/tTypeworksheetRef';
import { TUserHead } from 'src/domain/tUserHead';
import { TWorksheetHead } from 'src/domain/worksheet/tWorksheetHead';
import { WorksheetHeadData } from 'src/domain/worksheet/view/worksheetHeadData';
import employeeService from 'src/services/employee/tEmployee2Service';
import tTypetaxRefService from 'src/services/reference/tTypetaxRefService';
import tTypeworksheetRefService from 'src/services/reference/tTypeworksheetRefService';
import tAssetmovementDetailService from 'src/services/tAssetmovementDetailService';
import worksheetService from 'src/services/worksheetService';
import { useAppSelector } from 'src/stores/store';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';

const { Option } = Select;

interface IProps {
  editWorksheetTypeModalVisible: boolean;
  setEditWorksheetTypeModalVisible: any;
  // setUpdateStatus: any;
  worksheetHeadData: WorksheetHeadData;
  setWorksheetHeadData: any;
  tUserHeadUpdate: TUserHead;
}
const EditWorksheetTypeModal: React.FC<IProps> = (props) => {
  const { editWorksheetTypeModalVisible, setEditWorksheetTypeModalVisible, worksheetHeadData, setWorksheetHeadData, tUserHeadUpdate } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [vatDisable, setVatDisable] = useState<boolean>(false);
  const [worksheetTypeRefs, setWorksheetTypeRefs] = useState<TTypeworksheetRef[]>([]); //ประเภทใบสั่งงาน
  const [currentWorksheetType, setCurrentWorksheetType] = useState<TTypeworksheetRef>({} as TTypeworksheetRef);
  const [vatTypeRefs, setVatTypeRefs] = useState<TTypetaxRef[]>([]);; //ประเภทภาษี
  const [currentTaxType, setCurrentTaxType] = useState<TTypetaxRef>({} as TTypetaxRef);
  const [employees, setEmployees] = useState<TEmployee2View[]>([]);//ผู้แจ้ง
  const [form] = Form.useForm();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  useEffect(() => {
    form.setFieldsValue({
      typeWorksheetCode: worksheetHeadData.tworksheetHead.itypeworksheetCode,
      typevatCode: worksheetHeadData.tworksheetHead.itypetaxCode,
    });

    loadData();
    return () => { };
  }, []);

  const loadData = async () => {
    if (editWorksheetTypeModalVisible) {
      const worksheetTypesRes = await tTypeworksheetRefService.getTTypeworksheetRefList();
      const vatTypeRefs = await tTypetaxRefService.getTTypetaxRefList();
      const oldWorksheetType = (worksheetTypesRes.data as Array<TTypeworksheetRef>).find((obj) => obj.iid === worksheetHeadData.tworksheetHead.itypeworksheetCode);
      const oldVatType = (vatTypeRefs.data as Array<TTypetaxRef>).find((obj) => obj.iid === worksheetHeadData.tworksheetHead.itypetaxCode);
      setWorksheetTypeRefs(worksheetTypesRes.data);
      setVatTypeRefs(vatTypeRefs.data);
      setCurrentWorksheetType(oldWorksheetType!);
      setCurrentTaxType(oldVatType!);
    }
  };

  const handleSearchEmployee = async (data: any) => {
    if (data.length < 3) return;
    const res = await employeeService.getEmployee2ByParam(data);
    setEmployees(res.data);
  };

  const onSelectTypeWorksheet = (data: any) => {
    if (data === "02") {
      const vat = (vatTypeRefs as Array<TTypetaxRef>).find((obj) => obj.iid === "03");
      setVatDisable(true);
      form.setFieldsValue({
        typevatCode: vat?.iid,
      });
    } else {
      form.setFieldsValue({
        typevatCode: "",
      });
      setVatDisable(false);
    }
  }

  const calVatAmt = (serviceAmt: number, vatType: any) => {
    let vatAmt = 0.00;
    switch (vatType.iid) {
      case "01":
        vatAmt = Math.round(((serviceAmt * 7) / 100) * 100) / 100;
        break;
      case "02":
        vatAmt = Math.round(((serviceAmt * 7) / (107)) * 100) / 100;
        break;
      case "03":
        break;
    }
    return vatAmt;
  }

  //TODO AT API side
  const onFinish = async (values: any) => {
    if (values.typeWorksheetCode === currentWorksheetType.iid && values.typevatCode === currentTaxType.iid) {
      showNotification(NotificationType.WARNING, "ไม่มีการเปลี่ยนแปลง");
      setTrigger(false);
    } else if (1 < Number(worksheetHeadData.tworksheetHead.iworksheetStatus)) {
      const tAssetMovementDetailRes = await tAssetmovementDetailService.getByWorksheetCode(worksheetHeadData.tworksheetHead.iworksheetCode!);
      if (!tAssetMovementDetailRes.data) {
        showNotification(NotificationType.WARNING, "การปล่อยรถไม่สมบูรณ์ กรุณาติดต่อโปรแกรมเมอร์");
        setTrigger(false);
      }
    } else {
      const employeeArr = employees as Array<TEmployee2View>;
      const empSelected = employeeArr.find((obj) => obj.id === values.userInfoId);
      const vatSelected = vatTypeRefs.find((obj) => obj.iid === values.typevatCode);

      const serviceAmt = worksheetHeadData.netServiceAmt ? worksheetHeadData.netServiceAmt : 0;
      let vatAmount = calVatAmt(serviceAmt, vatSelected);
      let amountBefTax = 0.00;
      if (vatSelected!.iid === "02") {
        amountBefTax = serviceAmt - vatAmount;
      } else {
        amountBefTax = serviceAmt;
      }

      const netSumService = amountBefTax + vatAmount;
      const remain = netSumService - (worksheetHeadData.tworksheetHead.payedamount ? worksheetHeadData.tworksheetHead.payedamount : 0);

      const temp = {
        ...(worksheetHeadData as WorksheetHeadData),
        tworksheetHead: {
          ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
          itypeworksheetCode: values.typeWorksheetCode,
          itypetaxCode: values.typevatCode,
          itaxAmt: vatAmount,
          itotal1Amt: amountBefTax,
          itotal2Amt: amountBefTax + vatAmount,
          iremainAmt: remain,
          idepositAmt: (worksheetHeadData.tworksheetHead.itypepaymentCode === "01") ? remain : worksheetHeadData.tworksheetHead.idepositAmt,
          isavStation: userInfo.saveStation,
          icreateUser: tUserHeadUpdate.iuserId,
          ilastUser: tUserHeadUpdate.iuserId,
        },
        updateUser: tUserHeadUpdate,
      };
      setWorksheetHeadData(temp);

      try {
        // console.log("**** temp *** ", temp)
        await worksheetService.updateWorksheetWithSubMenuKey(temp, values.userRemark, empSelected ? empSelected.empname : "", "MENU_WORKSHEET_TYPE");
        showNotification(NotificationType.SUCCESS, "แก้ไขประเภทใบสั่งงานเรียบร้อยแล้ว!");
        setEditWorksheetTypeModalVisible(false);
        form.resetFields();
        setTrigger(false);
      } catch (err) {
        showNotification(NotificationType.ERROR, "เกิดขอผิดพลาดในการปรับปรุงใบงานกรุณาแจ้งผู้ดูแลระบบ");
        setTrigger(false);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    setTrigger(false);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>แก้ไขประเภทใบสั่งงาน</Typography.Title>
          </div>
        }
        visible={editWorksheetTypeModalVisible}
        width={"50%"}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setEditWorksheetTypeModalVisible(false)}
      >
        <Form
          form={form}
          name="vatType"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="ประเภทใบสั่งงาน"
            name="typeWorksheetCode"
            rules={[{ required: true, message: "กรุณาระบุ ประเภทใบสั่งงาน" }]}
          >
            <Select
              // style={{ width: "200px" }}
              showSearch
              size="middle"
              placeholder="เลือกประเภทใบสั่งงาน"
              optionFilterProp="children"
              filterOption={true}
              onSelect={onSelectTypeWorksheet}
            >
              {worksheetTypeRefs.map((obj: any) => {
                return (
                  <Option key={obj.iid} value={obj.iid}>
                    {obj.iname}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="ภาษี"
            name="typevatCode"
            rules={[{ required: true, message: "กรุณาระบุ ภาษี" },
            {
              validator: (_, value) => {
                if (form.getFieldValue("typeWorksheetCode") === "02" && value != "03") {
                  return Promise.reject(showNotification(NotificationType.ERROR, "ประเภทการชำระภาษี ไม่สัมพันธ์กับประเภทใบสั่งงาน"));
                } else {
                  return Promise.resolve()
                }
              },
            },
            ]}
          >
            <Select
              // style={{ width: "200px" }}
              showSearch
              size="middle"
              placeholder="เลือกประเภทภาษี"
              optionFilterProp="children"
              filterOption={true}
              disabled={vatDisable}
            >
              {vatTypeRefs.map((obj: any) => {
                return (
                  <Option key={obj.iid} value={obj.iid}>
                    {obj.iname}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="ผู้แจ้ง"
            name="userInfoId"
            rules={[{ required: true, message: "กรุณาระบุ ผู้แจ้ง" }]}
          >
            <Select
              // style={{ width: "200px" }}
              showSearch
              allowClear
              size="middle"
              placeholder="เลือกผู้แจ้ง"
              optionFilterProp="children"
              filterOption={true}
              onSearch={handleSearchEmployee}
            >
              {employees.map((obj) => {
                return (
                  <Option key={obj.id} value={obj.id}>
                    {obj.empname}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label="หมายเหตุ"
            name="userRemark"
            rules={[{ required: true, message: "กรุณาระบุ เหตุผล" }]}
          >
            <TextArea
              rows={4}
            ></TextArea>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default EditWorksheetTypeModal
