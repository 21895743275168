import http from "src/lib/http";
import Constants from "src/utils/constants";

const findConcludeWorksheetData = (entryDateFrom: string, entryDateTo: string, customercode: string, worksheetcode: string) => {
  return http.get(Constants.DEFAULT_URL + "/concludeWorksheet/findConcludeWorksheetData", {
    params: {
      entryDateFrom,
      entryDateTo,
      customercode,
      worksheetcode,
    }
  });
};

const saveConcludeWorksheet = (worksheetHeadViews: any, userRemark: string, reporterName: string) => {
  return http.post(Constants.DEFAULT_URL + "/concludeWorksheet/saveConcludeWorksheet",
  worksheetHeadViews,
    {
      params: {
        userRemark,
        reporterName
      },
    });
};

const defaultApi = {
  findConcludeWorksheetData,
  saveConcludeWorksheet,
}
export default defaultApi;