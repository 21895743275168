import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Descriptions, Form, InputNumber, Modal, Row, Space, Spin, Table, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { TTaxinvoiceData } from 'src/domain/view/tTaxinvoiceData';
import { TTaxinvoiceDetailView } from 'src/domain/view/tTaxinvoiceDetailView';
import { TTaxinvoiceHeadView } from 'src/domain/view/tTaxinvoiceHeadView';
import PageHeader from 'src/layouts/PageHeader';
import tTaxinvoiceHeadService from 'src/services/tTaxinvoiceHeadService';
import { useAppSelector } from 'src/stores/store';
import { toThDate } from 'src/utils/dateTimeUtils';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber } from 'src/utils/numberUtils';
import CancelRemarkModal from '../modals/CancelRemarkModal';
import TaxInvoiceDrawer from './TaxInvoiceDrawer';

const { confirm } = Modal;

interface IParams {
  id: string;
}

const ViewTaxInvoice: React.FC<RouteComponentProps<IParams>> = (props) => {
  const id = props.match.params.id;
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [ttaxinvoiceData, setTtaxinvoiceData] = useState<TTaxinvoiceData>({} as TTaxinvoiceData);
  const [onDrawerVisible, setOnDrawerVisible] = useState<boolean>(false);
  const [onModalRemarkVisible, setOnModalRemarkVisible] = useState<boolean>(false);
  const [remark, setRemark] = useState<string>("");
  const [pageStage, setPageStage] = useState<string>("VIEW");
  const [showAllPage, setShowAllPage] = useState<boolean>(false);

  const [taxInvoicePreviewBlob, setTaxInvoicePreviewBlob] = useState<any>();
  const [taxInvoicePreviewData, setTaxInvoicePreviewData] = useState<any>();
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: TTaxinvoiceDetailView, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่ใบสั่งงาน",
      dataIndex: "worksheetcode",
      key: "worksheetcode",
      width: 120,
      render: (value: any, item: TTaxinvoiceDetailView) => {
        return <span>{item.worksheetcode}</span>;
      },
    },
    {
      title: "เลขที่ใบแจ้งหนี้",
      dataIndex: "debitnotecode",
      key: "debitnotecode",
      width: 120,
      render: (value: any, item: TTaxinvoiceDetailView) => {
        if (ttaxinvoiceData.ttaxinvoiceHeadView.types === "00") {
          return <span>{item.tworksheetHeadView?.debitnotecode}</span>;
        } else {
          return <span>{item.debitnotecode}</span>;
        }
      },
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "customername",
      key: "customername",
      width: 200,
      render: (value: any, item: TTaxinvoiceDetailView) => (<>{item.tworksheetHeadView?.icustName}</>)
    },
    {
      title: "รายการ",
      dataIndex: "descriptions",
      key: "descriptions",
      width: 200,
    },
    {
      title: "ข้อความในใบกำกับ",
      dataIndex: "remarkDescriptions",
      key: "remarkDescriptions",
      width: 200,
    },
    {
      title: "วันที่ทำงาน",
      dataIndex: "startDate",
      key: "startDate",
      width: 90,
      render: (value: any, item: TTaxinvoiceDetailView) => (<>{toThDate(item.tworksheetHeadView?.istartDate)}</>)
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "amount",
      key: "amount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TTaxinvoiceDetailView) => {
        return <span>{formatNumber(item.amount)}</span>;
      },
    },
    {
      title: "เลขที่การรับเงิน",
      dataIndex: "paymentCode",
      key: "paymentCode",
      width: 100,
    },
  ];

  useEffect(() => {
    setLoading(true);
    initData();
  }, []);

  useEffect(() => {
    if (!onDrawerVisible) {
      setLoading(true);
      initData();
    }
  }, [onDrawerVisible]);

  useEffect(() => {
    (async () => {
      if (!onModalRemarkVisible && remark.length > 0 && pageStage === "DEL") {
        const temp = {
          ...(ttaxinvoiceData as TTaxinvoiceData),
          ttaxinvoiceHeadView: {
            ...(ttaxinvoiceData.ttaxinvoiceHeadView as TTaxinvoiceHeadView),
            remarks: remark,
            lastuser: userInfo.userId,
            termno: userInfo.termNo,
            savstation: userInfo.saveStation,
          },
        }
        setTtaxinvoiceData(temp);
        try {
          const tTaxinvoiceRes = await tTaxinvoiceHeadService.createUpdateTaxInvoiceData(temp, "DEL");
          showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเรียบร้อย!");
          history.push("/app/taxInvoice/list");
        } catch (err) {
          showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        }
      }
    })();
  }, [onModalRemarkVisible]);

  const initData = async () => {
    const res = await tTaxinvoiceHeadService.findTTaxinvoiceData(id);
    setTtaxinvoiceData(res.data);

    form.setFieldsValue({
      remarks: res.data.ttaxinvoiceHeadView.remarks,
      cash: res.data.ttaxinvoiceHeadView.cash,
      chequeamount: res.data.ttaxinvoiceHeadView.chequeamount,
      payinamount: res.data.ttaxinvoiceHeadView.payinamount,
      tax: res.data.ttaxinvoiceHeadView.tax,
      amount: res.data.ttaxinvoiceHeadView.amount,
      totalAmt: res.data.ttaxinvoiceHeadView.amount + res.data.ttaxinvoiceHeadView.tax,
    });

    setLoading(false);
  }

  const onPrint = async () => {
    setLoading(true);
    const resultTaxInvoicePreview = await tTaxinvoiceHeadService.printPreviewAndUpdateTaxInvoice(ttaxinvoiceData, showAllPage);
    if (resultTaxInvoicePreview.data) {
      setTaxInvoicePreviewBlob(resultTaxInvoicePreview.data);
      const fileObj = URL.createObjectURL(resultTaxInvoicePreview.data);
      setTaxInvoicePreviewData(fileObj);
      setIsPreviewModalVisible(true);
    } else {
      showNotification(
        NotificationType.ERROR,
        "ไม่พบข้อมูลที่เกี่ยวข้อง ไม่สามารถแสดงข้อมูลตัวอย่างได้"
      );
    }
    setLoading(false);
  }

  return (
    <>
      <PageHeader title="" onClose={() => history.push("/app/taxInvoice/list")} />
      <Spin spinning={loading}>
        <Content className="app-page-content">
          <Descriptions
            style={{ margin: "1em" }}
            column={4}
            title="ข้อมูลออกใบกำกับภาษี"
            size="middle"
            extra={
              <Space align='end'>
                <Button
                  size="middle"
                  type="primary"
                  disabled={ttaxinvoiceData.ttaxinvoiceHeadView?.flg === -1}
                  icon={<PrinterOutlined />}
                  onClick={() => {
                    if (ttaxinvoiceData.ttaxinvoiceHeadView.flg === -1) {
                      showNotification(NotificationType.ERROR, "รายการใบกำกับนี้ถูกยกเลิกแล้ว ไม่สามารถแก้ไขได้อีก");
                      return;
                    } else {
                      if (ttaxinvoiceData.ttaxinvoiceHeadView.flgprint === 1) {
                        form.validateFields()
                          .then(() => {
                            confirm({
                              title: "ยืนยัน!",
                              icon: <ExclamationCircleOutlined />,
                              content: "รายการใบกำกับนี้พิมพ์ไปแล้ว คุณต้องการพิมพ์ซ้ำหรือไม่",
                              cancelText: "ยกเลิก",
                              okText: "ยืนยัน",
                              onOk() {
                                onPrint();
                              },
                              onCancel() {
                                return;
                              }
                            });
                          }).catch(() => {
                            return;
                          });
                      } else {
                        onPrint();
                      }
                    }
                  }
                  }
                >
                  พิมพ์
                </Button>
                <Modal
                  style={{ top: 20 }}
                  width={"90vw"}
                  title="Tax Invoice"
                  bodyStyle={{ height: "85vh" }}
                  centered={true}
                  visible={isPreviewModalVisible}
                  okButtonProps={{ style: { display: 'none' } }}
                  cancelButtonProps={{ style: { display: 'none' } }}
                  onCancel={() => setIsPreviewModalVisible(false)}
                >
                  <iframe title=" " src={taxInvoicePreviewData} width={"100%"} height={"100%"}></iframe>
                </Modal>
                <Button
                  size="middle"
                  type="primary"
                  disabled={ttaxinvoiceData.ttaxinvoiceHeadView?.flg === -1 || ttaxinvoiceData.ttaxinvoiceHeadView?.flgprint === 1}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setOnDrawerVisible(true)
                  }
                  }
                >
                  แก้ไข
                </Button>
                <Button
                  size="middle"
                  type="primary"
                  danger
                  disabled={ttaxinvoiceData.ttaxinvoiceHeadView?.flg === -1}
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    if (ttaxinvoiceData.ttaxinvoiceHeadView.flg === -1) {
                      showNotification(NotificationType.ERROR, "รายการใบกำกับนี้ถูกยกเลิกแล้ว ไม่สามารถแก้ไขได้อีก");
                      return;
                    } else {
                      setOnModalRemarkVisible(true);
                    }
                  }
                  }
                >
                  ลบ
                </Button>
              </Space>
            }
          />
          <div style={{ margin: "1em" }}>
            <Form
              form={form}
              name="viewTaxInvoice"
              labelCol={{ span: 5 }}
            >
              <Descriptions
                column={6}
                size="small"
                bordered
                style={{ paddingLeft: '10em' }}
                labelStyle={{ textAlign: "right", fontWeight: "bold" }}
              >
                <Descriptions.Item label="วันที่ออกใบกำกับ" span={4}>{toThDate(ttaxinvoiceData.ttaxinvoiceHeadView?.invoicedate)}</Descriptions.Item>
                <Descriptions.Item label="เล่มที่" span={2}>{ttaxinvoiceData.ttaxinvoiceHeadView?.bookno}</Descriptions.Item>
                <Descriptions.Item label="สาขา" span={4}>{ttaxinvoiceData.ttaxinvoiceHeadView?.branch} - {ttaxinvoiceData.ttaxinvoiceHeadView?.branchName}</Descriptions.Item>
                <Descriptions.Item label="เลขที่" span={2}>{ttaxinvoiceData.ttaxinvoiceHeadView?.booknumber}</Descriptions.Item>
                <Descriptions.Item label="ได้รับเงินจาก" span={4}>{ttaxinvoiceData.ttaxinvoiceHeadView?.customername}</Descriptions.Item>
                <Descriptions.Item label="สำนักงานใหญ่/สาขา" span={2}>
                  {<Checkbox disabled checked={!ttaxinvoiceData.ttaxinvoiceHeadView?.customerBranchName}>สำนักงานใหญ่</Checkbox>}
                  {<Checkbox disabled checked={ttaxinvoiceData.ttaxinvoiceHeadView?.customerBranchName ? true : false}>สาขา</Checkbox>}
                  {!ttaxinvoiceData.ttaxinvoiceHeadView?.customerBranchName ? "" : ttaxinvoiceData.ttaxinvoiceHeadView?.customerBranchName}
                </Descriptions.Item>
                <Descriptions.Item label="ที่อยู่" span={4} style={{width:250}}>{ttaxinvoiceData.ttaxinvoiceHeadView?.customeraddress}</Descriptions.Item>
                <Descriptions.Item label="เลขผู้เสียภาษีอากร" span={2}>{ttaxinvoiceData.ttaxinvoiceHeadView?.customerTaxId}</Descriptions.Item>
              </Descriptions>
              <div style={{ margin: 15 }}>
                <Table
                  columns={columns}
                  dataSource={ttaxinvoiceData.ttaxinvoiceDetailViewList}
                  size="small"
                  rowKey={(item: TTaxinvoiceDetailView) => (item?.seq)}
                  pagination={false}
                />
              </div>
              <Row>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>หมายเหตุ: </Typography.Text>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="remarks"
                  >
                    <TextArea
                      disabled
                      rows={2}
                    ></TextArea>
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ padding: "5px 0px 0px" }}>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เงินสด: </Typography.Text>
                </Col>
                <Col span={8}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="cash"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        readOnly={true}
                        style={{
                          color: "#FD1212",
                          backgroundColor: "#F7F7F8",
                          width: "99%",
                        }}
                      >
                      </InputNumber>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
                <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>มูลค่าสินค้า/บริการ: </Typography.Text>
                </Col>
                <Col span={9}>
                  <Space align='baseline' style={{ width: "80%" }}>

                    <Form.Item
                      name="amount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        readOnly={true}
                        style={{
                          color: "#FD1212",
                          backgroundColor: "#F7F7F8",
                          width: "99%",
                        }}
                      >
                      </InputNumber>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
              </Row>
              <Row style={{ padding: "5px 0px 0px" }}>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เช็ค: </Typography.Text>
                </Col>
                <Col span={8}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="chequeamount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        readOnly={true}
                        style={{
                          color: "#FD1212",
                          backgroundColor: "#F7F7F8",
                          width: "99%",
                        }}
                      >
                      </InputNumber>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
                <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ภาษีมูลค่าเพิ่ม 7%: </Typography.Text>
                </Col>
                <Col span={9}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="tax"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        parser={(value) => parserNumber(value)}
                        readOnly={true}
                        style={{
                          color: "#FD1212",
                          backgroundColor: "#F7F7F8",
                          width: "99%",
                        }}
                      >
                      </InputNumber>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
              </Row>
              <Row style={{ padding: "5px 0px 0px" }}>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เงินโอน: </Typography.Text>
                </Col>
                <Col span={8}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="payinamount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        readOnly={true}
                        style={{
                          color: "#FD1212",
                          backgroundColor: "#F7F7F8",
                          width: "99%",
                        }}
                      >
                      </InputNumber>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
                <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>รวมจำนวนเงินทั้งสิ้น: </Typography.Text>
                </Col>
                <Col span={9}>
                  <Space align='baseline' style={{ width: "80%" }}>

                    <Form.Item
                      name="totalAmt"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        readOnly={true}
                        style={{
                          color: "#FD1212",
                          backgroundColor: "#F7F7F8",
                          width: "99%",
                        }}
                      >
                      </InputNumber>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
              </Row>
            </Form>
          </div>
          <div>
            {onDrawerVisible && (
              <TaxInvoiceDrawer
                onDrawerVisible={onDrawerVisible}
                setOnDrawerVisible={setOnDrawerVisible}
                pageStage="EDIT"
                id={id}
              ></TaxInvoiceDrawer>
            )}
          </div>
          <div>
            {onModalRemarkVisible && (
              <CancelRemarkModal
                onModalRemarkVisible={onModalRemarkVisible}
                setOnModalRemarkVisible={setOnModalRemarkVisible}
                setRemark={setRemark}
                setPageStage={setPageStage}
              ></CancelRemarkModal>
            )}
          </div>
        </Content>
      </Spin>
    </>
  )
}

export default ViewTaxInvoice