import { Button, Table } from "antd";
import Search from "antd/es/input/Search";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PageHeader from "src/layouts/PageHeader";
import { TReference } from "../../domain/master/tReference";
import tReferenceService from "../../services/reference/tReferenceService";


const ReferenceList: React.FC<TReference> = () => {
    const [references, setReferences] = useState<TReference[]>([]);
    const [page, setPage] = React.useState(1);
    const history = useHistory();

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            width: 100,
            render: (value: number, item: TReference, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
        },
        {
            title: "รหัส",
            dataIndex: "code",
            key: "code",
            width: 100,
            render: (value: string, item: TReference) => (
                <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.code}</Button>
            )
        },
        {
            title: "ชื่อ",
            dataIndex: "name",
            key: "name",
        },
    ];

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
        (async () => {
            const res = await tReferenceService.getTReferences("");
            setReferences(res.data);
        })();
    }, []);

    const doSearch = async (searchText: string) => {
        const res = await tReferenceService.getTReferences(searchText);
        setReferences(res.data);
    }


    function onSearchTextChange(searchText: string) {
        if (!searchText) {
            doSearch("");
        } else if (searchText.length >= 2) {
            doSearch(searchText);
        }
    }

    const onItemCliked = (item: TReference) => {
        history.replace("/app/reference/view/" + item.id);
    };

    return (
        <>
            <PageHeader title="โปรแกรมข้อมูลอ้างอิง">
            </PageHeader>
            <Content className="app-page-content">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "baseline",
                        margin: "1em 0 0 0",
                        borderBlockEnd: "2px solid #c9c9c9",
                    }}
                >
                    <label style={{ margin: "0 1em 1em 2em" }}>คำค้นหา</label>
                    <Search
                        allowClear
                        style={{ width: "350px" }}
                        size="middle"
                        placeholder="ป้อนคำค้นหา"
                        onChange={(event) => onSearchTextChange(event.target.value)}
                    ></Search>
                </div>
                <div>
                    <Table
                        columns={columns}
                        dataSource={references}
                        size="small"
                        pagination={{
                            pageSize: 15,
                            onChange(current) {
                                setPage(current);
                            }
                        }}
                        rowKey={(item: TReference) => item.id}
                        onRow={(item: TReference) => {
                            return {
                                onDoubleClick: () => onItemCliked(item),
                            };
                        }}
                    />
                </div>
            </Content>
        </>
    );
};

export default ReferenceList;
