import http from "src/lib/http";
import Constants from "src/utils/constants";

const getRyPercentpayindepositList = () => {
  return http.get(
    Constants.DEFAULT_URL + "/ryPercentpayindeposit"
  );
};

const getRyPercentpayindeposit = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/ryPercentpayindeposit/" + id);
}

const defaultApi = {
  getRyPercentpayindeposit,
  getRyPercentpayindepositList,
};
export default defaultApi;