import { PrinterOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Descriptions, Form, Input, InputNumber, Modal, Radio, Row, Space, Spin, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { TRangradWangbillHead } from 'src/domain/tRangradWangbillHead';
import { TRangradWangbillHeadData } from 'src/domain/view/tRangradWangbillHeadData';
import { TWorksheetHeadView } from 'src/domain/worksheet/view/tWorksheetHeadView';
import PageHeader from 'src/layouts/PageHeader';
import tRangradWangbillHeadService from 'src/services/tRangradWangbillHeadService';
import { useAppSelector } from 'src/stores/store';
import Constants from 'src/utils/constants';
import { toMomentDate, toThDate } from 'src/utils/dateTimeUtils';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, formatNumberInt } from 'src/utils/numberUtils';

interface IParams {
  debitnoteNo: string;
}

const ViewTRengRadWangBill: React.FC<RouteComponentProps<IParams>> = (props) => {
  const debitnoteNo = props.match.params.debitnoteNo;
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [debitNotePreviewBlob, setDebitNotePreviewBlob] = useState<any>();
  const [debitNotePreviewData, setDebitNotePreviewData] = useState<any>();
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false);
  const [showAllPage, setShowAllPage] = useState<boolean>(false);

  const [worksheetHeadShows, setWorksheetHeadShows] = useState<TWorksheetHeadView[]>([]);
  const [worksheetHeadIns, setWorksheetHeadIns] = useState<TWorksheetHeadView[]>([]);//ใบงานใน
  const [worksheetHeadOuts, setWorksheetHeadOuts] = useState<TWorksheetHeadView[]>([]);//ใบงานนอก
  const [trangradWangbillHeadData, setTrangradWangbillHeadData] = useState<TRangradWangbillHeadData>({} as TRangradWangbillHeadData);

  const getItemColor = (item: TWorksheetHeadView) => {
    let foreColor = "";
    if (item.itypeworksheetCode === "01") {
      foreColor = "red";
    } else {
      foreColor = "blue";
    }
    return 'text-' + foreColor;
  }

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: TWorksheetHeadView, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่ใบสั่งงาน",
      dataIndex: "iworksheetCode",
      key: "iworksheetCode",
      width: 120,
    },
    {
      title: "รายการ",
      dataIndex: "serviceDetailName",
      key: "serviceDetailName",
      width: 300,
    },
    {
      title: "วัน-เวลาที่ทำงาน",
      dataIndex: "workDate",
      key: "workDate",
      width: 250,
      render: (value: any, item: TWorksheetHeadView) => (<>{toThDate(item.istartDate)} - {toThDate(item.iendDate)}</>)
    },
    {
      title: "จำนวนเงินคงค้าง(บาท)",
      dataIndex: "remainAmt",
      key: "remainAmt",
      width: 200,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iremainAmt)}</span>;
      },
    },
    {
      title: "ค่าบริการ",
      dataIndex: "iserviceAmt",
      key: "iserviceAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iserviceAmt)}</span>;
      },
    },
    {
      title: "OT",
      dataIndex: "iotAmt",
      key: "iotAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iotAmt)}</span>;
      },
    },
    {
      title: "ค่าอื่นๆ",
      dataIndex: "iextraAmt",
      key: "iextraAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iextraAmt)}</span>;
      },
    },
    {
      title: "ส่วนลด",
      dataIndex: "idiscount",
      key: "idiscount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.idiscount)}</span>;
      },
    },
    {
      title: "รวม",
      dataIndex: "total1Amt",
      key: "total1Amt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itotal1Amt)}</span>;
      },
    },
    {
      title: "ภาษี",
      dataIndex: "vatAmt",
      key: "vatAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itaxAmt)}</span>;
      },
    },
    {
      title: "สุทธิ",
      dataIndex: "total2Amt",
      key: "total2Amt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itotal2Amt)}</span>;
      },
    },
    {
      title: "ชำระแล้ว",
      dataIndex: "payedamount",
      key: "payedamount",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.payedamount)}</span>
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    (async () => {
      const res = await tRangradWangbillHeadService.getTRangradWangbillHeadDataByDebitnoteNo(debitnoteNo);

      const arrayOut = worksheetHeadOuts ? [...worksheetHeadOuts] : [];
      const arrayIn = worksheetHeadIns ? [...worksheetHeadIns] : [];
      for (const item of res.data.tworksheetList) {
        if (item.itypeworksheetCode === "01") {
          arrayOut.push(item);
        } else if (item.itypeworksheetCode === "02") {
          arrayIn.push(item);
        }
      }

      setTrangradWangbillHeadData(res.data);
      setWorksheetHeadOuts(arrayOut);
      setWorksheetHeadIns(arrayIn);
      setWorksheetHeadShows(arrayOut);

      form.setFieldsValue({
        debitor: res.data.trangradWangbillHead?.idebitorCode + " : " + res.data.trangradWangbillHead?.debitorname,
        iaddress: res.data.tdebitorHeadView.iaddress,
        iamphurName: res.data.tdebitorHeadView.iamphurName,
        iprovinceName: res.data.tdebitorHeadView.iprovinceName,
        ipostcode: res.data.tdebitorHeadView.ipostcode,
        itelephoneNumber: res.data.tdebitorHeadView.itelephoneNumber,
        icreditamount: res.data.tdebitorHeadView.icreditamount,
        creditdate: res.data.tdebitorHeadView.creditdate,
        iextradiscountamount: res.data.tdebitorHeadView.iextradiscountamount,
        currentusage: res.data.tdebitorHeadView.currentusage,
        rdInOut: "WSH_OUT",
        idebitnoteNo: res.data.trangradWangbillHead?.idebitnoteNo,
        iappointmentDate: toMomentDate(res.data.trangradWangbillHead?.iappointmentDate),
        iamount: res.data.trangradWangbillHead?.iamount,
        idiscount: res.data.trangradWangbillHead?.idiscount,
        total: res.data.trangradWangbillHead?.total,
        vat: res.data.trangradWangbillHead?.vat,
        nettotal: res.data.trangradWangbillHead?.nettotal,
        remain: res.data.trangradWangbillHead?.remain,
        remark: res.data.trangradWangbillHead?.remark,
        deposit: res.data.trangradWangbillHead?.deposit,
      });

      setLoading(false);
    })();
  }, []);

  const onChangeRdInOut = () => {
    const rdInOut = form.getFieldValue("rdInOut");
    const arrayOut = worksheetHeadOuts ? [...worksheetHeadOuts] : [];
    const arrayIn = worksheetHeadIns ? [...worksheetHeadIns] : [];
    if (rdInOut === "WSH_IN") {
      setWorksheetHeadShows(arrayIn);
    } else if (rdInOut === "WSH_OUT") {
      setWorksheetHeadShows(arrayOut);
    }
  }

  const onPrint = async () => {
    if (!trangradWangbillHeadData.tworksheetList?.length) {
      showNotification(NotificationType.WARNING, "ไม่สามารถสั่งพิมพ์ได้ ไม่พบรายการใบงานในใบแจ้งหนี้");
      return;
    } else if (trangradWangbillHeadData.trangradWangbillHead.idebitnotestatus !== "00") {
      showNotification(NotificationType.WARNING, "ไม่สามารถสั่งพิมพ์ได้ เนื่องจากสถานะใบแจ้งหนี้ไม่ถูกต้อง");
      return;
    } else {
      const temp = {
          ...(trangradWangbillHeadData as TRangradWangbillHeadData),
          trangradWangbillHead: {
            ...(trangradWangbillHeadData.trangradWangbillHead as TRangradWangbillHead),
            numofreprint: trangradWangbillHeadData.trangradWangbillHead.numofreprint + 1,
            isavStation: userInfo.saveStation,
            ilastUser: userInfo.userId,
            itermNo: userInfo.termNo
          },
          tworksheetList: [...trangradWangbillHeadData.tworksheetList],
        }
        setLoading(true);
        const resultDebitNotePreview = await tRangradWangbillHeadService.printPreviewAndSaveRangradWangbill(temp, showAllPage);
        if (resultDebitNotePreview.data) {
          setDebitNotePreviewBlob(resultDebitNotePreview.data);
          const fileObj = URL.createObjectURL(resultDebitNotePreview.data);
          setDebitNotePreviewData(fileObj);
          setIsPreviewModalVisible(true);
        } else {
          showNotification(
            NotificationType.ERROR,
            "ไม่พบข้อมูลที่เกี่ยวข้อง ไม่สามารถแสดงข้อมูลตัวอย่างได้"
          );
        }
        setLoading(false);
    }
  }

  return (
    <>
      <PageHeader title="" onClose={() => history.push("/app/rengradWangbill/list")} />
      <Spin spinning={loading}>
        <Content className="app-page-content">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              justifyContent: "space-between",
              marginRight: "1rem",
            }}
          >
            <Descriptions
              style={{ margin: "1em" }}
              column={4}
              title="ข้อมูลเร่งรัดออกใบแจ้งหนี้"
              size="middle"
            />
            <Button
              type="primary"
              disabled={!trangradWangbillHeadData.tworksheetList?.length}
              icon={<PrinterOutlined />}
              onClick={onPrint}
            >
              พิมพ์ใบแจ้งหนี้
            </Button>
            <Modal
              style={{ top: 20 }}
              width={"90vw"}
              title="DebitNote"
              bodyStyle={{ height: "85vh" }}
              centered={true}
              visible={isPreviewModalVisible}
              okButtonProps={{ style: { display: 'none' } }}
              cancelButtonProps={{ style: { display: 'none' } }}
              onCancel={() => setIsPreviewModalVisible(false)}
            >
              <iframe title=" " src={debitNotePreviewData} width={"100%"} height={"100%"}></iframe>
            </Modal>
          </div>
          <Form
            form={form}
            name="rengeadWangbill"
            labelCol={{ span: 10 }}
          >
            <Card
              style={{
                width: "auto",
                marginBottom: 5,
              }}
              headStyle={{
                fontSize: 14,
                paddingBottom: 5,
              }}
              title="ข้อมูลลูกหนี้"
            >
              <Row>
                <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>บัญชีลูกหนี้เลขที่: </Typography.Text>
                </Col>
                <Col span={11}>
                  <Form.Item
                    name="debitor"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input
                      style={{ marginRight: 5, backgroundColor: "#F7F7F8" }} readOnly
                    >
                    </Input>
                  </Form.Item>
                </Col>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>วงเงิน: </Typography.Text>
                </Col>
                <Col span={6}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="icreditamount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        readOnly={true}
                        style={{
                          backgroundColor: "#F7F7F8",
                          width: "auto",
                        }}
                      ></InputNumber>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ที่อยู่: </Typography.Text>
                </Col>
                <Col span={11}>
                  <Form.Item
                    name="iaddress"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input
                      style={{ backgroundColor: "#F7F7F8" }} readOnly
                    >
                    </Input>
                  </Form.Item>
                </Col>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>จำนวนวันเครดิต: </Typography.Text>
                </Col>
                <Col span={6}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="creditdate"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumberInt(value)}
                        readOnly={true}
                        style={{
                          textAlign: "end",
                          backgroundColor: "#F7F7F8",
                          width: "auto",
                        }}
                      ></InputNumber>
                    </Form.Item>
                    <Typography.Text> วัน</Typography.Text>
                  </Space>
                </Col>
              </Row>

              <Row>
                <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>อำเภอ: </Typography.Text>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="iamphurName"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input
                      style={{ backgroundColor: "#F7F7F8" }}
                      readOnly
                    >
                    </Input>
                  </Form.Item>
                </Col>
                <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>จังหวัด: </Typography.Text>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="iprovinceName"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input
                      style={{ backgroundColor: "#F7F7F8" }} readOnly
                    >
                    </Input>
                  </Form.Item>
                </Col>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ส่วนลดพิเศษ: </Typography.Text>
                </Col>
                <Col span={6}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="iextradiscountamount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        readOnly={true}
                        style={{
                          backgroundColor: "#F7F7F8",
                          width: "auto",
                        }}
                      ></InputNumber>
                    </Form.Item>
                    <Typography.Text> %</Typography.Text>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>รหัสไปรษณีย์: </Typography.Text>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="ipostcode"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input
                      style={{ backgroundColor: "#F7F7F8" }}
                      readOnly
                    >
                    </Input>
                  </Form.Item>
                </Col>
                <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>โทร/มือถือ/FAX: </Typography.Text>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="itelephoneNumber"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input
                      style={{ backgroundColor: "#F7F7F8" }} readOnly
                    >
                    </Input>
                  </Form.Item>
                </Col>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ยอดค้างชำระ: </Typography.Text>
                </Col>
                <Col span={6}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="currentusage"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumber(value)}
                        readOnly={true}
                        style={{
                          backgroundColor: "#F7F7F8",
                          width: "auto",
                        }}
                      ></InputNumber>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
              </Row>
            </Card>

            <Card
              style={{
                width: "auto",
                marginBottom: 5,
              }}
              headStyle={{
                fontSize: 14,
                paddingBottom: 5,
              }}
              title="รายการใบสั่งงาน"
            >
              <Row>
                <Col span={16}>
                  <Form.Item
                    name="rdInOut"
                  >
                    <Radio.Group
                      style={{ margin: "0px" }}
                      onChange={() => onChangeRdInOut()}
                    >
                      <Radio value="WSH_IN" style={{ backgroundColor: "blue", color: "#FFF" }}>ใบสั่งงานใน</Radio>
                      <Radio value="WSH_OUT" style={{ backgroundColor: "red", color: "#FFF" }}>ใบสั่งงานนอก</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={8} style={{ textAlign: "end" }}>
                  <Space align="center" direction='vertical'>
                    <Typography.Text strong>รายการที่เลือก</Typography.Text>
                    <Button
                      type="text"
                      danger
                      style={{ margin: 0, width: 60, fontWeight: "bold" }}>

                      {formatNumberInt((worksheetHeadIns ? worksheetHeadIns.length : 0) + (worksheetHeadOuts ? worksheetHeadOuts.length : 0))}
                    </Button>
                  </Space>
                </Col>

              </Row>
              <Table
                columns={columns}
                dataSource={worksheetHeadShows}
                size="small"
                rowKey={(item: TWorksheetHeadView) => item.ireserveCode!}
                pagination={false}
                scroll={{ x: 1800 }}
                rowClassName={getItemColor}
              />
            </Card>

            <Card
              style={{
                width: "auto",
                marginBottom: 5,
                border: "0"
              }}
              headStyle={{
                fontSize: 14,
                paddingBottom: 5,
              }}
              title="จำนวนเงิน"
            >
              <Row>
                <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เลขที่ใบแจ้งหนี้: </Typography.Text>
                </Col>
                <Col span={22}>
                  <Form.Item
                    name="idebitnoteNo"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input
                      style={{ width: "18%", backgroundColor: "#F7F7F8" }} readOnly
                    >
                    </Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>จำนวนเงิน: </Typography.Text>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="iamount"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <InputNumber
                      controls={false}
                      formatter={(value) => formatNumber(value)}
                      readOnly={true}
                      style={{
                        backgroundColor: "#F7F7F8",
                        width: "99%",
                      }}
                    >
                    </InputNumber>
                  </Form.Item>
                </Col>
                <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ส่วนลดใบวางบิล: </Typography.Text>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="idiscount"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <InputNumber
                      controls={false}
                      formatter={(value) => formatNumber(value)}
                      readOnly={true}
                      style={{
                        backgroundColor: "#F7F7F8",
                        width: "99%",
                      }}
                    >
                    </InputNumber>
                  </Form.Item>
                </Col>
                <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ครบกำหนดชำระเงิน: </Typography.Text>
                </Col>
                <Col span={3}>
                  <Form.Item
                    name="iappointmentDate"
                    rules={[{ required: false }]}
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <DatePicker
                      disabled
                      format={Constants.DATE_FORMAT}
                      placeholder="เลือกวันที่"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ชำระแล้ว: </Typography.Text>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="deposit"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <InputNumber
                      controls={false}
                      formatter={(value) => formatNumber(value)}
                      readOnly={true}
                      style={{
                        backgroundColor: "#F7F7F8",
                        width: "99%",
                      }}
                    >
                    </InputNumber>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>รวมหลังลด: </Typography.Text>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="total"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <InputNumber
                      controls={false}
                      formatter={(value) => formatNumber(value)}
                      readOnly={true}
                      style={{
                        backgroundColor: "#F7F7F8",
                        width: "99%",
                      }}
                    >
                    </InputNumber>
                  </Form.Item>
                </Col>
                <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ภาษี: </Typography.Text>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="vat"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <InputNumber
                      controls={false}
                      formatter={(value) => formatNumber(value)}
                      readOnly={true}
                      style={{
                        backgroundColor: "#F7F7F8",
                        width: "99%",
                      }}
                    >
                    </InputNumber>
                  </Form.Item>
                </Col>
                <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เป็นเงิน: </Typography.Text>
                </Col>
                <Col span={3}>
                  <Form.Item
                    name="nettotal"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <InputNumber
                      controls={false}
                      formatter={(value) => formatNumber(value)}
                      readOnly={true}
                      style={{
                        backgroundColor: "#F7F7F8",
                        width: "99%",
                      }}
                    >
                    </InputNumber>
                  </Form.Item>
                </Col>
                <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ค้างชำระ: </Typography.Text>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name="remain"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <InputNumber
                      controls={false}
                      formatter={(value) => formatNumber(value)}
                      readOnly={true}
                      style={{
                        backgroundColor: "#F7F7F8",
                        width: "99%",
                      }}
                    >
                    </InputNumber>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>หมายเหตุ: </Typography.Text>
                </Col>
                <Col span={22}>
                  <Form.Item
                    name="remark"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input
                      style={{ width: "73%" }}
                      disabled
                    >
                    </Input>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Form>


        </Content>
      </Spin>
    </>
  )
}

export default ViewTRengRadWangBill