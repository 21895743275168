import { ClearOutlined, PlusSquareOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TFinanceTrushconfirmData } from 'src/domain/finance/view/TFinanceTrushconfirmData';
import { RyRefbranch } from 'src/domain/ryRefbranch';
import { RyAccountView } from 'src/domain/view/ryAccountView';
import ryAccountService from 'src/services/bank/ryAccountService';
import tFinanceTrushconfirmService from 'src/services/finance/tFinanceTrushconfirmService';
import ryRefbranchService from 'src/services/reference/ryRefbranchService';
import Constants from 'src/utils/constants';
import { toThDate } from 'src/utils/dateTimeUtils';
import { formatNumber } from 'src/utils/numberUtils';
import TrushMoneyConfirmDrawer from './TrushMoneyConfirmDrawer';
import PageHeader from 'src/layouts/PageHeader';

const { Option } = Select;
const TrushMoneyConfirmList: React.FC<TFinanceTrushconfirmData> = () => {
  const [page, setPage] = React.useState(1);
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [onDrawerVisible, setOnDrawerVisible] = useState<boolean>(false);
  const [tfinanceTrushconfirmDatas, setTfinanceTrushconfirmDatas] = useState<TFinanceTrushconfirmData[]>([]);
  const [ryAccounts, setRyAccounts] = useState<RyAccountView[]>([]); //บัญชีธนาคาร
  const [ryRefbranches, setRyRefbranches] = useState<RyRefbranch[]>([]);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 60,
      render: (value: number, item: TFinanceTrushconfirmData, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
    },
    {
      title: "วันที่ยืนยันนำฝาก",
      dataIndex: "confirmdate",
      key: "confirmdate",
      width: 130,
      render: (value: any, item: TFinanceTrushconfirmData) => (<>{toThDate(item.tfinanceTrushConfirm.entrydate)}</>)
    },
    {
      title: "เลขที่การนำฝาก",
      dataIndex: "trushmoneyid",
      key: "trushmoneyid",
      width: 110,
      render: (value: any, item: TFinanceTrushconfirmData) => (
        <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.tfinanceTrushConfirm.trushmoneyid}</Button>
      )
    },
    {
      title: "เลขที่บัญชี",
      dataIndex: "accountNumber",
      key: "accountNumber",
      width: 200,
      render: (value: any, item: TFinanceTrushconfirmData) => (<>{item.tfinanceTrushView.accountNumber} - {item.tfinanceTrushView.bankName}</>)
    },
    {
      title: "ชื่อบัญชี",
      dataIndex: "accountName",
      key: "accountName",
      width: 150,
      render: (value: any, item: TFinanceTrushconfirmData) => (<>{item.tfinanceTrushView.accountName}</>)
    },
    {
      title: "ประเภท",
      dataIndex: "type",
      key: "type",
      width: 70,
      render: (value: any, item: TFinanceTrushconfirmData) => (<>{item.tfinanceTrushConfirm.confirmtype}</>)
    },
    {
      title: "เลขที่เช็ค",
      dataIndex: "chquenumber",
      key: "chquenumber",
      width: 170,
      render: (value: any, item: TFinanceTrushconfirmData) => (<>{item.tfinanceTrushView.chquenumber}</>)
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "amount",
      key: "amount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TFinanceTrushconfirmData) => (<>{formatNumber(item.tfinanceTrushConfirm.amount)}</>)
    },
    {
      title: "พนักงานยืนยันนำฝาก",
      dataIndex: "confirmuser",
      key: "confirmuser",
      width: 150,
      render: (value: any, item: TFinanceTrushconfirmData) => (<>{item.tfinanceTrushView.confirmuser}</>)
    },
    {
      title: "หมายเหตุ",
      dataIndex: "remark",
      key: "remark",
      width: 150,
      render: (value: any, item: TFinanceTrushconfirmData) => (<>{item.tfinanceTrushConfirm.remark}</>)
    },
    {
      title: "สาขาที่นำฝาก",
      dataIndex: "branchName",
      key: "branchName",
      width: 150,
      render: (value: any, item: TFinanceTrushconfirmData) => (<>{item.tfinanceTrushView.branchName}</>)
    },
  ];

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    initData();
  }, []);

  const initData = async () => {
    const ryAccountRes = await ryAccountService.getRyAccountViewList();
    const ryRefbranchesRes = await ryRefbranchService.getRyRefbranchList();
    setRyAccounts(ryAccountRes.data);
    setRyRefbranches(ryRefbranchesRes.data);

    form.setFieldsValue({
      fromDate: moment(new Date(), Constants.DATE_FORMAT),
      toDate: moment(new Date(), Constants.DATE_FORMAT),
      trushmoneyid: undefined,
      branchid: undefined,
      accSeq: undefined,
    });
  }

  const onItemCliked = (item: TFinanceTrushconfirmData) => {
    history.replace("/app/trushMoneyConfirm/view/" + item.tfinanceTrushConfirm.id);
  };

  function onReset() {
    initData();
  }

  const onFinish = async (values: any) => {
    setLoading(true);
    const trushconfirmId = "";
    const trushmoneyid = values.trushmoneyid ? values.trushmoneyid : "";
    const branchid = values.branchid ? values.branchid : "";
    const accSeq = values.accSeq ? values.accSeq : "";
    let fromDate = moment(values.fromDate).format("yyyyMMDD");
    let toDate = moment(values.toDate).format("yyyyMMDD");

    if (trushmoneyid) {
      fromDate = "";
      toDate = "";
    }

    const res = await tFinanceTrushconfirmService.findTFinanceTrushconfirmDatas(
      trushconfirmId,
      trushmoneyid,
      branchid,
      accSeq,
      fromDate,
      toDate);
    setTfinanceTrushconfirmDatas(res.data);
    setLoading(false);
  }

  const onFinishFailed = (errorInfo: any) => {
  };
  
  return (
    <>
      <PageHeader title="โปรแกรมยืนยันการนำฝาก">
        <Button
          size="small"
          type="primary"
          icon={<PlusSquareOutlined />}
          onClick={() => setOnDrawerVisible(true)}
        >
          เพิ่ม
        </Button>
      </PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="financeTrushconfirm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
            }}
          >
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>วันที่ยืนยันนำฝาก ตั้งแต่: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="fromDate"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ธนาคาร: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="accSeq"
                  rules={[{ required: false }]}
                >
                  <Select
                    showSearch
                    size="middle"
                    placeholder="เลือกธนาคาร"
                    optionFilterProp="children"
                    filterOption={true}
                  >
                    {ryAccounts.map((obj: RyAccountView) => {
                      return (
                        <Option key={obj.accountnumber} value={obj.seq}>
                          {obj.accountnumber} : {obj.accountname}<br />
                          <span style={{ marginLeft: "2rem", fontSize: 12 }}>
                            {obj.bankname} - สาขา{obj.branchname}
                          </span>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ถึง: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="toDate"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>สาขาที่นำฝาก: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="branchid"
                  rules={[{ required: false }]}
                >
                  <Select
                    showSearch
                    size="middle"
                    placeholder="สาขาที่นำฝาก"
                    optionFilterProp="children"
                    filterOption={true}
                  >
                    {ryRefbranches.map((obj) => {
                      return (
                        <Option key={obj.id} value={obj.id}>
                          {obj.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เลขที่ฝาก: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="trushmoneyid"
                >
                  <Input
                    allowClear
                    placeholder="ป้อนเลขที่ฝาก"
                  ></Input>
                </Form.Item>
              </Col>
              <Col span={10}></Col>
            </Row>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: 'center',
              paddingBottom: "1em",
              borderBlockEnd: "2px solid #c9c9c9",
            }}
          >
            <Space>
              <Button
                size="small"
                type="primary"
                icon={<SearchOutlined />}
                onClick={form.submit}
              >
                ค้นหา
              </Button>
              <Button
                size="small"
                type="primary"
                icon={<ClearOutlined />}
                onClick={onReset}
              >
                ล้าง
              </Button>
            </Space>
          </div>
        </Form>
        <div>
          <Table
            columns={columns}
            dataSource={tfinanceTrushconfirmDatas}
            size="small"
            loading={loading}
            scroll={{ x: 1600, y: 300 }}
            pagination={{
              pageSize: 7,
              onChange(current) {
                setPage(current);
              }
            }}
            rowKey={(item: TFinanceTrushconfirmData) => item.tfinanceTrushConfirm.id!}
            onRow={(item: TFinanceTrushconfirmData) => {
              return {
                onDoubleClick: () => onItemCliked(item),
              };
            }}
          />
        </div>
        <div>
          {onDrawerVisible && (
            <TrushMoneyConfirmDrawer
              onDrawerVisible={onDrawerVisible}
              setOnDrawerVisible={setOnDrawerVisible}
            ></TrushMoneyConfirmDrawer>
          )}
        </div>
      </Content>
    </>
  )
}

export default TrushMoneyConfirmList