import { TFinanceTrushconfirmData } from "src/domain/finance/view/TFinanceTrushconfirmData";
import http from "src/lib/http";
import Constants from "src/utils/constants";


const findTFinanceTrushconfirmDatas = (trushconfirmId: string, trushmoneyid: string, branchid: string, accseq: string, fromDate: string, toDate: string) => {
  return http.get(Constants.DEFAULT_URL + "/tFinanceTrushconfirm/findTFinanceTrushconfirmDatas", {
    params: {
      trushconfirmId,
      trushmoneyid,
      branchid,
      accseq,
      fromDate,
      toDate,
    }
  });
};

const saveTfinanceTrushconfirmData = (trushMoneyconfirmData: TFinanceTrushconfirmData) => {
  return http.post(Constants.DEFAULT_URL + "/tFinanceTrushconfirm/saveTfinanceTrushconfirmData", trushMoneyconfirmData);
};

const defaultApi = {
  findTFinanceTrushconfirmDatas,
  saveTfinanceTrushconfirmData,
}
export default defaultApi;