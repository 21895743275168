import http from "src/lib/http";
import Constants from "src/utils/constants";

const getTTypepaymentRefList = () => {
  return http.get(
    Constants.DEFAULT_URL + "/tTypepaymentRef"
  );
};

const getTTypepaymentRef = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/tTypepaymentRef/" + id);
}

const defaultApi = {
  getTTypepaymentRef,
  getTTypepaymentRefList,
};
export default defaultApi;