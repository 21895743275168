import { Col, Descriptions, Form, Input, Row, Space, Spin, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { TBankBranchHead } from 'src/domain/master/tBankBranchHead';
import { TBankHead } from 'src/domain/master/tBankHead';
import { TTypeInsurancePaymentRef } from 'src/domain/tTypeInsurancePaymentRef';
import { TWorksheetInsuranceView } from 'src/domain/worksheet/view/tWorksheetInsuranceView';
import PageHeader from "src/layouts/PageHeader";
import ryAccountService from 'src/services/bank/ryAccountService';
import tFinanceDetailsService from "src/services/finance/tFinanceDetailsService";
import tFinanceHead2Service from "src/services/finance/tFinanceHead2Service";
import tTypeInsurancePaymentRefService from 'src/services/reference/tTypeInsurancePaymentRefService';

import { TFinanceHead2View } from 'src/domain/finance/view/tFinanceHead2View';
import tWorksheetInsuranceService from 'src/services/tWorksheetInsuranceService';
import { toThDate } from 'src/utils/dateTimeUtils';
import { formatNumber } from 'src/utils/numberUtils';
import tBankBranchHeadService from "../../services/bank/tBankBranchHeadService";
import tBankHeadService from "../../services/bank/tBankHeadService";

interface IParams {
  tworksheetInsuranceId: string;
}

const ViewFinanceInsurance: React.FC<RouteComponentProps<IParams>> = (props) => {
  const tworksheetInsuranceId = props.match.params.tworksheetInsuranceId;
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [tworksheetInsuranceView, setTWorksheetInsuranceView] = useState<TWorksheetInsuranceView>({} as TWorksheetInsuranceView);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: TWorksheetInsuranceView, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่ใบสั่งงาน",
      dataIndex: "iworksheetCode",
      key: "iworksheetCode",
      width: 120,
      align: "center" as "center",
      render: (value: any, item: TWorksheetInsuranceView) => {
        return <span>{item.tworksheetHeadView?.iworksheetCode}</span>;
      },
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "icustName",
      key: "icustName",
      width: 200,
      render: (value: any, item: TWorksheetInsuranceView) => {
        return <span>{item.tworksheetHeadView?.icustName}</span>;
      },
    },
    {
      title: "ที่อยู่",
      dataIndex: "icustAddress",
      key: "icustAddress",
      render: (value: any, item: TWorksheetInsuranceView) => {
        return <span>{item.tworksheetHeadView?.icustAddress}</span>;
      },
    },
    {
      title: "รายการบริการ",
      dataIndex: "serviceName",
      key: "serviceName",
    },
    {
      title: "ค่าบริการทั้งหมด",
      dataIndex: "itotal2Amt",
      key: "itotal2Amt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetInsuranceView) => {
        return <span>{formatNumber(item.tworksheetHeadView?.itotal2Amt)}</span>;
      },
    },
    {
      title: "หมายเหตุ",
      dataIndex: "iremark",
      key: "iremark",
      render: (value: any, item: TWorksheetInsuranceView) => {
        return <span>{formatNumber(item.tworksheetHeadView?.iremark)}</span>;
      },
    },
  ];

  useEffect(() => {
    initial();
  }, []);

  useEffect(() => {
    dispalyData();
  }, [tworksheetInsuranceView]);


  const initial = async () => {
    setLoading(true);
    const res = await tWorksheetInsuranceService.getPaymentInsuranceHeadViewById(tworksheetInsuranceId);
    setTWorksheetInsuranceView(res.data)
  }

  const dispalyData = async () => {
    if (tworksheetInsuranceView.itypePaymentInsurance) {
      const tTypeInsurancePaymentRefRes = await tTypeInsurancePaymentRefService.findById(tworksheetInsuranceView.itypePaymentInsurance);
      const tTypeInsurancePaymentRef = tTypeInsurancePaymentRefRes.data as TTypeInsurancePaymentRef;

      let accountPayIn = '-';
      let insuranceNumber = '-';
      let insuranceExpire = '-';
      let bank = '-';
      let bankBranch = '-';
      if ("03" === tTypeInsurancePaymentRef.iid) {
        if (tworksheetInsuranceView.iinsuranceNumber) {
          const ryAccountsRes = await ryAccountService.getByAccountNumber(tworksheetInsuranceView.iinsuranceNumber);
          accountPayIn = ryAccountsRes.data.accountname;
        }
        insuranceNumber = tworksheetInsuranceView.ipayinAccountnumber
      } else if ("01" !== tTypeInsurancePaymentRef.iid && "99" !== tTypeInsurancePaymentRef.iid) {
        if (tworksheetInsuranceView.ibankCodeCheque) {
          const tBankHeadRes = await tBankHeadService.getBankHeadByIBankCode(tworksheetInsuranceView.ibankCodeCheque);
          const tBankHead = tBankHeadRes.data as TBankHead;
          bank = tBankHead.ibankName;
          if (tworksheetInsuranceView.ibankBranchCheque) {
            const tBankBranchHeadRes = await tBankBranchHeadService.getByBankCodeAndBankBranchCode(tBankHead.ibankCode, tworksheetInsuranceView.ibankBranchCheque)
            const tBankBranchHead = tBankBranchHeadRes.data as TBankBranchHead;
            bankBranch = tBankBranchHead.ibankBranchName;
          }
        }
        insuranceNumber = tworksheetInsuranceView.iinsuranceNumber;
        insuranceExpire = tworksheetInsuranceView.iinsuranceExpire.toString();
      }

      const tFinanceDetailsRes = await tFinanceDetailsService.getByWorksheetCode(tworksheetInsuranceView.tworksheetHeadView.iworksheetCode!);
      const tFinanceHead2Res = await tFinanceHead2Service.getTFinanceHead2CashPaymentViewData(tFinanceDetailsRes.data.parentid);
      const tFinanceHead2 = tFinanceHead2Res.data as TFinanceHead2View;

      form.setFieldsValue({
        typePaymentInsuranceName: tTypeInsurancePaymentRef.iname,
        insuranceAmt: formatNumber(tworksheetInsuranceView.iinsuranceAmt),
        accountPayIn: accountPayIn,
        paymentDate: tworksheetInsuranceView.ipayDate ? toThDate(tworksheetInsuranceView.ipayDate) : "",
        insuranceNumber: insuranceNumber,
        bank: bank,
        bankBranch: bankBranch,
        insuranceExpire: insuranceExpire,
        cashAmount: formatNumber(tFinanceHead2.cashamount),
        chequeAmount: formatNumber(tFinanceHead2.chequeamount),
        payinAmount: formatNumber(tFinanceHead2.payinamount),
        otherReceiveAmount: formatNumber(tFinanceHead2.otheramount)
      });
    }

    setLoading(false);
  }

  return (
    <>
      <PageHeader title="" onClose={() => history.push("/app/financeInsurance/list")} />
      <Spin spinning={loading}>
        <Content className="app-page-content">
          <Descriptions
            style={{ margin: "1em" }}
            column={4}
            title="ข้อมูลการเงินรับชำระเงินประกัน"
            size="middle"
          />
          <div style={{ margin: "1em" }}>
            <Form
              form={form}
              name="receiveCashWorksheet"
              labelCol={{ span: 10 }}
            >
              <Space direction="vertical" size="middle">
                <Descriptions
                  column={4}
                  size="small"
                  bordered
                  style={{ paddingLeft: '10em' }}
                  labelStyle={{ textAlign: "right", fontWeight: "bold" }}
                >
                  <Descriptions.Item label="ชื่อลูกค้า" span={2}>{tworksheetInsuranceView?.tworksheetHeadView?.custFullName}</Descriptions.Item>
                  <Descriptions.Item label="วันที่รับชำระ" span={2}>{tworksheetInsuranceView.tfinanceHead2View ? toThDate(tworksheetInsuranceView.tfinanceHead2View.entrydate) : ""}</Descriptions.Item>
                  <Descriptions.Item label="หมายเหตุ" span={2}>{tworksheetInsuranceView?.iremark}</Descriptions.Item>
                  <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fff" }}> </Descriptions.Item>
                </Descriptions>
                <Table
                  columns={columns}
                  dataSource={[tworksheetInsuranceView]}
                  size="small"
                  rowKey={(item: TWorksheetInsuranceView) => item.id!}
                  pagination={false}
                />
              </Space>
              <Row style={{ paddingTop: "1em" }}>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ประเภทเงินค้ำประกัน: </Typography.Text>
                </Col>
                <Col span={5}>
                  {<Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="typePaymentInsuranceName"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ border: 0, backgroundColor: "#fff" }} readOnly></Input>
                    </Form.Item>
                  </Space>}
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เงินสด: </Typography.Text>
                </Col>
                <Col span={9}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="cashAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ fontWeight: "bold", border: 0, textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#fff" }} readOnly></Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
              </Row>

              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ธนาคาร: </Typography.Text>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="bank"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, backgroundColor: "#fff" }} readOnly></Input>
                  </Form.Item>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เช็ค: </Typography.Text>
                </Col>
                <Col span={9}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="chequeAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ fontWeight: "bold", border: 0, textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#fff" }} readOnly></Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>สาขา: </Typography.Text>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="bankBank"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, backgroundColor: "#fff" }} readOnly></Input>
                  </Form.Item>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เงินโอน: </Typography.Text>
                </Col>
                <Col span={9}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="payinAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ fontWeight: "bold", border: 0, textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#fff" }} readOnly></Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>จำนวนเงินประกัน: </Typography.Text>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="insuranceAmt"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, backgroundColor: "#fff" }} readOnly></Input>
                  </Form.Item>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>รายรับอื่นๆ: </Typography.Text>
                </Col>
                <Col span={9}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="otherReceiveAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ fontWeight: "bold", border: 0, textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#fff" }} readOnly></Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เลขที่เช็ค/สัญญา/บัญชี: </Typography.Text>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="insuranceNumber"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, backgroundColor: "#fff" }} readOnly></Input>
                  </Form.Item>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>อายุสัญญา: </Typography.Text>
                </Col>
                <Col span={9}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="insuranceExpire"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ border: 0, backgroundColor: "#fff" }} readOnly></Input>
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ลงวันที่: </Typography.Text>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="paymentDate"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, backgroundColor: "#fff" }} readOnly></Input>
                  </Form.Item>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เงินโอนเข้าบัญชี: </Typography.Text>
                </Col>
                <Col span={9}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="accountPayIn"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ border: 0, backgroundColor: "#fff" }} readOnly></Input>
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
            </Form>
          </div>
        </Content>
      </Spin>
    </>
  );
};

export default ViewFinanceInsurance;