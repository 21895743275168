import http from "src/lib/http";
import Constants from "src/utils/constants";

const getTTypeunitRefList = () => {
  return http.get(
    Constants.DEFAULT_URL + "/tTypeunitRef"
  );
};

const getTTypeunitRef = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/tTypeunitRef/" + id);
}

const defaultApi = {
  getTTypeunitRef,
  getTTypeunitRefList,
};
export default defaultApi;
