import http from "../../lib/http";
import Constants from "../../utils/constants";

const getCustomerHeadById = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/tCustomerHead/" + id);
};

const getCustomerByParam = (nameOrPhone: string) => {
  return http.get(Constants.DEFAULT_URL + "/tCustomerHead/getCustomerHeadNameOrPhoneList", {
    params: {
      nameOrPhone
    }
  });
};

const getCustomerByCode = (code: string) => {
  return http.get(Constants.DEFAULT_URL + "/tCustomerHead/getCustomerByCode/" + code);
};

const getCustomerByCustcode = (code: string) => {
  return http.get(Constants.DEFAULT_URL + "/tCustomerHead/getCustomerByCustcode/" + code);
};

const updateCustomerHead = (customerHead: any) => {
  return http.put(Constants.DEFAULT_URL + "/tCustomerHead", customerHead);
}

const postCustomerHead = (tcustHead: any) => {
  return http.post(Constants.DEFAULT_URL + "/tCustomerHead", tcustHead);
}

const getNextCustomerHeadCode = () => {
  return http.get(Constants.DEFAULT_URL + "/tCustomerHead/nextCode");
}

const defaultApi = {
  getCustomerHeadById,
  getCustomerByParam,
  getCustomerByCode,
  getCustomerByCustcode,
  updateCustomerHead,
  postCustomerHead,
  getNextCustomerHeadCode
};
export default defaultApi;