import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, DatePicker, Form, Row, Select, Space, Table, Typography, Modal, Radio, RadioChangeEvent, Input } from 'antd';
import moment from 'moment';
import Constants from 'src/utils/constants';
import PageHeader from 'src/layouts/PageHeader';
import { Content } from 'antd/lib/layout/layout';
import { useAppSelector } from 'src/stores/store';
import { PrinterOutlined, SearchOutlined } from '@ant-design/icons';
import tTypeworksheetRefService from "src/services/reference/tTypeworksheetRefService";
import { TTypeworksheetRef } from 'src/domain/tTypeworksheetRef';
import reportService from 'src/services/reportService';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { formatNumber, formatNumberInt } from 'src/utils/numberUtils';
import { toThDate } from 'src/utils/dateTimeUtils';
import PdfViewPopup from './PdfViewPopup';
import { RyRefbranch } from 'src/domain/ryRefbranch';
import ryRefbranchService from 'src/services/reference/ryRefbranchService';
import { TWorksheetHeadView } from 'src/domain/worksheet/view/tWorksheetHeadView';
import { WorksheetPaymentReportHead } from 'src/domain/worksheet/worksheetPaymentReportHead';

const { Option } = Select;

const WorksheetPaymentReport = () => {
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [tTypeworksheetRefs, setTTypeworksheetRefs] = useState<TTypeworksheetRef[]>([]); //ประเภทงาน
  
  const [ryRefBranchs, setRyRefBranchs] = useState<RyRefbranch[]>([]);  //สาขา
  const [ryRefBranchSelected, setRyRefBranchSelected] = useState<RyRefbranch>({} as RyRefbranch);

  // const [dataList, setDataList] = useState<WorksheetPaymentReportHead[]>([]);
  const [dataObj, setDataObj] = useState<WorksheetPaymentReportHead | null>(null);
  const [dataPreview, setDataPreview] = useState<any>();
  // const [worksheetDateWorkSummary, setWorksheetDateWorkSummary] = useState<WorksheetDateWorkReportSummary>({} as WorksheetDateWorkReportSummary);

  const [selectPaymentType, setSelectPaymentType] = useState<boolean>(false);
  const [paymentType, setPaymentType] = useState<String>(null);
  const [isPaymentDisable, setIsPaymentDisable] = useState<boolean>(false);
  const [visiblePDF, setVisiblePDF] = useState(false);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false);

  const [customerNameParam, setCustomerNameParam] = useState<any>();
  const [branchIdParam, setBranchIdParam] = useState<any>();
  const [typeWorksheetCodeParam, setTypeWorksheetCodeParam] = useState<any>();
  const [fromDateParam, setFromDateParam] = useState<any>();
  const [toDateParam, setToDateParam] = useState<any>();
  const [payDateParam, setPayDateParam] = useState<any>();
  const [selectPaymentTypeParam, setSelectPaymentTypeParam] = useState<any>();
  const [typePaymentParam, setTypePaymentParam] = useState<any>();

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 10,
      align: "center" as "center",
      render: (value: number, item: WorksheetPaymentReportHead, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
    },
    {
      title: "วันที่เริ่มงาน",
      dataIndex: "startDateStr",
      key: "startDateStr",
      width: 70,
      align: "center" as "center",
      // render: (value: any, item: WorksheetPaymentReportHead) => (<>{toThDate(item.startDate)}</>)
    },
    {
      title: "วันที่สิ้นสุดงาน",
      dataIndex: "endDateStr",
      key: "endDateStr",
      width: 70,
      align: "center" as "center",
      // render: (value: any, item: WorksheetPaymentReportHead) => (<>{toThDate(item.endDate)}</>)
    },
    {
      title: "เลขที่ใบงาน",
      dataIndex: "worksheetCode",
      key: "worksheetCode",
      width: 70,
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "custName",
      key: "custName",
      width: 180,
    },
    {
      title: "รายการบริการ",
      dataIndex: "serviceName",
      key: "serviceName",
      width: 180,
    },
    {
      title: "ประเภทการชำระ",
      dataIndex: "typePaymentName",
      key: "typePaymentName",
      width: 90,
    },
    {
      title: "ค่าบริการ",
      dataIndex: "serviceAmt",
      key: "serviceAmt",
      width: 50,
      align: "right" as "right",
      render: (value: any, item: WorksheetPaymentReportHead) => {
        return <span>{formatNumber(item.serviceAmt)}</span>;
      },
    },
    {
      title: "ภาษี",
      dataIndex: "vatAmt",
      key: "vatAmt",
      width: 50,
      align: "right" as "right",
      render: (value: any, item: WorksheetPaymentReportHead) => {
        return <span>{formatNumber(item.vatAmt)}</span>;
      },
    },
    {
      title: "ค่าบริการรวม",
      dataIndex: "serviceNetAmt",
      key: "serviceNetAmt",
      width: 50,
      align: "right" as "right",
      render: (value: any, item: WorksheetPaymentReportHead) => {
        return <span>{formatNumber(item.serviceNetAmt)}</span>;
      },
    },
    {
      title: "สถานะใบงาน",
      dataIndex: "worksheetStatusName",
      key: "worksheetStatusName",
      width: 90,
    },
    {
      title: "วันที่รับชำระ",
      dataIndex: "payDate",
      key: "payDate",
      width: 70,
      align: "center" as "center",
      // render: (value: any, item: WorksheetPaymentReportHead) => (<>{toThDate(item.startDate)}</>)
    },
    {
      title: "รหัสใบแจ้งหนี้",
      dataIndex: "debitnoteCoder",
      key: "debitnoteCoder",
      width: 80,
      align: "center" as "center",
    },
  ]

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });

    (async () => {
      const tTypeworksheetRefsRes = await tTypeworksheetRefService.getTTypeworksheetRefList();
      const refbranchRes = await ryRefbranchService.getRyRefbranchList();

      setTTypeworksheetRefs(tTypeworksheetRefsRes.data);
      setRyRefBranchs(refbranchRes.data);
    })();

    initData();
    form.submit();
  }, []);

  const initData = async () => {
    form.setFieldsValue({
      customerName: undefined,
      branchId: undefined,
      typeWorksheetCode: undefined,
      // fromDate: moment(new Date(), Constants.DATE_FORMAT).startOf('month'),
      // toDate: moment(new Date(), Constants.DATE_FORMAT).endOf('month'),
      fromDate: moment(new Date(), Constants.DATE_FORMAT),
      toDate: moment(new Date(), Constants.DATE_FORMAT),
      payDate: moment(new Date(), Constants.DATE_FORMAT),
      selectPaymentType: false,
    });
  }


  const rowClassNameFn = (record: WorksheetPaymentReportHead, index) => {
    const currDate = moment(new Date(), Constants.DATE_FORMAT);
    const endDate = moment(record.endDate, Constants.DATE_FORMAT);
    var overDate = currDate.diff(endDate,  "days");

    if(record.paymentStatus === "01" && record.payDate){     
      return "row-green"
    } else if (overDate > 7) {
      return "row-red"
    }

    return null;
  }
 
  const onFinish = async (values: any) => {
    setLoading(true);
    const customerName = values.customerName ? values.customerName : "";
    const branchId = values.branchId ? values.branchId : "";
    const typeWorksheetCode = values.typeWorksheetCode ? values.typeWorksheetCode : "";
    const fromDate = moment(values.fromDate).format("yyyyMMDD");
    const toDate = moment(values.toDate).format("yyyyMMDD");
    const payDate = moment(values.payDate).format("yyyyMMDD");
    const typePayment = values.rdInOut ? values.rdInOut : "";

    const res = await reportService.getWorksheetPaymentReport(
        customerName, branchId, typeWorksheetCode, fromDate, toDate, payDate, selectPaymentType, typePayment);
    // console.log('----------res--------', res.data)
    setDataObj(res.data);

    setPage(1);
    setCustomerNameParam(customerName);
    setBranchIdParam(branchId);
    setTypeWorksheetCodeParam(typeWorksheetCode);
    setFromDateParam(fromDate);
    setToDateParam(toDate);
    setPayDateParam(payDate);
    setSelectPaymentTypeParam(selectPaymentType);
    setTypePaymentParam(typePayment);

    setLoading(false);    
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  const onSelectPaymentType = (e: CheckboxChangeEvent) => {
    let selectPaymentTypeTmp = e.target.checked;
    console.log("payment type : ", e.target.checked, paymentType, form.getFieldValue('rdInOut'))
  
    if (!selectPaymentTypeTmp) {
      setIsPaymentDisable(true);
      form.setFieldsValue({
        rdInOut: '',
      })
    } else {
      form.setFieldsValue({
        rdInOut: 'PAY_CA',
        typePayment: 'PAY_CA'
      })
    }
    setSelectPaymentType(selectPaymentTypeTmp);    
  }


  const onPrint = async () => {
    setVisiblePDF(true);
  }


  return (
    <>
      <PageHeader title="รายงานใบงานค้างชำระ"></PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="rengradWangbill"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
            }}
          >
           
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ชื่อลูกค้า: </Typography.Text>
              </Col>
              <Col span={5}>
                <Form.Item name="customerName" style={{ marginBottom: "3px" }}>
                  <Input style={{ marginRight: 5 }}></Input>
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>วันที่เริ่มงานตั้งแต่: </Typography.Text>
              </Col>
              <Col span={3}>
                <Form.Item
                  name="fromDate"
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    // onChange={form.submit}
                    style={{ width: "80%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={1} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ถึง: </Typography.Text>
              </Col>
              <Col span={3}>
                <Form.Item
                  name="toDate"
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    // onChange={form.submit}
                    style={{ width: "80%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>สาขา: </Typography.Text>
              </Col>
              <Col span={5}>
                <Form.Item
                  name="branchId"
                  style={{ marginBottom: "3px" }}
                >
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกสาขา"
                    optionFilterProp="children"
                    filterOption={true}
                    onClear={() => setRyRefBranchSelected({} as RyRefbranch)}
                  >
                    {ryRefBranchs.map((obj) => {
                      return (
                        <Option key={obj.id} value={obj.id}>
                          {obj.id}: {obj.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Form.Item 
                  name="selectPayment"
                  style={{ marginBottom: "3px" }}
                >
                  <Checkbox style={{ marginRight: "0.5em" }}
                    // checked={selectPaymentType}
                    onChange={onSelectPaymentType}
                  >
                  </Checkbox>
                  <Typography.Text>เลือกการชำระเงิน: </Typography.Text>
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item 
                  name="rdInOut"
                  style={{ marginBottom: "3px" }}
                >
                  <Radio.Group 
                    style={{ marginTop: "8px" }} 
                    disabled = {!selectPaymentType}
                    onChange={(e:RadioChangeEvent) => {
                      console.log('onChange -> radio value: ', e.target.value);
                      setPaymentType(e.target.value);
                      form.setFieldsValue({
                        rdInOut: e.target.value
                      })
                    }}
                  >
                    <Radio value="PAY_CA" >ใบสั่งงานจ่ายเงินสด</Radio>
                    <Radio value="PAY_CR" >ใบสั่งเงินเชื่อ</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ประเภทงาน: </Typography.Text>
              </Col>
              <Col span={5}>
                <Form.Item
                  name="typeWorksheetCode"
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกประเภทงาน"
                    optionFilterProp="children"
                  // onSelect={form.submit}
                  >
                    {tTypeworksheetRefs.map((obj) => {
                      return <Option value={obj.iid} key={obj.iid}>{obj.iname}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ค้างชำระ ณ วันที่: </Typography.Text>
              </Col>
              <Col span={3}>
                <Form.Item
                  name="payDate"
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    // onChange={form.submit}
                    style={{ width: "80%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={4} />
            </Row>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: 'center',
              paddingBottom: "1em",
              // borderBlockEnd: "2px solid #c9c9c9",
            }}
          >
            <Space>
              <Button
                size="small"
                type="primary"
                icon={<SearchOutlined />}
                onClick={form.submit}
              >
                ค้นหา
              </Button>
            </Space>
          </div>
        </Form>
           
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            // margin: '0 0 10px 0',
            padding: "0px 20px 10px 0px",
            borderBottom: '2px solid #c9c9c9'
          }}
        >
          <Button
          style={{
            marginLeft: "30px",}}
            size="small"
            type="primary"
            icon={<PrinterOutlined />}
            onClick={onPrint}
          >
            พิมพ์
          </Button>
          <Modal
            style={{ top: 20 }}
            width={"90vw"}
            title="RS001"
            bodyStyle={{ height: "85vh" }}
            centered={true}
            visible={isPreviewModalVisible}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={() => setIsPreviewModalVisible(false)}
          >
            {/* <iframe title=" " src={worksheetDateWorkPreview} width={"100%"} height={"100%"}></iframe> */}
          </Modal>
        </div>

        <div>
          <Table
            rowClassName={rowClassNameFn}
            columns={columns}
            dataSource={dataObj ? dataObj.details : []}
            size="small"
            loading={loading}
            rowKey={(item: WorksheetPaymentReportHead) => item.worksheetCode!}
            scroll={{ x: 2200 }}
            pagination={{
              pageSize: 10,
              current: page,
              onChange(current) {
                setPage(current);
              }
            }}
          />
        </div>

        <div style={{
            padding: "20px 0px 0px 0px"
          }}>
          <Row>
            <Col span={9}>
              <Typography.Text style={{ color: "red", marginLeft: "0.5em" }}> [หมายเหตุ : ยังไม่ได้ชำระเงินหลังจากงานเสร็จเกิน 7 วัน] </Typography.Text>
            </Col>
            <Col span={10} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> จำนวนใบงาน : </Typography.Text>
            </Col>
            <Col span={3} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> {formatNumberInt(dataObj ? dataObj.countWsh : 0)} </Typography.Text>
            </Col>
            <Col span={1} style={{ textAlign: "left", marginLeft: "2em" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> ใบ </Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col span={19} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> ค่าบริการ : </Typography.Text>
            </Col>
            <Col span={3} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold", color: "red" }}> {formatNumber(dataObj ? dataObj.serviceTotalAmt : 0)} </Typography.Text>
            </Col>
            <Col span={1} style={{ textAlign: "left", marginLeft: "2em" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> บาท </Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col span={19} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> ภาษี : </Typography.Text>
            </Col>
            <Col span={3} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold", color: "red" }}> {formatNumber(dataObj ? dataObj.vatTotalAmt : 0)} </Typography.Text>
            </Col>
            <Col span={1} style={{ textAlign: "left", marginLeft: "2em" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> บาท </Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col span={19} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> ค้างชำระ ณ วันที่ {dataObj ? dataObj.payDateHead : 0} : </Typography.Text>
            </Col>
            <Col span={3} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold", color: "red" }}> {formatNumber(dataObj ? dataObj.serviceNetTotalAmt : 0)} </Typography.Text>
            </Col>
            <Col span={1} style={{ textAlign: "left", marginLeft: "2em" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> บาท </Typography.Text>
            </Col>
          </Row>
        </div>
      </Content>

      <Modal
        title= 'print'
        centered
        closable={true}
        visible={visiblePDF}
        onCancel={() => setVisiblePDF(false)}
        width="400"
      >
        <PdfViewPopup
          urlPath={reportService.getWorksheetPaymentReportPreview(customerNameParam, branchIdParam, typeWorksheetCodeParam, 
                        fromDateParam, toDateParam, payDateParam, selectPaymentTypeParam, typePaymentParam)}
          onClose={() => setVisiblePDF(false)}
        />
      </Modal>
    </>
  )
}

export default WorksheetPaymentReport