import { ArrowDownOutlined, ArrowUpOutlined, CloseCircleOutlined, ExclamationCircleOutlined, PlusSquareOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, Drawer, Form, Input, InputNumber, Modal, Row, Select, Space, Table, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { TChequeHead } from 'src/domain/cheque/tChequeHead';
import { TFinanceChequedetail } from 'src/domain/finance/tFinanceChequedetail';
import { TFinanceHead2 } from 'src/domain/finance/tFinanceHead2';
import { TFinanceOtherworkdetail } from 'src/domain/finance/tFinanceOtherworkdetail';
import { TFinancePayindetail } from 'src/domain/finance/tFinancePayindetail';
import { FinanceData } from 'src/domain/finance/view/financeData';
import { TServiceHeadView } from 'src/domain/master/view/tServiceHeadView';
import { TCreditnoteHead } from 'src/domain/tCreditnoteHead';
import { TDecimal } from 'src/domain/tDecimal';
import { TPayinHead } from 'src/domain/tPayinHead';
import { TWshtaxDetail } from 'src/domain/tWshtaxDetail';
import { TWshtaxDetailView } from 'src/domain/view/tWshtaxDetailView';
import { TWorksheetDetail } from 'src/domain/worksheet/tWorksheetDetail';
import { TWorksheetHeadView } from 'src/domain/worksheet/view/tWorksheetHeadView';
import tCreditnoteHeadService from 'src/services/finance/tCreditnoteHeadService';
import tFinanceHead2Service from 'src/services/finance/tFinanceHead2Service';
import tServiceHeadService from 'src/services/tservice/tServiceHeadService';
import tWorksheetDetailService from 'src/services/tWorksheetDetailService';
import tWorksheetHeadService from 'src/services/tWorksheetHeadService';
import tWshtaxDetailService from 'src/services/tWshtaxDetailService';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber, textNumber } from 'src/utils/numberUtils';
import AddTChequeHeadModal from '../modals/AddTChequeHeadModal';
import AddTPayinHeadModal from '../modals/AddTPayinHeadModal';
import AddTWshtaxDetailModal from '../modals/AddTWshtaxDetailModal';
import SearchCreditNoteDiscountModal from '../modals/SearchCreditNoteDiscountModal';
import SearchOtherWorkSearchModal from '../modals/SearchOtherWorkSearchModal';
import SearchTChequeHeadModal from '../modals/SearchTChequeHeadModal';
import SearchTPayinHeadModal from '../modals/SearchTPayinHeadModal';

const { Option } = Select;
const { confirm } = Modal;

interface IProps {
  onDrawerVisible: boolean;
  setOnDrawerVisible: any;
  selectWorksheetHead: TWorksheetHeadView;
  setSelectWorksheetHead: any;
}

const FinanceReceivableDrawer: React.FC<IProps> = (props) => {
  const { onDrawerVisible, setOnDrawerVisible, selectWorksheetHead, setSelectWorksheetHead } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [form] = Form.useForm();

  const [tFinanceChequeDetails, setTFinanceChequeDetails] = useState<TFinanceChequedetail[]>([]);
  const [tFinancePayinDetails, setTFinancePayinDetails] = useState<TFinancePayindetail[]>([]);
  const [tFinanceOtherWorkDetails, setTFinanceOtherWorkDetails] = useState<TFinanceOtherworkdetail[]>([]);

  const [addTChequeHeadModalVisible, setAddTChequeHeadModalVisible] = useState<boolean>(false);
  const [searchTChequeHeadModalVisible, setSearchTChequeHeadModalVisible] = useState<boolean>(false);
  const [addTPayinHeadModalVisible, setAddTPayinHeadModalVisible] = useState<boolean>(false);
  const [searchTPayinHeadModalVisible, setSearchTPayinHeadModalVisible] = useState<boolean>(false);
  const [searchOtherWorkModalVisible, setSearchOtherWorkModalVisible] = useState<boolean>(false);
  const [addTWshtaxDetailModalVisible, setAddTWshtaxDetailModalVisible] = useState<boolean>(false);
  const [searchCreditNoteModalVisible, setSearchCreditNoteModalVisible] = useState<boolean>(false);  

  const [isCreditNote, setIsCreditNote] = useState<boolean>(false);
  const [isWithholdingTax, setIsWithholdingTax] = useState<boolean>(false);
  
  const [tWorksheetDetails, setTWorksheetDetails] = useState<TWorksheetDetail[]>([]);
  const [tCreditnoteHeads, setTCreditnoteHeads] = useState<TCreditnoteHead[]>([]);
  const [tServiceHead, setTServiceHead] = useState<TServiceHeadView>({} as TServiceHeadView);
  const [tWshtaxDetail, setTWshtaxDetail] = useState<TWshtaxDetail>({} as TWshtaxDetail);
  const [newTWshtaxDetailView, setNewTWshtaxDetailView] = useState<TWshtaxDetailView>({ worksheetcode: selectWorksheetHead.iworksheetCode, reservecode: selectWorksheetHead.ireserveCode!, customercode: selectWorksheetHead.icustCode! } as TWshtaxDetailView);
  
  const newTChequeHead = { financetype: selectWorksheetHead.itypeworksheetCode!, customercode: selectWorksheetHead.icustCode! } as TChequeHead;
  const newTPayinHead = { financetype: selectWorksheetHead.itypeworksheetCode!, customercode: selectWorksheetHead.icustCode! } as TPayinHead;
  const tFinanceOtherworkdetail: TFinanceOtherworkdetail = { customercode: selectWorksheetHead.icustCode! } as TFinanceOtherworkdetail

  const [buttonRemainUpDisabled, setButtonRemainUpDisabled] = useState<boolean>(false);
  const [buttonRemainDownDisabled, setButtonRemainDownDisabled] = useState<boolean>(false);


  const [absAmountStr, setAbsAmountStr] = useState<string>("");
  const [decimal, setDecimal] = useState(0.00);
  const [decimalR, setDecimalR] = useState(0.00);

  const [isRecal, setIsRecal] = useState<boolean>(false);

  useEffect(() => {
    if (trigger) {
      if (isValid()) {
        setTrigger(false);
        return;
      }

      form.validateFields()
        .then(() => {
          confirm({
            title: "ยืนยัน!",
            icon: <ExclamationCircleOutlined />,
            content: "คุณต้องการที่จะบันทึกข้อมูลหรือไม่?",
            cancelText: "ยกเลิก",
            okText: "ยืนยัน",
            onOk() {
              form.submit();
            },
            onCancel() {
              setTrigger(false);
            }
          });
        }).catch(() => {
          setTrigger(false);
        });
    }
  }, [trigger]);

  let sumservice = 0.00;
  let total = 0.00;
  let vat = 0.00;
  const serviceamt = ((selectWorksheetHead.iserviceAmt ? Number(selectWorksheetHead.iserviceAmt) : 0) + (selectWorksheetHead.iotAmt ? Number(selectWorksheetHead.iotAmt) : 0) + (selectWorksheetHead.iextraAmt ? Number(selectWorksheetHead.iextraAmt) : 0)) - (selectWorksheetHead.idiscount ? Number(selectWorksheetHead.idiscount) : 0);
  if ("01" === selectWorksheetHead.itypetaxCode) {
    vat = Math.round((serviceamt * 7 / 100) * 100) / 100;
    total = serviceamt;
  }
  if ("02" === selectWorksheetHead.itypetaxCode) {
    vat = Math.round((serviceamt * 7 / 107) * 100) / 100;
    total = serviceamt - vat;
  }
  if ("03" === selectWorksheetHead.itypetaxCode) {
    vat = 0;
    total = serviceamt;
  }
  const total2 = total + vat;
  let remaint = total2 - ((selectWorksheetHead.purchasetax ? Number(selectWorksheetHead.purchasetax) : 0) - (selectWorksheetHead.payedamount ? Number(selectWorksheetHead.payedamount) : 0));
  sumservice += remaint;

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: TWorksheetHeadView, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่ใบสั่งงาน",
      dataIndex: "iworksheetCode",
      key: "iworksheetCode",
      width: 120,
      align: "center" as "center",
    },
    {
      title: "รายการ",
      dataIndex: "serviceNameRemarks",
      key: "serviceNameRemarks",
      width: 350,
      align: "center" as "center",
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "iserviceAmt",
      key: "iserviceAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iserviceAmt)}</span>;
      },
    },
    {
      title: "OT",
      dataIndex: "iotAmt",
      key: "iotAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iotAmt)}</span>;
      },
    },
    {
      title: "ค่าพิเศษ",
      dataIndex: "iextraAmt",
      key: "iextraAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.iextraAmt)}</span>;
      },
    },
    {
      title: "ส่วนลด",
      dataIndex: "idiscount",
      key: "idiscount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.idiscount)}</span>;
      },
    },
    {
      title: "รวม",
      dataIndex: "total1Amt",
      key: "total1Amt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(total)}</span>;
      },
    },
    {
      title: "ภาษี",
      dataIndex: "vatAmt",
      key: "vatAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(vat)}</span>;
      },
    },
    {
      title: "สุทธิ",
      dataIndex: "total2Amt",
      key: "total2Amt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(total2)}</span>;
      },
    },
    {
      title: "ชำระแล้ว",
      dataIndex: "idepositAmt",
      key: "idepositAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.idepositAmt)}</span>;
      },
    },
    {
      title: "คงเหลือ",
      dataIndex: "remainAmt",
      key: "remainAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(remaint)}</span>;
      },
    },
    {
      title: "หมายเหตุ",
      dataIndex: "iremark",
      key: "iremark",
      width: 80,
      align: "center" as "center",
    },
  ];

  const initial = async () => {
    const tCreditnoteHeadRes = await tCreditnoteHeadService.isExistByWorksheetCode(selectWorksheetHead.iworksheetCode!)
    const tWorksheetDetailRes = await tWorksheetDetailService.getByReserveCode(selectWorksheetHead.ireserveCode!);
    const tWshtaxDetailRes = await tWshtaxDetailService.getByWorksheetCode(selectWorksheetHead.iworksheetCode!);
    if (tWorksheetDetailRes.data[0]) {
      const tWorksheetDetail = tWorksheetDetailRes.data[0] as TWorksheetDetail
      const tServiceHeadViewRes = await tServiceHeadService.getTServiceHeadByCode(tWorksheetDetail.iserviceCode!);
      setTServiceHead(tServiceHeadViewRes.data)
    }
    setTWorksheetDetails(tWorksheetDetailRes.data);
    setIsCreditNote(tCreditnoteHeadRes.data);
    setIsWithholdingTax(tCreditnoteHeadRes.data || tWshtaxDetailRes.data)
    setTWshtaxDetail(tWshtaxDetailRes.data);
    setRemark(tWorksheetDetailRes.data[0]);
    setIsRecal(true);
    // sumationData();   

    form.setFieldsValue({
      cashAmount: formatNumber(0),
    });
  }

  useEffect(() => {
    if (isRecal) {
      sumationData();
    }
  }, [isRecal]);

  useEffect(() => {
    if (onDrawerVisible) {
      initial();
    }
  }, [onDrawerVisible]);

  useEffect(() => {
    if (!searchTChequeHeadModalVisible && isRecal) {

      let chequeAmount = 0;
      if (tFinanceChequeDetails.length > 0) {
        tFinanceChequeDetails.forEach(obj => {
          chequeAmount += (obj.amount) ? obj.amount : 0;
        });
      }
      form.setFieldsValue({
        chequeAmount: formatNumber(chequeAmount),
      });
      recalculate();
    }
  }, [searchTChequeHeadModalVisible]);

  useEffect(() => {
    if (!searchTPayinHeadModalVisible && isRecal) {

      let payinAmount = 0;
      if (tFinancePayinDetails.length > 0) {
        tFinancePayinDetails.forEach(obj => {
          payinAmount += (obj.amount) ? obj.amount : 0;
        });
      }
      form.setFieldsValue({
        payinAmount: formatNumber(payinAmount),
      });
      recalculate();
    }
  }, [searchTPayinHeadModalVisible]);

  useEffect(() => {
    (async () => {
      if (!addTWshtaxDetailModalVisible && isRecal) {
        if (newTWshtaxDetailView.remianAmountStr && newTWshtaxDetailView.worksheetcode) {
          const worksheetRes = await tWorksheetHeadService.getByWorksheetCodeView(selectWorksheetHead.iworksheetCode!);
          sumationData();
          setDecimal(0.00);
          const fraction = Number('0' + newTWshtaxDetailView.remianAmountStr.substring(newTWshtaxDetailView.remianAmountStr.indexOf('.')));
          if (0 < fraction) {
            setButtonRemainUpDisabled(false);
            setButtonRemainDownDisabled(false);
          } else {
            setButtonRemainUpDisabled(true);
            setButtonRemainDownDisabled(true);
          }
          setIsWithholdingTax(newTWshtaxDetailView.taxamount > 0);
          setSelectWorksheetHead({ ...worksheetRes.data });
        }
      }
    })();
  }, [addTWshtaxDetailModalVisible]);

  useEffect(() => {
    if (!searchOtherWorkModalVisible && isRecal) {
      let otherReceiveAmount = 0;
      if (tFinanceOtherWorkDetails.length > 0) {
        tFinanceOtherWorkDetails.forEach(obj => {
          otherReceiveAmount += (obj.amount) ? obj.amount : 0;
        });
      }
      form.setFieldsValue({
        otherReceiveAmount: formatNumber(otherReceiveAmount),
      });
      recalculate();
    }
  }, [searchOtherWorkModalVisible]);

  useEffect(() => {
    if (!searchCreditNoteModalVisible && isRecal) {
      let creditnoteAmount = 0;
      if (tCreditnoteHeads.length > 0) {
        tCreditnoteHeads.forEach(obj => {
          creditnoteAmount += (obj.creditTotal) ? obj.creditTotal : 0;
        });
      }
      form.setFieldsValue({
        creditNoteDiscountAmt: formatNumber(creditnoteAmount),
      });
      recalculate();
    }
  }, [searchCreditNoteModalVisible]);

  const setRemark = (tWorksheetDetail: TWorksheetDetail) => {
    let remark = '';
    if (selectWorksheetHead.typeworksheetflag !== "02" && selectWorksheetHead.typeworksheetflag !== "04" && selectWorksheetHead.typeworksheetflag !== '05') {
      // if (tWorksheetDetails && tWorksheetDetails.length > 0) {
      remark = tWorksheetDetail?.iserviceName?.trim() + (tWorksheetDetail.iremark ? " - " + tWorksheetDetail.iremark : "");
      // }
    } else if (selectWorksheetHead.typeworksheetflag === "04") {
      remark = "รับเงินจากใบงานเหมาแผ่นเหล็ก";
    } else if (selectWorksheetHead.typeworksheetflag === "05") {
      remark = "รับเงินจากใบงานขาย/ซ่อม/อื่นๆ";
    } else {
      remark = "รับเงินจากใบงานเหมา";
    }
    form.setFieldsValue({
      remark: remark,
    });
  }

  const recalculate = async () => {
    let amountBeforVat = 0.00;
    let vatamount = 0.00;
    let sumtotal = 0.00;
    let purchaseTax = 0.00;
    let sumremaint = 0.00;
    let total = 0.00;
    let serviceamt = 0.00;
    let vat = 0.00;
    let total2 = 0.00;
    let sumPay = 0.00;
    if ((selectWorksheetHead.allinstalment ? selectWorksheetHead.allinstalment : 0) > 1 //มีจำนวนงวดมากกว่า 1 งวด
      && selectWorksheetHead.itypepaymentCode !== '01' //ไม่ใช่สดก่อนเริ่มงาน
      && selectWorksheetHead.itypepaymentCode !== '02'//ไม่ใช่สดเมื่องานเสร็จ
      && selectWorksheetHead.typeworksheetflag !== '02' //ไม่ใช่ใบงานเหมา
      && selectWorksheetHead.typeworksheetflag !== '05'//ไม่ใช่ใบงานซ่อม/ขายอื่นๆ
      && selectWorksheetHead.typeworksheetflag !== '04') {//ไม่ใช่ใบงานเหมาแผ่นเหล็ก
      if (selectWorksheetHead.idepositFlg !== 1) {//กรณีชำระเงินมัดจำเรียบร้อย
        if ((selectWorksheetHead.allinstalment ? selectWorksheetHead.allinstalment : 0) > 12) {//ชำระงวดที่มากกว่า 12
          serviceamt = tServiceHead.iunitPrice3;
        } else if (((selectWorksheetHead.allinstalment ? selectWorksheetHead.allinstalment : 0) > 3) &&
          ((selectWorksheetHead.allinstalment ? selectWorksheetHead.allinstalment : 0) <= 12)) {//ชำระงวดที่น้อยกว่า 12 และมากกว่างวดที่ 3
          serviceamt = tServiceHead.iunitPrice2;
        } else {//ชำระเงินงวดปกติ
          serviceamt = tServiceHead.iunitPrice;
        }
      } else {//กรณีชำระเงินมัดจำ
        serviceamt = tServiceHead.iunitPrice + (selectWorksheetHead.iextraAmt ? selectWorksheetHead.iextraAmt : 0) + (selectWorksheetHead.iotAmt ? selectWorksheetHead.iotAmt : 0);
      }
      serviceamt = serviceamt - ((selectWorksheetHead.idiscount ? selectWorksheetHead.idiscount : 0) / (selectWorksheetHead.allinstalment ? selectWorksheetHead.allinstalment : 1));
    } else {//กรณีเป็นสดก่อนเริ่มงาน
      serviceamt = (selectWorksheetHead.iserviceAmt ? selectWorksheetHead.iserviceAmt : 0) + (selectWorksheetHead.iotAmt ? selectWorksheetHead.iotAmt : 0)
        + (selectWorksheetHead.iextraAmt ? selectWorksheetHead.iextraAmt : 0) - (selectWorksheetHead.idiscount ? selectWorksheetHead.idiscount : 0);
    }
    if ("01" === selectWorksheetHead.itypetaxCode) {//กรณีเป็น Vat นอก
      vat = Math.round((serviceamt * 7 / 100) * 100) / 100;
      total = serviceamt;
    }
    if ("02" === selectWorksheetHead.itypetaxCode) {//กรณีเป็น Vat  ใน
      vat = Math.round((serviceamt * 7 / 107) * 100) / 100;
      total = serviceamt - vat;
    }
    if ("03" === selectWorksheetHead.itypetaxCode) {//กรณีไม่มี Vat
      vat = 0;
      total = serviceamt;
    }

    amountBeforVat += total;
    vatamount += vat;
    total2 = total + vat;
    sumtotal += total2;

    //if ((selectWorksheetHead.allinstalment ? selectWorksheetHead.allinstalment : 0) > 1 //ตัดทิ้งเนื่องจาก ใช้กับทุกกรณีเพราะไม่ได้ป้อนที่ใบงานแล้ว
    if (selectWorksheetHead.itypepaymentCode !== '01' //กรณีจำนวนงวดมากกว่า 1งวด,ไม่ใช่สดก่อนเริ่มงาน,ถูกหักภาษีณที่จ่าย
      && tWshtaxDetail.worksheetcode) {
      purchaseTax = (tWshtaxDetail.taxamount ? tWshtaxDetail.taxamount : 0);
    } else {
      //purchaseTax = (selectWorksheetHead.purchasetax ? selectWorksheetHead.purchasetax : 0);//ไม่ใช้อันนี้ เพราะไม่ได้ป้อนที่ใบงานแล้ว
      //แก้เป็นเช็คตามความสำคัญ ดังนี้
      if (selectWorksheetHead.purchasetax && selectWorksheetHead.purchasetax > 0) {
        purchaseTax = selectWorksheetHead.purchasetax;
      } else if (tWshtaxDetail.taxamount > 0) {
        purchaseTax = tWshtaxDetail.taxamount;
      }
    }

    if (selectWorksheetHead.itypepaymentCode === "01") {
      sumremaint = sumtotal - purchaseTax;
    } else if (selectWorksheetHead.itypepaymentCode === "02") {
      sumremaint = sumtotal - purchaseTax;
    } else if (selectWorksheetHead.itypepaymentCode === "03") {
      if (selectWorksheetHead.typeworksheetflag !== "02" && selectWorksheetHead.typeworksheetflag !== "04" && selectWorksheetHead.typeworksheetflag !== "05") {
        if (selectWorksheetHead.truckAssettype === "002") {
          sumremaint = sumtotal - purchaseTax;
        } else {
          if (selectWorksheetHead.idepositFlg !== 1) {
            sumremaint = (selectWorksheetHead.iremainAmt ? selectWorksheetHead.iremainAmt : 0);
          } else {
            sumremaint = (selectWorksheetHead.idepositAmt ? selectWorksheetHead.idepositAmt : 0) - purchaseTax;
          }
        }
      } else {
        if (selectWorksheetHead.idepositFlg !== 1) {
          sumremaint = (selectWorksheetHead.iremainAmt ? selectWorksheetHead.iremainAmt : 0);
        } else {
          sumremaint = (selectWorksheetHead.idepositAmt ? selectWorksheetHead.idepositAmt : 0) - purchaseTax;
        }
      }
    }

    form.setFieldsValue({
      numServiceAmt: formatNumber(amountBeforVat),
      numVatAmt: formatNumber(vatamount),
      numTotal: formatNumber(sumtotal),
      numTaxAmount: formatNumber(purchaseTax),
    });

    if (decimal === 0) {
      form.setFieldsValue({
        numNetTotal: formatNumber(sumremaint),
      });
    }
    const cashAmount = form.getFieldValue('cashAmount') ? parserNumber(form.getFieldValue('cashAmount')) : 0;
    const chequeAmount = form.getFieldValue('chequeAmount') ? parserNumber(form.getFieldValue('chequeAmount')) : 0;
    const payinAmount = form.getFieldValue('payinAmount') ? parserNumber(form.getFieldValue('payinAmount')) : 0;
    const otherReceiveAmount = form.getFieldValue('otherReceiveAmount') ? parserNumber(form.getFieldValue('otherReceiveAmount')) : 0;
    const creditNoteAmount = form.getFieldValue('creditNoteDiscountAmt') ? parserNumber(form.getFieldValue('creditNoteDiscountAmt')) : 0;

    sumPay = cashAmount + chequeAmount + payinAmount + otherReceiveAmount + creditNoteAmount;
    form.setFieldsValue({
      numNetAmount: formatNumber(sumPay), //จำนวนเงินที่ต้องรับชำระ
    });
  }

  const sumationData = () => {

    setDecimal(0.00);
    recalculate();
    const numNetTotalStr = form.getFieldValue('numNetTotal') ? String(form.getFieldValue('numNetTotal')) : "0.00";
    setAbsAmountStr(numNetTotalStr.substring(0, numNetTotalStr.indexOf('.')));
    const fraction = Number('0' + numNetTotalStr.substring(numNetTotalStr.indexOf('.')));
    setDecimalR(parserNumber(fraction));
    if (0 < fraction) {
      setButtonRemainUpDisabled(false);
      setButtonRemainDownDisabled(false);
    } else {
      setButtonRemainUpDisabled(true);
      setButtonRemainDownDisabled(true);
    }
  }

  const onButtonRemainUpClicked = () => {
    let sum = 0.00;
    let expectFraction = 0.00;
    const numNetTotalStr = form.getFieldValue('numNetTotal') ? String(form.getFieldValue('numNetTotal')) : "0.00";
    const fraction = Number('0' + numNetTotalStr.substring(numNetTotalStr.indexOf('.')));
    if (fraction > 0 && fraction < 1) {
      if (fraction < 0.25) {
        expectFraction = 0.25;
      } else if (fraction < 0.50) {
        expectFraction = 0.50;
      } else if (fraction < 0.75) {
        expectFraction = 0.75;
      } else {
        expectFraction = 1;
      }

      sum = expectFraction + parserNumber(absAmountStr);
      form.setFieldsValue({
        numNetTotal: formatNumber(sum),
      });

      if (sum === parserNumber(absAmountStr) + 1) {
        setButtonRemainUpDisabled(true);
      }
    } else if (fraction === 0) {
      expectFraction = 0.25;
      sum = expectFraction + parserNumber(absAmountStr);
      form.setFieldsValue({
        numNetTotal: formatNumber(sum)
      });
    }
    setDecimal(parserNumber(expectFraction - Number(decimalR)));
  }

  const onButtonTWshtaxDetailClicked = () => {
    if (selectWorksheetHead.iworksheetCode) {
      if (tWshtaxDetail.taxamount > 0) {
        showNotification(NotificationType.ERROR, "มีการบันทึกข้อมูลอยู่แล้ว!");
      } else {
        setAddTWshtaxDetailModalVisible(true)
      }
    } else {
      showNotification(NotificationType.ERROR, "กรุณาระบุเลขที่ใบสั่งงานใหม่อีกครั้ง!");
    }
  }

  const onButtonRemainDownClicked = () => {
    let sum = 0.00;
    let expectFraction = 0.00;
    const numNetTotalStr = form.getFieldValue('numNetTotal') ? String(form.getFieldValue('numNetTotal')) : "0.00";
    const fraction = Number('0' + numNetTotalStr.substring(numNetTotalStr.indexOf('.'), numNetTotalStr.length));
    if (fraction > 0 && fraction < 1) {
      if (fraction <= 0.25) {
        expectFraction = 0;
      } else if (fraction <= 0.50) {
        expectFraction = 0.25;
      } else if (fraction <= 0.75) {
        expectFraction = 0.50;
      } else {
        expectFraction = 0.75;
      }
      sum = expectFraction + parserNumber(absAmountStr);
      form.setFieldsValue({
        numNetTotal: formatNumber(sum),
      });

      if (sum === parserNumber(absAmountStr)) {
        setButtonRemainDownDisabled(true);
      }
    } else if (fraction === 0) {
      expectFraction = 0.75;
      sum = expectFraction + parserNumber(absAmountStr);
      form.setFieldsValue({
        numNetTotal: formatNumber(sum)
      });
    }
    setDecimal(parserNumber(expectFraction - Number(decimalR)));
  }

  const onFinish = async (values: any) => {
    const _financeData: FinanceData = {
      tfinanceHead2: {
        ...({} as TFinanceHead2),
        cashamount: parserNumber(values.cashAmount),//จำนวนเงินสด
        financetype: selectWorksheetHead.itypeworksheetCode === '02' ? '02' : '01', // '02' การชำระเงินใบงานใน, '01' การชำระเงินใบงานนอก
        taxamount: selectWorksheetHead.itypeworksheetCode === '02' ? 0.00 : parserNumber(values.numTaxAmount),//ภาษี
        customercode: selectWorksheetHead.icustCode ? selectWorksheetHead.icustCode : '',//รหัสลูกค้า
        customername: selectWorksheetHead.icustName ? selectWorksheetHead.icustName : '',//ชื่อลูกค้า
        chequeamount: parserNumber(values.chequeAmount),//จำนวนเงินจากเช็ค
        remarks: values.remark,//หมายเหตุการชำระเงิน
        payinamount: parserNumber(values.payinAmount),//จำนวนเงินจากเงินโอน
        paymenttype: '01', // ประเภทใบงาน-01,ใบแจ้งหนี้-02,รายการอื่นๆ-03
        worksheetReturnStatus: values.receiveDocument === 'YES' ? 1 : 0, //การรับเอกสาร '1' ได้รับเอกสาร, '0' ไม่ได้รับเอกสาร
        otheramount: parserNumber(values.otherReceiveAmount),
        creditcardamount: 0.0, // TODO: no CreditCard information,
        creditnoteDiscount: parserNumber(values.creditNoteDiscountAmt),//จำนวนเงินจากใบลดหนี้
      },
      tworksheetHeadView: {
        ...selectWorksheetHead,
        payedamount: (selectWorksheetHead.payedamount ? selectWorksheetHead.payedamount : 0) + parserNumber(values.numNetAmount),//เพิ่มจำนวนเงินที่ชำระ
        iremainAmt: (selectWorksheetHead.iremainAmt ? selectWorksheetHead.iremainAmt : 0) - parserNumber(values.numNetAmount),//ลดจำนวนเงินค้างชำระ
      },
      tdecimal: {
        ...({} as TDecimal),
        idecimal: decimal.toString()
      },
      tfinanceChequeDetailList: tFinanceChequeDetails,
      tfinancePayinDetailList: tFinancePayinDetails,
      tfinanceOtherWorkDetailList: tFinanceOtherWorkDetails,
      tcreditnoteHeadList: tCreditnoteHeads
    }
    try {
      await tFinanceHead2Service.saveFinanceData(_financeData);
      showNotification(NotificationType.SUCCESS, "บันทึกรับชำระใบงานสดเรียบร้อยแล้ว!");
      form.resetFields();
      setTrigger(false);
      setOnDrawerVisible(false);
    } catch (err) {
      showNotification(NotificationType.ERROR, "เกิดข้อผิดพลาด กรุณาติดต่อผู้แลระบบ !");
      setTrigger(false);
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน");
    setTrigger(false);
  };

  const isValid = () => {
    const numNetTotal = form.getFieldValue('numNetTotal') ? parserNumber(form.getFieldValue('numNetTotal')) : 0;
    const numNetAmount = form.getFieldValue('numNetAmount') ? parserNumber(form.getFieldValue('numNetAmount')) : 0;
    const creditnoteAmount = form.getFieldValue('creditNoteDiscountAmt') ? parserNumber(form.getFieldValue('creditNoteDiscountAmt')) : 0;
    if (!creditnoteAmount && isCreditNote) {
      showNotification(NotificationType.ERROR, "ใบงานนี้มีการออกใบลดหนี้ กรุณาระบุเลขที่ใบลดหนี้")
      return true;
    }

    if ((selectWorksheetHead.itypepaymentCode === "01" || selectWorksheetHead.itypepaymentCode === "03") && selectWorksheetHead.idepositFlg === 1) {//กรณีรับเงินสดก่อน,ไม่ได้ชำระเงินมัดจำ
      let deposiAmt = 0.00;
      if (selectWorksheetHead.typeworksheetflag !== "02" && selectWorksheetHead.typeworksheetflag !== "04" && selectWorksheetHead.typeworksheetflag !== "05") {//กรณีไม่ใช่ใบงานเหมา
        if (selectWorksheetHead.truckAssettype !== "002") {//กรณีไม่ใช่ตู้
          deposiAmt = (selectWorksheetHead.idepositAmt ? selectWorksheetHead.idepositAmt : 0) - (selectWorksheetHead.purchasetax ? selectWorksheetHead.purchasetax : 0) + decimal;
        }
        //jackciman28-5-50
        else {//กรณีเป็นตู้
          deposiAmt = numNetTotal;
        }
      } else {//กรณีเป็นใบงานเหมา
        deposiAmt = (selectWorksheetHead.idepositAmt ? selectWorksheetHead.idepositAmt : 0) - (selectWorksheetHead.purchasetax ? selectWorksheetHead.purchasetax : 0) + decimal;
      }
      if (deposiAmt !== numNetAmount) {//กรณีจำนวนเงินที่จะชำระไม่เท่ากับจำนวนเงินทีต้องชำระ
        showNotification(NotificationType.ERROR, "จำนวนเงินที่รับชำระไม่เท่ากับจำนวนเงินตามใบสั่งงาน");
        return true;
      }
    } else {//กรณีเป็นสดเมื่องานเสร็จ,ชำระเงินมัดจำเรียบร้อยแล้ว
      if (numNetTotal !== numNetAmount) {//กรณีจำนวนเงินที่จะชำระไม่เท่ากับจำนวนเงินทีต้องชำระ
        showNotification(NotificationType.ERROR, "จำนวนเงินที่รับชำระไม่เท่ากับจำนวนเงินตามใบสั่งงาน");
        return true;
      }
    }
    return false;
  }

  return (
    <>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Typography.Title level={4}>โปรแกรมการเงินรับชำระใบงานสด</Typography.Title>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "",
              }}
            >
              <Button
                type="primary"
                danger
                icon={<CloseCircleOutlined />}
                onClick={() => setOnDrawerVisible(false)}>
                ยกเลิก
              </Button>
              <Button
                onClick={() => setTrigger(true)}
                type="primary"
                icon={<SaveOutlined />}
                style={{ marginLeft: "5px" }}
              >
                บันทึก
              </Button>
            </div>
          </div>
        }
        width="90%"
        visible={onDrawerVisible}
        forceRender={true}
        placement="right"
        bodyStyle={{ paddingBottom: 80 }}
        closable={false}
        destroyOnClose={true}
      >
        <Content className="app-page-content">
          <Form
            form={form}
            name="receiveCashWorksheet"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            labelCol={{ span: 10 }}
          >
            <Space direction="vertical" size="middle">
              <Descriptions
                column={6}
                size="small"
                bordered
                style={{ paddingLeft: '10em' }}
                labelStyle={{ textAlign: "right", fontWeight: "bold" }}
              >
                <Descriptions.Item label="เลขที่ใบสั่งงาน" span={2}>{selectWorksheetHead.iworksheetCode}</Descriptions.Item>
                <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fff" }}> </Descriptions.Item>
                <Descriptions.Item label="ประเภทการชำระเงิน" span={2}>{selectWorksheetHead.itypepaymentName}</Descriptions.Item>
                <Descriptions.Item label="ชื่อลูกค้า" span={2}>{selectWorksheetHead.custFullName}</Descriptions.Item>
                <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fff" }}> </Descriptions.Item>
                <Descriptions.Item label="ประเภทใบสั่งงาน" span={2}>{selectWorksheetHead.itypeworksheetName}</Descriptions.Item>
                <Descriptions.Item label="ยอดค้างชำระ" span={2}>{formatNumber(sumservice)} บาท</Descriptions.Item>
                <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fff" }}> </Descriptions.Item>
                <Descriptions.Item label="รับเอกสารใบงาน" span={2}>

                  <Form.Item
                    name="receiveDocument"
                    rules={[{ required: true, message: "กรุณาระบุการได้รับเอกสารใบงาน!" }]}
                  >
                    <Select
                      size="middle"
                      placeholder="เลือกการรับเอกสาร"
                      optionFilterProp="children"
                      filterOption={true}
                      style={{ width: "150px" }}
                    >
                      <Option key='NO' value='NO'>ไม่ได้รับเอกสาร</Option>
                      <Option key='YES' value='YES'>ได้รับเอกสารแล้ว</Option>
                    </Select>
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
              <Table
                columns={columns}
                dataSource={[selectWorksheetHead]}
                size="small"
                rowKey={(item: TWorksheetHeadView) => item.ireserveCode!}
                pagination={false}
              />
              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>หมายเหตุ: </Typography.Text>
                </Col>
                <Col span={7}>
                  <Form.Item
                    name="remark"
                    rules={[{ required: true, message: "กรุณาระบุหมายเหตุ!" }]}
                  >
                    <TextArea
                      rows={2}
                    ></TextArea>
                  </Form.Item>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ส่วนลด(ใบลดหนี้): </Typography.Text>
                </Col>
                <Col span={7}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="creditNoteDiscountAmt"
                      style={{ margin: "0 0 3px 0px" }}                      
                    >
                      <InputNumber
                        style={{ textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }} readOnly
                        controls={false}
                      >
                      </InputNumber>
                    </Form.Item>
                    <Button
                      id='btnCreditNote'
                      disabled={!isCreditNote}
                      onClick={() => setSearchCreditNoteModalVisible(true)}
                      type="primary"
                      icon={<SearchOutlined />}                      
                    >
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Space>
            <Row style={{ padding: "5px 0px 0px" }}>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>จำนวนเงินก่อนภาษี: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="numServiceAmt"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                  </Form.Item>
                  <Typography.Text> บาท</Typography.Text>
                </Space>
              </Col>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เงินสด: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="cashAmount"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input
                      type="text"
                      style={{ textAlign: "end", color: "#FD1212", width: "100%" }}
                      onKeyDown={(event: any) => textNumber(event)}
                      onChange={recalculate}
                      onFocus={(e: any) => { e.target.select() }}
                      onBlur={(e: any) => { form.setFieldsValue({ cashAmount: formatNumber(parserNumber(e.target.value)) }) }}
                      onPressEnter={(e: any) => { form.setFieldsValue({ cashAmount: formatNumber(parserNumber(e.target.value)) }) }}
                    ></Input>
                  </Form.Item>
                  <Typography.Text> บาท</Typography.Text>
                </Space>
              </Col>
            </Row>

            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ภาษี: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="numVatAmt"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input
                      style={{ border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly ></Input>
                  </Form.Item>
                  <Typography.Text> บาท</Typography.Text>
                </Space>
              </Col>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เช็ค: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="chequeAmount"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <InputNumber
                      style={{ fontWeight: "bold", textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }} readOnly
                    >
                    </InputNumber>
                  </Form.Item>
                  <Button
                    id='btnSelectCheque'
                    onClick={() => setSearchTChequeHeadModalVisible(true)}
                    type="primary"
                    icon={<SearchOutlined />}
                  >
                  </Button>
                  <Button
                    id='btnAddTChequeHead'
                    onClick={() => setAddTChequeHeadModalVisible(true)}
                    type="primary"
                    icon={<PlusSquareOutlined />}
                  >
                  </Button>
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เป็นเงิน: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="numTotal"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                  </Form.Item>
                  <Typography.Text> บาท</Typography.Text>
                </Space>
              </Col>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เงินโอน: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="payinAmount"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <InputNumber
                      style={{ textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }} readOnly
                    >
                    </InputNumber>
                  </Form.Item>
                  <Button
                    id='btnSelectPayin'
                    onClick={() => setSearchTPayinHeadModalVisible(true)}
                    type="primary"
                    icon={<SearchOutlined />}
                  >
                  </Button>
                  <Button
                    id='btnAddTPayinHead'
                    onClick={() => setAddTPayinHeadModalVisible(true)}
                    type="primary"
                    icon={<PlusSquareOutlined />}
                  >
                  </Button>
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>หัก ณ ที่จ่าย: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="numTaxAmount"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, textAlign: "right", color: "#FD1212", backgroundColor: "#F7F7F8" }} readOnly></Input>
                  </Form.Item>
                  <Button
                    id='btnTWshtaxDetail'
                    disabled={isWithholdingTax}
                    onClick={onButtonTWshtaxDetailClicked}
                    type="primary"
                    icon={<PlusSquareOutlined />}
                  >
                  </Button>
                </Space>
              </Col>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>รายรับอื่นๆ: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="otherReceiveAmount"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <InputNumber
                      style={{ textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }} readOnly
                      controls={false}
                    >
                    </InputNumber>
                  </Form.Item>
                  <Button
                    id='btnOtherWork'
                    onClick={() => setSearchOtherWorkModalVisible(true)}
                    type="primary"
                    icon={<SearchOutlined />}
                  >
                  </Button>
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>คงเหลือต้องชำระ: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="numNetTotal"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input
                      style={{ textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }}
                      readOnly
                    >
                    </Input>
                  </Form.Item>
                  <Button
                    id='buttonRemainUp'
                    disabled={buttonRemainUpDisabled}
                    type="primary"
                    icon={<ArrowUpOutlined />}
                    onClick={onButtonRemainUpClicked}
                  >
                  </Button>
                  <Button
                    id='buttonRemainDown'
                    disabled={buttonRemainDownDisabled}
                    type="primary"
                    icon={<ArrowDownOutlined />}
                    onClick={onButtonRemainDownClicked}
                  >
                  </Button>
                </Space>
              </Col>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>รวมรับชำระ: </Typography.Text>
              </Col>
              <Col span={7}>
                <Space align='baseline' style={{ width: "80%" }}>
                  <Form.Item
                    name="numNetAmount"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <InputNumber
                      style={{ textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }}
                      readOnly
                      controls={false}
                    >
                    </InputNumber>
                  </Form.Item>
                </Space>
              </Col>
            </Row>
          </Form>
        </Content>
      </Drawer>

      <div>
        {addTChequeHeadModalVisible && (
          <AddTChequeHeadModal
            addTChequeHeadModalVisible={addTChequeHeadModalVisible}
            setAddTChequeHeadModalVisible={setAddTChequeHeadModalVisible}
            tChequeHead={newTChequeHead}
          ></AddTChequeHeadModal>
        )}
      </div>
      <div>
        {searchTChequeHeadModalVisible && (
          <SearchTChequeHeadModal
            searchTChequeHeadModalVisible={searchTChequeHeadModalVisible}
            setSearchTChequeHeadModalVisible={setSearchTChequeHeadModalVisible}
            tFinanceChequeDetails={tFinanceChequeDetails}
            setTFinanceChequeDetails={setTFinanceChequeDetails}
          ></SearchTChequeHeadModal>
        )}
      </div>
      <div>
        {addTPayinHeadModalVisible && (
          <AddTPayinHeadModal
            addTPayinHeadModalVisible={addTPayinHeadModalVisible}
            setAddTPayinHeadModalVisible={setAddTPayinHeadModalVisible}
            tPayinHead={newTPayinHead}
          ></AddTPayinHeadModal>
        )}
      </div>

      <div>
        {searchTPayinHeadModalVisible && (
          <SearchTPayinHeadModal
            searchTPayinHeadModalVisible={searchTPayinHeadModalVisible}
            setSearchTPayinHeadModalVisible={setSearchTPayinHeadModalVisible}
            tFinancePayinDetails={tFinancePayinDetails}
            setTFinancePayinDetails={setTFinancePayinDetails}
          ></SearchTPayinHeadModal>
        )}
      </div>
      <div>
        {searchOtherWorkModalVisible && (
          <SearchOtherWorkSearchModal
            searchOtherWorkModalVisible={searchOtherWorkModalVisible}
            setSearchOtherWorkModalVisible={setSearchOtherWorkModalVisible}
            tFinanceOtherworkdetail={tFinanceOtherworkdetail}
            tFinanceOtherWorkDetails={tFinanceOtherWorkDetails}
            setTFinanceOtherWorkDetails={setTFinanceOtherWorkDetails}
          ></SearchOtherWorkSearchModal>
        )}
      </div>
      <div>
        {searchCreditNoteModalVisible && (
          <SearchCreditNoteDiscountModal
            searchCreditNoteModalVisible={searchCreditNoteModalVisible}
            setSearchCreditNoteModalVisible={setSearchCreditNoteModalVisible}
            iworksheetCode={selectWorksheetHead.iworksheetCode}
            wshRangradWangbills={undefined}
            tCreditnoteHeads={tCreditnoteHeads}
            setTCreditnoteHeads={setTCreditnoteHeads}
          ></SearchCreditNoteDiscountModal>
        )}
      </div>
      <div>
        {addTWshtaxDetailModalVisible && (
          <AddTWshtaxDetailModal
            addTWshtaxDetailModalVisible={addTWshtaxDetailModalVisible}
            setAddTWshtaxDetailModalVisible={setAddTWshtaxDetailModalVisible}
            tWshtaxDetailView={newTWshtaxDetailView}
            seTWshtaxDetailView={setNewTWshtaxDetailView}
            setTWshtaxDetail={setTWshtaxDetail}
          ></AddTWshtaxDetailModal>
        )}
      </div>
    </>
  )
}

export default FinanceReceivableDrawer