import { TWorksheetInsuranceData } from "src/domain/worksheet/view/tWorksheetInsuranceData";
import http from "../lib/http";
import Constants from "../utils/constants";

const getPaymentInsuranceHeadViewById = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetInsurance/getPaymentInsuranceHeadViewById", {
    params: {
      id
    },
  });
};

const getPaymentInsuranceHeadByAll = (fromDate: string, toDate: string, worksheetCodeCustomerName: string, statusInsurances:string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetInsurance/getPaymentInsuranceHeadByAll", {
    params: {
      fromDate,
      toDate,
      worksheetCodeCustomerName,
      statusInsurances
    },
  });
};

const getFinanceInsuranceList = (fromDate: string, toDate: string, worksheetCodeCustomerName: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetInsurance/getFinanceInsuranceList", {
    params: {
      fromDate,
      toDate,
      worksheetCodeCustomerName
    },
  });
};

const saveTWorksheetInsuranceData = (tworksheetInsuranceData: TWorksheetInsuranceData) => {
  return http.post(Constants.DEFAULT_URL + "/tWorksheetInsurance/saveTWorksheetInsuranceData", tworksheetInsuranceData);
};

const defaultApi = {
  getPaymentInsuranceHeadViewById,
  getPaymentInsuranceHeadByAll,
  getFinanceInsuranceList,
  saveTWorksheetInsuranceData
};

export default defaultApi;