import React from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { validateAction } from "src/stores/authen/authenAction";
import { useAppSelector } from "src/stores/store";

const AuthenRoute: React.FC<RouteProps> = (props) => {
  const dispatch = useDispatch();
  dispatch(validateAction());
  const isLogined = useAppSelector((state) => state.authen.authenUser.isLogin);
  return (!isLogined ? <Redirect to='/login' /> : <Route {...props} />);
};

export default AuthenRoute;
