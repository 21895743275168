import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import Search from "antd/es/input/Search";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PageHeader from "src/layouts/PageHeader";
import { TCustomerHeadView } from "../../domain/master/view/tCustomerHeadView";
import tCustomerService from "../../services/customer/tCustomerHeadService";
import AddEditCustomerHeadDrawer from "./AddEditCustomerHeadDrawer";

const CustomerHeadList: React.FC<TCustomerHeadView> = () => {
    const [customerHeads, setCustomerHeads] = useState<TCustomerHeadView[]>([]);
    const [page, setPage] = React.useState(1);
    const history = useHistory();
    const [visibleAddEditCustomerHeadDrawer, setVisibleAddEditCustomerHeadDrawer] = useState<boolean>(false);

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            width: 50,
            render: (value: number, item: TCustomerHeadView, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
        },
        {
            title: "รหัสลูกค้า",
            dataIndex: "icustCode",
            key: "icustCode",
            width: 50,
            render: (value: string, item: TCustomerHeadView) => (
                <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.icustCode}</Button>
            )
        },
        {
            title: "เลขที่บัญชีลูกหนี้",
            dataIndex: "idebitorCode",
            key: "idebitorCode",
            width: 70
        },
        {
            title: "ชื่อ",
            dataIndex: "iname",
            key: "iname",
            width: 170,
            render: (value: any, item: TCustomerHeadView) => (<>{item.iprenameName} {item.iname}</>)
        },
        {
            title: "นามสกุล",
            dataIndex: "isurname",
            key: "isurname",
            width: 50
        },
        {
            title: "ที่อยู่",
            dataIndex: "iremark",
            key: "iremark",
            width: 200,
            render: (value: any, item: TCustomerHeadView) => (<>{item.iaddress} {item.iamphurName} {item.iprovinceName} {item.ipostcode}</>)
        },
        {
            title: "เบอร์โทร",
            dataIndex: "iphone",
            key: "iphone",
            width: 100,
        },
        {
            title: "Fax",
            dataIndex: "ifax",
            key: "ifax",
            width: 100,
        },
    ];

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
    }, []);

    const doSearch = async (searchText: string) => {
        const res = await tCustomerService.getCustomerByParam(searchText);
        setCustomerHeads(res.data);
    }

    function onSearchTextChange(searchText: string) {
        if (!searchText) {
            doSearch("");
        } else if (searchText.length >= 3) {
            doSearch(searchText);
        }
    }

    function onAddCustomerHead() {
        setVisibleAddEditCustomerHeadDrawer(true);
    }

    const onItemCliked = (item: TCustomerHeadView) => {
        history.replace("/app/customer/view/" + item.icustCode);
    };

    return (
        <>
            <PageHeader title="โปรแกรมข้อมูลลูกค้า">
                <Button
                    size="small"
                    type="primary"
                    icon={<PlusSquareOutlined />}
                    onClick={onAddCustomerHead}
                >
                    เพิ่ม
                </Button>
            </PageHeader>
            <Content className="app-page-content">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "baseline",
                        margin: "1em 0 0 0",
                        borderBlockEnd: "2px solid #c9c9c9",
                    }}
                >
                    <label style={{ margin: "0 1em 1em 2em" }}>คำค้นหา</label>
                    <Search
                        allowClear
                        style={{ width: "350px" }}
                        size="middle"
                        placeholder="ป้อนชื่อหรือเบอร์โทร"
                        onChange={(event) => onSearchTextChange(event.target.value)}
                        onSearch={(value) => onSearchTextChange(value)}
                    ></Search>
                </div>
                <div>
                    <Table
                        columns={columns}
                        dataSource={customerHeads}
                        size="small"
                        pagination={{
                            pageSize: 15,
                            onChange(current) {
                                setPage(current);
                            }
                        }}
                        rowKey={(item: TCustomerHeadView) => item.icustCode}
                        onRow={(item: TCustomerHeadView) => {
                            return {
                                onDoubleClick: () => onItemCliked(item),
                            };
                        }}
                    />
                </div>
            </Content>
            <div> {
                visibleAddEditCustomerHeadDrawer && <AddEditCustomerHeadDrawer
                    icustCode={""}
                    visibleAddEditCustomerHeadDrawer={visibleAddEditCustomerHeadDrawer}
                    setVisibleAddEditCustomerHeadDrawer={setVisibleAddEditCustomerHeadDrawer} />
            }</div>
        </>
    );
};

export default CustomerHeadList;
