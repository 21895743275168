import { CloseCircleOutlined, ExclamationCircleOutlined, PlusSquareOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Drawer, Form, Input, Modal, Row, Select, Space, Spin, Switch, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { RyRefbranassets } from "src/domain/ryRefbranassets";
import { RyRefbranch } from "src/domain/ryRefbranch";
import { RyRefownerAsset } from "src/domain/ryRefownerAsset";
import { RyReftypeasset } from "src/domain/ryReftypeasset";
import ryRefbranassetsService from "src/services/reference/ryRefbranassetsService";
import ryRefbranchService from "src/services/reference/ryRefbranchService";
import ryReftypeassetService from "src/services/reference/ryReftypeassetService";
import ryRefownerAssetService from "src/services/tasset/ryRefownerAssetService";
import { useAppSelector } from "src/stores/store";
import Constants from "src/utils/constants";
import { toMomentDate } from "src/utils/dateTimeUtils";
import { formatNumber, parserNumber, textNumber } from "src/utils/numberUtils";
import { getFlg } from "src/utils/rodyokUtils";
import { TAssetHead } from "../../domain/master/tAssetHead";
import tAssetHeadService from "../../services/tasset/tAssetHeadService";
import { NotificationType, showNotification } from "../../utils/notificationUtils";
import AddRyRefownerAssetModal from "./AddRyRefownerAssetModal";

const { Option } = Select;
const { confirm } = Modal;

interface IParams {
    id: string;
    visibleAddEditTAssetHeadTab1Drawer: boolean;
    setVisibleAddEditTAssetHeadTab1Drawer: any;
}

const AddEditTAssetHeadTab1Drawer: React.FC<IParams> = (props) => {
    const { id, visibleAddEditTAssetHeadTab1Drawer: visibleTAssetHeadDrawer, setVisibleAddEditTAssetHeadTab1Drawer: setVisibleTAssetHeadDrawer } = props;
    const [trigger, setTrigger] = useState<boolean>(false);
    const [tAssetHead, setTAssetHead] = useState<TAssetHead>({} as TAssetHead);
    const [form] = Form.useForm();
    const history = useHistory();
    const [showSpin, setShowSpin] = useState<boolean>(false);
    const [visibleAddRyRefownerAssetModal, setVisibleAddRyRefownerAssetModal] = useState<boolean>(false);

    const [ryRefbranches, setRyRefbranches] = useState<RyRefbranch[]>([]);
    const [ryReftypeassets, setRyReftypeassets] = useState<RyReftypeasset[]>([]);
    const [ryRefbranassets, setRyRefbranassets] = useState<RyRefbranassets[]>([]);
    const [ryRefownerAssets, setRyRefownerAssets] = useState<RyRefownerAsset[]>([]);
    const userInfo = useAppSelector((state) => state.authen.authenUser.user);

    let title: string = "เพิ่มข้อมูลรายละเอียดทั่วไป";
    if (id) {
        title = "แก้ไขข้อมูลรายละเอียดทั่วไป";
    }

    useEffect(() => {
        if (trigger) {
            form.validateFields()
                .then(() => {
                    confirm({
                        title: "ยืนยัน!",
                        icon: <ExclamationCircleOutlined />,
                        content: "คุณต้องการที่จะบันทึกข้อมูลหรือไม่?",
                        cancelText: "ยกเลิก",
                        okText: "ยืนยัน",
                        onOk() {
                            form.submit();
                        },
                        onCancel() {
                            setTrigger(false);
                        }
                    });
                }).catch(() => {
                    setTrigger(false);
                });
        }
    }, [trigger]);

    useEffect(() => {
        setShowSpin(true);

        initial();
        initialRyRefownerAssets();
        return () => {
        };
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            id: tAssetHead.id,
            numbers: tAssetHead.numbers,
            branchcode: tAssetHead.branchcode,
            assettype: tAssetHead.assettype,
            assetowner: tAssetHead.assetowner,
            assetbrand: tAssetHead.assetbrand,
            assetdatebuy: tAssetHead.assetdatebuy ? toMomentDate(tAssetHead.assetdatebuy) : "",
            assetprice: tAssetHead.assetprice ? formatNumber(tAssetHead.assetprice) : 0.00,
            assetweight: tAssetHead.assetweight ? formatNumber(tAssetHead.assetweight) : 0.00,
            assetheight: tAssetHead.assetheight ? formatNumber(tAssetHead.assetheight) : 0.00,
            assetwidth: tAssetHead.assetwidth ? formatNumber(tAssetHead.assetwidth) : 0.00,
            assetlong: tAssetHead.assetlong ? formatNumber(tAssetHead.assetlong) : 0.00,
            assetcolor: tAssetHead.assetcolor,
            assetname: tAssetHead.assetname,
            assetdescription: tAssetHead.assetdescription,
            assetflgdel: getFlg(tAssetHead.assetflgdel),
            assetremark: tAssetHead.assetremark
        });
        return () => {
        };
    }, [tAssetHead]);

    useEffect(() => {
        if (!visibleAddRyRefownerAssetModal) {
            initialRyRefownerAssets();
        }
    }, [visibleAddRyRefownerAssetModal]);

    const initial = async () => {
        const ryRefbranchesRes = await ryRefbranchService.getRyRefbranchList();
        const ryReftypeassetsRes = await ryReftypeassetService.getRyReftypeassetList();
        const ryRefbranassetsRes = await ryRefbranassetsService.getRyRefbranassetsList();

        if (id) {
            const res = await tAssetHeadService.getTAssetHeadById(id);
            setTAssetHead(res.data);
        } else {
            const nextCodeRes = await tAssetHeadService.getNextTAssetHeadCode();
            setTAssetHead({ ...tAssetHead, id: nextCodeRes.data });
        }

        setRyRefbranches(ryRefbranchesRes.data)
        setRyReftypeassets(ryReftypeassetsRes.data);
        setRyRefbranassets(ryRefbranassetsRes.data);
        setShowSpin(false);
    };

    const initialRyRefownerAssets = async () => {
        const ryRefownerAssetsRes = await ryRefownerAssetService.getRyRefownerAssets();
        setRyRefownerAssets(ryRefownerAssetsRes.data);
    }

    const onFinish = async (values: any) => {
        const _tAssetHead: TAssetHead = {
            ...tAssetHead,
            id: values.id,
            numbers: values.numbers.trim().toUpperCase(),
            branchcode: values.branchcode,
            assettype: values.assettype,
            assetowner: values.assetowner,
            assetbrand: values.assetbrand,
            assetdatebuy: values.assetdatebuy ? values.assetdatebuy.format(Constants.DB_FORMAT) : "",
            assetprice: parserNumber(values.assetprice),
            assetweight: parserNumber(values.assetweight),
            assetheight: parserNumber(values.assetheight),
            assetwidth: parserNumber(values.assetwidth),
            assetlong: parserNumber(values.assetlong),
            assetcolor: values.assetcolor,
            assetname: values.assetname,
            assetdescription: values.assetdescription,
            assetflgdel: values.assetflgdel ? 0 : 1,
            assetremark: values.assetremark,
            savstation: userInfo.saveStation,
            createuser: userInfo.userId,
            lastuser: userInfo.userId,
            termno: userInfo.termNo,
        };

        if (!id) {
            _tAssetHead.assetstatus = "00";
            _tAssetHead.truckhostpower = 0;
            _tAssetHead.truckpump = 0;
            _tAssetHead.truckmile = 0;
            try {
                const res = await tAssetHeadService.postTAssetHead(_tAssetHead);
                showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลรายละเอียดทั่วไปเรียบร้อยแล้ว!");
                form.resetFields();
                setTrigger(false);
                setVisibleTAssetHeadDrawer(false);
                history.replace("/app/tasset/view/" + res.data.id);
            } catch (err) {
                showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
                setTrigger(false);
            }
        } else {
            try {
                await tAssetHeadService.updateTAssetHead(_tAssetHead);
                showNotification(NotificationType.SUCCESS, "แก้ไขข้อมูลรายละเอียดทั่วไปเรียบร้อยแล้ว!");
                form.resetFields();
                setTrigger(false);
                setVisibleTAssetHeadDrawer(false);
                history.replace("/app/tasset/view/" + tAssetHead.id);
            } catch (err) {
                showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
                setTrigger(false);
            }
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน");
        setTrigger(false);
    };

    const onAddAssetOwner = () => {
        setVisibleAddRyRefownerAssetModal(true);
    }

    return (
        <>
            <Drawer
                title={
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "baseline",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography.Title level={4}>{title}</Typography.Title>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "",
                            }}
                        >
                            <Button
                                type="primary"
                                danger
                                icon={<CloseCircleOutlined />}
                                onClick={() => setVisibleTAssetHeadDrawer(false)}>
                                ยกเลิก
                            </Button>
                            <Button
                                onClick={() => setTrigger(true)}
                                type="primary"
                                icon={<SaveOutlined />}
                                style={{ marginLeft: "5px" }}
                            >
                                บันทึก
                            </Button>
                        </div>
                    </div>
                }
                width="90%"
                visible={visibleTAssetHeadDrawer}
                forceRender={true}
                placement="right"
                bodyStyle={{ paddingBottom: 80 }}
                closable={false}
                destroyOnClose={true}
            >
                <Content className="app-page-content">
                    <div>
                        <Spin spinning={showSpin}>
                            <Form
                                form={form}
                                name="tAssetHead"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>รหัสสินทรัพย์: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="id"
                                            rules={[
                                                { required: false }
                                            ]}
                                        >
                                            <Input disabled={true} style={{ width: "200px" }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>เบอร์: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="numbers"
                                            rules={[
                                                { required: true, message: "กรุณาป้อนเบอร์!" },
                                                {
                                                    validator: (_, value) => {
                                                        if (value && (value === " " || value === "-")) {
                                                            return Promise.reject(new Error('กรุณาป้อนเบอร์ให้ถูกต้อง!'))
                                                        } else {
                                                            return Promise.resolve()
                                                        }
                                                    },
                                                }
                                            ]}
                                        >
                                            <Input placeholder="ป้อนเบอร์" maxLength={255} style={{ width: "200px" }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>สาขา: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={40}>
                                            <Form.Item
                                                name="branchcode"
                                                rules={[
                                                    { required: true, message: "กรุณาเลือกสาขา!" }
                                                ]}
                                            >
                                                <Select
                                                    size="middle"
                                                    placeholder="เลือกสาขา"
                                                    optionFilterProp="children"
                                                    filterOption={true}
                                                    style={{ width: "200px" }}
                                                >
                                                    {ryRefbranches.map((obj) => {
                                                        return (
                                                            <Option key={obj.id} value={obj.id}>
                                                                {obj.name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                label="ประเภทสินทรัพย์: "
                                                name="assettype"
                                                rules={[
                                                    { required: true, message: "กรุณาเลือกประเภทสินทรัพย์!" }
                                                ]}
                                            >
                                                <Select
                                                    size="middle"
                                                    placeholder="เลือกประเภทสินทรัพย์"
                                                    optionFilterProp="children"
                                                    filterOption={true}
                                                    style={{ width: "200px" }}
                                                >
                                                    {ryReftypeassets.map((obj) => {
                                                        return (
                                                            <Option key={obj.id} value={obj.id}>
                                                                {obj.name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>ผู้ถือกรรมสิทธิ์: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={60}>
                                            <Space align="baseline">
                                                <Form.Item
                                                    name="assetowner"
                                                    rules={[
                                                        { required: true, message: "กรุณาเลือกผู้ถือกรรมสิทธิ์!" }
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        size="middle"
                                                        placeholder="เลือกผู้ถือกรรมสิทธิ์"
                                                        optionFilterProp="children"
                                                        filterOption={true}
                                                        style={{ width: "200px" }}
                                                    >
                                                        {ryRefownerAssets.map((obj) => {
                                                            return (
                                                                <Option key={obj.id} value={obj.id}>
                                                                    {obj.prenamecode} {obj.name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                                <Button
                                                    onClick={onAddAssetOwner}
                                                    type="primary"
                                                    icon={<PlusSquareOutlined />}
                                                    style={{ marginLeft: "5px" }}
                                                >
                                                </Button>
                                            </Space>

                                            <Form.Item
                                                label="ยี่ห้อ: "
                                                name="assetbrand"
                                                rules={[
                                                    { required: true, message: "กรุณาเลือกยี่ห้อ!" }
                                                ]}
                                            >
                                                <Select
                                                    size="middle"
                                                    placeholder="เลือกยี่ห้อ"
                                                    optionFilterProp="children"
                                                    filterOption={true}
                                                    style={{ width: "200px" }}
                                                >

                                                    {ryRefbranassets.map((obj) => {
                                                        return (
                                                            <Option key={obj.id} value={obj.id}>
                                                                {obj.name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>วันที่ซื้อ: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="assetdatebuy"
                                            rules={[
                                                { required: false }
                                            ]}
                                        >
                                            <DatePicker
                                                format={Constants.DATE_FORMAT}
                                                placeholder="ป้อนวันที่ซื้อ" style={{ width: "200px" }}>
                                            </DatePicker>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>ราคา: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={30}>
                                            <Space align="baseline">
                                                <Form.Item
                                                    name="assetprice"
                                                    rules={[{ required: false },
                                                    {
                                                        validator: (_, value) => {
                                                            const assetprice = value ? parserNumber(value) : 0;
                                                            if (assetprice && assetprice < 0) {
                                                                return Promise.reject(new Error('กรุณาป้อนราคาให้ถูกต้อง!'))
                                                            } else {
                                                                return Promise.resolve()
                                                            }
                                                        },
                                                    },]}
                                                >
                                                    <Input
                                                        type="text"
                                                        onKeyDown={(event: any) => textNumber(event)}
                                                        onPressEnter={(e: any) => form.setFieldsValue({ assetprice: formatNumber(parserNumber(e.target.value)) })}
                                                        onBlur={(e: any) => form.setFieldsValue({ assetprice: formatNumber(parserNumber(e.target.value)) })}
                                                        onFocus={(e) => e.target.select()}
                                                        style={{ textAlign: "end", width: "100%" }}
                                                    ></Input>
                                                </Form.Item>
                                                <Typography.Text>บาท</Typography.Text>
                                            </Space>
                                            <Space align="baseline">
                                                <Form.Item
                                                    label="น้ำหนัก: "
                                                    name="assetweight"
                                                >
                                                    <Input
                                                        type="text"
                                                        onKeyDown={(event: any) => textNumber(event)}
                                                        onPressEnter={(e: any) => form.setFieldsValue({ assetweight: formatNumber(parserNumber(e.target.value)) })}
                                                        onBlur={(e: any) => form.setFieldsValue({ assetweight: formatNumber(parserNumber(e.target.value)) })}
                                                        onFocus={(e) => e.target.select()}
                                                        style={{ textAlign: "end", width: "100%" }}
                                                    ></Input>
                                                </Form.Item>
                                                <Typography.Text>kg.</Typography.Text>
                                            </Space>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>สูง: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={48}>
                                            <Space align="baseline">
                                                <Form.Item
                                                    name="assetheight"
                                                >
                                                    <Input
                                                        type="text"
                                                        onKeyDown={(event: any) => textNumber(event)}
                                                        onPressEnter={(e: any) => form.setFieldsValue({ assetheight: formatNumber(parserNumber(e.target.value)) })}
                                                        onBlur={(e: any) => form.setFieldsValue({ assetheight: formatNumber(parserNumber(e.target.value)) })}
                                                        onFocus={(e) => e.target.select()}
                                                        style={{ textAlign: "end", width: "100%" }}
                                                    ></Input>
                                                </Form.Item>
                                                <Typography.Text>cm.</Typography.Text>
                                            </Space>
                                            <Space align="baseline">
                                                <Form.Item
                                                    label="ยาว: "
                                                    name="assetlong"
                                                    rules={[
                                                        { required: false }
                                                    ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        onKeyDown={(event: any) => textNumber(event)}
                                                        onPressEnter={(e: any) => form.setFieldsValue({ assetlong: formatNumber(parserNumber(e.target.value)) })}
                                                        onBlur={(e: any) => form.setFieldsValue({ assetlong: formatNumber(parserNumber(e.target.value)) })}
                                                        onFocus={(e) => e.target.select()}
                                                        style={{ textAlign: "end", width: "100%" }}
                                                    ></Input>
                                                </Form.Item>
                                                <Typography.Text>cm.</Typography.Text>
                                            </Space>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>กว้าง: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={60}>
                                            <Space align="baseline">
                                                <Form.Item
                                                    name="assetwidth"
                                                    rules={[
                                                        { required: false }
                                                    ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        onKeyDown={(event: any) => textNumber(event)}
                                                        onPressEnter={(e: any) => form.setFieldsValue({ assetwidth: formatNumber(parserNumber(e.target.value)) })}
                                                        onBlur={(e: any) => form.setFieldsValue({ assetwidth: formatNumber(parserNumber(e.target.value)) })}
                                                        onFocus={(e) => e.target.select()}
                                                        style={{ textAlign: "end", width: "100%" }}
                                                    ></Input>
                                                </Form.Item>
                                                <Typography.Text>cm.</Typography.Text>
                                            </Space>
                                            <Form.Item
                                                label="สี: "
                                                name="assetcolor"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Input placeholder="ป้อนสี" style={{ width: "150px" }} maxLength={20}></Input>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>สภานะ: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="assetflgdel"
                                            rules={[
                                                { required: false }
                                            ]}
                                            valuePropName="checked"
                                        >
                                            <Switch checkedChildren="ใช้งาน" unCheckedChildren="ไม่ใช้งาน" defaultChecked={true} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>ชื่อสินทรัพย์: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="assetname"
                                            rules={[
                                                { required: true, message: "กรุณาป้อนชื่อสินทรัพย์!" },
                                                {
                                                    validator: (_, value) => {
                                                        if (value && (value === " " || value === "-")) {
                                                            return Promise.reject(new Error('กรุณาป้อนชื่อสินทรัพย์ให้ถูกต้อง!'))
                                                        } else {
                                                            return Promise.resolve()
                                                        }
                                                    },
                                                }
                                            ]}
                                        >
                                            <Input placeholder="ป้อนชื่อสินทรัพย์" style={{ width: "550px" }} maxLength={250}></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>รายละเอียด: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="assetdescription"
                                            rules={[
                                                { required: false }
                                            ]}
                                        >
                                            <Input placeholder="ป้อนรายละเอียด" style={{ width: "550px" }} maxLength={250}></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>หมายเหตุ: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="assetremark"
                                            rules={[
                                                { required: false }
                                            ]}
                                        >
                                            <TextArea
                                                style={{ width: "545px" }}
                                                rows={3}
                                                name="address"
                                                maxLength={250}
                                            ></TextArea>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Spin>
                    </div>
                </Content>
            </Drawer>

            <div> {
                visibleAddRyRefownerAssetModal && <AddRyRefownerAssetModal
                    visibleAddRyRefownerAssetModal={visibleAddRyRefownerAssetModal}
                    setVisibleAddRyRefownerAssetModal={setVisibleAddRyRefownerAssetModal} />
            }</div>
        </>
    );
};

export default AddEditTAssetHeadTab1Drawer;
