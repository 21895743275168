import { DeleteOutlined, ExclamationCircleOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Select, Space, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { TAssetHeadView } from 'src/domain/master/view/tAssetHeadView';
import { TTruckRepair } from 'src/domain/tTruckRepair';
import { TTruckRepairView } from 'src/domain/view/tTruckRepairView';
import PageHeader from 'src/layouts/PageHeader';
import tAssetHeadService from 'src/services/tasset/tAssetHeadService';
import tTruckRepairService from 'src/services/tTruckRepairService';
import { useAppSelector } from 'src/stores/store';
import { formatTime, toThDate } from 'src/utils/dateTimeUtils';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import TruckRepairModal from './modal/TruckRepairModal';

const { Option } = Select;
const { confirm } = Modal;

const TruckRepairList: React.FC<TTruckRepairView> = () => {
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [onAddEditModalVisible, setOnAddEditModalVisible] = useState<boolean>(false);
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [ttruckRepairs, sertTtruckRepairs] = useState<TTruckRepairView[]>([]);
  const [ttruckRepairViewSelected, setTtruckRepairViewSelected] = useState<TTruckRepairView>({} as TTruckRepairView);
  const [tAssetHeadViews, setTAssetHeadViews] = useState<TAssetHeadView[]>([]);
  const [tAssetHeadViewSelected, setTAssetHeadViewSelected] = useState<TAssetHeadView>({} as TAssetHeadView);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 50,
      render: (value: number, item: TTruckRepairView, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
    },
    {
      title: "รหัสรถ",
      dataIndex: "itruckCode",
      key: "itruckCode",
      width: 150,
    },
    {
      title: "ชื่อรถ",
      dataIndex: "itruckNumber",
      key: "itruckNumber",
      width: 150,
    },
    {
      title: "เริ่ม",
      dataIndex: "istartRepair",
      key: "istartRepair",
      width: 150,
      align: "center" as "center",
      render: (value: any, item: TTruckRepairView) => (<>{toThDate(item.istartRepair)} : {formatTime(item.istimeRepair)} </>)
    },
    {
      title: "ถึง",
      dataIndex: "iendRepair",
      key: "iendRepair",
      width: 150,
      align: "center" as "center",
      render: (value: any, item: TTruckRepairView) => (<>{toThDate(item.iendRepair)} : {formatTime(item.ietimeRepair)} </>)
    },
    {
      title: "สถานะ",
      dataIndex: "istatusRepairName",
      key: "istatusRepairName",
      width: 100,
    },
    {
      title: "พนักงาน",
      dataIndex: "lastuserName",
      key: "lastuserName",
      width: 150,
    },
    {
      title: "หมายเหตุ",
      dataIndex: "iremarkRepair",
      key: "iremarkRepair",
      width: 200,
    },
    {
      title: "",
      key: "action",
      width: 50,
      align: "center" as "center",
      render: (value: any, item: TTruckRepairView, index: number) => {
        return (
          < Button
            type="primary"
            size='small'
            danger
            shape="circle"
            icon={< DeleteOutlined />}
            onClick={() => onDelete(item, index)}
          />
        );
      },
    },
  ];
  
  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  }, []);

  useEffect(() => {
    if (!onAddEditModalVisible && tAssetHeadViewSelected.numbers) {
      handleSelectAsset(tAssetHeadViewSelected.id);
    }
  }, [onAddEditModalVisible]);

  const handleSearchAsset = async (data: any) => {
    if (data.length < 3) return;
    const branch: string = userInfo.branchId;
    const res = await tAssetHeadService.getTAssetHeadByParamAndBranch(data, branch);
    setTAssetHeadViews(res.data);
  };

  const handleSelectAsset = async (assetCode: any) => {
    const findAsset = (tAssetHeadViews as Array<TAssetHeadView>).find((obj) => obj.id === assetCode);
    setLoading(true);
    const truckRepairListRes = await tTruckRepairService.getByTruckId(findAsset?.id);
    if (truckRepairListRes) {
      sertTtruckRepairs(truckRepairListRes.data);
    }
    setTAssetHeadViewSelected(findAsset!);
    setLoading(false);
  };

  const onDelete = (item: TTruckRepairView, index: number) => {
    form.validateFields()
      .then(() => {
        confirm({
          title: "ยืนยัน!",
          icon: <ExclamationCircleOutlined />,
          content: "คุณต้องการที่จะลบรายการ การกำหนดเวลาซ่อมรถข้อมูล หรือไม่?",
          cancelText: "ยกเลิก",
          okText: "ยืนยัน",
          onOk() {
            deleteTruckRepair(item);
          },
        });
      }).catch(() => {
      });
  }

  const deleteTruckRepair = async (item: TTruckRepairView) => {
    const tTruckRepairUpdate: TTruckRepair = {
      ...item,
      iflgDel: -1,
      lastuser: userInfo.userId,
      termno: userInfo.termNo,
      savstation: userInfo.saveStation,
    }
    await tTruckRepairService.updateTTruckRepair(tTruckRepairUpdate);

    const index = (ttruckRepairs as Array<TTruckRepairView>).findIndex(object => object.iid === item.iid);
    if (index !== -1) {
      const array = ttruckRepairs ? [...ttruckRepairs] : [];
      array?.splice(index, 1);
      sertTtruckRepairs(array);
    }
    showNotification(NotificationType.SUCCESS, "ลบรายการเรียบร้อย");
  }

  const handleAdd = () => {
    if (!tAssetHeadViewSelected.id) {
      showNotification(NotificationType.ERROR, "กรุณาเลือกรถก่อน");
    } else {
      const newTruckRepair = {} as TTruckRepairView
      setTtruckRepairViewSelected(newTruckRepair);
      setOnAddEditModalVisible(true)
    }
  }

  const handleEdit = (editItem: TTruckRepairView) => {
    if (!editItem) {
      showNotification(NotificationType.ERROR, "กรุณาเลือกรายการที่ต้องการแก้ไขก่อน");
    } else {
      setTtruckRepairViewSelected(editItem);
      setOnAddEditModalVisible(true)
    }
  }

  return (
    <>
      <PageHeader title="โปรแกรมกำหนดระยะเวลาการซ่อมรถ"></PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="truckRepair"
        >
          <div
            style={{
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
              borderBlockEnd: "2px solid #c9c9c9",
            }}
          >
            <Row>
              <Col span={3} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ชื่อรถ: </Typography.Text>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="truckNumber"
                  style={{ marginBottom: "3px" }}
                >
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกรถ"
                    optionFilterProp="children"
                    filterOption={true}
                    onSearch={handleSearchAsset}
                    onSelect={handleSelectAsset}
                    onClear={()=>setTAssetHeadViewSelected({} as TAssetHeadView)}
                  >
                    {tAssetHeadViews.map((obj) => {
                      return (
                        <Option key={obj.id} value={obj.id}>
                          {obj.id}: {obj.assetFullName}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ textAlign: "left", padding: "0.5em" }}>
                <Space align="start" size={"large"}>
                  <Button
                    size="small"
                    type="primary"
                    icon={<PlusSquareOutlined />}
                    onClick={() => handleAdd()}
                  >
                    เพิ่ม
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        </Form>
        <div>
          <Table
            columns={columns}
            dataSource={ttruckRepairs}
            size="small"
            loading={loading}
            pagination={{
              pageSize: 15,
              onChange(current) {
                setPage(current);
              }
            }}
            rowKey={(item: TTruckRepairView) => item.iid!}
            onRow={(item: TTruckRepairView) => {
              return {
                onDoubleClick: () => handleEdit(item),
              };
            }}
          />
        </div>
      </Content>
      <div>
        {onAddEditModalVisible && (
          <TruckRepairModal
            onAddEditModalVisible={onAddEditModalVisible}
            setOnAddEditModalVisible={setOnAddEditModalVisible}
            ttruckRepairViewSelected={ttruckRepairViewSelected}
            tAssetHeadViewSelected={tAssetHeadViewSelected}
          ></TruckRepairModal>
        )}
      </div>
    </>
  )
}

export default TruckRepairList