import { EditOutlined } from "@ant-design/icons";
import { Button, Descriptions, Table, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { TDebitorDetailView } from "src/domain/finance/view/tDebitorDetailView";
import { TDebitorHeadView } from "src/domain/finance/view/tDebitorHeadView";
import PageHeader from "src/layouts/PageHeader";
import tDebitorDetailService from "src/services/debitor/tDebitorDetailService";
import { formatNumber } from "src/utils/numberUtils";
import tDebitorService from "../../services/debitor/tDebitorService";
import AddEditDebitorHeadDrawer from "./AddEditDebitorHeadDrawer";

const { Title } = Typography;

interface IParams {
    iid: string;
}

const ViewDebitorHead: React.FC<RouteComponentProps<IParams>> = (props) => {
    const iid = props.match.params.iid;
    const [page, setPage] = React.useState(1);
    const [debitorHead, setDebitorHead] = useState<TDebitorHeadView>({} as TDebitorHeadView);
    const [debitorDetails, setDebitorDetails] = useState<TDebitorDetailView[]>([]);
    const history = useHistory();
    const [visibleAddEditDebitorHeadDrawer, setVisibleAddEditDebitorHeadDrawer] = useState<boolean>(false);

    useEffect(() => {
        if (!visibleAddEditDebitorHeadDrawer) {
            initial();
        }
    }, [visibleAddEditDebitorHeadDrawer]);

    const initial = async () => {
        const debitorRes = await tDebitorService.getDebitorByCode(iid);
        const debitorDetailRes = await tDebitorDetailService.getDebitorDetailByCode(iid);
        setDebitorHead(debitorRes.data);
        setDebitorDetails(debitorDetailRes.data);
    };

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            width: 100,
            render: (value: number, item: TDebitorDetailView, index: number) => (
                <>{(page - 1) * 15 + index + 1}</>
            ),
        },
        {
            title: "รหัสลูกค้า",
            dataIndex: "icustomerCode",
            key: "icustomerCode",
            width: 150,
        },
        {
            title: "ชื่อ-สกุล",
            dataIndex: "fullName",
            key: "fullName",
            render: (value: any, item: TDebitorDetailView) => (<>{item.iprename} {item.iname} {item.isurname}</>)
        },
        {
            title: "ที่อยู่",
            dataIndex: "fullAddress",
            key: "fullAddress",
            render: (value: any, item: TDebitorDetailView) => (<>{item.iaddress} {item.iamphurName} {item.iprovinceName} {item.ipostcode}</>)
        },
        {
            title: "เบอร์โทร",
            dataIndex: "iphone",
            key: "iphone",
            width: 150,
            render: (value: any, item: TDebitorDetailView) => (<>{item.iphone} {item.iphoneContinue}</>)
        },
    ];

    const onEditButtonClicked = () => {
        setVisibleAddEditDebitorHeadDrawer(true);
    }

    return (
        <>
            <PageHeader title="" onClose={() => history.push("/app/debitor/list")} />
            <Content className="app-page-content">
                <Descriptions
                    style={{ margin: "1em" }}
                    column={4}
                    title="ข้อมูลลูกหนี้"
                    size="middle"
                    extra={
                        <Button
                            size="middle"
                            type="primary"
                            icon={<EditOutlined />}
                            onClick={onEditButtonClicked}

                        >
                            แก้ไข
                        </Button>
                    }
                />
                <div style={{ display: "flex", justifyContent: "flex-start", margin: "1em" }}>
                    <Descriptions
                        column={6}
                        size="middle"
                        bordered
                        labelStyle={{ textAlign: "right", fontWeight: "bold" }}
                    >
                        <Descriptions.Item label="รหัสลูกหนี้" span={6}>{debitorHead.iid}</Descriptions.Item>
                        <Descriptions.Item label="ชื่อลูกหนี้" span={6}>{debitorHead.iprename} {debitorHead.iname} {debitorHead.isurname}</Descriptions.Item>
                        <Descriptions.Item label="ที่อยู่" span={6}>{debitorHead.iaddress}</Descriptions.Item>
                        <Descriptions.Item label="อำเภอ" span={2}>({debitorHead.iamphurcode}) {debitorHead.iamphurName}</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="จังหวัด" span={2}>({debitorHead.iprovinceCode}) {debitorHead.iprovinceName}</Descriptions.Item>
                        <Descriptions.Item label="รหัสไปรษณีย์" span={6}>{debitorHead.ipostcode}</Descriptions.Item>
                        <Descriptions.Item label="โทร" span={2}>{debitorHead.itelephoneNumber}</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="ต่อ" span={2}>{debitorHead.telephonecontinue}</Descriptions.Item>
                        <Descriptions.Item label="มือถือ" span={2}>{debitorHead.imobilenumber}</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="Fax" span={2}>{debitorHead.ifaxNumber}</Descriptions.Item>
                        <Descriptions.Item label="Email" span={6}>{debitorHead.iemailaddress}</Descriptions.Item>
                        <Descriptions.Item label="บุคคลอ้างอิง" span={6}>{debitorHead.iorganizername}</Descriptions.Item>
                        <Descriptions.Item label="ตำแหน่ง" span={2}>{debitorHead.iorganizerposition}</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="เบอร์โทร" span={2}>{debitorHead.iorganizerphone}</Descriptions.Item>
                        <Descriptions.Item label="ผู้อนุมัติ" span={6}>{debitorHead.inameconsent}</Descriptions.Item>
                        <Descriptions.Item label="วงเงิน" span={2}>{formatNumber(debitorHead.icreditamount)} บาท</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="ส่วนลดพิเศษ" span={2}>{formatNumber(debitorHead.iextradiscountamount)} %</Descriptions.Item>
                        <Descriptions.Item label="เวลาการชำระเงิน" span={6}>{debitorHead.creditdate} วัน</Descriptions.Item>
                        <Descriptions.Item label="หมายเหตุแจ้งเตือน" span={6}>{debitorHead.iremarkMessage}</Descriptions.Item>
                    </Descriptions>
                </div>
            </Content>

            <br />
            <br />
            <Content className="app-page-content">
                <div style={{ padding: "0px 20px" }}>
                    <Descriptions
                        column={4}
                        size="middle"
                        extra={
                            <Title level={3}>จำนวนลูกค้า: <span style={{ color: "red" }}>{debitorDetails.length}</span></Title>
                        }
                    />
                    <Table
                        style={{ borderBlockStart: "2px solid #c9c9c9" }}
                        columns={columns}
                        dataSource={debitorDetails}
                        rowKey={(item: TDebitorDetailView) => item.iautoid}
                        size="small"
                        pagination={{
                            pageSize: 15,
                            onChange(current) {
                                setPage(current);
                            },
                        }}
                    />
                </div>
            </Content>
            <div> {
                visibleAddEditDebitorHeadDrawer && <AddEditDebitorHeadDrawer
                    iid={debitorHead.iid}
                    visibleAddEditDebitorHeadDrawer={visibleAddEditDebitorHeadDrawer}
                    setVisibleAddEditDebitorHeadDrawer={setVisibleAddEditDebitorHeadDrawer} />
            }</div>
        </>
    );
};

export default ViewDebitorHead;
