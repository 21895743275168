import { Card, Col, Descriptions, Form, Space, Spin, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { ConcludeWorksheetData } from 'src/domain/worksheet/view/concludeWorksheetData';
import { TWorksheetHeadView } from 'src/domain/worksheet/view/tWorksheetHeadView';
import PageHeader from 'src/layouts/PageHeader';
import concludeWorksheetService from 'src/services/concludeWorksheetService';
import { formatNumber, formatNumberInt } from 'src/utils/numberUtils';

interface IParams {
  worksheetcode: string;
}
const ViewConcludeWorksheet: React.FC<RouteComponentProps<IParams>> = (props) => {
  const worksheetcode = props.match.params.worksheetcode;
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [concludeWorksheetData, setConcludeWorksheetData] = useState<ConcludeWorksheetData>({} as ConcludeWorksheetData);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 80,
      align: "center" as "center",
      render: (value: number, item: TWorksheetHeadView, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่ใบงาน",
      dataIndex: "iworksheetCode",
      key: "iworksheetCode",
      width: 150,
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "icustName",
      key: "icustName",
      width: 300,
    },
    {
      title: "ประเภทใบงาน",
      dataIndex: "itypeworksheetName",
      key: "itypeworksheetName",
      width: 100,
    },
    {
      title: "ประเภทการชำระเงิน",
      dataIndex: "itypepaymentName",
      key: "itypepaymentName",
      width: 150,
    },
    {
      title: "รายการบริการ",
      dataIndex: "serviceDetailName",
      key: "serviceDetailName",
      width: 300,
    },
    {
      title: "ค่าบริการ",
      dataIndex: "iserviceAmt",
      key: "iserviceAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item?.iserviceAmt)}</span>;
      },
    },
    {
      title: "ค่าบริการพิเศษ",
      dataIndex: "iextraAmt",
      key: "iextraAmt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item?.iextraAmt)}</span>;
      },
    },
    {
      title: "ค่าล่วงเวลา",
      dataIndex: "iotAmt",
      key: "iotAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item?.iotAmt)}</span>;
      },
    },
    {
      title: "ส่วนลด",
      dataIndex: "idiscount",
      key: "idiscount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item?.idiscount)}</span>;
      },
    },
    {
      title: "ค่าบริการรวม",
      dataIndex: "itotal1Amt",
      key: "itotal1Amt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item?.itotal1Amt)}</span>;
      },
    },
    {
      title: "ภาษี",
      dataIndex: "itaxAmt",
      key: "itaxAmt",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item?.itaxAmt)}</span>;
      },
    },
    {
      title: "ค่าบริการรวมภาษี",
      dataIndex: "itotal2Amt",
      key: "itotal2Amt",
      width: 130,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item?.itotal2Amt)}</span>;
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    (async () => {
      const res = await concludeWorksheetService.findConcludeWorksheetData("", "", "", worksheetcode);
      setConcludeWorksheetData(res.data[0]);
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <PageHeader title="" onClose={() => history.push("/app/concludeWorksheet/list")} />
      <Spin spinning={loading}>
        <Content className="app-page-content">
          <Descriptions
            style={{ margin: "1em" }}
            column={4}
            title="ข้อมูลตัดจบใบงาน"
            size="middle"
          />
          <div style={{ margin: "1em" }}>
            <Form
              form={form}
              name="viewTrushMoney"
            >
              <Descriptions
                column={2}
                size="small"
                bordered
                style={{ paddingLeft: '10em' }}
                labelStyle={{ textAlign: "right", fontWeight: "bold", width: "200px" }}
              >
                <Descriptions.Item label="ผู้แจ้ง" span={6}>{concludeWorksheetData.tworksheetMovementView?.isystemRemark}</Descriptions.Item>
                <Descriptions.Item label="หมายเหตุ" span={6}>{concludeWorksheetData.tworksheetMovementView?.iuserRemark}</Descriptions.Item>
              </Descriptions>
            </Form>
          </div>
          <Card
            style={{
              width: "auto",
              marginBottom: 5,
            }}
            headStyle={{
              fontSize: 14,
              paddingBottom: 5,
            }}
            title="รายการ"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "baseline",
                paddingLeft: "7em",
                borderBlockEnd: "2px solid #c9c9c9",
              }}
            >
              <Col span={17}></Col>
              <Col span={3} style={{ textAlign: "center" }}>
                <Space align="center" direction='vertical'>
                  <Typography.Title level={4}>ใบงานทั้งหมด</Typography.Title>
                  <Typography.Title level={5} style={{ margin: 0, color: "#FD1212" }}>
                    {formatNumberInt(1)}
                  </Typography.Title>
                </Space>
              </Col>
              <Col span={3} style={{ textAlign: "center" }}>
                <Space align="center" direction='vertical'>
                  <Typography.Title level={4}>จำนวนเงินทั้งหมด</Typography.Title>
                  <Typography.Title level={5} style={{ margin: 0, color: "#FD1212" }}>
                    {formatNumber(concludeWorksheetData.tworksheetHeadView?.itotal2Amt)}
                  </Typography.Title>
                </Space>
              </Col>
              <Col span={1}></Col>
            </div>
            <div style={{ marginBottom: 0 }}>
              <Table
                columns={columns}
                dataSource={[concludeWorksheetData.tworksheetHeadView]}
                size="small"
                scroll={{ x: 1500, y: 250 }}
                pagination={false}
                rowKey={(item: TWorksheetHeadView) => item?.iworksheetCode!}
              />
            </div>
          </Card>
        </Content>
      </Spin>
    </>
  )
}

export default ViewConcludeWorksheet