
import { Button, Dropdown, Menu, Typography } from "antd";

import { BellOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import { Header } from "antd/lib/layout/layout";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { logoutAction } from "src/stores/authen/authenAction";
import { useAppSelector } from "src/stores/store";
const { Title, Text } = Typography;
const HeaderNav = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  const onLogout = (item: any) => {
    if (item.key === "2") {
      dispatch(logoutAction());
      history.replace("/app");
    }
  }

  const menu = (
    <Menu
      mode="inline"
      items={[
        {
          key: '1',
          label: <Title level={5}>{userInfo.fullName}</Title>,
        },
        {
          key: '2',
          icon: <LogoutOutlined style={{ fontSize: '16px', color: '#ed0908' }} />,
          label: "ออกจากระบบ",
        },
      ]}
      onClick={onLogout}
    />
  );

  return (
    <Header className="app-header">
      <div
        className="logo d-flex flex-row justify-content-start align-items-center"
        style={{ paddingLeft: "1em" }}
      >
        <div style={{ marginRight: "10px" }}>
          <img src="/app-logo.png" alt="" width="auto" height="25px" />
        </div>
        <h4 className="title">รถยก</h4>
      </div>
      <div className="nav-right">
        <BellOutlined className="nav-icon" style={{ marginRight: "20px" }} />

        <SettingOutlined className="nav-icon" style={{ marginRight: "20px" }} />
        <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
          <Button shape="circle" icon={<UserOutlined />} />
        </Dropdown>
      </div>
    </Header >
  );
};

export default HeaderNav;
