import { Button, Result } from 'antd';
import { useHistory } from 'react-router-dom';

const NotFound: React.FC = (props) => {
  const history = useHistory();

  const onCliked = () => {
    history.replace("/app");
  };

  return (
    <Result
      status="404"
      title="404"
      subTitle="ขออภัย ไม่พบหน้าเว็บที่คุณต้องการ!"
      extra={<Button type="primary" onClick={() => onCliked()} >กลับหน้าหลัก</Button>}
    />);
};
export default NotFound;