import { PlusSquareOutlined } from '@ant-design/icons';
import { Button, Col, Form, InputNumber, Modal, Row, Select, Space, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { TEmployee2View } from 'src/domain/master/view/tEmployee2View';
import { TUserHead } from 'src/domain/tUserHead';
import { TWorksheetHead } from 'src/domain/worksheet/tWorksheetHead';
import { TWorksheetExtrapriceView } from 'src/domain/worksheet/view/tWorksheetExtrapriceView';
import { WorksheetHeadData } from 'src/domain/worksheet/view/worksheetHeadData';
import employeeService from 'src/services/employee/tEmployee2Service';
import worksheetService from 'src/services/worksheetService';
import { useAppSelector } from 'src/stores/store';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber } from 'src/utils/numberUtils';
import AddEditWorksheetExtraPriceModal from './AddEditWorksheetExtraPriceModal';

const { Option } = Select;

interface IProps {
  editWorksheetExtraPriceModalVisible: boolean;
  setEditWorksheetExtraPriceModalVisible: any;
  worksheetHeadData: WorksheetHeadData;
  setWorksheetHeadData: any;
  tUserHeadUpdate: TUserHead;
}

const EditWorksheetExtraPriceModal: React.FC<IProps> = (props) => {
  const { editWorksheetExtraPriceModalVisible, setEditWorksheetExtraPriceModalVisible, worksheetHeadData, setWorksheetHeadData, tUserHeadUpdate } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [employees, setEmployees] = useState<TEmployee2View[]>([]);//ผู้แจ้ง
  const [worksheetHeadDataTemp, setWorksheetHeadDataTemp] = useState<WorksheetHeadData>({} as WorksheetHeadData);

  //FOR ADDEDITWORKSHEETDETAILMODAL
  const [addEditWshExtraPriceModalVisible, setAddEditWshExtraPriceModalVisible] = useState(false);
  const [extraPriceDataTempList, setExtraPriceDataTempList] = useState<TWorksheetExtrapriceView[]>();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  useEffect(() => {
    loadData();
    return () => { };
  }, []);

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  const loadData = async () => {
    if (editWorksheetExtraPriceModalVisible) {
      const vatCode = (worksheetHeadData.tworksheetHead.itypetaxCode) ? worksheetHeadData.tworksheetHead.itypetaxCode : "";
      form.setFieldsValue({
        extraAmt: worksheetHeadData.tworksheetHead.iextraAmt,
      });

      setExtraPriceDataTempList(worksheetHeadData.tworksheetExtrapriceViewList);
      await setWorksheetHeadDataTemp(worksheetHeadData);
    }
  };

  useEffect(() => {
    if (!addEditWshExtraPriceModalVisible) {
      let sumExtraPrice = 0;
      if (extraPriceDataTempList && extraPriceDataTempList.length > 0) {
        extraPriceDataTempList.forEach((obj: TWorksheetExtrapriceView) => {
          sumExtraPrice += (obj.iamount) ? obj.iamount : 0;
        });
      }
      
      //set for show extraAmt value in formItem
      form.setFieldsValue({
        extraAmt: sumExtraPrice,
      });

      //calculate to prepare save
      //round at most 2 decimal places ->> Math.round(num * 100) / 100
      const serviceAmt = worksheetHeadData.tworksheetHead.iserviceAmt ? (worksheetHeadData.tworksheetHead.iserviceAmt) : 0;
      const discount = worksheetHeadData.tworksheetHead.idiscount ? (worksheetHeadData.tworksheetHead.idiscount) : 0;
      const otAmt = worksheetHeadData.tworksheetHead.iotAmt ? (worksheetHeadData.tworksheetHead.iotAmt) : 0;
      const purchasetax = worksheetHeadData.tworksheetHead.purchasetax ? worksheetHeadData.tworksheetHead.purchasetax : 0;
      const idepositAmt = worksheetHeadData.tworksheetHead.idepositAmt ? worksheetHeadData.tworksheetHead.idepositAmt : 0;
      let vatAmount = 0;
      let amountBefTax = (serviceAmt + sumExtraPrice + otAmt) - discount;
      
      switch (worksheetHeadData.tworksheetHead.itypetaxCode) {
        case "01":
          vatAmount = Math.round(((amountBefTax * 7) / 100) * 100) / 100;
          break;
        case "02":
          vatAmount = Math.round(((amountBefTax * 7) / 107) * 100) / 100;
          amountBefTax = amountBefTax - vatAmount;
          break;
        case "03":
          break;
      }

      const temp = {
        ...(worksheetHeadDataTemp as WorksheetHeadData),
        tworksheetHead: {
          ...(worksheetHeadDataTemp.tworksheetHead as TWorksheetHead),
          iextraAmt: sumExtraPrice,
          itotal1Amt: amountBefTax,
          itaxAmt: vatAmount,
          itotal2Amt: amountBefTax + vatAmount,
          iremainAmt: amountBefTax + vatAmount - (purchasetax + idepositAmt),
        },
        netServiceAmt: (serviceAmt + sumExtraPrice + otAmt) - discount,
      };
      setWorksheetHeadDataTemp(temp);
    }
  }, [addEditWshExtraPriceModalVisible]);

  const handleSearchEmployee = async (data: any) => {
    if (data.length < 3) return;
    const res = await employeeService.getEmployee2ByParam(data);
    setEmployees(res.data);
  };

  const handleEditExtrapriceModal = () => {
    setAddEditWshExtraPriceModalVisible(true);;
  }

  const onFinish = async (values: any) => {
    const employeeArr = employees as Array<TEmployee2View>;
    const empSelected = employeeArr.find((obj) => obj.id === values.userInfoId);

    try {
      const temp = {
        ...(worksheetHeadData as WorksheetHeadData),
        tworksheetHead: {
          ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
          iextraAmt: worksheetHeadDataTemp.tworksheetHead.iextraAmt,
          iserviceAmt: worksheetHeadData.tworksheetHead.iserviceAmt,
          idiscount: worksheetHeadData.tworksheetHead.idiscount,
          itotal1Amt: worksheetHeadDataTemp.tworksheetHead.itotal1Amt,
          itaxAmt: worksheetHeadDataTemp.tworksheetHead.itaxAmt,
          itotal2Amt: worksheetHeadDataTemp.tworksheetHead.itotal2Amt,
          iremainAmt: worksheetHeadDataTemp.tworksheetHead.iremainAmt,
          isavStation: userInfo.saveStation,
          ilastUser: tUserHeadUpdate.iuserId,
          itermNo: userInfo.termNo,
        },
        sumServiceLastAmt: worksheetHeadDataTemp.sumServiceLastAmt,
        netServiceAmt: worksheetHeadDataTemp.netServiceAmt,
        tworksheetExtrapriceViewList: extraPriceDataTempList,
        updateUser: tUserHeadUpdate,
      };
      setWorksheetHeadData(temp);
      // console.log("*************** temp : ", temp);
      await worksheetService.updateWorksheetWithSubMenuKey(temp, values.userRemark, empSelected ? empSelected.empname : "", "MENU_WORKSHEET_EXTRAPRICE");
      showNotification(NotificationType.SUCCESS, "แก้ไขค่าบริการพิเศษอื่น ๆ เรียบร้อยแล้ว!");
      setEditWorksheetExtraPriceModalVisible(false);
      form.resetFields();
      setTrigger(false);
    } catch (err) {
      showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
      setTrigger(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    setTrigger(false);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>เพิ่มค่าบริการอื่นๆ</Typography.Title>
          </div>
        }
        visible={editWorksheetExtraPriceModalVisible}
        width={"50%"}
        style={{ minHeight: "70%" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setEditWorksheetExtraPriceModalVisible(false)}
      >
        <Form
          form={form}
          name="wshDetail"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row>
            <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
              <span>
                <Typography.Text>ค่าบริการพิเศษอื่นๆ: </Typography.Text>
                <label className="text-red">*</label>
              </span>
            </Col>
            <Col span={19}>
              <Space>
                <Form.Item
                  name="extraAmt"
                  style={{ marginBottom: "3px" }}
                  rules={[{ required: true, message: "กรุณาระบุค่าบริการพิเศษ" },
                  {
                    validator: (_, value) => {
                      if (value && value <= 0) {
                        return Promise.reject(new Error('กรุณาระบุค่าบริการพิเศษที่เป็นจำนวนที่ มากกว่า 0'))
                      } else {
                        return Promise.resolve()
                      }
                    },
                  },
                  ]}
                >
                  <InputNumber
                    controls={false}
                    formatter={(value) => formatNumber(value)}
                    parser={(value) => parserNumber(value)}
                    disabled={true}
                    style={{ backgroundColor: "#F8FC9D", color: "#000", width: "auto" }}
                  ></InputNumber>
                </Form.Item>
                <Button
                  size="small"
                  type="primary"
                  style={{ marginBottom: "3px" }}
                  icon={<PlusSquareOutlined />}
                  onClick={() => handleEditExtrapriceModal()}
                ></Button>
              </Space>
            </Col>
          </Row>
          <Row>
            <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
              <span>
                <Typography.Text>ผู้แจ้ง: </Typography.Text>
                <label className="text-red">*</label>
              </span>
            </Col>
            <Col span={19}>
              <Form.Item
                name="userInfoId"
                style={{ marginBottom: "3px" }}
                rules={[
                  { required: true, message: "กรุณาเลือก ผู้แจ้ง" }
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  size="middle"
                  placeholder="เลือกผู้แจ้ง"
                  optionFilterProp="children"
                  filterOption={true}
                  style={{ width: 550 }}
                  onSearch={handleSearchEmployee}
                >
                  {employees.map((obj) => {
                    return (
                      <Option key={obj.id} value={obj.id}>
                        {obj.empname}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
              <span>
                <Typography.Text>หมายเหตุ: </Typography.Text>
                <label className="text-red">*</label>
              </span>
            </Col>
            <Col span={19}>
              <Form.Item
                name="userRemark"
                style={{ marginBottom: "3px" }}
                validateTrigger="onBlur"
                rules={[{ required: true, message: "กรุณาระบุเหตุผล" }]}
              >
                <TextArea
                  rows={4}
                  style={{ width: 550 }}
                ></TextArea>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <div>
        {addEditWshExtraPriceModalVisible && (
          <AddEditWorksheetExtraPriceModal
            addEditWshExtraPriceModalVisible={addEditWshExtraPriceModalVisible}
            setAddEditWshExtraPriceModalVisible={setAddEditWshExtraPriceModalVisible}
            worksheetHeadData={worksheetHeadData}
            extraPriceDataTempList={extraPriceDataTempList}
            setExtraPriceDataTempList={setExtraPriceDataTempList}
          ></AddEditWorksheetExtraPriceModal>
        )}
      </div>
    </>
  )
}

export default EditWorksheetExtraPriceModal