import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Row, Select, Table, Typography, Modal } from 'antd';
import moment from 'moment';
import Constants from 'src/utils/constants';
import PageHeader from 'src/layouts/PageHeader';
import { Content } from 'antd/lib/layout/layout';
import { useAppSelector } from 'src/stores/store';
import { PrinterOutlined, SearchOutlined } from '@ant-design/icons';
import reportService from 'src/services/reportService';
import { formatNumber, formatNumberInt } from 'src/utils/numberUtils';
import PdfViewPopup from './PdfViewPopup';
import { RyRefbranch } from 'src/domain/ryRefbranch';
import ryRefbranchService from 'src/services/reference/ryRefbranchService';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RangradSendFnReportData } from 'src/domain/worksheet/rangradSendFnReportData';
import tTypeworksheetRefService from "src/services/reference/tTypeworksheetRefService";
import { TTypeworksheetRef } from 'src/domain/tTypeworksheetRef';
import tCustomerHeadService from "src/services/customer/tCustomerHeadService";
import { TCustomerHeadView } from 'src/domain/master/view/tCustomerHeadView';
import tReferenceDetailService from "src/services/reference/tReferenceDetailService";
import { TReferenceDetail } from 'src/domain/master/tReferenceDetail';
import { FnReceiveDailySummaryReportData } from 'src/domain/worksheet/fnReceiveDailySummaryReportData';


const { Option } = Select;

const FnReceiveDailySummaryReport = () => {
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  const [tReferenceDetails, setTReferenceDetails] = useState<TReferenceDetail[]>([]); //ประเภทการรับเงิน
  const [tTypeworksheetRefs, setTTypeworksheetRefs] = useState<TTypeworksheetRef[]>([]); //ประเภทงาน
  const [ryRefBranchSelected, setRyRefBranchSelected] = useState<RyRefbranch>({} as RyRefbranch);
  const [ryRefBranchs, setRyRefBranchs] = useState<RyRefbranch[]>([]);  //สาขา
  const [customers, setCustomers] = useState<TCustomerHeadView[]>([]);  //ลูกค้า

  const [rangradSendFnList, setRangradSendFnList] = useState<RangradSendFnReportData[]>([]);
  const [rangradSendFnPreview, setRangradSendFnPreview] = useState<any>();
  // FnReceiveDailySummaryReportData
  const [fnReceiveDailySummaryList, setFnReceiveDailySummaryList] = useState<FnReceiveDailySummaryReportData[]>([]);

  const [visiblePDF, setVisiblePDF] = useState(false);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false);

  const [numCashAmt, setNumCashAmt] = useState<number>(0);
  const [numChequeAmt, setNumChequeAmt] = useState<number>(0);
  const [numCancelWshAmt, setNumCancelWshAmt] = useState<number>(0);
  const [numPayinAmt, setNumPayinAmt] = useState<number>(0);
  const [numTotalReceiveAmt, setNumTotalReceiveAmt] = useState<number>(0);

  const [fromDateParam, setFromDateParam] = useState<any>();
  const [toDateParam, setToDateParam] = useState<any>();
  const [paymentTypeParam, setPaymentTypeParam] = useState<any>();
  const [typeWorksheetCodeParam, setTypeWorksheetCodeParam] = useState<any>();
  const [branchIdParam, setBranchIdParam] = useState<any>();
  const [customercodeParam, setCustomercodeParam] = useState<any>();

  
  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: '1%',
      align: "center" as "center",
      render: (value: number, item: FnReceiveDailySummaryReportData, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
    },
    {
      title: "เลขที่การรับเงิน",
      dataIndex: "id",
      key: "id",
      width: '5%',
      align: "center" as "center",
    },
    {
      title: "วันที่",
      dataIndex: "entryDate",
      key: "entryDate",
      width: '5%',
      align: "center" as "center",
    },
    {
      title: "การรับเงิน",
      dataIndex: "typePayment",
      key: "typePayment",
      width: '5%',
    },
    {
      title: "เลขที่รายการ",
      dataIndex: "strCode",
      key: "strCode",
      width: '5%',
      align: "center" as "center",
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "customerName",
      key: "customerName",
      width: '11%',
    },
    {
      title: "ประเภทการชำระ",
      dataIndex: "typeReceive",
      key: "typeReceive",
      width: '5%',
    },
    {
      title: "จำนวนเงินจากใบลดหนี้",
      dataIndex: "creditNoteAmt",
      key: "creditNoteAmt",
      width: '7%',
      align: "right" as "right",
      render: (value: any, item: FnReceiveDailySummaryReportData) => {
        return <span>{formatNumber(item.creditNoteAmt)}</span>;
      },
    },
    {
      title: "เงินสด",
      dataIndex: "cashAmt",
      key: "cashAmt",
      width: '5%',
      align: "right" as "right",
      render: (value: any, item: FnReceiveDailySummaryReportData) => {
        return <span>{formatNumber(item.cashAmt)}</span>;
      },
    },
    {
      title: "เงินโอน",
      dataIndex: "payinAmt",
      key: "payinAmt",
      width: '5%',
      align: "right" as "right",
      render: (value: any, item: FnReceiveDailySummaryReportData) => {
        return <span>{formatNumber(item.payinAmt)}</span>;
      },
    },
    {
      title: "เช็คธนาคาร/สาขา-เลขที่",
      dataIndex: "chequeBank",
      key: "chequeBank",
      width: '6%',
    },
    {
      title: "เช็คลงวันที่",
      dataIndex: "chequeDate",
      key: "chequeDate",
      width: '5%',
      align: "center" as "center",
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "chequeAmt",
      key: "chequeAmt",
      width: '5%',
      align: "right" as "right",
      render: (value: any, item: FnReceiveDailySummaryReportData) => {
        return <span>{formatNumber(item.chequeAmt)}</span>;
      },
    },
    {
      title: "เลขที่อ้างอิงจากรายรับอื่นๆ",
      dataIndex: "otherWshCode",
      key: "otherWshCode",
      width: '8%',
    },
    {
      title: "จำนวนเงินอ้างอิง",
      dataIndex: "otherWshAmt",
      key: "otherWshAmt",
      width: '5%',
      align: "right" as "right",
      render: (value: any, item: FnReceiveDailySummaryReportData) => {
        return <span>{formatNumber(item.otherWshAmt)}</span>;
      },
    },
    {
      title: "พนักงานรับชำระ",
      dataIndex: "userName",
      key: "userName",
      width: '7%',
    },
    {
      title: "หมายเหตุ",
      dataIndex: "remark",
      key: "remark",
      width: '11%',
    },
  ]

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });

    initData();
    form.submit();
  }, []);


  const initData = async () => {
    const tReferenceDetails = await tReferenceDetailService.getTReferenceDetailByKey(10);
    const tTypeworksheetRefsRes = await tTypeworksheetRefService.getTTypeworksheetRefList();
    const refbranchRes = await ryRefbranchService.getRyRefbranchList();

    setTReferenceDetails(tReferenceDetails.data);
    setTTypeworksheetRefs(tTypeworksheetRefsRes.data);
    setRyRefBranchs(refbranchRes.data);

    form.setFieldsValue({
      // fromDate: moment(new Date(), Constants.DATE_FORMAT).startOf('month'),
      // toDate: moment(new Date(), Constants.DATE_FORMAT).endOf('month'),
      fromDate: moment(new Date(), Constants.DATE_FORMAT),
      toDate: moment(new Date(), Constants.DATE_FORMAT),
      paymentType: "",
      typeWorksheetCode: "",
      branchId: "",
      customercode: "",
    });
  }

  const rowClassNameFn = (record: FnReceiveDailySummaryReportData, index) => {
    console.log('class status : ', record.worksheetStatus);
    if(record.worksheetStatus === '98'){
      return "row-red"
    }
    
    return null;
  }

  const onFinish = async (values: any) => {
    setLoading(true);
    const fromDate = moment(values.fromDate).format("yyyyMMDD");
    const toDate = moment(values.toDate).format("yyyyMMDD");
    const paymentType = values.paymentType ? values.paymentType : "";
    const typeWorksheetCode = values.typeWorksheetCode ? values.typeWorksheetCode : "";
    const branchId = values.branchId ? values.branchId : "";
    const customercode = values.customercode ? values.customercode : "";

    const res = await reportService.getFnReceiveDailySummaryReport(fromDate, toDate, paymentType, 
                                                                    typeWorksheetCode, branchId, customercode);
    // console.log('----------res--------', res.data)

    setFnReceiveDailySummaryList(res.data.details);
    setLoading(false);
    setPage(1);

    setNumCashAmt(res.data.numCashAmt);
    setNumCancelWshAmt(res.data.numCancelWshAmt);
    setNumChequeAmt(res.data.numChequeAmt);
    setNumPayinAmt(res.data.numPayinAmt);
    setNumTotalReceiveAmt(res.data.numTotalReceiveAmt);

    setFromDateParam(fromDate);
    setToDateParam(toDate);
    setPaymentTypeParam(paymentType);
    setTypeWorksheetCodeParam(typeWorksheetCode);
    setBranchIdParam(branchId);
    setCustomercodeParam(customercode);
  }

  const onFinishFailed = (errorInfo: any) => {
  };


  const handleSearchCustomer = async (data: any) => {
    if (data.length < 3) return;
    const res = await tCustomerHeadService.getCustomerByParam(data);
    setCustomers(res.data);
  };

  const onPrint = async () => {
    setVisiblePDF(true);
  }

  return (
    <>
      <PageHeader title="รายงานสรุปการรับเงินประจำวัน"></PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="fnReceiveDailySummary"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
            }}
          >
           
           <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>วันที่รับเงิน: </Typography.Text>
              </Col>
              <Col span={3}>
                <Form.Item
                  name="fromDate"
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={1} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ถึง: </Typography.Text>
              </Col>
              <Col span={3}>
                <Form.Item
                  name="toDate"
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ประเภทการรับเงิน: </Typography.Text>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="paymentType"
                  style={{ marginBottom: "3px" }}
                >
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกประเภทการรับเงิน"
                    optionFilterProp="children"
                  >
                    {tReferenceDetails.map((obj) => {
                      return (
                        <Option key={obj.id} value={obj.id}>
                          {obj.code}: {obj.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ประเภทใบงาน: </Typography.Text>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="typeWorksheetCode"
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกประเภทใบงาน"
                    optionFilterProp="children"
                  // onSelect={form.submit}
                  >
                    {/* {tTypeworksheetRefs.map((obj) => {
                      return <Option value={obj.iid} key={obj.iid}>{obj.iname}</Option>;
                    })} */}
                    {tTypeworksheetRefs.map((obj) => {
                      return (
                        <Option key={obj.iid} value={obj.iid}>
                          {obj.iid}: {obj.iname}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>สาขา: </Typography.Text>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="branchId"
                  style={{ marginBottom: "3px" }}
                >
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกสาขา"
                    optionFilterProp="children"
                    filterOption={true}
                    onClear={() => setRyRefBranchSelected({} as RyRefbranch)}
                  >
                    {ryRefBranchs.map((obj) => {
                      return (
                        <Option key={obj.id} value={obj.id}>
                          {obj.id}: {obj.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ลูกค้า: </Typography.Text>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="customercode"
                >
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกลูกค้า"
                    optionFilterProp="children"
                    filterOption={false}
                    onSearch={handleSearchCustomer}
                  >
                    {customers.map((obj) => {
                      return (
                        <Option key={obj.icustCode} value={obj.icustCode}>
                          {obj.iprenameName}{obj.iname}  {obj.isurname ? obj.isurname : ""}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={13} style={{ textAlign: "right"}}>
                <Button
                  size="small"
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={form.submit}
                >
                  ค้นหา
                </Button>
              </Col>
              <Col span={8}></Col>
            </Row>
          </div>
        </Form>
           
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "0px 20px 10px 0px",
            borderBottom: '2px solid #c9c9c9'
          }}
        >
          <Button
          style={{
            marginLeft: "30px",}}
            size="small"
            type="primary"
            icon={<PrinterOutlined />}
            onClick={onPrint}
          >
            พิมพ์
          </Button>
          <Modal
            style={{ top: 20 }}
            width={"90vw"}
            title="RR002"
            bodyStyle={{ height: "85vh" }}
            centered={true}
            visible={isPreviewModalVisible}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={() => setIsPreviewModalVisible(false)}
          >
            <iframe title=" " src={rangradSendFnPreview} width={"100%"} height={"100%"}></iframe>
          </Modal>
        </div>

        <div>
          <Table
            rowClassName={rowClassNameFn}
            columns={columns}
            dataSource={fnReceiveDailySummaryList}
            size="small"
            loading={loading}
            rowKey={(item: FnReceiveDailySummaryReportData) => item.id!}
            scroll={{ x: 2900 }}
            pagination={{
              pageSize: 10,
              current: page,
              onChange(current) {
                setPage(current);
              }
            }}
          />
        </div>

        <div style={{
            padding: "20px 0px 0px 0px"
          }}>
          <Row>
            <Col span={6} style={{ marginLeft: "2em" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> ยอดรับเงินสดรวม: </Typography.Text>
            </Col>
            <Col span={4} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold", color: "red", margin: "0.5em" }}> {formatNumber(numCashAmt)} </Typography.Text>
              <Typography.Text style={{ fontWeight: "bold", marginRight: "1.3em" }}> บาท </Typography.Text>
            </Col>
            <Col span={3}></Col>
            <Col span={5}>
              <Typography.Text style={{ fontWeight: "bold" }}> ยอดเงินใบงานถูกยกเลิก: </Typography.Text>
            </Col>
            <Col span={5} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold", color: "red" }}> {formatNumber(numCancelWshAmt)} </Typography.Text>
              <Typography.Text style={{ fontWeight: "bold", margin: "0.5em" }}> บาท </Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ marginLeft: "2em" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> ยอดรับเงินโอนรวม: </Typography.Text>
            </Col>
            <Col span={4} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold", color: "red" }}> {formatNumber(numPayinAmt)} </Typography.Text>
              <Typography.Text style={{ fontWeight: "bold", margin: "0.5em" }}> บาท </Typography.Text>
            </Col>
            <Col span={3}></Col>
            <Col span={5}>
              <Typography.Text style={{ fontWeight: "bold" }}> ยอดรวมรับเงินทั้งหมด: </Typography.Text>
            </Col>
            <Col span={5} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold", color: "red" }}> {formatNumber(numTotalReceiveAmt)} </Typography.Text>
              <Typography.Text style={{ fontWeight: "bold", margin: "0.5em" }}> บาท </Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{ marginLeft: "2em" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> ยอดรับเช็ครวม: </Typography.Text>
            </Col>
            <Col span={4} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold", color: "red" }}> {formatNumber(numChequeAmt)} </Typography.Text>
              <Typography.Text style={{ fontWeight: "bold", margin: "0.5em" }}> บาท </Typography.Text>
            </Col>
            <Col span={13}></Col>
          </Row>
        </div>
      </Content>

      <Modal
        title= 'print'
        centered
        closable={true}
        visible={visiblePDF}
        onCancel={() => setVisiblePDF(false)}
        width="400"
      >
        <PdfViewPopup
          urlPath={reportService.getFnReceiveDailySummaryReportPreview(fromDateParam, toDateParam, paymentTypeParam
                                                                      , typeWorksheetCodeParam, branchIdParam, customercodeParam)}
          onClose={() => setVisiblePDF(false)}
        />
      </Modal>
    </>
  )
}

export default FnReceiveDailySummaryReport