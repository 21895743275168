import { CloseCircleOutlined, ExclamationCircleOutlined, PlusSquareOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Drawer, Form, Input, InputNumber, Modal, Row, Select, Space, Spin, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TCustomerHeadView } from "src/domain/master/view/tCustomerHeadView";
import { TServiceHeadView } from "src/domain/master/view/tServiceHeadView";
import { RyPercentpayindeposit } from "src/domain/ryPercentpayindeposit";
import { TReportDiscount } from "src/domain/tReportDiscount";
import { TTypepaymentRef } from "src/domain/tTypepaymentRef";
import { TTypetaxRef } from "src/domain/tTypetaxRef";
import { TTypeworksheetRef } from "src/domain/tTypeworksheetRef";
import { TWshPromiss } from "src/domain/tWshPromiss";
import { TWorksheetInsurance } from "src/domain/worksheet/tWorksheetInsurance";
import { TWorksheetSteelHead } from "src/domain/worksheet/tWorksheetSteelHead";
import { TWorksheetExtrapriceView } from "src/domain/worksheet/view/tWorksheetExtrapriceView";
import { TWorksheetHeadView } from "src/domain/worksheet/view/tWorksheetHeadView";
import { TWorksheetSteelHeadData } from "src/domain/worksheet/view/tWorksheetSteelHeadData";
import tCustomerHeadService from 'src/services/customer/tCustomerHeadService';
import ryPercentpayindepositService from "src/services/reference/ryPercentpayindepositService";
import tTypepaymentRefService from "src/services/reference/tTypepaymentRefService";
import tTypetaxRefService from "src/services/reference/tTypetaxRefService";
import tTypeworksheetRefService from "src/services/reference/tTypeworksheetRefService";
import { useAppSelector } from "src/stores/store";
import Constants from "src/utils/constants";
import { dateToString, disabledDateTimeExceptWorkHour, timeToString, toMomentDateTime } from "src/utils/dateTimeUtils";
import { formatNumber, parserNumber, textNumber } from "src/utils/numberUtils";
import { isEmpty } from "src/utils/stringUtils";
import tDebitorDetailService from "../../services/debitor/tDebitorDetailService";
import tServiceHeadService from "../../services/tservice/tServiceHeadService";
import tWorksheetSteelHeadService from "../../services/tWorksheetSteelHeadService";
import { NotificationType, showNotification } from "../../utils/notificationUtils";
import AddExtraPriceModal from "../modals/AddExtraPriceModal";
import AddInsuranceModal from "../modals/AddInsuranceModal";

const { Option } = Select;
const { confirm } = Modal;

interface IParams {
    ireserveCode: string;
    visibleAddEditWorkSheetSteelHeadDrawer: boolean;
    setVisibleAddEditWorkSheetSteelHeadDrawer: any;
}

const AddEditWorkSheetSteelHeadDrawer: React.FC<IParams> = (props) => {
    const { ireserveCode, visibleAddEditWorkSheetSteelHeadDrawer, setVisibleAddEditWorkSheetSteelHeadDrawer } = props;
    const [trigger, setTrigger] = useState<boolean>(false);
    const [form] = Form.useForm();
    const history = useHistory();
    const isEditMode = ireserveCode ? true : false;
    const [showSpin, setShowSpin] = useState<boolean>(false);
    const [isCustType, setIsCustType] = useState<boolean>(false);
    const [workSheetSteelHeadData, setWorkSheetSteelHeadData] = useState<TWorksheetSteelHeadData>({} as TWorksheetSteelHeadData);

    const [customers, setCustomers] = useState<TCustomerHeadView[]>([]);
    const [tTypeworksheetRefs, setTTypeworksheetRefs] = useState<TTypeworksheetRef[]>([]); //ประเภทใบงาน(ปกติ,ใบงานย่อย,ใบงานคุม)
    const [tServiceHeads, setTServiceHeads] = useState<TServiceHeadView[]>([]); // การบริการ
    const [tTypetaxRefs, setTTypetaxRefs] = useState<TTypetaxRef[]>([]); //ภาษีขาย
    const [tTypepaymentRefs, setTTypepaymentRefs] = useState<TTypepaymentRef[]>([]); //ประเภทการชำระเงิน
    const [ryPercentpayindeposits, setRyPercentpayindeposits] = useState<RyPercentpayindeposit[]>([]); //จำนวนเงินที่ชำระ
    const [tWorksheetExtrapriceList, setTWorksheetExtrapriceList] = useState<TWorksheetExtrapriceView[]>([]);

    const [selectedTCustomerHead, setSelectedTCustomerHead] = useState<TCustomerHeadView>();
    const [selectedTServiceHead, setSelectedTServiceHead] = useState<TServiceHeadView>(); // การบริการ
    const [selectedRyPercentpayindeposit, setSelectedRyPercentpayindeposit] = useState<RyPercentpayindeposit>(); //จำนวนเงินที่ชำระ
    const [selectedTWorksheetInsurance, setSelectedTWorksheetInsurance] = useState<TWorksheetInsurance>({} as TWorksheetInsurance);

    const [disableTypevat, setDisableTypevat] = useState<boolean>(true);
    const [disableTypepayment, setDisableTypepayment] = useState<boolean>(true);
    const [disableDepositpercent, setDisableDepositpercent] = useState<boolean>(true);
    const [disableDiscountRemark, setDisableDiscountRemark] = useState<boolean>(true);
    const [disableInsuranceAmt, setDisableInsuranceAmt] = useState<boolean>(true);

    const [unitTypeName, setUnitTypeName] = useState<string>('');

    const [visibleModalExtraPrice, setVisibleModalExtraPrice] = useState(false);
    const [visibleInsurance, setVisibleInsurance] = useState(false);

    const userInfo = useAppSelector((state) => state.authen.authenUser.user);

    let title: string = "เพิ่มข้อมูลใบจองแผ่นเหล็ก";
    if (isEditMode) {
        title = "แก้ข้อมูลใบจองแผ่นเหล็ก";
    }

    useEffect(() => {
        if (trigger) {
            if (isEditMode) {
                form.validateFields()
                    .then(() => {
                        checkDateAndConfirm();
                    }).catch(() => {
                        setTrigger(false);
                    });
            } else {
                form.validateFields()
                    .then(() => {
                        confirm({
                            title: "ยืนยัน!",
                            icon: <ExclamationCircleOutlined />,
                            content: "คุณต้องการที่จะบันทึกข้อมูลหรือไม่?",
                            cancelText: "ยกเลิก",
                            okText: "ยืนยัน",
                            onOk() {
                                form.submit();
                            },
                            onCancel() {
                                setTrigger(false);
                            }
                        });
                    }).catch(() => {
                        setTrigger(false);
                    });
            }
        }
    }, [trigger]);

    useEffect(() => {
        setShowSpin(true);
        initial();
    }, []);


    useEffect(() => {
        form.setFieldsValue({
            iamount: (workSheetSteelHeadData?.tworksheetSteelHead?.iamount) ? workSheetSteelHeadData?.tworksheetSteelHead?.iamount : 0,
            iserviceAmt: (workSheetSteelHeadData?.tworksheetSteelHead?.iamount) ? workSheetSteelHeadData?.tworksheetSteelHead?.iamount : 0,
            serviceDiscount: (workSheetSteelHeadData?.tworksheetSteelHead?.idiscount) ? formatNumber(workSheetSteelHeadData?.tworksheetSteelHead?.idiscount) : 0,
            inumDate: (workSheetSteelHeadData?.tworksheetSteelHead?.inumDate) ? workSheetSteelHeadData?.tworksheetSteelHead?.inumDate : 0,
            inumSteel: (workSheetSteelHeadData?.tworksheetSteelHead?.inumSteel) ? workSheetSteelHeadData?.tworksheetSteelHead?.inumSteel : 0,
            iextraAmt: (workSheetSteelHeadData?.tworksheetHeadView?.iextraAmt) ? workSheetSteelHeadData?.tworksheetHeadView?.iextraAmt : 0,
            idiscount: (workSheetSteelHeadData?.tworksheetHeadView?.idiscount) ? workSheetSteelHeadData?.tworksheetHeadView?.idiscount : 0,
            itotal1Amt: (workSheetSteelHeadData?.tworksheetHeadView?.itotal1Amt) ? workSheetSteelHeadData?.tworksheetHeadView?.itotal1Amt : 0,
            itaxAmt: (workSheetSteelHeadData?.tworksheetHeadView?.itaxAmt) ? workSheetSteelHeadData?.tworksheetHeadView?.itaxAmt : 0,
            itotal2Amt: (workSheetSteelHeadData?.tworksheetHeadView?.itotal2Amt) ? workSheetSteelHeadData?.tworksheetHeadView?.itotal2Amt : 0,
            idepositAmt: (workSheetSteelHeadData?.tworksheetHeadView?.idepositAmt) ? formatNumber(workSheetSteelHeadData?.tworksheetHeadView?.idepositAmt) : 0,
            amountToPay: 0,
            payedamount: (workSheetSteelHeadData?.tworksheetHeadView?.payedamount) ? workSheetSteelHeadData?.tworksheetHeadView?.payedamount : 0,
            iremainAmt: (workSheetSteelHeadData?.tworksheetHeadView?.iremainAmt) ? workSheetSteelHeadData?.tworksheetHeadView?.iremainAmt : 0,
            iinsuranceAmt: (workSheetSteelHeadData?.tworksheetHeadView?.iinsuranceAmt) ? workSheetSteelHeadData?.tworksheetHeadView?.iinsuranceAmt : 0,

            icustCode: (workSheetSteelHeadData?.tworksheetHeadView?.icustCode) ? workSheetSteelHeadData?.tworksheetHeadView?.icustCode : "",
            itypeworksheetCode: (workSheetSteelHeadData?.tworksheetHeadView?.itypeworksheetCode) ? workSheetSteelHeadData?.tworksheetHeadView?.itypeworksheetCode : "",
            promissnumber: (workSheetSteelHeadData?.twshPromiss?.promissnumber) ? workSheetSteelHeadData?.twshPromiss?.promissnumber : "",
            ilocation: (workSheetSteelHeadData?.tworksheetHeadView?.ilocation) ? workSheetSteelHeadData?.tworksheetHeadView?.ilocation : "",
            icontactName: (workSheetSteelHeadData?.tworksheetHeadView?.icontactName) ? workSheetSteelHeadData?.tworksheetHeadView?.icontactName : "",
            icontactPhone: (workSheetSteelHeadData?.tworksheetHeadView?.icontactPhone) ? workSheetSteelHeadData?.tworksheetHeadView?.icontactPhone : "",
            iserviceCode: (workSheetSteelHeadData?.tworksheetSteelHead?.iserviceCode) ? workSheetSteelHeadData?.tworksheetSteelHead?.iserviceCode : "",
            iremark: (workSheetSteelHeadData?.tworksheetSteelHead?.iremark) ? workSheetSteelHeadData?.tworksheetSteelHead?.iremark : "",
            istartDate: (workSheetSteelHeadData?.tworksheetHeadView?.istartDate) ? toMomentDateTime(workSheetSteelHeadData.tworksheetHeadView.istartDate!, workSheetSteelHeadData.tworksheetHeadView.istartTime!) : moment().set({ 'hour': 8, 'minute': 0, 'second': 0 }),
            iendDate: (workSheetSteelHeadData?.tworksheetHeadView?.iendDate) ? toMomentDateTime(workSheetSteelHeadData.tworksheetHeadView?.iendDate!, workSheetSteelHeadData.tworksheetHeadView.iendTime!) : moment().set({ 'hour': 17, 'minute': 0, 'second': 0 }),
            discountRemark: (workSheetSteelHeadData?.treportDiscount?.iremark) ? workSheetSteelHeadData?.treportDiscount?.iremark : "",
            itypetaxCode: (workSheetSteelHeadData?.tworksheetHeadView?.itypetaxCode) ? workSheetSteelHeadData?.tworksheetHeadView?.itypetaxCode : "",
            itypepaymentCode: (workSheetSteelHeadData?.tworksheetHeadView?.itypepaymentCode) ? workSheetSteelHeadData?.tworksheetHeadView?.itypepaymentCode : "",
            depositpercentId: (workSheetSteelHeadData?.tworksheetHeadView?.depositpercentId) ? workSheetSteelHeadData?.tworksheetHeadView?.depositpercentId : "",
        });

        if (workSheetSteelHeadData?.tworksheetHeadView?.icustCode) {
            handleChangeCustomer(workSheetSteelHeadData?.tworksheetHeadView?.icustCode);
        }
        if (workSheetSteelHeadData?.tworksheetSteelHead?.iserviceCode) {
            handleChangeServiceHead(workSheetSteelHeadData?.tworksheetSteelHead?.iserviceCode);
        }
        if (workSheetSteelHeadData?.tworksheetSteelHead?.idiscount) {
            setDisableDiscountRemark(Number(workSheetSteelHeadData?.tworksheetSteelHead?.idiscount) > 0 ? false : true)
        }
    }, [workSheetSteelHeadData]);

    useEffect(() => {
        handleCustType();
    }, [isCustType]);

    useEffect(() => {
        calculationPercentPay();
    }, [selectedRyPercentpayindeposit]);

    useEffect(() => {
        if (!visibleModalExtraPrice) {
            let sumExtraPrice = 0;
            if ((tWorksheetExtrapriceList) && tWorksheetExtrapriceList.length > 0) {
                tWorksheetExtrapriceList.forEach((obj: TWorksheetExtrapriceView) => {
                    sumExtraPrice += (obj.iamount) ? obj.iamount : 0;
                });
            }
            form.setFieldsValue({
                iextraAmt: sumExtraPrice,
            });
            calculation();
        }
    }, [visibleModalExtraPrice]);

    useEffect(() => {
        if (!visibleInsurance) {
            form.setFieldsValue({
                iinsuranceAmt: (selectedTWorksheetInsurance?.iinsuranceAmt) ? selectedTWorksheetInsurance?.iinsuranceAmt : 0,
            });
        }
    }, [visibleInsurance]);

    useEffect(() => {
        calculationService();
    }, [selectedTServiceHead]);

    const initial = async () => {
        const tTypeworksheetRefRes = await tTypeworksheetRefService.getTTypeworksheetRefList();
        const tTypetaxRefRes = await tTypetaxRefService.getTTypetaxRefList();
        const tTypepaymentRefRes = await tTypepaymentRefService.getTTypepaymentRefList();
        const ryPercentpayindepositRes = await ryPercentpayindepositService.getRyPercentpayindepositList();
        const tServiceHeadRes = await tServiceHeadService.getServiceByTypeAsset('003', '10', '000', '0000');

        setTTypeworksheetRefs(tTypeworksheetRefRes.data);
        setTServiceHeads(tServiceHeadRes.data);
        setTTypetaxRefs(tTypetaxRefRes.data);
        setTTypepaymentRefs(tTypepaymentRefRes.data);
        setRyPercentpayindeposits(ryPercentpayindepositRes.data);

        if (isEditMode) {
            const res = await tWorksheetSteelHeadService.getTWorksheetSteelHeadData(ireserveCode);
            const workSheetSteelHeadDataTemp = res.data as TWorksheetSteelHeadData;
            workSheetSteelHeadDataTemp.userInfo = userInfo;

            if (workSheetSteelHeadDataTemp?.tworksheetHeadView?.icustCode) {
                const res = await tCustomerHeadService.getCustomerByCode(workSheetSteelHeadDataTemp.tworksheetHeadView.icustCode!);
                setCustomers([res.data]);
            }
            setWorkSheetSteelHeadData(workSheetSteelHeadDataTemp);
        } else {
            const workSheetSteelHeadDataTemp = workSheetSteelHeadData;
            workSheetSteelHeadDataTemp.userInfo = userInfo;
            setWorkSheetSteelHeadData(workSheetSteelHeadDataTemp);
        }

        setShowSpin(false);
    };

    const handleSearchCustomer = async (data: any) => {
        if (data.length < 3) return;
        const res = await tCustomerHeadService.getCustomerByParam(data);
        setCustomers(res.data);
    };

    const handleCustType = () => {
        let custType = "ลูกค้าทั่วไป"
        if (isCustType) {
            custType = "ลูกค้าเครดิต";
        }
        form.setFieldsValue({
            custType: custType,
        })
    }

    const handleChangeCustomer = async (icustCode: any) => {
        const custArray = customers as Array<TCustomerHeadView>;

        const findCust = custArray.find((ob) => ob.icustCode === icustCode);

        let custAddress = "";
        if (findCust?.iaddress) {
            custAddress += findCust?.iaddress;
        }
        if (findCust?.iamphurName) {
            custAddress += " อำเภอ/เขต " + findCust?.iamphurName;
        }
        if (findCust?.iprovinceName) {
            custAddress += " จังหวัด " + findCust?.iprovinceName + " ";
        }
        if (findCust?.ipostcode) {
            custAddress += findCust?.ipostcode
        }

        form.setFieldsValue({
            icustAddress: custAddress,
            icustPhone: (findCust?.iphone) ? findCust?.iphone : "",
        })

        const res = await tDebitorDetailService.isExistCustomer(findCust!.icustCode.trim());
        setIsCustType(res.data);
        setSelectedTCustomerHead(findCust);
        if (!isEditMode) {
            setDisableInsuranceAmt(false)
        }
    }

    const handleChangeTypeWorksheet = (value: any) => {
        //01=ใบงานนอก 02=ใบงานใน 03=รายการอื่นๆ 04=เงินประกัน
        if (value === "03") {
            showNotification(NotificationType.ERROR, "ไม่สามารถเลือกประเภทใบงานเป็นรายการอื่นๆได้ !");
            form.resetFields(['itypeworksheetCode'])
            return;
        }
        setEnable('wshTypeSelect')
    };

    const handleChangeServiceHead = async (iserviceCode: string) => {
        const findServiceHead = tServiceHeads.find((ob) => ob.iserviceCode === iserviceCode)!;
        form.setFieldsValue({
            iunitPrice: (findServiceHead?.iunitPrice) ? findServiceHead?.iunitPrice : 0,
        })
        setUnitTypeName(((findServiceHead?.unitName) ? findServiceHead?.unitName : ''))
        setSelectedTServiceHead(findServiceHead);
    }

    const handleChangeNumDate = async (value: any) => {
        if (isEmpty(value)) return;
        calculationService();
    }

    const handleChangeNumSteel = async (value: any) => {
        if (isEmpty(value)) return;
        calculationService();
    }

    const handleChangeServiceDiscount = async (e: any) => {
        const serviceDiscount = parserNumber(e.target.value);
        if (isEmpty(serviceDiscount)) return;
        form.setFieldsValue({
            idiscount: serviceDiscount,
        })
        setDisableDiscountRemark((serviceDiscount > 0) ? false : true);
        calculationPercentPay();
    }

    const handleChangeVatType = () => {
        calculation();
    }

    const handleChangePaymentType = () => {
        setEnable("payTypeSelect");
        form.setFieldsValue({
            idepositAmt: 0.00,
        })
    }

    const handleChangePercentDepositType = (value: any) => {
        const findRyPercentpayindeposit = ryPercentpayindeposits.find((obj) => obj.id === value);
        if (value !== "00" && value !== "01" && value !== "05") {
            calculationPercentPay();
        } else {
            showNotification(NotificationType.ERROR, "ประเภทการชำระไม่ถูกต้อง!");
            return;
        }
        setSelectedRyPercentpayindeposit(findRyPercentpayindeposit)
    }

    const handleChangeDepositAmt = async (e: any) => {
        const depositAmt = parserNumber(e.target.value);
        if (isEmpty(depositAmt)) return;
        let numRemain = 0.0;
        numRemain = parserNumber(form.getFieldValue('iserviceAmt')) + parserNumber(form.getFieldValue('iextraAmt')) + parserNumber(form.getFieldValue('itaxAmt')) - parserNumber(form.getFieldValue('idiscount')) - (depositAmt ? depositAmt : 0.0);
        form.setFieldsValue({
            iremainAmt: Number(numRemain),
        })
    }

    const calculation = () => {
        let numTotalNonVat = 0.0;
        let numVat = 0.0;
        let numTotalWithVat = 0.0;
        let numRemain = 0.0;

        numTotalNonVat = parserNumber(form.getFieldValue('iserviceAmt')) - parserNumber(form.getFieldValue('idiscount')) + parserNumber(form.getFieldValue('iextraAmt'));

        const itypetaxCode = form.getFieldValue('itypetaxCode');
        if (!itypetaxCode) return;
        if (itypetaxCode === "01") {
            numVat = (numTotalNonVat * 7) / 100;
        } else if (itypetaxCode === "02") {
            numVat = (numTotalNonVat * 7) / 107;
            numTotalNonVat = numTotalNonVat - numVat;
        }
        numTotalWithVat = numTotalNonVat + numVat;
        numRemain = numTotalWithVat;

        form.setFieldsValue({
            itotal1Amt: numTotalNonVat,
            itaxAmt: numVat,
            itotal2Amt: numTotalWithVat,
            amountToPay: numTotalWithVat,
            iremainAmt: numRemain,
        });
    }

    const calculationPercentPay = () => {
        let numTotalNonVat = 0.0;
        let numVat = 0.0;
        let numTotalWithVat = 0.0;
        let numRemain = 0.0;
        let numPercentPay = 0.0;
        let numToPay = 0.0;

        numTotalNonVat = parserNumber(form.getFieldValue('iserviceAmt')) - parserNumber(form.getFieldValue('idiscount')) + parserNumber(form.getFieldValue('iextraAmt'));

        const itypetaxCode = form.getFieldValue('itypetaxCode');
        if (!itypetaxCode) return;

        if (itypetaxCode === "01") {
            numVat = (numTotalNonVat * 7) / 100;
        } else if (itypetaxCode === "02") {
            numVat = (numTotalNonVat * 7) / 107;
            numTotalNonVat = numTotalNonVat - numVat;
        }
        numTotalWithVat = numTotalNonVat + numVat;

        numPercentPay = Number(selectedRyPercentpayindeposit?.value);
        numToPay = ((numTotalNonVat + numVat) / 100) * numPercentPay;
        numRemain = numTotalWithVat - numToPay;

        form.setFieldsValue({
            itotal1Amt: numTotalNonVat,
            itaxAmt: numVat,
            itotal2Amt: numTotalWithVat,
            amountToPay: numToPay,
            idepositAmt: formatNumber(numToPay),
            iremainAmt: numRemain,
        });
    }

    const setEnable = (setBy: string) => {
        switch (setBy) {
            case "wshTypeSelect":
                if (form.getFieldValue("itypeworksheetCode") === "01") {
                    form.setFieldsValue({
                        itypetaxCode: "01",
                    });
                    setDisableTypevat(false);
                } else {
                    form.setFieldsValue({
                        itypetaxCode: "03",
                    });
                    setDisableTypevat(true);
                }

                setDisableTypepayment(false);
                form.setFieldsValue({
                    itypepaymentCode: "01",
                    depositpercentId: "01",
                });
                setDisableDepositpercent(true);
                setSelectedRyPercentpayindeposit(ryPercentpayindeposits.find((obj) => obj.id === '01'))
                break;
            case "payTypeSelect":
                switch (form.getFieldValue('itypepaymentCode')) {
                    case "01":
                        form.setFieldsValue({
                            depositpercentId: "01",
                        });
                        setDisableDepositpercent(true);
                        setSelectedRyPercentpayindeposit(ryPercentpayindeposits.find((obj) => obj.id === '01'))
                        break;
                    case "02":
                        form.setFieldsValue({
                            depositpercentId: "00",
                        });
                        setDisableDepositpercent(true);
                        setSelectedRyPercentpayindeposit(ryPercentpayindeposits.find((obj) => obj.id === '00'))
                        break;
                    case "03":
                        form.setFieldsValue({
                            depositpercentId: "02",
                        });
                        setDisableDepositpercent(false);
                        setSelectedRyPercentpayindeposit(ryPercentpayindeposits.find((obj) => obj.id === '02'))
                        break;
                    case "04":
                        form.setFieldsValue({
                            depositpercentId: "00",
                        });
                        setDisableDepositpercent(true);
                        setSelectedRyPercentpayindeposit(ryPercentpayindeposits.find((obj) => obj.id === '00'))
                        break;
                }
                break;
        }
    }

    const calServiceForIronsheet = (qty: string) => {
        let result = 0;
        let x = Number(qty);
        if (x > 1) {
            result += (x - 1) * Number((selectedTServiceHead?.iunitPrice2) ? selectedTServiceHead?.iunitPrice2 : 0);
            x = 1;
        }
        if (x > 0) {
            result += x * Number((selectedTServiceHead?.iunitPrice) ? selectedTServiceHead?.iunitPrice : 0);
        }
        return result;
    }

    const calculationService = () => {
        const serviceAmt = (form.getFieldValue('inumDate') === "") ? 0.0 : calServiceForIronsheet(form.getFieldValue('inumDate'));
        const numSteel = (form.getFieldValue('inumSteel') === "") ? 0.0 : parserNumber(form.getFieldValue('inumSteel'));
        const serviceTotal = (serviceAmt * numSteel);

        form.setFieldsValue({
            iserviceAmt: serviceTotal,
            iamount: serviceTotal
        });
        calculationPercentPay();
    }

    const checkDateAndConfirm = () => {
        const nowDateStr = moment().format(Constants.DATE_FORMAT);
        const nowTimeStr = moment().format(Constants.TIME_FORMAT);
        const nowDateAsInt = Number(moment().format(Constants.DB_FORMAT));
        const nowTimeAsInt = Number(moment().format(Constants.DB_TIME_FORMAT));

        const istartDateAsInt = Number(dateToString(form.getFieldValue('istartDate')));
        const istartTimeAsInt = Number(timeToString(form.getFieldValue('istartDate')));
        const iendDateAsInt = Number(dateToString(form.getFieldValue('endDate')));
        const iendTimeAsInt = Number(timeToString(form.getFieldValue('endDate')));

        Promise.resolve().then(() => {
            return new Promise((resolve, reject) => {
                if (istartDateAsInt < nowDateAsInt) {
                    confirm({
                        title: "คำเตือน!",
                        icon: <ExclamationCircleOutlined />,
                        content: "ไม่สามารถยืนยันใบจองที่มีวันที่เริ่มงาน ก่อนวันที่ " + nowDateStr + " ได้ ต้องการพิมพ์ใบสั่งงานหลังกำหนด",
                        cancelText: "ยกเลิก",
                        okText: "ยืนยัน",
                        onOk() {
                            resolve(true);
                        },
                        onCancel() {
                            reject(false);
                        },
                    });
                } else {
                    resolve(true);
                }
            })
        })
            .then(() => {
                return new Promise((resolve, reject) => {
                    if (iendDateAsInt < nowDateAsInt) {
                        confirm({
                            title: "คำเตือน!",
                            icon: <ExclamationCircleOutlined />,
                            content: "ไม่สามารถยืนยันใบจองที่มีวันที่ครบกำหนด ก่อนวันที่ " + nowDateStr + " ได้ ต้องการพิมพ์ใบสั่งงานหลังกำหนด",
                            cancelText: "ยกเลิก",
                            okText: "ยืนยัน",
                            onOk() {
                                resolve(true);
                            },
                            onCancel() {
                                reject(false);
                            },
                        });
                    } else {
                        resolve(true);
                    }
                })
            })
            .then(() => {
                return new Promise((resolve, reject) => {
                    if (istartDateAsInt === nowDateAsInt && istartTimeAsInt < nowTimeAsInt) {
                        confirm({
                            title: "คำเตือน!",
                            icon: <ExclamationCircleOutlined />,
                            content: "ไม่สามารถยืนยันใบจองที่มีเวลาเริ่มก่อนเวลา " + nowTimeStr + " ได้ ต้องการพิมพ์ใบสั่งงานหลังกำหนด",
                            cancelText: "ยกเลิก",
                            okText: "ยืนยัน",
                            onOk() {
                                resolve(true);
                            },
                            onCancel() {
                                reject(false);
                            },
                        });
                    } else {
                        resolve(true);
                    }
                })
            })
            .then(() => {
                return new Promise((resolve, reject) => {
                    if (istartDateAsInt === nowDateAsInt && iendTimeAsInt < nowTimeAsInt) {
                        confirm({
                            title: "คำเตือน!",
                            icon: <ExclamationCircleOutlined />,
                            content: "ไม่สามารถยืนยันใบจองที่มีเวลาครบกำหนดก่อนเวลา " + nowTimeStr + " ได้ ต้องการพิมพ์ใบสั่งงานหลังกำหนด",
                            cancelText: "ยกเลิก",
                            okText: "ยืนยัน",
                            onOk() {
                                resolve(true);
                            },
                            onCancel() {
                                reject(false);
                            },
                        });
                    } else {
                        resolve(true);
                    }
                })
            })
            .then(() => {
                return new Promise((resolve, reject) => {
                    if (istartDateAsInt > iendDateAsInt) {
                        confirm({
                            title: "คำเตือน!",
                            icon: <ExclamationCircleOutlined />,
                            content: "ไม่สามารถยืนยันใบจองที่มวันที่เริ่ม มากกว่าวันที่ครบกำหนดได้ ต้องการพิมพ์ใบสั่งงานหลังกำหนด",
                            cancelText: "ยกเลิก",
                            okText: "ยืนยัน",
                            onOk() {
                                resolve(true);
                            },
                            onCancel() {
                                reject(false);
                            },
                        });
                    } else {
                        resolve(true);
                    }
                })
            })
            .then(() => {
                return new Promise((resolve, reject) => {
                    confirm({
                        title: "ยืนยัน!",
                        icon: <ExclamationCircleOutlined />,
                        content: "ต้องการบันทึกยืนยันใบจองหรือไม่?",
                        cancelText: "ยกเลิก",
                        okText: "ยืนยัน",
                        onOk() {
                            form.submit();
                            resolve(true);
                        },
                        onCancel() {
                            resolve(true);
                        },
                    });
                })
            });
    }

    const onFinish = async (values: any) => {
        let depositFlg = 0;
        let depositAmt = 0.00
        setShowSpin(true);
        switch (values.itypepaymentCode) {
            case "01": //สดก่อนเริ่มงาน
            case "03": //สดมีมัดจำ
                depositFlg = 1;
                depositAmt = values.amountToPay ? values.amountToPay : 0.00;
                break;
            case "02": //สดหลังงานเสร็จ
            case "04": //เชื่อ
                depositFlg = 0;
                depositAmt = 0.00;
                break;
        }

        const istartDate = dateToString(values.istartDate);
        const istartTime = timeToString(values.istartDate);
        const iendDate = dateToString(values.iendDate);
        const iendTime = timeToString(values.iendDate);

        const _workSheetSteelHeadData: TWorksheetSteelHeadData = {
            tworksheetHeadView: {
                ...workSheetSteelHeadData.tworksheetHeadView,
                icustCode: values.icustCode,
                icustName: selectedTCustomerHead?.iname,
                icustAddress: values.icustAddress,
                icustPhone: values.icustPhone,
                debitorcode: selectedTCustomerHead?.idebitorCode,
                itypeworksheetCode: values.itypeworksheetCode,
                ilocation: values.ilocation,
                icontactName: values.icontactName,
                icontactPhone: values.icontactPhone,
                iserviceAmt: (values.iserviceAmt) ? values.iserviceAmt : 0,
                iextraAmt: (values.iextraAmt) ? values.iextraAmt : 0,
                idiscount: (values.idiscount) ? values.idiscount : 0,
                itotal1Amt: (values.itotal1Amt) ? values.itotal1Amt : 0,
                itypetaxCode: values.itypetaxCode,
                itaxAmt: (values.itaxAmt) ? values.itaxAmt : 0,
                itotal2Amt: (values.itotal2Amt) ? values.itotal2Amt : 0,
                itypepaymentCode: values.itypepaymentCode,
                depositpercentId: values.depositpercentId,
                depositpercent: selectedRyPercentpayindeposit?.value,
                idepositFlg: depositFlg,
                idepositAmt: parserNumber(depositAmt),
                payedamount: (values.payedamount) ? values.payedamount : 0,
                iremainAmt: (values.iremainAmt) ? values.iremainAmt : 0,
                iinsuranceAmt: (values.iinsuranceAmt) ? values.iinsuranceAmt : 0,
                istartDate: istartDate,
                istartTime: istartTime,
                iendDate: iendDate,
                iendTime: iendTime,
            } as TWorksheetHeadView,
            tworksheetSteelHead: {
                ...workSheetSteelHeadData.tworksheetSteelHead,
                iserviceCode: values.iserviceCode,
                iserviceName: (selectedTServiceHead?.iserviceName) ? selectedTServiceHead?.iserviceName : "",
                iremark: values.iremark,
                iunitPrice: values.iunitPrice,
                istartDate: istartDate,
                istartTime: istartTime,
                iendDate: iendDate,
                iendTime: iendTime,
                inumSteel: values.inumSteel,
                inumDate: values.inumDate,
                iamount: values.iserviceAmt,
                idiscount: parserNumber(values.serviceDiscount),
                itypeunitCode: (selectedTServiceHead?.iunitCode) ? selectedTServiceHead?.iunitCode : "",
            } as TWorksheetSteelHead,
            twshPromiss: {
                ...workSheetSteelHeadData.twshPromiss,
                promissnumber: (values.promissnumber) ? values.promissnumber : "",
                startdate: istartDate,
                starttime: istartTime,
                enddate: iendDate,
                endtime: iendTime,
                customercode: selectedTCustomerHead?.icustCode,
                customername: selectedTCustomerHead?.iname
            } as TWshPromiss,
            treportDiscount: {
                ...workSheetSteelHeadData.treportDiscount,
                iremark: values.discountRemark,
            } as TReportDiscount,
            tworksheetInsurance: {
                ...selectedTWorksheetInsurance,
                iinsuranceAmt: (values.iinsuranceAmt) ? values.iinsuranceAmt : 0,

            } as TWorksheetInsurance,
            tworksheetExtrapriceViewList: tWorksheetExtrapriceList,
            userInfo: userInfo,
        };

        try {
            const res = await tWorksheetSteelHeadService.postWorkSheetSteelHeadData(_workSheetSteelHeadData);
            setShowSpin(false);
            showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลใบจองแผ่นเหล็กเรียบร้อยแล้ว!");
            form.resetFields();
            setTrigger(false);
            setVisibleAddEditWorkSheetSteelHeadDrawer(false);
            if (isEditMode) {
                history.replace("/app/tConfirmreserveHead/view");
            } else {
                history.replace("/app/workSheetSteel/view/" + res.data);
            }
        } catch (err) {
            setShowSpin(false);
            showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
            setTrigger(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        showNotification(NotificationType.ERROR, "กรุณาตรวจสอบข้อมูลให้ถูกต้อง!");
        setTrigger(false);
    };

    return (
        <>
            <Drawer
                title={
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "baseline",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography.Title level={4}>{title}</Typography.Title>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "",
                            }}
                        >
                            <Button
                                type="primary"
                                danger
                                icon={<CloseCircleOutlined />}
                                onClick={() => setVisibleAddEditWorkSheetSteelHeadDrawer(false)}>
                                ยกเลิก
                            </Button>
                            <Button
                                onClick={() => setTrigger(true)}
                                type="primary"
                                icon={<SaveOutlined />}
                                style={{ marginLeft: "5px" }}
                            >
                                บันทึก
                            </Button>
                        </div>
                    </div>
                }
                width="90%"
                visible={visibleAddEditWorkSheetSteelHeadDrawer}
                forceRender={true}
                placement="right"
                bodyStyle={{ paddingBottom: 80 }}
                closable={false}
                destroyOnClose={true}
            >
                <Content className="app-page-content">
                    <div>
                        <Spin spinning={showSpin}>
                            <Form
                                form={form}
                                name="workSheetSteelHead"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <Row gutter={[10, 0]}>
                                    <Col span={16}>
                                        <Card type="inner" title="ข้อมูลลูกค้า">
                                            <Row>
                                                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                                    <Typography.Text>ชื่อลูกค้า: </Typography.Text>
                                                </Col>
                                                <Col span={18}>
                                                    <Form.Item
                                                        name="icustCode"
                                                        rules={[{ required: true, message: "กรุณาเลือกชื่อลูกค้าน" }]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            disabled={isEditMode}
                                                            size="middle"
                                                            placeholder="เลือกลูกค้า"
                                                            optionFilterProp="children"
                                                            filterOption={true}
                                                            onSearch={handleSearchCustomer}
                                                            onSelect={handleChangeCustomer}
                                                        >
                                                            {customers.map((obj) => {
                                                                return (
                                                                    <Option key={obj.icustCode} value={obj.icustCode}>
                                                                        {obj.iprenameName}{obj.iname}  {obj.isurname ? obj.isurname : ""}: {obj.iphone}<br />
                                                                        <span style={{ marginLeft: "1rem", fontSize: 12 }}>
                                                                            {obj.iaddress}{'   '}อำเภอ/เขต{'   '}{obj.iamphurName}{'   '}จังหวัด{'   '}{obj.iprovinceName}{'   '}{obj.ipostcode}
                                                                        </span>
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                                    <Typography.Text>ที่อยู่: </Typography.Text>
                                                </Col>
                                                <Col span={20}>
                                                    <Form.Item
                                                        name="icustAddress"
                                                        rules={[
                                                            { required: false }
                                                        ]}
                                                    >
                                                        <Input placeholder="ป้อนที่อยู่" style={{ width: "550px" }} disabled></Input>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                                    <Typography.Text>โทรศัพท์: </Typography.Text>
                                                </Col>
                                                <Col span={20}>
                                                    <Space size={50}>
                                                        <Form.Item
                                                            name="icustPhone"
                                                            rules={[
                                                                { required: false }
                                                            ]}
                                                        >
                                                            <Input placeholder="ป้อนโทรศัพท์" style={{ width: "200px" }} disabled></Input>
                                                        </Form.Item>
                                                        <Form.Item
                                                            label="ประเภทลูกค้า"
                                                            name="custType"
                                                            rules={[
                                                                { required: false }
                                                            ]}
                                                        >
                                                            <Input style={{ width: "213px" }} disabled></Input>
                                                        </Form.Item>
                                                    </Space>
                                                </Col>
                                            </Row>
                                        </Card>
                                        <Card type="inner" title="ข้อมูลทั่วไป">
                                            <Row>
                                                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                                    <Typography.Text>ใบงาน: </Typography.Text>
                                                </Col>
                                                <Col span={18}>
                                                    <Space size={28}>
                                                        <Form.Item
                                                            name="itypeworksheetCode"
                                                            rules={[{ required: true, message: "กรุณาเลือกใบงาน" }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                disabled={isEditMode}
                                                                style={{ width: "230px" }}
                                                                size="middle"
                                                                placeholder="เลือกใบงาน"
                                                                optionFilterProp="children"
                                                                filterOption={true}
                                                                onSelect={handleChangeTypeWorksheet}
                                                            >
                                                                {tTypeworksheetRefs.map((obj) => {
                                                                    return (
                                                                        <Option key={obj.iid} value={obj.iid}>
                                                                            {obj.iid}: {obj.iname}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item
                                                            label="เลขที่สัญญา"
                                                            name="promissnumber"
                                                            rules={[
                                                                { required: true, message: "กรุณาระบุเลขที่สัญญา" }
                                                            ]}
                                                        >
                                                            <Input style={{ width: "200px" }} maxLength={20}></Input>
                                                        </Form.Item>
                                                    </Space>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                                    <Typography.Text>สถานที่: </Typography.Text>
                                                </Col>
                                                <Col span={20}>
                                                    <Form.Item
                                                        name="ilocation"
                                                        rules={[
                                                            { required: true, message: "กรุณาระบุสถานที่ปฎิบัติงาน" }
                                                        ]}
                                                    >
                                                        <Input placeholder="ป้อนสถานที่" style={{ width: "550px" }} maxLength={200}></Input>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                                    <Typography.Text>ติดต่อคุณ: </Typography.Text>
                                                </Col>
                                                <Col span={20}>
                                                    <Space size={"small"}>
                                                        <Form.Item
                                                            name="icontactName"
                                                            rules={[
                                                                { required: true, message: "กรุณาระบุชื่อผู้ติดต่อ" }
                                                            ]}
                                                        >
                                                            <Input placeholder="ป้อนชื่อติดต่อ" style={{ width: "230px" }} maxLength={100}></Input>
                                                        </Form.Item>
                                                        <Form.Item
                                                            label="หมายเลขโทรศัพท์"
                                                            name="icontactPhone"
                                                            rules={[
                                                                { required: true, message: "กรุณาระบุหมายเลขโทรศัพท์ผู้ติดต่อ" }
                                                            ]}
                                                        >
                                                            <Input style={{ width: "190px" }} maxLength={30}></Input>
                                                        </Form.Item>
                                                    </Space>
                                                </Col>
                                            </Row>
                                        </Card>
                                        <Card type="inner" title="ข้อมูลรายการบริการ">
                                            <Row>
                                                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                                    <Typography.Text>การบริการ: </Typography.Text>
                                                </Col>
                                                <Col span={18}>
                                                    <Form.Item
                                                        name="iserviceCode"
                                                        rules={[{ required: true, message: "กรุณาเลือกการบริการ" }]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            disabled={isEditMode}
                                                            size="middle"
                                                            placeholder="เลือกการบริการ"
                                                            optionFilterProp="children"
                                                            filterOption={true}
                                                            onSelect={handleChangeServiceHead}
                                                        >
                                                            {tServiceHeads.map((obj) => {
                                                                return (
                                                                    <Option key={obj.iserviceCode} value={obj.iserviceCode}>
                                                                        [{obj.iserviceCode}] {obj.iserviceName} ( {obj.unitName} )
                                                                        <br />
                                                                        <span style={{ marginLeft: "1rem", fontSize: 13 }}>
                                                                            ราคา1: {formatNumber(obj.iunitPrice)}
                                                                        </span>
                                                                        <span style={{ marginLeft: "1rem", fontSize: 13 }}>
                                                                            ราคา2: {formatNumber(obj.iunitPrice2)}
                                                                        </span>
                                                                        <span style={{ marginLeft: "1rem", fontSize: 13 }}>
                                                                            ราคา3: {formatNumber(obj.iunitPrice3)}
                                                                        </span>
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                                    <Typography.Text>ลักษณะงาน: </Typography.Text>
                                                </Col>
                                                <Col span={20}>
                                                    <Form.Item
                                                        name="iremark"
                                                        rules={[
                                                            { required: false }
                                                        ]}
                                                    >
                                                        <Input placeholder="ป้อนลักษณะงาน" style={{ width: "550px" }} maxLength={255}></Input>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                                    <Typography.Text>ราคา/หน่วย: </Typography.Text>
                                                </Col>
                                                <Col span={20}>
                                                    <Form.Item
                                                        name="iunitPrice"
                                                        rules={[
                                                            { required: false }
                                                        ]}
                                                    >
                                                        <InputNumber
                                                            placeholder="ป้อนราคา/หน่วย"
                                                            controls={false}
                                                            formatter={(value) => formatNumber(value)}
                                                            parser={(value) => parserNumber(value)}
                                                            disabled
                                                            style={{
                                                                backgroundColor: "#F8FC9D",
                                                                width: "224px"
                                                            }}
                                                            maxLength={15}></InputNumber>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                                    <Typography.Text>วันที่เริ่มงาน: </Typography.Text>
                                                </Col>
                                                <Col span={20}>
                                                    <Form.Item
                                                        name="istartDate"
                                                        rules={[
                                                            { required: false }
                                                        ]}
                                                    >
                                                        <DatePicker
                                                            format={Constants.DATE_FORMAT_SKIP_MINUTE_SEC}
                                                            disabledTime={disabledDateTimeExceptWorkHour}
                                                            showTime={{ format: "HH" }}
                                                            size="middle"
                                                            placeholder="เลือกวันที่เริ่มงาน"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                                    <Typography.Text>วันที่สิ้นสุดงาน: </Typography.Text>
                                                </Col>
                                                <Col span={20}>
                                                    <Form.Item
                                                        name="iendDate"
                                                        rules={[
                                                            { required: false }
                                                        ]}
                                                    >
                                                        <DatePicker
                                                            format={Constants.DATE_FORMAT_SKIP_MINUTE_SEC}
                                                            disabledTime={disabledDateTimeExceptWorkHour}
                                                            showTime={{ format: "HH" }}
                                                            size="middle"
                                                            placeholder="เลือกวันที่สิ้นสุดงาน"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                                    <Typography.Text>ระยะเวลา: </Typography.Text>
                                                </Col>
                                                <Col span={20}>
                                                    <Space align="baseline">
                                                        <Form.Item
                                                            name="inumDate"
                                                            rules={[
                                                                { required: true, message: "กรุณาระบุระยะเวลา!" }
                                                            ]}
                                                        >
                                                            <InputNumber
                                                                controls={false}
                                                                style={{ width: "224px" }}
                                                                onChange={handleChangeNumDate}
                                                                onFocus={(e: any) => e.target.select()}
                                                                maxLength={15}
                                                            ></InputNumber>
                                                        </Form.Item>
                                                        <Typography.Text> {unitTypeName}</Typography.Text>
                                                    </Space>

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                                    <Typography.Text>จำนวนแผ่นเหล็ก: </Typography.Text>
                                                </Col>
                                                <Col span={20}>
                                                    <Space align="baseline">
                                                        <Form.Item
                                                            name="inumSteel"
                                                            rules={[
                                                                { required: true, message: "กรุณาระบุจำนวนแผ่นเหล็ก!" }
                                                            ]}
                                                        >
                                                            <InputNumber
                                                                controls={false}
                                                                maxLength={15}
                                                                onChange={handleChangeNumSteel}
                                                                onFocus={(e: any) => e.target.select()}
                                                                style={{ width: "224px" }}
                                                            ></InputNumber>
                                                        </Form.Item>
                                                        <Typography.Text> แผ่น</Typography.Text>
                                                    </Space>

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                                    <Typography.Text>จำนวนเงิน: </Typography.Text>
                                                </Col>
                                                <Col span={20}>
                                                    <Form.Item
                                                        name="iamount"
                                                        rules={[
                                                            { required: true, message: "กรุณาระบุจำนวนเงิน!" }
                                                        ]}
                                                    >
                                                        <InputNumber
                                                            controls={false}
                                                            disabled
                                                            maxLength={15}
                                                            formatter={(value) => formatNumber(value)}
                                                            parser={(value) => parserNumber(value)}
                                                            style={{
                                                                backgroundColor: "#F8FC9D",
                                                                width: "224px"
                                                            }}
                                                        ></InputNumber>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                                    <Typography.Text>ส่วนลด: </Typography.Text>
                                                </Col>
                                                <Col span={20}>
                                                    <Form.Item
                                                        name="serviceDiscount"
                                                        rules={[
                                                            { required: false }
                                                        ]}
                                                    >
                                                        <Input
                                                            type="text"
										                    onKeyDown={(event: any) => textNumber(event)}
                                                            onChange={(e: any) => handleChangeServiceDiscount(e)}
                                                            onPressEnter={(e: any) => form.setFieldsValue({ serviceDiscount: formatNumber(parserNumber(e.target.value)) })}
                                                            onBlur={(e: any) => form.setFieldsValue({ serviceDiscount: formatNumber(parserNumber(e.target.value)) })}
                                                            onFocus={(e) => e.target.select()}
                                                            style={{ textAlign: "end", width: "224px" }}
                                                        ></Input>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                                    <Typography.Text>หมายเหตุส่วนลด: </Typography.Text>
                                                </Col>
                                                <Col span={20}>
                                                    <Form.Item
                                                        name="discountRemark"
                                                        rules={[
                                                            { required: false },
                                                            {
                                                                validator: (_, value) => {
                                                                    const idiscountValue = form.getFieldValue('idiscount');
                                                                    if (value && idiscountValue > 0 && !value) {
                                                                        return Promise.reject(new Error('กรุณาระบุหมายเหตุการให้ส่วนลด!'))
                                                                    } else {
                                                                        return Promise.resolve()
                                                                    }
                                                                },
                                                            }
                                                        ]}
                                                    >
                                                        <TextArea
                                                            disabled={disableDiscountRemark}
                                                            style={{ width: "550px" }}
                                                            rows={3}
                                                            maxLength={255}
                                                        ></TextArea>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col span={8}>
                                        <Card type="inner" title="ข้อมูลการชำระ">
                                            <Col span={24}>
                                                <Row>
                                                    <Col span={8} style={{ textAlign: "right", padding: "0.5em" }}>
                                                        <Typography.Text>ค่าบริการ: </Typography.Text>
                                                    </Col>
                                                    <Col span={16}>
                                                        <Form.Item
                                                            name="iserviceAmt"
                                                            style={{ marginBottom: "5px" }}
                                                        >
                                                            <InputNumber
                                                                controls={false}
                                                                maxLength={15}
                                                                formatter={(value) => formatNumber(value)}
                                                                parser={(value) => parserNumber(value)}
                                                                disabled
                                                                style={{
                                                                    backgroundColor: "#F8FC9D",
                                                                    width: "100%"
                                                                }}
                                                            ></InputNumber>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={8} style={{ textAlign: "right", padding: "0.5em" }}>
                                                        <Typography.Text>ค่าอื่นๆ: </Typography.Text>
                                                    </Col>
                                                    <Col span={13}>
                                                        <Form.Item
                                                            name="iextraAmt"
                                                            style={{ marginBottom: "5px" }}
                                                        >
                                                            <InputNumber
                                                                controls={false}
                                                                disabled
                                                                maxLength={15}
                                                                formatter={(value) => formatNumber(value)}
                                                                parser={(value) => parserNumber(value)}
                                                                style={{
                                                                    backgroundColor: "#F8FC9D",
                                                                    width: "100%"
                                                                }}
                                                            ></InputNumber>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={3}>
                                                        <Button
                                                            disabled={isEditMode}
                                                            style={{ margin: "2px 0 0 13px" }}
                                                            size="small"
                                                            type="primary"
                                                            icon={<PlusSquareOutlined />}
                                                            onClick={() => setVisibleModalExtraPrice(true)}
                                                        ></Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={8} style={{ textAlign: "right", padding: "0.5em" }}>
                                                        <Typography.Text>ส่วนลด: </Typography.Text>
                                                    </Col>
                                                    <Col span={16}>
                                                        <Form.Item
                                                            name="idiscount"
                                                            style={{ marginBottom: "5px" }}
                                                        >
                                                            <InputNumber
                                                                controls={false}
                                                                disabled
                                                                maxLength={15}
                                                                formatter={(value) => formatNumber(value)}
                                                                parser={(value) => parserNumber(value)}
                                                                style={{
                                                                    backgroundColor: "#F8FC9D",
                                                                    width: "100%"
                                                                }}
                                                            ></InputNumber>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={8} style={{ textAlign: "right", padding: "0.5em" }}>
                                                        <Typography.Text>รวม: </Typography.Text>
                                                    </Col>
                                                    <Col span={16}>
                                                        <Form.Item
                                                            name="itotal1Amt"
                                                            style={{ marginBottom: "5px" }}
                                                        >
                                                            <InputNumber
                                                                controls={false}
                                                                maxLength={15}
                                                                formatter={(value) => formatNumber(value)}
                                                                parser={(value) => parserNumber(value)}
                                                                style={{
                                                                    backgroundColor: "#F8FC9D",
                                                                    width: "100%"
                                                                }}
                                                            ></InputNumber>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={8} style={{ textAlign: "right", padding: "0.5em" }}>
                                                        <Typography.Text>ภาษี: </Typography.Text>
                                                    </Col>
                                                    <Col span={16}>
                                                        <Form.Item
                                                            name="itypetaxCode"
                                                            style={{ marginBottom: "5px" }}
                                                        >
                                                            <Select
                                                                disabled={disableTypevat}
                                                                showSearch
                                                                size="middle"
                                                                placeholder="เลือกประเภทภาษี"
                                                                optionFilterProp="children"
                                                                filterOption={true}
                                                                onChange={handleChangeVatType}
                                                            >
                                                                {tTypetaxRefs.map((obj) => {
                                                                    return (
                                                                        <Option key={obj.iid} value={obj.iid}>
                                                                            {obj.iid}: {obj.iname}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={8}></Col>
                                                    <Col span={16}>
                                                        <Form.Item
                                                            name="itaxAmt"
                                                            colon={false}
                                                            style={{ marginBottom: "5px" }}
                                                        >
                                                            <InputNumber
                                                                controls={false}
                                                                disabled
                                                                maxLength={15}
                                                                formatter={(value) => formatNumber(value)}
                                                                parser={(value) => parserNumber(value)}
                                                                style={{
                                                                    backgroundColor: "#F8FC9D",
                                                                    width: "100%"
                                                                }}
                                                            ></InputNumber>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={8} style={{ textAlign: "right", padding: "0.5em" }}>
                                                        <Typography.Text>รวมภาษี: </Typography.Text>
                                                    </Col>
                                                    <Col span={16}>
                                                        <Form.Item
                                                            name="itotal2Amt"
                                                            style={{ marginBottom: "5px" }}
                                                        >
                                                            <InputNumber
                                                                controls={false}
                                                                disabled
                                                                maxLength={15}
                                                                formatter={(value) => formatNumber(value)}
                                                                parser={(value) => parserNumber(value)}
                                                                style={{
                                                                    backgroundColor: "#F8FC9D",
                                                                    width: "100%"
                                                                }}
                                                            ></InputNumber>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={8} style={{ textAlign: "right", padding: "0.5em" }}>
                                                        <Typography.Text>การชำระ: </Typography.Text>
                                                    </Col>
                                                    <Col span={16}>
                                                        <Form.Item
                                                            name="itypepaymentCode"
                                                            style={{ marginBottom: "5px" }}
                                                        >
                                                            <Select
                                                                disabled={disableTypepayment}
                                                                showSearch
                                                                size="middle"
                                                                placeholder="เลือกชำระเงินแบบ"
                                                                optionFilterProp="children"
                                                                filterOption={true}
                                                                onSelect={handleChangePaymentType}
                                                            >
                                                                {tTypepaymentRefs.map((obj) => {
                                                                    return (
                                                                        <Option key={obj.iid} value={obj.iid}>
                                                                            {obj.iid}: {obj.iname}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={8} style={{ textAlign: "right", padding: "0.5em" }}>
                                                        <Typography.Text>เงินชำระ: </Typography.Text>
                                                    </Col>
                                                    <Col span={16}>
                                                        <Form.Item
                                                            name="depositpercentId"
                                                            style={{ marginBottom: "5px" }}
                                                        >
                                                            <Select
                                                                disabled={disableDepositpercent}
                                                                showSearch
                                                                size="middle"
                                                                placeholder="เลือกจำนวนเงินที่ชำระ"
                                                                optionFilterProp="children"
                                                                filterOption={true}
                                                                onSelect={handleChangePercentDepositType}
                                                            >
                                                                {ryPercentpayindeposits.map((obj) => {
                                                                    return (
                                                                        <Option key={obj.id} value={obj.id}>
                                                                            {obj.id}: {obj.remark} {obj.value} %
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={8}></Col>
                                                    <Col span={16}>
                                                        <Form.Item
                                                            name="amountToPay"
                                                            colon={false}
                                                            style={{ marginBottom: "5px" }}
                                                        >
                                                            <InputNumber
                                                                controls={false}
                                                                maxLength={15}
                                                                formatter={(value) => formatNumber(value)}
                                                                parser={(value) => parserNumber(value)}
                                                                style={{
                                                                    backgroundColor: "#F8FC9D",
                                                                    width: "100%"
                                                                }}
                                                                disabled
                                                            ></InputNumber>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                {/* row นี้ ใช้กรณีเป็นใบงานในเท่านั้น */}
                                                <Row>
                                                    <Col span={8} style={{ textAlign: "right", padding: "0.5em" }}>
                                                        <Typography.Text>รับชำระ: </Typography.Text>
                                                    </Col>
                                                    <Col span={16}>
                                                        <Form.Item
                                                            name="idepositAmt"
                                                            style={{ marginBottom: "5px" }}
                                                            rules={[
                                                                { required: true, message: "กรุณาป้อนรับชำระ" },
                                                                {
                                                                    validator: (_, value) => {
                                                                        const payedamount = value ? parserNumber(value) : 0.0;
                                                                        const total2AmtValue = form.getFieldValue('amountToPay') ? parserNumber(form.getFieldValue('amountToPay')) : 0.0;
                                                                        if (payedamount !== total2AmtValue) {
                                                                            return Promise.reject(new Error('จำนวนเงินที่รับชำระไม่ถูกต้อง!'))
                                                                        } else {
                                                                            return Promise.resolve()
                                                                        }
                                                                    },
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                type="text"
                                                                disabled={isEditMode}
                                                                onKeyDown={(event: any) => textNumber(event)}
                                                                onChange={(e: any) => handleChangeDepositAmt(e)}
                                                                onPressEnter={(e: any) => form.setFieldsValue({ idepositAmt: formatNumber(parserNumber(e.target.value)) })}
                                                                onBlur={(e: any) => form.setFieldsValue({ idepositAmt: formatNumber(parserNumber(e.target.value)) })}
                                                                onFocus={(e) => e.target.select()}
                                                                style={{ textAlign: "end", width: "100%" }}
                                                            ></Input>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={8} style={{ textAlign: "right", padding: "0.5em" }}>
                                                        <Typography.Text>ค้างชำระ: </Typography.Text>
                                                    </Col>
                                                    <Col span={16}>
                                                        <Form.Item
                                                            name="iremainAmt"
                                                            style={{ marginBottom: "5px" }}
                                                        >
                                                            <InputNumber
                                                                controls={false}
                                                                maxLength={15}
                                                                formatter={(value) => formatNumber(value)}
                                                                parser={(value) => parserNumber(value)}
                                                                disabled
                                                                style={{
                                                                    backgroundColor: "#F8FC9D",
                                                                    width: "100%"
                                                                }}
                                                            ></InputNumber>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={8} style={{ textAlign: "right", padding: "0.5em" }}>
                                                        <Typography.Text>เงินประกัน: </Typography.Text>
                                                    </Col>
                                                    <Col span={13}>
                                                        <Form.Item
                                                            name="iinsuranceAmt"
                                                            style={{ marginBottom: "5px" }}
                                                            rules={[
                                                                { required: true, message: "กรุณาระบุเงินประกัน" }
                                                            ]}
                                                        >
                                                            <InputNumber
                                                                controls={false}
                                                                disabled
                                                                maxLength={15}
                                                                formatter={(value) => formatNumber(value)}
                                                                parser={(value) => parserNumber(value)}
                                                                style={{
                                                                    backgroundColor: "#F8FC9D",
                                                                    width: "100%"
                                                                }}
                                                            ></InputNumber>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={3}>
                                                        <Button
                                                            style={{ margin: "2px 0 0 13px" }}
                                                            size="small"
                                                            type="primary"
                                                            disabled={disableInsuranceAmt}
                                                            icon={<PlusSquareOutlined />}
                                                            onClick={() => setVisibleInsurance(true)}
                                                        ></Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Card>
                                    </Col>
                                </Row>
                            </Form>
                        </Spin>
                    </div>
                </Content>
            </Drawer>
            <div>
                {visibleModalExtraPrice && (
                    <AddExtraPriceModal
                        visibleModalExtraPrice={visibleModalExtraPrice}
                        setVisibleModalExtraPrice={setVisibleModalExtraPrice}
                        tWorksheetExtrapriceList={tWorksheetExtrapriceList}
                        setTWorksheetExtrapriceList={setTWorksheetExtrapriceList}
                    ></AddExtraPriceModal>
                )}
            </div>
            <div>
                {visibleInsurance && (
                    <AddInsuranceModal
                        custName={(selectedTCustomerHead?.iname) ? selectedTCustomerHead?.iname : ""}
                        visibleInsurance={visibleInsurance}
                        setVisibleInsurance={setVisibleInsurance}
                        tWorksheetInsurance={selectedTWorksheetInsurance}
                        setTWorksheetInsurance={setSelectedTWorksheetInsurance}
                    ></AddInsuranceModal>
                )}
            </div>
        </>
    );
};

export default AddEditWorkSheetSteelHeadDrawer;
