import { ReturnForWitholdingTaxData } from "src/domain/view/returnForWitholdingTaxData";
import http from "src/lib/http";
import Constants from "src/utils/constants";

const findReturnForWitholdingTaxViews = (entrydateFrom: string, entrydateTo: string, customercode: string, code: string) => {
  return http.get(Constants.DEFAULT_URL + "/returnForWitholdingTax/findReturnForWitholdingTaxViews", {
    params: {
      entrydateFrom,
      entrydateTo,
      customercode,
      code,
    }
  });
};

const createReturnForWitholdingTax = (returnReturnForWitholdingTaxData: ReturnForWitholdingTaxData) => {
  return http.post(Constants.DEFAULT_URL + "/returnForWitholdingTax/createReturnForWitholdingTax", returnReturnForWitholdingTaxData);
};

const defaultApi = {
  findReturnForWitholdingTaxViews,
  createReturnForWitholdingTax,
}
export default defaultApi;