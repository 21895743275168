import http from "../lib/http";
import Constants from "../utils/constants";

const getByDebitNote = (debitNoteCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tRangradMovement/getByDebitNote", {
    params:{
      debitNoteCode
    }
  });
}

const getToReport = (debitNoteCode: string, userId: string, startDate: string, endDate: string) => {
  return http.get(Constants.DEFAULT_URL + "/tRangradMovement/getToReport", {
    params:{
      debitNoteCode
    }
  });
}
const defaultApi = {
  getByDebitNote,
  getToReport,
};
export default defaultApi;