import { Col, Form, Input, Space, Spin, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { TFinanceDetails } from 'src/domain/finance/tFinanceDetails';
import { TFinanceHead2View } from 'src/domain/finance/view/tFinanceHead2View';
import tFinanceHead2Service from 'src/services/finance/tFinanceHead2Service';
import { formatTime, toThDate } from 'src/utils/dateTimeUtils';
import { formatNumber, formatNumberInt } from 'src/utils/numberUtils';

interface IProp {
  setTfinanceHead2Cashs: any;
}

const CashTrushTab: React.FC<IProp> = (props) => {
  const { setTfinanceHead2Cashs } = props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [countCodePaymentCash, setCountCodePaymentCash] = useState(0);//นับจำนวนใบงานเงินสด
  const [paymentCashAmt, setPaymentCashAmt] = useState(0);//จำนวนเงินสดทั้งหมด
  const [selectedTfinanceHead2Cashs, setSelectedTfinanceHead2Cashs] = useState<TFinanceHead2View[]>([]);
  const [tfinanceHead2CashFormList, setTfinanceHead2CashFormList] = useState<TFinanceHead2View[]>([]);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: TFinanceHead2View, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่การรับเงิน",
      dataIndex: "id",
      key: "id",
      width: 40
    },
    {
      title: "ประเภทรายการ",
      dataIndex: "typePayment",
      key: "typePayment",
      width: 40,
      render: (value: any, item: TFinanceHead2View) => {
        const tFinanceDetail: TFinanceDetails = item.tfinanceDetailList[0];
        if (tFinanceDetail.othercode) {
          return <span>รายรับอื่นๆ</span>;
        } else if (tFinanceDetail.debitnotecode) {
          return <span>ใบแจ้งหนี้</span>;
        } else {
          return <span>ใบงาน</span>;
        }
      },
    },
    {
      title: "เลขที่รายการ",
      dataIndex: "codePayment",
      key: "codePayment",
      width: 50,
      render: (value: any, item: TFinanceHead2View) => {
        const tFinanceDetail: TFinanceDetails = item.tfinanceDetailList[0];
        if (tFinanceDetail.othercode) {
          return <span>{tFinanceDetail.othercode}</span>;
        } else if (tFinanceDetail.debitnotecode) {
          return <span>{tFinanceDetail.debitnotecode}</span>;
        } else {
          return <span>{tFinanceDetail.worksheetcode}</span>;
        }
      },
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "customername",
      key: "customername",
      width: 80
    },
    {
      title: "จำนวนเงินสด",
      dataIndex: "cashamount",
      key: "cashamount",
      width: 40,
      render: (value: any, item: TFinanceHead2View) => {
        return <span>{formatNumber(item.cashamount)}</span>;
      },
    },
    {
      title: "ผู้รับชำระ",
      dataIndex: "createuserName",
      key: "createuserName",
      width: 60,
    },
    {
      title: "วันที่รับชำระ",
      dataIndex: "entryDate",
      key: "entryDate",
      width: 50,
      render: (value: any, item: TFinanceHead2View) => {
        return <span>{toThDate(item.entrydate) + " : " + formatTime(item.entrytime)}</span>;
      },
    },
  ];

  useEffect(() => {
    initial();
  }, []);

  useEffect(() => {
    if (selectedTfinanceHead2Cashs) {
      setTfinanceHead2Cashs([...selectedTfinanceHead2Cashs]);
    }
  }, [selectedTfinanceHead2Cashs]);

  const initial = async () => {
    let cashamountAll = 0;
    const fromDate = "20071218";//เริ่มนับตั้งแต่วันที่ 16/12/50
    setLoading(true);
    const res = await tFinanceHead2Service.getTFinanceHead2Cash(fromDate, "");
    if (res.data.length) {
      res.data.forEach((obj: TFinanceHead2View) => {
        cashamountAll += (obj.cashamount) ? obj.cashamount : 0;
      });
    }
    setTfinanceHead2CashFormList(res.data);
    setCountCodePaymentCash(res.data.length);
    setPaymentCashAmt(cashamountAll);
    setLoading(false);
  }

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: TFinanceHead2View[]) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedTfinanceHead2Cashs(selectedRows);
      setTfinanceHead2Cashs([...selectedRows]);
    },
  };

  const onCodePaymentChange = (codePayment: string) => {
    if (!codePayment) {
      return;
    }

    const tFinanceHead2View = tfinanceHead2CashFormList.find((obj) => {
      const tFinanceDetail: TFinanceDetails = obj.tfinanceDetailList[0];
      let codePaymentInList = tFinanceDetail.worksheetcode;
      if (tFinanceDetail.othercode) {
        codePaymentInList = tFinanceDetail.othercode;
      } else if (tFinanceDetail.debitnotecode) {
        codePaymentInList = tFinanceDetail.debitnotecode;
      }
      return codePaymentInList === codePayment;
    });
    setSelectedTfinanceHead2Cashs([...selectedTfinanceHead2Cashs, tFinanceHead2View!]);
    const selectedRowKeysTemp = [...selectedRowKeys, tFinanceHead2View!.id];
    setSelectedRowKeys(selectedRowKeysTemp);
    form.setFieldsValue({
      codePayment: undefined,
    })
  }

  return (
    <>
      <Content className="app-page-content">
        <Spin spinning={loading}>
          <>
            <Form
              form={form}
              name="cashTrushTab"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                  margin: "1em 0 0 0",
                  paddingLeft: "1em",
                  borderBlockEnd: "2px solid #c9c9c9",
                }}
              >
                <Col span={14} style={{ textAlign: "left", paddingLeft: "3.5em" }}>
                  <Space align="start" direction='vertical'>
                    <Typography.Title level={4}>
                      <span>เลขที่ใบงาน/ใบแจ้งหนี้/รายรับอื่นๆ <label className="text-red">*</label>
                      </span>
                    </Typography.Title>
                    <Form.Item
                      name="codePayment"
                      rules={[{ required: false }]}
                      style={{ width: 200 }}
                    >
                      <Input
                        allowClear={false}
                        onFocus={(e: any) => e.target.select()}
                        onKeyDown={(evt: any) => {
                          if (evt.keyCode === 13) {
                            onCodePaymentChange(evt.target.value);
                          }
                        }}
                      ></Input>
                    </Form.Item>
                  </Space>
                </Col>
                <Col span={3} style={{ textAlign: "center" }}>
                  <Space align="center" direction='vertical'>
                    <Typography.Title level={4}>ใบงานทั้งหมด</Typography.Title>
                    <Typography.Title level={5} style={{ margin: 0, color: "#FD1212" }}>
                      {formatNumberInt(countCodePaymentCash)}
                    </Typography.Title>
                  </Space>
                </Col>
                <Col span={3} style={{ textAlign: "center" }}>
                  <Space align="center" direction='vertical'>
                    <Typography.Title level={4}>จำนวนเงินทั้งหมด</Typography.Title>
                    <Typography.Title level={5} style={{ margin: 0, color: "#FD1212" }}>
                      {formatNumber(paymentCashAmt)}
                    </Typography.Title>
                  </Space>
                </Col>
                <Col span={4}></Col>
              </div>
            </Form>
            <div style={{ marginBottom: 0 }}>
              <Table
                columns={columns}
                dataSource={tfinanceHead2CashFormList}
                size="small"
                scroll={{ x: 1300, y: 250 }}
                pagination={false}
                rowSelection={rowSelection}
                rowKey={(item: TFinanceHead2View) => item.id}
              />
            </div>
          </>
        </Spin>
      </Content>
    </>
  )
}

export default CashTrushTab