import { CloseCircleOutlined, ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Card, Col, Drawer, Form, Input, InputNumber, Modal, Row, Select, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TEmployee2View } from 'src/domain/master/view/tEmployee2View';
import { TCreditnoteHeadView } from 'src/domain/view/tCreditnoteHeadView';
import { TWorksheetHeadView } from 'src/domain/worksheet/view/tWorksheetHeadView';
import { default as employeeService } from 'src/services/employee/tEmployee2Service';
import tCreditnoteHeadService from 'src/services/finance/tCreditnoteHeadService';
import tWorksheetHeadService from 'src/services/tWorksheetHeadService';
import { useAppSelector } from 'src/stores/store';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber, textNumber } from 'src/utils/numberUtils';

const { Option } = Select;
const { confirm } = Modal;

interface IProps {
  onDrawerVisible: boolean;
  setOnDrawerVisible: any;
}

const CreditnoteDrawer: React.FC<IProps> = (props) => {
  const { onDrawerVisible, setOnDrawerVisible } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [creditNotePreviewBlob, setCreditNotePreviewBlob] = useState<any>();
  const [creditNotePreviewData, setCreditNotePreviewData] = useState<any>();
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false);
  const [showAllPage, setShowAllPage] = useState<boolean>(false);

  const [disabledCreditDiscount, setDisabledCreditDiscount] = useState<boolean>(true);
  const [tWorksheetHeadView, setTWorksheetHeadView] = useState<TWorksheetHeadView>({} as TWorksheetHeadView);
  const [employees, setEmployees] = useState<TEmployee2View[]>([]);//ผู้แจ้ง

  useEffect(() => {
    if (trigger) {
      form.validateFields()
        .then(() => {
          confirm({
            title: "ยืนยัน!",
            icon: <ExclamationCircleOutlined />,
            content: "ต้องการออกใบลดหนี้หรือไม่?",
            cancelText: "ยกเลิก",
            okText: "ยืนยัน",
            onOk() {
              form.submit();
            },
            onCancel() {
              setTrigger(false);
            }
          });
        }).catch(() => {
          setTrigger(false);
        });
    }
  }, [trigger]);

  const loadWorksheetData = async (worksheetCode: any) => {
    if (!worksheetCode) return;
    const workSheetHeadViewRes = await tWorksheetHeadService.getByWorksheetCodeView(worksheetCode);
    if (!workSheetHeadViewRes.data) {
      showNotification(NotificationType.INFO, "ไม่พบเลขที่ใบงาน");
    } else {
      if (await checkData(workSheetHeadViewRes.data)) {
        const worksheetHeadView: TWorksheetHeadView = workSheetHeadViewRes.data;
        form.setFieldsValue({
          customername: worksheetHeadView.icustName,
          bookInVoice: "",
          bookNumberInVoice: "",
          itypeworksheetName: worksheetHeadView.itypeworksheetName,
          serviceAmount: formatNumber(worksheetHeadView.itotal1Amt),
          itypepaymentName: worksheetHeadView.itypepaymentName,
          itaxAmt: formatNumber(worksheetHeadView.itaxAmt),
          iworksheetStatusName: worksheetHeadView.iworksheetStatusName,
          itotal2Amt: formatNumber(worksheetHeadView.itotal2Amt),
          creditDiscount: formatNumber(0),
          creditVat: formatNumber(0),
          creditTotal: formatNumber(0),
          serviceAfterDiscount: formatNumber(worksheetHeadView.itotal1Amt),
          vatAfterDiscount: formatNumber(worksheetHeadView.itaxAmt),
          totalAfterDiscount: formatNumber(worksheetHeadView.itotal2Amt),
        });

        setDisabledCreditDiscount(false);
        setTWorksheetHeadView(worksheetHeadView);
      } else {
        return;
      }
    }
  }

  const checkData = async (data: TWorksheetHeadView) => {
    let result: boolean = true;
    if (!data.itransferSalary?.trim()) {
      showNotification(NotificationType.INFO, "ใบงานนี้ยังไม่ได้โอนข้อมูลเงินเดือน");
      result = false;
    } else if (data.typeworksheetflag !== "00" && data.typeworksheetflag !== "01" && data.typeworksheetflag !== "03") {
      showNotification(NotificationType.INFO, "ต้องเป็นใบงานปกติ,ใบงานเหมาย่อย,ใบงานเข้าไซด์ เท่านั้น");
      result = false;
    }

    const tCreditNoteHeadRes = await tCreditnoteHeadService.isExistByWorksheetCode(data.iworksheetCode!)
    if (tCreditNoteHeadRes.data) {
      showNotification(NotificationType.INFO, "ใบงานนี้ออกใบลดนี้แล้ว");
      result = false;
    }
    return result;
  }

  const calculation = async () => {
    let creditDiscount = form.getFieldValue("creditDiscount") ? parserNumber(form.getFieldValue("creditDiscount")) : 0;
    let serviceAfterDiscount = 0;
    let vatAfterDiscount = 0;
    let totalAfterDiscount = 0;
    let vatDiscount = 0;
    let totalDiscount = 0;
    if (tWorksheetHeadView.itypetaxCode === "01") {
      serviceAfterDiscount = tWorksheetHeadView.itotal1Amt! - creditDiscount;
      vatAfterDiscount = Math.round(((serviceAfterDiscount * 7) / 100) * 100) / 100;
      vatDiscount = Math.round(((creditDiscount * 7) / 100) * 100) / 100;
    } else if (tWorksheetHeadView.itypetaxCode === "02") {
      serviceAfterDiscount = tWorksheetHeadView.itotal2Amt! - creditDiscount;
      vatAfterDiscount = Math.round(((serviceAfterDiscount * 7) / 107) * 100) / 100;
      serviceAfterDiscount = serviceAfterDiscount - vatAfterDiscount;
      vatDiscount = Math.round(((creditDiscount * 7) / 107) * 100) / 100;
      creditDiscount = creditDiscount - vatDiscount;
    } else {
      serviceAfterDiscount = tWorksheetHeadView.itotal2Amt! - creditDiscount;
      vatAfterDiscount = 0.0;
      vatDiscount = 0.0;
    }

    totalAfterDiscount = serviceAfterDiscount + vatAfterDiscount;
    totalDiscount = creditDiscount + vatDiscount;

    form.setFieldsValue({
      creditVat: formatNumber(vatDiscount),
      creditTotal: formatNumber(totalDiscount),
      serviceAfterDiscount: formatNumber(serviceAfterDiscount),
      vatAfterDiscount: formatNumber(vatAfterDiscount),
      totalAfterDiscount: formatNumber(totalAfterDiscount),
    });
  }

  const handleSearchEmployee = async (data: any) => {
    if (data.length < 3) return;
    const res = await employeeService.getEmployee2ByParam(data);
    setEmployees(res.data);
  };

  const onFinish = async (values: any) => {
    const empSelected = (employees as Array<TEmployee2View>).find((obj) => obj.id === values.userInfoId);
    const tcreditHeadView: TCreditnoteHeadView = {
      typework: "00",
      workcode: values.workcode,
      creditDiscount: parserNumber(values.creditDiscount),
      creditRemark: values.creditRemark,
      crediteStatus: "00",
      customercode: tWorksheetHeadView.icustCode!,
      customername: values.customername,
      paymentId: "",
      creditVat: parserNumber(values.creditVat),
      creditTotal: parserNumber(values.creditTotal),
      printNumber: 0,
      branch: userInfo.saveStation,
      createUser: userInfo.userId,
      serviceAmt: parserNumber(values.serviceAmount),
      serviceAfterDiscount: parserNumber(values.serviceAfterDiscount),
      vatAfterDiscount: parserNumber(values.vatAfterDiscount),
      totalAfterDiscount: parserNumber(values.totalAfterDiscount),      
      bookInVoice: values.bookInVoice,
      bookNumberInVoice: values.bookInVoice,
      userReportName: empSelected ? empSelected.empname : '',
      createUserName: userInfo.fullName,
      id: '0',
      tworksheetHeadView: tWorksheetHeadView,
    };

    try {
      const creditNoteRes = await tCreditnoteHeadService.createTcreditnote(tcreditHeadView);
      if (creditNoteRes.data) {
        await printCreateNote(creditNoteRes.data);
        setTrigger(false);
        showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเรียบร้อย");
      }
    } catch {
      showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ");
      setTrigger(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน !");
    setTrigger(false);
  };

  const printCreateNote = async (creditNoteHeadView: TCreditnoteHeadView) => {
    setLoading(true);
    const resultCreditNotePreview = await tCreditnoteHeadService.printPreviewAndSaveCreditnote(creditNoteHeadView, showAllPage);
    if (resultCreditNotePreview.data) {
      setCreditNotePreviewBlob(resultCreditNotePreview.data);
      const fileObj = URL.createObjectURL(resultCreditNotePreview.data);
      setCreditNotePreviewData(fileObj);
      setIsPreviewModalVisible(true);
    } else {
      showNotification(
        NotificationType.ERROR,
        "ไม่พบข้อมูลที่เกี่ยวข้อง ไม่สามารถแสดงข้อมูลตัวอย่างได้"
      );
    }
    setLoading(false);
  }

  return (
    <>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Typography.Title level={4}></Typography.Title>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "",
              }}
            >
              <Button
                type="primary"
                danger
                icon={<CloseCircleOutlined />}
                onClick={() => setOnDrawerVisible(false)}>
                ยกเลิก
              </Button>
              <Button
                onClick={() => setTrigger(true)}
                type="primary"
                icon={<SaveOutlined />}
                style={{ marginLeft: "5px" }}
              >
                บันทึก
              </Button>
            </div>
          </div>
        }
        width="90%"
        visible={onDrawerVisible}
        forceRender={true}
        placement="right"
        bodyStyle={{ paddingBottom: 80 }}
        closable={false}
        destroyOnClose={true}
      >
        <Content className="app-page-content">
          <Form
            form={form}
            name="creditNote"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Card
              style={{
                width: "auto",
                borderRadius: "10px 10px 10px 10px",
                margin: "5px 0px 5px 0px",
                // backgroundColor: "#f3f3f3",
              }}
            >
              <Typography.Title level={4}>รายละเอียดใบงาน</Typography.Title>
              <Row>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เลขที่ใบงาน: </Typography.Text>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="workcode"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input
                      onFocus={(e: any) => { e.target.select() }}
                      onPressEnter={(event: any) => loadWorksheetData(event.target.value)}
                    ></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ชื่อลูกค้า: </Typography.Text>
                </Col>
                <Col span={20}>
                  <Form.Item
                    name="customername"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input disabled></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ใบกำกับภาษีเล่มที่: </Typography.Text>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="bookInVoice"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input disabled></Input>
                  </Form.Item>
                </Col>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เลขที่: </Typography.Text>
                </Col>
                <Col span={8}>
                  {/* <Space align='baseline' style={{ width: "100%" }}> */}
                  <Form.Item
                    name="bookNumberInVoice"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input disabled></Input>
                  </Form.Item>
                  {/* </Space> */}
                </Col>
              </Row>
              <Row>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ประเภทใบงาน: </Typography.Text>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="itypeworksheetName"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input disabled></Input>
                  </Form.Item>
                </Col>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ค่าบริการ: </Typography.Text>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="serviceAmount"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    {/* <Input style={{ fontWeight: "bold", textAlign: "right" }} disabled></Input> */}
                    <InputNumber
                      style={{ fontWeight: "bold", textAlign: "right", width: 200, backgroundColor: "#F7F7F8" }}
                      disabled
                    >
                    </InputNumber>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ประเภทการชำระ: </Typography.Text>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="itypepaymentName"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input disabled></Input>
                  </Form.Item>
                </Col>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ภาษี: </Typography.Text>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="itaxAmt"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    {/* <Input style={{ fontWeight: "bold", textAlign: "right" }} disabled></Input> */}
                    <InputNumber
                      style={{ fontWeight: "bold", textAlign: "right", width: 200, backgroundColor: "#F7F7F8" }}
                      disabled
                    ></InputNumber>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>สถานะใบงาน: </Typography.Text>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="iworksheetStatusName"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input disabled></Input>
                  </Form.Item>
                </Col>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ค่าบริการรวมภาษี: </Typography.Text>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="itotal2Amt"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    {/* <Input style={{ fontWeight: "bold", textAlign: "right" }} disabled></Input> */}
                    <InputNumber
                      style={{ fontWeight: "bold", textAlign: "right", width: 200, backgroundColor: "#F7F7F8" }}
                      disabled
                    ></InputNumber>
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Card
              style={{
                width: "auto",
                borderRadius: "10px 10px 10px 10px",
                margin: "5px 0px 5px 0px",
              }}
            >
              <Typography.Title level={4}>ข้อมูลลดหนี้</Typography.Title>
              <Row>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ส่วนลด: </Typography.Text>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="creditDiscount"
                    style={{ margin: "0 0 3px 0px" }}
                    rules={[{ required: true, message: "กรุณาระบุส่วนลด" },
                    {
                      validator: (_, value) => {
                        if (!value || parserNumber(value) <= 0) {
                          return Promise.reject(new Error('กรุณาระบุส่วนลด'))
                        } else {
                          return Promise.resolve()
                        }
                      },
                    },
                    ]}
                  >
                    <Input
                      type="text"
                      style={{ textAlign: "end", width: "47%" }}
                      disabled={disabledCreditDiscount}
                      onKeyDown={(event: any) => textNumber(event)}
                      onChange={calculation}
                      onFocus={(e: any) => { e.target.select() }}
                      onBlur={(e: any) => { form.setFieldsValue({ creditDiscount: formatNumber(parserNumber(e.target.value)) }) }}
                      onPressEnter={(e: any) => { form.setFieldsValue({ creditDiscount: formatNumber(parserNumber(e.target.value)) }) }}
                    ></Input>
                  </Form.Item>
                </Col>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ค่าบริการหลังให้ส่วนลด: </Typography.Text>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="serviceAfterDiscount"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <InputNumber
                      style={{ fontWeight: "bold", textAlign: "right", width: 200, backgroundColor: "#F7F7F8" }}
                      disabled
                    ></InputNumber>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ภาษีส่วนลด: </Typography.Text>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="creditVat"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <InputNumber
                      style={{ fontWeight: "bold", textAlign: "right", width: 200, backgroundColor: "#F7F7F8" }}
                      disabled
                    ></InputNumber>
                  </Form.Item>
                </Col>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ภาษี: </Typography.Text>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="vatAfterDiscount"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <InputNumber
                      style={{ fontWeight: "bold", textAlign: "right", width: 200, backgroundColor: "#F7F7F8" }}
                      disabled
                    ></InputNumber>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ส่วนลดรวมภาษี: </Typography.Text>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="creditTotal"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <InputNumber
                      style={{ fontWeight: "bold", textAlign: "right", width: 200, backgroundColor: "#F7F7F8" }}
                      disabled
                    ></InputNumber>
                  </Form.Item>
                </Col>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>รวมค่าบริการ: </Typography.Text>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="totalAfterDiscount"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <InputNumber
                      style={{ fontWeight: "bold", textAlign: "right", width: 200, backgroundColor: "#F7F7F8" }}
                      disabled
                    ></InputNumber>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ผู้แจ้ง: </Typography.Text>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="userInfoId"
                    style={{ margin: "0 0 3px 0px" }}
                    rules={[{ required: true, message: "กรุณาระบุผู้แจ้ง" }]}
                  >
                    <Select
                      showSearch
                      size="middle"
                      placeholder="เลือกผู้แจ้ง"
                      optionFilterProp="children"
                      filterOption={true}
                      onSearch={handleSearchEmployee}
                    >
                      {employees.map((obj) => {
                        return (
                          <Option key={obj.id} value={obj.id}>
                            {obj.empname}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>หมายเหตุ: </Typography.Text>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="creditRemark"
                    style={{ margin: "0 0 3px 0px" }}
                    validateTrigger="onBlur"
                    rules={[{ required: true, message: "กรุณาระบุหมายเหตุ" }]}
                  >
                    <TextArea
                      rows={4}
                    ></TextArea>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Form>
        </Content>
      </Drawer>
      <Modal
        style={{ top: 20 }}
        width={"90vw"}
        title="CreditNote"
        bodyStyle={{ height: "85vh" }}
        centered={true}
        visible={isPreviewModalVisible}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={() => {
          setIsPreviewModalVisible(false)
          setOnDrawerVisible(false);
        }}
      >
        <iframe title=" " src={creditNotePreviewData} width={"100%"} height={"100%"}></iframe>
      </Modal>
    </>
  )
}

export default CreditnoteDrawer