import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, Row, Select, Table, Typography, Modal, Checkbox } from 'antd';
import moment from 'moment';
import Constants from 'src/utils/constants';
import PageHeader from 'src/layouts/PageHeader';
import { Content } from 'antd/lib/layout/layout';
import { useAppSelector } from 'src/stores/store';
import { PrinterOutlined, SearchOutlined } from '@ant-design/icons';
import reportService from 'src/services/reportService';
import { formatNumber, formatNumberInt } from 'src/utils/numberUtils';
import PdfViewPopup from './PdfViewPopup';
import { TRangradMovement } from 'src/domain/tRangradMovement';
import { toThDate, formatTime } from 'src/utils/dateTimeUtils';
import { TUserHead } from 'src/domain/tUserHead';
import userService from 'src/services/tUserHeadService';

const { Option } = Select;

const MovementDebitNoteReport = () => {
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  const [userHead, setUserHeads] = useState<TUserHead[]>([]);    //ผู้ใช้งาน

  const [rangradMovementList, setRangradMovementList] = useState<TRangradMovement[]>([]);
  const [rangradMovementPreview, setRangradMovementPreview] = useState<any>();

  const [visiblePDF, setVisiblePDF] = useState(false);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false);

  const [empIdParam, setEmpIdParam] = useState<any>();
  const [debitnoteParam, setDebitnoteParam] = useState<any>();
  const [fromDateParam, setFromDateParam] = useState<any>();
  const [toDateParam, setToDateParam] = useState<any>();

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 5,
      align: "center" as "center",
      render: (value: number, item: TRangradMovement, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
    },
    {
      title: "วันที่กำหนด",
      dataIndex: "movementDate",
      key: "movementDate",
      width: 10,align: "center" as "center"
    },
    {
      title: "เลขที่ใบแจ้งหนี้",
      dataIndex: "idebitnote",
      key: "idebitnote",
      width: 10,
    },
    {
      title: "หมายเหตุระบุ",
      dataIndex: "isystemRemark",
      key: "isystemRemark",
      width: 180,
    },
    {
      title: "ชื่อพนักงาน",
      dataIndex: "userFullname",
      key: "userFullname",
      width: 30,
    },
  ]

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });

    initData();
    form.submit();
  }, []);

  const handleSearchUser = async (data: any) => {
    if (data.length < 3) return;
    const res = await userService.getUserHeadNameList(data);
    setUserHeads(res.data);
  };

  const initData = async () => {
    form.setFieldsValue({
      debitnote: "",
      userInfoId: "",
      fromDate: moment(new Date(), Constants.DATE_FORMAT),
      toDate: moment(new Date(), Constants.DATE_FORMAT)
    });
  }

  const rowClassNameFn = (record: TRangradMovement, index) => {
    if(record.idebitStatus === '99'){
      return "row-blue"
    } else if(record.idebitStatus === '98'){
      return "row-red"
    }
    
    return null;
  }


  const onFinish = async (values: any) => {
    setLoading(true);
    const debitnote = values.debitnote ? values.debitnote : "";
    const empId = values.userInfoId ? values.userInfoId : "";
    const fromDate = moment(values.fromDate).format("yyyyMMDD");
    const toDate = moment(values.toDate).format("yyyyMMDD");

    const res = await reportService.getMovementDebitNoteReport(debitnote, empId, fromDate, toDate);
    // console.log('----------res--------', res.data)

    setRangradMovementList(res.data);
    // setLoading(false);

    setPage(1);
    setDebitnoteParam(debitnote);
    setEmpIdParam(empId);
    setFromDateParam(fromDate);
    setToDateParam(toDate);
    setLoading(false);
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  const onPrint = async () => {
    setVisiblePDF(true);
  }

  return (
    <>
      <PageHeader title="รายงานความเคลื่อนไหวใบแจ้งหนี้"></PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="rengradWangbill"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
            }}
          >
           
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เลขที่ใบแจ้งหนี้: </Typography.Text>
              </Col>
              <Col span={7}>
                <Form.Item name="debitnote" style={{ marginBottom: "3px" }}>
                  <Input style={{ marginRight: 5 }}></Input>
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ผู้ใช้งาน: </Typography.Text>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="userInfoId"
                  style={{ marginBottom: "3px" }}
                 >
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกผู้ใช้งาน"
                    optionFilterProp="children"
                    filterOption={true}
                    onSearch={handleSearchUser}
                  >
                    {userHead.map((obj) => {
                      return (
                        <Option key={obj.iuserId} value={obj.iuserId}>
                          {obj.ifullName}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>วันที่ตั้งแต่: </Typography.Text>
              </Col>
              <Col span={3}>
                <Form.Item
                  name="fromDate"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={1} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ถึง: </Typography.Text>
              </Col>
              <Col span={3}>
                <Form.Item
                  name="toDate"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col span={13} style={{ textAlign: "right"}}>
                <Button
                  size="small"
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={form.submit}
                >
                  ค้นหา
                </Button>
              </Col>
              <Col span={8}></Col>
            </Row>
          </div>
        </Form>
           
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "0px 20px 10px 0px",
            borderBottom: '2px solid #c9c9c9'
          }}
        >
          <Button
          style={{
            marginLeft: "30px",}}
            size="small"
            type="primary"
            icon={<PrinterOutlined />}
            onClick={onPrint}
          >
            พิมพ์
          </Button>
          <Modal
            style={{ top: 20 }}
            width={"90vw"}
            title="RR002"
            bodyStyle={{ height: "85vh" }}
            centered={true}
            visible={isPreviewModalVisible}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={() => setIsPreviewModalVisible(false)}
          >
            <iframe title=" " src={rangradMovementPreview} width={"100%"} height={"100%"}></iframe>
          </Modal>
        </div>

        <div>
          <Table
            rowClassName={rowClassNameFn}
            columns={columns}
            dataSource={rangradMovementList}
            size="small"
            loading={loading}
            rowKey={(item: TRangradMovement) => item.iseq!}
            // scroll={{ x: 1500 }}
            pagination={{
              pageSize: 10,
              current: page,
              onChange(current) {
                setPage(current);
              }
            }}
          />
        </div>
      </Content>

      <Modal
        title= 'print'
        centered
        closable={true}
        visible={visiblePDF}
        onCancel={() => setVisiblePDF(false)}
        width="400"
      >
        <PdfViewPopup
          urlPath={reportService.getRangradMovementReportPreview(debitnoteParam, empIdParam, fromDateParam, toDateParam)}
          onClose={() => setVisiblePDF(false)}
        />
      </Modal>
    </>
  )
}

export default MovementDebitNoteReport