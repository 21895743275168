import { EditOutlined } from "@ant-design/icons";
import { Button, Descriptions, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { TEmployee2View } from "src/domain/master/view/tEmployee2View";
import PageHeader from "src/layouts/PageHeader";
import { getYearOlds, toThDate } from "src/utils/dateTimeUtils";
import { formatNumber } from "src/utils/numberUtils";
import { getFlg } from "src/utils/rodyokUtils";
import tEmployeeService from "../../services/employee/tEmployee2Service";
import AddEditEmployee2Drawer from "./AddEditEmployee2Drawer";

interface IParams {
    id: string;
}

const ViewEmployee2Drawer: React.FC<RouteComponentProps<IParams>> = (props) => {
    const id = props.match.params.id;
    const [employee2, setEmployee2] = useState<TEmployee2View>({} as TEmployee2View);
    const history = useHistory();
    const [visibleAddEditEmployee2Drawer, setVisibleAddEditEmployee2Drawer] = useState<boolean>(false);

    useEffect(() => {
        if (!visibleAddEditEmployee2Drawer) {
            initial();
        }
    }, [visibleAddEditEmployee2Drawer]);

    const initial = async () => {
        const res = await tEmployeeService.getEmployee2ByCode(id);
        setEmployee2(res.data);
    };

    const onEditButtonClicked = () => {
        setVisibleAddEditEmployee2Drawer(true);
    }

    return (
        <>
            <PageHeader title="" onClose={() => history.push("/app/employee/list")} />
            <Content className="app-page-content">
                <Descriptions
                    style={{ margin: "1em" }}
                    column={4}
                    title="ข้อมูลพนักงาน"
                    size="middle"
                    extra={
                        <Button
                            size="middle"
                            type="primary"
                            icon={<EditOutlined />}
                            onClick={onEditButtonClicked}

                        >
                            แก้ไข
                        </Button>
                    }
                />
                <div style={{ display: "flex", justifyContent: "flex-start", margin: "1em" }}>
                    <Descriptions
                        column={6}
                        size="middle"
                        bordered
                        labelStyle={{ textAlign: "right", fontWeight: "bold" }}
                    >
                        <Descriptions.Item label="รหัสพนักงาน" span={2}>{employee2.id}</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="สาขา" span={2}>({employee2.empbranch}) {employee2.empbranchName}</Descriptions.Item>
                        <Descriptions.Item label="ประเภทพนักงาน" span={2}>({employee2.typeemployeecode}) {employee2.typeemployeeName}</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="ตำแหน่ง" span={2}>({employee2.positioncode}) {employee2.positionName}</Descriptions.Item>
                        <Descriptions.Item label="ชื่อ-สกุล" span={2}>{employee2.empname}</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="วัน เดือน ปี เกิด" span={2}>{employee2.empbirthdate ? toThDate(employee2.empbirthdate) : ""}</Descriptions.Item>
                        <Descriptions.Item label="เลขที่บัตรประชาชน" span={2}>{employee2.emppersonalid}</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="วันที่เข้าทำงาน" span={2}>{employee2.empentrydate ? toThDate(employee2.empentrydate) : ""}</Descriptions.Item>
                        <Descriptions.Item label="อายุ" span={6}> {employee2.empbirthdate ? getYearOlds(employee2.empbirthdate) : ""} ปี </Descriptions.Item>
                        <Descriptions.Item label="ที่อยู่ บ้านเลขที่" span={6}>
                            {employee2.addresshomenumber}
                            {(employee2.addresshosename && employee2.addresshosename !== "-") ? `ชื่อหมู่บ้าน/อาคาร ${employee2.addresshosename}` : ""}
                            {(employee2.addressroomnumber && employee2.addressroomnumber !== "-") ? `ห้อง ${employee2.addressroomnumber}` : ""}
                            {(employee2.addressmoo && employee2.addressmoo !== "-") ? `หมู่ที่ ${employee2.addressmoo}` : ""}
                        </Descriptions.Item>
                        <Descriptions.Item label="ตรอก" span={2}>{employee2.addresslane}</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="ถนน" span={2}>{employee2.addressroad}</Descriptions.Item>
                        <Descriptions.Item label="ตำบล" span={2}>{employee2.addresstumbol}</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="อำเภอ" span={2}>({employee2.addressamphurcode}) {employee2.addressamphurName}</Descriptions.Item>
                        <Descriptions.Item label="จังหวัด" span={2}>({employee2.addressprovincecode}) {employee2.addressprovinceName}</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="รหัสไปรษณีย์" span={2}>{employee2.addresspostcode}</Descriptions.Item>
                        <Descriptions.Item label="โทรศัพท์" span={2}>{employee2.addresstelephonenumber}</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="ต่อ" span={2}>{employee2.addresstelephonenumberplus}</Descriptions.Item>
                        <Descriptions.Item label="มือถือ" span={6}>{employee2.addressmobilenumber}</Descriptions.Item>
                        <Descriptions.Item label="ธนาคาร" span={2}>({employee2.bankcode}) {employee2.bankName}</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="เลขที่บัญชีธนาคาร" span={2}>{employee2.accountcode}</Descriptions.Item>
                        <Descriptions.Item label="สาขาธนาคาร" span={6}>({employee2.bankbranchcode}) {employee2.bankbranchName}</Descriptions.Item>
                        <Descriptions.Item label="จำนวน % ที่ได้รับปกติ" span={2}>{formatNumber(employee2.percentperday)}</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="จำนวน % ที่ได้รับ(OT)" span={2}>{formatNumber(employee2.percentotperhour)}</Descriptions.Item>
                        <Descriptions.Item label="เบี้ยเลี้ยงที่ได้รับปกติ" span={2}>{formatNumber(employee2.otherexressperahalfday)}</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="เบี้ยเลี้ยงที่ได้รับปกติ" span={2}>{formatNumber(employee2.otherexressperday)}</Descriptions.Item>
                        <Descriptions.Item label="เบี้ยเลี้ยงเพิ่มหลัง 5 โมงเย็น" span={2}>{formatNumber(employee2.otherexressafterfiveoclock)}</Descriptions.Item>
                        <Descriptions.Item label="" span={2} labelStyle={{ backgroundColor: "#fafafa" }}> </Descriptions.Item>
                        <Descriptions.Item label="จำนวนเงินสะสมรวม" span={2}>0.00</Descriptions.Item>
                        <Descriptions.Item label="วันสิ้นสุดการทำงาน" span={6}>{employee2.empendworkdate ? toThDate(employee2.empendworkdate) : ""}</Descriptions.Item>
                        <Descriptions.Item label="สถานะ" span={6}>{getFlg(employee2.flgdel) ? <Typography.Text type="success">ใช้งาน</Typography.Text> : <Typography.Text type="danger">ไม่ใช้งาน</Typography.Text>}</Descriptions.Item>
                    </Descriptions>
                </div>
            </Content>
            <div> {
                visibleAddEditEmployee2Drawer && <AddEditEmployee2Drawer
                    id={employee2.id}
                    visibleAddEditEmployee2Drawer={visibleAddEditEmployee2Drawer}
                    setVisibleAddEditEmployee2Drawer={setVisibleAddEditEmployee2Drawer} />
            }</div>
        </>
    );
};

export default ViewEmployee2Drawer;