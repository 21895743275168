import { ClearOutlined, PlusSquareOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row, Space, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TCreditnoteHeadView } from 'src/domain/view/tCreditnoteHeadView';
import PageHeader from 'src/layouts/PageHeader';
import tCreditnoteHeadService from 'src/services/finance/tCreditnoteHeadService';
import { useAppSelector } from 'src/stores/store';
import Constants from 'src/utils/constants';
import { toThDate } from 'src/utils/dateTimeUtils';
import { formatNumber } from 'src/utils/numberUtils';
import CreditnoteDrawer from './CreditnoteDrawer';

const CreditnoteList: React.FC<TCreditnoteHeadView> = () => {
  const [page, setPage] = React.useState(1);
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [onDrawerVisible, setOnDrawerVisible] = useState<boolean>(false);

  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [tcreditnoteHeadViews, setTcreditnoteHeadViews] = useState<TCreditnoteHeadView[]>([]);
  
  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 50,
      render: (value: number, item: TCreditnoteHeadView, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
    },
    {
      title: "วันที่สร้างใบลดหนี้",
      dataIndex: "entryDate",
      key: "entryDate",
      width: 120,
      render: (value: any, item: TCreditnoteHeadView) => (<>{toThDate(item.entryDate)}</>)
    },
    {
      title: "เลขที่ใบลดหนี้",
      dataIndex: "id",
      key: "id",
      width: 120,
      render: (value: any, item: TCreditnoteHeadView) => (
        <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.id}</Button>
      )
    },
    {
      title: "เลขที่ใบงาน",
      dataIndex: "workcode",
      key: "workcode",
      width: 120,
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "customername",
      key: "customername",
      width: 250,
    },
    {
      title: "ประเภทใบงาน",
      dataIndex: "typeworkName",
      key: "typeworkName",
      width: 100,
    },
    {
      title: "ค่าบริการ",
      dataIndex: "worksheetTotalAmt1",
      key: "worksheetTotalAmt1",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TCreditnoteHeadView) => (<>{formatNumber(item.serviceAmt)}</>)
    },
    {
      title: "ส่วนลด",
      dataIndex: "creditDiscount",
      key: "creditDiscount",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TCreditnoteHeadView) => (<>{formatNumber(item.creditDiscount)}</>)
    },
    {
      title: "รวมค่าบริการ",
      dataIndex: "nettotal",
      key: "nettotal",
      width: 100,
      align: "right" as "right",
      render: (value: any, item: TCreditnoteHeadView) => (<>{formatNumber(item.serviceAfterDiscount)}</>)
    },
    {
      title: "หมายเหตุ",
      dataIndex: "creditRemark",
      key: "creditRemark",
      width: 250,
    },
    {
      title: "ผู้แจ้ง",
      dataIndex: "userReportName",
      key: "userReportName",
      width: 150,
    },
  ];

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    initData();
  }, []);
  
  const initData = async () => {
    form.setFieldsValue({
      fromDate: moment(new Date(), Constants.DATE_FORMAT).startOf('month'),
      toDate: moment(new Date(), Constants.DATE_FORMAT).endOf('month'),
      creditnoteCode: undefined,
      wshCode: undefined,
    });
  }

  function onReset() {
    initData();
  }

  const onItemCliked = (item: TCreditnoteHeadView) => {
    history.replace("/app/creditNote/view/" + item.id);
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    const wshCode = values.wshCode ? values.wshCode : "";
    const creditnoteCode = values.creditnoteCode ? values.creditnoteCode : "";
    let fromDate = moment(values.fromDate).format("yyyyMMDD");
    let toDate = moment(values.toDate).format("yyyyMMDD");

    if (wshCode || creditnoteCode) {
      fromDate = "";
      toDate = "";
    }

    const res = await tCreditnoteHeadService.findTCreditnoteHeadViews(
      wshCode,
      creditnoteCode,
      fromDate,
      toDate);
    setTcreditnoteHeadViews(res.data);
    setLoading(false);
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  return (
    <>
      <PageHeader title="โปรแกรมสร้างใบลดหนี้กรณีใบงานคิดเงินเดือนพนักงานแล้ว">
        <Button
          size="small"
          type="primary"
          icon={<PlusSquareOutlined />}
          onClick={() => setOnDrawerVisible(true)}
        >
          เพิ่ม
        </Button>
      </PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="creditnote"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
            }}
          >
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>วันที่สร้างใบลดหนี้ ตั้งแต่: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="fromDate"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เลขที่ลดหนี้: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="creditnoteCode"
                  rules={[{ required: false }]}
                >
                  <Input
                    allowClear
                    placeholder="ป้อนเลขที่ใบลดหนี้"
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ถึง: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="toDate"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เลขที่ใบงาน: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="wshCode"
                  rules={[{ required: false }]}
                >
                  <Input
                    allowClear
                    placeholder="ป้อนเลขที่ใบงาน"
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: 'center',
              paddingBottom: "1em",
              borderBlockEnd: "2px solid #c9c9c9",
            }}
          >
            <Space>
              <Button
                size="small"
                type="primary"
                icon={<SearchOutlined />}
                onClick={form.submit}
              >
                ค้นหา
              </Button>
              <Button
                size="small"
                type="primary"
                icon={<ClearOutlined />}
                onClick={onReset}
              >
                ล้าง
              </Button>
            </Space>
          </div>
        </Form>
        <div>
          <Table
            columns={columns}
            dataSource={tcreditnoteHeadViews}
            size="small"
            loading={loading}
            scroll={{ x: 1800 }}
            pagination={{
              pageSize: 15,
              onChange(current) {
                setPage(current);
              }
            }}
            rowKey={(item: TCreditnoteHeadView) => item.id!}
            onRow={(item: TCreditnoteHeadView) => {
              return {
                onDoubleClick: () => onItemCliked(item),
              };
            }}
          />
        </div>
      </Content>
      <div>
        {onDrawerVisible && (
          <CreditnoteDrawer
            onDrawerVisible={onDrawerVisible}
            setOnDrawerVisible={setOnDrawerVisible}
          ></CreditnoteDrawer>
        )}
      </div>
    </>
  )
}

export default CreditnoteList