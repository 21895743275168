import { CloseCircleOutlined, ExclamationCircleOutlined, PlusSquareOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, Drawer, Form, Input, InputNumber, Modal, Row, Space, Spin, Table, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { TChequeHead } from 'src/domain/cheque/tChequeHead';
import { TFinanceChequedetail } from 'src/domain/finance/tFinanceChequedetail';
import { TFinanceOtherworkdetail } from 'src/domain/finance/tFinanceOtherworkdetail';
import { TFinancePayindetail } from 'src/domain/finance/tFinancePayindetail';
import { TBankBranchHead } from 'src/domain/master/tBankBranchHead';
import { TBankHead } from 'src/domain/master/tBankHead';
import { TPayinHead } from 'src/domain/tPayinHead';
import { TTypeInsurancePaymentRef } from 'src/domain/tTypeInsurancePaymentRef';
import { TWorksheetInsuranceData } from 'src/domain/worksheet/view/tWorksheetInsuranceData';
import { TWorksheetInsuranceView } from 'src/domain/worksheet/view/tWorksheetInsuranceView';
import ryAccountService from 'src/services/bank/ryAccountService';
import tTypeInsurancePaymentRefService from 'src/services/reference/tTypeInsurancePaymentRefService';

import { TFinanceHead2 } from 'src/domain/finance/tFinanceHead2';
import tWorksheetInsuranceService from 'src/services/tWorksheetInsuranceService';
import { toThDate } from 'src/utils/dateTimeUtils';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import { formatNumber, parserNumber, textNumber } from 'src/utils/numberUtils';
import tBankBranchHeadService from "../../services/bank/tBankBranchHeadService";
import tBankHeadService from "../../services/bank/tBankHeadService";
import AddTChequeHeadModal from '../modals/AddTChequeHeadModal';
import AddTPayinHeadModal from '../modals/AddTPayinHeadModal';
import SearchOtherWorkSearchModal from '../modals/SearchOtherWorkSearchModal';
import SearchTChequeHeadModal from '../modals/SearchTChequeHeadModal';
import SearchTPayinHeadModal from '../modals/SearchTPayinHeadModal';

const { confirm } = Modal;

interface IProps {
  onDrawerVisible: boolean;
  setOnDrawerVisible: any;
  selectTWorksheetInsuranceView: TWorksheetInsuranceView;
}

const FinanceInsuranceDrawer: React.FC<IProps> = (props) => {
  const { onDrawerVisible, setOnDrawerVisible, selectTWorksheetInsuranceView } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();

  const [tFinanceChequeDetails, setTFinanceChequeDetails] = useState<TFinanceChequedetail[]>([]);
  const [tFinancePayinDetails, setTFinancePayinDetails] = useState<TFinancePayindetail[]>([]);
  const [tFinanceOtherWorkDetails, setTFinanceOtherWorkDetails] = useState<TFinanceOtherworkdetail[]>([]);

  const [addTChequeHeadModalVisible, setAddTChequeHeadModalVisible] = useState<boolean>(false);
  const [searchTChequeHeadModalVisible, setSearchTChequeHeadModalVisible] = useState<boolean>(false);
  const [addTPayinHeadModalVisible, setAddTPayinHeadModalVisible] = useState<boolean>(false);
  const [searchTPayinHeadModalVisible, setSearchTPayinHeadModalVisible] = useState<boolean>(false);
  const [searchOtherWorkModalVisible, setSearchOtherWorkModalVisible] = useState<boolean>(false);

  const newTChequeHead = { financetype: selectTWorksheetInsuranceView.tworksheetHeadView.itypeworksheetCode!, customercode: selectTWorksheetInsuranceView.tworksheetHeadView.icustCode! } as TChequeHead;
  const newTPayinHead = { financetype: selectTWorksheetInsuranceView.tworksheetHeadView.itypeworksheetCode!, customercode: selectTWorksheetInsuranceView.tworksheetHeadView.icustCode! } as TPayinHead;
  const tFinanceOtherworkdetail: TFinanceOtherworkdetail = { customercode: selectTWorksheetInsuranceView.tworksheetHeadView.icustCode! } as TFinanceOtherworkdetail

  useEffect(() => {
    if (trigger) {
      if (isValid()) {
        setTrigger(false);
        return;
      }

      form.validateFields()
        .then(() => {
          confirm({
            title: "ยืนยัน!",
            icon: <ExclamationCircleOutlined />,
            content: "คุณต้องการที่จะบันทึกข้อมูลหรือไม่?",
            cancelText: "ยกเลิก",
            okText: "ยืนยัน",
            onOk() {
              form.submit();
            },
            onCancel() {
              setTrigger(false);
            }
          });
        }).catch(() => {
          setTrigger(false);
        });
    }
  }, [trigger]);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 20,
      align: "center" as "center",
      render: (value: number, item: TWorksheetInsuranceView, index: number) => (<>{index + 1}</>)
    },
    {
      title: "เลขที่ใบสั่งงาน",
      dataIndex: "iworksheetCode",
      key: "iworksheetCode",
      width: 120,
      align: "center" as "center",
      render: (value: any, item: TWorksheetInsuranceView) => {
        return <span>{item.tworksheetHeadView.iworksheetCode}</span>;
      },
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "icustName",
      key: "icustName",
      width: 200,
      render: (value: any, item: TWorksheetInsuranceView) => {
        return <span>{item.tworksheetHeadView.icustName}</span>;
      },
    },
    {
      title: "ที่อยู่",
      dataIndex: "icustAddress",
      key: "icustAddress",
      render: (value: any, item: TWorksheetInsuranceView) => {
        return <span>{item.tworksheetHeadView.icustAddress}</span>;
      },
    },
    {
      title: "รายการบริการ",
      dataIndex: "serviceName",
      key: "serviceName",
    },
    {
      title: "ค่าบริการทั้งหมด",
      dataIndex: "itotal2Amt",
      key: "itotal2Amt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetInsuranceView) => {
        return <span>{formatNumber(item.tworksheetHeadView.itotal2Amt)}</span>;
      },
    },
    {
      title: "หมายเหตุ",
      dataIndex: "iremark",
      key: "iremark",
      render: (value: any, item: TWorksheetInsuranceView) => {
        return <span>{item.tworksheetHeadView.iremark ? item.tworksheetHeadView.iremark: ""}</span>;
      },
    },
  ];

  const initial = async () => {
    const tTypeInsurancePaymentRefRes = await tTypeInsurancePaymentRefService.findById(selectTWorksheetInsuranceView.itypePaymentInsurance);
    const tTypeInsurancePaymentRef = tTypeInsurancePaymentRefRes.data as TTypeInsurancePaymentRef;

    let accountPayIn = '-';
    let insuranceNumber = '-';
    let insuranceExpire = '-';
    let bank = '-';
    let bankBranch = '-';
    if ("03" === tTypeInsurancePaymentRef.iid) {
      if (selectTWorksheetInsuranceView.iinsuranceNumber) {
        const ryAccountsRes = await ryAccountService.getByAccountNumber(selectTWorksheetInsuranceView.iinsuranceNumber);
        accountPayIn = ryAccountsRes.data.accountname;
      }
      insuranceNumber = selectTWorksheetInsuranceView.ipayinAccountnumber
    } else if ("01" !== tTypeInsurancePaymentRef.iid && "99" !== tTypeInsurancePaymentRef.iid) {
      if (selectTWorksheetInsuranceView.ibankCodeCheque) {
        const tBankHeadRes = await tBankHeadService.getBankHeadByIBankCode(selectTWorksheetInsuranceView.ibankCodeCheque);
        const tBankHead = tBankHeadRes.data as TBankHead;
        bank = tBankHead.ibankName;
        if (selectTWorksheetInsuranceView.ibankBranchCheque) {
          const tBankBranchHeadRes = await tBankBranchHeadService.getByBankCodeAndBankBranchCode(tBankHead.ibankCode, selectTWorksheetInsuranceView.ibankBranchCheque)
          const tBankBranchHead = tBankBranchHeadRes.data as TBankBranchHead;
          bankBranch = tBankBranchHead.ibankBranchName;
        }
      }
      insuranceNumber = selectTWorksheetInsuranceView.iinsuranceNumber;
      insuranceExpire = selectTWorksheetInsuranceView.iinsuranceExpire.toString();
    }

    form.setFieldsValue({
      typePaymentInsuranceName: tTypeInsurancePaymentRef.iname,
      insuranceAmt: formatNumber(selectTWorksheetInsuranceView.iinsuranceAmt),
      cashAmount: 0,
      accountPayIn: accountPayIn,
      paymentDate: selectTWorksheetInsuranceView.ipayDate ? toThDate(selectTWorksheetInsuranceView.ipayDate) : "",
      insuranceNumber: insuranceNumber,
      bank: bank,
      bankBranch: bankBranch,
      insuranceExpire: insuranceExpire
    });
  }

  useEffect(() => {
    if (onDrawerVisible) {
      initial();
    }
  }, [onDrawerVisible]);

  useEffect(() => {
    if (!searchTChequeHeadModalVisible) {

      let chequeAmount = 0;
      if (tFinanceChequeDetails.length > 0) {
        tFinanceChequeDetails.forEach(obj => {
          chequeAmount += (obj.amount) ? obj.amount : 0;
        });
      }
      form.setFieldsValue({
        chequeAmount: formatNumber(chequeAmount),
      });
    }
  }, [searchTChequeHeadModalVisible]);

  useEffect(() => {
    if (!searchTPayinHeadModalVisible) {

      let payinAmount = 0;
      if (tFinancePayinDetails.length > 0) {
        tFinancePayinDetails.forEach(obj => {
          payinAmount += (obj.amount) ? obj.amount : 0;
        });
      }
      form.setFieldsValue({
        payinAmount: formatNumber(payinAmount),
      });
    }
  }, [searchTPayinHeadModalVisible]);

  useEffect(() => {
    if (!searchOtherWorkModalVisible) {
      let otherReceiveAmount = 0;
      if (tFinanceOtherWorkDetails.length > 0) {
        tFinanceOtherWorkDetails.forEach(obj => {
          otherReceiveAmount += (obj.amount) ? obj.amount : 0;
        });
      }
      form.setFieldsValue({
        otherReceiveAmount: formatNumber(otherReceiveAmount),
      });
    }
  }, [searchOtherWorkModalVisible]);

  const onFinish = async (values: any) => {
    setLoading(true);
    let otherReceiveAmount = 0;
    if (selectTWorksheetInsuranceView.itypePaymentInsurance === '04' || selectTWorksheetInsuranceView.itypePaymentInsurance === '05' || selectTWorksheetInsuranceView.itypePaymentInsurance === '06') {
      otherReceiveAmount = parserNumber(selectTWorksheetInsuranceView.iinsuranceAmt);
    }
    if (values.otherReceiveAmount && values.otherReceiveAmoun > 0) {
      otherReceiveAmount = parserNumber(values.otherReceiveAmount);
    }

    const _tworksheetInsuranceData: TWorksheetInsuranceData = {
      tworksheetInsurance: selectTWorksheetInsuranceView,
      tfinanceHead2: {
        ...({} as TFinanceHead2),
        cashamount: parserNumber(values.cashAmount),//จำนวนเงินสด
        financetype: '04',
        taxamount: 0,//ภาษี
        customercode: selectTWorksheetInsuranceView.tworksheetHeadView.icustCode ? selectTWorksheetInsuranceView.tworksheetHeadView.icustCode : '',//รหัสลูกค้า
        customername: selectTWorksheetInsuranceView.tworksheetHeadView.icustName ? selectTWorksheetInsuranceView.tworksheetHeadView.icustName : '',//ชื่อลูกค้า
        chequeamount: parserNumber(values.chequeAmount),//จำนวนเงินจากเช็ค
        remarks: selectTWorksheetInsuranceView.iremark,
        payinamount: parserNumber(values.payinAmount),//จำนวนเงินจากเงินโอน
        paymenttype: "04", //04:เงินประกัน
        worksheetReturnStatus: 1, //การรับเอกสาร '1' ได้รับเอกสาร, '0' ไม่ได้รับเอกสาร
        otheramount: otherReceiveAmount,
        creditcardamount: 0
      },
      tworksheetHeadView: selectTWorksheetInsuranceView.tworksheetHeadView,
      tfinanceChequeDetailList: tFinanceChequeDetails,
      tfinancePayinDetailList: tFinancePayinDetails,
      tfinanceOtherWorkDetailList: tFinanceOtherWorkDetails,
    }
    try {
      await tWorksheetInsuranceService.saveTWorksheetInsuranceData(_tworksheetInsuranceData);
      showNotification(NotificationType.SUCCESS, "บันทึกรับชำระเงินประกันเรียบร้อยแล้ว!");
      form.resetFields();
      setTrigger(false);
      setLoading(false);
      setOnDrawerVisible(false);
    } catch (err) {
      setLoading(false);
      setTrigger(false);
      showNotification(NotificationType.ERROR, "เกิดข้อผิดพลาด กรุณาติดต่อผู้แลระบบ !");
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    setTrigger(false);
    setLoading(false);
    showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน");
  };

  const isValid = () => {
    const cashAmount = form.getFieldValue('cashAmount') ? parserNumber(form.getFieldValue('cashAmount')) : 0;
    const chequeAmount = form.getFieldValue('chequeAmount') ? parserNumber(form.getFieldValue('chequeAmount')) : 0;
    const payinAmount = form.getFieldValue('payinAmount') ? parserNumber(form.getFieldValue('payinAmount')) : 0;
    const otherReceiveAmount = form.getFieldValue('otherReceiveAmount') ? parserNumber(form.getFieldValue('otherReceiveAmount')) : 0;

    const sumPay = cashAmount + chequeAmount + payinAmount + otherReceiveAmount;
    const insuranceAmt = form.getFieldValue('insuranceAmt') ? parserNumber(form.getFieldValue('insuranceAmt')) : 0;

    if (sumPay !== insuranceAmt) {
      showNotification(NotificationType.ERROR, "จำนวนเงินรับชำระไม่ถูกต้อง");
      return true;
    }
    return false;
  }

  return (
    <>
      <Drawer
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <Typography.Title level={4}>โปรแกรมการเงินรับชำระเงินประกัน</Typography.Title>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "",
              }}
            >
              <Button
                type="primary"
                danger
                icon={<CloseCircleOutlined />}
                onClick={() => setOnDrawerVisible(false)}>
                ยกเลิก
              </Button>
              <Button
                onClick={() => setTrigger(true)}
                type="primary"
                icon={<SaveOutlined />}
                style={{ marginLeft: "5px" }}
              >
                บันทึก
              </Button>
            </div>
          </div>
        }
        width="90%"
        visible={onDrawerVisible}
        forceRender={true}
        placement="right"
        bodyStyle={{ paddingBottom: 80 }}
        closable={false}
        destroyOnClose={true}
      >
        <Content className="app-page-content">
          <Spin spinning={loading}>
            <Form
              form={form}
              name="receiveCashWorksheet"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              labelCol={{ span: 10 }}
            >
              <Space direction="vertical" size="middle">
                <Descriptions
                  column={2}
                  size="small"
                  bordered
                  style={{ paddingLeft: '10em' }}
                  labelStyle={{ textAlign: "right", fontWeight: "bold" }}
                >
                  <Descriptions.Item label="ชื่อลูกค้า" span={2}>{selectTWorksheetInsuranceView.tworksheetHeadView.custFullName}</Descriptions.Item>
                  <Descriptions.Item label="หมายเหตุ" span={2}>{selectTWorksheetInsuranceView.iremark}</Descriptions.Item>
                </Descriptions>
                <Table
                  columns={columns}
                  dataSource={[selectTWorksheetInsuranceView]}
                  size="small"
                  rowKey={(item: TWorksheetInsuranceView) => item.id!}
                  pagination={false}
                />
              </Space>
              <Row style={{ paddingTop: "1em" }}>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ประเภทเงินค้ำประกัน: </Typography.Text>
                </Col>
                <Col span={5}>
                  {<Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="typePaymentInsuranceName"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ border: 0, backgroundColor: "#fff" }} readOnly></Input>
                    </Form.Item>
                  </Space>}
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เงินสด: </Typography.Text>
                </Col>
                <Col span={9}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="cashAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input
                        type="text"
                        style={{ textAlign: "end", color: "#FD1212", width: "100%" }}
                        onKeyDown={(event: any) => textNumber(event)}
                        onFocus={(e: any) => { e.target.select() }}
                        onBlur={(e: any) => { form.setFieldsValue({ cashAmount: formatNumber(parserNumber(e.target.value)) }) }}
                        onPressEnter={(e: any) => { form.setFieldsValue({ cashAmount: formatNumber(parserNumber(e.target.value)) }) }}
                      ></Input>
                    </Form.Item>
                    <Typography.Text> บาท</Typography.Text>
                  </Space>
                </Col>
              </Row>

              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ธนาคาร: </Typography.Text>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="bank"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, backgroundColor: "#fff" }} readOnly></Input>
                  </Form.Item>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เช็ค: </Typography.Text>
                </Col>
                <Col span={9}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="chequeAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        style={{ fontWeight: "bold", textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }} readOnly
                      >
                      </InputNumber>
                    </Form.Item>
                    <Button
                      id='btnSelectCheque'
                      onClick={() => setSearchTChequeHeadModalVisible(true)}
                      type="primary"
                      icon={<SearchOutlined />}
                    >
                    </Button>
                    <Button
                      id='btnAddTChequeHead'
                      onClick={() => setAddTChequeHeadModalVisible(true)}
                      type="primary"
                      icon={<PlusSquareOutlined />}
                    >
                    </Button>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>สาขา: </Typography.Text>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="bankBank"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, backgroundColor: "#fff" }} readOnly></Input>
                  </Form.Item>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เงินโอน: </Typography.Text>
                </Col>
                <Col span={9}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="payinAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        style={{ textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }} readOnly
                      >
                      </InputNumber>
                    </Form.Item>
                    <Button
                      id='btnSelectPayin'
                      onClick={() => setSearchTPayinHeadModalVisible(true)}
                      type="primary"
                      icon={<SearchOutlined />}
                    >
                    </Button>
                    <Button
                      id='btnAddTPayinHead'
                      onClick={() => setAddTPayinHeadModalVisible(true)}
                      type="primary"
                      icon={<PlusSquareOutlined />}
                    >
                    </Button>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>จำนวนเงินประกัน: </Typography.Text>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="insuranceAmt"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, backgroundColor: "#fff", color: "#FD1212", fontWeight:"bold" }} readOnly></Input>
                  </Form.Item>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>รายรับอื่นๆ: </Typography.Text>
                </Col>
                <Col span={9}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="otherReceiveAmount"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <InputNumber
                        style={{ textAlign: "right", color: "#FD1212", width: 200, backgroundColor: "#F7F7F8" }} readOnly
                        controls={false}
                      >
                      </InputNumber>
                    </Form.Item>
                    <Button
                      id='btnOtherWork'
                      onClick={() => setSearchOtherWorkModalVisible(true)}
                      type="primary"
                      icon={<SearchOutlined />}
                    >
                    </Button>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เลขที่เช็ค/สัญญา/บัญชี: </Typography.Text>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="insuranceNumber"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, backgroundColor: "#fff" }} readOnly></Input>
                  </Form.Item>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>อายุสัญญา: </Typography.Text>
                </Col>
                <Col span={9}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="insuranceExpire"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ border: 0, backgroundColor: "#fff" }} readOnly></Input>
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>ลงวันที่: </Typography.Text>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="paymentDate"
                    style={{ margin: "0 0 3px 0px" }}
                  >
                    <Input style={{ border: 0, backgroundColor: "#fff" }} readOnly></Input>
                  </Form.Item>
                </Col>
                <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                  <Typography.Text>เงินโอนเข้าบัญชี: </Typography.Text>
                </Col>
                <Col span={9}>
                  <Space align='baseline' style={{ width: "80%" }}>
                    <Form.Item
                      name="accountPayIn"
                      style={{ margin: "0 0 3px 0px" }}
                    >
                      <Input style={{ border: 0, backgroundColor: "#fff" }} readOnly></Input>
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Content>
      </Drawer>

      <div>
        {addTChequeHeadModalVisible && (
          <AddTChequeHeadModal
            addTChequeHeadModalVisible={addTChequeHeadModalVisible}
            setAddTChequeHeadModalVisible={setAddTChequeHeadModalVisible}
            tChequeHead={newTChequeHead}
          ></AddTChequeHeadModal>
        )}
      </div>
      <div>
        {searchTChequeHeadModalVisible && (
          <SearchTChequeHeadModal
            searchTChequeHeadModalVisible={searchTChequeHeadModalVisible}
            setSearchTChequeHeadModalVisible={setSearchTChequeHeadModalVisible}
            tFinanceChequeDetails={tFinanceChequeDetails}
            setTFinanceChequeDetails={setTFinanceChequeDetails}
          ></SearchTChequeHeadModal>
        )}
      </div>
      <div>
        {addTPayinHeadModalVisible && (
          <AddTPayinHeadModal
            addTPayinHeadModalVisible={addTPayinHeadModalVisible}
            setAddTPayinHeadModalVisible={setAddTPayinHeadModalVisible}
            tPayinHead={newTPayinHead}
          ></AddTPayinHeadModal>
        )}
      </div>

      <div>
        {searchTPayinHeadModalVisible && (
          <SearchTPayinHeadModal
            searchTPayinHeadModalVisible={searchTPayinHeadModalVisible}
            setSearchTPayinHeadModalVisible={setSearchTPayinHeadModalVisible}
            tFinancePayinDetails={tFinancePayinDetails}
            setTFinancePayinDetails={setTFinancePayinDetails}
          ></SearchTPayinHeadModal>
        )}
      </div>
      <div>
        {searchOtherWorkModalVisible && (
          <SearchOtherWorkSearchModal
            searchOtherWorkModalVisible={searchOtherWorkModalVisible}
            setSearchOtherWorkModalVisible={setSearchOtherWorkModalVisible}
            tFinanceOtherworkdetail={tFinanceOtherworkdetail}
            tFinanceOtherWorkDetails={tFinanceOtherWorkDetails}
            setTFinanceOtherWorkDetails={setTFinanceOtherWorkDetails}
          ></SearchOtherWorkSearchModal>
        )}
      </div>
    </>
  )
}

export default FinanceInsuranceDrawer