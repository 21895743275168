import http from "../lib/http";
import Constants from "../utils/constants";

const getByReserveCode = (reserveCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetHead/getByReserveCode", {
    params: {
      reserveCode,
    },
  });
};

const getByWorksheetCode = (worksheetCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetHead/getByWorksheetCode", {
    params: {
      worksheetCode,
    },
  });
};

const getByWorksheetCodeView = (worksheetCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetHead/getByWorksheetCodeView", {
    params: {
      worksheetCode,
    },
  });
};

const getByWorksheetCodeViewForConclude = (worksheetCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetHead/getByWorksheetCodeViewForConclude", {
    params: {
      worksheetCode,
    },
  });
};

const getByWorksheetCodeViewForTaxInvoice = (worksheetCode: string, taxTypeCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetHead/getByWorksheetCodeViewForTaxInvoice", {
    params: {
      worksheetCode,
      taxTypeCode,
    },
  });
};

const getByWorksheetCodeTypeWorksheetAndBranchId = (worksheetCode: string, typeWorksheet: string, branchId: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetHead/getByWorksheetCodeTypeWorksheetAndBranchId", {
    params: {
      worksheetCode,
      typeWorksheet,
      branchId
    },
  });
};

const getWorksheetConfirmReserveByBranchId = (branchId: string, reserveCodeCustname: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetHead/getWorksheetConfirmReserveByBranchId", {
    params: {
      branchId,
      reserveCodeCustname
    },
  });
};

const getWorksheetTruckDischarges = (branchId: string, worksheetCode: string, fromDate: string, toDate: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetHead/getWorksheetTruckDischarges", {
    params: {
      branchId,
      worksheetCode,
      fromDate,
      toDate
    },
  });
};

const getWorksheetTruckUnDischarges = (branchId: string, worksheetCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetHead/getWorksheetTruckUnDischarges", {
    params: {
      branchId,
      worksheetCode
    },
  });
};

const isExistWorkSheetCode = (worksheetCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetHead/isExistWorkSheetCode", {
    params: {
      worksheetCode,
    },
  });
};

const isExistByWorkSheetCodeTypeWorksheetAndBranchId = (worksheetCode: string, typeWorksheet: string, branchId: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetHead/isExistByWorkSheetCodeTypeWorksheetAndBranchId", {
    params: {
      worksheetCode,
      typeWorksheet,
      branchId
    },
  });
};

const getWorksheetHeadForReturnWorksheet = (fromDate: string, toDate: string, branchId: string, worksheetCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetHead/getWorksheetHeadForReturnWorksheet", {
    params: {
      fromDate,
      toDate,
      branchId,
      worksheetCode
    },
  });
};

const getWorksheetByConstructionWorkReserveFlg = (worksheetCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetHead/getWorksheetByConstructionWorkReserveFlg/" + worksheetCode);
};

const getWorksheetForPrepareReturnAll = (date10dFrom: string, date10dTo: string,
  date30dFrom: string, date30dTo: string,
  date90dFrom: string, date90dTo: string,
  date1yFrom: string, date1yTo: string,
  branchId: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetHead/getWorksheetForPrepareReturnAll", {
    params: {
      date10dFrom,
      date10dTo,
      date30dFrom,
      date30dTo,
      date90dFrom,
      date90dTo,
      date1yFrom,
      date1yTo,
      branchId,
    },
  });
};

const getWorksheetByDebitor = (debitorCode: string, statusPayment: string, branch: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetHead/getWorksheetByDebitor", {
    params: {
      debitorCode,
      statusPayment,
      branch
    },
  });
};

const defaultApi = {
  getByReserveCode,
  getByWorksheetCode,
  getByWorksheetCodeView,
  getByWorksheetCodeViewForConclude,
  getByWorksheetCodeViewForTaxInvoice,
  getByWorksheetCodeTypeWorksheetAndBranchId,
  getWorksheetConfirmReserveByBranchId,
  getWorksheetTruckDischarges,
  getWorksheetTruckUnDischarges,
  isExistWorkSheetCode,
  isExistByWorkSheetCodeTypeWorksheetAndBranchId,
  getWorksheetHeadForReturnWorksheet,
  getWorksheetByConstructionWorkReserveFlg,
  getWorksheetForPrepareReturnAll,
  getWorksheetByDebitor,
};

export default defaultApi;