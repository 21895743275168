import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PageHeader from 'src/layouts/PageHeader';
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Table, Typography, Modal } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { ClearOutlined, PlusSquareOutlined, PrinterOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import Constants from 'src/utils/constants';
import { toThDate } from 'src/utils/dateTimeUtils';
import { formatNumber } from 'src/utils/numberUtils';
import { RyRefbranch } from 'src/domain/ryRefbranch';
import ryRefbranchService from 'src/services/reference/ryRefbranchService';
import { useAppSelector } from 'src/stores/store';
import tCustomerHeadService from "src/services/customer/tCustomerHeadService";
import { TCustomerHeadView } from 'src/domain/master/view/tCustomerHeadView';
import reportService from 'src/services/reportService';
import { CustomerServiceUseReportData } from 'src/domain/worksheet/customerServiceUseReportData';
import { CustomerServiceUseReportPreview } from 'src/domain/worksheet/customerServiceUseReportPreview';
import { NotificationType, showNotification } from 'src/utils/notificationUtils';
import PdfViewPopup from './PdfViewPopup';

const { Option } = Select;

const CustomerServiceUseReport = () => {
  const [page, setPage] = React.useState(1);
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const [ryRefBranchSelected, setRyRefBranchSelected] = useState<RyRefbranch>({} as RyRefbranch);
  const [ryRefBranchs, setRyRefBranchs] = useState<RyRefbranch[]>([]);
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [customers, setCustomers] = useState<TCustomerHeadView[]>([]);
  const [customerServiceUseList, setCustomerServiceUseList] = useState<CustomerServiceUseReportData[]>([]);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false);
  const [customerServiceUseReportPreviewData, setCustomerServiceUseReportPreviewData] = useState<any>();

  const [fromDateStr, setFromDateStr] = useState<any>();
  const [toDateStr, setToDateStr] = useState<any>();
  const [branchStr, setBranchStr] = useState<any>();
  const [customercodeStr, setCustomercodeStr] = useState<any>();

  const [visiblePDF, setVisiblePDF] = useState(false);

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 50,
      align: "center" as "center",
      render: (value: number, item: CustomerServiceUseReportData, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
    },
    {
      title: "รหัสลูกค้า",
      dataIndex: "customerCode",
      key: "customerCode",
      width: 120,
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "customerName",
      key: "customerName",
      width: 300,
    },
    {
      title: "โทรศัพท์",
      dataIndex: "custPhone",
      key: "custPhone",
      width: 130,
    },
    {
      title: "มือถือ",
      dataIndex: "custMobile",
      key: "custMobile",
      width: 130,
    },
    {
      title: "จำนวนครั้งที่ใช้บริการ",
      dataIndex: "cntService",
      key: "cntService",
      width: 130,
      align: "right" as "right",
    },
    {
      title: "ยอดค่าใช้บริการ",
      dataIndex: "sumTotalAmt",
      key: "sumTotalAmt",
      width: 140,
      align: "right" as "right",
      render: (value: any, item: CustomerServiceUseReportData) => {
        return <span>{formatNumber(item.sumTotalAmt)}</span>;
      },
    },
    {
      title: "ยอดชำระแล้ว",
      dataIndex: "sumPaidAmt",
      key: "sumPaidAmt",
      width: 140,
      align: "right" as "right",
      render: (value: any, item: CustomerServiceUseReportData) => {
        return <span>{formatNumber(item.sumPaidAmt)}</span>;
      },
    },
    {
      title: "ยอดค้างชำระ",
      dataIndex: "sumRemainAmt",
      key: "sumRemainAmt",
      width: 140,
      align: "right" as "right",
      render: (value: any, item: CustomerServiceUseReportData) => {
        return <span>{formatNumber(item.sumRemainAmt)}</span>;
      },
    },
  ];

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    initData();
    form.submit();
  }, []);

  const initData = async () => {
    const refbranchRes = await ryRefbranchService.getRyRefbranchList();
    const foundDefaultBanch = (refbranchRes.data as Array<RyRefbranch>).find((obj) => obj.id === userInfo.branchId);

    setRyRefBranchs(refbranchRes.data);
    setRyRefBranchSelected(foundDefaultBanch!);
    // setTDebitorHeadSelected({} as TDebitorHeadView);

    form.setFieldsValue({
      fromDate: moment(new Date(), Constants.DATE_FORMAT).startOf('month'),
      toDate: moment(new Date(), Constants.DATE_FORMAT).endOf('month'),
      branch: undefined,
      customercode: undefined
    });
  }

  const handleSearchCustomer = async (data: any) => {
    if (data.length < 3) return;
    const res = await tCustomerHeadService.getCustomerByParam(data);
    setCustomers(res.data);
  };

  const onPrint = async () => {
    setVisiblePDF(true);
  }

  const onFinish = async (values: any) => {
    setLoading(true);
    const fromDate = moment(values.fromDate).format("yyyyMMDD");
    const toDate = moment(values.toDate).format("yyyyMMDD");
    const branch = values.branch ? values.branch : "";
    const customercode = values.customercode ? values.customercode : "";

    const res = await reportService.getCustomerServiceUseReport(
      fromDate,
      toDate,
      branch,
      customercode);
    // console.log('--fromDate toDate----', fromDate, toDate);
    setPage(1);

    setCustomerServiceUseList(res.data);
    setFromDateStr(fromDate);
    setToDateStr(toDate);
    setBranchStr(branch);
    setCustomercodeStr(customercode);
    setLoading(false);    
  }

  const onFinishFailed = (errorInfo: any) => {
  };

   
  return (
    <>
      <PageHeader title="รายงานการใช้บริการลูกค้า"></PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="rengradWangbill"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
            }}
          >
           
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ตั้งแต่: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="fromDate"
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    // onChange={form.submit}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>สาขา: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="branch"
                  style={{ marginBottom: "3px" }}
                >
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกสาขา"
                    optionFilterProp="children"
                    filterOption={true}
                    onClear={() => setRyRefBranchSelected({} as RyRefbranch)}
                  >
                    {ryRefBranchs.map((obj) => {
                      return (
                        <Option key={obj.id} value={obj.id}>
                          {obj.id}: {obj.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ถึง: </Typography.Text>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="toDate"
                  rules={[{ required: false }]}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    // onChange={form.submit}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ชื่อลูกค้า: </Typography.Text>
              </Col>
              <Col span={6}>
                {/* <Space align='baseline' style={{ width: "100%" }}> */}
                  <Form.Item
                    name="customercode"
                  >
                    <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกลูกค้า"
                    optionFilterProp="children"
                    filterOption={false}
                    onSearch={handleSearchCustomer}
                  >
                    {customers.map((obj) => {
                      return (
                        <Option key={obj.icustCode} value={obj.icustCode}>
                          {obj.iprenameName}{obj.iname}  {obj.isurname ? obj.isurname : ""}
                        </Option>
                      );
                    })}
                  </Select>
                  </Form.Item>
                {/* </Space> */}
              </Col>
            </Row>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: 'center',
              paddingBottom: "1em",
              // borderBlockEnd: "2px solid #c9c9c9",
            }}
          >
            <Space>
              <Button
                size="small"
                type="primary"
                icon={<SearchOutlined />}
                onClick={form.submit}
              >
                ค้นหา
              </Button>
            </Space>
          </div>
        </Form>
           
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            // margin: '0 0 10px 0',
            padding: "0px 20px 10px 0px",
            borderBottom: '2px solid #c9c9c9'
          }}
        >
          <Button
          style={{
            marginLeft: "30px",}}
            size="small"
            type="primary"
            icon={<PrinterOutlined />}
            onClick={onPrint}
          >
            พิมพ์
          </Button>
          <Modal
            style={{ top: 20 }}
            width={"90vw"}
            title="RS001"
            bodyStyle={{ height: "85vh" }}
            centered={true}
            visible={isPreviewModalVisible}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={() => setIsPreviewModalVisible(false)}
          >
            <iframe title=" " src={customerServiceUseReportPreviewData} width={"100%"} height={"100%"}></iframe>
          </Modal>
        </div>

        <div>
          <Table
            columns={columns}
            dataSource={customerServiceUseList}
            size="small"
            loading={loading}
            rowKey={(item: CustomerServiceUseReportData) => item.customerCode!}
            scroll={{ x: 1500 }}
            pagination={{
              pageSize: 15,
              current: page,
              onChange(current) {
                setPage(current);
              }
            }}
          />
        </div>
      </Content>
      <Modal
        title= 'print'
        centered
        closable={true}
        visible={visiblePDF}
        onCancel={() => setVisiblePDF(false)}
        width="400"
      >
        <PdfViewPopup
          // urlPath='http://localhost:8080/printReport/printCustomerServiceUseReportPreview?fromDate=20230901&toDate=20230930&branch=501&customercode=2019031118551'
          urlPath={reportService.getCustomerServiceUseReportUrl(fromDateStr, toDateStr, branchStr, customercodeStr)}
          onClose={() => setVisiblePDF(false)}
        />
      </Modal>
    </>
  )
}

export default CustomerServiceUseReport