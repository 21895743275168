import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Col, DatePicker, Form, Input, Modal, Row, Spin, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { TWorksheetHeadView } from "src/domain/worksheet/view/tWorksheetHeadView";
import worksheetAdvanceDateService from "src/services/worksheetAdvanceDateService";
import worksheetService from "src/services/worksheetService";
import { useAppSelector } from "src/stores/store";
import Constants from "src/utils/constants";
import { toMomentDateTime } from "src/utils/dateTimeUtils";
import { NotificationType, showNotification } from "src/utils/notificationUtils";

const { confirm } = Modal;

interface IProp {
  onModalVisible: boolean;
  setOnModalVisible: any;
  selectedWorksheet: TWorksheetHeadView;
  worksheetHeadForAdvanceDates: TWorksheetHeadView[];
  setWorksheetHeadForAdvanceDates: any;
}

const WorksheetAdvanceDateModal: React.FC<IProp> = (props) => {
  const { selectedWorksheet, onModalVisible, setOnModalVisible, worksheetHeadForAdvanceDates, setWorksheetHeadForAdvanceDates } = props;
  const [form] = Form.useForm();
  const [showSpin, setShowSpin] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<boolean>(false);
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  useEffect(() => {
    if (trigger) {
      form.validateFields()
        .then(() => {
          confirm({
            title: "ยืนยัน!",
            icon: <ExclamationCircleOutlined />,
            content: "ต้องการยืนยันเลื่อนเวลาการทำงานหรือไม่",
            cancelText: "ยกเลิก",
            okText: "ยืนยัน",
            onOk() {
              form.submit();
            },
            onCancel() {
              setTrigger(false);
            }
          });
        }).catch(() => {
          setTrigger(false);
        });
    }
  }, [trigger]);

  useEffect(() => {
    const start = toMomentDateTime(selectedWorksheet.istartDate ? selectedWorksheet.istartDate : "",
      selectedWorksheet.istartTime ? selectedWorksheet.istartTime : "");
    const end = toMomentDateTime(selectedWorksheet.iendDate ? selectedWorksheet.iendDate : "",
      selectedWorksheet.iendTime ? selectedWorksheet.iendTime : "");

    form.setFieldsValue({
      iworksheetCode: selectedWorksheet?.iworksheetCode,
      icustName: selectedWorksheet?.icustName,
      truckNumber: selectedWorksheet.truckNumber,
      serviceDetailName: selectedWorksheet.serviceDetailName,
      startDate: start,
      endDate: end,
    });
    setShowSpin(false);
  }, []);

  const onFinish = async (values: any) => {
    let std = values.startDate.format("yyyyMMDD");
    let stt = values.startDate.format("HHmm00");
    let ed = values.endDate.format("yyyyMMDD");
    let et = values.endDate.format("HHmm00");
    const checkAddDataRes = await worksheetService.isExistCreateWorksheet(std, stt, ed, et, selectedWorksheet.itruckCode, "");
    if (checkAddDataRes.data && checkAddDataRes.data.ireserveCode !== selectedWorksheet.ireserveCode) {
      showNotification(NotificationType.ERROR, "มีการสร้างรายการจองไว้แล้ว\n กรุณาดูที่โปรแกรมยืนยันใบจองหรือโปรแกรมพิมพ์ซ้ำก่อนการบันทึกครั้งต่อไป");
      setTrigger(false);
    } else {
      const temp = {
        ...(selectedWorksheet as TWorksheetHeadView),
        istartDate: std,
        istartTime: stt,
        iendDate: ed,
        iendTime: et,
        iremark: selectedWorksheet.iremark ? selectedWorksheet.iremark : "" + " : " + values.iremark ? values.iremark : "",
        isavStation: userInfo.saveStation,
        ilastUser: userInfo.userId,
        itermNo: userInfo.termNo,
      }

      try {
        await worksheetAdvanceDateService.updateWorksheetHead(temp);

        //remove list
        const index = (worksheetHeadForAdvanceDates as Array<TWorksheetHeadView>).findIndex(object => object.ireserveCode === selectedWorksheet.ireserveCode);
        if (index !== -1) {
          const array = worksheetHeadForAdvanceDates ? [...worksheetHeadForAdvanceDates] : [];
          array?.splice(index, 1);
          setWorksheetHeadForAdvanceDates(array);
        }

        showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเรียบร้อย");
        form.resetFields();
        setTrigger(false);
        setOnModalVisible(false);
      } catch (err) {
        showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        setTrigger(false);
        setOnModalVisible(false);
      }
    };
  };

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาตรวจสอบข้อมูลให้ครบถ้วน!");
    setTrigger(false);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>บันทึกเลื่อนการทำงาน</Typography.Title>
          </div>
        }
        visible={onModalVisible}
        width={"50%"}
        style={{ minHeight: "70%" }}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setOnModalVisible(false)}
      >
        <Spin spinning={showSpin}>
          <Form
            form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          >
            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>เลขที่ใบงาน: </Typography.Text>
              </Col>
              <Col span={19}>
                <Form.Item
                  name="iworksheetCode"
                  style={{ marginBottom: 3 }}>
                  <Input
                    readOnly={true}
                    style={{ backgroundColor: "#F7F7F8" }}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ลูกค้า: </Typography.Text>
              </Col>
              <Col span={19}>
                <Form.Item
                  name="icustName"
                  style={{ marginBottom: 3 }}>
                  <Input
                    readOnly={true}
                    style={{ backgroundColor: "#F7F7F8" }}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>รถ/รายการ: </Typography.Text>
              </Col>
              <Col span={19}>
                <Form.Item
                  name="truckNumber"
                  style={{ marginBottom: 3 }}>
                  <Input
                    readOnly={true}
                    style={{ backgroundColor: "#F7F7F8" }}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>บริการ: </Typography.Text>
              </Col>
              <Col span={19}>
                <Form.Item
                  name="serviceDetailName"
                  style={{ marginBottom: "3px" }}>
                  <TextArea
                    style={{ backgroundColor: "#F7F7F8" }}
                    readOnly
                    rows={3}
                  ></TextArea>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>วัน-เวลาทำงานตั้งแต่: </Typography.Text>
              </Col>
              <Col span={19}>
                <Form.Item
                  name="startDate"
                  style={{ marginBottom: 3 }}
                >
                  <DatePicker
                    showTime={{ format: "HH:mm:00" }}
                    format={Constants.DATE_FORMAT_SKIP_SEC}
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ถึงวันที่: </Typography.Text>
              </Col>
              <Col span={19}>
                <Form.Item
                  name="endDate"
                  style={{ marginBottom: 3 }}
                >
                  <DatePicker
                    showTime={{ format: "HH:mm:00" }}
                    format={Constants.DATE_FORMAT_SKIP_SEC}
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={5} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>หมายเหตุ: </Typography.Text>
              </Col>
              <Col span={19}>
                <Form.Item
                  name="iremark"
                  style={{ marginBottom: 3 }}
                >
                  <TextArea
                    rows={3}
                  ></TextArea>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  )
}

export default WorksheetAdvanceDateModal