import { TWorksheetSteelHeadData } from "src/domain/worksheet/view/tWorksheetSteelHeadData";
import http from "../lib/http";
import Constants from "../utils/constants";
import { TAssetHead } from './../domain/master/tAssetHead';

const getWorksheetSteel = (branchId: string, reserveCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetSteelHead/getWorksheetSteel", {
    params: {
      branchId,
      reserveCode
    },
  });
}

const getWorksheetSteels = (branchId: string, reserveCode: string, fromDate: string, toDate: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetSteelHead/getWorksheetSteels", {
    params: {
      branchId,
      reserveCode,
      fromDate,
      toDate
    },
  });
};

const getTWorksheetSteelHeadData = (reserveCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetSteelHead/getTWorksheetSteelHeadData", {
    params: {
      reserveCode,
    },
  });
};


const getWorksheetSteelDischarges = (branchId: string, worksheetCode: string, fromDate: string, toDate: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetSteelHead/getWorksheetSteelDischarges", {
    params: {
      branchId,
      worksheetCode,
      fromDate,
      toDate
    },
  });
};

const getWorksheetSteelUnDischarges = (branchId: string, worksheetCode: string) => {
  return http.get(Constants.DEFAULT_URL + "/tWorksheetSteelHead/getWorksheetSteelUnDischarges", {
    params: {
      branchId,
      worksheetCode
    },
  });
};

const postWorkSheetSteelHeadData = (tWorkSheetSteelHeadData: TWorksheetSteelHeadData) => {
  return http.post(Constants.DEFAULT_URL + "/tWorksheetSteelHead/postWorkSheetSteelHeadData", tWorkSheetSteelHeadData);
};

const steelDischargeWorksheets = (reserveCode: string, tAssetHeads: TAssetHead[]) => {
  return http.post(Constants.DEFAULT_URL + "/tWorksheetSteelHead/steelDischargeWorksheets/" + reserveCode, tAssetHeads);
};

const defaultApi = {
  getWorksheetSteel,
  getWorksheetSteels,
  getTWorksheetSteelHeadData,
  getWorksheetSteelDischarges,
  getWorksheetSteelUnDischarges,
  postWorkSheetSteelHeadData,
  steelDischargeWorksheets,
};

export default defaultApi;