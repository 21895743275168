import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Form, Input, Modal } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "src/stores/store";
import { TReference } from "../../domain/master/tReference";
import tReferenceService from "../../services/reference/tReferenceService";
import { NotificationType, showNotification } from "../../utils/notificationUtils";

const { confirm } = Modal;

interface IParams {
    id: string;
    visibleReferenceModal: boolean;
    setVisibleReferenceModal: any;
}

const AddEditReferenceModal: React.FC<IParams> = (props) => {
    const { id, visibleReferenceModal, setVisibleReferenceModal } = props;
    const [trigger, setTrigger] = useState<boolean>(false);
    const [reference, setReference] = useState<TReference>({} as TReference);
    const [form] = Form.useForm();
    const history = useHistory();
    const userInfo = useAppSelector((state) => state.authen.authenUser.user);

    let title: string = "เพิ่มข้อมูลอ้างอิง";
    if (id) {
        title = "แก้ไขข้อมูลอ้างอิง";
    }

    useEffect(() => {
        if (trigger) {
            form.validateFields()
                .then(() => {
                    confirm({
                        title: "ยืนยัน!",
                        icon: <ExclamationCircleOutlined />,
                        content: "คุณต้องการที่จะบันทึกข้อมูลหรือไม่?",
                        cancelText: "ยกเลิก",
                        okText: "ยืนยัน",
                        onOk() {
                            form.submit();
                        },
                        onCancel() {
                            setTrigger(false);
                        }
                    });
                }).catch(() => {
                    setTrigger(false);
                });
        }
    }, [trigger]);

    useEffect(() => {
        loadReference();
        return () => {
        };
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            code: reference.code,
            key: reference.id,
            name: reference.name,
        });
        return () => {
        };
    }, [reference]);

    const loadReference = async () => {
        if (id) {
            const res = await tReferenceService.getTReferenceById(Number(id));
            setReference(res.data);
        } else {
            const nextCodeRes = await tReferenceService.getNextTReferenceCode();
            setReference({ ...reference, code: nextCodeRes.data });
        }
    };

    const onFinish = async (values: any) => {
        const _reference: TReference = {
            id: Number(id),
            code: values.code,
            name: values.name,
            savStation: userInfo.saveStation,
            createUser: userInfo.userId,
            lastUser: userInfo.userId,
            termNo: userInfo.termNo
        };

        if (!id) {
            try {
                const res = await tReferenceService.postTReference(_reference);
                showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลอ้างอิงเรียบร้อยแล้ว!");
                form.resetFields();
                setTrigger(false);
                setVisibleReferenceModal(false);
                history.replace("/app/reference/view/" + res.data.id);
            } catch (err) {
                showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
                setTrigger(false);
            }
        } else {
            try {
                await tReferenceService.updateTReference(_reference);
                showNotification(NotificationType.SUCCESS, "แก้ไขข้อมูลอ้างอิงเรียบร้อยแล้ว!");
                form.resetFields();
                setTrigger(false);
                setVisibleReferenceModal(false);
                history.replace("/app/reference/view/" + id);
            } catch (err) {
                showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
                setTrigger(false);
            }
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        setTrigger(false);
    };

    return (
        <>
            <Modal
                title={title}
                cancelText={"ยกเลิก"}
                okText={"บันทึก"}
                visible={visibleReferenceModal}
                width={700}
                closable={false}
                destroyOnClose
                maskClosable={false}
                onOk={() => setTrigger(true)}
                onCancel={() => setVisibleReferenceModal(false)}
            >
                <Content className="app-page-content">
                    <Form
                        form={form}
                        name="reference"
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                        // initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            label="รหัส"
                            name="code"
                            rules={[
                                { required: true }
                            ]}
                        >
                            <Input disabled={true} style={{ width: 100 }} />
                        </Form.Item>
                        <Form.Item
                            label="ชื่อ"
                            name="name"
                            rules={[
                                {
                                    required: true, message: "กรุณาใส่ชื่อ!",
                                },
                                {
                                    validator: (_, value) => {
                                        if (value && (value === " " || value === "-")) {
                                            return Promise.reject(new Error('กรุณาป้อนข้อมูลชื่อให้ถูกต้อง!'))
                                        } else {
                                            return Promise.resolve()
                                        }
                                    },
                                },
                            ]}
                        >
                            <Input placeholder={"ป้อนชื่อ"} maxLength={100} />
                        </Form.Item>
                    </Form>
                </Content>
            </Modal>
        </>
    );
};

export default AddEditReferenceModal;
