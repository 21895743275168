import { ClearOutlined, PlusSquareOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, Space, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TWorksheetSteelHeadView } from "src/domain/worksheet/view/tWorksheetSteelHeadView";
import PageHeader from "src/layouts/PageHeader";
import { useAppSelector } from "src/stores/store";
import Constants from "src/utils/constants";
import { NotificationType, showNotification } from "src/utils/notificationUtils";
import { formatNumber } from "src/utils/numberUtils";
import tWorksheetSteelHeadService from "../../services/tWorksheetSteelHeadService";
import { toThDate } from "../../utils/dateTimeUtils";
import SteelUnDischargeModal from "./SteelUnDischargeModal";

const SteelDischargeList: React.FC<TWorksheetSteelHeadView> = () => {
    const [steelDischarges, setSteelDischarges] = useState<TWorksheetSteelHeadView[]>([]);
    const [page, setPage] = React.useState(1);
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [selectedSteelDischarge, setSelectedSteelDischarge] = useState<TWorksheetSteelHeadView>({} as TWorksheetSteelHeadView);
    const [visibleSteelUnDischargeModal, setVisibleSteelUnDischargeModal] = useState<boolean>(false);

    const userInfo = useAppSelector((state) => state.authen.authenUser.user);
    const [form] = Form.useForm();

    const columns = [
        {
            title: "ลำดับ",
            key: "index",
            width: 50,
            render: (value: number, item: TWorksheetSteelHeadView, index: number) => (<>{(page - 1) * 15 + index + 1}</>)
        },
        {
            title: "เลขที่ใบงาน",
            dataIndex: "iworksheetCode",
            key: "iworksheetCode", render: (value: string, item: TWorksheetSteelHeadView) => (
                <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.iworksheetCode}</Button>
            )
        },
        {
            title: "ชื่อลูกค้า",
            dataIndex: "icustName",
            key: "icustName",
        },
        {
            title: "รายการบริการ",
            dataIndex: "steelServiceName",
            key: "steelServiceName",
        },
        {
            title: "วันที่ทำงาน",
            dataIndex: "steelStartDate",
            key: "steelStartDate",
            width: 185,
            render: (value: any, item: TWorksheetSteelHeadView) => (<>{toThDate(item.steelStartDate)} - {toThDate(item.steelEndDate)}</>)
        },
        {
            title: "จำนวนแผ่นเหล็ก",
            dataIndex: "steelNumSteel",
            key: "steelNumSteel",
            align: "right" as "right",
        },
        {
            title: "จำนวนวัน",
            dataIndex: "steelNumDate",
            key: "steelNumDate",
            align: "right" as "right",
        },
        {
            title: "ค่าบริการ",
            dataIndex: "iserviceAmt",
            key: "iserviceAmt",
            align: "right" as "right",
            render: (value: any, item: TWorksheetSteelHeadView) => {
                return <span>{formatNumber(item.iserviceAmt)}</span>;
            },
        },
        {
            title: "ค่าบริการพิเศษ",
            dataIndex: "iextraAmt",
            key: "iextraAmt",
            align: "right" as "right",
            render: (value: any, item: TWorksheetSteelHeadView) => {
                return <span>{formatNumber(item.iextraAmt)}</span>;
            },
        },
        {
            title: "ส่วนลด",
            dataIndex: "idiscount",
            key: "idiscount",
            align: "right" as "right",
            render: (value: any, item: TWorksheetSteelHeadView) => {
                return <span>{formatNumber(item.idiscount)}</span>;
            },
        },
        {
            title: "ภาษีมูลค่าเพิ่ม",
            dataIndex: "itaxAmt",
            key: "itaxAmt",
            align: "right" as "right",
            render: (value: any, item: TWorksheetSteelHeadView) => {
                return <span>{formatNumber(item.itaxAmt)}</span>;
            },
        },
        {
            title: "รวมค่าบริการ",
            dataIndex: "itotal2Amt",
            key: "itotal2Amt",
            align: "right" as "right",
            render: (value: any, item: TWorksheetSteelHeadView) => {
                return <span>{formatNumber(item.itotal2Amt)}</span>;
            },
        },
        {
            title: "ค่าประกัน",
            dataIndex: "iinsuranceAmt",
            key: "iinsuranceAmt",
            align: "right" as "right",
            render: (value: any, item: TWorksheetSteelHeadView) => {
                return <span>{formatNumber(item.iinsuranceAmt)}</span>;
            },
        },
    ];

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
        form.setFieldsValue({
            worksheetCode: "",
            fromDate: moment(new Date(), Constants.DATE_FORMAT),
            toDate: moment(new Date(), Constants.DATE_FORMAT)
        });
        form.submit();
    }, []);

    useEffect(() => {
        if (selectedSteelDischarge.ireserveCode) {
            history.replace("/app/steelDischarge/view/" + selectedSteelDischarge.ireserveCode);
        }
    }, [selectedSteelDischarge]);

    const onItemCliked = (item: TWorksheetSteelHeadView) => {
        history.replace("/app/steelDischarge/view/" + item.ireserveCode);
    };

    const onFinish = async (values: any) => {
        setLoading(true);
        const branchId = userInfo.branchId;
        let worksheetCode: string = values.worksheetCode ? values.worksheetCode : "";
        let fromDate: string = values.fromDate ? moment(values.fromDate).format(Constants.DB_FORMAT) : "";
        let toDate: string = values.toDate ? moment(values.toDate).format(Constants.DB_FORMAT) : "";
        if (worksheetCode && worksheetCode.length >= 7) {
            fromDate = "";
            toDate = "";
        } else if (worksheetCode.length > 0 && worksheetCode.length < 7) {
            showNotification(NotificationType.WARNING, "กรุณาป้อนเลขที่ใบงานให้มากกว่า 7 ตัวอักษร");
            form.setFieldsValue({
                worksheetCode: "",
            });
            setLoading(false);
            return;
        }
        const res = await tWorksheetSteelHeadService.getWorksheetSteelDischarges(branchId, worksheetCode, fromDate, toDate);
        setSteelDischarges(res.data);
        setLoading(false);
    }

    const onFinishFailed = (errorInfo: any) => {
    };

    const onReset = () => {
        form.setFieldsValue({
            worksheetCode: "",
            fromDate: moment(new Date(), Constants.DATE_FORMAT),
            toDate: moment(new Date(), Constants.DATE_FORMAT)
        });
        form.submit();
    };

    const onAddSteelDischarge = () => {
        setVisibleSteelUnDischargeModal(true);
    }

    return (
        <>
            <PageHeader title="โปรแกรมปล่อยแผ่นเหล็ก">
                <Button
                    size="small"
                    type="primary"
                    icon={<PlusSquareOutlined />}
                    onClick={onAddSteelDischarge}
                >
                    เพิ่ม
                </Button>
            </PageHeader>
            <Content className="app-page-content">
                <Form
                    form={form}
                    name="bankBranchHead"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "baseline",
                            margin: "1em 0 1em 0",
                            paddingLeft: "1em",
                        }}
                    >

                        <Space direction="vertical" size={"small"}>
                            <Form.Item
                                label="เลขที่ใบงาน"
                                name="worksheetCode"
                                rules={[{ required: false }]}
                            >
                                <Input
                                    allowClear
                                    style={{
                                        width: 378,
                                    }}
                                    placeholder="ป้อนเลขที่ใบงาน"
                                ></Input>
                            </Form.Item>
                            <Space align="baseline" size={"large"}>
                                <Form.Item
                                    label="วันที่ปล่อย ตั้งแต่"
                                    name="fromDate"
                                    rules={[{ required: false }]}
                                >
                                    <DatePicker
                                        format={Constants.DATE_FORMAT}
                                        size="middle"
                                        placeholder="เลือกวันที่"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="ถึง"
                                    name="toDate"
                                    rules={[{ required: false }]}
                                >
                                    <DatePicker
                                        format={Constants.DATE_FORMAT}
                                        size="middle"
                                        placeholder="เลือกวันที่"
                                    />
                                </Form.Item>
                            </Space>
                        </Space>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: 'space-between',
                            padding: "1em",
                            borderBlockEnd: "2px solid #c9c9c9",
                        }}
                    >
                        <span></span>
                        <Space>
                            <Button
                                size="small"
                                type="primary"
                                icon={<SearchOutlined />}
                                onClick={form.submit}
                            >
                                ค้นหา
                            </Button>
                            <Button
                                size="small"
                                type="primary"
                                icon={<ClearOutlined />}
                                onClick={onReset}
                            >
                                ล้าง
                            </Button>
                        </Space>
                    </div>
                </Form>
                <div>
                    <Table
                        columns={columns}
                        dataSource={steelDischarges}
                        size="small"
                        loading={loading}
                        pagination={{
                            pageSize: 15,
                            onChange(current) {
                                setPage(current);
                            }
                        }}
                        rowKey={(item: TWorksheetSteelHeadView) => item.ireserveCode!}
                        onRow={(item: TWorksheetSteelHeadView) => {
                            return {
                                onDoubleClick: () => onItemCliked(item),
                            };
                        }}
                    />
                </div>
                <div> {
                    visibleSteelUnDischargeModal && <SteelUnDischargeModal
                        setSelectedSteelDischarge={setSelectedSteelDischarge}
                        visibleSteelUnDischargeModal={visibleSteelUnDischargeModal}
                        setVisibleSteelUnDischargeModal={setVisibleSteelUnDischargeModal} />
                }</div>
            </Content>
        </>
    );
};

export default SteelDischargeList;
