import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, Space, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { TAssetHeadView } from "src/domain/master/view/tAssetHeadView";
import { TWorksheetDetail } from "src/domain/worksheet/tWorksheetDetail";
import { TWorksheetHead } from "src/domain/worksheet/tWorksheetHead";
import { WorksheetHeadData } from "src/domain/worksheet/view/worksheetHeadData";
import { NotificationType, showNotification } from "src/utils/notificationUtils";
import { formatNumber } from '../../../utils/numberUtils';
import AddServiceDetailModal from "../modal/AddServiceDetailModal";

interface ModalProp {
	worksheetHeadData: WorksheetHeadData;
	setWorksheetHeadData: any;
	assetSelected: TAssetHeadView;
	setRecalculateWsh: any;
	wshDetailFormDataList: TWorksheetDetail[];
	setWshDetailFormDataList: any;
}

const ServiceDetailTab: React.FC<ModalProp> = (props) => {
	const { worksheetHeadData, setWorksheetHeadData, assetSelected, setRecalculateWsh, wshDetailFormDataList, setWshDetailFormDataList } = props;
	const [visibleServiceDetailModal, setVisibleOtherServiceModal] = useState(false);
	const [recalculateServiceDet, setRecalculateServiceDet] = useState<boolean>(false);

	const columns = [
		{
			title: "ลำดับ",
			dataIndex: "index",
			key: "index",
			align: "center" as "center",
			render: (value: any, item: TWorksheetDetail, index: number) => {
				return <span>{index + 1}</span>;
			},
		},
		{
			title: "รายการ",
			dataIndex: "iserviceName",
			key: "iserviceName",
		},
		{
			title: "ราคา/หน่วย",
			dataIndex: "iunitPrice",
			key: "iunitPrice",
			align: "right" as "right",
			render: (value: any, item: TWorksheetDetail, index: number) => {
				return <span>{formatNumber(item.iunitPrice)}</span>;
			},
		},
		{
			title: "จำนวน",
			dataIndex: "iqty",
			key: "iqty",
			align: "right" as "right",
		},
		{
			title: "หน่วย",
			dataIndex: "itypeunitName",
			key: "itypeunitName",
		},
		{
			title: "ส่วนลด",
			dataIndex: "discount",
			key: "discount",
			align: "right" as "right",
			render: (value: any, item: TWorksheetDetail, index: number) => {
				return <span>{formatNumber(item.discount)}</span>;
			},
		},
		{
			title: "รวม",
			dataIndex: "iamount",
			key: "iamount",
			align: "right" as "right",
			render: (value: any, item: TWorksheetDetail, index: number) => {
				return <span>{formatNumber(item.iamount)}</span>;
			},
		},
		{
			title: "",
			key: "action",
			align: "center" as "center",
			render: (value: any, item: TWorksheetDetail, index: number) => {
				return (
					<span>
						<a onClick={(e) => { onDelete(item, index); }} style={{ color: "red" }}
						>
							X
						</a>
					</span>
				);
			},
		},
	];

	const reindex = (tWorksheetDetail: TWorksheetDetail[]) => {
		return tWorksheetDetail.map((item, index) => {
			item.iid = index;
			return item;
		});
	}

	const onDelete = (item: TWorksheetDetail, index: number) => {
		const array = wshDetailFormDataList ? [...wshDetailFormDataList] : [];
		array?.splice(index, 1);
		setWshDetailFormDataList(array);
		setRecalculateServiceDet(true);
	}

	useEffect(() => {
		let sumServiceAmount = 0;
		let sumServiceDiscount = 0;
		let sumServiceLastAmt = 0;
		wshDetailFormDataList.forEach((obj) => {
			sumServiceAmount += ((obj.iamount ? obj.iamount : 0) + (obj.discount ? obj.discount : 0));
			sumServiceDiscount += (obj.discount) ? obj.discount : 0;
			sumServiceLastAmt += (obj.iamount) ? obj.iamount : 0;
		});

		setWorksheetHeadData({
			...(worksheetHeadData as WorksheetHeadData),
			tworksheetHead: {
				...(worksheetHeadData.tworksheetHead as TWorksheetHead),
				iserviceAmt: sumServiceAmount ? sumServiceAmount : 0,
				idiscount: sumServiceDiscount ? sumServiceDiscount : 0,
			},
			tworksheetDetailList: [...reindex(wshDetailFormDataList)],
			sumServiceLastAmt: sumServiceLastAmt,
		});
	}, [wshDetailFormDataList]);

	useEffect(() => {
		if (Object.keys(worksheetHeadData).length > 0 && recalculateServiceDet) {
			setRecalculateWsh(true);
			setRecalculateServiceDet(false);
		}
	}, [worksheetHeadData]);

	const handleAdd = () => {
		if (!worksheetHeadData.tworksheetHead.typeworksheetflag) {
			showNotification(NotificationType.ERROR, "กรุณาระบุประเภทงานก่อน !");
			return;
		} else if (!worksheetHeadData.tworksheetHead.itruckCode) {
			showNotification(NotificationType.ERROR, "กรุณาระบุ รถ/ตู้/แผ่นเหล็กก่อน !");
			return;
		}
		setVisibleOtherServiceModal(true);
	}

	return (
		<>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "baseline",
					justifyContent: "space-between",
				}}
			>
				<Space align="baseline" direction="horizontal">
					<Button
						size="small"
						type="primary"
						icon={<PlusSquareOutlined />}
						onClick={() => handleAdd()}
					>
						เพิ่ม
					</Button>
				</Space>
				<Typography.Title
					type="danger"
					level={4}
					style={{ height: "22px", textAlign: "right" }}
				>
					จำนวนเงิน :{" "}
					{formatNumber(
						Number(!worksheetHeadData?.sumServiceLastAmt ? 0 : worksheetHeadData?.sumServiceLastAmt).toFixed(2)
					)}
				</Typography.Title>
			</div>
			<div>
				<Table
					columns={columns}
					dataSource={wshDetailFormDataList}
					size="small"
					pagination={false}
					rowKey={(item: TWorksheetDetail) => item.iid}
				/>
			</div>
			<div>
				{visibleServiceDetailModal && (
					<AddServiceDetailModal
						visibleServiceDetailModal={visibleServiceDetailModal}
						setVisibleOtherServiceModal={setVisibleOtherServiceModal}
						assetSelected={assetSelected}
						wshDetailFormDataList={wshDetailFormDataList}
						setWshDetailFormDataList={setWshDetailFormDataList}
						setRecalculateServiceDet={setRecalculateServiceDet}
					/>
				)}
			</div>
		</>
	);
};

export default ServiceDetailTab;