import { notification } from "antd";
import { NotificationPlacement } from "antd/lib/notification";

export enum NotificationType {
    INFO = "INFO",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
    WARNING = "WARNING",
}

export function showNotification(notificationType: NotificationType, content: string) {
    const contents = {
        message:'',
        description: content,
        duration: 0,
        placement: 'top' as NotificationPlacement,
        top: 100,
    }
    switch (notificationType) {
        case NotificationType.INFO:
            contents.message = 'แจ้ง'
            notification.info(contents);
            break;
        case NotificationType.SUCCESS:
            contents.duration=2
            contents.message = 'สำเร็จ'
            notification.success(contents);
            break;
        case NotificationType.ERROR:
            contents.message = 'เกิดข้อผิดพลาด'
            notification.error(contents);
            break;
        case NotificationType.WARNING:
            contents.message = 'แจ้งเตือน'
            notification.warning(contents);
            break;
    }
}

