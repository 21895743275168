import { CloseCircleOutlined, ExclamationCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, DatePicker, Drawer, Form, Input, InputNumber, Modal, Row, Select, Space, Spin, Switch, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BsAmphurH } from "src/domain/bsAmphurH";
import { BsProvinceH } from "src/domain/bsProvinceH";
import { TBankBranchHead } from "src/domain/master/tBankBranchHead";
import { TBankHead } from "src/domain/master/tBankHead";
import { TEmployeechange } from "src/domain/master/tEmployeechange";
import { RyRefbranch } from "src/domain/ryRefbranch";
import { RyRefposition } from "src/domain/ryRefposition";
import { TTypeemployeeRef } from "src/domain/tTypeemployeeRef";
import tBankBranchHeadService from "src/services/bank/tBankBranchHeadService";
import tBankHeadService from "src/services/bank/tBankHeadService";
import tEmployeeChangeService from "src/services/employee/tEmployeeChangeService";
import bsAmphurHService from "src/services/province/bsAmphurHService";
import bsProvinceHService from "src/services/province/bsProvinceHService";
import ryRefbranchService from "src/services/reference/ryRefbranchService";
import ryRefpositionService from "src/services/reference/ryRefpositionService";
import tTypeemployeeRefService from "src/services/reference/tTypeemployeeRefService";
import { useAppSelector } from "src/stores/store";
import Constants from "src/utils/constants";
import { getYearOlds, toMomentDate } from "src/utils/dateTimeUtils";
import { formatNumber, parserNumber, textNumber } from "src/utils/numberUtils";
import { TEmployee2 } from "../../domain/master/tEmployee2";
import employeeService from "../../services/employee/tEmployee2Service";
import { NotificationType, showNotification } from "../../utils/notificationUtils";
import { getFlg } from "../../utils/rodyokUtils";

const { Option } = Select;
const { confirm } = Modal;

interface IParams {
    id: string;
    visibleAddEditEmployee2Drawer: boolean;
    setVisibleAddEditEmployee2Drawer: any;
}

const AddEditEmployee2Drawer: React.FC<IParams> = (props) => {
    const { id, visibleAddEditEmployee2Drawer, setVisibleAddEditEmployee2Drawer } = props;
    const [trigger, setTrigger] = useState<boolean>(false);
    const [employee2, setEmployee2] = useState<TEmployee2>({} as TEmployee2);
    const [form] = Form.useForm();
    const history = useHistory();
    const [showSpin, setShowSpin] = useState<boolean>(false);

    const [ryRefbranches, setRyRefbranches] = useState<RyRefbranch[]>([]);
    const [provinces, setProvinces] = useState<BsProvinceH[]>([]);
    const [amphurs, setAmphurs] = useState<BsAmphurH[]>([]);
    const [tTypeemployeeRefs, setTTypeemployeeRefs] = useState<TTypeemployeeRef[]>([]);
    const [ryRefpositions, setRyRefpositions] = useState<RyRefposition[]>([]);
    const [bankHeads, setBankHeads] = useState<TBankHead[]>([]);
    const [bankBranchHeads, setBankBranchHeads] = useState<TBankBranchHead[]>([]);
    const userInfo = useAppSelector((state) => state.authen.authenUser.user);

    let title: string = "เพิ่มข้อมูลพนักงาน";
    if (id) {
        title = "แก้ไขข้อมูลพนักงาน";
    }

    useEffect(() => {
        if (trigger) {
            form.validateFields()
                .then(() => {
                    confirm({
                        title: "ยืนยัน!",
                        icon: <ExclamationCircleOutlined />,
                        content: "คุณต้องการที่จะบันทึกข้อมูลหรือไม่?",
                        cancelText: "ยกเลิก",
                        okText: "ยืนยัน",
                        onOk() {
                            form.submit();
                        },
                        onCancel() {
                            setTrigger(false);
                        }
                    });
                }).catch(() => {
                    setTrigger(false);
                });
        }
    }, [trigger]);

    useEffect(() => {
        setShowSpin(true);

        initial();
        return () => {
        };
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            id: employee2.id,
            positioncode: employee2.positioncode,
            typeemployeecode: employee2.typeemployeecode,
            empbranch: employee2.empbranch,
            empname: employee2.empname,
            flgdel: getFlg(employee2.flgdel),
            empentrydate: employee2.empentrydate ? toMomentDate(employee2.empentrydate) : "",
            empbirthdate: employee2.empbirthdate ? toMomentDate(employee2.empbirthdate) : "",
            olds: employee2.empbirthdate ? getYearOlds(employee2.empbirthdate) : "",
            emppersonalid: employee2.emppersonalid ? employee2.emppersonalid : "-",
            addresshomenumber: employee2.addresshomenumber ? employee2.addresshomenumber : "-",
            addresshosename: employee2.addresshosename ? employee2.addresshosename : "-",
            addressroomnumber: employee2.addressroomnumber ? employee2.addressroomnumber : "-",
            addressmoo: employee2.addressmoo ? employee2.addressmoo : "-",
            addresslane: employee2.addresslane ? employee2.addresslane : "-",
            addressroad: employee2.addressroad ? employee2.addressroad : "-",
            addressamphurcode: employee2.addressamphurcode,
            addressprovincecode: employee2.addressprovincecode,
            addresspostcode: employee2.addresspostcode,
            addresstelephonenumber: employee2.addresstelephonenumber ? employee2.addresstelephonenumber : "-",
            addresstelephonenumberplus: employee2.addresstelephonenumberplus ? employee2.addresstelephonenumberplus : "-",
            addressmobilenumber: employee2.addressmobilenumber ? employee2.addressmobilenumber : "-",
            percentperday: employee2.percentperday ? employee2.percentperday : 0,
            percentotperhour: employee2.percentotperhour ? employee2.percentotperhour : 0,
            otherexressperahalfday: employee2.otherexressperahalfday ? formatNumber(employee2.otherexressperahalfday) : 0.00,
            otherexressperday: employee2.otherexressperday ? formatNumber(employee2.otherexressperday) : 0.00,
            otherexressafterfiveoclock: employee2.otherexressafterfiveoclock ? formatNumber(employee2.otherexressafterfiveoclock) : 0.00,
            addresstumbol: employee2.addresstumbol ? employee2.addresstumbol : "-",
            empendworkdate: employee2.empendworkdate ? toMomentDate(employee2.empendworkdate) : "",
            issalary: getFlg(employee2.issalary),
            bankcode: employee2.bankcode,
            bankbranchcode: employee2.bankbranchcode,
            accountcode: employee2.accountcode,
        });

        if (employee2.bankcode) {
            initBankBranchHeadHs(employee2.bankcode);
        }
        return () => {
        };
    }, [employee2]);

    const initial = async () => {
        const ryRefbranchesRes = await ryRefbranchService.getRyRefbranchList();
        const provinceRes = await bsProvinceHService.getBsProvinceHs("");
        const amphurRes = await bsAmphurHService.getBsAmphurHs();
        const tTypeemployeeRefsRes = await tTypeemployeeRefService.getTTypeemployeeRefList();
        const ryRefpositionsRes = await ryRefpositionService.getRyRefpositionList();
        const bankHeadsRes = await tBankHeadService.getBankHeads("");
        if (id) {
            const res = await employeeService.getEmployee2ById(id);
            setEmployee2(res.data);
        } else {
            const nextCodeRes = await employeeService.getNextEmployee2Code();
            setEmployee2({ ...employee2, id: nextCodeRes.data });
        }

        setRyRefbranches(ryRefbranchesRes.data)
        setProvinces(provinceRes.data);
        setAmphurs(amphurRes.data);
        setTTypeemployeeRefs(tTypeemployeeRefsRes.data);
        setRyRefpositions(ryRefpositionsRes.data);
        setBankHeads(bankHeadsRes.data);
        setShowSpin(false);
    };

    const onFinish = async (values: any) => {
        const _employee2: TEmployee2 = {
            id: values.id,
            positioncode: values.positioncode,
            typeemployeecode: values.typeemployeecode,
            empbranch: values.empbranch,
            empname: values.empname,
            flgdel: values.flgdel ? 0 : -1,
            empentrydate: values.empentrydate ? values.empentrydate.format(Constants.DB_FORMAT) : "",
            empbirthdate: values.empbirthdate ? values.empbirthdate.format(Constants.DB_FORMAT) : "",
            emppersonalid: values.emppersonalid ? values.emppersonalid.replaceAll("-", "") : "",
            addresshomenumber: values.addresshomenumber,
            addresshosename: values.addresshosename,
            addressroomnumber: values.addressroomnumber,
            addressmoo: values.addressmoo,
            addresslane: values.addresslane,
            addressroad: values.addressroad,
            addressamphurcode: values.addressamphurcode,
            addressprovincecode: values.addressprovincecode,
            addresspostcode: values.addresspostcode,
            addresstelephonenumber: values.addresstelephonenumber,
            addresstelephonenumberplus: values.addresstelephonenumberplus,
            addressmobilenumber: values.addressmobilenumber,
            percentperday: values.percentperday,
            percentotperhour: values.percentotperhour,
            otherexressperahalfday: parserNumber(values.otherexressperahalfday),
            otherexressperday: parserNumber(values.otherexressperday),
            otherexressafterfiveoclock: parserNumber(values.otherexressafterfiveoclock),
            addresstumbol: values.addresstumbol,
            empendworkdate: values.empendworkdate ? values.empendworkdate.format(Constants.DB_FORMAT) : "",
            issalary: values.issalary ? "0" : "1",
            bankcode: values.bankcode,
            bankbranchcode: values.bankbranchcode,
            accountcode: values.accountcode,
            savstation: userInfo.saveStation,
            createuser: userInfo.userId,
            lastuser: userInfo.userId,
            termno: userInfo.termNo
        };

        // defualt 0 for id and seq, will set and automatic increase in API
        const _employeeChange: TEmployeechange = {
            seq: 0,
            employeecode: values.id,
            positioncode: values.positioncode,
            typeemployeecode: values.typeemployeecode,
            empbranch: values.empbranch,
            empname: values.empname,
            flgdel: values.flgdel ? 0 : 1,
            empentrydate: values.empentrydate ? values.empentrydate.format(Constants.DB_FORMAT) : "",
            empbirthdate: values.empbirthdate ? values.empbirthdate.format(Constants.DB_FORMAT) : "",
            emppersonalid: values.emppersonalid ? values.emppersonalid.replaceAll("-", "") : "",
            addresshomenumber: values.addresshomenumber,
            addresshosename: values.addresshosename,
            addressroomnumber: values.addressroomnumber,
            addressmoo: values.addressmoo,
            addresslane: values.addresslane,
            addressroad: values.addressroad,
            addressamphurcode: values.addressamphurcode,
            addressprovincecode: values.addressprovincecode,
            addresspostcode: values.addresspostcode,
            addresstelephonenumber: values.addresstelephonenumber,
            addresstelephonenumberplus: values.addresstelephonenumberplus,
            addressmobilenumber: values.addressmobilenumber,
            percentperday: values.percentperday,
            percentotperhour: values.percentotperhour,
            otherexressperahalfday: parserNumber(values.otherexressperahalfday),
            otherexressperday: parserNumber(values.otherexressperday),
            otherexressafterfiveoclock: parserNumber(values.otherexressafterfiveoclock),
            addresstumbol: values.addresstumbol,
            eremark: values.eremark ? values.eremark : "สร้างรายการพนักงานใหม่",
            savstation: userInfo.saveStation,
            createuser: userInfo.userId,
            lastuser: userInfo.userId,
            termno: userInfo.termNo
        };

        if (!id) {
            try {
                const res = await employeeService.postEmployee2(_employee2);
                await tEmployeeChangeService.postEmployeeChange(_employeeChange);
                showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลพนักงานสำเร็จ!");
                form.resetFields();
                setTrigger(false);
                setVisibleAddEditEmployee2Drawer(false);
                history.replace("/app/employee/view/" + res.data.id);
            } catch (err) {
                showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
                setTrigger(false);
            }
        } else {
            try {
                await employeeService.updateEmployee2(_employee2);
                await tEmployeeChangeService.postEmployeeChange(_employeeChange);
                showNotification(NotificationType.SUCCESS, "แก้ไขข้อมูลพนักงานเรียบร้อยแล้ว!");
                form.resetFields();
                setTrigger(false);
                setVisibleAddEditEmployee2Drawer(false);
                history.replace("/app/employee/view/" + employee2.id);
            } catch (err) {
                showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
                setTrigger(false);
            }
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        showNotification(NotificationType.ERROR, "กรุณาระบุข้อมูลให้ครบถ้วน");
        setTrigger(false);
    };

    const initBankBranchHeadHs = (bankcode: string) => {
        (async () => {
            const res = await tBankBranchHeadService.getBankBranchHeadByBankCode(bankcode);
            setBankBranchHeads(res.data);
        })();
    }

    const onEmpBirthDateChnaged = () => {
        form.setFieldsValue({
            olds: form.getFieldValue("empbirthdate") ? getYearOlds(form.getFieldValue("empbirthdate").format(Constants.DB_FORMAT)) : "",
        })
    }

    const handleSearchAmphur = async (data: any) => {
        if (data.length < 3) return;
        const res = await bsAmphurHService.getBsAmphurHByName(data);
        setAmphurs(res.data);
    };

    return (
        <>
            <Drawer
                title={
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "baseline",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography.Title level={4}>{title}</Typography.Title>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "",
                            }}
                        >
                            <Button
                                type="primary"
                                danger
                                icon={<CloseCircleOutlined />}
                                onClick={() => setVisibleAddEditEmployee2Drawer(false)}>
                                ยกเลิก
                            </Button>
                            <Button
                                onClick={() => setTrigger(true)}
                                type="primary"
                                icon={<SaveOutlined />}
                                style={{ marginLeft: "5px" }}
                            >
                                บันทึก
                            </Button>
                        </div>
                    </div>
                }
                width="90%"
                visible={visibleAddEditEmployee2Drawer}
                forceRender={true}
                placement="right"
                bodyStyle={{ paddingBottom: 80 }}
                closable={false}
                destroyOnClose={true}
            >
                <Content className="app-page-content">
                    <div>
                        <Spin spinning={showSpin}>
                            <Form
                                form={form}
                                name="employee2"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>รหัสพนักงาน: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={142}>
                                            <Form.Item
                                                name="id"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Input disabled={true} />
                                            </Form.Item>

                                            <Form.Item
                                                label="สาขา"
                                                name="empbranch"
                                                rules={[
                                                    { required: true, message: "กรุณาเลือกสาขา" }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    size="middle"
                                                    placeholder="เลือกสาขา"
                                                    optionFilterProp="children"
                                                    filterOption={true}
                                                    style={{ width: "200px" }}
                                                >
                                                    {ryRefbranches.map((obj) => {
                                                        return (
                                                            <Option key={obj.id} value={obj.id}>
                                                                {obj.name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>ประเภทพนักงาน: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="typeemployeecode"
                                            rules={[
                                                { required: true, message: "กรุณาเลือกประเภทพนักงาน" }
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                size="middle"
                                                placeholder="เลือกประเภทพนักงาน"
                                                optionFilterProp="children"
                                                filterOption={true}
                                                style={{ width: "200px" }}
                                            >
                                                {tTypeemployeeRefs.map((obj) => {
                                                    return (
                                                        <Option key={obj.iid} value={obj.iid}>
                                                            {obj.iname}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>ตำแหน่ง: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Space>
                                            <Form.Item
                                                name="positioncode"
                                                rules={[
                                                    { required: true, message: "กรุณาเลือกตำแหน่ง" }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    size="middle"
                                                    placeholder="เลือกตำแหน่ง"
                                                    optionFilterProp="children"
                                                    filterOption={true}
                                                    style={{ width: "200px" }}
                                                >
                                                    {ryRefpositions.map((obj) => {
                                                        return (
                                                            <Option key={obj.id} value={obj.id}>
                                                                {obj.name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                name="issalary"
                                                rules={[
                                                    { required: false }
                                                ]}
                                                valuePropName="checked"
                                            >
                                                <Checkbox >คำนวนเงินเดือน</Checkbox>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>
                                <br />
                                <br />

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>ชื่อ-สกุล: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={30}>
                                            <Form.Item
                                                name="empname"
                                                rules={[
                                                    { required: true, message: "กรุณาป้อน ชื่อ-สกุล" },
                                                    {
                                                        validator: (_, value) => {
                                                            if (value && (value === " " || value === "-")) {
                                                                return Promise.reject(new Error('กรุณาป้อนชื่อ-สกุลให้ถูกต้อง!'))
                                                            } else {
                                                                return Promise.resolve()
                                                            }
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="ป้อน ชื่อ-สกุล" style={{ width: "300px" }} maxLength={200}></Input>
                                            </Form.Item>
                                            <Form.Item
                                                label="วัน เดือน ปี เกิด"
                                                name="empbirthdate"
                                                rules={[
                                                    { required: true, message: "กรุณาป้อน วัน/เดือน/ปี เกิด" }
                                                ]}
                                            >
                                                <DatePicker
                                                    format={Constants.DATE_FORMAT}
                                                    onChange={onEmpBirthDateChnaged}
                                                    placeholder="ป้อน วัน/เดือน/ปี เกิด">
                                                </DatePicker>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>เลขที่บัตรประชาชน: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={18}>
                                            <Form.Item
                                                name="emppersonalid"
                                                rules={[
                                                    { required: true, message: "กรุณาป้อนเลขที่บัตรประชาชน" }
                                                ]}
                                            >
                                                <Input placeholder="ป้อนเลขที่บัตรประชาชน" style={{ width: "210px" }}></Input>
                                            </Form.Item>
                                            <Form.Item
                                                label="อายุ"
                                                name="olds"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Input disabled style={{ width: "50px" }}></Input>
                                            </Form.Item>
                                            <Form.Item
                                                label="วันที่เข้าทำงาน"
                                                name="empentrydate"
                                                rules={[{ required: true, message: "ป้อนวันที่เข้าทำงาน!" },]}
                                            >
                                                <DatePicker
                                                    format={Constants.DATE_FORMAT}
                                                    placeholder="ป้อนวันที่เข้าทำงาน"></DatePicker>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>
                                <br />
                                <br />
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>ชื่อหมู่บ้าน/อาคาร: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="addresshosename"
                                            rules={[
                                                { required: true, message: "กรุณาป้อนชื่อหมู่บ้าน/อาคาร!" },
                                                {
                                                    validator: (_, value) => {
                                                        if (value && (value === " ")) {
                                                            return Promise.reject(new Error('กรุณาป้อนชื่อหมู่บ้าน/อาคารให้ถูกต้อง!'))
                                                        } else {
                                                            return Promise.resolve()
                                                        }
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input placeholder="ป้อนชื่อหมู่บ้าน/อาคาร" style={{ width: "300px" }} maxLength={50}></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>ที่อยู่ บ้านเลขที่: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Space>
                                            <Form.Item
                                                name="addresshomenumber"
                                                rules={[
                                                    { required: true, message: "ป้อนบ้านเลขที่!" },
                                                ]}
                                            >
                                                <Input style={{ width: "98px" }} placeholder="ป้อนบ้านเลขที่" maxLength={10}></Input>
                                            </Form.Item>
                                            <Form.Item
                                                label="ห้อง"
                                                name="addressroomnumber"
                                                rules={[{ required: false },]}
                                            >
                                                <Input style={{ width: "50px" }} maxLength={10}></Input>
                                            </Form.Item>
                                            <Form.Item
                                                label="หมู่ที่"
                                                name="addressmoo"
                                                rules={[{ required: true, message: "ป้อนหมู่ที่!" },]}
                                            >
                                                <Input style={{ width: "50px" }} maxLength={10}></Input>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>ตรอก: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={158}>
                                            <Form.Item
                                                name="addresslane"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Input placeholder="ป้อนตรอก" maxLength={50}></Input>
                                            </Form.Item>
                                            <Form.Item
                                                label="ถนน"
                                                name="addressroad"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Input placeholder="ป้อนถนน" maxLength={50}></Input>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>ตำบล: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={138}>
                                            <Form.Item
                                                name="addresstumbol"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Input placeholder="ป้อนตำบล" maxLength={100}></Input>
                                            </Form.Item>
                                            <Form.Item
                                                label="อำเภอ"
                                                name="addressamphurcode"
                                                rules={[
                                                    { required: true, message: "กรุณาเลือกอำเภอ" }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    size="middle"
                                                    placeholder="เลือกอำเภอ"
                                                    optionFilterProp="children"
                                                    filterOption={true}
                                                    onSearch={handleSearchAmphur}
                                                    style={{ width: "200px" }}
                                                >
                                                    {amphurs.map((obj) => {
                                                        return (
                                                            <Option key={obj.iid} value={obj.iid}>
                                                                {obj.iamphurName}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>จังหวัด: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={102}>
                                            <Form.Item
                                                name="addressprovincecode"
                                                rules={[
                                                    { required: true, message: "กรุณาเลือกจังหวัด" }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    size="middle"
                                                    placeholder="เลือกจังหวัด"
                                                    optionFilterProp="children"
                                                    filterOption={true}
                                                    style={{ width: "200px" }}
                                                >
                                                    {provinces.map((obj) => {
                                                        return (
                                                            <Option key={obj.iprovinceCode} value={obj.iprovinceCode}>
                                                                {obj.iprovinceName}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                label="รหัสไปรษณีย์"
                                                name="addresspostcode"
                                                rules={[
                                                    { required: true, message: "กรุณาป้อนรหัสไปรษณีย์" }
                                                ]}
                                            >
                                                <Input placeholder="ป้อนรหัสไปรษณีย์"></Input>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>โทรศัพท์: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={"middle"}>
                                            <Form.Item
                                                name="addresstelephonenumber"
                                                rules={[
                                                    { required: true, message: "กรุณาป้อนโทรศัพท์ หากไม่มีกรุณาใส่เครื่องหมาย - " },
                                                    {
                                                        validator: (_, value) => {
                                                            if (value && (value === " ")) {
                                                                return Promise.reject(new Error('กรุณาป้อนโทรศัพท์ให้ถูกต้อง!'))
                                                            } else {
                                                                return Promise.resolve()
                                                            }
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="ป้อนเบอร์โทร" maxLength={20}></Input>
                                            </Form.Item>
                                            <Form.Item
                                                label="ต่อ"
                                                name="addresstelephonenumberplus"
                                                rules={[{ required: true, message: "กรุณาป้อนต่อ หากไม่มีกรุณาใส่เครื่องหมาย - " }]}
                                            >
                                                <Input style={{ width: "70px" }} maxLength={5}></Input>
                                            </Form.Item>
                                            <Form.Item
                                                label="มือถือ"
                                                name="addressmobilenumber"
                                                rules={[
                                                    { required: true, message: "กรุณาป้อนมือถือ" },
                                                    {
                                                        validator: (_, value) => {
                                                            if (value && (value === " ")) {
                                                                return Promise.reject(new Error('กรุณาป้อนมือถือให้ถูกต้อง!'))
                                                            } else {
                                                                return Promise.resolve()
                                                            }
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="ป้อนเบอร์มือถือ" maxLength={20}></Input>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>
                                <br />
                                <br />

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>ธนาคาร: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={75}>
                                            <Form.Item
                                                name="bankcode"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    size="middle"
                                                    placeholder="เลือกธนาคาร"
                                                    optionFilterProp="children"
                                                    filterOption={true}
                                                    style={{ width: "200px" }}
                                                    onSelect={initBankBranchHeadHs}
                                                >
                                                    {bankHeads.map((obj) => {
                                                        return (
                                                            <Option key={obj.ibankCode} value={obj.ibankCode}>
                                                                {obj.ibankName}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                label="เลขที่บัญชีธนาคาร"
                                                name="accountcode"
                                                rules={[
                                                    { required: false }
                                                ]}
                                            >
                                                <Input placeholder="ป้อนเลขที่บัญชีธนาคาร" maxLength={20}></Input>
                                            </Form.Item>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>สาขาธนาคาร: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="bankbranchcode"
                                            rules={[
                                                { required: false }
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                size="middle"
                                                placeholder="เลือกสาขาธนาคาร"
                                                optionFilterProp="children"
                                                filterOption={true}
                                                style={{ width: "200px" }}
                                            >
                                                {bankBranchHeads?.map((obj) => {
                                                    return (
                                                        <Option key={obj.ibankBranchCode} value={obj.ibankBranchCode}>
                                                            {obj.ibankBranchName}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>จำนวน % ที่ได้รับปกติ: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={120}>
                                            <Space align="baseline">
                                                <Form.Item
                                                    name="percentperday"
                                                    rules={[
                                                        { required: true, message: "กรุณาป้อนจำนวน % ที่ได้รับปกติ!" },
                                                        {
                                                            validator: (_, value) => {
                                                                if (value && (Number(value) < 0 || Number(value) > 100)) {
                                                                    return Promise.reject(new Error('เปอร์เซนต์ที่พนักงานจะได้รับควรอยู่ระหว่าง 0 - 100!'))
                                                                } else {
                                                                    return Promise.resolve()
                                                                }
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber
                                                        controls={false}
                                                        precision={2}
                                                        parser={(value) => parserNumber(value)}
                                                        onFocus={(e) => e.target.select()}
                                                    ></InputNumber>
                                                </Form.Item>
                                                <Typography.Text> %</Typography.Text>
                                            </Space>
                                            <Space align="baseline">
                                                <Form.Item
                                                    label="จำนวน % ที่ได้รับ(OT)"
                                                    name="percentotperhour"
                                                    rules={[
                                                        { required: true, message: "กรุณาป้อนจำนวน % ที่ได้รับ(OT)!" },
                                                        {
                                                            validator: (_, value) => {
                                                                if (value && (Number(value) < 0 || Number(value) > 100)) {
                                                                    return Promise.reject(new Error('จำนวน % ที่ได้รับ(OT)ควรอยู่ระหว่าง 0 - 100!'))
                                                                } else {
                                                                    return Promise.resolve()
                                                                }
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber
                                                        controls={false}
                                                        precision={2}
                                                        parser={(value) => parserNumber(value)}
                                                        onFocus={(e) => e.target.select()}
                                                    ></InputNumber>
                                                </Form.Item>
                                                <Typography.Text> %</Typography.Text>
                                            </Space>
                                        </Space>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>เบี้ยเลี้ยงที่ได้รับปกติ: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={80}>
                                            <Space align="baseline">
                                                <Form.Item
                                                    name="otherexressperahalfday"
                                                    rules={[
                                                        { required: true, message: "กรุณาป้อนเบี้ยเลี้ยงที่ได้รับปกติ!" },
                                                        {
                                                            validator: (_, value) => {
                                                                const otherexressperahalfday = value ? parserNumber(value) : 0.0;
                                                                if (otherexressperahalfday && (otherexressperahalfday < 0 || otherexressperahalfday > 100)) {
                                                                    return Promise.reject(new Error('เบี้ยเลี้ยงที่ได้รับปกติควรมากกว่าหรือเท่ากับ 0!'))
                                                                } else {
                                                                    return Promise.resolve()
                                                                }
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        onKeyDown={(event: any) => textNumber(event)}
                                                        onPressEnter={(e: any) => form.setFieldsValue({ otherexressperahalfday: formatNumber(parserNumber(e.target.value)) })}
                                                        onBlur={(e: any) => form.setFieldsValue({ otherexressperahalfday: formatNumber(parserNumber(e.target.value)) })}
                                                        onFocus={(e) => e.target.select()}
                                                        style={{ textAlign: "end", width: "100%" }}
                                                    ></Input>
                                                </Form.Item>
                                                <Typography.Text> บาท/ครึ่งวัน</Typography.Text>
                                            </Space>
                                            <Space align="baseline">
                                                <Form.Item
                                                    label="เบี้ยเลี้ยงที่ได้รับปกติ"
                                                    name="otherexressperday"
                                                    rules={[
                                                        { required: true, message: "กรุณาป้อนเบี้ยเลี้ยงที่ได้รับปกติ!" },
                                                        {
                                                            validator: (_, value) => {
                                                                const otherexressperday = value ? parserNumber(value) : 0.0;
                                                                if (otherexressperday && (otherexressperday < 0 || otherexressperday > 100)) {
                                                                    return Promise.reject(new Error('เบี้ยเลี้ยงที่ได้รับปกติควรมากกว่าหรือเท่ากับ 0!'))
                                                                } else {
                                                                    return Promise.resolve()
                                                                }
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        onKeyDown={(event: any) => textNumber(event)}
                                                        onPressEnter={(e: any) => form.setFieldsValue({ otherexressperday: formatNumber(parserNumber(e.target.value)) })}
                                                        onBlur={(e: any) => form.setFieldsValue({ otherexressperday: formatNumber(parserNumber(e.target.value)) })}
                                                        onFocus={(e) => e.target.select()}
                                                        style={{ textAlign: "end", width: "100%" }}
                                                    ></Input>
                                                </Form.Item>
                                                <Typography.Text> บาท/วัน</Typography.Text>
                                            </Space>
                                        </Space>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>เบี้ยเลี้ยงเพิ่มหลัง 5 โมงเย็น: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Space size={95}>
                                            <Space align="baseline">
                                                <Form.Item
                                                    name="otherexressafterfiveoclock"
                                                    rules={[
                                                        { required: true, message: "กรุณาป้อนเบี้ยเลี้ยงเพิ่มหลัง 5 โมงเย็น!" },
                                                        {
                                                            validator: (_, value) => {
                                                                const otherexressafterfiveoclock = value ? parserNumber(value) : 0.0;
                                                                if (otherexressafterfiveoclock && (otherexressafterfiveoclock < 0 || otherexressafterfiveoclock > 100)) {
                                                                    return Promise.reject(new Error('เบี้ยเลี้ยงเพิ่มหลัง 5 โมงเย็นควรมากกว่าหรือเท่ากับ 0!'))
                                                                } else {
                                                                    return Promise.resolve()
                                                                }
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        onKeyDown={(event: any) => textNumber(event)}
                                                        onPressEnter={(e: any) => form.setFieldsValue({ otherexressafterfiveoclock: formatNumber(parserNumber(e.target.value)) })}
                                                        onBlur={(e: any) => form.setFieldsValue({ otherexressafterfiveoclock: formatNumber(parserNumber(e.target.value)) })}
                                                        onFocus={(e) => e.target.select()}
                                                        style={{ textAlign: "end", width: "100%" }}
                                                    ></Input>
                                                </Form.Item>
                                                <Typography.Text> บาท/ชั่วโมง</Typography.Text>
                                            </Space>
                                            <Space align="baseline">
                                                <Form.Item
                                                    label="จำนวนเงินสะสมรวม"
                                                    name="total"
                                                    rules={[
                                                        { required: false }
                                                    ]}
                                                >
                                                    <InputNumber
                                                        controls={false}
                                                        precision={2}
                                                        disabled={true}
                                                        formatter={(value) => formatNumber(value)}
                                                        parser={(value) => parserNumber(value)}
                                                    ></InputNumber>
                                                </Form.Item>
                                                <Typography.Text> บาท</Typography.Text>
                                            </Space>
                                        </Space>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>วันสิ้นสุดการทำงาน: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="empendworkdate"
                                            rules={[
                                                { required: false }
                                            ]}
                                        >
                                            <DatePicker
                                                format={Constants.DATE_FORMAT}
                                                placeholder="ป้อนวันสิ้นสุดการทำงาน" style={{ width: "200px" }}>
                                            </DatePicker>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <Typography.Text>สถานะ: </Typography.Text>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="flgdel"
                                            rules={[
                                                { required: false }
                                            ]}
                                            valuePropName="checked"
                                        >
                                            <Switch checkedChildren="ใช้งาน" unCheckedChildren="ไม่ใช้งาน" defaultChecked={true} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
                                        <span>
                                            <Typography.Text>หมายเหตุ: </Typography.Text>
                                            <label className="text-red">*</label>
                                        </span>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item
                                            name="eremark"
                                            rules={[

                                                { required: true, message: "กรุณากรอกข้อมูลหมายเหตุ !" },
                                                {
                                                    validator: (_, value) => {
                                                        if (id) {
                                                            if (value && (value === "")) {
                                                                return Promise.reject(new Error('กรุณากรอกข้อมูลหมายเหตุให้ถูกต้อง !'))
                                                            } else if (value && value.length < 10) {
                                                                return Promise.reject(new Error('กรุณากรอกข้อมูลหมายเหตุมากกว่า 10 ตัวอักษร !'))
                                                            } else {
                                                                return Promise.resolve();
                                                            }
                                                        } else {
                                                            return Promise.resolve();
                                                        }
                                                    },
                                                },
                                            ]}
                                        >
                                            <TextArea
                                                style={{ width: "565px" }}
                                                rows={3}
                                                maxLength={255}
                                            ></TextArea>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Spin>
                    </div>
                </Content>
            </Drawer>
        </>
    );
};

export default AddEditEmployee2Drawer;
