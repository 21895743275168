import { ClearOutlined, PrinterFilled, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker, Form, Input, Modal,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tabs
} from "antd";
import { Content } from "antd/lib/layout/layout";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { TEmployee2 } from "src/domain/master/tEmployee2";
import { TAssetHeadView } from "src/domain/master/view/tAssetHeadView";
import { TCustomerHeadView } from "src/domain/master/view/tCustomerHeadView";
import { RyReftypework } from "src/domain/ryReftypework";
import { TTypepaymentRef } from "src/domain/tTypepaymentRef";
import { TTypetaxRef } from "src/domain/tTypetaxRef";
import { TTypeworksheetRef } from "src/domain/tTypeworksheetRef";
import { WorksheetHeadTab } from "src/domain/worksheet/bill/WorksheetHeadTab";
import { TWorksheetHead } from "src/domain/worksheet/tWorksheetHead";
import { TWorksheetHeadView } from "src/domain/worksheet/view/tWorksheetHeadView";
import PageHeader from "src/layouts/PageHeader";
import chartviewService from "src/services/chart/chartviewService";
import tCustomerHeadService from "src/services/customer/tCustomerHeadService";
import tEmployee2Service from "src/services/employee/tEmployee2Service";
import ryReftypeworkService from "src/services/reference/ryReftypeworkService";
import tTypepaymentRefService from "src/services/reference/tTypepaymentRefService";
import tTypetaxRefService from "src/services/reference/tTypetaxRefService";
import tTypeworksheetRefService from "src/services/reference/tTypeworksheetRefService";
import tAssetHeadService from "src/services/tasset/tAssetHeadService";
import worksheetService from "src/services/worksheetService";
import { useAppSelector } from "src/stores/store";
import Constants from "src/utils/constants";
import { formatTime, toThDate } from "src/utils/dateTimeUtils";
import { NotificationType, showNotification } from "src/utils/notificationUtils";
import { formatNumber } from "src/utils/numberUtils";
import ChartBookingForm from "../chart/ChartBookingForm";


const { RangePicker } = DatePicker;
const { Option } = Select;
const { TabPane } = Tabs;

const BillList: React.FC<TWorksheetHead> = () => {
  const [bills, setBills] = useState<WorksheetHeadTab[]>([]);
  const [page, setPage] = useState(1);
  const [showSpin, setShowSpin] = useState<boolean>(false);
  const [worksheetPreviewBlob, setWorksheetPreviewBlob] = useState<any>();
  const [worksheetPreviewData, setWorksheetPreviewData] = useState<any>();
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false);

  const [assets, setAssets] = useState<TAssetHeadView[]>([]); //001 รถ,002 ตู้,003 แผ่นเหล็ก
  const [tTypeworksheetRefs, setTTypeworksheetRefs] = useState<TTypeworksheetRef[]>([]); //ประเภทใบสั่งงาน
  const [customers, setCustomers] = useState<TCustomerHeadView[]>([]);
  const [drivers, setDrivers] = useState<TEmployee2[]>([]);
  const [paymentTypeRefs, setPaymentTypeRefs] = useState<TTypepaymentRef[]>([]);
  const [ryReftypeworks, setRyReftypeworks] = useState<RyReftypework[]>([]); //ประเภทงาน
  const [tTypetaxRefs, setTTypetaxRefs] = useState<TTypetaxRef[]>([]); //ประเภทภาษี

  const [isSearchBillDate, setIsSearchBillDate] = useState<boolean>(true);
  const [isSearchDateType, setIsSearchDateType] = useState<boolean>(true);
  const [showAllPage, setShowAllPage] = useState<boolean>(false);
  const [useCustomDate, setUseCustomDate] = useState<boolean>(false);

  const [visibleChartBookingForm, setVisibleChartBookingForm] = useState(false);
  const [selectedReserveCode, setSelectedReserveCode] = useState<string>();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  const [form] = Form.useForm();
  const tabs: Array<Object> = [];

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: 50,
      render: (value: number, item: TWorksheetHeadView, index: number) => (
        <>{(page - 1) * 15 + index + 1}</>
      ),
    },
    {
      title: "",
      key: "print",
      align: "center" as "center",
      width: 50,
      render: (value: any, item: TWorksheetHeadView, index: number) => {
        if (item.iworksheetStatus === "00") {
          return "";
        } else {
          return (
            <span>
              <a onClick={(e) => { onPrint(item); }}
              >
                <PrinterFilled />
              </a>
            </span>
          )
        };
      },
    },
    {
      title: "เลขที่ใบจอง",
      dataIndex: "ireserveCode",
      key: "ireserveCode",
      width: 100,
      render: (value: string, item: TWorksheetHeadView) => (
        <Button style={{ padding: "unset", height: "unset" }} type="link" onClick={() => onItemCliked(item)} >{item.ireserveCode}</Button>
      )
    },
    {
      title: "เลขที่ใบงาน",
      dataIndex: "iworksheetCode",
      key: "iworksheetCode",
      width: 100,
    },
    {
      title: "วันที่สร้างใบจอง",
      dataIndex: "ientryDate",
      key: "ientryDate",
      width: 150,
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{item.ientryDate ? toThDate(item.ientryDate) : ""}</span>;
      },
    },
    {
      title: "วัน/เวลาที่ทำงาน",
      dataIndex: "istartDate",
      key: "istartDate",
      width: 500,
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{item.istartDate ? toThDate(item.istartDate) : ""} : {item.istartTime ? formatTime(item.istartTime) : ""} - {item.iendDate ? toThDate(item.iendDate) : ""} : {item.iendTime ? formatTime(item.iendTime) : ""}</span>;
      },
    },
    {
      title: "รถ",
      dataIndex: "truckNumber",
      key: "truckNumber",
      width: 200,
    },
    {
      title: "บริการ",
      dataIndex: "serviceDetailName",
      key: "serviceDetailName",
      width: 200,
    },
    {
      title: "ลูกค้า",
      dataIndex: "icustName",
      key: "icustName",
      width: 200,
    },
    {
      title: "สถานที่",
      dataIndex: "ilocation",
      key: "ilocation",
      width: 200,
    },
    {
      title: "ประเภทการชำระ",
      dataIndex: "itypepaymentName",
      key: "itypepaymentName",
      width: 150,
    },
    {
      title: "สถานะการชำระเงิน",
      dataIndex: "ipaymentStatusName",
      key: "ipaymentStatusName",
      width: 180,
    },
    {
      title: "ยอดเงินรวม",
      dataIndex: "itotal2Amt",
      key: "itotal2Amt",
      width: 150,
      align: "right" as "right",
      render: (value: any, item: TWorksheetHeadView) => {
        return <span>{formatNumber(item.itotal2Amt)}</span>;
      },
    },
    {
      title: "หมายเหตุ",
      dataIndex: "iremark",
      key: "iremark",
      width: 200,
    },
    {
      title: "เลขที่ใบแจ้งหนี้",
      dataIndex: "debitnotecode",
      key: "debitnotecode",
      width: 150,
    },
  ];

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    setIsSearchBillDate(true);
    setIsSearchDateType(true);
    // setUseCustomDate(false);
    setShowSpin(false);

    (async () => {
      const tTypeworksheetRefsRes = await tTypeworksheetRefService.getTTypeworksheetRefList();
      const paymentTypeRefs = await tTypepaymentRefService.getTTypepaymentRefList();
      const ryReftypeworksRes = await ryReftypeworkService.getRyReftypeworkList();
      const tTypetaxRefsRes = await tTypetaxRefService.getTTypetaxRefList();

      setTTypeworksheetRefs(tTypeworksheetRefsRes.data);
      setPaymentTypeRefs(paymentTypeRefs.data);
      setRyReftypeworks(ryReftypeworksRes.data);
      setTTypetaxRefs(tTypetaxRefsRes.data);
    })();


    setUseCustomDate(true);
    const start = moment().startOf('month');
    const end = moment().endOf('month');
    form.setFieldsValue({
      selectedBillDate: "custom",
      selectedRangePicker: [start, end],
      selectedDateType: "ENTRY_DATE",
    });

  }, []);

  useEffect(() => {
    setShowSpin(false);
  }, [bills]);

  function onResetBill() {
    setUseCustomDate(true);
    // const now = dateToString(moment(new Date()))
    const start = moment().startOf('month');
    const end = moment().endOf('month');

    form.setFieldsValue({
      selectedBillDate: "custom",
      selectedRangePicker: [start, end],
      truckCode: undefined,
      typeWorksheetCode: undefined,
      custCode: undefined,
      driverCode: undefined,
      worksheetCodeReserveCode: undefined,
      typePaymentCode: undefined,
      typeWork: undefined,
      typeTaxCode: undefined,
      statusGroup: undefined,
      location: undefined,
    });
    setIsSearchBillDate(true);
    setIsSearchDateType(true);
    // setUseCustomDate(false);
    setBills([]);
  }

  const onSearchBills = () => {
    form.submit();
  }

  const handleOnClickedSwitch = (isSearchBillDate: boolean) => {
    setIsSearchBillDate(isSearchBillDate);
    setIsSearchDateType(isSearchBillDate);
    setUseCustomDate(false);
    if (isSearchBillDate) {
      if (form.getFieldValue("selectedBillDate") === "custom") {
        setUseCustomDate(true);
      }
    }
  };

  function onSelectSelectedDate(selectedBillDate: string) {
    setUseCustomDate(false);
    if (selectedBillDate === "custom") {
      setUseCustomDate(true);
    }
    //  else if (selectedBillDate) {
    //   form.submit();
    // }
  }

  function handleChangeselectedRangePicker(dateStrings: any) {
    // if (dateStrings[0] && dateStrings[1]) {
    //   form.submit();
    // }
  }

  const onPrint = async (item: TWorksheetHeadView) => {
    const wsh: TWorksheetHead = { ...item }
    if (item.iworksheetStatus == '00') {
      showNotification(NotificationType.ERROR, "ยังไม่ได้ยืนยันใบงาน กรุณายืนยันใบงานก่อน");
    } else if (!item.iworksheetCode) {
      showNotification(NotificationType.ERROR, "ไม่มีเลขที่ใบงาน ไม่สามารถพิมพ์ใบงานได้");
    } else {
      const resultWorksheetPreview = await chartviewService.getWorksheetPreview(wsh, showAllPage);
      if (resultWorksheetPreview.data) {
        setWorksheetPreviewBlob(resultWorksheetPreview.data);
        const fileObj = URL.createObjectURL(resultWorksheetPreview.data);
        setWorksheetPreviewData(fileObj);
        setIsPreviewModalVisible(true);
      } else {
        showNotification(
          NotificationType.ERROR,
          "ไม่พบข้อมูลที่เกี่ยวข้อง ไม่สามารถแสดงข้อมูลตัวอย่างได้"
        );
      }
    }
  }

  const handleSearchAsset = async (data: any) => {
    if (data.length < 3) return;
    const res = await tAssetHeadService.getAssetByNameNumberDriverList(data, userInfo.branchId);
    setAssets(res.data);
  };

  const handleSearchCustomer = async (data: any) => {
    if (data.length < 3) return;
    const res = await tCustomerHeadService.getCustomerByParam(data);
    setCustomers(res.data);
  };

  const handleSearchDriver = async (data: any) => {
    if (data.length < 3) return;
    const res = await tEmployee2Service.getDriverByName(data);
    setDrivers(res.data);
  };

  const onFinish = async (values: any) => {
    setShowSpin(true);
    let selectedBillDateStart: string = "";
    let selectedBillDateEnd: string = "";
    let selectedRangePickerStart: string = "";
    let selectedRangePickerEnd: string = "";
    let searchDateType: string = "";
    if (isSearchBillDate) {
      searchDateType = values.selectedDateType;
      if (values.selectedBillDate !== "custom") {
        switch (values.selectedBillDate) {
          case "today":
            selectedBillDateStart = moment().format(Constants.DB_FORMAT);
            selectedBillDateEnd = moment().format(Constants.DB_FORMAT);
            break;
          case "last1day":
            selectedBillDateStart = moment()
              .subtract(1, "days")
              .format(Constants.DB_FORMAT);
            selectedBillDateEnd = moment().format(Constants.DB_FORMAT);
            break;
          case "last3days":
            selectedBillDateStart = moment()
              .subtract(3, "days")
              .format(Constants.DB_FORMAT);
            selectedBillDateEnd = moment().format(Constants.DB_FORMAT);
            break;
          case "last7days":
            selectedBillDateStart = moment()
              .subtract(7, "days")
              .format(Constants.DB_FORMAT);
            selectedBillDateEnd = moment().format(Constants.DB_FORMAT);
            break;
          case "last31days":
            selectedBillDateStart = moment()
              .subtract(31, "days")
              .format(Constants.DB_FORMAT);
            selectedBillDateEnd = moment().format(Constants.DB_FORMAT);
            break;
          default:
            break;
        }
      } else {
        if (values.selectedRangePicker) {
          selectedRangePickerStart = moment(
            values.selectedRangePicker[0]
          ).format(Constants.DB_FORMAT);
          selectedRangePickerEnd = moment(values.selectedRangePicker[1]).format(
            Constants.DB_FORMAT
          );
        }
      }
    }

    const billRes = await worksheetService.getBills(
      searchDateType,
      isSearchBillDate,
      selectedBillDateStart,
      selectedBillDateEnd,
      selectedRangePickerStart,
      selectedRangePickerEnd,
      values.truckCode ? values.truckCode : "",
      values.typeWorksheetCode ? values.typeWorksheetCode : "",
      values.custCode ? values.custCode : "",
      values.driverCode ? values.driverCode : "",
      values.worksheetCodeReserveCode ? values.worksheetCodeReserveCode : "",
      values.typePaymentCode ? values.typePaymentCode : "",
      values.typeWork ? values.typeWork : "",
      values.typeTaxCode ? values.typeTaxCode : "",
      values.statusGroup ? values.statusGroup : "",
      values.location ? values.location : ""
    );
    setBills(billRes.data);
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };

  const onItemCliked = (item: TWorksheetHeadView) => {
    setSelectedReserveCode(item.ireserveCode);
    setVisibleChartBookingForm(true);
  };
  bills?.forEach((worksheetHeadTab) => {
    tabs.push(
      <TabPane
        tab={worksheetHeadTab.treferenceDetail.name + (worksheetHeadTab.worksheetHeadViews.length > 0 ? '(' + worksheetHeadTab.worksheetHeadViews.length + ')' : '')}
        key={worksheetHeadTab.treferenceDetail.code}
      >
        <Table
          columns={columns}
          scroll={{ x: 1800 }}
          dataSource={worksheetHeadTab.worksheetHeadViews}
          size="small"
          pagination={{
            pageSize: 15,
            onChange(current) {
              setPage(current);
            },
          }}
          rowKey={(item: TWorksheetHeadView) => (item.ireserveCode ? item.ireserveCode : "")}
          onRow={(item: TWorksheetHeadView) => {
            return {
              onDoubleClick: () => onItemCliked(item),
            };
          }}

        />
      </TabPane>
    );
  });

  return (
    <>
      <PageHeader title="จัดการใบจอง/ใบงาน" />
      <Content className="app-page-content">
        <Form
          form={form}
          name="bankBranchHead"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              margin: "0.5em 1em 0 1em",
            }}
          >
            <Space direction="vertical" size={0}>
              <Space align="baseline">
                <Switch
                  checkedChildren="วันที่สร้าง/ทำงาน"
                  unCheckedChildren=""
                  checked={isSearchBillDate}
                  onClick={handleOnClickedSwitch}
                />
                <Form.Item
                  name="selectedBillDate"
                  rules={[{ required: false }]}
                >
                  <Select
                    style={{ width: 208 }}
                    showSearch
                    size="middle"
                    disabled={!isSearchBillDate}
                    placeholder="เลือกวันย้อนหลัง"
                    optionFilterProp="children"
                    onSelect={onSelectSelectedDate}
                  >
                    <Option value="today">วันนี้</Option>
                    <Option value="last1day">ย้อนหลัง 1 วัน</Option>
                    <Option value="last3days">ย้อนหลัง 3 วัน</Option>
                    <Option value="last7days">ย้อนหลัง 7 วัน</Option>
                    <Option value="last31days">ย้อนหลัง 31 วัน</Option>
                    <Option value="custom">กำหนดเอง</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="selectedDateType"
                  rules={[{ required: false }]}
                >
                  <Select
                    style={{ width: 150 }}
                    showSearch
                    size="middle"
                    disabled={!isSearchDateType}
                    placeholder="เลือกประเภทวันที่"
                    optionFilterProp="children"
                  // onSelect={onSelectDateType}
                  >
                    <Option value="ENTRY_DATE">วันที่สร้างใบงาน</Option>
                    <Option value="WORK_DATE">วันที่ทำงาน</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="selectedRangePicker"
                  rules={[{ required: false }]}
                >
                  <RangePicker
                    allowClear
                    placeholder={["ตั้งแต่วันที่", "ถึงวันที่"]}
                    format={Constants.DATE_FORMAT}
                    disabled={!useCustomDate}
                    onChange={(dateStrings) => handleChangeselectedRangePicker(dateStrings)}
                  ></RangePicker>
                </Form.Item>
              </Space>

              <Space align="baseline">
                <Form.Item
                  name="worksheetCodeReserveCode"
                  rules={[{ required: false }]}
                >
                  <Input
                    allowClear
                    placeholder="เลขที่ใบสั่งงาน/เลขที่ใบจอง"
                    style={{ width: 406 }}
                  // onChange={form.submit}
                  ></Input>
                </Form.Item>
                <Form.Item name="typeWork" rules={[{ required: false }]}>
                  <Select
                    style={{ width: 200 }}
                    showSearch
                    size="middle"
                    placeholder="ประเภทใบสั่งงาน"
                    optionFilterProp="children"
                    filterOption={true}
                  // onSelect={form.submit}
                  >
                    {ryReftypeworks.map((obj: RyReftypework) => {
                      return (
                        <Option key={obj.id} value={obj.id}>
                          {obj.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item name="statusGroup" rules={[{ required: false }]}>
                  <Select
                    style={{ width: 200 }}
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกสถานะใบสั่งงาน"
                    optionFilterProp="children"
                    filterOption={true}
                  >
                      return (
                        <Option key="0" value="0">
                          จอง (00)
                        </Option>
                        <Option key="1" value="1">
                          ยืนยัน<br/>
                          <span style={{ marginLeft: "1rem", fontSize: 12 }}>
                            - 01: ยืนยันใบงาน
                          </span><br/>
                          <span style={{ marginLeft: "1rem", fontSize: 12 }}>
                            - 04: รอปล่อยใบงาน
                          </span><br/>
                          <span style={{ marginLeft: "1rem", fontSize: 12 }}>
                            - 05: รอยกเลิกใบงาน
                          </span>
                        </Option>
                        <Option key="2" value="2">
                          ปล่อยรถ (02)
                        </Option>
                        <Option key="3" value="3">
                          รับใบงานกลับ (03)
                        </Option>
                        <Option key="4" value="4">
                          ส่งเร่งรัด (06)
                        </Option>
                        <Option key="5" value="5">
                          อยู่เร่งรัด<br/>
                          <span style={{ marginLeft: "1rem", fontSize: 12 }}>
                            - 07: เร่งรัดรับใบงาน
                          </span><br/>
                          <span style={{ marginLeft: "1rem", fontSize: 12 }}>
                            - 08: เร่งรัดออกใบแจ้งหนี้
                          </span><br/>
                          <span style={{ marginLeft: "1rem", fontSize: 12 }}>
                            - 09: เร่งรัดรับใบแจ้งหนี้กลับ
                          </span><br/>
                          <span style={{ marginLeft: "1rem", fontSize: 12 }}>
                            - 10: เร่งรัดรับเงินจากลูกหนี้
                          </span><br/>
                          <span style={{ marginLeft: "1rem", fontSize: 12 }}>
                            - 11: เร่งรัดส่งให้การเงิน
                          </span><br/>
                          <span style={{ marginLeft: "1rem", fontSize: 12 }}>
                            - 13: ส่งการเงินสด
                          </span><br/>
                          <span style={{ marginLeft: "1rem", fontSize: 12 }}>
                            - 14: รอส่งสำนักงานใหญ่
                          </span><br/>
                          <span style={{ marginLeft: "1rem", fontSize: 12 }}>
                            - 15: ส่งสำนักงานใหญ่
                          </span><br/>
                          <span style={{ marginLeft: "1rem", fontSize: 12 }}>
                            - 16: สำนักงานใหญ่รับใบสั่งงานจากสาขา
                          </span>
                        </Option>
                        <Option key="6" value="6">
                          รับชำระเงิน<br/>
                          <span style={{ marginLeft: "1rem", fontSize: 12 }}>
                            - 12: การเงินรับชำระ(จบ)
                          </span>
                        </Option>
                        <Option key="7" value="7">
                          หนี้สงสัยจะสูญ<br/>
                          <span style={{ marginLeft: "1rem", fontSize: 12 }}>
                            - 17: หนี้สงสัยจะสูญ
                          </span><br/>
                          <span style={{ marginLeft: "1rem", fontSize: 12 }}>
                            - 20: รอตัดจบ
                          </span><br/>
                          <span style={{ marginLeft: "1rem", fontSize: 12 }}>
                            - 97: ยกเลิกการรับเงินแนบใบลดหนี้
                          </span>
                        </Option>
                        <Option key="8" value="8">
                          ยกเลิกใบงาน (98)
                        </Option>
                        <Option key="9" value="9">
                          จบ (99)
                        </Option>
                      );
                  </Select>
                </Form.Item>
              </Space>
              <Space align="baseline">
                <Form.Item
                  name="typeTaxCode"
                  rules={[{ required: false }]}
                >
                  <Select
                    style={{ width: 200 }}
                    showSearch
                    size="middle"
                    placeholder="ประเภทภาษี"
                    optionFilterProp="children"
                  // onSelect={form.submit}
                  >
                    {tTypetaxRefs.map((obj: TTypetaxRef) => {
                      return <Option value={obj.iid} key={obj.iid}>{obj.iname}</Option>;
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="typePaymentCode"
                  rules={[
                    { required: false }
                  ]}
                >
                  <Select
                    showSearch
                    size="middle"
                    placeholder="เลือกการชำระเงิน"
                    optionFilterProp="children"
                    filterOption={true}
                    // onSelect={form.submit}
                    style={{ width: 200 }}
                  >
                    {paymentTypeRefs.map((obj) => {
                      return (
                        <Option key={obj.iid} value={obj.iid}>
                          {obj.iname}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="typeWorksheetCode"
                  rules={[{ required: false }]}
                >
                  <Select
                    style={{ width: 200 }}
                    showSearch
                    size="middle"
                    placeholder="เลือกประเภทงาน"
                    optionFilterProp="children"
                  // onSelect={form.submit}
                  >
                    {tTypeworksheetRefs.map((obj) => {
                      return <Option value={obj.iid} key={obj.iid}>{obj.iname}</Option>;
                    })}
                  </Select>
                </Form.Item>
                <Form.Item name="driverCode" rules={[{ required: false }]}>
                  <Select
                    style={{ width: 200 }}
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกชื่อพนักงานขับรถ"
                    optionFilterProp="children"
                    filterOption={true}
                    onSearch={handleSearchDriver}
                  // onSelect={form.submit}
                  >
                    {drivers.map((obj) => {
                      return (
                        <Option key={obj.id} value={obj.id}>
                          {obj.empname}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Space>

              <Space align="baseline">
                <Form.Item name="truckCode" rules={[{ required: false }]}>
                  <Select
                    style={{ width: 200 }}
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกรถ/อุปกรณ์"
                    optionFilterProp="children"
                    filterOption={true}
                    onSearch={handleSearchAsset}
                  // onSelect={form.submit}
                  >
                    {assets.map((obj) => {
                      return (
                        <Option key={obj.id} value={obj.id}>
                          {obj.numbers} : {obj.assetname}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item name="custCode" rules={[{ required: false }]}>
                  <Select
                    style={{ width: 200 }}
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกลูกค้า"
                    optionFilterProp="children"
                    filterOption={false}
                    onSearch={handleSearchCustomer}
                  // onSelect={form.submit}
                  >
                    {customers.map((obj) => {
                      return (
                        <Option key={obj.icustCode} value={obj.icustCode}>
                          {obj.iprenameName}{obj.iname}  {obj.isurname ? obj.isurname : ""}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="location"
                  rules={[{ required: false }]}
                >
                  <Input
                    allowClear
                    placeholder="ป้อนสถานที่ทำงาน"
                    style={{ width: 406 }}
                  ></Input>
                </Form.Item>
              </Space>
            </Space>
          </div>
        </Form>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            margin: '0 20px 10px 20px',
            padding: "0px 20px 20px 0px",
            borderBottom: '2px solid #c9c9c9'
          }}
        >
          <Checkbox
            onChange={(e) => setShowAllPage(e.target.checked)}
          >
            พิมพ์ท้ายเอกสารทุกหน้า
          </Checkbox>
          <Space align="end">
            <Button
              size="small"
              type="primary"
              icon={<SearchOutlined />}
              onClick={onSearchBills}
            >
              ค้นหา
            </Button>
            <Button
              size="small"
              type="primary"
              icon={<ClearOutlined />}
              onClick={onResetBill}
            >
              ล้าง
            </Button>
          </Space>
        </div>
        <div>
          <Spin spinning={showSpin}>
            <Tabs
              defaultActiveKey="0"
              // type="card"
              style={{ margin: "1em 1em 0 1em" }}
            >
              {tabs}
            </Tabs>
          </Spin>
        </div>
      </Content>
      <div>
        {visibleChartBookingForm && (
          <ChartBookingForm
            selectedReserveCode={selectedReserveCode}
            openDrawerView={visibleChartBookingForm}
            setOpenDrawerView={setVisibleChartBookingForm}
          />
        )}
      </div>
      <div>
        {isPreviewModalVisible && (
          <Modal
            style={{ top: 20 }}
            width={"90vw"}
            title="ตัวอย่างใบงาน"
            bodyStyle={{ height: "85vh" }}
            centered={true}
            visible={isPreviewModalVisible}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={() => setIsPreviewModalVisible(false)}
          >
            {/* <embed title=" " src={worksheetPreviewData} width={"100%"} height={"100%"}></embed> */}
            <object title=" " data={worksheetPreviewData} width={"100%"} height={"100%"}></object>
            {/* <iframe title=" " src={worksheetPreviewData} width={"100%"} height={"100%"}></iframe> */}
          </Modal>
        )}
      </div>
    </>
  );
};

export default BillList;
