import http from "../../lib/http";
import Constants from "../../utils/constants";

const getRyRefpositionList = () => {
  return http.get(
    Constants.DEFAULT_URL + "/ryRefposition"
  );
};

const getRyRefposition = (id: string) => {
  return http.get(Constants.DEFAULT_URL + "/ryRefposition/" + id);
}

const defaultApi = {
  getRyRefposition,
  getRyRefpositionList,
};
export default defaultApi;
