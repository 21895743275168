import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, Row, Select, Table, Typography, Modal, Checkbox } from 'antd';
import moment from 'moment';
import Constants from 'src/utils/constants';
import PageHeader from 'src/layouts/PageHeader';
import { Content } from 'antd/lib/layout/layout';
import { useAppSelector } from 'src/stores/store';
import { PrinterOutlined, SearchOutlined, TrophyFilled } from '@ant-design/icons';
import reportService from 'src/services/reportService';
import { formatNumber, formatNumberInt } from 'src/utils/numberUtils';
import PdfViewPopup from './PdfViewPopup';
import { TUserHead } from 'src/domain/tUserHead';
import { RyRefbranch } from 'src/domain/ryRefbranch';
import ryRefbranchService from 'src/services/reference/ryRefbranchService';
import { RangradDebitNoteReportData } from 'src/domain/worksheet/rangradDebitNoteReportData';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { TDebitorHeadView } from 'src/domain/finance/view/tDebitorHeadView';
import tDebitorService from 'src/services/debitor/tDebitorService';


const { Option } = Select;

const RangradDebitNoteReport = () => {
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);

  const [userHead, setUserHeads] = useState<TUserHead[]>([]);    //ผู้ใช้งาน
  const [ryRefBranchSelected, setRyRefBranchSelected] = useState<RyRefbranch>({} as RyRefbranch);
  const [ryRefBranchs, setRyRefBranchs] = useState<RyRefbranch[]>([]);

  const [selectAppDate, setSelectAppDate] = useState<boolean>(true);
  const [selectEntryDate, setSelectEntryDate] = useState<boolean>(false);
  const [debitors, setDebitors] = useState<TDebitorHeadView[]>([]);

  const [rangradDebitNoteList, setRangradDebitNoteList] = useState<RangradDebitNoteReportData[]>([]);
  const [rangradDebitNotePreview, setRangradDebitNotePreview] = useState<any>();

  const [visiblePDF, setVisiblePDF] = useState(false);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false);
  
  const [sumAllNetTotalAmt, setSumAllNetTotalAmt] = useState<any>();
  const [sumRevNetTotalAmt, setSumRevNetTotalAmt] = useState<any>();
  const [sumNotRevNetTotalAmt, setSumNotRevNetTotalAmt] = useState<any>();
  
  const [fromAppDateParam, setFromAppDateParam] = useState<any>();
  const [toAppDateParam, setToAppDateParam] = useState<any>();
  const [fromEntryDateParam, setFromEntryDateParam] = useState<any>();
  const [toEntryDateParam, setToEntryDateParam] = useState<any>();
  const [branchIdParam, setBranchIdParam] = useState<any>();
  const [debitorCodeParam, setDebitorCodeParam] = useState<any>();

  const columns = [
    {
      title: "ลำดับ",
      key: "index",
      width: '5%',
      align: "center" as "center",
      render: (value: number, item: RangradDebitNoteReportData, index: number) => (<>{(page - 1) * 10 + index + 1}</>)
    },
    {
      title: "วันที่ครบกำหนดชำระ",
      dataIndex: "appointmentDate",
      key: "appointmentDate",
      width: '8%', align: "center" as "center"
    },
    {
      title: "เลขที่ใบแจ้งหนี้",
      dataIndex: "debitnoteNo",
      key: "debitnoteNo",
      width: '10%',
    },
    {
      title: "ลูกหนี้",
      dataIndex: "debitorName",
      key: "debitorName",
      width: '25%',
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "total",
      key: "total",
      width: '10%',
      align: "right" as "right",
      render: (value: any, item: RangradDebitNoteReportData) => {
        return <span>{formatNumber(item.total)}</span>;
      },
    },
    {
      title: "ภาษี 7%",
      dataIndex: "vat",
      key: "vat",
      width: '7%',
      align: "right" as "right",
      render: (value: any, item: RangradDebitNoteReportData) => {
        return <span>{formatNumber(item.vat)}</span>;
      },
    },
    {
      title: "สุทธิ",
      dataIndex: "netTotal",
      key: "netTotal",
      width: '10%',
      align: "right" as "right",
      render: (value: any, item: RangradDebitNoteReportData) => {
        return <span>{formatNumber(item.vat)}</span>;
      },
    },
    {
      title: "สถานะใบแจ้งหนี้",
      dataIndex: "statusName",
      key: "statusName",
      width: '10%',
    },
    {
      title: "หมายเหตุ",
      dataIndex: "remark",
      key: "remark",
      width: '10%',
    },

  ]

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
    // setSelectAppDate(false);
    // setSelectEntryDate(false);

    initData();
    form.submit();
  }, []);

  const initData = async () => {
    const refbranchRes = await ryRefbranchService.getRyRefbranchList();
    setRyRefBranchs(refbranchRes.data);

    form.setFieldsValue({
      fromAppDate: moment(new Date(), Constants.DATE_FORMAT),
      toAppDate: moment(new Date(), Constants.DATE_FORMAT),
      fromEntryDate: moment(new Date(), Constants.DATE_FORMAT),
      toEntryDate: moment(new Date(), Constants.DATE_FORMAT),
      branchId: "",
      debitorCode: "",
    });
  }

  const handleSearchDebitor = async (data: any) => {
    if (data.length < 3) return;
    const res = await tDebitorService.getDebitorByParam(data);
    setDebitors(res.data);
  };
  
  const rowClassNameFn = (record: RangradDebitNoteReportData, index) => {
    if(record.status === '99'){
      return "row-blue"
    } else if(record.status === '98'){
      return "row-red"
    }
    
    return null;
  }


  const onFinish = async (values: any) => {
    setLoading(true);
    let fromAppDate = "";
    let toAppDate = "";
    let fromEntryDate = "";
    let toEntryDate = "";
    let branchId = values.branchId ? values.branchId : "";
    let debitorCode = values.debitorCode ? values.debitorCode : "";

    if(selectAppDate === true) {
      fromAppDate = moment(values.fromAppDate).format("yyyyMMDD");
      toAppDate = moment(values.toAppDate).format("yyyyMMDD");
    }
    if (selectEntryDate === true) {
      fromEntryDate = moment(values.fromEntryDate).format("yyyyMMDD");
      toEntryDate = moment(values.toEntryDate).format("yyyyMMDD");
    }

    const res = await reportService.getRangradDebitNoteReport(fromAppDate, toAppDate, 
                                                              fromEntryDate, toEntryDate, 
                                                              branchId, debitorCode);
    // console.log('----------res--------', res.data)

    setRangradDebitNoteList(res.data);
    setLoading(false);

    setPage(1);
    setFromAppDateParam(fromAppDate);
    setToAppDateParam(toAppDate);
    setFromEntryDateParam(fromEntryDate);
    setToEntryDateParam(toEntryDate);
    setBranchIdParam(branchId);   
    setDebitorCodeParam(debitorCode);  
  }

  const onFinishFailed = (errorInfo: any) => {
  };

  const onSelectAppDate = (e: CheckboxChangeEvent) => {
    setSelectAppDate(e.target.checked);
  }

  const onSelectEntryDate = (e: CheckboxChangeEvent) => {
    setSelectEntryDate(e.target.checked);
  }

  useEffect(() => {  
    if (!selectAppDate) {
      setSelectAppDate(true);
    } else {
      setSelectAppDate(false);
    }

    if (!selectEntryDate) {
      setSelectEntryDate(true);
    } else {
      setSelectEntryDate(false);
    }
  }, []);


  const onPrint = async () => {
    setVisiblePDF(true);
  }

  return (
    <>
      <PageHeader title="รายงานเร่งรัดออกใบแจ้งหนี้"></PageHeader>
      <Content className="app-page-content">
        <Form
          form={form}
          name="rengradWangbill"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div
            style={{
              alignItems: "baseline",
              margin: "1em 0 1em 0",
              paddingLeft: "1em",
            }}
          >
           
            <Row>
              <Col span={6} />
              <Col span={3}>
                <Form.Item name="chkAppDate" 
                  style={{ marginBottom: "3px" }}>
                  <Checkbox style={{ marginRight: "0.5em" }}
                    checked={selectAppDate}
                    onChange={onSelectAppDate}
                  >
                  </Checkbox>
                  <Typography.Text>วันที่ครบกำหนดชำระ </Typography.Text>
                </Form.Item>
              </Col>
              <Col span={2}></Col>
              <Col span={3}>
                <Form.Item name="chkEntryDate" 
                  style={{ marginBottom: "3px" }}>
                  <Checkbox style={{ marginRight: "0.5em" }}
                    checked={selectEntryDate}
                    onChange={onSelectEntryDate}
                  >
                  </Checkbox>
                  <Typography.Text>วันที่สร้าง </Typography.Text>
                </Form.Item>
              </Col>
              <Col span={7}></Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ตั้งแต่: </Typography.Text>
              </Col>
              <Col span={3}>
                <Form.Item
                  name="fromAppDate"
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    size="middle"
                    style={{ width: "100%" }}
                    disabled={!selectAppDate}
                  />
                </Form.Item>
              </Col>
              <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ตั้งแต่: </Typography.Text>
              </Col>
              <Col span={3}>
                <Form.Item
                  name="fromEntryDate"
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    style={{ width: "100%" }}
                    disabled={!selectEntryDate}
                  />
                </Form.Item>
              </Col>
              <Col span={7}></Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ถึง: </Typography.Text>
              </Col>
              <Col span={3}>
                <Form.Item
                  name="toAppDate"
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    style={{ width: "100%" }}
                    disabled={!selectAppDate}
                  />
                </Form.Item>
              </Col>
              <Col span={2} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ถึง: </Typography.Text>
              </Col>
              <Col span={3}>
                <Form.Item
                  name="toEntryDate"
                  rules={[{ required: false }]}
                  style={{ marginBottom: "3px" }}
                >
                  <DatePicker
                    format={Constants.DATE_FORMAT}
                    style={{ width: "100%" }}
                    disabled={!selectEntryDate}
                  />
                </Form.Item>
              </Col>
              <Col span={7}></Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>สาขา: </Typography.Text>
              </Col>
              <Col span={8}>
              <Form.Item
                  name="branchId"
                  style={{ marginBottom: "3px" }}
                >
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกสาขา"
                    optionFilterProp="children"
                    filterOption={true}
                    onClear={() => setRyRefBranchSelected({} as RyRefbranch)}
                  >
                    {ryRefBranchs.map((obj) => {
                      return (
                        <Option key={obj.id} value={obj.id}>
                          {obj.id}: {obj.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7}></Col>
            </Row>
            <Row>
              <Col span={6} style={{ textAlign: "right", padding: "0.5em" }}>
                <Typography.Text>ลูกหนี้: </Typography.Text>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="debitorCode"
                  style={{ margin: "0 0 3px 0px" }}
                >
                  <Select
                    showSearch
                    allowClear
                    size="middle"
                    placeholder="เลือกลูกหนี้"
                    optionFilterProp="children"
                    filterOption={false}
                    onSearch={handleSearchDebitor}
                  >
                    {debitors.map((obj) => {
                      return (
                        <Option key={obj.iid} value={obj.iid}>
                          {obj.iname}  {obj.isurname ? obj.isurname : ""}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7}></Col>
            </Row>
            <Row style={{marginTop: "1em"}}>
              <Col span={14} style={{ textAlign: "right"}}>
                <Button
                  size="small"
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={form.submit}
                >
                  ค้นหา
                </Button>
              </Col>
              <Col span={7}></Col>
            </Row>
          </div>
        </Form>
           
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "0px 20px 10px 0px",
            borderBottom: '2px solid #c9c9c9'
          }}
        >
          <Button
          style={{
            marginLeft: "30px",}}
            size="small"
            type="primary"
            icon={<PrinterOutlined />}
            onClick={onPrint}
          >
            พิมพ์
          </Button>
          <Modal
            style={{ top: 20 }}
            width={"90vw"}
            title="RR002"
            bodyStyle={{ height: "85vh" }}
            centered={true}
            visible={isPreviewModalVisible}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={() => setIsPreviewModalVisible(false)}
          >
            <iframe title=" " src={rangradDebitNotePreview} width={"100%"} height={"100%"}></iframe>
          </Modal>
        </div>

        <div>
          <Table
            rowClassName={rowClassNameFn}
            columns={columns}
            dataSource={rangradDebitNoteList}
            size="small"
            loading={loading}
            rowKey={(item: RangradDebitNoteReportData) => item.debitnoteNo!}
            scroll={{ x: 1800 }}
            pagination={{
              pageSize: 10,
              current: page,
              onChange(current) {
                setPage(current);
              }
            }}
            summary={(pageData: any) => {
              let sumAllNetTotal = 0.0;
              let sumRevNetTotalAmt = 0.0;
              let sumNotRevNetTotalAmt = 0.0;

              rangradDebitNoteList.forEach((item) =>{
                sumAllNetTotal += item.netTotal;

                if(item.status === '99'){
                  sumRevNetTotalAmt += item.netTotal;
                }

                sumNotRevNetTotalAmt = sumAllNetTotal - sumRevNetTotalAmt;
              });
              
              setSumAllNetTotalAmt(sumAllNetTotal);
              setSumRevNetTotalAmt(sumRevNetTotalAmt);
              setSumNotRevNetTotalAmt(sumNotRevNetTotalAmt);
              
              return (
                <></>
              )
            }}
          />
        </div>

        <div style={{
            padding: "20px 0px 0px 0px"
          }}>
          <Row>
            <Col span={11}></Col>
            <Col span={6} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> จำนวนใบแจ้งหนี้: </Typography.Text>
            </Col>
            <Col span={6} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold", color: "red", marginRight: "1em" }}> {formatNumberInt(rangradDebitNoteList.length)} </Typography.Text>
            </Col>
            <Col span={1} style={{ textAlign: "left" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> ใบ </Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col span={11}></Col>
            <Col span={6} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> เป็นเงิน: </Typography.Text>
            </Col>
            <Col span={6} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold", color: "red", marginRight: "1em" }}> {formatNumber(sumAllNetTotalAmt)} </Typography.Text>
            </Col>
            <Col span={1} style={{ textAlign: "left" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> บาท </Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col span={11}></Col>
            <Col span={6} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> ค้างชำระ: </Typography.Text>
            </Col>
            <Col span={6} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold", color: "red", marginRight: "1em" }}> {formatNumber(sumNotRevNetTotalAmt)} </Typography.Text>
            </Col>
            <Col span={1} style={{ textAlign: "left" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> บาท </Typography.Text>
            </Col>
          </Row>
          
          <Row>
            <Col span={11}></Col>
            <Col span={6} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> ชำระแล้ว: </Typography.Text>
            </Col>
            <Col span={6} style={{ textAlign: "right" }}>
              <Typography.Text style={{ fontWeight: "bold", color: "blue", marginRight: "1em" }}> {formatNumber(sumRevNetTotalAmt)} </Typography.Text>
            </Col>
            <Col span={1} style={{ textAlign: "left" }}>
              <Typography.Text style={{ fontWeight: "bold" }}> บาท </Typography.Text>
            </Col>
          </Row>
        </div>
      </Content>

      <Modal
        title= 'print'
        centered
        closable={true}
        visible={visiblePDF}
        onCancel={() => setVisiblePDF(false)}
        width="400"
      >
        <PdfViewPopup
          urlPath={reportService.getRangradDebitNoteReportPreview(fromAppDateParam, toAppDateParam, 
                                                                  fromEntryDateParam, toEntryDateParam,
                                                                  branchIdParam, debitorCodeParam)}
          onClose={() => setVisiblePDF(false)}
        />
      </Modal>
    </>
  )
}

export default RangradDebitNoteReport