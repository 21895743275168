import { Checkbox, Col, Form, Modal, Row, Select, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";
import { TEmployee2View } from "src/domain/master/view/tEmployee2View";
import { TUserHead } from "src/domain/tUserHead";
import { WorksheetHeadData } from "src/domain/worksheet/view/worksheetHeadData";
import { useAppSelector } from "src/stores/store";
import { NotificationType, showNotification } from "src/utils/notificationUtils";
import employeeService from 'src/services/employee/tEmployee2Service';
import worksheetService from "src/services/worksheetService";
import { TWorksheetHead } from "src/domain/worksheet/tWorksheetHead";

const { Option } = Select;

interface IProps {
  editWshWasteTimeOvertimeModalVisible: boolean;
  setEditWshWasteTimeOvertimeModalVisible: any;
  worksheetHeadData: WorksheetHeadData;
  setWorksheetHeadData: any;
  tUserHeadUpdate: TUserHead;
}

const EditWshWasteTimeOvertimeModal: React.FC<IProps> = (props) => {
  const { editWshWasteTimeOvertimeModalVisible, setEditWshWasteTimeOvertimeModalVisible, worksheetHeadData, setWorksheetHeadData, tUserHeadUpdate } = props;
  const [trigger, setTrigger] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [employees, setEmployees] = useState<TEmployee2View[]>([]);//ผู้แจ้ง 
  const userInfo = useAppSelector((state) => state.authen.authenUser.user);
  const [disableChkWshWasteTime, setDisableChkWshWasteTime] = useState<boolean>(true);
  const [disableChkWshOvertime, setDisableChkWshWshOvertime] = useState<boolean>(true); 
  const [disableChkReferencePayment, setDisableChkReferencePayment] = useState<boolean>(true);

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  useEffect(() => {
    const disableChk: boolean = worksheetHeadData.tworksheetHead.typeworksheetflag !== "05";
    setDisableChkWshWshOvertime(disableChk);
    setDisableChkWshWasteTime(disableChk);
    setDisableChkReferencePayment(!disableChk);

    form.setFieldsValue({
      chkWshWasteTime: worksheetHeadData.tworksheetHead.iflgWasteTime === 1 ? true : false,
      chkNoAllowIncome: worksheetHeadData.tworksheetHead.iflgNotAllowIncome === 1 ? true : false,
      chkWshOvertime: worksheetHeadData.tworksheetHead.iflgWshOt === 1 ? true : false,
      chkReferencePayment: worksheetHeadData.totherpaymentHead ? true : false,
    });
    return () => { };
  }, []);

  const handleSearchEmployee = async (data: any) => {
    if (data.length < 3) return;
    const res = await employeeService.getEmployee2ByParam(data);
    setEmployees(res.data);
  };

  const onFinish = async (values: any) => {
    //flgDisplaydetail: values.flgDisplayDetail ? 1 : 0,
    if (!values.chkReferencePayment) {
      if (worksheetHeadData.totherpaymentHead && worksheetHeadData.totherpaymentHead.iremainAmt !== worksheetHeadData.totherpaymentHead.itotalAmt) {
        showNotification(NotificationType.ERROR, "จำนวนเงินคงเหลือรายการอ้างอิงไม่ถูกต้อง");
        setTrigger(false);
      }
    }

    if (worksheetHeadData.totherpaymentHead && worksheetHeadData.totherpaymentHead.iremainAmt !== worksheetHeadData.totherpaymentHead.itotalAmt) {
      showNotification(NotificationType.ERROR, "จำนวนเงินคงเหลือรายการอ้างอิงไม่ถูกต้อง");
      setTrigger(false);
    } else {
      const employeeArr = employees as Array<TEmployee2View>;
      const empSelected = employeeArr.find((obj) => obj.id === values.userInfoId);

      const temp = {
        ...(worksheetHeadData as WorksheetHeadData),
        tworksheetHead: {
          ...(worksheetHeadData.tworksheetHead as TWorksheetHead),
          iflgWasteTime: values.chkWshWasteTime ? 1 : 0,
          iflgNotAllowIncome: values.chkNoAllowIncome ? 1 : 0,
          iflgWshOt: values.chkWshOvertime ? 1 : 0,
          isavStation: userInfo.saveStation,
          ilastUser: tUserHeadUpdate.iuserId,
          itermNo: userInfo.termNo,
        },
        updateUser: tUserHeadUpdate,
      };
      setWorksheetHeadData(temp);

      try {
        // console.log("*************** temp : " , temp);
        await worksheetService.updateWorksheetWithSubMenuKey(temp, values.userRemark, empSelected ? empSelected.empname : "", "MENU_WORKSHEET_WSHWASTETIME_OVERTIME");
        showNotification(NotificationType.SUCCESS, "บันทึกข้อมูลเรียบร้อย");
        setEditWshWasteTimeOvertimeModalVisible(false);
        form.resetFields();
        setTrigger(false);
      } catch (err) {
        showNotification(NotificationType.ERROR, "ข้อมูลผิดพลาด กรุณาติดต่อผู้ดูแลระบบ!");
        setTrigger(false);
      }
    }
  };  

  const onFinishFailed = (errorInfo: any) => {
    showNotification(NotificationType.ERROR, "กรุณาตรวจสอบข้อมูลให้ครบถ้วน!");
    setTrigger(false);
  };

  return (
    <>
      <Modal
        title={
          <div>
            <Typography.Title level={3}>ปรับปรุงใบงาน</Typography.Title>
          </div>
        }
        visible={editWshWasteTimeOvertimeModalVisible}
        width={"50%"}
        closable={false}
        destroyOnClose
        maskClosable={false}
        onOk={() => setTrigger(true)}
        onCancel={() => setEditWshWasteTimeOvertimeModalVisible(false)}
      >
        <Form
          form={form}
          name="editInstallment"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >

          <Row>
            <Col span={4}></Col>
            <Col span={10}>
              <Form.Item
                name="chkWshWasteTime"
                valuePropName="checked"
                style={{ marginBottom: "2px", backgroundColor:"#33CC00", paddingLeft: 5, borderRadius: 5 }}
              >
                <Checkbox
                  style={{ color:"black !important" }}
                  disabled={disableChkWshWasteTime}
                  >
                  ส่วนลดมีผลกับ % คนขับรถ
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4}></Col>
            <Col span={10}>
              <Form.Item
                name="chkNoAllowIncome"
                valuePropName="checked"
                style={{ marginBottom: "2px", backgroundColor:"#FF0000", paddingLeft: 5, borderRadius: 5 }}
              >
                <Checkbox>
                ไม่คิด % พนักงาน
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4}></Col>
            <Col span={10}>
              <Form.Item
                name="chkWshOvertime"
                valuePropName="checked"
                style={{ marginBottom: "2px", backgroundColor:"blue", paddingLeft: 5, borderRadius: 5 }}
              >
                <Checkbox disabled={disableChkWshOvertime}>
                ใบงานล่วงเวลา
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4}></Col>
            <Col span={10}>
              <Form.Item
                name="chkReferencePayment"
                valuePropName="checked"
                style={{ backgroundColor:"#4f9151", paddingLeft: 5, borderRadius: 5 }}
              >
                <Checkbox disabled={disableChkReferencePayment}>
                ใบงานอ้างอิงการรับเงิน
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <span>
                <Typography.Text>ผู้แจ้ง: </Typography.Text>
                <label className="text-red">*</label>
              </span>
            </Col>
            <Col span={20}>
              <Form.Item
                name="userInfoId"
                rules={[{ required: true, message: "กรุณาระบุ ผู้แจ้ง!" }]}
              >
                <Select
                  showSearch
                  allowClear
                  size="middle"
                  placeholder="เลือกผู้แจ้ง"
                  optionFilterProp="children"
                  filterOption={true}
                  onSearch={handleSearchEmployee}

                >
                  {employees.map((obj) => {
                    return (
                      <Option key={obj.id} value={obj.id}>
                        {obj.empname}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4} style={{ textAlign: "right", padding: "0.5em" }}>
              <span>
                <Typography.Text>หมายเหตุ: </Typography.Text>
                <label className="text-red">*</label>
              </span>
            </Col>
            <Col span={20}>
              <Form.Item
                name="userRemark"
                validateTrigger="onBlur"
                rules={[{ required: true, message: "กรุณาระบุเหตุผล !" }]}
              >
                <TextArea
                  rows={4}
                ></TextArea>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default EditWshWasteTimeOvertimeModal